<div mat-dialog-title class="position-relative w-100">
  <h5>Filter Inspections & Audits</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close this dialog." mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">
  <ng-container matColumnDef="sites">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectSites()"
        [matBadge]="('' + filters.site_ids.length) || 'All'"
        class="mx-3 mt-3">
        <mat-icon>location_on</mat-icon> {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="site_audits">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        *ngIf="include_template_selector"
        (click)="onSelectSiteAuditTemplate()"
        [matBadge]="'' + filters.template_ids.length"
        matTooltip="You can filter your audits by selecting any of your templates. The template names will be used for the filtering."
        class="mx-3 mt-3">
        <mat-icon>library_books</mat-icon> Audit/Inspection Templates
      </button>
    </div>
  </ng-container>

  <app-date-range-picker dateRangeTitle="Date Range" dateRangePlaceholder="Select Date Range" dateRangeTooltip="Filter Inspections & Audits by creation date range." [dateRangeInput]="filters.date_range" (dateRangeOutput)="getOutputDateRange($event)"></app-date-range-picker>

</div>

<div class="d-flex justify-content-center my-2 py-2">
  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        class="float-right"
        color="primary"
        (click)="onSaveAndClose()">
        <mat-icon>filter_list</mat-icon> Apply Filter
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        color="secondary"
        class="float-right"
        (click)="onClearAndClose()">
        <mat-icon> clear_all</mat-icon> Clear Filter
      </button>
    </div>
  </ng-container>
</div>
