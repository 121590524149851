import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {RISK_LEVEL_OF_CONTROLS} from "../../shared/risk-level-of-controls";
import {AppService} from "../../app.service";
import {ActivatedRoute, Router} from "@angular/router";
import { TaskAnalysesRevisedTabsComponent } from '../task-analyses-revised-tabs/task-analyses-revised-tabs.component';
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-task-analyses-revised-view',
  templateUrl: './task-analyses-revised-view.component.html',
  styleUrls: ['./task-analyses-revised-view.component.scss']
})
export class TaskAnalysesRevisedViewComponent implements OnInit {

  @Input() taTabsComponent: TaskAnalysesRevisedTabsComponent;
  @Input() private taRevisedId: number;

  ta_revised: TaskAnalysesRevisedModel = {} as TaskAnalysesRevisedModel;

  loc_list: string[] = RISK_LEVEL_OF_CONTROLS;

  inProgress = false;
  path: string;

  approverId: number = 0;
  approverAcknowledged: boolean = false;

  authorizerId: number = 0;
  authorizerAcknowledged: boolean = false;

  supervisorId: number = 0;
  supervisorAcknowledged: boolean = false;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // get the ta revised id from the parent route params
    this.ta_revised.id = this.taRevisedId;

    // get sites path edit/view
    this.path = this.route.parent.snapshot.routeConfig.path;

    if(this.ta_revised.id) {
      this.find(this.ta_revised.id);
    } else {
      this.redirectBack();
    }
  }

  private redirectBack() {
    this.router.navigate([`/task-analyses-revised/`]);
  }

  public find(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {})
      .then((response) => {

          this.ta_revised = {...response};

          // Extract PPE ids.
          this.ta_revised.ppe_ids = this.ta_revised.ppe ? this.ta_revised.ppe.map((ppe) => ppe.id) : [];

          // Extract Work Permit ids.
          this.ta_revised.work_permit_ids = this.ta_revised.work_permits ? this.ta_revised.work_permits.map((work_permit) => work_permit.id) : [];

          // Extract Tool ids.
          this.ta_revised.tool_ids = this.ta_revised.tools ? this.ta_revised.tools.map((tool) => tool.id) : [];

          // Extract User ids.
          this.ta_revised.user_ids = this.ta_revised.users ? this.ta_revised.users.map((user) => user.id) : [];

          // Extract File ids.
          this.ta_revised.file_ids = this.ta_revised.files ? this.ta_revised.files.map((file) => file.id) : [];

          // Fixed Acknowledgement Data not changed by Form updates
          this.approverId = this.ta_revised.approver_id;
          this.approverAcknowledged = this.ta_revised.approver_acknowledged;

          this.authorizerId = this.ta_revised.authorizer_id;
          this.authorizerAcknowledged = this.ta_revised.authorizer_acknowledged;

          this.supervisorId = this.ta_revised.supervisor_id;
          this.supervisorAcknowledged = this.ta_revised.supervisor_acknowledged;

      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private acknowledgementUpdate(updateAcknowledgement: string = "") {
    this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.taRevisedId}/acknowledgement`, this.ta_revised, {
        user_id: this.app.user.id,
        acknowledge_approver_status: updateAcknowledgement == "approver" ? this.approverAcknowledged : false,
        acknowledge_authorizer_status: updateAcknowledgement == "authorizer" ? this.authorizerAcknowledged : false,
        acknowledge_supervisor_status: updateAcknowledgement == "supervisor" ? this.supervisorAcknowledged : false,
        acknowledge_user_id: 0
      })
      .then((response) => {
        let acknowledgement_text = "Acknowledgement";
        if(updateAcknowledgement == "approver") {
          acknowledgement_text = "Approval";
        } else if(updateAcknowledgement == "authorizer") {
          acknowledgement_text = "Authorization";
        } else if(updateAcknowledgement == "supervisor") {
          acknowledgement_text = "Supervisor Acknowledgement";
        }

        this.onSuccess(`TA/JSA/SWMS Template Updated "${acknowledgement_text}"`);
        this.find(this.ta_revised.id);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
  }

  onApprove() {
    this.approverAcknowledged = true;
    this.inProgress = true;
    this.acknowledgementUpdate("approver");
  }

  onAuthorize() {
    this.authorizerAcknowledged = true;
    this.inProgress = true;
    this.acknowledgementUpdate( "authorizer");
  }

  onSupervisorAcknowledge() {
    this.supervisorAcknowledged = true;
    this.inProgress = true;
    this.acknowledgementUpdate( "supervisor");
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
