import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMarginRight]'
})
export class MarginRightDirective {
  constructor(element: ElementRef) {
    element.nativeElement.style.marginRight = '16px';
  }
}
