import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CodesService } from '../codes.service';
import { Location } from '@angular/common';
import { CodeModel } from 'src/app/models/code.model';
import {UtilsService} from "../../../shared/utils.service";

@Component({
  selector: 'app-codes-view',
  templateUrl: './codes-view.component.html',
  styleUrls: ['./codes-view.component.scss']
})
export class CodesViewComponent implements OnInit {
  code = new CodeModel();

  constructor(
    public location: Location,
    private activatedRoute: ActivatedRoute,
    private codeService: CodesService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    this.code.id = Number(this.activatedRoute.snapshot.params['id']);
    this.codeService
      .find(this.code.id)
      .subscribe((code) => (this.code = code.data));
  }
}
