<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="button-row">
    <button mat-raised-button routerLink="../..">
      <mat-icon>arrow_back</mat-icon> Inspection/Audit Templates
    </button>
  </div>
</nav>

<h6>Inspection/Audit Template</h6>
<p>{{ audit.name }}</p>

<h6>Inspection/Audit Questions</h6>
<table mat-table [dataSource]="audit.items" class="mat-elevation-z8">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Question</th>
    <td mat-cell *matCellDef="let item">{{ item.name }}</td>
  </ng-container>

  <ng-container matColumnDef="section">
    <th mat-header-cell *matHeaderCellDef>Section</th>
    <td mat-cell *matCellDef="let item">{{ item.section }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
