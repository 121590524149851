import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from '../../../utils/api-data-source';
import { ApiRequestService } from '../../../shared/api-request.service';
import { MatSort, Sort } from '@angular/material/sort';
import {
  NotificationRolesSelectorComponent
} from '../../../account/notification-roles/notification-roles-selector/notification-roles-selector.component';
import {
  NotificationRolesTabsComponent
} from '../../../account/notification-roles/notification-roles-tabs/notification-roles-tabs.component';
import { NotificationRole } from '../../../interfaces/notification-role.interface';
import { UserModel } from '../../../models/user.model';
import { SiteModel } from '../../../models/site.model';
import { SystemNotification } from '../../../interfaces/system-notification.interface';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-sites-notifications-roles',
  templateUrl: './sites-notifications-roles.component.html',
  styleUrls: ['./sites-notifications-roles.component.scss']
})
export class SitesNotificationsRolesComponent implements OnInit, AfterViewInit {

  // Get the site id.
  @Input('site_id') site_id: number;

  // Columns to show and the data source.
  displayedColumns: string[] = ['select', 'name', 'is_active', 'system_notifications_count', 'sites_count', 'users_count', 'actions'];
  dataSource: SiteNotificationsRolesDataSource = new SiteNotificationsRolesDataSource(this.app, this.api, true);

  // The paginator and sorter.
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) {}

  ngOnInit(): void {
    // Store the site id before making API requests.
    this.dataSource.site_id = this.site_id;

    // Make the initial API request to get the list of notification roles attached to the site.
    this.dataSource.getData(true);
  }

  ngAfterViewInit(): void {
    // Reset the paginator when sorting.
    this.sort.sortChange.subscribe((): void => {
      this.paginator.pageIndex = 0;
    });

    // Define the paginator tap events.
    const _tap: any = tap((): void => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;
      // Refresh the data.
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator.page.pipe(_tap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter: Sort): void => {
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData(true);
    })).subscribe();
  }

  /**
   * Edit an existing notification role.
   *
   * @param notification_role_id The id of the notification role.
   */
  onEditNotificationRole(notification_role_id?: number): void {
    this.utils.showComponentDialog(NotificationRolesTabsComponent, {
      notification_role_id: notification_role_id
    }, {
      width: '90%'
    })
      .then((): void => {
        // Refresh the list after the dialog closed.
        this.dataSource.getData();
      });
  }

  /**
   * Opens the notification roles selector dialog and syncs selected notification roles with the site.
   *
   * @returns {void}
   */
  onSelectNotificationRoles(): void {
    // Show the notification roles selector.
    this.utils.showComponentDialog(NotificationRolesSelectorComponent, {
        multiple: true,
        selected: this.dataSource.allIds
      }, {}, (notification_role_ids: number[]): void => {
        if ( typeof notification_role_ids !== 'undefined') {
          // Make an update request to the API.
          this.api.makeRequest('put', `v2/site/${this.site_id}/notifications/roles`, {
            notification_role_ids: notification_role_ids
          }).then((): void => {
            this.utils.showToast(`The selected notification role(s) were synced successfully to the ${this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase()}.`);
          }).finally((): void => {
            this.dataSource.getData(true);
          });
        }
      }
    );
  }

  /**
   * Unlinks a notification role from the site.
   *
   * @param notification_role_id The notification role id.
   * @param evt
   * @return {void}
   */
  onUnlinkNotificationRole(notification_role_id: number, evt: any): void {
    this.utils.showQuickActions(evt.target, `Are you sure you want to unlink the notification role from the ${this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase()}?`, [
      {
        text: 'Yes',
        handler: (): void => {
          // Unlink the notification role from the site.
          this.unlinkNotificationRoles([notification_role_id]);
        }
      },
      {
        text: 'No',
        handler: (): void => {
          // Do nothing.
        }
      }
    ]);
  }

  /**
   * Unlinks selected notification roles from the site.
   *
   * @return {void}
   */
  onUnlinkSelectedNotificationRoles(): void {
    // Get the selected notification role ids.
    const ids: any[] = this.dataSource.selection.selected;
    // Check if we have notification roles selected.
    if ( ids.length == 0 ) {
      this.utils.showModal('Unlink Notification Roles', `Please select some notification role(s) to unlink from the ${this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase()}.`);
      return;
    }
    // Make a request to delete the notification roles.
    this.utils.showModal('Unlink Notification Roles', `Are you sure you want to unlink notification role(s) from the ${this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase()}?`, (): void => {
      this.unlinkNotificationRoles(this.dataSource.selection.selected).then((): void => {
        // Clear the list selection.
        this.dataSource.selection.clear();
      });
    });
  }

  /**
   * This method unlinks the notification roles from the site.
   *
   * @returns {Promise<any>} - A promise that resolves when the unlinking is complete.
   * @private
   * @param notification_role_ids The notification role ids.
   */
  private unlinkNotificationRoles(notification_role_ids: number[]): Promise<any> {
    // Make an API request to unlink the notification roles.
    return this.api.makeRequest('delete', `v2/site/${this.site_id}/notifications/roles`, {}, {
      notification_role_ids: notification_role_ids.join(',')
    }).then((): void => {
      this.utils.showToast(`The notification role(s) was detached from the ${this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase()}.`);
      // Refresh the list.
      this.dataSource.getData();
    });
  }

  /**
   * Get system notifications labels for a specific notification role to display in a tooltip.
   *
   * @param notification_role The notification role for which the system notifications labels are needed.
   * @return The concatenated labels of system notifications for the specified notification role separated by newline characters.
   */
  getNotificationRoleSystemNotificationsForTooltip(notification_role: NotificationRole) {
    return notification_role.system_notifications.map((system_notification: SystemNotification) => {
      return system_notification.label;
    }).join('\n');
  }

  /**
   * Retrieves the names of sites associated with a specific notification role for tooltip display.
   *
   * @param notification_role The notification role for which to retrieve associated sites.
   * @return A string containing the names of sites associated with the provided notification role, separated by line breaks.
   */
  getNotificationRoleSitesForTooltip(notification_role: NotificationRole) {
    return notification_role.sites.map((site: SiteModel) => {
      return site.name;
    }).join('\n');
  }

  /**
   * Retrieves the contact persons of the users associated with a given notification role.
   *
   * @param {NotificationRole} notification_role - The notification role for which to fetch users.
   * @returns A string containing the contact persons of the users associated with the notification role, separated by new lines.
   */
  getNotificationRoleUsersForTooltip(notification_role: NotificationRole) {
    return notification_role.users.map((user: UserModel) => {
      return user.contact_person;
    }).join('\n');
  }
}

export class SiteNotificationsRolesDataSource extends ApiDataSource {

  // Order and order by params.
  order_by: string = 'name';
  order: string = 'asc';

  // Store the site id.
  site_id: number;

  getData(resetOffset: boolean = false): void {
    this.makeRequest(`v2/site/${this.site_id}/notifications/roles`, resetOffset, {
      order_by: this.order_by,
      order: this.order
    });
  }
}
