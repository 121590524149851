<div mat-dialog-title>
    <h5>
      Form Audit Logs
      <button mat-icon-button class="float-right" mat-dialog-close>
        <mat-icon color="secondary">close</mat-icon>
      </button>
    </h5>
</div>

<div mat-dialog-content>
  <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

  <table mat-table [dataSource]="dataSource">

    <caption class="px-3">This is a list of audit logs for this user and form.</caption>

    <!-- Selection Toggle -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef id="th-select">
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
          matTooltip="Toggle selection of all audit log entries in the visible list.">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let user">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(user.id) : null"
          [checked]="dataSource.selection.isSelected(user.id)" matTooltip="Toggle selection of this audit log entry.">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- ID -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef id="th-id" matTooltip="The ID of the audit log entry.">
        #
      </th>
      <td mat-cell *matCellDef="let auditLogEntry">
        {{ auditLogEntry.id }}
      </td>
    </ng-container>

    <!-- Full Name -->
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef id="th-contact_person" matTooltip="The user that submitted the form.">
        Full Name
      </th>
      <td mat-cell *matCellDef="let auditLogEntry">
        <a (click)="onUserPublicView(auditLogEntry.user.hash)" matTooltip="View the user's public profile.">
          {{ auditLogEntry.user.contact_person }}
        </a>
      </td>
    </ng-container>

    <!-- Form -->
    <ng-container matColumnDef="form">
      <th mat-header-cell *matHeaderCellDef id="th-email" matTooltip="The form that the user submitted.">
        Form
      </th>
      <td mat-cell *matCellDef="let auditLogEntry" [matTooltip]="auditLogEntry.form.description">
        {{ auditLogEntry.form.title }}
      </td>
    </ng-container>

    <!-- Description -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef id="th-dynamic_form_status" matTooltip="The description of the action that was taken.">
        Description
      </th>
      <td mat-cell *matCellDef="let auditLogEntry">
        {{ auditLogEntry.description }}
      </td>
    </ng-container>

    <!-- Actioned By (Full Name) -->
    <ng-container matColumnDef="actioned_by">
      <th mat-header-cell *matHeaderCellDef id="th-actioned-by" matTooltip="The user that performed the action.">
        Actioned By
      </th>
      <td mat-cell *matCellDef="let auditLogEntry">
        <a *ngIf="auditLogEntry.created_by_user; else noActioner" (click)="onUserPublicView(auditLogEntry.created_by_user.hash)" matTooltip="View the user that performed the action's public profile.">
          {{ auditLogEntry.created_by_user.contact_person }}
        </a>
        <ng-template #noActioner>
          <span class="text-muted" matTooltip="This was created done by the system.">System</span>
        </ng-template>
      </td>
    </ng-container>

    <!-- Actioned At (Date) -->
    <ng-container matColumnDef="actioned_at">
      <th mat-header-cell *matHeaderCellDef id="th-actioned-at" matTooltip="The date the audit log entry was created at.">
        Actioned At
      </th>
      <td mat-cell *matCellDef="let auditLogEntry">
        {{ auditLogEntry.date_created ? ((auditLogEntry.date_created * 1000) | timezoneConvertLong) : '' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
</div>
