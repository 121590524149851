
<form #form="ngForm" (ngSubmit)="onSubmit(form)">

  <mat-dialog-content class="my-3 mx-0 py-0 pr-3 pl-0" style="max-height: 40vh">

    <ng-container *ngIf="ta_revised_workflow.step_no">
      <h6>Step No</h6>
      <p>{{ta_revised_workflow.step_no}}</p>
    </ng-container>

    <mat-form-field>
      <textarea
        rows="8"
        matInput
        placeholder="Description"
        [(ngModel)]="ta_revised_workflow.description"
        name="description"
        required
        #description
        matTooltip="Enter a TA/JSA/SWMS Template Workflow Step description.">
      </textarea>
      <mat-hint>Please enter a TA/JSA/SWMS Template Workflow Step description.</mat-hint>
    </mat-form-field>

  </mat-dialog-content>

  <button
    mat-raised-button
    [disabled]="!form.valid || inProgress || !ta_revised_workflow.id"
    color="primary"
    type="button"
    id="save"
    class="my-2"
    (click)="onSubmit(form, false)">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    mat-raised-button
    [disabled]="!form.valid || inProgress"
    color="primary"
    type="button"
    id="save_close"
    class="margin-right my-2"
    (click)="onSubmit(form)">
    <mat-icon>save</mat-icon>
    Save & Close
  </button>

  <app-file-browse-button
    (selectedFiles)="onSelectFiles($event)"
    (afterDialogClosedEvent)="onSubmit(form, false)"
    parentObjectType="task_analysis_revised_workflow"
    [parentObjectId]="ta_revised_workflow.id"
    [filesCount]="ta_revised_workflow.files?.length">
  </app-file-browse-button>

</form>


