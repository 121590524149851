<table mat-table [dataSource]="dataSource">
  <!-- Left-aligned headers -->
  <ng-container matColumnDef="header">
    <th mat-header-cell *matHeaderCellDef>Statistic</th>
    <td mat-cell *matCellDef="let element">{{ element.header }}</td>
  </ng-container>

  <!-- Right-aligned data -->
  <ng-container matColumnDef="data">
    <th mat-header-cell *matHeaderCellDef>Value</th>
    <td mat-cell *matCellDef="let element">{{ element.data }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['header', 'data']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['header', 'data']"></tr>
</table>
<sub *ngIf="!filters.date_range || filters.date_range.length == 0 else date_range">A maximum of 3 years of data is displayed.</sub>
<ng-template #date_range>
  <sub>{{ chartsUtils.getFormattedDateRange(filters.date_range) }}</sub>
</ng-template>
