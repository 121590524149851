import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import { SafetyObservation } from 'src/app/models/safety-observation.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { NetworkedAccountsSelectorComponent } from 'src/app/shared/networked-accounts-selector/networked-accounts-selector.component';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import * as moment from 'moment';
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import {IndustriesModel} from "../../admin/industries/industries.model";


@Component({
  selector: 'app-safety-observations-view-contractors',
  templateUrl: './safety-observations-view-contractors.component.html',
  styleUrls: ['./safety-observations-view-contractors.component.scss']
})
export class SafetyObservationsViewContractorsComponent implements OnInit {

  @Input('safety_observation_id') safety_observation_id: number;
  @Input('safety_observation') safety_observation: SafetyObservation;

  dataSource: SafetyObservationsContractorsDataSource = new SafetyObservationsContractorsDataSource(this.app, this.api);
  faHardHat = faHardHat;

  industries: IndustriesModel[] = [];

  listColumns: string[] = [
    'id',
    'name',
    'industry',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];

  // the paginator and sorter
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService // used in view
  ) { }

  ngOnInit() {
    this.dataSource.safety_observation_id = this.safety_observation_id;
    this.dataSource.getData();

    // Get industries from the API.
    this.getIndustries();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC dates based on its corresponding date column.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData();
    })).subscribe();
  }

  private async getIndustries() {
    this.industries = await this.utils.getIndustries();
  }

  onManageContractors() {
    this.utils.showComponentDialog(
      NetworkedAccountsSelectorComponent,
      {
        selected: this.dataSource.allIds,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results != 'undefined') {
          // Set the newly selected ids.
          this.dataSource.allIds = results;
          // Send API request to sync contractors.
          this.api.makeRequest('put', `v2/safety-observations/${this.safety_observation_id}/contractors`, {
            ids: this.dataSource.allIds
          })
          .then(() => {
            // Refresh the list.
            this.dataSource.getData();
          });

        }
      }
    );
  }

  /**
   * Send a message to the contractor.
   * @param contractor_id The contractor id.
   */
  onSendMessage(contractor_id: number) {
    if ( !contractor_id ) {
      this.utils.showModal('Error', `Please click the send message button to send a message to a ${ this.utils.getLangTerm('contractors.singular', 'Contractor') }.`);
      return;
    }
    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectAccountIds: [contractor_id],
      safetyObservationId: this.safety_observation_id, // This is only associated when new records are created.
      prePopulateSubject: 'Safety Observation Reported On ' + moment.unix(this.safety_observation.date_created).toString(),
      prePopulateMessage: ('Hi ,<br/><br/><b>Safety Observation:</b><br/>' + this.safety_observation.so_description + '<br/><br/><b>Action Taken:</b> <br/>' + (this.safety_observation.so_action_taken || '') + '<br/><br/><b>Category:</b> <br/>' + this.safety_observation.so_category + '<br/><br/><b>Type:</b> <br/>' + this.safety_observation.so_type + '<br/><br/><b>Priority:</b> <br/>' + this.safety_observation.so_priority + '<br/><br/><b>Status:</b> <br/>' + this.safety_observation.so_status + '<br/><br/><b>Rating:</b> <br/>' + this.safety_observation.so_rating)
    })
    .then(() => {
      this.utils.showToast('You can view the message stats in the messages section.');
    });
  }
}


export class SafetyObservationsContractorsDataSource extends ApiDataSource {
  // Order the records by the date it was created.
  order_by = 'date_created';
  order = 'desc';

  safety_observation_id: number;

  industry: string = '';

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/safety-observations/${this.safety_observation_id}/contractors`, resetOffset, {
      industry: this.industry
    });
  }
}
