<div mat-dialog-title>
  <div class="d-flex">
    <h3>
      Form Templates
    </h3>

    <span style="flex: 1 1 auto;"></span>

    <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
      <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search" aria-label="Search" id="search-input" matTooltip="Search for forms by title." />

      <button mat-raised-button color="secondary" appMarginRight id="search-button" matTooltip="Run the current search">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>
  </div>
</div>

<mat-dialog-content>
  <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

  <table mat-table [dataSource]="dataSource" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>

    <caption class="px-3">This is a list of all form templates that you have access to.</caption>

    <!-- Selection Toggle -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef id="th-select">
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
          matTooltip="Toggle selection of all forms in the visible list.">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let form">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(form.id) : null"
          [checked]="dataSource.selection.isSelected(form.id)" matTooltip="Toggle selection of this form.">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- ID -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-id" matTooltip="Toggle sorting by form ID.">
        #
      </th>
      <td mat-cell *matCellDef="let form">
        {{ form.id }}
      </td>
    </ng-container>

    <!-- Title -->
    <ng-container matColumnDef="title">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-title" matTooltip="Toggle sorting by form title.">
        Title
      </th>
      <td mat-cell *matCellDef="let form">
        {{ form.title }}
      </td>
    </ng-container>

    <!-- From -->
    <ng-container matColumnDef="from">
      <th mat-header-cell *matHeaderCellDef id="th-from" matTooltip="Shows who owns the form template.">
        From
      </th>
      <td mat-cell *matCellDef="let form">
        {{ form?.account?.name }}
      </td>
    </ng-container>

    <!-- Date Created -->
    <ng-container matColumnDef="date_created">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created" matTooltip="Toggle sorting by creation date.">
        Created On
      </th>
      <td mat-cell *matCellDef="let form">
        {{ (form.date_created * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <!-- Date Modifeid -->
    <ng-container matColumnDef="date_modified">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_modified" matTooltip="Toggle sorting by the date the form was last updated on.">
        Last Updated On
      </th>
      <td mat-cell *matCellDef="let form">
        {{ (form.date_modified * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef id="th-actions"></th>
      <td mat-cell *matCellDef="let form">
        <button type="button" mat-button (click)="onSelectForm(form, $event)" matTooltip="Select this form." matTooltipPosition="above" *ngIf="!dataSource.multiple">
          Select Form
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="dataSource.selection.toggle(row.id)"></tr>
  </table>

  <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end" *ngIf="dataSource.multiple">
  <button mat-raised-button color="primary" type="button" (click)="onSelectForms()" mat-dialog-close [disabled]="!dataSource.selection.hasValue()">Select Forms</button>
</mat-dialog-actions>
