import { Component, OnInit } from '@angular/core';
import { InductionModel } from '../induction.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { NgForm } from '@angular/forms';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-inductions-edit',
  templateUrl: './inductions-edit.component.html',
  styleUrls: ['./inductions-edit.component.scss']
})
export class InductionsEditComponent implements OnInit {

  induction: InductionModel = new InductionModel();

  inProgress: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private api: ApiRequestService,
    public utils: UtilsService,
    private router: Router
  ) { }

  ngOnInit() {

    this.induction.id = Number(this.route.snapshot.params['induction_id']);

    if ( this.induction.id ) {
      this.api.makeRequest(`get`, `v2/inductions/${this.induction.id}`)
      .then((response: InductionModel) => {
        this.induction = response;
      });
    }

  }

  onSubmit(form: NgForm) {
    if ( !form.valid ) {
      this.utils.showModal('Form Validation Error', 'Please enter all required fields.');
      return;
    }

    if ( this.induction.id ) {
      this.api.makeRequest(`put`, `v2/inductions/${this.induction.id}`, this.induction)
      .then((response: InductionModel) => {
        this.utils.showToast('Induction Updated.');
      });
    } else {
      this.api.makeRequest(`post`, `v2/inductions`, this.induction)
      .then((response: InductionModel) => {
        this.induction = response;
        this.utils.showToast('New induction added to account.');
        this.router.navigate(['/inductions', response.id, 'edit']);
      });;
    }

  }

}
