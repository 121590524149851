import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appColorPercent]'
})
export class ColorPercentDirective implements OnInit {
  @Input('appColorPercent') percent: number;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    if (this.percent >= 2 / 3) { this.element.nativeElement.style.color = 'green'; }
    else if (this.percent >= 1 / 3 && this.percent < 2 / 3) {
      this.element.nativeElement.style.color = 'orange';
         }
    else {
      this.element.nativeElement.style.color = 'red';
    }
  }
}
