export class Auditable {
    /**
     * ID of the User who created this entity.
     */
    created_by: number = 0;

    /**
     * Unix timestamp of when this entity was created.
     * 
     * Use date_created instead.
     * 
     * @deprecated
     */
    created_at: number = 0;

    /**
     * ID of the User who last updated this entity.
     */
    updated_by?: number = 0;

    /**
     * Unix timestamp of when this entity was lats updated.
     * 
     * Use date_modified instead.
     * 
     * @deprecated
     */
    updated_at?: number = 0;

    /**
     * ID of the User who last updated this entity.
     */
    deleted_by?: number = 0;

    /**
     * Unix timestamp of when this entity was deleted.
     */
    deleted_at?: number = 0;

    /**
     * Unix timestamp of when this entity was last modified.
     */
    date_modified?: number = 0;

    /**
     * Unix timestamp of when this entity was created.
     */
    date_created?: number = 0;
}
