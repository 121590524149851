<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button mat-raised-button routerLink="/sites" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
  </button>

  <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
  </button>

  <button mat-raised-button [routerLink]="utils.prepareSiteRelatedLink(site_id, child_id ? parent_id : null, 'edit/toolbox-talks')" class="margin-right my-2">
    <mat-icon>arrow_back</mat-icon>
    {{ utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') }}
  </button>
</nav>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class="navbar-brand">
    Toolbox Talk
  </span>
  <span class="mr-auto">

    <button mat-raised-button (click)="onEdit(toolbox_talk.id)" class="margin-right my-2">
      <mat-icon>edit</mat-icon> Edit
    </button>
    <button mat-raised-button (click)="onRemove()" class="margin-right my-2" color="warn">
      <mat-icon>delete</mat-icon> Delete
    </button>

    <button mat-raised-button (click)="onExport('pdf')" color="secondary" class="margin-right my-2">
      <mat-icon>cloud_download</mat-icon> Export PDF
    </button>

    <button mat-raised-button (click)="onExport('csv')" color="secondary" class="margin-right my-2">
      <mat-icon>cloud_download</mat-icon> Export CSV
    </button>

  </span>
</nav>

<mat-tab-group class="bg-white">
  <mat-tab label="Overview">
    <app-sites-toolbox-talks-view *ngIf="toolbox_talk.id" [site_id]="toolbox_talk.site_id" [toolbox_talk_id]="toolbox_talk.id"></app-sites-toolbox-talks-view>
  </mat-tab>
  <mat-tab *ngIf="toolbox_talk.id" label="Corrective Actions">
    <app-toolbox-talks-corrective-actions [toolboxTalksId]="toolbox_talk.id" [toolboxTalksSiteId]="toolbox_talk.site_id" [toolboxTalksUserIds]="toolbox_talk.user_ids"></app-toolbox-talks-corrective-actions>
  </mat-tab>
</mat-tab-group>

