<div mat-dialog-title>
  <h5>View Item</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <p id="name">{{ pivot.item?.name }}</p>

  <p>{{ pivot.item?.question }}</p>
  <p>{{ pivot.answer == 'NA' ? 'Not Applicable' : pivot.answer}}</p>

  <h6>Status</h6>
  <p>{{ pivot.status }}</p>

  <h6 *ngIf="pivot.rating">Rating</h6>
  <app-rating [rating]="pivot.rating"></app-rating>

  <ng-container *ngIf="pivot.expiry">
    <h6>Expiry</h6>
    <p>{{ (pivot.expiry * 1000) | timezoneConvertShort }}</p>
  </ng-container>

  <h6 *ngIf="pivot.notes">Notes</h6>
  <p id="note">{{ pivot.notes }}</p>

  <h6 *ngIf="pivot.files?.length">Files</h6>
  <app-files-list [files]="pivot.files" [view]="true"></app-files-list>
</mat-dialog-content>
