import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { HazardousSubstancesSelectorComponent } from 'src/app/hazardous-substances/hazardous-substances-selector/hazardous-substances-selector.component';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { HazardousSubstancesEditDialogComponent } from 'src/app/hazardous-substances/hazardous-substances-edit-dialog/hazardous-substances-edit-dialog.component';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import {HazardousSubstanceModel} from "../../models/hazardous-substance.model";
import {SiteModel} from "../../models/site.model";
import {
  HazardousSubstancesFilterComponent
} from "../../hazardous-substances/hazardous-substances-filter/hazardous-substances-filter.component";
import {
  SitesHazardousSubstancesLinkDialogComponent
} from "../sites-hazardous-substances-link-dialog/sites-hazardous-substances-link-dialog.component";

@Component({
  selector: 'app-sites-hazardous-substances',
  templateUrl: './sites-hazardous-substances.component.html',
  styleUrls: ['./sites-hazardous-substances.component.scss']
})
export class SitesHazardousSubstancesComponent implements OnInit, AfterViewInit {

  parent_id: number;
  child_id: number;
  site_id: number;

  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'substance',
    'hazardous_substance_type',
    'hazardous_substance_unit_type',
    'quantity',
    'max_quantity_allowed',
    // 'sites',
    'location',
    'sds_expires_at',
    // 'date_created',
    'actions'
  ];
  dataSource: SiteHazardousSubstanceDataSource = new SiteHazardousSubstanceDataSource(this.app, this.oldApi, true);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private oldApi: ApiService,
    public route: ActivatedRoute,
    private api: ApiRequestService
  ) {}

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;
      // init the data source
      this.dataSource.site_id = this.site_id;
      this.dataSource.site_ids.push(this.site_id);
      // get the data
      this.dataSource.getData(true);
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC field by its corresponding date field.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * This is used when new hazardous substances are created and associated with the current site.
   * Todo: hazard_id seems a bit redundant here. Refactor it out of the code.
   * @param hazard_id
   */
  onEdit(hazard_id: number | string) {
    // open the hazard edit dialog for editing
    this.utils.showComponentDialog(
      HazardousSubstancesEditDialogComponent,
      {
        site_id: this.site_id,
        hazard_id: hazard_id
      },
      {},
      (response) => {
        // response is not used
        this.dataSource.getData(true);
      }
    );
  }

  /**
   * This unlinks a single hazardous substance from a site.
   * @param id
   */
  onUnlinkHazardousSubstance(id: number) {
    this.utils.showModal('Unlink Hazardous Substance', 'Are you sure you want to unlink this hazardous substance?', () => {
      this.api.makeRequest('delete', `v1/sites/${this.site_id}/hazardous-substances/unlink`, {}, {
        hazardous_substance_ids: id
      })
        .then((response) => {
          // Deselect the record if it was previously selected.
          this.dataSource.selection.deselect(id);
          // Refresh the list.
          this.dataSource.getData();
        });
    });
  }

  /**
   * This unlinks the selected hazardous substances from the site.
   */
  onUnlinkSelectedHazardousSubstances() {
    this.utils.showModal('Unlink Selected Hazardous Substances', 'Are you sure you want to unlink the selected hazardous substances?', () => {
      this.api.makeRequest('delete', `v1/sites/${this.site_id}/hazardous-substances/unlink`, {}, {
        hazardous_substance_ids: this.dataSource.selection.selected.join(',')
      })
        .then((response) => {
          // Clear the list selection.
          this.dataSource.selection.clear();
          // Refresh the list.
          this.dataSource.getData();
        });
    });
  }

  /**
   * This unlinks all hazardous substances from the site.
   */
  onUnlinkAllHazardousSubstances() {
    this.utils.showModal('Unlink All Hazardous Substances', 'Are you sure you want to unlink all hazardous substances?', () => {
      this.api.makeRequest('delete', `v1/sites/${this.site_id}/hazardous-substances/unlink-all`)
        .then((response) => {
          // Clear the list selection.
          this.dataSource.selection.clear();
          // Refresh the list.
          this.dataSource.getData(true);
        });
    });
  }

  /**
   * This opens a dialog allowing the user to select hazardous substances to be linked to the site.
   */
  onSelectHazardousSubstance() {
    // Show the hazardous substances' selector.
    this.utils.showComponentDialog(HazardousSubstancesSelectorComponent, {
        multiple: true,
        selected: this.dataSource.hazardous_substance_ids
      },
      {},
      (results) => {
        if (typeof results !== 'undefined') {
          // Store the selected ids.
          this.dataSource.hazardous_substance_ids = results;
          // Make a request to update the list of hazardous substances.
          this.api.makeRequest('put', `v1/sites/${this.site_id}/hazardous-substances/link`, {
            hazardous_substance_ids: this.dataSource.hazardous_substance_ids
          })
            .then((response) => {
              // Refresh the list.
              this.dataSource.getData(true);
            });
        }
      }
    );
  }

  /**
   * Links all the hazardous substances from the account the site belongs to.
   */
  onLinkAllHazardousSubstances() {
    this.api.makeRequest('put', `v1/sites/${this.site_id}/hazardous-substances/link-all`)
      .then((response) => {
        // Refresh the list.
        this.dataSource.getData(true);
      });
  }

  /**
   * Send a request to the API to download the list of hazardous substances.
   * @param type Defaulted to CSV.
   */
  onExportSelected(type: string = 'csv') {
    // Make a download request.
    this.api.makeDownloadRequest(`v1/sites/${this.site_id}/hazardous-substances`, {}, { //  + (this.dataSource.selection.selected.length ? '/' + this.dataSource.selection.selected.join(',') : '')
      export: type,
      hazardous_substance_type: this.dataSource.hazardous_substance_type,
      selected_ids: this.dataSource.selection.selected.join(',')
    })
    .then((response) => {
      // IE & Edge should save or open the blob immediately.
      if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
        window.navigator.msSaveOrOpenBlob(response);
        return;
      }

      // Get the current date object.
      const date = new Date();

      // Create object url to handle file downloads.
      const data = window.URL.createObjectURL(response);

      // Create a download link.
      const downloadLink = document.createElement('a');
      downloadLink.href = data;
      downloadLink.download = `hazardous-substances-${date.getFullYear()}${(date.getMonth()+1)}${date.getDate()}.${type}`;

      // Initiate the download.
      downloadLink.click();

      // For FireFox it is necessary to delay revoking the ObjectURL.
      setTimeout(function() {
        window.URL.revokeObjectURL(data);
      }, 300); // Minimum 300 milliseconds.
    })
    .catch((errorResponse) => {
      this.utils.showModal('Error', errorResponse.message);
    });
  }

  onCreateFrom(hazardousSubstance: HazardousSubstanceModel) {
    const newHazardousSubstance: HazardousSubstanceModel = new HazardousSubstanceModel();
    newHazardousSubstance.createFrom(hazardousSubstance);

    this.utils.showComponentDialog(
      HazardousSubstancesEditDialogComponent,
      {
        site_id: this.site_id,
        hazard_id: 0,
        create_from_data: newHazardousSubstance
      },
      {},
      (response) => {
        // response is not used
        this.dataSource.getData(true);
      }
    );
  }

  /**
   * Filter the list of hazardous substances by site and/or type.
   */
  onFilter() {
    this.utils.showComponentDialog(HazardousSubstancesFilterComponent, {
        selected_site_ids: this.dataSource.site_ids,
        parent_site_id: this.parent_id,
        hazardous_substance_type: this.dataSource.hazardous_substance_type
      }, {
        width: '768px'
      }, (results) => {
        if (typeof results !== 'undefined') {
          // Store the site ids.
          this.dataSource.site_ids = results.site_ids;
          // If no sites were selected, push the current site to filter based on the site being viewed.
          if ( this.dataSource.site_ids.length === 0 ) {
            this.dataSource.site_ids.push(this.site_id);
          }
          // Store the hazardous substance type.
          this.dataSource.hazardous_substance_type = results.hazardous_substance_type;
          // Refresh the list.
          this.dataSource.getData();
        }
      });
  }

  containsActiveSiteId(hazardous_substance: HazardousSubstanceModel) {
    return hazardous_substance.sites.map(site => site.id).indexOf(this.site_id) > -1;
  }

  /**
   * Edit the hazardous substance inventory information.
   * @param hazardous_substance
   */
  onEditInventory(hazardous_substance: HazardousSubstanceModel) {
    this.utils.showComponentDialog(SitesHazardousSubstancesLinkDialogComponent, {
      hazardous_substance: hazardous_substance,
      hazardous_substance_id: hazardous_substance.id,
      site_id: this.site_id
    }).then((response) => {
      this.dataSource.getData(false);
    });
  }
}

export class SiteHazardousSubstanceDataSource extends CustomDataSource {

  sort_by = 'substance';
  sort_order = 'asc';

  site_id: number = null;
  site_ids: number[] = [];

  hazardous_substance_type: string = '';

  hazardous_substance_ids: number[] = [];

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      'sites/' + this.site_id + '/hazardous-substances',
      resetOffset,
      (response) => {
        this.hazardous_substance_ids = response.hazardous_substance_ids;
      }, {
        site_ids: this.site_ids.join(','),
        hazardous_substance_type: this.hazardous_substance_type
      }
    );
  }
}
