<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/contractors">{{ utils.getLangTerm('contractors.plural', 'Contractors') }}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="primary" (click)="onAddContractor()" appMarginRight id="add" matTooltip="Click this button to add a new {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.">
          <mat-icon>add</mat-icon>
          Add {{ utils.getLangTerm('contractors.singular', 'Contractor') }}
        </button>

        <button mat-raised-button  class="mr-2" color="" routerLink="/contractors/importer" matTooltip="Click this button to mass import {{ utils.getLangTerm('contractors.plural', 'Contractors') }} from a CSV file.">
          <mat-icon>cloud_upload</mat-icon> Import
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="listDataSource.selectAllToggle()">
            <mat-icon *ngIf="listDataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!listDataSource.isAllSelected()">select_all</mat-icon>
            {{ listDataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onExport('csv')">
            <mat-icon>cloud_download</mat-icon> Export {{ listDataSource.selection.hasValue() ? '(' + listDataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
          </button>
          <button mat-menu-item (click)="composeNewMessage()">
            <mat-icon>send</mat-icon>
            Send Message {{ listDataSource.selection.hasValue() ? 'to (' + listDataSource.selection.selected.length + ') Selected' : '(0)' }}
          </button>
          <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!listDataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect and/or export {{ utils.getLangTerm('contractors.plural', 'Contractors') }}." style="min-width: 150px">
          <mat-icon>more_vert</mat-icon>
          List Menu/Export
        </button>

      </span>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="listDataSource.getData(true)" style="min-width: 325px">
        <input
          class="form-control mr-sm-2" type="search"
          [(ngModel)]="listDataSource.search" name="search" placeholder="Search"
          aria-label="Search" id="search-input"
          matTooltip="Search for {{ utils.getLangTerm('contractors.plural', 'Contractors') }} by company name, address or main contact." />

        <button mat-raised-button
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                color="secondary"
                (click)="listDataSource.getData(true)"
                id="search-button"
                matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="listDataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? listDataSource.selectAllToggle() : null"
            [checked]="listDataSource.selection.hasValue() && listDataSource.isAllSelected()"
            [indeterminate]="listDataSource.selection.hasValue() && !listDataSource.isAllSelected()"
            matTooltip="Toggle select all contactors in this list.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? listDataSource.selection.toggle(row.id) : null"
            [checked]="listDataSource.selection.isSelected(row.id)"
            matTooltip="Toggle select '{{row.name}}' for removal.">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="icons">
        <th mat-header-cell *matHeaderCellDef>
          Status Icons
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon matTooltip="" *ngIf="element.courses_count > 0"
            [matTooltip]="element.courses_count_completed + '/' + element.courses_count + ' Inductions Completed.'"
            [ngStyle]="{'color': (element.courses_count_completed > 0 ? (element.courses_count_completed < element.courses_count ? 'orange' : 'green') : 'red')}">
            {{ element.courses_count_completed > 0 ? 'done_outline' : 'clear' }}
          </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by company name.">
          Company Name
        </th>
        <td mat-cell *matCellDef="let contractor">
          {{ contractor.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contact_person">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by main contact.">
          Main Contact
        </th>
        <td mat-cell *matCellDef="let contractor">
          <p>
            <a (click)="onUserPublicView(contractor.hash)"
               matTooltip="View User Public Profile">
              {{ contractor.contact_person }}
            </a> <br>
            {{ contractor.email }}<br>
            {{ contractor.mobile }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="industry">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by industry.">
          Industry
        </th>
        <td mat-cell *matCellDef="let contractor">{{ contractor.industry }}</td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by enrollment date.">
          Date Enrolled
        </th>
        <td mat-cell *matCellDef="let contractor">{{ (contractor.date_created * 1000) | timezoneConvertMedium }}</td>
      </ng-container>

      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by enrollment date.">
          Date Enrolled (UTC)
        </th>
        <td mat-cell *matCellDef="let contractor">{{ (contractor.date_created * 1000) | timezoneConvertUTCMedium }}</td>
      </ng-container>

      <ng-container matColumnDef="pre_qualification_score">
        <th mat-header-cell *matHeaderCellDef>
          H&S Score
        </th>
        <td mat-cell *matCellDef="let contractor">
          <ng-container *ngIf="contractor.pre_qualification_exists">
            <span appPrequalScoreColor [scorePercentage]="contractor.pre_qualification_score">
              {{ contractor.pre_qualification_score }}%<br>
            </span>
            <span *ngIf="contractor.pre_qualification_pending_items" matTooltip="'{{contractor.name}}'' have pre-qualification items that are pending review." class="blink">
                Pending Review
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let contractor">
          <a type="button" mat-button routerLink="/contractors/{{ contractor.id }}/details" matTooltip="Click this button to edit '{{contractor.name}}' information."  *ngIf="app.userAccountLink.permissions != 'Employee'">
            Edit
          </a>

          <button type="button" mat-button (click)="onRemove(contractor.id)" matTooltip="Click this button to remove '{{contractor.name}}''."  *ngIf="app.userAccountLink.permissions != 'Employee'">
            Delete
          </button>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
