import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuditsEditComponent } from './audits-edit/audits-edit.component';
import { AuditsTemplatesEditComponent } from './audits-templates-edit/audits-templates-edit.component';
import { AuditsTemplatesComponent } from './audits-templates/audits-templates.component';
import { AuditsViewComponent } from './audits-view/audits-view.component';
import { AuditsComponent } from './audits.component';
import { AuditsTemplatesViewComponent } from './audits-templates-view/audits-templates-view.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: 'audits',
    canActivate: [
      AuthGuard
    ],
    children: [
      { path: '', component: AuditsComponent },
      { path: ':id/edit', component: AuditsEditComponent },
      { path: ':id/view', component: AuditsViewComponent }
    ]
  },
  {
    path: 'audits-templates',
    canActivate: [
      AuthGuard
    ],
    children: [
      { path: '', component: AuditsTemplatesComponent },
      { path: ':id/edit', component: AuditsTemplatesEditComponent },
      { path: ':id/view', component: AuditsTemplatesViewComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuditsRoutingModule {}
