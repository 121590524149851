<mat-card>
  <mat-card-content>

    <div class="alert alert-warning" role="alert" *ngIf="user.notification_sites_count > 0; else noSites">
      This {{ utils.getLangTerm('employees.singular', 'Employee').toLowerCase() }} is currently associated with {{ user.notification_sites_count }} {{ utils.getLangTerm('parent-child-sites-combined.' + (user.notification_sites_count == 1 ? 'singular' : 'plural'), 'site' + (user.notification_sites_count == 1 ? '' : 's')).toLowerCase() }} and will only receive notifications for those {{ utils.getLangTerm('parent-child-sites-combined.plural', 'sites').toLowerCase() }}. To enable them to receive notifications not tied to specific {{ utils.getLangTerm('parent-child-sites-combined.plural', 'sites').toLowerCase() }}, please assign them a notification role without {{ utils.getLangTerm('parent-child-sites-combined.singular', 'site').toLowerCase() }} associations.
    </div>

    <ng-template #noSites>
      <div class="alert alert-warning" role="alert">
        This {{ utils.getLangTerm('employees.singular', 'Employee').toLowerCase() }} will receive account notifications and notifications for all {{ utils.getLangTerm('parent-child-sites-combined.plural', 'sites').toLowerCase() }} for any enabled alerts. For example, if you enable new incident notifications without associating specific {{ utils.getLangTerm('parent-child-sites-combined.plural', 'sites').toLowerCase() }}, the {{ utils.getLangTerm('employees.singular', 'Employee').toLowerCase() }} will receive incident notifications for all {{ utils.getLangTerm('parent-child-sites-combined.plural', 'sites').toLowerCase() }}. To restrict the {{ utils.getLangTerm('employees.singular', 'Employee').toLowerCase() }} to {{ utils.getLangTerm('parent-child-sites-combined.singular', 'site').toLowerCase() }}-specific notifications only, please associate the desired {{ utils.getLangTerm('parent-child-sites-combined.plural', 'sites').toLowerCase() }} or assign them a notification role that includes specific {{ utils.getLangTerm('parent-child-sites-combined.plural', 'sites').toLowerCase() }}.
      </div>
    </ng-template>

    <form #f="ngForm" (ngSubmit)="onSubmit(f)">

      <div class="columns">
        <ng-container *ngFor="let feature of grouped_system_notifications | keyvalue">
          <div class="mb-3" *ngIf="user.notification_sites_count == 0 || (user.notification_sites_count > 0 && feature.value.has_site_related_notifications)">
            <mat-card>
              <mat-card-subtitle><b>{{ feature.key }}</b></mat-card-subtitle>
              <mat-card-content>
                <div *ngFor="let system_notification of feature.value.system_notifications" class="mb-3">
                  <ng-container *ngIf="user.notification_sites_count == 0 || (user.notification_sites_count > 0 && system_notification.is_site_related)">
                    <mat-checkbox [(ngModel)]="system_notification.checked" [name]="'system_notification_' + system_notification.id">
                      {{ system_notification.label }}
                    </mat-checkbox>
                    <mat-hint>{{ system_notification.description }}</mat-hint>
                  </ng-container>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>
      </div>

      <div class="d-flex justify-content-end">
        <button mat-button mat-raised-button [disabled]="!f.valid" type="submit" class="mr-1" color="primary">
          <mat-icon>save</mat-icon> Update
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
