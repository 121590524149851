import { StorageService } from './storage.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrentTimezoneStateService {
  // Get the local timezone from the browser.
  localTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Set the default timezone key for the app.
  timezoneKey: string = "appCurrentTimezoneKey";

  private readonly _currentTimezoneSource;

  // readonly currentTimezone$ = this._currentTimezoneSource.asObservable();

  constructor(
    private storageService: StorageService
  ) {
    // Get the stored timezone from storage.
    const storedTimezone = this.storageService.get(this.timezoneKey);
    // Initialise the timezone source.
    this._currentTimezoneSource = new BehaviorSubject<string>(storedTimezone ? storedTimezone : this.localTimezone)
  }

  /**
   * Get the current timezone.
   */
  getCurrentTimezone(): string {
    return this._currentTimezoneSource.getValue();
  }

  /**
   * Store the timezone.
   * @param timezone
   */
  setCurrentTimezone(timezone: string): void {
    this._currentTimezoneSource.next(timezone);
  }
}
