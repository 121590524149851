import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IncidentModel } from 'src/app/models/incident.model';
import { UserModel } from 'src/app/models/user.model';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { UtilsService } from 'src/app/shared/utils.service';
import { IncidentService } from '../incident.service';

@Component({
  selector: 'app-incidents-details',
  templateUrl: './incidents-details.component.html',
  styleUrls: ['./incidents-details.component.scss']
})
export class IncidentsDetailsComponent {

  @Input() incident: IncidentModel;

  inProgress: boolean = false;

  assignee: UserModel = {} as UserModel;
  reviewer: UserModel = {} as UserModel;

  private incidentId: number;

  constructor(
    public utils: UtilsService,
    private incidentService: IncidentService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.incidentService.findAssignee(this.incident.id).subscribe((response) => {
      this.assignee = response.data;
    });

    this.incidentService.getReviewer(this.incident.id).subscribe(
      response => this.reviewer = response.data
    );
  }

  onSave() {

    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;

    this.incidentService.update(this.incident).subscribe(() => {
      this.utils.showToast('Updated Details');
      this.inProgress = false;
    }, () => {
      this.inProgress = false;
    });
  }

  onReviewedInvestigation() {
    this.incidentService.reviewedInvestigation(this.incident.id).subscribe(() => {
      this.utils.showToast('The investigation is now completed.');
      this.incident.status = 'Completed';
      this.router.navigate(['/incidents']);
    });
  }

  onSubmitInvestigation() {
    this.incidentService.submitInvestigation(this.incident.id).subscribe(() => {
      this.utils.showToast('The investigation is now submitted.');
      this.incident.status = 'Submitted';
      this.router.navigate(['/incidents']);
    });
  }

  onStartInvestigation() {
    this.incident.status = 'In Progress';
    this.incidentService.update(this.incident).subscribe(() => {
      this.utils.showToast('Started investigation');
    });
  }

  onSelectInvestigator() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: false,
        selected: [this.assignee.id],
        visitor_site_id: this.incident.site_id
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.incidentService
        .setAssignee(this.incident.id, userId)
        .subscribe((response) => {
          this.utils.showToast('The investigator was changed successfully.');
          this.assignee = response.data;
        });
      }
    );
  }

  onSelectReviewer() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: false,
        selected: [this.reviewer.id],
        visitor_site_id: this.incident.site_id
      },
      {},
      (userId: number) => {

        if ( typeof userId === 'undefined' ) {
          return;
        }

        this.incidentService
        .updateReviewer(this.incident.id, userId)
        .subscribe((response) => {

          this.utils.showToast('The reviewer was successfully changed.');
          this.reviewer = response.data;

        });
      }
    );
  }

  haveInvalidRisks() {
    return false;
  }

  canSubmitInvestigation() {
    return (
      this.incident.status == 'In Progress' &&
      this.incident.causes.length &&
      !this.haveInvalidRisks()
    );
  }
}
