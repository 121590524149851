<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/messages">Messages</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="primary" (click)="onComposeMessage()" appMarginRight id="add" matTooltip="Click this button to compose a new message.">
          <mat-icon>add</mat-icon>
          Compose Message
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="listDataSource.selectAllToggle()">
            <mat-icon *ngIf="listDataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!listDataSource.isAllSelected()">select_all</mat-icon>
            {{ listDataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!listDataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Messages." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>
      </span>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="chartsUtils.onOpenMasterCharts('Messages')" matTooltip="Click this button to open the charts.">
        <mat-icon>bar_chart</mat-icon> Charts
      </button>

      <button mat-raised-button class="mr-0 mr-lg-4 my-1 float-right float-lg-none" (click)="onFilter()" matTooltip="Click this button to display filter options.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="listDataSource.getData(true)" style="min-width: 325px">
        <input
          class="form-control mr-sm-2" type="search"
          [(ngModel)]="listDataSource.search" name="search" placeholder="Search"
          aria-label="Search" id="search-input"
          matTooltip="Search for messages by subject or body content." />
        <button mat-raised-button color="secondary"
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                (click)="listDataSource.getData(true)" id="search-button"
                matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

    </div>
  </nav>

  <app-messages-charts-container [baseStorageKey]="'messages'"></app-messages-charts-container>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="listDataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? listDataSource.selectAllToggle() : null"
            [checked]="listDataSource.selection.hasValue() && listDataSource.isAllSelected()"
            [indeterminate]="listDataSource.selection.hasValue() && !listDataSource.isAllSelected()"
            matTooltip="Toggle select all messages in this list.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? listDataSource.selection.toggle(row.id) : null"
            [checked]="listDataSource.selection.isSelected(row.id)"
            matTooltip="Toggle select this message.">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the ID of the message.">
          #
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the subject of the message.">
          Subject
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.subject }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the type of message.">
          Type
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.type | titlecase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the status of the message.">
          Status
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.status | titlecase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the priority of the message.">
          Priority
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.priority | titlecase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="reply_to">
        <th mat-header-cell *matHeaderCellDef>
          Sender
        </th>
        <td mat-cell *matCellDef="let message">
          <ng-container *ngIf="message.reply_to.id">
            <a (click)="onUserPublicView(message.reply_to.hash)"
               matTooltip="View User Public Profile">
              <b>{{ message.reply_to.contact_person }}</b>
            </a> <br>
            {{ message.reply_to.employer.name }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="sent_to">
        <th mat-header-cell *matHeaderCellDef>
          Sent To
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.users.length > 0 ? (message.users[0].contact_person + (message.users_count > 1 ? (' & ' + (message.users_count-1) + ' Other' + ((message.users_count-1) > 1 ? 's' : '') + '.') : '')) : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the message was last modified at.">
          Last Modified At
        </th>
        <td mat-cell *matCellDef="let message">
          {{ (message.date_modified * 1000) | timezoneConvertLong }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_modified_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the message was last modified at.">
          Last Modified At (UTC)
        </th>
        <td mat-cell *matCellDef="let message">
          {{ (message.date_modified * 1000) | timezoneConvertUTCLong }}
        </td>
      </ng-container>

      <ng-container matColumnDef="send_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the message is scheduled for.">
          Scheduled For
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.send_at ? ((message.send_at * 1000) | timezoneConvertLong) : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="send_at_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the message is scheduled for.">
          Scheduled For (UTC)
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.send_at ? ((message.send_at * 1000) | timezoneConvertUTCLong) : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let message">
          <button type="button" mat-button (click)="onComposeMessage(message.id)" matTooltip="Click this button to edit this message."  *ngIf="app.userAccountLink.permissions != 'Employee' && canEditMessage(message)">
            Edit
          </button>

          <button type="button" mat-button *ngIf="message.status == 'sent' && app.userAccountLink.permissions != 'Employee'" (click)="onViewMessage(message.id)" matTooltip="Click this button to view this message.">
            View
          </button>

          <button type="button" mat-button *ngIf="app.userAccountLink.permissions != 'Employee'" (click)="onCreateFrom(message)" matTooltip="Create message from this message information.">
            Clone
          </button>

          <button
            mat-button
            *ngIf="message.status == 'queued'"
            type="button"
            (click)="onDequeueMessage(message, $event)">
            Dequeue
          </button>

          <button type="button" mat-button (click)="onRemove(message.id, $event)" matTooltip="Click this button to remove this message. You can only remove drafts."  *ngIf="app.userAccountLink.permissions != 'Employee' && message.status == 'draft'">
            Delete
          </button>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
