import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FileService } from 'src/app/shared/file.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { FileModel } from 'src/app/shared/file.model';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss']
})
export class FilesListComponent {
  @Input() files: FileModel[] | FileList;
  @Input() view = false;

  @Output() changedFiles = new EventEmitter<FileModel[] | FileList>();

  constructor(private fileService: FileService, private utils: UtilsService) {}

  delete(id: number) {
    this.utils.showModal('Delete Files', 'Are you sure you want to delete this file?', () => {
      this.fileService.remove(id).subscribe(() => {
        this.utils.showToast('The file was deleted.');
        this.files = Array.from(this.files).filter((file) => file.id !== id);
        this.changedFiles.emit(this.files);
      });
    });
  }

  isImage(name: string) {
    return name && name.match(/.(jpg|jpeg|png|gif|bmp)/i);
  }

  onOpenUrl(url: string) {
    this.utils.openUrl(url);
  }

  onCopiedURL() {
    this.utils.showToast('The URL is copied to your clipboard.');
  }
}
