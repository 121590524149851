import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { UtilsService } from 'src/app/shared/utils.service';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {ApiDataSource} from "../../utils/api-data-source";
import {ApiRequestService} from "../../shared/api-request.service";

@Component({
  selector: 'app-contractors-selector',
  templateUrl: './contractors-selector.component.html',
  styleUrls: ['./contractors-selector.component.scss']
})
export class ContractorsSelectorComponent implements OnInit, AfterViewInit {
  // Columns to show and the data source.
  displayedColumns: string[] = [
    'select',
    'contact_person',
    'entity_name',
    'email',
    'mobile'
  ];
  dataSource: ContractorsSelectorDataSource;

  // The paginator and sorter.
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public dialogRef: MatDialogRef<ContractorsSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utils: UtilsService
  ) {
    // Set multiple to false if it is not present.
    if (typeof this.data['multiple'] == 'undefined') {
      this.data['multiple'] = false;
    }

    // Construct the datasource.
    this.dataSource = new ContractorsSelectorDataSource(this.app, this.api, this.data['multiple']);

    // Set a default selection of nothing if no selected values were passed through.
    if (typeof this.data['selected'] == 'undefined') {
      this.data['selected'] = [];
    }

    // Loop and select the values.
    for (let i = 0; i < this.data['selected'].length; i++) {
      this.dataSource.selection.select(this.data['selected'][i]);
    }
  }

  ngOnInit() {
    // Make a request to get the data from the API.
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    // Define the tap event.
    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData();
    });
    // Subscribe to the paginator and sorting tap events.
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * before the dialog close we want to store the new active site id
   */
  onSaveAndClose() {
    this.dialogRef.close(this.dataSource.selection.selected);
  }

  /**
   * Open the user's public profile dialog.
   * @param hash
   * @param event
   */
  onUserPublicView(hash: string, event: Event) {
    // Prevent the default events and propagation from triggering.
    event.preventDefault();
    event.stopPropagation();
    // Show the user's public profile.
    this.utils.showComponentDialog(UserPublicProfileComponent, hash, {
      width: '90%'
    }, () => {
        // Do nothing...
    });
  }
}

/**
 * This datasource gets contractors from the v2 endpoint.
 */
export class ContractorsSelectorDataSource extends ApiDataSource {
  // Order and Order By variables with defaults.
  order_by = 'entity_name';
  order = 'asc';
  // Make a request to the API to get the data.
  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/contractors', resetOffset);
  }
}
