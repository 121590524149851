import { FileModel } from '../shared/file.model';
import { Auditable } from '../shared/auditable.model';
import { SiteModel } from './site.model';

export class TaskAnalysisModel extends Auditable {
  id = 0;
  contractor = '';
  company = '';
  phone = '';
  address = '';
  task_description = '';
  ppe_required = '';
  administrative_controls = '';
  task_name = '';

  sssp_ids: number[] = [];
  link_to_all_sssps = false;
  files: FileModel[] = [];
  site_ids: number[] = [];
  sites: SiteModel[] = [];

  sites_count: number = 0;

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.contractor = '';
    this.company = '';
    this.phone = '';
    this.address = '';
    this.task_description = '';
    this.ppe_required = '';
    this.administrative_controls = '';
    this.task_name = '';

    this.sssp_ids.length = 0;
    this.link_to_all_sssps = false;
    this.files = [];
  }
}
