<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class=" mr-auto">
    <button mat-raised-button routerLink="/erps" appMarginRight>
      <mat-icon>arrow_back</mat-icon> Emergency Response Plan Templates
    </button>

    <button
      mat-raised-button
      routerLink="/erps/{{ erp.id }}/view"
      *ngIf="erp.id"
      appMarginRight>
      View
    </button>

    <button
      mat-raised-button
      (click)="onRemove()"
      *ngIf="erp.id"
      appMarginRight>
      Delete
    </button>
  </span>
</nav>

<div class="container-fluid" appPadding>
  <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
    <div class="card">
      <div class="card-body form-container">
        <h5 class="card-title">Emergency Response Plan Form</h5>

        <div class="d-flex justify-content-end">
          <mat-checkbox
            [(ngModel)]="erp.copy_to_all_sites"
            name="copy_to_all_sites"
            appMarginRight
            appMarginTop>
            Copy to all sites or
          </mat-checkbox>

          <button
            mat-raised-button
            type="button"
            color="secondary"
            (click)="onSelectSites()"
            appMarginRight
            [matBadge]="'' + (erp.copy_to_all_sites ? 'All' : erp.selected_site_ids.length)">
            Copy to selected sites
          </button>
        </div>

        <mat-form-field>
          <input
            matInput
            placeholder="Emergency Response Plan"
            [(ngModel)]="erp.name"
            name="name"
            type="text"
            #name
            required/>
          <mat-error *ngIf="!name.valid">
            Please enter the emergency response plan name.
          </mat-error>
        </mat-form-field>

        <button
          mat-raised-button
          [disabled]="!f.valid"
          color="primary"
          type="button"
          (click)="onSubmit(f)"
          class="save-button"
          appMarginRight>
          Save
        </button>

        <app-files-selector (selected)="newFiles = $event" [selectedFilesCount]="newFiles?.length" appMarginRight></app-files-selector>

        <button
          mat-raised-button
          (click)="onRemoveFiles()"
          color="secondary"
          [matBadge]="'' + (erp.files?.length)"
          [disabled]="!erp.files?.length"
          type="button"
          class="remove-files">
          <mat-icon>open_in_browser</mat-icon>
          Manage Files
        </button>
      </div>
    </div>
  </form>
</div>
