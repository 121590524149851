<div mat-dialog-title>
  <h5>Hierarchy of Controls</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<!-- START Hierarchy of Controls Table -->
<div class="hierarchy-container">
  <table class="hierarchy-table">
    <tbody>
      <tr>
        <td rowspan="9" class="arrow-column">
          <div class="cell-content">
            <div class="top-text">Most Effective</div>
            <div class="arrow">
              <span>&#x2193;</span> <!-- Down Arrow -->
            </div>
            <div class="bottom-text">Least Effective</div>
          </div>
        </td>
        <td colspan="3" class="blue-back" style="padding: 0;">
          <table class="w-100">
            <thead class="w-100">
              <tr><th style="border: none;">ELIMINATE</th></tr>
            </thead>
          </table>
        </td>
      </tr>
      <tr>
        <td><span class="font-weight-bold">1</span></td>
        <td><span class="font-weight-bold">Eliminate the hazard</span> - remove it completely from your workplace</td>
        <td><span class="font-italic control-info">If this isn't reasonably practicable, then...</span></td>
      </tr>
      <tr>
        <td colspan="3" class="blue-back" style="padding: 0">
          <table class="w-100">
            <thead class="w-100">
              <tr><th style="border: none;">MINIMISE</th></tr>
            </thead>
          </table>
        </td>
      </tr>
      <tr>
        <td><span class="font-weight-bold">2</span></td>
        <td><span class="font-weight-bold">Substitute the hazard</span> - replace it with a safer alternative</td>
        <td><span class="font-italic control-info">If this isn't reasonably practicable, then...</span></td>
      </tr>
      <tr>
        <td><span class="font-weight-bold">3</span></td>
        <td><span class="font-weight-bold">Isolate the hazard</span> - keep it away from workers as much as possible</td>
        <td><span class="font-italic control-info">If this isn't reasonably practicable, then...</span></td>
      </tr>
      <tr>
        <td><span class="font-weight-bold">4</span></td>
        <td><span class="font-weight-bold">Use engineering controls</span> - adapt tools or equipment to reduce the risk</td>
        <td><span class="font-italic control-info">If this isn't reasonably practicable, then...</span></td>
      </tr>
      <tr>
        <td><span class="font-weight-bold">5</span></td>
        <td><span class="font-weight-bold">Use administrative controls</span> - change work practices and organization</td>
        <td><span class="font-italic control-info">If this isn't reasonably practicable, then...</span></td>
      </tr>
      <tr>
        <td><span class="font-weight-bold">6</span></td>
        <td><span class="font-weight-bold">Use personal protective equipment (PPE)</span> - this is the last option after considering all others</td>
        <td><span class="font-italic control-info">If this isn't reasonably practicable, then...</span></td>
      </tr>
      <tr>
        <td><span class="font-weight-bold">7</span></td>
        <td><span class="font-weight-bold">Minimize the hazard</span></td>
        <td>&nbsp;</td>
      </tr>
    </tbody>
  </table>
</div>
<!-- END Hierarchy of Controls Table -->
