import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { SiteModel } from 'src/app/models/site.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import {ApiRequestService} from "../../../shared/api-request.service";

@Component({
  selector: 'app-admin-site-tabs',
  templateUrl: './admin-site-tabs.component.html',
  styleUrls: ['./admin-site-tabs.component.scss']
})
export class AdminSiteTabsComponent implements OnInit {

  // site reference
  site: SiteModel = new SiteModel();

  constructor(
    public route: ActivatedRoute,
    public app: AppService,
    private oldApi: ApiService,
    public router: Router,
    public utils: UtilsService,
    private api: ApiRequestService
  ) {}

  ngOnInit() {
    // Get the site id from the query params.
    const site_id = Number(this.route.snapshot.params['site_id']);
    if ( site_id ) {
      // Make a request to get the site.
      this.api.makeRequest('get', `v2/admin/sites/${site_id}`)
        .then((response) => {
          this.site = response.data as SiteModel;
        })
        .catch((errorResponse) => {
          this.utils.showToast('The ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() + ' could not be found.');
          this.utils.redirectPage(['/admin/sites']);
        });
    } else {
      // Redirect the user to the list of sites.
      this.utils.showToast('The ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() + ' could not be opened.');
      this.utils.redirectPage(['/admin/sites']);
    }
  }

}
