<div mat-dialog-title>
  <h5>Edit Item</h5>

  <button mat-icon-button class="float-right" (click)="onClose()">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form #form="ngForm">
    <mat-form-field>
      <input matInput placeholder="Name" [value]="pivot.item?.name" type="text" disabled />
    </mat-form-field>

    <p>{{ pivot.item?.question }}</p>
    <mat-button-toggle-group [(ngModel)]="pivot.answer" name="answer" aria-label="Answer">
      <mat-button-toggle value="Yes">Yes</mat-button-toggle>
      <mat-button-toggle value="No">No</mat-button-toggle>
      <mat-button-toggle value="NA">Not Applicable</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-form-field appMarginTop>
      <textarea matInput placeholder="Notes" [(ngModel)]="pivot.notes" name="notes" type="text" rows="5"></textarea>
    </mat-form-field>

    <p>Rating</p>
    <app-rating [rating]="pivot.rating" [edit]="true" (ratingChanged)="pivot.rating = $event">
    </app-rating>

    <mat-form-field>
      <input matInput [matDatepicker]="picker" placeholder="Expiry Date" readonly (click)="picker.open()"
        [(ngModel)]="expiry" name="date" id="date" [min]="now" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker disabled="false"></mat-datepicker>

      <button type="button" mat-button matSuffix mat-icon-button *ngIf="expiry" (click)="onRemoveExpiryDate($event)"
        matTooltip="Remove the Pre-Qualification Item Expiry Date.">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <p *ngIf="pivot.files?.length">Uploaded Files</p>
    <app-files-list (changedFiles)="pivot.files = $event" [files]="pivot.files"></app-files-list>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" id="save" type="submit" class="margin-right" (click)="onSave()"
    [disabled]="inProgress">
    <mat-icon>save</mat-icon>
    Save
  </button>
  <app-files-selector (selected)="newFiles = $event" [selectedFilesCount]="newFiles?.length"></app-files-selector>
</mat-dialog-actions>