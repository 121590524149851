import { Component, Inject } from '@angular/core';
import { AuditItemModel } from '../audit-item.model';
import { UtilsService } from 'src/app/shared/utils.service';
import { AuditItemsService } from '../audit-items.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-audit-items-template-edit',
  templateUrl: './audit-items-template-edit.component.html',
  styleUrls: ['./audit-items-template-edit.component.scss']
})
export class AuditItemsTemplateEditComponent {
  /**
   * Is the form being submitted?
   */
  inProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public auditItem: AuditItemModel,
    private util: UtilsService,
    private auditItemService: AuditItemsService,
    private utils: UtilsService,
    private dialogRef: MatDialogRef<AuditItemsTemplateEditComponent>
  ) {}

  onSubmit() {
    if (this.inProgress) {
      return;
    }

    this.inProgress = true;
    isNaN(this.auditItem.id) ? this.create() : this.update();
  }

  delete() {
    this.utils.showModal(
      'Remove Inspection/Audit Question',
      `Are you sure you want to remove the "${this.auditItem.name}" Inspection/Audit question?`,
      () => {
        this.auditItemService
          .remove([this.auditItem.id])
          .subscribe(() =>
            this.onSuccess(`The "${this.auditItem.name}" Inspection/Audit question was removed.`)
          );
      }
    );
  }

  private update() {
    this.auditItemService
      .put(this.auditItem)
      .subscribe({
        next: () => this.onSuccess(`The "${this.auditItem.name}" Inspection/Audit question was updated.`),
        error: () => this.inProgress = false,
        complete: () => this.inProgress = false
      });
  }

  private create() {
    this.auditItemService
      .post(this.auditItem)
      .subscribe({
        next: () => this.onSuccess(`The "${this.auditItem.name}" Inspection/Audit question was created.`),
        error: () => this.inProgress = false,
        complete: () => this.inProgress = false
      });
  }

  private onSuccess(message: string) {
    this.util.showToast(message);
    this.dialogRef.close(true);
  }
}
