<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-6">

      <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
        <div class="card">
          <div class="card-body form-container">
            <h5 class="card-title">{{ account.id ? 'Update' : 'Add' }} Account</h5>

            <mat-form-field class="full-width mb-3">
              <input matInput placeholder="Account Name" [(ngModel)]="account.name" name="name" type="text" #name
                required />
              <mat-hint>
                Enter the name of the organisation.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <input matInput placeholder="Address" [(ngModel)]="account.address" name="address" type="text" #address />
              <mat-hint>
                Enter the address of the organisation.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <input matInput [placeholder]="utils.getLangTerm('accounts.form.suburb', 'Suburb')" [(ngModel)]="account.suburb" name="suburb" type="text" #suburb />
              <mat-hint>
                Enter the {{ utils.getLangTerm('accounts.form.suburb', 'Suburb') }} of the organisation.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <input matInput [placeholder]="utils.getLangTerm('accounts.form.city', 'City')" [(ngModel)]="account.city" name="city" type="text" #city />
              <mat-hint>
                Enter the {{ utils.getLangTerm('accounts.form.city', 'City') }} of the organisation.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <input matInput [placeholder]="utils.getLangTerm('accounts.form.postal_code', 'Postal Code')" [(ngModel)]="account.postal_code" name="postal_code" type="text"
                #postal_code />
              <mat-hint>
                Enter the {{ utils.getLangTerm('accounts.form.postal_code', 'Postal Code') }} of the organisation.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <input matInput placeholder="Country" [(ngModel)]="account.country" name="country" type="text" #country />
              <mat-hint>
                Enter the country of the organisation.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <mat-label>Industry</mat-label>
              <mat-select [(ngModel)]="account.industry" name="industry" #industry>
                <mat-option *ngFor="let industry of industries" [value]="industry.name">{{ industry.name }}</mat-option>
              </mat-select>
            </mat-form-field>

            <app-timezone-select [timezoneInput]="account.timezone" (timezoneOutput)="getOutputTimezone($event)">
            </app-timezone-select>

            <div class="d-flex justify-content-end">
              <button
                class="mr-3 my-2"
                type="button"
                mat-raised-button
                color="secondary"
                [matBadge]="'' + (account.main_contact_id ? 1 : 0)"
                [disabled]="inProgress"
                (click)="onSelectMainContact()">
                <mat-icon>person</mat-icon>
                Main Contact
              </button>

              <button class="mr-2 my-2" type="button" mat-raised-button color="secondary" [disabled]="inProgress" (click)="onResyncAccountInductons()">
                <mat-icon>sync</mat-icon>
                Resync Account Inductions
              </button>
              <button class="mr-2 my-2" type="button" mat-raised-button color="secondary" [disabled]="inProgress" (click)="onOpenCopyTemplates()">
                <mat-icon>file_copy</mat-icon>
                Copy Templates
              </button>

              <button class="my-2" type="submit" mat-raised-button [disabled]="!f.valid || inProgress" color="primary">
                <mat-icon>save</mat-icon>
                Save
              </button>
            </div>

          </div>
        </div>
      </form>

    </div>

    <div class="col-6">

      <div class="card">
        <div class="card-body form-container">
          <h5 class="card-title">Account Subscription</h5>

          <mat-form-field>
            <mat-label>Subscription</mat-label>
            <mat-select [(ngModel)]="account.subscription" name="published">
              <mat-option *ngFor="let subscription of app.getSubscriptions()" [value]="subscription">{{ subscription }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div>
            <mat-slide-toggle [(ngModel)]="account.is_logbook" name="is_logbook">
              Enable Digital Logbook Product
            </mat-slide-toggle>
          </div>

          <div>
            <mat-slide-toggle [(ngModel)]="account.enable_sms_notifications" name="enable_after_hours_notification">
              Enable SMS Notifications
            </mat-slide-toggle>
          </div>

          <div>
            <mat-slide-toggle [(ngModel)]="account.hazard_verification" name="hazard_verification">
              Enable Hazards & Risks Verification
            </mat-slide-toggle>
          </div>

          <div>
            <mat-slide-toggle [(ngModel)]="account.include_in_search_results" name="include_in_search_results" matTooltip="By enabling this option, this account will be shown publicly when other accounts admins search for contractors to add to their accounts.">
              Include this account in search results.
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <mat-tab-group *ngIf="account.id">
        <mat-tab label="{{ utils.getLangTerm('employees.plural', 'Employees') }}">
          <nav class="navbar navbar-expand-lg navbar-light bg-light" appMarginTop>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle Navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <span class="mr-auto">
                <button type="button" mat-raised-button color="secondary" (click)="onSelectUsers()" appMarginRight>
                  Select Users
                </button>
              </span>

              <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
                <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search"
                  placeholder="Search" aria-label="Search" />
              </form>

              <button type="button" mat-raised-button color="secondary" (click)="dataSource.getData(true)">
                <mat-icon>filter_list</mat-icon> Search
              </button>
            </div>
          </nav>

          <div class="scroll-box">

            <table mat-table [dataSource]="dataSource" matSort matSortActive="contact_person" matSortDirection="asc"
              matSortDisableClear>

              <ng-container matColumnDef="user_id">
                <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by ID">
                  #
                </th>
                <td mat-cell *matCellDef="let user">
                  {{ user.user_id }}
                </td>
              </ng-container>

              <ng-container matColumnDef="icons">
                <th mat-header-cell *matHeaderCellDef>Status Icons</th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon
                    matTooltip="Connected to the {{ utils.getAppName() }} mobile app{{ element.version ? (', using version ' + element.version) : ''}}."
                    *ngIf="element.device_id">
                    phonelink_setup
                  </mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="contact_person">
                <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by full name">
                  Full Name
                </th>
                <td mat-cell *matCellDef="let user">
                  <a (click)="onRedirectToUser(user.user_id)">{{ user.contact_person }}</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by Email Address">
                  Email Address
                </th>
                <td mat-cell *matCellDef="let user">
                  {{ user.email }}
                </td>
              </ng-container>

              <ng-container matColumnDef="mobile">
                <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by {{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}">
                  {{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}
                </th>
                <td mat-cell *matCellDef="let user">
                  {{ user.mobile }}
                </td>
              </ng-container>

              <ng-container matColumnDef="date_created">
                <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by Date Joined">
                  Date Joined
                </th>
                <td mat-cell *matCellDef="let user">
                  {{ (user.date_created * 1000) | timezoneConvertShort }} <br>
                  {{ (user.date_created * 1000) | timezoneConvertUTCShort }}
                </td>
              </ng-container>

              <ng-container matColumnDef="permissions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
                <td mat-cell *matCellDef="let user">
                  <mat-form-field>
                    <mat-select (selectionChange)="onUpdateUserPermissions(user)" [(ngModel)]="user.permissions"
                      [ngModelOptions]="{ standalone: true }">
                      <mat-option *ngFor="let role of utils.getUserRoles()" [value]="role">{{ role }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let user">

                  <button type="button" mat-button (click)="onDestroyUser(user, $event)">
                    Delete
                  </button>

                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="usersDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: usersDisplayedColumns"></tr>
            </table>

            <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit"
              [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons>
            </mat-paginator>
          </div>
        </mat-tab>

        <mat-tab label="{{ utils.getLangTerm('contractors.singular', 'Contractor') }} Of">
          <div class="scroll-box">

            <table mat-table [dataSource]="account.contractor_of" #contractorOf>

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>
                  #
                </th>
                <td mat-cell *matCellDef="let client">
                  {{ client.id }}
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                  Name
                </th>
                <td mat-cell *matCellDef="let client">
                  <a (click)="onRedirectToAccount(client.id)">{{ client.name }}</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="subscription">
                <th mat-header-cell *matHeaderCellDef>
                  Subscription
                </th>
                <td mat-cell *matCellDef="let client">
                  {{ client.subscription }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="clientColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: clientColumns"></tr>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>


    </div>
  </div>
</div>
