import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { SsspDocComponent } from './sssp-doc.component';

@Component({
  selector: 'app-sssp-doc-dialog',
  templateUrl: './sssp-doc.component.html',
  styleUrls: ['./sssp-doc.component.scss']
})
export class SsspDocDialogComponent extends SsspDocComponent implements OnInit {
  selectedTimezone: string;

  constructor(
    public app: AppService,
    public api: ApiRequestService,
    public utils: UtilsService,
    private dialogRef: MatDialogRef<SsspDocDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) {
    super(app, api, utils);
  }

  /**
   * When this Safety Plan doc dialog opens, it is to add a new document.
   */
  ngOnInit() {
    // Get the Safety Plan hash if it is present.
    if ( !this.sssp_hash && this.dialogData['sssp_hash'] ) {
      this.sssp_hash = this.dialogData['sssp_hash'];
    }

    // Get the Safety Plan if it is present.
    if ( !this.sssp.id && this.dialogData['sssp'] ) {
      this.sssp.apply(this.dialogData['sssp']);
      this.selectedTimezone = this.sssp.site.timezone;
    }
  }

  getOutputTimezone($event) {
    this.selectedTimezone = $event;
  }

  // Save the form from the super class.
  onSave(form: NgForm): Promise<any> {
    return super.onSave(form)
    .then(() => {
      // Close this dialog if the create or update was successful.
      if ( this.sssp_doc.id ) {
        this.dialogRef.close();
      }
    });
  }

}
