import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import CryptoJS from 'crypto-js';
import {IndexedDB} from "ng-indexed-db";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  // Create a reference to local browser storage.
  storage: Storage = localStorage;

  constructor(
    private db: IndexedDB
  ) {}

  /**
   * Sets a value in the storage using the given key.
   *
   * @param {string} key - The key under which the value is stored.
   * @param {any} value - The value to be stored.
   *
   * @return {void}
   */
  set(key: string, value: any): void {
    // Store the value in an encrypted format.
    const encryptedValue: string = CryptoJS.AES.encrypt(JSON.stringify(value), environment.AES_STORAGE_KEY);
    this.storage.setItem(key, encryptedValue.toString());
  }

  /**
   * Retrieves the decrypted value associated with the given key from the storage.
   *
   * @param {string} key - The key associated with the value to be retrieved.
   * @returns {any} The decrypted value associated with the given key, or null if no value is found or an error occurred during decryption.
   */
  get(key: string): any {
    const encryptedValue: string = this.storage.getItem(key);
    if ( !encryptedValue ) {
      return null;
    }
    // Try to decrypt the stored value. Attempt to delete the key on failure and return null.
    try {
      const decryptedValue: string = CryptoJS.AES.decrypt(encryptedValue, environment.AES_STORAGE_KEY).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedValue);
    } catch (error) {
      this.delete(key);
      return null;
    }
  }

  /**
   * Deletes an item from storage based on the provided key.
   *
   * @param {string} key - The key of the item to be deleted from storage.
   * @return {void}
   */
  delete(key: string): void {
    this.storage.removeItem(key);
  }

  /**
   * Clear the storage.
   * @returns {void}
   */
  clear(): void {
    this.storage.clear();
  }

  /**
   * Sets a new record in the specified store of the database.
   *
   * @param {string} storeName - The name of the store where the record will be created.
   * @param {any} data - The data to be stored in the new record. The id property is a requirement.
   * @param {string} [databaseName='sc_db'] - The name of the database where the store is located.
   *
   * @returns {Promise<any>} - A promise that resolves with the response from the database operation.
   */
  async dbCreate(storeName: string, data: { id: string | number; } & { [key: string]: any; }, databaseName: string = 'sc_db'): Promise<any>  {
    // Return a resolvable promise that will contain the value from the db operation.
    return await new Promise((resolve) => {
      // Create a new record.
      this.db.create(storeName, data, databaseName).subscribe(
        (response) => {
          // Resolve with the created object.
          resolve(response);
        },
        (errorResponse) => {
          // Resolve null on failure.
          resolve(null);
        }
      );
    });
  }

  /**
   * Retrieves a record from the specified database and store.
   *
   * @param {string} storeName - The name of the store to retrieve the record from.
   * @param {string | number} id - The ID of the record to retrieve.
   * @param {string} [databaseName='sc_db'] - The name of the database to search in. Default value is 'sc_db'.
   * @return {Promise<any>} - A promise that resolves with the retrieved record or null if not found.
   */
  async dbGet(storeName: string, id: string | number, databaseName: string = 'sc_db'): Promise<any>  {
    // Return a resolvable promise that will contain the value from the db operation.
    return await new Promise((resolve) => {
      // Get an existing record.
      this.db.get(storeName, id, databaseName).subscribe(
        (response) => {
          // Resolve the response with the record from the db.
          resolve(response);
        },
        (errorResponse) => {
          // Resolve null on failure.
          resolve(null);
        }
      );
    });
  }

  /**
   * Updates a record in the specified store in the specified database.
   *
   * @param {string} storeName - The name of the store in which the record will be updated.
   * @param {object} data - The data that will be updated. It should have an 'id' property, which is either a string or a number and represents the ID of the record. It can contain additional
   * properties to update in the record.
   * @param {string} [databaseName='sc_db'] - The name of the database where the store resides. Default is 'sc_db'.
   * @return {Promise<any>} - A promise that resolves to the response containing the updated entry, or null if the update fails.
   */
  async dbUpdate(storeName: string, data: { id: string | number; } & { [key: string]: any; }, databaseName: string = 'sc_db'): Promise<any>  {
    // Return a resolvable promise that will contain the value from the db operation.
    return await new Promise((resolve) => {
      // Update an existing record.
      this.db.update(storeName, data, databaseName).subscribe(
        (response) => {
          // Resolve the response with the updated entry.
          resolve(response);
        },
        (errorResponse) => {
          // Resolve null on failure.
          resolve(null);
        }
      );
    });
  }

  /**
   * Deletes a record from the database.
   *
   * @param {string} storeName - The name of the store from which to delete the record.
   * @param {string|number} id - The ID of the record to delete.
   * @param {string} [databaseName='sc_db'] - The name of the database (optional, default: 'sc_db').
   *
   * @returns {Promise<any>} A promise that resolves with the deletion status or null on failure.
   */
  async dbDelete(storeName: string, id: string | number, databaseName: string = 'sc_db'): Promise<any>  {
    // Return a resolvable promise that will contain the value from the db operation.
    return await new Promise((resolve) => {
      // Delete an existing record.
      this.db.delete(storeName, id, databaseName).subscribe(
        (response: boolean) => {
          // Resolve the deleted status.
          resolve(response);
        },
        (errorResponse) => {
          // Resolve null on failure.
          resolve(null);
        }
      );
    });
  }

  /**
   * Updates or creates a record in the specified store of a database.
   *
   * @param {string} storeName - The name of the store where the record should be updated or created.
   * @param {object} data - The data for the record to update or create.
   * @param {string} databaseName - The name of the database where the store is located. (optional, default: 'sc_db')
   * @returns {Promise<any>} - A promise that resolves to the updated or created record.
   */
  async dbUpdateOrCreate(storeName: string, data: { id: string | number; } & { [key: string]: any; }, databaseName: string = 'sc_db'): Promise<any> {
    // Attempt to update a record.
    const updated = await this.dbUpdate(storeName, data, databaseName);
    if ( !updated ) {
      // Create a new record because the update failed.
      return await this.dbCreate(storeName, data, databaseName);
    }
    // Return the updated values.
    return updated;
  }
}
