<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button mat-raised-button routerLink="/sites" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>

    <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
    </button>
  </nav>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand" id="title">{{ utils.getLangTerm('contractors.plural', 'Contractors') }}</span>
    <button class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <mat-menu #linkMenu="matMenu">
          <button mat-menu-item (click)="onLinkContractorBusiness()">
            Link {{ utils.getLangTerm('contractors.singular', 'Contractor') }}
          </button>

          <button mat-menu-item (click)="onUpdateLinkedContractors('all')"*ngIf="site_id">
            Link All {{ utils.getLangTerm('contractors.singular', 'Contractor') }}
          </button>

          <button mat-menu-item (click)="onUpdateLinkedContractors([])" *ngIf="site_id">
            Unlink All {{ utils.getLangTerm('contractors.singular', 'Contractor') }}
          </button>
        </mat-menu>

        <button mat-raised-button color="" [matMenuTriggerFor]="linkMenu" id="menu-button">
          <mat-icon>link</mat-icon> Link
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>

          <button mat-menu-item (click)="composeNewMessage()">
            <mat-icon>send</mat-icon>
            Send Message {{ dataSource.selection.hasValue() ? 'to (' + dataSource.selection.selected.length + ') Selected' : '(0)' }}
          </button>

          <button mat-menu-item (click)="onDeleteSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>link_off</mat-icon> Unlink Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect and/or send messages to {{ utils.getLangTerm('contractors.plural', 'Contractors') }}." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>
      </span>

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (ngSubmit)="dataSource.getData(true)">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search"
          id="search-input" />

        <button
          mat-raised-button
          color="secondary"
          class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
          (click)="dataSource.getData(true)"
          id="search-button">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="entity_name" matSortDirection="asc" matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null" [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? dataSource.selection.toggle(row.id) : null" [checked]="dataSource.selection.isSelected(row.id)"></mat-checkbox>
        </td>
      </ng-container>

      <!-- Full Name Column -->
      <ng-container matColumnDef="contact_person">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Full Name
        </th>
        <td mat-cell *matCellDef="let element">
          <a (click)="onUserPublicView(element.hash)"
             matTooltip="View User Public Profile">
            {{ element.contact_person }}
          </a>
        </td>
      </ng-container>

      <!-- Company Name Column -->
      <ng-container matColumnDef="entity_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
        <td mat-cell *matCellDef="let element">{{ element.entity_name }}</td>
      </ng-container>

      <!-- Email Address Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <!-- Mobile Number Column -->
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ utils.getLangTerm('accounts.list.mobile', 'Mobile Number') }} </th>
        <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let contractor">
          <button type="button" mat-button (click)="contractor.sssp ? onManageSSSP(contractor.sssp.sssp_hash) : onCreateSSSP(contractor.id)" [matTooltip]="(contractor.sssp ? 'Manage the' : 'Create a') + ' Safety Plan for this ' + utils.getLangTerm('contractors.singular', 'Contractor') + '.'" matTooltipPosition="above">
            {{ contractor.sssp ? 'Manage' : 'Create' }} Safety Plan
          </button>

          <button type="button" mat-button (click)="onRemove(contractor.id)" matTooltip="Remove this {{ utils.getLangTerm('contractors.singular', 'Contractor') }} from the site." matTooltipPosition="above">
            Unlink
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
