import { Component, OnInit } from '@angular/core';
import { AccountConfigurationService } from './account-configuration.service';
import { AccountConfigurationModel } from 'src/app/models/account-configuration.model';
import { AppService } from 'src/app/app.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-account-configuration',
  templateUrl: './account-configuration.component.html',
  styleUrls: ['./account-configuration.component.scss']
})
export class AccountConfigurationComponent implements OnInit {

  config: AccountConfigurationModel = {} as AccountConfigurationModel;

  inProgress: boolean = false;

  auto_site_signout_duration_options: number[] = [2, 3, 4, 5, 6];

  constructor(
    private configService: AccountConfigurationService,
    private app: AppService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    this.configService
      .find(this.app.account.id)
      .subscribe((response) => (this.config = response.data));
  }

  onSave() {

    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;

    this.configService.update(this.config.id, this.config).subscribe((response: any) => {
      this.utils.showToast('Updated account configuration');
      this.inProgress = false;

      // Apply the response data
      this.app.account.apply(response.data);

    }, () => {
      this.inProgress = false;
    });
  }
}
