<div mat-dialog-title class="position-relative w-100">
    <h5>Copy Account Templates</h5>

    <button mat-icon-button class="position-absolute" matTooltip="Close Dialog" mat-dialog-close style="top: -10px;right: 0">
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </div>

  <div class="d-flex my-2 py-2 flex-wrap justify-content-between align-items-center">
    <p>Select which account you want to copy templates from.</p>
    <button type="button" mat-raised-button class="float-right" color="primary" (click)="onSelectAccount()">
      Select Account
    </button>
  </div>

  <div class="my-2 py-2">
    <h6>Selected Account: <span>{{ sourceAccount ? sourceAccount.name : 'Not Selected' }}</span></h6>
    <h6 class="mt-3">Templates</h6>
    <div class="mt-3">
      <mat-radio-group [(ngModel)]="selectedTemplate">
        <p><mat-radio-button value="hazards">Hazards & Risks</mat-radio-button></p>
        <p><mat-radio-button value="hazardous_substances">Hazardous Substances</mat-radio-button></p>
        <p><mat-radio-button value="site_audits">Inspections & Audits</mat-radio-button></p>
        <p><mat-radio-button value="all">All of the Above</mat-radio-button></p>
      </mat-radio-group>
    </div>
  </div>

  <div class="d-flex justify-content-end my-2 py-2">
    <button type="button" mat-raised-button class="float-right" color="primary" [disabled]="!sourceAccountId || inProgress" (click)="onCopyTemplates()">
      Copy Templates
    </button>
  </div>
