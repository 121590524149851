import { Component, OnInit, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { AppService } from '../../app.service';
import { UtilsService } from '../../shared/utils.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContractorAccountModel } from 'src/app/models/contractor-account.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { EmployeeModel } from 'src/app/models/employee.model';
import {TradeModel} from "../../admin/trades/trades.model";
import {IndustriesModel} from "../../admin/industries/industries.model";
import _ from 'lodash';


@Component({
  selector: 'app-contractors-add',
  templateUrl: './contractors-add.component.html',
  styleUrls: ['./contractors-add.component.scss']
})
export class ContractorsAddComponent implements OnInit {

  contractor: ContractorAccountModel = new ContractorAccountModel();

  inProgress = false;

  _search: string = '';
  _contractors: ContractorAccountModel[] = [];

  isNewContractorEmployee: boolean = false;

  industries: IndustriesModel[] = [];
  trades: TradeModel[] = [];

  isContractorSelected: boolean = false;
  isMainContactSelected: boolean = false;
  mainContactHasMobile: boolean = false;

  // Mobile field Country Code selector values
  mobile_input_id: string = 'phone-1';
  mobile_number: string = '';
  mobile_country_code: string = '';
  mobile_dial_code: string = '';
  mobile_e164: string = '';
  mobile_error_state: boolean = true;

  constructor(
    public router: Router,
    public app: AppService,
    public utils: UtilsService,
    private dialog: MatDialogRef<ContractorsAddComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private api: ApiRequestService
  ) {}

  ngOnInit() {
    // If the contractor is provided, the new user will be added to it.
    if ( this.data['contractor'] ) {
      this.contractor.id = this.data['contractor'].id;
      this.contractor.name = this.data['contractor'].name;
      this.isNewContractorEmployee = true;
    } else {
      // Get initial list of contractors
      this.searchContractors();
    }

    // Get industries from the API.
    this.getIndustries();

    // Get trades from the API.
    this.getTrades();
  }

  /**
   * Get Output values from phone input component.
   */
  getOutputPhoneDetails($event) {
    if($event) {
      let phone = $event;
      this.mobile_number = phone.hasOwnProperty('number') && phone.number !== null ? phone.number : '';
      this.mobile_country_code = phone.hasOwnProperty('countryCode') && phone.countryCode !== null ? phone.countryCode : '';
      this.mobile_dial_code = phone.hasOwnProperty('dialCode') && phone.dialCode !== null ? phone.dialCode : '';
      this.mobile_e164 = phone.hasOwnProperty('e164Number') && phone.e164Number !== null ? phone.e164Number : '';
      this.mobile_error_state = phone.hasOwnProperty('errorState') && phone.errorState !== null ? phone.errorState : true;
    }
  }

  private async getIndustries() {
    this.industries = await this.utils.getIndustries();
  }

  private async getTrades() {
    this.trades = await this.utils.getTrades();
  }

  /**
   * Search for contractors based on input triggers.
   */
  onSearchContractors() {
    if ( this._search.length < 2 ) {
      return;
    }
    this.searchContractors();
  }

  /**
   * Send the API request to perform the search for contractors.
   * @private
   */
  private searchContractors() {
    // Send the API request to search for contractors.
    this.api.makeRequest('get', 'v2/contractors/search', {}, {
      search: this._search
    }).then((response: { data: ContractorAccountModel[] }) => {
      this._contractors.length = 0;
      this._contractors.push(...response.data);
    });
  }

  /**
   * Select a contractor from the list.
   * @param contractor
   */
  onSelectContractor(contractor: ContractorAccountModel) {
    this.contractor.apply(contractor);
    this.isContractorSelected = true;
  }

  /**
   * Select an employee from the list and update the contractor's contact information.
   *
   * @param {EmployeeModel} user - The employee object to select.
   */
  onSelectUser(user: EmployeeModel): void {
    this.contractor.contact_person = user.contact_person;
    this.contractor.email = user.email;
    this.contractor.mobile = user.mobile;
    this.contractor.mobile_country_code = user.mobile_country_code;
    this.contractor.mobile_dial_code = user.mobile_dial_code;
    this.contractor.mobile_e164 = user.mobile_e164;
    this.isMainContactSelected = true;
    this.mainContactHasMobile = !(_.isEmpty(this.contractor.mobile));
  }

  /**
   * Reset the selected user.
   */
  onResetSelectedUser() {
    this.contractor.contact_person = '';
    this.contractor.email = '';
    this.contractor.mobile = '';
    this.contractor.mobile_country_code = '';
    this.contractor.mobile_dial_code = '';
    this.contractor.mobile_e164 = '';
    this.isMainContactSelected = false;
    this.mainContactHasMobile = false;
  }

  onReset() {
    this.contractor.reset();
    this._search = '';
    this.isContractorSelected = false;
    this.isMainContactSelected = false;
    this.mainContactHasMobile = false;
  }

  /**
   * Submits a form and performs necessary actions of adding a contractor to the account.
   *
   * @param {NgForm} form - The form to be submitted.
   * @return {void}
   */
  onSubmit(form: NgForm) {
    // Check if the submitted form is valid.
    if (!form.valid) {
      this.utils.showFormValidationError();
      return;
    }

    // Prevent form from being submitted multiple time in a row.
    if ( this.inProgress ) {
      return;
    }

    if(!this.isMainContactSelected || !this.mainContactHasMobile) {
      // Check Error states of phone input then continue submission
      if (this.mobile_error_state) {
        if(this.mobile_number.length < 3) {
          this.utils.showFormValidationError('A Phone number must be at least 3 characters long.');
        } else {
          this.utils.showFormValidationError();
        }
        return;
      }

      // Update User details with Mobile field Country Code selector values
      this.contractor.mobile = this.mobile_number;
      this.contractor.mobile_country_code = this.mobile_country_code;
      this.contractor.mobile_dial_code = this.mobile_dial_code;
      this.contractor.mobile_e164 = this.mobile_e164;
    }

    this.inProgress = true;

    // Send a request to the API to create the new account and/or user.
    this.api.makeRequest('post', 'v2/contractors', {...this.contractor, ...{ is_contractor_employee: this.isNewContractorEmployee }})
      .then((contractor: ContractorAccountModel) => {
        this.utils.showToast('The ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + ' was successfully linked.');
        this.dialog.close(contractor);
        this.inProgress = false;
      })
      .catch((error) => {
        this.utils.handleAPIErrors(error);
        this.inProgress = false;
      });
  }

  onSelectSites() {
    this.utils.showComponentDialog(SitesSelectorComponent, {
        selected: this.contractor.site_ids,
        multiple: true
      }, {
        width: '1024px'
      }, (results) => {
        if (typeof results != 'undefined') {
          this.contractor.site_ids = results;
        }
      }
    );
  }
}
