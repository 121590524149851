import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErpTeamsService } from '../erp-teams.service';

@Component({
  selector: 'app-erp-teams-view',
  templateUrl: './erp-teams-view.component.html',
  styleUrls: ['./erp-teams-view.component.css']
})
export class ErpTeamsViewComponent implements OnInit {
  parent_id: number;
  id: number;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public service: ErpTeamsService
  ) {}

  ngOnInit() {
    this.parent_id = Number(this.route.snapshot.params['erp_id']);
    this.id = Number(this.route.snapshot.params['member_id']);

    if (this.id) {
      this.service.get(this.parent_id, this.id);
    } else {
      this.router.navigate(['erps', this.parent_id, 'teams']);
    }
  }
}
