import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  constructor(private router: Router, private route: ActivatedRoute) { }

  public isAuthRoute() {
    return !this.router.url.match('sign-in|forgot-password');
  }

  public setQueryParams(params) {
    this.router.navigate([], {relativeTo: this.route, queryParams: params});
  }
}
