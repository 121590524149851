<div mat-dialog-title>
  <h5>Incident Investigation</h5>

  <button mat-icon-button class="float-right" style="margin-top: -2.6rem" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="Overview">
      <app-incidents-edit [incidentTabsComponent]="this"></app-incidents-edit>
    </mat-tab>

    <mat-tab label="LTI - Lost Time Injury" *ngIf="incident.id">
      <app-incidents-ltis [incident]="incident" (closed_incident_lti_evt)="find()"></app-incidents-ltis>
    </mat-tab>

    <mat-tab *ngIf="incident.id">
      <ng-template mat-tab-label>
        Investigation
      </ng-template>
      <app-incidents-details [incident]="incident"></app-incidents-details>
    </mat-tab>

    <mat-tab *ngIf="incident.id">
      <ng-template mat-tab-label>
        Causes / Corrective Actions
      </ng-template>
      <app-incidents-causes [incidentId]="incident.id" (incidentChange)="find()" [incidentStatus]="incident.status"></app-incidents-causes>
    </mat-tab>

    <mat-tab label="Witnesses" *ngIf="incident.id">
      <app-incidents-witnesses [incidentId]="incident.id"></app-incidents-witnesses>
    </mat-tab>

    <mat-tab label="Hazards & Risks" *ngIf="incident.id">
      <app-incidents-hazards [incidentId]="incident.id" [siteId]="incident.site_id"></app-incidents-hazards>
    </mat-tab>

    <mat-tab label="Notes" *ngIf="incident.id">
      <app-incidents-notes [incidentId]="incident.id" [incidentStatus]="incident.status"> </app-incidents-notes>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
