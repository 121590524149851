import { Component, OnInit, Input, Inject } from '@angular/core';
import { ActionModel } from '../action.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionNoteModel } from '../action-note.model';
import { ApiService } from 'src/app/shared/api.service';
import { NgForm } from '@angular/forms';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-actions-notes-edit',
  templateUrl: './actions-notes-edit.component.html',
  styleUrls: ['./actions-notes-edit.component.scss']
})
export class ActionsNotesEditComponent implements OnInit {

  action: ActionModel;

  note: ActionNoteModel = new ActionNoteModel();

  inProgress = false;

  constructor(
    public dialogRef: MatDialogRef<ActionsNotesEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {action: ActionModel, noteId: number},
    private api: ApiService,
    private utils: UtilsService
  ) { }

  ngOnInit() {

    this.action = this.data.action;

    if ( this.action.id && this.data.noteId ) {
      this.api.laravelApiRequest('get', `actions/${this.action.id}/notes/${this.data.noteId}`, {}, {}, 
        (response) => {
          this.note = response.data;
        }
      );
    }

  }

  onSubmit(form: NgForm) {
    if ( !form.valid ) {
      this.utils.showModal('Validation Error', 'Please make sure you enter all required fields.');
      return;
    }

    if (this.inProgress) {
      return;
    }

    this.inProgress = true;

    if ( this.data.noteId ) {
      this.api.laravelApiRequest('put', `actions/${this.action.id}/notes/${this.data.noteId}`, this.note, {},
      (resposne: any) => {
        this.utils.showToast('Note Updated.');
        this.inProgress = false;
      }, 
      (_error: any) => this.inProgress = false
    );
    } else {
      this.api.laravelApiRequest('post', `actions/${this.action.id}/notes`, this.note, {},
        (resposne: any) => {
          this.utils.showToast('Note Created.');
          this.dialogRef.close();
          this.inProgress = false;
        }, 
        (_error: any) => this.inProgress = false 
      );
    }
  }

  onDelete() {

    if ( !this.data.noteId ) {
      return;
    }

    this.utils.showModal('Delete Note', 'Are you sure you want to delete the note?', () => {
      this.api.laravelApiRequest('delete', `actions/${this.action.id}/notes/${this.data.noteId}`, {}, {},
        (resposne: any) => {
          this.utils.showToast('Note Deleted.');
          this.dialogRef.close();
        } 
      );
    });
  }

}
