<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/task-analyses">TA's</a></li>
    <li class="breadcrumb-item">
      <a routerLink="/task-analyses/{{ parent_id }}/steps">TA Steps</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">View</li>
  </ol>
</nav>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <ng-container *ngIf="service.item.step">
        <h6>Step</h6>
        <p>{{ service.item.step }}</p>
      </ng-container>

      <ng-container *ngIf="service.item.hazards.length">
        <h6>Hazards & Risks</h6>
        <ul>
          <ng-container *ngFor="let storedHazard of service.item.hazards">
            <ng-container *ngFor="let hazard of hazardsService.list">
              <li *ngIf="storedHazard.hazard_id === hazard.id">
                {{ hazard.name }}
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </ng-container>
    </div>
    <div class="col-12 col-md-6"></div>
  </div>
</div>
