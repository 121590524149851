import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { SsspTabsComponent } from './sssp-tabs.component';

@Component({
  selector: 'app-sssp-tabs-dialog',
  templateUrl: './sssp-tabs.component.html',
  styleUrls: ['./sssp-tabs.component.scss']
})
export class SsspTabsDialogComponent extends SsspTabsComponent implements OnInit {

  @Input('sssp_hash') sssp_hash: string;

  constructor(
    public app: AppService,
    public api: ApiRequestService,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<SsspTabsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) {
    super(app, api, utils);
  }

  ngOnInit() {
    // If the dialog data is available, use it instead.
    if ( this.dialogData['sssp_hash'] ) {
      this.sssp_hash = this.dialogData['sssp_hash'];
    }

    // Called from super class.
    this.getSSSP()
    .then(() => {
      if ( !this.sssp.id ) {
        // The request failed to return the Safety Plan data.
        // Close the dialog.
        this.dialogRef.close();
      }
    });
  }
}
