<div mat-dialog-title>
  <h5>New Version of TA/JSA/SWMS - {{ taskName }}</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #form="ngForm">

  <mat-dialog-content style="max-height: 40vh">
    <mat-form-field>
      <input
        matInput
        placeholder="Amendment Title"
        [(ngModel)]="amendmentTitle"
        name="amendment_title"
        type="text"
        required
        #amendment_title />
      <mat-hint>Please enter an Amendment title.</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <textarea
        rows="4"
        matInput
        placeholder="Reason for Amendment"
        [(ngModel)]="reasonForAmendment"
        name="reason_for_amendment"
        required
        #reason_for_amendment>
      </textarea>
      <mat-hint>Please enter a Reason for Amendment.</mat-hint>
    </mat-form-field>

  </mat-dialog-content>

  <div class="row">
    <div class="col-12 mt-3">
      <div class="alert alert-warning" role="alert">
        You are about to create a new version of the current TA/JSA/SWMS. <br>Please enter an amendment title and a reason for amendment before continuing. The new version will need to be acknowledged by everyone who is associated with it.
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 text-center">

      <button
        mat-raised-button
        type="button"
        id="cancel"
        class="m-2"
        (click)="onCancel()">
        <mat-icon>cancel</mat-icon>
        Cancel
      </button>

      <button
        mat-raised-button
        [disabled]="!form.valid"
        color="primary"
        type="button"
        id="continue"
        class="m-2"
        (click)="onContinue(form)">
        <mat-icon>arrow_forward</mat-icon>
        Continue
      </button>

    </div>
  </div>

</form>
