import {Component, Inject, OnInit} from '@angular/core';
import {FileModel} from "../file.model";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-user-public-profile-training-doc-files',
  templateUrl: './user-public-profile-training-doc-files.component.html',
  styleUrls: ['./user-public-profile-training-doc-files.component.scss']
})
export class UserPublicProfileTrainingDocFilesComponent implements OnInit {

  files: FileModel[];
  name: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      files: FileModel[],
      name: string
    }
  ) { }

  ngOnInit() {
    this.files = this.data.files;
    this.name = this.data.name;
  }

}
