import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-users-documents-importer',
  templateUrl: './users-documents-importer.component.html',
  styleUrls: ['./users-documents-importer.component.css']
})
export class UsersDocumentsImporterComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
