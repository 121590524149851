<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/admin/work-permits">Work Permits</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <span class="mr-auto">
      <button mat-raised-button color="primary" appMarginRight (click)="onAdd()" id="add"
              class="margin-right">
        <mat-icon>add</mat-icon>
        Add Work Permit
      </button>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>
        <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>delete</mat-icon> Delete Selected
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Work Permits." style="min-width: 120px">
        <mat-icon>more_vert</mat-icon>
        List Menu
      </button>
    </span>

    <mat-form-field style="width: 150px;margin-top: 15px !important;" class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none">
      <mat-select placeholder="Industry" [(ngModel)]="dataSource.industryFilter"
                  name="active_archive"
                  matTooltip="Filter by Industry."
                  (selectionChange)="dataSource.getData(true)">
        <mat-option [value]="0">All</mat-option>
        <mat-option *ngFor="let industry of industries" [value]="industry.id">{{ industry.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <form tourAnchor="tour.search" class="form-inline my-2 my-lg-0" style="min-width: 325px"
          (ngSubmit)="dataSource.getData(true)">
      <input class="form-control mr-sm-2" type="search"
             [(ngModel)]="dataSource.search"
             name="search"
             aria-label="Search"
             placeholder="Search"
             matTooltip="Search for Work Permits"/>
      <button mat-raised-button class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0" color="secondary"
              (click)="dataSource.getData(false)"
              type="button"
              matTooltip="Run the current search">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>

  </div>

</nav>

<div class="mat-elevation-z8 scroll-box">
  <table mat-table [dataSource]="dataSource" style="width: 100%" matSort
         matSortActive="title" matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
                      [checked]="
            dataSource.selection.hasValue() && dataSource.isAllSelected()
          " [indeterminate]="
            dataSource.selection.hasValue() && !dataSource.isAllSelected()
          ">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? dataSource.selection.toggle(row.id) : null"
                      [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        #
      </th>
      <td mat-cell *matCellDef="let workPermit">
        {{ workPermit.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Work Permit
      </th>
      <td mat-cell *matCellDef="let workPermit" class="no-padding text-wrap">
        <span>
          {{ workPermit.title }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="industry">
      <th mat-header-cell *matHeaderCellDef class="no-padding">
        Industry
      </th>
      <td mat-cell *matCellDef="let workPermit" class="no-padding">
        <span *ngIf="workPermit?.industry else showAllIndustries">{{ workPermit.industry.name }}</span>
        <ng-template #showAllIndustries>All</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="creator">
      <th mat-header-cell *matHeaderCellDef class="no-padding">
        Created By
      </th>
      <td mat-cell *matCellDef="let workPermit" class="no-padding">
        <span *ngIf="workPermit?.created_by_user else showSystemCreator">
          <a (click)="onUserPublicView(workPermit.created_by_user.hash)"
             matTooltip="View User Public Profile">
            {{ workPermit.created_by_user.contact_person }}
          </a>
        </span>
        <ng-template #showSystemCreator>System</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Created At
      </th>
      <td mat-cell *matCellDef="let workPermit" class="no-padding">
        <span>
          {{ (workPermit.date_created * 1000) | timezoneConvertMedium }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Created At (UTC)
      </th>
      <td mat-cell *matCellDef="let workPermit" class="no-padding">
        <span>
          {{ (workPermit.date_created * 1000) | timezoneConvertUTCMedium }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let workPermit">
        <button type="button" mat-button (click)="onView(workPermit.id)" matTooltip="View the work permit information." matTooltipPosition="above">
          View
        </button>

        <button type="button" mat-button (click)="onEdit(workPermit)" matTooltip="Edit the work permit information." matTooltipPosition="above">
          Edit
        </button>

        <button type="button" mat-button (click)="onRemove(workPermit)" matTooltip="Remove (Archive) the work permit." matTooltipPosition="above">
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [length]="dataSource?.total | async"
                 [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions"
                 showFirstLastButtons>
  </mat-paginator>
</div>
