import { CollectionViewer, SelectionModel } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from '../app.service';
import { ApiRequestService } from '../shared/api-request.service';

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class ApiDataSource implements DataSource<any> {

  public dataSubject = new BehaviorSubject<any[]>([]);

  private loadSubject = new BehaviorSubject<boolean>(false);

  /**
   * Total number of data objects as a BehaviourSubject.
   */
  private totalSubject = new BehaviorSubject<number>(0);

  public loading = this.loadSubject.asObservable();

  /**
   * Total number of data objects returned.
   */
  public total = this.totalSubject.asObservable();

  /**
   * A variable to observe changes in record ids.
   */
  public allIds = [];

  /**
   * Page size for data requests.
   */
  limit = 30;

  /**
   * Page offset for data requests.
   */
  offset = 0;

  /**
   * List of page sizes the user may choose from.
   */
  pageSizeOptions: number[] = [1, 30, 60, 90, 120];

  /**
   * Field to sort data by.
   */
  order_by = 'id';

  /**
   * Direction to sort data by.
   */
  order = 'asc';

  /**
   * String to search the data for.
   */
  search = '';

  /**
   * Used for selecting rows in a table.
   */
  selection = new SelectionModel<any>(this.multiple, []);

  ids: number[] = [];

  /**
   * Change this to true to only show archived records. The API need to be changed accordingly.
   */
  only_archived: string = 'false';

  constructor(
    public app: AppService,
    public api: ApiRequestService,
    public multiple: boolean = true,
    public properties: any = {}
  ) {
    // add aditional custom data
    Object.keys(properties).forEach((property) => {
      this[property] = properties[property];
    });
  }

  connect(collectionViewer?: CollectionViewer): Observable<any> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer?: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadSubject.complete();
    this.totalSubject.complete();
  }

  /**
   * check if all records in the current data source is selected.
   */
  isAllSelected(primaryKeyIdentifier: string = null) {
    // default value of true
    let isAllSelectedStatus = true;
    // loop through all records in the data source and check if it is selected
    this.dataSubject
      .subscribe((records: any) => {
        records.forEach((record) => {
          // check if the record is not selected
          if (!this.selection.isSelected(primaryKeyIdentifier ? record[primaryKeyIdentifier] : record.id)) {
            // if the record is not selected, not all records are selected
            isAllSelectedStatus = false;
          }
        });
      })
      .unsubscribe(); // unsubscribe required to prevent this code from executing multiple times
    // return the status
    return isAllSelectedStatus;
  }

  /**
   * the master toggle will select or deselect all records from the current data source records.
   */
  selectAllToggle(primaryKeyIdentifier: string = null) {
    // check if all records from the current data source is selected
    if (this.isAllSelected(primaryKeyIdentifier)) {
      // if all is selected we want to deselect all records from the current data source
      this.dataSubject
        .subscribe((records: any) => {
          records.forEach((record) => {
            this.selection.deselect(primaryKeyIdentifier ? record[primaryKeyIdentifier] : record.id);
          });
        })
        .unsubscribe(); // unsubscribe required to prevent this code from executing multiple times
    } else {
      // if not all records are selected, we want to select all records
      this.dataSubject
        .subscribe((records: any) => {
          records.forEach((record) => {
            this.selection.select(primaryKeyIdentifier ? record[primaryKeyIdentifier] : record.id);
          });
        })
        .unsubscribe(); // unsubscribe required to prevent this code from executing multiple times
    }
  }

  selectAllToggleObjects(primaryKeyIdentifier: string = null) {
    this.isAllSelected(primaryKeyIdentifier)
      ? this.selection.clear()
      : this.dataSubject.subscribe((records) =>
          records.forEach((record) => this.selection.select(record))
        );
  }

  /**
   * deselect all selected records.
   */
  deselectAll() {
    // clear all selected recotds
    this.selection.clear();
  }

  /**
   * Row toggling should only happen when multi select is enabled.
   * @param id The row id that should be toggled.
   */
  toggleRowSelection(id: number) {
    if ( this.multiple ) {
      this.selection.toggle(id);
    }
  }

  /**
   * Check if a row is selected.
   * @param id The row id that should be checked.
   */
  isSelected(id: number) {
    return this.selection.selected.indexOf(id) > -1;
  }

  /**
   * The make request function automatically populates the datasource. It also returns a promise that can be used
   * to dismiss any loaders or performs any additional post api request actions.
   *
   * When a search is performed remember to reset the offset otherwise your query may not load all the data.
   *
   * @param endpoint The endpoint to get data from. The endpoint must return an object with a data property containing an array of data objects.
   * @param resetOffset This resets the offset to 0 so that all data can be loaded from the start.
   * @param callback
   */
  makeRequest(
    endpoint: string,
    resetOffset: boolean = false,
    params: any = {},
    headers: any = {}
  ) {
    // update the loader
    this.loadSubject.next(true);

    // reset the offset if it need to
    if ( resetOffset ) {
      this.offset = 0;
    }

    // make an API request to get data
    return this.api.makeRequest('get', endpoint, {}, {
      limit: this.limit,
      offset: this.offset,
      order_by: this.order_by,
      order: this.order,
      search: this.search,
      only_archived: this.only_archived,
      ...params
    }, headers).then((response: any) => {
      this.dataSubject.next(response.data || response); // Some lists don't use response.data.
      // this.totalSubject.next(response.total || 0);

      if ( typeof response.meta !== 'undefined' ) {
        // store the total number of records
        if ( typeof response.meta.total !== 'undefined' ) {
          this.totalSubject.next(response.meta.total);
        } else {
          this.totalSubject.next(response.total || 0);
        }

        // store collected ids
        if ( typeof response.meta.ids !== 'undefined' ) {
          this.ids = response.meta.ids;
        }
      } else {
        this.totalSubject.next(response.total || 0);
      }

      this.allIds = response.allIds || [];
    }).catch(() => {
      this.dataSubject.next([]);
      this.totalSubject.next(0);
      this.allIds = [];
    }).finally(() => {
      this.loadSubject.next(false);
    });
  }

  makeDownloadRequest(endpoint: string, params: any = {}, headers: any = {}) {
    return this.api.makeDownloadRequest(endpoint, headers, {
      order_by: this.order_by,
      order: this.order,
      search: this.search,
      only_archived: this.only_archived,
      ...params
    });
  }

  /**
   * Clear the result set from the data source.
   */
  clearResults() {
    this.dataSubject.next([]);
    this.totalSubject.next(0);
    this.loadSubject.next(false);
  }
}
