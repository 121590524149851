import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnChanges {
  @Input() rating = 0;
  @Input() edit = false;
  @Output() ratingChanged = new EventEmitter<number>();

  filledStars = [];
  emptyStars = [];

  ngOnChanges() {
    this.filledStars = [];
    this.emptyStars = [];
    for (let i = 0; i < this.rating; i++) {
      this.filledStars.push(1);
    }
    for (let i = 0; i < 5 - this.rating; i++) {
      this.emptyStars.push(1);
    }
  }

  onRateUp(i: number) {
    this.rating += i + 1;
    this.ratingChanged.emit(this.rating);
    this.ngOnChanges();
  }

  onRateDown(i: number) {
    if (i + 1 === this.rating) {
      this.rating = 0;
    } else {
      this.rating = i + 1;
    }
    this.ratingChanged.emit(this.rating);
    this.ngOnChanges();
  }
}
