import { UserModel } from '../models/user.model';
import { SiteModel } from '../models/site.model';
import { AuditItemModel } from '../audit-items/audit-item.model';
import { IncidentCauseModel } from '../incidents/incident-cause.model';
import { Auditable } from '../shared/auditable.model';
import { FileModel } from '../shared/file.model';

export class ActionModel extends Auditable {
  id: number;
  name: string;
  priority: 'Medium' | 'High' | 'Critical' | 'Low';
  due: string;
  completed: boolean;
  users: UserModel[];
  user_ids?: number[];
  incident_cause_id: number;
  new_files?: FileList;

  audit_items?: AuditItemModel[];

  incident_cause?: IncidentCauseModel;

  files?: FileModel[];

  /**
   * Should this action be shared amongst users or be created multiple times
   * for each user?
   */
  individual_actions?: boolean;

  /**
   * Used for assigning and updating the Site.
   */
  site_id?: number;

  /**
   * Returned when getting the site of an Action.
   */
  site: SiteModel;

  /**
   * Who created this Action?
   */
  creator: UserModel;

  /**
   * Who last updated this Action?
   */
  updater: UserModel;

  /**
   * This is not stored in the actions table. It is simply passed along with the form data
   * to associate the action with the given safety observation.
   */
  safety_observation_id: number;

  /**
   * This is not stored in the actions table. It is simply passed along with the form data
   * to associate the action with the given toolbox talk.
   */
  toolbox_talk_id: number;
}
