import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { FilesModule } from '../files/files.module';
import { SharedModule } from '../shared/shared.module';
import { DynamicFormsCategoriesComponent } from './dynamic-forms-categories/dynamic-forms-categories.component';
import { DynamicFormsCategoriesEditComponent } from './dynamic-forms-categories-edit/dynamic-forms-categories-edit.component';
import { DynamicFormsCategoriesViewComponent } from './dynamic-forms-categories-view/dynamic-forms-categories-view.component';
import { DynamicFormsCategoriesRoutingModule } from './dynamic-forms-categories-routing.module';
import { DynamicFormsCategoriesSelectorComponent } from './dynamic-forms-categories-selector/dynamic-forms-categories-selector.component';
import {ChartsModule} from "../charts/charts.module";

@NgModule({
  declarations: [
    DynamicFormsCategoriesComponent,
    DynamicFormsCategoriesEditComponent,
    DynamicFormsCategoriesViewComponent,
    DynamicFormsCategoriesSelectorComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        DirectivesModule,
        DynamicFormsCategoriesRoutingModule,
        DragDropModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatListModule,
        MatSelectModule,
        MatTabsModule,
        MatButtonModule,
        MatTooltipModule,
        MatDialogModule,
        MatBadgeModule,
        MatCardModule,
        MatExpansionModule,
        MatRadioModule,
        MatSlideToggleModule,
        PipesModule,
        FilesModule,
        SharedModule,
        MatDatepickerModule,
        ChartsModule
    ],
  entryComponents: [
    DynamicFormsCategoriesEditComponent,
    DynamicFormsCategoriesViewComponent,
    DynamicFormsCategoriesSelectorComponent
  ]
})
export class DynamicFormsCategoriesModule {}
