<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button mat-raised-button routerLink="/sites" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
  </button>

  <button mat-raised-button *ngIf="site.parent_site_id" [routerLink]="'/sites/'+site.parent_site_id+'/children'" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
  </button>
</nav>

<app-sssp-tabs *ngIf="site.id && site.sssp && site.sssp.id else noSSSP" [sssp_hash]="site.sssp.sssp_hash"></app-sssp-tabs>

<ng-template #noSSSP>
  <div class="p-3">
    <p>Create a master Safety Plan using the button below.</p>

    <button type="button" mat-button (click)="onCreateSSSP()" matTooltip="Create a new master Safety Plan record." matTooltipPosition="above">
      Create Master Safety Plan
    </button>
  </div>
</ng-template>
