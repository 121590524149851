<div mat-dialog-title>
  <h3>Accounts Merger</h3>
</div>

<mat-dialog-content>
  <div class="container-fluid">
    <div class="mat-elevation-z8 scroll-box" appMarginBottom>
      <table 
        mat-table 
        [dataSource]="dataSource" 
        matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
    
        <ng-container matColumnDef="id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by ID">
            #
          </th>
          <td mat-cell *matCellDef="let account">
            {{ account.id }}
          </td>
        </ng-container>
    
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef   matTooltip="Sort by Name">
            Name
          </th>
          <td mat-cell *matCellDef="let account">
            {{ account.name }}
          </td>
        </ng-container>
    
        <ng-container matColumnDef="date_created">
          <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by Date Joined">
            Date Joined
          </th>
          <td mat-cell *matCellDef="let account">
            {{ (account.date_created * 1000) | timezoneConvertShort }}
          </td>
        </ng-container>
    
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let account; let i = index">
            <button type="button" mat-button (click)="onMakePrimaryAccount(i)" *ngIf="!primaryAccount.id || primaryAccount.id != account.id">
              Make Primary Account
            </button>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    
      <mat-paginator 
        [length]="dataSource?.total | async" 
        [pageSize]="dataSource.limit"
        [pageSizeOptions]="dataSource.pageSizeOptions" 
        showFirstLastButtons>
      </mat-paginator>
    </div>

    <br>

    <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
      <div class="card">
        <div class="card-body form-container">
          <h5 class="card-title">Update Primary Account</h5>

          <mat-form-field>
            <input matInput placeholder="Account Name"
              [(ngModel)]="primaryAccount.name" name="name" type="text" #name
              required />
            <mat-error *ngIf="!name.valid">
              Please enter the name of the account.
            </mat-error>
          </mat-form-field>

          <div class="d-flex justify-content-end">
            <button type="submit" mat-raised-button [disabled]="!f.valid || !primaryAccount.id" color="primary">
              Merge
            </button>
          </div>
        </div>
      </div>
    </form>

  </div>
</mat-dialog-content>

