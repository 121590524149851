import { ItemPivotModel } from './item-pivot.model';
import { Auditable } from '../shared/auditable.model';

export class ItemModel extends Auditable {
  id: number;
  name: string;

  /**
   * The intermediary table in the items <-> prequalifications relationship.
   */
  pivot: ItemPivotModel;

  question: string;
  tooltip: string;

  sort_order: number = 0;
}
