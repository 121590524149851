import {Component, Inject, OnInit} from '@angular/core';
import {FolderLinkModel} from "../../shared/folder-link.model";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {UtilsService} from "../../shared/utils.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-account-folders-links-view',
  templateUrl: './account-folders-links-view.component.html',
  styleUrls: ['./account-folders-links-view.component.scss']
})
export class AccountFoldersLinksViewComponent implements OnInit {

  folder_link: FolderLinkModel = new FolderLinkModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    const folder_link_id = this.dialogData.folder_link_id;
    const folder_id = this.dialogData.folder_id;
    if(folder_link_id && folder_id) {
      this.getLink(folder_id, folder_link_id);
    }
  }

  private getLink (folder_id: number, folder_link_id: number) {
    this.api.makeRequest('get', `v2/folders/${folder_id}/links/${folder_link_id}`)
      .then((response) => {
        this.folder_link = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
