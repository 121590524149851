<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class="mr-auto">
    <button mat-raised-button routerLink="/incidents" appMarginRight>
      <mat-icon>arrow_back</mat-icon> Incident & Near Miss Register
    </button>

    <button
      mat-raised-button
      *ngIf="incident?.id"
      routerLink="/incidents/{{ incident?.id }}/edit"
      appMarginRight>
      Edit
    </button>

    <button
      mat-raised-button
      (click)="onRemove(incident.id)"
      *ngIf="incident?.id"
      appMarginRight>
      Delete
    </button>
  </span>
</nav>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <h6>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}</h6>
      <p>{{ incident.site?.name }}</p>

      <ng-container *ngIf="incident?.date_incident">
        <h6>Date of Incident</h6>
        <p>{{ (incident.date_incident * 1000) | timezoneConvertLong }}</p>
      </ng-container>

      <ng-container *ngIf="incident?.details">
        <h6>Summary of Incident</h6>
        <p>{{ incident.details }}</p>
      </ng-container>

      <ng-container *ngIf="incident?.type">
        <h6>Type of Incident</h6>
        <p>{{ incident.type }}</p>
      </ng-container>

      <ng-container *ngIf="incident?.type === 'Illness'">
        <h6>Illness Type</h6>
        <p>{{ incident.illness_type }}</p>
      </ng-container>

      <ng-container *ngIf="incident?.type === 'Injury'">
        <h6>Injury Type</h6>
        <p>{{ incident.injury_type }}</p>
      </ng-container>

      <ng-container *ngIf="incident?.type === 'Injury' && incident?.body_parts !== ''">
        <h6>Body Parts</h6>
        <p>{{ incident.body_parts }}</p>
      </ng-container>

      <ng-container *ngIf="created_by_user">
        <h6>Incident Reported By</h6>
        <p><b>Full Name</b>: {{ created_by_user.contact_person }}</p>
        <p><b>Email Address</b>: {{ created_by_user.email }}</p>
        <p><b>Mobile</b>: {{ created_by_user.mobile }}</p>
      </ng-container>

      <ng-container *ngIf="incident.files?.length">
        <h6>Files</h6>
        <app-files-list [view]="true" [files]="incident.files"></app-files-list>
      </ng-container>

      <ng-container *ngIf="incident.users?.length">
        <h6>Affected Users ({{incident.users?.length}})</h6>
        <ul>
          <li *ngFor="let user of incident.users">{{ user.contact_person }}</li>
        </ul>
      </ng-container>
    </div>
  </div>
</div>
