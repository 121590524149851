import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {NgForm} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TagModel} from "../../models/tag.model";

@Component({
  selector: 'app-account-tags-edit',
  templateUrl: './account-tags-edit.component.html',
  styleUrls: ['./account-tags-edit.component.scss']
})
export class AccountTagsEditComponent implements OnInit {

  inProgress = false;

  constructor(
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public tag: TagModel,
    private dialogRef: MatDialogRef<AccountTagsEditComponent>,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    if(this.tag.id) {
      this.find(this.tag.id);
    }
  }

  onSubmit(form: NgForm) {
    // Check if the button was already pressed.
    if ( this.inProgress ) {
      return;
    }

    // Check form validation.
    if ( !form.valid ) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    this.inProgress = true;

    !this.tag.id ? this.create() : this.update();
  }

  private find(tag_id: number) {
    this.api.makeRequest('get', `v2/tags/${tag_id}`)
      .then((response) => {
        this.tag = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onDelete() {
    this.utils.showModal(
      'Remove Tag',
      `Are you sure you want to archive "${this.tag.title}"?`,
      () => {
        this.api.makeRequest('delete', `v2/tags/${this.tag.id}`)
          .then((response) => {
            this.utils.showToast(`"${this.tag.title}" has been archived.`);
            this.dialogRef.close(true);
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  private update() {
    this.api
      .makeRequest('put', `v2/tags/${this.tag.id}`, this.tag)
      .then((response) => {
        this.tag = response.data;
        this.onSuccess(`Updated: "${response.data.title}"`);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      })
      .finally(() => (this.inProgress = false));
  }

  private create() {
    this.api
      .makeRequest('post', 'v2/tags', this.tag)
      .then((response) => {
        this.tag = response.data;
        this.onSuccess(`New Tags "${response.data.title}" created.`);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      })
      .finally(() => this.inProgress = false);
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close(this.tag);
  }

}
