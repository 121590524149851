import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment-timezone';
import {UtilsService} from "../utils.service";

@Component({
  selector: 'app-timezone-select',
  templateUrl: './timezone-select.component.html',
  styleUrls: ['./timezone-select.component.scss']
})

export class TimezoneSelectComponent implements OnInit {
  @Input() timezoneInput: string;
  @Output() timezoneOutput: EventEmitter<any> = new EventEmitter<any>();
  originalList: TimezoneModel[] = [];
  filteredList: TimezoneModel[] = [];

  constructor(
    private utils: UtilsService
  ) { }

  ngOnInit() {

    // Use UTC as the default timezone for SetConnect.
    if (this.utils.getAppName() === 'SetConnect'){
      this.timezoneInput = 'UTC';
    }

    this.getAllTimezonesWithUTC();
    //
    this.filteredList = this.originalList;
  }

  // get all timezones and add UTC in the front
  getAllTimezonesWithUTC() {
    let timeZones = moment.tz.names();

    for (let timezone in timeZones) {
      this.originalList.push({
        offset: moment.tz(timeZones[timezone]).format('Z'),
        offsetString: '(UTC' + moment.tz(timeZones[timezone]).format('Z') + ')',
        timezone: timeZones[timezone]
      });
    }

    this.originalList.sort(function(a, b) {
      return parseFloat(b.offset.replace(':', '.')) - parseFloat(a.offset.replace(':', '.'));
    })
  }

  onKey(value) {
    this.filteredList = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.originalList.filter(option => option.timezone.toLowerCase().includes(filter));
  }

  submit($event) {
    this.timezoneOutput.emit($event.option.value);
  }

}

export class TimezoneModel {
  offset: string
  offsetString: string;
  timezone: string;
}
