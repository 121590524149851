<div mat-dialog-title class="position-relative w-100">
    <h5>Import Tasks</h5>

    <button mat-icon-button class="position-absolute" matTooltip="Close Filter Tasks" mat-dialog-close style="top: -10px;right: 0">
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </div>

  <form id="file-upload-form" class="uploader d-flex flex-column align-items-center" appDragAndDrop  *ngIf="actionsDataAfter.length === 0" (fileDropped)="onFileChange($event)">
    <input id="file-upload" type="file" name="fileUpload" accept=".xls,.xlsx,.csv" (change)="onFileChange($event.target.files[0])"/>
    <div id="file-drag" class="upload-container">
      <div id="start">
        <div>{{(selectedFile && fileIsExcel(selectedFile.name)) ? selectedFile.name : 'Select a file or drag & drop it here. You can upload a CSV, XLS or XLSX file.'}}</div><br>
        <div><strong>Please make sure your file has less than 100 rows of data.</strong></div>
        <span id="file-upload-btn" class="select btn-primary">Select a file</span>
      </div>
    </div>
    <div>
      <mat-form-field *ngIf="sheetNames">
        <mat-label>Excel Sheets</mat-label>
        <mat-select>
          <mat-option *ngFor="let sheet of sheetNames" (click)="excelSheetSelected(sheet)" [value]="sheet">
            {{ sheet }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button *ngIf="sheetNames" [disabled]="actionsDataBefore.length === 0" mat-raised-button color="primary"  (click)="onConfirm()" class="margin-left">
        Confirm
      </button>
    </div>

  </form>

  <div class=" mx-1 my-2 py-2 "  *ngIf="actionsDataAfter.length !== 0">
    Only the first 100 tasks will be imported. Please split your task lists into files of 100 tasks each if you have more than 100 tasks to import.
    <div class="d-flex align-items-center">
      <mat-form-field class="margin-right">
        <input
          matInput
          (dateInput)="onAllDateChange($event.value)"
          [matDatepicker]="picker"
          placeholder="Due Date"
          name="due"
          #due
          readonly
          (click)="picker.open()"
        />
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="margin-right">
        <mat-select style="min-width: 100px;" matInput (selectionChange)="onAllPriorityChange($event.value)" name="priority" placeholder="Priority">
          <mat-option *ngFor="let priority of priorities" [value]="priority">
            {{priority}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-timezone-select (timezoneOutput)="onAllTimezoneChange($event)"></app-timezone-select>
      <button
        mat-raised-button
        type="button"
        (click)="onAllSelectSite()"
        class="margin-right margin-left"
        color="secondary">
        <mat-icon>location_on</mat-icon>
        {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
      </button>

      <button
        mat-raised-button
        type="button"
        (click)="onAllAddUsers()"
        class="margin-right"
        color="secondary">
        <mat-icon>account_circle</mat-icon>
        Add Users
      </button>
      <span *ngIf="selection.selected.length !== 0">{{selection.selected.length + ' tasks selected'}}</span>
    </div>
  </div>

  <div class="mat-elevation-z8 scroll-box" *ngIf="actionsDataAfter.length !== 0" style="max-height: 65vh; overflow-y: auto;">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="task_name">
        <th mat-header-cell *matHeaderCellDef> Task Name </th>
        <td mat-cell *matCellDef="let action">
          <mat-form-field>
            <input matInput placeholder="Task" [(ngModel)]="action.name"
              name="name" type="text" required #name
            />
          </mat-form-field>
      </td>
      </ng-container>

      <ng-container matColumnDef="timezone">
        <th mat-header-cell *matHeaderCellDef> Timezone </th>
        <td mat-cell *matCellDef="let action" (click)="action.changeTz = true"> <div *ngIf="!action.changeTz">{{action.timezone}}</div>
          <app-table-timezone-select *ngIf="action.changeTz" [timezoneInput]="action.timezone" (timezoneOutput)="action.timezone = $event"></app-table-timezone-select>
        </td>
      </ng-container>

      <ng-container matColumnDef="due_date">
        <th mat-header-cell *matHeaderCellDef> Due Date </th>
        <td mat-cell *matCellDef="let action">
          <mat-form-field>
            <input
              matInput
              (dateInput)="onDateChange($event.value, action)"
              [matDatepicker]="picker"
              placeholder="Due Date"
              [(ngModel)]="action.due"
              name="due"
              #due
              readonly
              (click)="picker.open()"
            />
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef> Priority </th>
        <td mat-cell *matCellDef="let action">
          <mat-select style="min-width: 100px;" matInput [(ngModel)]="action.priority" name="priority">
            <mat-option *ngFor="let priority of priorities" [value]="priority">
              {{priority}}
            </mat-option>
          </mat-select>
        </td>
      </ng-container>

      <ng-container matColumnDef="individual">
        <th mat-header-cell *matHeaderCellDef> Individual </th>
        <td mat-cell *matCellDef="let action">
          <mat-slide-toggle [(ngModel)]="action.individual_actions"
            name="individual-actions">
          </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let action">
          <button
            mat-raised-button
            type="button"
            (click)="onSelectSite(action)"
            [matBadge]="'' + (action.site_id ? 1 : 0)"
            class="margin-right"
            color="secondary">
            <mat-icon>location_on</mat-icon>
            Site
          </button>

          <button
            mat-raised-button
            type="button"
            (click)="onAddUsers(action)"
            [matBadge]="action.user_ids?.length"
            class="margin-right"
            color="secondary">
            <mat-icon>account_circle</mat-icon>
            Add Users
          </button>
          <!-- <button type="button" mat-button (click)="onAddFiles(action)" matTooltip="Add files to the task." matTooltipPosition="above">
            Add Files
          </button> -->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 20, 40]" showFirstLastButtons></mat-paginator>
  </div>

  <div class="d-flex justify-content-end my-2" *ngIf="actionsDataAfter.length !== 0">
    <button
      [disabled]="inProgress"
      type="button"
      mat-raised-button
      class="float-right"
      color="primary"
      (click)="onSaveAndClose()">
      <mat-icon>save</mat-icon> Save
    </button>

  </div>
