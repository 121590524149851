import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrequalificationModel } from 'src/app/models/prequalification.model';
import { UtilsService } from 'src/app/shared/utils.service';
import { PrequalificationService } from '../prequalification.service';
import { NetworkedAccountsSelectorComponent } from 'src/app/shared/networked-accounts-selector/networked-accounts-selector.component';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-prequalifications-view',
  templateUrl: './prequalifications-view.component.html',
  styleUrls: ['./prequalifications-view.component.scss']
})
export class PrequalificationsViewComponent implements OnInit {

  prequal = new PrequalificationModel();

  selectedContractors: number[] = [];

  constructor(
    public route: ActivatedRoute,
    private location: Location,
    private prequalService: PrequalificationService,
    private router: Router,
    public utils: UtilsService,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.prequal.id = Number(this.route.snapshot.params['id']);
    this.prequalService.find(this.prequal.id).subscribe((response) => {
      this.prequal = response.data;
    });
  }

  delete() {
    this.prequalService
      .remove([this.prequal.id])
      .subscribe((prequal: PrequalificationModel) => {
        this.utils.showToast(`Deleted: "${prequal.name}".`);
        this.router.navigate([
          'prequalifications',
          {
            contractorId: this.prequal.contractor.id
          }
        ]);
      });
  }

  onCopyToContractors() {
    this.utils.showComponentDialog(
      NetworkedAccountsSelectorComponent,
      {
        selected: this.selectedContractors,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.selectedContractors = results;
          if ( this.selectedContractors.length > 0 ) {
            this.utils.showModal(
              'Confirmation',
              'Are you sure you would like to copy this pre-qualification to the selected ' + this.utils.getLangTerm('contractors.plural', 'Contractors') + '? For safety, existing '  + this.utils.getLangTerm('contractors.singular', 'Contractor') + ' Pre-Qualifications will not be replaced.',
              () => {
                this.api.makeRequest('post', 'copy-prequalification-to-contractors', {
                  preQualificationId: this.prequal.id,
                  contractorIds: this.selectedContractors
                }).then((response) => {
                  this.utils.showToast('The Pre-Qualification was copied to the selected ' + this.utils.getLangTerm('contractors.plural', 'Contractors') + '.');
                });
              }
            );
          }
        }
      }
    );
  }

  back() {
    this.location.back();
  }
}
