<div mat-dialog-title>
  <div class="d-flex">
    <h3>
      Accounts {{ dataSource.multiple ? '(' + dataSource.selection.selected.length + ')' : '' }}
    </h3>

    <span style="flex: 1 1 auto;"></span>

    <button mat-raised-button (click)="onSelectSingle(null)" *ngIf="onCanCreateNewAccount()" appMarginRight color="primary">
      <mat-icon>add</mat-icon> New Account
    </button>

    <form
      class="form-inline my-2 my-lg-0"
      (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2"
        type="search"
        [(ngModel)]="dataSource.search"
        (input)="dataSource.getData(true)"
        name="search"
        placeholder="Search Accounts"
        aria-label="Search Accounts" />
    </form>

    <button mat-raised-button (click)="dataSource.getData(true)" appMarginRight>
      <mat-icon>search</mat-icon> Search
    </button>
  </div>
</div>

<mat-dialog-content>
  <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

    <caption class="px-3 text-wrap">
      <i>
        Enter your employer's name in the search field above and click search.
        You can select your employer from the list of results.
        If no results are shown, enter your full employer's name in the search field above and click the "New Account" button.
        Click outside the dialog to close it if you already have your employer selected.
      </i>
    </caption>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Joined</th>
      <td mat-cell *matCellDef="let element">{{ (element.date_created * 1000) | timezoneConvertMedium }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-raised-button
          color="primary"
          (click)="onSelectSingle(element.id)"
          *ngIf="!this.dataSource.isSelected(element.id); else alreadySelected">
          Select
        </button>
        <ng-template #alreadySelected>
          Already Selected
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="dataSource.toggleRowSelection(row.id)"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <mat-paginator #paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>

  <button mat-raised-button appMarginRight color="primary"
    (click)="onSelectMultiple()"
    *ngIf="data['multiple']">
    Select
  </button>

  <button mat-raised-button color="secondary"
    (click)="onDeselectAllRecords()"
    *ngIf="dataSource.selection.selected.length > paginator.pageSize">
    Deselect All
  </button>
</mat-dialog-actions>
