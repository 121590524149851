import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { CustomDataSource } from '../../utils/custom-data-source';
import { CoursesEditComponent } from '../courses-edit/courses-edit.component';
import { CourseModel } from 'src/app/models/course.model';
import { CoursesViewComponent } from '../courses-view/courses-view.component';
import {CoursesReportComponent} from "../courses-report/courses-report.component";
import {ChartsUtilsService} from "../../charts/charts-utils.service";

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'enabled',
    'assigned_users_count',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];
  dataSource = new CoursesDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    private dialog: MatDialog,
    public chartsUtils: ChartsUtilsService
  ) {}

  ngOnInit() {
    // this.service.get(0, true);
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns by their corresponding date columns.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onEdit(new CourseModel());
  }

  onEdit(course: CourseModel) {
    this.dialog
      .open(CoursesEditComponent, {
        width: '900px',
        data: { course: {...course} }
      })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }

  onView(course: CourseModel) {
    this.dialog
      .open(CoursesViewComponent, {
        width: '900px',
        data: course
      })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove Induction',
      'Are you sure you want to remove this induction?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          `inductions/${id}`,
          {},
          {},
          (response) => {
            this.dataSource.selection.deselect(id);
            this.utils.showToast('The induction was removed.');
            this.dataSource.getData();
          }
        );
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Remove Selected Induction',
      'Are you sure you want to remove the selected inductions?',
      () => {
        const selectedInductions = this.dataSource.selection.selected.join(',');
        this.api.laravelApiRequest(
          'delete',
          `inductions/${selectedInductions}`,
          {},
          {},
          (response) => {
            this.dataSource.selection.clear();
            this.utils.showToast('The selected inductions was removed.');
            this.dataSource.getData();
          }
        );
      }
    );
  }

  onViewReport(course: CourseModel) {
    this.dialog
      .open(CoursesReportComponent, {
        width: '1024px',
        data: course
      })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }
}

export class CoursesDataSource extends CustomDataSource {
  // record sorting and direction
  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('inductions', resetOffset);
  }
}
