import { SiteModel } from './site.model';
import { FileModel } from '../shared/file.model';
import { UserModel } from './user.model';
import { IncidentCauseModel } from '../incidents/incident-cause.model';
import { Auditable } from '../shared/auditable.model';

export class IncidentLtiTimeRecordModel extends Auditable {

  incident_lti_time_record_id: number = 0;
  incident_lti_id: number = 0;
  actual_lti_date: number = 0;
  actual_lti_days: number = 0;
  actual_lti_hours: number = 0;
  actual_lti_comments: string = '';
  deleted: boolean = false;
  date_created: number = 0;
  date_modified: number = 0;
  deleted_at: number = 0;
  created_by: number = 0;
  updated_by: number = 0;
  deleted_by: number = 0;

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.incident_lti_time_record_id = 0;
    this.incident_lti_id = 0;
    this.actual_lti_date = 0;
    this.actual_lti_days = 0;
    this.actual_lti_hours = 0;
    this.actual_lti_comments = '';
    this.deleted = false;
    this.date_created = 0;
    this.date_modified = 0;
    this.deleted_at = 0;
    this.created_by = 0;
    this.updated_by = 0;
    this.deleted_by = 0;
  }
}
