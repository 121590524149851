import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../models/api-response.model';
import { FileModel } from './file.model';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private readonly resourceUrl = 'files';

  constructor(private api: ApiService) {}

  private createFromForm(file: File) {
    const form = new FormData();
    form.append('file', file, file.name);
    return this.api
      .laravelApiObservable('post', 'files/form', form)
      .toPromise();
  }

  public async createManyFromForm(files: FileList) {
    if (!files || !files.length) { return []; }
    const requests = [];
    for(let i = 0; i < files.length; i++) {
      requests.push(this.createFromForm(files[i]));
    }
    const responses = await Promise.all(requests);
    const createdFiles = responses.map(response => response.data);
    return createdFiles;
  }

  public findAll(query?: {
    folder_id: number;
  }): Observable<ApiResponseModel<FileModel[]>> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl,
      {},
      {},
      query
    );
  }

  /**
   * Stores a File from a base64 encoding.
   *
   * @deprecated
   */
  public create(file: FileModel): Promise<ApiResponseModel<FileModel>> {
    return this.api
      .laravelApiObservable('post', this.resourceUrl, file).toPromise();
  }

  /**
   * Creates many base64 encoded files.
   *
   * @deprecated
   */
  public async createMany(
    files: FileModel[]
  ) {
    const requests = files.map(file => this.create(file));
    const responses = await Promise.all(requests);
    const createdFiles = responses.map(response => response.data);
    return createdFiles;
  }

  /**
   * @deprecated
   */
  public createForHazardousSubstance(
    hazardousSubstanceId: number,
    file: FileModel
  ) {
    return this.api.laravelApiObservable(
      'post',
      this.resourceUrl + `/hazardous-substances/${hazardousSubstanceId}`,
      file
    );
  }

  /**
   * @deprecated
   */
  public createForHazard(hazardId: number, file: FileModel) {
    return this.api.laravelApiObservable(
      'post',
      this.resourceUrl + `/hazards/${hazardId}`,
      file
    );
  }

  public findAllForHazard(
    hazardId: number
  ): Observable<ApiResponseModel<FileModel[]>> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl + `/hazards/${hazardId}`
    );
  }

  public findAllForHazardousSubstance(
    hazardousSubstanceId: number
  ): Observable<ApiResponseModel<FileModel[]>> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl + `/hazardous-substances/${hazardousSubstanceId}`
    );
  }

  public remove(id: number): Observable<{}> {
    return this.api.laravelApiObservable('delete', `${this.resourceUrl}/${id}`);
  }

  /**
   * @deprecated
   */
  public getBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}
