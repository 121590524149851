<div mat-dialog-title>
  <h5>Incident Witness Form</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form #form="ngForm">
    <mat-form-field>
      <input matInput placeholder="Witness Full Name"
        [(ngModel)]="witness.full_name" required name="full_name" type="text"
        #fullName matTooltip="The full name of the witness" />
      <mat-error *ngIf="!fullName.valid">Please enter the witnesses full name.
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Company Name"
        [(ngModel)]="witness.company_name" name="company_name" type="text"
        matTooltip="The name of the company the witness works for" />
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Email" [(ngModel)]="witness.email"
        name="email" type="email"
        matTooltip="The email address of the witness" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ utils.getLangTerm('users.form.mobile', 'Mobile Number') }}</mat-label>
      <app-phone-input
        [idInput]="phone_input_id"
        [numberInput]="witness.phone"
        [countryCodeInput]="witness.phone_country_code"
        [dialCodeInput]="witness.phone_dial_code"
        [e164NumberInput]="witness.phone_e164"
        (phoneDetailsOutput)="getOutputPhoneDetails($event)"
        required="false">
      </app-phone-input>
    </mat-form-field>

    <mat-form-field>
      <textarea matInput placeholder="Statement" [(ngModel)]="witness.statement"
        name="statement" type="text" rows="10"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button [disabled]="!form.valid || inProgress || phone_error_state"
    color="primary" id="save" class="margin-right" (click)="onSubmit()">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <app-files-selector (selected)="newFiles = $event" [selectedFilesCount]="newFiles?.length" class="margin-right">
  </app-files-selector>

  <button mat-raised-button (click)="onRemoveFiles()" color="secondary"
    [matBadge]="witness.files?.length" [disabled]="!witness.files?.length"
    class="margin-right">
    <mat-icon>open_in_browser</mat-icon>
    Remove Files
  </button>

  <button mat-raised-button color="secondary" (click)="onSelectWitness()"
    matTooltip="If the user exists in our system, this can fill in the details for you."
    [matBadge]="witness.user_id ? 1 : 0">
    <mat-icon>person</mat-icon>
    Select Witness
  </button>

  <span class="mr-auto"></span>

  <button type="button" mat-icon-button [disabled]="!witness.user_id"
    class="float-right" (click)="onDelete()" matTooltip="Delete this Witness">
    <mat-icon>delete</mat-icon>
  </button>
</mat-dialog-actions>
