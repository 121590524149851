<div mat-dialog-title class="position-relative w-100">
  <h5>Dashboard Filters</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close this dialog." [mat-dialog-close]="false" style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="pt-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="mx-3">
          <button mat-raised-button color="secondary" type="button" class="mb-3" (click)="onSelectSites()" [matBadge]="'' + (filter_options.site_ids && filter_options.site_ids.length ? filter_options.site_ids.length : '')" matTooltip="Select from a list of {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} in your account.">{{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-date-range-picker dateRangeTitle="Select Date Range" dateRangePlaceholder="Select Date Range" dateRangeTooltip="Filter stats by date range." [dateRangeInput]="filter_options.date_range" (dateRangeOutput)="getOutputDateRange($event)"></app-date-range-picker>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-center my-3">
  <button type="button" mat-raised-button color="primary" (click)="onSaveAndClose()">
    <mat-icon>filter_list</mat-icon> Apply Filter
  </button>

  <button type="button" mat-raised-button color="secondary" (click)="onClearAndClose()">
    <mat-icon> clear_all</mat-icon> Clear Filter
  </button>
</mat-dialog-actions>
