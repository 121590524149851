import {Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, Renderer2} from '@angular/core';
import { UtilsService } from 'src/app/shared/utils.service';
import { FileManagerComponent } from '../file-manager/file-manager.component';

@Component({
  selector: 'app-file-browse-button',
  templateUrl: './file-browse-button.component.html',
  styleUrls: ['./file-browse-button.component.scss']
})
export class FileBrowseButtonComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;

  // Parent object type and id to handle file uploads.
  @Input('parentObjectType') parentObjectType: string = '';
  @Input('parentObjectId') parentObjectId: number = 0;
  @Input('parentRemove') parentRemove: boolean = false;
  @Input('parentUpload') parentUpload: boolean = false;

  // Input for a file count.
  @Input('filesCount') filesCount: number = 0;

  // Event that gets triggered when files are selected.
  @Output() selectedFiles = new EventEmitter<FileList>();

  // Event that gets triggered when the dialog closes.
  @Output() afterDialogClosedEvent = new EventEmitter<FileList>();

  // Event that gets triggered when a file removed.
  @Output() afterFileRemove = new EventEmitter<FileList>();

  // Enable/Disable the button.
  @Input('disabled') disabled: boolean = false;

  constructor(
    private utils: UtilsService,
    public renderer: Renderer2
  ) { }

  ngOnInit() { }

  /**
   * Send the selected files back to the caller.
   * @param event Event object to extract files from.
   */
  onSelectFiles(event: any) {
    this.filesCount = event.target.files.length;
    this.selectedFiles.emit(event.target.files);
  }

  /**
   * Trigger the file input event.
   * @param evt
   */
  onTriggerFileSelector(evt: Event) {
    if(this.fileInput) {
      const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
      this.fileInput.nativeElement.dispatchEvent(clickEvt);
    }
  }

  /**
   * Open the file manager dialog to handle file uploads.
   */
  onManageFiles() {
    this.utils.showComponentDialog(FileManagerComponent, {
      parentObjectType: this.parentObjectType,
      parentObjectId: this.parentObjectId,
      parentRemove: this.parentRemove,
      parentUpload: this.parentUpload
    }, {}, (result: any) => {
      if(result > 0 && this.parentRemove) {
        this.afterFileRemove.emit(result);
      } else if(result && result.length > 0 && this.parentUpload) {
        this.selectedFiles.emit(result);
      } else {
        this.afterDialogClosedEvent.emit(null);
      }
    });
  }

}
