<div mat-dialog-title class="position-relative w-100">
  <h5>Filter Incidents</h5>

  <button mat-icon-button type="button" class="position-absolute" matTooltip="Close Incidents Filter" (click)="onSaveAndClose()" style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">

  <ng-container matColumnDef="sites">
    <div style="padding: 5px !important;">
      <button mat-raised-button type="button" (click)="onSelectSites()" [matBadge]=" '' + (filters.site_ids.length || 'All')" class="mx-3 mt-3">
        <mat-icon>location_on</mat-icon> {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="users">
    <div style="padding: 5px !important;">
      <button mat-raised-button type="button" (click)="onSelectUsers()" [matBadge]="'' + (filters.user_ids.length || 'All')" class="mx-3 mt-3">
        <mat-icon>account_circle</mat-icon> Affected Users
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="type">
    <div style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-2" style="width: 180px;">
        <mat-select placeholder="Type" class="filter-dropdowns" [(ngModel)]="filters.type" matTooltip="Filter incidents by type." name="type">
          <mat-option value="">All</mat-option>
          <mat-option *ngFor="let incident_type of utils.getIncidentTypes()" [value]="incident_type">{{ incident_type }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container matColumnDef="status">
    <div style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-2" style="width: 100px;">
        <mat-select placeholder="Status" class="filter-dropdowns" [(ngModel)]="filters.status" matTooltip="Filter incidents by status." name="status">
          <mat-option value="">All</mat-option>
          <mat-option [value]="status.toLowerCase()" *ngFor="let status of utils.getIncidentStatuses()">{{ status }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <app-date-range-picker dateRangeTitle="Select Date Range" dateRangePlaceholder="Select Date Range" dateRangeTooltip="Filter incidents by date range." [dateRangeInput]="filters.date_range" (dateRangeOutput)="getOutputDateRange($event)"></app-date-range-picker>

</div>

<div class="d-flex justify-content-center my-2 py-2">
  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button type="button" mat-raised-button color="primary" (click)="onSaveAndClose()">
        <mat-icon>filter_list</mat-icon> Apply Filters
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button type="button" mat-raised-button color="secondary" (click)="onClearAndClose()">
        <mat-icon>clear_all</mat-icon> Clear Filters
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="buttons" *ngIf="filters.date_range.length == 2">
    <div class="p-2">
      <button type="button" mat-raised-button color="secondary" (click)="onClearDateRange()">
        <mat-icon>clear</mat-icon> Clear Date Range
      </button>
    </div>
  </ng-container>
</div>
