<div mat-dialog-title>
  <h5>Induction Form</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)" appMargin>
    <mat-form-field>
      <input
        matInput
        placeholder="Name"
        [(ngModel)]="induction.name"
        name="name"
        type="text"
        #name
        required />
      <mat-error *ngIf="!name.valid">Please enter an induction name.</mat-error>
    </mat-form-field>

    <editor
      placeholder="Course Introduction"
      [(ngModel)]="induction.description"
      name="description"
      [init]="{
        skin_url: '/assets/skins/lightgray',
        plugins: 'link,image,lists,hr,table,media',
        height: '450'
      }">
    </editor>
    <mat-error *ngIf="!induction.description">
      Please fill in the Induction.
    </mat-error>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    (click)="onSubmit(f)"
    mat-raised-button
    style="max-width: 100px;"
    [disabled]="!f.valid || inProgress || !induction.description"
    color="primary"
    class="save-button"
    id="save">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <mat-slide-toggle class="mx-2 mt-2" *ngIf="induction.id" [(ngModel)]="induction.induction_enabled"
                    matTooltip="This will allow users to see the induction."
                    name="induction_enabled">
    Induction is now <b>{{ induction.induction_enabled ? 'enabled' : 'disabled' }}</b>.
  </mat-slide-toggle>

  <mat-slide-toggle class="mx-2 mt-2" *ngIf="induction.id && induction.induction_enabled" [(ngModel)]="induction.reset_status"
    matTooltip="This will reset the induction status for all your staff, {{ utils.getLangTerm('contractors.plural', 'Contractors') }} and their {{ utils.getLangTerm('employees.plural', 'Employees') }} resulting in incomplete induction statuses for this induction. They will need to complete and submit this induction again."
    name="reset-status">
    Reset induction statuses
  </mat-slide-toggle>

  <span class="mr-auto"></span>
  <button
    mat-icon-button
    [disabled]="!induction.id"
    class="float-right"
    (click)="onRemove()">
    <mat-icon>delete</mat-icon>
  </button>
</mat-dialog-actions>
