<div mat-dialog-title>
  <h5>View a Module</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <h6>Module Name</h6>
  <p>{{ module.name }}</p>

  <h6>Module Introduction</h6>
  <div [innerHTML]="module.description | sanitizeHtml"></div>
</mat-dialog-content>

