<ng-template #containerHeader>
  <ng-container *ngIf="config.isPanelExpanded">
    <div class="row">
      <div class="col">
        <div class="pl-3">
          <h4 style="line-height: 58px;">Dashboard</h4>
        </div>
      </div>
      <div class="col">
        <div class="d-flex justify-content-end" style="margin-left: auto;" [ngClass]="{ 'non-expansion-panel-container': disableExpansionPanel }">
          <div class="charts-controls-hover-effects">
            <button mat-icon-button [ngClass]="{ 'inline-mat-panel-title-icon-button': !disableExpansionPanel }" [matMenuTriggerFor]="menu" (click)="onStopEventPropagation($event)" matTooltip="Add a row to show containers in it."><mat-icon>add</mat-icon></button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [value]="1" (click)="onAddRow(1)">Row with 1 Column</button>
              <button mat-menu-item [value]="2" (click)="onAddRow(2)">Row with 2 Columns</button>
            </mat-menu>

            <!--      <button mat-icon-button [ngClass]="{ 'inline-mat-panel-title-icon-button': !disableExpansionPanel }" (click)="onDownloadAllCharts($event)" *ngIf="doesRowsHaveDownloadableCharts()" matTooltip="Download all of the chart images.">-->
            <!--        <mat-icon>download</mat-icon>-->
            <!--      </button>-->

            <button mat-icon-button [ngClass]="{ 'pulsating-filter-button': !isFiltersEmptyExcluding(), 'inline-mat-panel-title-icon-button': !disableExpansionPanel }" (click)="onFilter($event)" matTooltip="Click this button to display filter options.">
              <mat-icon>filter_list</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #containerContent>
  <div cdkDropList cdkDropListOrientation="vertical" (cdkDropListDropped)="onDragAndDrop($event)">
    <mat-card *ngFor="let row of config.rows; let i = index" cdkDrag>
      <mat-card-content *ngIf="config.isPanelExpanded">
        <div class="row pt-2 pb-2" style="background: white; border-bottom: silver;" >
          <div class="col-12 d-flex justify-content-end bg-light py-2 mb-2">
            <div class="charts-controls-hover-effects">
              <select class="form-select mr-3" [(ngModel)]="row.cols" (change)="onChangeColsConfig(i)" matTooltip="Change the number of columns for this row. Remove excess containers to decrease the number of columns if options are missing.">
                <option [value]="1" *ngIf="row.containers.length < 2">1 Column</option>
                <option [value]="2">2 Columns</option>
              </select>

              <button mat-icon-button cdkDragHandle matTooltip="Drag and drop the row to reorder the list.">
                <mat-icon>drag_indicator</mat-icon>
              </button>

              <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Add containers to this row from a selection of available containers."><mat-icon>add</mat-icon></button>
              <mat-menu #menu="matMenu">
                <ng-container *ngFor="let availableContainer of availableContainers">
                  <button mat-menu-item *ngIf="!doesRowsHaveLegacyWidget(availableContainer.container)" [value]="availableContainer.container" (click)="onAddContainer(availableContainer.container, i)">{{ availableContainer.containerName }}</button>
                </ng-container>
              </mat-menu>

              <!--          <button mat-icon-button (click)="onDownloadRowOfCharts(i)" *ngIf="doesRowHaveDownloadableCharts(i)" matTooltip="Download this row of chart images.">-->
              <!--            <mat-icon>download</mat-icon>-->
              <!--          </button>-->

              <button mat-icon-button color="warn" class="mr-3" (click)="onRemoveRow($event, i)" matTooltip="Remove this row.">
                <mat-icon>close</mat-icon>
              </button>
            </div>

          </div>

          <div [class]="getColsConfig(row.cols)" *ngFor="let container of row.containers; let j = index">
            <ng-container [ngSwitch]="container.container">
              <app-incidents-charts-container *ngSwitchCase="'IncidentsChartsContainerComponent'" (referenceEvent)="onStoreContainerRef($event, i, j)" [show_remove_container_button]="true" (removeContainer)="onRemoveContainer($event, i, j)" [parent_filters]="config.filters" [baseStorageKey]="configStorageKey + '-' + container.id" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-incidents-charts-container>
              <app-safety-observations-charts-container *ngSwitchCase="'SafetyObservationsChartsContainerComponent'" (referenceEvent)="onStoreContainerRef($event, i, j)" [show_remove_container_button]="true" (removeContainer)="onRemoveContainer($event, i, j)" [parent_filters]="config.filters" [baseStorageKey]="configStorageKey + '-' + container.id" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-safety-observations-charts-container>
              <app-dynamic-forms-charts-container *ngSwitchCase="'DynamicFormsChartsContainerComponent'" (referenceEvent)="onStoreContainerRef($event, i, j)" [show_remove_container_button]="true" (removeContainer)="onRemoveContainer($event, i, j)" [parent_filters]="config.filters" [baseStorageKey]="configStorageKey + '-' + container.id" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-dynamic-forms-charts-container>
              <app-site-access-activities-charts-container *ngSwitchCase="'SiteAccessActivitiesChartsContainerComponent'" (referenceEvent)="onStoreContainerRef($event, i, j)" [show_remove_container_button]="true" (removeContainer)="onRemoveContainer($event, i, j)" [parent_filters]="config.filters" [baseStorageKey]="configStorageKey + '-' + container.id" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-site-access-activities-charts-container>
              <app-site-audits-charts-container *ngSwitchCase="'SiteAuditsChartsContainerComponent'" (referenceEvent)="onStoreContainerRef($event, i, j)" [show_remove_container_button]="true" (removeContainer)="onRemoveContainer($event, i, j)" [parent_filters]="config.filters" [baseStorageKey]="configStorageKey + '-' + container.id" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-site-audits-charts-container>
              <app-tasks-charts-container *ngSwitchCase="'TasksChartsContainerComponent'" (referenceEvent)="onStoreContainerRef($event, i, j)" [show_remove_container_button]="true" (removeContainer)="onRemoveContainer($event, i, j)" [parent_filters]="config.filters" [baseStorageKey]="configStorageKey + '-' + container.id" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-tasks-charts-container>
              <app-inductions-charts-container *ngSwitchCase="'InductionsChartsContainerComponent'" (referenceEvent)="onStoreContainerRef($event, i, j)" [show_remove_container_button]="true" (removeContainer)="onRemoveContainer($event, i, j)" [parent_filters]="config.filters" [baseStorageKey]="configStorageKey + '-' + container.id" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-inductions-charts-container>
              <app-sites-charts-container *ngSwitchCase="'SitesChartsContainerComponent'" (referenceEvent)="onStoreContainerRef($event, i, j)" [show_remove_container_button]="true" (removeContainer)="onRemoveContainer($event, i, j)" [parent_filters]="config.filters" [baseStorageKey]="configStorageKey + '-' + container.id" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-sites-charts-container>
              <app-hazards-charts-container *ngSwitchCase="'HazardsChartsContainerComponent'" (referenceEvent)="onStoreContainerRef($event, i, j)" [show_remove_container_button]="true" (removeContainer)="onRemoveContainer($event, i, j)" [parent_filters]="config.filters" [baseStorageKey]="configStorageKey + '-' + container.id" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-hazards-charts-container>
              <app-messages-charts-container *ngSwitchCase="'MessagesChartsContainerComponent'" (referenceEvent)="onStoreContainerRef($event, i, j)" [show_remove_container_button]="true" (removeContainer)="onRemoveContainer($event, i, j)" [parent_filters]="config.filters" [baseStorageKey]="configStorageKey + '-' + container.id" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-messages-charts-container>
              <app-toolbox-talks-charts-container *ngSwitchCase="'ToolboxTalksChartsContainerComponent'" (referenceEvent)="onStoreContainerRef($event, i, j)" [show_remove_container_button]="true" (removeContainer)="onRemoveContainer($event, i, j)" [parent_filters]="config.filters" [baseStorageKey]="configStorageKey + '-' + container.id" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-toolbox-talks-charts-container>
              <app-user-inductions-widget *ngSwitchCase="'UserInductionsWidgetComponent'"></app-user-inductions-widget>
              <app-legacy-dashboard-widget *ngSwitchCase="'LegacyDashboardWidgetComponent'"></app-legacy-dashboard-widget>
              <div *ngSwitchDefault></div>
            </ng-container>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>

<mat-accordion *ngIf="!disableExpansionPanel; else basicContainer">
  <mat-expansion-panel [expanded]="config.isPanelExpanded" #expansionPanel (opened)="onStoreChartsPanelExpandedState(expansionPanel.expanded)"  (closed)="onStoreChartsPanelExpandedState(expansionPanel.expanded)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>Incidents (Charts)</span>
        <ng-template [ngTemplateOutlet]="containerHeader"></ng-template>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template [ngTemplateOutlet]="containerContent"></ng-template>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #basicContainer>
  <ng-template [ngTemplateOutlet]="containerHeader"></ng-template>
  <ng-template [ngTemplateOutlet]="containerContent"></ng-template>
</ng-template>
