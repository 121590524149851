<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class=" mr-auto">
      <button mat-raised-button routerLink="/contractors" appMarginRight>
        <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('contractors.plural', 'Contractors') }}
      </button>

      <!--mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.deselectAll()">Deselect All</button>
        <button mat-menu-item (click)="delete()">Delete Selected</button>
      </mat-menu>

      <button mat-raised-button color="secondary" [matMenuTriggerFor]="appMenu" *ngIf="dataSource.selection.hasValue()">
        List Actions
      </button-->
    </span>

    <span class="spinner-container" *ngIf="(dataSource.loading | async)" appMarginRight></span>

    <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2"
        type="search"
        [(ngModel)]="dataSource.search"
        name="search"
        placeholder="Search"
        aria-label="Search"/>

      <button
        mat-raised-button
        color="secondary"
        (click)="dataSource.getData(true)"
        class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>

    <!--button mat-raised-button (click)="export()" color="secondary"><mat-icon>import_export</mat-icon> Export</button-->
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="name"
      matSortDirection="asc"
      matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="folder_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let element">{{ element.folder_type }}</td>
      </ng-container>

      <!-- Expiry Date Column -->
      <ng-container matColumnDef="expiry">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiry Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.expiry ? ((element.expiry * 1000) | timezoneConvertMedium) : '' }}
        </td>
      </ng-container>

      <!-- Expiry Date (UTC) Column -->
      <ng-container matColumnDef="expiry_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiry Date (UTC)</th>
        <td mat-cell *matCellDef="let element">
          {{ element.expiry ? ((element.expiry * 1000) | timezoneConvertUTCMedium) : '' }}
        </td>
      </ng-container>

      <!-- Date Created Column -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.created_at * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- Date Created (UTC) Column -->
      <ng-container matColumnDef="created_at_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created (UTC)</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.created_at * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button type="button" mat-button (click)="onView(element)" matTooltip="View the document information.">
            View
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
