import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from '../app.service';
import { PrequalificationModel } from '../models/prequalification.model';
import { ApiService } from '../shared/api.service';
import { UtilsService } from '../shared/utils.service';
import { CustomDataSource } from '../utils/custom-data-source';
import { PrequalificationService } from './prequalification.service';
import { PrequalificationsEditComponent } from './prequalifications-edit/prequalifications-edit.component';

@Component({
  selector: 'app-prequalifications',
  templateUrl: './prequalifications.component.html',
  styleUrls: ['./prequalifications.component.scss']
})
export class PrequalificationsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new PrequalificationsDataSource(this.app, this.api);
  displayed_columns = [
    'select',
    'id',
    'name',
    'items',
    'default',
    'enable_notifications',
    'date_created',
    // 'date_created_UTC',
    'buttons'
  ];
  query: string;

  constructor(
    private app: AppService,
    private utils: UtilsService,
    private prequalService: PrequalificationService,
    private api: ApiService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.dataSource.getData(false);
  }

  toggleDefault(id: number) {
    /*
    // Make an API request instead to check if there are any default pre-qualifications.
    let multipleDefault: boolean = false;
    this.dataSource.dataSubject.subscribe(res => {
      res.forEach((item: any) => {
        if (item.default === 1) multipleDefault = true;
      })
    });

    if (multipleDefault) {
      this.utils.showToast('You can only have one default Pre-qualification, please turn off others.');
      this.dataSource.getData(true);
    } else {
      this.prequalService.toggleDefault(id).subscribe(() => {
        this.utils.showToast('Toggled default Prequalification');
        this.dataSource.getData(true);
      });
    }
    //*/
    this.prequalService.toggleDefault(id).subscribe(() => {
      this.utils.showToast('Default Pre-Qualification was successfully changed.');
      this.dataSource.getData(true);
    });
  }

  toggleNotifications(id: number) {
    this.prequalService.toggleNotifications(id).then((response: any) => {
      this.utils.showToast('Toggled Notifications');
      // this.dataSource.getData(true);
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns by their corresponding date columns.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData(false);
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  public add() {
    this.edit(new PrequalificationModel());
  }

  public edit(prequalification: PrequalificationModel) {
    this.utils.showComponentDialog(PrequalificationsEditComponent, {...prequalification}, {}, (success) => {
      // if (!success) { return; } // Removed to update list on close
      this.dataSource.getData(true);
    });
  }

  onRemoveSelected() {
    const prequalCount = this.dataSource.selection.selected.length;
    this.utils.showModal(
      'Remove Prequalification',
      `Are you sure you want to remove ${prequalCount} Prequalifications?`,
      () => {
        this.remove(this.dataSource.selection.selected);
      }
    );
  }

  onRemove(prequal: PrequalificationModel) {
    this.utils.showModal(
      'Remove Prequalification',
      `Are you sure you want to remove "${prequal.name}"?`,
      () => this.remove([prequal.id])
    );
  }

  private remove(ids: number[]) {
    this.prequalService.remove(ids).subscribe(() => {
      this.utils.showToast('Removed Prequalifications');
      this.dataSource.selection.clear();
      this.dataSource.getData(true);
    });
  }

  onCreateFrom(prequalification: PrequalificationModel) {
    const newPrequalification: PrequalificationModel = new PrequalificationModel();
    newPrequalification.createFrom(prequalification);
    this.edit(newPrequalification);
  }
}

export class PrequalificationsDataSource extends CustomDataSource {
  sort_by = 'name';
  sort_order = 'asc';
  pageSizeOptions = [5, 10, 15];

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('prequalifications?template=true', resetOffset);
  }
}
