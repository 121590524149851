<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">

    <span class="mr-auto">
      <button *ngIf="taRevisedPath == 'edit'" mat-raised-button class="mr-2" color="primary" (click)="onAdd()" id="add" matTooltip="Click this button to add a new TA/JSA/SWMS Template Workflow Step.">
        <mat-icon>add</mat-icon>
        New Step
      </button>

      <button [disabled]="!dataSource.total || ((dataSource?.total | async) < 2)" mat-raised-button  color="" (click)="onReorderSteps()" matTooltip="Click this button to reorder TA/JSA/SWMS Template Workflow Steps.">
        <mat-icon>swap_vert</mat-icon> Reorder Steps
      </button>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>
        <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>delete</mat-icon> Delete Selected
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect TA/JSA/SWMS Template Workflow records." style="min-width: 120px">
        <mat-icon>more_vert</mat-icon>
        List Menu
      </button>
    </span>

    <form class="form-inline my-2 my-lg-0"
          style="min-width: 325px"
          (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2" type="search"
        [(ngModel)]="dataSource.search" name="search" placeholder="Search"
        aria-label="Search" id="search-input"
        matTooltip="Search for TA/JSA/SWMS Template Workflow Steps." />
      <button mat-raised-button
              class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
              color="secondary"
              (click)="dataSource.getData(true)"
              id="search-button"
              matTooltip="Click this button to run the current search.">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>

  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="step_no" matSortDirection="asc" matSortDisableClear class="w-100">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="65px">
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          #
        </th>
        <td mat-cell *matCellDef="let ta_revised_workflow">
          {{ ta_revised_workflow.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="step_no">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          Step No
        </th>
        <td mat-cell *matCellDef="let ta_revised_workflow">
          {{ ta_revised_workflow.step_no }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Description
        </th>
        <td mat-cell *matCellDef="let ta_revised_workflow" class="no-padding text-wrap">
        <span>
          {{ ta_revised_workflow.description  | ellipsis: [150] }}
        </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="created_by_user">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Created By
        </th>
        <td mat-cell *matCellDef="let ta_revised_workflow" class="no-padding">
          <span *ngIf="ta_revised_workflow?.created_by_user else showSystemCreator">
            <a (click)="onUserPublicView(ta_revised_workflow.created_by_user.hash)"
              matTooltip="View User Public Profile">
              {{ ta_revised_workflow.created_by_user.contact_person }}
            </a>
          </span>
          <ng-template #showSystemCreator>System</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Created At
        </th>
        <td mat-cell *matCellDef="let ta_revised_workflow" class="no-padding">
        <span>
          {{ (ta_revised_workflow.date_created * 1000) | timezoneConvertMedium }}
        </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let ta_revised_workflow">
          <button type="button" mat-button (click)="onView(ta_revised_workflow)" matTooltip="View this TA/JSA/SWMS Template Workflow Step information." matTooltipPosition="above">
            View
          </button>

          <button *ngIf="taRevisedPath == 'edit'" type="button" mat-button (click)="onEdit(ta_revised_workflow)" matTooltip="Click this button to edit this TA/JSA/SWMS Template Workflow Step.">
            Edit
          </button>

          <button type="button" mat-button (click)="onRemove(ta_revised_workflow.id)" matTooltip="Click this button to remove this TA/JSA/SWMS Template Workflow Step.">
            Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>


