<form #form_authorization="ngForm" (ngSubmit)="onSubmit(form_authorization)">
  <div class="container-fluid">

    <div class="row m-0 my-2">
      <div class="col">

        <div style="display: inline-block" class="my-2">
          <button
            type="button"
            mat-raised-button
            color="secondary"
            (click)="onSelectApprover()"
            [matBadge]="'' + (ta_revised.approver_id ? 1 : 0)"
            class="margin-right"
            matTooltip="Select an approver.">
            <mat-icon>rate_review</mat-icon>
            {{ !ta_revised.approver_id ? 'Select' : 'Change' }} Approver
          </button><br/>
          <span style="font-size: small">Status:</span> <span style="font-size: small" [class]='utils.getSiteTAStatusTextColor(app.user.id == ta_revised.approver_id && !ta_revised.approver_acknowledged ? "Required" : (ta_revised.approver_acknowledged ? "Approved" : "Pending"))'>
            {{ app.user.id == ta_revised.approver_id && !ta_revised.approver_acknowledged ? "Required" : (ta_revised.approver_acknowledged ? "Approved" : "Pending") }}
          </span>
        </div>

        <div style="display: inline-block" class="my-2">
          <button
            type="button"
            mat-raised-button
            color="secondary"
            (click)="onSelectAuthorizer()"
            [matBadge]="'' + (ta_revised.authorizer_id ? 1 : 0)"
            class="margin-right"
            matTooltip="Select an authorizer.">
            <mat-icon>rate_review</mat-icon>
            {{ !ta_revised.authorizer_id ? 'Select' : 'Change' }} Authorizer
          </button><br/>
          <span style="font-size: small">Status:</span> <span style="font-size: small" [class]='utils.getSiteTAStatusTextColor(app.user.id == ta_revised.authorizer_id && !ta_revised.authorizer_acknowledged ? "Required" : (ta_revised.authorizer_acknowledged ? "Authorized" : "Pending"))'>
            {{ app.user.id == ta_revised.authorizer_id && !ta_revised.authorizer_acknowledged ? "Required" : (ta_revised.authorizer_acknowledged ? "Authorized" : "Pending") }}
          </span>
        </div>

        <div style="display: inline-block" class="my-2">
          <button
            mat-raised-button
            type="button"
            color="secondary"
            (click)="onSelectSupervisor()"
            [matBadge]="'' + (ta_revised.supervisor_id ? 1 : 0)"
            class="margin-right"
            matTooltip="Select a supervisor">
            <mat-icon>person</mat-icon>
            {{ !ta_revised.supervisor_id ? 'Select' : 'Change' }} Supervisor
          </button><br/>
          <span style="font-size: small">Status:</span> <span style="font-size: small" [class]='utils.getSiteTAStatusTextColor((app.user.id == ta_revised.supervisor_id && !ta_revised.supervisor_acknowledged) ? "Required" : (ta_revised.supervisor_acknowledged ? "Acknowledged" : "Pending"))'>
            {{ (app.user.id == ta_revised.supervisor_id && !ta_revised.supervisor_acknowledged) ? "Required" : (ta_revised.supervisor_acknowledged ? "Acknowledged" : "Pending") }}
          </span>
        </div>

      </div>
      <div class="col text-center text-lg-right">
        <button
          color="success"
          type="button"
          *ngIf="app.user.id == approverId"
          [disabled]="approverAcknowledged || inProgress"
          mat-raised-button
          (click)="onApprove()"
          class="mx-2 ml-lg-3 mr-lg-0 my-2">
          <mat-icon>check_circle</mat-icon>
          {{ approverAcknowledged ? 'Approved' : 'Approve' }}
        </button>

        <button
          color="success"
          type="button"
          *ngIf="app.user.id == authorizerId"
          [disabled]="authorizerAcknowledged || inProgress"
          mat-raised-button
          (click)="onAuthorize()"
          class="mx-2 ml-lg-3 mr-lg-0 my-2">
          <mat-icon>check_circle</mat-icon>
          {{ authorizerAcknowledged ? 'Authorized' : 'Authorize' }}
        </button>

        <button
          color="success"
          type="button"
          *ngIf="app.user.id == supervisorId"
          [disabled]="supervisorAcknowledged || inProgress"
          mat-raised-button
          (click)="onSupervisorAcknowledge()"
          class="mx-2 ml-lg-3 mr-lg-0 my-2">
          <mat-icon>check_circle</mat-icon>
          Supervisor {{ supervisorAcknowledged ? 'Acknowledged' : 'Acknowledge' }}
        </button>

      </div>
    </div>

  </div>
</form>


<form #form="ngForm" (ngSubmit)="onSubmit(form)">
  <div class="container-fluid">

    <div class="row m-0 mb-3 mt-3">
      <div class="col">
        <mat-form-field *ngIf="ta_revised.id">
          <input
            matInput
            placeholder="Name"
            [(ngModel)]="ta_revised.task_name"
            name="task_name"
            type="text"
            required
            #task_name
            matTooltip="Enter a TA/JSA/SWMS name." />
          <mat-hint>Please enter a TA/JSA/SWMS Template name.</mat-hint>
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field *ngIf="ta_revised.id">
          <input
            matInput
            placeholder="Work Procedure"
            [(ngModel)]="ta_revised.work_procedure"
            name="work_procedure"
            type="text"
            #work_procedure />
          <mat-hint>Enter the Work Procedures for this TA/JSA/SWMS Template.</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row m-0 mb-3 mt-3">
      <div class="col">
        <mat-form-field *ngIf="ta_revised.id">
          <textarea
            rows="4"
            matInput
            placeholder="Description"
            [(ngModel)]="ta_revised.task_description"
            name="task_description"
            required
            #task_description
            matTooltip="Enter a TA/JSA/SWMS description.">
          </textarea>
          <mat-hint>Please enter a TA/JSA/SWMS Template description.</mat-hint>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field *ngIf="ta_revised.id">
            <textarea
              rows="4"
              matInput
              placeholder="Training/Qualifications"
              [(ngModel)]="ta_revised.training_qualifications"
              name="training_qualifications"
              #training_qualifications>
            </textarea>
          <mat-hint>Enter the Training/Qualifications needed for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row m-0 mb-3 mt-3">
      <div class="col">
        <mat-form-field *ngIf="ta_revised.id">
          <textarea
            rows="4"
            matInput
            placeholder="Duties"
            [(ngModel)]="ta_revised.duties"
            name="duties"
            #duties>
          </textarea>
          <mat-hint>Enter the Duties to undertake for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field *ngIf="ta_revised.id">
          <textarea
            rows="4"
            matInput
            placeholder="Certificates of Competency"
            [(ngModel)]="ta_revised.certificates"
            name="certificates"
            #certificates>
          </textarea>
          <mat-hint>Enter the Certificates of Competency required for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row m-0 mb-3 mt-3">
      <div class="col">
        <mat-form-field *ngIf="ta_revised.id">
          <textarea
            rows="4"
            matInput
            placeholder="Code of Practice"
            [(ngModel)]="ta_revised.code_of_practice"
            name="code_of_practice"
            #code_of_practice>
          </textarea>
          <mat-hint>Enter the Code of Practice for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field *ngIf="ta_revised.id">
          <textarea
            rows="4"
            matInput
            placeholder="Maintenance Checks"
            [(ngModel)]="ta_revised.maintenance_checks"
            name="maintenance_checks"
            #maintenance_checks>
          </textarea>
          <mat-hint>Enter the Maintenance Checks required for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row m-0 mb-3 mt-3">
      <div class="col">
        <mat-form-field *ngIf="ta_revised.id">
          <textarea
            rows="4"
            matInput
            placeholder="Safety Practice"
            [(ngModel)]="ta_revised.safety_practices"
            name="safety_practices"
            #safety_practices>
          </textarea>
          <mat-hint>Enter the Safety Practices for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field *ngIf="ta_revised.id">
          <textarea
            rows="4"
            matInput
            placeholder="PPE Other"
            [(ngModel)]="ta_revised.ppe_other"
            name="ppe_other"
            #ppe_other>
          </textarea>
          <mat-hint>Enter Other PPE required for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row m-0 mb-3 mt-3">
      <div class="col">
        <mat-form-field *ngIf="ta_revised.id">
          <textarea
            rows="4"
            matInput
            placeholder="Work Permits Other"
            [(ngModel)]="ta_revised.work_permits_other"
            name="work_permits_other"
            #work_permits_other>
          </textarea>
          <mat-hint>Enter Other Work Permits required for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field *ngIf="ta_revised.id">
          <textarea
            rows="4"
            matInput
            placeholder="Tools Other"
            [(ngModel)]="ta_revised.tools_other"
            name="tools_other"
            #tools_other>
          </textarea>
          <mat-hint>Enter Other Tools required for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row m-0 mb-1">
      <div class="col">

        <button
          type="button"
          mat-raised-button
          matTooltip="Save the Task Analysis"
          [disabled]="!form.valid || inProgress"
          color="primary"
          (click)="onSubmit(form)"
          class="margin-right my-2">
          <mat-icon>save</mat-icon>
          Save
        </button>

        <app-file-browse-button
          (selectedFiles)="onSelectFiles($event)"
          (afterDialogClosedEvent)="onSubmit(form)"
          parentObjectType="task_analysis_revised"
          [parentObjectId]="ta_revised.id"
          [filesCount]="ta_revised.files?.length">
        </app-file-browse-button>

        <button
          mat-raised-button
          type="button"
          (click)="selectPPE()"
          [matBadge]="'' + (ta_revised.ppe_ids ? ta_revised.ppe_ids?.length : 0)"
          class="margin-right my-2"
          color="secondary"
          matTooltip="Select PPE from the PPE selection list.">
          <mat-icon>headset</mat-icon>
          PPE
        </button>

        <button
          mat-raised-button
          type="button"
          (click)="selectWorkPermits()"
          [matBadge]="'' + (ta_revised.work_permit_ids ? ta_revised.work_permit_ids?.length : 0)"
          class="margin-right my-2"
          color="secondary"
          matTooltip="Select Work Permits from the Work Permits selection list.">
          <mat-icon>assignment_ind</mat-icon>
          Work Permits
        </button>

        <button
          mat-raised-button
          type="button"
          (click)="selectTools()"
          [matBadge]="'' + (ta_revised.tool_ids ? ta_revised.tool_ids?.length : 0)"
          class="margin-right my-2"
          color="secondary"
          matTooltip="Select Tools from the Tools selection list.">
          <mat-icon>build</mat-icon>
          Tools
        </button>

      </div>
    </div>

  </div>
</form>

