import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AuditsDataSource } from 'src/app/audits/audits.component';
import { AuditsModel } from 'src/app/audits/audits.model';
import { AuditsService } from 'src/app/audits/audits.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { UtilsService } from 'src/app/shared/utils.service';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { SitesAuditsFilterComponent } from '../sites-audits-filter/sites-audits-filter.component';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-sites-audits',
  templateUrl: './sites-audits.component.html',
  styleUrls: ['./sites-audits.component.scss']
})
export class SitesAuditsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new AuditsDataSource(this.app, this.api);

  displayedColumns = [
    'select',
    'id',
    'site',
    'name',
    'created_by_name',
    'created_by_employer',
    'completed',
    'percentCompleted',
    'date_created',
    'date_modified',
    'buttons'
  ];
  query: string;

  parent_id: number;
  child_id: number;
  site_id: number;

  constructor(
    private auditsService: AuditsService,
    public utils: UtilsService,
    private app: AppService,
    private route: ActivatedRoute,
    private api: ApiRequestService) { }

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      this.dataSource.site_ids.push(this.site_id);
      this.dataSource.getData(false);
    }
  }

  onRemoveSelected() {
    const selected = this.dataSource.selection.selected;
    this.utils.showModal(
      'Remove Inspections & Audits',
      `Are you sure you want to remove ${selected.length} Inspections & Audits?`,
      () => this.remove(selected)
    );
  }

  private remove(ids: number[]) {
    this.auditsService.remove(ids).subscribe(() => {
      this.utils.showToast('Inspections & Audits have been removed.');
      this.dataSource.selection.clear();
      this.dataSource.getData(false);
    });
  }

  onRemove(audit: AuditsModel) {
    this.utils.showModal(
      'Remove Inspection/Audit',
      `Are you sure you want to remove the "${audit.name}" Inspection/Audit?`,
      () => this.remove([audit.id])
    );
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.dataSource.order_by = "date_created";
      } else {
        this.dataSource.order_by = this.sort.active;
      }
      this.dataSource.order = this.sort.direction;
      this.dataSource.getData(false);
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.dataSource.site_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results != 'undefined') {
          this.dataSource.site_ids = results;
          this.dataSource.getData();
        }
      }
    );
  }

  /**
   * Send a request to the API to export site audits as CSV/XLSX.
   */
  onExportSiteAudits(exportType: string = 'csv') {
    this.api.makeRequest('get', 'v2/site-audits/export/' + exportType, {}, {
      site_ids: this.dataSource.site_ids.join(','),
      date_range: this.dataSource.date_range && this.dataSource.date_range.length > 1 ? [
        this.dataSource.date_range[0].getTime() / 1000,
        this.dataSource.date_range[1].getTime() / 1000
      ].join(',') : '',
      selected_ids: this.dataSource.selection.selected.join(','), // To export selected records.
      site_audit_template_ids: this.dataSource.site_audit_template_ids.join(','),
      search: this.dataSource.search
    })
      .then((response) => {
        // Check if we have a message response.
        this.utils.showToast(response.message || 'Your export is queued in the background. You will receive an email when it is ready.');
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  /**
   * Send a request to the API to export site audits as PDF.
   * Duplicated code in: src/app/audits/audits.component.ts. Should be refactored into a service file.
   */
  onExportSiteAuditPDFs() {
    this.api.makeRequest('get', 'v2/site-audits/export/pdf', {}, {
      site_ids: this.dataSource.site_ids.join(','),
      date_range: this.dataSource.date_range && this.dataSource.date_range.length > 1 ? [
        this.dataSource.date_range[0].getTime() / 1000,
        this.dataSource.date_range[1].getTime() / 1000
      ].join(',') : '',
      selected_ids: this.dataSource.selection.selected.join(','), // To export selected records.
      site_audit_template_ids: this.dataSource.site_audit_template_ids.join(','),
      search: this.dataSource.search
    })
      .then((response) => {
        // Check if we have a message response.
        if ( typeof response.message != 'undefined' ) {
          this.utils.showToast(response.message);
        }
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onFilter() {
    this.utils.showComponentDialog(
      SitesAuditsFilterComponent,
      {
        parent_site_id: this.parent_id,
        site_ids: this.dataSource.site_ids,
        date_range: this.dataSource.date_range,
        template_ids: this.dataSource.site_audit_template_ids
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results != 'undefined') {
          this.dataSource.site_ids = results.site_ids ? results.site_ids : this.dataSource.site_ids;
          this.dataSource.date_range = results.date_range ? results.date_range : this.dataSource.date_range;
          this.dataSource.site_audit_template_ids = results.template_ids ? results.template_ids : this.dataSource.site_audit_template_ids;
          this.dataSource.getData(true);
        }
      }
    );
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}
