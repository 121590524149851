import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { SiteModel } from 'src/app/models/site.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';

declare var google;

@Component({
  selector: 'app-sites-details-view',
  templateUrl: './sites-details-view.component.html',
  styleUrls: ['./sites-details-view.component.scss']
})
export class SitesDetailsViewComponent implements OnInit {

  site: SiteModel = new SiteModel();

  search: string = '';
  inProgress: boolean = false;
  offset: number = 0;
  limit: number = 30;

  onsite_records: any = [];

  // variables used to manipulate the map
  @ViewChild('map') mapElement: ElementRef;
  map: google.maps.Map;
  polygon: any;

  updateUserLocationOnMapInterval: any;
  updateOnsiteUserLocationsOnMapInterval: any;

  onsiteUsersMarkers: any = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) {}

  ngOnDestroy(): void {
    // Clear the update onsite users on map interval.
    if ( this.updateOnsiteUserLocationsOnMapInterval ) {
      clearInterval(this.updateOnsiteUserLocationsOnMapInterval);
    }
    // Clear the user location on map interval.
    if ( this.updateUserLocationOnMapInterval ) {
      clearInterval(this.updateUserLocationOnMapInterval);
    }
  }

  ngOnInit() {
    // Get the site id from the route params.
    const parent_id: number = Number(this.route.parent.snapshot.params['parent_id']);
    const child_id: number = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && parent_id) ) {
      // Store the site id.
      this.site.id = child_id ? child_id : parent_id;
      // Get the site details from the API.
      this.api.laravelApiRequest('get', 'sites/' + this.site.id, {}, {}, (response) => {
          // apply the site response data
          this.site.apply(response.data);

          // Load the Google Maps script and configure the map once it is loaded.
          this.utils.loadExternalScript('google-maps-script', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA91kej0UNf8eHR_O_iTY7oFZVFTSVxk2I&libraries=drawing,places,geometry', () => {
            // load the map configuration
            this.configureMap();
          });
        },
        (error) => {
          this.utils.showModal('Error', error.message);
        }
      );
    } else {
      this.router.navigate(['sites']);
      return;
    }
  }

  /**
   * Delete the site.
   */
  onRemove() {
    this.utils.showModal(
      'Remove ' + this.utils.getLangTerm(this.site.parent_site_id ? 'child-sites.singular' : 'sites.singular', this.site.parent_site_id ? 'Subsite' : 'Site'),
      'Are you sure you want to remove this ' + this.utils.getLangTerm(this.site.parent_site_id ? 'child-sites.singular' : 'sites.singular', this.site.parent_site_id ? 'Subsite' : 'Site').toLowerCase() + '?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'sites/' + this.site.id,
          {},
          {},
          (response) => {
            this.utils.showToast('The ' + this.utils.getLangTerm(this.site.parent_site_id ? 'child-sites.singular' : 'sites.singular', this.site.parent_site_id ? 'Subsite' : 'Site').toLowerCase() + ' was removed.');
            this.router.navigate(['/sites']);
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  configureMap() {
    // set the default geofence bounds and coordinates
    const geofenceBounds = new google.maps.LatLngBounds();
    const geofenceCoordinates = [];

    // if the currently loaded site has a geofence, load it instead
    if ( this.site.geofence ) {
      const points = this.site.geofence.split(',');
      if ( points.length ) {
        // Clear the default geofence values and add the new values.
        geofenceCoordinates.length = 0;
        // Loop and extend the bounds.
        points.forEach((point, i) => {
          const coordinates_data = point.split(' ');
          const coordinates = new google.maps.LatLng(
            coordinates_data[0],
            coordinates_data[1]
          );
          geofenceBounds.extend(coordinates);
          geofenceCoordinates.push(coordinates);
        });
      }
    }

    // initialize the map object
    this.map = new google.maps.Map(this.mapElement.nativeElement, {
      zoom: 17,
      center: geofenceBounds.getCenter(),
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
    });

    // if there's a polygon, construct and add it to the map
    if ( geofenceCoordinates.length > 1 ) {
      // Construct the polygon.
      this.polygon = new google.maps.Polygon({
        paths: geofenceCoordinates,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.35,
        editable: false
      });
      this.polygon.setMap(this.map);
      this.map.fitBounds(geofenceBounds);

      // Get the max distance from the centroid to draw the circular geofence.
      let maxDistanceFromCentroid = 0;
      geofenceCoordinates.forEach((coordnates) => {
        const distanceFromCentroid = google.maps.geometry.spherical.computeDistanceBetween(geofenceBounds.getCenter(), coordnates);
        if ( distanceFromCentroid > maxDistanceFromCentroid ) {
          maxDistanceFromCentroid = distanceFromCentroid;
        }
      });
      // Use the max distance from the centroid and add another 200m for the sign-out radius.
      let radius = maxDistanceFromCentroid + 200;
      // Draw the circular sign-out zone.
      const circularGeofence = new google.maps.Circle({
        strokeColor: '#ADD8E6',
        strokeOpacity: 0.4,
        strokeWeight: 2,
        fillColor: '#ADD8E6',
        fillOpacity: 0.3,
        map: this.map,
        center: geofenceBounds.getCenter(),
        radius: radius
      });

      // Update user locations every 30 seconds.
      this.getOnsiteUsers(true);
      this.updateOnsiteUserLocationsOnMapInterval = setInterval(() => {
        this.getOnsiteUsers(true);
      }, 30000);
    } else {
      // Center the map at the stored location if possible.
      if ( this.site.location ) {
        // Get the geocoder instance.
        const geocoder = new google.maps.Geocoder();
        // Center the map if the location is found.
        geocoder.geocode({ address: this.site.location }, (results, status) => {
          if (status == 'OK') {
            this.map.setCenter(results[0].geometry.location);

            // Other users are orange.
            const currentLocationMarker = new google.maps.Marker({
              zIndex: 10,
              map: this.map,
              title: this.site.name,
              position: results[0].geometry.location,
              icon: {
                path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                scale: 2,
                fillColor: '#0096A3',
                fillOpacity: 1,
                strokeColor: '#aaa',
                strokeOpacity: 1,
                strokeWeight: 1,
                rotation: 0,
                anchor: new google.maps.Point(15, 30)
              }
            });
            const infowindow = new google.maps.InfoWindow({
              content: this.site.name + '<br>' + this.site.location
            });
            currentLocationMarker.addListener('click', () => {
              infowindow.open(this.map, currentLocationMarker);
            });
          }
        });
      }
    }
  }

  /**
   * Get a list of onsite users.
   */
  getOnsiteUsers(resetOffset: boolean = false): Promise<any> {
    // Ignore if already in progress.
    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;

    // Reset the offset.
    if ( resetOffset ) {
      this.offset = 0;
    }
    // Make the API request.
    return this.api.makeRequest('get', `sites/${this.site.id}/onsite-users`, {}, {}, {
      limit: this.limit,
      offset: this.offset,
      search: this.search
    })
    .then((response) => {
      // Clear the current list.
      if (resetOffset) {
        this.onsite_records.length = 0;
      }

      this.onsite_records.push(...response.data);

      this.offset++;

      this.updateOnsiteUserLocationsOnMap();
    })
    .finally(() => {
      this.inProgress = false;
    });
  }

  /**
   * Update onsite user locations on the map showing them as orange dots.
   */
   updateOnsiteUserLocationsOnMap() {
    // Remove all markers from the map.
    this.onsiteUsersMarkers.forEach((marker) => {
      marker.setMap(null);
    });
    // Clear marker list.
    this.onsiteUsersMarkers.length = 0;
    // Show new markers.
    this.onsite_records.forEach((onsite_record) => {
      // Other users are orange.
      const currentLocationMarker = new google.maps.Marker({
        zIndex: 10,
        map: this.map,
        title: onsite_record.user.contact_person,
        position: new google.maps.LatLng(onsite_record.lat, onsite_record.lng),
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 8,
          fillColor: '#F4901E',
          fillOpacity: 1,
          strokeColor: '#fff',
          strokeOpacity: 1,
          strokeWeight: 4
        }
      });
      const infowindow = new google.maps.InfoWindow({
        content: onsite_record.user.contact_person + '<br>' + onsite_record.user.employer.name + '<br>' + onsite_record.device_id
      });
      currentLocationMarker.addListener('click', () => {
        infowindow.open(this.map, currentLocationMarker);
      });
      // store marker
      this.onsiteUsersMarkers.push(currentLocationMarker);
    });
  }
}
