import {Auditable} from "../shared/auditable.model";
import {UserModel} from "./user.model";

export class TaskAnalysesRevisedWorkflowNoteModel extends Auditable{
  id = 0;

  note = '';

  /**
   * Who created this Task Analysis Workflow Note?
   */
  created_by_user: UserModel;

  /**
   * Who updated this Task Analysis Workflow Note?
   */
  updated_by_user: UserModel;
}
