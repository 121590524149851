import {Component, Inject, OnInit} from '@angular/core';
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";
import {NetworkedUsersSelectorComponent} from "../../shared/networked-users-selector/networked-users-selector.component";
import {NetworkedAccountsSelectorComponent} from "../../shared/networked-accounts-selector/networked-accounts-selector.component";
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import { NetworkedEmployeesSelectorComponent } from 'src/app/shared/networked-employees-selector/networked-employees-selector.component';

@Component({
  selector: 'app-onsite-records-filter',
  templateUrl: './onsite-records-filter.component.html',
  styleUrls: ['./onsite-records-filter.component.scss']
})
export class OnsiteRecordsFilterComponent implements OnInit {

  dataSource = new OnsiteRecordsFilterDataSource(); // No API calls needed
  faHardHat = faHardHat;

  constructor(
    private app: AppService,
    public location: Location,
    public utils: UtilsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<OnsiteRecordsFilterComponent>
    // private api: ApiRequestService
  ) {
    // set a default site selection of nothing if no selected values are passed through
    if (typeof this.data['selected_site_ids'] === 'undefined') {
      this.data['selected_site_ids'] = [];
    }

    // set a default contractor selection of nothing if no selected values are passed through
    if (typeof this.data['selected_contractor_ids'] === 'undefined') {
      this.data['selected_contractor_ids'] = [];
    }

    // set a default user of nothing if no values are passed through
    if (typeof this.data['selected_user_ids'] === 'undefined') {
      this.data['selected_user_ids'] = [];
    }

    // set a default user of nothing if no values are passed through
    if (typeof this.data['selected_employee_ids'] === 'undefined') {
      this.data['selected_employee_ids'] = [];
    }
  }

  ngOnInit() {
    this.dataSource.selected_site_ids = this.data['selected_site_ids'];
    this.dataSource.selected_contractor_ids = this.data['selected_contractor_ids'];
    this.dataSource.selected_user_ids = this.data['selected_user_ids'];
    this.dataSource.selected_employee_ids = this.data['selected_employee_ids'];
  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.dataSource.selected_site_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.selected_site_ids = results;
        }
      }
    );
  }

  onSelectUsers() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
        selected: this.dataSource.selected_user_ids,
        multiple: true,
        selectedAccountId: this.dataSource.selected_contractor_ids.length ? this.dataSource.selected_contractor_ids[0] : 0,
        visitors_from_all_sites: true
      }, {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.dataSource.selected_user_ids = results;
      });
  }

  onSelectContractors() {
    this.utils.showComponentDialog(
      NetworkedAccountsSelectorComponent,
      {
        selected: this.dataSource.selected_contractor_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.selected_contractor_ids = results;
        }
      }
    );
  }

  onSelectEmployees() {
    this.utils.showComponentDialog(NetworkedEmployeesSelectorComponent, {
      selected: this.dataSource.selected_employee_ids,
      multiple: true
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.dataSource.selected_employee_ids = results;
    });
  }

  onClearAndClose() {
    this.dataSource.selected_site_ids = [];
    this.dataSource.selected_contractor_ids = [];
    this.dataSource.selected_user_ids = [];
    this.dataSource.selected_employee_ids = [];

    this.onSaveAndClose();
  }

  onSaveAndClose() {
    let response: any;

    response = {
      site_ids: this.dataSource.selected_site_ids,
      contractor_ids: this.dataSource.selected_contractor_ids,
      user_ids: this.dataSource.selected_user_ids,
      selected_employee_ids: this.dataSource.selected_employee_ids
    }

    this.dialogRef.close(response);
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 */
export class OnsiteRecordsFilterDataSource {

  selected_site_ids: number[] = [];
  selected_contractor_ids: number[] = [];
  selected_user_ids: number[] = [];
  selected_employee_ids: number[] = [];

  constructor() {
    this.selected_site_ids = [];
    this.selected_contractor_ids = [];
    this.selected_user_ids = [];
    this.selected_employee_ids = [];
  }

}
