<div #risk_matrix_ref class="risk-matrix">
  <table *ngIf="isValid5x5Array(gridData)">
    <tr>
      <th></th>
      <th class="{{ enableInteractiveMode && riskData?.likelihood == 1 ? 'selected' : '' }}">Very unlikely to happen</th>
      <th class="{{ enableInteractiveMode && riskData?.likelihood == 2 ? 'selected' : '' }}">Unlikely to happen</th>
      <th class="{{ enableInteractiveMode && riskData?.likelihood == 3 ? 'selected' : '' }}">Possibly could happen</th>
      <th class="{{ enableInteractiveMode && riskData?.likelihood == 4 ? 'selected' : '' }}">Likely to happen</th>
      <th class="{{ enableInteractiveMode && riskData?.likelihood == 5 ? 'selected' : '' }}">Very likely to happen</th>
    </tr>

    <tr *ngFor="let row of gridData; let i_severity = index;">
      <th class="{{ enableInteractiveMode && riskData?.severity == (5 - i_severity) ? 'selected' : '' }}">{{ utils.getRiskAssessmentSeverityList().reverse()[i_severity] }}</th>
      <td *ngFor="let col of gridData[i_severity]; let j_likelihood = index" class="{{ getBackgroundColors(utils.getRiskAssessmentText(j_likelihood+1, getSeverityIndexInReverseOrder(i_severity)+1)) }}" (click)="onSelectRiskLevel(j_likelihood+1, getSeverityIndexInReverseOrder(i_severity)+1)">
        {{ utils.getRiskAssessmentText(j_likelihood+1, getSeverityIndexInReverseOrder(i_severity)+1) }} <b *ngIf="!enableInteractiveMode">{{ ': ' + gridData[i_severity][j_likelihood] }}</b>
      </td>
    </tr>
  </table>
</div>
