<mat-tab-group>
  <mat-tab label="Notifications">
    <ng-template matTabContent>
      <app-employees-notifications [user_id]="user_id"></app-employees-notifications>
    </ng-template>
  </mat-tab>

  <mat-tab label="Notification Roles">
    <ng-template matTabContent>
      <app-employees-notifications-roles [user_id]="user_id"></app-employees-notifications-roles>
    </ng-template>
  </mat-tab>

  <mat-tab label="Sites">
    <ng-template matTabContent>
      <app-employees-notifications-sites [user_id]="user_id"></app-employees-notifications-sites>
    </ng-template>
  </mat-tab>
</mat-tab-group>
