<div mat-dialog-title>
  <h5>{{ tag.id ? 'Edit' : 'Create' }} Tag</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit(form)">
  <mat-form-field class="full-width mb-3">
    <input
      matInput
      placeholder="Tag"
      [(ngModel)]="tag.title"
      name="title"
      type="text"
      required
      #title />
    <mat-hint>Please enter a Tag title.</mat-hint>
  </mat-form-field>

  <mat-form-field class="full-width mb-3">
    <textarea
      rows="4"
      matInput
      placeholder="Description"
      [(ngModel)]="tag.description"
      name="description"
      #description>
    </textarea>
    <mat-hint>Please enter a short description for the tag.</mat-hint>
  </mat-form-field>

  <button
    mat-raised-button
    [disabled]="!form.valid || inProgress"
    color="primary"
    type="submit"
    id="save">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    type="button"
    title="Archive Tag"
    mat-icon-button
    [disabled]="!tag.id"
    class="float-right"
    (click)="onDelete()">
    <mat-icon>archive</mat-icon>
  </button>
</form>
