import { Pipe, PipeTransform } from '@angular/core';
import { PrequalificationModel } from '../models/prequalification.model';

@Pipe({
  name: 'prequalScore'
})
export class PrequalScorePipe implements PipeTransform {
  transform(prequal: PrequalificationModel): number {
    if (!Array.isArray(prequal.items) || !prequal.items.length) { return 0; }
    const stars = prequal.items
      .map((item) => item.pivot.rating)
      .reduce((rating1, rating2) => rating1 + rating2);
    return stars / (prequal.items.length * 5);
  }
}
