<div mat-dialog-title>
  <div style="width: 100%;">
    <h3>
      {{ dataSource.parent_site_id ? utils.getLangTerm('child-sites.plural', 'Subsites') : utils.getLangTerm('sites.plural', 'Sites') }} Selector

      <button mat-icon-button class="float-right" [mat-dialog-close]="undefined">
        <mat-icon color="secondary">close</mat-icon>
      </button>
    </h3>
  </div>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button mat-raised-button class="mr-sm-2" type="button" (click)="onResetList()" *ngIf="dataSource.parent_site_id">
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>

    <span style="flex: 1 1 auto;"></span>

    <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
      <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search" aria-label="Search" id="search-input" matTooltip="Search records." />

      <button mat-raised-button color="secondary" appMarginRight id="search-button" matTooltip="Run the current search">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>
  </nav>
</div>

<mat-dialog-content>
  <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

  <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null" *ngIf="dataSource.multiple"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
          matTooltip="Toggle select all records in this list.">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)"
          matTooltip="Toggle select this record.">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the name.">
        {{ dataSource.parent_site_id ? utils.getLangTerm('child-sites.singular', 'Subsite') : utils.getLangTerm('sites.singular', 'Site') }} Name
      </th>
      <td mat-cell *matCellDef="let site">
        <mat-icon *ngIf="site?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
        {{ site.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef matTooltip="Toggle sorting by the address.">
        {{ dataSource.parent_site_id ? utils.getLangTerm('child-sites.singular', 'Subsite') : utils.getLangTerm('sites.singular', 'Site') }} Address
      </th>
      <td mat-cell *matCellDef="let site">
        {{ site.location }}
      </td>
    </ng-container>

    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef>
        From
      </th>
      <td mat-cell *matCellDef="let site">
        {{ site.account.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let site">
        <button type="button" mat-button *ngIf="!site.parent_site_id && !dataSource.parent_site_id" (click)="onFilterByParentSite(site.id)" matTooltip="Show {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}." matTooltipPosition="above" >
          {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
          <mat-icon>chevron_right</mat-icon>
        </button>

        <button type="button" mat-button (click)="onSelectSite(site, $event)" matTooltip="Select this record." matTooltipPosition="above" *ngIf="!dataSource.multiple">
          Select {{ dataSource.parent_site_id ? utils.getLangTerm('child-sites.singular', 'Subsite') : utils.getLangTerm('sites.singular', 'Site') }}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: listColumns" (click)="dataSource.selection.toggle(row.id)"></tr>
  </table>

  <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end" *ngIf="dataSource.multiple">
  <button mat-raised-button color="primary" type="button" (click)="onSelectSites()" mat-dialog-close [disabled]="!dataSource.multiple && !dataSource.selection.hasValue()">Select {{ dataSource.parent_site_id ? utils.getLangTerm('child-sites.plural', 'Subsites') : utils.getLangTerm('sites.plural', 'Sites') }}</button>
</mat-dialog-actions>
