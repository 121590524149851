import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { SiteModel } from 'src/app/models/site.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-sites-induction-view',
  templateUrl: './sites-induction-view.component.html',
  styleUrls: ['./sites-induction-view.component.scss']
})
export class SitesInductionViewComponent implements OnInit {
  // the site model placeholder
  site: SiteModel = new SiteModel();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    // Get the site id from the route params.
    const parent_id: number = Number(this.route.parent.snapshot.params['parent_id']);
    const child_id: number = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && parent_id) ) {
      // Store the site id.
      this.site.id = child_id ? child_id : parent_id;

      this.api.laravelApiRequest('get', 'sites/' + this.site.id, {}, {}, (response) => {
          // apply the site response data
          this.site.apply(response.data);
        },
        (error) => {
          this.utils.showModal('Error', error.message);
        }
      );
    }
  }
}
