<mat-card>
  <mat-card-content>
    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
      <mat-form-field class="full-width mb-3 mt-3">
        <mat-label>Role Name</mat-label>
        <input
          matInput
          placeholder="Role Name"
          [(ngModel)]="notification_role.name"
          name="name"
          type="text"
          id="name"
          required />
        <mat-hint>Enter the name for the role.</mat-hint>
      </mat-form-field>

      <section>
        <mat-checkbox [(ngModel)]="notification_role.is_active" name="is_active">Activate</mat-checkbox>
        <mat-hint>Enable this checkbox to activate the notification role.</mat-hint>
      </section>

      <hr>

      <div class="alert alert-warning" role="alert" *ngIf="notification_role.sites_count > 0; else noSites">
        This notification role is associated with {{ notification_role.sites_count }} {{ utils.getLangTerm('parent-child-sites-combined.' + (notification_role.sites_count == 1 ? 'singular' : 'plural'), 'site' + (notification_role.sites_count == 1 ? '' : 's')).toLowerCase() }}. {{ utils.getLangTerm('employees.plural', 'Employees') }} assigned to it will only receive notifications for those {{ utils.getLangTerm('parent-child-sites-combined.plural', 'sites').toLowerCase() }}. To enable {{ utils.getLangTerm('employees.plural', 'Employees').toLowerCase() }} to receive notifications for all {{ utils.getLangTerm('parent-child-sites-combined.plural', 'sites').toLowerCase() }}, please either create a separate role without {{ utils.getLangTerm('parent-child-sites-combined.singular', 'site').toLowerCase() }} associations or remove the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'site').toLowerCase() }} associations from this role.
      </div>

      <ng-template #noSites>
        <div class="alert alert-warning" role="alert">
          {{ utils.getLangTerm('employees.plural', 'Employees') }} assigned to this notification role will receive account notifications and notifications for all {{ utils.getLangTerm('parent-child-sites-combined.plural', 'sites').toLowerCase() }} for any {{ utils.getLangTerm('parent-child-sites-combined.singular', 'site').toLowerCase() }}-specific enabled alerts. To restrict notifications to specific {{ utils.getLangTerm('parent-child-sites-combined.plural', 'sites').toLowerCase() }}, please associate the desired {{ utils.getLangTerm('parent-child-sites-combined.plural', 'sites').toLowerCase() }} with this notification role or assign a different role that includes those {{ utils.getLangTerm('parent-child-sites-combined.plural', 'sites').toLowerCase() }}.
        </div>
      </ng-template>

      <div class="columns">
        <ng-container *ngFor="let feature of grouped_system_notifications | keyvalue">
          <div class="mb-3" *ngIf="notification_role.sites_count == 0 || (notification_role.sites_count > 0 && feature.value.has_site_related_notifications)">
            <mat-card>
              <mat-card-subtitle><b>{{ feature.key }}</b></mat-card-subtitle>
              <mat-card-content>
                <div *ngFor="let system_notification of feature.value.system_notifications" class="mb-3">
                  <ng-container *ngIf="notification_role.sites_count == 0 || (notification_role.sites_count > 0 && system_notification.is_site_related)">
                    <mat-checkbox [(ngModel)]="system_notification.checked" [name]="'system_notification_' + system_notification.id">
                      {{ system_notification.label }}
                    </mat-checkbox>
                    <mat-hint>{{ system_notification.description }}</mat-hint>
                  </ng-container>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>
      </div>

      <div class="d-flex justify-content-end">
        <button mat-button mat-raised-button [disabled]="!f.valid" type="submit" class="mr-1" color="primary">
          <mat-icon>save</mat-icon> {{ notification_role && notification_role.id ? 'Update' : 'Save' }}
        </button>

        <button mat-button mat-raised-button [disabled]="!f.valid" type="button" color="primary" (click)="onSubmit(f, true)">
          <mat-icon>save</mat-icon> {{ notification_role && notification_role.id ? 'Update' : 'Save' }} & Close
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
