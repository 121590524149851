<div mat-dialog-title>
  <div class="d-flex">
    <h3>Notification Roles</h3>

    <span style="flex: 1 1 auto;"></span>

    <form class="form-inline my-2 my-lg-0" (ngSubmit)="listDataSource.getData(true)">
      <button mat-button mat-raised-button type="button" color="primary" (click)="onEditNotificationRole()" appMarginRight id="add" matTooltip="Create a new notification role.">
        <mat-icon>add</mat-icon>
        New Notification Role
      </button>

      <input class="form-control mr-sm-2" type="search" [(ngModel)]="listDataSource.search" name="search" placeholder="Search" aria-label="Search" />

      <button type="submit" mat-button mat-raised-button color="secondary" appMarginRight>
        <mat-icon>search</mat-icon> Search
      </button>
    </form>
  </div>
</div>

<mat-dialog-content>
  <table mat-table [dataSource]="listDataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? listDataSource.selectAllToggle() : null"
          [checked]="listDataSource.selection.hasValue() && listDataSource.isAllSelected()"
          [indeterminate]="listDataSource.selection.hasValue() && !listDataSource.isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? onToggleRowSelection(row) : null"
          [checked]="listDataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <!-- Role Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Role Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="system_notifications_count">
      <th mat-header-cell *matHeaderCellDef matTooltip="The count of enabled notifications.">
        Enabled Notifications
      </th>
      <td mat-cell *matCellDef="let notification_role">
          <span style="text-decoration: underline;" [matTooltip]="getNotificationRoleSystemNotificationsForTooltip(notification_role)">
            {{ notification_role.system_notifications_count  }}
          </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="sites_count">
      <th mat-header-cell *matHeaderCellDef matTooltip="The count of associated sites.">
        Sites
      </th>
      <td mat-cell *matCellDef="let notification_role">
          <span style="text-decoration: underline;" [matTooltip]="getNotificationRoleSitesForTooltip(notification_role)">
            {{ notification_role.sites_count  }}
          </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="users_count">
      <th mat-header-cell *matHeaderCellDef [matTooltip]="'The count of associated ' + utils.getLangTerm('employees.plural', 'Employees').toLowerCase() + '.'">
        {{ utils.getLangTerm('employees.plural', 'Employees') }}
      </th>
      <td mat-cell *matCellDef="let notification_role">
          <span style="text-decoration: underline;" [matTooltip]="getNotificationRoleUsersForTooltip(notification_role)">
            {{ notification_role.users_count  }}
          </span>
      </td>
    </ng-container>

    <!-- Is Active Column -->
    <ng-container matColumnDef="is_active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Is Active
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.is_active ? 'Yes' : 'No' }}
      </td>
    </ng-container>

    <!-- Created At / Date Created Column -->
    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
      <td mat-cell *matCellDef="let element">{{ (element.date_created * 1000) | timezoneConvertMedium }}</td>
    </ng-container>

    <!-- Updated At / Modified At Column -->
    <ng-container matColumnDef="date_modified">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified At</th>
      <td mat-cell *matCellDef="let element">{{ (element.date_modified * 1000) | timezoneConvertMedium }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: listColumns" (click)="onToggleRowSelection(row)"></tr>
  </table>

  <div class="d-flex justify-content-end">
    <mat-paginator #paginator [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button mat-raised-button color="primary" (click)="onSelectMultiple()" *ngIf="listDataSource.multiple">
    Select
  </button>

  <button mat-button mat-raised-button color="secondary" (click)="onSelectAllRecords()">
    Select All ({{ listDataSource.allIds.length }})
  </button>

  <button mat-button mat-raised-button appMarginRight color="secondary" (click)="onDeselectAllRecords()" *ngIf="listDataSource.selection.selected.length > 0">
    Clear Selection
  </button>
</mat-dialog-actions>
