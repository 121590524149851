import { Component, OnInit, ViewChild } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ApiDataSource } from '../../../utils/api-data-source';
import { MatSort, Sort } from '@angular/material/sort';
import { AppService } from '../../../app.service';
import { ApiRequestService } from '../../../shared/api-request.service';
import { UtilsService } from '../../../shared/utils.service';
import { SystemNotificationEditComponent } from '../system-notification-edit/system-notification-edit.component';

@Component({
  selector: 'app-system-notifications',
  templateUrl: './system-notifications.component.html',
  styleUrls: ['./system-notifications.component.scss']
})
export class SystemNotificationsComponent implements OnInit {

  // The columns that should show in the list.
  listColumns: string[] = [
    'id',
    'identifier',
    'label',
    'feature',
    'is_site_related',
    'date_created',
    'date_modified',
    'actions'
  ];

  // The list data source.
  listDataSource: SystemNotificationsDataSource = new SystemNotificationsDataSource(this.app, this.api);

  // The list sorter.
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit(): void {
    // Get the data when the view loads.
    this.listDataSource.getData(true);
  }

  ngAfterViewInit(): void {
    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter: Sort): void => {
      this.listDataSource.order = sorter['direction'];
      this.listDataSource.getData(true);
    })).subscribe();
  }

  /**
   * Update an existing system notification.
   *
   * @param system_notification_id The id of the system notification.
   */
  onEditSystemNotification(system_notification_id?: number): void {
    this.utils.showComponentDialog(SystemNotificationEditComponent, {
      system_notification_id: system_notification_id
    }, {
      width: '50%'
    })
      .then((): void => {
        // Refresh the list after the dialog closed.
        this.listDataSource.getData();
      });
  }
}

/**
 * An API data source for retrieving system notifications.
 */
export class SystemNotificationsDataSource extends ApiDataSource {

  // Set the default order and order by data.
  order_by: string = 'identifier';
  order: string = 'asc';

  /**
   * Retrieves data from the API.
   *
   * @param {boolean} resetOffset - A flag indicating whether to reset the offset.
   * @returns {void}
   */
  getData(resetOffset: boolean = false): void {
    // Make a request to the API to get the data.
    this.makeRequest(`v2/system-notifications`, resetOffset);
  }
}

