import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ApiDataSource} from "../../utils/api-data-source";
import {IndustriesModel} from "../industries/industries.model";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiService} from "../../shared/api.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {tap} from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import {WorkPermitsEditComponent} from "./work-permits-edit/work-permits-edit.component";
import {WorkPermitsViewComponent} from "./work-permits-view/work-permits-view.component";
import {WorkPermitModel} from "./work-permits.model";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";


@Component({
  selector: 'app-work-permits',
  templateUrl: './work-permits.component.html',
  styleUrls: ['./work-permits.component.scss']
})
export class WorkPermitsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new WorkPermitsDataSource(this.app, this.api);

  industries: IndustriesModel[] = [];

  displayedColumns = [
    'select',
    'id',
    'title',
    'industry',
    'creator',
    'date_created',
    // 'date_created_UTC',
    'buttons'
  ];

  constructor(
    private utils: UtilsService,
    private app: AppService,
    private oldApi: ApiService,
    private api: ApiRequestService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource.getData(false);
    this.getIndustries();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      // Using date created when sorting by the UTC column.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData(false);
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  private async getIndustries() {
    this.industries = await this.utils.getIndustries();
  }

  onAdd() {
    this.onEdit(new WorkPermitModel());
  }

  onEdit(workPermit: WorkPermitModel) {
    this.dialog
      .open(WorkPermitsEditComponent, {
        width: '900px',
        data: workPermit
      })
      .afterClosed()
      .subscribe(() => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onView(id: number) {
    this.utils.showComponentDialog(
      WorkPermitsViewComponent,
      id,
      { width: '700px' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      }
    );
  }

  onRemoveSelected() {
    this.utils.showModal(
      'Remove Work Permit(s)',
      `Are you sure you want to remove ${this.dataSource.selection.selected.length} work permit(s)?`,
      () => {
        this.remove(this.dataSource.selection.selected);
      }
    );
  }

  onRemove(workPermit: WorkPermitModel) {
    this.utils.showModal(
      'Remove Work Permit',
      `Are you sure you want to remove "${workPermit.title}"?`,
      () => {
        this.remove([workPermit.id]);
      }
    );
  }

  private remove(ids: number[]) {
    this.api.makeRequest('delete', `v2/admin/work-permits/${ids.join(',')}`)
      .then((response) => {
        this.utils.showToast('Work Permits have been removed');
        this.dataSource.selection.clear();
        this.dataSource.getData(false);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class WorkPermitsDataSource extends ApiDataSource {
  order_by = 'title';
  order = 'asc';
  searchBy = 'title';
  industryFilter = 0;

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/admin/work-permits', resetOffset, {
      searchBy: this.searchBy,
      industryFilter: this.industryFilter
    });
  }
}
