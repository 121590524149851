import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {tap} from 'rxjs/operators';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { AppService } from '../../app.service';
import { UtilsService } from '../../shared/utils.service';
import { IncidentService } from '../incident.service';
import { IncidentsEditComponent } from '../incidents-edit/incidents-edit.component';
import {IncidentsFilterComponent} from "../incidents-filter/incidents-filter.component";
import { IncidentsTabsComponent } from '../incidents-tabs/incidents-tabs.component';
import {StorageService} from "../../shared/storage.service";
import _ from 'lodash';
import {
  MasterChartsPopupDialogComponent
} from "../../charts/master-charts-popup-dialog/master-charts-popup-dialog.component";
import {ChartsUtilsService} from "../../charts/charts-utils.service";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {IncidentsFilter} from "../incidents-filter/incidents-filter.interface";

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.css']
})
export class IncidentsComponent implements OnInit, AfterViewInit {

  // Incident filters storage key.
  incidentFiltersStorageKey: string = 'incidents_aid_' + this.app.account.id + '_filters';

  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'site',
    'details',
    'type',
    'status',
    'date_incident',
    // 'date_incident_UTC',
    'estimated_lti_days',
    'actual_lti_days',
    // 'actual_lti_hours',
    'created_by_user',
    'actions'
  ];

  dataSource = new IncidentsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    // public service: IncidentsService,
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private incidentService: IncidentService,
    private storage: StorageService,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit() {
    // Get the list filters.
    this.getListFilters();
    // Get the data from the API.
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;

      this.dataSource.order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active === "date_incident_UTC") {
        this.dataSource.order_by = "date_incident";
      } else {
        this.dataSource.order_by = this.sort.active;
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * Retrieves the list filters from storage and sets them to the DataSource object.
   * The returned filters include site IDs, user IDs, contractor IDs, tag IDs, incident type,
   * incident status, and incident dates.
   *
   * @returns {void}
   */
  getListFilters(): void {
    // Get and apply the incidents filters from storage or set default values.
    this.dataSource.filters = this.storage.get(this.incidentFiltersStorageKey) || {
      site_ids: [],
      user_ids: [],
      contractor_ids: [],
      tag_ids: [],
      type: '',
      status: '',
      date_range: []
    }
    // Remap the dates as new Date objects. Storing it in storage stores it as string.
    this.dataSource.filters.date_range = this.dataSource.filters.date_range.map((date: Date|string) => new Date(date));
  }

  async downloadPdf(id: number) {
    this.utils.showToast(
      'You can either wait or continue using the application ' +
      'while you wait for the incident report. You will automatically be redirected to it once it is finished.'
    );

    this.incidentService.export(id).subscribe((response) => {
      const link = document.createElement('a');
      link.href = response.data.url;
      link.target = '_blank';
      link.download = 'incident-report-2000.pdf';
      link.click();
    });
  }

  onAddIncident() {
    this.utils.showComponentDialog(IncidentsEditComponent, {}, {
        width: '90%'
    })
    .then(() => {
      this.dataSource.getData();
    })
  }

  onManageIncident(incident_id: number) {
    this.utils.showComponentDialog(IncidentsTabsComponent, {
      incident_id: incident_id
    }, {
        width: '90%'
    })
    .then(() => {
      // Refresh the list after the dialog closed.
      this.dataSource.getData();
    });
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Delete Incident',
      'Are you sure you want to delete this incident?',
      () => {
        this.api.makeRequest('delete', `v2/incidents/${id}`)
          .then(() => {
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
            this.utils.showToast('The incident was deleted.');
          })
          .catch((errorResponse) => {
            this.utils.showModal('Delete Incident Error', errorResponse.message);
          });
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Delete Selected Incidents',
      'Are you sure you want to delete the selected incidents?',
      () => {
        this.api.makeRequest('delete', `v2/incidents/${this.dataSource.selection.selected.join(',')}`)
          .then(() => {
            this.dataSource.selection.clear();
            this.dataSource.getData();
            this.utils.showToast('The selected incidents were deleted.');
          })
          .catch((errorResponse) => {
            this.utils.showModal('Delete Incidents Error', errorResponse.message);
          });
      }
    );
  }

  /**
   * Sets the tags for the filters and updates the incident filter preferences.
   * Retrieves the data from the API using the updated filters.
   *
   * @param {Array} $event - An array of tag IDs to be used as new filter tags.
   * @return {void}
   */
  getOutputTagsFilter($event) {
    // Set the tags for the filters.
    this.dataSource.filters.tag_ids = $event;
    // Update the incidents filter preferences.
    this.storage.set(this.incidentFiltersStorageKey, this.dataSource.filters);
    // Get the data from the API.
    this.dataSource.getData(true);
  }

  onFilter() {
    // Show the filters component.
    this.utils.showComponentDialog(IncidentsFilterComponent, {
      site_ids: this.dataSource.filters.site_ids,
      user_ids: this.dataSource.filters.user_ids,
      contractor_ids: this.dataSource.filters.contractor_ids,
      type: this.dataSource.filters.type,
      status: this.dataSource.filters.status,
      date_range: this.dataSource.filters.date_range
    }, {
      width: '1024px'
    }, (filters: any): void => {
      // Check if we received a valid filters object.
      if ( typeof filters !== 'undefined' && filters != false ) {
        // Update or reset the filters.
        this.dataSource.filters.site_ids = filters.site_ids || [];
        this.dataSource.filters.user_ids = filters.user_ids || [];
        this.dataSource.filters.contractor_ids = filters.contractor_ids || [];
        this.dataSource.filters.type = filters.type || '';
        this.dataSource.filters.status = filters.status || '';
        this.dataSource.filters.date_range = filters.date_range || [];
        // Update the incidents filter preferences.
        this.storage.set(this.incidentFiltersStorageKey, this.dataSource.filters);
        // Get the data from the API.
        this.dataSource.getData(true);
      }
    });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class IncidentsDataSource extends ApiDataSource {
  // Initial soring direction and field
  order_by = 'date_incident';
  order = 'desc';

  incident_date_range = "";

  // The filters to use for API requests.
  filters: IncidentsFilter = {
    site_ids: [],
    user_ids: [],
    contractor_ids: [],
    tag_ids: [],
    type: '',
    status: '',
    date_range: []
  };

  /**
   * Gets the data from the API based on the provided filters.
   *
   * @param {boolean} resetOffset - Flag indicating whether to reset the offset or not. Default is false.
   *
   * @return {void}
   */
  getData(resetOffset: boolean = false) {
    // Prepare the date range for filtering.
    this.incident_date_range = this.filters.date_range && this.filters.date_range.length > 1 ? [
      this.filters.date_range[0].getTime() / 1000,
      this.filters.date_range[1].getTime() / 1000
    ].join(',') : '';
    // Make the API request to get the data.
    this.makeRequest('v2/incidents', resetOffset, {
      site_ids: this.filters.site_ids.length ? this.filters.site_ids.join(',') : '',
      user_ids: this.filters.user_ids.length ? this.filters.user_ids.join(',') : '',
      contractor_ids: this.filters.contractor_ids.length ? this.filters.contractor_ids.join(',') : '',
      tag_ids: this.filters.tag_ids.length ? this.filters.tag_ids.join(',') : '',
      status: this.filters.status ? this.filters.status : '',
      type: this.filters.type ? this.filters.type : '',
      date_range: this.incident_date_range
    });
  }

  /**
   * Exports incidents data as a file of specified type.
   *
   * @param {string} type - The type of the exported file (default: 'pdf').
   * @param {number} id - The incident ID to export (optional).
   * @return {void} - No return value.
   */
  onExport(type: string = 'pdf', id?: number) {
    let ids: number[] = [];
    if (id) {
      ids.push(id);
    } else {
      ids.push(...this.selection.selected);
    }
    // Prepare the date range filter.
    this.incident_date_range = this.filters.date_range && this.filters.date_range.length > 1 ? [
      this.filters.date_range[0].getTime() / 1000,
      this.filters.date_range[1].getTime() / 1000
    ].join(',') : '';
    // Make the API request to download the data.
    this.api.makeDownloadRequest(`v2/export/incidents/${type}` + (ids.length > 0 ? ('/' + ids.join(',')) : ''), {}, {
      site_ids: this.filters.site_ids.length ? this.filters.site_ids.join(',') : '',
      user_ids: this.filters.user_ids.length ? this.filters.user_ids.join(',') : '',
      contractor_ids: this.filters.contractor_ids.length ? this.filters.contractor_ids.join(',') : '',
      tag_ids: this.filters.tag_ids.length ? this.filters.tag_ids.join(',') : '',
      status: this.filters.status ? this.filters.status : '',
      type: this.filters.type ? this.filters.type : '',
      date_range: this.incident_date_range
    }).then((response) => {
      saveAs(response, `${this.app.account.name} - Incidents.${type}`);
    });
  }

  /**
   * Checks if all filters, excluding specified properties, are empty.
   *
   * @param {string[]} propertiesToExclude - The list of property names to exclude from checking.
   * @returns {boolean} - True if all filters are empty, false otherwise.
   */
  isFiltersEmptyExcludingTags(propertiesToExclude: string[] = ['tag_ids']): boolean {
    // Get a list of property names to check in the filters.
    const filteredProperties = _.difference(Object.keys(this.filters), propertiesToExclude);
    // Check if all filters are empty.
    return _.every(filteredProperties, (key: string) => _.isEmpty(this.filters[key]));
  }
}
