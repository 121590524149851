<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class=" mr-auto">
    <button mat-raised-button routerLink="/sites" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>

    <button mat-raised-button *ngIf="site.parent_site_id" [routerLink]="'/sites/'+site.parent_site_id+'/children'" appMarginRight tourAnchor="tour.navigate.sites">
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
    </button>

    <button mat-raised-button [routerLink]="utils.prepareSiteRelatedLink(site.id, site.parent_site_id, 'edit/induction')" appMarginRight>
      <mat-icon>edit</mat-icon> Edit
    </button>
  </span>
</nav>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="site.induction_content">
        <h6>Noticeboard Content</h6>
        <div [innerHTML]="site.induction_content | sanitizeHtml"></div>
      </ng-container>
    </div>
  </div>
</div>
