import { Injectable } from '@angular/core';
import {DynamicFormModel} from "../dynamic-forms/dynamic-form.model";
import {SiteModel} from "../models/site.model";
import {UtilsService} from "./utils.service";

@Injectable({
  providedIn: 'root'
})
export class DynamicFormsService {

  constructor(
    private utils: UtilsService
  ) { }

  /**
   * Format the text display for assigned users.
   * Examples:
   *  - John Doe (User's Name)
   *    Brick Contractors (User's Employer)
   *  - 0 Users
   *  - 1 User
   *  - 2 Users
   *  - Etc.
   * @param form
   */
  formatAssignedUsersListColumnText(form: DynamicFormModel) {
    // Check if we have one user.
    if ( form.users_count == 1 ) {
      // Check if we have details of one user.
      if ( form.assigned_users[0] ) {
        return form.assigned_users[0].contact_person + '<br/>' + form.assigned_users[0].employer.name;
      }
      // Respond with a generic 1 User response.
      return form.users_count + ' User';
    }
    // Respond with the users count.
    return form.users_count + ' Users';
  }

  /**
   * Format the text display for associated sites.
   * Examples:
   *  - Head Office
   *  - 0 Sites
   *  - 1 Site (If name is not accessible)
   *  - 2 Sites
   *  - Etc.
   * @param form
   */
  formatSitesListColumnText(form: DynamicFormModel) {
    // Check if we have one user.
    if ( form.const_sites_count == 1 ) {
      // Check if we have details of one user.
      if ( form.const_sites[0] ) {
        return form.const_sites[0].name;
      }
      // Respond with a generic 1 User response.
      return form.const_sites_count + ' ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site');
    }
    // Respond with the users count.
    return form.const_sites_count + ' ' + this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites');
  }

  /**
   * The tooltip text is only used if there is more than 1 site.
   * @param form
   */
  formatSitesListColumnTooltip(form: DynamicFormModel) {
    if ( form.const_sites_count > 1 ) {
      const site_names: string[] = form.const_sites.map((site: SiteModel) => {
        return site.name;
      });
      return site_names.join(`\n`);
    }
    return '';
  }
}
