import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMarginTop]'
})
export class MarginTopDirective {
  constructor(element: ElementRef) {
    element.nativeElement.style.marginTop = '16px';
  }
}
