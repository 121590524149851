import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { AuditItemDataSource } from 'src/app/audit-items/audit-item.data.source';
import { AuditItemModel } from 'src/app/audit-items/audit-item.model';
import { AuditItemsService } from 'src/app/audit-items/audit-items.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-audit-items-selector',
  templateUrl: './audit-items-selector.component.html',
  styleUrls: ['./audit-items-selector.component.css']
})
export class AuditItemsSelectorComponent implements OnInit, AfterViewInit {
  @ViewChild('auditItems') auditItems: AuditItemModel[];
  displayedColumns: string[] = ['select', 'name', 'section', 'status'];
  dataSource: AuditItemDataSource;
  sections: string[];
  selectedSection: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<AuditItemsSelectorComponent>,
    private auditItemService: AuditItemsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataSource = new AuditItemDataSource(
      this.app,
      this.api,
      this.data['multiple']
    );
    this.dataSource.template = true;
    this.data.selected = this.data.selected || [];
    this.data.selected.forEach((selected) =>
      this.dataSource.selection.select(selected)
    );
    this.dataSource.getData();
  }

  ngOnInit() {
    this.auditItemService
      .getSections()
      .subscribe((response) => (this.sections = response.data));
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  filterSection() {
    this.dataSource.getDataForSection(false, this.selectedSection);
  }

  onClearSearch() {
    this.dataSource.search = '';
    this.dataSource.getData(true);
  }

  onClearSelected() {
    this.dataSource.selection.clear();
  }

  onSaveAndClose() {
    this.dialogRef.close(this.dataSource.selection.selected);
  }
}
