import { Auditable } from "../shared/auditable.model";
import { FileModel } from "../shared/file.model";
import { SsspModel } from "./sssp-model";

export class SsspDocModel extends Auditable {

  id: number = 0;
  sssp_id: number = 0;
  doc_type: string = 'other';
  doc_rating: number = 0;
  doc_comments: string = '';
  expires_at: number = 0;
  doc_status: string = 'submitted';

  files: FileModel[] = [];

  sssp: SsspModel = new SsspModel();

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          switch( key ) {
            case 'sssp':
              this[key].apply(data[key]);
              break;
            default:
              this[key] = data[key];
          }
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.sssp_id = 0;
    this.doc_type = 'other';
    this.doc_rating = 0;
    this.doc_comments = '';
    this.expires_at = 0;
    this.doc_status = 'required';
  }
}
