import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-account-tags-filter',
  templateUrl: './account-tags-filter.component.html',
  styleUrls: ['./account-tags-filter.component.scss']
})
export class AccountTagsFilterComponent implements OnInit {

  dataSource = new AccountTagsFilterDataSource(); // No API calls needed

  constructor(
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AccountTagsFilterComponent>
  ) {
    // set a default archived value of false if no values are passed through
    if (typeof this.data['filter_archived'] === 'undefined') {
      this.data['filter_archived'] = "false";
    }
  }

  ngOnInit() {
    this.dataSource.filter_archived = this.data['filter_archived'];
  }

  onClearAndClose() {
    this.dataSource.filter_archived = "false";

    this.onSaveAndClose();
  }

  onSaveAndClose() {
    let response: any;

    response = {
      archived: this.dataSource.filter_archived
    }

    this.dialogRef.close(response);
  }

}

/**
 * the data source class should contain everything needed to get data for the feature.
 */
export class AccountTagsFilterDataSource {
  filter_archived: string = 'false';

  constructor() {
    this.filter_archived = 'false';
  }
}
