import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicFormModel } from 'src/app/dynamic-forms/dynamic-form.model';
import { SiteModel } from 'src/app/models/site.model';
import { UserDynamicFormsTemplateSelectorComponent } from '../user-dynamic-forms-template-selector/user-dynamic-forms-template-selector.component';
import { UserAccessibleSitesSelectorComponent } from '../user-accessible-sites-selector/user-accessible-sites-selector.component';
import { isNumber } from 'lodash';
import { UserDynamicFormsEditComponent } from '../user-dynamic-forms-edit/user-dynamic-forms-edit.component';

@Component({
  selector: 'app-user-dynamic-forms-create-wizard',
  templateUrl: './user-dynamic-forms-create-wizard.component.html',
  styleUrls: ['./user-dynamic-forms-create-wizard.component.scss']
})
export class UserDynamicFormsCreateWizardComponent implements OnInit {

  selected_form: DynamicFormModel = {} as DynamicFormModel;

  selected_site: SiteModel = {} as SiteModel;

  constructor(
    public utils: UtilsService,
    private api: ApiRequestService,
    private dialogRef: MatDialogRef<UserDynamicFormsCreateWizardComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { form_id?: number }
  ) { }

  ngOnInit() { }

  /**
   * Open the accessible sites selector for the authenticated user.
   */
  onSelectSiteFromAccessibleSite() {
    // Open the accessible sites selector.
    this.utils.showComponentDialog(UserAccessibleSitesSelectorComponent, {
      selected: [this.selected_site.id],
      multiple: false,
      return_object: true
    }).then((response: SiteModel | number | number[]) => {
      // Validate the selection response.
      if ( typeof response != 'undefined' ) {
        if ( !Number(response) ) {
          this.selected_site = response as SiteModel;
        }
      }
    });
  }

  /**
   * Open the form template selector only if a site was already selected.
   * @returns
   */
  onSelectFormFromTemplates() {
    // Make sure a site was previously selected.
    if ( !this.selected_site.id ) {
      this.utils.showModal('Create Form', 'You first need to select a ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() + ' before you can select from form templates.');
      return;
    }
    // Open the form template selector.
    this.utils.showComponentDialog(UserDynamicFormsTemplateSelectorComponent, {
      selected: [this.selected_form.id],
      selected_site_ids: [this.selected_site.id],
      multiple: false,
      return_object: true
    }).then((response) => {
      // Validate the selection response.
      if ( typeof response != 'undefined' ) {
        if ( !isNumber(response) ) {
          this.selected_form = response as DynamicFormModel;
        }
      }
    });
  }

  /**
   * Submits the form for completion.
   * @param form
   * @returns
   */
  onSubmitForm(form: NgForm) {
    // Make sure the submitted form data is valid and that we have a site and form template selected.
    if ( !form.valid || !this.selected_site.id || !this.selected_form.id ) {
      this.utils.showFormValidationError('Please select a ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site') + ' and form template to get started.');
      return;
    }
    // Get user confirmation before creating the form.
    this.utils.showModal('Create Form', 'Are you sure you want to create this form?', () => {
      this.api.makeRequest('post', `v2/user/copy-form-template`, {
        site_id: this.selected_site.id,
        form_id: this.selected_form.id
      })
      .then((response: DynamicFormModel) => {
        // Close the dialog and navigate the user to the newly created form.
        this.dialogRef.close();
        // Open the form for the user to complete.
        this.utils.showComponentDialog(UserDynamicFormsEditComponent, {
          form_id: response.id
        });
      })
      .catch((errorResponse: any) => {
        this.utils.handleAPIErrors(errorResponse);
      });
    });
  }
}
