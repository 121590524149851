<div class="app-padding">
  <h5>Inspection/Audit Question</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>

  <form #form="ngForm" (ngSubmit)="onSubmit()">
    <mat-form-field class="audit-item-field">
      <input
        matInput
        placeholder="Question"
        [(ngModel)]="auditItem.name"
        name="name"
        type="text"
        required
        #name />
      <mat-hint>Please enter an Item name. </mat-hint>
    </mat-form-field>

    <mat-form-field class="audit-item-field">
      <input
        matInput
        placeholder="Section"
        [(ngModel)]="auditItem.section"
        name="section"
        type="text"
        required
        #section />
      <mat-hint>Please enter a section.</mat-hint>
    </mat-form-field>

    <button
      mat-raised-button
      [disabled]="!form.valid || inProgress"
      color="primary"
      type="submit">
      <mat-icon>save</mat-icon>
      Save
    </button>

    <button mat-icon-button class="float-right" [disabled]="!auditItem.id">
      <mat-icon>delete</mat-icon>
    </button>
  </form>
</div>
