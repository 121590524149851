import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErpTeamsService } from '../erp-teams.service';
import {UtilsService} from "../../../shared/utils.service";

@Component({
  selector: 'app-erp-teams',
  templateUrl: './erp-teams.component.html',
  styleUrls: ['./erp-teams.component.css']
})
export class ErpTeamsComponent implements OnInit {
  parent_id: number;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public service: ErpTeamsService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    // get the site id
    this.parent_id = Number(this.route.snapshot.params['erp_id']);

    if (this.parent_id) {
      // get the list of codes
      this.service.get(this.parent_id, 0, true);
    } else {
      // redirect back to sites because the site id is not valid
      this.router.navigate(['erps']);
    }
  }

  onRemove(id: number) {
    this.service.remove(this.parent_id, id);
  }
}
