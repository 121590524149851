import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contractors-importer',
  templateUrl: './contractors-importer.component.html',
  styleUrls: ['./contractors-importer.component.css']
})
export class ContractorsImporterComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
