<div class="container-fluid">

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">{{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }} Worked On</span>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <ng-container *ngIf="includeAdminActions">
          <button mat-raised-button (click)="onAddSites()">
            <mat-icon>add</mat-icon> Add {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
          </button>

          <mat-menu #appMenu="matMenu">
            <button mat-menu-item (click)="dataSource.selectAllToggle()">
              <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
              {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
            </button>

            <button mat-menu-item (click)="onExport()" >
            <mat-icon>cloud_download</mat-icon>
            Export to CSV
          </button>

            <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
              <mat-icon>link_off</mat-icon> Unlink Selected
            </button>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to link/unlink or export {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}." style="min-width: 120px">
            <mat-icon>more_vert</mat-icon>
            List Menu
          </button>
        </ng-container>
      </span>

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (ngSubmit)="dataSource.getData(true)">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search"/>

        <button
          mat-raised-button
          color="secondary"
          type="submit"
          class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table class="w-100" mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
      <!-- SELECT COLUMN -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="65px">
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let site">{{ site.id }}</td>
      </ng-container>

      <!-- Owner Column -->
      <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef>Owner</th>
        <td mat-cell *matCellDef="let site">{{ site?.account?.name }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let site">
          <ng-container *ngIf="site?.parent_site">
            <small style="display: block;">
              <a *ngIf="includeAdminActions; else parentSiteNameOnly" [routerLink]="utils.prepareSiteRelatedLink(site?.parent_site?.id, null, '/edit/details')">
                {{ site?.parent_site?.name || '' }}
              </a>
              <ng-template #parentSiteNameOnly>
                {{ site?.parent_site?.name || '' }}
              </ng-template>
            </small>
            <mat-icon style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
          </ng-container>
          <a *ngIf="includeAdminActions; else siteNameOnly" [routerLink]="utils.prepareSiteRelatedLink(site?.id, site?.parent_site?.id, '/edit/details')">
            {{ site?.name || '' }}
          </a>
          <ng-template #siteNameOnly>
            {{ site?.name || '' }}
          </ng-template>
        </td>
      </ng-container>

      <!-- Location Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Address</th>
        <td mat-cell *matCellDef="let site">{{ site.location }}</td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button type="button" mat-button *ngIf="includeAdminActions" (click)="onRemove(element.id)" matTooltip="Unlink the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}.">
            Unlink
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>

</div>
