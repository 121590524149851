import {Component, Inject, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TaskAnalysesRevisedWorkflowModel} from "../../models/task-analyses-revised-workflow.model";
import {SitesTaskAnalysesRevisedVersioningComponent} from "../sites-task-analyses-revised-versioning/sites-task-analyses-revised-versioning.component";
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-sites-task-analyses-revised-workflows-steps',
  templateUrl: './sites-task-analyses-revised-workflows-steps.component.html',
  styleUrls: ['./sites-task-analyses-revised-workflows-steps.component.scss']
})
export class SitesTaskAnalysesRevisedWorkflowsStepsComponent implements OnInit {

  ta_revised_id: number;
  site_id: number;
  path: string;

  ta_revised_reordered_workflow: TaskAnalysesRevisedWorkflowModel[] = [];
  ta_revised: TaskAnalysesRevisedModel = new TaskAnalysesRevisedModel();
  inProgress: boolean = false;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    private dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<SitesTaskAnalysesRevisedWorkflowsStepsComponent>
  ) { }

  ngOnInit() {

    // get the task analysis id from the parent route params
    this.ta_revised_id = this.dialogData.ta_revised_id;

    // get the site id from the parent route params
    this.site_id = this.dialogData.site_id;

    // get sites path edit/view
    this.path = this.dialogData.path;

    // Get Unique Names for Workflow Steps
    this.getWorkflowSteps(this.ta_revised_id, this.site_id);

    this.findTA(this.ta_revised_id);
  }

  private findTA(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {
      site_id: this.site_id
    })
      .then((response) => {
        this.ta_revised = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private async getWorkflowSteps(ta_revised_id: number, site_id:number = 0) {
    this.ta_revised_reordered_workflow = await this.utils.getWorkflowSteps(ta_revised_id, site_id);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.ta_revised_reordered_workflow, event.previousIndex, event.currentIndex);
    this.onUpdateOrder(this.ta_revised_reordered_workflow);
  }

  onUpdateOrder(ta_revised_reordered_workflow: TaskAnalysesRevisedWorkflowModel[]) {
    ta_revised_reordered_workflow.forEach((step, index) => {
      this.ta_revised_reordered_workflow[index].step_no = index + 1;
    });
  }

  onSaveOrder() {
    this.acknowledgementCheck('reorder');
  }

  continueOnSaveOrder() {
    this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised_id}/workflow-steps`, this.ta_revised_reordered_workflow, {
        site_id: this.site_id
      })
      .then((response) => {
        this.utils.showToast('TA/JSA/SWMS Workflow Steps reordered.');
        this.dialogRef.close();
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  private acknowledgementCheck(crud_action: string) {
    this.api
      .makeRequest('get', `v2/task-analyses-revised/${this.ta_revised_id}/acknowledgement`, {}, {
        site_id: this.site_id
      })
      .then((response) => {
        if(response.data.acknowledgementsDone) {
          this.beforeVersioning(crud_action);
        } else {
          switch(crud_action) {
            case "reorder":
              this.continueOnSaveOrder();
              break;
            default:
            // do nothing
          }
        }
      });
  }

  private beforeVersioning(crud_action: string) {
    this.utils.showComponentDialog(
      SitesTaskAnalysesRevisedVersioningComponent,
      {
        task_name: this.ta_revised.task_name
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          // If confirmed then begin versioning of Task Analysis
          if(results.confirmed) {
            this.createNewVersion(results.amendment_title, results.reason_for_amendment, crud_action);
          }
        }
      }
    );
  }

  private createNewVersion(amendment_title: string, reason_for_amendment: string, crud_action: string) {
    this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised_id}/workflows-version`, this.ta_revised_reordered_workflow, {
        site_id: this.site_id,
        workflow_id: 0,
        amendment_title: amendment_title,
        reason_for_amendment: reason_for_amendment,
        crud_action: crud_action,
        remove_ids: ''
      })
      .then((response) => {
        this.utils.showToast(`Created a new version of the "${this.ta_revised.task_name}" TA/JSA/SWMS.`);
        this.dialogRef.close('version');
        this.redirectBack();
      });
  }

  private redirectBack() {
    if(this.site_id && this.path) {
      this.router.navigate([`/sites/${this.site_id}/${this.path}/task-analyses-revised/`]);
    }
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close();
  }

}
