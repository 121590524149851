import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiDataSource } from "../utils/api-data-source";
import { UtilsService } from "../shared/utils.service";
import { AppService } from "../app.service";
import { ApiRequestService } from "../shared/api-request.service";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { tap } from "rxjs/operators";
import { TaskAnalysesRevisedViewComponent } from "./task-analyses-revised-view/task-analyses-revised-view.component";
import { TaskAnalysesRevisedFilterComponent } from "./task-analyses-revised-filter/task-analyses-revised-filter.component";
import { TaskAnalysesRevisedSelectorComponent } from "../shared/task-analyses-revised-selector/task-analyses-revised-selector.component";
import { TaskAnalysesRevisedAddComponent } from "./task-analyses-revised-add/task-analyses-revised-add.component";
import { TaskAnalysesRevisedModel } from '../models/task-analyses-revised.model';
import {UserPublicProfileComponent} from "../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-task-analyses-revised',
  templateUrl: './task-analyses-revised.component.html',
  styleUrls: ['./task-analyses-revised.component.scss']
})
export class TaskAnalysesRevisedComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new TaskAnalysesRevisedDataSource(this.app, this.api);

  displayedColumns = [
    'select',
    'id',
    'task_name',
    'approved',
    'authorized',
    'supervisor_acknowledged',
    'created_by_user',
    'date_created',
    // 'date_created_UTC',
    'buttons',
  ];

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.dataSource.order_by = "date_created";
      } else {
        this.dataSource.order_by = this.sort.active;
      }


      this.dataSource.getData(false);
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onEdit(new TaskAnalysesRevisedModel());
  }

  onEdit(ta_revised: TaskAnalysesRevisedModel) {
    // We should use this.utils.showComponentDialog() instead.
    this.dialog
      .open(TaskAnalysesRevisedAddComponent, {
        width: '900px',
        data: {
          ta_revised: ta_revised
          // path: this.path
        }
      })
      .afterClosed()
      .subscribe(() => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onClone(ta_revised: TaskAnalysesRevisedModel) {
    const newTARevised = new TaskAnalysesRevisedModel();
    newTARevised.cloneFrom(ta_revised);
    this.onEdit(newTARevised);
  }

  onView(id: number) {
    this.utils.showComponentDialog(
      TaskAnalysesRevisedViewComponent,
      id,
      { width: '700px' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      }
    );
  }

  onRemoveSelected() {
    this.utils.showModal(
      'Archive TA/JSA/SWMS Template',
      `Are you sure you want to archive ${this.dataSource.selection.selected.length} TA/JSA/SWMS templates?`,
      () => {
        this.remove(this.dataSource.selection.selected);
      }
    );
  }

  onRemove(ta_revised: TaskAnalysesRevisedModel) {
    this.utils.showModal(
      'Archive TA/JSA/SWMS Template',
      `Are you sure you want to archive the "${ta_revised.task_name}" TA/JSA/SWMS template?`,
      () => {
        this.remove([ta_revised.id]);
      }
    );
  }

  private remove(ids: number[]) {
    this.api.makeRequest('delete', `v2/task-analyses-revised/${ids.join(',')}`)
      .then((response) => {
        this.utils.showToast('The TA/JSA/SWMS templates have been archived.');
        this.dataSource.selection.clear();
        this.dataSource.getData(false);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onFilter() {
    this.utils.showComponentDialog(
      TaskAnalysesRevisedFilterComponent,
      {
        selected_approver_ids: this.dataSource.approver_ids,
        selected_authorizer_ids: this.dataSource.authorizer_ids,
        selected_supervisor_ids: this.dataSource.supervisor_ids,
        selected_ppe_ids: this.dataSource.ppe_ids,
        selected_work_permit_ids: this.dataSource.work_permit_ids,
        selected_tool_ids: this.dataSource.tool_ids,
        filter_approver_status: this.dataSource.approver_status,
        filter_authorizer_status: this.dataSource.authorizer_status,
        filter_supervisor_status: this.dataSource.supervisor_status,
        date_range: this.dataSource.date_range,
        filter_archived: this.dataSource.archived
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.approver_ids = results.approver_ids ? results.approver_ids : this.dataSource.approver_ids;
          this.dataSource.authorizer_ids = results.authorizer_ids ? results.authorizer_ids : this.dataSource.authorizer_ids;
          this.dataSource.supervisor_ids = results.supervisor_ids ? results.supervisor_ids : this.dataSource.supervisor_ids;
          this.dataSource.ppe_ids = results.ppe_ids ? results.ppe_ids : this.dataSource.ppe_ids;
          this.dataSource.work_permit_ids = results.work_permit_ids ? results.work_permit_ids : this.dataSource.work_permit_ids;
          this.dataSource.tool_ids = results.tool_ids ? results.tool_ids : this.dataSource.tool_ids;
          this.dataSource.approver_status = results.approver_status ? results.approver_status : this.dataSource.approver_status;
          this.dataSource.authorizer_status = results.authorizer_status ? results.authorizer_status : this.dataSource.authorizer_status;
          this.dataSource.supervisor_status = results.supervisor_status ? results.supervisor_status : this.dataSource.supervisor_status;
          this.dataSource.date_range = results.date_range ? results.date_range : this.dataSource.date_range;
          this.dataSource.archived = results.archived ? results.archived : this.dataSource.archived;
          this.dataSource.getData();
        }
      }
    );
  }

  onRestore(id: number) {
    this.utils.showModal(
      'Restore the TA/JSA/SWMS template',
      'Are you sure you want to restore this TA/JSA/SWMS template?',
      () => {
        this.api.makeRequest('put', `v2/task-analyses-revised/${id}/restore`)
          .then((response) => {
            this.utils.showToast('The TA/JSA/SWMS template was restored.');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  onRestoreSelected() {
    this.utils.showModal(
      'Restore Selected TA/JSA/SWMS templates',
      'Are you sure you want to restore the selected TA/JSA/SWMS templates?',
      () => {
        this.api.makeRequest('put', `v2/task-analyses-revised/${this.dataSource.selection.selected.join(',')}/restore`)
          .then((response) => {
            this.utils.showToast('The selected TA/JSA/SWMS templates was restored.');
            this.dataSource.selection.clear();
            this.dataSource.getData();
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  onSelectTemplates() {
    this.utils.showComponentDialog(
      TaskAnalysesRevisedSelectorComponent,
      {
        multiple: true,
        global: true
      },
      {},
      (results) => {
        if (typeof results !== 'undefined') {
          this.onCopyTemplates(results.length ? results.join(',') : '');
        }
      }
    );
  }

  onCopyTemplates(selected_ta_ids) {
    this.api.makeRequest('put', `v2/task-analyses-revised`, [], {
      selected_ta_ids: selected_ta_ids,
      global: true
    })
      .then((response) => {
        this.dataSource.getData(true);
        this.utils.showToast('The TA/JSA/SWMS template(s) copied to account.');
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class TaskAnalysesRevisedDataSource extends ApiDataSource {
  order_by = 'id';
  order = 'desc';
  searchBy = 'task_name';

  // Filter Variables
  approver_ids = [];
  authorizer_ids = [];
  supervisor_ids = [];
  ppe_ids = [];
  work_permit_ids = [];
  tool_ids = [];
  approver_status = 'all';
  authorizer_status = 'all';
  supervisor_status = 'all';
  date_range: Date[] = [];
  archived = "false";

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/task-analyses-revised', resetOffset, {
      searchBy: this.searchBy,
      approver_ids: this.approver_ids ? this.approver_ids.join(',') : "",
      authorizer_ids: this.authorizer_ids ? this.authorizer_ids.join(',') : "",
      supervisor_ids: this.supervisor_ids ? this.supervisor_ids.join(',') : "",
      ppe_ids: this.ppe_ids.length ? this.ppe_ids.join(',') : '',
      work_permit_ids: this.work_permit_ids.length ? this.work_permit_ids.join(',') : '',
      tool_ids: this.tool_ids.length ? this.tool_ids.join(',') : '',
      approver_status: this.approver_status,
      authorizer_status: this.authorizer_status,
      supervisor_status: this.supervisor_status,
      date_range: this.date_range && this.date_range.length > 1 ? [
        this.date_range[0].getTime() / 1000,
        this.date_range[1].getTime() / 1000
      ].join(',') : "",
      archived: this.archived
    });
  }
}


