import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FolderLinkModel} from "../folder-link.model";

@Component({
  selector: 'app-user-public-profile-training-doc-links',
  templateUrl: './user-public-profile-training-doc-links.component.html',
  styleUrls: ['./user-public-profile-training-doc-links.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class UserPublicProfileTrainingDocLinksComponent implements OnInit {

  links: FolderLinkModel[];
  name: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      links: FolderLinkModel[],
      name: string
    }
  ) { }

  ngOnInit() {
    this.links = this.data.links;
    this.name = this.data.name;
  }

}
