<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/erps">ERP's</a></li>
    <li class="breadcrumb-item">
      <a routerLink="/erps/{{ parent_id }}/teams">ERP Team Members</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">View</li>
  </ol>
</nav>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <ng-container *ngIf="service.item.name">
        <h6>Full Name</h6>
        <p>{{ service.item.name }}</p>
      </ng-container>

      <ng-container *ngIf="service.item.role">
        <h6>Role / Responsibility</h6>
        <p>{{ service.item.role }}</p>
      </ng-container>

      <ng-container *ngIf="service.item.training_required">
        <h6>Training Required</h6>
        <p>{{ service.item.training_required }}</p>
      </ng-container>

      <ng-container *ngIf="service.item.phone">
        <h6>Contact Number</h6>
        <p>{{ service.item.phone }}</p>
      </ng-container>

      <ng-container *ngIf="service.item.email">
        <h6>Email Address</h6>
        <p>{{ service.item.email }}</p>
      </ng-container>
    </div>
    <div class="col-12 col-md-6"></div>
  </div>
</div>
