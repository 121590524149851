
import { FileModel } from "../shared/file.model";
import { AccountModel } from "./account.model";
import { SiteModel } from "./site.model";
import { UserModel } from "./user.model";
import {Auditable} from "../shared/auditable.model";

export class SafetyObservation extends Auditable {

  id: number = 0;

  account_id: number = 0;
  site_id: number = 0;

  so_category: string = '';
  so_type: string = '';
  so_priority: string = 'Moderate'; // Can only be very low, low, moderate, high or critical.
  so_status: string = 'Open'; // Can only be open, review in progress, closed or archived.
  so_rating: number = 3; // Can only be 1-5.
  so_description: string = '';
  so_action_taken: string = '';

  files: FileModel[] = [];

  site: SiteModel = new SiteModel();

  contractors: AccountModel[] = [];

  users: UserModel[] = [];

  // Extend some super class for this.
  created_by_user: UserModel = new UserModel();
  updated_by_user: UserModel = new UserModel();

  // Additional form data that is not stored in the database.
  contractor_ids: number[] = [];
  user_ids: number[] = [];

  /**
   * Who reported this Safety Observation?
   */
  reported_by_user: UserModel = new UserModel();
  reported_by: number = 0;
  reported_by_employer: AccountModel = new AccountModel();
  reported_by_employer_id: number = 0;

  // The date the safety observation was reported at.
  reported_date: number = null;

  apply(data: any) {
    if ( data ) {
      this.reset();
      Object.keys(data).forEach((key) => {
        if (key in this) {
          switch ( key ) {
            case 'files':
              this[key].length = 0;
              data[key].forEach((file: FileModel) => {
                this[key].push(file);
              });
              break;
            case 'site':
              this.site.apply(data[key]);
              break;
            case 'created_by_user':
              this.created_by_user.apply(data[key]);
              break;
            case 'updated_by_user':
              this.updated_by_user.apply(data[key]);
              break;
            case 'reported_by_user':
              this.reported_by_user.apply(data[key]);
              break;
            default:
              this[key] = data[key];
          }
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;

    this.account_id = 0;
    this.site_id = 0;

    this.so_category = '';
    this.so_type = '';
    this.so_priority = 'moderate'; // Can only be very low, low, moderate, high or critical.
    this.so_status = 'open'; // Can only be open, review in progress, closed or archived.
    this.so_rating = 3; // Can only be 1-5.
    this.so_description = '';

    this.date_created = 0;
    this.date_modified = 0;
    this.deleted_at = 0;

    this.created_by = 0;
    this.updated_by = 0;
    this.deleted_by = 0;

    this.files.length = 0;
    this.contractors.length = 0;
    this.users.length = 0;

    this.contractor_ids.length = 0;
    this.user_ids.length = 0;

    this.reported_date = null;
  }
}
