import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodesComponent } from './codes.component';
import { CodesViewComponent } from './codes-view/codes-view.component';
import { CodesEditComponent } from './codes-edit/codes-edit.component';
import { Routes, RouterModule } from '@angular/router';
import { MATERIAL_MODULES } from '../../app.const';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { CodesAddManyComponent } from './codes-add-many/codes-add-many.component';
import { AuthGuard } from '../../guards/auth.guard';
import { PipesModule } from '../../pipes/pipes.module';
import {CodesFilterComponent} from "./codes-filter/codes-filter.component";
import {DirectivesModule} from "../../directives/directives.module";

const routes: Routes = [
  {
    path: 'admin/codes',
    component: CodesComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'admin/codes/:id/edit',
    component: CodesEditComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'admin/codes/:id/view',
    component: CodesViewComponent,
    canActivate: [
      AuthGuard
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ...MATERIAL_MODULES,
    ClipboardModule,
    PipesModule,
    DirectivesModule
  ],
  declarations: [
    CodesComponent,
    CodesViewComponent,
    CodesEditComponent,
    CodesAddManyComponent,
    CodesFilterComponent
  ],
  entryComponents: [
    CodesAddManyComponent,
    CodesFilterComponent
  ]
})
export class CodesModule {}
