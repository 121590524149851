<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle Users Menu">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="primary" id="add" [routerLink]="['/admin', 'users', 'new', 'edit']" appMarginRight>
          Add User
        </button>

        <button mat-raised-button color="secondary" id="import" [routerLink]="['/admin', 'users', 'documents', 'importer']" appMarginRight>
          Import User Documents
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.deselectAll()">
            Deselect All
          </button>

          <button mat-menu-item (click)="composeNewMessage()" [disabled]="!dataSource.selection.hasValue()" matTooltip="Click this button to send a message to the selected users.">
            Send Message
          </button>
        </mat-menu>

        <button mat-button [matMenuTriggerFor]="appMenu"
          matTooltip="System Administrators Menu" *ngIf="dataSource.selection.hasValue()">
          <mat-icon>more_vert</mat-icon>
          Menu
        </button>
      </span>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
        <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search"
          placeholder="Search Users" aria-label="Search" id="search-input"
          matTooltip="Search all users by name." />
      </form>

      <button mat-raised-button color="secondary" (click)="dataSource.getData(true)" appMarginRight id="search-button"
        matTooltip="Search all users.">
        <mat-icon>search</mat-icon> Search
      </button>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort matSortActive="id" matSortDirection="asc" matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Select All Visible Users">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let user">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(user.id) : null"
            [checked]="dataSource.selection.isSelected(user.id)"
            matTooltip="Select User {{user.id}}">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by ID">
          #
        </th>
        <td mat-cell *matCellDef="let user">
          {{ user.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="icons">
        <th mat-header-cell *matHeaderCellDef>Status Icons</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon matTooltip="Connected to the {{ utils.getAppName() }} mobile app{{ element.version ? (', using version ' + element.version) : ''}}." *ngIf="element.device_id">
            phonelink_setup
          </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="contact_person">
        <th mat-header-cell mat-sort-header *matHeaderCellDef   matTooltip="Sort by Full Name">
          Full Name
        </th>
        <td mat-cell *matCellDef="let user">
          <a (click)="onUserPublicView(user.hash)"
             matTooltip="View User Public Profile">
            {{ user.contact_person }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by Email Address">
          Email Address
        </th>
        <td mat-cell *matCellDef="let user">
          {{ user.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="mobile_country_code">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by {{ utils.getLangTerm('users.list.mobile_country_code', 'Mobile Country Code') }}">
          {{ utils.getLangTerm('users.list.mobile_country_code', 'Mobile Country Code') }}
        </th>
        <td mat-cell *matCellDef="let user">
          {{ user.mobile_country_code }}
        </td>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by {{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}">
          {{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}
        </th>
        <td mat-cell *matCellDef="let user">
          {{ user.mobile }}
        </td>
      </ng-container>

      <ng-container matColumnDef="employer">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by Employer">
          Employer
        </th>
        <td mat-cell *matCellDef="let user">
          {{ '#' + user.account_id }} <br>
          {{ user.employer }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by Date Joined">
          Date Joined
        </th>
        <td mat-cell *matCellDef="let user">
          {{ (user.date_created * 1000) | timezoneConvertShort }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by Date Joined">
          Date Joined(UTC)
        </th>
        <td mat-cell *matCellDef="let user">
          {{ (user.date_created * 1000) | timezoneConvertUTCShort }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
          <!--button type="button" mat-button routerLink="/admin/users/{{user.id}}/view">
            View
          </button-->

          <a type="button" mat-button routerLink="/admin/users/{{user.id}}/edit">
            Edit
          </a>

          <button type="button" mat-button (click)="composeNewMessage(user.id)" matTooltip="Click this button to send a message to this user.">
            Send Message
          </button>

          <button type="button" mat-button (click)="onUnlockUser(user.id)" matTooltip="Click this button to unlock this user for login.">
            Unlock
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
