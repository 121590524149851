<h5 mat-dialog-title>
  Category
  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</h5>

<div mat-dialog-content>

  <p>Category # {{ category.id }}</p>

  <p>Category: {{ category.category }}</p>

  <p>Created on {{ (category.date_created * 1000) | timezoneConvertMedium }} {{ category.created_by_user ? ('by ' + category.created_by_user.contact_person) : '' }} </p>

  <p *ngIf="category.date_modified">Last Updated on {{ (category.date_modified * 1000) | timezoneConvertMedium }} {{ category.updated_by_user ? ('by ' + category.updated_by_user.contact_person) : '' }} </p>

  <ng-container *ngIf="category.forms && category.forms.length > 0">
    <h5>Associated Forms</h5>
    <mat-list>
      <mat-list-item *ngFor="let dynamic_form of category.forms"> #{{ dynamic_form.id }} - {{ dynamic_form.title }} ({{ dynamic_form.is_active ? 'Active' : 'Inactive' }}) </mat-list-item>
     </mat-list>
  </ng-container>

</div>
