<div class="mt-3">
  <div class="d-flex">
    <h5>Notification Users</h5>

    <span style="flex: 1 1 auto;"></span>

    <mat-menu #appMenu="matMenu">
      <button type="button" mat-menu-item (click)="dataSource.selectAllToggle()">
        <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
        {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
      </button>
      <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onDeleteSelectedUsers()" [disabled]="!dataSource.selection.hasValue()">
        <mat-icon>archive</mat-icon> Archive Selected
      </button>
    </mat-menu>

    <button type="button" mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to perform list actions." style="min-width: 120px">
      <mat-icon>more_vert</mat-icon>
      List Menu
    </button>

    <button mat-raised-button type="button" color="secondary" class="mr-1" (click)="onSelectUsers()" [matBadge]="'' + (notification_user_ids.length)">Users</button>

    <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">

      <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search" aria-label="Search" id="search-input" matTooltip="Search for forms by title." />

      <button mat-raised-button color="secondary" appMarginRight id="search-button" matTooltip="Run the current search">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>
  </div>
</div>

<div>

  <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

  <table mat-table [dataSource]="dataSource" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>

    <caption class="px-3">This is a list of all users that will be notified when the form is submitted.</caption>

    <!-- Selection Toggle -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef id="th-select">
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
          matTooltip="Toggle selection of all assigned users in the visible list.">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let user">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(user.id) : null"
          [checked]="dataSource.selection.isSelected(user.id)" matTooltip="Toggle selection of this assigned user.">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- ID -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-id" matTooltip="Toggle sorting by assigned user ID.">
        #
      </th>
      <td mat-cell *matCellDef="let user">
        {{ user.id }}
      </td>
    </ng-container>

    <!-- Employer -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-name" matTooltip="Toggle sorting by employer name.">
        Employer
      </th>
      <td mat-cell *matCellDef="let user">
        {{ user.employer.name }}
      </td>
    </ng-container>

    <!-- Full Name -->
    <ng-container matColumnDef="contact_person">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-contact_person" matTooltip="Toggle sorting by assigned user name.">
        Full Name
      </th>
      <td mat-cell *matCellDef="let user">
        <a (click)="onUserPublicView(user.hash)"
           matTooltip="View User Public Profile">
          {{ user.contact_person }}
        </a>
      </td>
    </ng-container>

    <!-- Email Address -->
    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-email" matTooltip="Toggle sorting by assigned user email address.">
        Email Address
      </th>
      <td mat-cell *matCellDef="let user">
        {{ user.email }}
      </td>
    </ng-container>

    <!-- Date Created -->
    <ng-container matColumnDef="date_created">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created" matTooltip="Toggle sorting by creation date.">
        Created On
      </th>
      <td mat-cell *matCellDef="let user">
        {{ (user.date_created * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <!-- Date Created (UTC) -->
    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created_utc" matTooltip="Toggle sorting by creation date.">
        Created On (UTC)
      </th>
      <td mat-cell *matCellDef="let user">
        {{ (user.date_created * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <!-- Date Modifeid -->
    <ng-container matColumnDef="date_modified">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_modified" matTooltip="Toggle sorting by the date the form was last updated on.">
        Last Updated On
      </th>
      <td mat-cell *matCellDef="let user">
        {{ (user.date_modified * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef id="th-actions"></th>
      <td mat-cell *matCellDef="let user">
        <button type="button" mat-button (click)="onDeleteUser(user, $event)" matTooltip="Remove the user form the list of users to notify.">
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
</div>
