import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DynamicFormsCategoriesComponent } from './dynamic-forms-categories/dynamic-forms-categories.component';
import { DynamicFormsCategoriesEditComponent } from './dynamic-forms-categories-edit/dynamic-forms-categories-edit.component';
import { DynamicFormsCategoriesViewComponent } from './dynamic-forms-categories-view/dynamic-forms-categories-view.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: 'dynamic-forms-categories',
    component: DynamicFormsCategoriesComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'dynamic-forms-categories/:category_id/edit',
    component: DynamicFormsCategoriesEditComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'dynamic-forms-categories/:category_id/view',
    component: DynamicFormsCategoriesViewComponent,
    canActivate: [
      AuthGuard
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DynamicFormsCategoriesRoutingModule {}
