import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import { SiteModel } from 'src/app/models/site.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SitesService } from 'src/app/shared/sites.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { faHardHat, faFirstAid } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from 'src/app/shared/api.service';
import {ApiRequestService} from "../../shared/api-request.service";

@Component({
  selector: 'app-sites-emergency-evacuation-plan-edit',
  templateUrl: './sites-emergency-evacuation-plan-edit.component.html',
  styleUrls: ['./sites-emergency-evacuation-plan-edit.component.scss']
})
export class SitesEmergencyEvacuationPlanEditComponent implements OnInit {
  site = new SiteModel();
  faHardHat = faHardHat;
  faFirstAid = faFirstAid;

  inProgress: boolean = false;

  @ViewChild('emergencyEvacuationPlanMapFileSelector') emergencyEvacuationPlanMapFileSelector: ElementRef;

  parent_id: number;
  child_id: number;
  site_id: number;

  // Mobile field Country Code selector values
  phone_types: string[] = ['emergency', 'hospital', 'medical_centre', 'worksafe', 'civil_defence', 'poison_centre', 'power', 'faults'];
  phone_input_id = {};
  phone_number = {};
  phone_country_code = {};
  phone_dial_code = {};
  phone_e164 = {};
  phone_error_state = {};

  constructor(
    private route: ActivatedRoute,
    private siteService: SitesService,
    public utils: UtilsService,
    private router: Router,
    private oldApi: ApiService,
    private renderer: Renderer2,
    private api: ApiRequestService
  ) {

  }

  ngOnInit() {
    // Generate phone input ids
    for (let i = 0; i < this.phone_types.length; i++) {
      this.phone_input_id[this.phone_types[i]] = `phone-${i+1}`;
    }

    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      this.site.id = this.site_id;

      this.siteService
        .find(this.site.id)
        .subscribe((response) => {
          this.site = response.data;
        });
    }
  }

  onSubmit() {
    // Check Error states of phone inputs then continue submission
    if(this.getPhoneDetailsErrorState()) {
      this.utils.showModal('Form Validation Failed','A Phone number must be at least 3 characters long.');
      return;
    }

    // Terminate early if another process is in progress.
    if ( this.inProgress ) {
      return;
    }

    // Update Site details with Mobile field Country Code selector values
    for (let i = 0; i < this.phone_types.length; i++) {
      this.site[this.phone_types[i]+'_phone'] = this.phone_number[this.phone_types[i]];
      this.site[this.phone_types[i]+'_phone_country_code'] = this.phone_country_code[this.phone_types[i]];
      this.site[this.phone_types[i]+'_phone_dial_code'] = this.phone_dial_code[this.phone_types[i]];
      this.site[this.phone_types[i]+'_phone_e164'] = this.phone_e164[this.phone_types[i]];
    }

    this.inProgress = true;

    this.siteService
      .update(this.site)
      .subscribe(() => {
        this.utils.showToast('Updated Emergency Evacuation Plan');
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      });
  }

  /**
   * Get Output values from phone input component.
   */
  getOutputPhoneDetails($event, $type) {
    if($event) {
      let phone = $event;
      this.phone_number[$type] = phone.hasOwnProperty('number') && phone.number !== null ? phone.number : '';
      this.phone_country_code[$type] = phone.hasOwnProperty('countryCode') && phone.countryCode !== null ? phone.countryCode : '';
      this.phone_dial_code[$type] = phone.hasOwnProperty('dialCode') && phone.dialCode !== null ? phone.dialCode : '';
      this.phone_e164[$type] = phone.hasOwnProperty('e164Number') && phone.e164Number !== null ? phone.e164Number : '';
      this.phone_error_state[$type] = phone.hasOwnProperty('errorState') && phone.errorState !== null ? phone.errorState : true;
    }
  }

  /**
   * Return Error state from all phone inputs.
   */
  getPhoneDetailsErrorState() {
    let errorState = false;
    for (let i = 0; i < this.phone_types.length; i++) {
      if(this.phone_error_state[this.phone_types[i]]) {
        errorState = true;
        break;
      }
    }
    return errorState;
  }

  /**
   * Dispatch a click event for the user to select an image file from their device.
   */
  onSelectEmergencyEvacuationPlanMap() {
    if(this.emergencyEvacuationPlanMapFileSelector) {
      const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
      this.emergencyEvacuationPlanMapFileSelector.nativeElement.dispatchEvent(clickEvt);
    }
  }

  /**
   * Make an API request to upload a new image file to be stored as an emergency evacuation plan map.
   * @param evt
   */
  onUploadEmergencyEvacuationPlanMap(evt: any) {
    // Make the file upload request.
    this.api.makeUploadRequest(`v1/sites/${this.site.id}/emergency-evacuation-plan-map`, evt.target.files[0])
    .then((response: SiteModel) => {
      // Replace teh site object.
      this.site = response as SiteModel;
      // Show a success message.
      this.utils.showToast('Your emergency evacuation plan map was uploaded.');
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
    });
  }

  /**
   * Make an API request to remove the emergency evacuation plan map from the site.
   * @param evt
   */
  onRemoveEmergencyEvacuationPlanMap(evt) {
    // Check if the delete button was pressed intentionally.
    this.utils.showQuickActions(evt.target, "Are you sure you want to remove the emergency evacuation plan map?", [
      {
        text: "Cancel",
        handler: () => {}
      },
      {
        text: "Yes",
        handler: () => {
          // Make an API request to remove the emergency evacuation plan map.
          this.api.makeRequest('delete', `v1/sites/${this.site.id}/emergency-evacuation-plan-map`)
          .then((response: SiteModel) => {
            // Replace the site object with the response.
            this.site = response as SiteModel;
            // Show a success message.
            this.utils.showToast('Your emergency evacuation plan map was removed.');
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
        }
      }
    ]);
  }

  /**
   * Export the emergency evacuation plan PDF document.
   */
  onExportEmergencyEvacuationPlan() {
    this.api.makeRequest('get', `v1/sites/${this.site.id}/emergency-evacuation-plan`)
      .then((response) => {
        this.utils.openUrl(response.data.url);
      });
  }

  /**
   * @deprecated
   * This method is kept for backward compatability and should be removed in future versions of the web portal.
   * Get and store a single site safety manager.
   */
  onSelectSafetyManager() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: false,
        selected: [this.site.safety_manager_id],
        visitor_site_id: this.site.id
      },
      {},
      (result: number) => {
        if (!result) { return; }

        // make a request to get the user data
        this.oldApi.laravelApiRequest('get', `user/${result}`, {}, {}, (response) => {
          this.site.safety_manager = response.data;
          this.site.safety_manager_id = result;
        });

      }
    );
  }

  /**
   * @deprecated
   * This method is kept for backward compatability and should be removed in future versions of the web portal.
   * Get and store a single trained first aider.
   */
  onSelectTrainedFirstAider() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: false,
        selected: [this.site.trained_first_aider_id],
        visitor_site_id: this.site.id
      },
      {},
      (result: number) => {
        if (!result) { return; }

        this.oldApi.laravelApiRequest('get', `user/${result}`, {}, {}, (response) => {
          this.site.trained_first_aider = response.data;
          this.site.trained_first_aider_id = result;
        });

      }
    );
  }
}
