<div class="d-flex justify-content-center align-items-center">
  <div class="login-form" appMargin>

    <img src="./assets/images/logo.png" class="logo" alt="SiteSoft New Zealand Ltd" />

    <mat-card>
      <mat-card-content>
        <mat-tab-group>

          <mat-tab label="Sign In">
            <form #f="ngForm" (ngSubmit)="onSubmit(f)">
              <p>
                <mat-form-field class="full-width">
                  <mat-label>Email Address / Username</mat-label>
                  <input
                    matInput
                    placeholder="Email Address / Username"
                    [(ngModel)]="email"
                    name="email"
                    type="text"
                    id="email"
                    required
                    dusk="email-input"/>
                  <mat-hint>Enter your email address or username in this field.</mat-hint>
                </mat-form-field>
              </p>

              <p>
                <mat-form-field class="full-width">
                  <mat-label>Password</mat-label>
                  <input
                    matInput
                    placeholder="Password"
                    [(ngModel)]="password"
                    name="password"
                    [type]="passwordFieldType"
                    required
                    id="password"
                    dusk="password-input"/>
                  <button mat-icon-button matSuffix aria-label="Show/Hide" (click)="togglePasswordVisibility()" type="button">
                    <mat-icon>{{ passwordFieldType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
                  </button>
                  <mat-hint>Enter your password in this field.</mat-hint>
                </mat-form-field>
              </p>

              <button type="submit" mat-raised-button [disabled]="!f.valid || inProgress" color="primary" class="full-width">
                Sign In
              </button>

              <button type="button" mat-button routerLink="/forgot-password" appMarginTop  class="full-width">
                Forgot Password?
              </button>

              <p>
                <mat-hint>
                  Click the <b>Forgot Password?</b> button if you want to reset your password.
                </mat-hint>
              </p>

              <div appMarginTop>
                <mat-hint>
                  <h5>Recommended Browsers</h5>
                  <ul>
                    <li><a href="https://www.google.com/chrome/" target="_blank">Download Google Chrome</a></li>
                    <li><a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Download Firefox</a></li>
                    <li><a href="https://support.apple.com/downloads/safari" target="_blank">Download Safari</a></li>
                  </ul>
                  We <b>do not</b> recommend using Internet Explorer or Microsoft Edge (Legacy).
                </mat-hint>
              </div>

            </form>
          </mat-tab>

          <mat-tab label="Sign Up">
            <app-sign-up></app-sign-up>
          </mat-tab>

        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
</div>


