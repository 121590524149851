import { CurrentTimezoneStateService } from '../../shared/current-timezone-state.service';
import { Location } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from 'src/app/shared/utils.service';
import { ActionModel } from '../../actions/action.model';
import { ActionsService } from '../../actions/actions.service';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { FilesRemoveComponent } from 'src/app/files/files-remove/files-remove.component';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import * as moment from 'moment';
import {
  UserAccessibleSitesSelectorComponent
} from "../user-accessible-sites-selector/user-accessible-sites-selector.component";
import {SiteModel} from "../../models/site.model";

@Component({
  selector: 'app-user-tasks-edit',
  templateUrl: './user-tasks-edit.component.html',
  styleUrls: ['./user-tasks-edit.component.scss']
})
export class UserTasksEditComponent implements OnInit {

  priorities = ['Low', 'Medium', 'High', 'Critical'];

  now = new Date();

  newFiles: FileList;

  inProgress = false;

  selectedTimezone: string = this.cTmzState.getCurrentTimezone();

  selectedDate: moment.Moment;

  constructor(
    private app: AppService,
    public location: Location,
    public utils: UtilsService,
    private actionsService: ActionsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public action: ActionModel,
    private dialogRef: MatDialogRef<UserTasksEditComponent>,
    private api: ApiRequestService,
    private cTmzState: CurrentTimezoneStateService
  ) {}

  ngOnInit() {
    if ( !this.action.id ) { return (this.action.priority = 'Medium'); }
    this.find();
  }

  getOutputTimezone($event) {
    this.selectedTimezone = $event;
  }

  onDateChange(value) {
    this.selectedDate = value;
  }

  /**
   * Send the API request to create or update a task.
   */
  onSubmit() {
    // Make sure the task is assigned to users.
    if (this.action.user_ids == null || !this.action.user_ids.length) {
      this.utils.showModal('Assignees Required', 'Please add at least one ' + this.utils.getLangTerm('employees.singular', 'Employee') + ' as an assignee');
      return;
    }

    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;

    // Check if a due date was selected before attempting to use formatting functions.
    if ( this.selectedDate ) {
      const dateToSave = moment.tz(this.selectedDate.format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a', this.selectedTimezone);
      const dateString = dateToSave.format('YYYY-MM-DD');
      this.action.due = dateString;
    }

    // If the id is present we want to send an update request, otherwise a create request.
    if ( this.action.id ) {
      this.update();
    } else {
      this.create();
    }
  }

  /**
   * Find a task.
   * @private
   */
  private find() {
    this.actionsService.find(this.action.id).subscribe((result) => {
      this.action = result.data;
      this.action.user_ids = this.action.users.map((user) => user.id);
    });
  }

  /**
   * Remove files from the task.
   */
  onRemoveFiles() {
    const dialogRef = this.dialog.open(FilesRemoveComponent, {
      data: this.action.files,
      width: '700px'
    });
    dialogRef.afterClosed().subscribe(() => this.find());
  }

  /**
   * Select users to assign to this task.
   */
  selectAssignee() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        selected: this.action.user_ids,
        multiple: true,
        selectedAccountId: this.app.account.id
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.action.user_ids = results;
      }
    );
  }

  /**
   * Select a site from the list of accessible sites the user has access to.
   */
  onSelectSite() {
    // Open the accessible sites' selector.
    this.utils.showComponentDialog(UserAccessibleSitesSelectorComponent, {
      selected: [this.action.site_id],
      multiple: false,
      return_object: false
    }).then((response: SiteModel | number | number[]) => {
      // Validate the selection response.
      if (typeof response !== 'number') { return; }
      this.action.site_id = response;
    });
  }

  /**
   * Remove the task.
   */
  delete() {
    this.utils.showModal(
      'Remove Actions',
      `Are you sure you want to remove "${this.action.name}"?`,
      () => {
        this.actionsService.remove([this.action.id]).subscribe(() => {
          this.utils.showToast(`"${this.action.name}" has been deleted.`);
          this.dialogRef.close(true);
        });
      }
    );
  }

  /**
   * Update the task.
   * @private
   */
  private update() {
    this.api
      .makeRequest('put', `v1/actions/${this.action.id}`, this.action)
      .then((response: { data: ActionModel }) => {
        this.onSuccess(`Updated: "${response.data.name}"`);

        if (!this.newFiles || !this.newFiles.length) {
          return;
        }
        this.actionsService
          .storeFiles(this.newFiles, response.data.id)
          .subscribe({
            complete: () => {
              this.newFiles = null;
              this.find();
            }
          });
      })
      .finally(() => (this.inProgress = false));
  }

  /**
   * Create a new task.
   * @private
   */
  private create() {
    this.api
      .makeRequest('post', 'v1/actions', this.action)
      .then((response: { data: ActionModel; meta: {total: number} }) => {
        this.onSuccess(`Created ${response.meta.total} Task(s)`);

        if (!this.newFiles || !this.newFiles.length) {
          return;
        }
        this.actionsService
          .storeFiles(this.newFiles, response.data.id)
          .subscribe(() => {});
      })
      .finally(() => this.inProgress = false);
  }

  /**
   * Handle the success of the API request.
   * @param message
   * @private
   */
  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close(true);
  }
}
