<div mat-dialog-title>
  <h5>Select Your Employer</h5>
  <p>
    Please take a moment to select your employer. It is important that we keep information as accurate as possible for reporting. <br>
    Press the save button when done.
  </p>
</div>

<form #f="ngForm" class="form-container" (ngSubmit)="onClose()" mat-dialog-content>
  <mat-form-field>
    <input matInput placeholder="Company Name" [value]="defaultAccount.name"
      type="text" readonly (click)="onSelectAccount()" />
  </mat-form-field>

  <mat-form-field *ngIf="defaultAccount.address">
    <input matInput placeholder="Address" [value]="defaultAccount.address" type="text"
      readonly />
  </mat-form-field>

  <mat-form-field *ngIf="defaultAccount.city">
    <input matInput placeholder="City" [value]="defaultAccount.city" type="text"
      readonly />
  </mat-form-field>

  <mat-form-field *ngIf="defaultAccount.country">
    <input matInput placeholder="Country" [value]="defaultAccount.country" type="text"
      readonly />
  </mat-form-field>

  <mat-form-field *ngIf="defaultAccount.industry">
    <input matInput placeholder="Industry" [value]="defaultAccount.industry"
      type="text" readonly />
  </mat-form-field>

  <button type="submit" mat-raised-button
    [disabled]="!app.user.account_id || inProgress" color="primary"
    class="margin-right" id="save">
    {{ alreadyUpdated ? 'Close' : 'Save' }}
  </button>

  <button mat-raised-button type="button" color="secondary"
    (click)="onSelectAccount()"
    matTooltip="Select your Employer"
    class="margin-bottom">
    <mat-icon>business</mat-icon>
    Change Employer
  </button>
</form>
