import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-induction-modules',
  templateUrl: './induction-modules.component.html',
  styleUrls: ['./induction-modules.component.scss']
})
export class InductionModulesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
