import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";
import {ApiRequestService} from "../../../shared/api-request.service";
import {WorkPermitModel} from "../work-permits.model";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {UserPublicProfileComponent} from "../../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-work-permits-view',
  templateUrl: './work-permits-view.component.html',
  styleUrls: ['./work-permits-view.component.scss']
})
export class WorkPermitsViewComponent implements OnInit {

  workPermit: WorkPermitModel = new WorkPermitModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private id: number,
    private utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    const work_permit_id = this.id;
    if(work_permit_id) {
      this.getWorkPermit(work_permit_id);
    }
  }

  private getWorkPermit (work_permit_id: number) {
    this.api.makeRequest('get', `v2/admin/work-permits/${work_permit_id}`)
      .then((response) => {
        this.workPermit = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
