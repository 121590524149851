import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployeeService } from '../employee.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';

@Component({
  selector: 'app-employees-tabs',
  templateUrl: './employees-tabs.component.html',
  styleUrls: ['./employees-tabs.component.scss'],
  providers: [EmployeeService]
})
export class EmployeesTabsComponent implements OnInit {

  constructor(
    public app: AppService,
    private route: ActivatedRoute,
    private router: Router,
    public employeeService: EmployeeService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    // Get the employee id
    const employeeId = Number(this.route.snapshot.params['user_id']);
    // Check if it it a valid employee id
    if ( !employeeId ) {
      this.router.navigate(['employees']);
      return;
    }

    this.employeeService.shouldRedirectToEmployeesOnFailure = true;

    this.employeeService.getEmployeeById(employeeId);
  }

  // Toggle the user's role.
  onSwitchRole() {
    this.utils.showModal(`Change ${ this.utils.getLangTerm('employees.plural', 'Employees') } Role`, 'Are you sure you want to change the ' + this.utils.getLangTerm('employees.singular', 'Employee')  + '\'s role to ' + (this.employeeService.employee.pivot.permissions == 'Administrator' ? 'Employee' : 'Administrator'), () => {
      this.employeeService.switchRole();
    });
  }

  // This poses a massive security risk without account verification added.
  onSendPasswordResetEmail() {
    return;
    this.utils.showModal('Confirmation Needed', 'Are you sure you want to send the password reset instructions to ' + this.employeeService.employee.email + '?', () => {
      this.employeeService.sendPasswordResetEmail();
    });
  }

  composeNewMessage(employeeId: number) {
    if ( !employeeId) {
      this.utils.showToast('Unable to open message composer. Please try again.');
      return; // early termination
    }

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectUserIds: [employeeId]
    })
    .then(() => {
      this.utils.showToast('You can view the message stats in the messages section.');
    });
  }

  onUnlockUser(employeeId: number) {
    this.employeeService.unlockEmployeeForLogin(employeeId)
  }
}
