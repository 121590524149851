import {
  Component,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input, Renderer2
} from '@angular/core';

@Component({
  selector: 'app-files-selector',
  templateUrl: './files-selector.component.html',
  styleUrls: ['./files-selector.component.scss']
})
export class FilesSelectorComponent {

  @ViewChild('fileInput') fileInput: ElementRef;

  @Output() selected = new EventEmitter<FileList>();
  @Input() raisedButton = true;

  @Input() selectedFilesCount: number = 0;

  constructor(
    private renderer: Renderer2
  ) {
  }

  /**
   * Trigger the file input event.
   * @param evt
   */
  onTriggerFileSelector(evt: Event) {
    if(this.fileInput) {
      const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
      this.fileInput.nativeElement.dispatchEvent(clickEvt);
    }
  }

  async changeFiles(evt: any) {
    this.selected.emit(evt.target.files);
  }
}
