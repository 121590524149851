import { Component, OnInit, Inject } from '@angular/core';
import { PrequalificationModel } from 'src/app/models/prequalification.model';
import { PrequalificationService } from 'src/app/prequalifications/prequalification.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from 'src/app/shared/utils.service';

declare var saveAs: any;

@Component({
  selector: 'app-my-prequalifications-view',
  templateUrl: './my-prequalifications-view.component.html',
  styleUrls: ['./my-prequalifications-view.component.scss']
})
export class MyPrequalificationsViewComponent implements OnInit {
  prequal = new PrequalificationModel();
  score: number;
  shareLink: string;
  readonly baseUrl = window.location.origin;

  constructor(
    @Inject(MAT_DIALOG_DATA) private id: number,
    private prequalService: PrequalificationService,
    private utils: UtilsService
  ) {}

  ngOnInit() {
    this.prequalService.find(this.id, true).subscribe((response) => {
      this.prequal = response.data;
      this.shareLink =
        window.location.origin +
        `/noauth/prequalifications/${this.prequal.hash}`;
    });
  }

  async downloadPdf() {
    const data = await this.prequalService.exportPdf(this.prequal.id);
    saveAs(data, `Prequalification ${this.prequal.name}`);
  }

  notifyCopied() {
    this.utils.showToast('Copied link to clipboard');
  }
}
