import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarginBottomDirective } from './margin-bottom.directive';
import { MarginLeftDirective } from './margin-left.directive';
import { MarginRightDirective } from './margin-right.directive';
import { MarginTopDirective } from './margin-top.directive';
import { MarginDirective } from './margin.directive';
import { PaddingDirective } from './padding.directive';
import { ColorPercentDirective } from './color-percent.directive';
import { ColorInductionDirective } from './color-induction.directive';
import { PrequalScoreColorDirective } from './prequal-score-color.directive';
import { DragAndDropDirective } from './drag-and-drop.directive';
import { ReadOnlyDirective } from './readonly.directive';

@NgModule({
  declarations: [
    MarginBottomDirective,
    MarginLeftDirective,
    MarginRightDirective,
    MarginTopDirective,
    MarginDirective,
    PaddingDirective,
    ColorPercentDirective,
    ColorInductionDirective,
    PrequalScoreColorDirective,
    DragAndDropDirective,
    ReadOnlyDirective
  ],
  imports: [CommonModule],
  exports: [
    MarginBottomDirective,
    MarginLeftDirective,
    MarginRightDirective,
    MarginTopDirective,
    MarginDirective,
    PaddingDirective,
    ColorPercentDirective,
    ColorInductionDirective,
    PrequalScoreColorDirective,
    DragAndDropDirective,
    ReadOnlyDirective
  ]
})
export class DirectivesModule {}
