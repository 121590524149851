<div class="forgot-password" appMargin>
  <img src="./assets/images/logo.png" class="logo" alt="SiteSoft New Zealand Ltd" />

  <mat-card>
    <form (submit)="onChangePassword(f)" #f="ngForm">

      <p>
        <mat-hint>
          Use the password change form below to enter a new password for your account.
        </mat-hint>
      </p>

      <p>
        <mat-form-field class="full-width">
          <mat-label>New Password</mat-label>
          <input
            matInput
            placeholder="New Password"
            required
            [(ngModel)]="password"
            name="password"
            [type]="passwordFieldType"/>
          <button mat-icon-button matSuffix aria-label="Show/Hide" (click)="togglePasswordVisibility('password')" type="button">
            <mat-icon>{{ passwordFieldType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
          </button>
          <mat-hint>Enter a new password for your account.</mat-hint>
        </mat-form-field>
      </p>

      <app-password-strength-indicator [passwordInput]="password" (passwordValidatedOutput)="getOutputPasswordValidated($event)"></app-password-strength-indicator>

      <p>
        <mat-form-field class="full-width">
          <mat-label>New Password Confirmation</mat-label>
          <input
            matInput
            placeholder="New Password Confirmation"
            required
            [(ngModel)]="passwordConfirmation"
            name="passwordConfirmation"
            [type]="confirmPasswordFieldType"/>
          <button mat-icon-button matSuffix aria-label="Show/Hide" (click)="togglePasswordVisibility('confirmPassword')" type="button">
            <mat-icon>{{ confirmPasswordFieldType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
          </button>
          <mat-hint>Enter the new password again for confirmation.</mat-hint>
        </mat-form-field>
      </p>

      <button mat-raised-button color="primary" type="submit" [disabled]="!f.valid || password !== passwordConfirmation || !passwordValidated" appMarginTop class="full-width">
        Save New Password
      </button>

      <button type="button" mat-button routerLink="/sign-in" appMarginTop class="full-width">
        Remembered Password?
      </button>

    </form>
  </mat-card>
</div>

