import { Component, Inject, OnInit } from '@angular/core';
import { DynamicFormFieldModel } from '../dynamic-form-field.model';
import { CdkDragDrop, moveItemInArray, copyArrayItem } from '@angular/cdk/drag-drop';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { DynamicFormModel } from '../dynamic-form.model';
import { DynamicFormFieldOptionModel } from '../dynamic-form-field-option.model';
import { DynamicFormFieldConditionModel } from '../dynamic-form-field-condition.model';
import { ContractorsSelectorComponent } from 'src/app/contractors/contractors-selector/contractors-selector.component';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { IndustriesSelectorComponent } from 'src/app/shared/industries-selector/industries-selector.component';
import { TradesSelectorComponent } from 'src/app/shared/trades-selector/trades-selector.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicFormsCategoriesSelectorComponent } from 'src/app/dynamic-forms-categories/dynamic-forms-categories-selector/dynamic-forms-categories-selector.component';
import {MatCheckboxChange} from "@angular/material/checkbox";
import { DigitalSignatureComponent } from '../../shared/digital-signature/digital-signature.component';

declare var tinymce: any;

@Component({
  selector: 'app-dynamic-forms-edit',
  templateUrl: './dynamic-forms-edit.component.html',
  styleUrls: ['./dynamic-forms-edit.component.scss']
})
export class DynamicFormsEditComponent implements OnInit {

  // The list of available fields that can be used.
  // When adding new field properties you also need to include them when fields are copied in the onCopyFieldToFormBuilder() method below.
  availableFields: DynamicFormFieldModel[] = [
    {
      field_type: 'input',
      field_header: 'Text Input',
      field_input_type: 'text',
      field_label: '',
      field_value: '',
      field_meta: {},
      field_options: [],
      field_conditions: [],
      field_is_required: false,
      include_in_exports: true,
      field_logical_operator: '&&'
    } as DynamicFormFieldModel,
    {
      field_type: 'textarea',
      field_header: 'Text Area',
      field_label: '',
      field_value: '',
      field_meta: {},
      field_options: [],
      field_conditions: [],
      field_is_required: false,
      include_in_exports: true,
      field_logical_operator: '&&'
    } as DynamicFormFieldModel,
    {
      field_type: 'datetime',
      field_header: 'Date Picker',
      field_date_range: 'any',
      field_label: '',
      field_value: moment(new Date(), moment.ISO_8601).format(),
      field_meta: {},
      field_options: [],
      field_conditions: [],
      field_is_required: false,
      include_in_exports: true,
      field_logical_operator: '&&'
    } as DynamicFormFieldModel,
    {
      field_type: 'select',
      field_header: 'Dropdown',
      field_options: [
        {
          option_text: ''
        } as DynamicFormFieldOptionModel
      ],
      field_label: '',
      field_value: '',
      field_meta: {},
      field_conditions: [],
      field_is_required: false,
      include_in_exports: true,
      field_logical_operator: '&&'
    } as DynamicFormFieldModel,
    {
      field_type: 'checkbox',
      field_header: 'Checkboxes',
      field_options: [
        {
          option_text: ''
        } as DynamicFormFieldOptionModel
      ],
      field_label: '',
      field_value: '',
      field_meta: {},
      field_conditions: [],
      field_is_required: false,
      include_in_exports: true,
      field_logical_operator: '&&'
    } as DynamicFormFieldModel,
    {
      field_type: 'toggle',
      field_header: 'Toggles',
      field_options: [
        {
          option_text: ''
        } as DynamicFormFieldOptionModel
      ],
      field_label: '',
      field_value: '',
      field_meta: {},
      field_conditions: [],
      field_is_required: false,
      include_in_exports: true,
      field_logical_operator: '&&'
    } as DynamicFormFieldModel,
    {
      field_type: 'radio',
      field_header: 'Radio Buttons',
      field_options: [
        {
          option_text: ''
        } as DynamicFormFieldOptionModel
      ],
      field_label: '',
      field_value: '',
      field_meta: {},
      field_conditions: [],
      field_is_required: false,
      include_in_exports: true,
      field_logical_operator: '&&'
    } as DynamicFormFieldModel,
    {
      field_type: 'paragraph',
      field_header: 'Plain Text Content',
      field_label: '',
      field_value: '',
      field_meta: {},
      field_options: [],
      field_conditions: [],
      field_is_required: false,
      include_in_exports: true,
      field_logical_operator: '&&'
    } as DynamicFormFieldModel,
    {
      field_type: 'wysiwyg',
      field_header: 'Rich Text Content',
      field_label: '',
      field_value: '',
      field_meta: {},
      field_options: [],
      field_conditions: [],
      field_is_required: false,
      include_in_exports: true,
      field_logical_operator: '&&'
    } as DynamicFormFieldModel,
    {
      field_type: 'filepicker',
      field_header: 'File Upload',
      field_label: '',
      field_value: '',
      field_meta: {},
      field_options: [],
      field_conditions: [],
      field_is_required: false,
      include_in_exports: true,
      field_logical_operator: '&&'
    } as DynamicFormFieldModel,
    {
      field_type: 'signature',
      field_header: 'Signature',
      field_label: 'Signature',
      field_value: '',
      field_meta: {},
      field_options: [],
      field_conditions: [],
      field_is_required: false,
      include_in_exports: true,
      field_logical_operator: '&&'
    } as DynamicFormFieldModel,
    {
      field_type: 'user-selector',
      field_header: 'User Selector',
      field_label: '',
      field_value: '',
      field_meta: {},
      field_options: [],
      field_conditions: [],
      field_is_required: false,
      include_in_exports: true,
      field_logical_operator: '&&'
    } as DynamicFormFieldModel
  ];

  // Stores the active tab here.
  active_tab: string = 'Form Builder';

  // Used by TinyMCE editors when switching between tabs.
  // Currently, commented due to breaking two-way binding. See onTabChanged() below.
  // editorIds: string[] = [];

  // This is used strategically to remove form fields and re-add it to the form builder when switching between tabs.
  // The goal if for TinyMCE editors to re-initialise without loosing two-way bindings.
  form_field_backups: DynamicFormFieldModel[] = [];

  // The form that is being constructed.
  form: DynamicFormModel = {
    is_template: true,
    is_active: false,
    is_recurring: false,
    is_auto_assignable: false,
    is_notifications_enabled: false,
    schedule: 'day-of-month',
    schedule_minute: 0,
    schedule_hour: 8,
    schedule_days: [],
    schedule_months: [],
    schedule_weeks: [],
    expires_at: 0,
    form_type: 'form',
    fields: [],
    category_ids: [],
    const_site_ids: [],
    include_site_managers_for_notifications: false
  } as DynamicFormModel;

  // Selectable hours. Used in the schedule configuration.
  // This is populated in the constructor.
  selectableHours: number[] = [];

  // Selectable month days. Used in the schedule configuration.
  // This is populated in the constructor.
  selectableDays: {
    value: number,
    label: string,
    checked: boolean
  }[] = [];

  // Selectable months. Used in the schedule configuration.
  selectableMonths: {
    value: number,
    label: string,
    checked: boolean
  }[] = [
    {
      value: 1,
      label: 'January',
      checked: false
    },
    {
      value: 2,
      label: 'February',
      checked: false
    },
    {
      value: 3,
      label: 'March',
      checked: false
    },
    {
      value: 4,
      label: 'April',
      checked: false
    },
    {
      value: 5,
      label: 'May',
      checked: false
    },
    {
      value: 6,
      label: 'June',
      checked: false
    },
    {
      value: 7,
      label: 'July',
      checked: false
    },
    {
      value: 8,
      label: 'August',
      checked: false
    },
    {
      value: 9,
      label: 'September',
      checked: false
    },
    {
      value: 10,
      label: 'October',
      checked: false
    },
    {
      value: 11,
      label: 'November',
      checked: false
    },
    {
      value: 12,
      label: 'December',
      checked: false
    }
  ];

  // Selectable weekdays. Used in the schedule configuration.
  selectableWeeks: {
    value: number,
    label: string,
    checked: boolean
  }[] = [
    {
      value: 0,
      label: 'Sundays',
      checked: false
    },
    {
      value: 1,
      label: 'Mondays',
      checked: false
    },
    {
      value: 2,
      label: 'Tuesdays',
      checked: false
    },
    {
      value: 3,
      label: 'Wednesdays',
      checked: false
    },
    {
      value: 4,
      label: 'Thursdays',
      checked: false
    },
    {
      value: 5,
      label: 'Fridays',
      checked: false
    },
    {
      value: 6,
      label: 'Saturdays',
      checked: false
    }
  ];

  // The cron preview when creating a schedule. Used in the schedule configuration.
  cronPreview: string = '0 8 * * *';

  // Used for available fields. It's a display value.
  dateSample: any = moment(new Date(), moment.ISO_8601).format();

  // Used for available fields. It's a display value.
  radioSample: string = '';

  // Signature component instances are stored here. This is for the preview only.
  preview_signature_component_instances: DigitalSignatureComponent[] = [];

  constructor(
    public utils: UtilsService,
    private api: ApiRequestService,
    @Inject(MAT_DIALOG_DATA) private dialogData: { form_id?: number, site_id?: number }
  ) { }

  ngOnInit() {
    // Check if the form id is present and request the form.
    if ( this.dialogData.form_id ) {
      // Make an API request to get the form data.
      this.api.makeRequest('get', `v2/dynamic-forms/${this.dialogData.form_id}`)
      .then((response: DynamicFormModel) => {
        this.form = response;

        // Make sure the field_meta properties are set.
        this.form.fields.forEach((field) => {
          field.field_meta = field.field_meta || {};
        });

        this.refreshSelectedScheduleValues();
      })
      .catch((errorResponse: any) => {
        this.utils.handleAPIErrors(errorResponse);
      });
    } else {
      // Add at least one field for new form.
      if ( this.form.fields.length == 0 ) {
        // Use a JSON parsing technique to create a clone.
        this.form.fields.push(JSON.parse(JSON.stringify(this.availableFields[0])));
      }

      // If a new form is created from the site list, add the site by default.
      if ( this.dialogData.site_id ) {
        this.form.const_site_ids.push(this.dialogData.site_id);
      }
    }

    // Populate hours array.
    for( let i = 0; i <= 23; i++ ) {
      this.selectableHours.push(i);
    }

    // Populate days of month array.
    for( let i = 1; i <= 28; i++ ) {
      this.selectableDays.push({
        value: i,
        label: moment.localeData().ordinal(i), // i.toString(),
        checked: false
      });
    }
  }

  /**
   * If the field type changed and the new field type is in ('checkbox', 'toggle', 'select', 'radio') and does not have any options, we need to add options.
   * Update the field requirement status and header. Jira#SIT-50.
   * @param formField The field to check and add options to.
   */
  onFieldTypeChanged(formField: DynamicFormFieldModel) {
    // Only add one option if there are none.
    if ( ['checkbox', 'toggle', 'select', 'radio'].indexOf(formField.field_type) > -1 && formField.field_options.length == 0 ) {
      this.onAddOption(formField.field_options);
    }

    // Remove the "field_is_required" option from the fields that does not require it. Jira#SIT-50.
    if ( ['checkbox', 'toggle', 'paragraph', 'wysiwyg', 'filepicker'].indexOf(formField.field_type) > -1 ) {
      formField.field_is_required = false;
    }

    // Update the field header. Jira#SIT-50.
    this.availableFields.forEach((availableField: DynamicFormFieldModel) => {
      if ( availableField.field_type == formField.field_type ) {
        formField.field_header = availableField.field_header;
      }
    });
  }

  /**
   * When the form template state changes, we need to disable auto-assignment settings.
   * @param checkbox The form template checkbox.
   */
  onFormTemplateStateChange(checkbox: MatCheckboxChange) {
    // Check if the form template is enabled.
    if ( checkbox.checked ) {
      // Disable auto-assignment form settings.
      this.form.is_auto_assignable = false;
      this.form.auto_assign_to_employees = false;
      this.form.auto_assign_at_site_entry = false;
    }
  }

  /**
   * When tabs are changed, TinyMCE editors need to be re-initialised.
   * This is currently done by creating a backup and restoring fields.
   * @param tabData The active tab.
   */
  onTabChanged(tabData) {
    // Validate and store the new active tab.
    if ( typeof tabData.tab.textLabel != 'undefined' ) {
      this.active_tab = tabData.tab.textLabel;
    }

    // Create field backups when on the form settings tab.
    if ( this.active_tab == "Form Settings") {
      // --> WORKS but breaks Angular two-way bindings.
      // this.editorIds.forEach((editorId, index) => {
      //   tinymce.execCommand('mceToggleEditor', false, editorId);
      // });

      // Alternative strategy until the above can be resolved.
      this.backupFormFields();
    } else {
      // --> WORKS but breaks Angular two-way bindings.
      // tinymce.EditorManager.editors.forEach((editor: any) => {
      //   this.editorIds.push(editor.id);
      // });
      // tinymce.remove();

      // Alternative strategy until the above can be resolved.
      this.restoreFormFields();
    }
  }

  /**
   * Create form field backups when the user navigates to the form settings tab.
   * This is a strategic replacement solution for the default TinyMCE initialisation.
   */
  private backupFormFields() {
    if ( this.active_tab == 'Form Settings' && this.form_field_backups.length == 0 ) {
      // Alternative strategy until the above can be resolved.
      this.form_field_backups = JSON.parse(JSON.stringify(this.form.fields));
      this.form.fields.length = 0;
    }
  }

  /**
   * Restore form field backups when the user navigates to any tab and the form fields are not present.
   * This is a strategic replacement solution for the default TinyMCE initialisation.
   */
  private restoreFormFields() {
    if ( this.form.fields.length == 0 ) {
      // Alternative strategy until the above can be resolved.
      this.form.fields = JSON.parse(JSON.stringify(this.form_field_backups));
      this.form_field_backups.length = 0;
    }
  }

  /**
   * When the cron schedule config changes, recompile the cron schedule.
   */
  refreshSelectedScheduleValues() {
    // Set schedule days.
    this.selectableDays.forEach((selectableDay) => {
      this.form.schedule_days.forEach((schedule_day) => {
        if ( schedule_day == selectableDay.value ) {
          selectableDay.checked = true;
        }
      });
    });

    // Set schedule months.
    this.selectableMonths.forEach((selectableMonth) => {
      this.form.schedule_months.forEach((schedule_month) => {
        if ( schedule_month == selectableMonth.value ) {
          selectableMonth.checked = true;
        }
      });
    });

    // Set schedule weeks.
    this.selectableWeeks.forEach((selectableWeek) => {
      this.form.schedule_weeks.forEach((schedule_week) => {
        if ( schedule_week == selectableWeek.value ) {
          selectableWeek.checked = true;
        }
      });
    });

    // Compile the cron schedule based on new selections.
    this.compileSchedule();
  }

  /**
   * This compiles a cron like schedule which is passed to the API and validated.
   */
  compileSchedule() {
    // Set selected days.
    this.form.schedule_days = this.selectableDays.filter((selectableDay) => {
      return selectableDay.checked;
    }).map((selectableDay) => {
      return selectableDay.value;
    });

    // Set selected months.
    this.form.schedule_months = this.selectableMonths.filter((filteredSelectableMonth) => {
      return filteredSelectableMonth.checked;
    }).map((filteredSelectableMonth) => {
      return filteredSelectableMonth.value;
    });

    // Set selected weeks.
    this.form.schedule_weeks = this.selectableWeeks.filter((filteredSelectableWeek) => {
      return filteredSelectableWeek.checked;
    }).map((filteredSelectableWeek) => {
      return filteredSelectableWeek.value;
    });

    // Build Cron Preview
    this.cronPreview =
      '0 ' +
      this.form.schedule_hour + ' ' +
      ( this.form.schedule == 'day-of-month' ? ( this.form.schedule_days.length > 0 ? ( this.form.schedule_days.join(',') + ' ' ) : '* ' ) : '* ' ) +
      ( this.form.schedule_months.length > 0 ? ( this.form.schedule_months.join(',') + ' ' ) : '* ' ) +
      ( this.form.schedule_weeks.length > 0 && this.form.schedule != 'day-of-month' ? this.form.schedule_weeks : '*' )
    ;
  }

  /**
   * Move fields up and down in the array.
   * @param event The drag and drop event handler.
   */
  drop(event: CdkDragDrop<DynamicFormFieldModel[]>) {
    if ( event.previousContainer == event.container ) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Need to serialise and parse the array to prevent object referencing.
      copyArrayItem(JSON.parse(JSON.stringify(event.previousContainer.data)), event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  /**
   * Copy a field from the available fields list to the form builder.
   * @param indexToCopy Field index to copy.
   * @param indexToShift Field index to shift along.
   */
  onCopyFieldToFormBuilder(indexToCopy: number, indexToShift: number) {
    // Need to serialise and parse object to create unreferenced copy.
    this.form.fields.splice(indexToShift, 0, JSON.parse(JSON.stringify({
      field_type: this.availableFields[indexToCopy].field_type,
      field_header: this.availableFields[indexToCopy].field_header,
      field_label:  this.availableFields[indexToCopy].field_label,
      field_description:  this.availableFields[indexToCopy].field_description,
      field_input_type:  this.availableFields[indexToCopy].field_input_type,
      field_date_range:  this.availableFields[indexToCopy].field_date_range,
      field_options: this.availableFields[indexToCopy].field_options,
      field_conditions: this.availableFields[indexToCopy].field_conditions,
      field_value: this.availableFields[indexToCopy].field_value,
      field_meta: this.availableFields[indexToCopy].field_meta,
      field_is_required: this.availableFields[indexToCopy].field_is_required,
      include_in_exports: this.availableFields[indexToCopy].include_in_exports,
      field_logical_operator: this.availableFields[indexToCopy].field_logical_operator
    })) as DynamicFormFieldModel);
  }

  /**
   * Add a new option to the field. This is typically used for selection lists, checkboxes, toggle selections and radio selections.
   * @param field_options Field options array.
   */
  onAddOption(field_options: DynamicFormFieldOptionModel[]) {
    // Push a new indexed option onto the array stack.
    field_options.push({
      option_text: 'Option ' + (field_options.length + 1),
      option_checked: false
    } as DynamicFormFieldOptionModel);
  }

  /**
   * Remove a field option.
   * @param field_options The field options array.
   * @param index The option index to remove.
   */
  onRemoveOption(field_options: DynamicFormFieldOptionModel[], index: number) {
    field_options.splice(index, 1);
  }

  /**
   * Add a new field condition.
   * @param field_conditions The field's conditions array.
   */
  onAddCondition(field_conditions: DynamicFormFieldConditionModel[]) {
    // Push a new condition onto the array stack.
    field_conditions.push({
      condition_operator: '==',
      condition_checked: false
    } as DynamicFormFieldConditionModel);
  }

  /**
   * Remove a field condition.
   * @param field_conditions The field's conditions array.
   * @param index The condition index to remove.
   */
  onRemoveCondition(field_conditions: DynamicFormFieldConditionModel[], index: number) {
    field_conditions.splice(index, 1);
  }

  /**
   * Evaluate field conditions to show/hide the field.
   * @returns
   * @param formField
   */
  evaluateConditions(formField?: DynamicFormFieldModel) {
    if ( formField.field_conditions && formField.field_conditions.length > 0 ) {
      let conditions_array: string[] = [];
      formField.field_conditions.forEach((condition, i) => {
        this.form.fields.forEach((targetField, j) => {
          if ( targetField.id == condition.dynamic_form_target_field_id && condition.condition_operator != "" ) {
            if ( ['checkbox', 'toggle'].indexOf(targetField.field_type) > -1 ) {
              targetField.field_options.forEach((fieldOption, k) => {
                if ( fieldOption.option_text == condition.condition_value ) {
                  conditions_array.push('this.form.fields['+j+'].field_options['+k+'].option_checked ' + condition.condition_operator + ' ' + (condition.condition_checked ? 'true' : 'false'));
                }
              });
            } else {
              // If no field answer is provided, the field should remain hidden.
              if ( typeof targetField.field_value != 'undefined' && targetField.field_value != null ) {
                // Compare numeric field values without quotes. No value or null will be treated as 0.
                if ( targetField.field_type == 'input' && targetField.field_input_type == 'number' ) {
                  // Numeric input fields must be answered.
                  if ( targetField.field_value != '' ) {
                    conditions_array.push('this.form.fields['+j+'].field_value ' + condition.condition_operator + ' ' + Number(condition.condition_value));
                  } else {
                    // Hide if the target numeric field is unanswered.
                    conditions_array.push('false');
                  }
                } else {
                  // Only for the form builder preview. Do not add this to the user form field evaluations.
                  // Make sure field_value is empty when null.
                  if ( targetField.field_value == null ) {
                    targetField.field_value = '';
                  }
                  conditions_array.push('this.form.fields['+j+'].field_value ' + condition.condition_operator + ' "' + (condition.condition_value ? condition.condition_value : '') + '"');
                }
              } else {
                // Hide if the target field is unanswered.
                conditions_array.push('false');
              }
            }
          }
        });
      });
      // If any conditions are constructed, run the evaluation.
      if ( conditions_array.length > 0 ) {
        return eval(conditions_array.join(' ' + formField.field_logical_operator + ' '));
      }
    }
    return true;
  }

  /**
   * Gets a list of valid target fields for conditions.
   * A valid target field must have an id (stored in the database).
   * @param exclude_ids Form field ids to exclude.
   * @returns
   */
  getStoredTargetFormFields(exclude_ids?: number[]) {
    return this.form.fields.filter((field) => {
      return field.id && !(exclude_ids.indexOf(field.id) > -1)
    });
  }

  /**
   * Get the target form field by id.
   * @param id The form field target id.
   * @returns The form field.
   */
  getTargetFormFieldById(id: number) {
    let targetField: DynamicFormFieldModel = JSON.parse(JSON.stringify(this.availableFields[0]));
    this.form.fields.forEach((field) => {
      if ( field.id && field.id == id ) {
        targetField = field;
      }
    });
    return targetField;
  }

  /**
   * Copies the field at position index and appends it to the bottom of the form.
   * @param index The field array index to copy from.
   */
  onCopyField(index: number) {
    // Create a copy of the field at position index.
    const copiedField: DynamicFormFieldModel = JSON.parse(JSON.stringify(this.form.fields[index]));
    // Remove the id property as this is a new field being added.
    if ( typeof copiedField.id != 'undefined' ) {
      delete copiedField.id;
    }
    // Remove the condition id and associated form field id.
    if ( typeof copiedField.field_conditions != 'undefined' && copiedField.field_conditions.length > 0 ) {
      copiedField.field_conditions.forEach((field_condition: DynamicFormFieldConditionModel, _index: number) => {
        delete copiedField.field_conditions[_index].id;
        delete copiedField.field_conditions[_index].dynamic_form_field_id;
      });
    }
    // Remove the option id and associated form field id.
    if ( typeof copiedField.field_options != 'undefined' && copiedField.field_options.length > 0 ) {
      copiedField.field_options.forEach((field_option: DynamicFormFieldOptionModel, _index: number) => {
        delete copiedField.field_options[_index].id;
        delete copiedField.field_options[_index].dynamic_form_field_id;
      });
    }
    // Push the new field onto the stack.
    this.form.fields.splice((index+1), 0, copiedField);
  }

  /**
   * Remove a field from the form.
   * @param index The form field index number.
   */
  onRemoveField(index: number) {
    this.utils.showModal('Remove Form Field', 'Are you sure you want to remove the form field?', () => {
      // Get the form field.
      const form_field: DynamicFormFieldModel = this.form.fields[index];
      // Check if the form field was previously saved.
      if ( form_field.id ) {
        // Loop through all form fields to look into form field conditions that targets the field that is to be removed.
        for ( let i = 0; i < this.form.fields.length; i++ ) {
          if ( this.form.fields[i].field_conditions.length > 0 ) {
            for ( let j = (this.form.fields[i].field_conditions.length - 1); j >= 0; j-- ) {
              // If the field condition targets the field that is to be removed, remove the condition as well.
              if ( this.form.fields[i].field_conditions[j].dynamic_form_target_field_id == form_field.id ) {
                this.form.fields[i].field_conditions.splice(j, 1);
              }
            }
          }
        }
      }
      // Remove the form field regardless if it was saved or not.
      this.form.fields.splice(index, 1);
    });
  }

  /**
   * Save the form data.
   * @param form The NG form reference.
   * @returns
   */
  onSaveForm(form: NgForm) {
    // Make sure all required fields are filled in.
    if ( !form.valid ) {
      this.utils.showFormValidationError('A form must have a title and description in the settings tab.');
      return;
    }

    // Make sure all custom fields have completed properties.
    if ( !this.validateFormBuilderFields() ) {
      // Collect generic error messages.
      const validationErrorMessages: string[] = [
        'All form fields must have labels in the Form Builder tab.',
        'Plain text content and rich text content must have labels and descriptions.',
        'For dropdown lists, checkboxes, toggle options and radio options you must have at least one option with text.'
      ];
      // Construct the error message.
      const validationErrorMessage: string = '<ul><li>' + validationErrorMessages.join('</li><li>') + '</li></ul>';
      this.utils.showFormValidationError(validationErrorMessage);
      return;
    }

    if ( this.form.id ) {
      // Make sure the user understands that updating a form that is assigned to users will reset form submission statuses.
      // if ( this.form.users_count > 0 ) {
      //   if ( !confirm('This form is assigned to users. If you proceed with updating it, all user submissions statuses will be reset. Their previously submitted answers will still be maintained for resubmission.') ) {
      //     return;
      //   }
      // }

      // Reset the digital signature instances. They will be re-applied when the form reloads.
      this.preview_signature_component_instances.length = 0;

      // Update an existing form.
      this.api.makeRequest('put', `v2/dynamic-forms/${this.form.id}`, this.form)
      .then((response: DynamicFormModel) => {
        this.form = response;
        this.refreshSelectedScheduleValues();

        // Form field backups will only run for the form settings tab.
        this.backupFormFields();
      })
      .catch((errorResponse: any) => {
        this.utils.handleAPIErrors(errorResponse);
      });
    } else {
      // Make a request to create a new form.
      this.api.makeRequest('post', `v2/dynamic-forms`, this.form)
      .then((response: DynamicFormModel) => {
        this.form = response;
        this.refreshSelectedScheduleValues();

        // Form field backups will only run for the form settings tab.
        this.backupFormFields();
      })
      .catch((errorResponse: any) => {
        this.utils.handleAPIErrors(errorResponse);
      });
    }
  }

  /**
   * Make sure all custom fields have completed form properties.
   * @returns whether the form is valid or not.
   */
  private validateFormBuilderFields(): boolean {
    // Default validation status is true.
    let isValid: boolean = true;

    // Check if form fields should be restored before processing it.
    this.restoreFormFields();

    // Run validation against all fields.
    this.form.fields.forEach((field: DynamicFormFieldModel) => {
      if ( !this.validFormBuilderField(field) ) {
        isValid = false;
      }
    });

    return isValid;
  }

  /**
   * Make sure the provided custom field have completed form properties.
   * @returns whether the form is valid or not.
   */
  public validFormBuilderField(field: DynamicFormFieldModel): boolean {
    // Default validation status is true.
    let isValid: boolean = true;

    // Check if the all custom fields have labels. Descriptions for parapgraphs and wysiwyg editors are now optional.
    if ( typeof field.field_label == 'undefined' || field.field_label == '' ) { //  || ( field.field_type == 'paragraph' && ( typeof field.field_description == 'undefined' || field.field_description == '' ) )
      isValid = false;
    }

    // Check if all option based fields have at least one option.
    if ( ['select', 'checkbox', 'toggle', 'radio'].indexOf(field.field_type) > -1 ) {
      if ( field.field_options.length == 0 ) {
        isValid = false;
      } else {
        // Make sure all options have text in it.
        for ( let i = 0; i < field.field_options.length; i++ ) {
          if ( typeof field.field_options[i].option_text == 'undefined' || field.field_options[i].option_text == '' ) {
            isValid = false;
          }
        }
      }
    }

    // Check that all conditions for the field has targets selected. Values to match against can be empty.
    if ( field.field_conditions.length > 0 ) {
      for ( let i = 0; i < field.field_conditions.length; i++ ) {
        if ( typeof field.field_conditions[i].dynamic_form_target_field_id == 'undefined' || !field.field_conditions[i].dynamic_form_target_field_id ) {
          isValid = false;
        }
      }
    }

    return isValid;
  }

  /**
   * Open the account's contractors selector.
   */
  onSelectContractors() {
    // Initialise the const account ids variable if undefined.
    if ( typeof this.form.const_account_ids == 'undefined' ) {
      this.form.const_account_ids = [];
    }

    this.utils.showComponentDialog(ContractorsSelectorComponent, {
      multiple: true,
      selected: this.form.const_account_ids
    })
    .then((response: number[]) => {
      if ( typeof response != 'undefined' ) {
        this.form.const_account_ids = response;
      }
    });
  }

  /**
   * Open the account's connected network users selector.
   */
  onSelectUsers() {
    // Initialise the const user ids variable if undefined.
    if ( typeof this.form.const_user_ids == 'undefined' ) {
      this.form.const_user_ids = [];
    }

    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      multiple: true,
      selected: this.form.const_user_ids,
      visitors_from_all_sites: true
    })
    .then((response: number[]) => {
      if ( typeof response != 'undefined' ) {
        this.form.const_user_ids = response;
      }
    });
  }

  /**
   * Open the account sites selector.
   */
  onSelectSites() {
    // Initialise the const site ids variable if undefined.
    if ( typeof this.form.const_site_ids == 'undefined' ) {
      this.form.const_site_ids = [];
    }

    this.utils.showComponentDialog(SitesSelectorComponent, {
      multiple: true,
      selected: this.form.const_site_ids
    })
    .then((response: number[]) => {
      if ( typeof response != 'undefined' ) {
        this.form.const_site_ids = response;
      }
    });
  }

  /**
   * Open the global industries selector for the user to select from industries.
   */
  onSelectIndustries() {
    // Initialise the const industry ids variable if undefined.
    if ( typeof this.form.const_industry_ids == 'undefined' ) {
      this.form.const_industry_ids = [];
    }

    this.utils.showComponentDialog(IndustriesSelectorComponent, {
      multiple: true,
      selected: this.form.const_industry_ids
    })
    .then((response: number[]) => {
      if ( typeof response != 'undefined' ) {
        this.form.const_industry_ids = response;
      }
    });
  }

  /**
   * Open the global trades selector for the user to select from trades.
   */
  onSelectTrades() {
    // Initialise the const trade ids variable if undefined.
    if ( typeof this.form.const_trade_ids == 'undefined' ) {
      this.form.const_trade_ids = [];
    }

    this.utils.showComponentDialog(TradesSelectorComponent, {
      multiple: true,
      selected: this.form.const_trade_ids
    })
    .then((response: number[]) => {
      if ( typeof response != 'undefined' ) {
        this.form.const_trade_ids = response;
      }
    });
  }

  /**
   * Open the form categories selector and allow the user to select and associate categories to the form.
   */
  onSelectCategories() {
    // Initialise the category ids variable if undefined.
    if ( typeof this.form.category_ids == 'undefined' ) {
      this.form.category_ids = [];
    }

    this.utils.showComponentDialog(DynamicFormsCategoriesSelectorComponent, {
      multiple: true,
      selected: this.form.category_ids
    })
    .then((response: number[]) => {
      if ( typeof response != 'undefined' ) {
        this.form.category_ids = response;
      }
    });
  }

  /**
   * This is used to store the changes for the form type so that when the user changes back, it will reinstate previous settings.
   */
  formTypeChangedLog: {
    is_template: boolean,
    is_recurring: boolean,
    is_auto_assignable: boolean
  } = {
    is_template: this.form.is_template,
    is_recurring: this.form.is_recurring,
    is_auto_assignable: this.form.is_auto_assignable
  };

  /**
   * Check if the user changed the form type and store the previous settings in memory.
   */
  onFormTypeChanged() {
    // Site inductions cannot be set up as form templates. It must be active forms that gets dynamically assigned to users.
    if ( this.form.form_type == 'induction' ) {
      // Store the user selected values.
      this.formTypeChangedLog.is_template = this.form.is_template;
      this.formTypeChangedLog.is_recurring = this.form.is_recurring;
      // Disable template and recurring options since inductions does not utilise it.
      this.form.is_template = false;
      this.form.is_recurring = false;
      // this.form.is_auto_assignable = this.formTypeChangedLog.is_auto_assignable;
    } else {
      // Store user selected values.
      // this.formTypeChangedLog.is_auto_assignable = this.form.is_auto_assignable;
      // Restore logged changes.
      this.form.is_template = this.formTypeChangedLog.is_template;
      this.form.is_recurring = this.formTypeChangedLog.is_recurring;
      // this.form.is_auto_assignable = false;
    }
  }

  /**
   * Get a human friendly version of the cron text.
   * @returns The schedule.
   */
  getCronText() {
    let cronText: string = '';

    if ( this.form.is_recurring ) {
      switch ( this.form.schedule ) {
        case 'weekdays':
          // Compile day of week text.
          const selectableWeeks = this.selectableWeeks.filter((selectableWeek) => {
            return selectableWeek.checked;
          }).map((selectableWeek) => {
            return selectableWeek.label;
          }).join(', ').replace(/, ([^,]*)$/, ' and $1');
          if ( selectableWeeks != '' ) {
            cronText += 'On ' + selectableWeeks + ',';
          } else {
            cronText += 'Every Day,';
          }
          break;
        case 'day-of-month':
          // Compile day of month text.
          const selectableDays = this.selectableDays.filter((selectableDay) => {
            return selectableDay.checked;
          }).map((selectableDay) => {
            return selectableDay.label;
          }).join(', ').replace(/, ([^,]*)$/, ' and $1');
          if ( selectableDays != '' ) {
            cronText += 'On the ' + selectableDays + ',';
          } else {
            cronText += 'Every Day,';
          }
          break;
        default:
          // Do nothing.
      }

      // Compile month text.
      const selectableMonths = this.selectableMonths.filter((selectableMonth) => {
        return selectableMonth.checked;
      }).map((selectableMonth) => {
        return selectableMonth.label;
      }).join(', ').replace(/, ([^,]*)$/, ' and $1');
      if ( selectableMonths != '' ) {
        cronText += ' In ' + selectableMonths;
      } else {
        cronText += ' Every Month, ';
      }

      cronText += ' At ' + this.form.schedule_hour + (this.form.schedule_hour > 11 ? 'pm' : 'am');
    }

    return cronText;
  }

  /**
   * This resets all stored field values for the form being build.
   * This is only used by the "Form Preview" tab and does not affect any user submitted answers.
   */
  onResetFormPreviewFieldValues(): void {
    // Loop through all form fields and reset their values.
    for ( let i: number=0; i<this.form.fields.length; i++ ) {
      // Checkbox and toggle fields should have their options reset.
      if ( ['checkbox', 'toggle'].indexOf(this.form.fields[i].field_type) > -1 ) {
        for ( let j=0; j<this.form.fields[i].field_options.length; j++ ) {
          this.form.fields[i].field_options[j].option_checked = false;
        }
      } else {
        // Reset the form field value.
        this.form.fields[i].field_value = '';
        this.form.fields[i].field_meta = {};
      }
    }

    // Loop through signature component instances and reset them. This is for previews only.
    for ( let i: number=0; i<this.preview_signature_component_instances.length; i++ ) {
      this.preview_signature_component_instances[i].resetSignaturePad();
    }

    this.utils.showToast('The "Form Preview" field values were reset. You can now test form field conditions on an "unanswered" form.');
  }

  /**
   * Extracts phone details from the given event and updates the form field.
   *
   * @param {any} event - The event containing phone details.
   * @param {number} fieldIndex - The index of the form field to update.
   * @returns {void}
   */
  extractPhoneDetails(event: any, fieldIndex: number): void {
    if( event ) {
      this.form.fields[fieldIndex].field_value = event.hasOwnProperty('number') && event.number !== null ? event.number : '';
      this.form.fields[fieldIndex].field_meta['mobile_country_code'] = event.hasOwnProperty('countryCode') && event.countryCode !== null ? event.countryCode : '';
      this.form.fields[fieldIndex].field_meta['mobile_dial_code'] = event.hasOwnProperty('dialCode') && event.dialCode !== null ? event.dialCode : '';
      this.form.fields[fieldIndex].field_meta['mobile_e164'] = event.hasOwnProperty('e164Number') && event.e164Number !== null ? event.e164Number : '';
      this.form.fields[fieldIndex].field_meta['mobile_error_state'] = event.hasOwnProperty('errorState') && event.errorState !== null ? event.errorState : true;
    }
  }

  /**
   * Opens the account's connected network users selector. This is only for testing the field in the form builder.
   * Selected users are not shown to assigned users.
   *
   * @param {DynamicFormFieldModel} formField - The form field to open the users selector for.
   * @return {void}
   */
  onOpenUsersSelectorForUserSelectorField(formField: DynamicFormFieldModel): void {
    // Make sure the users_id property is set.
    if ( typeof formField.field_meta['user_ids'] == 'undefined' ) {
      formField.field_meta['user_ids'] = [];
    }
    // Open the users selector and store the results in the field meta.
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      multiple: true,
      selected: formField.field_meta['user_ids'],
      selected_objects: formField.field_meta['user_objects'],
      visitors_from_all_sites: true,
      return_objects: true
    })
      .then((response: number[]): void => {
        if ( typeof response != 'undefined' ) {
          // Store user objects.
          formField.field_meta['user_objects'] = response;
          // Extract and store user ids.
          formField.field_meta['user_ids'] = response.map((user: any) => user.id);
        }
      });
  }

  /**
   * Removes a user object for a user selector field.
   *
   * @param {DynamicFormFieldModel} formField - The form field to remove the user object from.
   * @param {number} index - The index of the user object to remove.
   *
   * @return {void}
   */
  onRemoveUserObjectForUserSelectorField(formField: DynamicFormFieldModel, index: number): void {
    // Get user object at index.
    const user_object = formField.field_meta['user_objects'][index];
    // Remove the user id from user ids by user id.
    const user_id_index = formField.field_meta['user_ids'].indexOf(user_object.id);
    if ( user_id_index > -1 ) {
      formField.field_meta['user_ids'].splice(user_id_index, 1);
    }
    // Remove the user_object by index.
    formField.field_meta['user_objects'].splice(index, 1);
  }
}
