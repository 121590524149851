import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";
import {ApiRequestService} from "../../../shared/api-request.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {TaskAnalysesRevisedModel} from "../task-analyses-revised.model";
import {UserPublicProfileComponent} from "../../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-task-analyses-revised-view',
  templateUrl: './task-analyses-revised-view.component.html',
  styleUrls: ['./task-analyses-revised-view.component.scss']
})
export class TaskAnalysesRevisedViewComponent implements OnInit {

  ta_revised: TaskAnalysesRevisedModel = new TaskAnalysesRevisedModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private id: number,
    private utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    const ta_revised_id = this.id;
    if(ta_revised_id) {
      this.getTARevised(ta_revised_id);
    }
  }

  private getTARevised (ta_revised_id: number) {
    this.api.makeRequest('get', `v2/admin/task-analyses-revised/${ta_revised_id}`)
      .then((response) => {
        this.ta_revised = response;

        // Extract PPE ids.
        this.ta_revised.ppe_ids = this.ta_revised.ppe ? this.ta_revised.ppe.map((ppe) => ppe.id) : [];

        // Extract Work Permit ids.
        this.ta_revised.work_permit_ids = this.ta_revised.work_permits ? this.ta_revised.work_permits.map((work_permit) => work_permit.id) : [];
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
