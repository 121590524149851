import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppService } from '../../app.service';
import { CodesService } from './codes.service';
import { tap } from 'rxjs/operators';
import { CustomDataSource } from '../../utils/custom-data-source';
import { UtilsService } from '../../shared/utils.service';
import { ApiService } from '../../shared/api.service';
import { CodeModel } from '../../models/code.model';
import { SelectionModel } from '@angular/cdk/collections';
import { CodesAddManyComponent } from './codes-add-many/codes-add-many.component';
import {CodesFilterComponent} from "./codes-filter/codes-filter.component";

@Component({
  selector: 'app-codes',
  templateUrl: './codes.component.html',
  styleUrls: ['./codes.component.scss']
})
export class CodesComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new CodesDataSource(this.app, this.api);
  selection = new SelectionModel<CodeModel>(true, []);
  displayedColumns = [
    'select',
    'id',
    'paper_id',
    'name',
    'site.name',
    'site.account.name',
    'date_created',
    'buttons'
  ];
  query: string;

  constructor(
    private codeService: CodesService,
    public utils: UtilsService,
    private app: AppService,
    private api: ApiService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.dataSource.getData(false);
  }

  copied(paper_id: string) {
    this.utils.showToast(`Copied "${paper_id}" to clipboard.`);
  }

  addMany() {
    const dialogRef = this.dialog.open(CodesAddManyComponent, {
      width: '300px'
    });
    dialogRef.afterClosed().subscribe((success) => {
      if (success) { this.dataSource.getData(true); }
    });
  }

  async unlink(id: number) {
    await this.codeService.unlink(id).toPromise();
    this.utils.showToast('Unlinked Code');
    this.dataSource.getData();
  }

  delete(code: CodeModel) {
    this.utils.showModal(
      'Remove Code',
      `Are you sure you want to remove "${code.name}"?`,
      () => {
        this.codeService.delete(code.id).subscribe((result) => {
          this.utils.showToast(`Item "${result.data.name}" was removed`);
          this.dataSource.getData(false);
        });
      }
    );
  }

  deleteMany() {
    this.utils.showModal(
      'Delete Many Codes',
      `Are you sure you want to delete ${
        this.dataSource.selection.selected.length
      } QR Codes?`,
      () => {
        this.codeService
          .deleteMany(this.dataSource.selection.selected)
          .subscribe((result) =>
            this.utils.showToast(`Deleted: ${result.meta.total} QR Codes`)
          );
        this.dataSource.selection.clear();
        this.dataSource.getData(true);
      }
    );
  }

  unlinkMany() {
    this.utils.showModal(
      'Unlink Many Codes',
      `Are you sure you want to unlink ${
        this.dataSource.selection.selected.length
      } QR Codes?`,
      () => {
        this.codeService
          .unlinkMany(this.dataSource.selection.selected)
          .subscribe((result) =>
            this.utils.showToast(`Unlinked: ${result.meta.total} QR Codes`)
          );
        this.dataSource.selection.clear();
        this.dataSource.getData(true);
      }
    );
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;
      this.dataSource.getData(false);
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * Open the codes filter and apply any changes before making APi requests to get data.
   * Jira#SIT-38
   */
  onOpenFilters() {
    this.utils.showComponentDialog(CodesFilterComponent, {
        search_by: this.dataSource.search_by,
        only_archived: this.dataSource.only_archived
      },
      {
        width: '350px'
      },
      (filters) => {
        // Check if we have any filters to apply.
        if (typeof filters != 'undefined' && filters) {
          this.dataSource.search_by = typeof filters.search_by != 'undefined' ? filters.search_by : 'id';
          this.dataSource.only_archived = typeof filters.only_archived != 'undefined' ? filters.only_archived : false;
          this.dataSource.getData();
        }
      }
    );
  }

  /**
   * Send an API request to restore multiple codes.
   * Jira#SIT-38
   */
  onRestoreSelected() {
    this.utils.showModal('Restore Codes', 'Are you sure you want to restore the selected codes?', () => {
      this.api.makeRequest('put', 'codes/restore', {
        ids: this.dataSource.selection.selected
      })
        .then((response) => {
          // Show a confirmation toast message.
          this.utils.showToast(response.message);
          // Deselect selected ids.
          this.dataSource.selection.clear();
          // Reload the list after restoring the codes.
          this.dataSource.getData();
        })
        .catch((errorResponse) => {
          this.utils.handleAPIErrors(errorResponse);
        });
    });
  }

  /**
   * Send an API request to restore a single code.
   * Jira#SIT-38
   * @param code
   */
  onRestore(code: CodeModel) {
    this.utils.showModal('Restore Code', `Are you sure you want to restore the "${code.name}" code?`, () => {
      this.api.makeRequest('put', 'codes/restore', {
        ids: [code.id]
      })
        .then((response) => {
          // Show a confirmation toast message.
          this.utils.showToast(response.message);
          // Deselect this record if it was selected.
          this.dataSource.selection.deselect(code.id);
          // Reload the list after restoring the code.
          this.dataSource.getData();
        })
        .catch((errorResponse) => {
          this.utils.handleAPIErrors(errorResponse);
        });
    });
  }
}

export class CodesDataSource extends CustomDataSource {
  sort_by: string = 'name';
  sort_order: string = 'asc';
  limit: number = 30;
  search_by: string = 'id';
  only_archived: string = 'false';

  getData(resetOffset = false) {
    this.getDataFromLaravelAPI(`codes`, resetOffset, () => {}, {
      searchBy: this.search_by,
      only_archived: this.only_archived
    });
  }
}
