import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { CodeModel } from 'src/app/models/code.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-code-feature-access-control',
  templateUrl: './code-feature-access-control.component.html',
  styleUrls: ['./code-feature-access-control.component.scss']
})
export class CodeFeatureAccessControlComponent implements OnInit {

  @Input('code') code: CodeModel;

  @Input('code_hash') code_hash: string;

  signInFormData: {
    company_name: string,
    contact_person: string,
    email: string,
    mobile: string,
    mobile_e164: string,
    mobile_country_code: string,
    mobile_dial_code: string,
    reason: string,
    acknowledged: boolean
  } = {
    company_name: "",
    contact_person: "",
    email: "",
    mobile: "",
    mobile_e164: "",
    mobile_country_code: "",
    mobile_dial_code: "",
    reason: "",
    acknowledged: false
  };

  signOutFormData: {
    id: number,
    company_name: string,
    contact_person: string,
    email: string,
    mobile: string,
    autocomplete_value: string
  } = {
    id: 0,
    company_name: "",
    contact_person: "",
    email: "",
    mobile: "",
    autocomplete_value: ""
  };

  inProgress: boolean = false;

  autoCompletedEntityNames: string[] = [];
  autoCompletedUserEmails: string[] = [];
  autoCompletedOnsiteUserEmails: string[] = [];
  autoCompletedUserObjs: any[] = [];
  autoCompletedOnsiteUserObjs: any[] = [];
  allowAutoComplete: boolean = true; // Only used for Sign-in Email field

  // Mobile field Country Code selector values
  mobile_input_id:string = 'phone-1';
  mobile_number: string = '';
  mobile_country_code: string = '';
  mobile_dial_code: string = '';
  mobile_e164: string = '';
  mobile_error_state: boolean = true;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private router: Router,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if ( !this.code || !this.code.site_id ) {
      this.utils.showToast('This is an invalid QR code or it is not associated with a ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() + '. You will be redirected shortly.');
      setTimeout(() => {
        this.router.navigate(['sign-in']);
      }, 5000);
      return;
    }
  }

  /**
   * Get Output values from phone input component.
   */
  getOutputPhoneDetails($event) {
    if($event) {
      let phone = $event;
      this.mobile_number = phone.hasOwnProperty('number') && phone.number !== null ? phone.number : '';
      this.mobile_country_code = phone.hasOwnProperty('countryCode') && phone.countryCode !== null ? phone.countryCode : '';
      this.mobile_dial_code = phone.hasOwnProperty('dialCode') && phone.dialCode !== null ? phone.dialCode : '';
      this.mobile_e164 = phone.hasOwnProperty('e164Number') && phone.e164Number !== null ? phone.e164Number : '';
      this.mobile_error_state = phone.hasOwnProperty('errorState') && phone.errorState !== null ? phone.errorState : true;
    }
  }

  // Debounce timer and delay for the entity name autocomplete.
  debounceTimer: any;
  debounceDelay: number = 300;

  /**
   * Gets the entity names from the API for the autocomplete.
   * @param search The search string.
   */
  getEntityNames(search: string): void {
    // Check if the search string is less than 3 characters.
    if ( search.length < 3 ) {
      return;
    }
    // Check if the debounce timer is set.
    if ( this.debounceTimer ) {
      // Clear the debounce timer.
      clearTimeout(this.debounceTimer);
    }
    // Set the debounce timer.
    this.debounceTimer = setTimeout(() => {
      // Make an API request to get the entity names.
      this.api.makeRequest('get', 'v2/global-account-search', {}, {
        search: search,
        order_by: 'name',
        order_direction: 'asc'
      }).then((response) => {
        // Clear the current list of accounts and store the new ones.
        this.autoCompletedEntityNames.length = 0;
        this.autoCompletedEntityNames.push(...response.data.map((record: { name: any; }) => record.name));
      });
    }, this.debounceDelay);
  }

  /**
   * Gets the user emails from the API for the autocomplete.
   * @param search The search string.
   * @param type The form type.
   */
  getUserEmails(search: string, type: string = 'signin'): void {
    // Check if the search string is less than 3 characters.
    if ( search.length < 3 || (type == 'signin' && !this.allowAutoComplete) ) {
      return;
    }
    // Check if the debounce timer is set.
    if ( this.debounceTimer ) {
      // Clear the debounce timer.
      clearTimeout(this.debounceTimer);
    }
    // Set the debounce timer.
    this.debounceTimer = setTimeout(() => {
      // Make an API request to get the user emails.
      this.api.makeRequest('get', 'v1/tablet/signed-in-users-search', {}, {
        search: search,
        site_id: this.code.site_id,
        only_onsite_users: type == 'signout',
        sign_in_field: 'email',
        order_by: 'email',
        order_direction: 'asc'
      }).then((response) => {
        if(type == 'signin') {
          // Clear the current list of user emails and store the new ones.
          this.autoCompletedUserEmails.length = 0;
          // Populate with new data.
          this.autoCompletedUserObjs = response.data;
          this.autoCompletedUserEmails.push(...response.data.map((record: { email: any; }) => record.email));
          this.autoCompletedUserEmails = Array.from(new Set(this.autoCompletedUserEmails)); // Removes Duplicate entries
        } else if(type == 'signout') {
          // Clear the current list of onsite user emails and store the new ones.
          this.autoCompletedOnsiteUserEmails.length = 0;
          // Populate with new data.
          this.autoCompletedOnsiteUserObjs = response.data;
          this.autoCompletedOnsiteUserEmails.push(...response.data.map((record: { email: any; }) => record.email));
          this.autoCompletedOnsiteUserEmails = Array.from(new Set(this.autoCompletedOnsiteUserEmails)); // Removes Duplicate entries
        }
      });
    }, this.debounceDelay);
  }

  /**
   * Set the selected user email.
   * Clear the list of auto completed user emails.
   * Autofill Full Name and Mobile fields.
   * @param email The selected user email.
   */
  onSelectUserEmail(email: string): void {
    // Clear Autocomplete results
    this.autoCompletedUserEmails.length = 0;
    // Find User Details Object in array with matching email
    let autoCompletedUserObj = this.autoCompletedUserObjs.find(obj => obj.email == email);

    // Autofill Full Name and Mobile fields
    this.signInFormData.contact_person = autoCompletedUserObj.contact_person ? autoCompletedUserObj.contact_person : '';

    // Set Input values for phone input component.
    this.signInFormData.mobile = autoCompletedUserObj.mobile ? autoCompletedUserObj.mobile : '';
    this.signInFormData.mobile_e164 = autoCompletedUserObj.mobile_e164 ? autoCompletedUserObj.mobile_e164 : '';
    this.signInFormData.mobile_country_code = autoCompletedUserObj.mobile_country_code ? autoCompletedUserObj.mobile_country_code : '';
    this.signInFormData.mobile_dial_code = autoCompletedUserObj.mobile_dial_code ? autoCompletedUserObj.mobile_dial_code : '';
  }

  /**
   * Set the selected onsite user email.
   * Clear the list of auto completed onsite user emails.
   * Autofill Full Name, Email and Mobile fields.
   * @param email The selected onsite user email.
   */
  onSelectOnsiteUserEmail(email: string): void {
    // Clear Autocomplete results
    this.autoCompletedOnsiteUserEmails.length = 0;
    // Find User Details Object in array with matching email
    let autoCompletedOnsiteUserObj = this.autoCompletedOnsiteUserObjs.find(obj => obj.email == email);

    // Autofill ID, Company Name, Full Name, Email and Mobile fields
    this.signOutFormData.id = autoCompletedOnsiteUserObj.id ? autoCompletedOnsiteUserObj.id : 0;
    this.signOutFormData.company_name = autoCompletedOnsiteUserObj.company_name ? autoCompletedOnsiteUserObj.company_name : '';
    this.signOutFormData.contact_person = autoCompletedOnsiteUserObj.contact_person ? autoCompletedOnsiteUserObj.contact_person : '';
    this.signOutFormData.mobile = autoCompletedOnsiteUserObj.mobile ? autoCompletedOnsiteUserObj.mobile : '';
    this.signOutFormData.email = autoCompletedOnsiteUserObj.email ? autoCompletedOnsiteUserObj.email : '';

    // Clear Autocomplete selected value
    this.signOutFormData.autocomplete_value = '';
  }

  onSignIn(form: NgForm) {
    if ( !form.valid ) {
      this.utils.showToast('Please enter all required fields denoted with "*".');
      return;
    }

    // Check Error states of phone input then continue submission
    if (this.mobile_error_state) {
      if(this.mobile_number.length < 3) {
        this.utils.showToast('A Phone number must be at least 3 characters long.');
      } else {
        this.utils.showToast('Please enter all required fields denoted with "*".');
      }
      return;
    }

    // Check if another request is already in progress.
    if ( this.inProgress ) {
      return;
    }

    // Update User details with Mobile field Country Code selector values
    this.signInFormData.mobile = this.mobile_number;
    this.signInFormData.mobile_country_code = this.mobile_country_code;
    this.signInFormData.mobile_dial_code = this.mobile_dial_code;
    this.signInFormData.mobile_e164 = this.mobile_e164;

    this.inProgress = true;

    this.api.makeRequest('post', `v2/codes/signin/${this.code_hash}`, this.signInFormData)
    .then((response) => {
      this.utils.showModal('Message', response.message);
      this.router.navigate(['sign-in']);
    })
    .finally(() => {
      this.inProgress = false;
    });
  }

  clearWhiteSpace(content: string) {
    if ( content && content != '' ) {
      return content.replace(/&nbsp;/gi, '');
    }
    return '';
  }

  onSignOut(form: NgForm) {
    if ( !form.valid || this.signOutFormData.id <= 0) {
      this.utils.showToast('Please select Email address from Autocomplete list.');
      return;
    }

    this.inProgress = true;

    this.api.makeRequest('post', `v2/codes/signout/${this.code_hash}`, {
      user_id: this.signOutFormData.id
    })
      .then((response) => {
        this.utils.showModal('Message', response.message);
        this.router.navigate(['sign-in']);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  onClear() {
    // Clear Autocomplete results
    this.autoCompletedOnsiteUserEmails.length = 0;

    // Clear Form
    this.signOutFormData.id = 0;
    this.signOutFormData.company_name = '';
    this.signOutFormData.contact_person = '';
    this.signOutFormData.mobile = '';
    this.signOutFormData.email = '';

    // Clear Autocomplete selected value
    this.signOutFormData.autocomplete_value = '';
  }

  hideEmail(email: string) {
    // e.g. clint.esau@sitesoft.com -> c*********@sitesoft.com
    return email
      ? email.replace(/\B.+@/g, (c) => c.split('').slice(0, -1).map(v => '*').join('') + '@')
      : email;
  }

  hideMobile(mobile: string) {
    // Doesn't work in Safari browser on IOS devices
    // e.g. (123) 123-1234 -> (1**) ***-**34
    // return mobile
    //   ? mobile.replace(/(?<!\()\d(?!\d?$)/g, '*')
    //   : mobile;

    let mask_mobile = mobile;

    if(mobile && mobile.length >= 5) {
      // e.g. 021702780 -> 02*****80
      mask_mobile = mobile.substring(0, 2) + "*".repeat(mobile.length-4) + mobile.substring(mobile.length-2, mobile.length);
    } else if(mobile && mobile.length == 4) {
      // e.g. 3224 -> 3**4
      mask_mobile = mobile.substring(0, 1) + "*".repeat(2) + mobile.slice(-1);
    } else if(mobile && mobile.length == 3) {
      // e.g. 111 -> 1*1
      mask_mobile = mobile.substring(0, 1) + "*" + mobile.slice(-1);
    }

    return mobile
      ? mask_mobile
      : mobile;
  }

}
