<div mat-dialog-title>
  <h5>Create TA/JSA/SWMS Template</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit(form)">

  <mat-dialog-content style="max-height: 40vh" class="mb-3">
    <mat-form-field class="mb-3">
      <input
        matInput
        placeholder="Name"
        [(ngModel)]="ta_revised.task_name"
        name="task_name"
        type="text"
        required
        #task_name
        matTooltip="Enter a TA/JSA/SWMS Template name." />
      <mat-hint>Please enter a TA/JSA/SWMS name.</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="2"
        matInput
        placeholder="Description"
        [(ngModel)]="ta_revised.task_description"
        name="task_description"
        required
        #task_description
        matTooltip="Enter a TA/JSA/SWMS Template description.">
      </textarea>
      <mat-hint>Please enter a TA/JSA/SWMS description.</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <input
        matInput
        placeholder="Work Procedure"
        [(ngModel)]="ta_revised.work_procedure"
        name="work_procedure"
        type="text"
        #work_procedure />
      <mat-hint>Enter the Work Procedures for this TA/JSA/SWMS Template.</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Training/Qualifications"
        [(ngModel)]="ta_revised.training_qualifications"
        name="training_qualifications"
        #training_qualifications>
      </textarea>
      <mat-hint>Enter the Training/Qualifications needed for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Duties"
        [(ngModel)]="ta_revised.duties"
        name="duties"
        #duties>
      </textarea>
      <mat-hint>Enter the Duties to undertake for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Certificates of Competency"
        [(ngModel)]="ta_revised.certificates"
        name="certificates"
        #certificates>
      </textarea>
      <mat-hint>Enter the Certificates of Competency required for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Code of Practice"
        [(ngModel)]="ta_revised.code_of_practice"
        name="code_of_practice"
        #code_of_practice>
      </textarea>
      <mat-hint>Enter the Code of Practice for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Maintenance Checks"
        [(ngModel)]="ta_revised.maintenance_checks"
        name="maintenance_checks"
        #maintenance_checks>
      </textarea>
      <mat-hint>Enter the Maintenance Checks required for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Safety Practice"
        [(ngModel)]="ta_revised.safety_practices"
        name="safety_practices"
        #safety_practices>
      </textarea>
      <mat-hint>Enter the Safety Practices for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="PPE Other"
        [(ngModel)]="ta_revised.ppe_other"
        name="ppe_other"
        #ppe_other>
      </textarea>
      <mat-hint>Enter the Other PPE required for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Work Permits Other"
        [(ngModel)]="ta_revised.work_permits_other"
        name="work_permits_other"
        #work_permits_other>
      </textarea>
      <mat-hint>Enter Other Work Permits required for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Tools Other"
        [(ngModel)]="ta_revised.tools_other"
        name="tools_other"
        #tools_other>
      </textarea>
      <mat-hint>Enter Other Tools required for this TA/JSA/SWMS Template. (Seperated by a new line)</mat-hint>
    </mat-form-field>
  </mat-dialog-content>

  <div class="row m-0 mt-1">
    <div class="col p-0">

      <button
        type="button"
        mat-raised-button
        color="secondary"
        (click)="onSelectApprover()"
        [matBadge]="ta_revised.approver_id ? 1 : 0"
        class="margin-right my-2"
        matTooltip="Select an approver.">
        <mat-icon>rate_review</mat-icon>
        {{ !ta_revised.approver_id ? 'Select' : 'Change' }} Approver
      </button>

      <button
        type="button"
        mat-raised-button
        color="secondary"
        (click)="onSelectAuthorizer()"
        [matBadge]="ta_revised.authorizer_id ? 1 : 0"
        class="margin-right my-2"
        matTooltip="Select an authorizer.">
        <mat-icon>rate_review</mat-icon>
        {{ !ta_revised.authorizer_id ? 'Select' : 'Change' }} Authorizer
      </button>

      <button
        mat-raised-button
        type="button"
        color="secondary"
        (click)="onSelectSupervisor()"
        [matBadge]="ta_revised.supervisor_id ? 1 : 0"
        class="margin-right my-2"
        matTooltip="Select a supervisor">
        <mat-icon>person</mat-icon>
        {{ !ta_revised.supervisor_id ? 'Select' : 'Change' }} Supervisor
      </button>

    </div>
  </div>

  <div class="row m-0">
    <div class="col p-0">
      <button
        mat-raised-button
        type="button"
        (click)="selectPPE()"
        [matBadge]="ta_revised.ppe_ids ? ta_revised.ppe_ids?.length : 0"
        class="margin-right my-2"
        color="secondary"
        matTooltip="Select PPE from the PPE selection list.">
        <mat-icon>headset</mat-icon>
        PPE
      </button>

      <button
        mat-raised-button
        type="button"
        (click)="selectWorkPermits()"
        [matBadge]="ta_revised.work_permit_ids ? ta_revised.work_permit_ids?.length : 0"
        class="margin-right my-2"
        color="secondary"
        matTooltip="Select Work Permits from the Work Permits selection list.">
        <mat-icon>assignment_ind</mat-icon>
        Work Permits
      </button>

      <button
        mat-raised-button
        type="button"
        (click)="selectTools()"
        [matBadge]="ta_revised.tool_ids ? ta_revised.tool_ids?.length : 0"
        class="margin-right my-2"
        color="secondary"
        matTooltip="Select Tools from the Tools selection list.">
        <mat-icon>build</mat-icon>
        Tools
      </button>

    </div>
  </div>

  <button
    mat-raised-button
    [disabled]="!form.valid || inProgress"
    color="primary"
    type="button"
    id="save"
    class="margin-right my-2"
    (click)="onSubmit(form)">
    <mat-icon>save</mat-icon>
    Save & Close
  </button>

  <button
    mat-raised-button
    [disabled]="!form.valid || inProgress"
    color="primary"
    type="button"
    id="continue"
    class="margin-right my-2"
    (click)="onSubmit(form, true)">
    <mat-icon>save</mat-icon>
    Save & Continue
  </button>

  <app-file-browse-button
    *ngIf="!ta_revised.clone_from_id"
    (selectedFiles)="onSelectFiles($event)"
    (afterDialogClosedEvent)="onSubmit(form)"
    parentObjectType="task_analysis_revised"
    [parentObjectId]="ta_revised.id"
    [filesCount]="ta_revised.files?.length">
  </app-file-browse-button>

</form>
