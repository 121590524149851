<ng-container *ngIf="user.profile_pic">
  <div class="mt-4">
    <h6>Profile Picture</h6>
    <img [src]="user.profile_pic" class="profile-picture" alt="Profile Picture" />
  </div>
</ng-container>

<div class="mt-4">
  <h6>Full Name</h6>
  <p>{{ user.contact_person }}</p>
</div>

<h6>Job Title</h6>
<p>{{ user.trade }}</p>

<ng-container *ngIf="user.iqp_number">
  <h6>IQP Number</h6>
  <p>{{ user.iqp_number }}</p>
</ng-container>

<ng-container *ngIf="user.employer_name">
  <h6>Employer</h6>
  <p>{{ user.employer_name }}</p>
</ng-container>
