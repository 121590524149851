import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import { Message } from 'src/app/models/message.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { UserMessagesService } from '../user-messages.service';

@Component({
  selector: 'app-user-messages-view',
  templateUrl: './user-messages-view.component.html',
  styleUrls: ['./user-messages-view.component.scss']
})
export class UserMessagesViewComponent implements OnInit {

  message: Message = new Message();

  messageRecipientListHeaders: string[] = [
    'contact_person',
    'employer'
  ];
  messageRecipientListDataSource = new UserMessageRecipientsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;

  constructor(
    public app: AppService,
    public api: ApiRequestService,
    public utils: UtilsService,
    private dialogRef: MatDialogRef<MessageEditComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private userMessagesService: UserMessagesService
  ) { }

  ngOnInit() {
    // Get the message id
    const messageId = Number(this.dialogData['messageId']);
    // Validate and make a request to get the message data
    if ( !messageId ) {
      this.utils.showToast('The message could not be opened.');
      this.dialogRef.close();
      return;
    }

    this.api.makeRequest('get', `v2/user/messages/${messageId}`)
    .then((message: Message) => {
      this.message.apply(message);
      // Get the messages meta
      this.userMessagesService.getMessagesMeta();

      this.messageRecipientListDataSource.messageId = this.message.id;
      this.messageRecipientListDataSource.getData(true);
    })
    .catch(() => {
      this.utils.showToast('The message could not be opened.');
      this.dialogRef.close();
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.messageRecipientListDataSource.limit = paginator['pageSize'];
      this.messageRecipientListDataSource.offset = paginator['pageIndex'];
      this.messageRecipientListDataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();
  }

  onOpenUrl(url: string) {
    this.utils.openUrl(url);
  }

}

export class UserMessageRecipientsDataSource extends ApiDataSource {

  messageId: number = 0;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/user/messages/${this.messageId}/recipients`, resetOffset);
  }
}
