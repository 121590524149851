import { Component, OnInit, Inject } from '@angular/core';
import { IncidentWitnessModel } from '../incident-witness.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from 'src/app/shared/utils.service';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { FileUploaderService } from 'src/app/shared/file-uploader.service';
import { IncidentWitnessService } from '../incident-witness.service';
import { UserService } from 'src/app/shared/user.service';
import { FilesRemoveComponent } from 'src/app/files/files-remove/files-remove.component';

@Component({
  selector: 'app-incidents-witnesses-edit',
  templateUrl: './incidents-witnesses-edit.component.html',
  styleUrls: ['./incidents-witnesses-edit.component.scss']
})
export class IncidentsWitnessesEditComponent implements OnInit {
  witness = {} as IncidentWitnessModel;
  newFiles: FileList;
  inProgress = false;

  // Mobile field Country Code selector values
  phone_input_id: string = 'phone-1';
  phone_number: string = '';
  phone_country_code: string = '';
  phone_dial_code: string = '';
  phone_e164: string = '';
  phone_error_state: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { witnessId: number | string; incidentId: number },
    public utils: UtilsService,
    private dialog: MatDialogRef<IncidentsWitnessesEditComponent>,
    private fileUploaderService: FileUploaderService,
    private witnessService: IncidentWitnessService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.witness.id = Number(this.data.witnessId);
    this.witness.incident_id = this.data.incidentId;
    if (isNaN(this.witness.id)) {
      return;
    }
    this.witnessService
      .find(this.witness.id)
      .subscribe((response) => {
        this.witness = response.data;
        this.initPhoneDetails();
      });
  }

  /**
   * Get Output values from phone input component.
   */
  getOutputPhoneDetails($event) {
    if($event) {
      let phone = $event;
      this.phone_number = phone.hasOwnProperty('number') && phone.number !== null ? phone.number : '';
      this.phone_country_code = phone.hasOwnProperty('countryCode') && phone.countryCode !== null ? phone.countryCode : '';
      this.phone_dial_code = phone.hasOwnProperty('dialCode') && phone.dialCode !== null ? phone.dialCode : '';
      this.phone_e164 = phone.hasOwnProperty('e164Number') && phone.e164Number !== null ? phone.e164Number : '';
      this.phone_error_state = phone.hasOwnProperty('errorState') && phone.errorState !== null ? phone.errorState : true;
    }
  }

  /**
   * Set Input values for phone input component.
   */
  private initPhoneDetails() {
    this.phone_number = this.witness.phone;
    this.phone_country_code = this.witness.phone_country_code;
    this.phone_dial_code = this.witness.phone_dial_code;
    this.phone_e164 = this.witness.phone_e164;
  }

  onRemoveFiles() {
    this.utils.showComponentDialog(
      FilesRemoveComponent,
      this.witness.files,
      {
        width: '700px'
      },
      () => {
        this.ngOnInit();
      }
    );
  }

  onSelectWitness() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: false,
        selected: [this.witness.user_id],
        visitors_from_all_sites: true
      },
      {},
      (witnessId: number) => {
        if (typeof witnessId === 'undefined') {
          return;
        }
        this.witness.user_id = witnessId;
        this.userService.find(witnessId).subscribe((response) => {
          this.witness.full_name = response.data.contact_person;
          this.witness.email = response.data.email;
          this.witness.phone = response.data.mobile;
          this.witness.phone_country_code = response.data.mobile_country_code;
          this.witness.phone_dial_code = response.data.mobile_dial_code;
          this.witness.phone_e164 = response.data.mobile_e164;
          this.witness.company_name = response.data.company_name;
        });
      }
    );
  }

  onDelete() {
    this.utils.showModal(
      'Delete Witness',
      'Are you sure you want to delete this witness?',
      () => {
        this.witnessService.remove([this.witness.id]).subscribe(() => {
          this.utils.showToast('Deleted witness');
          this.dialog.close(true);
        });
      }
    );
  }

  onSubmit() {
    // Check Error states of phone inputs then continue submission
    if(this.phone_error_state) {
      this.utils.showModal('Form Validation Failed','A Phone number must be at least 3 characters long.');
      return;
    }

    // Terminate early if another process is in progress.
    if (this.inProgress) {
      return;
    }

    // Update User details with Mobile field Country Code selector values
    this.witness.phone = this.phone_number;
    this.witness.phone_country_code = this.phone_country_code;
    this.witness.phone_dial_code = this.phone_dial_code;
    this.witness.phone_e164 = this.phone_e164;

    this.inProgress = true;
    if (isNaN(this.witness.id)) {
      this.create();
    } else {
      this.update();
    }
  }

  private create() {
    this.witnessService.create(this.witness).subscribe(() => {
      if (this.newFiles && this.newFiles.length) {
        this.inProgress = false;
        this.utils.showToast('Added a Witness, uploading files...');
        this.fileUploaderService
          .storeUploadedFiles(
            this.newFiles,
            'incident-witness',
            this.witness.id
          )
          .subscribe(
            () => {},
            () => {},
            () => {
              this.utils.showToast('Files uploaded');
              this.dialog.close(true);
              this.inProgress = false;
            }
          );
      } else {
        this.utils.showToast('Added a Witness');
        this.dialog.close(true);
      }
    }, (_error: any) => this.inProgress = false);
  }

  private update() {
    this.witnessService.update(this.witness).subscribe(() => {
      if (this.newFiles && this.newFiles.length) {
        this.utils.showToast('Updated a Witness, uploading files...');
        this.fileUploaderService
          .storeUploadedFiles(
            this.newFiles,
            'incident-witness',
            this.witness.id
          )
          .subscribe(
            () => {},
            () => {},
            () => {
              this.utils.showToast('Files uploaded');
              this.newFiles = null;
              this.ngOnInit();
            }
          );
      } else {
        this.utils.showToast('Updated a Witness');
        this.newFiles = null;
        this.ngOnInit();
      }
    });
  }
}
