
import { FileModel } from "../shared/file.model";
import { AccountModel } from "./account.model";
import { SiteModel } from "./site.model";
import { UserModel } from "./user.model";

export class Message {

  id: number = 0;

  account_id: number = 0;
  message_id: number = 0; // Parent message id for nested messages.

  subject: string = '';
  message: string = '';

  type: string = 'email'; // Can only be email, text or push notifications.
  status: string = 'draft'; // Can only be draft in web portal for editing.
  priority: string = 'normal'; // Can only be low, normal, high or critical.

  only_admins: boolean = true;
  only_onsite: boolean = true;

  send_at: any = 0;

  date_created: number = 0;
  date_modified: number = 0;
  deleted_at: number = 0;

  created_by: number = 0;
  updated_by: number = 0;
  deleted_by: number = 0;

  // Used for relational data
  const_accounts: AccountModel[] = [];
  const_accounts_ids: number[] = [];

  const_sites: SiteModel[] = [];
  const_sites_ids: number[] = [];

  const_users: UserModel[] = [];
  const_users_ids: number[] = [];

  files: FileModel[] = [];

  reply_to: UserModel = new UserModel();

  /**
   * The Hazardous Substance ID from which the new Hazardous Substance is created.
   */
  create_from_id = 0;

  safety_observation_id: number = null;

  apply(data: any) {
    if ( data ) {
      this.reset();
      Object.keys(data).forEach((key) => {
        if (key in this) {
          switch ( key ) {
            case 'const_accounts':
              this.const_accounts.length = 0;
              this.const_accounts_ids.length = 0;
              data[key].forEach((account: AccountModel) => {
                this.const_accounts.push(new AccountModel().apply(account));
                this.const_accounts_ids.push(account.id);
              });
              break;
            case 'const_sites':
              this.const_sites.length = 0;
              this.const_sites_ids.length = 0;
              data[key].forEach((site: SiteModel) => {
                this.const_sites.push(new SiteModel().apply(site));
                this.const_sites_ids.push(site.id);
              });
              break;
            case 'const_users':
              this.const_users.length = 0;
              this.const_users_ids.length = 0;
              data[key].forEach((user: UserModel) => {
                this.const_users.push(new UserModel().apply(user));
                this.const_users_ids.push(user.id);
              });
              break;
            case 'files':
              this[key].length = 0;
              data[key].forEach((file: FileModel) => {
                this[key].push(file);
              });
              break;
            case 'reply_to':
              this.reply_to.apply(data[key]);
              break;
            default:
              this[key] = data[key];
          }
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;

    this.account_id = 0;
    this.message_id = 0; // Parent message id for nested messages.

    this.subject = '';
    this.message = '';

    this.type = 'email'; // Can only be email, text or push notifications.
    this.status = 'draft'; // Can only be draft in web portal for editing.
    this.priority = 'normal'; // Can only be low, normal, high or critical

    this.only_admins = true;
    this.only_onsite = true;

    this.date_created = 0;
    this.date_modified = 0;
    this.deleted_at = 0;
    this.created_by = 0;
    this.updated_by = 0;
    this.deleted_by = 0;

    this.const_accounts.length = 0;
    this.const_accounts_ids.length = 0;
    this.const_sites.length = 0;
    this.const_sites_ids.length = 0;
    this.const_users.length = 0;
    this.const_users_ids.length = 0;

    this.reply_to.reset();

    this.files.length = 0;
  }

  createFrom(data: any) {
    this.create_from_id = data.id;

    this.subject = data.subject;
    this.message = data.message;

    this.type = data.type; // Can only be email, text or push notifications.
    this.status = 'draft'; // Can only be draft in web portal for editing.
    this.priority = data.priority; // Can only be low, normal, high or critical

    this.send_at = data.send_at;
  }

}
