import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from 'src/app/shared/utils.service';
import { AuditItemsSelectorComponent } from '../audit-items-selector/audit-items-selector.component';
import { AuditsModel } from '../audits.model';
import { AuditsService } from '../audits.service';

@Component({
  selector: 'app-audits-templates-edit',
  templateUrl: './audits-templates-edit.component.html',
  styleUrls: ['./audits-templates-edit.component.scss']
})
export class AuditsTemplatesEditComponent implements OnInit {
  /**
   * Is the form being submitted?
   */
  inProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public audit: AuditsModel,
    private utils: UtilsService,
    private dialog: MatDialog,
    private auditsService: AuditsService,
    private dialogRef: MatDialogRef<AuditsTemplatesEditComponent>
  ) {}

  ngOnInit() {
    if (!isNaN(this.audit.id)) { this.find(this.audit.id); }
  }

  onSubmit() {
    if (this.inProgress) {
      return;
    }

    this.inProgress = true;
    this.audit.template = true;
    isNaN(this.audit.id) ? this.create() : this.update();
  }

  onSelectAuditItems() {
    const dialogRef = this.dialog.open(AuditItemsSelectorComponent, {
      width: '1024px',
      data: { selected: this.audit.item_ids, multiple: true }
    });
    dialogRef.afterClosed().subscribe((itemIds: number[]) => {
      if (!Array.isArray(itemIds)) { return; }
      this.audit.item_ids = itemIds;
    });
  }

  delete(audits: AuditsModel) {
    this.utils.showModal(
      'Remove Inspection/Audit template',
      `Are you sure you want to remove the "${audits.name}" Inspection/Audit template?`,
      () => {
        this.auditsService.remove([audits.id]).subscribe(() => {
          this.onSuccess(`The "${this.audit.name}" Inspection/Audit template was removed.`);
        });
      }
    );
  }

  private find(id: number) {
    this.auditsService.find(id).subscribe((result) => {
      this.audit = result.data;
      this.audit.item_ids = result.data.items.map((item) => item.id);
    });
  }

  private update() {
    this.auditsService.put(this.audit).subscribe({
      next: () => this.onSuccess(`The "${this.audit.name}" Inspection/Audit template was updated.`),
      error: () => this.inProgress = false,
      complete: () => this.inProgress = false
    });
  }

  private create() {
    this.auditsService.post(this.audit).subscribe({
      next: () => this.onSuccess(`The "${this.audit.name}" Inspection/Audit template was updated.`),
      error: () => this.inProgress = false,
      complete: () => this.inProgress = false
    });
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close(true);
  }
}
