import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CONST from '../app.const';
import { AppService } from '../app.service';
import { StorageService } from './storage.service';
import { UtilsService } from './utils.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    public http: HttpClient,
    public utils: UtilsService,
    public app: AppService,
    public storage: StorageService,
    public router: Router
  ) {}

  // @depricated
  request(
    method: string,
    endpoint: string,
    payload: any = {},
    headers = {},
    success_callback?: any,
    fail_callback?: any,
    error_callback?: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      // get the auth token
      headers['SS-Token'] = this.storage.get('AuthToken') || ''; // header cannot be null or empty

      // delcaire the request object
      let request: any;

      // prepare the request options
      const options = {
        headers: new HttpHeaders(headers)
      };

      // prepare the service url
      const serviceUrl = this.getServiceURL(endpoint);

      // define the request object
      switch (method) {
        case 'post':
        case 'POST':
          request = this.http.post(serviceUrl, payload, options);
          break;
        case 'put':
        case 'PUT':
          request = this.http.put(serviceUrl, payload, options);
          break;
        case 'delete':
        case 'DELETE':
          request = this.http.delete(serviceUrl, options);
          break;
        default:
          request = this.http.get(serviceUrl, options);
      }

      // execute the request object
      request.subscribe(
        (response: any) => {
          if (response.status !== 'auth_failed') {
            if (response.status === 'ok') {
              if (typeof success_callback === 'function') {
                success_callback(response);
              }

              resolve(response);
            } else {
              if (typeof fail_callback === 'function') {
                fail_callback(response);
              } else {
                this.utils.showModal(response.status, response.message);
              }

              resolve(null);
            }
          } else {
            // sign the user out and clear app.service data
            // this.utils.showModal(response.status, response.message);

            this.app.isAuthenticated = false;
            this.app.user.reset();
            this.app.account.reset();

            resolve(null);
          }
        },
        (error: any) => {
          // show the dialog error

          if (typeof error_callback === 'function') {
            error_callback(error);
          }

          reject(error);
        }
      );
    });
  }

  // @depricated
  requestObservable(
    method: string,
    endpoint: string,
    payload: any = {},
    headers = {},
    success_callback?: any,
    fail_callback?: any,
    error_callback?: any
  ): Observable<any> {
    // get the auth token
    headers['SS-Token'] = this.storage.get('AuthToken');

    // delcaire the request object
    let request: any;

    // prepare the request options
    const options = {
      headers: new HttpHeaders(headers)
    };

    // prepare the service url
    const serviceUrl = this.getServiceURL(endpoint);

    // define the request object
    switch (method) {
      case 'post':
      case 'POST':
        request = this.http.post(serviceUrl, payload, options);
        break;
      case 'put':
      case 'PUT':
        request = this.http.put(serviceUrl, payload, options);
        break;
      case 'delete':
      case 'DELETE':
        request = this.http.delete(serviceUrl, options);
        break;
      default:
        request = this.http.get(serviceUrl, options);
    }

    return request;
  }

  laravelApiObservable(
    method: string,
    endpoint: string,
    payload: any = {},
    headers = {},
    params = {}
  ): Observable<any> {
    // get the auth token
    headers['Authorization'] = 'Bearer ' + this.storage.get('AuthToken');

    // delcaire the request object
    let request: any;

    // prepare the request options
    const options = {
      headers: new HttpHeaders(headers),
      params: params
    };

    // prepare the service url
    const apiEndpoint = this.getLaravelApiURL(endpoint);

    // define the request object
    switch (method) {
      case 'post':
      case 'POST':
        request = this.http.post(apiEndpoint, payload, options);
        break;
      case 'put':
      case 'PUT':
        request = this.http.put(apiEndpoint, payload, options);
        break;
      case 'delete':
      case 'DELETE':
        request = this.http.delete(apiEndpoint, options);
        break;
      case 'patch':
        request = this.http.patch(apiEndpoint, payload, options);
        break;
      default:
        request = this.http.get(apiEndpoint, options);
    }

    return request;
  }

  laravelApiRequest(
    method: string,
    endpoint: string,
    payload: any = {},
    headers = {},
    success_callback?: any,
    error_callback?: any,
    params = {}
  ): void {
    this.laravelApiObservable(
      method,
      endpoint,
      payload,
      headers,
      params
    ).subscribe(
      (response: any) => {
        if (typeof success_callback === 'function') {
          success_callback(response);
        }
      },
      (error: any) => {
        if (typeof error_callback === 'function') {
          error_callback(error.error);
        }

        // check if authentication failed
        if (error.error && error.error.status_code === 401) {
          this.storage.delete('AuthToken');

          this.app.isAuthenticated = false;
          this.app.user.reset();
          this.app.account.reset();

          this.router.navigate(['sign-in']);
        }
      }
    );
  }

  /**
   *
   * Created on 22/11/2019
   *
   * This makeRequest() method returns a promise that can be chained with
   * .then() and .catch() methods to handle responses and errors.
   *
   * @param method Possible values: POST, PUT, GET, DELETE. Defaults to GET.
   * @param endpoint The relative endpoint for the API. This can also be a fully qualified URL to an external service.
   * @param payload The data that should be sent with POST and PUT requests. It is ignored for all other requests.
   * @param headers Addtional headers that should be sent with the request.
   * @param params Additional params that should be sent with the request. These params are appended to the endpoint being queried e.g. ?limit=30&offset=0
   */
  makeRequest(
    method: string,
    endpoint: string,
    payload: any = {},
    headers = {},
    params = {}
  ): Promise<any> {
    return this.laravelApiObservable(
      method,
      endpoint,
      payload,
      headers,
      params
    ).toPromise();
  }

  /**
   *
   * Created on 25/11/2019
   *
   * This makeDownloadRequest() method returns a promise that can be chained with
   * .then() and .catch() methods to handle responses and errors.
   *
   * @param endpoint The relative endpoint for the API. This can also be a fully qualified URL to an external service.
   * @param headers Addtional headers that should be sent with the request.
   * @param params Additional params that should be sent with the request. These params are appended to the endpoint being queried e.g. ?limit=30&offset=0
   */
  makeDownloadRequest(
    endpoint: string,
    headers = {},
    params = {}
  ): Promise<any> {
    // get the auth token
    headers['Authorization'] = 'Bearer ' + this.storage.get('AuthToken');
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';

    // prepare the service url
    const apiEndpoint = this.getLaravelApiURL(endpoint);

    return this.http.get(apiEndpoint, {
      headers: new HttpHeaders(headers),
      responseType: 'blob', // Response must be blob
      params: params
    }).toPromise();
  }

  // @depricated
  laravelApiDownloadObservable(
    endpoint: string,
    headers = {},
    _params = {}
  ): Observable<any> {
    // get the auth token
    headers['Authorization'] = 'Bearer ' + this.storage.get('AuthToken');
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';

    // prepare the service url
    const apiEndpoint = this.getLaravelApiURL(endpoint);

    return this.http.get(apiEndpoint, {
      headers: new HttpHeaders(headers),
      responseType: 'blob',
      params: _params
    });
  }

  // @depricated
  laravelApiDownloadRequest(
    endpoint: string,
    headers = {},
    success_callback?: any,
    error_callback?: any
  ): void {
    this.laravelApiDownloadObservable(endpoint, headers).subscribe(
      (response: any) => {
        if (typeof success_callback === 'function') {
          success_callback(response);
        }
      },
      (error: any) => {
        if (typeof success_callback === 'function') {
          error_callback(error.error);
        }
      }
    );
  }

  // @depricated
  laravelApiUploadFiles(endpoint: string, files: any[], callback?: any): void {
    // create the form object
    const form = new FormData();
    // append each file
    files.forEach((file, index) => {
      form.append(file.name, file);
    });
    // post the form data
    this.laravelApiRequest('post', endpoint, form, {}, (response) => {
      // run the callback if it is present
      if (typeof callback === 'function') {
        callback(response);
      }
    });
  }

  // @depricated
  laravelApiUploadMedia(endpoint: string, files: any[], callback?: any): void {
    // create the form object
    const form = new FormData();
    // append each file
    files.forEach((file) => {
      form.append('media[]', file, file.name);
    });
    // post the form data
    this.laravelApiRequest('post', endpoint, form, {}, (response) => {
      // run the callback if it is present
      if (typeof callback === 'function') {
        callback(response);
      }
    });
  }

  /**
   *
   * @param endpoint The endpoint of an API that retruns JSON data.
   */
  getServiceURL(endpoint: string): string {
    let url = '';
    // check if the endpoint starts with http/https or not
    if (!/^https?:\/\//i.test(endpoint)) {
      // get the service url and append the endpoint
      url = CONST.SERVICE_URL + endpoint;
    } else {
      // the endpoint contains http/s so use it as it is
      url = endpoint;
    }
    return url;
  }

  /**
   *
   * @param endpoint The endpoint of an API that retruns JSON data.
   */
  getLaravelApiURL(endpoint: string): string {
    let url = '';
    // check if the endpoint starts with http/https or not
    if (!/^https?:\/\//i.test(endpoint)) {
      // get the service url and append the endpoint
      url = CONST.LARAVEL_API_URL + endpoint;
    } else {
      // the endpoint contains http/s so use it as it is
      url = endpoint;
    }
    return url;
  }

  /**
   *
   * @param endpoint the endpoint the file should be uploaded to
   * @param files an array of files
   * @param callback the callback
   *
   * a simple function to upload an array of files.
   *
   * @depricated
   */
  uploadFiles(endpoint: string, files: any[], callback?: any): void {
    // create the form object
    const form = new FormData();
    // append each file
    files.forEach((file, index) => {
      form.append('file[]', file);
    });
    // post the form data
    this.request('post', endpoint, form, {}, (response) => {
      // run the callback if it is present
      if (typeof callback === 'function') {
        callback(response);
      }
    });
  }









}
