import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { FolderModel } from 'src/app/shared/folder.model';
import { SiteModel } from 'src/app/models/site.model';
import { FoldersViewComponent } from 'src/app/folders/folders-view/folders-view.component';
import { FolderService } from 'src/app/shared/folder.service';
import {AccountFoldersTabsComponent} from "../../account/account-folders-tabs/account-folders-tabs.component";
import {MatDialog} from "@angular/material/dialog";
import {SitesEditFoldersFilterComponent} from "../sites-edit-folders-filter/sites-edit-folders-filter.component";

@Component({
  selector: 'app-sites-edit-folders',
  templateUrl: './sites-edit-folders.component.html',
  styleUrls: ['./sites-edit-folders.component.scss']
})
export class SitesEditFoldersComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new SitesLibraryDataSource(this.app, this.api);

  displayedColumns = [
    'select',
    'id',
    'name',
    'media',
    'links',
    'sites',
    'created_at',
    // 'created_at_UTC',
    'buttons'
  ];

  query: string;
  isEdit = window.location.toString().match(/edit/);

  parent_id: number;
  child_id: number;
  site_id: number;

  constructor(
    private folderService: FolderService,
    public utils: UtilsService,
    private app: AppService,
    private api: ApiService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      this.dataSource.siteId = this.site_id;
      this.dataSource.site_ids.push(this.site_id);

      this.dataSource.getData(false);
    }

  }

  async onDelete(folder: FolderModel) {
    this.utils.showModal(
      'Remove Folder',
      `Are you sure you want to remove the "${folder.name}" folder?`,
      () => {
        this.folderService.delete([folder.id]).subscribe(() => {
          this.utils.showToast(`The folder was deleted.`);
          this.dataSource.getData(true);
        });
      }
    );
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC dates by its corresponding date column.
      if ( this.sort.active == 'created_at_UTC' ) {
        this.dataSource.sort_by = 'created_at';
      }

      this.dataSource.getData(false);
    });

    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onView(folder: FolderModel) {
    this.dialog
      .open(FoldersViewComponent, {
        width: '750px',
        data: {
          folder: folder,
          is_training_doc: false
        }
      })
      .afterClosed()
      .subscribe((result) => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData(true);
      });
  }

  onAdd() {
    this.onEdit({} as FolderModel);
  }

  onEdit(folder: FolderModel) {
    if (!folder.id) {
      folder.sites = [{ id: this.dataSource.siteId } as SiteModel];
      folder.site_specific = true;
    }
    this.dialog
      .open(AccountFoldersTabsComponent, {
        width: '1024px',
        data: {
          folder: folder,
          is_training_doc: false,
          show_document_types_list_for: 'account',
          show_document_visibility: false
        }
      })
      .afterClosed()
      .subscribe((result) => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onRemoveSelected() {
    this.utils.showModal(
      'Remove Folders',
      `Are you sure you want to remove ${this.dataSource.selection.selected.length} Folders?`,
      () => {
        this.remove(this.dataSource.selection.selected);
      }
    );
  }

  private remove(ids: number[]) {
    this.folderService.delete(ids).subscribe(() => {
      this.utils.showToast('Removed Folder(s)');
      this.dataSource.selection.clear();
      this.dataSource.getData(true);
    });
  }

  onFilter() {
    this.utils.showComponentDialog(
      SitesEditFoldersFilterComponent,
      {
        selected_site_ids: this.dataSource.site_ids,
        parent_site_id: this.parent_id
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {

          this.dataSource.site_ids = results.site_ids;

          if ( this.dataSource.site_ids.length === 0 ) {
            this.dataSource.site_ids.push(this.site_id);
          }

          this.dataSource.getData();
        }
      }
    );
  }

  /**
   * Format the text display for associated sites.
   * Examples:
   *  - Head Office
   *  - 0 Sites
   *  - 1 Site (If name is not accessible otherwise the name of the site)
   *  - 2 Sites
   *  - Etc.
   * @param form
   */
  formatSitesListColumnText(document: FolderModel) {
    // Check if we have one user.
    if ( document.sites_count == 1 ) {
      // Check if we have details of one user.
      if ( document.sites[0] ) {
        return document.sites[0].name;
      }
      // Respond with a generic 1 User response.
      return document.sites_count + ' ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site');
    }
    // Respond with the users count.
    return document.sites_count + ' ' + this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites');
  }

  /**
   * The tooltip text is only used if there is more than 1 site.
   * @param form
   */
  formatSitesListColumnTooltip(document: FolderModel) {
    if ( document.sites_count > 1 ) {
      const site_names: string[] = document.sites.map((site: SiteModel) => {
        return site.name;
      });
      return site_names.join(`\n`);
    }
    return '';
  }
}

class SitesLibraryDataSource extends CustomDataSource {

  sort_by = 'name';
  sort_order = 'asc';

  siteId: number;

  site_ids: number[] = [];

  getData(resetOffset = false) {
    this.getDataFromLaravelAPI(
      `folders`,
      resetOffset,
      {},
      {
        site_id: this.siteId,
        eager_load: true,
        site_ids: this.site_ids.join(',')
      }
    );
  }
}
