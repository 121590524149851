import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-accounts-filter',
  templateUrl: './accounts-filter.component.html',
  styleUrls: ['./accounts-filter.component.scss']
})
export class AccountsFilterComponent implements OnInit {

  // Define the filter options.
  filter_options: {
    subscription: string,
    include_merged_accounts: boolean
  } = {
    subscription: '',
    include_merged_accounts: false
  };

  constructor(
    private app: AppService,
    public location: Location,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AccountsFilterComponent>
  ) {
    // Set default subscription of an empty string if none is provided.
    if (typeof this.data['subscription'] == 'undefined') {
      this.data['subscription'] = '';
    }

    // Set a default value of false for including merged accounts.
    if (typeof this.data['include_merged_accounts'] == 'undefined') {
      this.data['include_merged_accounts'] = false;
    }
  }

  ngOnInit() {
    // The constructor makes sure the values are set and present.
    this.filter_options.subscription = this.data['subscription'];
    this.filter_options.include_merged_accounts = this.data['include_merged_accounts'];
  }

  /**
   * Clear the filter options and send the response back.
   */
  onClearAndClose() {
    // Clear all filter options.
    this.filter_options.subscription = '';
    this.filter_options.include_merged_accounts = false;

    this.onSaveAndClose();
  }

  /**
   * Close the dialog and send the selected options as the response.
   */
  onSaveAndClose() {
    this.dialogRef.close(this.filter_options);
  }
}
