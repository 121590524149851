<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let element">{{ element.title }}</td>
  </ng-container>

  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef>Total</th>
    <td mat-cell *matCellDef="let element">{{ element.total }}</td>
  </ng-container>

  <ng-container matColumnDef="status_submitted">
    <th mat-header-cell *matHeaderCellDef>Submitted</th>
    <td mat-cell *matCellDef="let element">{{ element.status_submitted }}</td>
  </ng-container>

  <ng-container matColumnDef="status_assigned">
    <th mat-header-cell *matHeaderCellDef>Assigned</th>
    <td mat-cell *matCellDef="let element">{{ element.status_assigned }}</td>
  </ng-container>

  <ng-container matColumnDef="status_unassigned">
    <th mat-header-cell *matHeaderCellDef>Unassigned</th>
    <td mat-cell *matCellDef="let element">{{ element.status_unassigned }}</td>
  </ng-container>

  <ng-container matColumnDef="status_in_progress">
    <th mat-header-cell *matHeaderCellDef>In Progress</th>
    <td mat-cell *matCellDef="let element">{{ element.status_in_progress }}</td>
  </ng-container>

  <ng-container matColumnDef="status_approved">
    <th mat-header-cell *matHeaderCellDef>Approved</th>
    <td mat-cell *matCellDef="let element">{{ element.status_approved }}</td>
  </ng-container>

  <ng-container matColumnDef="status_work_completed">
    <th mat-header-cell *matHeaderCellDef>Work Completed</th>
    <td mat-cell *matCellDef="let element">{{ element.status_work_completed }}</td>
  </ng-container>

  <ng-container matColumnDef="status_rejected">
    <th mat-header-cell *matHeaderCellDef>Rejected</th>
    <td mat-cell *matCellDef="let element">{{ element.status_rejected }}</td>
  </ng-container>

  <ng-container matColumnDef="status_approved_work_in_progress">
    <th mat-header-cell *matHeaderCellDef>Approved: Work in Progress</th>
    <td mat-cell *matCellDef="let element">{{ element.status_approved_work_in_progress }}</td>
  </ng-container>

  <ng-container matColumnDef="status_pending">
    <th mat-header-cell *matHeaderCellDef>Pending</th>
    <td mat-cell *matCellDef="let element">{{ element.status_pending }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<sub *ngIf="!filters.date_range || filters.date_range.length == 0 else date_range">A maximum of 3 years of data is displayed.</sub>
<ng-template #date_range>
  <sub>{{ chartsUtils.getFormattedDateRange(filters.date_range) }}</sub>
</ng-template>
<mat-paginator #paginator [length]="total" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
