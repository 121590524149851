import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {WorkPermitsSelectorComponent} from "../../../shared/work-permits-selector/work-permits-selector.component";
import {PpeSelectorComponent} from "../../../shared/ppe-selector/ppe-selector.component";

@Component({
  selector: 'app-task-analyses-revised-filter',
  templateUrl: './task-analyses-revised-filter.component.html',
  styleUrls: ['./task-analyses-revised-filter.component.scss']
})
export class TaskAnalysesRevisedFilterComponent implements OnInit {

  dataSource = new TaskAnalysesRevisedFilterDataSource(); // No API calls needed

  constructor(
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TaskAnalysesRevisedFilterComponent>
  ) {
    // set a default ppe selection of nothing if no selected values are passed through
    if (typeof this.data['selected_ppe_ids'] == 'undefined') {
      this.data['selected_ppe_ids'] = [];
    }

    // set a default work permits selection of nothing if no selected values are passed through
    if (typeof this.data['selected_work_permit_ids'] == 'undefined') {
      this.data['selected_work_permit_ids'] = [];
    }

    // set a default archived value of false if no values are passed through
    if (typeof this.data['filter_archived'] === 'undefined') {
      this.data['filter_archived'] = "false";
    }
  }

  ngOnInit() {
    this.dataSource.selected_ppe_ids = this.data['selected_ppe_ids'];
    this.dataSource.selected_work_permit_ids = this.data['selected_work_permit_ids'];
    this.dataSource.filter_archived = this.data['filter_archived'];
  }

  onSelectPPE() {
    this.utils.showComponentDialog(
      PpeSelectorComponent,
      {
        selected: this.dataSource.selected_ppe_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results != 'undefined') {
          this.dataSource.selected_ppe_ids = results;
        }
      }
    );
  }

  onSelectWorkPermits() {
    this.utils.showComponentDialog(
      WorkPermitsSelectorComponent,
      {
        selected: this.dataSource.selected_work_permit_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results != 'undefined') {
          this.dataSource.selected_work_permit_ids = results;
        }
      }
    );
  }

  onClearAndClose() {
    this.dataSource.selected_ppe_ids = [];
    this.dataSource.selected_work_permit_ids = [];
    this.dataSource.filter_archived = "false";

    this.onSaveAndClose();
  }

  onSaveAndClose() {
    let response: any;

    response = {
      ppe_ids: this.dataSource.selected_ppe_ids,
      work_permit_ids: this.dataSource.selected_work_permit_ids,
      archived: this.dataSource.filter_archived
    }

    this.dialogRef.close(response);
  }

}

/**
 * the data source class should contain everything needed to get data for the feature.
 */
export class TaskAnalysesRevisedFilterDataSource {
  selected_ppe_ids: number[] = [];
  selected_work_permit_ids: number[] = [];
  filter_archived: string = 'false';

  constructor() {
    this.selected_ppe_ids = [];
    this.selected_work_permit_ids = [];
    this.filter_archived = 'false';
  }
}
