<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">Details</span>
  </nav>
</div>

<div class="container-fluid p-3">

  <form #f="ngForm" (ngSubmit)="onSubmit(f)">

    <div class="row">
      <div class="col-12">

        <mat-form-field class="full-width mb-3">
          <mat-label>Company Name</mat-label>
          <input
            matInput
            placeholder="Company Name"
            [(ngModel)]="contractorService.contractor.name"
            name="name"
            type="text"
            id="name"
            required
            dusk="dusk_name"/>
          <mat-hint>Enter the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} name.</mat-hint>
        </mat-form-field>

        <mat-form-field class="full-width mb-3">
          <mat-label>Industry</mat-label>
          <mat-select
            matInput
            placeholder="Industry"
            [(ngModel)]="contractorService.contractor.industry"
            name="industry"
            id="industry"
            dusk="dusk_industry">
            <mat-option *ngFor="let industry of industries" [value]="industry.name">{{ industry.name }}</mat-option>
          </mat-select>
          <mat-hint>Select the industry of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.</mat-hint>
        </mat-form-field>

      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="full-width mb-3">
          <mat-label>Address</mat-label>
          <input
            matInput
            placeholder="Address"
            [(ngModel)]="contractorService.contractor.address"
            name="address"
            type="text"
            id="address"
            dusk="dusk_address"/>
          <mat-hint>Enter the address of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.</mat-hint>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field class="full-width mb-3">
          <mat-label>{{ utils.getLangTerm('accounts.form.suburb', 'Suburb') }}</mat-label>
          <input
            matInput
            [placeholder]="utils.getLangTerm('accounts.form.suburb', 'Suburb')"
            [(ngModel)]="contractorService.contractor.suburb"
            name="suburb"
            type="text"
            id="suburb"
            dusk="dusk_suburb"/>
          <mat-hint>Enter the {{ utils.getLangTerm('accounts.form.suburb', 'Suburb') }} of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.</mat-hint>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field class="full-width mb-3">
          <mat-label>{{ utils.getLangTerm('accounts.form.city', 'City') }}</mat-label>
          <input
            matInput
            [placeholder]="utils.getLangTerm('accounts.form.city', 'City')"
            [(ngModel)]="contractorService.contractor.city"
            name="city"
            type="text"
            id="city"
            dusk="dusk_city"/>
          <mat-hint>Enter the {{ utils.getLangTerm('accounts.form.city', 'City') }} of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.</mat-hint>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field class="full-width mb-3">
          <mat-label>{{ utils.getLangTerm('accounts.form.postal_code', 'Postal Code') }}</mat-label>
          <input
            matInput
            [placeholder]="utils.getLangTerm('accounts.form.postal_code', 'Postal Code')"
            [(ngModel)]="contractorService.contractor.postal_code"
            name="postal_code"
            type="text"
            id="postal_code"
            dusk="dusk_postal_code"/>
          <mat-hint>Enter the {{ utils.getLangTerm('accounts.form.postal_code', 'Postal Code') }} of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.</mat-hint>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field class="full-width mb-3">
          <mat-label>Country</mat-label>
          <input
            matInput
            placeholder="Country"
            [(ngModel)]="contractorService.contractor.country"
            name="country"
            type="text"
            id="country"
            dusk="dusk_country"/>
          <mat-hint>Enter the country of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button
          mat-raised-button
          [disabled]="!f.valid"
          (click)="onSubmit(f)"
          type="button"
          color="primary"
          id="update_button"
          dusk="dusk_update_button">
          <mat-icon>save</mat-icon>
          Update
        </button>
      </div>
    </div>

  </form>

</div>
