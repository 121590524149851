import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../models/api-response.model';
import { FolderModel } from './folder.model';
import { merge } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FolderService {
  private readonly resourceUrl = 'folders';

  constructor(private api: ApiService) {}

  public create(folder: FolderModel): Observable<ApiResponseModel<number>> {
    return this.api.laravelApiObservable('post', this.resourceUrl, folder);
  }

  public update(folder: FolderModel): Observable<{}> {
    return this.api.laravelApiObservable(
      'put',
      `${this.resourceUrl}/${folder.id}`,
      folder
    );
  }

  public delete(ids: number[]): Observable<{}> {
    return this.api.laravelApiObservable(
      'delete',
      `${this.resourceUrl}/${ids.join(',')}`
    );
  }

  public deleteMany(ids: number[]): Observable<{}> {
    const deletes = ids.map(id => this.api.laravelApiObservable(
      'delete', this.resourceUrl + `/${id}`
    ));
    return merge(...deletes);
  }

  find(id: number): Observable<ApiResponseModel<FolderModel>> {
    return this.api.laravelApiObservable('get', this.resourceUrl + `/${id}`);
  }
}
