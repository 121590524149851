<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" [routerLink]="['inductions']">Inductions</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="primary" id="add" [routerLink]="['/inductions', 'new', 'edit']" appMarginRight>
          Add Induction
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.deselectAll()">
            Deselect All
          </button>
          <button mat-menu-item (click)="onDeleteSelected()">
            Delete Selected
          </button>
        </mat-menu>

        <button mat-raised-button color="secondary" [matMenuTriggerFor]="appMenu"
          *ngIf="dataSource.selection.hasValue()" matTooltip="Edit the selected Incidents">
          List Actions
        </button>
      </span>


      <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
        <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search"
          placeholder="Search" aria-label="Search" id="search-input"
          matTooltip="Search for inductions by title or context" />
      </form>

      <button mat-raised-button color="secondary" (click)="dataSource.getData(true)" appMarginRight id="search-button"
        matTooltip="Run the current search">
        <mat-icon>search</mat-icon> Search
      </button>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="date_incident" matSortDirection="desc"
      matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null" [checked]="
              dataSource.selection.hasValue() && dataSource.isAllSelected()
            " [indeterminate]="
              dataSource.selection.hasValue() && !dataSource.isAllSelected()
            " matTooltip="Toggle selecting all Incidents">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)" matTooltip="Toggle selecting this Incident">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef  matTooltip="Toggle sorting by ID">
          #
        </th>
        <td mat-cell *matCellDef="let induction">
          {{ induction.id }}
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell mat-sort-header *matHeaderCellDef   matTooltip="Toggle sorting by title">
          Title
        </th>
        <td mat-cell *matCellDef="let induction">
          {{ induction.title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="published">
        <th mat-header-cell mat-sort-header *matHeaderCellDef  matTooltip="Toggle sorting by published status">
          Published
        </th>
        <td mat-cell *matCellDef="let induction">
          {{ induction.published ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <a matTooltip="Download a report of this Incident as PDF">View</a>
          |
          <a [routerLink]="['/inductions', element.id, 'edit']" matTooltip="Edit the induction.">Edit</a>
          |
          <a matTooltip="Download a report of this Incident as PDF">Modules</a>
          |
          <a [routerLink]="['/inductions', element.id, 'forms']" matTooltip="Download a report of this Incident as PDF">Forms</a>
          |
          <a matTooltip="Download a report of this Incident as PDF">Delete</a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
