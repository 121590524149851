  <form #f="ngForm" class="p-3">
    <div class="container-fluid">

      <div class="row">
        <div class="col">

          <h5>After-hours Notifications</h5>

          <mat-checkbox [(ngModel)]="site.enable_after_hours_notification" name="enable_after_hours_notification">
            Enable after-hours notifications for this {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }}.
          </mat-checkbox>

          <p>
            <small>
              <b>When this option is enabled</b>, a few notifications will be sent after the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} closing time elapsed. <br>
              30 minutes: Onsite users are prompted to sign out. <br>
              60 minutes: Onsite users are prompted again to sign out. <br>
              75 minutes: Managers or Administrators are notified of all onsite users.
            </small>
          </p>

        </div>
      </div>

      <div class="row">
        <div class="col">

          <h5>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Access Options</h5>

          <mat-form-field class="full-width" style="max-width: 600px;">
            <mat-select placeholder="Site Access Options" [(ngModel)]="site.site_access_option" name="site_access_option" required id="site_access_option">
              <mat-option value="signin:only">Sign-in with acknowledgement.</mat-option>
              <mat-option value="signin:induction">Sign-in with acknowledgement and Hazards & Risks.</mat-option>
              <mat-option value="signin:all">Sign-in with acknowledgement and all information.</mat-option>
            </mat-select>
          </mat-form-field>

          <p>
            <small>
              The selected {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} access option will apply to all users (scanning QR codes) that are not in your connected network.
            </small>
          </p>

        </div>
      </div>

      <div class="row">
        <div class="col">

          <h5>Risk Assessment</h5>

          <mat-checkbox [(ngModel)]="site.show_site_risk_assessment" name="show_site_risk_assessment">
            Show the risk level in the {{ utils.getAppName() }} mobile app.
          </mat-checkbox>

          <p>
            <small>
              <b>When this option is enabled</b>, the color coded risk level will be shown to the users on the {{utils.getAppName()}} mobile app.
            </small>
          </p>

        </div>
      </div>

      <div class="row">
        <div class="col">

          <button
            mat-raised-button
            [disabled]="!f.valid || inProgress"
            (click)="onSave(f)"
            type="button"
            color="primary"
            class="save-button mr-3">
            <mat-icon>save</mat-icon> Save
          </button>

        </div>
      </div>
    </div>
  </form>

  <ng-template #showLoader>
    <div class="d-flex justify-content-center align-items-center full-height">
      <table>
        <tr>
          <td class="d-flex justify-content-center">
            <mat-progress-spinner [mode]="'indeterminate'" diameter="32"></mat-progress-spinner>
          </td>
        </tr>
        <tr>
          <td class="d-flex justify-content-center">
            <p>Please wait while we get the settings...</p>
          </td>
        </tr>
      </table>
    </div>
  </ng-template>
