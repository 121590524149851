<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/task-analyses">TA's</a></li>
    <li class="breadcrumb-item">
      <a routerLink="/task-analyses/{{ parent_id }}/workers">TA Workers</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Edit</li>
  </ol>
</nav>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
        <div class="card">
          <div class="card-body form-container">
            <h5 class="card-title">Worker Form</h5>

            <mat-form-field>
              <input
                matInput
                placeholder="Fullname"
                [(ngModel)]="service.item.fullname"
                name="fullname"
                type="text"
                #fullname
                required
              />
              <mat-error *ngIf="!fullname.valid"
                >Please enter the name of the worker.</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                placeholder="Mobile"
                [(ngModel)]="service.item.mobile"
                name="mobile"
                type="tel"
                #mobile
                required
              />
              <mat-error *ngIf="!mobile.valid"
                >Please enter the worker's mobile.</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                placeholder="Email Address"
                [(ngModel)]="service.item.email"
                name="email"
                type="email"
                #email
                required
              />
              <mat-error *ngIf="!email.valid"
                >Please enter the worker's email address.</mat-error
              >
            </mat-form-field>
          </div>
        </div>

        <div appMarginTop class="d-flex justify-content-end">
          <button
            type="submit"
            mat-raised-button
            [disabled]="!f.valid"
            color="primary"
          >
            Save
          </button>
        </div>
      </form>
    </div>

    <div class="col-12 col-md-6">
      <h4>Instructions</h4>
      <p>
        Complete the form on the left to add a new or update an existing task
        analysis worker.
      </p>
      <p>Workers are all people who are assigned to do the work.</p>
      <p>Complete all required fields.</p>
    </div>
  </div>
</div>
