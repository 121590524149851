import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SitesContractorsComponent } from './sites-contractors/sites-contractors.component';
import { SitesDetailsViewComponent } from './sites-details-view/sites-details-view.component';
import { SitesErpsComponent } from './sites-erps/sites-erps.component';
import { SitesHazardousSubstancesComponent } from './sites-hazardous-substances/sites-hazardous-substances.component';
import { SitesHazardsComponent } from './sites-hazards/sites-hazards.component';
import { SitesInductionEditComponent } from './sites-induction-edit/sites-induction-edit.component';
import { SitesInductionViewComponent } from './sites-induction-view/sites-induction-view.component';
import { SitesManagersComponent } from './sites-managers/sites-managers.component';
import { SitesTabsComponent } from './sites-tabs/sites-tabs.component';
import { SitesToolboxTalksComponent } from './sites-toolbox-talks/sites-toolbox-talks.component';
import { SitesComponent } from './sites/sites.component';
import { SitesEditFoldersComponent } from './sites-edit-folders/sites-edit-folders.component';
import { SitesCodesComponent } from './sites-codes/sites-codes.component';
import { SitesEmergencyEvacuationPlanEditComponent } from './sites-emergency-evacuation-plan-edit/sites-emergency-evacuation-plan-edit.component';
import { SitesEmergencyEvacuationPlanViewComponent } from './sites-emergency-evacuation-plan-view/sites-emergency-evacuation-plan-view.component';
import { SitesTaskAnalysesComponent } from './sites-task-analyses/sites-task-analyses.component';
import { AuthGuard } from '../guards/auth.guard';
import { SitesMainSsspComponent } from './sites-main-sssp/sites-main-sssp.component';
import { SitesSafetyObservationsComponent } from './sites-safety-observations/sites-safety-observations.component';
import {SitesTaskAnalysesRevisedComponent} from "./sites-task-analyses-revised/sites-task-analyses-revised.component";
import {SitesTaskAnalysesRevisedTabsComponent} from "./sites-task-analyses-revised-tabs/sites-task-analyses-revised-tabs.component";
import { SitesDynamicFormsComponent } from './sites-dynamic-forms/sites-dynamic-forms.component';
import { SitesAuditsEditComponent } from './sites-audits-edit/sites-audits-edit.component';
import { SitesAuditsComponent } from './sites-audits/sites-audits.component';
import { SitesAuditsViewComponent } from './sites-audits-view/sites-audits-view.component';
import { SitesIncidentsComponent } from './sites-incidents/sites-incidents.component';
import {SitesToolboxTalksTabsComponent} from "./sites-toolbox-talks-tabs/sites-toolbox-talks-tabs.component";
import {SitesImporterComponent} from "./sites-importer/sites-importer.component";
import {SitesChildrenComponent} from "./sites-children/sites-children.component";
import {SitesCodesEditComponent} from "./sites-codes/codes-edit/sites-codes-edit.component";
import {SitesCodesViewComponent} from "./sites-codes/codes-view/sites-codes-view.component";
import {SitesUsersWorkedOnComponent} from "./sites-users-worked-on/sites-users-worked-on.component";
import {SitesDetailsTabsComponent} from "./sites-details-tabs/sites-details-tabs.component";
import {
  SitesNotificationsTabsComponent
} from './sites-notificaions/sites-notifications-tabs/sites-notifications-tabs.component';

const editRoutes = [
  { path: 'details', component: SitesDetailsTabsComponent },
  { path: 'induction', component: SitesInductionEditComponent },
  { path: 'contractors', component: SitesContractorsComponent },
  { path: 'managers', component: SitesManagersComponent },
  { path: 'hazards', component: SitesHazardsComponent },
  { path: 'hazardous-substances', component: SitesHazardousSubstancesComponent },
  { path: 'erps', component: SitesErpsComponent },
  { path: 'toolbox-talks', component: SitesToolboxTalksComponent },
  { path: 'toolbox-talks/:toolbox_talk_id/view', component: SitesToolboxTalksTabsComponent },
  { path: 'folders', component: SitesEditFoldersComponent },
  { path: 'codes', component: SitesCodesComponent },
  { path: 'codes/:code_id/edit', component: SitesCodesEditComponent },
  { path: 'codes/:code_id/view', component: SitesCodesViewComponent},
  { path: 'emergency-evacuation-plans', component: SitesEmergencyEvacuationPlanEditComponent },
  { path: 'task-analyses', component: SitesTaskAnalysesComponent },
  { path: 'task-analyses-revised', component: SitesTaskAnalysesRevisedComponent },
  { path: 'task-analyses-revised/:ta_revised_id/edit', component: SitesTaskAnalysesRevisedTabsComponent },
  { path: 'task-analyses-revised/:ta_revised_id/view', component: SitesTaskAnalysesRevisedTabsComponent },
  { path: 'sssp-config', component: SitesMainSsspComponent },
  { path: 'safety-observations', component: SitesSafetyObservationsComponent },
  { path: 'incidents', component: SitesIncidentsComponent },
  { path: 'dynamic-forms', component: SitesDynamicFormsComponent },
  { path: 'audits', component: SitesAuditsComponent },
  { path: 'audits/:audit_id/edit', component: SitesAuditsEditComponent },
  { path: 'audits/:audit_id/view', component: SitesAuditsViewComponent },
  { path: 'users-worked-on', component: SitesUsersWorkedOnComponent },
  { path: 'notifications', component: SitesNotificationsTabsComponent }
];

const viewRoutes = [
  { path: 'details', component: SitesDetailsViewComponent },
  { path: 'induction', component: SitesInductionViewComponent },
  { path: 'contractors', component: SitesContractorsComponent },
  { path: 'managers', component: SitesManagersComponent },
  { path: 'hazards', component: SitesHazardsComponent },
  { path: 'hazardous-substances',component: SitesHazardousSubstancesComponent },
  { path: 'erps', component: SitesErpsComponent },
  { path: 'toolbox-talks', component: SitesToolboxTalksComponent },
  { path: 'toolbox-talks/:toolbox_talk_id/view', component: SitesToolboxTalksTabsComponent },
  { path: 'folders', component: SitesEditFoldersComponent },
  { path: 'codes', component: SitesCodesComponent },
  { path: 'codes/:code_id/edit', component: SitesCodesEditComponent },
  { path: 'codes/:code_id/view', component: SitesCodesViewComponent},
  { path: 'emergency-evacuation-plans',component: SitesEmergencyEvacuationPlanViewComponent },
  { path: 'task-analyses', component: SitesTaskAnalysesComponent },
  { path: 'task-analyses-revised', component: SitesTaskAnalysesRevisedComponent },
  { path: 'task-analyses-revised/:ta_revised_id/edit', component: SitesTaskAnalysesRevisedTabsComponent },
  { path: 'task-analyses-revised/:ta_revised_id/view', component: SitesTaskAnalysesRevisedTabsComponent },
  { path: 'sssp-config', component: SitesMainSsspComponent },
  { path: 'safety-observations', component: SitesSafetyObservationsComponent },
  { path: 'incidents', component: SitesIncidentsComponent },
  { path: 'dynamic-forms', component: SitesDynamicFormsComponent },
  { path: 'audits', component: SitesAuditsComponent },
  { path: 'audits/:audit_id/edit', component: SitesAuditsEditComponent },
  { path: 'audits/:audit_id/view', component: SitesAuditsViewComponent },
  { path: 'users-worked-on', component: SitesUsersWorkedOnComponent },
  { path: 'notifications', component: SitesNotificationsTabsComponent }
];

const routes: Routes = [
  {
    path: 'sites',
    component: SitesComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'sites/importer',
    component: SitesImporterComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'sites/:parent_id',
    canActivate: [
      AuthGuard
    ],
    children: [
      {
        path: 'children',
        component: SitesChildrenComponent
      },
      {
        path: 'children/:child_id',
        children: [
          {
            path: 'edit',
            component: SitesTabsComponent,
            children: editRoutes
          },
          {
            path: 'view',
            component: SitesTabsComponent,
            children: viewRoutes
          }
        ]
      },
      {
        path: 'edit',
        component: SitesTabsComponent,
        children: editRoutes
      },
      {
        path: 'view',
        component: SitesTabsComponent,
        children: viewRoutes
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SitesRoutingModule {}
