import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CustomDataSource} from "../../utils/custom-data-source";
import {AppService} from "../../app.service";
import {ApiService} from "../api.service";
import {UtilsService} from "../utils.service";
import {tap} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {
  UserPublicProfileTrainingDocFilesComponent
} from "../user-public-profile-training-doc-files/user-public-profile-training-doc-files.component";
import {FileModel} from "../file.model";
import {FolderLinkModel} from "../folder-link.model";
import {
  UserPublicProfileTrainingDocLinksComponent
} from "../user-public-profile-training-doc-links/user-public-profile-training-doc-links.component";

@Component({
  selector: 'app-user-public-profile-training-docs',
  templateUrl: './user-public-profile-training-docs.component.html',
  styleUrls: ['./user-public-profile-training-docs.component.scss']
})
export class UserPublicProfileTrainingDocsComponent implements OnInit {

  @Input() hash: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new UserPublicProfileTrainingDocsDataSource(this.app, this.api);

  // columns to show and the data source
  displayedColumns: string[] = [
    // 'id',
    'name',
    'folder_type',
    'visibility',
    'expiry',
    'actions'
  ];

  constructor(
    private app: AppService,
    private api: ApiService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.dataSource.hash = this.hash;
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns by their corresponding date columns.
      if (this.sort.active == "date_created_UTC") {
        this.dataSource.sort_by = "date_created";
      }

      this.dataSource.getData(false); // TBD: Need to add sorting to the API endpoint.
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onOpenUrl(url: string) {
    this.utils.openUrl(url);
  }

  onViewFiles(files: FileModel[], name: string) {
    this.utils.showComponentDialog(
      UserPublicProfileTrainingDocFilesComponent,
      {
        files: files,
        name: name
      },
      { width: '700px' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

  onViewLinks(links: FolderLinkModel[], name: string) {
    this.utils.showComponentDialog(
      UserPublicProfileTrainingDocLinksComponent,
      {
        links: links,
        name: name
      },
      { width: '700px' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class UserPublicProfileTrainingDocsDataSource extends CustomDataSource {
  hash: string = '';

  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset = false) {
    this.getDataFromLaravelAPI(
      `noauth/users/${this.hash}/folders`,
      resetOffset,
      () => {},
      { eager_load: true }
    );
  }
}
