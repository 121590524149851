import {Component, Input, OnInit} from '@angular/core';
import {UserModel} from "../../models/user.model";

@Component({
  selector: 'app-user-public-profile-details',
  templateUrl: './user-public-profile-details.component.html',
  styleUrls: ['./user-public-profile-details.component.scss']
})
export class UserPublicProfileDetailsComponent implements OnInit {

  @Input() user: UserModel;

  constructor() { }

  ngOnInit() {
  }

}
