import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from "../guards/auth.guard";
import {TaskAnalysesRevisedComponent} from "./task-analyses-revised.component";
import {TaskAnalysesRevisedTabsComponent} from "./task-analyses-revised-tabs/task-analyses-revised-tabs.component";

const routes: Routes = [
  {
    path: 'task-analyses-revised',
    component: TaskAnalysesRevisedComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'task-analyses-revised/:ta_revised_id',
    children: [
      { path: 'edit', component: TaskAnalysesRevisedTabsComponent },
      { path: 'view', component: TaskAnalysesRevisedTabsComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskAnalysesRevisedRoutingModule { }
