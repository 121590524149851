<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/task-analyses/{{ parent_id }}/steps"
      >TA Workers</a
    >
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item ">
          <a class="nav-link" routerLink="/task-analyses">Task Analyses</a>
        </li>
        <li class="nav-item ">
          <a
            class="nav-link"
            routerLink="/task-analyses/{{ parent_id }}/steps/new/edit"
            >New Step</a
          >
        </li>
      </ul>
      <form
        class="form-inline my-2 my-lg-0"
        (ngSubmit)="service.get(parent_id, 0, true)"
      >
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="service.search"
          name="search"
          placeholder="Search"
          aria-label="Search"
        />
        <button class="btn btn-outline-primary my-2 my-sm-0" type="submit">
          Search
        </button>
      </form>
    </div>
  </nav>

  <div class="table-responsive">
    <table class="table table-hover">
      <thead class="thead-light">
        <tr>
          <th scope="col">Step</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of service.list">
          <td>{{ item.step }}</td>
          <td width="200">
            <a
              routerLink="/task-analyses/{{ parent_id }}/steps/{{
                item.id
              }}/view"
              class="action-icon"
              matTooltip="View"
              matTooltipPosition="above"
              ><mat-icon>visibility</mat-icon></a
            >
            <a
              routerLink="/task-analyses/{{ parent_id }}/steps/{{
                item.id
              }}/edit"
              class="action-icon"
              matTooltip="Edit"
              matTooltipPosition="above"
              ><mat-icon>edit</mat-icon></a
            >
            <a
              href="javascript:void(0)"
              (click)="onRemove(item.id)"
              class="action-icon"
              matTooltip="Remove"
              matTooltipPosition="above"
              ><mat-icon>delete</mat-icon></a
            >
          </td>
        </tr>
      </tbody>

      <tfoot class="thead-light">
        <tr>
          <td class="text-center" colspan="2">
            <a
              href="javascript:void(0)"
              (click)="service.get(parent_id, 0, false)"
              >Click to load more</a
            >
          </td>
        </tr>
        <tr>
          <th scope="col">Step</th>
          <th scope="col">Actions</th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
