<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-8">
      <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
        <p>Follow the steps below to import your subscriber list.</p>

        <p>(1) Select the list you would like to import into.</p>
        <mat-select
          placeholder="Select List"
          [(ngModel)]="selectedListId"
          name="selectedListId"
          (selectionChange)="onListItemChanged()"
          required>
          <mat-option value="-">- SELECT A LIST -</mat-option>
          <mat-option
            *ngFor="let listItem of list; let i = index"
            [value]="listItem.id">
            {{ listItem.name }} ({{ listItem.stats.member_count }})</mat-option>
        </mat-select>

        <div appMarginTop class="d-flex justify-content-end">
          <button
            type="button"
            (click)="refreshTheList()"
            mat-raised-button
            color="secondary">
            Refresh List
          </button>
        </div>

        <ng-container *ngIf="selectedListId !== '-'">
          <hr />
          <p>(2) Select the CSV file you want to import.</p>
          <div appMarginTop class="d-flex justify-content-end">
            <span *ngIf="selectedFile">{{ selectedFile.name }}</span>
            <button
              appMarginLeft
              mat-raised-button
              type="button"
              (click)="onTriggerFileSelector($event)"
              color="secondary">
              Select &amp; Upload CSV File
            </button>
            <input
              type="file"
              (change)="onFileSelected($event)"
              style="display: none;"
              #fileInput/>
          </div>

          <ng-container *ngIf="selectedFile">
            <hr />
            <p>(3) Upload and import the CSV file.</p>
            <div appMarginTop class="d-flex justify-content-end">
              <button type="submit" mat-raised-button color="primary">
                Upload &amp; Import
              </button>
            </div>
          </ng-container>
        </ng-container>
      </form>
    </div>

    <div class="col-12 col-md-4">
      <p>
        Welcome to the MailChimp integration tool for {{ utils.getAppName() }}. By using this
        tool, you can import a list of subscribers from a CSV file.
      </p>
      <p>
        Use this
        <a href="{{ serviceUrl }}/uploads/resources/MailChimp-CSV-Template.csv" target="_blank">
          CSV Template
        </a>
        file and keep the order of the columns.
      </p>
    </div>
  </div>
</div>
