
<h6>Task</h6>
<p>{{ action.name }}</p>

<ng-container *ngIf="action?.audit_items && action.audit_items.length">
  <h6>Related to Inspections/Audits</h6>
  <ul>
    <li *ngFor="let audit_item of action.audit_items">
      {{ audit_item.name }}
    </li>
  </ul>
</ng-container>

<ng-container *ngIf="action.incident_cause">
  <h6>Related to the following incident cause</h6>
  <p>{{ action.incident_cause.name }}</p>
</ng-container>

<h6>Priority</h6>
<p>{{ action.priority }}</p>

<ng-container *ngIf="action.due">
  <h6>Due</h6>
  <p>{{ action.due }}</p>
</ng-container>

<ng-container  *ngIf="action.site">
  <h6>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Name</h6>
  <p>{{action.site.name}}</p>

  <h6>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Address</h6>
  <p>{{action.site.location}}</p>
</ng-container>

<ng-container *ngIf="action?.users && action.users.length">
  <h6>Assignees</h6>
  <ul>
    <li *ngFor="let user of action.users">
      <a (click)="onUserPublicView(user.hash)"
         matTooltip="View User Public Profile">
        {{ user.contact_person }}
      </a>
    </li>
  </ul>
</ng-container>

<ng-container *ngIf="action.creator">
  <h6>Created By</h6>
  <p>
    <a (click)="onUserPublicView(action.creator.hash)"
       matTooltip="View User Public Profile">
      {{ action.creator.contact_person }}
    </a>
  </p>
</ng-container>

<ng-container *ngIf="action.date_created">
  <h6>Created At</h6>
  <p>{{ (action.date_created * 1000) | timezoneConvertLong }}</p>
</ng-container>

<ng-container *ngIf="action.updater">
  <h6>Last Updated By</h6>
  <p>
    <a (click)="onUserPublicView(action.updater.hash)"
       matTooltip="View User Public Profile">
      {{ action.updater.contact_person }}
    </a>
  </p>
</ng-container>

<ng-container *ngIf="action.date_modified">
  <h6>Last Updated At</h6>
  <p>{{ (action.date_modified * 1000) | timezoneConvertLong }}</p>
</ng-container>
