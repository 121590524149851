import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ToolboxTalksEditComponent } from './toolbox-talks-edit/toolbox-talks-edit.component';
import { ToolboxTalkViewComponent } from './toolbox-talks-view/toolbox-talks-view.component';
import { ToolboxTalksComponent } from './toolbox-talks/toolbox-talks.component';
import { AuthGuard } from '../guards/auth.guard';
import {ToolboxTalksTabsComponent} from "./toolbox-talks-tabs/toolbox-talks-tabs.component";

const routes: Routes = [
  {
    path: 'toolbox-talks',
    canActivate: [
      AuthGuard
    ],
    children: [
      { path: '', component: ToolboxTalksComponent },
      { path: ':toolbox_talk_id/edit', component: ToolboxTalksEditComponent },
      { path: ':toolbox_talk_id/view', component: ToolboxTalksTabsComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ToolboxTalksRoutingModule {}
