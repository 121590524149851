import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { IncidentModel } from '../../models/incident.model';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';

@Component({
  selector: 'app-incidents-view',
  templateUrl: './incidents-view.component.html',
  styleUrls: ['./incidents-view.component.css']
})
export class IncidentsViewComponent implements OnInit {
  incident: IncidentModel = new IncidentModel();

  created_by_user: any;
  mediaDisplayedColumns = ['name', 'icon'];

  constructor(
    // public service: IncidentsService,
    public route: ActivatedRoute,
    public router: Router,
    public utils: UtilsService,
    public app: AppService,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.incident.id = Number(this.route.parent.snapshot.params['incident_id']);
    // this.api.requestObservable('get', 'my-account')
    //   .subscribe(res => this.handleAccount(res.data));

    if (!this.incident.id) {
      this.router.navigate(['incidents']);
      return;
    }

    this.api.laravelApiRequest(
      'get',
      'incidents/' + this.incident.id + '?eager_load=true',
      {},
      {},
      (response) => {
        this.incident.apply(response.data);

        // todo show who created the incident

        if (response.data.created_by_user) {
          this.created_by_user = response.data.created_by_user;
        }
      },
      (error) => {
        this.utils.showModal('Error', error.message);
        this.router.navigate(['incidents']);
      }
    );
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove Incident',
      'Are you sure you want to remove this incident?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'incidents/' + this.incident.id,
          {},
          {},
          (response) => {
            this.utils.showToast('The incident was removed');
            this.router.navigate(['incidents']);
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  getMatIcon(name: string) {
    const extension = name.split('.').pop();
    const video = 'video_label';
    const mappings = {
      jpg: 'image',
      png: 'image',
      gif: 'image',
      bmp: 'image',
      mp4: video,
      wmv: video,
      webm: video,
      avi: video,
      flv: video
    };
    let icon = mappings[extension];
    if (!icon) { icon = 'text_format'; }
    return icon;
  }

  downloadMedia(id: number, name: string) {
    this.api
      .laravelApiDownloadObservable(`media/export/${id}`)
      .subscribe((data) => {
        saveAs(data, name);
      });
  }
}
