import { AccountModel } from 'src/app/models/account.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from 'src/app/shared/utils.service';
import { AccountsSelectorComponent } from '../accounts-selector/accounts-selector.component';
import { ApiRequestService } from 'src/app/shared/api-request.service';

@Component({
  selector: 'app-account-copy-templates-dialog',
  templateUrl: './account-copy-templates-dialog.component.html',
  styleUrls: ['./account-copy-templates-dialog.component.scss']
})
export class AccountCopyTemplatesDialogComponent implements OnInit {

  sourceAccount: AccountModel;
  sourceAccountId: number;
  targetAccountId: number;

  inProgress: boolean = false;

  selectedTemplate: 'hazards' | 'hazardous_substances' | 'site_audits' | 'all' = 'hazards';

  constructor(
    public utils: UtilsService,
    private api: ApiRequestService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AccountCopyTemplatesDialogComponent>
    ) {}

  ngOnInit() {
    // Set the target account id.
    this.targetAccountId = this.data['targetAccountId'];
  }

  /**
   * Open the account selector to set the source account.
   */
  onSelectAccount() {
    this.utils.showComponentDialog(AccountsSelectorComponent, {
      multiple: false,
      returnObjects: true,
      selected: this.sourceAccount
    }, {
      disableClose: false
    }, (account: AccountModel) => {
      if (account) {
        this.sourceAccount = account;
        this.sourceAccountId = this.sourceAccount.id;
      }
    });
  }

  /**
   * Make the request to the API to copy the account templates.
   */
  onCopyTemplates() {
    // Check if we have a source account selected.
    if ( !this.sourceAccountId ) {
      this.utils.showModal('Error', 'Please select the account the templates should be copied from.');
      return;
    }
    // Show a confirmation dialog.
    this.utils.showModal('Copy Account Templates', `Are you sure you want to copy the templates from "${this.sourceAccount.name}"?`, () => {
      this.inProgress = true;
      // Make the API request to copy the account templates.
      this.api.makeRequest('post', `v2/admin/account/copy-templates`, {
        'targetAccountId':this.targetAccountId,
        'sourceAccountId':this.sourceAccountId,
        'feature':this.selectedTemplate
      }, {}, {})
        .then(response => {
          this.utils.showToast('The account templates will be copied in the background. Check the account in a few minutes to verify that the templates were copied.');
        }).catch(err => {
        this.utils.showToast('Failed to copy the account templates. Please try again.');
      }).finally(() => {
        this.inProgress = false;
        this.dialogRef.close();
      });
    });

  }

}
