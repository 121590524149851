import { Auditable } from "../shared/auditable.model";

export class ERPTeamMemberModel extends Auditable {
  id = 0;
  // emergency_response_plan_id = 0
  name = '';
  role = '';
  training_required = '';
  phone = '';
  email = '';

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.name = '';
    this.role = '';
    this.training_required = '';
    this.phone = '';
    this.email = '';
  }
}
