import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { AppService } from './app.service';
import { ApiService } from './shared/api.service';
import { UtilsService } from './shared/utils.service';

import {
  BreakpointObserver,
  Breakpoints,
  MediaMatcher
} from '@angular/cdk/layout';
import { setTheme } from 'ngx-bootstrap/utils';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { SessionService } from './shared/session.service';
import { RouterService } from './shared/router.service';
import { UserMessagesService } from './user/user-messages/user-messages.service';
import {GoogleAnalyticsServiceService} from "./services/google-analytics-service.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  mobileQuery: MediaQueryList;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  menu: any;
  private readonly _mobileQueryListener: () => void;

  minuteInterval: any;

  // Default the primary color to the SiteConnect color.
  primaryColor: string = '#F4901E';

  constructor(
    public utils: UtilsService,
    private api: ApiService,
    public app: AppService,
    public sessionService: SessionService,
    public routerService: RouterService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private userMessagesService: UserMessagesService,
    private googleAnalyticsService: GoogleAnalyticsServiceService // Load this for Google Analytics tracking even if it's not used here.
  ) {
    // ngx-bootstrap date picker theme
    setTheme('bs4');
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    router.events
      .pipe(
        withLatestFrom(this.isHandset$),
        filter(([a, b]) => b && a instanceof NavigationEnd)
      )
      .subscribe((_) => this.sidenav.close());
  }

  ngAfterViewInit(): void {
    // Get the document root.
    const root: HTMLElement = document.documentElement;
    // Extract the styles from the document root.
    const styles: CSSStyleDeclaration = getComputedStyle(root);
    // Update the primary color with one from the styles.
    this.primaryColor = styles.getPropertyValue('--primary-color').trim();
  }

  async ngOnInit() {
    if (
      !this.app.isAuthenticated &&
      !(['/','/sign-in','/sign-up','/about','/contact-us','/noauth','/reset-password','/verify'].some(path => location.pathname.includes(path)))
    ) {
      this.router.navigate(['sign-in'], {
        queryParams: {
          redirect: location.pathname,
          queryParams: location.search
        }
      });
    }

    // detect IE/Edge Legacy
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1 || userAgent.indexOf('Edge/') > -1) {
      this.utils.showModal(
        'Internet Explorer/Edge Legacy - Not Fully Supported',
        'Internet Explorer is outdated and not fully supported by this web application. ' +
        'Perhaps try the <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a> (recommended), <a href="https://www.mozilla.org/en-US/firefox/" target="_blank">Firefox</a> or <a href="https://www.microsoft.com/en-nz/windows/microsoft-edge" target="_blank">Microsoft Edge</a> web browsers.'
      );
    }

    // Run code every 1 minutes
    this.minuteInterval = setInterval(() => {
      this.minuteIntervalFunctions();
    }, 60000);

    // Preloads external scripts.
    this.preloadExternalScripts();
  }

  /**
   * Preloads external scripts required for the software. These scripts will load asynchronously.
   *
   * @return {void}
   */
  preloadExternalScripts(): void {
    // Load the Google charts library into the software.
    this.utils.loadExternalScript('google-charts', 'https://www.gstatic.com/charts/loader.js', () => {});
  }

  minuteIntervalFunctions() {
    if ( this.app.isAuthenticated ) {
      this.userMessagesService.getMessagesMeta();
    }
  }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);

    // Clear all intervals
    clearInterval(this.minuteInterval);

    // Unsubscribe from Google Analytics.
    this.googleAnalyticsService.destroy();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 600) {
      this.sidenav.close();
    } else {
      this.sidenav.open();
    }
  }
}
