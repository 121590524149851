<ng-container matColumnDef="tags_filter" *ngIf="allTagObjs && allTagObjs.length > 0">
  <div class="tag-filter-wrapper">
    <mat-form-field class="tag-chip-list" appearance="fill">
      <mat-label>Filter with Tags</mat-label>
      <mat-chip-list #tagChipList aria-label="Tag selection">
        <mat-chip
          *ngFor="let tag of tags"
          (removed)="removeFilterTag(tag)">
          {{tag}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="Search Tag..."
          #tagInput
          [formControl]="tagCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="tagChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addFilterTag($event)">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedFilterTag($event)">
        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
          {{tag}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button mat-button class="tag-filter-clear" (click)="onClearTags()">
      Clear Tags
    </button>
  </div>
</ng-container>
