import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ApiDataSource } from "../../utils/api-data-source";
import { UtilsService } from "../../shared/utils.service";
import { AppService } from "../../app.service";
import { ApiService } from "../../shared/api.service";
import { ApiRequestService } from "../../shared/api-request.service";
import { tap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { IndustriesModel } from "./industries.model";
import { IndustriesEditComponent } from "./industries-edit/industries-edit.component";
import { IndustriesViewComponent } from "./industries-view/industries-view.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.scss']
})
export class IndustriesComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new IndustriesDataSource(this.app, this.api);

  displayedColumns = [
    'select',
    'id',
    'name',
    'creator',
    'date_created',
    // 'date_created_UTC',
    'buttons'
  ];

  constructor(
    private utils: UtilsService,
    private app: AppService,
    private oldApi: ApiService,
    private api: ApiRequestService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource.getData(false);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      // Using date created when sorting by the UTC column.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData(false);
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onEdit(new IndustriesModel());
  }

  onEdit(industry: IndustriesModel) {
    this.dialog
      .open(IndustriesEditComponent, {
        width: '900px',
        data: industry
      })
      .afterClosed()
      .subscribe(() => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onView(id: number) {
    this.utils.showComponentDialog(
      IndustriesViewComponent,
      id,
      { width: '700px' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      }
    );
  }

  onRemoveSelected() {
    this.utils.showModal(
      'Remove Industries',
      `Are you sure you want to remove ${this.dataSource.selection.selected.length} industries?`,
      () => {
        this.remove(this.dataSource.selection.selected);
      }
    );
  }

  onRemove(industry: IndustriesModel) {
    this.utils.showModal(
      'Remove Industry',
      `Are you sure you want to remove "${industry.name}"?`,
      () => {
        this.remove([industry.id]);
      }
    );
  }

  private remove(ids: number[]) {
    this.api.makeRequest('delete', `v2/admin/industries/${ids.join(',')}`)
      .then((response) => {
        this.utils.showToast('Industries have been removed');
        this.dataSource.selection.clear();
        this.dataSource.getData(false);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class IndustriesDataSource extends ApiDataSource {
  order_by = 'name';
  order = 'asc';

  searchBy = 'name';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/admin/industries', resetOffset, {
      searchBy: this.searchBy
    });
  }
}
