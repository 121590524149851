import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DynamicFormsComponent } from './dynamic-forms/dynamic-forms.component';
// import { DynamicFormsEditComponent } from './dynamic-forms-edit/dynamic-forms-edit.component';
// import { DynamicFormsViewComponent } from './dynamic-forms-view/dynamic-forms-view.component';
import { AuthGuard } from '../guards/auth.guard';
import { DynamicFormsAssignedComponent } from './dynamic-forms-assigned/dynamic-forms-assigned.component';

const routes: Routes = [
  {
    path: 'dynamic-forms',
    component: DynamicFormsComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'dynamic-forms-assigned',
    component: DynamicFormsAssignedComponent,
    canActivate: [
      AuthGuard
    ]
  }// ,
  // {
  //   path: 'dynamic-forms/:form_id/edit',
  //   component: DynamicFormsEditComponent,
  //   canActivate: [
  //     AuthGuard
  //   ]
  // },
  // {
  //   path: 'dynamic-forms/:form_id/view',
  //   component: DynamicFormsViewComponent,
  //   canActivate: [
  //     AuthGuard
  //   ]
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DynamicFormsRoutingModule {}
