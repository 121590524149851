import { Component, OnInit, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { CourseModuleModel } from '../../../models/course-module.model';
import { ApiService } from '../../../shared/api.service';
import { UtilsService } from '../../../shared/utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-courses-modules-edit',
  templateUrl: './courses-modules-edit.component.html',
  styleUrls: ['./courses-modules-edit.component.scss']
})
export class CoursesModulesEditComponent implements OnInit {
  parent_id: number;

  module: CourseModuleModel = new CourseModuleModel();

  inProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { parent_id: number; module: CourseModuleModel },
    private dialogRef: MatDialogRef<CoursesModulesEditComponent>,
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    this.parent_id = this.data.parent_id;
    this.module = this.data.module;
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      this.utils.showModal(
        'Form Validation Error',
        'Please make sure you enter all fields with valid information.'
      );
      return;
    }

    if (this.inProgress) {
      return;
    }

    this.inProgress = true;

    if (this.module.id) {
      this.api.laravelApiRequest(
        'put',
        `inductions/${this.parent_id}/modules/${this.module.id}`,
        this.module,
        {},
        (response) => {
          this.utils.showToast('The module was updated.');
          this.dialogRef.close(true);
          this.inProgress = false;
        },
        (_error: any) => this.inProgress = false
      );
    } else {
      this.api.laravelApiRequest(
        'post',
        `inductions/${this.parent_id}/modules`,
        this.module,
        {},
        (response) => {
          this.utils.showToast('The module was created');
          this.dialogRef.close(true);
          this.inProgress = false;
        }, 
        (_error: any) => this.inProgress = false
      );
    }
  }

  onRemove() {
    this.utils.showModal(
      'Remove Module',
      'Are you sure you want to remove this induction module?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          `inductions/${this.parent_id}/modules/${this.module.id}`,
          {},
          {},
          (response) => {
            this.utils.showToast('The induction module is deleted.');
            this.router.navigate(['courses', this.parent_id, 'modules']);
          }
        );
      }
    );
  }
}
