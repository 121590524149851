import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { tap } from 'rxjs/operators';
import { NotificationRolesDataSource } from '../notification-roles/notification-roles.component';
import { NotificationRole } from '../../../interfaces/notification-role.interface';
import { ApiRequestService } from '../../../shared/api-request.service';
import { MatSort, Sort } from '@angular/material/sort';
import { UserModel } from '../../../models/user.model';
import { SiteModel } from '../../../models/site.model';
import { SystemNotification } from '../../../interfaces/system-notification.interface';
import { UtilsService } from '../../../shared/utils.service';
import { NotificationRolesTabsComponent } from '../notification-roles-tabs/notification-roles-tabs.component';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-roles-selector',
  templateUrl: './notification-roles-selector.component.html',
  styleUrls: ['./notification-roles-selector.component.scss']
})
export class NotificationRolesSelectorComponent implements OnInit {

  // The columns that should show in the list.
  listColumns: string[] = [
    'name',
    'is_active',
    'system_notifications_count',
    'sites_count',
    'users_count',
    'date_created',
    'date_modified'
  ];

  // The list data source.
  listDataSource: NotificationRolesDataSource = new NotificationRolesDataSource(this.app, this.api);

  // The paginator and sorter.
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  // Used to check if objects should be returned instead.
  return_objects: boolean = false;

  // Selected objects from the parent component.
  selected_objects: NotificationRole[] = [];

  constructor(
    public app: AppService,
    public api: ApiRequestService,
    public dialogRef: MatDialogRef<NotificationRolesSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utils: UtilsService
  ) {
    // Update the multiple property of the data source.
    this.listDataSource.multiple = typeof this.data['multiple'] !== 'undefined' ? Boolean(this.data['multiple']) : false;

    // Check if we need to display the checkboxes for the selector.
    if ( this.listDataSource.multiple ) {
      this.listColumns.unshift('select');
    } else {
      this.listColumns.push('actions');
    }

    // Check if we need to return objects or ids.
    this.return_objects = typeof this.data['return_objects'] !== 'undefined' ? Boolean(this.data['return_objects']) : false;

    // Set a default selection if no selected values are passed through.
    const selected_ids: number[] = this.data['selected'] || [];

    // Apply the selected ids.
    this.listDataSource.selection.select(...selected_ids);

    // Check if we have selected objects to work with.
    this.selected_objects = typeof this.data['selected_objects'] !== 'undefined' ? this.data['selected_objects'] : [];

    // Get the data.
    this.listDataSource.getData(true);
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    // Reset the paginator when sorting.
    this.sort.sortChange.subscribe((): void => {
      this.paginator.pageIndex = 0;
    });

    // Define the paginator tap events.
    const _tap = tap((): void => {
      this.listDataSource.limit = this.paginator.pageSize;
      this.listDataSource.offset = this.paginator.pageIndex;
      this.listDataSource.order_by = this.sort.active;
      this.listDataSource.order = this.sort.direction;
      // Refresh the list.
      this.listDataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator.page.pipe(_tap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter: Sort): void => {
      this.listDataSource.order = sorter['direction'];
      this.listDataSource.getData(true);
    })).subscribe();
  }

  /**
   * Handles the selection of multiple items in the list.
   * This can return objects or ids.
   * Returned objects will only be the ones that are selected, not all objects due to pagination unless the user clicks through all pages and select everything.
   *
   * @return {void} - Does not return anything.
   */
  onSelectMultiple(): void {
    if ( this.return_objects ) {
      // Return the selected objects.
      this.dialogRef.close(this.selected_objects);
    } else {
      // Return the selected ids.
      this.dialogRef.close(this.listDataSource.selection.selected);
    }
  }

  /**
   * Function to toggle row selection.
   *
   * @param {any} row - The row object to be toggled.
   * @return {void}
   */
  onToggleRowSelection(row: any): void {
    // Toggle the default row selection.
    this.listDataSource.selection.toggle(row.id);
    // Check if objects should be returned.
    if ( this.return_objects ) {
      // Get the index of the selected object. The index will be -1 if the object is not present.
      const index: number = this.selected_objects.findIndex((notification_role: NotificationRole): boolean => notification_role.id === row.id);
      // Check if the row is selected and the index is not present.
      if ( this.listDataSource.selection.isSelected(row.id) && index == -1 ) {
        // Add the row to the array.
        this.selected_objects.push(row);
      } else {
        // The row is not selected. Check if an object was found.
        if (index > -1) {
          // Remove the object if it was found.
          this.selected_objects.splice(index, 1);
        }
      }
    }
  }

  /**
   * Selects all records and closes the dialog.
   *
   * @returns {void} Returns nothing.
   */
  onSelectAllRecords(): void {
    // Return all ids from the data source.
    this.dialogRef.close(this.listDataSource.allIds);
  }

  /**
   * Deselects all records in the list data source.
   *
   * @return {void}
   */
  onDeselectAllRecords(): void {
    // Clear all selected items.
    this.listDataSource.selection.clear();
  }

  /**
   * Create a new or edit an existing notification role.
   *
   * @param notification_role_id The id of the notification role.
   */
  onEditNotificationRole(notification_role_id?: number): void {
    this.utils.showComponentDialog(NotificationRolesTabsComponent, {
      notification_role_id: notification_role_id
    }, {
      width: '90%'
    })
      .then((): void => {
        // Refresh the list after the dialog closed.
        this.listDataSource.getData();
      });
  }

  /**
   * Get system notifications labels for a specific notification role to display in a tooltip.
   *
   * @param notification_role The notification role for which the system notifications labels are needed.
   * @return The concatenated labels of system notifications for the specified notification role separated by newline characters.
   */
  getNotificationRoleSystemNotificationsForTooltip(notification_role: NotificationRole) {
    return notification_role.system_notifications.map((system_notification: SystemNotification) => {
      return system_notification.label;
    }).join('\n');
  }

  /**
   * Retrieves the names of sites associated with a specific notification role for tooltip display.
   *
   * @param notification_role The notification role for which to retrieve associated sites.
   * @return A string containing the names of sites associated with the provided notification role, separated by line breaks.
   */
  getNotificationRoleSitesForTooltip(notification_role: NotificationRole) {
    return notification_role.sites.map((site: SiteModel) => {
      return site.name;
    }).join('\n');
  }

  /**
   * Retrieves the contact persons of the users associated with a given notification role.
   *
   * @param {NotificationRole} notification_role - The notification role for which to fetch users.
   * @returns A string containing the contact persons of the users associated with the notification role, separated by new lines.
   */
  getNotificationRoleUsersForTooltip(notification_role: NotificationRole) {
    return notification_role.users.map((user: UserModel) => {
      return user.contact_person;
    }).join('\n');
  }
}
