import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {SitesSelectorComponent} from "../../../shared/sites-selector/sites-selector.component";

@Component({
  selector: 'app-sites-codes-filter',
  templateUrl: './sites-codes-filter.component.html',
  styleUrls: ['./sites-codes-filter.component.scss']
})
export class SitesCodesFilterComponent implements OnInit {

  /**
   * The filter object and default values.
   */
  filters: {
    parent_site_id: number,
    site_ids: number[] | string
  } = {
    parent_site_id: null,
    site_ids: []
  };

  constructor(
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SitesCodesFilterComponent>
  ) { }

  ngOnInit() {
    // Store the incoming values.
    this.filters.parent_site_id = this.data['parent_site_id'] || null;
    this.filters.site_ids = this.data['selected_site_ids'] || [];
  }

  /**
   * Open the site selector.
   */
  onSelectSites() {
    this.utils.showComponentDialog(SitesSelectorComponent, {
        parent_site_id: this.filters.parent_site_id,
        selected: this.filters.site_ids,
        multiple: true
      }, {
        width: '1024px'
      }
    )
      .then((results) => {
        if (typeof results !== 'undefined') {
          this.filters.site_ids = results;
        }
      });
  }

  /**
   * Clear the filters and close the dialog.
   */
  onClearAndClose() {
    this.filters.site_ids = [];
    // Close the dialog.
    this.onSaveAndClose();
  }

  /**
   * Close the dialog and send the filters back.
   */
  onSaveAndClose() {
    this.dialogRef.close(this.filters);
  }

}
