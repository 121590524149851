<div class="container-fluid" class="app-padding">
  <h5>Inspection/Audit Template</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>

  <form #form="ngForm" (ngSubmit)="onSubmit()">
    <mat-form-field style="width: 100%">
      <input
        matInput
        placeholder="Inspection/Audit Template"
        [(ngModel)]="audit.name"
        name="name"
        type="text"
        required
        #name />
      <mat-hint>Please enter an Inspection/Audit template name. </mat-hint>
    </mat-form-field>

    <mat-error *ngIf="!audit.item_ids?.length">Please select questions to Inspection or Audit. </mat-error>

    <button
      mat-raised-button
      [disabled]="!form.valid || !audit.item_ids?.length || inProgress"
      color="primary"
      type="submit"
      class="save">
      <mat-icon>save</mat-icon>
      Save
    </button>

    <button
      mat-raised-button
      (click)="onSelectAuditItems()"
      [matBadge]="'' + (audit.item_ids?.length)"
      type="button"
      color="secondary">
      <mat-icon>list</mat-icon>
      Questions
    </button>

    <button mat-icon-button class="float-right" [disabled]="!audit.id">
      <mat-icon>delete</mat-icon>
    </button>
  </form>
</div>
