<div mat-dialog-title>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5>TA/JSA/SWMS Workflow Step</h5>
      </div>
      <div class="col">
        <button mat-icon-button class="float-right" mat-dialog-close>
          <mat-icon color="secondary">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>


<mat-dialog-content>

  <p>{{ ta_revised_workflow.description }}</p>

  <ng-container *ngIf="ta_revised_workflow.step_no">
    <h6>Step No</h6>
    <p>{{ta_revised_workflow.step_no}}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow.created_by_user">
    <h6>Created By</h6>
    <p>
      <a (click)="onUserPublicView(ta_revised_workflow.created_by_user.hash)"
        matTooltip="View User Public Profile">
        {{ ta_revised_workflow.created_by_user.contact_person }}
      </a>
    </p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow.date_created">
    <h6>Created At</h6>
    <p>{{ (ta_revised_workflow.date_created * 1000) | timezoneConvertMedium}}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow.updated_by_user">
    <h6>Last Updated By</h6>
    <p>
      <a (click)="onUserPublicView(ta_revised_workflow.updated_by_user.hash)"
         matTooltip="View User Public Profile">
        {{ ta_revised_workflow.updated_by_user.contact_person }}
      </a>
    </p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow.date_modified">
    <h6>Last Updated At</h6>
    <p>{{ (ta_revised_workflow.date_modified * 1000) | timezoneConvertMedium}}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow.step_hazards?.length">
    <h6>Hazards & Risks</h6>
    <ul>
      <li *ngFor="let hazard of ta_revised_workflow.step_hazards">
        <p><span style="font-weight: 600">Hazard/Risk</span>: {{ hazard.name }}</p>
        <p><span style="font-weight: 600">Description</span>: {{ hazard.description }}</p>
        <p><span style="font-weight: 600">Status</span>: {{ hazard.status }}</p>
        <p><span style="font-weight: 600">Initial Risk Assessment</span>: <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(hazard.ira_likelihood, hazard.ira_severity))">
                        {{ utils.getRiskAssessmentText(hazard.ira_likelihood, hazard.ira_severity) }}
                      </span></p>
        <p><span style="font-weight: 600">Residual Risk Assessment</span>: <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(hazard.rra_likelihood, hazard.rra_severity))">
                        {{ utils.getRiskAssessmentText(hazard.rra_likelihood, hazard.rra_severity) }}
                      </span></p>

        <ng-container *ngIf="hazard.controls.length">
          <p><span style="font-weight: 600">Controls</span>:</p>
          <ul>
            <li *ngFor="let control of utils.jsonParse(hazard.controls); let i = index">
              <span style="font-weight: 500">{{ i + 1 }} - {{ control.control }}</span> <br />
              {{ loc_list[control.loc - 1] }}
            </li>
          </ul>
        </ng-container>
      </li>
    </ul>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow.notes?.length">
    <h6>Notes</h6>
    <ul>
      <li *ngFor="let note of ta_revised_workflow.notes">
        <p><span style="font-weight: 600">Note</span>: {{ note.note }}</p>
        <p><span style="font-weight: 600">Written By</span>: <span *ngIf="note.created_by_user"><a (click)="onUserPublicView(note.created_by_user.hash)"
            matTooltip="View User Public Profile">
            {{ note.created_by_user.contact_person }}
          </a></span>
        </p>
        <p><span style="font-weight: 600">Created At</span>: {{ note.date_created ? ((note.date_created * 1000) | timezoneConvertMedium) : "" }}</p>
        <p><span style="font-weight: 600">Last Updated At</span>: {{ note.date_modified ? ((note.date_modified * 1000) | timezoneConvertMedium) : "" }}</p>
      </li>
    </ul>
  </ng-container>

  <ng-container *ngIf="ta_revised_workflow.files?.length">
    <h6>Files</h6>
    <app-files-list
      id="files"
      [files]="ta_revised_workflow.files"
      [view]="true">
    </app-files-list>
  </ng-container>

</mat-dialog-content>

