import { PrequalificationModel } from './prequalification.model';
import { Auditable } from '../shared/auditable.model';
import { EmployeeModel } from './employee.model';

export class ContractorAccountModel extends Auditable {

  // Fillabe
  name: string = '';
  industry: string = '';
  address: string = '';
  suburb: string = '';
  city: string = '';
  postal_code: string = '';
  country: string = '';

  date_created: number = 0;
  date_modified: number = 0;

  //Guarded
  id: number = 0;
  email: string = '';
  mobile: string = '';
  contact_person: string = '';
  trade: string = '';

  /**
   * Mobile Country Code fields.
   */
  mobile_e164: string = '';
  mobile_country_code: string = '';
  mobile_dial_code: string = '';

  // Used for new account signups
  site_ids: number[] = [];

  employees: EmployeeModel[] = [];
  users: EmployeeModel[] = [];

  main_contact_id: number = 0;

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          switch ( key ) {
            case 'employees':
              this[key].length = 0;
              data[key].forEach((employee) => {
                this[key].push(new EmployeeModel().apply(employee));
              });
              break;
            default:
            this[key] = data[key];
          }
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0; // the user id
    this.email = '';
    this.mobile = '';
    this.mobile_e164 = '';
    this.mobile_country_code = '';
    this.mobile_dial_code = '';
    this.contact_person = '';
    // this.signoff = false;
    // this.date_signoff = '';
    this.name = '';
    this.industry = '';
    this.address = '';
    this.suburb = '';
    this.city = '';
    this.postal_code = '';
    this.country = '';

    this.site_ids.length = 0;
    this.employees.length = 0;

    // this.personalised_message = '';
    // this.percent_completed = 0;
    // this.average_rating = 0;
    // this.entity_name = '';
    //
    // this.site_ids.length = 0;
    // this.link_to_all_sites = false;
    // this.inductions_completed = 0;
    // this.inductions_count = 0;
    // this.prequalification = new PrequalificationModel();
    //
    // this.iqp_number = '';
  }
}
