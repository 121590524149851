<div class="container-fluid" appPadding>
  <ng-container *ngIf="toolbox_talk.name">
    <h6>{{ utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') }}</h6>
    <p>{{ toolbox_talk.name }}</p>
  </ng-container>

  <ng-container *ngIf="toolbox_talk.agenda">
    <h6>Agenda</h6>
    <div class="agenda-div" [innerHTML]="toolbox_talk.agenda | sanitizeHtml"></div>
  </ng-container>

  <ng-container *ngIf="toolbox_talk.site">
    <h6>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Name</h6>
    <p>{{toolbox_talk.site?.name}}</p>

    <h6>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Location</h6>
    <p>{{toolbox_talk.site?.location}}</p>
  </ng-container>

  <ng-container *ngIf="toolbox_talk.created_by_user">
    <h6>Created By</h6>
    <p>
      <a (click)="onUserPublicView(toolbox_talk.created_by_user.hash, $event)"
         matTooltip="View User Public Profile">
        {{ toolbox_talk.created_by_user.contact_person }}
      </a>
    </p>
  </ng-container>

  <h6>Created At</h6>
  <p>{{ (toolbox_talk.date_created * 1000) | timezoneConvertLong}}</p>

  <ng-container *ngIf="toolbox_talk.updated_by_user">
    <h6>Last Updated By</h6>
    <p>
      <a (click)="onUserPublicView(toolbox_talk.updated_by_user.hash, $event)"
         matTooltip="View User Public Profile">
        {{ toolbox_talk.updated_by_user.contact_person }}
      </a>
    </p>
  </ng-container>

  <h6>Last Updated At</h6>
  <p>{{ (toolbox_talk.date_modified * 1000) | timezoneConvertLong}}</p>

  <ng-container *ngIf="reported_by_user">
    <h6>Conducted By</h6>
    <p><b>Full Name</b>: <a (click)="onUserPublicView(reported_by_user.hash, $event)"
      matTooltip="View User Public Profile">
      {{ reported_by_user.contact_person }}
    </a></p>
    <p><b>Email Address</b>: {{ reported_by_user.email }}</p>
    <p><b>Mobile</b>: {{ reported_by_user.mobile }}</p>
  </ng-container>

  <ng-container *ngIf="toolbox_talk.auth_user_as_assignee">
    <app-digital-signature *ngIf="!toolbox_talk.auth_user_as_assignee.signature_url; else showSignature" (meta_output)="onSaveSignature($event)" [meta]="{ user_id: toolbox_talk.auth_user_as_assignee.id, user_name: toolbox_talk.auth_user_as_assignee.contact_person }"></app-digital-signature>
    <ng-template #showSignature>
      <app-digital-signature-image [meta]="{ signature_url: toolbox_talk.auth_user_as_assignee.signature_url, user_id: toolbox_talk.auth_user_as_assignee.id, user_name: toolbox_talk.auth_user_as_assignee.contact_person, signed_at: toolbox_talk.auth_user_as_assignee.signed_at }"></app-digital-signature-image>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="toolbox_talk.users.length">
    <h6 class="mt-3">Attendees</h6>
    <mat-list role="list">
      <mat-list-item *ngFor="let user of toolbox_talk.users" role="listitem">
        <img *ngIf="user.selfie_url" [src]="user.selfie_url" alt="{{ user.contact_person }}'s selfie" class="rounded-circle mr-3" style="width: 40px; height: 40px;">

        <a (click)="onUserPublicView(user.hash, $event)" matTooltip="View User Public Profile" class="mr-1">
          {{ user.contact_person }}
        </a>

        <button mat-button *ngIf="toolbox_talk.allow_signing; else signingNotAllowed" (click)="onOpenDigitalSignaturePad(user.id, user.contact_person, user.signature_url, user.signed_at)" class="ml-3">
          {{ user.signature_url ? 'Signed' : 'Sign' }}
        </button>
        <ng-template #signingNotAllowed>
          <small>
            - {{ user.signature_url ? 'Signed' : 'Not Signed' }}
          </small>
        </ng-template>
      </mat-list-item>
    </mat-list>
  </ng-container>

  <ng-container *ngIf="toolbox_talk.files?.length">
    <h6 class="mt-3">Uploaded Files</h6>
    <app-files-list [view]="true" [files]="toolbox_talk.files"></app-files-list>
  </ng-container>

</div>
