import { ApiDataSource } from 'src/app/utils/api-data-source';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TourService } from 'ngx-tour-md-menu';
import { tap } from 'rxjs/operators';
import { AdminSitesFilterComponent } from '../admin-sites-filter/admin-sites-filter.component';
import { AppService } from 'src/app/app.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-admin-sites',
  templateUrl: './admin-sites.component.html',
  styleUrls: ['./admin-sites.component.scss']
})
export class AdminSitesComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'account',
    'name',
    'location',
    'site_type',
    'site_status',
    'geofence',
    'date_created',
    'actions'
  ];
  dataSource = new SitesDataSource(this.app, this.apiRequest);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public app: AppService,
    private apiRequest: ApiRequestService,
    public tour: TourService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    this.dataSource.getData(true);

    // this.tour.initialize(
    //   [
    //     {
    //       anchorId: 'tour.add.site',
    //       content:
    //         "Click here to add a new site. You can set a geofence for the site, add the onsite manager's contact information and a site-specific induction.",
    //       title: 'Adding a new site'
    //     },
    //     {
    //       anchorId: 'tour.search',
    //       content:
    //         "Enter your search term here and click the filter button to search for specific records. Try to use whole words and if you want to exclude results with specific words, you can put a minus (-) sign in front of the word. E.g. 'Auckland -Takapuna'. It will find all records for Auckland but exclude records containing Takapuna.",
    //       title: 'Searching for records'
    //     },
    //     {
    //       anchorId: 'tour.sort.headers',
    //       content:
    //         'Click on any of these headers to sort the records in ascending or descending order.',
    //       title: 'Sorting records'
    //     },
    //     {
    //       anchorId: 'tour.paginator',
    //       content:
    //         'You can page through large sets of data by using the pagination controls.',
    //       title: 'Paging through records'
    //     },
    //     {
    //       anchorId: 'tour.actions',
    //       content:
    //         'You can manage records by clicking on the relevant actions for each record. You can view, edit or remove sites. You can also manage QR Codes for each site.',
    //       title: 'List actions'
    //     }
    //   ],
    //   {
    //     enableBackdrop: true
    //   }
    // );
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      // sorting for utc time by parsing original time
      if (this.sort.active === 'date_created_UTC') {
        this.dataSource.order_by = 'date_created';
      } else {
        this.dataSource.order_by = this.sort.active;
      }
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onSiteStatusFilter(status: string) {
    this.dataSource.status = status;
    this.dataSource.getData(true);
  }

  onSiteTypeFilter(type: string) {
    this.dataSource.type = type;
    this.dataSource.getData(true);
  }


  onFilter() {
    this.utils.showComponentDialog(
      AdminSitesFilterComponent,
      {
        filter_status: this.dataSource.status,
        filter_type: this.dataSource.type,
        filter_accounts: this.dataSource.accounts,
        filter_account_ids: this.dataSource.accountIds,
        date_filter_range: this.dataSource.date_filter_range
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.status =
            typeof results.status !== 'undefined'
              ? results.status
              : this.dataSource.status;
          this.dataSource.type =
            typeof results.type !== 'undefined'
              ? results.type
              : this.dataSource.type;
          this.dataSource.accounts =
            typeof results.accounts !== 'undefined'
              ? results.accounts
              : this.dataSource.accounts;
          this.dataSource.accountIds =
            typeof results.accountIds !== 'undefined'
              ? results.accountIds
              : this.dataSource.accountIds;
          this.dataSource.date_filter_range = results.date_filter_range ? results.date_filter_range : this.dataSource.date_filter_range;
          this.dataSource.getData();
        }
      }
    );
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
 export class SitesDataSource extends ApiDataSource  {

  order_by = 'name';
  order = 'asc';
  status = '';
  type = '';
  accounts: any = [];
  accountIds: any = [];
  date_filter_range: Date[] = [];

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/admin/sites', resetOffset, {
      search: this.search,
      status: this.status ? this.status : '',
      type: this.type ? this.type : '',
      account_ids: this.accountIds.join(','),
      date_filter_range: this.date_filter_range && this.date_filter_range.length > 1 ? [
        this.date_filter_range[0].getTime() / 1000,
        this.date_filter_range[1].getTime() / 1000
      ].join(',') : ''
    });
  }

  onExport(type: string = 'pdf', id?: number) {
    let ids: number[] = [];
    if (id) {
      ids.push(id);
    } else {
      ids.push(...this.selection.selected);
    }

    this.api.makeDownloadRequest(`v2/admin/sites/export/${type}` + (ids.length > 0 ? ('/' + ids.join(',')) : ''), {}, {
      search: this.search,
      status: this.status ? this.status : '',
      type: this.type ? this.type : '',
      account_ids: this.accountIds.join(','),
      date_filter_range: this.date_filter_range && this.date_filter_range.length > 1 ? [
        this.date_filter_range[0].getTime() / 1000,
        this.date_filter_range[1].getTime() / 1000
      ].join(',') : ''
    }).then((response) => {
      saveAs(response, `${this.app.account.name} - Sites.${type}`);
    });
  }
}
