import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InductionsComponent } from './inductions/inductions.component';
import { InductionsEditComponent } from './inductions-edit/inductions-edit.component';
import { InductionsViewComponent } from './inductions-view/inductions-view.component';
import { InductionModulesComponent } from './induction-modules/induction-modules/induction-modules.component';
import { InductionModulesEditComponent } from './induction-modules/induction-modules-edit/induction-modules-edit.component';
import { InductionModulesViewComponent } from './induction-modules/induction-modules-view/induction-modules-view.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: 'inductions',
    component: InductionsComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'inductions/:induction_id/edit',
    component: InductionsEditComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'inductions/:induction_id/view',
    component: InductionsViewComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'inductions/:induction_id/modules',
    component: InductionModulesComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'inductions/:induction_id/modules/:module_id/edit',
    component: InductionModulesEditComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'inductions/:induction_id/modules/:module_id/view',
    component: InductionModulesViewComponent,
    canActivate: [
      AuthGuard
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InductionsRoutingModule {}
