<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class=" mr-auto">
    <button mat-raised-button routerLink="/erps" appMarginRight>
      <mat-icon>arrow_back</mat-icon> Emergency Response Plan Templates
    </button>
    <button
      mat-raised-button
      routerLink="/erps/{{ erp.id }}/edit"
      appMarginRight
    >
      Edit
    </button>
  </span>
</nav>

<div class="container-fluid" appPadding>
  <ng-container *ngIf="erp.name">
    <h6>Emergency Response Plan Name</h6>
    <p>{{ erp.name }}</p>
  </ng-container>

  <ng-container *ngIf="erp.files">
    <h6>Emergency Response Plan Files</h6>
    <app-files-list [view]="true" [files]="erp.files"></app-files-list>
  </ng-container>
</div>
