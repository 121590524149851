import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { IncidentLtiModel } from 'src/app/models/incident-lti.model';
import { IncidentModel } from 'src/app/models/incident.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { IncidentsLtisTimeRecordsEditComponent } from '../incidents-ltis-time-records-edit/incidents-ltis-time-records-edit.component';

@Component({
  selector: 'app-incidents-ltis-time-records',
  templateUrl: './incidents-ltis-time-records.component.html',
  styleUrls: ['./incidents-ltis-time-records.component.scss']
})
export class IncidentsLtisTimeRecordsComponent implements OnInit {

  @Input() incident: IncidentModel;

  @Input() incident_lti: IncidentLtiModel;

  @Output() closed_incident_lti_time_record_evt: EventEmitter<any> = new EventEmitter();

  displayedColumns: string[] = [
    'select',
    'incident_lti_time_record_id',
    'actual_lti_date',
    // 'actual_lti_date_UTC',
    'actual_lti_days',
    'actual_lti_hours',
    'actions'
  ];
  dataSource = new IncidentLtiTimeRecordsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
  ) { }

  ngOnInit() {
    this.dataSource.incident_id = this.incident.id;
    this.dataSource.incident_lti_id = this.incident_lti.incident_lti_id;
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order = sorter['direction'];
      // sorting for utc time by parsing original time
      if (sorter['active'] === "actual_lti_date_UTC") {
        this.dataSource.order_by = "actual_lti_date";
      } else {
        this.dataSource.order_by = sorter['active'];
      }

      this.dataSource.getData();
    })).subscribe();
  }

  onEditTimeRecord(incident_lti_time_record_id: number = null) {
    this.utils.showComponentDialog(IncidentsLtisTimeRecordsEditComponent, {
      incident: this.incident,
      incident_lti: this.incident_lti,
      incident_lti_time_record_id: incident_lti_time_record_id
    })
      .then(() => {
        this.dataSource.getData();
        this.closed_incident_lti_time_record_evt.emit();
      });
  }

  onRemove(incident_lti_time_record_id: number) {
    this.utils.showModal('Delete Incident LTI Time Record', 'Are you sure you want to delete the incident LTI time record?', () => {
      this.api.makeRequest('delete', `v2/incidents/${this.incident.id}/ltis/${this.incident_lti.incident_lti_id}/time-records/${incident_lti_time_record_id}`)
        .then(() => {
          this.utils.showToast('The incident LTI time record was deleted.');
        })
        .finally(() => {
          this.dataSource.getData();
          this.closed_incident_lti_time_record_evt.emit();
        });
    });
  }

  onRemoveSelected() {
    this.utils.showModal('Delete Incident LTI Time Records', 'Are you sure you want to delete the selected incident LTI time records?', () => {
      const incident_lti_time_record_ids = this.dataSource.selection.selected.join(',');
      this.api.makeRequest('delete', `v2/incidents/${this.incident.id}/ltis/${this.incident_lti.incident_lti_id}/time-records/${incident_lti_time_record_ids}`)
        .then(() => {
          this.utils.showToast('The selected incident LTI time records were deleted.');
        })
        .finally(() => {
          this.dataSource.getData();
          this.closed_incident_lti_time_record_evt.emit();
        });
    });
  }
}

export class IncidentLtiTimeRecordsDataSource extends ApiDataSource {

  incident_id: number;
  incident_lti_id: number;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/incidents/${this.incident_id}/ltis/${this.incident_lti_id}/time-records`, resetOffset);
  }
}
