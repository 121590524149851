import { Component, OnInit } from '@angular/core';
import {EmployeeService} from "../employee.service";

@Component({
  selector: 'app-employees-sites-visited',
  templateUrl: './employees-sites-visited.component.html',
  styleUrls: ['./employees-sites-visited.component.scss']
})
export class EmployeesSitesVisitedComponent implements OnInit {

  constructor(
    public employeeService: EmployeeService
  ) {}

  ngOnInit() {}
}
