<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/contractors/{{contractorService.contractor.id}}/contractors">{{ utils.getLangTerm('sub-contractors.plural', 'Subcontractors') }}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto"></span>

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (ngSubmit)="listDataSource.getData(true)">
        <input class="form-control mr-sm-2"
               type="search"
               [(ngModel)]="listDataSource.search"
               name="search"
               placeholder="Search {{ utils.getLangTerm('sub-contractors.plural', 'Subcontractors') }}"
               aria-label="Search"
               id="search-input"
               matTooltip="Search for {{ utils.getLangTerm('sub-contractors.plural', 'Subcontractors') }} by company name, address or main contact." />

        <button
          mat-raised-button
          color="secondary"
          (click)="listDataSource.getData(true)"
          class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
          id="search-button"
          matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="listDataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

      <ng-container matColumnDef="icons">
        <th mat-header-cell *matHeaderCellDef>
          Status Icons
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon matTooltip="" *ngIf="element.courses_count > 0"
                    [matTooltip]="element.courses_count_completed + '/' + element.courses_count + ' Inductions Completed.'"
                    [ngStyle]="{'color': (element.courses_count_completed > 0 ? (element.courses_count_completed < element.courses_count ? 'orange' : 'green') : 'red')}">
            {{ element.courses_count_completed > 0 ? 'done_outline' : 'clear' }}
          </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by company name.">
          Company Name
        </th>
        <td mat-cell *matCellDef="let contractor">
          {{ contractor.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contact_person">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by main contact.">
          Main Contact
        </th>
        <td mat-cell *matCellDef="let contractor">
          <p>
            <a (click)="onUserPublicView(contractor.hash)"
               matTooltip="View User Public Profile">
              {{ contractor.contact_person }}
            </a> <br>
            {{ contractor.email }}<br>
            {{ contractor.mobile }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="industry">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by industry.">
          Industry
        </th>
        <td mat-cell *matCellDef="let contractor">{{ contractor.industry }}</td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by enrollment date.">
          Date Enrolled
        </th>
        <td mat-cell *matCellDef="let contractor">{{ (contractor.date_created * 1000) | timezoneConvertMedium }}</td>
      </ng-container>

      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by enrollment date.">
          Date Enrolled (UTC)
        </th>
        <td mat-cell *matCellDef="let contractor">{{ (contractor.date_created * 1000) | timezoneConvertUTCMedium }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>

