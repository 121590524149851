<div mat-dialog-title>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5>Visitor</h5>
      </div>
      <div class="col">
        <button mat-icon-button class="float-right" mat-dialog-close>
          <mat-icon color="secondary">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<h6>Full Name</h6>
<p>
  <a (click)="onUserPublicView(visitor.hash)"
     matTooltip="View User Public Profile">
    {{ visitor.contact_person }}
  </a>
</p>

<h6>Email Address</h6>
<p>{{ visitor.email }}</p>


<h6>{{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}</h6>
<p>{{ visitor.mobile }}</p>

<ng-container *ngIf="visitor.date_created">
  <h6>Date Visited</h6>
  <p>{{ (visitor.date_created * 1000) | timezoneConvertLong}}</p>
</ng-container>
