import {Component, Inject, OnInit} from '@angular/core';
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  NetworkedAccountsSelectorComponent
} from "../../shared/networked-accounts-selector/networked-accounts-selector.component";
import {
  NetworkedUsersSelectorComponent
} from "../../shared/networked-users-selector/networked-users-selector.component";

@Component({
  selector: 'app-courses-report-filter',
  templateUrl: './courses-report-filter.component.html',
  styleUrls: ['./courses-report-filter.component.scss']
})
export class CoursesReportFilterComponent implements OnInit {

  // Define the filter options.
  filter_options: {
    account_ids: number[],
    user_ids: number[],
    status: string
  } = {
    account_ids: [],
    user_ids: [],
    status: ""
  };

  constructor(
    public location: Location,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CoursesReportFilterComponent>
  ) {
    // Set a default downstream account ids list of nothing if no values are passed through
    if (typeof this.data['account_ids'] == 'undefined') {
      this.data['account_ids'] = [];
    }

    // Set a default user ids list of nothing if no values are passed through
    if (typeof this.data['user_ids'] == 'undefined') {
      this.data['user_ids'] = [];
    }

    // Set a default status of nothing if no values are passed through
    if (typeof this.data['status'] == 'undefined') {
      this.data['status'] = "";
    }
  }

  ngOnInit() {
    // The constructor makes sure the values are set and present.
    this.filter_options.account_ids = this.data['account_ids'];
    this.filter_options.user_ids = this.data['user_ids'];
    this.filter_options.status = this.data['status'];
  }

  /**
   * Open the accessible networked users selector.
   */
  onSelectUsers() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
        selected: this.filter_options.user_ids,
        multiple: true,
        visitors_from_all_sites: true
      }, {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.filter_options.user_ids = results;
        }
      }
    );
  }

  /**
   * Open the accessible accounts selector.
   */
  onChangeSelectedAccount() {
    this.utils.showComponentDialog(
      NetworkedAccountsSelectorComponent,
      {
        selected: this.filter_options.account_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (result: any) => {
        if (typeof result !== 'undefined') {
          this.filter_options.account_ids = result;
        }
      }
    );
  }

  /**
   * Clear the filter options and send the response back.
   */
  onClearAndClose() {
    // Clear all filter options.
    this.filter_options.account_ids = [];
    this.filter_options.user_ids = [];
    this.filter_options.status = "";

    this.onSaveAndClose();
  }

  /**
   * Close the dialog and send the selected options as the response.
   */
  onSaveAndClose() {
    this.dialogRef.close(this.filter_options);
  }

}
