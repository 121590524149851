<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="mr-auto">
      <button mat-raised-button color="primary" (click)="onAdd()" appMarginRight id="add" matTooltip="Click this button to add a new Document Link.">
        <mat-icon>add</mat-icon>
        New Link
      </button>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>
        <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>delete</mat-icon> Delete Selected
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Document Links." style="min-width: 120px">
        <mat-icon>more_vert</mat-icon>
        List Menu
      </button>
    </span>

    <form class="form-inline my-2 my-lg-0"
          style="min-width: 325px"
          (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2" type="search"
        [(ngModel)]="dataSource.search" name="search" placeholder="Search"
        aria-label="Search" id="search-input"
        matTooltip="Search for Document Links." />
      <button mat-raised-button
              class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
              color="secondary"
              (click)="dataSource.getData(true)"
              id="search-button"
              matTooltip="Click this button to run the current search.">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>

  </nav>

  <mat-dialog-content class="my-3 mx-0 py-0 pr-3 pl-0" style="max-height: 40vh">
    <div class="scroll-box">
      <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

      <table mat-table [dataSource]="dataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef width="65px">
            <mat-checkbox
              (change)="$event ? dataSource.selectAllToggle() : null"
              [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? dataSource.selection.toggle(row.id) : null"
              [checked]="dataSource.selection.isSelected(row.id)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            #
          </th>
          <td mat-cell *matCellDef="let folder_link">
            {{ folder_link.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Url
          </th>
          <td mat-cell *matCellDef="let folder_link">
            <div class="text-wrap link-wrap">
              <a target="_blank" [href]="folder_link.url">{{ folder_link.url | ellipsis: [150] }}</a>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Description
          </th>
          <td mat-cell *matCellDef="let folder_link">
            <mat-form-field>
              <textarea matInput rows="5" [value]="folder_link.description" disabled></textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="created_by_user">
          <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
            Created By
          </th>
          <td mat-cell *matCellDef="let folder_link" class="no-padding">
            <span *ngIf="folder_link?.created_by_user else showSystemCreator">
              <a (click)="onUserPublicView(folder_link.created_by_user.hash)"
                 matTooltip="View User Public Profile">
                {{ folder_link.created_by_user.contact_person }}
              </a>
            </span>
            <ng-template #showSystemCreator>System</ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="date_created">
          <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
            Created At
          </th>
          <td mat-cell *matCellDef="let folder_link" class="no-padding">
        <span>
          {{ (folder_link.date_created * 1000) | timezoneConvertMedium }}
        </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>

          <td mat-cell *matCellDef="let folder_link">
            <button type="button" mat-button (click)="onView(folder_link.id)" matTooltip="Click this button to view this Document Link.">
              View
            </button>

            <button type="button" mat-button (click)="onEdit(folder_link)" matTooltip="Click this button to edit this Document Link.">
              Edit
            </button>

            <button type="button" mat-button (click)="onRemove(folder_link.id)" matTooltip="Click this button to remove this Document Link.">
              Delete
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
    </div>
  </mat-dialog-content>

</div>

