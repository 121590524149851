import { AfterContentInit, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { UtilsService } from '../shared/utils.service';
import { ApiRequestService } from '../shared/api-request.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, AfterContentInit {

  email: string = '';

  constructor(
    public router: Router,
    public app: AppService,
    public utils: UtilsService,
    private api: ApiRequestService
  ) {}

  ngOnInit() {}

  ngAfterContentInit() {
    // If the user is authenticated, redirect them to the application root for validation and further redirection.
    if ( this.app.isAuthenticated ) {
      this.router.navigate(['/']);
    }
  }

  onSubmit(form: NgForm) {
    // Check if the form inputs are valid.
    if ( !form.valid ) {
      this.utils.showModal('Error Message', 'Please make sure you enter all required fields.');
      return;
    }
    // Make an API request to reset the user's password.
    this.api.makeRequest('post', 'v2/password-reset-request', {
      email: this.email
    })
    .then((response) => {
      this.utils.showToast(response.message);
      this.router.navigate(['/']);
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
    });
  }
}
