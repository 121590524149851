import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import {NetworkedAccountsSelectorComponent} from "../../shared/networked-accounts-selector/networked-accounts-selector.component";
import {NetworkedUsersSelectorComponent} from "../../shared/networked-users-selector/networked-users-selector.component";
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";
import {SafetyObservationsFilter} from "./safety-observations-filter.interface";

@Component({
  selector: 'app-safety-observations-filter',
  templateUrl: './safety-observations-filter.component.html',
  styleUrls: ['./safety-observations-filter.component.scss']
})
export class SafetyObservationsFilterComponent implements OnInit {

  faHardHat = faHardHat;

  // The filter object.
  filters: SafetyObservationsFilter = {
    so_category: '',
    so_type: '',
    so_priority: '',
    so_status: '',
    so_rating: '',
    site_ids: [],
    date_range: [],
    contractor_ids: [],
    user_ids: [],
    reported_by_ids: []
  } as SafetyObservationsFilter;

  constructor(
    private app: AppService,
    public location: Location,
    public utils: UtilsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SafetyObservationsFilterComponent>
    // private api: ApiRequestService
  ) {

  }

  /**
   * @method getOutputDateRange
   * @description Sets the safety observations date range in the filters object based on the provided event.
   *
   * @param {any} $event - The event object containing the safety observations date range.
   *
   * @return {void}
   */
  getOutputDateRange($event: any): void {
    // Get the safety observation date range from the date selector.
    this.filters.date_range = $event;
  }

  ngOnInit() {
    // Get and apply the safety observations filters.
    this.filters = {
      so_category: this.data['so_category'] || '',
      so_type: this.data['so_type'] || '',
      so_priority: this.data['so_priority'] || '',
      so_status: this.data['so_status'] || '',
      so_rating: this.data['so_rating'] || '',
      site_ids: this.data['site_ids'] || [],
      date_range: this.data['date_range'] || [],
      contractor_ids: this.data['contractor_ids'] || [],
      user_ids: this.data['user_ids'] || [],
      reported_by_ids: this.data['reported_by_ids'] || []
    }
    // Remap the dates as new Date objects. Storing it in storage stores it as string.
    this.filters.date_range = this.filters.date_range.map((date: Date|string) => new Date(date));
  }

  /**
   * Shows a component dialog to select sites and stores the selected site IDs in the filters object.
   *
   * @return {void}
   */
  onSelectSites(): void {
    this.utils.showComponentDialog(SitesSelectorComponent, {
        selected: this.filters.site_ids,
        multiple: true
      }, {
        width: '1024px'
      }, ( results: number[] ): void => {
        if ( typeof results != 'undefined' ) {
          this.filters.site_ids = results;
        }
      }
    );
  }

  /**
   * Shows a component dialog for selecting contractors.
   *
   * @return {void}
   */
  onSelectContractors() {
    this.utils.showComponentDialog(NetworkedAccountsSelectorComponent, {
      selected: this.filters.contractor_ids,
      multiple: true
    }, {
      width: '1024px'
    }, (results: number[]): void => {
      if ( typeof results != 'undefined' ) {
        this.filters.contractor_ids = results;
      }
    });
  }

  /**
   * Opens a dialog to select multiple reported by users.
   *
   * @return {void} - This method does not return a value.
   */
  onSelectReportedBy(): void {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.filters.reported_by_ids,
      multiple: true,
      visitors_from_all_sites: true
    }, {
      width: '1024px'
    }, (results: number[]): void => {
      if (typeof results != 'undefined' ) {
        this.filters.reported_by_ids = results;
      }
    });
  }

  /**
   * Opens a dialog to select multiple users and updates the selected user IDs.
   *
   * @return {void}
   */
  onSelectUsers(): void {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.filters.user_ids,
      multiple: true,
      selectedAccountId: this.app.account.id,
      visitors_from_all_sites: true
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if ( typeof results != 'undefined' ) {
        this.filters.user_ids = results;
      }
    });
  }

  /**
   * Clears all filter options and closes the component.
   *
   * @return {void}
   */
  onClearAndClose(): void {
    this.filters.so_category = "";
    this.filters.so_type = "";
    this.filters.so_priority = "";
    this.filters.so_status = "";
    this.filters.so_rating = "";
    this.filters.site_ids = [];
    this.filters.contractor_ids = [];
    this.filters.user_ids = [];
    this.filters.reported_by_ids = [];
    this.filters.date_range = [];

    this.onSaveAndClose();
  }

  /**
   * Saves and closes the dialog.
   *
   * @return {void} - This method does not return anything.
   */
  onSaveAndClose(): void {
    // Close the dialog and send the filters back.
    this.dialogRef.close(this.filters);
  }

  /**
   * Clears the date range for the incident.
   *
   * @returns {void}
   */
  onClearDateRange(): void {
    this.filters.date_range = [];
  }
}
