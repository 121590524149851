import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from 'src/app/shared/utils.service';
import { ItemModel } from '../item.model';
import { ItemsService } from '../items.service';

@Component({
  selector: 'app-items-view',
  templateUrl: './items-view.component.html',
  styleUrls: ['./items-view.component.scss']
})
export class ItemsViewComponent implements OnInit {
  public item = new ItemModel();
  public mediaDisplayedColumns = ['name', 'icon'];
  private pivotId: number;

  constructor(
    public utils: UtilsService,
    private route: ActivatedRoute,
    private itemsService: ItemsService
  ) {}

  ngOnInit() {
    this.item.id = this.route.snapshot.params['id'];
    this.pivotId = this.route.snapshot.params['pivotId'];
    this.itemsService
      .find(this.item.id, this.pivotId)
      .subscribe((item: ItemModel) => (this.item = item));
  }
}
