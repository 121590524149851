<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button mat-raised-button routerLink="/sites" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
  </button>

  <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
  </button>
</nav>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="." id="title">Documents</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <span class="mr-auto">
      <button
        mat-raised-button
        color="primary"
        appMarginRight
        (click)="onAdd()">
        <mat-icon>add</mat-icon>
        Add Folder
      </button>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>
        <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>delete</mat-icon> Delete Selected
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Folders." style="min-width: 120px">
        <mat-icon>more_vert</mat-icon>
        List Menu
      </button>
    </span>

    <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onFilter()" matTooltip="Click this button to display filter options.">
      <mat-icon>filter_list</mat-icon> Filter
    </button>

    <form
      tourAnchor="tour.search"
      style="min-width: 325px"
      class="form-inline my-2 my-lg-0"
      (ngSubmit)="dataSource.getData(true)">

      <input
        class="form-control mr-sm-2"
        type="search"
        [(ngModel)]="dataSource.search"
        name="search"
        placeholder="Search"
        aria-label="Search" />

      <button
        mat-raised-button
        class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
        color="secondary"
        (click)="dataSource.getData(false)"
        type="button">
        <mat-icon>search</mat-icon>Search
      </button>
    </form>
  </div>

</nav>

<div class="mat-elevation-z8 scroll-box">
  <table
    mat-table
    [dataSource]="dataSource"
    style="width: 100%"
    matSort
    matSortActive="name"
    matSortDisableClear>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
                      [checked]="
              dataSource.selection.hasValue() && dataSource.isAllSelected()
            " [indeterminate]="
              dataSource.selection.hasValue() && !dataSource.isAllSelected()
            ">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? dataSource.selection.toggle(row.id) : null"
                      [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        #
      </th>
      <td mat-cell *matCellDef="let folder" class="no-padding">
        {{ folder.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Name
      </th>
      <td mat-cell *matCellDef="let folder" class="no-padding">
        {{ folder.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="media">
      <th mat-header-cell *matHeaderCellDef class="no-padding">File Count</th>
      <td mat-cell *matCellDef="let folder" class="no-padding">
        {{ folder.files?.length }}
      </td>
    </ng-container>

    <ng-container matColumnDef="links">
      <th mat-header-cell *matHeaderCellDef class="no-padding">Link Count</th>
      <td mat-cell *matCellDef="let folder" class="no-padding">
        {{ folder.links?.length }}
      </td>
    </ng-container>

    <!-- Associated Site Count -->
    <ng-container matColumnDef="sites">
      <th mat-header-cell *matHeaderCellDef id="th-sites" matTooltip="The number of {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} that are assigned to this folder.">
        {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
      </th>
      <td mat-cell *matCellDef="let document">
        <ng-container *ngIf="document.sites_count == 1 && document.sites.length == 1; else summarisedSites">
          <ng-container *ngIf="document.sites[0]?.parent_site">
            <small style="display: block;">
              <a [routerLink]="utils.prepareSiteRelatedLink(document.sites[0]?.parent_site?.id, null, '/edit/details')">
                {{ document.sites[0]?.parent_site?.name || '' }}
              </a>
            </small>
          </ng-container>
          <mat-icon *ngIf="document.sites[0]?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
          <a [routerLink]="utils.prepareSiteRelatedLink(document?.sites[0]?.id, document?.sites[0]?.parent_site?.id, '/edit/details')">
            {{ document.sites[0].name || '' }}
          </a>
        </ng-container>
        <ng-template #summarisedSites>
            <span [matTooltip]="formatSitesListColumnTooltip(document)">
              {{ formatSitesListColumnText(document) }}
            </span>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>Date Created</th>
      <td mat-cell *matCellDef="let folder" class="no-padding">
        {{ (folder.created_at * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="created_at_UTC">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>Date Created</th>
      <td mat-cell *matCellDef="let folder" class="no-padding">
        {{ (folder.created_at * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let folder">
        <button type="button" mat-button (click)="onView(folder)" matTooltip="View the Folder information." matTooltipPosition="above">
          View
        </button>

        <button type="button" mat-button (click)="onEdit(folder)" matTooltip="Edit the Folder information." matTooltipPosition="above">
          Edit
        </button>

        <button type="button" mat-button (click)="onDelete(folder)" matTooltip="Remove (Archive) the Folder." matTooltipPosition="above">
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>
</div>
