<div mat-dialog-title>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5>Industry</h5>
      </div>
      <div class="col">
        <button mat-icon-button class="float-right" mat-dialog-close>
          <mat-icon color="secondary">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<p>{{ industry.name }}</p>

<ng-container *ngIf="industry.created_by_user">
  <h6>Created By</h6>
  <p>
    <a (click)="onUserPublicView(industry.created_by_user.hash)"
      matTooltip="View User Public Profile">
      {{ industry.created_by_user.contact_person }}
    </a>
  </p>
</ng-container>

<ng-container *ngIf="industry.date_created">
  <h6>Created At</h6>
  <p>{{ (industry.date_created * 1000) | timezoneConvertLong }}</p>
</ng-container>

<ng-container *ngIf="industry.updated_by_user">
  <h6>Last Updated By</h6>
  <p>
    <a (click)="onUserPublicView(industry.updated_by_user.hash)"
      matTooltip="View User Public Profile">
      {{ industry.updated_by_user.contact_person }}
    </a>
  </p>
</ng-container>

<ng-container *ngIf="industry.date_modified">
  <h6>Last Updated At</h6>
  <p>{{ (industry.date_modified * 1000) | timezoneConvertLong }}</p>
</ng-container>
