import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { CodeModel } from '../models/code.model';
import { ApiRequestService } from '../shared/api-request.service';
import { UtilsService } from '../shared/utils.service';

@Component({
  selector: 'app-handle-qr-code',
  templateUrl: './handle-qr-code.component.html',
  styleUrls: ['./handle-qr-code.component.scss']
})
export class HandleQrCodeComponent implements OnInit {

  code_hash: string = '';

  isInvalid: boolean = false;

  code: CodeModel;

  constructor(
    private route: ActivatedRoute,
    private app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService,
    private router: Router
  ) { }

  ngOnInit() {
    // Get the QR code hash.
    this.code_hash = this.route.snapshot.params['code_hash'];

    if ( this.code_hash.length != 32 ) {
      this.isInvalid = true;
      this.utils.showToast('The QR code hash you provided is invalid. You will be redirected in 5 seconds.');
      setTimeout(() => {
        this.router.navigate(['/sign-in']);
      }, 5000);
      return;
    }

    this.api.makeRequest('get', `v2/codes/validate/${this.code_hash}`)
    .then((response) => {
      if ( !this.code ) {
        this.code = new CodeModel();
      }
      this.code.apply(response);
    })
    .catch((errorResponse) => {
      this.isInvalid = true;
    });
  }

}
