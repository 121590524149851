<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class="mr-auto">
    <button mat-raised-button routerLink="../.." class="margin-right">
      <mat-icon>arrow_back</mat-icon> Back
    </button>

    <button mat-raised-button (click)="onCopyToContractors()" color="primary" class="margin-right">
      <mat-icon>file_copy</mat-icon> Copy to {{ utils.getLangTerm('contractors.plural', 'Contractors') }}
    </button>
  </span>
</nav>

<div class="app-padding">
  <h6>Name</h6>
  <p id="name">{{ prequal.name }}</p>

  <ng-container *ngIf="prequal.description != ''">
    <h6>Pre-Qualification Description</h6>
    <p id="description">{{ prequal.description }}</p>
  </ng-container>

  <h6>Initial Notifications</h6>
  <p>Initial Notifications for this pre-qualification are <b>{{ prequal.enable_notifications ? 'enabled' : 'disabled' }}</b>.</p>

  <h6>Items</h6>
  <ul>
    <li *ngFor="let item of prequal.items">{{item.name}}</li>
  </ul>
</div>
