import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ApiDataSource } from "../../utils/api-data-source";
import { UtilsService } from "../../shared/utils.service";
import { AppService } from "../../app.service";
import { ApiService } from "../../shared/api.service";
import { ApiRequestService } from "../../shared/api-request.service";
import { tap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { TradeModel } from "./trades.model";
import { TradesEditComponent } from "./trades-edit/trades-edit.component";
import { TradesViewComponent } from "./trades-view/trades-view.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.scss']
})
export class TradesComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new TradesDataSource(this.app, this.api);

  displayedColumns = [
    'select',
    'id',
    'name',
    'creator',
    'date_created',
    // 'date_created_UTC',
    'buttons'
  ];

  constructor(
    private utils: UtilsService,
    private app: AppService,
    private oldApi: ApiService,
    private api: ApiRequestService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource.getData(false);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      // Using date created when sorting by the UTC column.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData(false);
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onEdit(new TradeModel());
  }

  onEdit(trade: TradeModel) {
    this.utils.showComponentDialog(TradesEditComponent, trade, {
      width: '900px'
    }, () => {
      this.dataSource.getData(true);
    });
  }

  onView(id: number) {
    this.utils.showComponentDialog(
      TradesViewComponent,
      id,
      {
        width: '700px'
      },
      () => {
        this.dataSource.getData(true);
      }
    );
  }

  onRemoveSelected() {
    this.utils.showModal(
      'Remove Job Titles',
      `Are you sure you want to remove ${this.dataSource.selection.selected.length} job title(s)?`,
      () => {
        this.remove(this.dataSource.selection.selected);
      }
    );
  }

  onRemove(trade: TradeModel) {
    this.utils.showModal(
      'Remove Job Title',
      `Are you sure you want to remove the "${trade.name}" job title?`,
      () => {
        this.remove([trade.id]);
      }
    );
  }

  private remove(ids: number[]) {
    this.api.makeRequest('delete', `v2/admin/trades/${ids.join(',')}`)
      .then((response) => {
        this.utils.showToast('Job titles have been removed.');
        this.dataSource.selection.clear();
        this.dataSource.getData(false);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class TradesDataSource extends ApiDataSource {
  order_by = 'name';
  order = 'asc';

  searchBy = 'name';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/admin/trades', resetOffset, {
      searchBy: this.searchBy
    });
  }
}
