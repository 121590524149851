<ng-template #containerHeader>
  <ng-container *ngIf="config.isPanelExpanded">
    <div class="d-flex justify-content-end" style="margin-left: auto;" [ngClass]="{ 'non-expansion-panel-container': disableExpansionPanel }">
      <div class="charts-controls-hover-effects">
        <button mat-icon-button class="mr-1" [ngClass]="{ 'pulsating-filter-button': !isFiltersEmptyExcludingTags(['archived']), 'inline-mat-panel-title-icon-button': !disableExpansionPanel }" (click)="onFilter($event)" matTooltip="Click this button to display filter options.">
          <mat-icon>filter_list</mat-icon>
        </button>

        <button mat-icon-button class="mr-2" [ngClass]="{ 'inline-mat-panel-title-icon-button': !disableExpansionPanel }" [matMenuTriggerFor]="rowMenu" (click)="onStopEventPropagation($event)" matTooltip="Add a row to show charts in it."><mat-icon>add</mat-icon></button>
        <mat-menu #rowMenu="matMenu">
          <button mat-menu-item [value]="1" (click)="onAddRow(1)">Row with 1 Column</button>
          <button mat-menu-item [value]="2" (click)="onAddRow(2)">Row with 2 Columns</button>
          <button mat-menu-item [value]="3" (click)="onAddRow(3)">Row with 3 Columns</button>
          <button mat-menu-item [value]="4" (click)="onAddRow(4)">Row with 4 Columns</button>
        </mat-menu>

        <button mat-icon-button class="mr-1" [ngClass]="{ 'inline-mat-panel-title-icon-button': !disableExpansionPanel }" (click)="onDownloadAllCharts($event)" *ngIf="doesRowsHaveDownloadableCharts()" matTooltip="Download all of the chart images.">
          <mat-icon>download</mat-icon>
        </button>

        <button mat-icon-button color="warn" (click)="onRemoveContainer($event)" class="mr-1"  [ngClass]="{ 'inline-mat-panel-title-icon-button': !disableExpansionPanel }" *ngIf="show_remove_container_button; else whiteSpacePlaceholder" matTooltip="Remove this container.">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <ng-template #whiteSpacePlaceholder>
        <span class="mr-1" style="width: 32px;"></span>
      </ng-template>
    </div>
  </ng-container>
</ng-template>
<ng-template #containerContent>
  <ng-container *ngFor="let row of config.rows; let i = index">
    <mat-card *ngIf="config.isPanelExpanded">
      <mat-card-header class="col-12 d-flex justify-content-end bg-light py-2">
        <div class="charts-controls-hover-effects">
          <select class="form-select mr-3" [(ngModel)]="row.cols" (change)="onChangeColsConfig(i)" matTooltip="Change the number of columns for this row. Remove excess charts to decrease the number of columns if options are missing.">
            <option [value]="1" *ngIf="row.charts.length < 2">1 Column</option>
            <option [value]="2" *ngIf="row.charts.length < 3">2 Columns</option>
            <option [value]="3" *ngIf="row.charts.length < 4">3 Columns</option>
            <option [value]="4">4 Columns</option>
          </select>

          <button mat-icon-button [matMenuTriggerFor]="chartsMenu" matTooltip="Add charts to this row from a selection of available charts."><mat-icon>add</mat-icon></button>
          <mat-menu #chartsMenu="matMenu">
            <button mat-menu-item *ngFor="let availableChart of availableCharts" [value]="availableChart.chart" (click)="onAddChart(availableChart.chart, i)">{{ availableChart.chartName }}</button>
          </mat-menu>

          <button mat-icon-button (click)="onDownloadRowOfCharts(i)" *ngIf="doesRowHaveDownloadableCharts(i)" matTooltip="Download this row of chart images.">
            <mat-icon>download</mat-icon>
          </button>

          <button mat-icon-button color="warn" class="mr-3" (click)="onRemoveRow($event, i)" matTooltip="Remove this row.">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="row pt-2 pb-2" style="background: white; border-bottom: silver;" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="onDragAndDrop($event, i)">
          <div [class]="getColsConfig(row.cols)" *ngFor="let chart of row.charts; let j = index" cdkDrag>
            <ng-container [ngSwitch]="chart.chart">
              <mat-card>
                <mat-card-header class="d-flex justify-content-between">

                  <div style="flex-grow: 1;">
                    <b class="text-muted">{{ getCardTitle(i, j) }}</b>
                  </div>

                  <div class="charts-controls-hover-effects">
                    <button mat-icon-button cdkDragHandle matTooltip="Drag and drop the chart to reorder the list.">
                      <mat-icon>drag_indicator</mat-icon>
                    </button>

                    <button mat-icon-button (click)="onRefreshChart(i, j)" matTooltip="Refresh the chart data.">
                      <mat-icon>refresh</mat-icon>
                    </button>

                    <button mat-icon-button (click)="onDownloadChart(i, j)" *ngIf="chart.chart != 'SafetyObservationsStatsTableComponent'" matTooltip="Download this chart image.">
                      <mat-icon>download</mat-icon>
                    </button>

                    <button mat-icon-button color="warn" (click)="onRemoveChart($event, i, j)" class="mr-3" matTooltip="Remove this chart.">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </mat-card-header>
                <mat-card-content>
                  <app-hazards-statuses-pie-chart *ngSwitchCase="'HazardsStatusesPieChartComponent'" (referenceEvent)="onStoreChartRef($event, i, j)" [filters]="config.filters" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-hazards-statuses-pie-chart>
                  <app-hazards-historical-combo-chart *ngSwitchCase="'HazardsHistoricalComboChartComponent'" (referenceEvent)="onStoreChartRef($event, i, j)" [filters]="config.filters" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-hazards-historical-combo-chart>
                  <app-hazards-risk-assessments-matrix-chart *ngSwitchCase="'HazardsRiskAssessmentsMatrixChartComponent'" (referenceEvent)="onStoreChartRef($event, i, j)" [filters]="config.filters" [configStorageKey]="configStorageKey + '_' + chart.id" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-hazards-risk-assessments-matrix-chart>
                  <div *ngSwitchDefault></div>
                </mat-card-content>
              </mat-card>
            </ng-container>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>
</ng-template>

<mat-accordion *ngIf="!disableExpansionPanel; else basicContainer">
  <mat-expansion-panel [expanded]="config.isPanelExpanded" #expansionPanel (opened)="onStoreChartsPanelExpandedState(expansionPanel.expanded)"  (closed)="onStoreChartsPanelExpandedState(expansionPanel.expanded)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>{{ containerTitle }}</span>
        <ng-template [ngTemplateOutlet]="containerHeader"></ng-template>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template [ngTemplateOutlet]="containerContent"></ng-template>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #basicContainer>
  <ng-template [ngTemplateOutlet]="containerHeader"></ng-template>
  <ng-template [ngTemplateOutlet]="containerContent"></ng-template>
</ng-template>
