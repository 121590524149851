import { SiteModel } from './site.model';
import { Auditable } from '../shared/auditable.model';
import { AccountModel } from './account.model';

export class CodeModel extends Auditable {
  id = 0;
  feature = 'Access Control';
  type = 'In/Out';
  name = '';
  message = '';
  site_id = 0;
  parent_id = 0;
  site = new SiteModel();
  paper_id = '';

  /**
   * Whether or not this QR code was printed off and claimed.
   */
  claimed = false;

  code_url: string = '';
  code_branded_url: string = '';

  account: AccountModel = new AccountModel();

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          switch ( key ) {
            case 'site':
              this.site.apply(data[key]);
              break;
            default:
              this[key] = data[key];
          }
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.feature = 'Access Control';
    this.type = 'In/Out';
    this.name = '';
    this.message = '';
    this.site_id = 0;
    this.parent_id = 0;
    this.site = new SiteModel();
    this.paper_id = '';
    this.claimed = false;
    this.code_url = '';
    this.code_branded_url = '';
  }
}
