import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DirectivesModule } from '../directives/directives.module';
import { CoursesEditComponent } from './courses-edit/courses-edit.component';
import { CoursesModulesEditComponent } from './courses-modules/courses-modules-edit/courses-modules-edit.component';
import { CoursesModulesQuestionsEditComponent } from './courses-modules/courses-modules-questions/courses-modules-questions-edit/courses-modules-questions-edit.component';
import { CoursesModulesQuestionsViewComponent } from './courses-modules/courses-modules-questions/courses-modules-questions-view/courses-modules-questions-view.component';
import { CoursesModulesQuestionsComponent } from './courses-modules/courses-modules-questions/courses-modules-questions/courses-modules-questions.component';
import { CoursesModulesSectionsEditComponent } from './courses-modules/courses-modules-sections/course-modules-sections-edit/courses-modules-sections-edit.component';
import { CoursesModulesSectionsViewComponent } from './courses-modules/courses-modules-sections/course-modules-sections-view/courses-modules-sections-view.component';
import { CoursesModulesSectionsComponent } from './courses-modules/courses-modules-sections/courses-modules-sections/courses-modules-sections.component';
import { CoursesModulesViewComponent } from './courses-modules/courses-modules-view/courses-modules-view.component';
import { CoursesModulesComponent } from './courses-modules/courses-modules/courses-modules.component';
import { CoursesRoutingModule } from './courses-routing.module';
import { CoursesViewComponent } from './courses-view/courses-view.component';
import { CoursesComponent } from './courses/courses.component';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { PipesModule } from '../pipes/pipes.module';
import { CoursesReportComponent } from './courses-report/courses-report.component';
import { CoursesReportFilterComponent } from './courses-report-filter/courses-report-filter.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {SharedModule} from "../shared/shared.module";
import {ChartsModule} from "../charts/charts.module";

@NgModule({
  declarations: [
    CoursesComponent,
    CoursesEditComponent,
    CoursesViewComponent,
    CoursesModulesComponent,
    CoursesModulesEditComponent,
    CoursesModulesViewComponent,
    CoursesModulesQuestionsComponent,
    CoursesModulesQuestionsEditComponent,
    CoursesModulesQuestionsViewComponent,
    CoursesModulesSectionsComponent,
    CoursesModulesSectionsViewComponent,
    CoursesModulesSectionsEditComponent,
    CoursesReportComponent,
    CoursesReportFilterComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        DirectivesModule,
        CoursesRoutingModule,
        EditorModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatListModule,
        MatSelectModule,
        MatTabsModule,
        MatButtonModule,
        MatTooltipModule,
        MatDialogModule,
        MatBadgeModule,
        MatSlideToggleModule,
        PipesModule,
        FontAwesomeModule,
        SharedModule,
        ChartsModule
    ],
  entryComponents: [
    SitesSelectorComponent,
    CoursesEditComponent,
    CoursesViewComponent,
    CoursesModulesEditComponent,
    CoursesModulesViewComponent,
    CoursesModulesQuestionsEditComponent,
    CoursesModulesQuestionsViewComponent,
    CoursesModulesSectionsViewComponent,
    CoursesModulesSectionsEditComponent,
    CoursesReportComponent,
    CoursesReportFilterComponent
  ]
})
export class CoursesModule {}
