import { Component, Inject, OnInit, Optional, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { HazardsEditComponent } from 'src/app/hazards/hazards-edit/hazards-edit.component';
import { RepoSearchComponent } from 'src/app/shared/repo-search/repo-search.component';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { HazardousSubstanceModel } from '../../models/hazardous-substance.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { FilesRemoveComponent } from 'src/app/files/files-remove/files-remove.component';
import { FileService } from 'src/app/shared/file.service';
import {PpeSelectorComponent} from "../../shared/ppe-selector/ppe-selector.component";
import * as moment from "moment";

@Component({
  selector: 'app-hazardous-substances-edit-dialog',
  templateUrl: './hazardous-substances-edit-dialog.component.html',
  styleUrls: ['./hazardous-substances-edit-dialog.component.scss']
})
export class HazardousSubstancesEditDialogComponent implements OnInit {

  hazardous_substance: HazardousSubstanceModel = new HazardousSubstanceModel();

  search = '';

  newFiles: FileList;

  inProgress: boolean = false;

  // The current date for min expiry date selection.
  currentDate: Date = moment().toDate();

  // The selected expiry date object.
  selectedExpiryDate: any = null;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    public renderer: Renderer2,
    @Optional() public dialogRef: MatDialogRef<HazardsEditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private fileService: FileService
  ) {}

  ngOnInit() {
    // get the site id
    this.hazardous_substance.id = Number(this.route.snapshot.params['hazardous_substance_id']);

    // Check if we need to load a hazardous substance.
    if ( this.hazardous_substance.id ) {
      this.find();
    } else {
      // Used for Create From feature
      if(this.dialogData) {
        // "create_from_data" will only be present when the hazardous substance is copied from the site hazardous substances register.
        if(this.dialogData.create_from_data) {
          this.createHazardousSubstance(this.dialogData.create_from_data, this.dialogData.site_id);
        } else {
          this.createHazardousSubstance(this.dialogData, 0);
        }
      }
    }
  }

  // Used for Create From feature
  createHazardousSubstance(hazardousSubstance: HazardousSubstanceModel, site_id = 0) {
    // Temporarily store the hazardous substance data.
    this.hazardous_substance.apply(hazardousSubstance);

    // Set the current expiry date if it was previously set.
    if ( this.hazardous_substance.sds_expires_at ) {
      this.selectedExpiryDate = moment.unix(this.hazardous_substance.sds_expires_at);
    }

    if( site_id ) {
      this.hazardous_substance.site_ids.push(site_id);
    }
  }

  onRemoveFiles() {
    this.utils.showComponentDialog(
      FilesRemoveComponent,
      this.hazardous_substance.files,
      { width: '700px' },
      () => this.find()
    );
  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.hazardous_substance.site_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.hazardous_substance.site_ids = results;
        }

        // TBD: refresh a list of selected sites?
      }
    );
  }

  async onSubmit(form: NgForm) {
    // Check if the submitted form is valid.
    if ( !form.valid ) {
      alert('Please make sure you enter all fields with valid information.');
      return;
    }

    // Check if there is another API request in progress.
    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;

    // Prepare files to be associated with the hazardous substance.
    const createdFiles = await this.fileService.createManyFromForm(
      this.newFiles
    );
    this.hazardous_substance.files = createdFiles;

    // Prepare the SDS expiry date.
    this.hazardous_substance.sds_expires_at = this.selectedExpiryDate ? this.selectedExpiryDate.unix() : null;

    // Check if we should create a new or update an existing hazardous substance.
    if (this.hazardous_substance.id) {
      this.update();
    } else {
      // Set the site ids when the hazardous substance is cloned.
      if ( this.dialogData && !this.dialogData.create_from_data ) {
        this.hazardous_substance.site_ids.push(this.dialogData['site_id']);
      }
      this.create();
    }
  }

  getRiskAssessmentColorChange(likelihood: number, severity: number) {
    const riskAssessment = this.utils.getRiskAssessmentText(
      likelihood,
      severity
    );

    if (['Very Low', 'Low'].find((value) => value === riskAssessment)) {
      return 'success';
    }

    if (['Moderate'].find((value) => value === riskAssessment)) {
      return 'warning';
    }

    if (['High', 'Critical'].find((value) => value === riskAssessment)) {
      return 'danger';
    }

    return 'danger';
  }

  onSearchRepository() {
    this.utils.showComponentDialog(
      RepoSearchComponent,
      {
        selected: [],
        multiple: false,
        repo: 'hazardous_substances',
        display_fields: ['substance', 'ira', 'rra']
      },
      {
        width: '1024px'
      },
      (results) => {
        // is something selected
        if (typeof results !== 'undefined') {
          // remove the id from data
          results.id = null;

          this.hazardous_substance.apply(results);
        }
      }
    );
  }

  onRemove() {
    this.utils.showModal(
      'Remove Hazardous Substance',
      'Are you sure you want to remove this hazardous substance?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'hazardous_substances/' + this.hazardous_substance.id,
          {},
          {},
          (response) => {
            this.utils.showToast('The hazardous substance was removed');
            this.router.navigate(['hazardous_substances']);
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  onCancel() {
    this.dialogRef.close();
  }

  private find(): void {
    this.api.laravelApiRequest('get', 'hazardous_substances/' + this.hazardous_substance.id, {}, {}, (response) => {
      // Get and store the hazardous substance.
      this.hazardous_substance.apply(response.data);

      // Extract the PPE ids.
      this.hazardous_substance.ppe_ids = this.hazardous_substance.ppe.map((ppe) => ppe.id);

      // Set the current expiry date if it was previously set.
      if ( this.hazardous_substance.sds_expires_at ) {
        this.selectedExpiryDate = moment.unix(this.hazardous_substance.sds_expires_at);
      }
    },
    (error) => {
      this.utils.showModal('Error', error.message);
    });
  }

  private create(): void {
    this.api.laravelApiRequest(
      'post',
      'hazardous_substances',
      this.hazardous_substance,
      {},
      (response) => {
        this.utils.showToast('The hazardous substance was created.');

        this.hazardous_substance.id = response.data.id;

        if (this.dialogData) {
          this.inProgress = false;
          this.dialogRef.close();
        } else {
          this.inProgress = false;
          this.router.navigate(['/hazardous_substances']);
        }
      },
      (error) => {
        this.utils.showModal('Error', error.message);
        this.inProgress = false;
      }
    );
  }

  private update(): void {
    this.api.laravelApiRequest(
      'put',
      'hazardous_substances/' + this.hazardous_substance.id,
      this.hazardous_substance,
      {},
      (response) => {
        this.utils.showToast('The hazardous substance was updated.');
        this.inProgress = false;
        this.newFiles = null;
        this.find();
      },
      (error) => {
        this.utils.showModal('Error', error.message);
        this.inProgress = false;
      }
    );
  }

  /**
   * Change the unit type based on the Hazardous Substance type.
   * @param hazardous_substance_type
   */
  onChangeUnitType(hazardous_substance_type: string) {
    this.hazardous_substance.hazardous_substance_unit_type = this.utils.getHazardousSubstanceUnitType(hazardous_substance_type);
  }

  /**
   * Open the PPE selector to select new PPE records to associate the hazardous substance with.
   */
  onManagePPE() {
    this.utils.showComponentDialog(PpeSelectorComponent, {
      multiple: true,
      selected: this.hazardous_substance.ppe_ids
    })
      .then((response) => {
        if ( typeof response != 'undefined' ) {
          this.hazardous_substance.ppe_ids = response;
        }
      })
  }
}
