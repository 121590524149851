import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../shared/utils.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  constructor(public utils: UtilsService) {}

  ngOnInit() {}
}
