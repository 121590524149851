import { Injectable } from '@angular/core';
import { ItemPivotModel } from '../items/item-pivot.model';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../models/api-response.model';
import { PrequalificationModel } from '../models/prequalification.model';
import { HazardModel } from '../models/hazard.model';
import {UserModel} from "../models/user.model";

@Injectable({
  providedIn: 'root'
})
export class NoauthService {
  private readonly resourceUrl = 'noauth';

  constructor(private api: ApiService) {}

  private createFile(file: File, hash: string) {
    const form = new FormData();
    form.append('file', file, file.name);
    return this.api
      .laravelApiObservable('post', this.resourceUrl + `/files/${hash}`, form)
      .toPromise();
  }

  public async createFiles(files: FileList, hash: string) {
    if (!files || !files.length) { return []; }
    const requests = [];
    for(let i = 0; i < files.length; i++) {
      requests.push(this.createFile(files[i], hash));
    }
    const responses = await Promise.all(requests);
    const createdFiles = responses.map(response => response.data);
    return createdFiles;
  }

  public updateItemPivot(
    id: number,
    hash: string,
    pivot: ItemPivotModel
  ): Promise<ApiResponseModel<void>> {
    return this.api
      .laravelApiObservable(
        'put',
        this.resourceUrl + `/pivot/${id}/${hash}`,
        pivot
      )
      .toPromise();
  }

  public findPrequal(
    hash: string
  ): Observable<ApiResponseModel<PrequalificationModel>> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl + `/prequalifications/${hash}`
    );
  }

  public completePrequal(hash: string): Observable<ApiResponseModel<void>> {
    return this.api.laravelApiObservable(
      'post',
      this.resourceUrl + `/prequalifications/${hash}`
    );
  }

  public findHazard(hash: string): Observable<ApiResponseModel<HazardModel>> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl + `/hazards/${hash}`
    );
  }

  public verifyHazard(hash: string): Observable<{}> {
    return this.api.laravelApiObservable(
      'put',
      this.resourceUrl + `/hazards/${hash}/verify`
    );
  }

  public unVerifyHazard(hash: string): Observable<{}> {
    return this.api.laravelApiObservable(
      'put',
      this.resourceUrl + `/hazards/${hash}/un-verify`
    );
  }

  public notifyHazard(hash: string): Observable<{}> {
    return this.api.laravelApiObservable(
      'post',
      this.resourceUrl + `/hazards/${hash}/notify`
    );
  }

  public findUser(
    hash: string,
    eager_load: boolean
  ): Observable<ApiResponseModel<UserModel>> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl + `/users/${hash}`,
      null,
      {},
      {eager_load: eager_load}
    );
  }
}
