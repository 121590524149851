import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {tap} from "rxjs/operators";
import {TaskAnalysesRevisedWorkflowHazardModel} from "../../models/task-analyses-revised-workflow-hazard.model";
import {ApiDataSource} from "../../utils/api-data-source";
import {TaskAnalysesRevisedWorkflowsHazardsEditComponent} from "../task-analyses-revised-workflows-hazards-edit/task-analyses-revised-workflows-hazards-edit.component";
import {TaskAnalysesRevisedWorkflowsHazardsViewComponent} from "../task-analyses-revised-workflows-hazards-view/task-analyses-revised-workflows-hazards-view.component";
import {HazardsSelectorComponent} from "../../hazards/hazards-selector/hazards-selector.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-task-analyses-revised-workflows-hazards',
  templateUrl: './task-analyses-revised-workflows-hazards.component.html',
  styleUrls: ['./task-analyses-revised-workflows-hazards.component.scss']
})
export class TaskAnalysesRevisedWorkflowsHazardsComponent implements OnInit {

  @Input() private taRevisedId: number;
  @Input() private taRevisedWorkflowId: number;
  @Input() public taRevisedPath: string;

  path: string;

  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'status',
    'ira',
    'rra',
    'created_by_user',
    'date_created',
    'reviewer_date',
    'actions'
  ];

  dataSource = new TaskAnalysesRevisedWorkflowsHazardsDataSource(this.app, this.api);
  ta_revised: TaskAnalysesRevisedModel = new TaskAnalysesRevisedModel();

  // the paginator and sorter
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    public router: Router,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<TaskAnalysesRevisedWorkflowsHazardsComponent>
  ) { }

  ngOnInit() {
    // get the ta revised workflow id
    this.dataSource.ta_revised_workflow_id = this.dialogData.ta_revised_workflow.id;

    // get the ta revised id
    this.dataSource.ta_revised_id = this.dialogData.ta_revised_id;

    // get sites path edit/view
    this.path = this.dialogData.path;

    if(this.dataSource.ta_revised_id) {
      this.dataSource.getData();
      this.findTa(this.dataSource.ta_revised_id);
    } else {
      this.redirectBack();
    }
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData();
    })).subscribe();
  }

  private redirectBack() {
      this.router.navigate([`/task-analyses-revised/`]);
  }

  private findTa(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {})
      .then((response) => {
        this.ta_revised = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onView(ta_revised_workflow_hazard: TaskAnalysesRevisedWorkflowHazardModel) {
    this.utils.showComponentDialog(
      TaskAnalysesRevisedWorkflowsHazardsViewComponent,
      {
        ta_revised_workflow_hazard: ta_revised_workflow_hazard,
        ta_revised_id: this.dataSource.ta_revised_id,
        ta_revised_workflow_id: this.dataSource.ta_revised_workflow_id,
        path: this.path
      },
      { width: '700px' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      }
    );
  }

  onAdd() {
    this.onEdit(new TaskAnalysesRevisedWorkflowHazardModel());
  }

  onEdit(ta_revised_workflow_hazard: TaskAnalysesRevisedWorkflowHazardModel) {
    this.dialog
      .open(TaskAnalysesRevisedWorkflowsHazardsEditComponent, {
        width: '1024px',
        data: {
          ta_revised_workflow_hazard: ta_revised_workflow_hazard,
          ta_revised_id: this.dataSource.ta_revised_id,
          ta_revised_workflow_id: this.dataSource.ta_revised_workflow_id,
          path: this.path
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if(result == "version") {
          this.dialogRef.close(true);
        }

        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onSelectHazards() {
    this.utils.showComponentDialog(
      // AccountSitesHazardsSelectorComponent, // All Account Sites Hazards Selector
      HazardsSelectorComponent, // All Account Hazard Templates Selector
      {
        // selected: [],
        multiple: true
        // selectedAccountId: this.app.account.id
      },
      {
        width: '1024px'
      },
      // (results: number[]) => {
      //   if (!results) { return; }
      //   this.onCopyHazards(results.length ? results.join(',') : '');
      // }
    (results) => {
        if (typeof results !== 'undefined') {
          this.onCopyHazards(results.length ? results.join(',') : '');
        }
      }
    );
  }

  onCopyHazards(selected_hazard_ids) {
    this.api.makeRequest('put', `v2/task-analyses-revised/${this.dataSource.ta_revised_id}/workflows/${this.dataSource.ta_revised_workflow_id}/hazards-copy`, [], {
      selected_hazard_ids: selected_hazard_ids,
      account_sites: true
    })
      .then((response) => {
        this.dataSource.getData(true);
        this.utils.showToast(this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site') + ' Hazards were copied.');
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onRemove(id: number) {
    this.utils.showModal('Delete TA/JSA/SWMS Template Workflow Step Hazard', 'Are you sure you want to delete the TA/JSA/SWMS Template Workflow Step Hazard?', () => {
      this.continueOnRemoveSelected([id]);
    });
  }

  onRemoveSelected() {
    this.utils.showModal('Delete TA/JSA/SWMS Template Workflow Step Hazard', 'Are you sure you want to delete the selected TA/JSA/SWMS Template Workflow Step Hazards?', () => {
      this.continueOnRemoveSelected(this.dataSource.selection.selected);
    });
  }

  continueOnRemoveSelected(ids: number[] = []) {
    this.api.makeRequest('delete', `v2/task-analyses-revised/${this.dataSource.ta_revised_id}/workflows/${this.dataSource.ta_revised_workflow_id}/hazards/${ids.join(',')}`, [], {})
      .then(() => {
        this.utils.showToast('The TA/JSA/SWMS Template Workflow Step Hazard(s) were deleted.');
        this.dataSource.selection.clear();
      })
      .finally(() => {
        this.dataSource.getData();
      });
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close(true);
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class TaskAnalysesRevisedWorkflowsHazardsDataSource extends ApiDataSource {
  order_by = 'id';
  order = 'desc';
  searchBy = 'name';

  ta_revised_id = 0;
  ta_revised_workflow_id = 0;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards`, resetOffset, {
      searchBy: this.searchBy
    });
  }
}
