import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { Observable, of } from 'rxjs';
import { ApiResponseModel } from '../models/api-response.model';
import { IncidentWitnessModel } from './incident-witness.model';

@Injectable({
  providedIn: 'root'
})
export class IncidentWitnessService {
  private readonly resourceUrl = 'incident-witnesses';

  constructor(private api: ApiService) {}

  public find(id: number): Observable<ApiResponseModel<IncidentWitnessModel>> {
    return this.api.laravelApiObservable('get', this.resourceUrl + `/${id}`);
  }

  public create(
    witness: IncidentWitnessModel
  ): Observable<ApiResponseModel<IncidentWitnessModel>> {
    return this.api.laravelApiObservable('post', this.resourceUrl, witness);
  }

  public update(witness: IncidentWitnessModel): Observable<{}> {
    return this.api.laravelApiObservable(
      'put',
      this.resourceUrl + `/${witness.id}`,
      witness
    );
  }

  public remove(ids: number[]): Observable<{}> {
    return this.api.laravelApiObservable(
      'delete',
      this.resourceUrl + '/' + ids.join(',')
    );
  }
}
