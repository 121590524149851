<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">Safety Managers</span>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">

      <ng-container *ngIf="enableEditing">
        <button mat-raised-button color="primary" (click)="onManageSiteSafetyManagers()" matTooltip="Add new safety managers." >
          <mat-icon>add</mat-icon>
          Change Safety Managers
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>

          <button mat-menu-item (click)="onComposeNewMessage()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>send</mat-icon>
            Send Message
          </button>

          <button mat-menu-item (click)="onRemoveSelectedSiteSafetyManagers($event)" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>link_off</mat-icon> Remove Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to unlink or send messages to selected safety managers." style="min-width: 120px" appMarginLeft>
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>
      </ng-container>

      <span class="mr-auto"></span>

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (ngSubmit)="dataSource.getData(true)">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search"/>

        <button mat-raised-button class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0" color="secondary" (click)="dataSource.getData(true)">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">

    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="contact_person" matSortDirection="asc" matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            *ngIf="enableEditing">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)"
            *ngIf="enableEditing">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Employer -->
      <ng-container matColumnDef="employer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Employer
        </th>
        <td mat-cell *matCellDef="let element">{{ element.employer.name }}</td>
      </ng-container>

      <!-- Full Name -->
      <ng-container matColumnDef="contact_person">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Full Name
        </th>
        <td mat-cell *matCellDef="let element">
          <a (click)="onUserPublicView(element.hash)"
             matTooltip="View User Public Profile">
            {{ element.contact_person }}
          </a>
        </td>
      </ng-container>

      <!-- Email Address -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <!-- Job Title -->
      <ng-container matColumnDef="trade">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Title</th>
        <td mat-cell *matCellDef="let element">{{ element.trade || '-' }}</td>
      </ng-container>

      <!-- Contact Number -->
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }} </th>
        <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
      </ng-container>

      <!-- Row Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button type="button" mat-button (click)="onComposeNewMessage(element.id)" matTooltip="Send a message to this safety manager." matTooltipPosition="above">
            Send Message
          </button>

          <button type="button" mat-button (click)="onRemoveSiteSafetyManager($event, element.id)" *ngIf="enableEditing" matTooltip="Remove this safety manager." matTooltipPosition="above">
            Remove
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
