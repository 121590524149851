import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../shared/utils.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {
  constructor(public utils: UtilsService) {}

  ngOnInit() {}
}
