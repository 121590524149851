<input #fileInput type="file" multiple (change)="changeFiles($event)" hidden />

<button mat-raised-button color="secondary"
  [matBadge]="'' + (selectedFilesCount ? selectedFilesCount : '')" (click)="onTriggerFileSelector($event)"
  matTooltip="Pick files to be uploaded with the form.&#13;Allowed extensions: doc,pdf,docx,zip,jpg,jpeg,bmp,png,gif,xls,xlsx,eml,&#13;csv,txt,mp3,m4a&#13;Maximum file size allowed (24MB)"
  matTooltipClass="files-tooltip"
  type="button"
  *ngIf="raisedButton; else showIconButton">
  <mat-icon>insert_drive_file</mat-icon>
  Add File
</button>

<ng-template #showIconButton>
  <button mat-icon-button [matBadge]="'' + (selectedFilesCount ? selectedFilesCount : '')" [attr.matBadge]="selectedFilesCount > 0"
    matBadgePosition="before" type="button" (click)="onTriggerFileSelector($event)"
    matTooltip="Pick files to be uploaded with the form.&#13;Allowed extensions: doc,pdf,docx,zip,jpg,jpeg,bmp,png,gif,xls,xlsx,eml,&#13;csv,txt,mp3,m4a&#13;Maximum file size allowed (24MB)"
    matTooltipClass="files-tooltip">
    <mat-icon>insert_drive_file</mat-icon>
  </button>
</ng-template>
