<div mat-dialog-title>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5>Task Viewer</h5>
      </div>
      <div class="col">
        <button mat-icon-button class="float-right" mat-dialog-close>
          <mat-icon color="secondary">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="Task Details">
      <app-actions-view [action]="data.action"></app-actions-view>
    </mat-tab>

    <mat-tab label="Notes">
      <app-actions-notes [action]="data.action"></app-actions-notes>
    </mat-tab>

    <mat-tab label="Files" *ngIf="data.action?.files?.length">
      <app-files-list [files]="data.action.files"></app-files-list>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
