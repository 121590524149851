<form #f="ngForm" (ngSubmit)="onSubmit(f)">
  <div mat-dialog-title>
    <h5>Emergency Response Plan Form</h5>

    <button
      type="button"
      (click)="onClose()"
      mat-raised-button
      *ngIf="dialogData"
      color="secondary"
      class="float-right">
      Close
    </button>
  </div>

  <mat-dialog-content>
    <div class="container-fluid" appPadding>
      <div class="form-container">
        <mat-form-field>
          <input
            matInput
            placeholder="Emergency Response Plan"
            [(ngModel)]="erp.name"
            name="name"
            type="text"
            #name
            required/>
          <mat-error *ngIf="!name.valid">
            Please enter the name of the emergency response plan.
          </mat-error>
        </mat-form-field>
      </div>

      <button
        type="submit"
        mat-raised-button
        [disabled]="!f.valid"
        color="primary"
        class="save-button"
        appMarginRight>
        Save
      </button>

      <app-files-add (change)="newFiles = $event" appMarginRight></app-files-add>

      <button
        mat-raised-button
        (click)="onRemoveFiles()"
        color="secondary"
        [matBadge]="'' + (erp.files?.length)"
        [disabled]="!erp.files?.length"
        type="button"
        class="remove-files">
        <mat-icon>open_in_browser</mat-icon>
        Manage Files
      </button>
    </div>
  </mat-dialog-content>
</form>
