<div mat-dialog-title class="p-3">
  <h5>
    View Message
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
</div>

<mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="Message">

      <ng-container *ngIf="message.date_modified">
        <b>Date</b>
        <p>{{ (message.date_modified * 1000) | timezoneConvertMedium }}</p>
      </ng-container>

      <ng-container *ngIf="message.reply_to.id">
        <b>Sender</b>
        <p>
          {{ message.reply_to.contact_person }} <br>
          {{ message.reply_to.employer.name }}
        </p>
      </ng-container>

      <ng-container *ngIf="message.subject">
        <b>Subject</b>
        <p>{{ message.subject }}</p>
      </ng-container>

      <div *ngIf="message.message" [innerHTML]="message.message"></div>

    </mat-tab>

    <mat-tab label="Attachments" *ngIf="message.files.length > 0">

      <mat-list>
        <div class="row p-0 m-0">
          <mat-list-item class="col-4" *ngFor="let file of message.files; let i = index">
            <img matListAvatar class="rounded-0" (click)="onOpenUrl(file.url)" [src]="file.url" [alt]="file.name">
            <div mat-line class="text-wrap" style="cursor: pointer;" (click)="onOpenUrl(file.url)"> {{ file.name }} </div>
            <div mat-line> {{ (file.date_created * 1000) | timezoneConvertMedium }}
            </div>
            <div mat-line>  </div>
          </mat-list-item>
        </div>
      </mat-list>

    </mat-tab>

    <mat-tab label="Recipients">

      <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <span class="mr-auto"> </span>

          <form class="form-inline my-2 my-lg-0" (ngSubmit)="userListDataSource.getData(true)">
            <input
              class="form-control mr-sm-2" type="search"
              [(ngModel)]="userListDataSource.search" name="search" placeholder="Search"
              aria-label="Search" id="search-input"
              matTooltip="Search for users by name or employer." />
          </form>

          <button mat-raised-button color="secondary"
            (click)="userListDataSource.getData(true)" appMarginRight id="search-button"
            matTooltip="Click this button to run the current search.">
            <mat-icon>search</mat-icon> Search
          </button>
        </nav>

        <div class="mat-elevation-z8 scroll-box">
          <mat-paginator #paginator1 [length]="userListDataSource?.total | async" [pageSize]="userListDataSource.limit" [pageSizeOptions]="userListDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

          <table mat-table [dataSource]="userListDataSource">

            <ng-container matColumnDef="contact_person">
              <th mat-header-cell *matHeaderCellDef>
                Full Name
              </th>
              <td mat-cell *matCellDef="let user">
                {{ user.contact_person }}
              </td>
            </ng-container>

            <ng-container matColumnDef="employer">
              <th mat-header-cell *matHeaderCellDef>
                Employer
              </th>
              <td mat-cell *matCellDef="let user">
                {{ user.employer.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="is_read">
              <th mat-header-cell *matHeaderCellDef>
                Opened
              </th>
              <td mat-cell *matCellDef="let user">
                {{ user.pivot.is_read ? 'Yes' : 'No' }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="userListHeaders"></tr>
            <tr mat-row *matRowDef="let row; columns: userListHeaders"></tr>
          </table>

          <mat-paginator #paginator2 [length]="userListDataSource?.total | async" [pageSize]="userListDataSource.limit" [pageSizeOptions]="userListDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
        </div>
      </div>

    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

