import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-employees-notifications-tabs',
  templateUrl: './employees-notifications-tabs.component.html',
  styleUrls: ['./employees-notifications-tabs.component.scss']
})
export class EmployeesNotificationsTabsComponent implements OnInit {

  // The user id.
  user_id: number;

  constructor(
    public app: AppService,
    public utils: UtilsService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Get the user id from the route params.
    this.user_id = Number(this.route.parent.snapshot.params['user_id']);
  }
}
