import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NoauthService} from "../../shared/noauth.service";
import {UserModel} from "../../models/user.model";
import {UtilsService} from "../../shared/utils.service";

@Component({
  selector: 'app-noauth-user-public-profile',
  templateUrl: './noauth-user-public-profile.component.html',
  styleUrls: ['./noauth-user-public-profile.component.scss']
})
export class NoauthUserPublicProfileComponent implements OnInit {

  user = new UserModel();
  hash: string;

  constructor(
    private route: ActivatedRoute,
    private noauthService: NoauthService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    this.hash = this.route.snapshot.params['hash'];
    this.find();
  }

  find() {
    this.noauthService
      .findUser(this.hash, true)
      .subscribe((response) => {
        this.user = response.data;
      }, (error) => {
        this.utils.showModal('Error Message', 'Sorry, we could not retrieve the user’s profile. Please try again later.');
        this.utils.redirectPage(['/sign-in']);
      });
  }

}
