<form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
  <div class="card">
    <div class="card-body ">
      <div class="container-fluid">

        <div class="row">
          <div class="col form-container">
            <mat-form-field>
              <input
                matInput
                placeholder="Address / Address Lookup"
                [(ngModel)]="site.location"
                name="location"
                id="location"
                #location
                type="text"
                required />
              <mat-error *ngIf="!location.valid">Please enter a physical address.</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col">

            <small>
              Use the map below to draw a geofence. The default max geofence size is 50000 m2.
            </small>

            <div #map id="map" appMarginTop></div>

            <small><b>Allowed Geofence Size:</b> {{ site.max_geofence_size }} &#13217; | <b>Current Size:</b> {{ site.geofence_size | number: '1.0-0' }} &#13217;</small>

            <div class="alert alert-danger" role="alert" *ngIf="site.geofence_size > site.max_geofence_size">
              <small>
                Please reduce the size of the Geofence. <br>
                Contact {{ utils.getAppName() }} at <a [href]="'mailto:' + utils.getSupportEmailAddress()" target="_blank">{{ utils.getSupportEmailAddress() }}</a> for assistance with a larger geofence. <br>
                Draw a smaller geofence and click save before contacting {{ utils.getAppName() }}.
              </small>
            </div>

          </div>
        </div>

        <div class="row">
          <div class="col">

            <button
              mat-raised-button
              appMarginTop
              [disabled]="!f.valid || inProgress || site.geofence_size > site.max_geofence_size"
              (click)="onSubmit(f)"
              type="button"
              color="primary"
              class="save-button mr-3">
              <mat-icon>save</mat-icon> Save
            </button>

            <button
              mat-raised-button
              appMarginTop
              type="button"
              (click)="resetMapControls()"
              color="secondary">
              <mat-icon>restore</mat-icon> Reset Map
            </button>

          </div>
        </div>

      </div>
    </div>
  </div>
</form>
