import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from '../app.service';
import { ApiService } from '../shared/api.service';
import { UtilsService } from '../shared/utils.service';
import { CustomDataSource } from '../utils/custom-data-source';
import { ActionModel } from './action.model';
import { ActionsService } from './actions.service';
import { ActionsEditComponent } from './actions-edit/actions-edit.component';
import { ActionsViewTabsComponent } from './actions-view-tabs/actions-view-tabs.component';
import { ActionsFilterComponent } from "./actions-filter/actions-filter.component";
import { ActionsUploadDialogComponent } from './actions-upload-dialog/actions-upload-dialog.component';
import {UserPublicProfileComponent} from "../shared/user-public-profile/user-public-profile.component";
import {ChartsUtilsService} from "../charts/charts-utils.service";

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new ActionsDataSource(this.app, this.api);
  displayedColumns = [
    'select',
    'id',
    'name',
    'users',
    'priority',
    'completed',
    'date_created',
    // 'date_created_UTC',
    'due',
    // 'due_UTC',
    'buttons'
  ];

  constructor(
    private actionsService: ActionsService,
    public utils: UtilsService,
    private app: AppService,
    private api: ApiService,
    private dialog: MatDialog,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit() {
    this.dataSource.getData(false);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.dataSource.sort_by = "date_created";
      } else if (this.sort.active === "due_UTC") {
        this.dataSource.sort_by = "due";
      } else {
        this.dataSource.sort_by = this.sort.active;
      }

      this.dataSource.getData(false);
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  add() {
    this.edit(new ActionModel());
  }

  edit(action: ActionModel) {
    this.dialog
      .open(ActionsEditComponent, {
        width: '900px',
        data: action
      })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }

  view(action: ActionModel) {
    this.utils.showComponentDialog(ActionsViewTabsComponent, {
      action: action
    });
  }

  update(action: ActionModel) {
    action.completed
      ? this.actionsService.complete(action.id).subscribe(() => {
        this.utils.showToast(`Completed task: "${action.name}"`);
        this.dataSource.getData();
      })
      : this.actionsService.open(action.id).subscribe(() => {
        this.utils.showToast(`Opened task: "${action.name}"`);
        this.dataSource.getData();
      });
  }

  onRemoveSelected() {
    this.utils.showModal(
      'Remove Tasks',
      `Are you sure you want to remove ${this.dataSource.selection.selected.length} tasks?`,
      () => {
        this.remove(this.dataSource.selection.selected);
      }
    );
  }

  onRemove(action: ActionModel) {
    this.utils.showModal(
      'Remove Task',
      `Are you sure you want to remove "${action.name}"?`,
      () => {
        this.remove([action.id]);
      }
    );
  }

  private remove(ids: number[]) {
    this.actionsService.remove(ids).subscribe(() => {
      this.utils.showToast('Tasks have been removed');
      this.dataSource.selection.clear();
      this.dataSource.getData(false);
    });
  }

  onFilter() {
    this.utils.showComponentDialog(
      ActionsFilterComponent,
      {
        site_ids: this.dataSource.site_ids,
        assigned_users: this.dataSource.user_ids,
        priority: this.dataSource.priority,
        status: this.dataSource.status,
        date_range: this.dataSource.due_date
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.site_ids = results.site_ids ? results.site_ids : this.dataSource.site_ids;
          this.dataSource.user_ids = results.assigned_users ? results.assigned_users : this.dataSource.user_ids;
          this.dataSource.priority = typeof results.priority != 'undefined' ? results.priority : this.dataSource.priority;
          this.dataSource.status = typeof results.status != 'undefined' ? results.status : this.dataSource.status;
          this.dataSource.due_date = results.date_range ? results.date_range : this.dataSource.due_date;
          this.dataSource.getData();
        }
      }
    );
  }

  onUpload() {
    this.utils.showComponentDialog(ActionsUploadDialogComponent, {}, {width: '1440px'},
      (results) => {
      })
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

  /**
   * Export the all or the selected actions to a PDF.
   * @param type The type of export. This can be pdf, csv or xlsx.
   * @param id The id of the task to export.
   */
  onExport(type: string = 'pdf', id?: number) {
    // Get the selected ids.
    const ids: number[] = id ? [id] : this.dataSource.selection.selected;
    // Make the request to the API.
    this.api.makeRequest('get', `actions/export/${type}` + (ids.length > 0 ? ('/' + ids.join(',')) : ''), {}, {}, {
      searchBy: this.dataSource.searchBy,
      search: this.dataSource.search,
      site_ids: this.dataSource.site_ids.length ? this.dataSource.site_ids.join(',') : '',
      user_ids: this.dataSource.user_ids.length ? this.dataSource.user_ids.join(',') : '',
      priority: this.dataSource.priority ? this.dataSource.priority : '',
      status: this.dataSource.status ? this.dataSource.status : '',
      due_date_range: this.dataSource.due_date_range
    }).then((response) => {
      this.utils.showToast(response.message || 'Your export is queued in the background. You will receive an email when it is ready.');
    });
  }
}

export class ActionsDataSource extends CustomDataSource {

  sort_by = 'name';
  sort_order = 'asc';
  searchBy = 'name';

  // Filter Variables
  site_ids = [];
  user_ids = [];
  priority = "";
  status = "";
  due_date: Date[] = [];

  due_date_range = "";

  getData(resetOffset = false) {
    this.due_date_range = this.due_date && this.due_date.length > 1 ? [
      this.due_date[0].getTime() / 1000,
      this.due_date[1].getTime() / 1000
    ].join(',') : '';

    this.getDataFromLaravelAPI(
      `actions`,
      resetOffset,
      () => { },
      {
        searchBy: this.searchBy,
        site_ids: this.site_ids.length ? this.site_ids.join(',') : '',
        user_ids: this.user_ids.length ? this.user_ids.join(',') : '',
        priority: this.priority ? this.priority : '',
        status: this.status ? this.status : '',
        due_date_range: this.due_date_range
      }
    );
  }
}
