import { AuditItemModel } from '../audit-items/audit-item.model';
import { SiteModel } from '../models/site.model';
import { Auditable } from '../shared/auditable.model';
import { AccountModel } from '../models/account.model';

export class AuditsModel extends Auditable {
  id: number;
  name: string;

  /**
   * Used for updating Items of an Audit.
   */
  item_ids?: number[];

  /**
   * Contractors to associate with the Audit.
   */
  contractor_ids?: number[];

  /**
   * Contractors associated with the Audit.
   */
  contractors?: AccountModel[];

  items: AuditItemModel[];
  template: boolean;
  site: SiteModel;
  positive_comments: string;
  date_created: number;
  created_by_name?: string;

  /**
   * Unique hash used for User Public Profile of the user who created this Audit.
   */
  created_by_hash?: string;

  items_count_completed: number = 0;
  items_count: number = 0;
  completed: number = 0;
}
