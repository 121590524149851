import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/utils.service';
import { AppService } from '../../app.service';
import { EmployeeModel } from '../../models/employee.model';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import {TradeModel} from "../../admin/trades/trades.model";


@Component({
  selector: 'app-employees-edit',
  templateUrl: './employees-add.component.html',
  styleUrls: ['./employees-add.component.scss']
})
export class EmployeesAddComponent implements OnInit {

  employee: EmployeeModel = new EmployeeModel();

  inProgress = false;

  trades: TradeModel[] = [];

  // Mobile field Country Code selector values
  mobile_input_id: string = 'phone-1';
  mobile_number: string = '';
  mobile_country_code: string = '';
  mobile_dial_code: string = '';
  mobile_e164: string = '';
  mobile_error_state: boolean = true;

  constructor(
    public router: Router,
    public app: AppService,
    public utils: UtilsService,
    private dialogRef: MatDialogRef<EmployeesAddComponent>,
    private api: ApiRequestService
  ) {}

  ngOnInit() {
    // Get Unique Names for Trades
    this.getTrades();
  }

  /**
   * Get Output values from phone input component.
   */
  getOutputPhoneDetails($event) {
    if($event) {
      let phone = $event;
      this.mobile_number = phone.hasOwnProperty('number') && phone.number !== null ? phone.number : '';
      this.mobile_country_code = phone.hasOwnProperty('countryCode') && phone.countryCode !== null ? phone.countryCode : '';
      this.mobile_dial_code = phone.hasOwnProperty('dialCode') && phone.dialCode !== null ? phone.dialCode : '';
      this.mobile_e164 = phone.hasOwnProperty('e164Number') && phone.e164Number !== null ? phone.e164Number : '';
      this.mobile_error_state = phone.hasOwnProperty('errorState') && phone.errorState !== null ? phone.errorState : true;
    }
  }

  private async getTrades() {
    this.trades = await this.utils.getTrades();
  }

  onSubmit(form: NgForm) {
    // Check if the submitted form is valid
    if ( !form.valid ) {
      this.utils.showFormValidationError();
      return;
    }

    // Check Error states of phone input then continue submission
    if (this.mobile_error_state) {
      if(this.mobile_number.length < 3) {
        this.utils.showFormValidationError('A Phone number must be at least 3 characters long.');
      } else {
        this.utils.showFormValidationError();
      }
      return;
    }

    // Prevent form from being submitted multiple time in a row.
    if ( this.inProgress ) {
      return;
    }

    // Update User details with Mobile field Country Code selector values
    this.employee.mobile = this.mobile_number;
    this.employee.mobile_country_code = this.mobile_country_code;
    this.employee.mobile_dial_code = this.mobile_dial_code;
    this.employee.mobile_e164 = this.mobile_e164;

    this.inProgress = true;

    this.api.makeRequest('post', 'v2/employees', this.employee)
    .then((employee: EmployeeModel) => {
      this.utils.showToast('The ' + this.utils.getLangTerm('employees.singular', 'Employee') + ' was added.');
      this.dialogRef.close(employee);
      this.inProgress = false;
    })
    .catch((_error) => {
      this.utils.handleAPIErrors(_error);
      this.inProgress = false;
    });
  }
}
