<form #f="ngForm" (ngSubmit)="onSubmit(f)">
  <h5 mat-dialog-title>
    {{ category && category.id ? 'Update' : 'New' }} Form Category
    <button type="button" mat-icon-button class="float-right my-0" (click)="onClose()">
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>

  <div mat-dialog-content>

    <mat-form-field class="full-width mb-3">
      <mat-label>Category</mat-label>
      <input
        matInput
        placeholder="Category"
        [(ngModel)]="category.category"
        name="category"
        type="text"
        id="category"
        required
        dusk="dusk_category" />
      <mat-hint>Enter a name for your category.</mat-hint>
    </mat-form-field>

  </div>

  <div mat-dialog-actions class="d-flex justify-content-end">
    <button
      mat-raised-button
      [disabled]="!f.valid"
      type="submit"
      class="mr-1"
      color="primary"
      dusk="dusk_save_button" cdkFocusInitial>
      <mat-icon>save</mat-icon> Save
    </button>

    <button
      mat-raised-button
      [disabled]="!f.valid"
      type="button"
      color="primary"
      dusk="dusk_save_button" cdkFocusInitial (click)="onSubmit(f, true)">
      <mat-icon>save</mat-icon> Save & Close
    </button>
  </div>
</form>

