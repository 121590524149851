import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {ApiDataSource} from "../../utils/api-data-source";
import {ApiRequestService} from "../../shared/api-request.service";
import {
  NetworkedUsersSelectorComponent
} from "../../shared/networked-users-selector/networked-users-selector.component";

@Component({
  selector: 'app-sites-users-worked-on',
  templateUrl: './sites-users-worked-on.component.html',
  styleUrls: ['./sites-users-worked-on.component.scss']
})
export class SitesUsersWorkedOnComponent implements OnInit, AfterViewInit {

  // The site id to query.
  parent_id: number;
  child_id: number;
  site_id: number;

  // This list columns that should be shown.
  displayedColumns: string[] = [
    'select',
    'contact_person',
    'email',
    'mobile',
    'trade',
    'actions'
  ];

  // The data source object.
  dataSource: SitesUsersWorkedOnDataSource;

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    // public service: ContractorsService,
    public app: AppService,
    private oldApi: ApiService,
    public utils: UtilsService,
    public route: ActivatedRoute,
    private api: ApiRequestService
  ) {}

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;
      // load a multi select data source object
      this.dataSource = new SitesUsersWorkedOnDataSource(this.app, this.api, true);
      this.dataSource.site_id = this.site_id;
      // get the data only when the site id is present
      this.dataSource.getData();
    }
  }

  ngAfterViewInit() {
    // Reset the paginator when soring records.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData(true);
    });

    // Subscribe to the paginator and sorting tap events.
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   *
   * @param id the contractor id
   *
   * unlink a single contractor
   */
  onRemove(user_id: number) {
    this.removeUsers([user_id]);
  }

  /**
   * Remove selected users from the list.
   */
  onRemoveSelected() {
    this.removeUsers(this.dataSource.selection.selected);
  }

  /**
   * Open a dialog to allow the admin user to select connected users to add to the list.
   */
  onAddUsers() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      multiple: true,
      visitor_site_id: this.site_id
    })
      .then((user_ids: number[]) => {
        // Do nothing if the response is undefined.
        if ( typeof user_ids === 'undefined' ) {
          return;
        }
        // Do nothing if no users were selected.
        if ( !user_ids || user_ids.length == 0 ) {
          return;
        }
        // Make an API request to add the users.
        this.api.makeRequest('put', `v2/sites/${this.site_id}/users-worked-on`, {
          user_ids: user_ids
        })
          .then((response) => {
            // Refresh the list.
            this.dataSource.getData(true);
            // Show toast message
            this.utils.showToast(response.message);
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      });
  }

  /**
   * Remove users from the list.
   * @param user_ids
   * @private
   */
  private removeUsers(user_ids: number[]) {
    // Terminate early if no site id is present.
    if ( !this.site_id ) {
      return;
    }
    // Check if there are any users to remove.
    if ( user_ids.length === 0 ) {
      this.utils.showModal('Select Users', 'You need to select some users before they can be removed.');
      return;
    }
    // Get Confirmation
    this.utils.showModal('Remove User(s)', `Are you sure you want to remove ${user_ids.length} user(s)?`, () => {
      // Make the API request to remove the users.
      this.api.makeRequest('delete', `v2/sites/${this.site_id}/users-worked-on`, {}, {
        user_ids: user_ids.join(',')
      })
        .then((response) => {
          // Refresh the list.
          this.dataSource.getData(true);
          // Show toast message
          this.utils.showToast(`${user_ids.length} User(s) were removed.`);
          // Clear selected items.
          this.dataSource.selection.deselect(...user_ids);
        })
        .catch((errorResponse) => {
          this.utils.handleAPIErrors(errorResponse);
        });
    });
  }

  /**
   * Send a new message to selected users.
   */
  composeNewMessage() {
    // Terminate early if no users were selected.
    if ( this.dataSource.selection.selected.length == 0 ) {
      this.utils.showToast('Please select some users to send the message to.');
      return;
    }
    // Open the message editor with the users pre-selected.
    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectUserIds: this.dataSource.selection.selected
    })
    .then(() => {
      this.utils.showToast('You can view the message stats in the messages section.');
    });
  }

  /**
   * View the user's public profile.
   * @param hash
   */
  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(UserPublicProfileComponent, hash, { width: '90%' });
  }

  /**
   * Export a list of users that worked on the site.
   */
  onExport() {
    this.api.makeDownloadRequest(`v2/sites/${this.site_id}/users-worked-on`, {}, {
      ids: this.dataSource.selection.selected.join(','),
      export: 'csv'
    })
      .then((response) => {
        // Workaround for IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // Get the current date object
        const date = new Date();

        // Create object url to handle file downloads
        const data = window.URL.createObjectURL(response);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = `Users - ${date.getFullYear()} / ${(date.getMonth() + 1)} / ${date.getDate()}.csv`;
        // Initiate the download
        downloadLink.click();

        // For FireFox it is necessary to delay revoking the ObjectURL
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 300); // Minimum 300 milliseconds.
      })
      .catch((errorResponse) => {
        this.utils.showModal('Error', errorResponse.message);
      });
  }
}

export class SitesUsersWorkedOnDataSource extends ApiDataSource {
  // The site id to filter records by.
  site_id: number = 0;

  order_by: string = 'contact_person';
  order: string = 'asc';

  getData(resetOffset: boolean = false) {
    this.makeRequest( `v2/sites/${this.site_id}/users-worked-on`, resetOffset);
  }
}
