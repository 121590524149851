import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigitalSignatureMeta } from '../digital-signature/digital-signature-meta';

@Component({
  selector: 'app-digital-signature-dialog',
  templateUrl: './digital-signature-dialog.component.html',
  styleUrls: ['./digital-signature-dialog.component.scss']
})
export class DigitalSignatureDialogComponent implements OnInit {

  meta: DigitalSignatureMeta = {}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DigitalSignatureDialogComponent>
  ) { }

  ngOnInit(): void {
    // Store the signature url if it is set.
    if ( typeof this.data['signature_url'] != 'undefined' && this.data['signature_url'] ) {
      this.meta.signature_url = this.data['signature_url'];
    }
    // Store the intended signer name if it is set.
    if ( typeof this.data['intended_signer_name'] != 'undefined' && this.data['intended_signer_name'] ) {
      this.meta.user_name = this.data['intended_signer_name'];
    }
    // Store the intended signer name if it is set.
    if ( typeof this.data['user_id'] != 'undefined' && this.data['user_id'] ) {
      this.meta.user_id = this.data['user_id'];
    }
    // Store the intended signer signed_at date if it is set.
    if ( typeof this.data['signed_at'] != 'undefined' && this.data['signed_at'] ) {
      this.meta.signed_at = this.data['signed_at'];
    }
  }

  /**
   * Saves the provided file and dismisses the dialog.
   *
   *
   * @return {Promise<void>} A promise that resolves when the file is saved and the dialog is dismissed.
   * @param meta_output
   */
  onSave(meta_output: DigitalSignatureMeta): void {
    // Dismiss the dialog with the signature file.
    this.dialogRef.close(meta_output.base64_image_data);
  }

  /**
   * Dismisses the dialog with no signature file.
   *
   * @returns {Promise<void>} A Promise that resolves when the dialog is dismissed.
   */
  onClose(): void {
    // Dismiss the dialog with no signature file.
    this.dialogRef.close();
  }
}
