import { Auditable } from "../shared/auditable.model";

export class TaStepHazardModel extends Auditable {
  id = 0;
  hazard_id = 0;

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        // do not store unwanted data
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.hazard_id = 0;
  }
}
