<div mat-dialog-title class="position-relative w-100">
  <h5>Filter Messages</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close Filter Messages" mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">

  <ng-container matColumnDef="type">
    <div style="padding: 5px !important;">
      <mat-form-field style="width: 150px;" class="mx-3 mt-2">
        <mat-select placeholder="Type" [(ngModel)]="filters.type"
                    name="type"
                    matTooltip="Filter messages by type.">
          <mat-option value="">All Types</mat-option>
          <mat-option value="email">Email</mat-option>
          <mat-option value="push notification">Push Notification</mat-option>
          <mat-option value="sms">SMS</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container matColumnDef="status">
    <div style="padding: 5px !important;">
      <mat-form-field style="width: 150px;" class="mx-3 mt-2">
        <mat-select placeholder="Status" [(ngModel)]="filters.status"
                    name="status"
                    matTooltip="Filter messages by status.">
          <mat-option value="">All Statuses</mat-option>
          <mat-option value="draft">Draft</mat-option>
          <mat-option value="queued">Queued</mat-option>
          <mat-option value="in progress">In Progress</mat-option>
          <mat-option value="sent">Sent</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container matColumnDef="priority">
    <div style="padding: 5px !important;">
      <mat-form-field style="width: 150px;" class="mx-3 mt-2">
        <mat-select placeholder="Priority" [(ngModel)]="filters.priority"
                    name="priority"
                    matTooltip="Filter messages by priority.">
          <mat-option value="">All Priorities</mat-option>
          <mat-option value="low">Low</mat-option>
          <mat-option value="normal">Normal</mat-option>
          <mat-option value="high">High</mat-option>
          <mat-option value="critical">Critical</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container matColumnDef="date_filter">
    <div style="padding: 5px !important;">
      <mat-form-field style="width: 150px;" class="mx-3 mt-2">
        <mat-select placeholder="Date Filter By" [(ngModel)]="filters.date_filter"
                    name="date_filter"
                    matTooltip="Filter messages by the date it was created or scheduled at.">
          <mat-option value="all">All</mat-option>
          <mat-option value="created">Created Date</mat-option>
          <mat-option value="scheduled">Scheduled Date</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <app-date-range-picker dateRangeTitle="Created/Scheduled Date" dateRangePlaceholder="Select Date Range" dateRangeTooltip="Filter Messages Date by date range." [dateRangeInput]="filters.date_range" (dateRangeOutput)="getOutputDateRange($event)"></app-date-range-picker>

</div>

<div class="d-flex justify-content-center my-2 py-2">
  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        class="float-right"
        color="primary"
        (click)="onSaveAndClose()">
        <mat-icon>filter_list</mat-icon> Apply Filter
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        color="secondary"
        class="float-right"
        (click)="onClearAndClose()">
        <mat-icon> clear_all</mat-icon> Clear Filter
      </button>
    </div>
  </ng-container>
</div>
