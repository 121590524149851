<mat-tab-group>
  <mat-tab label="Account Configuration">
    <div class="mt-3 pl-3 pr-3">
      <form (submit)="onSave()" #f="ngForm">

        <div class="row">
          <div class="col">
            <fieldset>
              <legend><h4>Hazards & Risks</h4></legend>
              <mat-checkbox [(ngModel)]="config.hazard_verification" matTooltip="When enabled, a manager will be automatically notified for verification whenever hazards and risks are reported by users through the mobile app." name="hazard_verification">
                Enable Manager Verification for Hazards & Risks Reports
              </mat-checkbox>
              <mat-hint>When enabled, a manager will be automatically notified for verification whenever hazards and risks are reported by users through the mobile app.</mat-hint>
              <hr>
            </fieldset>
          </div>
        </div>

        <div class="row">
          <div class="col mt-2">
            <fieldset>
              <legend><h4>{{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}</h4></legend>
              <mat-checkbox [(ngModel)]="config.allow_auto_site_signout" matTooltip="Users will be automatically signed out from {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} {{ config.auto_site_signout_duration }} hours after their closing times. The duration is adjustable via the dropdown selection." name="allow_auto_site_signout">
                Enable Auto-Sign Out from {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}.
              </mat-checkbox>

              <mat-form-field style="width: 300px;">
                <mat-select
                  placeholder="Duration for Auto Sign-Out from {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}"
                  [(ngModel)]="config.auto_site_signout_duration"
                  name="auto_site_signout_duration"
                  id="auto_site_signout_duration"
                  required>
                  <mat-option *ngFor="let auto_site_signout_duration_option of auto_site_signout_duration_options" [value]="auto_site_signout_duration_option">
                    {{ auto_site_signout_duration_option }} Hours
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-hint>Users will be automatically signed out from {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} {{ config.auto_site_signout_duration }} hours after their closing times. The duration is adjustable via the dropdown selection.</mat-hint>
              <hr>
            </fieldset>
          </div>
        </div>

        <div class="row">
          <div class="col mt-2">
            <fieldset>
              <legend><h4>Notifications</h4></legend>
              <div>
                <mat-checkbox [(ngModel)]="config.induction_notifications_enabled" matTooltip="When enabled, account administrators will receive notifications as users complete their account induction process." name="induction_notifications_enabled">
                  Enable Induction Completion Notifications for Administrators
                </mat-checkbox>
                <mat-hint>When enabled, account administrators will receive notifications as users complete their account induction process.</mat-hint>
              </div>

              <div class="mt-2">
                <mat-checkbox [(ngModel)]="config.enable_site_sign_in_ack_reminders" matTooltip="Enable reminders for users to acknowledge risks 5 minutes after signing into a {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }}, if they haven't done so already." name="enable_site_sign_in_ack_reminders">
                  Enable 5-Minute Risk Acknowledgment Reminders
                </mat-checkbox>
                <mat-hint>Enable reminders for users to acknowledge risks 5 minutes after signing into a {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }}, if they haven't done so already.</mat-hint>
              </div>

              <div class="mt-2">
                <mat-checkbox [(ngModel)]="config.enable_site_sign_in_ack_managers" [disabled]="!config.enable_site_sign_in_ack_reminders" matTooltip="When enabled, managers will receive an alert 5 minutes after a user signs into a {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} but failed to acknowledge the associated risks." name="enable_site_sign_in_ack_managers">
                  Enable Manager Alerts for Unacknowledged Risks 5-Minutes After Sign-In
                </mat-checkbox>
                <mat-hint>When enabled, managers will receive an alert 5 minutes after a user signs into a {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} but failed to acknowledge the associated risks.</mat-hint>
              </div>
              <hr>
            </fieldset>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <fieldset>
              <legend><h4>Search Results</h4></legend>
              <mat-checkbox [(ngModel)]="config.include_in_search_results" matTooltip="Enable this option to make your account publicly visible in search results. This applies when account administrators search for contractors and when users search for their employers." name="include_in_search_results">
                Include Account in Search Results
              </mat-checkbox>
              <mat-hint>Enable this option to make your account publicly visible in search results. This applies when account administrators search for contractors and when users search for their employers.</mat-hint>
              <hr>
            </fieldset>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <fieldset>
              <legend><h4>Safety Plan</h4></legend>
              <mat-form-field class="mb-3">
              <textarea matInput
                        placeholder="Enter your overall Safety Plan header here."
                        [(ngModel)]="config.sssp_header"
                        name="sssp_header"
                        rows="5"
                        matTooltip="Enter your overall Safety Plan header here.">
              </textarea>
              </mat-form-field>
            </fieldset>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <button mat-raised-button color="primary" [disabled]="!f.valid || inProgress">
              <mat-icon>save</mat-icon>
              Save
            </button>
          </div>
        </div>

      </form>
    </div>
  </mat-tab>

  <mat-tab label="Account Data Synchronisation">
    <app-related-slave-accounts></app-related-slave-accounts>
  </mat-tab>
</mat-tab-group>
