<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/admin/task-analyses-revised">TA/JSA/SWMS Templates</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <span class="mr-auto">
      <button mat-raised-button color="primary" appMarginRight (click)="onAdd()" id="add"
              class="margin-right">
        <mat-icon>add</mat-icon>
        Add TA/JSA/SWMS Template
      </button>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>
        <button *ngIf="dataSource.archived == 'false'" mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>archive</mat-icon> Archive Selected
        </button>
        <button *ngIf="dataSource.archived == 'true'" mat-menu-item (click)="onRestoreSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>restore</mat-icon> Restore Selected
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect TA/JSA/SWMS templates." style="min-width: 120px">
        <mat-icon>more_vert</mat-icon>
        List Menu
      </button>
    </span>

    <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onFilter()" matTooltip="Click this button to display filter options.">
      <mat-icon>filter_list</mat-icon> Filter
    </button>

    <form tourAnchor="tour.search" class="form-inline my-2 my-lg-0" style="min-width: 325px"
          (ngSubmit)="dataSource.getData(true)">
      <input class="form-control mr-sm-2" type="search"
             [(ngModel)]="dataSource.search"
             name="search"
             aria-label="Search"
             placeholder="Search"
             matTooltip="Search for Task Analyses Templates"/>
      <button mat-raised-button class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0" color="secondary"
              (click)="dataSource.getData(false)"
              type="button"
              matTooltip="Run the current search">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>

  </div>

</nav>

<div class="mat-elevation-z8 scroll-box">
  <table mat-table [dataSource]="dataSource" style="width: 100%" matSort
         matSortActive="task_name" matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? dataSource.selection.toggle(row.id) : null"
                      [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        #
      </th>
      <td mat-cell *matCellDef="let ta_revised">
        {{ ta_revised.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="task_name">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Name
      </th>
      <td mat-cell *matCellDef="let ta_revised" class="no-padding text-wrap">
        <span>
          <strong>{{ ta_revised.task_name }}</strong><br>
          <small>{{ ta_revised.task_description  | ellipsis: [100] }}</small>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="work_procedure">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Work Procedure
      </th>
      <td mat-cell *matCellDef="let ta_revised" class="no-padding text-wrap">
        <span>
          {{ ta_revised.work_procedure }}
        </span>
      </td>
    </ng-container>

<!--    <ng-container matColumnDef="ppe">-->
<!--      <th mat-header-cell *matHeaderCellDef class="no-padding">-->
<!--        PPE-->
<!--      </th>-->
<!--      <td mat-cell *matCellDef="let ta_revised" class="no-padding text-wrap">-->
<!--        <ng-container *ngIf="ta_revised.ppe?.length">-->
<!--          <mat-chip-list aria-label="PPE selection">-->
<!--            <div style="padding: 5px 0;">-->
<!--              <mat-chip  *ngFor="let ppe of ta_revised.ppe; let i = index">{{ ppe.title }}</mat-chip>-->
<!--            </div>-->
<!--          </mat-chip-list>-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

<!--    <ng-container matColumnDef="work_permits">-->
<!--      <th mat-header-cell *matHeaderCellDef class="no-padding">-->
<!--        Work Permits-->
<!--      </th>-->
<!--      <td mat-cell *matCellDef="let ta_revised" class="no-padding text-wrap">-->
<!--        <ng-container *ngIf="ta_revised.work_permits?.length">-->
<!--          <mat-chip-list aria-label="Work Permits selection">-->
<!--            <div style="padding: 5px 0;">-->
<!--              <mat-chip *ngFor="let work_permit of ta_revised.work_permits; let i = index">{{ work_permit.title }}</mat-chip>-->
<!--            </div>-->
<!--          </mat-chip-list>-->
<!--        </ng-container>-->
<!--      </td>-->
<!--    </ng-container>-->

    <ng-container matColumnDef="created_by_user">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Created By
      </th>
      <td mat-cell *matCellDef="let ta_revised" class="no-padding">
        <span *ngIf="ta_revised?.created_by_user else showSystemCreator">
          <a (click)="onUserPublicView(ta_revised.created_by_user.hash)"
            matTooltip="View User Public Profile">
            {{ ta_revised.created_by_user.contact_person }}
          </a>
        </span>
        <ng-template #showSystemCreator>System</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Created At (UTC)
      </th>
      <td mat-cell *matCellDef="let ta_revised" class="no-padding">
        <span>
          {{ (ta_revised.date_created * 1000) | timezoneConvertUTCMedium }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Created At
      </th>
      <td mat-cell *matCellDef="let ta_revised" class="no-padding">
        <span>
          {{ (ta_revised.date_created * 1000) | timezoneConvertMedium }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let ta_revised">
        <button *ngIf="dataSource.archived == 'false'" type="button" mat-button (click)="onView(ta_revised.id)" matTooltip="View the TA/JSA/SWMS template information." matTooltipPosition="above">
          View
        </button>

        <button *ngIf="dataSource.archived == 'false'" type="button" mat-button (click)="onEdit(ta_revised)" matTooltip="Edit the TA/JSA/SWMS template information." matTooltipPosition="above">
          Edit
        </button>

        <button *ngIf="dataSource.archived == 'false'" type="button" mat-button (click)="onClone(ta_revised)" matTooltip="Clone the TA/JSA/SWMS template." matTooltipPosition="above">
          Clone
        </button>

        <button *ngIf="dataSource.archived == 'false'" type="button" mat-button (click)="onRemove(ta_revised)" matTooltip="Remove (Archive) the TA/JSA/SWMS template." matTooltipPosition="above">
          Archive
        </button>

        <button *ngIf="dataSource.archived == 'true'" type="button" mat-button (click)="onRestore(ta_revised.id)" matTooltip="Restore this TA/JSA/SWMS template." matTooltipPosition="above">
          Restore
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [length]="dataSource?.total | async"
                 [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions"
                 showFirstLastButtons>
  </mat-paginator>
</div>

