<h5 mat-dialog-title>
  Select Form Categories
  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</h5>

<div mat-dialog-content>

  <div class="d-flex justify-content-end">

    <button mat-raised-button color="primary" type="button" class="mr-2" (click)="onEdit()" matTooltip="Add a new from category to your account.">
      <mat-icon>add</mat-icon> Category
    </button>

    <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
      <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search" aria-label="Search" id="search-input" matTooltip="Search for categories by name." />

      <button mat-raised-button color="secondary" appMarginRight id="search-button" matTooltip="Run the current search">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>
  </div>

  <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

  <table mat-table [dataSource]="dataSource" matSort matSortActive="category" matSortDirection="asc" matSortDisableClear>

    <caption class="px-3">This is a list of all form categories in your account.</caption>

    <!-- Selection Toggle -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef id="th-select">
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
          matTooltip="Toggle selection of all categories in the visible list."
          *ngIf="data['multiple']">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)" matTooltip="Toggle selection of this category.">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Category ID -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-id" matTooltip="Toggle sorting by category ID.">
        #
      </th>
      <td mat-cell *matCellDef="let category">
        {{ category.id }}
      </td>
    </ng-container>

    <!-- Category -->
    <ng-container matColumnDef="category">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-category" matTooltip="Toggle sorting by category name.">
        Category
      </th>
      <td mat-cell *matCellDef="let category">
        {{ category.category }}
      </td>
    </ng-container>

    <!-- Date Created -->
    <ng-container matColumnDef="date_created">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created" matTooltip="Toggle sorting by creation date.">
        Created On
      </th>
      <td mat-cell *matCellDef="let category">
        {{ (category.date_created * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <!-- Date Modifeid -->
    <ng-container matColumnDef="date_modified">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_modified" matTooltip="Toggle sorting by the date the category was last updated on.">
        Last Updated On
      </th>
      <td mat-cell *matCellDef="let category">
        {{ (category.date_modified * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef id="th-actions"></th>
      <td mat-cell *matCellDef="let category">
        <button type="button" mat-button (click)="onEdit(category.id)" matTooltip="Update the form category.">
          Edit
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="dataSource.selection.toggle(row.id)"></tr>
  </table>

  <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <button
    mat-raised-button
    color="primary"
    type="button"
    (click)="onSelectAndClose()">
    Select & Close
  </button>
</div>
