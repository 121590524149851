import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafetyObservationsRoutingModule } from './safety-observations-routing.module';
import { SafetyObservationsComponent } from './safety-observations/safety-observations.component';
import { SafetyObservationsEditComponent } from './safety-observations-edit/safety-observations-edit.component';
import { SafetyObservationsViewComponent } from './safety-observations-view/safety-observations-view.component';
import { MatComponentsModule } from '../mat-components/mat-components.module';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SafetyObservationsViewFilesComponent } from './safety-observations-view-files/safety-observations-view-files.component';
import { UtilsModule } from '../utils/utils.module';
import { SafetyObservationsViewCorrectiveActionsComponent } from './safety-observations-view-corrective-actions/safety-observations-view-corrective-actions.component';
import { SafetyObservationsViewHazardsComponent } from './safety-observations-view-hazards/safety-observations-view-hazards.component';
import { SafetyObservationsViewIncidentsComponent } from './safety-observations-view-incidents/safety-observations-view-incidents.component';
import { SafetyObservationsViewContractorsComponent } from './safety-observations-view-contractors/safety-observations-view-contractors.component';
import { SafetyObservationsViewUsersComponent } from './safety-observations-view-users/safety-observations-view-users.component';
import { SafetyObservationsViewMessagesComponent } from './safety-observations-view-messages/safety-observations-view-messages.component';
import { ActionsModule } from '../actions/actions.module';
import { PipesModule } from '../pipes/pipes.module';
import { SafetyObservationsFilterComponent } from './safety-observations-filter/safety-observations-filter.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {SharedModule} from "../shared/shared.module";
import {ChartsModule} from "../charts/charts.module";

@NgModule({
  entryComponents: [
    SafetyObservationsEditComponent,
    SafetyObservationsViewComponent,
    SafetyObservationsFilterComponent
  ],
  declarations: [
    SafetyObservationsComponent,
    SafetyObservationsEditComponent,
    SafetyObservationsViewComponent,
    SafetyObservationsViewFilesComponent,
    SafetyObservationsViewCorrectiveActionsComponent,
    SafetyObservationsViewHazardsComponent,
    SafetyObservationsViewIncidentsComponent,
    SafetyObservationsViewContractorsComponent,
    SafetyObservationsViewUsersComponent,
    SafetyObservationsViewMessagesComponent,
    SafetyObservationsFilterComponent
  ],
    imports: [
        CommonModule,
        SafetyObservationsRoutingModule,
        FormsModule,
        BsDatepickerModule,
        MatComponentsModule,
        UtilsModule,
        ActionsModule,
        PipesModule,
        FontAwesomeModule,
        SharedModule,
        ChartsModule
    ]
})
export class SafetyObservationsModule { }
