import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilsService } from './shared/utils.service';
import {AuthService} from "./shared/auth.service";
import {AppService} from "./app.service";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private utils: UtilsService,
    private app: AppService,
    private auth: AuthService,
    private dialog: MatDialog
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // Add the platform to the request headers
    if (!request.headers.has('Platform')) {
      request = request.clone({
        headers: request.headers.set('Platform', 'Web')
      });
    }

    return next.handle(request).do(
      () => {},
      (response: HttpErrorResponse) => {
        // Not found API error.
        if (response.status === 404 && !this.router.url.match(/sign-in/)) {
          this.utils.showToast(response.error.message);
        }

        // User level errors.
        else if (
          response.status >= 400 &&
          response.status !== 401 &&
          !this.router.url.match(/sign-in/) &&
          response.status < 500
        ) {
          // Custom error response.
          if (!response.error.errors) {
            this.utils.showModal('Error / Notice', response.error.message);
            return;
          }

          // Laravel generated error response.
          let errorMessage = '';
          let errors = response.error.errors;
          const keys = Object.keys(errors);
          keys.forEach((errorGroup) => {
            for (let i = 0; i < errors[errorGroup].length; i++) {
              errorMessage += errors[errorGroup][i] + '<br>';
            }
          });
          this.utils.showModal('Error / Notice', errorMessage);
        }

        // Redirect the user if an authenticated route is matched and the routes are not listed below.
        else if (response.status === 401 && (!this.router.url.match(/sign-in/) && !this.router.url.match(/login-credentials/) && !this.router.url.match(/handle-qr-code/))) {
          this.router.navigate([
            'sign-in',
            {
              redirect: this.router.url
            }
          ]);
        }

        // Server errors.
        else if (response.status >= 500) {
          if(response.status == 503 && this.app.isAuthenticated) {
            // Server is in Maintenance Mode
            if(this.dialog) {
              this.dialog.closeAll(); // Close Dialogs
            }
            this.auth.signout("Something went wrong with our server, try again later.");
          } else {
            this.utils.showModal(
              'Error / Notice',
              'Something went wrong with our server, try again later.'
            );
          }
        }

        // Failure to reach the server.
        else if (response.status === 0) {
          this.utils.showModal(
            'Cannot Reach our Server',
            'Your request could not reach our server. ' +
              'Please check your internet connectivity, and if this error ' +
              'persists contact <a href="mailto:' + this.utils.getSupportEmailAddress() + '">' + this.utils.getAppName() + ' support</a>.'
          );
        }
      }
    );
  }
}
