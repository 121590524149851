import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { UtilsService } from '../../shared/utils.service';
import { EmployeeService } from '../employee.service';
import { NgForm } from '@angular/forms';
import {ApiRequestService} from "../../shared/api-request.service";
import {TradeModel} from "../../admin/trades/trades.model";

@Component({
  selector: 'app-employees-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.css']
})
export class EmployeeDetailsComponent implements OnInit {

  trades: TradeModel[] = [];

  // Mobile field Country Code selector values
  mobile_input_id: string = 'phone-1';
  mobile_number: string = '';
  mobile_country_code: string = '';
  mobile_dial_code: string = '';
  mobile_e164: string = '';
  mobile_error_state: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    public employeeService: EmployeeService
  ) {}

  ngOnInit() {
    // Get Unique Names for Trades
    this.getTrades();
  }

  /**
   * Get Output values from phone input component.
   */
  getOutputPhoneDetails($event) {
    if($event) {
      let phone = $event;
      this.mobile_number = phone.hasOwnProperty('number') && phone.number !== null ? phone.number : '';
      this.mobile_country_code = phone.hasOwnProperty('countryCode') && phone.countryCode !== null ? phone.countryCode : '';
      this.mobile_dial_code = phone.hasOwnProperty('dialCode') && phone.dialCode !== null ? phone.dialCode : '';
      this.mobile_e164 = phone.hasOwnProperty('e164Number') && phone.e164Number !== null ? phone.e164Number : '';
      this.mobile_error_state = phone.hasOwnProperty('errorState') && phone.errorState !== null ? phone.errorState : true;
    }
  }

  private async getTrades() {
    this.trades = await this.utils.getTrades();
  }

  onSubmit(form: NgForm) {
    if ( !form.valid ) {
      this.utils.showFormValidationError();
      return;
    }

    // Check Error states of phone input then continue submission
    if (this.mobile_error_state) {
      if(this.mobile_number.length < 3) {
        this.utils.showFormValidationError('A Phone number must be at least 3 characters long.');
      } else {
        this.utils.showFormValidationError();
      }
      return;
    }

    // Update User details with Mobile field Country Code selector values
    this.employeeService.employee.mobile = this.mobile_number;
    this.employeeService.employee.mobile_country_code = this.mobile_country_code;
    this.employeeService.employee.mobile_dial_code = this.mobile_dial_code;
    this.employeeService.employee.mobile_e164 = this.mobile_e164;

    this.employeeService.updateEmployee();
  }
}
