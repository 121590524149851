import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/api.service';
import { HazardModel } from 'src/app/models/hazard.model';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { UtilsService } from 'src/app/shared/utils.service';
import { RISK_LEVEL_OF_CONTROLS } from 'src/app/shared/risk-level-of-controls';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-sites-hazards-view',
  templateUrl: './sites-hazards-view.component.html',
  styleUrls: ['./sites-hazards-view.component.scss']
})
export class SitesHazardsViewComponent implements OnInit {
  hazard: HazardModel = new HazardModel();
  loc_list = RISK_LEVEL_OF_CONTROLS;

  constructor(
    @Inject(MAT_DIALOG_DATA) private id: number,
    private api: ApiService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    this.api
      .laravelApiObservable('get', `hazards/${this.id}`)
      .subscribe(
        (response: ApiResponseModel<HazardModel>) =>
          (this.hazard = response.data)
      );
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }
}
