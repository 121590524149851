<div mat-dialog-title>
  <h5>Workflow Step Hazard/Risk</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form #f="ngForm" class="form-container" (ngSubmit)="onSubmitHazard(f)" appPadding>
    <mat-form-field>
      <input
        matInput
        placeholder="Hazard/Risk"
        [(ngModel)]="ta_revised_workflow_hazard.name"
        name="name"
        type="text"
        #name
        required />
      <mat-error *ngIf="!name.valid">Please enter the Hazard/Risk name. </mat-error>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="7"
        matInput
        placeholder="Description"
        [(ngModel)]="ta_revised_workflow_hazard.description"
        name="description"
        #description
        required>
      </textarea>
      <mat-error *ngIf="!description.valid">Please enter a description for this Hazard/Risk.</mat-error>
    </mat-form-field>

    <mat-checkbox [(ngModel)]="ta_revised_workflow_hazard.significant" name="significant" class="full-width">
      Significant Hazard/Risk (please tick the box if this is a significant hazard/risk).
    </mat-checkbox>

    <mat-form-field class="full-width mt-3">
      <mat-select
        placeholder="Status"
        [(ngModel)]="ta_revised_workflow_hazard.status"
        name="status"
        required
        id="status">
        <mat-option value="Unverified">Unverified</mat-option>
        <mat-option value="Verified">Verified</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="ta_revised_workflow_hazard?.id" class="full-width mb-3">
      <mat-label>Select the upcoming review date.</mat-label>
      <input matInput [ngxMatDatetimePicker]="reviewedAtDateTimePickerRef" readonly name="reviewedAtDateTime" [(ngModel)]="reviewedAtDateTime">
      <mat-datepicker-toggle matSuffix [for]="reviewedAtDateTimePickerRef"></mat-datepicker-toggle>
      <button type="button" mat-button matSuffix mat-icon-button *ngIf="reviewedAtDateTime" (click)="reviewedAtDateTime = null" matTooltip="Clear the date and time."><mat-icon>close</mat-icon></button>
      <ngx-mat-datetime-picker #reviewedAtDateTimePickerRef [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
      <mat-hint>
        Use the calendar icon to select the date and time.<br>
      </mat-hint>
    </mat-form-field>

    <h5 class="card-title">Initial Risk Assessment</h5>
    <button
      type="button"
      mat-raised-button
      color="secondary"
      appMarginLeft
      (click)="onViewMatrix('Initial')"
      matTooltip="View/Update Initial Risk Matrix">
      <mat-icon>assessment</mat-icon>
      Initial Risk Matrix
    </button>

    <div class="row" style="margin-right: 0 !important;margin-left: 0 !important">
      <div class="col-md-6 p-0 pr-md-3">
        <mat-form-field class="full-width" appMarginTop>
          <input
            matInput
            placeholder="Likelihood"
            readonly
            required
            [value]="utils.getRiskAssessmentLikelihoodList()[ta_revised_workflow_hazard.ira_likelihood - 1]"
            name="ira_likelihood"
            type="text"
            #ira_likelihood />
        </mat-form-field>
      </div>
      <div class="col-md-6 p-0 pl-md-3">
        <mat-form-field class="full-width" appMarginTop>
          <input
            matInput
            [placeholder]="utils.getLangTerm('hazards.form.severity', 'Severity')"
            readonly
            required
            [value]="utils.getRiskAssessmentSeverityList()[ta_revised_workflow_hazard.ira_severity - 1]"
            name="ira_severity"
            type="text"
            #ira_severity />
        </mat-form-field>
      </div>
    </div>

    <div
      class="alert alert-{{ getRiskAssessmentColorChange(ta_revised_workflow_hazard.ira_likelihood, ta_revised_workflow_hazard.ira_severity) }}"
      role="alert">{{ utils.getRiskAssessmentText(ta_revised_workflow_hazard.ira_likelihood, ta_revised_workflow_hazard.ira_severity) }}
    </div>

    <h5 class="card-title">Hazard/Risk Controls</h5>
    <button
      type="button"
      mat-raised-button
      color="secondary"
      appMarginLeft
      (click)="onAddControl()"
      matTooltip="Add Hazard/Risk Control">
      <mat-icon>add</mat-icon>
      Add Control
    </button>
    <button
      type="button"
      mat-raised-button
      color="secondary"
      appMarginLeft
      (click)="onViewHierarchyOfControl()"
      matTooltip="View Hierarchy of Controls">
      <mat-icon>vertical_align_bottom</mat-icon>
      Hierarchy of Controls
    </button>

    <div appMarginTop *ngFor="let control of ta_revised_workflow_hazard.controls; let i = index">
      <mat-form-field class="full-width">
        <input
          matInput
          placeholder="Control {{ i + 1 }}"
          [(ngModel)]="ta_revised_workflow_hazard.controls[i].control"
          [ngModelOptions]="{ standalone: true }"
          type="text"
          required />
        <mat-error *ngIf="!ta_revised_workflow_hazard.controls[i].control">Please enter a control.</mat-error>
      </mat-form-field>

      <div class="row">
        <div class="col-10">
          <mat-form-field class="full-width">
            <mat-select
              placeholder="Level of Control"
              [(ngModel)]="ta_revised_workflow_hazard.controls[i].loc"
              [ngModelOptions]="{ standalone: true }"
              required>
              <mat-option
                *ngFor="let loc of loc_list; let i = index"
                [value]="i + 1">
                {{ loc }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="!ta_revised_workflow_hazard.controls[i].loc">Please select a level of control.</mat-error>
          </mat-form-field>
        </div>

        <div class="col-2">
          <button
            type="button"
            mat-icon-button
            (click)="onRemoveControl(i)"
            *ngIf="ta_revised_workflow_hazard.controls.length > 1"
            matTooltip="Delete this Hazard/Risk Control">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <h5 class="card-title">Residual Risk Assessment</h5>
    <button
      type="button"
      mat-raised-button
      color="secondary"
      appMarginLeft
      (click)="onViewMatrix('Residual')"
      matTooltip="View/Update Residual Risk Matrix">
      <mat-icon>assessment</mat-icon>
      Residual Risk Matrix
    </button>

    <div class="row" style="margin-right: 0 !important;margin-left: 0 !important">
      <div class="col-md-6 p-0 pr-md-3">
        <mat-form-field class="full-width" appMarginTop>
          <input
            matInput
            placeholder="Likelihood"
            readonly
            required
            [value]="utils.getRiskAssessmentLikelihoodList()[ta_revised_workflow_hazard.rra_likelihood - 1]"
            name="rra_likelihood"
            type="text"
            #rra_likelihood />
        </mat-form-field>
      </div>
      <div class="col-md-6 p-0 pl-md-3">
        <mat-form-field class="full-width" appMarginTop>
          <input
            matInput
            [placeholder]="utils.getLangTerm('hazards.form.severity', 'Severity')"
            readonly
            required
            [value]="utils.getRiskAssessmentSeverityList()[ta_revised_workflow_hazard.rra_severity - 1]"
            name="rra_severity"
            type="text"
            #rra_severity />
        </mat-form-field>
      </div>
    </div>

    <div
      class="alert alert-{{ getRiskAssessmentColorChange(ta_revised_workflow_hazard.rra_likelihood, ta_revised_workflow_hazard.rra_severity) }}"
      role="alert">
      {{ utils.getRiskAssessmentText(ta_revised_workflow_hazard.rra_likelihood, ta_revised_workflow_hazard.rra_severity) }}
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-raised-button
    style="max-width: 100px;"
    [disabled]="!f.valid || inProgress || !hasValidControls() || !ta_revised_workflow_hazard.id"
    color="primary"
    class="save-button my-2"
    (click)="onSubmitHazard(f, false)"
    id="save"
  >
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    mat-raised-button
    style="max-width: 200px;"
    [disabled]="!f.valid || inProgress || !hasValidControls()"
    color="primary"
    class="save-button my-2"
    appMarginRight
    (click)="onSubmitHazard(f)"
    id="save_close"
  >
    <mat-icon>save</mat-icon>
    Save & Close
  </button>

  <app-file-browse-button
    (selectedFiles)="onSelectFiles(f, $event)"
    (afterDialogClosedEvent)="onSubmitHazard(f, false, true)"
    (afterFileRemove)="onRemoveFile($event)"
    parentObjectType="task_analysis_revised_step_hazard"
    [parentObjectId]="ta_revised_workflow_hazard.id"
    [parentRemove]="false"
    [parentUpload]="false"
    [filesCount]="ta_revised_workflow_hazard.files?.length">
  </app-file-browse-button>

  <button
    type="button"
    mat-raised-button
    color="secondary"
    appMarginRight
    class="my-2"
    [disabled]="inProgress"
    (click)="onSelectReviewer()"
    [matBadge]="'' + (ta_revised_workflow_hazard.reviewer_id ? 1 : 0)"
    *ngIf="ta_revised_workflow_hazard?.id"
    matTooltip="You can optionally select a reviewer.">
    <mat-icon>rate_review</mat-icon>
    {{ !ta_revised_workflow_hazard.reviewer_id ? 'Select' : 'Change' }} Reviewer
  </button>

  <button
    type="button"
    mat-raised-button
    color="secondary"
    class="my-2"
    [disabled]="inProgress"
    (click)="onRestoreFromRef()"
    *ngIf="ta_revised_workflow_hazard?.reference_id"
    matTooltip="Overwrite all Hazard data with the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Hazard/Risk used to create it.">
    <mat-icon>restore</mat-icon>
    Restore from Reference #{{ ta_revised_workflow_hazard.reference_id }}
  </button>

  <span class="mr-auto"></span>

  <button
    mat-icon-button
    [disabled]="!ta_revised_workflow_hazard?.id"
    class="float-right"
    (click)="onDelete()"
    matTooltip="Remove this Hazard/Risk">
    <mat-icon>delete</mat-icon>
  </button>
</mat-dialog-actions>

