<div class="app-margin" xmlns="http://www.w3.org/1999/html">

  <ng-container *ngIf="user.profile_pic">
    <div class="container-fluid mb-4">
      <h6>Profile Picture</h6>
      <div class="row">
        <div class="col-12">
          <img [src]="user.profile_pic" class="profile-picture" alt="Profile Picture" />
        </div>
      </div>
    </div>
  </ng-container>

  <h6>Full Name</h6>
  <p>{{ user.contact_person }}</p>

  <h6>Job Title</h6>
  <p>{{ user.trade }}</p>

  <ng-container *ngIf="user.iqp_number">
    <h6>IQP Number</h6>
    <p>{{ user.iqp_number }}</p>
  </ng-container>

  <ng-container *ngIf="user.employer_name">
    <h6>Employer</h6>
    <p>{{ user.employer_name }}</p>
  </ng-container>

  <ng-container *ngIf="user.folders?.length">
    <h6>Training/Competency Documents</h6>
    <ul>
      <li *ngFor="let folder of user.folders">
        <p><span style="font-weight: 600">Name</span>: {{ folder.name }}</p>
        <p><span style="font-weight: 600">Expiry Date</span>: <span *ngIf="folder.expiry">
            {{ (folder.expiry * 1000) | timezoneConvertMedium }}
        </span></p>
        <p><span style="font-weight: 600">Type</span>: {{ folder.folder_type }}</p>
        <p><span style="font-weight: 600">Visibilty</span>: {{ folder.visibility }}</p>

        <ng-container *ngIf="folder.links?.length">
          <p><span style="font-weight: 600">Links</span>:<br></p>
          <mat-list class="pt-0 mb-2">
            <mat-list-item *ngFor="let link of folder.links; last as isLast">
              <div mat-line class="text-wrap"><a target="_blank" [href]="link.url">{{ link.url | ellipsis: [150] }}</a></div>
              <div mat-line class="text-wrap">Created: {{ (link.date_created * 1000) | timezoneConvertMedium }} </div>
              <div mat-line class="text-wrap">Last Modified: {{ (link.date_modified * 1000) | timezoneConvertMedium }} </div>
              <mat-divider class="w-100 m-0" [inset]="true" *ngIf="!isLast"></mat-divider>
            </mat-list-item>
          </mat-list>
        </ng-container>

        <ng-container *ngIf="folder.files?.length">
          <p><span style="font-weight: 600">Files</span>:<br>
              <app-files-list
                id="files"
                [files]="folder.files"
                [view]="true">
              </app-files-list>
          </p>
        </ng-container>
      </li>
    </ul>
  </ng-container>
</div>
