<div mat-dialog-title>
  <h5>View Task Analysis Document</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <ng-container *ngIf="task_analysis.task_name">
    <p>{{ task_analysis.task_name }}</p>
  </ng-container>

  <h6 *ngIf="task_analysis.files">Files</h6>

  <app-files-list [view]="true" [files]="task_analysis.files"></app-files-list>
</mat-dialog-content>
