<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/task-analyses">TA's</a></li>
    <li class="breadcrumb-item">
      <a routerLink="/task-analyses/{{ parent_id }}/steps">TA Steps</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Edit</li>
  </ol>
</nav>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
        <div class="card">
          <div class="card-body form-container">
            <h5 class="card-title">TA Step Form</h5>

            <mat-form-field>
              <input
                matInput
                placeholder="Step"
                [(ngModel)]="service.item.step"
                name="step"
                type="text"
                #step
                required
              />
              <mat-error *ngIf="!step.valid">Please enter the step.</mat-error>
            </mat-form-field>

            <div
              appMarginTop
              *ngFor="let hazard of service.item.hazards; let i = index"
            >
              <div class="row">
                <div class="col">
                  <mat-form-field class="full-width">
                    <mat-select
                      placeholder="Hazard/Risk {{ i + 1 }}"
                      [(ngModel)]="service.item.hazards[i].hazard_id"
                      [ngModelOptions]="{ standalone: true }"
                      required
                    >
                      <mat-option
                        *ngFor="
                          let hazard of hazardsService.list;
                          let i = index
                        "
                        [value]="hazard.id"
                        >{{ hazard.name }}</mat-option
                      >
                    </mat-select>
                    <mat-error *ngIf="!service.item.hazards[i].hazard_id"
                      >Please select a level of control.</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <button
                    type="button"
                    mat-mini-fab
                    color="danger"
                    (click)="onRemoveHazard(i)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div appMarginTop class="d-flex justify-content-end">
              <button
                type="button"
                mat-raised-button
                color="secondary"
                (click)="onAddHazard()"
              >
                Add Hazard
              </button>
            </div>
          </div>
        </div>

        <div appMarginTop class="d-flex justify-content-end">
          <button
            type="submit"
            mat-raised-button
            [disabled]="!f.valid"
            color="primary"
          >
            Save
          </button>
        </div>
      </form>
    </div>

    <div class="col-12 col-md-6">
      <h4>Instructions</h4>
      <p>
        Complete the form on the left to add a new or update an existing task
        analysis step.
      </p>
      <p>Enter the step description in the step field.</p>
      <p>
        Each step you add can have potential risks. Click the
        <b>add a Hazard/Risk</b> button to link a Hazard/Risk to the step.
      </p>
      <p>
        If the list of Hazards & Risks is empty, you will first need to go and
        <a routerLink="/hazards">add a Hazard/Risk</a>. After adding Hazards & Risks, you can
        come back here to link the Hazards & Risks to the step.
      </p>
      <p>To remove a Hazard/Risk, click on the red trash icon.</p>
    </div>
  </div>
</div>
