<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">Related Accounts</span>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle Navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="primary" appMarginRight [routerLink]="['/account', 'configuration']"
                matTooltip="Manage your related accounts here.">
          <mat-icon>account_tree</mat-icon> Manage Related Accounts
        </button>
      </span>

      <form class="form-inline my-2 my-lg-0"
            style="min-width: 325px"
            (ngSubmit)="onRefresh(true)">
        <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search"
          placeholder="Search" aria-label="Search" id="search-input"
          matTooltip="Search for accounts by name." />
        <button
          mat-raised-button
          color="secondary"
          (click)="onRefresh(true)"
          class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
          id="search-button"
          matTooltip="Run the current search."
        >
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"
      matSortDisableClear>

      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef   matTooltip="Toggle sorting by name">
          Account Name
        </th>
        <td mat-cell *matCellDef="let account">
          {{ account.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef width="250px"></th>
        <td mat-cell *matCellDef="let account">
          <button type="button" mat-raised-button (click)="onTogglelink(account.id, $event)" [color]="isAssociated(account.id) ? 'warn' : 'primary'">
            {{ isAssociated(account.id) ? 'Unlink' : 'Link' }}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let account; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
  </div>

</div>
