import {Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges} from '@angular/core';
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import * as moment from "moment";

interface IRange {
  value: Date[];
  label: string;
}

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit, OnChanges  {

  @Input() dateRangeTitle: string;
  @Input() dateRangePlaceholder: string;
  @Input() dateRangeTooltip: string;
  @Input() dateRangeInput: Date[];
  @Output() dateRangeOutput: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('rangePicker') rangePicker;

  datepickerConfig: Partial<BsDatepickerConfig>;
  date_range: Date[] = [];

  // Date Range Picker Preset Data
  ranges: IRange[] = [{
    value: [
      moment().startOf('day').toDate(),
      moment().endOf('day').toDate(),
    ],
    label: 'Today'
  }, {
    value: [
      moment().subtract(1, 'day').startOf('day').toDate(),
      moment().subtract(1, 'day').endOf('day').toDate(),
    ],
    label: 'Yesterday'
  }, {
    value: [
      moment().startOf('week').toDate(),
      moment().endOf('week').toDate()
    ],
    label: 'This Week'
  }, {
    value: [
      moment().subtract(7, 'days').startOf('week').toDate(),
      moment().subtract(7, 'days').endOf('week').toDate()
    ],
    label: 'Last Week'
  }, {
    value: [
      moment().startOf('month').toDate(),
      moment().endOf('month').toDate()
    ],
    label: 'This Month'
  }, {
    value: [
      moment().subtract(1, 'month').startOf('month').toDate(),
      moment().subtract(1, 'month').endOf('month').toDate()
    ],
    label: 'Last Month'
  }, {
    value: [
      moment().subtract(2, 'months').startOf('month').toDate(),
      moment().endOf('month').toDate()
    ],
    label: 'Last Three Months'
  }, {
    value: [
      moment().subtract(5, 'months').startOf('month').toDate(),
      moment().endOf('month').toDate()
    ],
    label: 'Last Six Months'
  }, {
    value: [
      moment().subtract(11, 'months').startOf('month').toDate(),
      moment().endOf('month').toDate()
    ],
    label: 'Last Twelve Months'
  }];

  constructor() { }

  ngOnInit() {
    this.datepickerConfig = Object.assign(
      {},
      {
        containerClass: 'theme-orange',
        dateInputFormat: 'DD MMM YYYY',
        rangeInputFormat: 'DD MMM YYYY',
        showWeekNumbers: true,
        adaptivePosition: true,
        ranges: this.ranges
      }
    );

    this.date_range = this.dateRangeInput;
  }

  ngOnChanges() {
    if(this.dateRangeInput && this.date_range !== this.dateRangeInput) {
      this.date_range = this.dateRangeInput;
    }
  }

  onDateRangePickerShow() {
    // This is a workaround to show previous month with an invalid date check
    if(this.date_range.length === 0) {
      let prevMonth = new Date();
      prevMonth.setMonth(prevMonth.getMonth() - 1);
      this.rangePicker._datepicker.instance.monthSelectHandler({ date: prevMonth });
    }
  }

  onDateRangePickerChange() {
    this.dateRangeOutput.emit(this.date_range);
  }

}
