<div mat-dialog-title>
  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit()">
  <div class="container-fluid" class="app-padding" mat-dialog-content>
    <div class="row">
      <div class="col-12">

        <h4>Pre-Qualification Details</h4>

        <mat-form-field class="full-width" *ngIf="templates?.length">
          <mat-select placeholder="Copy from ..." name="templateSelector" (selectionChange)="templateChosen()"
            [(ngModel)]="template">
            <mat-option *ngFor="let template of templates" [value]="template">
              {{ template.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field style="width: 100%">
          <input matInput autocomplete="off" placeholder="Pre-Qualification Name" [(ngModel)]="prequalification.name"
            name="name" type="text" required #name id="name" />
          <mat-error *ngIf="!name.valid">Please enter an Prequalification Name.
          </mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%">
          <textarea matInput placeholder="Pre-Qualification Description" [(ngModel)]="prequalification.description"
            name="description" #description id="description" rows="5"></textarea>
            <mat-hint>A description that will be presented to the {{ util.getLangTerm('contractors.singular', 'Contractor') }} when they complete their Pre-Qualification.</mat-hint>
        </mat-form-field>

        <div class="full-width" appMarginBottom appMarginTop>
          <mat-slide-toggle [(ngModel)]="prequalification.enable_notifications" class="text-wrap"
            name="enable_notifications"
            matTooltip="{{prequalification.enable_notifications ? 'Disable notifications for this Pre-Qualification' : 'Enable notifications for this Pre-Qualification'}}">
            Enable Pre-Qualification Notifications
          </mat-slide-toggle>
        </div>

        <div class="row">
          <div class="col-12">
            <h4>Selectable Pre-Qualification Documents</h4>
          </div>
          <div class="col-6" *ngFor="let item of items; let i = index">
            <mat-checkbox (change)="onToggleItem(item.id)" [checked]="isItemSelected(item.id)">{{ item.name }}
            </mat-checkbox>
          </div>
        </div>

        <button mat-raised-button [disabled]="!form.valid || inProgress" color="primary" id="save" type="submit">
          <mat-icon>save</mat-icon> Save
        </button>

      </div>

    </div>

  </div>
</form>
