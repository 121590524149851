import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";
import {NetworkedAccountsSelectorComponent} from "../../shared/networked-accounts-selector/networked-accounts-selector.component";
import { faHardHat } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sssp-filter',
  templateUrl: './sssp-filter.component.html',
  styleUrls: ['./sssp-filter.component.scss']
})
export class SsspFilterComponent implements OnInit {

  dataSource = new SsspFilterDataSource(); // No API calls needed
  faHardHat = faHardHat;

  constructor(
    private app: AppService,
    public location: Location,
    public utils: UtilsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SsspFilterComponent>
    // private api: ApiRequestService
  ) {
    // set a default site selection of nothing if no selected values are passed through
    if (typeof this.data['selected_site_ids'] === 'undefined') {
      this.data['selected_site_ids'] = [];
    }

    // set a default user selection of nothing if no selected values are passed through
    if (typeof this.data['selected_contractor_ids'] === 'undefined') {
      this.data['selected_contractor_ids'] = [];
    }
  }

  ngOnInit() {
    this.dataSource.selected_site_ids = this.data['selected_site_ids'];
    this.dataSource.selected_contractor_ids = this.data['selected_contractor_ids'];
  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.dataSource.selected_site_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.selected_site_ids = results;
        }
      }
    );
  }

  onSelectUsers() {
    this.utils.showComponentDialog(
      NetworkedAccountsSelectorComponent,
      {
        selected: this.dataSource.selected_contractor_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.selected_contractor_ids = results;
        }
      }
    );
  }

  onClearAndClose() {
    this.dataSource.selected_site_ids = [];
    this.dataSource.selected_contractor_ids = [];

    this.onSaveAndClose();
  }

  onSaveAndClose() {
    let response: any;

    response = {
      site_ids: this.dataSource.selected_site_ids,
      contractor_ids: this.dataSource.selected_contractor_ids
    }

    this.dialogRef.close(response);
  }

}

/**
 * the data source class should contain everything needed to get data for the feature.
 */
export class SsspFilterDataSource {

  selected_site_ids: number[] = [];
  selected_contractor_ids: number[] = [];

  constructor() {
    this.selected_site_ids = [];
    this.selected_contractor_ids = [];
  }

}
