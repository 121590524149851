import { ActionModel } from "../actions/action.model";
import { Auditable } from "../shared/auditable.model";

export class IncidentCauseModel extends Auditable {
  id: number;
  name: string;
  description: string;
  incident_id: number;
  type: string;
  actions: ActionModel[];
}
