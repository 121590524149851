import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-sites-notifications-tabs',
  templateUrl: './sites-notifications-tabs.component.html',
  styleUrls: ['./sites-notifications-tabs.component.scss']
})
export class SitesNotificationsTabsComponent implements OnInit {

  // The parent, child and active site ids.
  parent_id: number;
  child_id: number;
  site_id: number;

  constructor(
    public app: AppService,
    public utils: UtilsService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Get the main and child site ids from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are getting information for the sub-site or main site.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Update the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;
    }
  }
}
