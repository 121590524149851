import { Component, Inject, OnInit } from '@angular/core';
import { NetworkedUsersSelectorComponent } from "../../shared/networked-users-selector/networked-users-selector.component";
import { NetworkedEmployeesSelectorComponent } from "../../shared/networked-employees-selector/networked-employees-selector.component";
import { UtilsService } from "../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";

@Component({
  selector: 'app-sites-toolbox-talks-filter',
  templateUrl: './sites-toolbox-talks-filter.component.html',
  styleUrls: ['./sites-toolbox-talks-filter.component.scss']
})
export class SitesToolboxTalksFilterComponent implements OnInit {

  // Define the default filters object.
  filters: {
    reported_by_ids: number[],
    employee_ids: number[],
    attendee_ids: number[],
    date_range: Date[],
    site_ids: number[],
    parent_site_id: number
  } = {
    reported_by_ids: [],
    employee_ids: [],
    attendee_ids: [],
    date_range: [],
    site_ids: [],
    parent_site_id: null
  };

  constructor(
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SitesToolboxTalksFilterComponent>
  ) { }

  ngOnInit() {
    this.filters.reported_by_ids = this.data['reported_by_ids'] || [];
    this.filters.employee_ids = this.data['employee_ids'] || [];
    this.filters.attendee_ids = this.data['attendee_ids'] || [];
    this.filters.date_range = this.data['date_range'] || [];
    this.filters.site_ids = this.data['site_ids'] || [];
    this.filters.parent_site_id = this.data['parent_site_id'] || null;
  }

  getOutputDateRange($event) {
    this.filters.date_range = $event;
  }

  onSelectReportedBy() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.filters.reported_by_ids,
      visitors_from_all_sites: true
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.filters.reported_by_ids = results;
    });
  }

  onSelectEmployees() {
    this.utils.showComponentDialog(NetworkedEmployeesSelectorComponent, {
      selected: this.filters.employee_ids,
      multiple: true
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.filters.employee_ids = results;
    });
  }

  onSelectAttendees() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.filters.attendee_ids,
      multiple: true,
      visitors_from_all_sites: true
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.filters.attendee_ids = results;
    });
  }

  /**
   * Open the site selector.
   */
  onSelectSites() {
    this.utils.showComponentDialog(SitesSelectorComponent, {
        parent_site_id: this.filters.parent_site_id,
        selected: this.filters.site_ids,
        multiple: true
      }, {
        width: '1024px'
      }
    )
      .then((results) => {
        if (typeof results !== 'undefined') {
          this.filters.site_ids = results;
        }
      });
  }

  onClearAndClose() {
    // Clear filters.
    this.filters.reported_by_ids = [];
    this.filters.employee_ids = [];
    this.filters.attendee_ids = [];
    this.filters.date_range = [];
    this.filters.site_ids = [];

    this.onSaveAndClose();
  }

  onSaveAndClose() {
    this.dialogRef.close(this.filters);
  }
}
