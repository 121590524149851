import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { IncidentCauseModel } from './incident-cause.model';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../models/api-response.model';
import { ActionModel } from '../actions/action.model';

@Injectable({
  providedIn: 'root'
})
export class IncidentCauseService {
  private readonly resourceUrl = 'incident-causes';

  constructor(private api: ApiService) {}

  create(
    cause: IncidentCauseModel
  ): Observable<ApiResponseModel<IncidentCauseModel>> {
    return this.api.laravelApiObservable('post', this.resourceUrl, cause);
  }

  update(
    cause: IncidentCauseModel
  ): Observable<ApiResponseModel<IncidentCauseModel>> {
    return this.api.laravelApiObservable(
      'put',
      this.resourceUrl + `/${cause.id}`,
      cause
    );
  }

  remove(ids: number[]): Observable<ApiResponseModel<void>> {
    return this.api.laravelApiObservable(
      'delete',
      this.resourceUrl + `/${ids.join(',')}`
    );
  }

  getActions(id: number): Observable<ApiResponseModel<ActionModel[]>> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl + `/${id}/actions`
    );
  }
}
