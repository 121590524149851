<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class="mr-auto">
    <button mat-raised-button routerLink="/toolbox-talks" class="margin-right my-2">
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') }}
    </button>

    <button mat-raised-button (click)="onEdit(toolbox_talk.id)" class="margin-right my-2">
      <mat-icon>edit</mat-icon> Edit
    </button>
    <button mat-raised-button (click)="onRemove()" class="margin-right my-2">
      <mat-icon>delete</mat-icon> Delete
    </button>

    <button mat-raised-button (click)="onExport('pdf')" color="secondary" class="margin-right my-2">
      <mat-icon>cloud_download</mat-icon> Export PDF
    </button>

    <button mat-raised-button (click)="onExport('csv')" color="secondary" class="margin-right my-2">
      <mat-icon>cloud_download</mat-icon> Export CSV
    </button>

  </span>
</nav>

<mat-tab-group *ngIf="urlPath == 'view'">
  <mat-tab label="Overview">
    <app-toolbox-talks-view [toolboxTalksTabsComponent]="this"></app-toolbox-talks-view>
  </mat-tab>
  <mat-tab *ngIf="toolbox_talk.id" label="Corrective Actions">
    <app-toolbox-talks-corrective-actions [toolboxTalksId]="toolbox_talk.id" [toolboxTalksSiteId]="toolbox_talk.site_id" [toolboxTalksUserIds]="toolbox_talk.user_ids"></app-toolbox-talks-corrective-actions>
  </mat-tab>
</mat-tab-group>
