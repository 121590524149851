<div mat-dialog-title>
  <h5>Question Form</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">

    <mat-form-field>
      <input matInput placeholder="Question"
        [(ngModel)]="question_data.question" name="question" type="text"
        #question required />
      <mat-error *ngIf="!question.valid">Please enter a question.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Status" [(ngModel)]="question_data.status"
        name="status" required>
        <mat-option value="Draft">Draft</mat-option>
        <mat-option value="Published">Published</mat-option>
      </mat-select>
      <mat-error *ngIf="!question_data.status">Please select the status of this
        section.</mat-error>
    </mat-form-field>

    <h5 class="card-title">Answers</h5>

    <div appMarginTop
      *ngFor="let control of question_data.answers; let i = index">
      <mat-form-field class="full-width">
        <input matInput placeholder="Answer {{ i + 1 }}" name="answer-text-{{i}}"
          [(ngModel)]="question_data.answers[i].answer_text" type="text"
          required />
        <mat-error *ngIf="!question_data.answers[i].answer_text">Please enter
          the answer.</mat-error>
      </mat-form-field>

      <div class="row">
        <div class="col">
          <div>
            <mat-slide-toggle
              [(ngModel)]="question_data.answers[i].answer_value"
              name="answer-value-{{i}}">
              {{question_data.answers[i].answer_value ? 'Yes' : 'No'}}
            </mat-slide-toggle>
          </div>
        </div>
        <div class="col-2">
          <button type="button" mat-mini-fab color="danger"
            (click)="onRemoveAnswer(i)" class="delete-answer"
            [disabled]="question_data.answers.length === 1">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button [disabled]="!f.valid" color="primary"
    class="save" (click)="onSubmit(f)">
    <mat-icon>save</mat-icon>
    Save
  </button>
  <button mat-raised-button color="secondary" (click)="onAddAnswer()">
    <mat-icon>add</mat-icon>
    Add Answer
  </button>
</mat-dialog-actions>
