<div mat-dialog-title>
  <h5>{{ tool.id ? 'Edit' : 'Create' }} Tool</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit(form, !tool.id)">
  <mat-form-field class="full-width mb-3">
    <input
      matInput
      placeholder="Tool"
      [(ngModel)]="tool.title"
      name="title"
      type="text"
      required
      #title />
    <mat-hint>Please enter a Tool title.</mat-hint>
  </mat-form-field>

  <mat-form-field class="full-width mb-3">
    <textarea
      rows="4"
      matInput
      placeholder="Description"
      [(ngModel)]="tool.description"
      name="description"
      #description>
    </textarea>
    <mat-hint>Please enter a short description for the tool.</mat-hint>
  </mat-form-field>

  <mat-form-field class="full-width mb-3">
    <mat-label>Industry</mat-label>
    <mat-select [(ngModel)]="tool.industry_id" name="industry" #industry>
      <mat-option [value]="0">All</mat-option>
      <mat-option *ngFor="let industry of industries" [value]="industry.id">{{ industry.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="full-width mb-3">
    <mat-label>Select the tool's expiry date.</mat-label>
    <input matInput [ngxMatDatetimePicker]="expiresAtDateTimePickerRef" readonly name="expiresAtDateTime" [(ngModel)]="expiresAtDateTime">
    <mat-datepicker-toggle matSuffix [for]="expiresAtDateTimePickerRef"></mat-datepicker-toggle>
    <button type="button" mat-button matSuffix mat-icon-button *ngIf="expiresAtDateTime" (click)="expiresAtDateTime = null" matTooltip="Clear the date and time."><mat-icon>close</mat-icon></button>
    <ngx-mat-datetime-picker #expiresAtDateTimePickerRef [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
    <mat-hint>
      Use the calendar icon to select the date and time.<br>
    </mat-hint>
  </mat-form-field>

  <mat-form-field class="full-width mb-3">
    <mat-label>Maintenance & Safety Checks List</mat-label>
    <mat-select [(ngModel)]="tool.maintenance_safety_checks" name="maintenance_safety_check" multiple>
      <ng-container *ngFor="let maintenance_safety_check of utils.getToolsMaintenanceAndSafetyChecksList(); let i = index;">
        <mat-option [value]="maintenance_safety_check">{{ maintenance_safety_check }}</mat-option>
        <mat-divider *ngIf="i == 9"></mat-divider>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <div class="d-flex justify-content-end">
    <button
      mat-raised-button
      [disabled]="!form.valid || inProgress"
      color="primary"
      type="submit"
      class="mr-3"
      id="save">
      <mat-icon>save</mat-icon>
      Save
    </button>

    <app-file-browse-button
      (selectedFiles)="onSelectFiles($event)"
      (afterDialogClosedEvent)="onSubmit(form, false)"
      parentObjectType="tool"
      [parentObjectId]="tool.id"
      [filesCount]="tool?.files?.length">
    </app-file-browse-button>

    <button
      type="button"
      mat-icon-button
      [disabled]="!tool.id"
      (click)="onDelete()">
      <mat-icon>archive</mat-icon>
    </button>
  </div>


</form>
