import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ContractorsSelectorComponent } from 'src/app/contractors/contractors-selector/contractors-selector.component';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { SsspTabsDialogComponent } from 'src/app/sssp/sssp-tabs/sssp-tabs-dialog.component';
import { AppService } from '../../app.service';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { CustomDataSource } from '../../utils/custom-data-source';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-sites-contractors',
  templateUrl: './sites-contractors.component.html',
  styleUrls: ['./sites-contractors.component.scss']
})
export class SitesContractorsComponent implements OnInit, AfterViewInit {
  // the site id must be present
  parent_id: number;
  child_id: number;
  site_id: number;

  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'contact_person',
    'entity_name',
    'email',
    'mobile',
    'actions'
  ];
  dataSource: ContractorsDataSource;

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    // public service: ContractorsService,
    public app: AppService,
    private oldApi: ApiService,
    public utils: UtilsService,
    public route: ActivatedRoute,
    private api: ApiRequestService
  ) {}

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      // load a multi select data source object
      this.dataSource = new ContractorsDataSource(this.app, this.oldApi, true, {
        site_id: this.site_id
      });

      // get the data only when the site id is present
      this.dataSource.getData();
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   *
   * @param id the contractor id
   *
   * unlink a single contractor
   */
  onRemove(id: number) {
    this.utils.showModal(
      `Unlink ${ this.utils.getLangTerm('contractors.singular', 'Contractor') }`,
      `Are you sure you want to unlink this ${ this.utils.getLangTerm('contractors.singular', 'Contractor') }?`,
      () => {
        this.onUpdateLinkedContractors(
          this.dataSource.contractor_ids.filter(
            (contractor_id) => ![id].includes(contractor_id)
          ),
          (response) => {
            this.dataSource.selection.deselect(id);
          }
        );
      }
    );
  }

  /**
   * unlink selected contractors from the specified site
   */
  onDeleteSelected() {
    this.utils.showModal(
      `Unlink Selected ${ this.utils.getLangTerm('contractors.plural', 'Contractors') }`,
      `Are you sure you want to unlink the selected ${ this.utils.getLangTerm('contractors.plural', 'Contractors') }?`,
      () => {
        this.onUpdateLinkedContractors(
          this.dataSource.contractor_ids.filter((contractor_id) => !this.dataSource.selection.selected.includes(contractor_id)),
          (response) => {
            this.dataSource.selection.clear();
          }
        );
      }
    );
  }

  /**
   * open a dialog allowing the user to select contractors to be linked to sites.
   */
  onLinkContractorBusiness() {
    // show the site selector
    this.utils.showComponentDialog( ContractorsSelectorComponent, {
        multiple: true,
        selected: this.dataSource.contractor_ids
      }, {}, (results) => {
        if (typeof results != 'undefined') {
          this.dataSource.contractor_ids = results;

          if (this.site_id) {
            this.onUpdateLinkedContractors(this.dataSource.contractor_ids);
          }
        }
      }
    );
  }

  onUpdateLinkedContractors(contractor_ids: any, callback?: any) {
    // Check if the site id is present and valid.
    if ( this.site_id ) {
      // link all contractor businesses
      this.oldApi.laravelApiRequest('put', 'sites/' + this.site_id + '/linked-contractors', {
          selected_contractor_ids: contractor_ids
        }, {}, (response) => {
          // reload the data and reset the offset
          this.dataSource.getData(true);

          if (typeof callback === 'function') {
            callback(response);
          }
        },
        (error) => {
          this.utils.showModal('Error', error.message);
        }
      );
    }
  }

  composeNewMessage() {
    if ( this.dataSource.selection.selected.length == 0 ) {
      this.utils.showToast(`You need to select some ${ this.utils.getLangTerm('contractors.plural', 'Contractors') } first.`);
      return; // early termination
    }

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectAccountIds: this.dataSource.selection.selected
    })
    .then(() => {
      this.utils.showToast('You can view the message stats in the messages section.');
    });
  }

  onCreateSSSP(contractor_id: number) {
    // Make a post request to create a new Safety Plan.
    // Reload the list to show the latest changes.
    this.api.makeRequest('post', 'v1/sssp', {
      site_id: this.site_id,
      contractor_id: contractor_id
    })
    .then((response: any) => {
      // This means the request was successful.
      // Open it in a dialog.
      this.onManageSSSP(response.sssp_hash);
      // Reload the list in the background.
      this.dataSource.getData();
    })
    .catch((error) => {
      this.utils.showToast('The Safety Plan was created successfully.');
    });
  }

  onManageSSSP(sssp_hash: string) {
    // Open the Safety Plan manager using a dialog window.
    this.utils.showComponentDialog(SsspTabsDialogComponent, {
      sssp_hash: sssp_hash
    });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class ContractorsDataSource extends CustomDataSource {

  sort_by = 'entity_name';
  sort_order = 'asc';

  contractor_ids: number[] = [];

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      'sites/' + this['site_id'] + '/linked-contractors',
      resetOffset,
      (response) => {
        this.contractor_ids = response.meta.contractor_ids;
      }
    );
  }
}
