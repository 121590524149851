<div mat-dialog-title>
  <h5>{{ !folder_id ? "Add" : "Edit" }} {{ !is_training_doc ? "Folder" : "Document" }}</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<app-account-folders-edit *ngIf="!folder_id && is_training_doc" [folder]="folder" [show_document_types_list_for]="show_document_types_list_for" [show_document_visibility]="show_document_visibility"></app-account-folders-edit>
<app-folders-edit *ngIf="!folder_id && !is_training_doc" [folder]="folder" [show_document_types_list_for]="show_document_types_list_for" [show_document_visibility]="show_document_visibility"></app-folders-edit>

<mat-tab-group *ngIf="folder_id">
  <mat-tab label="Overview">
    <app-account-folders-edit *ngIf="is_training_doc" [folder]="folder" [show_document_types_list_for]="show_document_types_list_for" [show_document_visibility]="show_document_visibility"></app-account-folders-edit>
    <app-folders-edit *ngIf="!is_training_doc" [folder]="folder" [show_document_types_list_for]="show_document_types_list_for" [show_document_visibility]="show_document_visibility"></app-folders-edit>
  </mat-tab>
  <mat-tab label="Links">
    <app-account-folders-links [folderId]="folder_id"></app-account-folders-links>
  </mat-tab>
</mat-tab-group>
