import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { AppService } from '../../app.service';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { IndustriesModel } from "../../admin/industries/industries.model";
import {
  NetworkedEmployeesSelectorComponent
} from "../../shared/networked-employees-selector/networked-employees-selector.component";

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css']
})
export class AccountDetailsComponent implements OnInit {

  inProgress: boolean = false;

  industries: IndustriesModel[] = [];

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private oldApi: ApiService,
    public utils: UtilsService,
    private renderer: Renderer2,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    // Get Industries
    this.getIndustries();
  }

  private async getIndustries() {
    this.industries = await this.utils.getIndustries();
  }

  // get timezone output from timezone select component
  getOutputTimezone($event) {
    this.app.account.timezone = $event;
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      this.utils.showModal(
        'Error',
        'You need to enter valid information in all required fields'
      );
      return;
    }

    if (this.inProgress) {
      return;
    }

    this.inProgress = true;

    this.oldApi.laravelApiRequest(
      'put',
      'account/' + this.app.account.id,
      this.app.account,
      {},
      (response) => {
        this.utils.showToast('Your account details were updated.');

        // Apply the account updates
        this.app.account.apply(response.data);

        if (
          typeof this.route.snapshot.queryParams['redirect'] !== 'undefined'
        ) {
          this.router.navigate([
            '/',
            this.route.snapshot.queryParams['redirect']
          ]);
        }

        this.inProgress = false;
      },
      () => {
        this.inProgress = false;
      }
    );
  }

  /**
   *
   * @param evt
   *
   * Click an ion-button that in turn triggers the file input type to select a file.
   * when a file is selected, it is uploaded.
   *
   * http://pjsdev.blogspot.co.nz/2016/05/ionic-2-beta-6-angular-2-styled-upload.html
   */
  onTriggerFileSelector(evt: Event) {
    if(this.fileInput) {
      const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
      this.fileInput.nativeElement.dispatchEvent(clickEvt);
    }
  }

  // When a file is selected, store it.
  onUploadSelectedFile(evt: any) {
    this.api.makeUploadRequest('v1/account/company-logo', evt.target.files[0])
      .then((response) => {
        this.app.account.apply(response);
        this.utils.showToast('Your company logo was updated.');
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onRemoveCompanyLogo() {
    this.api.makeRequest('delete', 'v1/account/company-logo')
      .then((response) => {
        this.app.account.apply(response);
        this.utils.showToast('Your company logo was removed.');
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onSelectMainContact() {
    this.utils.showComponentDialog(
      NetworkedEmployeesSelectorComponent,
      {
        multiple: false,
        deselect: false,
        selected: [this.app.account.main_contact_id]
        // selectedAccountId: this.app.account.id
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.app.account.main_contact_id = userId;
      }
    );
  }
}
