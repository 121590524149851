import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UtilsService} from "../../shared/utils.service";
import {SiteModel} from "../../models/site.model";
import {AppService} from "../../app.service";
import {ApiService} from "../../shared/api.service";

@Component({
  selector: 'app-sites-details-tabs',
  templateUrl: './sites-details-tabs.component.html',
  styleUrls: ['./sites-details-tabs.component.scss']
})
export class SitesDetailsTabsComponent implements OnInit {

  // the site model placeholder
  site: SiteModel = new SiteModel();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    // Get the site id from the route params.
    const parent_id: number = Number(this.route.parent.snapshot.params['parent_id']);
    const child_id: number = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && parent_id) ) {
      // Store the site id.
      this.site.id = child_id ? child_id : parent_id;
    } else {
      // Get and store the parent site id if it is present.
      if ( parent_id ) {
        // Store the site id.
        this.site.parent_site_id = parent_id;
      }
    }
  }

  /**
   * Remove a single site.
   */
  onRemove() {
    this.utils.showModal(
      'Remove ' + this.utils.getLangTerm(this.site.parent_site_id ? 'child-sites.singular' : 'sites.singular', this.site.parent_site_id ? 'Subsite' : 'Site'),
      'Are you sure you want to remove this ' + this.utils.getLangTerm(this.site.parent_site_id ? 'child-sites.singular' : 'sites.singular', this.site.parent_site_id ? 'Subsite' : 'Site').toLowerCase() + '?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'sites/' + this.site.id,
          {},
          {},
          (response) => {
            this.utils.showToast('The ' + this.utils.getLangTerm(this.site.parent_site_id ? 'child-sites.singular' : 'sites.singular', this.site.parent_site_id ? 'Subsite' : 'Site').toLowerCase() + ' was removed.');
            this.router.navigate(['/sites']);
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

}
