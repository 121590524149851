import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ERPModel } from 'src/app/models/erp.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { FileService } from 'src/app/shared/file.service';
import { FilesRemoveComponent } from 'src/app/files/files-remove/files-remove.component';

@Component({
  selector: 'app-erps-edit',
  templateUrl: './erps-edit.component.html',
  styleUrls: ['./erps-edit.component.scss']
})
export class ErpsEditComponent implements OnInit {
  erp: ERPModel = new ERPModel();
  newFiles: FileList;

  constructor(
    public app: AppService,
    private api: ApiService,
    public router: Router,
    public renderer: Renderer2,
    public route: ActivatedRoute,
    public utils: UtilsService,
    private fileService: FileService
  ) {}

  ngOnInit() {
    // the hazard id may already be set by the hazard edit component dialog
    if (!this.erp.id && !this.erp.site_id) {
      // get the hazard id from the url
      this.erp.id = Number(this.route.snapshot.params['erp_id']);
    }

    if (this.erp.id) {
      this.find();
    }
  }

  onRemoveFiles() {
    this.utils.showComponentDialog(
      FilesRemoveComponent,
      this.erp.files,
      {
        width: '500px'
      },
      () => this.find()
    );
  }

  async onSubmit(form: NgForm, callback?: any) {
    // make sure the form data is valid
    if (form.valid) {
      // Prevents changing external state.
      const erpCopy = { ...this.erp };
      erpCopy.files = await this.fileService.createManyFromForm(this.newFiles);

      // check if the site should be created or updated
      if (this.erp.id) {
        // update the existing hazard
        this.api.laravelApiRequest(
          'put',
          'erps/' + this.erp.id,
          erpCopy,
          {},
          () => {
            this.utils.showToast('The emergency response plan was updated.');
            this.newFiles = null;
            this.ngOnInit();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      } else {
        // create the new hazard
        this.api.laravelApiRequest(
          'post',
          'erps',
          erpCopy,
          {},
          (response) => {
            this.utils.showToast('The emergency response plan was created.');

            this.erp.id = response.data.id;

            if (typeof callback === 'function') {
              callback();
            }

            // only the dialog hazard edit screen will a hazard id and a site id
            if (!this.erp.site_id) {
              this.router.navigate(['/erps']);
            }
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    } else {
      alert('Please make sure you enter all fields with valid information.');
    }
  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.erp.selected_site_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.erp.selected_site_ids = results;
        }
      }
    );
  }

  onRemove() {
    this.utils.showModal(
      'Remove ERP',
      'Are you sure you want to remove this emergency response plan?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'erps/' + this.erp.id,
          {},
          {},
          () => {
            this.utils.showToast('The emergency response plan was removed');
            this.router.navigate(['erps']);
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  private find() {
    this.api.laravelApiRequest(
      'get',
      'erps/' + this.erp.id,
      {},
      {},
      (response) => {
        this.erp.apply(response.data);
      },
      (error) => {
        this.utils.showModal('Error', error.message);
      }
    );
  }
}
