import { Component, OnInit } from '@angular/core';
import {ToolboxTalkModel} from "../../models/toolbox-talk.model";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ToolboxTalksEditComponent} from "../toolbox-talks-edit/toolbox-talks-edit.component";

@Component({
  selector: 'app-toolbox-talks-tabs',
  templateUrl: './toolbox-talks-tabs.component.html',
  styleUrls: ['./toolbox-talks-tabs.component.scss']
})
export class ToolboxTalksTabsComponent implements OnInit {

  toolbox_talk: ToolboxTalkModel = new ToolboxTalkModel();
  path: string;
  urlPath: string;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    private route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    // get the toolbox talks id from the parent route params
    this.toolbox_talk.id = Number(this.route.parent.snapshot.children[0].params.toolbox_talk_id);

    this.urlPath = this.route.snapshot.url[1].path;

    if (!this.toolbox_talk.id) {
      this.redirectBack();
      return;
    }

    if(this.toolbox_talk.id) {
      this.find(this.toolbox_talk.id);
    }
  }

  private find(toolbox_talk_id: number) {
    this.api.makeRequest('get', `v1/toolbox-talks/${toolbox_talk_id}`, [], {})
      .then((response) => {
        // this.toolbox_talk.apply(response);
        this.toolbox_talk = response.data;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  public onEdit(toolbox_talk_id: number | string) {
    this.utils.showComponentDialog(ToolboxTalksEditComponent, {
      id: toolbox_talk_id
    }, {}, () => {
      this.find(Number(toolbox_talk_id));
    });
  }

  public onRemove () {
    this.utils.showModal(
      `Remove ${this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk')}`,
      `Are you sure you want to remove this ${this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk')}?`,
      () => {
        this.remove(this.toolbox_talk.id);
      }
    );
  }

  private remove(id: number) {
    this.api.makeRequest('delete', `v1/toolbox-talks/${id}`, [], {})
      .then((response) => {
        this.utils.showToast(`The ${this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk')} was removed`);
        this.redirectBack();
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  public onExport(type: string = 'pdf') {
    if ( !this.toolbox_talk.id || !this.toolbox_talk.site_id ) {
      this.utils.showModal('Error Message', 'We are unable to export the ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + '. Please try again or contact us at ' + this.utils.getSupportEmailAddress() + ' for assistance.');
      return;
    }

    this.api.makeDownloadRequest(`v1/toolbox-talks/export/${type}/` + this.toolbox_talk.id, {}, {
      site_ids: [this.toolbox_talk.site_id]
    })
      .then((response) => {
        if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // Get the current date object
        const date = new Date();

        // Create object url to handle file downloads
        const data = window.URL.createObjectURL(response);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = (this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks')) + ` - ${date.getFullYear()}${(date.getMonth()+1)}${date.getDate()}.${type}`;
        // Initiate the download
        downloadLink.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(function() {
          window.URL.revokeObjectURL(data);
        }, 300); // Minimum 300 miliseconds
      })
      .catch((errorResponse) => {
        this.utils.showModal('Error', errorResponse.message);
      });
  }

  private redirectBack() {
    this.router.navigate([`/toolbox-talks/`]);
  }

}
