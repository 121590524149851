import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import {AuditsTemplatesSelectorComponent} from "../audits-templates-selector/audits-templates-selector.component";
import {SiteAuditsFilter} from "./audits-filter.interface";

@Component({
  selector: 'app-audits-filter',
  templateUrl: './audits-filter.component.html',
  styleUrls: ['./audits-filter.component.scss']
})
export class AuditsFilterComponent implements OnInit {

  filters: SiteAuditsFilter = {
    site_ids: [],
    template_ids: [],
    date_range: []
  } as SiteAuditsFilter;

  // Determines if the template selector should be shown.
  include_template_selector: boolean = true;

  // dataSource = new AuditsFilterDataSource(); // No API calls needed
  faHardHat = faHardHat;

  constructor(
    public location: Location,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AuditsFilterComponent>
  ) {
    // set a default site selection of nothing if no selected values are passed through
    if (typeof this.data['site_ids'] == 'undefined') {
      this.data['site_ids'] = [];
    }

    // set a default time spent onsite date of nothing if no values are passed through
    if (typeof this.data['date_range'] == 'undefined') {
      this.data['date_range'] = [];
    }

    // If the site audits variable is not passed through, define a default value of [].
    if (typeof this.data['template_ids'] == 'undefined') {
      this.data['template_ids'] = [];
    }

    // Check if the audit templates selector should be shown or not.
    if ( typeof this.data['include_template_selector'] != 'undefined' ) {
      this.include_template_selector = this.data['include_template_selector'];
    } else {
      this.include_template_selector = true;
    }
  }

  ngOnInit(): void {
    // Store the inbound site ids.
    this.filters.site_ids = this.data['site_ids'];
    // Store the inbound date range.
    this.filters.date_range = this.data['date_range'];
    // Store the inbound template ids.
    this.filters.template_ids = this.data['template_ids'];
  }

  /**
   * Get the date range from the filter.
   * @param event
   */
  getOutputDateRange(event: any): void {
    this.filters.date_range = event;
  }

  /**
   * Displays a dialog to select one or more sites.
   *
   * @returns void
   */
  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.filters.site_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (site_ids: number[]): void => {
        if ( typeof site_ids != 'undefined' ) {
          this.filters.site_ids = site_ids;
        }
      }
    );
  }

  /**
   * Open the site audit template selector.
   */
  onSelectSiteAuditTemplate() {
    // Open the audits templates selector and allow the selection of multiple templates.
    this.utils.showComponentDialog(AuditsTemplatesSelectorComponent, {
      selected: this.filters.template_ids,
      multiple: true
    })
      .then((response) => {
        // If there are no response, ignore it.
        if ( !response ) {
          return;
        }
        // The response is expected to be an array.
        this.filters.template_ids = response;
      });
  }

  /**
   * Clears the filters object properties and calls the onSaveAndClose method.
   *
   * @return {void}
   */
  onClearAndClose() {
    // Clear the filters object properties.
    this.filters.site_ids = [];
    this.filters.date_range = [];
    this.filters.template_ids = [];

    this.onSaveAndClose();
  }

  /**
   * Saves the filter data and closes the dialog.
   *
   * @return {void}
   */
  onSaveAndClose() {
    this.dialogRef.close(this.filters);
  }
}
