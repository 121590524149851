import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../shared/utils.service';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {

  versionNotebooks = [
    {
      heading: 'Web Portal',
      notes: [
        {
          heading: 'Version 2.6.6 (Current)',
          items: [
            'A single safety manager or trainer first aider can now be selected and the site emergency evacuation plan map can be changed.',
            'When users are unassigned from forms, the system will "remember" their unassigned status. Unassigned users will not automatically be reassigned via any of the automatic form assignment processes. Unassigned users must manually be reassigned.',
            'Forms feature bug that did not evaluate empty fields responses correctly has been fixed.',
            'Fixed toolbox talk reporting errors that were triggered due to a lack of an associated site.',
            'Added the capability for super admins to restore deleted QR codes.',
            'Updated the API conditional checks for submitted forms to exclude non-required fields from validation.',
            'When changing a form field type, the field requirement status now changes to "not required" for non-required field types.',
            'Fixed a forms bug that prevented some forms to be submitted due to a lack of non-required answers.',
            'Fixed the super admin user accounts link list pagination.',
            'Fixed an error with the accounts merger that occurs when the selected primary account does not have any users associated with it.',
            'Added a tactical fix for user form submission errors where the answer fields are not present.',
            'A new site inspections & audits list is available in the single site view\'s side menu.',
            'A new incidents list is available in the single site view\'s side menu.',
            'Account admins can now dissociate investigators or reviewers from incidents.'
          ]
        }
      ]
    },
    {
      heading: 'Mobile App',
      notes: [
        {
          heading: 'Version 3.0.2 (Current)',
          items: [
            'Added an alert icon with the primary colour to make tasks from other features more noticeable on the home page.',
            'Changed the placeholder text in the site audit item comments/notes field to make more contextual sense.',
            'Added a new task list item to the tasks widget on the home page to show the number of pending TA/JSA/SWMS documents for the authenticated user.',
            'Added a new bade indicator to the site noticeboard menu to show the number of pending TA/JSA/SWMS documents for the authenticated user.',
            'Added a new information dialogue to the covid declaration form that prompts the user to contact a site manager or supervisor after signing in.',
            'Fixed the site noticeboard layout and made it mobile responsive.',
            'Fixed a forms feature bug that did not evaluate empty fields correctly.',
            'Added the capability for users to select multiple files using the file browser.',
            'Added a new badge to the root menu items to show the number of unread messages for the authenticated user.',
            'Added a new badge to the site menu items for forms to show the number of outstanding forms.',
            'Added site assignment validation to the toolbox talks form before sending the request to create a new toolbox talk. A toolbox talk must always be associated with a site.',
            'Fixed a forms bug that prevented some forms to be submitted due to a lack of non-required answers.',
            'General bug fixes and security updates.'
          ]
        }
      ]
    },
    {
      heading: 'Web Portal',
      notes: [
        {
          heading: 'Version 2.6.5',
          items: [
            'Account admins can now enable/disable individual account inductions.',
            'Account admins can now enable/disable account level induction notifications globally for their account. This refers to the notification account admins receive when users submit account inductions.',
            'When account admins assign a form to contractors, the form will also be assigned to the employees of the selected contractor\'s subcontractors.',
            'When copying contractor pre-qualification templates to contractors, the contractor\'s pre-qualification items will be synchronised with the items from the pre-qualification template. No existing data is removed.',
            'Site inspections & audits list can now be filtered by inspections & audits template names.',
            'Updated the time spent onsite records and csv exports to show the correctly captured covid-related information.'
          ]
        }
      ]
    },
    {
      heading: 'Mobile App',
      notes: [
        {
          heading: 'Version 3.0.0-1',
          items: [
            'New mobile app theme and UX updates.',
            'The "all sites" list now shows the most recently visited sites first.',
            'Improved the emergency evacuation plan page to show the new emergency number and AED location fields.',
            'Fixed the calling, texting and emailing buttons throughout the new mobile app.',
            'general file downloads have now been fixed.',
            'Fixed an issue that prevented some users from accessing the photo galleries when uploading files with reports.',
            'General bug fixes and security updates.',
          ]
        }
      ]
    },
    {
      heading: 'Web Portal',
      notes: [
        {
          heading: 'Version 2.6.4',
          items: [
            'Added "Last 12 Months" to the date range picker.',
            'Account admins can now add multiple first-aiders or safety managers to a site’s emergency evacuation plan.',
            'Account admins can now enable/disable automatic site sign-outs via the account configuration menu.',
            'Added new emergency number and AED location fields to the emergency evacuation plan. The default emergency number is 111.',
            'Fixed the date range filters for the messages feature.',
            'Improved the PPE selector for super admins.',
            'Super admins can now re-synchronise account inductions.',
            'Super admins can now copy hazard, hazardous substance or site audit templates between accounts.',
            'Super admins can now manage a global sites list to increase a site’s allowed geofence size. ',
          ]
        }
      ]
    },
    {
      heading: 'Version: 2.6.3',
      notes: [
        {
          heading: 'General',
          items: [
            'Simplified the use of site statuses and types by making use of language files.',
            'User accessible sites are now filtered by excluding the following statuses: Prep, Pre-Construction, Archived and Archived/Completed.',
            'Added a 1-minute delay to the safety observations notifications to allow more time for file uploads from mobile devices to complete before the email notification is sent to site managers or account admins.',
            'Bug Fix: Refactored the "sign-in/out" and "time onsite" records exports to export the data to CSV files instead. The exported data now reflects the correct data as captured in the database and is much easier to manage/update in the future.',
            'Added new email and push notifications to site managers if users entering sites answers "yes" to having cold & flu symptoms when the covid module is enabled.',
            'Updated the messages list to show the date and time the message was sent.',
            'Bug Fix: Updated the email template for after-hour notifications to show the user\'s employer instead of the account the site belongs to.',
            'Bug Fix: Fixed the networked users selector\'s list pagination that triggered errors.',
            'Bug Fix: Fixed the employees selector where it triggered errors when you tried sorting the list.',
            'Bug Fix: Fixed errors that prevented some users from receiving TA/JSA/SWMS notifications.',
            'Bug Fix: Fixed an error that occurs when a global TA/JSA/SWMS is copied into the account.',
            'Added searching and sorting for TA/JSA/SWMS workflow steps and notes.',
            'Improved the overall flow of TA/JSA/SWMS feature management.',
            'General bug fixes and security improvements.',
            'Incidents can now be filtered by contractors.',
            'Safety Observations can now be filtered by site, contractor and affected users.',
            'Changed date range selectors to show current and previous months.',
            'Upgraded date range selectors to show the following date range presets: Today, Yesterday, This Week, Last Week, This Month, Last Month, Last Three Months, Last Six Months and Custom Range.'
          ]
        }
      ]
    },
    {
      heading: 'Version: 2.6.2',
      notes: [
        {
          heading: 'General',
          items: [
            'Added CSV and PDF exports to the forms feature.',
            'Added the capability to un-assign all users from a form.',
            'The "Web Portal" in the form submission email now redirects the admin to the user\'s submitted form in the web portal.',
            'Fixed a bug when PDF forms were generated during form submissions.',
            'Updated the contractor and employees importing tools to assign forms to users.',
            'Refactored the Site Audits PDF exports.',
            'Incidents and Safety Observation CSV exports now include a list of associated files per record.',
            'Simplified Toolbox Talks exported CSV associated file list.',
            'Added a button in the Form Builder\'s "Form Preview" tab to reset the form field values. This is typically used to test form display conditions from an "unanswered" form\'s point of view.',
            'Added the capability to change field types after a field was added to the Form Builder.',
            'Refactored the incidents and Toolbox Talks PDF exports.',
            'Created a custom email validation rule to ensure only proper validated email addresses enter our database.',
            'Added the "created by" and "updated by" information to most (if not all) CSV exports.',
            'Fixed a bug where the same files in Toolbox Talks showed multiple times.',
            'General bug fixes and improvements all around.'
          ]
        }
      ]
    },
    {
      heading: 'Version: 2.6.1',
      notes: [
        {
          heading: 'General',
          items: [
            'Updated the password reset, new reported incidents and safety observation emails to have a higher priority when being processed.',
            'General form builder improvements.',
            'Form Builder: Colour coded the user form submission statuses when viewing responses.',
            'Form Builder: Newly copied fields will be placed directly below the field being copied.',
            'Form Builder: Improved API validation for forms submitted by users.',
            'Form Builder: Simplified the form save and submission buttons.',
            'Some system emails now includes buttons to the knowledge base (user guide).',
            'Fixed a timezone based date formatting and storing issue that prevented the addition of new SSSP documents without expiry dates.',
            'Added a button to remove SSSP documents.',
            'Added a new "Working at Heights" safety observation type.',
            'Added a new link to the knowledge base (user guide) in the top navigation bar.',
            'Updated the master SSSP settings and snapshot features.',
            'General bug fixes and improvements all around.',
          ]
        }
      ]
    },
    {
      heading: 'Version: 2.6.0',
      notes: [
        {
          heading: 'General',
          items: [
            'New form builder feature.',
            'Fixed a bug that prevented ' + this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') + ' from being copied between sites.',
            'Added new timezone selectors for accounts, sites and user profiles.',
            'All features that have date selectors now also have timezone selectors.',
            'System notifications now triggers based on the selected timezones in accounts and sites.',
            'All dates are now showing based on the timezone selected in your user profile.',
            'New Feature: TA/JSA/SWMS is now updated.',
            'General bug fixes and security updates.',
          ]
        }
      ]
    },
    {
      heading: 'Version: 2.5.4',
      notes: [
        {
          heading: 'General',
          items: [
            'New Industries feature (managed by SiteSoft).',
            'New Job Titles feature (managed by SiteSoft).',
            'New PPE feature (managed by SiteSoft).',
            'New Work Permits feature (managed by SiteSoft).',
            'New Tools feature.',
            'New Task Analysis feature which is focused on principal accounts. The contractors Task Analysis feature is coming soon.',
            'General bug fixes and security updates.'
          ]
        },
        {
          heading: 'API',
          items: [
            'Updated email notifications to show the ' + this.utils.getAppName() + ' logo.',
            'Updated the onboarding guides for new user signup emails.',
            'Added the onboarding guides to password reset emails.',
            'Fixed a bug that prevented ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + ' PDF exports.',
            'Site entry notifications now improved when the Covid-19 feature is enbaled.',
            'When removing contractors, their related pre-qualifications are now disabled.',
            'Added list sorting for hazards & risks CSV and PDF exports.',
            'Fixed a bug that prevented some clients from importing inspection & audit questions.',
            'Fixed an issue that incorrectly filtered the message recipients list.'
          ]
        },
        {
          heading: 'Web Portal',
          items: [
            'Fixed contractor pre-qualification bugs that prevented contractors from setting expiry dates for documents.',
            'Improved the contractor pre-qualification views for contractors to provide more relevant information for inputs.',
            'Inspection & Audit questions are now grouped by section and sorted by questions by default.',
            'Renamed Hazards to Hazards & Risks.',
            'Renamed Audits to Inspections & Audits.'
          ]
        }
      ]
    },
    {
      heading: 'Version: 2.5.3',
      notes: [
        {
          heading: 'General',
          items: [
            'New global lists of Industries, Job Titles, PPE and Work Permits are added to ' + this.utils.getAppName() + ' and can only be managed by super administrators. Industries and Job Titles can now easily be updated. PPE and Work Permits will be used in the upcoming Task Analysis feature.',
            'Updated email templates to show content slightly better.',
            'General bug fixes and security improvements.',
            'Onsite users are now at the top of user selection lists.'
          ]
        },
        {
          heading: 'API',
          items: [
            'Restructured the ' + this.utils.getAppName() + ' API in preparation for a migration to an AWS cloud based architecture in the coming months.',
            'Fixed an endpoint that loaded incomplete data for ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + ' exports from the ' + this.utils.getAppName() + ' mobile app.',
            '"Unverified" hazards that are created or reported for a site will trigger notifications to the account administrators the site belongs to.',
            'When hazards are "Verified", notifications are sent to all onsite users as well as users from the account the site belongs to.',
            'Fixed a contractor pre-qualification documents expiry status change bug.',
            'Updated contractor pre-qualification notification templates to send summarised information to account admins.',
            'General geofence related updates which includes a new timed sign-out buffer of 5 minutes. For geofenced site sign-outs, you will need to be offsite for at least 5 minutes before you will be signed out. This is to reduce GPS drifting multiple site sign-ins/outs.'
          ]
        },
        {
          heading: 'Web Portal',
          items: [
            'Updated the site map to refresh every 30 seconds with updated onsite user locations. The sign-out zone is also now visible on the map.',
            'Unique reference numbers for "sign-in/out", "currently onsite" and "time spent onsite" records are now showing in the web portal.',
            'Added list filtering capabilities (filtering by site and/or date range) to site audit reports as well as optimised the list query to load data faster.',
            this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') + ' can now be filtered by site and date range.',
            this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') + ' can now be sorted by site, "created by", "updated by" and "reported by" users in the web portal.',
            'Refactored parts of the incidents feature and added a new incident status dropdown field. Incident statuses must now be changed manually.',
            'Fixed an issue file uploads when new incidents are reported on the web portal.'
          ]
        },
        {
          heading: 'Mobile',
          items: [
            'Added multiple device support for site sign-ins, mostly focused around QR code and geofence sign-ins. You can now sign into multiple devices with your account where each device will be tracked individually.',
            'Users logged into the ' + this.utils.getAppName() + ' mobile app will now see a site sign-in reference number on the dashboard and site noticeboard when signed in. The sign-in reference number can potentially be quoted on invoices.',
            'Users logged into the ' + this.utils.getAppName() + ' mobile app can now see their site access records from the main side menu via the "Time Onsite Records" menu item.',
            'Updated the ' + this.utils.getAppName() + ' mobile app map to refresh every 30 seconds with updated onsite user locations. The sign-out zone is also now visible on the map.',
            'You can now send ' + this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') + ' to contacts when you create them from the ' + this.utils.getAppName() + ' mobile app, similar to how you can send site audits.',
            'Fixed input capitalisation issues in mobile app.',
            'Removed the Oceania banner in the mobile app.'
          ]
        },
        {
          heading: 'Visitor Kiosk',
          items: [
            'Fixed employee list data loading bugs in the ' + this.utils.getAppName() + ' Visitor Kiosk.',
            'Added auto-login to the ' + this.utils.getAppName() + ' visitor kiosk for site admins.'
          ]
        },
      ]
    },
    {
      heading: 'Version: 2.5.2',
      notes: [
        {
          heading: 'General',
          items: [
            'Data exports for all features were added/improved.',
            'Dates in exported CSV and XLSX files are now split into separate date and time fields and the date formats changed to day/month/year.',
            'New "Clone" capabilities were introduced for most template based features.',
            'Access Records and ' + this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') + ' can now be filtered by employees.',
            this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') + ' now have a new "Reported By" field to record the person that reported/conducted the ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + '.',
            'We standardised all lists and list filtering capabilities across the software.',
            'Added site ids and names to task, hazards and incident exports.',
            'Added a new view button for site ' + this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') + '.',
            'Incident downloads now contains the incident report number.',
            'Added missing site maintenance status in site list filters.',
            'Fixed a bug in the site contractor linking processes.',
            'Fixed the hazards importer which is now also using default initial and residual risk assessment values.'
          ]
        }
      ]
    },
    {
      heading: 'Version: 2.5.0',
      notes: [
        {
          heading: 'General',
          items: [
            'New Safety Observations Feature.'
          ]
        },
        {
          heading: 'API',
          items: [
            'Fixed access and time onsite records CSV exports to export bulk records.',
            'Added image thumbnails to all PDF exports.',
            'Removed the site entry notification restrictions for the pandemic feature when QR codes are scanned for site entry.'
          ]
        },
        {
          heading: 'Web',
          items: [
            'All file lists now shows image thumbnails.',
            'Standardised the ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + ' lists.',
            'You can now create new or report on existing safety observations.'
          ]
        },
        {
          heading: 'Mobile',
          items: [
            'All file lists now include image thumbnails.',
            'Updated the noticeboard to provide a better site induction acknowledgement experience.',
            'Added basic location data usage policies in the app on the login and settings pages.',
            'You can now report on and view safety observations.',
            'General bug fixes and security improvements.'
          ]
        }
      ]
    }
  ];

  constructor(
    public utils: UtilsService
  ) {}

  ngOnInit() {}
}
