<h5 mat-dialog-title>
  File Manager
  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</h5>

<mat-dialog-content class="p-3">
  <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

  <table mat-table [dataSource]="dataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
          matTooltip="Toggle select all files in this list.">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)"
          matTooltip="Toggle select this file.">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the id of the file.">
        ID #
      </th>
      <td mat-cell *matCellDef="let file">
        {{ file.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="thumbnail">
      <th mat-header-cell *matHeaderCellDef>

      </th>
      <td mat-cell *matCellDef="let file">
        <img matListAvatar class="rounded-0" style="cursor: pointer; max-width: 150px; max-height: 150px;" (click)="onOpenUrl(file.url)" [src]="file.url" [alt]="file.name" *ngIf="isImage(file.name)">
        <img matListAvatar class="rounded-0" style="cursor: pointer; max-width: 150px; max-height: 150px;" (click)="onOpenUrl(file.url)" [alt]="file.name" src="./assets/images/document-placeholder.png" *ngIf="!isImage(file.name)" />
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the name of the file.">
        File Name
      </th>
      <td mat-cell *matCellDef="let file">
        {{ file.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the file was created at.">
        Created At
      </th>
      <td mat-cell *matCellDef="let file">
        {{ (file.date_created * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let file">
        <button type="button" mat-button (click)="onOpenUrl(file.url)" matTooltip="Click this button to view the file.">
          View
        </button>

        <button type="button" mat-button ngxClipboard [cbContent]="file.url" (click)="onCopiedURL()" matTooltip="Click this button to copy the file URL to your clipboard.">
          Copy URL
        </button>

        <button type="button" mat-button (click)="onRemoveUploadedFile(file.id, $event)" matTooltip="Click this button to delete the file.">
          Delete
        </button>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
  </table>

  <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions>
  <app-file-browse-button (selectedFiles)="onSelectFiles($event)" [filesCount]="dataSource?.total | async"></app-file-browse-button>

  <button mat-button mat-dialog-close>Done</button>
</mat-dialog-actions>
