import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { DynamicFormsCategoriesEditComponent } from '../dynamic-forms-categories-edit/dynamic-forms-categories-edit.component';

@Component({
  selector: 'app-dynamic-forms-categories-selector',
  templateUrl: './dynamic-forms-categories-selector.component.html',
  styleUrls: ['./dynamic-forms-categories-selector.component.scss']
})
export class DynamicFormsCategoriesSelectorComponent implements OnInit, AfterViewInit {

  // Columns to display in table.
  displayedColumns: string[] = [
    'select',
    'id',
    'category',
    'date_created',
    'date_modified'
  ];

  // Define the datasource.
  dataSource: DynamicFormsCategoriesSelectorDataSource;

  // The paginator and sorter.
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public dialogRef: MatDialogRef<DynamicFormsCategoriesSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utils: UtilsService
  ) {
    // Check if multiple selection is defined.
    if (typeof this.data['multiple'] == 'undefined') {
      this.data['multiple'] = false;
    }

    // Construct the datasource.
    this.dataSource = new DynamicFormsCategoriesSelectorDataSource(this.app, this.api, this.data['multiple']);

    // Check if the default selection is defined.
    if (typeof this.data['selected'] == 'undefined') {
      this.data['selected'] = [];
    }

    // Select pre-selected records.
    this.dataSource.selection.select(...this.data['selected']);
  }

  ngOnInit() {
    // Call the API and get the data.
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData(true);
    })).subscribe();
  }

  // Clears the search bar and performs a search
  onClearSearch() {
    // clear the search field in the datasource
    this.dataSource.search = '';
    // search for records
    this.dataSource.getData(true);
  }

  // Clear the selected records.
  onClearSelected() {
    this.dataSource.selection.clear();
  }

  /**
   * Always return an array when the select and close button is pressed.
   */
   onSelectAndClose() {
    // Define an empty response.
    let response: number[];

    if ( this.data['multiple'] ) {
      response = this.dataSource.selection.selected;
    } else {
      // Return the first selection if mutilple selections are disabled.
      if (this.dataSource.selection.selected.length) {
        response = [this.dataSource.selection.selected[0]];
      } else {
        response = [];
      }
    }

    // Send the response.
    this.dialogRef.close(response);
  }

  /**
   * Open the category dialog to add a new or update an existing category.
   * Updating an existing category is not available yet.
   * @param category_id The category id.
   */
   onEdit(category_id?: number) {
    this.utils.showComponentDialog(DynamicFormsCategoriesEditComponent, {
      category_id: category_id
    }, {
      width: '400px'
    })
    .then((response) => {
      // When the dialog closes and a new category response is received, select it in the list.
      if ( response && response.id ) {
        this.dataSource.selection.select(response.id);
      }
      // Refresh the list data.
      this.dataSource.getData();
    });
  }
}

export class DynamicFormsCategoriesSelectorDataSource extends ApiDataSource {

  order_by = 'category';
  order = 'asc';

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/dynamic-forms-categories`, resetOffset);
  }
}
