import {
  Component,
  Inject,
  OnInit,
  Optional
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { HazardsEditComponent } from 'src/app/hazards/hazards-edit/hazards-edit.component';
import { HazardousSubstanceModel } from '../../models/hazardous-substance.model';
import { UtilsService } from 'src/app/shared/utils.service';
import {ApiRequestService} from "../../shared/api-request.service";
import {SiteHazardousSubstanceLinkModel} from "../../models/site-hazardous-substance-link.model";

@Component({
  selector: 'app-sites-hazardous-substances-link-dialog',
  templateUrl: './sites-hazardous-substances-link-dialog.component.html',
  styleUrls: ['./sites-hazardous-substances-link-dialog.component.scss']
})
export class SitesHazardousSubstancesLinkDialogComponent implements OnInit {

  hazardous_substance: HazardousSubstanceModel = new HazardousSubstanceModel();
  hazardous_substance_link: SiteHazardousSubstanceLinkModel = {} as SiteHazardousSubstanceLinkModel;

  hazardous_substance_id: number;
  site_id: number;

  inProgress: boolean = false;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    @Optional() public dialogRef: MatDialogRef<HazardsEditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit() {
    // Store the hazardous substance.
    this.hazardous_substance = this.dialogData['hazardous_substance'];

    // Store the hazardous substance id.
    this.hazardous_substance_id = this.dialogData['hazardous_substance_id'];

    // Store the site id.
    this.site_id = this.dialogData['site_id'];

    // Make an API request to get the hazardous substance inventory info.
    this.api.makeRequest('get', `v1/sites/${this.site_id}/hazardous-substances/${this.hazardous_substance_id}`)
      .then((response) => {
        // Store the hazardous substance.
        this.hazardous_substance = response;
        // Store the hazardous substance pivot data. This is the link between the site and hazardous substance.
        this.hazardous_substance_link = response.pivot;
      })
      .catch((errorResponse) => {
        this.utils.showToast('Failed to load the hazardous substance\'s inventory.');
        // Close the dialog.
        this.onCancel();
      });
  }

  /**
   * Send a request to the API to update the hazardous substance inventory data.
   * @param form
   */
  async onSubmit(form: NgForm) {
    // Check if the submitted form is valid.
    if ( !form.valid ) {
      this.utils.showModal('Error', 'Please enter valid information in the provided fields.');
      return;
    }
    // Check if the quantity does not exceed the max quantity allowed.
    if ( this.hazardous_substance_link.quantity > this.hazardous_substance_link.max_quantity_allowed ) {
      this.utils.showModal('Error', 'The quantity held on site may not exceed the max allowed quantity.');
      return;
    }
    // Make a post request to update the inventory info.
    this.api.makeRequest('put', `v1/sites/${this.site_id}/hazardous-substances/${this.hazardous_substance_id}`, this.hazardous_substance_link)
      .then((response) => {
        this.utils.showToast('The hazardous substance inventory was updated.');
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
