import { CollectionViewer, SelectionModel } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AppService } from '../app.service';
import { ApiService } from '../shared/api.service';

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class CustomDataSource implements DataSource<any> {

  public dataSubject = new BehaviorSubject<any[]>([]);

  private loadSubject = new BehaviorSubject<boolean>(false);

  /**
   * Total number of data objects as a BehaviourSubject.
   */
  private totalSubject = new BehaviorSubject<number>(0);

  public loading = this.loadSubject.asObservable();

  /**
   * Total number of data objects returned.
   */
  public total = this.totalSubject.asObservable();

  /**
   * Page size for data requests.
   */
  limit = 30;

  /**
   * Page offset for data requests.
   */
  offset = 0;

  /**
   * List of page sizes the user may choose from.
   */
  pageSizeOptions: number[] = [1, 30, 60, 90, 120];

  /**
   * Field to sort data by.
   */
  sort_by = 'name';

  /**
   * Direction to sort data by.
   */
  sort_order = 'asc';

  /**
   * String to search the data for.
   */
  search = '';

  /**
   * Used for selecting rows in a table.
   */
  selection = new SelectionModel<any>(this.multiple, []);

  /**
   * Unique ID's for lists stored here.
   */
  ids: number[] = [];

  constructor(
    public app: AppService,
    public api: ApiService,
    public multiple: boolean = true,
    public data: any = {}
  ) {
    // add aditional custom data
    Object.keys(data).forEach((key) => {
      this[key] = data[key];
    });
  }

  connect(collectionViewer?: CollectionViewer): Observable<any> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer?: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadSubject.complete();
    this.totalSubject.complete();
  }

  /**
   * check if all records in the current data source is selected.
   */
  isAllSelected() {
    // default value of true
    let isAllSelectedStatus = true;
    // loop through all records in the data source and check if it is selected
    this.dataSubject
      .subscribe((records: any) => {
        records.forEach((record) => {
          // check if the record is not selected
          if (!this.selection.isSelected(record.id)) {
            // if the record is not selected, not all records are selected
            isAllSelectedStatus = false;
          }
        });
      })
      .unsubscribe(); // unsubscribe required to prevent this code from executing multiple times
    // return the status
    return isAllSelectedStatus;
  }

  /**
   * the master toggle will select or deselect all records from the current data source records.
   */
  selectAllToggle() {
    // check if all records from the current data source is selected
    if (this.isAllSelected()) {
      // if all is selected we want to deselect all records from the current data source
      this.dataSubject
        .subscribe((records: any) => {
          records.forEach((record) => {
            this.selection.deselect(record.id);
          });
        })
        .unsubscribe(); // unsubscribe required to prevent this code from executing multiple times
    } else {
      // if not all records are selected, we want to select all records
      this.dataSubject
        .subscribe((records: any) => {
          records.forEach((record) => {
            this.selection.select(record.id);
          });
        })
        .unsubscribe(); // unsubscribe required to prevent this code from executing multiple times
    }
  }
  selectAllToggleObjects() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSubject.subscribe((records) =>
          records.forEach((record) => this.selection.select(record))
        );
  }

  /**
   * deselect all selected records.
   */
  deselectAll() {
    // clear all selected recotds
    this.selection.clear();
  }

  /**
   * Row toggling should only happen when multi select is enabled.
   * @param id The row id that should be toggled.
   */
  toggleRowSelection(id: number) {
    if ( this.multiple ) {
      this.selection.toggle(id);
    }
  }

  /**
   * Check if a row is selected.
   * @param id The row id that should be checked.
   */
  isSelected(id: number) {
    return this.selection.selected.indexOf(id) > -1;
  }

  /**
   *
   * @param endpoint
   * @param endpoint
   * @param callback
   * @param resetOffset should the offset be reset
   *
   * get the data to populate the data source.
   * If a search is performed, we want to reset the offset.
   * @param callback
   */
  getDataFromAPI(
    endpoint: string,
    resetOffset: boolean = false,
    callback?: any
  ) {
    // update the loader
    this.loadSubject.next(true);

    // reset the offset if it need to
    if (resetOffset) {
      this.offset = 0;
    }

    // check if there are params that are already added
    if (endpoint.indexOf('?') === -1) {
      endpoint += '?';
    } else {
      endpoint += '&';
    }

    // make an API request to get data
    this.api
      .requestObservable(
        'get',
        endpoint +
          'limit=' +
          this.limit +
          '&offset=' +
          this.offset +
          '&sort_by=' +
          this.sort_by +
          '&order=' +
          this.sort_order +
          '&search=' +
          this.search
      )
      .pipe(
        catchError(() => {
          return of([]);
        }),
        finalize(() => {
          this.loadSubject.next(false);
        })
      )
      .subscribe((response) => {
        // store the response in subjects
        this.dataSubject.next(response.data);

        if (typeof response.total !== 'undefined') {
          this.totalSubject.next(response.total);
        }

        if (typeof callback === 'function') {
          callback(response);
        }
      });
  }

  /**
   *
   * @param endpoint
   * @param endpoint
   * @param callback
   * @param params
   * @param endpoint
   * @param callback
   * @param params
   * @param resetOffset should the offset be reset
   *
   * get the data to populate the data source.
   * If a search is performed, we want to reset the offset.
   * @param callback
   * @param params
   */
  getDataFromLaravelAPI(
    endpoint: string,
    resetOffset: boolean = false,
    callback?: any,
    params?: any
  ) {
    // update the loader
    this.loadSubject.next(true);

    // reset the offset if it need to
    if (resetOffset) {
      this.offset = 0;
    }

    // check if there are params that are already added
    if (endpoint.indexOf('?') === -1) {
      endpoint += '?';
    } else {
      endpoint += '&';
    }

    // make an API request to get data
    this.api
      .laravelApiObservable(
        'get',
        endpoint +
          'limit=' +
          this.limit +
          '&offset=' +
          this.offset +
          '&sort_by=' +
          this.sort_by +
          '&order=' +
          this.sort_order +
          '&search=' +
          this.search,
        {},
        {},
        params
      )
      .pipe(
        catchError(() => {
          return of([]);
        }),
        finalize(() => {
          this.loadSubject.next(false);
        })
      )
      .subscribe((response) => {
        // store the response in subjects
        this.dataSubject.next(response.data);

        if ( typeof response.meta !== 'undefined' ) {
          // store the total number of records
          if ( typeof response.meta.total !== 'undefined' ) {
            this.totalSubject.next(response.meta.total);
          } else {
            this.totalSubject.next(response.total);
          }

          // store collected ids
          if ( typeof response.meta.ids !== 'undefined' ) {
            this.ids = response.meta.ids;
          }
        } else {
          if ( typeof response.total != 'undefined' ) {
            this.totalSubject.next(response.total);
          }
        }

        if (typeof callback === 'function') {
          callback(response);
        }
      });
  }



  

  protected export(table: string, params = {}): Observable<Blob> {
    const endpoint =
      `excel-exports/${table}?` +
      'limit=2147483647' +
      '&offset=0' +
      '&sort_by=' +
      this.sort_by +
      '&order=' +
      this.sort_order +
      '&search=' +
      this.search;
    return this.api.laravelApiDownloadObservable(endpoint, {}, params);
  }
}
