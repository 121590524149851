import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MATERIAL_MODULES } from '../app.const';
import { PipesModule } from '../pipes/pipes.module';
import { AuditItemsEditComponent } from './audit-items-edit/audit-items-edit.component';
import { AuditItemsRoutingModule } from './audit-items-routing.module';
import { AuditItemsComponent } from './audit-items.component';
import { AuditItemsTemplateViewComponent } from './audit-items-template-view/audit-items-template-view.component';
import { AuditItemsTemplateComponent } from './audit-items-template/audit-items-template.component';
import { AuditItemsTemplateEditComponent } from './audit-items-template-edit/audit-items-template-edit.component';
import { FilesModule } from '../files/files.module';

@NgModule({
  imports: [
    CommonModule,
    AuditItemsRoutingModule,
    FormsModule,
    ...MATERIAL_MODULES,
    PipesModule,
    FilesModule
  ],
  declarations: [
    AuditItemsComponent,
    AuditItemsEditComponent,
    AuditItemsTemplateViewComponent,
    AuditItemsTemplateComponent,
    AuditItemsTemplateEditComponent
  ],
  exports: [AuditItemsComponent]
})
export class AuditItemsModule {}
