<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button mat-raised-button routerLink="/sites" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
  </button>

  <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
  </button>

  <button mat-raised-button [routerLink]="utils.prepareSiteRelatedLink(site_id, child_id ? parent_id : null, 'edit/codes')" appMarginRight>
    <mat-icon>arrow_back</mat-icon> QR Codes
  </button>
</nav>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class="mr-auto">
    <button
      mat-raised-button
      [routerLink]="utils.prepareSiteRelatedLink(site_id, child_id ? parent_id : null, 'edit/codes/' + code.id + '/edit')"
      *ngIf="code.id"
      appMarginRight>
      <mat-icon>edit</mat-icon> Edit
    </button>

    <button
      mat-raised-button
      (click)="onRemove()"
      *ngIf="code.id"
      appMarginRight
      color="warn" >
      <mat-icon>delete</mat-icon> Delete
    </button>
  </span>
</nav>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <ng-container *ngIf="code.feature">
        <h6>Feature</h6>
        <p>{{ code.feature }}</p>
      </ng-container>

      <ng-container *ngIf="code.type">
        <h6>Type</h6>
        <p>{{ code.type }}</p>
      </ng-container>

      <ng-container *ngIf="code.name">
        <h6>Name</h6>
        <p>{{ code.name }}</p>
      </ng-container>

      <ng-container *ngIf="code.message">
        <h6>Message</h6>
        <p>{{ code.message }}</p>
      </ng-container>
    </div>

    <div class="col-12 col-md-6" *ngIf="code.id">

      <div class="row">
        <div class="col">
          <mat-hint>Right-click on any of the two images below to save them. When the context-menu opens up, click on "Save Image As...".</mat-hint>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h6>QR Code</h6>
          <a [href]="code.code_url" target="_blank">
            <img [src]="code.code_url" class="img-fluid img-thumbnail" />
          </a>
        </div>

        <div class="col-6">
          <h6>Branded QR Code</h6>
          <a [href]="code.code_branded_url" target="_blank">
            <img [src]="code.code_branded_url" class="img-fluid img-thumbnail" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
