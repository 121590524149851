import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MATERIAL_MODULES } from '../app.const';
import { AuditItemsModule } from '../audit-items/audit-items.module';
import { PipesModule } from '../pipes/pipes.module';
import { AuditItemsSelectorComponent } from './audit-items-selector/audit-items-selector.component';
import { AuditsEditComponent } from './audits-edit/audits-edit.component';
import { AuditsRoutingModule } from './audits-routing.module';
import { AuditsTemplatesEditComponent } from './audits-templates-edit/audits-templates-edit.component';
import { AuditsTemplatesComponent } from './audits-templates/audits-templates.component';
import { AuditsViewComponent } from './audits-view/audits-view.component';
import { AuditsComponent } from './audits.component';
import { AuditsTemplatesViewComponent } from './audits-templates-view/audits-templates-view.component';
import { AuditsViewItemsComponent } from './audits-view/audits-view-items/audits-view-items.component';
import { FilesModule } from '../files/files.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuditsFilterComponent } from './audits-filter/audits-filter.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {SharedModule} from "../shared/shared.module";
import {AuditsTemplatesSelectorComponent} from "./audits-templates-selector/audits-templates-selector.component";
import {ChartsModule} from "../charts/charts.module";

@NgModule({
    imports: [
        CommonModule,
        AuditsRoutingModule,
        FormsModule,
        AuditItemsModule,
        ...MATERIAL_MODULES,
        PipesModule,
        FilesModule,
        FontAwesomeModule,
        BsDatepickerModule,
        SharedModule,
        ChartsModule
    ],
  declarations: [
    AuditsComponent,
    AuditsViewComponent,
    AuditsEditComponent,
    AuditItemsSelectorComponent,
    AuditsTemplatesComponent,
    AuditsTemplatesEditComponent,
    AuditsTemplatesViewComponent,
    AuditsViewItemsComponent,
    AuditsFilterComponent,
    AuditsTemplatesSelectorComponent
  ],
  entryComponents: [
    AuditItemsSelectorComponent,
    AuditsViewItemsComponent,
    AuditsFilterComponent,
    AuditsTemplatesSelectorComponent
  ]
})
export class AuditsModule {}
