import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiResponseModel } from '../models/api-response.model';
import { SiteModel } from '../models/site.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SitesService {
  private readonly resourceUrl = 'sites';

  constructor(private api: ApiService) {}

  public find(
    id: number,
    eager_load = false
  ): Observable<ApiResponseModel<SiteModel>> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl + `/${id}`,
      {},
      {},
      { eager_load: eager_load }
    );
  }

  public update(site: SiteModel): Observable<ApiResponseModel<SiteModel>> {
    return this.api.laravelApiObservable(
      'put',
      this.resourceUrl + `/${site.id}`,
      site
    );
  }

  public delete(ids: number[]): Observable<{}> {
    return this.api.laravelApiObservable(
      'delete',
      this.resourceUrl + '/' + ids.join(',')
    );
  }
}
