import { Auditable } from "src/app/shared/auditable.model";

export class TimeOnsiteRecordModel extends Auditable {

  id: number = 0;
  site_id: number = 0;
  user_id: number = 0;
  time_spent_onsite: number = 0; // Convert from hours to seconds.
  user_account_id: number = 0;
  date_created: number = 0; // The date the record should be reflected as.

  duration_in_hours: number = 0; // This is just used by the form to capture user entered hours. The API converts it to seconds.

  // Initially for viewing only
  site_name = '';
  contact_person = '';
  reason = '';
  duration = 0;
  date_first_entry = 0;
  date_last_exit = 0;
  acknowledged = false;
  onsite = false;

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.site_name = '';
    this.contact_person = '';
    this.duration = 0;
    this.date_first_entry = 0;
    this.date_last_exit = 0;
    this.reason = '';
    this.acknowledged = false;
    this.onsite = false;
  }
}
