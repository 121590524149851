<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="button-row">
    <button mat-raised-button routerLink="../..">
      <mat-icon>arrow_back</mat-icon> Inspection/Audit Questions
    </button>
    <button
      mat-raised-button
      routerLink="/audit-items/{{ auditItem.id }}/view"
      *ngIf="auditItem?.id">
      <mat-icon>remove_red_eye</mat-icon>
      View
    </button>
    <button mat-raised-button (click)="delete()" *ngIf="auditItem?.id">
      <mat-icon>delete</mat-icon>
      Delete
    </button>
  </div>
</nav>

<div class="container-fluid" class="app-padding">
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <h6>Question</h6>
          </div>

          <form #form="ngForm" (ngSubmit)="onSubmit()" class="card-body">
            <mat-form-field class="audit-item-field">
              <input
                matInput
                placeholder="Question"
                [(ngModel)]="auditItem.name"
                name="name"
                type="text"
                required
                #name />
              <mat-hint>Please enter an question that can be answered with Yes/No/Partial/NA.</mat-hint>
            </mat-form-field>

            <mat-form-field class="audit-item-field">
              <input
                matInput
                placeholder="Section"
                [(ngModel)]="auditItem.section"
                name="section"
                type="text"
                required
                #section />
              <mat-hint>Please enter a section.</mat-hint>
            </mat-form-field>

            <mat-form-field class="audit-item-field">
              <input
                matInput
                placeholder="Notes"
                [(ngModel)]="auditItem.note"
                name="note"
                type="text" />
            </mat-form-field>

            <button
              mat-raised-button
              [disabled]="!form.valid"
              color="primary"
              type="submit" >
              <mat-icon>save</mat-icon>
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
