<div mat-dialog-title class="position-relative w-100">
    <h5>Filter Codes</h5>

    <button mat-icon-button class="position-absolute" matTooltip="Close codes filter" mat-dialog-close style="top: -10px;right: 0">
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </div>

  <div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">

    <mat-form-field class="mb-3" style="width: 225px;">
      <mat-select class="site-filter" [(ngModel)]="filters.search_by" placeholder="Search By" name="searchBy">
        <mat-option value="id">Short Hash</mat-option>
        <mat-option value="name">Name</mat-option>
        <mat-option value="site">{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}</mat-option>
        <mat-option value="account">Account</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 225px;" floatLabel="always" class="mb-3">
      <mat-label>Show Active or Archived Codes.</mat-label>
      <mat-select [(ngModel)]="filters.only_archived" matTooltip="Filter codes by archival status." name="only_archived">
        <mat-option value="false">All Active</mat-option>
        <mat-option value="true">Only Archived</mat-option>
      </mat-select>
      <mat-hint>Use this to filter between active and archived codes.</mat-hint>
    </mat-form-field>

  </div>

  <div class="d-flex justify-content-center my-2 py-2">

    <button type="button" mat-raised-button class="float-right" appMarginRight color="primary" (click)="onSaveAndClose()">
      <mat-icon>filter_list</mat-icon> Apply Filter
    </button>

    <button type="button" mat-raised-button color="secondary" class="float-right" (click)="onClearAndClose()">
      <mat-icon> clear_all</mat-icon> Clear Filter
    </button>
  </div>
