<div mat-dialog-title>
  <h5>{{ risk_type + ' ' + title }}</h5>
</div>

<mat-dialog-content>
  <app-risk-matrix (selectedRiskLevel)="getRiskLevel($event)" [enableInteractiveMode]="true" [gridData]="chartData" [riskLevelData]="risk_level" [riskMatrixTitle]="title"></app-risk-matrix>
</mat-dialog-content>

<mat-dialog-actions appMarginTop>
  <button
    mat-raised-button
    style="max-width: none;"
    color="primary"
    class="save-button"
    matTooltip="Select the Likelihood and {{ utils.getLangTerm('hazards.form.severity', 'Severity') }} to map the risk level using the risk matrix for the {{ risk_type }} risk assessment."
    (click)="saveRiskLevel()"
    id="save">
    <mat-icon>save</mat-icon>
    Use Risk Level
  </button>

  <span class="mr-auto"></span>

  <button
    mat-raised-button
    color="secondary"
    [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
    Cancel
  </button>
</mat-dialog-actions>
