<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col">
      <h5 id="title">Welcome to {{ utils.getAppName() }}.</h5>
      <p>
        This web portal is used to administer our mobile and tablet apps:
      </p>
      <ul>
        <li><a [href]="utils.getAndroidMobileAppStoreUrl()" target="_blank">Android {{ utils.getAppName() }} App</a></li>
        <li><a [href]="utils.getAndroidKioskAppStoreUrl()" target="_blank">Android {{ utils.getAppName() }} Visitor Kiosk App</a></li>
        <li><a [href]="utils.getIosMobileAppStoreUrl()" target="_blank">iOS {{ utils.getAppName() }} App</a></li>
        <li><a [href]="utils.getIosKioskAppStoreUrl()" target="_blank">iOS {{ utils.getAppName() }} Visitor Kiosk App</a></li>
      </ul>
    </div>
  </div>
</div>
