<div mat-dialog-title class="p-3">
  <h5>
    Create from Template Wizard
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
</div>

<div mat-dialog-content class="p-3">
  <form (submit)="onSubmitForm(f)" #f="ngForm">

    <p>Use this creation wizard to create forms from templates. If you do not see any {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} or templates, get in touch with your {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} supervisor.</p>

    <p><b>1) Use the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} selector below to select a {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} from the list of {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} you have access to.</b></p>

    <button mat-raised-button color="secondary" type="button" (click)="onSelectSiteFromAccessibleSite()" matTooltip="Select a {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} from a list of {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} you have access to.">Select {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}</button>

    <ng-container *ngIf="selected_site.id">

      <div class="alert alert-info" role="alert" appMarginTop>
        <p>
          You selected the following {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }}: <br>
          Name: {{ selected_site.name }} <br>
          Address: {{ selected_site.location }}
        </p>
      </div>

      <p><b>2) Select a form template to use.</b></p>

      <button mat-raised-button color="secondary" type="button" (click)="onSelectFormFromTemplates()" matTooltip="Select a form from a list of templates.">Select Form from Templates</button>

      <div class="alert alert-info" role="alert" *ngIf="selected_form.id" appMarginTop>
        <p>
          You selected the following form template: <br>
          Name: {{ selected_form.title }} <br>
          Description: {{ selected_form.description }}
        </p>
      </div>

    </ng-container>
  </form>
</div>

<mat-dialog-actions class="d-flex justify-content-end">
  <button mat-raised-button color="primary" type="button" (click)="onSubmitForm(f)" [disabled]="!selected_site.id || !selected_form.id" matTooltip="Create a new form from the selection.">Create & Open Form</button>
</mat-dialog-actions>
