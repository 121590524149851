<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">Form Categories</span>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button type="button" mat-raised-button color="primary" id="add" (click)="onEdit()" appMarginRight>
          <mat-icon>add</mat-icon> Add Category
        </button>

        <mat-menu #appMenu="matMenu">
          <button type="button" mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onArchiveSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>archive</mat-icon> Archive Selected
          </button>
          <button type="button" *ngIf="dataSource.only_archived == 'true'" mat-menu-item (click)="onRestoreSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>restore</mat-icon> Restore Selected
          </button>
        </mat-menu>

        <button type="button" mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to perform list actions." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>
      </span>

      <button type="button" mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="chartsUtils.onOpenMasterCharts('Forms')" matTooltip="Click this button to open the charts.">
        <mat-icon>bar_chart</mat-icon> Charts
      </button>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
        <mat-form-field class="mx-3" style="width: 180px;">
          <mat-select [(ngModel)]="dataSource.only_archived" matTooltip="Filter categories by archival status." name="only_archived" (selectionChange)="dataSource.selection.clear(); dataSource.getData(true);">
            <mat-option value="false">All Active</mat-option>
            <mat-option value="true">Only Archived</mat-option>
          </mat-select>
        </mat-form-field>

        <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search" aria-label="Search" id="search-input" matTooltip="Search for categories by name." />

        <button mat-raised-button color="secondary" appMarginRight id="search-button" matTooltip="Run the current search">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <app-dynamic-forms-charts-container [baseStorageKey]="'dynamic-forms-categories'"></app-dynamic-forms-charts-container>

  <div class="mat-elevation-z8 scroll-box">

    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="category" matSortDirection="asc" matSortDisableClear>

      <caption class="px-3">This is a list of all form categories in your account.</caption>

      <!-- Selection Toggle -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef id="th-select">
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Toggle selection of all categories in the visible list.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)" matTooltip="Toggle selection of this category.">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Category ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-id" matTooltip="Toggle sorting by category ID.">
          #
        </th>
        <td mat-cell *matCellDef="let category">
          {{ category.id }}
        </td>
      </ng-container>

      <!-- Category -->
      <ng-container matColumnDef="category">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-category" matTooltip="Toggle sorting by category name.">
          Category
        </th>
        <td mat-cell *matCellDef="let category">
          {{ category.category }}
        </td>
      </ng-container>

      <!-- Forms Counted -->
      <ng-container matColumnDef="forms_count">
        <th mat-header-cell *matHeaderCellDef id="th-forms_count" matTooltip="The count of forms associated with this category.">
          Forms Counted
        </th>
        <td mat-cell *matCellDef="let category">
          {{ category.forms_count }}
        </td>
      </ng-container>

      <!-- Date Created -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created" matTooltip="Toggle sorting by creation date.">
          Created On
        </th>
        <td mat-cell *matCellDef="let category">
          {{ (category.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- Date Created (UTC) -->
      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created_utc" matTooltip="Toggle sorting by creation date.">
          Created On (UTC)
        </th>
        <td mat-cell *matCellDef="let category">
          {{ (category.date_created * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <!-- Date Modifeid -->
      <ng-container matColumnDef="date_modified">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_modified" matTooltip="Toggle sorting by the date the category was last updated on.">
          Last Updated On
        </th>
        <td mat-cell *matCellDef="let category">
          {{ (category.date_modified * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- Date Modifeid (UTC) -->
      <ng-container matColumnDef="date_modified_UTC">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_modified_utc" matTooltip="Toggle sorting by the date the category was last updated on.">
          Last Updated On (UTC)
        </th>
        <td mat-cell *matCellDef="let category">
          {{ (category.date_modified * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef id="th-actions"></th>
        <td mat-cell *matCellDef="let category">

          <ng-container *ngIf="!category.deleted_at else restoreOptions;">
            <button type="button" mat-button (click)="onShowFormTemplates(category.id)" matTooltip="View the form templates for this category.">
              Form Templates
            </button>

            <button type="button" mat-button (click)="onShowAssignedForms(category.id)" matTooltip="View the assigned forms for this category.">
              Assigned Forms
            </button>

            <button type="button" mat-button (click)="onView(category.id)" matTooltip="View the category.">
              View
            </button>

            <button type="button" mat-button (click)="onEdit(category.id)" matTooltip="Edit the category.">
              Edit
            </button>

            <button type="button" mat-button (click)="onArchive(category, $event)" matTooltip="Archive the category.">
              Archive
            </button>
          </ng-container>

          <ng-template #restoreOptions>
            <button type="button" mat-button (click)="onRestore(category, $event)" matTooltip="Restore the category.">
              Restore
            </button>
          </ng-template>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
