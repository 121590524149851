import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UtilsService } from 'src/app/shared/utils.service';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { TaskAnalysesEditComponent } from 'src/app/task-analyses/task-analyses-edit/task-analyses-edit.component';
import { tap } from 'rxjs/operators';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { ActivatedRoute } from '@angular/router';
import { TaskAnalysesViewComponent } from 'src/app/task-analyses/task-analyses-view/task-analyses-view.component';
import {SiteModel} from "../../models/site.model";
import {SitesTaskAnalysesFilterComponent} from "../sites-task-analyses-filter/sites-task-analyses-filter.component";
import {TaskAnalysisModel} from "../../models/task-analysis.model";

@Component({
  selector: 'app-sites-task-analyses',
  templateUrl: './sites-task-analyses.component.html',
  styleUrls: ['./sites-task-analyses.component.scss']
})
export class SitesTaskAnalysesComponent implements OnInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'sites',
    'task_name',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];
  dataSource = new TaskAnalysisDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  parent_id: number;
  child_id: number;
  site_id: number;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      this.dataSource.site_ids.push(this.site_id);

      this.dataSource.getData();
    }
  }

  onAdd() {
    this.onEdit('new');
  }

  onEdit(id: number | string) {
    this.utils.showComponentDialog(
      TaskAnalysesEditComponent,
      { task_analysis_id: id, site_id: this.site_id },
      { width: '700px' },
      (success: boolean) => {
        // if (!success) { return; }
        this.dataSource.getData(true);
      }
    );
  }

  onView(id: number) {
    this.utils.showComponentDialog(
      TaskAnalysesViewComponent,
      id,
      { width: '700px' },
      (success: boolean) => {
        // if (!success) { return; }
        // this.dataSource.getData(true);
      }
    );
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns by its corresponding date column.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onClearSearch() {
    this.dataSource.search = '';
    this.dataSource.getData(true);
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove Task Analysis Document',
      'Are you sure you want to remove this Task Analysis Document?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'task-analysis/' + id,
          {},
          {},
          (response) => {
            this.utils.showToast('The Task Analysis Document was removed.');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Remove Task Analysis Document',
      'Are you sure you want to remove the selected Task Analysis Document?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'task-analysis/' + this.dataSource.selection.selected.join(','),
          {},
          {},
          (response) => {
            this.utils.showToast('The selected Task Analysis Document was removed');
            this.dataSource.selection.clear();
            this.dataSource.getData();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  onExportSelected(type: string = 'pdf') {
    // make a request to download a document
    this.api.laravelApiDownloadRequest(
      'hazardous_substances/export/' +
        type +
        '/' +
        this.dataSource.selection.selected.join(','),
      {},
      (response) => {
        // handle internet explorer
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // get current date object
        const date = new Date();

        // handle other browsers
        const data = window.URL.createObjectURL(response);
        // create a download link
        const link = document.createElement('a');
        link.href = data;
        link.download =
          'hazardous_substances-' +
          date.getFullYear() +
          (date.getMonth() + 1) +
          date.getDate() +
          '.' +
          type;
        link.click(); // initiate the download

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(function() {
          window.URL.revokeObjectURL(data);
        }, 100);
      },
      (error) => {
        this.utils.showModal('Error', error.message);
      }
    );
  }

  onFilter() {
    this.utils.showComponentDialog(SitesTaskAnalysesFilterComponent, {
      site_ids: this.dataSource.site_ids,
      parent_site_id: this.parent_id
    }, {
      width: '768px'
    }, (results) => {
      if (typeof results !== 'undefined') {

        this.dataSource.site_ids = results.site_ids;

        if ( this.dataSource.site_ids.length === 0 ) {
          this.dataSource.site_ids.push(this.site_id);
        }

        this.dataSource.getData(true);
      }
    });
  }

  /**
   * Format the text display for associated sites.
   * Examples:
   *  - Head Office
   *  - 0 Sites
   *  - 1 Site (If name is not accessible otherwise the name of the site)
   *  - 2 Sites
   *  - Etc.
   * @param form
   */
  formatSitesListColumnText(task_analysis: TaskAnalysisModel) {
    // Check if we have one user.
    if ( task_analysis.sites_count == 1 ) {
      // Check if we have details of one user.
      if ( task_analysis.sites[0] ) {
        return task_analysis.sites[0].name;
      }
      // Respond with a generic 1 User response.
      return task_analysis.sites_count + ' ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site');
    }
    // Respond with the users count.
    return task_analysis.sites_count + ' ' + this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites');
  }

  /**
   * The tooltip text is only used if there is more than 1 site.
   * @param form
   */
  formatSitesListColumnTooltip(task_analysis: TaskAnalysisModel) {
    if ( task_analysis.sites_count > 1 ) {
      const site_names: string[] = task_analysis.sites.map((site: SiteModel) => {
        return site.name;
      });
      return site_names.join(`\n`);
    }
    return '';
  }
}

export class TaskAnalysisDataSource extends CustomDataSource {

  sort_by = 'task_name';
  sort_order = 'asc';

  site_ids: number[] = [];

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(`task-analysis`, resetOffset, () => {}, {
      site_ids: this.site_ids.join(',')
    });
  }
}
