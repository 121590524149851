<div mat-dialog-title class="position-relative w-100">
  <h5>Filter Forms</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close this dialog." mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="pt-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <button mat-raised-button color="secondary" type="button" style="width:100%;" class="mb-3" (click)="onSelectSites()" [matBadge]="filter_options.site_ids && filter_options.site_ids.length ? filter_options.site_ids.length.toString() : ''" matTooltip="Select from a list of {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} you have access to.">{{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}</button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <button mat-raised-button color="secondary" type="button" style="width:100%;" class="mb-3" (click)="onSelectUpstreamAccounts()" [matBadge]="filter_options.upstream_account_ids && filter_options.upstream_account_ids.length ? filter_options.upstream_account_ids.length.toString() : ''" matTooltip="Select from a list of upstream account from your user profile.">Companies</button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="fill" style="width:100%;">
          <mat-label>Statuses</mat-label>
          <mat-select [(ngModel)]="filter_options.statuses" name="statuses" multiple>
            <mat-option value="Assigned">Assigned</mat-option>
            <mat-option value="In Progress">In Progress</mat-option>
            <mat-option value="Submitted">Submitted</mat-option>
            <mat-option value="Pending">Pending</mat-option>
            <mat-option value="Rejected">Rejected</mat-option>
            <mat-option value="Approved">Approved</mat-option>
            <mat-option value="Approved: Work in Progress">Approved: Work in Progress</mat-option>
            <mat-option value="Work Completed">Work Completed</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="fill" style="width:100%;">
          <mat-label>Form Types</mat-label>
          <mat-select [(ngModel)]="filter_options.form_types" name="form_types" multiple>
            <mat-option value="form">Form</mat-option>
            <mat-option value="induction">Induction</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-center my-3">
  <button type="button" mat-raised-button color="primary" (click)="onSaveAndClose()">
    <mat-icon>filter_list</mat-icon> Apply Filter
  </button>

  <button type="button" mat-raised-button color="secondary" (click)="onClearAndClose()">
    <mat-icon> clear_all</mat-icon> Clear Filter
  </button>
</mat-dialog-actions>
