import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { SiteModel } from 'src/app/models/site.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';

declare var tinymce: any;

@Component({
  selector: 'app-sites-induction-edit',
  templateUrl: './sites-induction-edit.component.html',
  styleUrls: ['./sites-induction-edit.component.scss']
})
export class SitesInductionEditComponent implements OnInit, AfterViewInit {
  // the site model placeholder
  site: SiteModel = new SiteModel();

  inProgress: boolean = false;

  // Content loaded boolean is used to delay the initialisation of the TinyMCE editor.
  // This prevents an error from triggering.
  contentLoaded: boolean  = false;

  constructor(
    // public service: SitesService,
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    // Get the site id from the route params.
    const parent_id: number = Number(this.route.parent.snapshot.params['parent_id']);
    const child_id: number = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && parent_id) ) {
      // Store the site id.
      this.site.id = child_id ? child_id : parent_id;
      // get the data from the api
      this.api.laravelApiRequest('get', 'sites/' + this.site.id, {}, {}, (response) => {
          // apply the data
          this.site.apply(response.data);

          // Extract Tag ids.
          this.site.tag_ids = this.site.tags ? this.site.tags.map((tag) => tag.id) : [];

          // Change the content loaded boolean to true to notify the TinyMCE editor that it can be initialised.
          this.contentLoaded = true;
        },
        (error) => {
          this.utils.showModal('Error', error.message);
        }
      );
    }
  }

  ngAfterViewInit() {
    // This does not seem to work and is therefore commented out.
    // tinymce.execCommand('mceAddControl', false, 'induction_content');
  }

  onSubmit(form: NgForm) {
    // make sure the form data is valid
    if ( !form.valid ) {
      alert('Please make sure you enter all fields with valid information.');
      return;
    }

    if ( this.inProgress || !this.site.id) {
      return;
    }

    this.inProgress = true;

    this.api.laravelApiRequest(
      'put',
      'sites/' + this.site.id,
      this.site,
      {},
      (response) => {
        this.utils.showToast('The noticeboard was updated.');
        this.inProgress = false;
      },
      (error) => {
        this.utils.showModal('Error', error.message);
        this.inProgress = false;
      }
    );
  }
}
