<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">{{ utils.getLangTerm('sites.plural', 'Sites') }}</span>

    <span class="mr-auto">
      <button mat-raised-button tourAnchor="tour.add.site" color="primary"
        routerLink="/sites/new/edit/details" appMarginRight id="add">
        <mat-icon>add</mat-icon>
        Add
      </button>

      <button mat-raised-button  class="mr-2" color="" routerLink="/sites/importer" matTooltip="Upload a CSV of {{ utils.getLangTerm('sites.plural', 'Sites') }} to mass insert.">
          <mat-icon>cloud_upload</mat-icon> Import
      </button>

      <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="dataSource.onExport('csv')">
            <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
          </button>
          <button mat-menu-item (click)="composeNewMessage()">
            <mat-icon>send</mat-icon>
            Send Message {{ dataSource.selection.hasValue() ? 'to (' + dataSource.selection.selected.length + ') Selected' : '(0)' }}
          </button>
          <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Click this menu for more list actions." style="min-width: 150px">
          <mat-icon>more_vert</mat-icon>
          Menu
        </button>
    </span>

    <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="chartsUtils.onOpenMasterCharts(utils.getLangTerm('parent-child-sites-combined.plural', 'Sites'))" matTooltip="Click this button to open the charts.">
      <mat-icon>bar_chart</mat-icon> Charts
    </button>

    <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="onFilter()" matTooltip="Click this button to display filter options.">
      <mat-icon>filter_list</mat-icon> Filter
    </button>

    <form tourAnchor="tour.search" class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)" style="min-width: 325px">
      <input class="form-control mr-sm-2" type="search"
        [(ngModel)]="dataSource.search" name="search" placeholder="Search"
        matTooltip="Search by name or address."
        aria-label="Search" id="search-input" />

      <button mat-raised-button
              class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
              color="secondary"
              matTooltip="Run the current search"
              id="search-button">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>
  </nav>

  <app-tags-filter (tagsFilterOutput)="getOutputTagsFilter($event)"></app-tags-filter>

  <app-sites-charts-container [baseStorageKey]="'sites'"></app-sites-charts-container>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Toggle selecting all records on this page">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)"
            matTooltip="Select '{{row.name}}' for deletion.">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by ID">#</th>
        <td mat-cell *matCellDef="let element">
          {{ element.id }}
        </td>
      </ng-container>

      <!-- Name -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Name">Name</th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="utils.prepareSiteRelatedLink(element?.id, null, '/edit/details')">
            {{ (element.name | ellipsis: [30]) || '' }}
          </a>
        </td>
      </ng-container>

      <!-- Address -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Address">Address</th>
        <td mat-cell *matCellDef="let element">
          {{ element.location | ellipsis: [30] }}
        </td>
      </ng-container>

      <!-- Type -->
      <ng-container matColumnDef="site_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Type">Type</th>
        <td mat-cell *matCellDef="let element">
          <a (click)="onSiteTypeFilter(element.site_type)"
            matTooltip="Filter records by {{element.site_type}}">
            {{ element.site_type }}
          </a>
        </td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="site_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Status">Status</th>
        <td mat-cell *matCellDef="let element">
          <a (click)="onSiteStatusFilter(element.site_status)"
            matTooltip="Filter records by {{element.site_status}}">
            {{ element.site_status }}
          </a>
        </td>
      </ng-container>

      <!-- Geofence: Yes/No -->
      <ng-container matColumnDef="geofence">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Geofenced">Geofenced</th>
        <td mat-cell *matCellDef="let element">
          {{ element.geofence ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <!-- Risk Assessment -->
      <ng-container matColumnDef="site_risk_assessment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by the Risk Level.">Risk Level</th>
        <td mat-cell *matCellDef="let element">
          <span [class]="utils.getSiteRiskStatusTextColor(element.site_risk_assessment)">
            {{ element.site_risk_assessment | titlecase }}
          </span>
        </td>
      </ng-container>

      <!-- Date Created -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the record was created.">Created At</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- Date Created (UTC) -->
      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the record was created.">Created At (UTC)</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.date_created * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef tourAnchor="tour.actions">
          Actions
        </th>

        <td mat-cell *matCellDef="let element">
          <button type="button" mat-button [routerLink]="utils.prepareSiteRelatedLink(element.id, element.parent_site_id, 'children')" matTooltip="View {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}." matTooltipPosition="above" >
            {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
            <mat-icon>chevron_right</mat-icon>
          </button>

          <a type="button" mat-button [routerLink]="utils.prepareSiteRelatedLink(element.id, element.parent_site_id, 'view/details')" matTooltip="View information." matTooltipPosition="above">
            View
          </a>

          <a type="button" mat-button [routerLink]="utils.prepareSiteRelatedLink(element.id, element.parent_site_id, 'edit/details')" matTooltip="Edit information." matTooltipPosition="above">
            Edit
          </a>

          <button type="button" mat-button (click)="onRemove(element.id)" matTooltip="Click this button to archives this record." matTooltipPosition="above">
            Delete
          </button>

          <button type="button" mat-button (click)="onCloneSite(element.id)" matTooltip="Clone the record." matTooltipPosition="above">
            Clone
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator tourAnchor="tour.paginator"
      [length]="dataSource?.total | async" [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
