import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { SiteModel } from 'src/app/models/site.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-admin-site-settings',
  templateUrl: './admin-site-settings.component.html',
  styleUrls: ['./admin-site-settings.component.scss']
})
export class AdminSiteSettingsComponent implements OnInit {

  site: SiteModel = new SiteModel();

  inProgress: boolean = false;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    // Get the site id
    const site_id = Number(this.route.parent.snapshot.params['site_id']);

    if ( !site_id ) {
      this.utils.showToast('The ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() + ' id is not valid.');
      this.router.navigate(['sites']);
      return;
    }

    this.api.makeRequest('get', `v2/admin/sites/${site_id}`)
    .then((response) => {
      this.site.apply(response.data);
    })
    .catch((errorResponse) => {
      this.utils.showToast('The ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() + ' could not be found. Please try again.');
      this.router.navigate(['admin/sites']);
    });
  }

  onSave(form: NgForm) {
    if ( !form.valid ) {
      this.utils.showToast('Please make sure you enter or select all required fields.');
      return;
    }

    this.inProgress = true;

    this.api.makeRequest('put', `v2/admin/sites/${this.site.id}`, this.site)
    .then((response) => {
      this.utils.showToast('The settings were updated successfully.');
    })
    .finally(() => {
      this.inProgress = false;
    });
  }

}
