import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { IncidentNoteModel } from '../incident-note.model';

@Injectable({
  providedIn: 'root'
})
export class IncidentNoteService {
  private readonly resourceUrl = 'incident-notes';

  constructor(private api: ApiService) {}

  create(
    note: IncidentNoteModel
  ): Observable<ApiResponseModel<IncidentNoteModel>> {
    return this.api.laravelApiObservable('post', this.resourceUrl, note);
  }

  update(
    note: IncidentNoteModel
  ): Observable<ApiResponseModel<void>> {
    return this.api.laravelApiObservable(
      'put',
      this.resourceUrl + `/${note.id}`,
      note
    );
  }

  remove(ids: number[]): Observable<ApiResponseModel<void>> {
    return this.api.laravelApiObservable(
      'delete',
      this.resourceUrl + `/${ids.join(',')}`
    );
  }
}
