import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '../shared/storage.service';
import { ApiRequestService } from '../shared/api-request.service';
import { NgForm } from '@angular/forms';
import { UtilsService } from '../shared/utils.service';
import { AppService } from '../app.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetToken: string = '';

  password: string = '';
  passwordConfirmation: string = '';
  passwordValidated: boolean = false;
  passwordFieldType: string = 'password';
  confirmPasswordFieldType: string = 'password';

  constructor(
    private route: ActivatedRoute,
    private storage: StorageService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private app: AppService
  ) {}

  ngOnInit() {
    // Get the password reset token
    this.resetToken = this.route.snapshot.params['token'];
    // Validate the password reset token
    this.api.makeRequest('post', 'v2/password-reset-validation', {
      resetToken: this.resetToken
    })
    .then(() => {})
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
      this.utils.redirectPage(['/sign-in']);
    });
  }

  onChangePassword(form: NgForm) {

    if ( !form.valid ) {
      this.utils.showModal(
        'Error Message',
        'Please make sure you enter all required fields with valid formats.'
      );
      return;
    }

    this.api.makeRequest('post', 'v2/password-reset', {
      resetToken: this.resetToken,
      password: this.password,
      password_confirmation: this.passwordConfirmation
    })
    .then((response) => {
      this.handleSignInResponse(response);
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse)
    });
  }

  // Similar method defined in signin.component.ts, change-password.component.ts
  private handleSignInResponse(response) {
    if (!response.account || !response.user || !response.user_account_link || !response.api_token) {
      this.utils.showToast(
        'We could not refresh your account details. You may need to log in again.'
      );
      return;
    }

    // user is authenticated
    this.app.isAuthenticated = true;

    this.storage.set('AuthToken', response.api_token);

    this.app.user.apply(response.user);
    this.app.account.apply(response.account);
    this.app.userAccountLink.apply(response.user_account_link);

    this.utils.redirectPage(['/dashboard']);
  }

  getOutputPasswordValidated($event) {
    this.passwordValidated = $event;
  }

  /**
   * Toggles the visibility of the password field based on the given field parameter.
   *
   * @param {string} field - The name of the field to toggle visibility for ('password' or 'confirmPassword').
   * @returns {void}
   */
  togglePasswordVisibility(field: string): void {
    if (field === 'password') {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    } else if (field === 'confirmPassword') {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
    }
  }
}
