<h1 mat-dialog-title>Add Many QR Codes</h1>
<div mat-dialog-content>
  <form (submit)="addMany()" (keydown.enter)="addMany()" #form="ngForm">
    <mat-form-field>
      <input matInput
        [(ngModel)]="codesCount"
        name="codesCount"
        type="number"
        max="1000000"
        placeholder="Number"
        required/>
    </mat-form-field>

    <mat-form-field>
      <input matInput
        [(ngModel)]="name"
        name="name"
        type="text"
        maxlength="255"
        placeholder="Name"/>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-raised-button
    color="primary"
    (click)="addMany()"
    [disabled]="form?.invalid || inProgress">
    Add
  </button>

  <span class="mr-auto"></span>

  <button mat-raised-button color="secondary" [mat-dialog-close]="false">
    Cancel
  </button>
</div>
