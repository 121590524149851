<div mat-dialog-title>
  <h5>Add {{ utils.getLangTerm('employees.singular', 'Employee') }}</h5>
  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #f="ngForm" (ngSubmit)="onSubmit(f)" mat-dialog-content>

  <div class="row">
    <div class="col-12">

      <mat-form-field class="full-width mb-3">
        <mat-label>Full Name</mat-label>
        <input
          matInput
          placeholder="Full Name"
          [(ngModel)]="employee.contact_person"
          name="contact_person"
          type="text"
          id="contact_person"
          required
          dusk="dusk_contact_person"/>
        <mat-hint>Enter the full name of the {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
      </mat-form-field>

      <mat-form-field class="full-width mb-3">
        <mat-label>Email Address</mat-label>
        <input
          matInput
          placeholder="Email Address"
          [(ngModel)]="employee.email"
          name="email"
          type="email"
          id="email"
          required
          dusk="dusk_email"/>
        <mat-hint>Enter the email address of the {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
      </mat-form-field>

      <mat-form-field class="full-width mb-3">
        <mat-label>{{ utils.getLangTerm('users.form.mobile', 'Mobile Number') }}</mat-label>
        <app-phone-input
          [idInput]="mobile_input_id"
          [numberInput]="employee.mobile"
          [countryCodeInput]="employee.mobile_country_code"
          [dialCodeInput]="employee.mobile_dial_code"
          [e164NumberInput]="employee.mobile_e164"
          (phoneDetailsOutput)="getOutputPhoneDetails($event)"
          required="true">
        </app-phone-input>
        <mat-hint>Enter the {{ utils.getLangTerm('users.form.mobile', 'Mobile Number') }} of the {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
      </mat-form-field>

      <mat-form-field class="full-width mb-3">
        <mat-label>{{ utils.getLangTerm('users.form.notes', 'Notes') }}</mat-label>
        <textarea
          matInput
          rows="5"
          [placeholder]="utils.getLangTerm('users.form.notes', 'Notes')"
          [(ngModel)]="employee.pivot.notes"
          name="notes"
          id="notes"
          dusk="dusk_notes">
          </textarea>
        <mat-hint>Enter {{ utils.getLangTerm('users.form.notes', 'Notes') }} for your {{ utils.getLangTerm('employees.singular', 'Employee') }}. The notes can only be seen in this active account by administrators.</mat-hint>
      </mat-form-field>

      <mat-form-field class="full-width mb-3">
        <mat-label>Job Title</mat-label>
        <mat-select matInput placeholder="Job Title" [(ngModel)]="employee.trade" name="trade" #trade>
          <mat-option *ngFor="let trade of trades" [value]="trade.name">{{ trade.name }}</mat-option>
        </mat-select>
        <mat-hint>Select the job title of the {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
      </mat-form-field>

    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-form-field class="full-width mb-3">
        <mat-label>Address</mat-label>
        <input
          matInput
          placeholder="Address"
          [(ngModel)]="employee.address"
          name="address"
          type="text"
          id="address"
          dusk="dusk_address"/>
        <mat-hint>Enter the address of the company or {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field class="full-width mb-3">
        <mat-label>{{ utils.getLangTerm('users.form.suburb', 'Suburb') }}</mat-label>
        <input
          matInput
          [placeholder]="utils.getLangTerm('users.form.suburb', 'Suburb')"
          [(ngModel)]="employee.suburb"
          name="suburb"
          type="text"
          id="suburb"
          dusk="dusk_suburb"/>
        <mat-hint>Enter the {{ utils.getLangTerm('users.form.suburb', 'Suburb') }} of the company or {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field class="full-width mb-3">
        <mat-label>{{ utils.getLangTerm('users.form.city', 'City') }}</mat-label>
        <input
          matInput
          [placeholder]="utils.getLangTerm('users.form.city', 'City')"
          [(ngModel)]="employee.city"
          name="city"
          type="text"
          id="city"
          dusk="dusk_city"/>
        <mat-hint>Enter the {{ utils.getLangTerm('users.form.city', 'City') }} of the company or {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field class="full-width mb-3">
        <mat-label>{{ utils.getLangTerm('users.form.postal_code', 'Postal Code') }}</mat-label>
        <input
          matInput
          [placeholder]="utils.getLangTerm('users.form.postal_code', 'Postal Code')"
          [(ngModel)]="employee.postal_code"
          name="postal_code"
          type="text"
          id="postal_code"
          dusk="dusk_postal_code"/>
        <mat-hint>Enter the {{ utils.getLangTerm('users.form.postal_code', 'Postal Code') }} of the company or {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field class="full-width mb-3">
        <mat-label>Country</mat-label>
        <input
          matInput
          placeholder="Country"
          [(ngModel)]="employee.country"
          name="country"
          type="text"
          id="country"
          dusk="dusk_country"/>
        <mat-hint>Enter the country of the company or {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <button
        mat-raised-button
        [disabled]="!f.valid || mobile_error_state"
        class="mr-3"
        type="submit"
        color="primary"
        id="save_button"
        dusk="dusk_save_button">
        <mat-icon>save</mat-icon>
        Save
      </button>
    </div>
  </div>

</form>
