<div mat-dialog-title>
  <h5>Course Module Form</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
  <mat-dialog-content>

      <mat-form-field>
        <input
          matInput
          placeholder="Module Name"
          [(ngModel)]="module.name"
          name="name"
          type="text"
          #name
          required />
        <mat-error *ngIf="!name.valid">Please enter a module name.</mat-error>
      </mat-form-field>

      <editor
        placeholder="Module Introduction"
        [(ngModel)]="module.description"
        name="description"
        [init]="{
          skin_url: '/assets/skins/lightgray',
          plugins: 'link,image,lists,hr,table,media',
          height: '450'
        }">
      </editor>
      <mat-error *ngIf="!module.description">Please enter a module description.</mat-error>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      type="submit"
      mat-raised-button
      [disabled]="!f.valid || inProgress || !module.description"
      color="primary">
      <mat-icon>save</mat-icon>
      Save
    </button>

    <span class="mr-auto"></span>

    <button
      type="button"
      mat-icon-button
      [disabled]="!module.id"
      class="float-right"
      (click)="onRemove()">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-dialog-actions>
</form>
