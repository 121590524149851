<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/prequalifications">
    <span id="title">PCBU1 Pre-Qualifications</span>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <span class="mr-auto">

    </span>

    <form tourAnchor="tour.search" class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)" style="min-width: 325px">
      <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search"
             aria-label="Search" />
      <button mat-raised-button
              class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
              color="secondary"
              type="button"
              (click)="dataSource.getData(true)"
              id="searchButton">
        <mat-icon>search</mat-icon>Search
      </button>
    </form>
  </div>

</nav>

<div class="mat-elevation-z8 scroll-box">
  <table mat-table [dataSource]="dataSource" style="width: 100%" matSort matSortActive="name" matSortDisableClear
    matSortDirection="asc">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="no-padding" class="no-padding" mat-sort-header>
        #
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        {{ prequalification.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="no-padding" class="no-padding" mat-sort-header>
        Name
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        {{ prequalification.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef class="no-padding" class="no-padding">
        Client
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        {{ prequalification.account.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="score">
      <th mat-header-cell *matHeaderCellDef class="no-padding" class="no-padding">
        H&S Score
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding"
        [appColorPercent]="prequalification | prequalScore">
        {{ prequalification.score }}%
      </td>
    </ng-container>

    <ng-container matColumnDef="items">
      <th mat-header-cell *matHeaderCellDef class="no-padding" class="no-padding">
        Documents
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        {{ prequalification.items.length }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef class="no-padding" class="no-padding" mat-sort-header>
        Created At
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        {{ (prequalification.date_created * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef class="no-padding" class="no-padding" mat-sort-header>
        Created At (UTC)
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        {{ (prequalification.date_created * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_modified">
      <th mat-header-cell *matHeaderCellDef class="no-padding" class="no-padding" mat-sort-header>
        Last Updated At
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        {{ (prequalification.date_modified * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_modified_UTC">
      <th mat-header-cell *matHeaderCellDef class="no-padding" class="no-padding" mat-sort-header>
        Last Updated At (UTC)
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        {{ (prequalification.date_modified * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let prequalification">
        <button type="button" mat-button (click)="view(prequalification.id)" matTooltip="View the PCBU1 Pre-Qualifications information." matTooltipPosition="above">
          View
        </button>
        <button type="button" mat-button (click)="edit(prequalification.id)" matTooltip="Edit the PCBU1 Pre-Qualifications information." matTooltipPosition="above">
          Edit
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayed_columns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayed_columns"></tr>
  </table>

  <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons>
  </mat-paginator>
</div>
