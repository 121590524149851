<div mat-dialog-title>
  <h5>
    {{ form.title }}
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
</div>

<div mat-dialog-content class="px-3">

  <div class="alert alert-info" role="alert" *ngIf="form.pivot" style="font-size: small;">
    <div class="row">
      <div class="col-6">
        <b>Submission Status:</b> {{ currentFormStatus }} <br>
        <ng-container *ngIf="(currentFormStatus == 'Approved' || currentFormStatus == 'Approved: Work in Progress') && form.pivot.approved_by && form.pivot.approved_by_user"><b>Approved By:</b> <a (click)="onUserPublicView(form.pivot.approved_by_user.hash)" matTooltip="View User Public Profile">{{ form.pivot.approved_by_user.contact_person }}</a> <br></ng-container>
        <ng-container *ngIf="(currentFormStatus == 'Approved' || currentFormStatus == 'Approved: Work in Progress') && form.pivot.approved_on"><b>Approved At:</b> {{ (form.pivot.approved_on * 1000) | timezoneConvertMedium }} <br></ng-container>
        <ng-container *ngIf="currentFormStatus == 'Rejected' && form.pivot.rejected_by && form.pivot.rejected_by_user"><b>Rejected By:</b> <a (click)="onUserPublicView(form.pivot.rejected_by_user.hash)" matTooltip="View User Public Profile">{{ form.pivot.rejected_by_user.contact_person }}</a> <br></ng-container>
        <ng-container *ngIf="currentFormStatus == 'Rejected' && form.pivot.rejected_on"><b>Rejected At:</b> {{ (form.pivot.rejected_on * 1000) | timezoneConvertMedium }} <br></ng-container>
        <ng-container *ngIf="form.pivot.assigned_by && form.pivot.assigned_by_user"><b>Assigned By:</b> <a (click)="onUserPublicView(form.pivot.assigned_by_user.hash)" matTooltip="View User Public Profile">{{ form.pivot.assigned_by_user.contact_person }}</a> <br></ng-container>
        <ng-container *ngIf="form.pivot.assigned_on"><b>Assigned At:</b> {{ (form.pivot.assigned_on * 1000) | timezoneConvertMedium }} <br></ng-container>
        <ng-container *ngIf="form.pivot.date_modified"><b>Last Updated At / Submitted At:</b> {{ (form.pivot.date_modified * 1000) | timezoneConvertMedium }} <br></ng-container>
      </div>

      <div class="col-6 text-right">
        <ng-container *ngIf="form.user">
          <b>Submitted By:</b> <a (click)="onUserPublicView(form.user.hash)"
            matTooltip="View User Public Profile">
            {{ form.user.contact_person }}
          </a> {{ form.user.employer ? (' from ' + form.user.employer.name) : '' }}
        </ng-container>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <mat-form-field class="full-width mb-3">
          <mat-label>Notes</mat-label>
          <textarea
            matInput
            rows="5"
            placeholder="Notes"
            [(ngModel)]="form.pivot.dynamic_form_notes"
            name="dynamic_form_notes"
            id="dynamic_form_notes"
            dusk="dusk_dynamic_form_notes">
          </textarea>
          <mat-hint>Enter notes here. These notes will also be shown to the assigned user.</mat-hint>
        </mat-form-field>

        <mat-form-field class="full-width mb-3">
          <mat-label>Admin Notes</mat-label>
          <textarea
            matInput
            rows="5"
            placeholder="Admin Notes"
            [(ngModel)]="form.pivot.dynamic_form_admin_notes"
            name="dynamic_form_admin_notes"
            id="dynamic_form_admin_notes"
            dusk="dusk_dynamic_form_admin_notes">
          </textarea>
          <mat-hint>Enter admin notes here. These notes will not be shown to the assigned user.</mat-hint>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field class="full-width mb-3">
          <mat-label>Status:</mat-label>
          <mat-select [(ngModel)]="form.pivot.dynamic_form_status" name="dynamic_form_status" aria-label="Change the status of the form.">
            <mat-option value="Submitted">Submitted</mat-option>
            <mat-option value="Pending">Pending</mat-option>
            <mat-option value="Rejected">Rejected</mat-option>
            <mat-option value="Approved">Approved</mat-option>
            <mat-option value="Approved: Work in Progress">Approved: Work in Progress</mat-option>
            <mat-option value="Work Completed">Work Completed</mat-option>
          </mat-select>
          <mat-hint>Change the status of the form.</mat-hint>
        </mat-form-field>

        <p *ngIf="form?.pivot?.dynamic_form_user_comments" class="text-left mb-3">
          <b>Assigned User Comments: </b><br>
          {{ form.pivot.dynamic_form_user_comments }}
        </p>

        <div class="full-width mb-3" *ngIf="form.pivot.dynamic_form_status == 'Work Completed'">
          <mat-checkbox [(ngModel)]="form.pivot.dynamic_form_verified" name="dynamic_form_verified">Verified</mat-checkbox>
          <mat-hint><small>Check this box to verify that the work is completed. To re-verify, you must first un-verify.</small></mat-hint>
          <p *ngIf="form?.verified_by">
            Last verified by <b>{{ form?.verified_by?.contact_person }}</b> at <b>{{ (form.pivot.dynamic_form_verified_at * 1000) | timezoneConvertMedium }}</b>
          </p>
        </div>

        <div class="text-right">
          <button mat-raised-button appMarginRight type="button" (click)="onViewAuditLogEntries()" matTooltip="View audit log entries for this form and user.">
            <mat-icon>history</mat-icon> Audit Logs
          </button>

          <button mat-raised-button color="secondary" appMarginRight type="button" (click)="onExportToPDF()" matTooltip="Export this form and its user submitted data.">
            <mat-icon>import_export</mat-icon> Export to PDF
          </button>

          <button mat-raised-button color="primary" type="button" (click)="onUpdateFormNotesAndStatus()" matTooltip="Update the form notes and status.">
            <mat-icon>save</mat-icon> Update
          </button>
        </div>

      </div>
    </div>
  </div>

  <form>

    <p *ngIf="form.description != ''">{{ form.description }}</p>

    <div *ngFor="let formField of form.fields; let i = index">
      <ng-container *ngIf="evaluateConditions(formField)">
        <div [ngSwitch]="formField.field_type" appMarginBottom>

          <mat-form-field floatLabel="always" *ngSwitchCase="'input'">
            <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>

            <ng-container *ngIf="formField.field_input_type == 'tel'; else noTel">
              <app-phone-input
                [idInput]="'tel-input-' + i"
                [numberInput]="formField.field_answer.meta.mobile_e164"
                [countryCodeInput]="formField.field_answer.meta.mobile_country_code"
                [dialCodeInput]="formField.field_answer.meta.mobile_dial_code"
                [e164NumberInput]="formField.field_answer.meta.mobile_e164"
                (phoneDetailsOutput)="extractPhoneDetails($event, i)"
                disabled>
              </app-phone-input>
            </ng-container>
            <ng-template #noTel>
              <input matInput disabled [type]="formField.field_input_type" [(ngModel)]="formField.field_answer.answer" [ngModelOptions]="{standalone: true}">
            </ng-template>

            <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
          </mat-form-field>

          <mat-form-field floatLabel="always" *ngSwitchCase="'datetime'">
            <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>
            <input matInput disabled [matDatepicker]="picker" [(ngModel)]="formField.field_answer.answer" [max]="formField.field_date_range == 'past' ? currentDate : null" [min]="formField.field_date_range == 'future' ? currentDate : null" [ngModelOptions]="{standalone: true}">
<!--            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
            <mat-datepicker #picker></mat-datepicker>
            <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
          </mat-form-field>

          <mat-form-field floatLabel="always" *ngSwitchCase="'textarea'">
            <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>
            <textarea matInput disabled [(ngModel)]="formField.field_answer.answer" [ngModelOptions]="{standalone: true}"></textarea>
            <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
          </mat-form-field>

          <mat-form-field floatLabel="always" *ngSwitchCase="'select'">
            <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>
            <select matNativeControl disabled [(ngModel)]="formField.field_answer.answer" [ngModelOptions]="{standalone: true}">
              <option></option>
              <option *ngFor="let formFieldOption of formField.field_options" [value]="formFieldOption.option_text">{{formFieldOption.option_text}}</option>
            </select>
            <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
          </mat-form-field>

          <div *ngSwitchCase="'radio'">
            <span class="sc-custom-label">{{formField.field_label}} <ng-container *ngIf="formField.field_is_required == true">*</ng-container></span>
            <mat-hint *ngIf="formField.field_description" appMarginBottom>{{ formField.field_description }}</mat-hint>
            <mat-radio-group aria-label="Selectable Options" [(ngModel)]="formField.field_answer.answer" [ngModelOptions]="{standalone: true}">
              <mat-radio-button class="sc-custom-radio" appReadOnly *ngFor="let formFieldOption of formField.field_options" [value]="formFieldOption.option_text">{{formFieldOption.option_text}}</mat-radio-button>
            </mat-radio-group>
          </div>

          <div *ngSwitchCase="'checkbox'">
            <span class="sc-custom-label">{{formField.field_label}}</span>
            <mat-hint *ngIf="formField.field_description" appMarginBottom>{{ formField.field_description }}</mat-hint>
            <mat-checkbox class="sc-custom-checkbox" appReadOnly *ngFor="let formFieldOption of formField.field_options" [(ngModel)]="formFieldOption.option_answer.answer" [ngModelOptions]="{standalone: true}">
              {{formFieldOption.option_text}}
            </mat-checkbox>
          </div>

          <div *ngSwitchCase="'toggle'">
            <span class="sc-custom-label">{{formField.field_label}}</span>
            <mat-hint *ngIf="formField.field_description" appMarginBottom>{{ formField.field_description }}</mat-hint>
            <mat-slide-toggle class="sc-custom-toggle" appReadOnly *ngFor="let formFieldOption of formField.field_options" [(ngModel)]="formFieldOption.option_answer.answer" [ngModelOptions]="{standalone: true}">{{formFieldOption.option_text}}</mat-slide-toggle>
          </div>

          <div *ngSwitchCase="'paragraph'">
            <span class="sc-custom-header">{{formField.field_label}}</span>
            <p class="sc-custom-content" *ngIf="formField.field_description">{{formField.field_description }}</p>
          </div>

          <div *ngSwitchCase="'wysiwyg'">
            <span class="sc-custom-header">{{formField.field_label}}</span>
            <div class="sc-custom-content" *ngIf="formField.field_description" [innerHTML]="formField.field_description | sanitizeHtml"></div>
          </div>

          <div *ngSwitchCase="'filepicker'">
            <span class="sc-custom-label">{{formField.field_label}}</span>
            <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>

            <mat-nav-list dense *ngIf="formField.field_answer.files && formField.field_answer.files.length > 0">
              <mat-list-item *ngFor="let file of formField.field_answer.files; let j = index">
                <img *ngIf="utils.isImage(file.name); else showDocumentIcon" matListIcon [src]="file.url" [alt]="file.name" />
                <ng-template #showDocumentIcon>
                  <img matListIcon src="assets/images/document-placeholder.png" alt="Document" />
                </ng-template>
                <a matLine [href]="file.url" target="_blank"><span class="sc-list-item-text">{{ file.name }}</span></a>
              </mat-list-item>
            </mat-nav-list>
          </div>

          <div *ngSwitchCase="'signature'">
            <app-digital-signature-image *ngIf="formField.field_answer.answer; else noSignature" [meta]="formField.field_answer.meta"></app-digital-signature-image>
            <ng-template #noSignature>
              <p>Not Signed</p>
            </ng-template>
          </div>

          <div *ngSwitchCase="'user-selector'">
            <span class="sc-custom-label">{{formField.field_label}}</span>
            <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
            <mat-nav-list dense *ngIf="formField.field_answer?.meta['user_objects']">
              <mat-list-item *ngFor="let user_object of formField.field_answer?.meta['user_objects']; let i = index;" (click)="onUserPublicView(user_object.hash)">
                <ng-container matLine>
                  <span style="color: #0096AB;" matTooltip="Open Public User Profile">{{ user_object.contact_person }}</span>
                </ng-container>
              </mat-list-item>
            </mat-nav-list>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</div>
