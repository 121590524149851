<div mat-dialog-title>
  <div class="d-flex">
    <h3 class="navbar-brand">
      Select Inspection/Audit Questions
    </h3>

    <mat-select
      [(ngModel)]="selectedSection"
      placeholder="Section Filter"
      class="navbar-brand selection-filter"
      (selectionChange)="filterSection()">
      <mat-option value="">All </mat-option>
      <mat-option *ngFor="let section of sections" [value]="section"
        >{{ section }}
      </mat-option>
    </mat-select>

    <span style="flex: 1 1 auto;"></span>

    <form
      class="form-inline my-2 my-lg-0"
      (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2"
        type="search"
        [(ngModel)]="dataSource.search"
        name="search"
        placeholder="Search"
        aria-label="Search" />

      <button mat-raised-button class="mr-sm-2" type="submit">
        <mat-icon>search</mat-icon> Search
      </button>

      <button
        mat-raised-button
        color="primary"
        type="button"
        mat-dialog-close
        (click)="onSaveAndClose()"
        [disabled]="!data['multiple'] && !dataSource.selection.hasValue()">
        Select
      </button>
    </form>
  </div>
</div>

<mat-dialog-content>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="name"
    matSortDirection="asc"
    matSortDisableClear
    style="width: 100%">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef width="65px">
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let auditItem">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(auditItem.id) : null"
          [checked]="dataSource.selection.isSelected(auditItem.id)"
          appMarginRight>
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Question</th>
      <td mat-cell *matCellDef="let auditItem" class="text-wrap">
        {{ auditItem.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="section">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Section
      </th>
      <td mat-cell *matCellDef="let auditItem" class="no-padding">
        {{ auditItem.section }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Status
      </th>
      <td mat-cell *matCellDef="let auditItem" class="no-padding">
        {{ auditItem.status }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let auditItem; columns: displayedColumns" (click)="dataSource.selection.toggle(auditItem.id)"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <mat-paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>
</mat-dialog-actions>
