<div mat-dialog-title>
    <h5>
      Assigned Users
      <button mat-icon-button class="float-right" mat-dialog-close>
        <mat-icon color="secondary">close</mat-icon>
      </button>
    </h5>

    <div class="d-flex justify-content-end">

      <mat-menu #appMenu="matMenu">
        <button type="button" mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>
        <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onExportFormsData('pdf')" [disabled]="!dataSource.selection.hasValue()" matTooltip="Only Submitted, Rejected, Approved, Approved: Work in Progress and Completed forms are included in the PDF exports.">
          <mat-icon>file_download</mat-icon> Export to PDF
        </button>
        <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onExportFormsData('csv')" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>file_download</mat-icon> Export to XLSX
        </button>
        <button type="button" mat-menu-item (click)="onUnassignSelectedUsers()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>archive</mat-icon> Unassign Selected Users
        </button>
        <button type="button" mat-menu-item (click)="onUnassignSelectedUsers(['all'])">
          <mat-icon>archive</mat-icon> Unassign All Users
        </button>
      </mat-menu>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">

        <button type="button" mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to perform list actions." style="min-width: 120px" class="mr-3">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>

        <button mat-raised-button color="primary" type="button" class="mr-3" (click)="onAssignUsers()" matTooltip="Assign users to this form.">
          <mat-icon>add</mat-icon> Assign Users
        </button>

        <button type="button" mat-raised-button color="secondary" type="button" class="mr-3" (click)="onFilterByAccount()" matTooltip="Filter the list by selecting accounts to filter by.">
          <mat-icon>filter_list</mat-icon> Accounts
        </button>

        <mat-form-field class="mr-3" style="width: 180px;">
          <mat-select [(ngModel)]="dataSource.dynamic_form_status" matTooltip="Filter users by form submission status." name="dynamic_form_status" (selectionChange)="dataSource.selection.clear(); dataSource.getData(true);">
            <mat-option value="">All Statuses</mat-option>
            <mat-option value="Assigned">Assigned</mat-option>
            <mat-option value="In Progress">In Progress</mat-option>
            <mat-option value="Submitted">Submitted</mat-option>
            <mat-option value="Pending">Pending</mat-option>
            <mat-option value="Rejected">Rejected</mat-option>
            <mat-option value="Approved">Approved</mat-option>
            <mat-option value="Approved: Work in Progress">Approved: Work in Progress</mat-option>
            <mat-option value="Work Completed">Work Completed</mat-option>
          </mat-select>
        </mat-form-field>

        <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search" aria-label="Search" id="search-input" matTooltip="Search for forms by title." />

        <button mat-raised-button color="secondary" appMarginRight id="search-button" matTooltip="Run the current search">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
</div>

<div mat-dialog-content>
  <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

  <table mat-table [dataSource]="dataSource" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>

    <caption class="px-3">This is a list of all users assigned to this forms in your account.</caption>

    <!-- Selection Toggle -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef id="th-select">
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
          matTooltip="Toggle selection of all assigned users in the visible list.">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let user">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(user.id) : null"
          [checked]="dataSource.selection.isSelected(user.id)" matTooltip="Toggle selection of this assigned user.">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- ID -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-id" matTooltip="Toggle sorting by assigned user ID.">
        #
      </th>
      <td mat-cell *matCellDef="let user">
        {{ user.id }}
      </td>
    </ng-container>

    <!-- Employer -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-name" matTooltip="Toggle sorting by employer name.">
        Employer
      </th>
      <td mat-cell *matCellDef="let user">
        {{ user.employer.name }}
      </td>
    </ng-container>

    <!-- Full Name -->
    <ng-container matColumnDef="contact_person">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-contact_person" matTooltip="Toggle sorting by assigned user name.">
        Full Name
      </th>
      <td mat-cell *matCellDef="let user">
        <a (click)="onUserPublicView(user.hash)"
           matTooltip="View User Public Profile">
          {{ user.contact_person }}
        </a>
      </td>
    </ng-container>

    <!-- Email Address -->
    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-email" matTooltip="Toggle sorting by assigned user email address.">
        Email Address
      </th>
      <td mat-cell *matCellDef="let user">
        {{ user.email }}
      </td>
    </ng-container>

    <!-- Status -->
    <ng-container matColumnDef="dynamic_form_status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-dynamic_form_status" matTooltip="Toggle sorting by form submission status.">
        Status
      </th>
      <td mat-cell *matCellDef="let user">
        <span [ngClass]="getFormSubmissionStatusColors(user)">
          {{ user.pivot.dynamic_form_status }}
        </span>
      </td>
    </ng-container>

    <!-- Verified -->
    <ng-container matColumnDef="dynamic_form_verified">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-dynamic_form_verified" matTooltip="Toggle sorting by form verification status.">
        Verified
      </th>
      <td mat-cell *matCellDef="let user">
        {{ user.pivot.dynamic_form_verified ? 'Yes' : 'No' }}
      </td>
    </ng-container>

    <!-- Assigned By -->
    <ng-container matColumnDef="assigned_by_user">
      <th mat-header-cell *matHeaderCellDef class="no-padding">
        Assigned By
      </th>
      <td mat-cell *matCellDef="let user" class="no-padding">
        <span *ngIf="user.pivot.assigned_by">
          <a (click)="onUserPublicView(user.pivot.assigned_by_user.hash)"
             matTooltip="View User Public Profile">
            {{ user.pivot.assigned_by_user.contact_person }}
          </a>
        </span>
      </td>
    </ng-container>

    <!-- Date Created -->
    <ng-container matColumnDef="assigned_on">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created" matTooltip="Toggle sorting by creation date.">
        Assigned At
      </th>
      <td mat-cell *matCellDef="let user">
        {{ user.pivot.assigned_on ? ((user.pivot.assigned_on * 1000) | timezoneConvertMedium) : '' }}
      </td>
    </ng-container>

    <!-- Date Created (UTC) -->
    <ng-container matColumnDef="assigned_on_UTC">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created-utc" matTooltip="Toggle sorting by creation date.">
        Assigned At (UTC)
      </th>
      <td mat-cell *matCellDef="let user">
        {{ user.pivot.assigned_on ? ((user.pivot.assigned_on * 1000) | timezoneConvertUTCMedium) : '' }}
      </td>
    </ng-container>

    <!-- Date Modifeid -->
    <ng-container matColumnDef="date_modified">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_modified" matTooltip="Toggle sorting by the date the form was last updated on.">
        User Last Updated At / Submitted At
      </th>
      <td mat-cell *matCellDef="let user">
        {{ user.pivot.date_modified ? ((user.pivot.date_modified * 1000) | timezoneConvertMedium) : '' }}
      </td>
    </ng-container>

    <!-- Date Modifeid (UTC) -->
    <ng-container matColumnDef="date_modified_UTC">
      <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_modified-utc" matTooltip="Toggle sorting by the date the form was last updated on.">
        User Last Updated At / Submitted At (UTC)
      </th>
      <td mat-cell *matCellDef="let user">
        {{ user.pivot.date_modified ? ((user.pivot.date_modified * 1000) | timezoneConvertUTCMedium) : '' }}
      </td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef id="th-actions"></th>
      <td mat-cell *matCellDef="let user">

        <button type="button" mat-button *ngIf="['Assigned', 'In Progress'].indexOf(user.pivot.dynamic_form_status) == -1" (click)="onViewUserSubmittedAnswers(user.id)" matTooltip="Open the form to see this user's submitted answers.">
          View Submitted Answers
        </button>

        <button type="button" mat-button (click)="onUnassignUser(user, $event)" matTooltip="Unassign the user from the form.">
          Unassign User
        </button>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
</div>
