import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActionsEditComponent } from './actions-edit/actions-edit.component';
import { ActionsViewComponent } from './actions-view/actions-view.component';
import { ActionsComponent } from './actions.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: 'actions',
    canActivate: [
      AuthGuard
    ],
    children: [
      { path: '', component: ActionsComponent },
      { path: ':id/edit', component: ActionsEditComponent },
      { path: ':id/view', component: ActionsViewComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActionsRoutingModule {}
