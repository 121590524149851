<div class="app-margin">
  <form (submit)="onSave()" #f="ngForm">

    <div class="row mb-3">
      <div class="col">

        <div *ngIf="assignee.id">
          <h6>Investigator</h6>
          <p>{{ assignee.contact_person }}</p>
        </div>

        <div *ngIf="reviewer.id">
          <h6>Reviewer</h6>
          <p>{{ reviewer.contact_person }}</p>
        </div>

        <button type="button" mat-raised-button color="secondary" (click)="onSelectInvestigator()"
          [matBadge]="'' + (assignee.id ? 1 : 0)" class="margin-right"
          matTooltip="An assignee is required for an investigation to begin">
          <mat-icon>person</mat-icon>
          {{ !assignee.id ? 'Select' : 'Change' }} Investigator
        </button>

        <button type="button" mat-raised-button color="secondary" (click)="onSelectReviewer()"
          [matBadge]="'' + (reviewer.id ? 1 : 0)"
          matTooltip="You can optionally select a reviewer.">
          <mat-icon>rate_review</mat-icon>
          {{ !reviewer.id ? 'Select' : 'Change' }} Reviewer
        </button>
      </div>
    </div>

    <h5>What happened before the incident?</h5>
    <mat-form-field class="full-width">
      <textarea [(ngModel)]="incident.before_incident_notes" matInput
        name="before-incident-notes" cols="30" rows="5"></textarea>
    </mat-form-field>

    <h5>How did the incident occur?</h5>
    <mat-form-field class="full-width">
      <textarea [(ngModel)]="incident.incident_notes" name="incident-notes"
        matInput cols="30" rows="5"></textarea>
    </mat-form-field>

    <h5>What happened after the incident?</h5>
    <mat-form-field class="full-width">
      <textarea [(ngModel)]="incident.after_incident_notes" matInput
        name="after-incident-notes" cols="30" rows="5"></textarea>
    </mat-form-field>

    <span matTooltip="Save the risk assessments">
      <button mat-raised-button color="primary" type="submit"
        [disabled]="!f.valid || inProgress">
        <mat-icon>save</mat-icon>
        Save
      </button>
    </span>

  </form>
</div>
