<div mat-dialog-title class="p-3">
  <h5>
    Form Templates
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
</div>

<div mat-dialog-content class="p-3">
  <div style="padding: 16px;">
    <div class="container-fluid">
      <div class="row">

        <div class="col-5">
          <h5>Available Form Fields</h5>

          <div
            cdkDropList
            #selectableFieldList="cdkDropList"
            [cdkDropListData]="availableFields"
            [cdkDropListConnectedTo]="selectedFieldList"
            class="row">

            <div *ngFor="let availableField of availableFields; let i = index" [ngSwitch]="availableField.field_type" cdkDrag class="mb-3 col-6">
              <mat-card>
                <mat-card-actions align="right">
                  <button mat-button [matMenuTriggerFor]="addMenu" mat-raised-button cdkDragHandle matTooltip="Click this button to open the context menu to add the field to the form builder on the right.">
                    <mat-icon>add</mat-icon>
                    Add Field
                  </button>

                  <mat-menu #addMenu="matMenu">
                    <button mat-menu-item (click)="onCopyFieldToFormBuilder(i, 0)" matTooltip="Prepend the field to the beginning of the form.">Prepend</button>
                    <button mat-menu-item [matMenuTriggerFor]="addBeforeMenu" matTooltip="Add the field before any selected field.">Before</button>
                    <button mat-menu-item [matMenuTriggerFor]="addAfterMenu" matTooltip="Add the field after any selected field.">After</button>
                    <button mat-menu-item (click)="onCopyFieldToFormBuilder(i, form.fields.length)" matTooltip="Append the field at the end of the form.">Append</button>
                  </mat-menu>

                  <mat-menu #addBeforeMenu="matMenu">
                    <button mat-menu-item *ngFor="let selectedField of form.fields; let j = index" (click)="onCopyFieldToFormBuilder(i, j)">{{(j+1)}}: {{selectedField.field_header}} - {{(selectedField.field_label || selectedField.field_type)}}</button>
                  </mat-menu>

                  <mat-menu #addAfterMenu="matMenu">
                    <button mat-menu-item *ngFor="let selectedField of form.fields; let j = index" (click)="onCopyFieldToFormBuilder(i, j+1)">{{(j+1)}}: {{selectedField.field_header}} - {{(selectedField.field_label || selectedField.field_type)}}</button>
                  </mat-menu>
                </mat-card-actions>

                <mat-card-content>
                  <mat-form-field  floatLabel="always" *ngSwitchCase="'input'">
                    <mat-label class="sc-custom-form-field-label">Text Input</mat-label>
                    <input matInput disabled placeholder="John Doe" />
                    <mat-hint>Add a field for short text responses.</mat-hint>
                  </mat-form-field>

                  <mat-form-field  floatLabel="always" *ngSwitchCase="'datetime'">
                    <mat-label class="sc-custom-form-field-label">Date Picker</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="dateSample" disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker" disabled></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-hint>Add a field for selecting a date.</mat-hint>
                  </mat-form-field>

                  <mat-form-field  floatLabel="always" *ngSwitchCase="'textarea'">
                    <mat-label class="sc-custom-form-field-label">Text Area</mat-label>
                    <textarea matInput disabled>19 Como Street&#13;&#10;Takapuna, Auckland 0622.</textarea>
                    <mat-hint>Add a field for longer text responses.</mat-hint>
                  </mat-form-field>

                  <mat-form-field floatLabel="always" *ngSwitchCase="'select'">
                    <mat-label class="sc-custom-form-field-label">Dropdown</mat-label>
                    <select matNativeControl disabled>
                      <option>Option 1</option>
                    </select>
                    <mat-hint>Add a dropdown field with predefined options.</mat-hint>
                  </mat-form-field>

                  <div *ngSwitchCase="'radio'">
                    <span class="sc-custom-label">Radio Buttons</span>
                    <mat-hint appMarginBottom>Add a field with mutually exclusive options.</mat-hint>
                    <mat-radio-group aria-label="Selectable Options" disabled value="Option 1">
                      <mat-radio-button value="Option 1" class="sc-custom-radio">Radio Option 1</mat-radio-button>
                      <mat-radio-button value="Option 2" class="sc-custom-radio">Radio Option 2</mat-radio-button>
                      <mat-radio-button value="Option 3" class="sc-custom-radio">Radio Option 3</mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div *ngSwitchCase="'checkbox'">
                    <span class="sc-custom-label">Checkboxes</span>
                    <mat-hint appMarginBottom>Add a field for selecting multiple options.</mat-hint>
                    <mat-checkbox class="sc-custom-checkbox" appReadOnly [checked]="true">Checkbox Option 1</mat-checkbox>
                    <mat-checkbox class="sc-custom-checkbox" appReadOnly>Checkbox Option 2</mat-checkbox>
                    <mat-checkbox class="sc-custom-checkbox" appReadOnly [checked]="true">Checkbox Option 3</mat-checkbox>
                  </div>

                  <div *ngSwitchCase="'toggle'">
                    <mat-hint class="sc-custom-label">Toggles</mat-hint>
                    <mat-hint appMarginBottom>Add a field for toggling options on or off.</mat-hint>
                    <mat-slide-toggle class="sc-custom-toggle" appReadOnly [checked]="true">Toggle Option 1</mat-slide-toggle>
                    <mat-slide-toggle class="sc-custom-toggle" appReadOnly [checked]="true">Toggle Option 2</mat-slide-toggle>
                    <mat-slide-toggle class="sc-custom-toggle" appReadOnly>Toggle Option 3</mat-slide-toggle>
                  </div>

                  <div *ngSwitchCase="'paragraph'">
                    <span class="sc-custom-label">Plain Text Content</span>
                    <p class="sc-custom-content">Add plain text information or instructions.</p>
                  </div>

                  <div *ngSwitchCase="'wysiwyg'">
                    <span class="sc-custom-label">Rich Text Content</span>
                    <p class="sc-custom-content">Add <b>formatted</b> information or instructions with images, <i>links</i> and lists.</p>
                  </div>

                  <div *ngSwitchCase="'filepicker'">
                    <span class="sc-custom-label">File Upload</span>
                    <mat-hint>Add a field for file uploads.</mat-hint>
                    <input type="file" placeholder="File Selection Field" disabled />
                  </div>

                  <div *ngSwitchCase="'signature'">
                    <span class="sc-custom-label">Signature</span>
                    <p class="sc-custom-content">Add a field for capturing signatures.</p>
                  </div>

                  <div *ngSwitchCase="'user-selector'">
                    <span class="sc-custom-label">User Selector</span>
                    <p class="sc-custom-content">Add a field for selecting one or more users.</p>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

          </div>
        </div>

        <div class="col-7">
          <mat-tab-group (selectedTabChange)="onTabChanged($event)">
            <mat-tab label="Form Builder">

              <mat-card>
                <mat-card-content>
                  <mat-card class="mb-3">
                    <mat-card-content>
                      Click on the <button type="button" mat-button><mat-icon>add</mat-icon> Add Field</button> button on the left to add the field from the list of available fields to the form builder below.
                    </mat-card-content>
                  </mat-card>

                  <mat-accordion
                    cdkDropList
                    #selectedFieldList="cdkDropList"
                    [cdkDropListData]="form.fields"
                    [cdkDropListConnectedTo]="selectableFieldList"
                    (cdkDropListDropped)="drop($event)">
                    <mat-expansion-panel *ngFor="let formField of form.fields; let i = index" cdkDrag>
                      <mat-expansion-panel-header cdkDragHandle>
                        <mat-panel-title [ngClass]="{ 'text-danger': !validFormBuilderField(formField) }">
                          {{(i+1)}}: {{formField.field_header}} - {{(formField.field_label || formField.field_type)}}&nbsp;<span *ngIf="formField.field_is_required == true">*</span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <ng-template matExpansionPanelContent>
                        <div [ngSwitch]="formField.field_type" appPadding>
                          <mat-card appMarginBottom>
                            <mat-card-content>

                              <h5 style="color: black;">Field Preview</h5>

                              <mat-form-field floatLabel="always" *ngSwitchCase="'input'">
                                <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>

                                <ng-container *ngIf="formField.field_input_type == 'tel'; else notTel">
                                  <app-phone-input
                                    [idInput]="'tel-input-' + i"
                                    [numberInput]="formField.field_value"
                                    [countryCodeInput]="formField.field_meta.mobile_country_code"
                                    [dialCodeInput]="formField.field_meta.mobile_dial_code"
                                    [e164NumberInput]="formField.field_meta.mobile_e164"
                                    (phoneDetailsOutput)="extractPhoneDetails($event, i)"
                                    [required]="formField.field_is_required == true">
                                  </app-phone-input>
                                </ng-container>
                                <ng-template #notTel>
                                  <input matInput [type]="formField.field_input_type" [(ngModel)]="formField.field_value" [required]="formField.field_is_required == true">
                                </ng-template>

                                <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
                              </mat-form-field>

                              <mat-form-field floatLabel="always" *ngSwitchCase="'datetime'">
                                <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="dateSample" [ngModelOptions]="{standalone: true}" [required]="formField.field_is_required == true">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
                              </mat-form-field>

                              <mat-form-field floatLabel="always" *ngSwitchCase="'textarea'">
                                <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>
                                <textarea matInput [(ngModel)]="formField.field_value" [required]="formField.field_is_required == true"></textarea>
                                <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
                              </mat-form-field>

                              <mat-form-field floatLabel="always" *ngSwitchCase="'select'">
                                <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>
                                <select matNativeControl [(ngModel)]="formField.field_value" [required]="formField.field_is_required == true">
                                  <option *ngFor="let formFieldOption of formField.field_options">{{formFieldOption.option_text}}</option>
                                </select>
                                <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
                              </mat-form-field>

                              <div *ngSwitchCase="'radio'">
                                <span class="sc-custom-label">{{ formField.field_label }} <span *ngIf="formField.field_is_required == true">*</span></span>
                                <mat-hint appMarginBottom *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
                                <mat-radio-group aria-label="Selectable Options" [(ngModel)]="formField.field_value" [ngModelOptions]="{standalone: true}" [required]="formField.field_is_required == true">
                                  <mat-radio-button class="sc-custom-radio" *ngFor="let formFieldOption of formField.field_options; let j = index" [value]="formFieldOption.option_text">{{formFieldOption.option_text}}</mat-radio-button>
                                </mat-radio-group>
                              </div>

                              <div *ngSwitchCase="'checkbox'">
                                <span class="sc-custom-label">{{ formField.field_label }}</span>
                                <mat-hint appMarginBottom *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
                                <mat-checkbox class="sc-custom-checkbox" *ngFor="let formFieldOption of formField.field_options; let j = index" [value]="formFieldOption.option_text">
                                  {{formFieldOption.option_text}} <span *ngIf="formFieldOption.option_is_required == true">*</span>
                                </mat-checkbox>
                              </div>

                              <div *ngSwitchCase="'toggle'">
                                <span class="sc-custom-label">{{ formField.field_label }}</span>
                                <mat-hint appMarginBottom *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
                                <mat-slide-toggle class="sc-custom-toggle" *ngFor="let formFieldOption of formField.field_options; let j = index">
                                  {{formFieldOption.option_text}} <span *ngIf="formFieldOption.option_is_required == true">*</span>
                                </mat-slide-toggle>
                              </div>

                              <div *ngSwitchCase="'paragraph'">
                                <span class="sc-custom-header">{{ formField.field_label }}</span>
                                <p class="sc-custom-content" *ngIf="formField.field_description">{{ formField.field_description }}</p>
                              </div>

                              <div *ngSwitchCase="'wysiwyg'">
                                <span class="sc-custom-header">{{ formField.field_label }}</span>
                                <div class="sc-custom-content" *ngIf="formField.field_description" [innerHTML]="formField.field_description | sanitizeHtml"></div>
                              </div>

                              <div *ngSwitchCase="'filepicker'">
                                <span class="sc-custom-label">{{ formField.field_label }}</span>
                                <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
                                <input type="file" placeholder="File Upload" />
                              </div>

                              <div *ngSwitchCase="'signature'">
                                <app-digital-signature [title]="formField.field_label + ' ' + (formField.field_is_required ? '*' : '')" [subtitle]="formField.field_description"></app-digital-signature>
                              </div>

                              <div *ngSwitchCase="'user-selector'">
                                <span class="sc-custom-label">{{ formField.field_label }} <span *ngIf="formField.field_is_required == true">*</span></span>
                                <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>

                                <mat-nav-list dense *ngIf="formField.field_meta['user_objects']" appMarginTop>
                                  <mat-list-item *ngFor="let user_object of formField.field_meta['user_objects']; let i = index;">
                                    <ng-container matLine>
                                      <span style="color: #0096AB;" matTooltip="Open Public User Profile">{{ user_object.contact_person }}</span>
                                    </ng-container>
                                    <button type="button" color="warn" mat-icon-button (click)="onRemoveUserObjectForUserSelectorField(formField, i)">
                                      <mat-icon>close</mat-icon>
                                    </button>
                                  </mat-list-item>
                                </mat-nav-list>

                                <button mat-button mat-raised-button (click)="onOpenUsersSelectorForUserSelectorField(formField)" matTooltip="Click this button to open the users selector.">
                                  <mat-icon>people</mat-icon>
                                  Select Users
                                </button>
                              </div>
                            </mat-card-content>
                          </mat-card>

                          <mat-card>
                            <mat-card-content>

                              <h5 style="color: black;">Field Properties</h5>

                              <div class="row mb-3">
                                <div class="col-8">
                                  <mat-form-field floatLabel="always">
                                    <mat-label>{{ ['paragraph', 'wysiwyg'].indexOf(formField.field_type) == -1 ? 'Label' : 'Header' }}</mat-label>
                                    <input matInput [(ngModel)]="formField.field_label" [ngModelOptions]="{standalone: true}" required>
                                    <mat-hint>This label will be shown to users filling out the form.</mat-hint>
                                  </mat-form-field>
                                </div>

                                <div class="col-4">
                                  <mat-form-field floatLabel="always">
                                    <mat-label>Field Type</mat-label>
                                    <select matNativeControl [(ngModel)]="formField.field_type" [ngModelOptions]="{standalone: true}" (change)="onFieldTypeChanged(formField)">
                                      <option [value]="availableField.field_type" *ngFor="let availableField of availableFields">{{ availableField.field_header }}</option>
                                    </select>
                                    <mat-hint>You can change the field type by using this dropdown without loosing any of the information you already entered.</mat-hint>
                                  </mat-form-field>
                                </div>
                              </div>

                              <div class="full-width mb-3" *ngIf="['paragraph', 'wysiwyg', 'checkbox', 'toggle', 'filepicker'].indexOf(formField.field_type) == -1">
                                <mat-checkbox [(ngModel)]="formField.field_is_required" name="field_is_required">Required</mat-checkbox>
                                <mat-hint><small>Enable this to make it a required field.</small></mat-hint>
                              </div>

                              <div class="full-width mb-3">
                                <mat-checkbox [(ngModel)]="formField.include_in_exports" name="include_in_exports">Include in CSV and PDF exports.</mat-checkbox>
                                <mat-hint><small>Enable this to include the field in CSV and PDF exports.</small></mat-hint>
                              </div>

                              <mat-form-field floatLabel="always" *ngIf="formField.field_type != 'wysiwyg'" class="mb-3">
                                <mat-label>{{ formField.field_type != 'paragraph' ? 'Description' : 'Content' }}</mat-label>
                                <textarea matInput rows="5" [(ngModel)]="formField.field_description" [ngModelOptions]="{standalone: true}"></textarea>
                                <mat-hint>This {{ formField.field_type != 'paragraph' ? 'description' : 'content' }} will be shown to users filling out the form as {{ formField.field_type != 'paragraph' ? 'helper' : 'a paragraph of' }} text.</mat-hint>
                              </mat-form-field>

                              <div *ngIf="active_tab == 'Form Builder' && formField.field_type == 'wysiwyg'" class="mb-3">
                                <editor
                                  [(ngModel)]="formField.field_description"
                                  [id]="'wysiwyg-'+i"
                                  [init]="{
                                  skin_url: '/assets/skins/lightgray',
                                  plugins: 'link,image,lists,hr,table,media',
                                  height: '200'
                                }">
                                </editor>
                                <mat-hint><small>This content will be shown to the user as formatted a formatted document.</small></mat-hint>
                              </div>

                              <mat-form-field floatLabel="always" *ngIf="formField.field_type == 'input'">
                                <mat-label>Input Type</mat-label>
                                <select matNativeControl [(ngModel)]="formField.field_input_type" [ngModelOptions]="{standalone: true}">
                                  <option value="text">Text</option>
                                  <option value="email">Email</option>
                                  <option value="tel">Tel</option>
                                  <option value="number">Number</option>
                                  <option value="url">URL</option>
                                </select>
                              </mat-form-field>

                              <mat-form-field floatLabel="always" *ngIf="formField.field_type == 'datetime'">
                                <mat-label>Date Selector Ranges</mat-label>
                                <select matNativeControl [(ngModel)]="formField.field_date_range" [ngModelOptions]="{standalone: true}">
                                  <option value="any">Users can select past and future dates.</option>
                                  <option value="past">Users can only select past dates.</option>
                                  <option value="future">Users can only select future dates.</option>
                                </select>
                              </mat-form-field>

                              <div *ngIf="['select', 'checkbox', 'radio', 'toggle'].indexOf(formField.field_type) > -1">
                                <p><strong>Selectable Options</strong></p>
                                <mat-form-field floatLabel="always" *ngFor="let formFieldOption of formField.field_options; let j = index">
                                  <mat-label>Option ({{j+1}})</mat-label>
                                  <mat-checkbox matPrefix [(ngModel)]="formFieldOption.option_is_required" [name]="'option_is_required-'+i+'-'+j" class="margin-right" matTooltip="Make this a required option." *ngIf="['checkbox', 'toggle'].indexOf(formField.field_type) > -1"></mat-checkbox>
                                  <input matInput required [(ngModel)]="formFieldOption.option_text" [ngModelOptions]="{standalone: true}">
                                  <button mat-button matSuffix mat-icon-button *ngIf="formField.field_options.length > 1" (click)="onRemoveOption(formField.field_options, j)">
                                    <mat-icon>close</mat-icon>
                                  </button>
                                </mat-form-field>
                              </div>

                              <ng-container *ngIf="formField.id && formField.field_conditions && formField.field_conditions.length > 0">
                                <p><strong>Display Conditions</strong></p>

                                <div class="alert alert-info" role="alert">

                                  <mat-form-field floatLabel="always">
                                    <mat-label>Logical Operator</mat-label>
                                    <select matNativeControl [(ngModel)]="formField.field_logical_operator" [ngModelOptions]="{standalone: true}">
                                      <option value="&&">AND</option>
                                      <option value="||">OR</option>
                                    </select>
                                  </mat-form-field>

                                  <small>
                                    <b>
                                      {{ formField.field_logical_operator == '&&' ? 'All conditions below must be met for this field to show.' : 'One or more of the conditions below must be met for this field to show.' }}
                                    </b>
                                  </small>
                                </div>

                                <div class="row" *ngFor="let formFieldCondition of formField.field_conditions; let j = index">
                                  <div class="col-4">
                                    <mat-form-field floatLabel="always">
                                      <mat-label>Select Target Field</mat-label>
                                      <select matNativeControl [(ngModel)]="formFieldCondition.dynamic_form_target_field_id" [ngModelOptions]="{standalone: true}">
                                        <option *ngFor="let targetField of getStoredTargetFormFields([formField.id]); let k = index" [value]="targetField.id">{{targetField.field_header}} - {{(targetField.field_label || targetField.field_type)}}</option>
                                      </select>
                                    </mat-form-field>
                                  </div>

                                  <div class="col-{{ ['checkbox', 'toggle'].indexOf(getTargetFormFieldById(formFieldCondition.dynamic_form_target_field_id).field_type) > -1 ? '3' : '4' }}">
                                    <mat-form-field floatLabel="always">
                                      <mat-label>Select Operator</mat-label>
                                      <select matNativeControl [(ngModel)]="formFieldCondition.condition_operator" [ngModelOptions]="{standalone: true}">
                                        <option value="==">IS (==)</option>
                                        <option value="!=">IS NOT (!=)</option>
                                        <option value="<">LESS THAN (&lt;)</option>
                                        <option value="<=">LESS THAN OR EQUAL TO (&lt;=)</option>
                                        <option value=">">GREATER THAN (&gt;)</option>
                                        <option value=">=">GREATER THAN OR EQUAL TO (&gt;=)</option>
                                      </select>
                                    </mat-form-field>
                                  </div>

                                  <div *ngIf="['checkbox', 'toggle'].indexOf(getTargetFormFieldById(formFieldCondition.dynamic_form_target_field_id).field_type) > -1" class="col-1">
                                    <mat-checkbox [(ngModel)]="formFieldCondition.condition_checked" [ngModelOptions]="{standalone: true}" class="d-inline-flex"></mat-checkbox>
                                  </div>

                                  <div class="col-4">
                                    <mat-form-field floatLabel="always">
                                      <mat-label>Condition {{ ['checkbox', 'toggle', 'radio', 'select'].indexOf(getTargetFormFieldById(formFieldCondition.dynamic_form_target_field_id).field_type) > -1 ? 'Option' : 'Value' }}</mat-label>

                                      <select matNativeControl [(ngModel)]="formFieldCondition.condition_value" [ngModelOptions]="{standalone: true}" *ngIf="['select', 'checkbox', 'radio', 'toggle'].indexOf(getTargetFormFieldById(formFieldCondition.dynamic_form_target_field_id).field_type) > -1; else noOptionsValue">
                                        <option *ngFor="let formFieldOption of getTargetFormFieldById(formFieldCondition.dynamic_form_target_field_id).field_options; let k = index" [value]="formFieldOption.option_text">{{ formFieldOption.option_text }}</option>
                                      </select>

                                      <ng-template #noOptionsValue>
                                        <input matInput [(ngModel)]="formFieldCondition.condition_value" [ngModelOptions]="{standalone: true}">
                                      </ng-template>

                                      <button mat-button matSuffix mat-icon-button (click)="onRemoveCondition(formField.field_conditions, j)">
                                        <mat-icon>close</mat-icon>
                                      </button>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>

                            </mat-card-content>

                            <mat-card-actions align="right">
                              <button mat-button mat-raised-button (click)="onAddOption(formField.field_options)" color="secondary"  *ngIf="['select', 'checkbox', 'radio', 'toggle'].indexOf(formField.field_type) > -1">Add Option</button>
                              <button mat-button mat-raised-button (click)="onAddCondition(formField.field_conditions)" color="secondary" *ngIf="formField.id">Add Condition</button>
                              <button mat-button mat-raised-button (click)="onCopyField(i)" color="secondary">Copy</button>
                              <button mat-button mat-raised-button (click)="onRemoveField(i)" color="warn" *ngIf="form.fields.length > 1">Remove</button>
                            </mat-card-actions>
                          </mat-card>
                        </div>
                      </ng-template>

                    </mat-expansion-panel>
                  </mat-accordion>

                </mat-card-content>
              </mat-card>

            </mat-tab>

            <mat-tab label="Form Settings">
              <form #f="ngForm" (submit)="onSaveForm(f)">
                <mat-card>
                  <mat-card-content>
                    <mat-form-field floatLabel="always" class="full-width mb-3">
                      <mat-label>Form Title</mat-label>
                      <input
                        matInput
                        [(ngModel)]="form.title"
                        name="title"
                        type="text"
                        id="title"
                        required
                        dusk="dusk_title" />
                      <mat-hint>Enter a form title.</mat-hint>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" class="full-width mb-3">
                      <mat-label>Form Description</mat-label>
                      <textarea
                        matInput
                        [(ngModel)]="form.description"
                        name="description"
                        id="description"
                        required
                        dusk="dusk_description"></textarea>
                      <mat-hint>Enter a form description.</mat-hint>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" class="full-width mb-3">
                      <mat-label>Form Type:</mat-label>
                      <mat-select [(ngModel)]="form.form_type" matTooltip="Please select the form type." name="form_type" (selectionChange)="onFormTypeChanged()">
                        <mat-option value="form">Form</mat-option>
                        <mat-option value="induction">{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Induction</mat-option>
                      </mat-select>
                      <mat-hint>Please select the form type.</mat-hint>
                    </mat-form-field>
                  </mat-card-content>

                  <div class="full-width mb-3">
                    <mat-checkbox [(ngModel)]="form.is_active" name="is_active">This form is <strong>{{ form.is_active ? 'Active' : 'Inactive' }}</strong></mat-checkbox>
                    <mat-hint>To activate this form you need to tick this box.</mat-hint>
                  </div>

                  <div class="full-width mb-3 mt-3">
                    <mat-checkbox [(ngModel)]="form.is_notifications_enabled" name="is_notifications_enabled">Enable notifications for this form.</mat-checkbox>
                    <mat-hint>By enabling notifications for this form, users that you select in the form notifications tab above will be notified when this form status changes to Submitted <i>(by the assigned users only)</i>, Rejected, Approved or "Approved: Work in Progress".</mat-hint>
                  </div>

                  <div class="full-width mb-3 mt-3">
                    <mat-checkbox [(ngModel)]="form.requires_approval" name="requires_approval">This form requires approval.</mat-checkbox>
                    <mat-hint>By enabling this option, when users submit this form, its status changes to "Pending" instead of "Submitted". Administrative users can then approve or reject the submitted responses.</mat-hint>
                  </div>

                  <div class="full-width mb-3 mt-3" *ngIf="form.is_notifications_enabled">
                    <mat-checkbox [(ngModel)]="form.include_site_managers_for_notifications" name="include_site_managers_for_notifications">Include {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} managers for notifications.</mat-checkbox>
                    <mat-hint>By enabling this, {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} managers for the selected {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} will be included when notifications are sent to users that you selected in the form notifications tab above.</mat-hint>
                  </div>

                  <div class="full-width mb-3" *ngIf="!form.is_template">
                    <mat-checkbox [(ngModel)]="form.is_auto_assignable" name="is_auto_assignable">Automatically assign this form to users in your connected network.</mat-checkbox>
                    <mat-hint>This option allows the form to automatically be assigned to users that are in or added to your connected network. Use the {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }}, industries and job titles selectors below to filter the user list automatically. The form must be activated for this to take affect.</mat-hint>
                  </div>

                  <div class="full-width mb-3" *ngIf="!form.is_template">
                    <mat-checkbox [(ngModel)]="form.auto_assign_to_employees" name="auto_assign_to_employees">Automatically assign this form to {{ utils.getLangTerm('employees.plural', 'Employees') }}.</mat-checkbox>
                    <mat-hint>This option allows the form to automatically be assigned to {{ utils.getLangTerm('employees.plural', 'Employees') }} in your account. You can filter {{ utils.getLangTerm('employees.plural', 'Employees') }} by selecting job titles below. The form must be activated for this to take affect.</mat-hint>
                  </div>

                  <div class="full-width mb-3" *ngIf="!form.is_template">
                    <mat-checkbox [(ngModel)]="form.auto_assign_at_site_entry" name="auto_assign_at_site_entry">Automatically assign this form to users entering selected {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }}.</mat-checkbox>
                    <mat-hint>This option allows the form to automatically be assigned to users that enters any of the selected {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} below. You can filter users by selecting industries and job titles below. The form must be activated for this to take affect.</mat-hint>
                  </div>

                  <ng-container *ngIf="(!form.users_count && form.form_type == 'form')">
                    <div class="full-width mb-3">
                      <mat-checkbox [(ngModel)]="form.is_template" name="is_template" [disabled]="form.is_recurring == true" (change)="onFormTemplateStateChange($event)">This is a template form: {{ form.is_template ? 'Yes' : 'No' }}</mat-checkbox>
                      <mat-hint>Template forms are not seen by end-users and are generally used to set up templates or recurring forms. To assign this form to users you need to make it a non-template form or make it a recurring form and activate it.</mat-hint>
                    </div>

                    <div class="full-width mb-3">
                      <mat-checkbox [(ngModel)]="form.is_recurring" name="is_recurring" (change)="form.is_recurring == true ? form.is_template = true : ''">This is a <strong>{{ form.is_recurring ? 'recurring' : 'non-recurring' }}</strong> form.</mat-checkbox>
                      <mat-hint>Recurring forms like daily pre-starts can be set up with a schedule.</mat-hint>
                    </div>

                    <mat-card *ngIf="form.is_recurring == true" class="mb-3">
                      <mat-card-header>
                        <mat-card-subtitle>Schedule</mat-card-subtitle>
                      </mat-card-header>

                      <mat-card-content>
                        <div class="row">
                          <div class="col-4">
                            <mat-form-field floatLabel="always" class="full-width mb-3">
                              <mat-label>Hour of day:</mat-label>
                              <mat-select [(ngModel)]="form.schedule_hour" matTooltip="Please select an hour of day." name="schedule_hour" (selectionChange)="compileSchedule()">
                                <mat-option *ngFor="let selectableHour of selectableHours" [value]="selectableHour">{{ selectableHour }}</mat-option>
                              </mat-select>
                              <mat-hint>Please select an hour of day.</mat-hint>
                            </mat-form-field>
                          </div>

                          <div class="col-4">
                            <mat-form-field floatLabel="always" class="full-width mb-3">
                              <mat-label>Schedule:</mat-label>
                              <mat-select [(ngModel)]="form.schedule" matTooltip="Please select your schedule." name="schedule" (selectionChange)="compileSchedule()">
                                <mat-option value="weekdays">Weekdays</mat-option>
                                <mat-option value="day-of-month">Day of month</mat-option>
                              </mat-select>
                              <mat-hint>Please select your schedule.</mat-hint>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="full-width mb-3" *ngIf="['day-of-month'].indexOf(form.schedule) > -1">
                          <p><strong>Selectable days of month:</strong></p>
                          <div class="row">
                            <div class="col-3" *ngFor="let selectableDay of selectableDays">
                              <mat-checkbox [(ngModel)]="selectableDay.checked" [value]="'' + (selectableDay.value)" [ngModelOptions]="{standalone: true}" (change)="compileSchedule()">{{ selectableDay.label }}</mat-checkbox>
                            </div>
                          </div>
                        </div>

                        <div class="full-width mb-3" *ngIf="['weekdays'].indexOf(form.schedule) > -1">
                          <p><strong>Selectable weekdays:</strong></p>
                          <div class="row">
                            <div class="col-3" *ngFor="let selectableWeek of selectableWeeks">
                              <mat-checkbox [(ngModel)]="selectableWeek.checked" [value]="'' + (selectableWeek.value)" [ngModelOptions]="{standalone: true}" (change)="compileSchedule()">{{ selectableWeek.label }}</mat-checkbox>
                            </div>
                          </div>
                        </div>

                        <div class="full-width mb-3" *ngIf="['weekdays', 'day-of-month'].indexOf(form.schedule) > -1">
                          <p><strong>Selectable months:</strong></p>
                          <div class="row">
                            <div class="col-3" *ngFor="let selectableMonth of selectableMonths">
                              <mat-checkbox [(ngModel)]="selectableMonth.checked" [value]="'' + (selectableMonth.value)" [ngModelOptions]="{standalone: true}" (change)="compileSchedule()">{{ selectableMonth.label }}</mat-checkbox>
                            </div>
                          </div>
                        </div>

                        <div>
                          Schedule: <strong>{{ getCronText() }}</strong>.
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </ng-container>

                  <div class="alert alert-info mb-3 small " role="alert" *ngIf="form.is_active || form.users_count > 0 || (form.is_auto_assignable && !form.is_template) || form.is_template">
                    <p *ngIf="form.is_active">
                      This form is active or will be activated.
                    </p>

                    <p *ngIf="form.users_count > 0">
                      This form is assigned to {{ form.users_count }} user(s). It can not be changed to a template.
                      You can still deactivate or remove the form if it was created and assigned in error.
                      Assigned users will not see deactivated forms.
                    </p>

                    <p *ngIf="form.is_auto_assignable && !form.is_template">
                      This form will automatically be assigned to users in your connected network.
                      Use the {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }}, industries or job titles buttons below to target specific users.
                      If you select {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }}, only your {{ utils.getLangTerm('employees.plural', 'Employees') }} and users from contractors that are associated with the selected {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} will be targeted.
                    </p>

                    <p *ngIf="form.auto_assign_to_employees && !form.is_template">
                      This form will automatically be assigned to {{ utils.getLangTerm('employees.plural', 'Employees') }} you add to your account.
                    </p>

                    <p *ngIf="form.auto_assign_at_site_entry && !form.is_template">
                      This form will automatically be assigned to users entering any of your selected {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} below.
                      Use the industries or job titles buttons below to target specific users.
                    </p>

                    <p *ngIf="form.is_template">
                      This form is a template. Template forms will not be assigned to users.
                      To assign a copy of this form to users, you need to enable the "recurring" form option and configure a schedule.
                      You will also need to select which {{ utils.getLangTerm('contractors.plural', 'Contractors') }} or users you want to assign the copied form to.
                      Use the industries and job titles buttons below to filter your {{ utils.getLangTerm('contractors.plural', 'Contractors') }} or users.
                      Selecting a {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} will only associate the form with that {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }}.
                    </p>

                    <p *ngIf="form.is_template && form.is_recurring">
                      This is a recurring form that will be copied and assigned to selected {{ utils.getLangTerm('contractors.plural', 'Contractors') }} and users.
                    </p>
                  </div>

                  <mat-card-actions align="right">
                    <button mat-raised-button type="button" color="secondary" class="mr-1" (click)="onSelectSites()" [matBadge]="'' + (form.const_site_ids && form.const_site_ids.length ? form.const_site_ids.length : '')" [matTooltip]="form.is_auto_assignable ? 'Only ' + utils.getLangTerm('contractors.plural', 'Contractors') + ' and users that have access to the selected ' + utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() + ' will be assigned. If ' + utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() + ' are not selected, all networked users will be assigned to the form. Use the industries and job titles selectors to filter the users.' : 'Select the ' + utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() + ' you want to associate this form with.'">{{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}</button>
                    <button mat-raised-button type="button" color="secondary" class="mr-1" (click)="onSelectContractors()" [matBadge]="'' + (form.const_account_ids && form.const_account_ids.length ? form.const_account_ids.length : '')" [matTooltip]="'Select ' + utils.getLangTerm('contractors.plural', 'Contractors') + ' to assign this form to. This will assign the form to all users associated with the selected ' + utils.getLangTerm('contractors.plural', 'Contractors') + '.'">{{ utils.getLangTerm('contractors.plural', 'Contractors') }}</button>
                    <button mat-raised-button type="button" color="secondary" class="mr-1" (click)="onSelectUsers()" [matBadge]="'' + (form.const_user_ids && form.const_user_ids.length ? form.const_user_ids.length : '')" matTooltip="Select users to assign this form to.">Users</button>
                    <button mat-raised-button type="button" color="secondary" class="mr-1" (click)="onSelectIndustries()" [matBadge]="'' + (form.const_industry_ids && form.const_industry_ids.length ? form.const_industry_ids.length : '')" matTooltip="Select industries to filter the list of {{ utils.getLangTerm('contractors.plural', 'Contractors') }} by.">Industries</button>
                    <button mat-raised-button type="button" color="secondary" (click)="onSelectTrades()" [matBadge]="'' + (form.const_trade_ids && form.const_trade_ids.length ? form.const_trade_ids.length : '')" matTooltip="Select job titles to filter the list of users by.">Job Titles</button>
                  </mat-card-actions>
                </mat-card>
              </form>
            </mat-tab>

            <mat-tab label="Form Notifications">
              <app-dynamic-forms-notification-users *ngIf="form.id; else noFormForNotifications" [form_id]="form.id"></app-dynamic-forms-notification-users>
              <ng-template #noFormForNotifications>
                <div class="alert alert-info m-3 small" role="alert">
                  <p>
                    Please save the form to enable the ability to select users for notifications to be sent to advising that the form has been submitted.
                  </p>
                </div>
              </ng-template>
            </mat-tab>

            <mat-tab label="Form Preview">

              <div class="d-flex justify-content-end p-3">
                <button mat-raised-button color="secondary" type="button" class="mr-1" (click)="onResetFormPreviewFieldValues()" matTooltip="Click this button to reset the form input values. This is generally used to test form field display conditions for an 'unanswered' form. This does not affect user form submissions.">Reset "Form Preview" Values</button>
              </div>

              <h5 *ngIf="form.title != ''">{{ form.title }}</h5>

              <p *ngIf="form.description != ''">{{ form.description }}</p>

              <div *ngFor="let formField of form.fields; let i = index">
                <ng-container *ngIf="evaluateConditions(formField)">
                  <div [ngSwitch]="formField.field_type" appMarginBottom>
                    <mat-form-field floatLabel="always" *ngSwitchCase="'input'">
                      <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>

                      <ng-container *ngIf="formField.field_input_type == 'tel'; else noTel">
                        <app-phone-input
                          [idInput]="'tel-input-' + i"
                          [numberInput]="formField.field_value"
                          [countryCodeInput]="formField.field_meta.mobile_country_code"
                          [dialCodeInput]="formField.field_meta.mobile_dial_code"
                          [e164NumberInput]="formField.field_meta.mobile_e164"
                          (phoneDetailsOutput)="extractPhoneDetails($event, i)"
                          [required]="formField.field_is_required == true">
                        </app-phone-input>
                      </ng-container>
                      <ng-template #noTel>
                        <input matInput [type]="formField.field_input_type" [(ngModel)]="formField.field_value" [required]="formField.field_is_required == true">
                      </ng-template>

                      <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" *ngSwitchCase="'datetime'">
                      <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>
                      <input matInput [matDatepicker]="picker" [(ngModel)]="formField.field_value" [ngModelOptions]="{standalone: true}" [required]="formField.field_is_required == true">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" *ngSwitchCase="'textarea'">
                      <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>
                      <textarea matInput [(ngModel)]="formField.field_value" [required]="formField.field_is_required == true"></textarea>
                      <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" *ngSwitchCase="'select'">
                      <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>
                      <select matNativeControl [(ngModel)]="formField.field_value" [required]="formField.field_is_required == true">
                        <option *ngFor="let formFieldOption of formField.field_options" [value]="formFieldOption.option_text">{{formFieldOption.option_text}}</option>
                      </select>
                      <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
                    </mat-form-field>

                    <div *ngSwitchCase="'radio'">
                      <span class="sc-custom-label">{{ formField.field_label }} <ng-container *ngIf="formField.field_is_required == true">*</ng-container></span>
                      <mat-hint *ngIf="formField.field_description" appMarginBottom>{{ formField.field_description }}</mat-hint>
                      <mat-radio-group aria-label="Selectable Options" [(ngModel)]="formField.field_value" [ngModelOptions]="{standalone: true}" [required]="formField.field_is_required == true">
                        <mat-radio-button class="sc-custom-radio" *ngFor="let formFieldOption of formField.field_options" [value]="formFieldOption.option_text">{{formFieldOption.option_text}}</mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div *ngSwitchCase="'checkbox'">
                      <mat-hint class="sc-custom-label">{{ formField.field_label }}</mat-hint>
                      <mat-hint *ngIf="formField.field_description" appMarginBottom>{{ formField.field_description }}</mat-hint>
                      <mat-checkbox class="sc-custom-checkbox" *ngFor="let formFieldOption of formField.field_options" [(ngModel)]="formFieldOption.option_checked" [ngModelOptions]="{standalone: true}" [required]="formFieldOption.option_is_required == true">
                        {{formFieldOption.option_text}} <span *ngIf="formFieldOption.option_is_required == true">*</span>
                      </mat-checkbox>
                    </div>

                    <div *ngSwitchCase="'toggle'">
                      <span class="sc-custom-label">{{formField.field_label}}</span>
                      <mat-hint *ngIf="formField.field_description" appMarginBottom>{{ formField.field_description }}</mat-hint>
                      <mat-slide-toggle class="sc-custom-toggle" *ngFor="let formFieldOption of formField.field_options" [(ngModel)]="formFieldOption.option_checked" [required]="formFieldOption.option_is_required == true">
                        {{formFieldOption.option_text}} <span *ngIf="formFieldOption.option_is_required == true">*</span>
                      </mat-slide-toggle>
                    </div>

                    <div *ngSwitchCase="'paragraph'">
                      <span class="sc-custom-header">{{ formField.field_label }}</span>
                      <p class="sc-custom-content" *ngIf="formField.field_description">{{formField.field_description}}</p>
                    </div>

                    <div *ngSwitchCase="'wysiwyg'">
                      <span class="sc-custom-header">{{ formField.field_label }}</span>
                      <div class="sc-custom-content" *ngIf="formField.field_description" [innerHTML]="formField.field_description | sanitizeHtml"></div>
                    </div>

                    <div *ngSwitchCase="'filepicker'">
                      <span class="sc-custom-label">{{ formField.field_label }}</span>
                      <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
                      <input type="file" placeholder="File Picker" />
                    </div>

                    <div *ngSwitchCase="'signature'" style="padding: 4px;">
                      <app-digital-signature [title]="formField.field_label + ' ' + (formField.field_is_required ? '*' : '')" [subtitle]="formField.field_description" (signature_component_instance)="preview_signature_component_instances.push($event)"></app-digital-signature>
                    </div>

                    <div *ngSwitchCase="'user-selector'">
                      <span class="sc-custom-label">{{ formField.field_label }} <span *ngIf="formField.field_is_required == true">*</span></span>
                      <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>

                      <mat-nav-list dense *ngIf="formField.field_meta['user_objects']" appMarginTop>
                        <mat-list-item *ngFor="let user_object of formField.field_meta['user_objects']; let i = index;">
                          <ng-container matLine>
                            <span style="color: #0096AB;" matTooltip="Open Public User Profile">{{ user_object.contact_person }}</span>
                          </ng-container>
                          <button type="button" color="warn" mat-icon-button (click)="onRemoveUserObjectForUserSelectorField(formField, i)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-list-item>
                      </mat-nav-list>

                      <button mat-button mat-raised-button (click)="onOpenUsersSelectorForUserSelectorField(formField)" matTooltip="Click this button to open the users selector.">
                        <mat-icon>people</mat-icon>
                        Select Users
                      </button>
                    </div>
                  </div>
                </ng-container>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-dialog-actions class="d-flex justify-content-end">
  <button mat-raised-button color="secondary" type="button" class="mr-1" (click)="onSelectCategories()" [matBadge]="'' + (form.category_ids && form.category_ids.length ? form.category_ids.length : '')" matTooltip="Select from a list of form categories in your account.">Categories</button>
  <button mat-raised-button color="primary" type="button" class="mr-1" (click)="onSaveForm(f)" [disabled]="!f.valid" matTooltip="Save the form and continue editing it.">Save Form</button>
  <button mat-raised-button color="primary" type="button" (click)="onSaveForm(f)" mat-dialog-close *ngIf="form.id" [disabled]="!f.valid" matTooltip="Save the form and close the dialog.">Save Form & Close</button>
</mat-dialog-actions>
