<div mat-dialog-title>
  <div class="d-flex">
    <h3>Hazards & Risks</h3>
    <span style="flex: 1 1 auto;"></span>
    <form
      class="form-inline my-2 my-lg-0"
      (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2"
        type="search"
        [(ngModel)]="dataSource.search"
        name="search"
        placeholder="Search"
        aria-label="Search" />

      <button mat-raised-button class="mr-sm-2" type="submit">
        <mat-icon>search</mat-icon> Search
      </button>

      <button
        mat-raised-button
        class="mr-sm-2"
        (click)="onClearSelected()"
        type="button">
        Clear Selected
      </button>
    </form>
  </div>
</div>

<mat-dialog-content>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="name"
    matSortDirection="asc"
    matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef width="65px">
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
      <td mat-cell *matCellDef="let element">
        {{ element.id }}
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Hazard/Risk</th>
      <td mat-cell *matCellDef="let element">
        <b>{{ element.name }}</b> <br />
        <small>{{ element.description }}</small>
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="ira">
      <th mat-header-cell *matHeaderCellDef>Initial <br />Risk Assessment</th>
      <td mat-cell *matCellDef="let element">
        {{ utils.getRiskAssessmentText(element.ira_likelihood, element.ira_severity) }}
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="rra">
      <th mat-header-cell *matHeaderCellDef>Residual <br />Risk Assessment</th>
      <td mat-cell *matCellDef="let element">
        {{ utils.getRiskAssessmentText(element.rra_likelihood, element.rra_severity) }}
      </td>
    </ng-container>

    <!-- Date Created Column -->
    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</th>
      <td mat-cell *matCellDef="let element">{{ (element.date_created * 1000) | timezoneConvertMedium }}</td>
    </ng-container>

    <!-- Date Created (UTC) Column -->
    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created (UTC)</th>
      <td mat-cell *matCellDef="let element">{{ (element.date_created * 1000) | timezoneConvertUTCMedium }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <mat-paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>

  <button
    mat-raised-button
    color="primary"
    type="button"
    mat-dialog-close
    (click)="onSaveAndClose()"
    [disabled]="!data['multiple'] && !dataSource.selection.hasValue()">
    Select & Close
  </button>
</mat-dialog-actions>
