<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/audit-items">Inspection/Audit Questions</a>

  <button
    mat-raised-button
    color="primary"
    routerLink="/audit-items/new/edit"
    *ngIf="!auditId">
    Add Question
  </button>

  <mat-select
    [(ngModel)]="selectedSection"
    placeholder="Section Filter"
    class="navbar-brand selection-filter"
    (selectionChange)="filterSection()"
    matTooltip="Filter by Section">
    <mat-option value="">All </mat-option>
    <mat-option *ngFor="let section of sections" [value]="section">
      {{ section }}
    </mat-option>
  </mat-select>

  <form
    tourAnchor="tour.search"
    class="form-inline my-2 my-lg-0 force-right"
    (ngSubmit)="dataSource.getData(true)">

    <input
      class="form-control mr-sm-2"
      type="search"
      [(ngModel)]="dataSource.search"
      name="search"
      placeholder="Search"
      aria-label="Search" />

    <button
      mat-raised-button
      class="mr-sm-2"
      color="secondary"
      (click)="dataSource.getData(false)"
      type="button">
      <mat-icon>search</mat-icon>Search
    </button>
  </form>
</nav>

<div class="mat-elevation-z8 scroll-box">
  <table
    mat-table
    [dataSource]="dataSource"
    style="width: 100%"
    matSort
    matSortActive="name"
    matSortDisableClear>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Question
      </th>
      <td mat-cell *matCellDef="let auditItem" class="no-padding">
        {{ auditItem.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="section">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Section
      </th>
      <td mat-cell *matCellDef="let auditItem" class="no-padding">
        {{ auditItem.section }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Status
      </th>
      <td mat-cell *matCellDef="let auditItem" class="no-padding">
        {{ auditItem.status }}
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Note
      </th>
      <td mat-cell *matCellDef="let auditItem" class="no-padding">
        {{ auditItem.note | ellipsis: [30] }}
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let auditItem">
        <a routerLink="/audit-items/{{ auditItem.id }}/view/">View</a>
        <span *ngIf="!auditId">
          | <a routerLink="/audit-items/{{ auditItem.id }}/edit/">Edit</a> |
        </span>
        <span *ngIf="!auditId">
          <a href="javascript:void(0)" (click)="delete(auditItem)">Delete</a>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>
</div>
