<div mat-dialog-title class="position-relative w-100">
  <h5>Filter Accounts</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close this dialog." mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <mat-form-field style="width:100%;" floatLabel="always" class="mb-3">
    <mat-label>Subscription.</mat-label>
    <mat-select [(ngModel)]="filter_options.subscription" matTooltip="Filter accounts by subscription." name="subscription">
      <mat-option value="">All</mat-option>
      <mat-option value="premium">Premium</mat-option>
      <mat-option value="advanced">Advanced</mat-option>
      <mat-option value="basic">Basic</mat-option>
      <mat-option value="free">Free</mat-option>
    </mat-select>
    <mat-hint>Use this to filter accounts by subscription.</mat-hint>
  </mat-form-field>

  <div>
    <mat-checkbox [(ngModel)]="filter_options.include_merged_accounts" matTooltip="When enabled, merged accounts will be included in the list of results." name="include_merged_accounts">
      Include "Merged" Accounts.
    </mat-checkbox>
    <mat-hint>When enabled, merged accounts will be included in the list of results.</mat-hint>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-center my-3">
  <button type="button" mat-raised-button color="primary" (click)="onSaveAndClose()">
    <mat-icon>filter_list</mat-icon> Apply Filter
  </button>

  <button type="button" mat-raised-button color="secondary" (click)="onClearAndClose()">
    <mat-icon> clear_all</mat-icon> Clear Filter
  </button>
</mat-dialog-actions>
