import { SiteModel } from './site.model';
import { FileModel } from '../shared/file.model';
import { UserModel } from './user.model';
import { IncidentCauseModel } from '../incidents/incident-cause.model';
import { Auditable } from '../shared/auditable.model';
import {TagModel} from "./tag.model";

export class IncidentModel extends Auditable {
  id = 0;
  before_incident_notes: string;
  incident_notes: string;
  after_incident_notes: string;

  causes: IncidentCauseModel[] = [];

  /**
   * Does this Incident require a WorkSafe notification?
   */
  worksafe_notification = 0;

  no_investigation_reason = '';

  /**
   * Does this Incident require an investigation?
   */
  requires_investigation = 0;

  drug_testing_required = '';
  body_parts = '';

  /**
   * Environment | Property Damage | Illness | Injury | Near Miss
   */
  type = '';

  who_was_involved = '';
  who_was_involved_selections: string[] = [];

  /**
   * Unverified | Reported | In Progress | Submitted
   */
  status = 'Reported';

  illness_type = '';

  injury_type = '';

  /**
   * Users this Incident affected.
   */
  users: UserModel[] = [];

  /**
   * Users to be associated with the Incident.
   */
  user_ids: number[] = [];

  date_incident = 0;
  details = '';

  site_id = 0;
  site = new SiteModel();
  files: FileModel[] = [];
  created_by_user: UserModel = new UserModel();

  date_created = 0;

  is_lti: boolean = false;
  estimated_lti_days: number = 0;
  actual_lti_days: number = 0;
  actual_lti_hours: number = 0;

  treatment: string = 'No Treatment'; // Can only be: No Treatment, Medical Treatment, First Aid, Hospitalisation.
  casual_analysis_findings: string = '';

  /**
   * Tags that belongs to Incidents?
   */
  tags: TagModel[] = [];
  tag_ids: number[] = [];

  location: string = '';

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.date_incident = 0;
    this.details = '';
    this.created_by = 0;
    this.site = new SiteModel();
    this.files = [];
    this.users = [];
    this.user_ids = [];
    this.tags = [];
    this.tag_ids = [];
    this.type = '';
    this.who_was_involved = '';
    this.who_was_involved_selections = [];
    this.illness_type = '';
    this.injury_type = '';
    this.drug_testing_required = '';
    this.worksafe_notification = 0;
    this.requires_investigation = 0;
    this.causes = [];
    this.body_parts = '';
    this.created_by_user = new UserModel();
    this.date_created = 0;
    this.before_incident_notes = '';
    this.incident_notes = '';
    this.after_incident_notes = '';
    this.location = '';

    this.is_lti = false;
    this.estimated_lti_days = 0;
    this.actual_lti_days = 0;
    this.actual_lti_hours = 0;
  }
}
