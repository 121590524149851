import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';

@Component({
  selector: 'app-sssp-snapshots',
  templateUrl: './sssp-snapshots.component.html',
  styleUrls: ['./sssp-snapshots.component.scss']
})
export class SsspSnapshotsComponent implements OnInit, OnDestroy {

  @Input('sssp_hash') sssp_hash: string;

  listDataSource = new SSSPSnapshotsDataSource(this.app, this.api);

  listColumns: string[] = [
    // 'select',
    'id',
    'snapshot_name',
    'status',
    'date_modified',
    // 'date_modified_UTC',
    'actions'
  ];

  // the paginator and sorter
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  refreshSnapshotListInterval: any;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    // Set the Safety Plan hash in the list data source to get the Safety Plan docs.
    this.listDataSource.sssp_hash = this.sssp_hash;

    this.listDataSource.getData();

    // Refresh this list every 30 seconds
    this.refreshSnapshotListInterval = setInterval(() => {
      this.listDataSource.getData();
    }, 30000);
  }

  ngOnDestroy() {
    clearInterval(this.refreshSnapshotListInterval);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    // Reset the paginator when sorting takes place
    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.listDataSource.limit = paginator['pageSize'];
      this.listDataSource.offset = paginator['pageIndex'];
      this.listDataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.listDataSource.order_by = sorter['active'];
      this.listDataSource.order = sorter['direction'];

      // Sort the UTC column by its corresponding date field.
      if ( sorter['active'] == 'date_modified_UTC' ) {
        this.listDataSource.order_by = 'date_modified';
      }

      this.listDataSource.getData();
    })).subscribe();
  }

  /**
   * Call the API endpoint to generate a new Safety Plan snapshot.
   */
  onGenerateSnapshot() {
    this.api.makeRequest('post', `v1/sssp/${this.sssp_hash}/snapshots`)
    .then((response) => {
      this.utils.showToast('Your request to generate a Safety Plan snaphot was successful. You will be notified via Email when it is ready.');
      this.listDataSource.getData();
    });
  }

}

export class SSSPSnapshotsDataSource extends ApiDataSource {

  order_by = 'date_modified';
  order = 'desc';

  sssp_hash: string;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v1/sssp/${this.sssp_hash}/snapshots`, resetOffset);
  }
}
