import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { AccountModel } from 'src/app/models/account.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-accounts-merger',
  templateUrl: './accounts-merger.component.html',
  styleUrls: ['./accounts-merger.component.scss']
})
export class AccountsMergerComponent implements OnInit {

  displayedColumns: string[] = [
    'id',
    'name',
    'date_created',
    'actions'
  ];

  dataSource = new AccountsMergerDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  primaryAccount: AccountModel = new AccountModel();

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService,
    private dialogRef: MatDialogRef<AccountsMergerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.preComponentChecks();
  }

  private preComponentChecks() {
    if ( !this.data.selectedAccountIds || this.data.selectedAccountIds.length < 2 ) {
      this.utils.showModal('Cannot Merge', 'You must have at least two accounts to merge.');
      this.dialogRef.close();
      return;
    }
  }

  ngOnInit() {
    // Get the accounts
    if ( this.data.selectedAccountIds && this.data.selectedAccountIds.length > 1 ) {
      this.dataSource.ids = this.data.selectedAccountIds;
      this.dataSource.getData(true);
    }
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData();
    });

    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onMakePrimaryAccount(index:  number) {
    this.primaryAccount.apply(this.dataSource.dataSubject.value[index]);
  }

  onSubmit(form: NgForm) {
    if ( !form.valid ) {
      this.utils.showModal('Confirmation', 'Please make sure you have a primary account selected.');
      return;
    }

    this.utils.showModal('Confirmation', 'Are you sure you want to merge all other listed accounts into the selected primary account?', () => {
      this.api.makeRequest('put', 'v2/admin/accounts/merge', {
        account: this.primaryAccount,
        mergeIds: this.data.selectedAccountIds.filter((id) => id != this.primaryAccount.id)
      })
      .then((response) => {
        this.utils.showToast('The accounts merged successfully.');
        this.dialogRef.close();
      });
    });
  }
}

export class AccountsMergerDataSource extends ApiDataSource {

  order_by = 'id';
  order = 'asc';

  ids: number[] = [];

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/admin/accounts', resetOffset, {
      ids: this.ids.join(',')
    });
  }
}
