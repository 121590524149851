<div mat-dialog-title class="p-3">
  <h5>
    Safety Observation
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
</div>

<div mat-dialog-content class="p-3">
  <mat-tab-group (selectedTabChange)="onTabChanged($event)">
    <mat-tab label="Safety Observation">
      <div class="pt-3">

        <div class="row">
          <div class="col">
            <p><b>Category</b>: {{ safety_observation.so_category }}</p>
            <p><b>Type</b>: {{ safety_observation.so_type }}</p>
            <p><b>Priority</b>: {{ safety_observation.so_priority }}</p>
            <p><b>Status</b>: {{ safety_observation.so_status }}</p>
            <p><b>Rating</b>: {{ safety_observation.so_rating }}</p>
            <p><b>Description</b>: {{ safety_observation.so_description }}</p>
            <p><b>Action Taken</b>: {{ safety_observation.so_action_taken }}</p>
          </div>

          <div class="col">
            <p><b>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Name</b>: {{ safety_observation.site.name }}</p>
            <p><b>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Address</b>: {{ safety_observation.site.location }}</p>
            <p><b>Created By</b>:
              <span *ngIf="safety_observation?.created_by_user"><a (click)="onUserPublicView(safety_observation.created_by_user.hash)"
                matTooltip="View User Public Profile">
                {{ safety_observation.created_by_user.contact_person }}
              </a></span>
            </p>
            <p>
              <b>Reported At</b>: {{ ((safety_observation.reported_date ? safety_observation.reported_date : safety_observation.date_created) * 1000) | timezoneConvertMedium }} <br>
              <b>Reported At (UTC)</b>: {{ ((safety_observation.reported_date ? safety_observation.reported_date : safety_observation.date_created) * 1000) | timezoneConvertUTCMedium }}
            </p>
            <p *ngIf="safety_observation?.reported_by_user">
              <b>Reported By</b>: <a (click)="onUserPublicView(safety_observation.reported_by_user.hash)" matTooltip="View User Public Profile">
                {{ safety_observation.reported_by_user.contact_person }}
              </a> <br>
              <b>Reported By Employer</b>: <span *ngIf="safety_observation.reported_by_employer">{{ safety_observation.reported_by_employer.name }}</span>
            </p>
            <p>
              <b>Last Updated At</b>: {{ (safety_observation.date_modified * 1000) | timezoneConvertMedium }} <br>
              <b>Last Updated At (UTC)</b>: {{ (safety_observation.date_modified * 1000) | timezoneConvertUTCMedium }}
            </p>
            <p><b>Last Updated By</b>: <span *ngIf="safety_observation?.updated_by_user"><a (click)="onUserPublicView(safety_observation.updated_by_user.hash)" matTooltip="View User Public Profile">
                {{ safety_observation.updated_by_user.contact_person }}
              </a></span>
            </p>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Files">
      <app-safety-observations-view-files
        #safetyObservationsViewFilesComponent
        *ngIf="safety_observation.id"
        [safety_observation_id]="safety_observation.id"
        [safety_observation]="safety_observation">
      </app-safety-observations-view-files>
    </mat-tab>

    <mat-tab label="Corrective Actions">
      <app-safety-observations-view-corrective-actions
        #safetyObservationsViewCorrectiveActionsComponent
        *ngIf="safety_observation.id"
        [safety_observation_id]="safety_observation.id"
        [safety_observation]="safety_observation">
      </app-safety-observations-view-corrective-actions>
    </mat-tab>

    <mat-tab label="Hazards & Risks">
      <app-safety-observations-view-hazards
        #safetyObservationsViewHazardsComponent
        *ngIf="safety_observation.id"
        [safety_observation_id]="safety_observation.id"
        [safety_observation]="safety_observation">
      </app-safety-observations-view-hazards>
    </mat-tab>

    <mat-tab label="{{ utils.getLangTerm('contractors.plural', 'Contractors') }}">
      <app-safety-observations-view-contractors
        #safetyObservationsViewContractorsComponent
        *ngIf="safety_observation.id"
        [safety_observation_id]="safety_observation.id"
        [safety_observation]="safety_observation">
      </app-safety-observations-view-contractors>
    </mat-tab>

    <mat-tab label="Users">
      <app-safety-observations-view-users
        #safetyObservationsViewUsersComponent
        *ngIf="safety_observation.id"
        [safety_observation_id]="safety_observation.id"
        [safety_observation]="safety_observation">
      </app-safety-observations-view-users>
    </mat-tab>

    <mat-tab label="Messages">
      <app-safety-observations-view-messages
        #safetyObservationsViewMessagesComponent
        *ngIf="safety_observation.id"
        [safety_observation_id]="safety_observation.id"
        [safety_observation]="safety_observation">
      </app-safety-observations-view-messages>
    </mat-tab>
  </mat-tab-group>
</div>
