import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TaskAnalysesRevisedWorkflowHazardModel} from "../../models/task-analyses-revised-workflow-hazard.model";
import {RISK_LEVEL_OF_CONTROLS} from "../../shared/risk-level-of-controls";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-sites-task-analyses-revised-workflows-hazards-view',
  templateUrl: './sites-task-analyses-revised-workflows-hazards-view.component.html',
  styleUrls: ['./sites-task-analyses-revised-workflows-hazards-view.component.scss']
})
export class SitesTaskAnalysesRevisedWorkflowsHazardsViewComponent implements OnInit {

  loc_list = RISK_LEVEL_OF_CONTROLS;
  site_id: number;
  ta_revised_id: number;
  ta_revised_workflow_id: number;
  ta_revised_workflow_hazard: TaskAnalysesRevisedWorkflowHazardModel = new TaskAnalysesRevisedWorkflowHazardModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    // get the task analysis workflow id
    this.ta_revised_workflow_hazard.id = this.dialogData.ta_revised_workflow_hazard.id;

    // get the task analysis workflow id
    this.ta_revised_workflow_id = this.dialogData.ta_revised_workflow_id;

    // get the task analysis id
    this.ta_revised_id = this.dialogData.ta_revised_id;

    // get the site id
    this.site_id = this.dialogData.site_id;

    // get sites path edit/view
    // this.path = this.dialogData.path;

    if(this.ta_revised_workflow_hazard.id) {
      this.getWorkflowHazard(this.ta_revised_workflow_hazard.id);
    }
  }

  private getWorkflowHazard (ta_revised_workflow_hazard_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards/${ta_revised_workflow_hazard_id}`, [], {
      site_id: this.site_id
    })
      .then((response) => {
        this.ta_revised_workflow_hazard = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }
}
