<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button mat-raised-button routerLink="/sites" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>

    <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
    </button>
  </nav>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand" id="title">QR Codes</span>
    <button class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class=" mr-auto">

        <button
          mat-raised-button
          color="primary"
          [routerLink]="utils.prepareSiteRelatedLink(site_id, child_id ? parent_id : null, 'edit/codes/new/edit')"
          appMarginRight>
          <mat-icon>add</mat-icon>
          New
        </button>

        <button
          mat-raised-button
          color="secondary"
          (click)="claimCode()"
          appMarginRight>
          <mat-icon>check</mat-icon>
          Claim
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onDeleteSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect QR Codes." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          Menu
        </button>
      </span>

      <span class="spinner-container" *ngIf="(dataSource.loading | async)" appMarginRight></span>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onFilter()" matTooltip="Click this button to display filter options.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (ngSubmit)="dataSource.getData(true)">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search" />

        <button
          mat-raised-button
          class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
          color="secondary"
          (click)="dataSource.getData(true)">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

      <!--button mat-raised-button (click)="export()" color="secondary"><mat-icon>import_export</mat-icon> Export</button-->
    </div>

  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <!-- Site Column -->
      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
        </th>
        <td mat-cell *matCellDef="let element" class="text-wrap">
          <ng-container *ngIf="element.site">
            <ng-container *ngIf="element?.site?.parent_site">
              <small style="display: block;">
                <a [routerLink]="utils.prepareSiteRelatedLink(element?.site?.parent_site?.id, null, '/edit/details')">
                  {{ element?.site?.parent_site?.name || '' }}
                </a>
              </small>
            </ng-container>
            <mat-icon *ngIf="element?.site?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
            <a [routerLink]="utils.prepareSiteRelatedLink(element?.site?.id, element?.site?.parent_site?.id, '/edit/details')">
              {{ element?.site?.name || '' }}
            </a>
          </ng-container>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Feature Column -->
      <ng-container matColumnDef="feature">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Feature</th>
        <td mat-cell *matCellDef="let element">{{ element.feature }}</td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let element">{{ element.type }}</td>
      </ng-container>

      <!-- Date Created Column -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</th>
        <td mat-cell *matCellDef="let element">{{ (element.date_created * 1000) | timezoneConvertMedium }}</td>
      </ng-container>

      <!-- Date Created Column -->
      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created (UTC)</th>
        <td mat-cell *matCellDef="let element">{{ (element.date_created * 1000) | timezoneConvertUTCMedium }}</td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let element">
          <a type="button" mat-button [routerLink]="utils.prepareSiteRelatedLink(element.site_id, element?.site?.parent_site_id, 'edit/codes/' + element.id + '/view')" matTooltip="View the QR Code information." matTooltipPosition="above">
            View
          </a>

          <a type="button" mat-button [routerLink]="utils.prepareSiteRelatedLink(element.site_id, element?.site?.parent_site_id, 'edit/codes/' + element.id + '/edit')" matTooltip="Edit the QR Code information." matTooltipPosition="above">
            Edit
          </a>

          <button type="button" mat-button (click)="onRemove(element)" matTooltip="Remove (Archive) the QR Code." matTooltipPosition="above">
            Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
