<h5 mat-dialog-title>
  Notification Role
  <button type="button" mat-icon-button class="float-right my-0" (click)="onClose()">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</h5>

<div mat-dialog-content #tabsContainer>
  <mat-tab-group>
    <mat-tab label="Notification Role">
      <ng-template matTabContent>
        <app-notification-roles-edit [notification_role_id]="notification_role_id" (notification_role_id_changed)="notification_role_id = $event" (close_dialog)="onClose()" (scroll_to_top)="onScrollToTop()"></app-notification-roles-edit>
      </ng-template>
    </mat-tab>

    <mat-tab label="Sites" *ngIf="notification_role_id">
      <ng-template matTabContent>
        <app-notification-roles-sites [notification_role_id]="notification_role_id"></app-notification-roles-sites>
      </ng-template>
    </mat-tab>

    <mat-tab [label]="utils.getLangTerm('employees.plural', 'Employees')" *ngIf="notification_role_id">
      <ng-template matTabContent>
        <app-notification-roles-users [notification_role_id]="notification_role_id"></app-notification-roles-users>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
