<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="javascript:void(0)">Items</a>
</nav>

<div class="mat-elevation-z8 scroll-box">
  <table mat-table [dataSource]="dataSource" style="width: 100%" matSort
    matSortActive="sort_order" matSortDirection="asc" matSortDisableClear>

    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item">
        <mat-icon [ngClass]="item.pivot.status.toLowerCase()">
          {{ getIcon(item) }}
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef >Status</th>
      <td mat-cell *matCellDef="let item">
        {{ item.pivot?.status }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Name
      </th>
      <td mat-cell *matCellDef="let item" class="no-padding">
        {{ item.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="requireDocuments">
      <th mat-header-cell *matHeaderCellDef class="no-padding">
        Require Documents
      </th>
      <td mat-cell *matCellDef="let item" class="no-padding">
        {{ item.pivot?.answer == 'NA' ? 'Not Applicable' : item.pivot?.answer }}
      </td>
    </ng-container>

    <ng-container matColumnDef="expiry">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>Expiry</th>
      <td mat-cell *matCellDef="let item" class="no-padding">
        <span *ngIf="item.pivot?.expiry">
          {{ (item.pivot?.expiry * 1000) | timezoneConvertShort }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="expiry_UTC">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>Expiry (UTC)</th>
      <td mat-cell *matCellDef="let item" class="no-padding">
        <span *ngIf="item.pivot?.expiry">
          {{ (item.pivot?.expiry * 1000) | timezoneConvertUTCShort }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="rating">
      <th mat-header-cell *matHeaderCellDef class="no-padding">Rating</th>
      <td mat-cell *matCellDef="let item" class="no-padding">
        <app-rating [rating]="item.pivot?.rating" [edit]="app.account.isMasterAccount()"
          (ratingChanged)="ratePivot(item.pivot?.id, $event)"></app-rating>
      </td>
    </ng-container>

    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef class="no-padding" >
        Notes
      </th>
      <td mat-cell *matCellDef="let item" class="no-padding text-wrap">
        {{ item.pivot.notes }}
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef width="300px">Actions</th>
      <td mat-cell *matCellDef="let item">

        <button type="button" mat-button (click)="onView(item.pivot?.id)">
          View
        </button>

        <button type="button" mat-button (click)="onEdit(item.pivot?.id)" *ngIf="app.account.isMasterAccount()">
          Edit
        </button>

        <button type="button" mat-button *ngIf="item.pivot.status !== 'Approved' && app.account.isMasterAccount()" (click)="onApprove(item, $event)">
          Approve
        </button>

        <button type="button" mat-button *ngIf="item.pivot.status !== 'Required' && app.account.isMasterAccount()" (click)="onReject(item, $event)">
          Reject
        </button>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons></mat-paginator>
</div>
