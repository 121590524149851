<div mat-dialog-title class="position-relative w-100">
  <h5>Filter Induction Report</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close Filter Induction Report" mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onChangeSelectedAccount()"
        [matBadge]="'' + (filter_options.account_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>business</mat-icon> Companies
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectUsers()"
        [matBadge]="'' + (filter_options.user_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>account_circle</mat-icon> Assigned Users
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-3" style="width: 140px;">
        <mat-select placeholder="Status" class="filter-dropdowns" [(ngModel)]="filter_options.status" matTooltip="Filter induction report by status." name="status">
          <mat-option value="">All</mat-option>
          <mat-option value="not started">Not Started</mat-option>
          <mat-option value="in progress">In Progress</mat-option>
          <mat-option value="completed">Completed</mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

</div>

<div class="d-flex justify-content-center my-2 py-2">
  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell>
      <button
        type="button"
        mat-raised-button
        class="float-right"
        color="primary"
        (click)="onSaveAndClose()">
        <mat-icon>filter_list</mat-icon> Apply Filter
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell>
      <button
        type="button"
        mat-raised-button
        color="secondary"
        class="float-right"
        (click)="onClearAndClose()">
        <mat-icon> clear_all</mat-icon> Clear Filter
      </button>
    </td>
  </ng-container>
</div>

