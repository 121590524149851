<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/gitlab-issues">Development Schedule</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto"></span>

      <span class="mr-3">Milestone:</span>

      <mat-select class="mr-3 filter-dropdowns" [(ngModel)]="milestone" matTooltip="Select the milestone to view." name="milestone_filter" (selectionChange)="getIssues()">
        <mat-option *ngFor="let _milestone of milestones" [value]="_milestone">{{ _milestone.title }}</mat-option>
      </mat-select>

      <span class="mr-3" *ngIf="milestone?.due_date">Target Date: {{ milestone.due_date | timezoneConvertMedium }}</span>

    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="listDataSource">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let issue">
          #{{ issue.iid }}
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let issue">
          <span>{{ issue.title }}</span> <br>
          <ng-container *ngIf="issue.labels.length > 0">
            <span class="badge mr-2" style="background-color: #1aa1ac; color: white;" *ngFor="let label of issue.labels">
              {{ label }}
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>State</th>
        <td mat-cell *matCellDef="let issue">{{ issue.state | titlecase }}</td>
      </ng-container>

      <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef>Last Updated</th>
        <td mat-cell *matCellDef="let issue">
          {{ issue.updated_at | timezoneConvertMedium }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listDisplayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listDisplayColumns"></tr>
    </table>
  </div>

  <div class="d-flex justify-content-center mt-3 mb-3">
    <button mat-raised-button class="mr-2" (click)="onLoadPrev()" [disabled]="shouldDisablePrevButton()">Prev</button>
    <button mat-raised-button (click)="onLoadNext()" [disabled]="shouldDisableNextButton()">Next</button>
  </div>
</div>
