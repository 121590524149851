<div mat-dialog-title class="p-3" *ngIf="!incidentTabsComponent">
  <h5>
    Incident
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
</div>

<ng-container *ngIf="!incidentTabsComponent else tabs">
  <div mat-dialog-content class="px-3">
    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div class="container-fluid">

        <div class="row m-0 mb-3 mt-3">
          <div class="col">
            <mat-form-field *ngIf="incident.id">
              <input placeholder="Reported By"
                [value]="incident?.created_by_user?.contact_person"
                matTooltip="Who created the Incident?" disabled matInput />
                <mat-hint>The person that reported the incident.</mat-hint>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field *ngIf="incident.id">
              <input placeholder="Reported At"
                [value]="incident?.date_created * 1000 | timezoneConvertLong"
                matTooltip="When was this Incident reported?" disabled matInput />
                <mat-hint>When the incident took place.</mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="row m-0 mb-3 mt-3">
          <div class="col">
            <mat-form-field *ngIf="incident.id">
              <input placeholder="{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}" [value]="incident.site.name + ' - ' + incident.site.location"
                matTooltip="Where did this Incident occur?" disabled matInput />
                <mat-hint>The address the incident took place at.</mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="row m-0 mb-3">
          <div class="col">

            <app-timezone-select *ngIf="!incident.id" [timezoneInput]="selectedTimezone" (timezoneOutput)="getOutputTimezone($event)"></app-timezone-select>

            <mat-form-field class="mb-3">
              <mat-label>Select the date and time the incident took place:</mat-label>
              <input matInput [ngxMatDatetimePicker]="incidentDateTimePicker" readonly required name="incidentDateTime" [(ngModel)]="incidentDateTime">
              <mat-datepicker-toggle matSuffix [for]="incidentDateTimePicker"></mat-datepicker-toggle>
              <button type="button" mat-button matSuffix mat-icon-button *ngIf="incidentDateTime" (click)="incidentDateTime = null" matTooltip="Remove the date."><mat-icon>close</mat-icon></button>
              <ngx-mat-datetime-picker #incidentDateTimePicker [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
              <mat-hint>
                Click the calendar icon to select the date and time the incident took place. <br>
                <b class="text-danger" *ngIf="!incidentDateTime">Please selected a date.</b>
              </mat-hint>
           </mat-form-field>

            <mat-form-field class="mb-3">
              <textarea matInput placeholder="Summary of Incident"
                [(ngModel)]="incident.details" name="details" #details rows="5"
                required matTooltip="What happened specifically?">
              </textarea>
              <mat-hint>Please enter a general summary of the incident.</mat-hint>
            </mat-form-field>

            <mat-form-field class="mb-3">
              <mat-select placeholder="What type of treatment was required?" [(ngModel)]="incident.treatment" name="treatment" matInput>
                <mat-option value="No Treatment">No Treatment</mat-option>
                <mat-option value="Medical Treatment">Medical Treatment</mat-option>
                <mat-option value="First Aid">First Aid</mat-option>
                <mat-option value="Hospitalisation">Hospitalisation</mat-option>
              </mat-select>
              <mat-hint>Please select what type of treatment was required.</mat-hint>
            </mat-form-field>

            <mat-form-field class="mb-3">
              <mat-select placeholder="Type of Incident" [(ngModel)]="incident.type"
                name="type" matInput required #type>
                <mat-option *ngFor="let incident_type of incident_types" [value]="incident_type">{{incident_type}}</mat-option>
              </mat-select>
              <mat-hint>Please select what type of incident took place.</mat-hint>
            </mat-form-field>

            <mat-form-field *ngIf="incident.type === 'Illness'" class="mb-3">
              <mat-select placeholder="Illness Type" [(ngModel)]="incident.illness_type"
                name="illness_type" matInput required #illnessType>
                <mat-option *ngFor="let illness of illness_types" [value]="illness">
                  {{illness}}
                </mat-option>
              </mat-select>
              <mat-hint>Please select what type of illness it is.</mat-hint>
            </mat-form-field>

            <mat-form-field *ngIf="incident.type === 'Injury'" class="mb-3">
              <mat-select placeholder="Injury Type" [(ngModel)]="incident.injury_type"
                name="injury_type" matInput required #injuryType>
                <mat-option *ngFor="let injury of injury_types" [value]="injury">
                  {{injury}}
                </mat-option>
              </mat-select>
              <mat-hint>Please select what type of injury it is.</mat-hint>
            </mat-form-field>

            <mat-form-field *ngIf="incident.type === 'Injury'" class="mb-3">
              <mat-select placeholder="Body Parts" [(ngModel)]="incidentBodyParts"
                multiple name="body_parts" matInput>
                <mat-option *ngFor="let part of body_parts" [value]="part">
                  {{part}}
                </mat-option>
              </mat-select>
              <mat-hint>Please select one or more affected body parts.</mat-hint>
            </mat-form-field>

            <mat-form-field class="mb-3">
              <mat-select placeholder="Who was Involved?" [(ngModel)]="incident.who_was_involved_selections"
                          name="who_was_involved" matInput #whoWasInvolved multiple>
                <mat-option *ngFor="let who_was_involved_option of who_was_involved_options" [value]="who_was_involved_option">{{who_was_involved_option}}</mat-option>
              </mat-select>
              <mat-hint>Please select who was involved with the incident.</mat-hint>
            </mat-form-field>

            <mat-form-field class="mb-3">
              <mat-select placeholder="Drug and Alcohol Testing Required?"
                [(ngModel)]="incident.drug_testing_required" name="drug_testing"
                matInput required #drugTesting
                matTooltip="Should the affected users be tested?">
                <mat-option></mat-option>
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
              <mat-hint>Please select Yes if drug and alcohol testing is required.</mat-hint>
            </mat-form-field>

            <mat-form-field class="mb-3">
              <mat-select
                [placeholder]="utils.getLangTerm('incidents.form.worksafe_notification', 'Does this incident require a WorkSafe notification?')"
                [(ngModel)]="incident.worksafe_notification" name="worksafe-required"
                matInput>
                <mat-option [value]="1">Yes</mat-option>
                <mat-option [value]="0">No</mat-option>
              </mat-select>
              <mat-hint>{{ utils.getLangTerm('incidents.form.worksafe_notification_hint', 'Please select Yes if a WorkSafe notification is required.') }}</mat-hint>
            </mat-form-field>

          </div>

          <div class="col">

            <ng-container *ngIf="incident.id else cannotChangeStatus">
              <mat-form-field class="mb-3">
                <mat-select placeholder="Please select the status of the incident." [(ngModel)]="incident.status" name="status" matInput>
                  <mat-option *ngFor="let status of statuses" [value]="status">{{ status }}</mat-option>
                </mat-select>
                <mat-hint>Please select the status of the incident.</mat-hint>
              </mat-form-field>
            </ng-container>
            <ng-template #cannotChangeStatus>
              <p><strong>Status:</strong> {{ incident.status }}</p>
            </ng-template>

            <mat-form-field class="mb-3">
              <mat-select placeholder="Does the incident require an investigation?"
                [(ngModel)]="incident.requires_investigation"
                name="requires-investigation" matInput>
                <mat-option [value]="1">Yes</mat-option>
                <mat-option [value]="0">No</mat-option>
              </mat-select>
              <mat-hint>Please select Yes if an investigation is required.</mat-hint>
            </mat-form-field>

            <mat-form-field *ngIf="!incident.requires_investigation" class="mb-3">
              <textarea matInput
                placeholder="Provide a reason for not investigating this incident"
                [(ngModel)]="incident.no_investigation_reason"
                name="no-investigation-reason" #noInvestigationReason rows="5"
                matTooltip="Why won't this Incident be investigated?">
              </textarea>
              <mat-error *ngIf="!noInvestigationReason.valid">
                Please provide a reason for not investigating this incident.
              </mat-error>
            </mat-form-field>

            <mat-form-field class="mb-3">
              <mat-select placeholder="Is this a Lost Time Injury?" [(ngModel)]="incident.is_lti" name="is_lti" matInput>
                <mat-option [value]="1">Yes</mat-option>
                <mat-option [value]="0">No</mat-option>
              </mat-select>
              <mat-hint>Please select Yes if this is a Lost Time Injury.</mat-hint>
            </mat-form-field>

            <mat-form-field *ngIf="incident.is_lti == true" class="mb-3">
              <input matInput placeholder="Estimated LTI Days" [(ngModel)]="incident.estimated_lti_days"
                name="estimated_lti_days" type="number" [required]="incident.is_lti == true" #estimated_lti_days matTooltip="How many days do you estimate for this LTI?" />
              <mat-hint>Please enter your estimated LTI days here. You can use 0.5 to indicate half a day.</mat-hint>
            </mat-form-field>

            <div class="alert alert-info mt-3" role="alert" *ngIf="incident.is_lti == true">
              Actual Calculated LTI: {{ incident.actual_lti_days }} day{{ incident.actual_lti_days != 1 ? 's' : '' }}.
            </div>

            <mat-form-field class="mb-3">
              <textarea matInput placeholder="Incident Causal Analysis Findings."
              [(ngModel)]="incident.casual_analysis_findings" name="casual_analysis_findings" #casual_analysis_findings
              rows="5" matTooltip="Incident causal analysis findings & how and why did this incident happen.">
              </textarea>
              <mat-hint>Incident causal analysis findings & how and why did this incident happen.</mat-hint>
            </mat-form-field>

            <mat-form-field class="mb-3">
              <textarea
                matInput
                placeholder="Location"
                [(ngModel)]="incident.location"
                name="location"
                #location
                rows="5"
                matTooltip="Please enter the incident location here.">
              </textarea>
              <mat-hint>Please enter the incident location here.</mat-hint>
            </mat-form-field>

          </div>
        </div>

        <div class="row m-0 mb-3">
          <div class="col">

            <mat-error *ngIf="!incident.site_id">Please select a {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}.</mat-error>

            <button type="button" mat-raised-button matTooltip="Save the Incident"
              [disabled]="!f.valid || !incident.site_id || inProgress" color="primary"
              (click)="onSubmit(f)" appMarginRight>
              <mat-icon>save</mat-icon>
              Save
            </button>

            <button mat-raised-button type="button" color="secondary"
              (click)="onSelectSites()" appMarginRight *ngIf="!incident.id"
              class="select-site" matTooltip="Where did this happen?">
              <mat-icon>location_on</mat-icon>
              Select {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} * ({{ incident.site_id ? '1' : '0' }})
            </button>

            <app-file-browse-button
              (selectedFiles)="onSelectFiles($event)"
              (afterDialogClosedEvent)="onSubmit(f)"
              parentObjectType="incident"
              [parentObjectId]="incident.id"
              [filesCount]="incident.files.length">
            </app-file-browse-button>

            <button mat-raised-button color="secondary" appMarginRight
              [matBadge]="'' + (incident?.user_ids?.length)" type="button"
              (click)="onSelectUsers()" matTooltip="Who was impacted by this Incident?">
              <mat-icon>people</mat-icon>
              Affected Users
            </button>

            <button
              mat-raised-button
              type="button"
              (click)="selectTags()"
              [matBadge]="'' + (incident.tag_ids ? incident.tag_ids?.length : 0)"
              color="secondary"
              matTooltip="Select Tags from the Tags selection list.">
              <mat-icon> local_offer</mat-icon>
              Tags
            </button>

            <button mat-icon-button (click)="onRemove()" *ngIf="incident.id"
              appMarginRight class="float-right" matTooltip="Delete this Incident"
              type="button">
              <mat-icon>delete</mat-icon>
            </button>

            <button type="button" class="ml-3" (click)="onCancel()" mat-raised-button
              color="secondary" appMarginRight *ngIf="dialogData">
              Cancel
            </button>

          </div>
        </div>

      </div>
    </form>
  </div>
</ng-container>

<ng-template #tabs>
  <form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <div class="container-fluid">

      <div class="row m-0 mb-3 mt-3">
        <div class="col">
          <mat-form-field *ngIf="incident.id">
            <input placeholder="Reported By"
              [value]="incident?.created_by_user?.contact_person"
              matTooltip="Who created the Incident?" disabled matInput />
              <mat-hint>The person that reported the incident.</mat-hint>
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field *ngIf="incident.id">
            <input placeholder="Reported At"
              [value]="incident?.date_created * 1000 | timezoneConvertLong"
              matTooltip="When was this Incident reported?" disabled matInput />
              <mat-hint>When the incident took place.</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row m-0 mb-3 mt-3">
        <div class="col">
          <mat-form-field *ngIf="incident.id">
            <input placeholder="{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}" [value]="incident.site.name + ' - ' + incident.site.location"
              matTooltip="Where did this Incident occur?" disabled matInput />
              <mat-hint>The address the incident took place at.</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row m-0 mb-3">
        <div class="col">

          <app-timezone-select *ngIf="!incident.id" [timezoneInput]="selectedTimezone" (timezoneOutput)="getOutputTimezone($event)"></app-timezone-select>

          <mat-form-field class="mb-3">
            <mat-label>Select the date and time the incident took place:</mat-label>
            <input matInput [ngxMatDatetimePicker]="incidentDateTimePicker" readonly required name="incidentDateTime" [(ngModel)]="incidentDateTime">
            <mat-datepicker-toggle matSuffix [for]="incidentDateTimePicker"></mat-datepicker-toggle>
            <button type="button" mat-button matSuffix mat-icon-button *ngIf="incidentDateTime" (click)="incidentDateTime = null" matTooltip="Remove the date."><mat-icon>close</mat-icon></button>
            <ngx-mat-datetime-picker #incidentDateTimePicker [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
            <mat-hint>
              Click the calendar icon to select the date and time the incident took place. <br>
              <b class="text-danger" *ngIf="!incidentDateTime">Please selected a date.</b>
            </mat-hint>
         </mat-form-field>

          <mat-form-field class="mb-3">
            <textarea matInput placeholder="Summary of Incident"
              [(ngModel)]="incident.details" name="details" #details rows="5"
              required matTooltip="What happened specifically?">
            </textarea>
            <mat-hint>Please enter a general summary of the incident.</mat-hint>
          </mat-form-field>

          <mat-form-field class="mb-3">
            <mat-select placeholder="What type of treatment was required?" [(ngModel)]="incident.treatment" name="treatment" matInput>
              <mat-option value="No Treatment">No Treatment</mat-option>
              <mat-option value="Medical Treatment">Medical Treatment</mat-option>
              <mat-option value="First Aid">First Aid</mat-option>
              <mat-option value="Hospitalisation">Hospitalisation</mat-option>
            </mat-select>
            <mat-hint>Please select what type of treatment was required.</mat-hint>
          </mat-form-field>

          <mat-form-field class="mb-3">
            <mat-select placeholder="Type of Incident" [(ngModel)]="incident.type"
              name="type" matInput required #type>
              <mat-option *ngFor="let incident_type of incident_types" [value]="incident_type">{{incident_type}}</mat-option>
            </mat-select>
            <mat-hint>Please select what type of incident took place.</mat-hint>
          </mat-form-field>

          <mat-form-field *ngIf="incident.type === 'Illness'" class="mb-3">
            <mat-select placeholder="Illness Type" [(ngModel)]="incident.illness_type"
              name="illness_type" matInput required #illnessType>
              <mat-option *ngFor="let illness of illness_types" [value]="illness">
                {{illness}}
              </mat-option>
            </mat-select>
            <mat-hint>Please select what type of illness it is.</mat-hint>
          </mat-form-field>

          <mat-form-field *ngIf="incident.type === 'Injury'" class="mb-3">
            <mat-select placeholder="Injury Type" [(ngModel)]="incident.injury_type"
              name="injury_type" matInput required #injuryType>
              <mat-option *ngFor="let injury of injury_types" [value]="injury">
                {{injury}}
              </mat-option>
            </mat-select>
            <mat-hint>Please select what type of injury it is.</mat-hint>
          </mat-form-field>

          <mat-form-field *ngIf="incident.type === 'Injury'" class="mb-3">
            <mat-select placeholder="Body Parts" [(ngModel)]="incidentBodyParts"
              multiple name="body_parts" matInput>
              <mat-option *ngFor="let part of body_parts" [value]="part">
                {{part}}
              </mat-option>
            </mat-select>
            <mat-hint>Please select one or more affected body parts.</mat-hint>
          </mat-form-field>

          <mat-form-field class="mb-3">
            <mat-select placeholder="Who was Involved?" [(ngModel)]="incident.who_was_involved_selections"
                        name="who_was_involved" matInput #whoWasInvolved multiple>
              <mat-option *ngFor="let who_was_involved_option of who_was_involved_options" [value]="who_was_involved_option">{{who_was_involved_option}}</mat-option>
            </mat-select>
            <mat-hint>Please select who was involved with the incident.</mat-hint>
          </mat-form-field>

          <mat-form-field class="mb-3">
            <mat-select placeholder="Drug and Alcohol Testing Required?"
              [(ngModel)]="incident.drug_testing_required" name="drug_testing"
              matInput required #drugTesting
              matTooltip="Should the affected users be tested?">
              <mat-option></mat-option>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-hint>Please select Yes if drug and alcohol testing is required.</mat-hint>
          </mat-form-field>

          <mat-form-field class="mb-3">
            <mat-select
              [placeholder]="utils.getLangTerm('incidents.form.worksafe_notification', 'Does this incident require a WorkSafe notification?')"
              [(ngModel)]="incident.worksafe_notification" name="worksafe-required"
              matInput>
              <mat-option [value]="1">Yes</mat-option>
              <mat-option [value]="0">No</mat-option>
            </mat-select>
            <mat-hint>{{ utils.getLangTerm('incidents.form.worksafe_notification_hint', 'Please select Yes if a WorkSafe notification is required.') }}</mat-hint>
          </mat-form-field>

        </div>

        <div class="col">

          <ng-container *ngIf="incident.id else cannotChangeStatus">
            <mat-form-field class="mb-3">
              <mat-select placeholder="Please select the status of the incident." [(ngModel)]="incident.status" name="status" matInput>
                <mat-option *ngFor="let status of statuses" [value]="status">{{ status }}</mat-option>
              </mat-select>
              <mat-hint>Please select the status of the incident.</mat-hint>
            </mat-form-field>
          </ng-container>
          <ng-template #cannotChangeStatus>
            <p><strong>Status:</strong> {{ incident.status }}</p>
          </ng-template>

          <mat-form-field class="mb-3">
            <mat-select placeholder="Does the incident require an investigation?"
              [(ngModel)]="incident.requires_investigation"
              name="requires-investigation" matInput>
              <mat-option [value]="1">Yes</mat-option>
              <mat-option [value]="0">No</mat-option>
            </mat-select>
            <mat-hint>Please select Yes if an investigation is required.</mat-hint>
          </mat-form-field>

          <mat-form-field *ngIf="!incident.requires_investigation" class="mb-3">
            <textarea matInput
              placeholder="Provide a reason for not investigating this incident"
              [(ngModel)]="incident.no_investigation_reason"
              name="no-investigation-reason" #noInvestigationReason rows="5"
              matTooltip="Why won't this Incident be investigated?">
            </textarea>
            <mat-error *ngIf="!noInvestigationReason.valid">
              Please provide a reason for not investigating this incident.
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mb-3">
            <mat-select placeholder="Is this a Lost Time Injury?" [(ngModel)]="incident.is_lti" name="is_lti" matInput>
              <mat-option [value]="1">Yes</mat-option>
              <mat-option [value]="0">No</mat-option>
            </mat-select>
            <mat-hint>Please select Yes if this is a Lost Time Injury.</mat-hint>
          </mat-form-field>

          <mat-form-field *ngIf="incident.is_lti == true" class="mb-3">
            <input matInput placeholder="Estimated LTI Days" [(ngModel)]="incident.estimated_lti_days"
              name="estimated_lti_days" type="number" [required]="incident.is_lti == true" #estimated_lti_days matTooltip="How many days do you estimate for this LTI?" />
            <mat-hint>Please enter your estimated LTI days here. You can use 0.5 to indicate half a day.</mat-hint>
          </mat-form-field>

          <div class="alert alert-info mt-3" role="alert" *ngIf="incident.is_lti == true">
            Actual Calculated LTI: {{ incident.actual_lti_days }} day{{ incident.actual_lti_days != 1 ? 's' : '' }}.
          </div>

          <mat-form-field class="mb-3">
            <textarea matInput placeholder="Causal Analysis Findings"
            [(ngModel)]="incident.casual_analysis_findings" name="casual_analysis_findings" #casual_analysis_findings
            rows="5" matTooltip="Please enter your causal analysis findings here.">
            </textarea>
            <mat-hint>Please enter your causal analysis findings here.</mat-hint>
          </mat-form-field>

          <mat-form-field class="mb-3">
            <textarea
              matInput
              placeholder="Location"
              [(ngModel)]="incident.location"
              name="location"
              #location
              rows="5"
              matTooltip="Please enter the incident location here.">
            </textarea>
            <mat-hint>Please enter the incident location here.</mat-hint>
          </mat-form-field>

        </div>
      </div>

      <div class="row m-0 mb-3">
        <div class="col">

          <mat-error *ngIf="!incident.site_id">Please select a Site.</mat-error>

          <button type="button" mat-raised-button matTooltip="Save the Incident"
            [disabled]="!f.valid || !incident.site_id || inProgress" color="primary"
            (click)="onSubmit(f)" appMarginRight>
            <mat-icon>save</mat-icon>
            Save
          </button>

          <button mat-raised-button type="button" color="secondary"
            (click)="onSelectSites()" appMarginRight *ngIf="!incident.id"
            class="select-site" matTooltip="Where did this happen?">
            <mat-icon>location_on</mat-icon>
            Select {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} * ({{ incident.site_id ? '1' : '0' }})
          </button>

          <app-file-browse-button
            (selectedFiles)="onSelectFiles($event)"
            (afterDialogClosedEvent)="onSubmit(f)"
            parentObjectType="incident"
            [parentObjectId]="incident.id"
            [filesCount]="incident.files.length">
          </app-file-browse-button>

          <button mat-raised-button color="secondary" appMarginRight
            [matBadge]="'' + (incident?.user_ids?.length)" type="button"
            (click)="onSelectUsers()" matTooltip="Who was impacted by this Incident?">
            <mat-icon>people</mat-icon>
            Affected Users
          </button>

          <button
            mat-raised-button
            type="button"
            (click)="selectTags()"
            [matBadge]="'' + (incident.tag_ids ? incident.tag_ids?.length : 0)"
            color="secondary"
            matTooltip="Select Tags from the Tags selection list.">
            <mat-icon> local_offer</mat-icon>
            Tags
          </button>

          <button mat-icon-button (click)="onRemove()" *ngIf="incident.id"
            appMarginRight class="float-right" matTooltip="Delete this Incident"
            type="button">
            <mat-icon>delete</mat-icon>
          </button>

          <button type="button" class="ml-3" (click)="onCancel()" mat-raised-button
            color="secondary" appMarginRight *ngIf="dialogData">
            Cancel
          </button>

        </div>
      </div>

    </div>
  </form>
</ng-template>

