import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { UserRoutingModule } from './user-routing.module';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserMessagesComponent } from './user-messages/user-messages.component';
import { UserMessagesViewComponent } from './user-messages/user-messages-view/user-messages-view.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { UserAccessibleSitesComponent } from './user-accessible-sites/user-accessible-sites.component';
import { UserMessagesFilterComponent } from './user-messages-filter/user-messages-filter.component';
import { UserDynamicFormsComponent } from './user-dynamic-forms/user-dynamic-forms.component';
import { MatComponentsModule } from '../mat-components/mat-components.module';
import { UserDynamicFormsEditComponent } from './user-dynamic-forms-edit/user-dynamic-forms-edit.component';
import { UtilsModule } from '../utils/utils.module';
import { UserDynamicFormsTemplateSelectorComponent } from './user-dynamic-forms-template-selector/user-dynamic-forms-template-selector.component';
import { UserDynamicFormsCreateWizardComponent } from './user-dynamic-forms-create-wizard/user-dynamic-forms-create-wizard.component';
import { UserAccessibleSitesSelectorComponent } from './user-accessible-sites-selector/user-accessible-sites-selector.component';
import { SharedModule } from '../shared/shared.module';
import { UserDynamicFormsFilterComponent } from './user-dynamic-forms-filter/user-dynamic-forms-filter.component';
import {UserTasksComponent} from "./user-tasks/user-tasks.component";
import {UserTasksEditComponent} from "./user-tasks-edit/user-tasks-edit.component";
import {UserTasksFilterComponent} from "./user-tasks-filter/user-tasks-filter.component";
import {FilesModule} from "../files/files.module";
import {MultiAccountDashboardModule} from "./multi-account-dashboard/multi-account-dashboard.module";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";

@NgModule({
  entryComponents: [
    UserMessagesViewComponent,
    UserMessagesFilterComponent,
    UserTasksEditComponent,
    UserTasksFilterComponent,
    UserDynamicFormsEditComponent,
    UserDynamicFormsTemplateSelectorComponent,
    UserAccessibleSitesSelectorComponent,
    UserDynamicFormsCreateWizardComponent,
    UserDynamicFormsFilterComponent
  ],
  declarations: [
    UserDetailsComponent,
    UserMessagesComponent,
    UserTasksComponent,
    UserTasksEditComponent,
    UserTasksFilterComponent,
    UserMessagesViewComponent,
    UserAccessibleSitesComponent,
    UserMessagesFilterComponent,
    UserDynamicFormsComponent,
    UserDynamicFormsEditComponent,
    UserDynamicFormsTemplateSelectorComponent,
    UserAccessibleSitesSelectorComponent,
    UserDynamicFormsCreateWizardComponent,
    UserDynamicFormsFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UserRoutingModule,
    DirectivesModule,
    PipesModule,
    MatComponentsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatDatepickerModule,
    MatTabsModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCardModule,
    BsDatepickerModule,
    MatRadioModule,
    UtilsModule,
    SharedModule,
    FilesModule,
    MultiAccountDashboardModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule
  ]
})
export class UserModule {}
