import {AfterContentInit, Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { ApiService } from '../shared/api.service';
import { SessionService } from '../shared/session.service';
import { UtilsService } from '../shared/utils.service';
import {StorageService} from "../shared/storage.service";
import {moveItemInArray} from "@angular/cdk/drag-drop";
import _ from 'lodash';
import {DashboardFilterComponent} from "./dashboard-filter/dashboard-filter.component";

/**
 * Represents the configuration for holding containers.
 * @interface
 */
interface ContainersConfig {
  rows: {
    cols: number,
    containers: { id: string, container: string }[]
  }[];
  isPanelExpanded: boolean;
  filters: {
    site_ids: number[],
    date_range: Date[]
  };
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterContentInit {

  // The base storage key to use for the dashboard chart containers.
  @Input('baseStorageKey') baseStorageKey: string;

  // Config storage key. This must be unique when multiple containers are stored on one page.
  configStorageKey: string;

  // Available charts for the user to select from.
  availableContainers: any[] = [
    {
      containerName: 'Access Activities Charts',
      container: 'SiteAccessActivitiesChartsContainerComponent',
      canHaveMany: true
    },
    {
      containerName: 'Forms Charts',
      container: 'DynamicFormsChartsContainerComponent',
      canHaveMany: true
    },
    {
      containerName: 'Hazards Charts',
      container: 'HazardsChartsContainerComponent',
      canHaveMany: true
    },
    {
      containerName: 'Incidents Charts',
      container: 'IncidentsChartsContainerComponent',
      canHaveMany: true
    },
    {
      containerName: 'Inductions Charts',
      container: 'InductionsChartsContainerComponent',
      canHaveMany: true
    },
    {
      containerName: 'Inspections & Audits Charts',
      container: 'SiteAuditsChartsContainerComponent',
      canHaveMany: true
    },
    {
      containerName: 'Messages',
      container: 'MessagesChartsContainerComponent',
      canHaveMany: true
    },
    {
      containerName: 'Safety Observations Charts',
      container: 'SafetyObservationsChartsContainerComponent',
      canHaveMany: true
    },
    {
      containerName: this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') + ' Charts',
      container: 'SitesChartsContainerComponent',
      canHaveMany: true
    },
    {
      containerName: 'Tasks Charts',
      container: 'TasksChartsContainerComponent',
      canHaveMany: true
    },
    {
      containerName: this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks'),
      container: 'ToolboxTalksChartsContainerComponent',
      canHaveMany: true
    },
    {
      containerName: 'Legacy Dashboard',
      container: 'LegacyDashboardWidgetComponent',
      canHaveMany: false
    },
    {
      containerName: 'My Inductions',
      container: 'UserInductionsWidgetComponent',
      canHaveMany: false
    }
  ];

  // The config for the containers.
  config: ContainersConfig = {
    rows: [],
    isPanelExpanded: false,
    filters: {
      site_ids: [],
      date_range: []
    }
  } as ContainersConfig;

  // Separate rows to store container references in. This does not have to persist.
  rows: {
    containerRefs: any[]
  }[] = [];

  // The config storage object to temporarily store config pulled from persisted storage.
  configStorageObject: any = {};

  // Disable the panel toggle capabilities.
  @Input('disableExpansionPanel') disableExpansionPanel: boolean;

  // Set the realtime data polling to false.
  enable_realtime_data: boolean = false;
  // Set the default realtime data polling interval to 60 seconds..
  realtime_data_interval_seconds: number = 60;

  // Set the default query type to single. This can only be single or multiple.
  query_type: string = 'single';

  constructor(
    public sessionService: SessionService,
    public router: Router,
    public utils: UtilsService,
    public app: AppService,
    private api: ApiService,
    private storage: StorageService
  ) {}

  async ngOnInit(): Promise<any> {
    // Check if the user is authenticated or not.
    if ( !this.sessionService.isAuthenticated() ) {
      // Redirect the user to the sign-in page.
      this.router.navigate(['/sign-in']);
      return;
    }

    // Set the base storage keys if it was not provided.
    if ( !this.baseStorageKey ) {
      this.baseStorageKey = 'dashboard';
    }

    // Set the config storage key.
    this.configStorageKey = this.baseStorageKey + '-' + this.app.account.id + '-config';

    // Disable the panel toggle by default for the dashboard.
    if ( typeof this.disableExpansionPanel == 'undefined' ) {
      this.disableExpansionPanel = true;
    }

    // Get the stored object from persistent storage.
    this.configStorageObject = await this.storage.dbGet('charts', this.configStorageKey);

    // Override the rows in the config from storage. Here we provide a default set of containers.
    this.config.rows = this.configStorageObject ? this.configStorageObject.rows : [{
      cols: 1,
      containers: [
        {
          id: this.generateUniqueId(),
          container: 'LegacyDashboardWidgetComponent'
        }
      ]
    }, {
        cols: 2,
        containers: [
          {
            id: this.generateUniqueId(),
            container: 'UserInductionsWidgetComponent'
          }
        ]
    }];

    // Override the panel expansion state from storage.
    this.config.isPanelExpanded = this.configStorageObject ? this.configStorageObject.isPanelExpanded : false;
    // Override the stored expansion panel status if the expansion panel is disabled.
    if ( this.disableExpansionPanel ) {
      // The dashboard panel must always be expanded.
      this.config.isPanelExpanded = true;
    }

    // Override the filters from storage if it was null.
    this.config.filters = this.configStorageObject ? this.configStorageObject.filters : {
      site_ids: [],
      date_range: []
    };

    // Remap the dates as new Date objects. Storing it in storage stores it as string.
    this.config.filters.date_range = this.config.filters.date_range.map((date: Date|string) => new Date(date));

    // Refresh the chart visuals.
    this.refreshChartVisuals();
  }

  ngAfterContentInit() {
    // Check if the user is authenticated or not.
    if (!this.app.isAuthenticated) {
      // Redirect the user to the sign-in page.
      this.router.navigate(['/sign-in']);
    }

    // Get the active site id.
    this.getActiveSite();
  }

  /**
   * Updates the chart reference in the rows object based on the given event, row index, and chart index.
   *
   * @param {any} containerRef - The event object containing the chart reference.
   * @param {number} rowIndex - The index of the row in the rows array.
   * @param {number} containerIndex - The index of the chart in the chartRefs array of the specified row.
   * @return {void}
   */
  onStoreContainerRef(containerRef: any, rowIndex: number, containerIndex: number): void {
    // Check if the row was initialised.
    if ( typeof this.rows[rowIndex] == 'undefined' ) {
      // Create a new row for chart refs if it was not initialised.
      this.rows[rowIndex] = {
        containerRefs: []
      };
    }
    // Add the chart reference.
    this.rows[rowIndex].containerRefs[containerIndex] = containerRef;
  }

  /**
   * Retrieves the bootstrap column configuration based on the number of columns.
   * The allowed number of columns for the dashboard is a max of 2.
   *
   * @param {number} cols - The number of columns to generate the configuration for.
   * @returns {string} - The bootstrap column configuration.
   */
  getColsConfig(cols: number): string {
    // Return bootstrap column config.
    return 'col-sm-12 col-md-6 col-lg-' + 12/cols;
  }

  /**
   * Updates the data and visuals of chart references for a given row index and stores user preferences.
   *
   * @param {number} rowIndex - The index of the row. Is not used due to two-way binding.
   * @return {void}
   */
  async onChangeColsConfig(rowIndex: number): Promise<any> {
    // Refresh the charts visuals.
    this.refreshChartVisuals();
    // Store the user preferences.
    await this.storeConfig();
  }

  /**
   * Refreshes the visuals of all charts in the rows that have containers with charts in them.
   *
   * @returns {void} Indicates that the method does not return a value.
   */
   refreshChartVisuals(): void {
     // Loop through rows to get container refs.
     this.rows.forEach((row) => {
       // Loop through container refs to refresh chart visuals.
       row.containerRefs.forEach((containerRef) => {
         // Update the child component's filters.
         if ( typeof containerRef.updateFiltersFromParentComponent == 'function' ) {
           containerRef.updateFiltersFromParentComponent(this.config.filters);
         }
         // Refresh the chart visuals.
         if ( typeof containerRef.refreshChartVisuals == 'function' ) {
           containerRef.refreshChartVisuals();
         }
       });
     });
  }

  /**
   * Adds a new row to the grid with the provided number of columns.
   *
   * @param {number} cols - The number of columns in the new row.
   * @return {void}
   */
  async onAddRow(cols: number): Promise<any> {
    // Add a new row with the provided number of cols.
    this.config.rows.unshift({
      cols: cols,
      containers: []
    });
    // Add a row for chart refs.
    this.rows.unshift({
      containerRefs: []
    });
    // Store the user preferences.
    await this.storeConfig();
  }

  /**
   * Remove a row from the grid.
   *
   * @param {any} event - The event that triggered the method.
   * @param {number} rowIndex - The index of the row to remove.
   *
   * @return {void}
   */
  async onRemoveRow(event: any, rowIndex: number): Promise<any> {
    // Get the container count for the row.
    const containerCount = this.config.rows[rowIndex].containers.length;
    // Get confirmation.
    if ( containerCount > 0 ) {
      this.utils.showQuickActions(event, `Are you sure you want to remove this row? There are ${containerCount} container(s) in it.`, [
        {
          text: 'Yes',
          handler: async (): Promise<any> => {
            // Remove the row.
            this.config.rows.splice(rowIndex, 1);
            // Do some pruning.
            await this.pruneStorage(rowIndex);
            // Remove the rows refs.
            this.rows.splice(rowIndex, 1);
            // Store the user preferences.
            await this.storeConfig();
          }
        },
        {
          text: 'No',
          handler: () => {
            // Do nothing.
          }
        }
      ]);
      return;
    }
    // Remove the row.
    this.config.rows.splice(rowIndex, 1);
    // Do some pruning.
    await this.pruneStorage(rowIndex);
    // Remove the rows refs.
    this.rows.splice(rowIndex, 1);
    // Store the user preferences.
    await this.storeConfig();
  }

  /**
   * Adds a container to a specific row in the current layout.
   *
   * @param {any} container - The container object to be added.
   * @param {number} rowIndex - The index of the row where the container will be added.
   * @return {void}
   */
  async onAddContainer(container: any, rowIndex: number): Promise<any> {
    // Check if the maximum number of containers were reached.
    if ( this.config.rows[rowIndex].containers.length >= this.config.rows[rowIndex].cols ) {
      this.utils.showToast('You have reached the maximum number of containers for this row.' + (this.config.rows[rowIndex].cols < 2 ? ' Increase the columns of the row to add more containers.' : ''));
      return;
    }
    // Add the container.
    this.config.rows[rowIndex].containers.push({
      id: this.generateUniqueId(),
      container: container
    });
    // Store the user preferences.
    await this.storeConfig();
  }

  /**
   * Removes a chart from a specific row.
   *
   * @param {boolean} shouldRemove - Indicates whether the container should be removed.
   * @param {number} rowIndex - The index of the row to remove the chart from.
   * @param {number} containerIndex - The index of the chart to remove.
   * @return {void}
   */
  async onRemoveContainer(shouldRemove: boolean, rowIndex: number, containerIndex: number): Promise<any> {
    // Check if the container should be removed.
    if ( shouldRemove ) {
      // Remove the chart from the row.
      this.config.rows[rowIndex].containers.splice(containerIndex, 1);
      // Do some pruning.
      await this.pruneStorage(rowIndex, containerIndex);
      // Remove the chartRef too if it is present.
      if ( typeof this.rows[rowIndex] != 'undefined' && typeof this.rows[rowIndex].containerRefs != 'undefined' ) {
        this.rows[rowIndex].containerRefs.splice(containerIndex, 1);
      }
      // Store the user preferences.
      await this.storeConfig();
    }
  }

  /**
   * Prune the storage of the specified rowIndex and containerIndex, or all rows and containers if not specified.
   * If both rowIndex and containerIndex are provided, prune the storage of the individual container at the specified indices.
   * If only rowIndex is provided, prune the storage of all containers in the specified row.
   * If neither rowIndex nor containerIndex are provided, prune the storage of all rows, containers, container rows, and charts.
   *
   * @param {number} rowIndex - Optional. The index of the row to prune the storage for.
   * @param {number} containerIndex - Optional. The index of the container to prune the storage for within the specified row.
   * @return {Promise} - A Promise that resolves when the storage pruning is complete.
   */
  async pruneStorage(rowIndex?: number, containerIndex?: number): Promise<any> {
    // Check if rows are empty.
    if ( this.rows.length == 0 ) {
      // Do nothing.
      return;
    }
    // Prune the storage of an individual container.
    if ( typeof rowIndex != 'undefined' && typeof containerIndex != 'undefined' ) {
      if ( typeof this.rows[rowIndex] != 'undefined' && typeof this.rows[rowIndex].containerRefs != 'undefined' && typeof this.rows[rowIndex].containerRefs[containerIndex] != 'undefined' && typeof this.rows[rowIndex].containerRefs[containerIndex].pruneStorage == 'function' ) {
        // Prune the storage.
        await this.rows[rowIndex].containerRefs[containerIndex].pruneStorage();
      }
      return;
    }
    // Prune the storage of all containers in a row.
    if ( typeof rowIndex != 'undefined' ) {
      if ( typeof this.rows[rowIndex] != 'undefined' && typeof this.rows[rowIndex].containerRefs != 'undefined' ) {
        this.rows[rowIndex].containerRefs.forEach((containerRef) => {
          if ( typeof containerRef.pruneStorage == 'function' ) {
            // Prune the storage.
            containerRef.pruneStorage();
          }
        });
      }
      return;
    }
    // DANGER: Prune the storage of all rows (containers, container rows and charts).
    this.rows.forEach((row) => {
      if ( typeof row.containerRefs != 'undefined' ) {
        row.containerRefs.forEach((containerRef) => {
          if ( typeof containerRef.pruneStorage == 'function' ) {
            // Prune the storage.
            containerRef.pruneStorage();
          }
        });
      }
    });
  }

  /**
   * Handles the vertical drag and drop functionality for containers.
   *
   * @param {Event} event - The drag and drop event.
   * @returns {void}
   */
  async onDragAndDrop(event: any): Promise<any> {
    // Move the row.
    moveItemInArray(this.config.rows, event.previousIndex, event.currentIndex);
    // Move the row's reference.
    moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
    // Store the user preferences.
    await this.storeConfig();
  }

  /**
   * Stops event propagation.
   *
   * @param {any} event - The event object.
   *
   * @return {void} - This method does not return any value.
   */
  onStopEventPropagation(event: any): void {
    // Stop bubbling.
    event.stopPropagation();
  }

  /**
   * Updates the expansion state of the dashboard panel and stores it.
   *
   * @param {boolean} isPanelExpanded - The new expansion state of the charts panel.
   * @return {Promise<any>} A Promise that resolves when the charts panel expansion state is stored.
   */
  async onStoreChartsPanelExpandedState(isPanelExpanded: boolean): Promise<any> {
    // Store the new dashboard panel expansion state.
    this.config.isPanelExpanded = isPanelExpanded;
    // Store the config.
    await this.storeConfig();
  }

  /**
   * Stores the user preferences.
   *
   * @returns {Promise<any>} A promise that resolves when the user preferences are stored.
   */
  async storeConfig(): Promise<any> {
    // Store the user preferences.
    this.configStorageObject = await this.storage.dbUpdateOrCreate('charts', { ...this.config, id: this.configStorageKey});
  }

  /**
   * Generates a unique ID by generating a random string of alphanumeric characters.
   *
   * @return {string} A unique ID.
   */
  generateUniqueId(): string {
    return Math.random().toString(36).substring(2,9);
  }

  /**
   * Open a global filter for all charts in the dashboard.
   *
   * @param {any} event - The event object.
   * @returns {void}
   */
  onFilter(event: any): void {
    // Stop event bubbling.
    this.onStopEventPropagation(event);
    // Show the filters component.
    this.utils.showComponentDialog(DashboardFilterComponent, {
      site_ids: this.config.filters.site_ids,
      date_range: this.config.filters.date_range
    }, {
      width: '350px'
    }, async (filters: any): Promise<any> => {
      // Check if we received a valid filters object.
      if ( typeof filters !== 'undefined' && filters != false ) {
        // Update or reset the filters.
        this.config.filters.site_ids = filters.site_ids || [];
        this.config.filters.date_range = filters.date_range || [];
        // Update the config in persistent storage.
        await this.storeConfig();
        // Get the data from the API.
        this.refreshChartVisuals();
      }
    });
  }

  /**
   * Checks if all filters in the config object are empty, excluding specified properties.
   * @param {string[]} propertiesToExclude - An array of property names to exclude from the check.
   * @return {boolean} - Returns true if all filters, excluding the specified properties, are empty; otherwise, returns false.
   */
  isFiltersEmptyExcluding(propertiesToExclude: string[] = []): boolean {
    // Get a list of property names to check in the filters.
    const filteredProperties = _.difference(Object.keys(this.config.filters), propertiesToExclude);
    // Check if all filters are empty.
    return _.every(filteredProperties, (key: string) => _.isEmpty(this.config.filters[key]));
  }

  /**
   * Checks if any of the rows in the configuration have already used the specified container.
   *
   * @param {string} containerToCheck - The container name to check for.
   *
   * @return {boolean} - True if the container is already used, false otherwise.
   */
  doesRowsHaveLegacyWidget(containerToCheck: string): boolean {
    // Get all available containers that can have many instances.
    const canHaveManyAvailableContainers: any[] = this.availableContainers.filter(availableContainer => availableContainer.canHaveMany);
    // Get a list of container names.
    const canHaveManyContainers: string[] = canHaveManyAvailableContainers.map(availableContainer => availableContainer.container);
    // Check if the provided container should be excluded.
    if ( canHaveManyContainers.indexOf(containerToCheck) > -1 ) {
      return false;
    }
    // Loop through all rows to check if the container to check was already used.
    for ( const row of this.config.rows ) {
      if ( typeof row.containers != 'undefined' && row.containers.some(container => container.container == containerToCheck) ) {
        // Return true if the container was already added.
        return true;
      }
    }
    // Return false if the container was not yet added.
    return false;
  }

  /**
   * full version of the code can be found in sites-selector-toolbar.component.ts
   * this code gets the first available site to use as the default site id
   */
  private getActiveSite() {
    if (!this.app.activeSiteId) {
      this.api.laravelApiObservable('get', 'sites?limit=1').toPromise().then(
        (response) => {
          if (response.data.length) {
            this.app.activeSiteId = response.data[0].id;
          }
        }
      );
    }
  }
}
