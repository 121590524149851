import { RouterModule, Routes } from '@angular/router';
import { MyPrequalificationsComponent } from './my-prequalifications.component';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: 'my-prequalifications',
    canActivate: [
      AuthGuard
    ],
    children: [
      { 
        path: '', 
        component: MyPrequalificationsComponent 
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class MyPrequalificationsRoutingModule {}
