import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { ApiRequestService } from '../shared/api-request.service';
import { EmployeeModel } from '../models/employee.model';
import { UtilsService } from '../shared/utils.service';

@Injectable()
export class EmployeeService {

  employee: EmployeeModel = new EmployeeModel();

  shouldRedirectToEmployeesOnFailure: boolean = false;

  isFirstLoad: boolean = true;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public router: Router,
    private utils: UtilsService
  ) { }

  getEmployeeById(id: number) {
    return this.api.makeRequest('get', `v2/employees/${id}`)
    .then((_employee: EmployeeModel) => {
      this.employee.apply(_employee);
      if ( this.isFirstLoad ) {
        this.isFirstLoad = !this.isFirstLoad;
      }
      return _employee;
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
      if ( this.shouldRedirectToEmployeesOnFailure ) {
        this.router.navigate(['employees']);
      }
    });
  }

  switchRole() {
    return this.api.makeRequest('put', `v2/employees/${this.employee.id}/switch-permissions`, {
      permissions: this.employee.pivot.permissions == 'Administrator' ? 'Employee' : 'Administrator'
    })
    .then((_employee: EmployeeModel) => {
      this.employee.apply(_employee);
      return _employee;
    });
  }

  updateEmployee() {
    this.api.makeRequest('put', `v2/employees/${this.employee.id}`, this.employee)
    .then((_employee: EmployeeModel) => {
      this.employee.apply(_employee);
      this.utils.showToast('The information was updated successfully.');
    })
    .catch((_errorResponse) => {
      this.utils.handleAPIErrors(_errorResponse);
    });
  }

  // This poses a massive security risk without account verification added.
  sendPasswordResetEmail() {
    return; 
    this.api.makeRequest('get', `v2/employees/${this.employee.id}/send-password-reset-email`)
    .then((response: any) => {
      this.utils.showToast(response.message);
    })
    .catch((_errorResponse) => {
      this.utils.handleAPIErrors(_errorResponse);
    });
  }

  unlockEmployeeForLogin(userId: number = null) {
    if (!(userId > 0)) {
      return this.utils.showModal(
        'Error',
        'User ID is incorrect.'
      );
    }
    this.api.makeRequest('post', `v2/employees/${userId}/unlock`).then((res) => {
      this.utils.showModal(
        'User unlocked successfully',
        'The user can now login again.'
      );
    })
  }
}
