import { FileModel } from '../shared/file.model';
import { ItemModel } from './item.model';
import { Auditable } from '../shared/auditable.model';

/**
 * The intermediary table for the items <-> prequalifications relationship.
 */
export class ItemPivotModel extends Auditable {
  id: number;

  status: 'Approved' | 'Required' | 'Pending';

  /**
   * 0-5.
   */
  rating: number;

  notes: string;
  files: FileModel[];
  expiry: number;

  item: ItemModel;

  answer: string;
}
