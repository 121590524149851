import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {NgForm} from "@angular/forms";
import {PpeSelectorComponent} from "../../shared/ppe-selector/ppe-selector.component";
import {WorkPermitsSelectorComponent} from "../../shared/work-permits-selector/work-permits-selector.component";
import {ToolsSelectorComponent} from "../../shared/tools-selector/tools-selector.component";
import {AppService} from "../../app.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NetworkedEmployeesSelectorComponent} from "../../shared/networked-employees-selector/networked-employees-selector.component";
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {TaskAnalysesRevisedTabsComponent} from "../task-analyses-revised-tabs/task-analyses-revised-tabs.component";

@Component({
  selector: 'app-task-analyses-revised-edit',
  templateUrl: './task-analyses-revised-edit.component.html',
  styleUrls: ['./task-analyses-revised-edit.component.scss']
})
export class TaskAnalysesRevisedEditComponent implements OnInit {

  @Input() taTabsComponent: TaskAnalysesRevisedTabsComponent;

  ta_revised: TaskAnalysesRevisedModel = {} as TaskAnalysesRevisedModel;

  inProgress = false;
  selectedFiles: File[] = [];
  newFiles: FileList;
  path: string;

  approverId: number = 0;
  approverAcknowledged: boolean = false;
  approverUpdateId: number = 0;

  authorizerId: number = 0;
  authorizerAcknowledged: boolean = false;
  authorizerUpdateId: number = 0;

  supervisorId: number = 0;
  supervisorAcknowledged: boolean = false;
  supervisorUpdateId: number = 0;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // get the ta revised id from the parent route params
    this.ta_revised.id = Number(this.route.parent.snapshot.children[0].params.ta_revised_id);

    // get sites path edit/view
    this.path = this.route.parent.snapshot.routeConfig.path;

    if(this.ta_revised.id) {
      this.find(this.ta_revised.id);
    } else {
      this.redirectBack();
    }
  }

  private redirectBack() {
    this.router.navigate([`/task-analyses-revised/`]);
  }

  async onSubmit(form: NgForm) {
    // Do not process if already in progress.
    if (this.inProgress) {
      return;
    }

    // Perform form validation.
    if (!form.valid) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    this.inProgress = true;

    if(this.ta_revised.id) {
      this.update();
    }
  }

  private find(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {})
      .then((response) => {
        this.ta_revised = response;

        // Extract PPE ids.
        this.ta_revised.ppe_ids = this.ta_revised.ppe ? this.ta_revised.ppe.map((ppe) => ppe.id) : [];

        // Extract Work Permit ids.
        this.ta_revised.work_permit_ids = this.ta_revised.work_permits ? this.ta_revised.work_permits.map((work_permit) => work_permit.id) : [];

        // Extract Tool ids.
        this.ta_revised.tool_ids = this.ta_revised.tools ? this.ta_revised.tools.map((tool) => tool.id) : [];

        // Extract File ids.
        this.ta_revised.file_ids = this.ta_revised.files ? this.ta_revised.files.map((file) => file.id) : [];

        // Fixed Acknowledgement Data not changed by Form updates
        this.approverId = this.ta_revised.approver_id;
        this.approverAcknowledged = this.ta_revised.approver_acknowledged;

        this.authorizerId = this.ta_revised.authorizer_id;
        this.authorizerAcknowledged = this.ta_revised.authorizer_acknowledged;

        this.supervisorId = this.ta_revised.supervisor_id;
        this.supervisorAcknowledged = this.ta_revised.supervisor_acknowledged;

      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onDelete() {
    this.utils.showModal(
      'Remove TA/JSA/SWMS Template',
      `Are you sure you want to remove the "${this.ta_revised.task_name}" TA/JSA/SWMS Template?`,
      () => {
        this.api.makeRequest('delete', `v2/task-analyses-revised/${this.ta_revised.id}`, {})
          .then((response) => {
            this.utils.showToast(`The "${this.ta_revised.task_name}" TA/JSA/SWMS Template has been deleted.`);
            // this.dialogRef.close(true);
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  private update(updateAcknowledgement: string = "", updateUsers: string = "") {
    if(updateAcknowledgement != "") {
      this.acknowledgementUpdate(updateAcknowledgement);
    } else if(updateUsers != "") {
      this.usersUpdate(updateUsers);
    } else {
      this.continueUpdate();
    }
  }

  private continueUpdate() {
    let request: Promise<any>;

    request = this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised.id}`, this.ta_revised, {})
      .then((response) => {
        this.onSuccess(`Updated: "${response.task_name}"`);
        // this.ta_revised.apply(response);
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised/${this.ta_revised.id}`, this.selectedFiles)
          .then((response) => {
            this.utils.showToast('Your files successfully uploaded.');
            this.find(this.ta_revised.id);
          })
          .finally(() => {
            this.selectedFiles.length = 0;
          });
      } else {
        this.find(this.ta_revised.id);
      }

      this.inProgress = false;
    });
  }

  private acknowledgementUpdate(updateAcknowledgement: string = "") {
    this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised.id}/acknowledgement`, this.ta_revised, {
        user_id: this.app.user.id,
        acknowledge_approver_status: updateAcknowledgement == "approver" ? this.approverAcknowledged : false,
        acknowledge_authorizer_status: updateAcknowledgement == "authorizer" ? this.authorizerAcknowledged : false,
        acknowledge_supervisor_status: updateAcknowledgement == "supervisor" ? this.supervisorAcknowledged : false,
        acknowledge_user_id: 0
      })
      .then((response) => {
        let update_text = "Acknowledgement";
        if(updateAcknowledgement == "approver") {
          update_text = "Approval";
        } else if(updateAcknowledgement == "authorizer") {
          update_text = "Authorization";
        } else if(updateAcknowledgement == "supervisor") {
          update_text = "Supervisor Acknowledgement";
        }

        this.find(this.ta_revised.id);

        this.onSuccess(`Updated "${update_text}"`);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  private usersUpdate (updateUsers: string = "") {
    this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised.id}/users`, this.ta_revised, {
        user_id: this.app.user.id,
        approver_update_id: updateUsers == "approver" ? this.approverUpdateId : false,
        authorizer_update_id: updateUsers == "authorizer" ? this.authorizerUpdateId : false,
        supervisor_update_id: updateUsers == "supervisor" ? this.supervisorUpdateId : false,
        user_update_ids: false
      })
      .then((response) => {
        let update_text = "Assignees";
        if(updateUsers == "approver") {
          update_text = "Approver";
        } else if(updateUsers == "authorizer") {
          update_text = "Authorizer";
        } else if(updateUsers == "supervisor") {
          update_text = "Supervisor";
        }

        this.find(this.ta_revised.id);

        this.onSuccess(`Updated "${update_text}"`);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
  }

  selectPPE() {
    this.utils.showComponentDialog(
      PpeSelectorComponent,
      {
        selected: this.ta_revised.ppe_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.ta_revised.ppe_ids = results;
      }
    );
  }

  selectWorkPermits() {
    this.utils.showComponentDialog(
      WorkPermitsSelectorComponent,
      {
        selected: this.ta_revised.work_permit_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.ta_revised.work_permit_ids = results;
      }
    );
  }

  selectTools() {
    this.utils.showComponentDialog(
      ToolsSelectorComponent,
      {
        selected: this.ta_revised.tool_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.ta_revised.tool_ids = results;
      }
    );
  }

  /**
   * If it is a new task analysis, add the files to the selected files array.
   * Existing task analysis files will be handled by the file manager.
   * @param files? List of files.
   */
  onSelectFiles(files?: any[]) {
    if ( files.length > 0 ) {
      this.selectedFiles.push(...files);
    }
  }

  onSelectApprover() {
    this.utils.showComponentDialog(
      NetworkedEmployeesSelectorComponent,
      {
        multiple: false,
        selected: [this.ta_revised.approver_id]
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.approverUpdateId = userId;
        this.inProgress = true;
        this.update( "", "approver");

      }
    );
  }

  onSelectAuthorizer() {
    this.utils.showComponentDialog(
      NetworkedEmployeesSelectorComponent,
      {
        multiple: false,
        selected: [this.ta_revised.authorizer_id]
        // selectedAccountId: this.app.account.id
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.authorizerUpdateId = userId;
        this.inProgress = true;
        this.update( "", "authorizer");
      }
    );
  }

  onSelectSupervisor() {
    this.utils.showComponentDialog(
      NetworkedEmployeesSelectorComponent,
      {
        multiple: false,
        selected: [this.ta_revised.supervisor_id]
        // selectedAccountId: this.app.account.id
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.supervisorUpdateId = userId;
        this.inProgress = true;
        this.update( "", "supervisor");
      }
    );
  }

  onApprove() {
    this.approverAcknowledged = true;
    this.inProgress = true;
    this.update("approver");
  }

  onAuthorize() {
    this.authorizerAcknowledged = true;
    this.inProgress = true;
    this.update("authorizer");
  }

  onSupervisorAcknowledge() {
    this.supervisorAcknowledged = true;
    this.inProgress = true;
    this.update("supervisor");
  }

}
