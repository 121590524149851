import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from '../app.service';
import { ApiService } from '../shared/api.service';
import { UtilsService } from '../shared/utils.service';
import { AuditItemDataSource } from './audit-item.data.source';
import { AuditItemModel } from './audit-item.model';
import { AuditItemsService } from './audit-items.service';

@Component({
  selector: 'app-audit-items',
  templateUrl: './audit-items.component.html',
  styleUrls: ['./audit-items.component.scss']
})
export class AuditItemsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() auditId: number;
  @Input() template = true;
  dataSource = new AuditItemDataSource(this.app, this.api);
  displayedColumns = ['name', 'section', 'status', 'note', 'buttons'];
  sections: string[];
  selectedSection: string;
  query: string;

  constructor(
    private auditItemService: AuditItemsService,
    private utils: UtilsService,
    private app: AppService,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.dataSource.template = this.template;
    this.dataSource.auditId = this.auditId;
    this.dataSource.getData(false);
    this.auditItemService
      .getSections()
      .subscribe((response) => (this.sections = response.data));
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;
      this.dataSource.getData(false);
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  filterSection() {
    this.dataSource.getDataForSection(false, this.selectedSection);
  }

  delete(auditItem: AuditItemModel) {
    this.utils.showModal(
      'Remove Question',
      `Are you sure you want to remove the "${auditItem.name}" question?`,
      () => {
        this.auditItemService
          .remove([auditItem.id])
          .subscribe(() => {
            this.utils.showToast(`The "${auditItem.name}" question was removed.`);
            this.dataSource.getData(false);
          });
      }
    );
  }
}
