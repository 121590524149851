<div mat-dialog-title>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5>PPE</h5>
      </div>
      <div class="col">
        <button mat-icon-button class="float-right" mat-dialog-close>
          <mat-icon color="secondary">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>


<p>{{ ppe.title }}</p>

<h6>Description</h6>
<p>{{ ppe.description }}</p>

<ng-container *ngIf="ppe.created_by_user">
  <h6>Created By</h6>
  <p>
    <a (click)="onUserPublicView(ppe.created_by_user.hash)"
      matTooltip="View User Public Profile">
      {{ ppe.created_by_user.contact_person }}
    </a>
  </p>
</ng-container>

<ng-container *ngIf="ppe.date_created">
  <h6>Created At</h6>
  <p>{{ (ppe.date_created * 1000) | timezoneConvertLong}}</p>
</ng-container>

<ng-container *ngIf="ppe.updated_by_user">
  <h6>Last Updated By</h6>
  <p>
    <a (click)="onUserPublicView(ppe.updated_by_user.hash)"
      matTooltip="View User Public Profile">
      {{ ppe.updated_by_user.contact_person }}
    </a>
  </p>
</ng-container>

<ng-container *ngIf="ppe.date_modified">
  <h6>Last Updated At</h6>
  <p>{{ (ppe.date_modified * 1000) | timezoneConvertLong}}</p>
</ng-container>

<ng-container *ngIf="ppe.web_icon">
  <h6>Web Icon</h6>
  <img matListAvatar class="rounded-0 ppe-icon mb-2" style="cursor: pointer;" (click)="onOpenUrl(ppe.web_icon)" [src]="ppe.web_icon" [alt]="ppe.title" />
</ng-container>

<ng-container *ngIf="ppe.mobile_icon">
  <h6>Mobile Icon</h6>
  <img matListAvatar class="rounded-0 ppe-icon mb-2" style="cursor: pointer;" (click)="onOpenUrl(ppe.mobile_icon)" [src]="ppe.mobile_icon" [alt]="ppe.title" />
</ng-container>
