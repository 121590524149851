<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/account/notification-roles">Notification Roles</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="primary" (click)="onEditNotificationRole()" appMarginRight id="add" matTooltip="Create a new notification role.">
          <mat-icon>add</mat-icon>
          New Notification Role
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="listDataSource.selectAllToggle()">
            <mat-icon *ngIf="listDataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!listDataSource.isAllSelected()">select_all</mat-icon>
            {{ listDataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
<!--          <button mat-menu-item (click)="onExportSafetyObservations('pdf')">-->
<!--            <mat-icon>cloud_download</mat-icon> Export {{ safetyObservationsService.dataSource.selection.hasValue() ? '(' + safetyObservationsService.dataSource.selection.selected.length + ') Selected' : 'All' }} to PDF-->
<!--          </button>-->
<!--          <button mat-menu-item (click)="onExportSafetyObservations('csv')">-->
<!--            <mat-icon>cloud_download</mat-icon> Export {{ safetyObservationsService.dataSource.selection.hasValue() ? '(' + safetyObservationsService.dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV-->
<!--          </button>-->
          <button mat-menu-item (click)="onDeleteSelectedNotificationRoles()" [disabled]="!listDataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect notification roles." style="min-width: 150px">
          <mat-icon>more_vert</mat-icon>
          Menu
        </button>
      </span>

<!--      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="chartsUtils.onOpenMasterCharts('Safety Observations')" matTooltip="Click this button to open the charts.">-->
<!--        <mat-icon>bar_chart</mat-icon> Charts-->
<!--      </button>-->

<!--      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="onFilter()" matTooltip="Click this button to display filter options.">-->
<!--        <mat-icon>filter_list</mat-icon> Filter-->
<!--      </button>-->

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (submit)="listDataSource.getData(true)">
        <input class="form-control mr-sm-2" type="search" [(ngModel)]="listDataSource.search" name="search" placeholder="Search" aria-label="Search" id="search-input" matTooltip="Search for notification roles." />
        <button mat-raised-button type="submit" color="secondary" class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0" id="search-button" matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="listDataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? listDataSource.selectAllToggle() : null"
            [checked]="listDataSource.selection.hasValue() && listDataSource.isAllSelected()"
            [indeterminate]="listDataSource.selection.hasValue() && !listDataSource.isAllSelected()"
            [matTooltip]="(listDataSource.isAllSelected() ? 'Deselect' : 'Select') + ' all notification roles.'">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? listDataSource.selection.toggle(row.id) : null"
            [checked]="listDataSource.selection.isSelected(row.id)"
            [matTooltip]="(listDataSource.selection.isSelected(row.id) ? 'Deselect' : 'Select') + ' this notification role.'">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by id.">
          ID #
        </th>
        <td mat-cell *matCellDef="let notification_role">
          {{ notification_role.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by name.">
          Name
        </th>
        <td mat-cell *matCellDef="let notification_role">
          {{ notification_role.name | titlecase  }}
        </td>
      </ng-container>

      <ng-container matColumnDef="system_notifications_count">
        <th mat-header-cell *matHeaderCellDef matTooltip="The count of enabled notifications.">
          Enabled Notifications
        </th>
        <td mat-cell *matCellDef="let notification_role">
          <span style="text-decoration: underline;" [matTooltip]="getNotificationRoleSystemNotificationsForTooltip(notification_role)">
            {{ notification_role.system_notifications_count  }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="sites_count">
        <th mat-header-cell *matHeaderCellDef matTooltip="The count of associated sites.">
          Sites
        </th>
        <td mat-cell *matCellDef="let notification_role">
          <span style="text-decoration: underline;" [matTooltip]="getNotificationRoleSitesForTooltip(notification_role)">
            {{ notification_role.sites_count  }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="users_count">
        <th mat-header-cell *matHeaderCellDef [matTooltip]="'The count of associated ' + utils.getLangTerm('employees.plural', 'Employees').toLowerCase() + '.'">
          {{ utils.getLangTerm('employees.plural', 'Employees') }}
        </th>
        <td mat-cell *matCellDef="let notification_role">
          <span style="text-decoration: underline;" [matTooltip]="getNotificationRoleUsersForTooltip(notification_role)">
            {{ notification_role.users_count  }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="is_active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by active status.">
          Is Active
        </th>
        <td mat-cell *matCellDef="let notification_role">
          {{ notification_role.is_active ? 'Yes' : 'No'  }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by creation data.">
          Created At
        </th>
        <td mat-cell *matCellDef="let notification_role">
          {{ notification_role.date_created ? ((notification_role.date_created * 1000) | timezoneConvertMedium) : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by modified date.">
          Updated At
        </th>
        <td mat-cell *matCellDef="let notification_role">
          {{ notification_role.date_modified ? ((notification_role.date_modified * 1000) | timezoneConvertMedium) : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let notification_role">
          <button type="button" mat-button (click)="onEditNotificationRole(notification_role.id)" matTooltip="Edit the notification role.">
            Edit
          </button>

          <button type="button" mat-button (click)="onDeleteNotificationRole(notification_role.id, $event)" matTooltip="Click this button to delete this notification role.">
            Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
