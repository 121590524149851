import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuditItemsEditComponent } from './audit-items-edit/audit-items-edit.component';
import { AuditItemsComponent } from './audit-items.component';
import { AuditItemsTemplateViewComponent } from './audit-items-template-view/audit-items-template-view.component';
import { AuditItemsTemplateComponent } from './audit-items-template/audit-items-template.component';
import { AuditItemsTemplateEditComponent } from './audit-items-template-edit/audit-items-template-edit.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: 'audit-items',
    canActivate: [
      AuthGuard
    ],
    children: [
      { path: '', component: AuditItemsComponent },
      { path: ':id/edit', component: AuditItemsEditComponent }
    ]
  },
  {
    path: 'audit-items-template',
    canActivate: [
      AuthGuard
    ],
    children: [
      { path: '', component: AuditItemsTemplateComponent },
      { path: ':id/edit', component: AuditItemsTemplateEditComponent },
      { path: ':id/view', component: AuditItemsTemplateViewComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuditItemsRoutingModule {}
