import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/utils.service';
import { AuditsModel } from '../audits.model';
import { AuditsService } from '../audits.service';
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import { NetworkedAccountsSelectorComponent } from 'src/app/shared/networked-accounts-selector/networked-accounts-selector.component';

@Component({
  selector: 'app-audits-edit',
  templateUrl: './audits-edit.component.html',
  styleUrls: ['./audits-edit.component.scss']
})
export class AuditsEditComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  audit = new AuditsModel();
  displayedColumns = ['name', 'status', 'media', 'buttons'];

  inProgress: boolean = false;
  faHardHat = faHardHat;

  constructor(
    public location: Location,
    private route: ActivatedRoute,
    public utils: UtilsService,
    private router: Router,
    private auditsService: AuditsService
  ) {}

  ngOnInit() {
    this.audit.id = Number(this.route.snapshot.params['id']);
    if (!isNaN(this.audit.id)) { this.find(this.audit.id); }
  }

  onSubmit() {

    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;

    this.audit.template = false;
    isNaN(this.audit.id) ? this.create() : this.update();
  }

  delete(audit: AuditsModel) {
    this.utils.showModal(
      'Remove Inspection/Audit',
      `Are you sure you want to remove the "${audit.name}" Inspection/Audit?`,
      () => {
        this.auditsService
          .remove([audit.id])
          .subscribe(() =>
            this.onSuccess(`The "${this.audit.name}" Inspection/Audit was removed.`)
          );
      }
    );
  }

  onSelectContractors() {
    this.utils.showComponentDialog(NetworkedAccountsSelectorComponent, {
      multiple: true,
      selected: this.audit.contractor_ids
    }, {}, (results: number[]) => {
      if (!results) {
        return;
      }

      this.audit.contractor_ids = results;
    })
  }

  private find(id: number) {
    this.auditsService
      .find(id)
      .subscribe(response => (this.audit = response.data));
  }

  private update() {
    this.audit.item_ids = this.audit.items.map(item => item.id);
    this.auditsService
      .put(this.audit)
      .subscribe(() => {
        this.onSuccess(`The "${this.audit.name}" Inspection/Audit was updated.`);
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      });
  }

  private create() {
    this.auditsService
      .post(this.audit)
      .subscribe(response => {
        this.onSuccess(`The "${response.data.name}" Inspection/Audit was created.`);
        this.inProgress = false;
        this.router.navigate(['/audits']);
      }, () => {
        this.inProgress = false;
      });
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
  }
}
