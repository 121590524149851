<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="javascript:void(0)">
    Inspection/Audit Templates
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <span class="mr-auto">
      <button mat-raised-button color="primary" (click)="add()" id="add"
              class="margin-right">
        <mat-icon>add</mat-icon>
        Add Inspection/Audit Template
      </button>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>
        <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>delete</mat-icon> Delete Selected
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Inspection/Audit Templates." style="min-width: 120px">
        <mat-icon>more_vert</mat-icon>
        List Menu
      </button>
    </span>

    <button type="button" mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="chartsUtils.onOpenMasterCharts('Inspections & Audits')" matTooltip="Click this button to open the charts.">
      <mat-icon>bar_chart</mat-icon> Charts
    </button>

    <form
      class="form-inline my-2 my-lg-0"
      style="min-width: 325px"
      (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2"
        type="search"
        [(ngModel)]="dataSource.search"
        name="search"
        placeholder="Search"
        aria-label="Search" />

      <button
        mat-raised-button
        class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
        color="secondary"
        id="searchButton"
        (click)="dataSource.getData(false)"
        type="button">
        <mat-icon>search</mat-icon>Search
      </button>
    </form>
  </div>

</nav>

<app-site-audits-charts-container [baseStorageKey]="'audits-inspections-templates'"></app-site-audits-charts-container>

<div class="mat-elevation-z8 scroll-box">
  <table
    mat-table
    [dataSource]="dataSource"
    style="width: 100%"
    matSort
    matSortActive="name"
    matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        #
      </th>
      <td mat-cell *matCellDef="let audit" class="no-padding">
        {{ audit.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Inspection/Audit Template
      </th>
      <td mat-cell *matCellDef="let audit" class="no-padding text-wrap">
        {{ audit.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="positive_comments">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Positive Comments
      </th>
      <td mat-cell *matCellDef="let audit" class="no-padding">
        {{ audit.positive_comments | ellipsis: [30] }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Date Created
      </th>
      <td mat-cell *matCellDef="let audit" class="no-padding">
        {{ (audit.date_created * 1000) | timezoneConvertMedium }} <br/>
        {{ (audit.date_created * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Date Created (UTC)
      </th>
      <td mat-cell *matCellDef="let audit" class="no-padding">
        {{ (audit.date_created * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let audit">
        <a type="button" mat-button routerLink="{{ audit.id }}/view/" matTooltip="View the Inspection/Audit template information." matTooltipPosition="above">
          View
        </a>

        <button type="button" mat-button (click)="edit(audit)" matTooltip="Edit the Inspection/Audit template information." matTooltipPosition="above">
          Edit
        </button>

        <button type="button" mat-button (click)="onRemove(audit)" matTooltip="Remove (Archive) the Inspection/Audit template." matTooltipPosition="above">
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>
</div>
