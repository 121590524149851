<div class="container-fluid">

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">Assigned Forms</span>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto"></span>

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (ngSubmit)="dataSource.getData(true)">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search"/>

        <button
          mat-raised-button
          color="secondary"
          type="submit"
          class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table class="w-100" mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
      <!-- From Account Column -->
      <ng-container matColumnDef="from_account">
        <th mat-header-cell *matHeaderCellDef>From Account</th>
        <td mat-cell *matCellDef="let form">{{ form.account.name }}</td>
      </ng-container>

      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let form">{{ form.title }}</td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let form">{{ form.description }}</td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="dynamic_form_status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let form">
          <span [ngClass]="getFormSubmissionStatusColors(form)">
            {{ form.pivot?.dynamic_form_status || '' }}
          </span>
        </td>
      </ng-container>

      <!-- Assigned At Column -->
      <ng-container matColumnDef="assigned_on">
        <th mat-header-cell *matHeaderCellDef>Assigned At</th>
        <td mat-cell *matCellDef="let form">
          <span *ngIf="form.pivot?.assigned_on">
            {{ ((form.pivot?.assigned_on * 1000) | timezoneConvertUTCLong) || '' }}
          </span>
        </td>
      </ng-container>

      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef id="th-actions">Actions</th>
        <td mat-cell *matCellDef="let form">
          <button type="button" mat-button *ngIf="['Assigned', 'In Progress'].indexOf(form.pivot.dynamic_form_status) == -1 && form.account.id == app.account.id; else noActionsAvailable" (click)="onViewUserSubmittedAnswers(form.id, form.pivot.user_id)" matTooltip="Open the form to see this user's submitted answers.">
            View Submitted Answers
          </button>
          <ng-template #noActionsAvailable>
            <b><i matTooltip="Submitted answers cannot be viewed from this account. Switch to the relevant account to view the submitted answers.">NA</i></b>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>

</div>
