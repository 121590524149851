import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { MessageEditComponent } from './message-edit/message-edit.component';
import { MessageViewComponent } from './message-view/message-view.component';
import { MessagesComponent } from './messages.component';

const routes: Routes = [
  {
    path: 'messages',
    component: MessagesComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'messages/:id',
    canActivate: [
      AuthGuard
    ],
    children: [
      {
        pathMatch: 'full', 
        path: '', 
        redirectTo: 'view'
      },
      {
        path: 'view',
        component: MessageViewComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MessagesRoutingModule { }
