import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { NotificationRole } from '../../../interfaces/notification-role.interface';
import { SystemNotification } from '../../../interfaces/system-notification.interface';

@Component({
  selector: 'app-notification-roles-edit',
  templateUrl: './notification-roles-edit.component.html',
  styleUrls: ['./notification-roles-edit.component.scss']
})
export class NotificationRolesEditComponent implements OnInit {

  // Get the notification role id.
  @Input('notification_role_id') notification_role_id: number;

  // Define the notification role id changed event emitter.
  @Output('notification_role_id_changed') notification_role_id_changed: EventEmitter<number> = new EventEmitter<number>();

  // Define the "close dialog" event emitter.
  @Output('close_dialog') close_dialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Used to output an event emitter for the parent component.
  @Output('scroll_to_top') scroll_to_top: EventEmitter<void> = new EventEmitter<void>();

  // The notification role interface.
  notification_role: NotificationRole = {
    id: 0,
    name: '',
    is_active: false,
    system_notifications: [],
    sites_count: 0
  } as NotificationRole;

  // All system notifications.
  system_notifications: SystemNotification[] = [];

  // Group system notifications.
  grouped_system_notifications: { [key: string]: {
    system_notifications: SystemNotification[],
    has_site_related_notifications: boolean
  } } = {};

  constructor(
    public utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit(): void {
    // Get all system notifications.
    this.getSystemNotifications().then((): void => {
      // Get the notification role by id.
      if ( this.notification_role_id ) {
        this.getNotificationRoleById(this.notification_role_id);
      } else {
        // Group the system notifications by feature.
        this.groupSystemNotificationsByFeature();
      }
    });
  }

  /**
   * Retrieves notification role by ID.
   *
   * @param {number} notification_role_id - The ID of the notification role to retrieve.
   * @returns {undefined}
   */
  getNotificationRoleById(notification_role_id: number): void {
    // Make an API request to get the notification role if it exists.
    this.api.makeRequest('get', `v2/account/notification-roles/${notification_role_id}`)
      .then((response: any): void => {
        // Apply the notification role from the API.
        this.notification_role = response;
        // Map the system notifications.
        this.mapSystemNotifications();
      })
      .catch((errorResponse: any): void => {
        // Handle API error responses.
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  /**
   * Maps the system notifications by marking them as checked if they exist in the notification role's system notifications.
   * This method updates the 'checked' property of each system notification in the 'system_notifications' array.
   *
   * @returns {void} - This method does not return anything.
   */
  mapSystemNotifications(): void {
    this.system_notifications = this.system_notifications.map((system_notification: SystemNotification): SystemNotification => {
      system_notification.checked = this.notification_role.system_notifications.some((role_system_notification: SystemNotification): boolean => {
        return role_system_notification.id == system_notification.id;
      });
      return system_notification;
    });
    // Group the system notifications by feature.
    this.groupSystemNotificationsByFeature();
  }

  /**
   * Retrieves system notifications from the API.
   *
   * @returns {Promise<any>} - A promise that resolves with the system notifications data.
   */
  async getSystemNotifications(): Promise<any> {
    // Make an API request to get all system notifications.
    return this.api.makeRequest('get', `v2/system-notifications`)
      .then((response: any): void => {
        // Apply the system notifications from the API.
        this.system_notifications = response.map((notification: SystemNotification) => {
          return notification as SystemNotification;
        });
      })
      .catch((errorResponse: any): void => {
        // Handle API error responses.
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  /**
   * Groups the system notifications by feature.
   *
   * @returns {void}
   */
  groupSystemNotificationsByFeature(): void {
    // Transform the system notifications into grouped system notification.
    this.grouped_system_notifications = this.system_notifications.reduce((grouped, notification: SystemNotification) => {
      // Get the feature.
      const feature: string = notification.feature;
      // Check if the grouping exists.
      if ( !grouped[feature] ) {
        grouped[feature] = {
          system_notifications: [],
          has_site_related_notifications: false
        };
      }
      // Push the notification into the group.
      grouped[feature].system_notifications.push(notification);

      // Check if any system notifications are site related.
      if ( !grouped[feature].has_site_related_notifications && notification.is_site_related ) {
        grouped[feature].has_site_related_notifications = true;
      }

      // Return the grouped notifications.
      return grouped;
    }, {});
  }

  /**
   * Validate the form input and save the data.
   * @param form The NgForm object that is used for validation.
   * @param autoCloseDialog An optional param to indicate if the dialog should be closed or not.
   * @returns Nothing.
   */
  onSubmit(form: NgForm, autoCloseDialog?: boolean): void {
    // Check if the submitted form is valid or not.
    if ( !form.valid ) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    // Fetch and prepare selected system notifications. Only get system notifications that the client checked.
    this.notification_role.system_notifications = Object.values(this.grouped_system_notifications)
      .reduce((acc: SystemNotification[], group) => {
        const selectedNotifications: SystemNotification[] = group.system_notifications.filter((system_notification: SystemNotification): boolean => {
          // Check if any sites were selected and filter the system notifications for it.
          if ( this.notification_role.sites_count > 0 ) {
            return system_notification.is_site_related && system_notification.checked;
          }
          return system_notification.checked;
        });
        return acc.concat(selectedNotifications);
      }, []);

    // Store the request Promise in this.
    let request: Promise<any>;
    let hasErrors: boolean = false;

    if ( this.notification_role.id ) {
      // Make an update request if the record id is present.
      request = this.api.makeRequest('put', `v2/account/notification-roles/${this.notification_role.id}`, this.notification_role)
      .then((response: NotificationRole): void => {
        this.notification_role = response;
        this.utils.showToast(`The notification role was updated.`);
      })
      .catch((errorResponse: any) => {
        hasErrors = true;
        this.utils.handleAPIErrors(errorResponse);
      });
    } else {
      // Make a "create" request when the record id is not present.
      request = this.api.makeRequest('post', `v2/account/notification-roles`, this.notification_role)
      .then((response: NotificationRole): void => {
        this.notification_role = response;
        // Emit the notification role id.
        this.notification_role_id_changed.emit(this.notification_role.id);
        // Show a success message.
        this.utils.showToast(`The notification role was created.`);
      })
      .catch((errorResponse: any): void => {
        hasErrors = true;
        this.utils.handleAPIErrors(errorResponse);
      });
    }

    // Check if the dialog should close. It should only close if there are no API errors.
    request.finally((): void => {
      // Terminate early if there are any errors.
      if ( hasErrors ) {
        return;
      }
      // Check if the dialog should be closed.
      if ( autoCloseDialog ) {
        // Handle close dialog event emitter.
        this.close_dialog.emit(true);
      } else {
        // Remap the system notifications.
        this.mapSystemNotifications();
        // Emit the scroll to top event emitter.
        this.scroll_to_top.emit();
      }
    });
  }

}
