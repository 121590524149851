import { Auditable } from "../shared/auditable.model";

export class CourseModuleModel extends Auditable {
  id = 0;
  name = '';
  description = '';

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.name = '';
    this.description = '';
  }
}
