<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button mat-raised-button routerLink="/sites" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
  </button>

  <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
  </button>

  <button mat-raised-button [routerLink]="utils.prepareSiteRelatedLink(site_id, child_id ? parent_id : null, path + '/task-analyses-revised')" class="margin-right my-2">
    <mat-icon>arrow_back</mat-icon> TA/JSA/SWMS
  </button>
</nav>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class="mr-auto">

    <button *ngIf="urlPath == 'view' && !ta_revised.contractor" mat-raised-button [routerLink]="utils.prepareSiteRelatedLink(site_id, child_id ? parent_id : null, path + '/task-analyses-revised/' + ta_revised.id + '/edit')"  class="margin-right my-2">
      <mat-icon>edit</mat-icon> Edit
    </button>
    <button *ngIf="urlPath == 'edit'" mat-raised-button [routerLink]="utils.prepareSiteRelatedLink(site_id, child_id ? parent_id : null, path + '/task-analyses-revised/' + ta_revised.id + '/view')"  class="margin-right my-2">
      <mat-icon>visibility</mat-icon> View
    </button>

    <button *ngIf="!ta_revised.contractor" mat-raised-button (click)="onRemove()"  class="margin-right my-2" color="warn">
      <mat-icon>delete</mat-icon> Delete
    </button>

    <button mat-raised-button (click)="onExportSelected('pdf')" color="secondary" class="margin-right my-2">
      <mat-icon>cloud_download</mat-icon> Export PDF
    </button>

    <button mat-raised-button (click)="onExportSelected('csv')" color="secondary" class="margin-right my-2">
      <mat-icon>cloud_download</mat-icon> Export CSV
    </button>
  </span>
</nav>

<mat-tab-group *ngIf="urlPath == 'edit'" class="bg-white">
  <mat-tab label="Overview">
    <app-sites-task-analyses-revised-edit [taRevisedId]="ta_revised.id" [site_id]="site_id"></app-sites-task-analyses-revised-edit>
  </mat-tab>
  <mat-tab *ngIf="ta_revised.id" label="Workflow">
    <app-sites-task-analyses-revised-workflows [taRevisedId]="ta_revised.id" [site_id]="site_id" [taRevisedPath]="urlPath"></app-sites-task-analyses-revised-workflows>
  </mat-tab>
</mat-tab-group>

<app-sites-task-analyses-revised-view *ngIf="urlPath == 'view'" [taRevisedId]="ta_revised.id" [site_id]="site_id"></app-sites-task-analyses-revised-view>


