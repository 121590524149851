<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/admin/system-notifications">System Notifications</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto"></span>

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (submit)="listDataSource.getData(true)">
        <input class="form-control mr-sm-2" type="search" [(ngModel)]="listDataSource.search" name="search" placeholder="Search" aria-label="Search" id="search-input" matTooltip="Search for system notifications." />
        <button mat-raised-button color="secondary" class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0" (click)="listDataSource.getData(true)" id="search-button" matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="listDataSource" matSort matSortActive="identifier" matSortDirection="desc" matSortDisableClear>
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by id.">
          ID #
        </th>
        <td mat-cell *matCellDef="let notification_role">
          {{ notification_role.id }}
        </td>
      </ng-container>

      <!-- Internal Identifier Column -->
      <ng-container matColumnDef="identifier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by identifier.">
          Identifier
        </th>
        <td mat-cell *matCellDef="let system_notification">
          {{ system_notification.identifier  }}
        </td>
      </ng-container>

      <!-- Label Column -->
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by label.">
          Label
        </th>
        <td mat-cell *matCellDef="let system_notification">
          <span [matTooltip]="system_notification.description">
            {{ system_notification.label  }}
          </span>
        </td>
      </ng-container>

      <!-- Feature Column -->
      <ng-container matColumnDef="feature">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by feature.">
          Feature
        </th>
        <td mat-cell *matCellDef="let system_notification">
          {{ system_notification.feature  }}
        </td>
      </ng-container>

      <!-- Site Related Column -->
      <ng-container matColumnDef="is_site_related">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by site related.">
          {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Related
        </th>
        <td mat-cell *matCellDef="let system_notification">
          {{ system_notification.is_site_related ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <!-- Date Created Column -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by creation data.">
          Created At
        </th>
        <td mat-cell *matCellDef="let system_notification">
          {{ system_notification.date_created ? ((system_notification.date_created * 1000) | timezoneConvertMedium) : '' }}
        </td>
      </ng-container>

      <!-- Date Modified Column -->
      <ng-container matColumnDef="date_modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by modified date.">
          Updated At
        </th>
        <td mat-cell *matCellDef="let system_notification">
          {{ system_notification.date_modified ? ((system_notification.date_modified * 1000) | timezoneConvertMedium) : '' }}
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let system_notification">
          <button type="button" mat-button (click)="onEditSystemNotification(system_notification.id)" matTooltip="Edit the system notification.">
            Edit
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>
  </div>
</div>
