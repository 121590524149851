import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UtilsService } from '../utils.service';
import { DigitalSignatureMeta } from './digital-signature-meta';
import { AppService } from '../../app.service';
import { NetworkedUsersSelectorComponent } from '../networked-users-selector/networked-users-selector.component';
import { UserModel } from '../../models/user.model';

@Component({
  selector: 'app-digital-signature',
  templateUrl: './digital-signature.component.html',
  styleUrls: ['./digital-signature.component.scss']
})
export class DigitalSignatureComponent implements OnInit, AfterViewInit {

  // A reference to the canvas in HTML.
  @ViewChild('signatureCanvasRef', { static: true }) signatureCanvasRef!: ElementRef<HTMLCanvasElement>;

  // The canvas rendering context.
  private context!: CanvasRenderingContext2D;

  // Used to check if the user is drawing or not.
  private isDrawing: boolean = false;

  // Used to check if the user drew something or not.
  hasDrawing: boolean = false;

  // Used to check if the user used the drawing.
  usedDrawing: boolean = false;

  // Used to store the last know x and y positions.
  private lastX: number;
  private lastY: number;

  // Used to emit the signature file to the parent component.
  @Output('meta_output') meta_output: EventEmitter<DigitalSignatureMeta> = new EventEmitter<DigitalSignatureMeta>();

  // Used to emit the signature file to the parent component.
  @Output('signature_component_instance') signature_component_instance: EventEmitter<DigitalSignatureComponent> = new EventEmitter<DigitalSignatureComponent>();

  // The meta information for this component.
  @Input('meta') meta: DigitalSignatureMeta;

  // This is used to display the title above the signature box.
  @Input('title') title: string;

  // This is used to display the subtitle above the signature box.
  @Input('subtitle') subtitle: string;

  // Allows the user to change the signer.
  @Input('allow_change_signer') allow_change_signer: boolean;

  constructor(
    private utils: UtilsService,
    private app: AppService
  ) { }

  ngOnInit(): void {
    // Check if the title is set and if not, make it "Signature" by default.
    this.title = this.title || 'Signature';
    // Check if the subtitle is set and if not, make it "Draw your signature below." by default.
    this.subtitle = this.subtitle || 'Draw your signature below.';
    // Apply meta defaults to the authenticated user.
    if ( typeof this.meta == 'undefined' ) {
      this.meta = {
        user_id: this.app.user.id,
        user_name: this.app.user.contact_person
      } as DigitalSignatureMeta;
    } else {
      // Default to the authenticated user if none is provided.
      this.meta.user_id = this.meta.user_id ? this.meta.user_id : this.app.user.id;
      this.meta.user_name = this.meta.user_name ? this.meta.user_name : this.app.user.contact_person;
    }
    // Emit a reference to this component.
    this.signature_component_instance.emit(this);
  }

  ngAfterViewInit(): void {
    // Get the native canvas element.
    const canvas: HTMLCanvasElement = this.signatureCanvasRef.nativeElement;
    // Set the canvas context for drawing.
    this.context = canvas.getContext('2d')!;
    // Set the canvas background to white.
    this.setCanvasBackground();
  }

  /**
   * Sets the background color of the canvas.
   * This method should only be called internally.
   * @private
   * @returns {void}
   */
  private setCanvasBackground(): void {
    // Get the native canvas element.
    const canvas: HTMLCanvasElement = this.signatureCanvasRef.nativeElement;
    // Set the fill style color to white.
    this.context.fillStyle = '#ffffff';
    // Fill the canvas with the white background.
    this.context.fillRect(0, 0, canvas.width, canvas.height);
  }

  /**
   * Starts the drawing process.
   *
   * @param {MouseEvent | TouchEvent} event - The event triggered when starting the drawing process.
   *
   * @return {void}
   */
  startDrawing(event: MouseEvent | TouchEvent): void {
    // Set the drawing and has drawn states to true.
    this.isDrawing = true;
    this.hasDrawing = true;
    // Get the last known x and y positions.
    const { x, y } = this.getCanvasCoordinates(event);
    // Store the last known x and y positions.
    this.lastX = x;
    this.lastY = y;
  }

  /**
   * Draws lines on a canvas based on the user input event.
   *
   * @param {MouseEvent | TouchEvent} event - The user input event that triggers the drawing.
   *
   * @returns {void}
   */
  draw(event: MouseEvent | TouchEvent): void {
    // Check if drawing was initiated or not. If the user used the drawing, no further modifications will be allowed.
    if ( !this.isDrawing || this.usedDrawing ) {
      return;
    }
    // Prevent default browser behaviours e.g. scrolling while drawing.
    event.preventDefault();

    // Get the set of x and y positions.
    const { x, y } = this.getCanvasCoordinates(event);

    // Set the stroke width, cap and style.
    this.context.lineWidth = 2;
    this.context.lineCap = 'round';
    this.context.strokeStyle = '#000000';

    // Use quadratic curve for smoother drawing.
    this.context.beginPath();
    this.context.moveTo(this.lastX, this.lastY);
    this.context.quadraticCurveTo(this.lastX, this.lastY, x, y);
    this.context.stroke();

    // Set the last known x and y positions.
    this.lastX = x;
    this.lastY = y;
  }

  /**
   * Returns the canvas coordinates for a given event.
   * @param {MouseEvent | TouchEvent} event - The event for which to retrieve the canvas coordinates.
   * @private
   * @returns {Object} - An object containing the x and y coordinates of the event relative to the canvas.
   */
  private getCanvasCoordinates(event: MouseEvent | TouchEvent): { x: number, y: number } {
    // Get the native canvas element.
    const canvas: HTMLCanvasElement = this.signatureCanvasRef.nativeElement;
    // Get the bounding box from the canvas.
    const rect: DOMRect | ClientRect = canvas.getBoundingClientRect();
    // Define the x and y positions.
    let x: number, y: number;
    // Calculate the x and y positions.
    if ( event instanceof MouseEvent ) {
      x = event.clientX - rect.left;
      y = event.clientY - rect.top;
    } else {
      x = event.touches[0].clientX - rect.left;
      y = event.touches[0].clientY - rect.top;
    }
    // Respond with the x and y positions.
    return { x, y };
  }

  /**
   * Stops the drawing process.
   *
   * @returns {void} - Does not return a value.
   */
  stopDrawing(): void {
    // Set the drawing state to false.
    this.isDrawing = false;
    // Start a new path for drawing to prevent connected lines.
    this.context.beginPath();
  }

  /**
   * Clears the signature on the canvas.
   *
   * @return {void}
   */
  clearSignature(): void {
    // Get the native canvas element.
    const canvas: HTMLCanvasElement = this.signatureCanvasRef.nativeElement;
    // Clear the entire canvas.
    this.context.clearRect(0, 0, canvas.width, canvas.height);
    // Set the has drawing state to false.
    this.hasDrawing = false;
  }

  /**
   * Saves the signature as a PNG file.
   *
   * @return {void} This method does not return anything.
   */
  saveSignature(): void {
    // Check with the user if they want to use the signature they have drawn.
    this.utils.showModal('Use Signature', 'Are you sure you want to use this signature? It cannot be changed after you used it.', (): void => {
      // Get the native canvas element.
      const canvas: HTMLCanvasElement = this.signatureCanvasRef.nativeElement;
      // Store the base64 image data.
      this.meta.base64_image_data =  canvas.toDataURL();
      // Emit the base64 encoded data.
      this.meta_output.emit(this.meta);
      // Mark the signature as used.
      this.usedDrawing = true;
    });
  }

  /**
   * Resets the signature pad by clearing the signature and resetting the used drawing value.
   *
   * @returns {void}
   */
  resetSignaturePad(): void {
    // Clear the signature pad.
    this.clearSignature();
    // Reset the used drawing value.
    this.usedDrawing = false;
  }

  /**
   * Method to handle the onChange event of the signer in the component.
   * This method opens a component dialog to select a new signer.
   *
   * @returns {void}
   */
  onChangeSigner(): void {
    // Open the networked users' selector.
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
        selected: [this.meta.user_id],
        multiple: false,
        selectedAccountId: this.app.user.account_id,
        return_objects: true
      }, {
        width: '1024px'
      },
      (user: UserModel): void => {
        if ( !user ) { return; }
        // Store the user id and name.
        this.meta.user_id = user.id;
        this.meta.user_name = user.contact_person;
      })
  }
}
