<div mat-dialog-title class="ph-3" *ngIf="dialogRef">
  <h5>
    Safety Plan
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
  <p><small>{{ sssp.site.location }}</small></p>
</div>

<div mat-dialog-content class="ph-3" *ngIf="dialogRef else tabs">
  <ng-container *ngIf="!sssp.id else tabs"></ng-container>
</div>

<ng-template #tabs>
  <mat-tab-group (selectedTabChange)="onTabChanged($event)">
    <mat-tab label="Loading" *ngIf="!sssp.id">
      <div class="p-3">
        <p>Please wait while we load your Safety Plan.</p>
      </div>
    </mat-tab>

    <mat-tab label="Safety Plan Settings" *ngIf="sssp.id && !sssp.contractor_id">
      <app-sssp-config *ngIf="sssp.id" [sssp_hash]="sssp.sssp_hash"></app-sssp-config>
    </mat-tab>

    <mat-tab label="Safety Plan Docs" *ngIf="sssp.id">
      <app-sssp-docs *ngIf="sssp.id" [sssp]="sssp" [sssp_hash]="sssp.sssp_hash"></app-sssp-docs>
    </mat-tab>

    <mat-tab label="Safety Plan Snapshots" *ngIf="sssp.id && !sssp.contractor_id">
      <app-sssp-snapshots #ssspSnapshotsComponent *ngIf="sssp.id" [sssp_hash]="sssp.sssp_hash"></app-sssp-snapshots>
    </mat-tab>
  </mat-tab-group>
</ng-template>

