import {UserModel} from "../models/user.model";
import {FolderModel} from "./folder.model";

export class FolderLinkModel {
  id = 0;

  url = '';
  description = '';

  folder_id: number = 0;
  folder: FolderModel;

  date_created: number;
  date_modified: number;

  /**
   * Who created this Training/Competency Document Link?
   */
  created_by_user: UserModel;

  /**
   * Who updated this Training/Competency Document Link?
   */
  updated_by_user: UserModel;
}
