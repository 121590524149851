import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import { AuditsModel } from 'src/app/audits/audits.model';
import { AuditsService } from 'src/app/audits/audits.service';
import { NetworkedAccountsSelectorComponent } from 'src/app/shared/networked-accounts-selector/networked-accounts-selector.component';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-sites-audits-edit',
  templateUrl: './sites-audits-edit.component.html',
  styleUrls: ['./sites-audits-edit.component.scss']
})
export class SitesAuditsEditComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  audit = new AuditsModel();
  displayedColumns = ['name', 'status', 'media', 'buttons'];

  // viewRoute: string;

  inProgress: boolean = false;
  faHardHat = faHardHat;

  parent_id: number;
  child_id: number;
  site_id: number;

  constructor(
    public location: Location,
    private route: ActivatedRoute,
    public utils: UtilsService,
    private router: Router,
    private auditsService: AuditsService
  ) { }

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      this.audit.id = Number(this.route.snapshot.params['audit_id']);
      if ( this.audit.id ) {
        this.find(this.audit.id);
      }
    }
  }

  onSubmit() {

    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;

    this.audit.template = false;
    isNaN(this.audit.id) ? this.create() : this.update();
  }

  delete(audit: AuditsModel) {
    this.utils.showModal(
      'Remove Inspection/Audit',
      `Are you sure you want to remove the "${audit.name}" Inspection/Audit?`,
      () => {
        this.auditsService
          .remove([audit.id])
          .subscribe(() =>
            this.onSuccess(`The "${this.audit.name}" Inspection/Audit was removed.`)
          );
      }
    );
  }

  onSelectContractors() {
    this.utils.showComponentDialog(NetworkedAccountsSelectorComponent, {
      multiple: true,
      selected: this.audit.contractor_ids
    }, {}, (results: number[]) => {
      if (!results) {
        return;
      }

      this.audit.contractor_ids = results;
    })
  }

  private find(id: number) {
    this.auditsService
      .find(id)
      .subscribe(response => (this.audit = response.data));
  }

  private update() {
    this.audit.item_ids = this.audit.items.map(item => item.id);
    this.auditsService
      .put(this.audit)
      .subscribe(() => {
        this.onSuccess(`The "${this.audit.name}" Inspection/Audit was updated.`);
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      });
  }

  private create() {
    this.auditsService
      .post(this.audit)
      .subscribe(response => {
        this.onSuccess(`The "${response.data.name}" Inspection/Audit was created.`);
        this.inProgress = false;
        this.router.navigate(['/audits']);
      }, () => {
        this.inProgress = false;
      });
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
  }

}
