import { Auditable } from "../shared/auditable.model";

export class CourseModuleSectionModel extends Auditable {
  id = 0;
  name = '';
  content = '';
  status = 'Published';

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.name = '';
    this.content = '';
    this.status = 'Published';
  }
}
