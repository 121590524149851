import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { SsspTabsDialogComponent } from 'src/app/sssp/sssp-tabs/sssp-tabs-dialog.component';
import { AppService } from '../../app.service';
import { ContractorModel } from '../../models/contractor.model';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { CustomDataSource } from '../../utils/custom-data-source';

@Component({
  selector: 'app-contractors-sites',
  templateUrl: './contractors-sites.component.html',
  styleUrls: ['./contractors-sites.component.scss']
})
export class ContractorsSitesComponent implements OnInit, AfterViewInit {
  contractor: ContractorModel = new ContractorModel();

  // the site id must be present
  // @Input() contractor_id: number;
  // @Input() selected_sites: number[];

  // an output event emitter to send the selected contractors back
  // @Output('selected_sites_evt') selected_sites_evt = new EventEmitter<any>();

  // columns to show and the data source
  displayedColumns: string[] = ['select', 'name', 'location', 'actions'];
  dataSource = new ContractorsSitesDataSource(this.app, this.oldApi);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    // public service: ContractorsService,
    public app: AppService,
    private oldApi: ApiService,
    public utils: UtilsService,
    public route: ActivatedRoute,
    public router: Router,
    private api: ApiRequestService
  ) {}

  ngOnInit() {
    // get the contractor account id
    this.contractor.id = Number(
      this.route.parent.snapshot.params['contractor_id']
    );

    if (!this.contractor.id) {
      this.router.navigate(['contractors']);
      return;
    }

    // If the user is not allowed to manage sites, redirect to docs
    if ( !this.app.userCan('manage_sites') ) {
      this.utils.refreshPage(['contractors', this.contractor.id, 'docs']);
      return;
    }

    // load a multi select data source object
    this.dataSource = new ContractorsSitesDataSource(this.app, this.oldApi, true, {
      contractor_id: this.contractor.id
    });

    // get the data only when the site id is present
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * open a dialog allowing the user to select contractors to be linked to sites.
   */
  onLinkSites() {
    // show the site selector
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        multiple: true,
        selected: this.dataSource.selected_sites
      },
      {},
      (results) => {
        if (typeof results !== 'undefined') {
          // store the newly selected sites.
          this.dataSource.selected_sites = results;

          // todo create contractor site link endpoint
          this.oldApi.laravelApiRequest(
            'put',
            'contractors/' + this.contractor.id,
            {
              link_to_all_sites: this.contractor.link_to_all_sites,
              site_ids: results
            },
            {},
            (response) => {
              this.oldApi.utils.showToast(
                'The selected ' + this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() + ' was linked to the ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + '.'
              );

              // reload the data and reset the offset
              this.dataSource.getData(true);
            }
          );
        }
      }
    );
  }

  /**
   * send a request to link all contractors
   */
  onLinkAllSites() {
    this.oldApi.laravelApiRequest(
      'put',
      'contractors/' + this.contractor.id,
      {
        link_to_all_sites: true
      },
      {},
      (response) => {
        this.oldApi.utils.showToast('All ' + this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() + ' were linked to the ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + '.');

        // reload the data and reset the offset
        this.dataSource.getData(true);
      }
    );
  }

  /**
   * send a request to unlink all contractors
   */
  onUnlinkAllSites() {
    this.utils.showModal(
      'Unlink All ' + this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites'),
      'Are you sure you want to unlink all ' + this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() + '?',
      () => {
        this.oldApi.laravelApiRequest(
          'delete',
          'contractors/' + this.contractor.id + '/unlink-sites/all',
          {},
          {},
          (response) => {
            this.utils.showToast('The ' + this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() + ' were Removed');

            this.dataSource.getData(true);
          },
          (error) => {
            this.utils.showModal(
              'Error',
              'The ' + this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() + ' were not Removed: ' + error.message
            );
          }
        );
      }
    );
  }

  /**
   *
   *
   * unlink a single contractor
   * @param site_id
   */
  onRemove(site_id: number) {
    this.utils.showModal(
      'Unlink ' + this.utils.getLangTerm('contractors.singular', 'Contractor'),
      'Are you sure you want to unlink this ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + '?',
      () => {
        this.oldApi.laravelApiRequest(
          'delete',
          'contractors/' + this.contractor.id + '/unlink-sites/' + site_id,
          {},
          {},
          (response) => {
            this.utils.showToast('The ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + ' was removed');

            this.dataSource.getData(true);
          },
          (error) => {
            this.utils.showModal(
              'Error',
              'The ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + ' was not Removed: ' + error.message
            );
          }
        );
      }
    );
  }

  /**
   * unlink selected contractors from the specified site
   */
  onDeleteSelected() {
    this.utils.showModal(
      'Unlink Selected ' + this.utils.getLangTerm('contractors.plural', 'Contractors'),
      'Are you sure you want to unlink the selected ' + this.utils.getLangTerm('contractors.plural', 'Contractors') + '?',
      () => {
        this.oldApi.laravelApiRequest(
          'delete',
          'contractors/' + this.contractor.id + '/unlink-sites/' + this.dataSource.selection.selected.join(','),
          {},
          {},
          (response) => {
            this.utils.showToast('The ' + this.utils.getLangTerm('contractors.plural', 'Contractors') + ' were removed');

            this.dataSource.selection.clear(); // clear the selection

            this.dataSource.getData(true);
          },
          (error) => {
            this.utils.showModal(
              'Error',
              'The ' + this.utils.getLangTerm('contractors.plural', 'Contractors') + ' were not removed: ' + error.message
            );
          }
        );
      }
    );
  }

  onCreateSSSP(site_id: number) {
    // Make a post request to create a new Safety Plan.
    // Reload the list to show the latest changes.
    this.api.makeRequest('post', 'v1/sssp', {
      site_id: site_id,
      contractor_id: this.contractor.id
    })
    .then((response: any) => {
      // This means the request was successful.
      // Open it in a dialog.
      this.onManageSSSP(response.sssp_hash);
      // Reload the list in the background.
      this.dataSource.getData();
    })
    .catch((error) => {
      this.utils.showToast('The Safety Plan was created successfully.');
    });
  }

  onManageSSSP(sssp_hash: string) {
    // Open the Safety Plan manager via a dialog window.
    this.utils.showComponentDialog(SsspTabsDialogComponent, {
      sssp_hash: sssp_hash
    });
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class ContractorsSitesDataSource extends CustomDataSource {
  // record sorting and direction
  sort_by = 'name';
  sort_order = 'asc';

  selected_sites: number[] = [];

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      'contractors/' + this['contractor_id'] + '/linked-sites',
      resetOffset,
      (response) => {
        this.selected_sites = response.meta.selected_sites;
      }
    );
  }
}
