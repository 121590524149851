<div mat-dialog-title>
  <h5 class="card-title">
    Hazardous Substance Inventory
  </h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
    <mat-form-field appMarginBottom>
      <input
        matInput
        placeholder="Quantity ({{ hazardous_substance.hazardous_substance_unit_type || 'Unit Not Set' }})"
        [(ngModel)]="hazardous_substance_link.quantity"
        name="quantity"
        type="number"
        #quantity />
      <mat-hint>
        Please enter the quantity that is currently held onsite. Long decimal values are rounded e.g. "5.6".
      </mat-hint>
    </mat-form-field>

    <mat-form-field appMarginBottom>
      <input
        matInput
        placeholder="Max Quantity Allowed ({{ hazardous_substance.hazardous_substance_unit_type || 'Unit Not Set' }})"
        [(ngModel)]="hazardous_substance_link.max_quantity_allowed"
        name="max_quantity_allowed"
        type="number"
        #max_quantity_allowed />
      <mat-hint>
        Please enter the max quantity that may be held onsite. Long decimal values are rounded e.g. "7.5".
      </mat-hint>
    </mat-form-field>

    <mat-form-field appMarginBottom>
      <input
        matInput
        placeholder="Location of Safety Data Sheet (SDS)"
        [(ngModel)]="hazardous_substance_link.sds_location"
        name="sds_location"
        type="text"
        #sds_location />
      <mat-hint>
        Please enter the location of the hazardous substance Safety Data Sheet (SDS).
      </mat-hint>
    </mat-form-field>

    <mat-form-field appMarginBottom>
      <input
        matInput
        placeholder="Location of Hazardous Substance"
        [(ngModel)]="hazardous_substance_link.location"
        name="location"
        type="text"
        #location />
      <mat-hint>
        Please enter the location on site where the Hazardous Substance is located or stored.
      </mat-hint>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    (click)="onSubmit(f)"
    mat-raised-button
    style="max-width: 100px;"
    [disabled]="!f.valid || inProgress"
    color="primary"
    class="save-button"
    appMarginRight >
    <mat-icon>save</mat-icon>
    Update
  </button>
</mat-dialog-actions>
