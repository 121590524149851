import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HazardModel } from 'src/app/models/hazard.model';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { UtilsService } from 'src/app/shared/utils.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NoauthService } from 'src/app/shared/noauth.service';
import { RISK_LEVEL_OF_CONTROLS } from 'src/app/shared/risk-level-of-controls';

@Component({
  selector: 'app-hazards-verify',
  templateUrl: './hazards-verify.component.html',
  styleUrls: ['./hazards-verify.component.scss']
})
export class HazardsVerifyComponent implements OnInit {
  hazard: HazardModel = new HazardModel();
  loc_list = RISK_LEVEL_OF_CONTROLS;

  constructor(
    public utils: UtilsService,
    private route: ActivatedRoute,
    private noauthService: NoauthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.find();
  }

  find() {
    const hash = this.route.snapshot.params['hash'];
    this.noauthService.findHazard(hash).subscribe(
      (response: ApiResponseModel<HazardModel>) => {
        this.hazard = response.data;
        // @ts-ignore
        if (typeof this.hazard.controls == 'string') {
          this.hazard.controls = JSON.parse(this.hazard.controls);
        }
      },
      (error: HttpErrorResponse) => {
        if (error.status === 404) { this.router.navigate(['not-found']); }
      }
    );
  }

  onToggleVerify() {
    this.hazard.status === 'Verified'
      ? this.noauthService.unVerifyHazard(this.hazard.hash).subscribe(() => {
          this.utils.showToast('Un-verified Hazard/Risk');
          this.find();
        })
      : this.noauthService.verifyHazard(this.hazard.hash).subscribe(() => {
          this.utils.showToast(
            'Verified Hazard/Risk. Click the "Send notifications" button to email relevant users.'
          );
          this.find();
        });
  }

  onNotify() {
    this.noauthService.notifyHazard(this.hazard.hash).subscribe(() => {
      this.utils.showToast('Sent emails to relevant users.');
    });
  }
}
