<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/erps/{{ parent_id }}/teams">
      ERP Team Members
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item ">
          <a class="nav-link" routerLink="/erps">ERP's</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" routerLink="/erps/{{ parent_id }}/teams/new/edit">
            New Team Member
          </a>
        </li>
      </ul>
      <form
        class="form-inline my-2 my-lg-0"
        (ngSubmit)="service.get(parent_id, 0, true)">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="service.search"
          name="search"
          placeholder="Search"
          aria-label="Search"/>

        <button class="btn btn-outline-primary my-2 my-sm-0" type="submit">
          Search
        </button>
      </form>
    </div>
  </nav>

  <div class="table-responsive">
    <table class="table table-hover">
      <thead class="thead-light">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Role / Responsibility</th>
          <th scope="col">Training Required</th>
          <th scope="col">{{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}</th>
          <th scope="col">Email</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of service.list">
          <td>{{ item.name }}</td>
          <td>{{ item.role }}</td>
          <td>{{ item.training_required }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.email }}</td>
          <td width="200">
            <a routerLink="/erps/{{ parent_id }}/teams/{{ item.id }}/view"
              class="action-icon"
              matTooltip="View"
              matTooltipPosition="above">
              <mat-icon>visibility</mat-icon>
            </a>

            <a routerLink="/erps/{{ parent_id }}/teams/{{ item.id }}/edit"
              class="action-icon"
              matTooltip="Edit"
              matTooltipPosition="above">
              <mat-icon>edit</mat-icon>
            </a>

            <a href="javascript:void(0)"
              (click)="onRemove(item.id)"
              class="action-icon"
              matTooltip="Remove"
              matTooltipPosition="above">
              <mat-icon>delete</mat-icon>
            </a>
          </td>
        </tr>
      </tbody>

      <thead class="thead-light">
        <tr>
          <td class="text-center" colspan="6">
            <a href="javascript:void(0)"
              (click)="service.get(parent_id, 0, false)">
              Click to load more
            </a>
          </td>
        </tr>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Role / Responsibility</th>
          <th scope="col">Training Required</th>
          <th scope="col">{{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}</th>
          <th scope="col">Email</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
    </table>
  </div>
</div>
