<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class=" mr-auto">
      <button
        mat-raised-button
        routerLink="../.."
        style="margin-right: 1rem"
        id="back"
      >
        <mat-icon>arrow_back</mat-icon> Back
      </button>
      <button
        mat-raised-button
        routerLink="/items/{{ item.id }}/edit"
        id="edit"
        *ngIf="item?.id"
        appMarginRight
      >
        Edit
      </button>
    </span>
  </nav>

  <div class="row app-padding">
    <div class="col-6">
      <h6>Name</h6>
      <p id="name">{{ item.name }}</p>

      <h6>Question</h6>
      <p>{{item.question}}</p>

      <h6>Tooltip</h6>
      <p>{{item.tooltip}}</p>
    </div>
  </div>
</div>
