import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-sites-task-analyses-revised-versioning',
  templateUrl: './sites-task-analyses-revised-versioning.component.html',
  styleUrls: ['./sites-task-analyses-revised-versioning.component.scss']
})
export class SitesTaskAnalysesRevisedVersioningComponent implements OnInit {

  taskName: string = "";
  amendmentTitle: string = "";
  reasonForAmendment: string = "";

  constructor(
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SitesTaskAnalysesRevisedVersioningComponent>
  ) {
    // close dialog if no values are passed through
    if (typeof this.data['task_name'] == 'undefined') {
      this.dialogRef.close();
    }
  }

  ngOnInit() {
    this.taskName = this.data['task_name'];
  }

  onCancel() {
    this.dialogRef.close();
  }

  onContinue(form: NgForm) {
    if (!form.valid) {
      this.utils.showModal(
        'Validation Error',
        'Please make sure you enter all fields with valid information.'
      );
      return;
    }

    let response: any;

    response = {
      confirmed: true,
      amendment_title: this.amendmentTitle,
      reason_for_amendment: this.reasonForAmendment
    }

    this.dialogRef.close(response);
  }

}
