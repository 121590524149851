<div mat-dialog-title>
  <h5>
    Lost Time Injury (LTI) Time Record
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
</div>

<mat-dialog-content>
  <form #f="ngForm" (ngSubmit)="onSave()">
    <div class="row">
      <div class="col">
        <div class="alert alert-info mt-3" role="alert">
          Please select a date and enter the LTI in a combination of days and/or hours. The maximum days/hours left in the month is calculated based on the selected date and values you entered for days and hours. <br>
          You will need to add additional time records for each month. <br>
          <b>LTI is based on an 8-hour working day.</b>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <app-timezone-select [timezoneInput]="selectedTimezone" (timezoneOutput)="getOutputTimezone($event)"></app-timezone-select>
      </div>
      <div class="col">
        <mat-form-field class="full-width mb-3">
          <mat-label>Select the date:</mat-label>
          <input matInput [ngxMatDatetimePicker]="timeRecordDateTimePicker" readonly required name="timeRecordDateTime" [(ngModel)]="timeRecordDateTime">
          <mat-datepicker-toggle matSuffix [for]="timeRecordDateTimePicker"></mat-datepicker-toggle>
          <button type="button" mat-button matSuffix mat-icon-button *ngIf="timeRecordDateTime" (click)="timeRecordDateTime = null" matTooltip="Remove the date."><mat-icon>close</mat-icon></button>
          <ngx-mat-datetime-picker #timeRecordDateTimePicker [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
          <mat-hint>
            Click the calendar icon to select a date and time. <br>
            <b class="text-danger" *ngIf="!timeRecordDateTime">Please selected a date.</b>
          </mat-hint>
       </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field>
          <input matInput type="number" placeholder="LTI Days" [max]="getMaxDaysLeftInSelectedMonth()" [disabled]="!timeRecordDateTime" [(ngModel)]="incident_lti_time_record.actual_lti_days" name="actual_lti_days" matTooltip="The actual days for this LTI time record." />
          <mat-hint>Please enter the actual days for this LTI time record. <br>
            <b [ngClass]="{ 'text-danger': !canSubmitForm(f) }">Max: {{ getMaxDaysLeftInSelectedMonth() }} day{{ getMaxDaysLeftInSelectedMonth() > 1 ? 's' : '' }} left in month from selected date.</b>
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field>
          <input matInput type="number" placeholder="LTI Hours" [max]="getMaxWorkingHoursLeftInSelectedMonth()" [disabled]="!timeRecordDateTime" [(ngModel)]="incident_lti_time_record.actual_lti_hours" name="actual_lti_hours" matTooltip="The actual hours for this LTI time record." />
          <mat-hint>
            Please enter the actual hours for this LTI time record. <br>
            <b [ngClass]="{ 'text-danger': !canSubmitForm(f) }">Max: {{ getMaxWorkingHoursLeftInSelectedMonth() }} hour{{ getMaxWorkingHoursLeftInSelectedMonth() > 1 ? 's' : '' }} left in month from selected date.</b>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field>
          <input matInput type="text" placeholder="Comments" [(ngModel)]="incident_lti_time_record.actual_lti_comments" name="actual_lti_comments" matTooltip="Add any additional comments here." />
          <mat-hint>Please enter any comments here.</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="onSave()" [disabled]="!canSubmitForm(f)" color="primary">
    <mat-icon>save</mat-icon>
    Save
  </button>
</mat-dialog-actions>





