import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyItemsComponent } from './my-items.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FilesModule } from '../files/files.module';
import { FormsModule } from '@angular/forms';
import { MyItemsNotesComponent } from './my-items-notes/my-items-notes.component';

@NgModule({
  declarations: [MyItemsComponent, MyItemsNotesComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    FilesModule,
    MatBadgeModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    MatDialogModule,
    MatButtonToggleModule
  ],
  exports: [MyItemsComponent],
  entryComponents: [MyItemsNotesComponent]
})
export class MyItemsModule {}
