<div mat-dialog-title>
  <h5>Manage Files</h5>

  <button mat-icon-button class="float-right" (click)="onClose()">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <app-files-list [files]="files" (changedFiles)="onUpdateFiles($event)"></app-files-list>
</mat-dialog-content>
