<div mat-dialog-title class="position-relative w-100">
  <h5>Task Upload Mapping</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close Filter Tasks" mat-dialog-close
    style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<ng-container>
  <p appMarginTop>
    Map the columns below to its corresponding fields in the
    <b>sample data</b> table below. If you map more than one column to
    the same field, the data will be concatenated e.g. first name and
    last name columns will be combined into one field called full name
    if you select full name for both columns.
  </p>

  <div class="table-responsive">
    <table class="table table-hover">
      <thead class="thead-light">
        <tr>
          <th scope="col" *ngFor="let field of fields">
            {{ field }}
          </th>
        </tr>
        <tr>
          <th scope="col" *ngFor="let field of fields; let i = index">
            <mat-form-field>
              <mat-select placeholder="Map Field" [(ngModel)]="action[field]" name="header_{{ i }}" defaultValue="-1"
                (selectionChange)="onSelectionChange($event, i)">
                <mat-option value="-1">-- Ignore --</mat-option>
                <mat-option *ngFor="let f of mapped_fields" [value]="f.value">{{ f.content }}</mat-option>
              </mat-select>
              <mat-hint style="color: red" *ngIf="hasDupliValue() &&
                getDupliValues(getValueArray(action)).includes(getValue(i))">Duplicate fields selected.</mat-hint>
            </mat-form-field>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let row of data.actionsData.slice(0, 5)">
          <td *ngFor="let field of fields">
            {{row[field]}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<div class="d-flex justify-content-end my-2 py-2">
  <button type="button" mat-raised-button class="float-right" color="primary" [disabled]="hasDupliValue()"
    (click)="onSaveAndClose()">
    <mat-icon>save</mat-icon> Save
  </button>
</div>
