import {PpeModel} from "../admin/ppe/ppe.model";
import {WorkPermitModel} from "../admin/work-permits/work-permits.model";
import {ToolModel} from "../tools/tools.model";
import {UserModel} from "./user.model";
import {Auditable} from "../shared/auditable.model";
import {SiteModel} from "./site.model";
import {FileModel} from "../shared/file.model";
import {AccountModel} from "./account.model";
import {TaskAnalysesRevisedWorkflowModel} from "./task-analyses-revised-workflow.model";

export class TaskAnalysesRevisedModel extends Auditable {
  id: number;

  version_no: number;

  task_name: string;
  task_description: string;
  work_procedure: string;

  amendment_title: string;
  reason_for_amendment: string;

  training_qualifications: string;
  qualifications: string;
  duties: string;
  training: string;
  certificates: string;
  code_of_practice: string;
  maintenance_checks: string;
  safety_practices: string;
  ppe_other: string;
  work_permits_other: string;
  tools_other: string;

  clone_from_id: number;


  parent: TaskAnalysesRevisedModel;
  children: TaskAnalysesRevisedModel[];

  /**
   * PPE that belongs to Task Analysis?
   */
  ppe: PpeModel[];
  ppe_ids?: number[];

  /**
   * Work Permits that belongs to Task Analysis?
   */
  work_permits: WorkPermitModel[];
  work_permit_ids?: number[];

  /**
   * Tools that belongs to Task Analysis?
   */
  tools: ToolModel[];
  tool_ids?: number[];

  /**
   * Who created this Task Analysis?
   */
  created_by_user: UserModel;

  /**
   * Who updated this Task Analysis?
   */
  updated_by_user: UserModel;

  /**
   * Site that belongs to Task Analysis?
   */
  site: SiteModel;
  site_id: number;

  /**
   * Contractor that belongs to Task Analysis?
   */
  contractor: AccountModel;
  contractor_id: number;

  /**
   * Files that belongs to Task Analysis?
   */
  files?: FileModel[];
  file_ids?: number[];

  /**
   * Assignees that belongs to Task Analysis?
   */
  users?: UserModel[];
  user_ids?: number[];

  /**
   * Assignees that belongs to Task Analysis?
   */
  approver: UserModel;
  approver_id: number;
  approver_acknowledged: boolean;
  approver_acknowledged_date: number;

  /**
   * Assignees that belongs to Task Analysis?
   */
  authorizer: UserModel;
  authorizer_id: number;
  authorizer_acknowledged: boolean;
  authorizer_acknowledged_date: number;

  /**
   * Assignees that belongs to Task Analysis?
   */
  supervisor: UserModel;
  supervisor_id: number;
  supervisor_acknowledged: boolean;
  supervisor_acknowledged_date: number;

  /**
   * Workflows that belongs to Task Analysis?
   */
  workflows?: TaskAnalysesRevisedWorkflowModel[];
  // workflow_ids?: number[];

  cloneFrom(data: any) {
    this.clone_from_id = data.id;

    this.task_name = data.task_name;
    this.task_description = data.task_description;
    this.work_procedure = data.work_procedure;

    this.training_qualifications = data.training_qualifications;
    this.qualifications = data.qualifications;
    this.duties = data.duties;
    this.training = data.training;
    this.certificates = data.certificates;
    this.code_of_practice = data.code_of_practice;
    this.maintenance_checks = data.maintenance_checks;
    this.safety_practices = data.safety_practices;
    this.ppe_other = data.ppe_other;
    this.work_permits_other = data.work_permits_other;
    this.tools_other = data.tools_other;

    this.ppe = data.ppe ? [...data.ppe] : [];
    this.ppe_ids = this.ppe ? this.ppe.map((ppe) => ppe.id) : [];

    this.work_permits = data.work_permits ? [...data.work_permits] : [];
    this.work_permit_ids = this.work_permits ? this.work_permits.map((work_permit) => work_permit.id) : [];

    this.tools = data.tools ? [...data.tools] : [];
    this.tool_ids = this.tools ? this.tools.map((tool) => tool.id) : [];

    this.site = data.site ? {...data.site} : {};
    this.site_id = this.site ? this.site.id : 0;

    this.approver = data.approver ? {...data.approver} : {};
    this.approver_id = this.approver ? data.approver_id : 0;

    this.authorizer = data.authorizer ? {...data.authorizer} : {};
    this.authorizer_id = this.authorizer ? data.authorizer_id : 0;

    this.supervisor = data.supervisor ? {...data.supervisor} : {};
    this.supervisor_id = this.supervisor ? data.supervisor_id : 0;

    this.users = data.users ? [...data.users] : [];
    this.user_ids = this.users ? this.users.map((user) => user.id) : [];

    // this.contractor = data.contractor ? {...data.contractor} : {};
    // this.contractor_id = this.contractor ? this.contractor.id : 0;
  }

  apply(data: any) {
    if ( data ) {
      this.reset();
      Object.keys(data).forEach((key) => {
        if (key in this) {
          switch ( key ) {
            case 'files':
              this[key].length = 0;
              data[key].forEach((file: FileModel) => {
                this[key].push(file);
              });
              break;
            case 'ppe':
              this[key].length = 0;
              data[key].forEach((ppe: PpeModel) => {
                this[key].push(ppe);
              });
              break;
            case 'work_permits':
              this[key].length = 0;
              data[key].forEach((work_permit: WorkPermitModel) => {
                this[key].push(work_permit);
              });
              break;
            case 'tools':
              this[key].length = 0;
              data[key].forEach((tool: ToolModel) => {
                this[key].push(tool);
              });
              break;
            case 'workflows':
              this[key].length = 0;
              data[key].forEach((workflow: TaskAnalysesRevisedWorkflowModel) => {
                this[key].push(workflow);
              });
              break;
            case 'users':
              this[key].length = 0;
              data[key].forEach((user: UserModel) => {
                this[key].push(user);
              });
              break;
            case 'site':
              this.site.apply(data[key]);
              break;
            case 'contractor':
              this.contractor.apply(data[key]);
              break;
            case 'approver':
              this.approver.apply(data[key]);
              break;
            case 'authorizer':
              this.authorizer.apply(data[key]);
              break;
            case 'supervisor':
              this.supervisor.apply(data[key]);
              break;
            case 'created_by_user':
              this.created_by_user.apply(data[key]);
              break;
            case 'updated_by_user':
              this.updated_by_user.apply(data[key]);
              break;
            default:
              this[key] = data[key];
          }
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;

    this.site_id = 0;
    this.contractor_id = 0;

    this.task_name = '';
    this.task_description = '';
    this.work_procedure = '';

    this.qualifications = '';
    this.duties = '';
    this.training = '';
    this.certificates = '';
    this.code_of_practice = '';
    this.maintenance_checks = '';
    this.safety_practices = '';
    this.ppe_other = '';
    this.work_permits_other = '';
    this.tools_other = '';

    this.date_created = 0;
    this.date_modified = 0;
    this.deleted_at = 0;

    this.created_by = 0;
    this.updated_by = 0;
    this.deleted_by = 0;

    if(this.files) {
      this.files.length = 0;
    }

    if(this.workflows) {
      this.workflows.length = 0;
    }

    if(this.ppe) {
      this.ppe.length = 0;
    }

    if(this.ppe_ids) {
      this.ppe_ids.length = 0;
    }

    if(this.work_permits) {
      this.work_permits.length = 0;
    }

    if(this.work_permit_ids) {
      this.work_permit_ids.length = 0;
    }

    if(this.tools) {
      this.tools.length = 0;
    }

    if(this.tool_ids) {
      this.tool_ids.length = 0;
    }

    if(this.users) {
      this.users.length = 0;
    }

    if(this.user_ids) {
      this.user_ids.length = 0;
    }

  }
}
