<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">

    <span class="navbar-brand">
      Corrective Actions
    </span>

    <span class="mr-auto">
      <button mat-raised-button class="mr-2" color="primary" (click)="onEditCorrectiveAction()" id="add" matTooltip="Click this button to create a new corrective action.">
        <mat-icon>add</mat-icon>
        Add
      </button>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>
        <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>delete</mat-icon> Delete Selected
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect and/or export Tasks." style="min-width: 120px">
        <mat-icon>more_vert</mat-icon>
        Menu
      </button>
    </span>

    <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onFilter()" matTooltip="Click this button to display filter options.">
      <mat-icon>filter_list</mat-icon> Filter
    </button>

    <form class="form-inline my-2 my-lg-0"
          style="min-width: 325px"
          (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2" type="search"
        [(ngModel)]="dataSource.search" name="search" placeholder="Search"
        aria-label="Search" id="search-input"
        matTooltip="Search the corrective actions by name." />
      <button mat-raised-button
              class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
              color="secondary"
              (click)="dataSource.getData(true)"
              id="search-button"
              matTooltip="Click this button to run the current search.">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>

  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="step_no" matSortDirection="asc" matSortDisableClear class="w-100">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="65px">
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- The id of the corrective action. -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the id of the corrective actions.">
          ID #
        </th>
        <td mat-cell *matCellDef="let corrective_action">
          {{ corrective_action.id }}
        </td>
      </ng-container>

      <!-- The task/name of the corrective actions. -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the corrective actions.">
          Corrective Action
        </th>
        <td mat-cell *matCellDef="let corrective_action">
          {{ corrective_action.name }}
        </td>
      </ng-container>

      <!-- The priority of the corrective action. -->
      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the priority of the corrective actions.">
          Priority
        </th>
        <td mat-cell *matCellDef="let corrective_action">
          {{ corrective_action.priority | titlecase }}
        </td>
      </ng-container>

      <!-- The due date of the corrective actions if any. -->
      <ng-container matColumnDef="due">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the due date of the corrective actions.">
          Due Date
        </th>
        <td mat-cell *matCellDef="let corrective_action">
          <span *ngIf="corrective_action.due">
            {{ corrective_action.due | timezoneConvertMedium }}
          </span>
        </td>
      </ng-container>

      <!-- The due date of the corrective actions if any. -->
      <ng-container matColumnDef="due_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the due date of the corrective actions.">
          Due Date (UTC)
        </th>
        <td mat-cell *matCellDef="let corrective_action">
          <span *ngIf="corrective_action.due">
            {{ corrective_action.due | timezoneConvertUTCMedium }}
          </span>
        </td>
      </ng-container>

      <!-- The completion status of the corrective actions. -->
      <ng-container matColumnDef="completed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the completion status of the corrective actions.">
          Completed
        </th>
        <td mat-cell *matCellDef="let corrective_action">
          {{ corrective_action.completed ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <!-- The date the corrective action was created on. -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the corrective action was added.">
          Created At
        </th>
        <td mat-cell *matCellDef="let corrective_action">
          {{ (corrective_action.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- The date the corrective action was created on. -->
      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the corrective action was added.">
          Created At (UTC)
        </th>
        <td mat-cell *matCellDef="let corrective_action">
          {{ (corrective_action.date_created * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let corrective_action">
          <button type="button" mat-button class="mr-3" (click)="onEditCorrectiveAction(corrective_action)" matTooltip="Click this button to edit the corrective action.">
            Edit
          </button>
          <button type="button" mat-button (click)="onRemove(corrective_action)" matTooltip="Click this button to remove the corrective action.">
            Delete
          </button>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
