import {IndustriesModel} from "../admin/industries/industries.model";
import {UserModel} from "../models/user.model";
import {Auditable} from "../shared/auditable.model";
import {FileModel} from "../shared/file.model";

export class ToolModel extends Auditable {
  id: number;
  title: string = '';
  description: string = '';

  industry_id: number = 0;
  account_id: number = 0;

  /**
   * Which Industry is associated?
   */
  industry: IndustriesModel;

  /**
   * Who created this Tool?
   */
  created_by_user: UserModel;

  /**
   * Who updated this Tool?
   */
  updated_by_user: UserModel;

  /**
   * The expiration timestamp for a specific tool.
   *
   * @type {number}
   * @default null
   */
  expires_at: number = null;

  /**
   * An array variable representing a list of maintenance and safety checks.
   *
   * @type {string[]}
   */
  maintenance_safety_checks: string[] = [];

  /**
   * Represents an array of FileModel objects.
   * @typedef {Array<FileModel>} FileModel[]
   */
  files: FileModel[] = [];
}
