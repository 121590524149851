<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class="mr-auto">
    <button mat-raised-button routerLink="/task-analyses-revised" class="margin-right my-2">
      <mat-icon>arrow_back</mat-icon> TA/JSA/SWMS Templates
    </button>

    <button *ngIf="urlPath == 'view'" mat-raised-button routerLink="/task-analyses-revised/{{ ta_revised.id }}/edit"  class="margin-right my-2">
      <mat-icon>edit</mat-icon> Edit
    </button>
    <button *ngIf="urlPath == 'edit'" mat-raised-button routerLink="/task-analyses-revised/{{ ta_revised.id }}/view"  class="margin-right my-2">
      <mat-icon>visibility</mat-icon> View
    </button>

    <button mat-raised-button (click)="onRemove()"  class="margin-right my-2">
      <mat-icon>delete</mat-icon> Delete
    </button>

  </span>
</nav>

<mat-tab-group *ngIf="urlPath == 'edit'">
  <mat-tab label="Overview">
    <app-task-analyses-revised-edit [taTabsComponent]="this"></app-task-analyses-revised-edit>
  </mat-tab>
  <mat-tab *ngIf="ta_revised.id" label="Workflow">
    <app-task-analyses-revised-workflows [taRevisedId]="ta_revised.id" [taRevisedPath]="urlPath"></app-task-analyses-revised-workflows>
  </mat-tab>
</mat-tab-group>

<app-task-analyses-revised-view *ngIf="urlPath == 'view'" [taTabsComponent]="this" [taRevisedId]="ta_revised.id"></app-task-analyses-revised-view>
