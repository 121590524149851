<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/admin/codes">Codes</a>

  <button
    mat-icon-button
    routerLink="/admin/codes/new/edit"
    matTooltip="Add new Code"
    class="add">
    <mat-icon>add</mat-icon>
  </button>

  <button mat-icon-button (click)="addMany()" matTooltip="Add many Codes">
    <mat-icon>format_list_bulleted</mat-icon>
  </button>

  <span class="mr-auto"></span>
  <form tourAnchor="tour.search" class="form-inline my-2 my-lg-0 force-right" (ngSubmit)="dataSource.getData(true)">

    <button mat-raised-button type="button" class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onOpenFilters()" matTooltip="Click this button to display filter options.">
      <mat-icon>filter_list</mat-icon> Filter
    </button>

    <input
      class="form-control mr-sm-2"
      type="search"
      [(ngModel)]="dataSource.search"
      name="search"
      placeholder="Search"
      aria-label="Search"/>

    <button
      mat-icon-button
      class="mr-sm-2"
      (click)="dataSource.getData(false)"
      type="button"
      matTooltip="Search">
      <mat-icon>search</mat-icon>
    </button>
  </form>
</nav>

<div class="mat-elevation-z8 scroll-box">
  <table
    mat-table
    [dataSource]="dataSource"
    style="width: 100%"
    matSort
    matSortActive="name"
    matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef width="65px">
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)"
          appMarginRight>
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        ID
      </th>
      <td mat-cell *matCellDef="let code" class="no-padding">
        {{ code.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="paper_id">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Short Hash
      </th>
      <td mat-cell *matCellDef="let code" class="no-padding">
        {{ code.paper_id | uppercase }}
        <button mat-icon-button
          ngxClipboard
          [cbContent]="code.paper_id"
          matTooltip="Copy to Clipboard"
          (click)="copied(code.paper_id)">
          <mat-icon>file_copy</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Name
      </th>
      <td mat-cell *matCellDef="let code" class="no-padding">
        {{ code.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="site.name">
      <th mat-header-cell *matHeaderCellDef class="no-padding">
        {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
      </th>
      <td mat-cell *matCellDef="let code" class="no-padding">
        <a routerLink="/admin/sites/{{ code?.site?.id }}/details" matTooltip="Edit the information." matTooltipPosition="above">
          {{ code.site?.name }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="site.account.name">
      <th mat-header-cell *matHeaderCellDef class="no-padding">
        Account
      </th>
      <td mat-cell *matCellDef="let code" class="no-padding">
        <a routerLink="/admin/accounts/{{code.site?.account?.id}}/edit">
          {{ code.site?.account?.name }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by Date Created">
        Date Created
      </th>
      <td mat-cell *matCellDef="let code">
        {{ (code.date_created * 1000) | timezoneConvertShort }} <br>
        {{ (code.date_created * 1000) | timezoneConvertUTCShort }}
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container *ngIf="dataSource.only_archived == 'false'; else restoreManyOption">
          <button mat-icon-button matTooltip="Unlink selected" *ngIf="dataSource.selection.hasValue()" (click)="unlinkMany()">
            <mat-icon>link_off</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Delete selected" *ngIf="dataSource.selection.hasValue()" (click)="deleteMany()">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>

        <ng-template #restoreManyOption>
          <button mat-icon-button matTooltip="Restore Selected" *ngIf="dataSource.selection.hasValue()" (click)="onRestoreSelected()">
            <mat-icon>restore</mat-icon>
          </button>
        </ng-template>
      </th>

      <td mat-cell *matCellDef="let code">
        <ng-container *ngIf="dataSource.only_archived == 'false'; else restoreOption">
          <a mat-icon-button routerLink="/admin/codes/{{ code.id }}/view/" matTooltip="View">
            <mat-icon>remove_red_eye</mat-icon>
          </a>

          <a mat-icon-button routerLink="/admin/codes/{{ code.id }}/edit/" matTooltip="Edit">
            <mat-icon>edit</mat-icon>
          </a>

          <button mat-icon-button [disabled]="!code.site" (click)="unlink(code.id)" matTooltip="Unlink from {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}">
            <mat-icon>link_off</mat-icon>
          </button>

          <button mat-icon-button (click)="delete(code)" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>

        <ng-template #restoreOption>
          <button mat-icon-button (click)="onRestore(code)" matTooltip="Restore">
            <mat-icon>restore</mat-icon>
          </button>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>
</div>
