import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CoursesRoutingModule } from '../courses/courses-routing.module';
import { DirectivesModule } from '../directives/directives.module';
import { HazardsSelectorComponent } from '../hazards/hazards-selector/hazards-selector.component';
import { PipesModule } from '../pipes/pipes.module';
import { SitesContractorsComponent } from './sites-contractors/sites-contractors.component';
import { SitesDetailsEditComponent } from './sites-details-edit/sites-details-edit.component';
import { SitesDetailsViewComponent } from './sites-details-view/sites-details-view.component';
import { SitesErpsComponent } from './sites-erps/sites-erps.component';
import { SitesHazardousSubstancesComponent } from './sites-hazardous-substances/sites-hazardous-substances.component';
import { SitesHazardsComponent } from './sites-hazards/sites-hazards.component';
import { SitesInductionEditComponent } from './sites-induction-edit/sites-induction-edit.component';
import { SitesInductionViewComponent } from './sites-induction-view/sites-induction-view.component';
import { SitesManagersComponent } from './sites-managers/sites-managers.component';
import { SitesRoutingModule } from './sites-routing.module';
import { SitesSelectorToolbarComponent } from './sites-selector-toolbar/sites-selector-toolbar.component';
import { SitesTabsComponent } from './sites-tabs/sites-tabs.component';
import { SitesToolboxTalksComponent } from './sites-toolbox-talks/sites-toolbox-talks.component';
import { SitesComponent } from './sites/sites.component';
import { ToolboxTalksSelectorComponent } from '../toolbox-talks/toolbox-talks-selector/toolbox-talks-selector.component';
import { ToolboxTalksEditDialogComponent } from '../toolbox-talks/toolbox-talks-edit-dialog/toolbox-talks-edit-dialog.component';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { EmployeesSelectorComponent } from '../employees/employees-selector/employees-selector.component';
import { HazardousSubstancesSelectorComponent } from '../hazardous-substances/hazardous-substances-selector/hazardous-substances-selector.component';
import { ErpsSelectorComponent } from '../erps/erps-selector/erps-selector.component';
import { ErpsEditDialogComponent } from '../erps/erps-edit-dialog/erps-edit-dialog.component';
import { ContractorsSelectorComponent } from '../contractors/contractors-selector/contractors-selector.component';
import { HazardousSubstancesEditDialogComponent } from '../hazardous-substances/hazardous-substances-edit-dialog/hazardous-substances-edit-dialog.component';
import { SitesEditFoldersComponent } from './sites-edit-folders/sites-edit-folders.component';
import { SitesHazardsViewComponent } from './sites-hazards-view/sites-hazards-view.component';
import { FilesModule } from '../files/files.module';
import { SitesUserSelectorComponent } from './sites-user-selector/sites-user-selector.component';
import { SitesEmergencyEvacuationPlanEditComponent } from './sites-emergency-evacuation-plan-edit/sites-emergency-evacuation-plan-edit.component';
import { SitesEmergencyEvacuationPlanViewComponent } from './sites-emergency-evacuation-plan-view/sites-emergency-evacuation-plan-view.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SitesTaskAnalysesComponent } from './sites-task-analyses/sites-task-analyses.component';
import { SitesSettingsComponent } from './sites-settings/sites-settings.component';
import { SitesMainSsspComponent } from './sites-main-sssp/sites-main-sssp.component';
import { SsspModule } from '../sssp/sssp.module';
import { MatComponentsModule } from '../mat-components/mat-components.module';
import { SitesSafetyObservationsComponent } from './sites-safety-observations/sites-safety-observations.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SitesToolboxTalksViewComponent } from './sites-toolbox-talks-view/sites-toolbox-talks-view.component';
import { SitesFilterComponent } from './sites-filter/sites-filter.component';
import { SitesToolboxTalksFilterComponent } from './sites-toolbox-talks-filter/sites-toolbox-talks-filter.component';
import { SitesSafetyObservationsFilterComponent } from './sites-safety-observations-filter/sites-safety-observations-filter.component';
import { SitesTaskAnalysesRevisedComponent } from './sites-task-analyses-revised/sites-task-analyses-revised.component';
import { SitesTaskAnalysesRevisedViewComponent } from './sites-task-analyses-revised-view/sites-task-analyses-revised-view.component';
import { SitesTaskAnalysesRevisedEditComponent } from './sites-task-analyses-revised-edit/sites-task-analyses-revised-edit.component';
import { SitesTaskAnalysesRevisedFilterComponent } from './sites-task-analyses-revised-filter/sites-task-analyses-revised-filter.component';
import {TaskAnalysesRevisedSelectorComponent} from "../shared/task-analyses-revised-selector/task-analyses-revised-selector.component";
import { SitesTaskAnalysesRevisedTabsComponent } from './sites-task-analyses-revised-tabs/sites-task-analyses-revised-tabs.component';
import { SitesTaskAnalysesRevisedWorkflowsComponent } from './sites-task-analyses-revised-workflows/sites-task-analyses-revised-workflows.component';
import { SitesTaskAnalysesRevisedAddComponent } from './sites-task-analyses-revised-add/sites-task-analyses-revised-add.component';
import {UtilsModule} from "../utils/utils.module";
import { SitesTaskAnalysesRevisedWorkflowsEditComponent } from './sites-task-analyses-revised-workflows-edit/sites-task-analyses-revised-workflows-edit.component';
import { SitesTaskAnalysesRevisedWorkflowsViewComponent } from './sites-task-analyses-revised-workflows-view/sites-task-analyses-revised-workflows-view.component';
import { SitesTaskAnalysesRevisedVersioningComponent } from './sites-task-analyses-revised-versioning/sites-task-analyses-revised-versioning.component';
import {SiteHazardsSelectorComponent} from "../shared/site-hazards-selector/site-hazards-selector.component";
import { SitesTaskAnalysesRevisedWorkflowsTabsComponent } from './sites-task-analyses-revised-workflows-tabs/sites-task-analyses-revised-workflows-tabs.component';
import { SitesTaskAnalysesRevisedWorkflowsNotesComponent } from './sites-task-analyses-revised-workflows-notes/sites-task-analyses-revised-workflows-notes.component';
import { SitesTaskAnalysesRevisedWorkflowsNotesEditComponent } from './sites-task-analyses-revised-workflows-notes-edit/sites-task-analyses-revised-workflows-notes-edit.component';
import { SitesTaskAnalysesRevisedWorkflowsStepsComponent } from './sites-task-analyses-revised-workflows-steps/sites-task-analyses-revised-workflows-steps.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import { SitesTaskAnalysesRevisedWorkflowsHazardsComponent } from './sites-task-analyses-revised-workflows-hazards/sites-task-analyses-revised-workflows-hazards.component';
import { SitesTaskAnalysesRevisedWorkflowsHazardsEditComponent } from './sites-task-analyses-revised-workflows-hazards-edit/sites-task-analyses-revised-workflows-hazards-edit.component';
import { SitesTaskAnalysesRevisedWorkflowsHazardsViewComponent } from './sites-task-analyses-revised-workflows-hazards-view/sites-task-analyses-revised-workflows-hazards-view.component';
import { SharedModule } from '../shared/shared.module';
import { SitesDynamicFormsComponent } from './sites-dynamic-forms/sites-dynamic-forms.component';
import {SitesSafetyManagersComponent} from "./components/sites-safety-managers/sites-safety-managers.component";
import {SitesFirstAidersComponent} from "./components/sites-first-aiders/sites-first-aiders.component";
import { SitesAuditsViewComponent } from './sites-audits-view/sites-audits-view.component';
import { SitesAuditsEditComponent } from './sites-audits-edit/sites-audits-edit.component';
import { SitesAuditsComponent } from './sites-audits/sites-audits.component';
import { SitesIncidentsFilterComponent } from './sites-incidents-filter/sites-incidents-filter.component';
import { SitesIncidentsComponent } from './sites-incidents/sites-incidents.component';
import { SitesAuditsFilterComponent } from './sites-audits-filter/sites-audits-filter.component';
import { SitesToolboxTalksTabsComponent } from './sites-toolbox-talks-tabs/sites-toolbox-talks-tabs.component';
import {ToolboxTalksModule} from "../toolbox-talks/toolbox-talks.module";
import {SitesImporterComponent} from "./sites-importer/sites-importer.component";
import {SitesChildrenComponent} from "./sites-children/sites-children.component";
import {SitesCodesEditComponent} from "./sites-codes/codes-edit/sites-codes-edit.component";
import {SitesCodesViewComponent} from "./sites-codes/codes-view/sites-codes-view.component";
import {SitesCodesComponent} from "./sites-codes/sites-codes.component";
import {SitesCodesClaimComponent} from "./sites-codes/codes-claim/sites-codes-claim.component";
import {SitesHazardsFilterComponent} from "./sites-hazards-filter/sites-hazards-filter.component";
import {SitesCodesFilterComponent} from "./sites-codes/sites-codes-filter/sites-codes-filter.component";
import {SitesEditFoldersFilterComponent} from "./sites-edit-folders-filter/sites-edit-folders-filter.component";
import {SitesTaskAnalysesFilterComponent} from "./sites-task-analyses-filter/sites-task-analyses-filter.component";
import {SitesUsersWorkedOnComponent} from "./sites-users-worked-on/sites-users-worked-on.component";
import {
  HazardousSubstancesFilterComponent
} from "../hazardous-substances/hazardous-substances-filter/hazardous-substances-filter.component";
import {
  SitesHazardousSubstancesLinkDialogComponent
} from "./sites-hazardous-substances-link-dialog/sites-hazardous-substances-link-dialog.component";
import {ChartsModule} from "../charts/charts.module";
import { SitesDetailsTabsComponent } from './sites-details-tabs/sites-details-tabs.component';
import { SitesDetailsMapComponent } from './sites-details-map/sites-details-map.component';
import {
  SitesNotificationsTabsComponent
} from './sites-notificaions/sites-notifications-tabs/sites-notifications-tabs.component';
import {
  SitesNotificationsUsersComponent
} from './sites-notificaions/sites-notifications-users/sites-notifications-users.component';
import {
  SitesNotificationsRolesComponent
} from './sites-notificaions/sites-notifications-roles/sites-notifications-roles.component';

@NgModule({
  declarations: [
    SitesComponent,
    SitesContractorsComponent,
    SitesDetailsEditComponent,
    SitesDetailsViewComponent,
    SitesErpsComponent,
    SitesHazardousSubstancesComponent,
    SitesHazardsComponent,
    SitesInductionEditComponent,
    SitesInductionViewComponent,
    SitesManagersComponent,
    SitesTabsComponent,
    SitesToolboxTalksComponent,
    SitesSelectorToolbarComponent,
    SitesEditFoldersComponent,
    SitesHazardsViewComponent,
    SitesUserSelectorComponent,
    SitesEmergencyEvacuationPlanEditComponent,
    SitesEmergencyEvacuationPlanViewComponent,
    SitesTaskAnalysesComponent,
    SitesSettingsComponent,
    SitesMainSsspComponent,
    SitesToolboxTalksViewComponent,
    SitesFilterComponent,
    SitesToolboxTalksFilterComponent,
    SitesSafetyObservationsComponent,
    SitesToolboxTalksViewComponent,
    SitesSafetyObservationsFilterComponent,
    SitesTaskAnalysesRevisedComponent,
    SitesTaskAnalysesRevisedAddComponent,
    SitesTaskAnalysesRevisedViewComponent,
    SitesTaskAnalysesRevisedEditComponent,
    SitesTaskAnalysesRevisedFilterComponent,
    SitesTaskAnalysesRevisedTabsComponent,
    SitesTaskAnalysesRevisedWorkflowsComponent,
    SitesTaskAnalysesRevisedWorkflowsEditComponent,
    SitesTaskAnalysesRevisedWorkflowsViewComponent,
    SitesTaskAnalysesRevisedVersioningComponent,
    SitesTaskAnalysesRevisedWorkflowsTabsComponent,
    SitesTaskAnalysesRevisedWorkflowsNotesComponent,
    SitesTaskAnalysesRevisedWorkflowsNotesEditComponent,
    SitesDynamicFormsComponent,
    SitesTaskAnalysesRevisedWorkflowsStepsComponent,
    SitesTaskAnalysesRevisedWorkflowsHazardsComponent,
    SitesTaskAnalysesRevisedWorkflowsHazardsEditComponent,
    SitesTaskAnalysesRevisedWorkflowsHazardsViewComponent,
    SitesSafetyManagersComponent,
    SitesFirstAidersComponent,
    SitesAuditsComponent,
    SitesAuditsEditComponent,
    SitesAuditsViewComponent,
    SitesIncidentsComponent,
    SitesIncidentsFilterComponent,
    SitesAuditsFilterComponent,
    SitesToolboxTalksTabsComponent,
    SitesImporterComponent,
    SitesChildrenComponent,
    SitesCodesComponent,
    SitesCodesEditComponent,
    SitesCodesViewComponent,
    SitesCodesClaimComponent,
    SitesHazardsFilterComponent,
    SitesCodesFilterComponent,
    SitesEditFoldersFilterComponent,
    HazardousSubstancesFilterComponent,
    SitesTaskAnalysesFilterComponent,
    SitesUsersWorkedOnComponent,
    SitesHazardousSubstancesLinkDialogComponent,
    SitesDetailsTabsComponent,
    SitesDetailsMapComponent,
    SitesNotificationsTabsComponent,
    SitesNotificationsUsersComponent,
    SitesNotificationsRolesComponent
  ],
    imports: [
        CommonModule,
        SitesRoutingModule,
        CommonModule,
        FormsModule,
        DirectivesModule,
        CoursesRoutingModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatListModule,
        MatSelectModule,
        MatTabsModule,
        MatButtonModule,
        MatDialogModule,
        MatToolbarModule,
        PipesModule,
        EditorModule,
        MatSlideToggleModule,
        FilesModule,
        MatTooltipModule,
        FontAwesomeModule,
        MatBadgeModule,
        MatSidenavModule,
        MatProgressSpinnerModule,
        MatCardModule,
        SsspModule,
        MatComponentsModule,
        BsDatepickerModule,
        UtilsModule,
        DragDropModule,
        SharedModule,
        ToolboxTalksModule,
        ChartsModule
    ],
  entryComponents: [
    HazardsSelectorComponent,
    HazardousSubstancesSelectorComponent,
    ToolboxTalksSelectorComponent,
    ToolboxTalksEditDialogComponent,
    SitesSelectorComponent,
    EmployeesSelectorComponent,
    ErpsSelectorComponent,
    ErpsEditDialogComponent,
    ContractorsSelectorComponent,
    HazardousSubstancesEditDialogComponent,
    SitesHazardsViewComponent,
    SitesUserSelectorComponent,
    SitesFilterComponent,
    SitesToolboxTalksFilterComponent,
    SitesSafetyObservationsFilterComponent,
    SitesTaskAnalysesRevisedAddComponent,
    SitesTaskAnalysesRevisedViewComponent,
    SitesTaskAnalysesRevisedEditComponent,
    SitesTaskAnalysesRevisedFilterComponent,
    TaskAnalysesRevisedSelectorComponent,
    SitesTaskAnalysesRevisedWorkflowsViewComponent,
    SitesTaskAnalysesRevisedVersioningComponent,
    SiteHazardsSelectorComponent,
    SitesTaskAnalysesRevisedWorkflowsTabsComponent,
    SitesTaskAnalysesRevisedWorkflowsNotesEditComponent,
    SitesTaskAnalysesRevisedWorkflowsStepsComponent,
    SitesTaskAnalysesRevisedWorkflowsHazardsEditComponent,
    SitesTaskAnalysesRevisedWorkflowsHazardsViewComponent,
    SitesIncidentsComponent,
    SitesIncidentsFilterComponent,
    SitesAuditsFilterComponent,
    SitesCodesClaimComponent,
    SitesHazardsFilterComponent,
    SitesCodesFilterComponent,
    SitesEditFoldersFilterComponent,
    HazardousSubstancesFilterComponent,
    SitesTaskAnalysesFilterComponent,
    SitesHazardousSubstancesLinkDialogComponent
  ],
  exports: [
    SitesUserSelectorComponent
  ]
})
export class SitesModule {}
