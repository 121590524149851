<div mat-dialog-title>
  <div class="d-flex">
    <h3>Search Repository</h3>
    <span style="flex: 1 1 auto;"></span>
    <form
      class="form-inline my-2 my-lg-0"
      (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2"
        type="search"
        [(ngModel)]="dataSource.search"
        name="search"
        placeholder="Search Repository"
        aria-label="Search"/>

      <button mat-raised-button class="mr-sm-2" type="submit">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>
  </div>
</div>

<mat-dialog-content>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="name"
    matSortDirection="asc"
    matSortDisableClear>

    <!-- Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ dataSource.repo == 'hazards' ? 'Hazard & Risk' : 'Name' }}
      </th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        <b>{{ element.name }}</b> <br />
        <small>{{ element.description }}</small>
      </td>
    </ng-container>

    <!-- Hazardous Substances -->
    <ng-container matColumnDef="substance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Substance / Product
      </th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        <b>{{ element.substance }}</b> <br />
        <small>{{ element.risk_info }}</small>
      </td>
    </ng-container>

    <!-- IRA -->
    <ng-container matColumnDef="ira">
      <th mat-header-cell *matHeaderCellDef width="150px">
        Initial <br />Risk Assessment
      </th>
      <td mat-cell *matCellDef="let element">
        {{ utils.getRiskAssessmentText(element.ira_likelihood, element.ira_severity) }}
      </td>
    </ng-container>

    <!-- RRA -->
    <ng-container matColumnDef="rra">
      <th mat-header-cell *matHeaderCellDef width="150px">
        Residual <br />Risk Assessment
      </th>
      <td mat-cell *matCellDef="let element">
        {{ utils.getRiskAssessmentText(element.rra_likelihood, element.rra_severity) }}
      </td>
    </ng-container>

    <!-- Controls -->
    <ng-container matColumnDef="controls">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Controls
      </th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ element.controls.length }}
      </td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef width="50px">Actions</th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        <a href="javascript:void(0)" (click)="onSelect(element)">Select</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="dataSource.selection.toggle(row.id)"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <mat-paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>

  <button
    mat-raised-button
    color="primary"
    type="button"
    mat-dialog-close
    (click)="onClose()">
    Close
  </button>
</mat-dialog-actions>
