<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="button-row">
    <button mat-raised-button routerLink="../.." class="margin-right">
      <mat-icon>arrow_back</mat-icon> Back
    </button>

    <button mat-raised-button class="margin-right"
      routerLink="/admin/codes/{{code.id}}/edit">
      <mat-icon>edit</mat-icon>
      Edit
    </button>
  </div>
</nav>

<div class="app-padding">

  <div class="row">
    <div class="col">
      <h6>ID</h6>
      <p id="paper_id">{{code.paper_id}}</p>

      <h6>Name</h6>
      <p id="name">{{code.name}}</p>

      <h6 *ngIf="code.site?.name">{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}</h6>
      <p id="site_name">{{code.site?.name}}</p>

      <h6 *ngIf="code.site?.account?.name">Account</h6>
      <p id="account_name">{{code.site?.account?.name}}</p>
    </div>

    <div class="col">
      <div class="row">
        <div class="col">
          <mat-hint>Right-click on any of the two images below to save them. When the context-menu opens up, click on "Save Image As...".</mat-hint>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h6>QR Code</h6>
          <a [href]="code.code_url" target="_blank">
            <img [src]="code.code_url" class="img-fluid img-thumbnail" />
          </a>
        </div>

        <div class="col-6">
          <h6>Branded QR Code</h6>
          <a [href]="code.code_branded_url" target="_blank">
            <img [src]="code.code_branded_url" class="img-fluid img-thumbnail" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
