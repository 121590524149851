import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EllipsisPipe } from './ellipsis.pipe';
import { SplitCsvPipe } from './split-csv.pipe';
import { FileIconPipe } from './file-icon.pipe';
import { InductionIconPipe } from './induction-icon.pipe';
import { PrequalScorePipe } from './prequal-score.pipe';
import { TimezoneConvertPipeLong, TimezoneConvertPipeMedium, TimezoneConvertPipeShort, TimezoneConvertPipeUTCLong, TimezoneConvertPipeUTCMedium, TimezoneConvertPipeUTCShort } from './timezone-convert.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    EllipsisPipe,
    SplitCsvPipe,
    FileIconPipe,
    InductionIconPipe,
    PrequalScorePipe,
    SanitizeHtmlPipe,
    TimezoneConvertPipeLong,
    TimezoneConvertPipeMedium,
    TimezoneConvertPipeShort,
    TimezoneConvertPipeUTCLong,
    TimezoneConvertPipeUTCMedium,
    TimezoneConvertPipeUTCShort
  ],
  exports: [
    EllipsisPipe,
    SplitCsvPipe,
    FileIconPipe,
    InductionIconPipe,
    PrequalScorePipe,
    SanitizeHtmlPipe,
    TimezoneConvertPipeLong,
    TimezoneConvertPipeMedium,
    TimezoneConvertPipeShort,
    TimezoneConvertPipeUTCLong,
    TimezoneConvertPipeUTCMedium,
    TimezoneConvertPipeUTCShort
  ]
})
export class PipesModule {}
