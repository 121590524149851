import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { SsspTabsDialogComponent } from 'src/app/sssp/sssp-tabs/sssp-tabs-dialog.component';
import { ApiDataSource } from 'src/app/utils/api-data-source';

@Component({
  selector: 'app-user-accessible-sites',
  templateUrl: './user-accessible-sites.component.html',
  styleUrls: ['./user-accessible-sites.component.scss']
})
export class UserAccessibleSitesComponent implements OnInit {

  listColumns: string[] = [
    // 'select',
    'name',
    'location',
    'owner',
    'actions'
  ];
  listDataSource = new UserAccessibleSitesDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private api: ApiRequestService,
    private app: AppService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.listDataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.listDataSource.limit = paginator['pageSize'];
      this.listDataSource.offset = paginator['pageIndex'];
      this.listDataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.listDataSource.order_by = sorter['active'];
      this.listDataSource.order = sorter['direction'];
      this.listDataSource.getData();
    })).subscribe();
  }

  onCreateSSSP(site_id: number) {
    // Make a post request to create a new Safety Plan.
    // Reload the list to show the latest changes.
    this.api.makeRequest('post', 'v1/sssp', {
      site_id: site_id,
      contractor_id: this.app.account.id
    })
    .then((response: any) => {
      // This means the request was successful.
      // Open it in a dialog.
      this.onManageSSSP(response.sssp_hash);
      // Reload the list in the background.
      this.listDataSource.getData();
    })
    .catch((error) => {
      this.utils.showToast('The Safety Plan was created successfully.');
    });
  }

  onManageSSSP(sssp_hash: string) {
    // Open the Safety Plan manager using a dialog window.
    this.utils.showComponentDialog(SsspTabsDialogComponent, {
      sssp_hash: sssp_hash
    });
  }

  onFilterByParentSite(parent_site_id: number) {
    this.listDataSource.parent_site_id = parent_site_id;
    this.listDataSource.getData(true);
  }

  onResetList() {
    this.listDataSource.parent_site_id = null;
    this.listDataSource.getData(true);
  }

}

export class UserAccessibleSitesDataSource extends ApiDataSource {

  order_by = 'name';
  order = 'asc';

  // The parent site id to filter by.
  parent_site_id: number;

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/user/accessible-sites', resetOffset, {
      traverse_recursively: true,
      include_employer_sites: true,
      order: this.order,
      order_by: this.order_by,
      parent_site_id: this.parent_site_id
    });
  }
}
