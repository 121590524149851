<div mat-dialog-title>
  <h5>View Inspection/Audit Questions</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <h6>Question</h6>
  <p>{{ auditItem.name }}</p>

  <h6>Section</h6>
  <p>{{ auditItem.section }}</p>

  <span *ngIf="!auditItem.template && auditItem.status">
    <h6>Status</h6>
    <p>{{ auditItem.status }}</p>
  </span>

  <h6 *ngIf="auditItem.files?.length">Files</h6>
  <app-files-list [files]="auditItem.files" [view]="true"></app-files-list>

  <span *ngIf="auditItem.note">
    <h6>Note</h6>
    <p>{{ auditItem.note }}</p>
  </span>

  <span *ngIf="auditItem.action">
    <h6>Task Name</h6>
    <p>{{ auditItem.action.name }}</p>

    <h6>Task Priority</h6>
    <p>{{ auditItem.action.priority }}</p>

    <h6>Task Due</h6>
    <p>{{ auditItem.action.due }}</p>

    <h6>Task Assignees</h6>
    <ul>
      <li *ngFor="let user of auditItem.action.users">
        {{ user.contact_person }}
      </li>
    </ul>
  </span>
</mat-dialog-content>
