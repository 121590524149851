import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { ApiRequestService } from '../shared/api-request.service';
import { UtilsService } from '../shared/utils.service';
import { ApiDataSource } from '../utils/api-data-source';

@Injectable()
export class SafetyObservationsService {

  public dataSource: SafetyObservationsDataSource = new SafetyObservationsDataSource(this.app, this.api);

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService
  ) { }
}

export class SafetyObservationsDataSource extends ApiDataSource {
  // Default records ordering
  order_by = 'date_created';
  order = 'desc';

  site_id: number = 0; // Must be 0 to avoid text being sent to API e.g. "null" or "undefined".

  // Filtering options
  so_category: string = '';
  so_type: string = '';
  so_priority: string = '';
  so_status: string = '';
  so_rating: string = '';
  site_ids: number[] = [];
  contractor_ids = [];
  user_ids = [];
  reported_by_ids = [];

  // TBD: Date range filtering.
  date_filter: string = '';

  date_filter_range: Date[] = [];

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/safety-observations', resetOffset, {
      site_id: this.site_id ? this.site_id : 0,
      so_category: this.so_category ? this.so_category : '',
      so_type: this.so_type ? this.so_type : '',
      so_priority: this.so_priority ? this.so_priority : '',
      so_status: this.so_status ? this.so_status : '',
      so_rating: this.so_rating ? this.so_rating : '',
      site_ids: this.site_ids.length ? this.site_ids.join(',') : '',
      contractor_ids: this.contractor_ids.length ? this.contractor_ids.join(',') : '',
      user_ids: this.user_ids.length ? this.user_ids.join(',') : '',
      reported_by_ids: this.reported_by_ids.length ? this.reported_by_ids.join(',') : '',
      date_filter: this.date_filter,
      date_filter_range: this.date_filter_range && this.date_filter_range.length > 1 ? [
        this.date_filter_range[0].getTime() / 1000,
        this.date_filter_range[1].getTime() / 1000
      ].join(',') : ''
    });
  }

  exportData(exportType: string = 'csv'): Promise<any> {
    return this.makeDownloadRequest(`v2/safety-observations/export/${exportType}`, {
      site_id: this.site_id ? this.site_id : 0,
      so_category: this.so_category ? this.so_category : '',
      so_type: this.so_type ? this.so_type : '',
      so_priority: this.so_priority ? this.so_priority : '',
      so_status: this.so_status ? this.so_status : '',
      so_rating: this.so_rating ? this.so_rating : '',
      site_ids: this.site_ids.length ? this.site_ids.join(',') : '',
      contractor_ids: this.contractor_ids.length ? this.contractor_ids.join(',') : '',
      user_ids: this.user_ids.length ? this.user_ids.join(',') : '',
      reported_by_ids: this.reported_by_ids.length ? this.reported_by_ids.join(',') : '',
      date_filter: this.date_filter,
      date_filter_range: this.date_filter_range && this.date_filter_range.length > 1 ? [
        this.date_filter_range[0].getTime() / 1000,
        this.date_filter_range[1].getTime() / 1000
      ].join(',') : '',
      ids: this.selection.selected.join(',') // To export selected records.
    });
  }

  exportDataPDF(){
    return this.api.makeRequestObservable(
      'get',
      'v2/safety-observations/export/pdf', {}, {
        site_id: this.site_id ? this.site_id : 0,
        so_category: this.so_category ? this.so_category : '',
        so_type: this.so_type ? this.so_type : '',
        so_priority: this.so_priority ? this.so_priority : '',
        so_status: this.so_status ? this.so_status : '',
        so_rating: this.so_rating ? this.so_rating : '',
        site_ids: this.site_ids.length ? this.site_ids.join(',') : '',
        contractor_ids: this.contractor_ids.length ? this.contractor_ids.join(',') : '',
        user_ids: this.user_ids.length ? this.user_ids.join(',') : '',
        reported_by_ids: this.reported_by_ids.length ? this.reported_by_ids.join(',') : '',
        date_filter: this.date_filter,
        date_filter_range: this.date_filter_range && this.date_filter_range.length > 1 ? [
          this.date_filter_range[0].getTime() / 1000,
          this.date_filter_range[1].getTime() / 1000
        ].join(',') : '',
        ids: this.selection.selected.join(',') // To export selected records.
      });

  }
}
