import { AccountModel } from './account.model';
import { Auditable } from '../shared/auditable.model';

export class PrequalificationModel extends Auditable {
  id: number;
  name: string;
  contractor: AccountModel;

  /**
   * Used for filtering and associating with a Contractor.
   */
  contractor_id: number;
  template: boolean;
  items: any[] = [];

  /**
   * How many items have been approved as a percentage.
   */
  percent_completed: number;
  active: boolean;

  /**
   * Average rating of Item Pivots.
   */
  average_rating: number;
  default: boolean;

  /**
   * Unique hash used for unauthenticated access.
   */
  hash: string;
  complete: boolean;

  /**
   * The H&S score in percentage from the API.
   */
  score: number;

  /**
   * Should notifications be sent when this pre-qualification is assigned to a
   * contractor?
   *
   * Added an initialised value.
   */
  enable_notifications: boolean = false;

  /**
   * The date the pre-qualification expires at.
   */
  expires_at: number;

  /**
   * The account the pre-qualification belongs to.
   */
  account: AccountModel;

  description: string = '';

  /**
   * The pre-qualification ID from which the new pre-qualification is created.
   */
  create_from_id = 0;

  createFrom(data: any) {
    this.create_from_id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.enable_notifications = data.enable_notifications;
    this.items = data.items;
  }

}
