import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { SiteModel } from 'src/app/models/site.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';

@Component({
  selector: 'app-user-accessible-sites-selector',
  templateUrl: './user-accessible-sites-selector.component.html',
  styleUrls: ['./user-accessible-sites-selector.component.scss']
})
export class UserAccessibleSitesSelectorComponent implements OnInit {

  listColumns: string[] = [
    'name',
    'location',
    'owner'
  ];
  dataSource = new UserAccessibleSitesSelectorDataSource(this.app, this.api, this.data['multiple'] || false);

  // the paginator and sorter
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private api: ApiRequestService,
    private app: AppService,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<UserAccessibleSitesSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      selected?: number[],
      multiple?: boolean,
      return_object?: boolean // Only works when multiple is false.
    }
  ) { }

  ngOnInit() {
    // Set a default selection of nothing if no selected values are passed through.
    if (typeof this.data['selected'] == 'undefined') {
      this.data['selected'] = [];
    }

    // Loop and select the values.
    for (let i = 0; i < this.data['selected'].length; i++) {
      if (this.data['selected'][i]) {
        this.dataSource.selection.select(this.data['selected'][i]);
      }
    }

    // Add the select column if multiple is enabled.
    if ( (typeof this.data['multiple'] != 'undefined' && this.data['multiple'] == true) || this.dataSource.multiple ) {
      this.listColumns.unshift('select');
    } else {
      // Alternatively add the actions column to allow single site selection.
      this.listColumns.push('actions');
    }

    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData();
    })).subscribe();
  }

  /**
   * Determine if the site id or object data should be send as a response.
   * @param site The site data to use.
   * @param event
   */
  onSelectSite(site: SiteModel, event: Event) {
    // Prevent the default events and propagation from triggering.
    event.preventDefault();
    event.stopPropagation();

    // Check if the site object should be retruned.
    if ( (typeof this.data['return_object'] != 'undefined' && this.data['return_object'] == true) ) {
      // Return the site object.
      this.dialogRef.close(site);
    } else {
      // Return the site object.
      this.dialogRef.close(site.id);
    }
  }

  onSelectSites() {
    // Return the selected ids.
    this.dialogRef.close(this.dataSource.selection.selected);
  }

  onFilterByParentSite(parent_site_id: number) {
    this.dataSource.parent_site_id = parent_site_id;
    this.dataSource.getData(true);
  }

  onResetList() {
    this.dataSource.parent_site_id = null;
    this.dataSource.getData(true);
  }
}

export class UserAccessibleSitesSelectorDataSource extends ApiDataSource {

  order_by = 'name';
  order = 'asc';

  // The parent site id to filter by.
  parent_site_id: number;

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/user/accessible-sites', resetOffset, {
      traverse_recursively: true,
      include_employer_sites: true,
      order: this.order,
      order_by: this.order_by,
      parent_site_id: this.parent_site_id
    });
  }
}
