<script src="sites-task-analyses-revised.component.ts"></script>
<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button mat-raised-button routerLink="/sites" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>

    <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
    </button>
  </nav>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">TA/JSA/SWMS</span>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">

        <button
          mat-raised-button
          color="primary"
          [matMenuTriggerFor]="linkMenu">
          <mat-icon>add</mat-icon>
          New
        </button>

        <mat-menu #linkMenu="matMenu">
          <button mat-menu-item (click)="onAdd()">
            Create New
          </button>
          <button mat-menu-item (click)="onSelectTemplates()">
            Select from Templates
          </button>
          <button mat-menu-item (click)="onCopyTemplates('all')">
            Copy all Templates
          </button>
        </mat-menu>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onExportSelected('pdf')">
            <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to PDF
          </button>
          <button mat-menu-item (click)="onExportSelected('csv')">
            <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
          </button>
          <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect and/or export select TA/JSA/SWMS records." style="min-width: 150px">
          <mat-icon>more_vert</mat-icon>
          Menu
        </button>
      </span>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onFilter()" matTooltip="Click this button to display filter options.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>

      <form
        class="form-inline my-2 my-lg-0"
        style="min-width: 325px"
        (ngSubmit)="dataSource.getData(true)">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search" />
        <button
          mat-raised-button
          color="secondary"
          type="submit"
          class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="id"
      matSortDirection="desc"
      matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="65px">
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          #
        </th>
        <td mat-cell *matCellDef="let ta_revised">
          {{ ta_revised.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
        </th>
        <td mat-cell *matCellDef="let element" class="text-wrap">
          <ng-container *ngIf="element.site">
            <ng-container *ngIf="element?.site?.parent_site">
              <small style="display: block;">
                <a [routerLink]="utils.prepareSiteRelatedLink(element?.site?.parent_site?.id, null, '/edit/details')">
                  {{ element?.site?.parent_site?.name || '' }}
                </a>
              </small>
            </ng-container>
            <mat-icon *ngIf="element?.site?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
            <a [routerLink]="utils.prepareSiteRelatedLink(element?.site?.id, element?.site?.parent_site?.id, '/edit/details')">
              {{ element?.site?.name || '' }}
            </a>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="version_no">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          Version
        </th>
        <td mat-cell *matCellDef="let ta_revised">
          {{ ta_revised.version_no ? ta_revised.version_no : 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="task_name">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Name
        </th>
        <td mat-cell *matCellDef="let ta_revised" class="no-padding text-wrap">
          <span>
            <strong>{{ ta_revised.task_name  | ellipsis: [50] }}</strong><br>
            <small>{{ ta_revised.task_description  | ellipsis: [100] }}</small>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="contractor">
        <th mat-header-cell *matHeaderCellDef>
          {{ utils.getLangTerm('contractors.singular', 'Contractor') }} Co
        </th>
        <td mat-cell *matCellDef="let ta_revised">
          {{ ta_revised.contractor ? ta_revised.contractor.name : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="approved">
        <th mat-header-cell *matHeaderCellDef>Approved<br> Status</th>
        <td mat-cell *matCellDef="let ta_revised">
          <span [class]='utils.getSiteTAStatusTextColor(app.user.id == ta_revised.approver_id && !ta_revised.approver_acknowledged ? "Required" : (ta_revised.approver_acknowledged ? "Approved" : "Pending"))'>
            {{ app.user.id == ta_revised.approver_id && !ta_revised.approver_acknowledged ? "Required" : (ta_revised.approver_acknowledged ? "Approved" : "Pending") }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="authorized">
        <th mat-header-cell *matHeaderCellDef>Authorized<br> Status</th>
        <td mat-cell *matCellDef="let ta_revised">
          <span [class]='utils.getSiteTAStatusTextColor(app.user.id == ta_revised.authorizer_id && !ta_revised.authorizer_acknowledged ? "Required" : (ta_revised.authorizer_acknowledged ? "Authorized" : "Pending"))'>
            {{ app.user.id == ta_revised.authorizer_id && !ta_revised.authorizer_acknowledged ? "Required" : (ta_revised.authorizer_acknowledged ? "Authorized" : "Pending") }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="supervisor_acknowledged">
        <th mat-header-cell *matHeaderCellDef>Supervisor<br> Acknowledged<br> Status</th>
        <td mat-cell *matCellDef="let ta_revised">
          <span [class]='utils.getSiteTAStatusTextColor((app.user.id == ta_revised.supervisor_id && !ta_revised.supervisor_acknowledged) ? "Required" : (ta_revised.supervisor_acknowledged ? "Acknowledged" : "Pending"))'>
            {{ (app.user.id == ta_revised.supervisor_id && !ta_revised.supervisor_acknowledged) ? "Required" : (ta_revised.supervisor_acknowledged ? "Acknowledged" : "Pending") }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="assignees_acknowledged">
        <th mat-header-cell *matHeaderCellDef>Assignees<br> Acknowledged<br> Status</th>
        <td mat-cell *matCellDef="let ta_revised">
          <span [class]='utils.getSiteTAStatusTextColor( utils.getSiteTAUserStatusRequired(ta_revised.users, app.user.id) ? "Required" : ( utils.getSiteTAUserStatus(ta_revised.users) ? "Acknowledged" : "Pending"))'>
            {{ utils.getSiteTAUserStatusRequired(ta_revised.users, app.user.id) ? "Required" : (utils.getSiteTAUserStatus(ta_revised.users) ? "Acknowledged" : "Pending") }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="created_by_user">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Created By
        </th>
        <td mat-cell *matCellDef="let ta_revised" class="no-padding">
          <span *ngIf="ta_revised?.created_by_user else showSystemCreator">
            <a (click)="onUserPublicView(ta_revised.created_by_user.hash)"
              matTooltip="View User Public Profile">
              {{ ta_revised.created_by_user.contact_person }}
            </a>
          </span>
          <ng-template #showSystemCreator>System</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Created At
        </th>
        <td mat-cell *matCellDef="let ta_revised" class="no-padding">
        <span>
          {{ (ta_revised.date_created * 1000) | timezoneConvertMedium }}
        </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Created At (UTC)
        </th>
        <td mat-cell *matCellDef="let ta_revised" class="no-padding">
        <span>
          {{ (ta_revised.date_created * 1000) | timezoneConvertUTCMedium }}
        </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let ta_revised">
          <a type="button" mat-button [routerLink]="utils.prepareSiteRelatedLink(ta_revised.site_id, ta_revised?.site?.parent_site_id, 'edit/task-analyses-revised/' + ta_revised.id + '/view')" matTooltip="View the TA/JSA/SWMS information." matTooltipPosition="above">
            View
          </a>
          <a *ngIf="!ta_revised.contractor" type="button" mat-button [routerLink]="utils.prepareSiteRelatedLink(ta_revised.site_id, ta_revised?.site?.parent_site_id, 'edit/task-analyses-revised/' + ta_revised.id + '/edit')" matTooltip="Edit the TA/JSA/SWMS information." matTooltipPosition="above">
            Edit
          </a>
          <button *ngIf="!ta_revised.contractor" type="button" mat-button (click)="onClone(ta_revised)" matTooltip="Clone the TA/JSA/SWMS information." matTooltipPosition="above">
            Clone
          </button>
          <button *ngIf="!ta_revised.contractor" type="button" mat-button (click)="onRemove(ta_revised)" matTooltip="Remove (Archive) the TA/JSA/SWMS information." matTooltipPosition="above">
            Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
