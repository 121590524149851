import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContractorsDocsComponent } from './contractors-docs/contractors-docs.component';
import { ContractorsEmployeesComponent } from './contractors-employees/contractors-employees.component';
import { ContractorsPrequalificationsEditComponent } from './contractors-prequalifications/contractors-prequalifications-edit/contractors-prequalifications-edit.component';
import { ContractorsPrequalificationsComponent } from './contractors-prequalifications/contractors-prequalifications.component';
import { ContractorsSitesComponent } from './contractors-sites/contractors-sites.component';
import { ContractorsTabsComponent } from './contractors-tabs/contractors-tabs.component';
import { ContractorsComponent } from './contractors/contractors.component';
import { ContractorsItemsViewComponent } from './contractors-items/contractors-items-view/contractors-items-view.component';
import { AuthGuard } from '../guards/auth.guard';
import { ContractorsRelatedAccountsComponent } from './contractors-related-accounts/contractors-related-accounts.component';
import { ContractorsDetailsComponent } from './contractors-details/contractors-details.component';
import {ContractorsImporterComponent} from "./contractors-importer/contractors-importer.component";
import {ContractorsContractorsComponent} from "./contractors-contractors/contractors-contractors.component";

const routes: Routes = [
  {
    path: 'contractors',
    component: ContractorsComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'contractors/importer',
    component: ContractorsImporterComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'contractors/:contractor_id',
    component: ContractorsTabsComponent,
    canActivate: [
      AuthGuard
    ],
    children: [
      {
        pathMatch: 'full', path: '', redirectTo: 'details'
      },
      { path: 'details', component: ContractorsDetailsComponent },
      { path: 'contractors', component: ContractorsContractorsComponent },
      { path: 'employees', component: ContractorsEmployeesComponent },
      { path: 'related-accounts', component: ContractorsRelatedAccountsComponent },
      { path: 'sites', component: ContractorsSitesComponent },
      { path: 'docs', component: ContractorsDocsComponent },
      {
        path: 'prequalifications',
        component: ContractorsPrequalificationsComponent
      },
      {
        path: 'prequalifications/:prequal_id/edit',
        component: ContractorsPrequalificationsEditComponent
      },
      {
        path: 'prequalifications/items/:pivot_id/view',
        component: ContractorsItemsViewComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContractorsRoutingModule {}
