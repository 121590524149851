import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import {FormsModule} from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { IncidentsContainerComponent } from './incidents-container/incidents-container.component';
import { IncidentsEditComponent } from './incidents-edit/incidents-edit.component';
import { IncidentsRoutingModule } from './incidents-routing.module';
import { IncidentsViewComponent } from './incidents-view/incidents-view.component';
import { IncidentsComponent } from './incidents/incidents.component';
import { FilesModule } from '../files/files.module';
import { IncidentsTabsComponent } from './incidents-tabs/incidents-tabs.component';
import { IncidentsInvestigationComponent } from './incidents-investigation/incidents-investigation.component';
import { SitesModule } from '../sites/sites.module';
import { IncidentsCausesComponent } from './incidents-causes/incidents-causes.component';
import { IncidentsCausesEditComponent } from './incidents-causes/incidents-causes-edit/incidents-causes-edit.component';
import { IncidentsWitnessesComponent } from './incidents-witnesses/incidents-witnesses.component';
import { IncidentsNotesComponent } from './incidents-notes/incidents-notes.component';
import { IncidentNotesEditComponent } from './incident-notes/incident-notes-edit/incident-notes-edit.component';
import { IncidentsDetailsComponent } from './incidents-details/incidents-details.component';
import { IncidentsWitnessesEditComponent } from './incidents-witnesses-edit/incidents-witnesses-edit.component';
import { NetworkedAccountsSelectorComponent } from '../shared/networked-accounts-selector/networked-accounts-selector.component';
import { SharedModule } from '../shared/shared.module';
import { NetworkedUsersSelectorComponent } from '../shared/networked-users-selector/networked-users-selector.component';
import { IncidentsLtisComponent } from './incidents-ltis/incidents-ltis.component';
import { IncidentsLtisEditComponent } from './incidents-ltis-edit/incidents-ltis-edit.component';
import { IncidentsLtisTimeRecordsComponent } from './incidents-ltis-time-records/incidents-ltis-time-records.component';
import { IncidentsLtisTimeRecordsEditComponent } from './incidents-ltis-time-records-edit/incidents-ltis-time-records-edit.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IncidentsFilterComponent } from './incidents-filter/incidents-filter.component';
import { UtilsModule } from '../utils/utils.module';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {ChartsModule} from "../charts/charts.module";
import { IncidentsHazardsComponent } from './incidents-hazards/incidents-hazards.component';

@NgModule({
  declarations: [
    IncidentsComponent,
    IncidentsContainerComponent,
    IncidentsEditComponent,
    IncidentsViewComponent,
    IncidentsTabsComponent,
    IncidentsInvestigationComponent,
    IncidentsCausesComponent,
    IncidentsCausesEditComponent,
    IncidentsWitnessesComponent,
    IncidentsNotesComponent,
    IncidentNotesEditComponent,
    IncidentsDetailsComponent,
    IncidentsWitnessesEditComponent,
    IncidentsLtisComponent,
    IncidentsLtisEditComponent,
    IncidentsLtisTimeRecordsComponent,
    IncidentsLtisTimeRecordsEditComponent,
    IncidentsFilterComponent,
    IncidentsHazardsComponent
  ],
  imports: [
    CommonModule,
    IncidentsRoutingModule,
    FormsModule,
    PipesModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatListModule,
    MatTabsModule,
    MatDialogModule,
    MatSelectModule,
    MatBadgeModule,
    MatButtonModule,
    DirectivesModule,
    FilesModule,
    MatTooltipModule,
    SitesModule,
    SharedModule,
    MatToolbarModule,
    MatCardModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    BsDatepickerModule,
    UtilsModule,
    FontAwesomeModule,
    ChartsModule
  ],
  entryComponents: [
    SitesSelectorComponent,
    IncidentsCausesEditComponent,
    IncidentNotesEditComponent,
    IncidentsWitnessesEditComponent,
    NetworkedAccountsSelectorComponent,
    NetworkedUsersSelectorComponent,
    IncidentsLtisEditComponent,
    IncidentsLtisTimeRecordsEditComponent,
    IncidentsFilterComponent,
    IncidentsTabsComponent,
    IncidentsEditComponent
  ],
  providers: [
    {
      provide: NGX_MAT_DATE_FORMATS, useValue: {
        display: {
          dateInput: 'DD/MM/YYYY HH:mm A',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    }
  ]
})
export class IncidentsModule {}
