import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {PpeSelectorComponent} from "../../shared/ppe-selector/ppe-selector.component";
import {WorkPermitsSelectorComponent} from "../../shared/work-permits-selector/work-permits-selector.component";
import {ToolsSelectorComponent} from "../../shared/tools-selector/tools-selector.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {NetworkedUsersSelectorComponent} from "../../shared/networked-users-selector/networked-users-selector.component";
import {AppService} from "../../app.service";
import {NetworkedEmployeesSelectorComponent} from "../../shared/networked-employees-selector/networked-employees-selector.component";
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import {ContractorsSelectorComponent} from "../../contractors/contractors-selector/contractors-selector.component";
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";

@Component({
  selector: 'app-sites-task-analyses-revised-filter',
  templateUrl: './sites-task-analyses-revised-filter.component.html',
  styleUrls: ['./sites-task-analyses-revised-filter.component.scss']
})
export class SitesTaskAnalysesRevisedFilterComponent implements OnInit {

  filters: {
    approver_ids: number[],
    authorizer_ids: number[],
    supervisor_ids: number[],
    user_ids: number[],
    ppe_ids: number[],
    work_permit_ids: number[],
    tool_ids: number[],
    contractor_ids: number[],
    approver_status: string,
    authorizer_status: string,
    supervisor_status: string,
    assignees_status: string,
    date_range: Date[],
    site_ids: number[],
    parent_site_id: number
  } = {
    approver_ids: [],
    authorizer_ids: [],
    supervisor_ids: [],
    user_ids: [],
    ppe_ids: [],
    work_permit_ids: [],
    tool_ids: [],
    contractor_ids: [],
    approver_status: 'all',
    authorizer_status: 'all',
    supervisor_status: 'all',
    assignees_status: 'all',
    date_range: [],
    site_ids: [],
    parent_site_id: null
  };

  faHardHat = faHardHat;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SitesTaskAnalysesRevisedFilterComponent>
  ) { }

  ngOnInit() {
    this.filters.approver_ids = this.data['approver_ids'] || [];
    this.filters.authorizer_ids = this.data['authorizer_ids'] || [];
    this.filters.supervisor_ids = this.data['supervisor_ids'] || [];
    this.filters.user_ids = this.data['user_ids'] || [];
    this.filters.ppe_ids = this.data['ppe_ids'] || [];
    this.filters.work_permit_ids = this.data['work_permit_ids'] || [];
    this.filters.tool_ids = this.data['tool_ids'] || [];
    this.filters.contractor_ids = this.data['contractor_ids'] || [];
    this.filters.approver_status = this.data['approver_status'] || 'all';
    this.filters.authorizer_status = this.data['authorizer_status'] || 'all';
    this.filters.supervisor_status = this.data['supervisor_status'] || 'all';
    this.filters.assignees_status = this.data['assignees_status'] || 'all';
    this.filters.date_range = this.data['date_range'] || [];
    this.filters.site_ids = this.data['site_ids'] || [];
    this.filters.parent_site_id = this.data['parent_site_id'] || null;
  }

  getOutputDateRange($event) {
    this.filters.date_range = $event;
  }

  onSelectApprovers() {
    this.utils.showComponentDialog(
      NetworkedEmployeesSelectorComponent, {
      selected: this.filters.approver_ids,
      multiple: true,
      selectedAccountId: this.app.account.id
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.filters.approver_ids = results;
    });
  }

  onSelectAuthorizers() {
    this.utils.showComponentDialog(NetworkedEmployeesSelectorComponent, {
      selected: this.filters.authorizer_ids,
      multiple: true,
      selectedAccountId: this.app.account.id
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.filters.authorizer_ids = results;
    });
  }

  onSelectSupervisors() {
    this.utils.showComponentDialog(NetworkedEmployeesSelectorComponent, {
      selected: this.filters.supervisor_ids,
      multiple: true,
      selectedAccountId: this.app.account.id
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.filters.supervisor_ids = results;
    });
  }

  onSelectUsers() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.filters.user_ids,
      multiple: true,
      selectedAccountId: this.app.account.id,
      visitors_from_all_sites: true
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.filters.user_ids = results;
    });
  }

  onSelectContractors() {
    this.utils.showComponentDialog(ContractorsSelectorComponent, {
      selected: this.filters.contractor_ids,
      multiple: true
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.filters.contractor_ids = results;
    });
  }

  onSelectPPE() {
    this.utils.showComponentDialog(
      PpeSelectorComponent,
      {
        selected: this.filters.ppe_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results != 'undefined') {
          this.filters.ppe_ids = results;
        }
      }
    );
  }

  onSelectWorkPermits() {
    this.utils.showComponentDialog(
      WorkPermitsSelectorComponent,
      {
        selected: this.filters.work_permit_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results != 'undefined') {
          this.filters.work_permit_ids = results;
        }
      }
    );
  }

  onSelectTools() {
    this.utils.showComponentDialog(
      ToolsSelectorComponent,
      {
        selected: this.filters.tool_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results != 'undefined') {
          this.filters.tool_ids = results;
        }
      }
    );
  }

  /**
   * Open the site selector.
   */
  onSelectSites() {
    this.utils.showComponentDialog(SitesSelectorComponent, {
        parent_site_id: this.filters.parent_site_id,
        selected: this.filters.site_ids,
        multiple: true
      }, {
        width: '1024px'
      }
    )
      .then((results) => {
        if (typeof results !== 'undefined') {
          this.filters.site_ids = results;
        }
      });
  }

  onClearAndClose() {
    this.filters.approver_ids = [];
    this.filters.authorizer_ids = [];
    this.filters.supervisor_ids = [];
    this.filters.user_ids = [];
    this.filters.ppe_ids = [];
    this.filters.work_permit_ids = [];
    this.filters.tool_ids = [];
    this.filters.contractor_ids = [];
    this.filters.approver_status = 'all';
    this.filters.authorizer_status = 'all';
    this.filters.supervisor_status = 'all';
    this.filters.assignees_status = 'all';
    this.filters.date_range = [];
    this.filters.site_ids = [];
    this.filters.parent_site_id = null;

    this.onSaveAndClose();
  }

  onSaveAndClose() {
    this.dialogRef.close(this.filters);
  }
}
