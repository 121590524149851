import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPadding]'
})
export class PaddingDirective {
  constructor(element: ElementRef) {
    element.nativeElement.style.padding = '16px';
  }
}
