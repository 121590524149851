<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" [routerLink]="['/dynamic-forms']">My Forms</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button type="button" mat-raised-button color="primary" id="add" (click)="onCreateFromTemplate()" appMarginRight>
          Create From Template
        </button>
      </span>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
        <button type="button" mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onOpenFilters()" matTooltip="Click this button to display filter options for this list.">
          <mat-icon>filter_list</mat-icon> Filter
        </button>

        <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search" aria-label="Search" id="search-input" matTooltip="Search for forms by title." />

        <button mat-raised-button color="secondary" appMarginRight id="search-button" matTooltip="Run the current search">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">

    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>

      <caption class="px-3">This is a list of all forms that are assigned to you.</caption>

      <!-- Selection Toggle -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef id="th-select">
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Toggle selection of all forms in the visible list.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let form">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(form.id) : null"
            [checked]="dataSource.selection.isSelected(form.id)" matTooltip="Toggle selection of this form.">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-id" matTooltip="Toggle sorting by form ID.">
          #
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.id }}
        </td>
      </ng-container>

      <!-- Title -->
      <ng-container matColumnDef="title">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-title" matTooltip="Toggle sorting by form title.">
          Title
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.title }}
        </td>
      </ng-container>

      <!-- From -->
      <ng-container matColumnDef="from">
        <th mat-header-cell *matHeaderCellDef id="th-from" matTooltip="The account that owns the form instance.">
          From
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form?.account?.name }}
        </td>
      </ng-container>

      <!-- Form Type -->
      <ng-container matColumnDef="form_type">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-form_type" matTooltip="Toggle sorting by form type.">
          Form Type
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.form_type | titlecase }}
        </td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="dynamic_form_status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-dynamic_form_status" matTooltip="Toggle sorting by the status of the form.">
          Status
        </th>
        <td mat-cell *matCellDef="let form">
          <span *ngIf="form?.pivot?.dynamic_form_status" [ngClass]="getFormSubmissionStatusColors(form)">
            {{ form.pivot.dynamic_form_status }}
          </span>
        </td>
      </ng-container>

      <!-- Date Created -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created" matTooltip="Toggle sorting by creation date.">
          Created On
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.pivot.date_created ? ((form.pivot.date_created * 1000) | timezoneConvertMedium) : '' }}
        </td>
      </ng-container>

      <!-- Date Modifeid -->
      <ng-container matColumnDef="date_modified">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_modified" matTooltip="Toggle sorting by the date the form was last updated on.">
          Last Updated On
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.pivot.date_modified ? ((form.pivot.date_modified * 1000) | timezoneConvertMedium) : '' }}
        </td>
      </ng-container>

      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef id="th-actions"></th>
        <td mat-cell *matCellDef="let form">
          <button type="button" mat-button (click)="onOpenForm(form.id)" matTooltip="Open the form.">
            Open Form
          </button>

          <button type="button" mat-button (click)="onExportToPDF(form.id)" *ngIf="['Assigned', 'In Progress'].indexOf(form?.pivot?.dynamic_form_status) == -1" matTooltip="Export the form to PDF.">
            Export to PDF
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
