import { Component, OnInit } from '@angular/core';
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiRequestService} from "../../shared/api-request.service";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";

@Component({
  selector: 'app-sites-task-analyses-revised-tabs',
  templateUrl: './sites-task-analyses-revised-tabs.component.html',
  styleUrls: ['./sites-task-analyses-revised-tabs.component.scss']
})
export class SitesTaskAnalysesRevisedTabsComponent implements OnInit {
  ta_revised: TaskAnalysesRevisedModel = {} as TaskAnalysesRevisedModel;
  path: string;
  urlPath: string;

  parent_id: number;
  child_id: number;
  site_id: number;

  // ta_revised_id: number;
  // ta_revised_name: string;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    private route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {

    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      // get the ta revised id from the parent route params
      this.ta_revised.id = Number(this.route.parent.snapshot.children[0].params.ta_revised_id);
      this.urlPath = this.route.parent.snapshot.children[0].url[2].path;

      // this.ta_revised_id = this.ta_revised.id;
      // this.ta_revised_name = this.ta_revised.task_name;

      // get the site id from the parent route params
      // this.site_id = Number(this.route.parent.snapshot.params['site_id']);

      // get sites path edit/view
      this.path = this.route.parent.snapshot.routeConfig.path;

      if ( !this.ta_revised.id ) {
        this.redirectBack();
        return;
      }

      this.find(this.ta_revised.id);
    }
  }

  private find(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {
      site_id: this.site_id
    })
      .then((response) => {
        this.ta_revised = response;

      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  public onRemove () {
    this.utils.showModal(
      'Remove TA/JSA/SWMS',
      `Are you sure you want to remove the "${this.ta_revised.task_name}" TA/JSA/SWMS?`,
      () => {
        this.remove([this.ta_revised.id]);
      }
    );
  }

  private remove(ids: number[]) {
    this.api.makeRequest('delete', `v2/task-analyses-revised/${ids.join(',')}`, [], {
      site_id: this.site_id
    })
      .then((response) => {
        this.utils.showToast('The TA/JSA/SWMS have been removed.');
        this.redirectBack()
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private redirectBack() {
    if(this.site_id && this.path) {
      this.router.navigate([`/sites/${this.site_id}/${this.path}/task-analyses-revised/`]);
    }
  }

  /**
   * Exports the list of task analysis into the specified format and sends a download request to the browser.
   * @param type csv, xls, xlsx, pdf.
   */
  onExportSelected(type: string = 'csv') {
    this.api.makeDownloadRequest(`v2/task-analyses-revised/export/${type}/${this.ta_revised.id}`, {}, {
      site_id: this.site_id
    })
      .then((response) => {
        // For IE and Edge browsers.
        if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // Get the current date object
        const date = new Date();

        // Create object url to handle file downloads
        const data = window.URL.createObjectURL(response);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = `ta-jsa-swms-${date.getFullYear()}${(date.getMonth()+1)}${date.getDate()}.${type}`;
        // Initiate the download
        downloadLink.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(function() {
          window.URL.revokeObjectURL(data);
        }, 300); // Minimum 300 miliseconds
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

}
