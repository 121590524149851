<div mat-dialog-title class="position-relative w-100">
  <h5>Filter TA/JSA/SWMS</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close this dialog." (click)="onSaveAndClose()" style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">

  <ng-container matColumnDef="sites">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectSites()"
        [matBadge]="'' + (filters.site_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>location_on</mat-icon> {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="approvers">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectApprovers()"
        [matBadge]="'' + (filters.approver_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>rate_review</mat-icon> Approver
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="authorizers">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectAuthorizers()"
        [matBadge]="'' + (filters.authorizer_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>rate_review</mat-icon> Authorizer
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="supervisors">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectSupervisors()"
        [matBadge]="'' + (filters.supervisor_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>person</mat-icon> Supervisor
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="users">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectUsers()"
        [matBadge]="'' + (filters.user_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>people</mat-icon> Assignees
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="ppe">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectPPE()"
        [matBadge]="'' + (filters.ppe_ids.length || 'All')"
        class="mx-3 mt-3"
        color="" >
        <mat-icon>headset</mat-icon> PPE
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="work_permits">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectWorkPermits()"
        [matBadge]="'' + (filters.work_permit_ids.length || 'All')"
        class="mx-3 mt-3"
        color="" >
        <mat-icon>assignment_ind</mat-icon> Work Permits
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="tools">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectTools()"
        [matBadge]="'' + (filters.tool_ids.length || 'All')"
        class="mx-3 mt-3"
        color="" >
        <mat-icon>build</mat-icon> Tools
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="approver_status">
    <div style="padding: 5px !important;">
      <mat-form-field style="width: 140px;" class="mx-3 mt-2">
        <mat-select [(ngModel)]="filters.approver_status"
                    placeholder="Approver Status"
                    name="status"
                    matTooltip="Toggle between Approver Statuses">
          <mat-option value="all" aria-selected="true">All</mat-option>
          <mat-option value="pending">Pending/Required</mat-option>
          <mat-option value="approved">Approved</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container matColumnDef="authorizer_status">
    <div style="padding: 5px !important;">
      <mat-form-field style="width: 140px;" class="mx-3 mt-2">
        <mat-select [(ngModel)]="filters.authorizer_status"
                    placeholder="Authorizer Status"
                    name="status"
                    matTooltip="Toggle between Authorizer Statuses">
          <mat-option value="all" aria-selected="true">All</mat-option>
          <mat-option value="pending">Pending/Required</mat-option>
          <mat-option value="authorized">Authorized</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container matColumnDef="supervisor_status">
    <div style="padding: 5px !important;">
      <mat-form-field style="width: 190px;" class="mx-3 mt-2">
        <mat-select [(ngModel)]="filters.supervisor_status"
                    placeholder="Supervisor Status"
                    name="status"
                    matTooltip="Toggle between Supervisor Statuses">
          <mat-option value="all" aria-selected="true">All</mat-option>
          <mat-option value="pending">Pending/Required</mat-option>
          <mat-option value="supervisor_acknowledged">Supervisor Acknowledged</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container matColumnDef="assignees_status">
    <div style="padding: 5px !important;">
      <mat-form-field style="width: 140px;" class="mx-3 mt-2">
        <mat-select [(ngModel)]="filters.assignees_status"
                    placeholder="Assignee(s) Status"
                    name="status"
                    matTooltip="Toggle between Assignee(s) Statuses">
          <mat-option value="all" aria-selected="true">All</mat-option>
          <mat-option value="pending">Pending/Required</mat-option>
          <mat-option value="acknowledged">Acknowledged</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <app-date-range-picker dateRangeTitle="Date Range" dateRangePlaceholder="Select Date Range" dateRangeTooltip="Filter by the date it was created." [dateRangeInput]="filters.date_range" (dateRangeOutput)="getOutputDateRange($event)"></app-date-range-picker>

</div>

<div class="d-flex justify-content-center my-2 py-2">
  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        class="float-right"
        color="primary"
        (click)="onSaveAndClose()" >
        <mat-icon>filter_list</mat-icon> Apply Filter
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        color="secondary"
        class="float-right"
        (click)="onClearAndClose()" >
        <mat-icon> clear_all</mat-icon> Clear Filter
      </button>
    </div>
  </ng-container>
</div>
