import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '../directives/directives.module';
import { RepoSearchComponent } from '../shared/repo-search/repo-search.component';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { HazardsContainerComponent } from './hazards-container/hazards-container.component';
import { HazardsEditComponent } from './hazards-edit/hazards-edit.component';
import { HazardsRoutingModule } from './hazards-routing.module';
import { HazardsSelectorComponent } from './hazards-selector/hazards-selector.component';
import { HazardsViewComponent } from './hazards-view/hazards-view.component';
import { HazardsComponent } from './hazards/hazards.component';
import { FilesModule } from '../files/files.module';
import { HazardsVerifyComponent } from './hazards-verify/hazards-verify.component';
import { HazardsFilterComponent } from './hazards-filter/hazards-filter.component';
import { PipesModule } from '../pipes/pipes.module';
import { HazardsImporterComponent } from './hazards-importer/hazards-importer.component';
import {SharedModule} from "../shared/shared.module";
import {ChartsModule} from "../charts/charts.module";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {NgxMatDatetimePickerModule} from "@angular-material-components/datetime-picker";
import { HazardsRisksEditComponent } from './hazards-risks-edit/hazards-risks-edit.component';
import {MatChipsModule} from "@angular/material/chips";
import { HazardsHierarchyOfControlsComponent } from './hazards-hierarchy-of-controls/hazards-hierarchy-of-controls.component';

@NgModule({
  declarations: [
    HazardsComponent,
    HazardsContainerComponent,
    HazardsEditComponent,
    HazardsSelectorComponent,
    HazardsViewComponent,
    HazardsVerifyComponent,
    HazardsFilterComponent,
    HazardsImporterComponent,
    HazardsRisksEditComponent,
    HazardsHierarchyOfControlsComponent
  ],
    imports: [
        CommonModule,
        HazardsRoutingModule,
        FormsModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatListModule,
        MatTabsModule,
        MatDialogModule,
        MatSelectModule,
        MatBadgeModule,
        MatButtonModule,
        MatTooltipModule,
        DirectivesModule,
        FilesModule,
        PipesModule,
        SharedModule,
        ChartsModule,
        MatDatepickerModule,
        NgxMatDatetimePickerModule,
        MatChipsModule,
    ],
  entryComponents: [
    SitesSelectorComponent,
    RepoSearchComponent,
    HazardsEditComponent,
    HazardsFilterComponent,
    HazardsRisksEditComponent,
    HazardsHierarchyOfControlsComponent
  ]
})
export class HazardsModule {}
