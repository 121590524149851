import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MultiAccountDashboardComponent } from './multi-account-dashboard.component';
import {AuthGuard} from "../../guards/auth.guard";

const routes: Routes = [
  {
    path: 'user/multi-account-dashboard',
    component: MultiAccountDashboardComponent,
    canActivate: [
      AuthGuard
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MultiAccountDashboardRoutingModule {}
