import {AfterContentInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserModel } from '../models/user.model';
import { SessionService } from '../shared/session.service';
import { UtilsService } from '../shared/utils.service';
import { StorageService } from '../shared/storage.service';
import { AppService } from '../app.service';
import { ApiRequestService } from '../shared/api-request.service';
import {IndustriesModel} from "../admin/industries/industries.model";
import {TradeModel} from "../admin/trades/trades.model";
import {fromEvent, Observable} from "rxjs";
import {debounceTime, map, startWith} from "rxjs/operators";
import {switchMap} from "rxjs-compat/operator/switchMap";

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, AfterContentInit {

  user: UserModel = new UserModel();
  tos: boolean;

  inProgress: boolean = false;

  industries: IndustriesModel[] = [];

  trades: TradeModel[] = [];

  passwordValidated: boolean = false;
  passwordFieldType: string = 'password';

  autoCompletedEntityNames: string[] = [];

  // Mobile field Country Code selector values
  mobile_input_id: string = 'phone-1';
  mobile_number: string = '';
  mobile_country_code: string = '';
  mobile_dial_code: string = '';
  mobile_e164: string = '';
  mobile_error_state: boolean = true;

  constructor(
    public router: Router,
    public sessionService: SessionService,
    public utils: UtilsService,
    private storage: StorageService,
    private app: AppService,
    private api: ApiRequestService
  ) {}

  ngOnInit() {
    // Get industries from the API.
    this.getIndustries();

    // Get trades from the API.
    this.getTrades();
  }

  // Debounce timer and delay for the entity name autocomplete.
  debounceTimer: any;
  debounceDelay: number = 300;

  /**
   * Gets the entity names from the API for the autocomplete.
   * @param search The search string.
   */
  getEntityNames(search: string): void {
    // Check if the search string is less than 3 characters.
    if ( search.length < 3 ) {
      return;
    }
    // Check if the debounce timer is set.
    if ( this.debounceTimer ) {
      // Clear the debounce timer.
      clearTimeout(this.debounceTimer);
    }
    // Set the debounce timer.
    this.debounceTimer = setTimeout(() => {
      // Make an API request to get the entity names.
      this.api.makeRequest('get', 'v2/global-account-search', {}, {
        search: search,
        order_by: 'name',
        order_direction: 'asc'
      }).then((response) => {
        // Clear the current list of accounts and store the new ones.
        this.autoCompletedEntityNames.length = 0;
        this.autoCompletedEntityNames.push(...response.data.map((record: { name: any; }) => record.name));
      });
    }, this.debounceDelay);
  }

  ngAfterContentInit() {
    if (this.sessionService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }

  private async getIndustries() {
    this.industries = await this.utils.getIndustries();
  }

  private async getTrades() {
    this.trades = await this.utils.getTrades();
  }

  /**
   * Get Output values from phone input component.
   */
  getOutputPhoneDetails($event) {
    if($event) {
      let phone = $event;
      this.mobile_number = phone.hasOwnProperty('number') && phone.number !== null ? phone.number : '';
      this.mobile_country_code = phone.hasOwnProperty('countryCode') && phone.countryCode !== null ? phone.countryCode : '';
      this.mobile_dial_code = phone.hasOwnProperty('dialCode') && phone.dialCode !== null ? phone.dialCode : '';
      this.mobile_e164 = phone.hasOwnProperty('e164Number') && phone.e164Number !== null ? phone.e164Number : '';
      this.mobile_error_state = phone.hasOwnProperty('errorState') && phone.errorState !== null ? phone.errorState : true;
    }
  }

  /**
   * Submits the registration form to the API for user registration.
   * @param form
   */
  onSubmit(form: NgForm) {
    // Make sure all required input fields are filled in.
    if ( !form.valid ) {
      this.utils.showModal('Error Message', 'Please complete all required fields.');
      return;
    }

    // Check Error states of phone input then continue submission
    if (this.mobile_error_state) {
      if(this.mobile_number.length < 3) {
        this.utils.showModal('Error Message','A Phone number must be at least 3 characters long.');
      } else {
        this.utils.showModal('Error Message', 'Please complete all required fields.');
      }
      return;
    }

    // Terminate early if another process is in progress.
    if ( this.inProgress ) {
      return;
    }

    // Update User details with Mobile field Country Code selector values
    this.user.mobile = this.mobile_number;
    this.user.mobile_country_code = this.mobile_country_code;
    this.user.mobile_dial_code = this.mobile_dial_code;
    this.user.mobile_e164 = this.mobile_e164;

    // Change the in progress state to true.
    this.inProgress = true;
    // Make an API request to register the user.
    this.api.makeRequest('post', 'v2/register', this.user)
    .then((response) => {
      this.inProgress = false;
      // Sign the user in when the registration is successful.
      this.handleSignIn(response);
    })
    .catch((errors) => {
      this.inProgress = false;
      this.utils.handleAPIErrors(errors);
    });
  }

  /**
   * This is used to determine if we can sign the user in or not.
   * @param response The response from the API.
   */
  private handleSignIn(response) {
    // Check if the account and user response data is present.
    if ( !response.account || !response.user ) {
      this.utils.showToast('Your account or user profile could not be retrieved. Please try to sign in using your email address and password.');
      this.inProgress = false;
      // Redirect the user to the sign in page.
      this.router.navigate(['/sign-in']);
      return;
    }
    // Set the user as being authenticated.
    this.app.isAuthenticated = true;
    // Store the user's API token in the browser storage.
    this.storage.set('AuthToken', response.api_token);
    // Apply the account, user and user account link response data.
    this.app.user.apply(response.user);
    this.app.account.apply(response.account);
    this.app.userAccountLink.apply(response.user_account_link);
    // Set the in progress state to false.
    this.inProgress = false;
    // Redirect the user to the dashboard.
    this.router.navigate(['/dashboard']);
  }

  getOutputPasswordValidated($event) {
    this.passwordValidated = $event;
  }

  /**
   * Toggles the visibility of the password field.
   *
   * @returns {void}
   */
  togglePasswordVisibility(): void {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }
}
