import { CurrentTimezoneStateService } from './../../shared/current-timezone-state.service';
import { Location } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from 'src/app/shared/utils.service';
import { ActionModel } from '../action.model';
import { ActionsService } from '../actions.service';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { FilesRemoveComponent } from 'src/app/files/files-remove/files-remove.component';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import * as moment from 'moment';

@Component({
  selector: 'app-actions-edit',
  templateUrl: './actions-edit.component.html',
  styleUrls: ['./actions-edit.component.scss']
})
export class ActionsEditComponent implements OnInit {
  priorities = ['Low', 'Medium', 'High', 'Critical'];
  now = new Date();
  newFiles: FileList;
  inProgress = false;

  selectedTimezone: string = this.cTmzState.getCurrentTimezone();
  selectedDate: moment.Moment;

  constructor(
    private app: AppService,
    public location: Location,
    public utils: UtilsService,
    private actionsService: ActionsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public action: ActionModel,
    private dialogRef: MatDialogRef<ActionsEditComponent>,
    private api: ApiRequestService,
    private cTmzState: CurrentTimezoneStateService
  ) {}

  ngOnInit() {
    if ( !this.action.id ) { return (this.action.priority = 'Medium'); }
    this.find();
  }

  getOutputTimezone($event) {
    this.selectedTimezone = $event;
  }

  onDateChange(value) {
    this.selectedDate = value;
  }

  onSubmit() {

    if (this.action.user_ids == null || !this.action.user_ids.length) {
      this.utils.showModal(
        'Assignees Required',
        'Please add at least one ' + this.utils.getLangTerm('employees.singular', 'Employee') + ' as an assignee'
      );
      return;
    }

    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;

    // Check if a due date was selected before attempting to use formatting functions.
    if ( this.selectedDate ) {
      const dateToSave = moment.tz(this.selectedDate.format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a', this.selectedTimezone);
      const dateString = dateToSave.format('YYYY-MM-DD');
      this.action.due = dateString;
    }

    if ( this.action.id ) {
      this.update();
    } else {
      this.create();
    }
  }

  private find() {
    this.actionsService.find(this.action.id).subscribe((result) => {
      this.action = result.data;
      this.action.user_ids = this.action.users.map((user) => user.id);
    });
  }

  onRemoveFiles() {
    const dialogRef = this.dialog.open(FilesRemoveComponent, {
      data: this.action.files,
      width: '700px'
    });
    dialogRef.afterClosed().subscribe(() => this.find());
  }

  selectAssignee() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        selected: this.action.user_ids,
        multiple: true,
        selectedAccountId: this.app.account.id,
        visitors_from_all_sites: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.action.user_ids = results;
      }
    );
  }

  onSelectSite() {
    const dialogRef = this.dialog.open(SitesSelectorComponent, {
      data: { multiple: false, selected: [this.action.site_id] }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result !== 'number') { return; }
      this.action.site_id = result;
    });
  }

  delete() {
    this.utils.showModal(
      'Remove Actions',
      `Are you sure you want to remove "${this.action.name}"?`,
      () => {
        this.actionsService.remove([this.action.id]).subscribe(() => {
          this.utils.showToast(`"${this.action.name}" has been deleted.`);
          this.dialogRef.close(true);
        });
      }
    );
  }

  private update() {
    this.api
      .makeRequest('put', `v1/actions/${this.action.id}`, this.action)
      .then((response: { data: ActionModel }) => {
        this.onSuccess(`Updated: "${response.data.name}"`);

        if (!this.newFiles || !this.newFiles.length) {
          return;
        }
        this.actionsService
          .storeFiles(this.newFiles, response.data.id)
          .subscribe({
            complete: () => {
              this.newFiles = null;
              this.find();
            }
          });
      })
      .finally(() => (this.inProgress = false));
  }

  private create() {
    this.api
      .makeRequest('post', 'v1/actions', this.action)
      .then((response: { data: ActionModel; meta: {total: number} }) => {
        this.onSuccess(`Created ${response.meta.total} Task(s)`);

        if (!this.newFiles || !this.newFiles.length) {
          return;
        }
        this.actionsService
          .storeFiles(this.newFiles, response.data.id)
          .subscribe(() => {});
      })
      .finally(() => this.inProgress = false);
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close(true);
  }
}
