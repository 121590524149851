import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UserModel } from '../models/user.model';
import { Observable, of } from 'rxjs';
import { ApiResponseModel } from '../models/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly resourceUrl = 'user';

  constructor(private api: ApiService) {}

  // Remove this at a later date.
  changePassword(user: UserModel): Observable<{}> {
    return this.api.laravelApiObservable('patch', 'user/change-password', user);
  }

  find(id: number): Observable<ApiResponseModel<UserModel>> {
    return this.api.laravelApiObservable('get', this.resourceUrl + `/${id}`);
  }
}
