import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SsspRoutingModule } from './sssp-routing.module';
import { SsspConfigComponent } from './sssp-config/sssp-config.component';
import { SsspListComponent } from './sssp-list/sssp-list.component';
import { MatComponentsModule } from '../mat-components/mat-components.module';
import { SsspDocComponent } from './sssp-doc/sssp-doc.component';
import { SsspDocDialogComponent } from './sssp-doc/sssp-doc-dialog.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { SsspSnapshotsComponent } from './sssp-snapshots/sssp-snapshots.component';
import { SsspDocsComponent } from './sssp-docs/sssp-docs.component';
import { SsspTabsComponent } from './sssp-tabs/sssp-tabs.component';
import { SsspTabsDialogComponent } from './sssp-tabs/sssp-tabs-dialog.component';
import { SsspFilterComponent } from './sssp-filter/sssp-filter.component';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { UtilsModule } from '../utils/utils.module';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  entryComponents: [
    SsspTabsDialogComponent,
    SsspDocDialogComponent,
    SsspFilterComponent
  ],
  declarations: [
    SsspConfigComponent,
    SsspListComponent,
    SsspDocComponent,
    SsspDocDialogComponent,
    SsspSnapshotsComponent,
    SsspDocsComponent,
    SsspTabsComponent,
    SsspTabsDialogComponent,
    SsspFilterComponent
  ],
  imports: [
    CommonModule,
    SsspRoutingModule,
    MatComponentsModule,
    FormsModule,
    SharedModule,
    UtilsModule,
    FontAwesomeModule,
    PipesModule
  ],
  exports: [
    SsspTabsComponent
  ]
})
export class SsspModule { }
