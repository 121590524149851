<mat-sidenav-container *ngIf="!employeeService.isFirstLoad; else isFirstLoad">
  <mat-sidenav position="end" [opened]="true" [disableClose]="true" mode="side">

    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ employeeService.employee.contact_person }}</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <p> <b>Role:</b> {{ employeeService.employee.pivot.permissions }} </p>
        <p matTooltip="The username is used for logging into an account and performing password resets."> <b>Username:</b> {{ employeeService.employee.username }} </p>
        <p>
          <b>Date Enrolled:</b> {{ (employeeService.employee.date_created * 1000) | timezoneConvertMedium }} <br>
          <b>Date Enrolled (UTC):</b> {{ (employeeService.employee.date_created * 1000) | timezoneConvertUTCMedium }}
        </p>
        <p>
          <b>Last Updated:</b> {{ (employeeService.employee.date_modified * 1000) | timezoneConvertMedium }} <br>
          <b>Last Updated (UTC):</b> {{ (employeeService.employee.date_modified * 1000) | timezoneConvertUTCMedium }}
        </p>
        <p>
          <mat-icon matTooltip="Connected to the {{ utils.getAppName() }} mobile app{{ employeeService.employee.version ? (', using version ' + employeeService.employee.version) : ''}}." *ngIf="employeeService.employee.device_id">
            phonelink_setup
          </mat-icon>
        </p>
      </mat-card-content>

      <mat-card-actions>
        <button mat-button (click)="onSwitchRole()">
          <mat-icon>swap_vert</mat-icon>
          Switch role to {{ employeeService.employee.pivot.permissions == 'Administrator' ? 'Employee' : 'Administrator' }}
        </button>

        <button mat-button (click)="composeNewMessage(employeeService.employee.id)" matTooltip="Click this button to send a message to this {{ utils.getLangTerm('employees.singular', 'Employee') }}.">
          <mat-icon>send</mat-icon>
          Send Message
        </button>

        <button mat-button (click)="onUnlockUser(employeeService.employee.id)" matTooltip="Click this button to unlock user at login.">
          <mat-icon>lock_open</mat-icon>
          Unlock user for login
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-list>
      <mat-list-item routerLink="details" routerLinkActive="active-submenu-item"> Details </mat-list-item>
      <mat-list-item routerLink="docs" routerLinkActive="active-submenu-item"> Training / Competencies </mat-list-item>
      <mat-list-item routerLink="linked-sites" routerLinkActive="active-submenu-item" *ngIf="app.userCan('manage_sites')"> Manage {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }} </mat-list-item>
      <mat-list-item routerLink="assigned-forms" routerLinkActive="active-submenu-item"> Assigned Forms </mat-list-item>
      <mat-list-item routerLink="notifications" routerLinkActive="active-submenu-item"> Notifications </mat-list-item>
      <mat-list-item routerLink="sites-visited" routerLinkActive="active-submenu-item"> {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }} Visited </mat-list-item>
      <mat-list-item routerLink="sites-worked-on" routerLinkActive="active-submenu-item"> {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }} Worked On </mat-list-item>
    </mat-list>
  </mat-sidenav>

  <section>
    <router-outlet></router-outlet>
  </section>
</mat-sidenav-container>

<ng-template #isFirstLoad>
  <div class="d-flex justify-content-center align-items-center full-height">
    <table>
      <tr>
        <td class="d-flex justify-content-center">
          <mat-progress-spinner [mode]="'indeterminate'" diameter="32"></mat-progress-spinner>
        </td>
      </tr>
      <tr>
        <td class="d-flex justify-content-center">
          <p>Please wait while we get the information...</p>
        </td>
      </tr>
    </table>
  </div>
</ng-template>
