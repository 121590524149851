import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HazardsContainerComponent } from './hazards-container/hazards-container.component';
import { HazardsEditComponent } from './hazards-edit/hazards-edit.component';
import { HazardsViewComponent } from './hazards-view/hazards-view.component';
import { HazardsComponent } from './hazards/hazards.component';
import { HazardsVerifyComponent } from './hazards-verify/hazards-verify.component';
import { AuthGuard } from '../guards/auth.guard';
import {SitesImporterComponent} from "../sites/sites-importer/sites-importer.component";
import {HazardsImporterComponent} from "./hazards-importer/hazards-importer.component";

const routes: Routes = [
  {
    path: 'hazards',
    component: HazardsComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'hazards/importer',
    component: HazardsImporterComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'hazards/:hazard_id',
    component: HazardsContainerComponent,
    canActivate: [
      AuthGuard
    ],
    children: [
      { path: 'edit', component: HazardsEditComponent },
      { path: 'view', component: HazardsViewComponent }
    ]
  },
  { path: 'hazards/:hash/verify', component: HazardsVerifyComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HazardsRoutingModule {}
