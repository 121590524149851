<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/employees">{{ utils.getLangTerm('employees.plural', 'Employees') }}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="primary" appMarginRight id="add" (click)="onAddEmployee()" matTooltip="Click this button to add a new {{ utils.getLangTerm('employees.singular', 'Employee') }}.">
          <mat-icon>add</mat-icon>
          Add {{ utils.getLangTerm('employees.singular', 'Employee') }}
        </button>

        <mat-menu #appImportMenu="matMenu">
          <button mat-menu-item routerLink="/employees/importer" matTooltip="Click this button to mass import {{ utils.getLangTerm('employees.plural', 'Employees') }} from a CSV file.">
            <mat-icon>cloud_upload</mat-icon> {{ utils.getLangTerm('employees.plural', 'Employees') }}
          </button>

          <!--button mat-menu-item routerLink="/employees/docs-importer" matTooltip="Click this button to mass import {{ utils.getLangTerm('employees.plural', 'Employees') }} Documents from a CSV file.">
            <mat-icon>cloud_upload</mat-icon> Documents
          </button-->
        </mat-menu>

        <button mat-raised-button [matMenuTriggerFor]="appImportMenu" matTooltip="Use this menu to do imports." style="min-width: 150px">
          <mat-icon>cloud_upload</mat-icon> Import
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="listDataSource.selectAllToggle()">
            <mat-icon *ngIf="listDataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!listDataSource.isAllSelected()">select_all</mat-icon>
            {{ listDataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>

          <button mat-menu-item (click)="onExport('csv')">
            <mat-icon>cloud_download</mat-icon> Export {{ listDataSource.selection.hasValue() ? '(' + listDataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
          </button>

          <button mat-menu-item (click)="onTrainingDocsExport('csv')">
            <mat-icon>cloud_download</mat-icon> Export {{ listDataSource.selection.hasValue() ? '(' + listDataSource.selection.selected.length + ') Selected' : 'All' }} Training/Competency Documents to CSV
          </button>

          <button mat-menu-item (click)="composeNewMessage()">
            <mat-icon>send</mat-icon>
            Send Message {{ listDataSource.selection.hasValue() ? 'to (' + listDataSource.selection.selected.length + ') Selected' : '(0)' }}
          </button>

          <button mat-menu-item (click)="onDeleteSelected()" [disabled]="!listDataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect and/or export {{ utils.getLangTerm('employees.plural', 'Employees') }}." style="min-width: 150px">
          <mat-icon>more_vert</mat-icon>
          List Menu/Export
        </button>

      </span>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="listDataSource.getData(true)" style="min-width: 325px">
        <input class="form-control mr-sm-2"
               type="search"
               [(ngModel)]="listDataSource.search"
               name="search"
               placeholder="Search {{ utils.getLangTerm('employees.plural', 'Employees') }}"
               aria-label="Search"
               id="search-input"
               matTooltip="Search for {{ utils.getLangTerm('employees.plural', 'Employees') }} by name, email address, mobile number or address." />

        <button mat-raised-button
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                color="secondary"
                (click)="listDataSource.getData(true)"
                id="search-button"
                matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon>
          Search
        </button>
      </form>

    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="listDataSource" matSort matSortActive="contact_person" matSortDirection="asc" matSortDisableClear>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? listDataSource.selectAllToggle() : null"
            [checked]="listDataSource.selection.hasValue() && listDataSource.isAllSelected()"
            [indeterminate]="listDataSource.selection.hasValue() && !listDataSource.isAllSelected()"
            matTooltip="Toggle selecting all {{ utils.getLangTerm('employees.plural', 'Employees') }} on this page">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? listDataSource.selection.toggle(row.id) : null"
            [checked]="listDataSource.selection.isSelected(row.id)"
            matTooltip="Toggle selecting '{{row.contact_person}}' for deletion'">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="icons">
        <th mat-header-cell *matHeaderCellDef>Status Icons</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon matTooltip="" *ngIf="element.courses_count > 0"
            [matTooltip]="element.courses_count_completed + '/' + element.courses_count + ' Inductions Completed.'"
            [ngStyle]="{'color': (element.courses_count_completed > 0 ? (element.courses_count_completed < element.courses_count ? 'orange' : 'green') : 'red')}">
            {{ element.courses_count_completed > 0 ? 'done_outline' : 'clear' }}
          </mat-icon>

          <mat-icon matTooltip="Connected to the {{ utils.getAppName() }} mobile app{{ element.version ? (', using version ' + element.version) : ''}}." *ngIf="element.device_id">
            phonelink_setup
          </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="contact_person">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by full name">
          Full Name
        </th>
        <td mat-cell *matCellDef="let element">
          <a (click)="onUserPublicView(element.hash)"
             matTooltip="View User Public Profile">
            {{ element.contact_person }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by email address">
          Email Address
        </th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by {{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}">
          {{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
      </ng-container>

      <ng-container matColumnDef="trade">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by job title">
          Job Title
        </th>
        <td mat-cell *matCellDef="let element">{{ element.trade }}</td>
      </ng-container>

      <ng-container matColumnDef="permissions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by role">
          Role
        </th>
        <td mat-cell *matCellDef="let element">{{ element.pivot.permissions }}</td>
      </ng-container>

      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>
          Notes
        </th>
        <td mat-cell *matCellDef="let element">{{ element.pivot?.notes }}</td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by role">
          Date Enrolled
        </th>
        <td mat-cell *matCellDef="let element">{{ (element.date_created * 1000) | timezoneConvertMedium }}</td>
      </ng-container>

      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by role">
          Date Enrolled(UTC)
        </th>
        <td mat-cell *matCellDef="let element">{{ (element.date_created * 1000) | timezoneConvertUTCMedium }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <a type="button" mat-button routerLink="/employees/{{ element.id }}/details" matTooltip="Update the details of your {{ utils.getLangTerm('employees.singular', 'Employee') }}." *ngIf="app.userAccountLink.permissions != 'Employee'">
            Edit
          </a>

          <button type="button" mat-button (click)="onDelete(element.id)" matTooltip="Delete your {{ utils.getLangTerm('employees.singular', 'Employee') }} record." *ngIf="app.userAccountLink.permissions != 'Employee'">
            Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
