import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserAccessibleSitesSelectorComponent } from '../user-accessible-sites-selector/user-accessible-sites-selector.component';
import { UpstreamAccountsSelectorComponent } from 'src/app/shared/upstream-accounts-selector/upstream-accounts-selector.component';

@Component({
  selector: 'app-user-dynamic-forms-filter',
  templateUrl: './user-dynamic-forms-filter.component.html',
  styleUrls: ['./user-dynamic-forms-filter.component.scss']
})
export class UserDynamicFormsFilterComponent implements OnInit {

  // Define the filter options.
  filter_options: {
    upstream_account_ids: number[],
    site_ids: number[],
    statuses: string[],
    form_types: string[]
  } = {
    upstream_account_ids: [],
    site_ids: [],
    statuses: [],
    form_types: []
  };

  constructor(
    private app: AppService,
    public location: Location,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserDynamicFormsFilterComponent>
  ) {

    // Set a default upstream account ids list of nothing if no values are passed through
    if (typeof this.data['upstream_account_ids'] == 'undefined') {
      this.data['upstream_account_ids'] = [];
    }

    // Set a default site ids list of nothing if no values are passed through
    if (typeof this.data['site_ids'] == 'undefined') {
      this.data['site_ids'] = [];
    }

    // Set a default list of statuses of nothing if no values are passed through
    if (typeof this.data['statuses'] == 'undefined') {
      this.data['statuses'] = [];
    }

    // Set a default list of form types of nothing if no values are passed through
    if (typeof this.data['form_types'] == 'undefined') {
      this.data['form_types'] = [];
    }
  }

  ngOnInit() {
    // The constructor makes sure the values are set and present.
    this.filter_options.upstream_account_ids = this.data['upstream_account_ids'];
    this.filter_options.site_ids = this.data['site_ids'];
    this.filter_options.statuses = this.data['statuses'];
    this.filter_options.form_types = this.data['form_types'];
  }

  /**
   * Open the accessible sites selector.
   */
  onSelectSites() {
    // Open the accessible sites selector.
    this.utils.showComponentDialog(UserAccessibleSitesSelectorComponent, {
      selected: this.filter_options.site_ids,
      multiple: true,
      return_object: false
    }).then((response: number[]) => {
      // Validate the selection response.
      if ( typeof response != 'undefined' ) {
        this.filter_options.site_ids = response;
      }
    });
  }

  /**
   * Open the accessible sites selector.
   */
  onSelectUpstreamAccounts() {
    // Open the accessible sites selector.
    this.utils.showComponentDialog(UpstreamAccountsSelectorComponent, {
      selected: this.filter_options.upstream_account_ids,
      multiple: true,
      return_object: false
    }).then((response: number[]) => {
      // Validate the selection response.
      if ( typeof response != 'undefined' ) {
        this.filter_options.upstream_account_ids = response;
      }
    });
  }

  /**
   * Clear the filter options and send the response back.
   */
  onClearAndClose() {
    // Clear all filter options.
    this.filter_options.upstream_account_ids = [];
    this.filter_options.site_ids = [];
    this.filter_options.statuses = [];
    this.filter_options.form_types = [];

    this.onSaveAndClose();
  }

  /**
   * Close the dialog and send the selected options as the response.
   */
  onSaveAndClose() {
    this.dialogRef.close(this.filter_options);
  }
}
