import { AfterViewInit, Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { HazardousSubstanceModel } from 'src/app/models/hazardous-substance.model';
import { ApiService } from 'src/app/shared/api.service';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { UtilsService } from '../../shared/utils.service';

@Component({
  selector: 'app-hazardous-substances-view',
  templateUrl: './hazardous-substances-view.component.html',
  styleUrls: ['./hazardous-substances-view.component.css']
})
export class HazardousSubstancesViewComponent implements OnInit, AfterViewInit {
  hazardous_substance: HazardousSubstanceModel = new HazardousSubstanceModel();

  // columns to show and the data source
  linkedSitesDisplayedColumns: string[] = ['name', 'location', 'quantity', 'max_quantity_allowed', 'hs_location'];
  linkedSitesDataSource = new HazardousSubstancesLinkedSitesDataSource(
    this.app,
    this.api
  );

  // the paginator and sorter
  @ViewChild(MatPaginator) linkedSitesPaginator: MatPaginator;
  @ViewChild(MatSort) linkedSitesSort: MatSort;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public utils: UtilsService,
    public app: AppService,
    private api: ApiService,
    public renderer: Renderer2
  ) {}

  ngOnInit() {
    // get the site id
    this.hazardous_substance.id = Number(
      this.route.snapshot.params['hazardous_substance_id']
    );

    // check if it's a valid id otherwise it's a new site being created.
    // NaN, NULL, 0, any sequence of letters will trigger a new site creation form.
    if (this.hazardous_substance.id) {
      this.linkedSitesDataSource.hazardous_substance_id = this.hazardous_substance.id;

      this.api.laravelApiRequest(
        'get',
        'hazardous_substances/' + this.hazardous_substance.id,
        {},
        {},
        (response) => {
          this.hazardous_substance.apply(response.data);
        },
        (error) => {
          this.utils.showModal('Error', error.message);
        }
      );

      this.linkedSitesDataSource.getData();
    } else {
      this.router.navigate(['hazards']);
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.linkedSitesSort.sortChange.subscribe(
      () => (this.linkedSitesPaginator.pageIndex = 0)
    );

    const _linkedSitesTap = tap(() => {
      this.linkedSitesDataSource.limit = this.linkedSitesPaginator.pageSize;
      this.linkedSitesDataSource.offset = this.linkedSitesPaginator.pageIndex;
      this.linkedSitesDataSource.sort_by = this.linkedSitesSort.active;
      this.linkedSitesDataSource.sort_order = this.linkedSitesSort.direction;

      this.linkedSitesDataSource.getData();
    });

    // subscribe to the paginator tap events
    this.linkedSitesPaginator.page.pipe(_linkedSitesTap).subscribe();
    this.linkedSitesSort.sortChange.pipe(_linkedSitesTap).subscribe();
  }

  onClearSearch() {
    this.linkedSitesDataSource.search = '';
    this.linkedSitesDataSource.getData(true);
  }

  onTabChanged(tabChangedEvent) {
    if (tabChangedEvent.tab.textLabel === 'Sites') {
      this.linkedSitesDataSource.getData(true);
    }
  }

  onRemove() {
    this.utils.showModal(
      'Remove Hazardous Substance',
      'Are you sure you want to remove this hazardous substance?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'hazardous_substances/' + this.hazardous_substance.id,
          {},
          {},
          (response) => {
            this.utils.showToast('The hazardous substance was removed');
            this.router.navigate(['hazardous_substances']);
          },
          (error) => {
            this.api.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  openDocument(url: string) {
    window.open(url, '_blank');
  }

  /**
   * Map the PPE to a simple array of titles and join them.
   * @param ppe
   */
  formattedPPE(ppe) {
    return ppe.map((ppe) => ppe.title).join(', ');
  }
}

export class HazardousSubstancesLinkedSitesDataSource extends CustomDataSource {
  // record sorting and direction
  sort_by = 'name';
  sort_order = 'asc';

  hazardous_substance_id = 0;

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      'hazardous_substances/' + this.hazardous_substance_id + '/linked-sites',
      resetOffset
    );
  }
}
