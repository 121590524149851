import { Component, OnInit, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { CourseModel } from '../../models/course.model';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-courses-edit',
  templateUrl: './courses-edit.component.html',
  styleUrls: ['./courses-edit.component.scss']
})
export class CoursesEditComponent implements OnInit {
  public induction: CourseModel;
  inProgress = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public utils: UtilsService,
    public app: AppService,
    private api: ApiService,
    private dialogRef: MatDialogRef<CoursesEditComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { course: CourseModel }
  ) {}

  ngOnInit() {
    this.induction = this.data.course;
  }

  onSubmit(form: NgForm) {
    if (this.inProgress) {
      return;
    }

    if (!form.valid) {
      this.utils.showModal(
        'Form Validation Error',
        'Please make sure you enter all fields with valid information.'
      );
      return;
    }

    if (this.induction.id) {
      if (this.induction.reset_status && this.induction.induction_enabled) {
        this.utils.showModal(
          'Resetting Induction Statuses',
          'Are you sure you want to reset the induction statuses? This will reset the induction status for all your staff, ' + this.utils.getLangTerm('contractors.plural', 'Contractors') + ' and their ' + this.utils.getLangTerm('employees.plural', 'Employees') + ' resulting in incomplete induction statuses for this induction. They will need to complete and submit this induction again.',
          () => {
            this.inProgress = true;
            this.update();
          }
        );
      } else {
        this.inProgress = true;
        this.update();
      }
    } else {
      this.inProgress = true;
      this.create();
    }
  }

  onRemove() {
    this.utils.showModal(
      'Remove Induction',
      'Are you sure you want to remove this induction?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          `inductions/${this.induction.id}`,
          {},
          {},
          () => {
            this.utils.showToast('The induction was removed.');
            this.dialogRef.close(true);
          }
        );
      }
    );
  }

  private update() {
    if(this.induction.reset_status && !this.induction.induction_enabled) {
      this.induction.reset_status = false; // If the reset status is enabled but the induction is disabled then this should be disabled when submitting the form
    }

    this.api.laravelApiRequest(
      'put',
      `inductions/${this.induction.id}`,
      this.induction,
      {},
      () => {
        this.utils.showToast('The induction was updated.');
        this.dialogRef.close(true);
        this.inProgress = false;
      },
      (_error: any) => {
        this.inProgress = false;
      }
    );
  }

  private create() {
    this.api.laravelApiRequest(
      'post',
      'inductions',
      this.induction,
      {},
      () => {
        this.utils.showToast(
          'New induction added. Please continue to add modules and questions.'
        );
        this.dialogRef.close(true);
        this.inProgress = false;
      },
      (_error: any) => (this.inProgress = false)
    );
  }
}
