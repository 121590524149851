<div mat-dialog-title class="position-relative w-100">
  <h5>Filter {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close Filter" mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">
  <ng-container matColumnDef="type">
    <div style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-2" style="width: 225px;">
        <mat-select class="site-filter"
          [(ngModel)]="dataSource.filter_type"
          matTooltip="Filter by Type"
          name="type">
          <mat-option value="">All Types</mat-option>
          <mat-option [value]="siteType" *ngFor="let siteType of utils.getSiteTypes()">{{siteType}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container matColumnDef="status">
    <div style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-2" style="width: 225px;">
          <mat-select class="site-filter" [(ngModel)]="dataSource.filter_status" matTooltip="Filter by Status" name="status">
            <mat-option value="">All Active Statuses</mat-option>
            <mat-option *ngFor="let siteStatus of utils.getSiteStatuses()" [value]="siteStatus">{{ siteStatus }}</mat-option>
          </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <app-date-range-picker dateRangeTitle="Date Range" dateRangePlaceholder="Select Date Range" dateRangeTooltip="Filter by Date Range." [dateRangeInput]="dataSource.date_filter_range" (dateRangeOutput)="getOutputDateRange($event)"></app-date-range-picker>

</div>

<div class="d-flex justify-content-center my-2 py-2">
  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        class="float-right"
        color="tertiary"
        (click)="onSelectAccounts()">
        {{selectedAccounts.length > 0 ? (selectedAccounts.length + ' Accounts Selected') : 'Select Accounts'}}
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        class="float-right"
        color="primary"
        (click)="onSaveAndClose()">
        <mat-icon>filter_list</mat-icon> Apply Filter
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        color="secondary"
        class="float-right"
        (click)="onClearAndClose()">
        <mat-icon> clear_all</mat-icon> Clear Filter
      </button>
    </div>
  </ng-container>
</div>
