<input #fileInput type="file" multiple (change)="changeFiles()" hidden />
<button
  mat-raised-button
  color="secondary"
  [matBadge]="(change | async)?.length"
  (click)="fileInput.click()"
  matTooltip="Pick files to be uploaded with the form.&#13;Allowed extensions: doc,pdf,docx,zip,jpg,jpeg,bmp,png,gif,xls,xlsx,eml,&#13;csv,txt,mp3,m4a&#13;Maximum file size allowed (24MB)"
  matTooltipClass="files-tooltip"
  type="button">
  <mat-icon>insert_drive_file</mat-icon>
  Add File
</button>
