import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { SsspModel } from 'src/app/models/sssp-model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { SsspSnapshotsComponent } from '../sssp-snapshots/sssp-snapshots.component';

@Component({
  selector: 'app-sssp-tabs',
  templateUrl: './sssp-tabs.component.html',
  styleUrls: ['./sssp-tabs.component.scss']
})
export class SsspTabsComponent implements OnInit {

  @Input('sssp_hash') sssp_hash: string;

  sssp: SsspModel = new SsspModel();

  public dialogRef: any; // Add this to prevent --aot build from failing due to non-existent ref.

  @ViewChild('ssspSnapshotsComponent') ssspSnapshotsComponent: SsspSnapshotsComponent;

  constructor(
    public app: AppService,
    public api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.getSSSP();
  }

  getSSSP() {
    return this.api.makeRequest('get', `v1/sssp/${this.sssp_hash}`)
    .then((response) => {
      this.sssp.apply(response);
    })
    .catch((errorResponse) => {
      this.utils.showModal('Error', errorResponse.error.message);
    });
  }

  onTabChanged(tabData) {
    if ( tabData.index == 2 ) {
      // Reload the list data in the snapshots tab.
      this.ssspSnapshotsComponent.listDataSource.getData();
    }
  }
}
