import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../shared/api.service';
import { AuditsModel } from './audits.model';
import { ApiResponseModel } from '../models/api-response.model';

type ResponseAudit = ApiResponseModel<AuditsModel>;
type ResponseAudits = ApiResponseModel<AuditsModel[]>;

@Injectable({
  providedIn: 'root'
})
export class AuditsService {
  constructor(private api: ApiService) {}

  public post(audit: AuditsModel): Observable<ResponseAudit> {
    audit.template = true;
    return this.api.laravelApiObservable('post', 'audits', audit);
  }

  public remove(ids: number[]): Observable<{}> {
    return this.api.laravelApiObservable('delete', `audits/${ids}`);
  }

  public find(id: number): Observable<ResponseAudit> {
    return this.api.laravelApiObservable('get', `audits/${id}`);
  }

  public get(template: boolean): Observable<ResponseAudits> {
    return this.api.laravelApiObservable('get', `audits?template=${template}`);
  }

  public put(audits: AuditsModel): Observable<{}> {
    return this.api.laravelApiObservable('put', `audits/${audits.id}`, audits);
  }

  public exportPdf(id: number): Promise<any> {
    return this.api
      .laravelApiDownloadObservable(`audits/export/${id}`)
      .toPromise();
  }

  public getContacts(id: number, page?: number) {
    return this.api.laravelApiObservable('get', `audits/contacts/${id}${page ? `?page=${page}` : ''}`);
  }
}
