import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserDetailsComponent } from './user-details/user-details.component';
import { AuthGuard } from '../guards/auth.guard';
import { UserMessagesComponent } from './user-messages/user-messages.component';
import { UserAccessibleSitesComponent } from './user-accessible-sites/user-accessible-sites.component';
import { UserDynamicFormsComponent } from './user-dynamic-forms/user-dynamic-forms.component';
import {UserTasksComponent} from "./user-tasks/user-tasks.component";

const routes: Routes = [
  {
    path: 'user',
    canActivate: [
      AuthGuard
    ],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/user/details'
      },
      {
        path: 'details', component: UserDetailsComponent
      },
      {
        path: 'messages', component: UserMessagesComponent
      },
      {
        path: 'tasks', component: UserTasksComponent
      },
      {
        path: 'dynamic-forms', component: UserDynamicFormsComponent
      },
      {
        path: 'accessible-sites', component: UserAccessibleSitesComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {}
