<div mat-dialog-title>
  <h5>Edit Prequalification</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <h6>Pre-Qualification Name</h6>
  <p id="name">{{ prequal.name }}</p>

  <ng-container *ngIf="prequal.description != ''">
    <h6>Pre-Qualification Description</h6>
    <p>{{ prequal.description }}</p>
  </ng-container>

  <h6>Client</h6>
  <p>{{ prequal.account?.name }}</p>

  <h6>Health and Safety Score</h6>
  <p>{{ prequal.score }}%</p>

  <app-my-items *ngIf="prequal?.items.length > 0" [items]="prequal.items" [edit]="true" (edited)="find()" [hash]="prequal.hash"></app-my-items>

  <div>
    <p>
      By clicking the submit button <strong>{{ prequal.account?.name }}</strong> will be notified to review your uploaded documents.
      Your overall <strong>Health and Safety Score</strong> will be updated by them. You can still continue making changes as needed after submitting it.
    </p>
  </div>

  <button mat-raised-button color="primary" (click)="onComplete()">
    <mat-icon>check</mat-icon>
    Submit
  </button>
</mat-dialog-content>

<mat-dialog-actions>

  <button mat-raised-button (click)="downloadPdf()" color="secondary">
    <mat-icon>save_alt</mat-icon>
    Download as PDF
  </button>
  <button
    mat-raised-button
    color="secondary"
    matTooltip="Changes the URL used to edit this Prequalification"
    (click)="resetShareLink()"
  >
    <mat-icon>link</mat-icon>
    Reset share link
  </button>
  <span class="mr-auto"></span>
  <a
    [href]="shareLink"
    target="_blank"
    class="float-right"
    matTooltip="Open this shareable link in a new tab"
  >
    <mat-icon>open_in_new</mat-icon>
    Share
  </a>
  <button
    mat-icon-button
    ngxClipboard
    [cbContent]="shareLink"
    (click)="notifyCopied()"
    class="float-right"
    matTooltip="Copy the share link to clipboard"
  >
    <mat-icon>file_copy</mat-icon>
  </button>
</mat-dialog-actions>
