<div class="forgot-password" appMargin>
  <img src="./assets/images/logo.png" class="logo" alt="SiteSoft New Zealand Ltd" />
  <mat-card>
    <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">

      <p>
        <mat-hint>
          Enter your email address or username below and click the reset password button.
          We will send you an email with a unique link to change your password.
          Follow the instructions in the email to change your password.
        </mat-hint>
      </p>

      <p>
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="Email Address / Username"
            [(ngModel)]="email"
            name="email"
            type="text"
            required />
          <mat-hint>Enter your email address or username here.</mat-hint>
        </mat-form-field>
      </p>

      <button
        type="submit"
        mat-raised-button
        [disabled]="!f.valid"
        color="primary">
        Reset Password
      </button>

      <button type="button" mat-button routerLink="/sign-in" appMarginTop>
        Remembered Password?
      </button>
    </form>
  </mat-card>
</div>
