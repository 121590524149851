<div mat-dialog-title class="p-3">
  <h5>
    Add/Update Time Onsite Record
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
</div>

<mat-dialog-content class="p-3">
  <form #f="ngForm" (ngSubmit)="onSave(f)">

    <div class="row m-0 mb-3">
      <div class="col">

        <h3>Required</h3>

        <button
          mat-raised-button
          class="mr-3 mb-3 custom-button-width"
          type="button"
          color="secondary"
          id="site_selector_button"
          dusk="dusk_site_selector_button"
          (click)="onSelectSites()"
          [matBadge]="timeOnsiteRecord.site_id != 0 ? 'Y' : 'N'"
          matTooltip="Select the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} this record is intended for.">
          Select a {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
        </button>

        <mat-form-field class="full-width mb-3">
          <mat-label>Select a date and/or time:</mat-label>
          <input matInput [ngxMatDatetimePicker]="recordDateTimePicker" readonly name="recordDateTime" [(ngModel)]="recordDateTime" [disabled]="!timeOnsiteRecord.site_id">
          <mat-datepicker-toggle matSuffix [for]="recordDateTimePicker"></mat-datepicker-toggle>
          <button type="button" mat-button matSuffix mat-icon-button *ngIf="recordDateTime" (click)="recordDateTime = null" matTooltip="Remove the selected date/time." [disabled]="!timeOnsiteRecord.site_id"><mat-icon>close</mat-icon></button>
          <ngx-mat-datetime-picker #recordDateTimePicker [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
          <mat-hint>Click the calendar icon to select a date/time.</mat-hint>
        </mat-form-field>

        <mat-form-field class="full-width mb-3">
          <mat-label>Enter the duration (of the visit) in hours:</mat-label>
          <input
            matInput
            placeholder="Duration in hours e.g. 6."
            [(ngModel)]="timeOnsiteRecord.duration_in_hours"
            name="duration_in_hours"
            type="number"
            id="duration_in_hours"
            [disabled]="!timeOnsiteRecord.site_id || !recordDateTime"
            required
            dusk="dusk_duration_in_hours"/>
          <mat-hint>Enter the duration in hours e.g. 6.</mat-hint>
        </mat-form-field>

      </div>

      <div class="col">

        <h3>Optional</h3>

        <div class="row mb-3">
          <div class="col">
            <button
              mat-raised-button
              class="mr-3 custom-button-width"
              type="button"
              color="secondary"
              id="account_selector_button"
              dusk="dusk_account_selector_button"
              (click)="onSelectAccounts()"
              [disabled]="!timeOnsiteRecord.site_id || !recordDateTime"
              [matBadge]="timeOnsiteRecord.user_account_id != 0 ? 'Y' : 'N'"
              matTooltip="Select the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} this record is intended for.">
              Select an existing {{ utils.getLangTerm('contractors.singular', 'Contractor') }}
            </button>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <button
              mat-raised-button
              class="mr-3 custom-button-width"
              type="button"
              color="secondary"
              id="account_creator_button"
              dusk="dusk_account_creator_button"
              (click)="onAddAccount()"
              [disabled]="!timeOnsiteRecord.site_id || !recordDateTime"
              [matBadge]="timeOnsiteRecord.user_account_id != 0 ? 'Y' : 'N'"
              matTooltip="Add the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} this record is intended for.">
              Add a new {{ utils.getLangTerm('contractors.singular', 'Contractor') }}
            </button>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <button
              mat-raised-button
              class="mr-3 custom-button-width"
              type="button"
              color="secondary"
              id="user_selector_button"
              dusk="dusk_user_selector_button"
              (click)="onSelectUsers()"
              [disabled]="!timeOnsiteRecord.site_id || !recordDateTime"
              [matBadge]="timeOnsiteRecord.user_id != 0 ? 'Y' : 'N'"
              matTooltip="Select the user this record is intended for.">
              Select an existing user
            </button>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <button
              mat-raised-button
              class="mr-3 custom-button-width"
              type="button"
              color="secondary"
              id="user_creator_button"
              dusk="dusk_user_creator_button"
              (click)="onAddUser()"
              [disabled]="!timeOnsiteRecord.site_id || !recordDateTime"
              [matBadge]="timeOnsiteRecord.user_id != 0 ? 'Y' : 'N'"
              matTooltip="Select the user this record is intended for.">
              Add a new user ({{ utils.getLangTerm('employees.singular', 'Employee') }})
            </button>
          </div>
        </div>

      </div>
    </div>

  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button type="button" (click)="onSave(f)" [disabled]="!f.valid" color="primary">Save</button>
</mat-dialog-actions>
