<nav class="navbar navbar-expand-lg navbar-light bg-light">

  <b style="font-size: larger;"> My Inductions</b>

  <span style="flex: 1 1 auto;"></span>

  <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
    <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search" aria-label="Search" id="search-input" matTooltip="Search for inductions." />

    <button mat-raised-button color="secondary" appMarginRight id="search-button" matTooltip="Run the current search.">
      <mat-icon>search</mat-icon> Search
    </button>
  </form>
</nav>

<table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the induction name.">
      Induction
    </th>
    <td mat-cell *matCellDef="let induction">
      {{ induction.name }}
    </td>
  </ng-container>


  <ng-container matColumnDef="company_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the account name.">
      From Account
    </th>
    <td mat-cell *matCellDef="let induction">
      {{ induction.company_name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the induction status.">
      Status
    </th>
    <td mat-cell *matCellDef="let account">
      {{ account.status }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>

    <td mat-cell *matCellDef="let account">

      <button type="button" mat-button (click)="onOpenCourse(account.course_id)" matTooltip="Open this induction." matTooltipPosition="above" *ngIf="!dataSource.multiple">
        Open Induction
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: listColumns" ></tr>
</table>

<mat-paginator #paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
