<app-risk-matrix (referenceEvent)="getRiskMatrixRef($event)" [gridData]="chartData" [riskMatrixTitle]="cardTitle"></app-risk-matrix>

<div class="row mt-2">
  <div class="col">
    <sub *ngIf="!filters.date_range || filters.date_range.length == 0 else date_range">A maximum of 3 years of data is displayed.</sub>
    <ng-template #date_range>
      <sub>{{ chartsUtils.getFormattedDateRange(filters.date_range) }}</sub>
    </ng-template>
  </div>
  <div class="col">
    <div class="d-flex justify-content-end">
      <label for="types" class="mr-1">Risk Assessment Filter:</label>
      <select class="form-select mr-3" id="types" [(ngModel)]="selectedRiskAssessmentType" (change)="onRiskAssessmentTypeChanged()" matTooltip="Select which set of risk assessment results you want to view.">
        <option [value]="riskAssessmentType.type" *ngFor="let riskAssessmentType of riskAssessmentTypes">{{ riskAssessmentType.label }}</option>
      </select>
    </div>
  </div>
</div>
