<div mat-dialog-title class="ph-3" *ngIf="this['dialogRef']">
  <h5>
    Safety Plan Document
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
</div>

<mat-card class="m-3">
  <form #f="ngForm" (ngSubmit)="onSave(f)">
    <mat-card-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <mat-label>Document Type:</mat-label>
              <mat-select [(ngModel)]="sssp_doc.doc_type" name="doc_type" aria-label="Select the type of this document.">
                <mat-option value="Hazard Register">Hazards & Risks Register</mat-option>
                <mat-option value="Hazardous Substance Register">Hazardous Substance Register</mat-option>
                <mat-option value="Task Analysis">Task Analysis</mat-option>
                <mat-option value="Training-Competency Register">Training/Competency Register</mat-option>
                <mat-option [value]="utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks')">{{ utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') }}</mat-option>
                <mat-option value="Emergency Response Plan">Emergency Response Plan</mat-option>
                <mat-option value="Incident Register">Incident Register</mat-option>
                <mat-option value="Induction">Induction</mat-option>
                <mat-option value="Safety Plan">Safety Plan</mat-option>
                <mat-option value="Other">Other</mat-option>
              </mat-select>
            </mat-form-field>

            <app-timezone-select [timezoneInput]="selectedTimezone" (timezoneOutput)="getOutputTimezone($event)"></app-timezone-select>

            <mat-form-field class="full-width mb-3">
              <mat-label>Expiry Date</mat-label>
              <input matInput [ngxMatDatetimePicker]="expiresAtDateTimePicker" readonly name="expiresAtDateTime" [(ngModel)]="expiresAtDateTime">
              <mat-datepicker-toggle matSuffix [for]="expiresAtDateTimePicker"></mat-datepicker-toggle>
              <button type="button" mat-button matSuffix mat-icon-button *ngIf="expiresAtDateTime" (click)="expiresAtDateTime = null" matTooltip="Remove the expiry date."><mat-icon>close</mat-icon></button>
              <ngx-mat-datetime-picker #expiresAtDateTimePicker [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
              <mat-hint>Click the calendar icon to select a date and time.</mat-hint>
            </mat-form-field>
          </div>

          <div class="col" *ngIf="sssp.account_id == app.account.id else showStatusAndComments">
            <mat-form-field class="full-width">
              <mat-label>Status:</mat-label>
              <mat-select [(ngModel)]="sssp_doc.doc_status" name="doc_status" aria-label="Select the status of this document.">
                <mat-option value="required">Required</mat-option>
                <mat-option value="submitted">Submitted</mat-option>
                <mat-option value="in review">In Review</mat-option>
                <mat-option value="accepted">Accepted</mat-option>
                <mat-option value="rejected">Rejected</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <mat-label>Comments:</mat-label>
              <input
                matInput
                placeholder="Please enter some comments here."
                [(ngModel)]="sssp_doc.doc_comments"
                name="doc_comments"
                type="text"
                id="doc_comments"
                dusk="dusk_doc_comments" />
              <mat-hint>
                Enter some comments for this document.
              </mat-hint>
            </mat-form-field>
          </div>

          <ng-template #showStatusAndComments>
            <div class="col" *ngIf="sssp_doc.id">
              <div class="row">
                <h3>Status</h3>
              </div>
              <div class="row">
                <p>{{ sssp_doc.doc_status | titlecase }}</p>
              </div>

              <div class="row">
                <h3>Comments</h3>
              </div>
              <div class="row">
                <p>{{ sssp_doc.doc_comments }}</p>
              </div>
            </div>
          </ng-template>

          <div class="col">
            <ng-container>
              <div class="row">
                <h5>Rating</h5>
              </div>
              <div class="row">
                <app-rating [rating]="sssp_doc.doc_rating" [edit]="sssp.account_id == app.account.id" (ratingChanged)="sssp_doc.doc_rating = $event"></app-rating>
              </div>
              <div class="row">
                <p *ngIf="sssp_doc.sssp.account_id != app.account.id">
                  <small>The rating can only be changed by PCBU1s.</small>
                </p>
              </div>
            </ng-container>

            <div class="row">
              <h5>Uploaded Files</h5>
            </div>
            <div class="row">
              <ng-container *ngIf="sssp_doc.files && sssp_doc.files.length > 0 else noFiles">
                <a *ngFor="let file of sssp_doc.files" [href]="file.url" target="_blank" class="m-1">
                  <span class="badge badge-pill badge-secondary">
                    {{ file.name }}
                  </span>
                </a>
              </ng-container>
              <ng-template #noFiles>
                <mat-hint>There are no uploaded files to show.</mat-hint>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>

    <mat-card-actions>
      <button
        mat-raised-button
        class="mr-3"
        type="button"
        color="primary"
        id="save_button"
        dusk="dusk_save_button"
        (click)="onSave(f)"
        matTooltip="Save this document.">
        <mat-icon>save</mat-icon>
        Save
      </button>

      <app-file-browse-button
        parentObjectType="sssp_doc"
        [parentObjectId]="sssp_doc.id"
        [filesCount]="sssp_doc.files.length"
        (selectedFiles)="onSelectFiles($event)"
        (afterDialogClosedEvent)="onSaveAndRefresh(f)">
      </app-file-browse-button>

      <button
        mat-raised-button
        class="mr-3"
        type="button"
        color="secondary"
        *ngIf="sssp.account_id == app.account.id && sssp.contractor_id"
        (click)="onComposeNewMessage(sssp.contractor_id, f)"
        matTooltip="Click this button to send a message to this {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.">
        <mat-icon>send</mat-icon>
        Send Message
      </button>

      <button
        mat-icon-button
        class="mr-3 float-right"
        type="button"
        color="danger"
        id="delete_button"
        dusk="dusk_delete_button"
        (click)="onDeleteSSSPDoc($event)"
        *ngIf="sssp_doc.id"
        matTooltip="Delete this document.">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-card-actions>

  </form>
</mat-card>


