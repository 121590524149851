import { SiteModel } from 'src/app/models/site.model';
import { SitesService } from 'src/app/shared/sites.service';
import { CurrentTimezoneStateService } from './../../shared/current-timezone-state.service';
import { Component, Inject, OnInit, Optional, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { AppService } from '../../app.service';
import { IncidentModel } from '../../models/incident.model';
import { UtilsService } from '../../shared/utils.service';
import { FilesRemoveComponent } from 'src/app/files/files-remove/files-remove.component';
import { FileService } from 'src/app/shared/file.service';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { IncidentsTabsComponent } from '../incidents-tabs/incidents-tabs.component';
import * as moment from 'moment';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';
import {TagsSelectorComponent} from "../../shared/tags-selector/tags-selector.component";

@Component({
  selector: 'app-incidents-edit',
  templateUrl: './incidents-edit.component.html',
  styleUrls: ['./incidents-edit.component.scss']
})
export class IncidentsEditComponent implements OnInit {

  @Input() incidentTabsComponent: IncidentsTabsComponent;

  id: number;
  inProgress = false;

  /**
   * change the default date display format for the date picker.
   * https://stackoverflow.com/questions/44452966/angular-2-material-2-datepicker-date-format
   */

  incident: IncidentModel = new IncidentModel();
  incidentBodyParts = [];

  selectedFiles: File[] = [];

  // date_object: Date;
  // date: moment.Moment = moment();
  // hour_value: number = 0;
  // minute_value: number = 0;

  @ViewChild('incidentDateTimePicker') incidentDateTimePicker: NgxMatDatetimePicker<any>;
  incidentDateTime: moment.Moment;

  selectedTimezone: string = this.cTmzState.getCurrentTimezone();
  selectedSite: SiteModel = new SiteModel;

  data: any = {
    Illness: ['You have become unwell at work, due to a work related activity'],
    Injury: [
      'Non Treatment Injury',
      'Minor First Aid Injury',
      'Medical Treatment Injury',
      'Lost Time Injury',
      'Notifiable Injury',
      'Fatal Injury'
    ],
    Incident: [
      'Property Damage',
      'Asset Damage',
      'Environmental Damage',
      'Vehicle Incident'
    ],
    'Near Miss': [
      'Safety Observation',
      'Unsafe Condition',
      'Near Miss',
      'Serious Near Miss',
      'Other'
    ]
  };

  // The list of selectable incident types.
  incident_types: string[] = this.utils.getIncidentTypes();

  illness_types = [
    'Allergic Reaction',
    'Heat Illness',
    'Heart Illness',
    'Hernia',
    'Respiration',
    'Skin Disorder',
    'Stress',
    'Unknown'
  ];

  injury_types = [
    'Abrasion',
    'Amputation',
    'Bite',
    'Bruise',
    'Burn',
    'Concussion',
    'Cut',
    'De-gloving',
    'Dislocation',
    'Electric Shock',
    'Fatality',
    'Foreign Body',
    'Fracture',
    'Hearing Loss',
    'Internal Injury',
    'Laceration',
    'Sprain',
    'Sting',
    'Strain'
  ];

  body_parts = [
    'Head',
    'Face',
    'Eyes',
    'Neck',
    'Shoulder',
    'Torso',
    'Chest',
    'Stomach',
    'Upper Back',
    'Lower Back',
    'Internal Organs',
    'Arm',
    'Hand',
    'Finger',
    'Hip',
    'Upper Leg',
    'Lower Leg',
    'Knee',
    'Foot'
  ];

  statuses: string[] = [
    'Reported', 'Assigned', 'In Progress', 'Submitted', 'Completed'
  ];

  who_was_involved_options: string[] = [
    'Employee', 'Contractor', 'Visitor'
  ];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public utils: UtilsService,
    private cTmzState: CurrentTimezoneStateService,
    private sitesService: SitesService,
    public app: AppService,
    private api: ApiRequestService,
    @Optional() public dialogRef: MatDialogRef<IncidentsEditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private fileService: FileService  ) {}

  ngOnInit() {
    // this.api.requestObservable('get', 'my-account')
    // .subscribe(res => this.account = res.data);

    const site_id: number = Number(this.dialogData['site_id']);

    // get the site id
    this.incident.id = Number(
      this.incidentTabsComponent ? this.incidentTabsComponent.incident.id : 0
    );

    // check if it's a valid id otherwise it's a new site being created.
    // NaN, NULL, 0, any sequence of letters will trigger a new site creation form.
    if (this.incident.id) {
      this.find(this.incident.id);
    } else {
      // show the current date if it's a new incident
      // this.hour_value = this.date.hours();
      // this.minute_value = this.date.minutes();

      if (site_id) {
        this.incident.site_id = site_id;
        this.sitesService.find(this.incident.site_id).subscribe(res => {
          this.selectedSite = res.data;
          this.selectedTimezone = this.selectedSite.timezone;
        })
      }

    }
  }

  getOutputTimezone($event) {
    this.selectedTimezone = $event;
  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: [this.incident.site_id],
        multiple: false
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.incident.site_id = results;
          this.sitesService.find(this.incident.site_id).subscribe(res => {
            this.selectedSite = res.data;
            this.selectedTimezone = this.selectedSite.timezone;
          })
        }
      }
    );
  }

  onSelectUsers() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        selected: this.incident.user_ids,
        multiple: true,
        visitors_from_all_sites: true
      },
      {
        width: '1024px'
      },
      (result: any) => {
        if (typeof result !== 'undefined') {
          this.incident.user_ids = result;
        }
      }
    );
  }

  onRemoveFiles() {
    this.utils.showComponentDialog(
      FilesRemoveComponent,
      this.incident.files,
      {
        width: '700px'
      },
      () => this.find(this.incident.id)
    );
  }

  async onSubmit(form: NgForm) {
    if (this.inProgress) {
      return;
    }

    // make sure the form data is valid
    if (!form.valid) {
      this.utils.showModal('Validation Error', 'Please make sure you enter all fields with valid information.');
      return;
    }

    this.inProgress = true;

    // get the unix timestamp for the selected date and times
    // this.date.hours(this.hour_value);
    // this.date.minutes(this.minute_value);
    // this.incident.date_incident = this.date.unix();

    const dateToSave = moment.tz(this.incidentDateTime.format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a', this.selectedTimezone);
    this.incident.date_incident = dateToSave.unix();

    const incidentCopy = {...this.incident};
    incidentCopy.body_parts = this.incidentBodyParts.join(',');

    // check if the site should be created or updated
    if (this.incident.id) {
      this.update(incidentCopy as IncidentModel);
    } else {
      if (!this.incident.site_id) {
        this.utils.showModal(
          'Validation Failed',
          'Please select a ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site') + ' before saving.'
        );
        return;
      }
      this.create(incidentCopy as IncidentModel);
    }
  }

  private uploadFiles(incident_id: number): Promise<any> {
    if ( this.selectedFiles.length > 0 ) {
      return this.api.makeUploadRequest(`v2/file-manager/incident/${incident_id}`, this.selectedFiles)
      .then((response) => {
        this.utils.showToast('Your incident files successfully uploaded.');
      })
      .finally(() => {
        this.selectedFiles.length = 0;
      });
    }
    return new Promise((resolve, reject) => { resolve(true); }); // Do nothing.
  }

  /**
   * If it is a new incident, add the files to the selected files array.
   * Existing incidents files will be handled by the file manager.
   * @param files List of files.
   */
   onSelectFiles(files?: any[]) {
    if ( files.length > 0 ) {
      this.selectedFiles.push(...files);
    }
  }

  onRemove() {
    this.utils.showModal(
      'Delete Incident',
      'Are you sure you want to delete this incident?',
      () => {
        this.api.makeRequest('delete', `v2/incidents/${this.incident.id}`)
        .then(() => {
          this.utils.showToast('The incident was deleted.');
          // this.router.navigate(['incidents']);
          this.dialogRef.close();
        })
        .catch((errorResponse) => {
          this.utils.showModal('Delete Incident Error', errorResponse.message);
        });
      }
    );
  }

  onCancel() {
    this.dialogRef.close();
  }

  private find(id: number): void {
    this.api.makeRequest('get', `v2/incidents/${id}`)
    .then((response) => {
      this.incident.apply(response);

      this.incidentDateTime = moment.unix(this.incident.date_incident);

      this.incident.user_ids = this.incident.users.map(user => user.id);

      // Extract Tag ids.
      this.incident.tag_ids = this.incident.tags ? this.incident.tags.map((tag) => tag.id) : [];

      if (this.incident.body_parts) {
        this.incidentBodyParts = this.incident.body_parts.split(',');
      }

      // Extract 'Who was involved?' selections.
      if (this.incident.who_was_involved) {
        this.incident.who_was_involved_selections = this.incident.who_was_involved.split(',');
      }

    })
    .catch((errorResponse) => {
      this.utils.showModal('Find Incident Error', errorResponse.message);
      // this.router.navigate(['incidents']);
    });
  }

  private create(incident: IncidentModel): Promise<any> {
    // Join 'Who was involved?' selections.
    incident.who_was_involved = incident.who_was_involved_selections.join();

    return this.api.makeRequest('post', 'v2/incidents', incident)
    .then((response) => {
      // this.utils.showToast('The incident was created. Please wait to be redirected...');
      this.utils.showToast('The incident was created.');

      this.uploadFiles(response.id)
      .then(() => {
        if ( this.dialogData ) {
          this.dialogRef.close();
        } else {
          this.router.navigate([`/incidents`]);
        }
        this.inProgress = false;
      });
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
      this.inProgress = false;
    });
  }

  private update(incident: IncidentModel): Promise<any> {
    // Join 'Who was involved?' selections.
    incident.who_was_involved = incident.who_was_involved_selections.join();

    return this.api.makeRequest('put', `v2/incidents/${incident.id}`, incident)
    .then((response) => {
      this.utils.showToast('The incident was updated.');
      this.uploadFiles(incident.id)
      .then(() => {
        // Reload the incident
        this.incidentTabsComponent.find();
        this.find(this.incident.id);
        this.inProgress = false;
      });
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
      this.inProgress = false;
    });
  }

  selectTags() {
    this.utils.showComponentDialog(
      TagsSelectorComponent,
      {
        selected: this.incident.tag_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.incident.tag_ids = results;
      }
    );
  }

}
