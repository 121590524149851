<div mat-dialog-title class="position-relative w-100">
  <h5>Filter {{ utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') }}</h5>

  <button mat-icon-button class="position-absolute" [matTooltip]="'Close Filter ' + utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks')" mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">
  <ng-container matColumnDef="sites">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onFilterSites()"
        [matBadge]="'' + (filters.site_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>location_on</mat-icon> {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="reported_by">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectReportedBy()"
        [matBadge]="'' + (filters.reported_by_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>people</mat-icon> Reported By
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="attendees">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectAttendees()"
        [matBadge]="'' + (filters.attendee_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>people</mat-icon> Attendees
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="employees">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectEmployees()"
        [matBadge]="'' + (filters.employee_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>account_circle</mat-icon> {{ utils.getLangTerm('employees.plural', 'Employees') }}
      </button>
    </div>
  </ng-container>

  <app-date-range-picker dateRangeTitle="Date Range" dateRangePlaceholder="Select Date Range" [dateRangeTooltip]="'Filter ' + utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') + ' by created at date range.'" [dateRangeInput]="filters.date_range" (dateRangeOutput)="getOutputDateRange($event)"></app-date-range-picker>

</div>

<div class="d-flex justify-content-center my-2 py-2">
  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        class="float-right"
        color="primary"
        (click)="onSaveAndClose()">
        <mat-icon>filter_list</mat-icon> Apply Filter
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        color="secondary"
        class="float-right"
        (click)="onClearAndClose()">
        <mat-icon> clear_all</mat-icon> Clear Filter
      </button>
    </div>
  </ng-container>
</div>
