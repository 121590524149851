import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultAccountComponent } from './default-account.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DefaultAccountComponent],
  imports: [
    CommonModule,
    FormsModule,

    MatIconModule,
    MatInputModule,
    MatButtonModule
  ],
  entryComponents: [DefaultAccountComponent]
})
export class DefaultAccountModule { }
