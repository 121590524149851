import { Component, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { tap } from 'rxjs/operators';
import { CustomDataSource } from 'src/app/utils/custom-data-source';

@Component({
  selector: 'app-sites-user-selector',
  templateUrl: './sites-user-selector.component.html',
  styleUrls: ['./sites-user-selector.component.scss']
})
export class SitesUserSelectorComponent {
  displayedColumns: string[] = ['select', 'contact_person', 'email'];
  dataSource: IncidentsUserSelectorDataSource;
  returnAllSelected = false;

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<SitesSelectorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      siteId: number;
      returnAllSelected: boolean;
      multiple: boolean;
    }
  ) {
    // construct the datasource
    this.dataSource = new IncidentsUserSelectorDataSource(
      this.app,
      this.api,
      data.multiple
    );
    this.dataSource.siteId = data['siteId'];

    // set a default selection of nothing if no selected values are passed through
    if (typeof this.data['selected'] === 'undefined') {
      this.data['selected'] = [];
    }

    // loop and select the values
    for (let i = 0; i < this.data['selected'].length; i++) {
      if (this.data['selected'][i]) {
        this.dataSource.selection.select(this.data['selected'][i]);
      }
    }
    this.returnAllSelected = this.data.returnAllSelected;
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  // clears the search bar and performs a search
  onClearSearch() {
    // clear the search field in the datasource
    this.dataSource.search = '';
    // search for records
    this.dataSource.getData(true);
  }

  onClearSelected() {
    this.dataSource.selection.clear();
  }

  /**
   * before the dialog close we want to store the new active site id
   */
  onSaveAndClose() {
    if (this.returnAllSelected) {
      return this.dialogRef.close({
        selected: this.dataSource.selection.selected,
        isAllSelected: this.dataSource.isAllSelected()
      });
    }

    this.dialogRef.close(this.dataSource.selection.selected);
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class IncidentsUserSelectorDataSource extends CustomDataSource {
  sort_by = 'name';
  sort_order = 'asc';
  siteId: number;

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      `sites/${this.siteId}/linked-contractors`,
      resetOffset
    );
  }
}
