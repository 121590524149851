<div mat-dialog-title>
  <div class="d-flex">
    <h3>
      Companies {{ dataSource.multiple ? '(' + dataSource.selection.selected.length + ')' : '' }}
    </h3>

    <span style="flex: 1 1 auto;"></span>

    <form
      class="form-inline my-2 my-lg-0"
      (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2"
        type="search"
        [(ngModel)]="dataSource.search"
        name="search"
        placeholder="Search Companies"
        aria-label="Search Companies" />
    </form>

    <button mat-raised-button (click)="dataSource.getData(true)" appMarginRight>
      <mat-icon>search</mat-icon> Search
    </button>
  </div>
</div>

<mat-dialog-content>

  <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

  <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Joined</th>
      <td mat-cell *matCellDef="let element">{{ (element.date_created * 1000) | timezoneConvertMedium }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-raised-button
          color="primary"
          (click)="onSelectSingle(element.id)"
          *ngIf="!this.dataSource.isSelected(element.id)">
          Select
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="dataSource.toggleRowSelection(row.id)">
    </tr>
  </table>

  <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <button mat-raised-button color="secondary" (click)="onDeselectAllRecords()" *ngIf="dataSource.selection.selected.length > 0">
    Deselect All
  </button>

  <button mat-raised-button appMarginRight color="primary" (click)="onSelectMultiple()" *ngIf="data['multiple']">
    Select & Close
  </button>
</mat-dialog-actions>
