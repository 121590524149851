import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../../app.service';
import { CourseModuleSectionModel } from '../../../../models/course-module-section.model';
import { ApiService } from '../../../../shared/api.service';
import { UtilsService } from '../../../../shared/utils.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-courses-modules-sections-view',
  templateUrl: './courses-modules-sections-view.component.html',
  styleUrls: ['./courses-modules-sections-view.component.scss']
})
export class CoursesModulesSectionsViewComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public section: CourseModuleSectionModel,
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) {}
}
