import { Component, OnInit } from '@angular/core';
import { AuditsModel } from '../audits.model';
import { AuditsService } from '../audits.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-audits-templates-view',
  templateUrl: './audits-templates-view.component.html',
  styleUrls: ['./audits-templates-view.component.scss']
})
export class AuditsTemplatesViewComponent implements OnInit {
  audit: AuditsModel = new AuditsModel();
  displayedColumns = ['name', 'section'];

  constructor(private service: AuditsService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.service.find(this.route.snapshot.params['id'])
      .subscribe(response => this.audit = response.data);
  }

}
