import { FileModel } from '../shared/file.model';
import { Auditable } from '../shared/auditable.model';

export class ERPModel extends Auditable {
  id = 0;

  name = '';

  site_id = 0;

  emergency_type = '';
  work_activity = '';
  rescue_method = '';
  contractor = '';
  company = '';
  supervisor = '';
  required_equipment: { name: string }[] = [];

  selected_site_ids: number[] = [];
  copy_to_all_sites = false;
  files: FileModel[] = [];

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;

    this.site_id = 0;

    this.name = '';

    this.emergency_type = '';
    this.work_activity = '';
    this.rescue_method = '';
    this.contractor = '';
    this.company = '';
    this.supervisor = '';
    this.required_equipment = [];

    this.selected_site_ids.length = 0;
    this.copy_to_all_sites = false;
    this.files = [];
  }
}
