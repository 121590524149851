import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ApiRequestService} from "../../../shared/api-request.service";
import {UtilsService} from "../../../shared/utils.service";
import {StorageService} from "../../../shared/storage.service";
import {ChartsUtilsService} from "../../charts-utils.service";
import {SiteAccessActivitiesFilter} from "../../../reports/access-records-filter/access-records-filter.interface";

declare var google;

@Component({
  selector: 'app-site-access-activities-acknowledged-pie-chart',
  templateUrl: './site-access-activities-acknowledged-pie-chart.component.html',
  styleUrls: ['./site-access-activities-acknowledged-pie-chart.component.scss']
})
export class SiteAccessActivitiesAcknowledgedPieChartComponent implements OnInit, OnDestroy {

  // Config storage key. This must be unique when multiple containers are stored on one page.
  @Input('configStorageKey') configStorageKey: string;

  // The default show onsite only storage key.
  defaultShowOnsiteOnlyStorageKey: string;

  // Get a reference to the chart.
  @ViewChild('chart_ref') chart_ref: ElementRef;

  // The chart object.
  chart: any;

  // Input to switch near realtime data polling on or off.
  @Input('enable_realtime_data') enable_realtime_data: boolean;
  // Input to change how long between intervals in seconds.
  @Input('realtime_data_interval_seconds') realtime_data_interval_seconds: number;
  // The realtime data interval for polling data.
  private realtime_data_interval: any;

  // The filters to use for API requests.
  @Input('filters') filters: SiteAccessActivitiesFilter;

  // Input to switch near realtime data polling on or off. This can only be single or multiple.
  @Input('query_type') query_type: string;

  // Used to only show onsite records or all records.
  default_show_onsite_only: boolean = false;

  // Used to pass a reference to this component back to the parent component.
  @Output() referenceEvent: EventEmitter<SiteAccessActivitiesAcknowledgedPieChartComponent> = new EventEmitter<SiteAccessActivitiesAcknowledgedPieChartComponent>();

  // Determine if the chart can be downloaded or not.
  @Input('canBeDownloaded') canBeDownloaded: boolean;

  // The name of the file that will be used when the chart is downloaded.
  @Input('chartDownloadFilename') chartDownloadFilename: string;

  // The name of the chart for the parent card title.
  cardTitle: string = 'Access Activities by Acknowledgement';

  // Used to store the chart data.
  chartData: any = [];

  constructor(
    private api: ApiRequestService,
    private utils: UtilsService,
    private storage: StorageService,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit(): void {
    // Define the default incident type storage key.
    this.defaultShowOnsiteOnlyStorageKey = this.configStorageKey + '-default-show-onsite-only';
    // Validate the default config.
    this.validateDefaultConfig();
    // Load the Google charts library into the software. It will only be loaded as needed.
    this.utils.loadExternalScript('google-charts', 'https://www.gstatic.com/charts/loader.js', () => {
      // Load the required libraries.
      google.charts.load('current', { packages: ['corechart'] });
      // Add a callback that will be executed when the chart libraries fully loaded.
      google.charts.setOnLoadCallback(async (): Promise<any> => {
        // Initialize the chart.
        this.chart = new google.visualization.PieChart(this.chart_ref.nativeElement);
        // Get the default incident type from storage.
        const defaultOnsiteOnlyStorageObject = await this.storage.dbGet('charts', this.defaultShowOnsiteOnlyStorageKey);
        // Get the default incident type from storage. Set as Near Miss if it was not found.
        this.default_show_onsite_only = defaultOnsiteOnlyStorageObject ? defaultOnsiteOnlyStorageObject.default_show_onsite_only : false;
        // Update the card title.
        this.cardTitle = 'Access Activities by Acknowledgement ' + (this.default_show_onsite_only ? '(Onsite Only)' : '');
        // Get the data from the api.
        this.getData();
        // Check if realtime data should be pulled.
        if ( this.enable_realtime_data ) {
          // Create an interval to poll the API.
          this.realtime_data_interval = setInterval(() => {
            // Make a request to get the data.
            this.getData();
          }, this.realtime_data_interval_seconds * 1000);
        }
      });
    });
    // Send a reference to the parent component.
    this.referenceEvent.emit(this);
  }

  /**
   * Lifecycle hook called when the component is about to be destroyed.
   * It clears the near realtime data interval if it was set.
   */
  ngOnDestroy(): void {
    // Check if near realtime data polling is enabled and if the interval is set.
    if ( this.enable_realtime_data && this.realtime_data_interval ) {
      // Clear the interval to stop the data polling.
      clearInterval(this.realtime_data_interval);
    }
  }

  /**
   * Validates the configuration of a chart.
   * Ensures that certain properties are defined and sets default values if not provided.
   *
   * @return {void}
   */
  validateDefaultConfig(): void {
    // Ensure realtime data is defined and set to false if not provided.
    if ( typeof this.enable_realtime_data == 'undefined' ) {
      this.enable_realtime_data = false;
    }
    // Ensure realtime data interval seconds is defined and set to 60 if not provided.
    if ( typeof this.realtime_data_interval_seconds == 'undefined' ) {
      this.realtime_data_interval_seconds = 60;
    }
    // Ensure the query type is defined and set to 'single' if not provided. It can be 'multiple'.
    if ( typeof this.query_type == 'undefined' ) {
      this.query_type = 'single';
    }
    // Ensure the "default onsite only" is defined and set to false if not provided.
    if ( typeof this.default_show_onsite_only == 'undefined' ) {
      this.default_show_onsite_only = false;
    }
    // Update the card title.
    this.cardTitle = 'Access Activities by Acknowledgement ' + (this.default_show_onsite_only ? '(Onsite Only)' : '');
    // Ensure "can be downloaded" is defined and set to true if not provided.
    if ( typeof this.canBeDownloaded == 'undefined' ) {
      setTimeout(() => {
        this.canBeDownloaded = true;
      }, 1000); // Need delay due to change detection errors in dev.
    }
    // Ensure "chart download filename" is defined and set a default if not provided.
    if ( typeof this.chartDownloadFilename == 'undefined' ) {
      this.chartDownloadFilename = this.cardTitle + ' PIE Chart';
    }
  }

  /**
   * Updates the filters from the parent component.
   *
   * @param {any} filters - The new filters to update.
   * @return {void}
   */
  updateFiltersFromParentComponent(filters: any): void {
    // Update the filters.
    this.filters = filters;
  }

  /**
   * Retrieves data from the API and updates the chart visuals.
   *
   * @returns {void}
   */
  getData(): void {
    // Normalize the query params.
    const queryParams:{[p: string]: any} = this.utils.normalizeQueryParams({
      ...this.filters,
      query_type: this.query_type,
      date_range: this.filters.date_range.map((date: Date) => {
        return date.getTime() / 1000;
      }).join(','),
      show_onsite_only: this.default_show_onsite_only
    });
    // Get data from the API.
    this.api.makeRequest('get', 'v2/site-access/charts/activities-acknowledged', {}, queryParams)
      .then((response) => {
        // Store the chart data.
        this.chartData = response;
        // Draw the chart.
        this.drawChart();
      });
  }

  /**
   * Draws a chart using the provided data.
   *
   * @param {boolean} includeTitle - Determines whether or not to include a title on the chart. Default is false.
   * @return {void}
   */
  drawChart(includeTitle: boolean = false): void {
    // Draw the chart after fetching the data. This will redraw the chart for any subsequent calls.
    this.chart.draw(google.visualization.arrayToDataTable(this.chartData), {
      title: includeTitle ? this.cardTitle : '',
      titleTextStyle: {
        fontSize: 16,
        bold: true,
        color: 'darkgray'
      },
      tooltip: {
        ignoreBounds: true,
        isHtml: true
      },
      colors: this.chartsUtils.defaultSystemChartColors()
    });
  }

  /**
   * Triggers when the onsite only checkbox is changed.
   * Reloads the data from the API.
   *
   * @return {void}
   */
  async onDefaultShowOnsiteOnlyChanged(): Promise<any> {
    // Update the default incident type in storage.
    await this.storage.dbUpdateOrCreate('charts', { default_show_onsite_only: this.default_show_onsite_only , id: this.defaultShowOnsiteOnlyStorageKey});
    // Update the card title.
    this.cardTitle = 'Access Activities by Acknowledgement ' + (this.default_show_onsite_only ? '(Onsite Only)' : '');
    // Update the file download filename.
    this.chartDownloadFilename = this.cardTitle + ' PIE Chart';
    // Reload the data from the API.
    this.getData();
  }

  /**
   * Cleans up any data from storage that needs to be removed.
   *
   * @returns {Promise<void>} A Promise that resolves when the cleanup is completed.
   */
  async pruneStorage(): Promise<any> {
    // Clean any data from storage that need to be removed.
    await this.storage.dbDelete('charts', this.defaultShowOnsiteOnlyStorageKey);
  }
}
