import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../api-request.service";
import {UtilsService} from "../utils.service";
import {TagModel} from "../../models/tag.model";

@Component({
  selector: 'app-tags-filter',
  templateUrl: './tags-filter.component.html',
  styleUrls: ['./tags-filter.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class TagsFilterComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl('');
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];
  allTagObjs: TagModel[] = [];
  tagIds: number[] = [];

  @Output() tagsFilterOutput: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    this.getTags();
  }

  private getTags() {
    this.api.makeRequest('get', `v2/tags`, {}, {
      order_by: 'title',
      order: 'asc',
      no_limit: 'true'
    })
      .then((response) => {
        this.allTagObjs = response.data;
        this.fillAllTags();
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private fillAllTags() {
    this.allTags = Array.isArray(this.allTagObjs) && this.allTagObjs.length > 0 ? this.allTagObjs.map(tagObj => tagObj.title) : [];
    this.allTags = this.correctAllTags(this.allTags);

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.allTags.slice()))
    );
  }

  private correctAllTags(alltags: string[]) {
    let alltagsIndex = 0;
    if(this.tags.length) {
      this.tags.forEach(function (title, index) {
        if (alltags.includes(title)) {
          alltagsIndex = alltags.indexOf(title);
          alltags.splice(alltagsIndex, 1);  // remove tag
        }
      });
    }

    return alltags;
  }

  addFilterTag(event: MatChipInputEvent) {
    // Adding a tag not allowed. Only selecting existing tags available.
    // Triggered by separatorKeysCodes

    // const value = (event.value || '').trim();

    // Add our tag
    // if (value) {
    //   this.tags.push(value);
    // }

    // Clear the input value
    // event.chipInput!.clear(); // Original code and not working
    // if (event.input) {
    //   event.input.value = '';
    // }

    // this.tagCtrl.setValue(null);
  }

  removeFilterTag(tag: string) {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1); // remove tag
      this.fillAllTags();
      this.outputTagIds();
    }
  }

  selectedFilterTag(event: MatAutocompleteSelectedEvent) {
    this.tags.push(event.option.viewValue); // add tag
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    this.fillAllTags();
    this.outputTagIds();
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(tag => tag.toLowerCase().includes(filterValue));
  }

  onClearTags() {
    this.tags = []; // clear all tags

    this.fillAllTags();
    this.outputTagIds();
  }

  private outputTagIds() {
    this.tagIds = [];
    if(this.tags.length) {
      this.tagIds = this.allTagObjs.filter(t => this.tags.includes(t.title)).map(tagObj => tagObj.id);
    }
    this.tagsFilterOutput.emit(this.tagIds);
  }

}
