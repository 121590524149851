import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {IndustriesModel} from "../../admin/industries/industries.model";
import {UtilsService} from "../../shared/utils.service";
import {ApiRequestService} from "../../shared/api-request.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {ToolModel} from "../tools.model";
import { NgForm } from '@angular/forms';
import {NgxMatDatetimePicker} from "@angular-material-components/datetime-picker";
import * as moment from "moment/moment";
import {AppService} from "../../app.service";

@Component({
  selector: 'app-tools-edit',
  templateUrl: './tools-edit.component.html',
  styleUrls: ['./tools-edit.component.scss']
})
export class ToolsEditComponent implements OnInit {

  inProgress: boolean = false;

  industries: IndustriesModel[] = [];

  // Used to extract the date and time from a date/time picker.
  @ViewChild('expiresAtDateTimePickerRef') expiresAtDateTimePickerRef: NgxMatDatetimePicker<any>;
  expiresAtDateTime: moment.Moment;

  selectedFiles: File[] = [];

  constructor(
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public tool: ToolModel,
    private dialogRef: MatDialogRef<ToolsEditComponent>,
    private api: ApiRequestService,
    private app: AppService
  ) { }

  ngOnInit() {
    // Check if the tool id is present. If so, load it from the API.
    if( this.tool.id ) {
      this.find(this.tool.id);
    }

    this.getIndustries();
  }

  private async getIndustries() {
    this.industries = await this.utils.getIndustries();
  }

  onSubmit(form: NgForm, autoCloseDialog: boolean = true): void {
    // Check if the button was already pressed.
    if ( this.inProgress ) {
      return;
    }

    // Check form validation.
    if ( !form.valid ) {
      this.utils.showFormValidationError('Please enter all required features.');
      return;
    }

    this.inProgress = true;

    // Convert the selected date and time to a unix timestamp.
    if ( this.expiresAtDateTime ) {
      const expiresAtDateTime: moment.Moment = moment.tz(this.expiresAtDateTime.format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a', this.app.account.timezone);
      this.tool.expires_at = expiresAtDateTime.unix();
    }

    !this.tool.id ? this.create(autoCloseDialog) : this.update(autoCloseDialog);
  }

  /**
   * Finds a tool by its ID.
   *
   * This method makes a request to the API to retrieve the tool information and stores it in the component.
   *
   * @param {number} id - The ID of the tool.
   *
   * @private
   *
   * @return {void}
   */
  private find(id: number) {
    // Make the API request.
    this.api.makeRequest('get', `v2/tools/${id}`)
      .then((response) => {
        // Store the response.
        this.tool = response as ToolModel;

        // Convert the unix "expires_at" timestamp to a moment date/time object.
        if ( this.tool.expires_at ) {
          this.expiresAtDateTime = moment.unix(this.tool.expires_at);
        }

        // Industry - Default to zero - All
        if( this.tool.industry_id == null ) {
          this.tool.industry_id = 0;
        }
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onDelete(): void {
    this.utils.showModal(
      'Remove Tool',
      `Are you sure you want to archive "${this.tool.title}"?`,
      () => {
        this.api.makeRequest('delete', `v2/tools/${this.tool.id}`)
          .then((response) => {
            this.utils.showToast(`"${this.tool.title}" has been archvied.`);
            this.dialogRef.close(true);
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  private update(autoCloseDialog: boolean = true): void {
    this.api
      .makeRequest('put', `v2/tools/${this.tool.id}`, this.tool)
      .then((response: ToolModel) => {
        this.tool = response as ToolModel;
        this.onSuccess(`Updated: "${response.title}"`, autoCloseDialog);
      })
      .finally(() => {
        // Upload files.
        this.uploadFiles();
      });
  }

  private create(autoCloseDialog: boolean = true): void {
    this.api
      .makeRequest('post', 'v2/tools', this.tool)
      .then((response: ToolModel) => {
        this.tool = response as ToolModel;
        this.onSuccess(`New Tools "${response.title}" created.`);
      })
      .finally(() => {
        // Upload files.
        this.uploadFiles();
      });
  }

  private onSuccess(message: string, autoCloseDialog: boolean = true): void {
    this.utils.showToast(message);
    // Check if the dialog should be closed.
    if ( autoCloseDialog ) {
      this.dialogRef.close(this.tool);
    }
  }

  /**
   * Uploads selected files to the server.
   *
   * @returns {void}
   */
  uploadFiles(): void {
    // Check if any new files were selected.
    if ( this.selectedFiles.length > 0 ) {
      this.api.makeUploadRequest(`v2/file-manager/tool/${this.tool.id}`, this.selectedFiles)
        .then((response) => {
          this.utils.showToast('Your files successfully uploaded.');
        })
        .finally(() => {
          // Reset the selected files array.
          this.selectedFiles.length = 0;
        });
    }

    // Change in progress status.
    this.inProgress = false
  }

  /**
   * If it is a new safety observation, add the files to the selected files array.
   * Existing safety observation files will be handled by the file manager.
   * @param files List of files.
   */
  onSelectFiles(files?: FileList|any): void {
    if ( files && files.length > 0 ) {
      this.selectedFiles.push(...files);
    }
  }

}
