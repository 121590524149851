import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../app.service";
import {ApiService} from "../../shared/api.service";
import {UtilsService} from "../../shared/utils.service";
import {SiteModel} from "../../models/site.model";
import {ActivatedRoute, Router} from "@angular/router";
import {NgForm} from "@angular/forms";

declare var google;

@Component({
  selector: 'app-sites-details-map',
  templateUrl: './sites-details-map.component.html',
  styleUrls: ['./sites-details-map.component.scss']
})
export class SitesDetailsMapComponent implements OnInit {

  /**
   * Is the form being submitted?
   */
  inProgress = false;

  // the site model placeholder
  site: SiteModel = new SiteModel();

  // Type of google.maps.LatLng
  geofenceCoordinates = [];

  coordinatesSetAtListener: any;
  coordinatesInsertAtListener: any;

  // variables used to manipulate the map
  @ViewChild('map') mapElement: ElementRef;
  map: any;
  polygon: any;
  drawingManager: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    // Get the site id from the route params.
    const parent_id: number = Number(this.route.parent.snapshot.params['parent_id']);
    const child_id: number = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && parent_id) ) {
      // Store the site id.
      this.site.id = child_id ? child_id : parent_id;
      // Get the data from the API.
      this.api.laravelApiRequest(
        'get',
        'sites/' + this.site.id,
        {},
        {},
        (response) => {
          // apply the data
          this.site.apply(response.data);

          // Load the Google Maps script and configure the map once it is loaded.
          this.utils.loadExternalScript('google-maps-script', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA91kej0UNf8eHR_O_iTY7oFZVFTSVxk2I&libraries=drawing,places,geometry', () => {
            // slight pause before populating map - wait for map to load
            setTimeout(() => {
              // load the map configuration
              this.loadMapConfiguration();
            }, 300);
          });
        },
        (error) => {
          this.utils.showModal('Error', error.message);
          this.router.navigate(['sites']);
        }
      );
    } else {
      this.router.navigate(['/sites']);
      return;
    }
  }

  /**
   * Create or update the site.
   * @param form
   */
  onSubmit(form: NgForm) {
    // make sure the form data is valid
    if (form.valid) {
      if (this.inProgress) {
        return;
      }

      this.inProgress = true;
      // update the geofence
      this.storePolygonPaths();

      // Check if the site should be updated.
      if (this.site.id) {
        this.api.laravelApiRequest(
          'put',
          'sites/' + this.site.id,
          this.site,
          {},
          (response) => {
            this.utils.showToast('The geofence map was updated.');
            this.inProgress = false;
          },
          () => this.inProgress = false
        );
      } else {
        this.inProgress = false;
      }
    } else {
      this.utils.showModal('Form Error', 'Please make sure you enter all fields with valid information.');
    }
  }

  /**
   * Load the map configuration.
   */
  loadMapConfiguration() {
    // set the default geofence bounds and coordinates
    const geofenceBounds = new google.maps.LatLngBounds();

    this.geofenceCoordinates.length = 0;

    // if the currently loaded site has a geofence, load it instead
    if (this.site.geofence) {
      const points = this.site.geofence.split(',');
      if (points.length) {
        // clear the default geofence values and add the new values
        // geofenceCoordinates.length = 0;
        points.forEach((point, i) => {
          const coordinates_data = point.split(' ');
          const coordinates = new google.maps.LatLng(
            coordinates_data[0],
            coordinates_data[1]
          );
          geofenceBounds.extend(coordinates);
          this.geofenceCoordinates.push(coordinates);
        });
      }
    } else {
      geofenceBounds.extend(new google.maps.LatLng(-36.848552, 174.762105));
    }

    // initialize the map object
    this.map = new google.maps.Map(this.mapElement.nativeElement, {
      zoom: 17,
      center: geofenceBounds.getCenter(),
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
    });

    // create the drawing manager
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: this.geofenceCoordinates.length > 1 ? null : google.maps.drawing.OverlayType.POLYGON,
      drawingControl: this.geofenceCoordinates.length <= 1, // if there is a polygon, hide controls
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ['polygon']
      },
      polygonOptions: {
        editable: true
      }
    });
    this.drawingManager.setMap(this.map);

    this.calculateGeofenceSize();

    // add a listener to disable the options to add another polygon
    google.maps.event.addListener(
      this.drawingManager,
      'polygoncomplete',
      (polygon) => {
        this.polygon = polygon;
        this.drawingManager.setOptions({
          drawingMode: null,
          drawingControl: false
        });
        this.storePolygonPaths();
        this.addGeofenceEditEvents();
      }
    );

    // add address lookup
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('location')
    ); // .getElementsByTagName('input')[0]
    autocomplete.bindTo('bounds', this.map);
    autocomplete.addListener('place_changed', () => {
      // get the place
      const place = autocomplete.getPlace();
      // store the location
      this.site.location = place.formatted_address;
      // recenter the map
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(18);
    });

    // if there's a polygon, construct and add it to the map
    if (this.geofenceCoordinates.length > 1) {
      // Construct the polygon.
      this.polygon = new google.maps.Polygon({
        paths: this.geofenceCoordinates,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.35,
        editable: true
      });
      this.polygon.setMap(this.map);

      this.addGeofenceEditEvents();

      this.map.fitBounds(geofenceBounds);
    } else {
      if (this.site.location) {
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({ address: this.site.location }, (results, status) => {
          if (status === 'OK') {
            this.map.setCenter(results[0].geometry.location);
          }
        });
      }
    }
  }

  /**
   * Subscribe to event listeners to update the polygon paths.
   * @private
   */
  private addGeofenceEditEvents() {
    // Triggered when a point is moved.
    this.coordinatesSetAtListener = google.maps.event.addListener(this.polygon.getPath(), 'set_at', () => {
      this.storePolygonPaths();
    });
    // Triggered when a point is inserted.
    this.coordinatesInsertAtListener = google.maps.event.addListener(this.polygon.getPath(), 'insert_at', () => {
      this.storePolygonPaths();
    });
  }

  /**
   * Reset the map and controls.
   */
  resetMapControls() {
    if (this.polygon) {
      this.polygon.setMap(null);
    }

    this.drawingManager.setOptions({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true
    });

    // clear the site geofence
    this.site.geofence = '';

    // Remove drawing listeners
    google.maps.event.removeListener(this.coordinatesSetAtListener);
    google.maps.event.removeListener(this.coordinatesInsertAtListener);
    this.coordinatesSetAtListener = null;
    this.coordinatesInsertAtListener = null;

    this.site.geofence_size = 0;
    this.geofenceCoordinates.length = 0;

    // remove the polygon
    this.polygon = null;
  }

  /**
   * Store the polygon data as concatenated coords.
   */
  storePolygonPaths() {
    if (this.polygon) {

      let geofence = '';
      const paths = this.polygon.getPath();
      let firstPath: any;

      this.geofenceCoordinates.length = 0;

      paths.forEach((path, i) => {
        geofence = geofence + parseFloat(path.lat()).toFixed(6) + ' ' + parseFloat(path.lng()).toFixed(6) + ',';
        if (!i) {
          firstPath = path;
        }
        this.geofenceCoordinates.push(new google.maps.LatLng(parseFloat(path.lat()).toFixed(6), parseFloat(path.lng()).toFixed(6)));
      });

      geofence = geofence + parseFloat(firstPath.lat()).toFixed(6) + ' ' + parseFloat(firstPath.lng()).toFixed(6);

      this.geofenceCoordinates.push(new google.maps.LatLng(parseFloat(firstPath.lat()).toFixed(6), parseFloat(firstPath.lng()).toFixed(6)));

      this.site.geofence = geofence;

      this.calculateGeofenceSize();

    }
  }

  /**
   * Calculates the geofence size.
   * @private
   */
  private calculateGeofenceSize() {
    if (this.geofenceCoordinates.length > 2) {
      this.site.geofence_size = google.maps.geometry.spherical.computeArea(this.geofenceCoordinates);
    }
  }

}
