<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle Account Menu">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="primary" id="add" [routerLink]="['/admin', 'accounts', 'new', 'edit']" appMarginRight>
          Add Account
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.deselectAll()">
            Deselect All
          </button>

          <button mat-menu-item (click)="onMergeSelected()">
            Merge Accounts
          </button>

          <button mat-menu-item (click)="composeNewMessage()" [disabled]="!dataSource.selection.hasValue()" matTooltip="Click this button to send a message to the selected accounts.">
            Send Message
          </button>
        </mat-menu>

        <button mat-button [matMenuTriggerFor]="appMenu"
          matTooltip="System Administrators Menu" *ngIf="dataSource.selection.hasValue()">
          <mat-icon>more_vert</mat-icon>
          Menu
        </button>
      </span>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
        <button type="button" mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onOpenFilters()" matTooltip="Click this button to display filter options for this list.">
          <mat-icon>filter_list</mat-icon> Filter
        </button>

        <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search"
          placeholder="Search Accounts" aria-label="Search" id="search-input"
          matTooltip="Search all accounts by name." />

        <button mat-raised-button type="submit" color="secondary" appMarginRight id="search-button" matTooltip="Search all accounts.">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="asc" matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Select All Visible Accounts">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let account">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(account.id) : null"
            [checked]="dataSource.selection.isSelected(account.id)"
            matTooltip="Select Account {{account.id}}">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by ID">
          #
        </th>
        <td mat-cell *matCellDef="let account">
          {{ account.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef   matTooltip="Sort by Name">
          Name
        </th>
        <td mat-cell *matCellDef="let account">
          {{ account.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="subscription">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by Subscription">
          Subscription
        </th>
        <td mat-cell *matCellDef="let account">
          {{ account.subscription }}
        </td>
      </ng-container>

      <ng-container matColumnDef="enable_sms_notifications">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by SMS Notifications">
          SMS Notifications
        </th>
        <td mat-cell *matCellDef="let account">
          {{ account.enable_sms_notifications ? 'Enabled' : 'Disabled' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="settings">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Settings for each account.">
          Settings
        </th>
        <td mat-cell *matCellDef="let account">
          <span [matTooltip]="getSettingsTooltip(account)">Settings</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by Date Joined">
          Date Joined
        </th>
        <td mat-cell *matCellDef="let account">
          {{ (account.date_created * 1000) | timezoneConvertShort }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by Date Joined">
          Date Joined (UTC)
        </th>
        <td mat-cell *matCellDef="let account">
          {{ (account.date_created * 1000) | timezoneConvertUTCShort }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let account">
          <!--button type="button" mat-button routerLink="/admin/accounts/{{account.id}}/view">
            View
          </button-->

          <a type="button" mat-button routerLink="/admin/accounts/{{account.id}}/edit">
            Edit
          </a>

          <button type="button" mat-button (click)="composeNewMessage(account.id)" matTooltip="Click this button to send a message to this account.">
            Send Message
          </button>

          <button type="button" mat-button (click)="onCombinePreQualifications(account.id)" *ngIf="account.slave_accounts.length > 0">
            Combine Pre-Qualifications
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
