<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">Notification Roles</span>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="primary" (click)="onSelectNotificationRoles()" appMarginRight>
          <mat-icon>notifications_active</mat-icon> Select Notification Roles
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onUnlinkSelectedNotificationRoles()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>link_off</mat-icon> Unlink Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect notification roles." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>
      </span>

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (ngSubmit)="dataSource.getData(true)">
        <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search" aria-label="Search" />

        <button mat-raised-button color="secondary" type="submit" class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="65px">
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.name | titlecase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="system_notifications_count">
        <th mat-header-cell *matHeaderCellDef matTooltip="The count of enabled notifications.">
          Enabled Notifications
        </th>
        <td mat-cell *matCellDef="let notification_role">
          <span style="text-decoration: underline;" [matTooltip]="getNotificationRoleSystemNotificationsForTooltip(notification_role)">
            {{ notification_role.system_notifications_count  }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="sites_count">
        <th mat-header-cell *matHeaderCellDef matTooltip="The count of associated sites.">
          Sites
        </th>
        <td mat-cell *matCellDef="let notification_role">
          <span style="text-decoration: underline;" [matTooltip]="getNotificationRoleSitesForTooltip(notification_role)">
            {{ notification_role.sites_count  }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="users_count">
        <th mat-header-cell *matHeaderCellDef [matTooltip]="'The count of associated ' + utils.getLangTerm('employees.plural', 'Employees') + '.'">
          {{ utils.getLangTerm('employees.plural', 'Employees') }}
        </th>
        <td mat-cell *matCellDef="let notification_role">
          <span style="text-decoration: underline;" [matTooltip]="getNotificationRoleUsersForTooltip(notification_role)">
            {{ notification_role.users_count  }}
          </span>
        </td>
      </ng-container>

      <!-- Is Active Column -->
      <ng-container matColumnDef="is_active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Is Active</th>
        <td mat-cell *matCellDef="let element">
          {{ element.is_active ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button type="button" mat-button (click)="onEditNotificationRole(element.id)" matTooltip="Edit the notification role.">
            Edit
          </button>

          <button type="button" mat-button (click)="onUnlinkNotificationRole(element.id, $event)" matTooltip="Unlink the notification role.">
            Unlink
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
