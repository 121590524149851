<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col">

      <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
        <div class="card">
          <div class="card-body form-container">
            <h5 class="card-title">{{ induction.id ? 'Update' : 'Add' }} Induction</h5>

            <mat-form-field>
              <input matInput placeholder="Title" [(ngModel)]="induction.title" name="title" type="text" #title required />
              <mat-error *ngIf="!title.valid">
                Please enter the induction title.
              </mat-error>
            </mat-form-field>

            <span id="example-radio-group-label">Select the type of the induction</span>
            <mat-radio-group>
              <mat-radio-button class="example-radio-button" value="">
                Assign this induction to all users in my network.
              </mat-radio-button>
              <mat-radio-button class="example-radio-button" value="">
                Assign this induction to selected {{ utils.getLangTerm('contractors.plural', 'Contractors') }}/users in my network.
              </mat-radio-button>
            </mat-radio-group>

            <mat-form-field *ngIf="induction.id">
              <mat-label>Publish</mat-label>
              <mat-select [(ngModel)]="induction.published" matTooltip="Should this induction be published?" name="published">
                <mat-option [value]="0">No</mat-option>
                <mat-option [value]="1">Yes</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="save-container">
              <button type="submit" mat-raised-button [disabled]="!f.valid || inProgress" color="primary" appMarginRight>
                <mat-icon>save</mat-icon>
                Save
              </button>
            </div>

          </div>
        </div>
      </form>

    </div>

    <div class="col"></div>

  </div>
</div>
