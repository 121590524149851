<div mat-dialog-title>
  <h5>Add {{ !isNewContractorEmployee ? utils.getLangTerm('contractors.singular', 'Contractor') : utils.getLangTerm('employees.singular', 'Employee') }}</h5>
  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>


<form #f="ngForm" (ngSubmit)="onSubmit(f)" mat-dialog-content>

  <div class="row">
    <div class="col-12">

      <mat-form-field class="full-width mb-3" *ngIf="!isNewContractorEmployee">
        <input
          matInput
          placeholder="Search Companies"
          [(ngModel)]="_search"
          name="search"
          type="text"
          [matAutocomplete]="auto"
          (input)="onSearchContractors()"/>

        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let _contractor of _contractors" [value]="_contractor.name" (click)="onSelectContractor(_contractor)">
            {{ _contractor.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-hint>Use the search field to look for companies already using {{ utils.getAppName() }} or enter the details in the form below.</mat-hint>
      </mat-form-field>

      <div class="alert alert-info mt-3" role="alert" *ngIf="contractor.id && !isNewContractorEmployee">

        You are about to link an existing company as a {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.
        Select an existing user as the main contact or create a new main contact by filling out the form below.

        <mat-form-field class="full-width mb-3" *ngIf="contractor.id && contractor.users.length > 0">
          <mat-label>Select Main Contact</mat-label>
          <mat-select name="employees" dusk="dusk_employees">
            <mat-option (click)="onResetSelectedUser()">
              -- New User --
            </mat-option>
            <mat-option *ngFor="let user of contractor.users" [value]="user.id" (click)="onSelectUser(user)">
              {{ user.contact_person }} (#{{ user.id }})
            </mat-option>
          </mat-select>
          <mat-hint>Select your main contact or enter a new contact in the form below.</mat-hint>
        </mat-form-field>

        <div class="d-flex justify-content-end" *ngIf="contractor.id">
          <button
            mat-raised-button
            (click)="onReset()"
            type="button"
            color="secondary"
            id="reset_button"
            dusk="dusk_reset_button"
            matTooltip="Clear the selected business.">
            Reset Form
          </button>
        </div>

      </div>

      <hr *ngIf="!isNewContractorEmployee">

      <mat-form-field class="full-width mb-3" *ngIf="!isNewContractorEmployee && !isContractorSelected">
        <mat-label>Company Name</mat-label>
        <input
          matInput
          placeholder="Company Name"
          [(ngModel)]="contractor.name"
          name="name"
          type="text"
          id="name"
          required
          dusk="dusk_name"/>
        <mat-hint>Enter the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} name.</mat-hint>
      </mat-form-field>

      <mat-form-field class="full-width mb-3" *ngIf="!isNewContractorEmployee && !isContractorSelected">
        <mat-label>Industry</mat-label>
        <mat-select matInput placeholder="Industry" [(ngModel)]="contractor.industry" name="industry" #industry>
          <mat-option *ngFor="let industry of industries" [value]="industry.name">{{ industry.name }}</mat-option>
        </mat-select>
        <mat-hint>Select the industry of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.</mat-hint>
      </mat-form-field>

      <mat-form-field class="full-width mb-3" *ngIf="!isMainContactSelected">
        <mat-label>Full Name</mat-label>
        <input
          matInput
          placeholder="Full Name"
          [(ngModel)]="contractor.contact_person"
          name="contact_person"
          type="text"
          id="contact_person"
          required
          dusk="dusk_contact_person"/>
        <mat-hint>Enter the full name.</mat-hint>
      </mat-form-field>

      <mat-form-field class="full-width mb-3" *ngIf="!isMainContactSelected">
        <mat-label>Email Address</mat-label>
        <input
          matInput
          placeholder="Email Address"
          [(ngModel)]="contractor.email"
          name="email"
          type="email"
          id="email"
          required
          dusk="dusk_email"/>
        <mat-hint>Enter the email address.</mat-hint>
      </mat-form-field>

      <mat-form-field class="full-width mb-3" *ngIf="!isMainContactSelected || !mainContactHasMobile">
        <mat-label>{{ utils.getLangTerm('accounts.form.mobile', 'Mobile Number') }}</mat-label>
        <app-phone-input
          [idInput]="mobile_input_id"
          [numberInput]="contractor.mobile"
          [countryCodeInput]="contractor.mobile_country_code"
          [dialCodeInput]="contractor.mobile_dial_code"
          [e164NumberInput]="contractor.mobile_e164"
          (phoneDetailsOutput)="getOutputPhoneDetails($event)"
          required="true">
        </app-phone-input>
        <mat-hint>Enter the {{ utils.getLangTerm('accounts.form.mobile', 'Mobile Number') }}.</mat-hint>
      </mat-form-field>

      <mat-form-field class="full-width mb-3" *ngIf="!isMainContactSelected">
        <mat-label>Job Title</mat-label>
        <mat-select matInput placeholder="Job Title" [(ngModel)]="contractor.trade" name="trade" #trade>
          <mat-option *ngFor="let trade of trades" [value]="trade.name">{{ trade.name }}</mat-option>
        </mat-select>
        <mat-hint>Select the job title.</mat-hint>
      </mat-form-field>

    </div>
  </div>

  <div class="row" *ngIf="!isContractorSelected || !isMainContactSelected">
    <div class="col-12">
      <mat-form-field class="full-width mb-3">
        <mat-label>Address</mat-label>
        <input
          matInput
          placeholder="Address"
          [(ngModel)]="contractor.address"
          name="address"
          type="text"
          id="address"
          dusk="dusk_address"/>
        <mat-hint>Enter the address of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.</mat-hint>
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field class="full-width mb-3">
        <mat-label>{{ utils.getLangTerm('accounts.form.suburb', 'Suburb') }}</mat-label>
        <input
          matInput
          [placeholder]="utils.getLangTerm('accounts.form.suburb', 'Suburb')"
          [(ngModel)]="contractor.suburb"
          name="suburb"
          type="text"
          id="suburb"
          dusk="dusk_suburb"/>
        <mat-hint>Enter the {{ utils.getLangTerm('accounts.form.suburb', 'Suburb') }} of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.</mat-hint>
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field class="full-width mb-3">
        <mat-label>{{ utils.getLangTerm('accounts.form.city', 'City') }}</mat-label>
        <input
          matInput
          [placeholder]="utils.getLangTerm('accounts.form.city', 'City')"
          [(ngModel)]="contractor.city"
          name="city"
          type="text"
          id="city"
          dusk="dusk_city"/>
        <mat-hint>Enter the {{ utils.getLangTerm('accounts.form.city', 'City') }} of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.</mat-hint>
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field class="full-width mb-3">
        <mat-label>{{ utils.getLangTerm('accounts.form.postal_code', 'Postal Code') }}</mat-label>
        <input
          matInput
          [placeholder]="utils.getLangTerm('accounts.form.postal_code', 'Postal Code')"
          [(ngModel)]="contractor.postal_code"
          name="postal_code"
          type="text"
          id="postal_code"
          dusk="dusk_postal_code"/>
        <mat-hint>Enter the {{ utils.getLangTerm('accounts.form.postal_code', 'Postal Code') }} of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.</mat-hint>
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field class="full-width mb-3">
        <mat-label>Country</mat-label>
        <input
          matInput
          placeholder="Country"
          [(ngModel)]="contractor.country"
          name="country"
          type="text"
          id="country"
          dusk="dusk_country"/>
        <mat-hint>Enter the country of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.</mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <button
        mat-raised-button
        [disabled]="!f.valid || (!isMainContactSelected && mobile_error_state)"
        class="mr-3"
        type="submit"
        color="primary"
        id="save_button"
        dusk="dusk_save_button">
        <mat-icon>save</mat-icon>
        Save
      </button>

      <button
        mat-raised-button
        (click)="onSelectSites()"
        type="button"
        color="secondary"
        id="site_select_button"
        dusk="dusk_site_select_button"
        [matBadge]="'' + (contractor.site_ids.length || 'All')"
        matTooltip="Select which {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} should have access to."
        *ngIf="!isNewContractorEmployee">
        <mat-icon>location_on</mat-icon>
        Select {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
      </button>
    </div>
  </div>

</form>
