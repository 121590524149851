import {Component, Inject, OnInit} from '@angular/core';
import {TaskAnalysesRevisedWorkflowModel} from "../../models/task-analyses-revised-workflow.model";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-task-analyses-revised-workflows-steps',
  templateUrl: './task-analyses-revised-workflows-steps.component.html',
  styleUrls: ['./task-analyses-revised-workflows-steps.component.scss']
})
export class TaskAnalysesRevisedWorkflowsStepsComponent implements OnInit {

  ta_revised_id: number;
  path: string;

  ta_revised_reordered_workflow: TaskAnalysesRevisedWorkflowModel[] = [];
  inProgress: boolean = false;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<TaskAnalysesRevisedWorkflowsStepsComponent>
  ) { }

  ngOnInit() {

    // get the task analysis id from the parent route params
    this.ta_revised_id = this.dialogData.ta_revised_id;

    // get sites path edit/view
    this.path = this.dialogData.path;

    // Get Unique Names for Workflow Steps
    this.getWorkflowSteps(this.ta_revised_id);
  }

  private async getWorkflowSteps(ta_revised_id: number, site_id:number = 0) {
    this.ta_revised_reordered_workflow = await this.utils.getWorkflowSteps(ta_revised_id, site_id);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.ta_revised_reordered_workflow, event.previousIndex, event.currentIndex);
    this.onUpdateOrder(this.ta_revised_reordered_workflow);
  }

  onUpdateOrder(ta_revised_reordered_workflow: TaskAnalysesRevisedWorkflowModel[]) {
    ta_revised_reordered_workflow.forEach((step, index) => {
      this.ta_revised_reordered_workflow[index].step_no = index + 1;
    });
  }

  onSaveOrder() {
    this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised_id}/workflow-steps`, this.ta_revised_reordered_workflow, {})
      .then((response) => {
        this.utils.showToast('TA/JSA/SWMS Template Workflow Steps reordered.');
        this.dialogRef.close();
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

}
