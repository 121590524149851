import { Component } from '@angular/core';
import { CodesService } from '../codes.service';
import { MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-codes-add-many',
  templateUrl: './codes-add-many.component.html',
  styleUrls: ['./codes-add-many.component.scss']
})
export class CodesAddManyComponent {
  codesCount: number;
  name = '';
  inProgress = false;

  constructor(
    private codeService: CodesService,
    private dialogRef: MatDialogRef<CodesAddManyComponent>,
    private utils: UtilsService
  ) {}

  addMany() {
    if (this.inProgress) {
      return;
    }

    this.inProgress = true;
    this.codeService.addMany(this.name, this.codesCount).subscribe(
      () => {
        this.utils.showToast(
          `Creating: ${this.codesCount} codes in the background...`
        );
        this.dialogRef.close(true);
        this.inProgress = false;
      },
      (_error) => (this.inProgress = false)
    );
  }
}
