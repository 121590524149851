<div mat-dialog-title>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5>Note</h5>
      </div>
      <div class="col">
        <button mat-icon-button class="float-right" mat-dialog-close>
          <mat-icon color="secondary">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<mat-dialog-content>
  <form #f="ngForm" (ngSubmit)="onSubmit(f)">

    <mat-form-field>
      <textarea matInput placeholder="Note" [(ngModel)]="note.note" name="note" required #noteRef rows="10"></textarea>
      <mat-error *ngIf="!noteRef.valid">Please enter a note.</mat-error>
    </mat-form-field>

    <button mat-raised-button [disabled]="!f.valid || inProgress" color="primary" type="submit" id="save">
      <mat-icon>save</mat-icon>
      Save
    </button>

    <button type="button" mat-icon-button *ngIf="data.noteId" class="float-right" (click)="onDelete()">
      <mat-icon>delete</mat-icon>
    </button>

  </form>
</mat-dialog-content>
