<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class=" mr-auto">
    <button mat-raised-button routerLink="/sites" appMarginRight tourAnchor="tour.navigate.sites">
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>

    <button mat-raised-button *ngIf="site.parent_site_id" [routerLink]="'/sites/'+site.parent_site_id+'/children'" appMarginRight tourAnchor="tour.navigate.sites">
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
    </button>

    <button mat-raised-button [routerLink]="utils.prepareSiteRelatedLink(site.id, site.parent_site_id, 'view/induction')" *ngIf="site.id" appMarginRight>
      <mat-icon>visibility</mat-icon> View
    </button>
  </span>

  <!--button mat-icon-button (click)="onStartTour()" appMarginRight><mat-icon>help</mat-icon></button-->
</nav>

<form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
  <editor
    *ngIf="contentLoaded"
    placeholder="Noticeboard Content"
    [(ngModel)]="site.induction_content"
    name="induction_content"
    id="induction_content"
    [init]="{
      skin_url: '/assets/skins/lightgray',
      plugins: 'link,image,lists,hr,table,media',
      height: '400'
    }">
  </editor>

  <button
    mat-raised-button
    [disabled]="!f.valid || inProgress"
    (click)="onSubmit(f)"
    color="primary"
    class="save-button"
    appMargin
    type="button">
    Save
  </button>
</form>
