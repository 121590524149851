<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/safety-observations">Safety Observations</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="primary" (click)="onEditSafetyObservation()" appMarginRight id="add" matTooltip="Click this button to report a new safety observation.">
          <mat-icon>add</mat-icon>
          New Safety Observation
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="safetyObservationsService.dataSource.selectAllToggle()">
            <mat-icon *ngIf="safetyObservationsService.dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!safetyObservationsService.dataSource.isAllSelected()">select_all</mat-icon>
            {{ safetyObservationsService.dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onExportSafetyObservations('pdf')">
            <mat-icon>cloud_download</mat-icon> Export {{ safetyObservationsService.dataSource.selection.hasValue() ? '(' + safetyObservationsService.dataSource.selection.selected.length + ') Selected' : 'All' }} to PDF
          </button>
          <button mat-menu-item (click)="onExportSafetyObservations('csv')">
            <mat-icon>cloud_download</mat-icon> Export {{ safetyObservationsService.dataSource.selection.hasValue() ? '(' + safetyObservationsService.dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
          </button>
          <button mat-menu-item (click)="onRemoveSelectedSafetyObservations()" [disabled]="!safetyObservationsService.dataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect and/or export Safety Observations." style="min-width: 150px">
          <mat-icon>more_vert</mat-icon>
          List Menu/Export
        </button>

      </span>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="chartsUtils.onOpenMasterCharts('Safety Observations')" matTooltip="Click this button to open the charts.">
        <mat-icon>bar_chart</mat-icon> Charts
      </button>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="onFilter()" matTooltip="Click this button to display filter options.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>

      <form class="form-inline my-2 my-lg-0"
            style="min-width: 325px"
            (ngSubmit)="safetyObservationsService.dataSource.getData(true)">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="safetyObservationsService.dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search"
          id="search-input"
          matTooltip="Search for safety observations by category, type or description." />
        <button mat-raised-button
                color="secondary"
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                (click)="safetyObservationsService.dataSource.getData(true)"
                id="search-button"
                matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

    </div>
  </nav>

  <app-safety-observations-charts-container [baseStorageKey]="'safety-observations'"></app-safety-observations-charts-container>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="safetyObservationsService.dataSource?.total | async" [pageSize]="safetyObservationsService.dataSource.limit" [pageSizeOptions]="safetyObservationsService.dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="safetyObservationsService.dataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? safetyObservationsService.dataSource.selectAllToggle() : null"
            [checked]="safetyObservationsService.dataSource.selection.hasValue() && safetyObservationsService.dataSource.isAllSelected()"
            [indeterminate]="safetyObservationsService.dataSource.selection.hasValue() && !safetyObservationsService.dataSource.isAllSelected()"
            matTooltip="Toggle select all messages in this list.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? safetyObservationsService.dataSource.selection.toggle(row.id) : null"
            [checked]="safetyObservationsService.dataSource.selection.isSelected(row.id)"
            matTooltip="Toggle select this message.">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the id of the safety observation.">
          ID #
        </th>
        <td mat-cell *matCellDef="let safety_observation">
          {{ safety_observation.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="site">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
        </th>
        <td mat-cell *matCellDef="let safety_observation" class="text-wrap">
          <ng-container *ngIf="safety_observation.site">
            <ng-container *ngIf="safety_observation?.site?.parent_site">
              <small style="display: block;">
                <a [routerLink]="utils.prepareSiteRelatedLink(safety_observation?.site?.parent_site?.id, null, '/edit/details')">
                  {{ safety_observation?.site?.parent_site?.name || '' }}
                </a>
              </small>
            </ng-container>
            <mat-icon *ngIf="safety_observation?.site?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
            <a [routerLink]="utils.prepareSiteRelatedLink(safety_observation?.site?.id, safety_observation?.site?.parent_site?.id, '/edit/details')">
              {{ safety_observation?.site?.name || '' }}
            </a>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="so_category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the category of the safety observation.">
          Category
        </th>
        <td mat-cell *matCellDef="let safety_observation">
          {{ safety_observation.so_category | titlecase  }}
        </td>
      </ng-container>

      <ng-container matColumnDef="so_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the type of the safety observation.">
          Type
        </th>
        <td mat-cell *matCellDef="let safety_observation">
          {{ safety_observation.so_type | titlecase  }}
        </td>
      </ng-container>

      <ng-container matColumnDef="so_priority">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the priority of the safety observation.">
          Priority
        </th>
        <td mat-cell *matCellDef="let safety_observation">
          {{ safety_observation.so_priority | titlecase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="so_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the status of the safety observation.">
          Status
        </th>
        <td mat-cell *matCellDef="let safety_observation">
          <span [ngClass]="utils.getSafetyObservationStatusCssClasses(safety_observation.so_status)">
            {{ safety_observation.so_status | titlecase }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="so_rating">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the rating of the safety observation.">
          Rating
        </th>
        <td mat-cell *matCellDef="let safety_observation">
          {{ safety_observation.so_rating }}
        </td>
      </ng-container>

      <ng-container matColumnDef="so_description">
        <th mat-header-cell *matHeaderCellDef matTooltip="The description of the safety observation.">
          Description
        </th>
        <td mat-cell *matCellDef="let safety_observation" class="text-wrap">
          {{ safety_observation.so_description }}
        </td>
      </ng-container>

      <ng-container matColumnDef="so_action_taken">
        <th mat-header-cell *matHeaderCellDef matTooltip="The action taken for the safety observation.">
          Action Taken
        </th>
        <td mat-cell *matCellDef="let safety_observation" class="text-wrap">
          {{ safety_observation.so_action_taken }}
        </td>
      </ng-container>

      <ng-container matColumnDef="reported_by_user">
        <th mat-header-cell *matHeaderCellDef class="no-padding">
          Reported By
        </th>
        <td mat-cell *matCellDef="let safety_observation" class="no-padding">
          <span *ngIf="safety_observation?.reported_by_user">
            <a (click)="onUserPublicView(safety_observation.reported_by_user.hash)"
                 matTooltip="View User Public Profile">
              {{ safety_observation.reported_by_user.contact_person }}
            </a>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the safety observation was reported at.">
          Reported At
        </th>
        <td mat-cell *matCellDef="let safety_observation">
          {{ (safety_observation.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the safety observation was reported at.">
          Reported At (UTC)
        </th>
        <td mat-cell *matCellDef="let safety_observation">
          {{ (safety_observation.date_created * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the safety observation was last updated at.">
          Last Updated At
        </th>
        <td mat-cell *matCellDef="let safety_observation">
          {{ (safety_observation.date_modified * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_modified_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the safety observation was last updated at.">
          Last Updated At (UTC)
        </th>
        <td mat-cell *matCellDef="let safety_observation">
          {{ (safety_observation.date_modified * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let safety_observation">
          <button type="button" mat-button (click)="onEditSafetyObservation(safety_observation.id)" matTooltip="Click this button to edit the safety observation.">
            Edit
          </button>

          <button type="button" mat-button (click)="onViewSafetyObservation(safety_observation.id)" matTooltip="Click this button to view the safety observation.">
            View
          </button>

          <button type="button" mat-button (click)="onDeleteSafetyObservation(safety_observation.id, $event)" matTooltip="Click this button to delete this safety observation.">
            Delete
          </button>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="safetyObservationsService.dataSource?.total | async" [pageSize]="safetyObservationsService.dataSource.limit" [pageSizeOptions]="safetyObservationsService.dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
