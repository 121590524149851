<div mat-dialog-title>
  <div style="width: 100%;">
    <h3>
      Multi-Account Selector

      <button mat-icon-button class="float-right" [mat-dialog-close]="false">
        <mat-icon color="secondary">close</mat-icon>
      </button>
    </h3>
  </div>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">

    <span style="flex: 1 1 auto;"></span>

    <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
      <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search" aria-label="Search" id="search-input" matTooltip="Search for accounts." />

      <button mat-raised-button color="secondary" appMarginRight id="search-button" matTooltip="Run the current search">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>
  </nav>
</div>

<mat-dialog-content>
  <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

  <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null" *ngIf="dataSource.multiple"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
          matTooltip="Toggle select all records in this list.">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)"
          matTooltip="Toggle select this record.">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the account name.">
        Account Name
      </th>
      <td mat-cell *matCellDef="let account">
        {{ account.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let account">

        <button type="button" mat-button (click)="onSelectAccount(account, $event)" matTooltip="Select this account." matTooltipPosition="above" *ngIf="!dataSource.multiple">
          Select Account
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: listColumns" (click)="dataSource.selection.toggle(row.id)"></tr>
  </table>

  <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end" *ngIf="dataSource.multiple">
  <button mat-raised-button color="secondary" type="button" class="mr-3" (click)="onClearAndClose()" mat-dialog-close [disabled]="!dataSource.multiple && !dataSource.selection.hasValue()">Clear & Close</button>
  <button mat-raised-button color="primary" type="button" (click)="onSelectAndClose()" mat-dialog-close [disabled]="!dataSource.multiple && !dataSource.selection.hasValue()">Select & Close</button>
</mat-dialog-actions>
