import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inductions-view',
  templateUrl: './inductions-view.component.html',
  styleUrls: ['./inductions-view.component.scss']
})
export class InductionsViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
