import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";
import {ApiRequestService} from "../../../shared/api-request.service";
import {IndustriesModel} from "../industries.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-industries-edit',
  templateUrl: './industries-edit.component.html',
  styleUrls: ['./industries-edit.component.scss']
})
export class IndustriesEditComponent implements OnInit {

  inProgress = false;

  constructor(
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public industry: IndustriesModel,
    private dialogRef: MatDialogRef<IndustriesEditComponent>,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    if(this.industry.id) {
      this.find(this.industry.id);
    }
  }

  onSubmit() {
    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;
    !this.industry.id ? this.create() : this.update();
  }

  private find(industry_id: number) {
    this.api.makeRequest('get', `v2/admin/industries/${industry_id}`)
      .then((response) => {
        this.industry = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onDelete() {
    this.utils.showModal(
      'Remove Industries',
      `Are you sure you want to remove "${this.industry.name}"?`,
      () => {
        this.api.makeRequest('delete', `v2/admin/industries/${this.industry.id}`)
          .then((response) => {
            this.utils.showToast(`"${this.industry.name}" has been deleted.`);
            this.dialogRef.close(true);
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  private update() {
    this.api
      .makeRequest('put', `v2/admin/industries/${this.industry.id}`, this.industry)
      .then((response: IndustriesModel) => {
        this.onSuccess(`Industry "${response.name}" updated.`);
      })
      .finally(() => (this.inProgress = false));
  }

  private create() {
    this.api
      .makeRequest('post', 'v2/admin/industries', this.industry)
      .then((response: IndustriesModel) => {
        this.onSuccess(`New industry "${response.name}" created.`);
      })
      .finally(() => this.inProgress = false);
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close(true);
  }

}
