import { Component, OnInit, Inject } from '@angular/core';
import { QUICK_ACTIONS_OVERLAY, QUICK_ACTIONS_STRUCT } from './quick-actions.const';
import { OverlayRef } from '@angular/cdk/overlay';

@Component({
  selector: 'app-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.scss']
})
export class QuickActionsComponent implements OnInit {

  constructor(
    @Inject(QUICK_ACTIONS_OVERLAY) public overlayRef: OverlayRef,
    @Inject(QUICK_ACTIONS_STRUCT)  public data: any
  ) { }

  ngOnInit() { }

  onExecuteButtonHandler(button: { text: string, handler: any }, event: any) {
    // Execute the button handler and pass the related event back.
    button.handler(event);
    // Dispose the overlay after a handler is executed.
    this.overlayRef.dispose();
  }

}
