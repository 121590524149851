<div class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" [innerHTML]="utils.modalTitle"></h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body" [innerHTML]="utils.modalContent"></div>

      <div class="modal-footer">
        <ng-container *ngIf="utils.modalOkBtn">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            No
          </button>

          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            *ngIf="utils.modalOkBtn"
            (click)="utils.modalOkBtn()"
            id="modal-yes">
            Yes
          </button>
        </ng-container>

        <ng-container *ngIf="!utils.modalOkBtn">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
