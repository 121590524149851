<button type="button" mat-raised-button class="mt-3 mb-3 ml-3" color="primary" (click)="onGenerateSnapshot()" matTooltip="Click this button to add a new doc to this Safety Plan.">
  Generate Snapshot
</button>

<div class="pl-3 pr-3">
  <mat-hint>
    <small>Safety Plan snapshots are generated in the background. You will be notified by email when it is completed.</small>
  </mat-hint>
</div>

<mat-paginator #paginator1 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

<table mat-table [dataSource]="listDataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? listDataSource.selectAllToggle() : null"
        [checked]="listDataSource.selection.hasValue() && listDataSource.isAllSelected()"
        [indeterminate]="listDataSource.selection.hasValue() && !listDataSource.isAllSelected()"
        matTooltip="Toggle select all Safety Plan snapshots in this list.">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
        (change)="$event ? listDataSource.selection.toggle(row.id) : null"
        [checked]="listDataSource.selection.isSelected(row.id)"
        matTooltip="Toggle select this Safety Plan snapshot.">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the ID of the Safety Plan snapshot.">
      #
    </th>
    <td mat-cell *matCellDef="let snapshot">
      {{ snapshot.id }}
    </td>
  </ng-container>

  <ng-container matColumnDef="snapshot_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the name of the Safety Plan snapshot.">
      Snapshot Name
    </th>
    <td mat-cell *matCellDef="let snapshot">
      {{ snapshot.snapshot_name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the status of the Safety Plan snapshot.">
      Status
    </th>
    <td mat-cell *matCellDef="let snapshot">
      {{ snapshot.status | titlecase }}
    </td>
  </ng-container>

  <ng-container matColumnDef="date_modified">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the Safety Plan snapshot was last updated.">
      Last Updated At
    </th>
    <td mat-cell *matCellDef="let snapshot">
      {{ (snapshot.date_modified * 1000) | timezoneConvertLong }}
    </td>
  </ng-container>

  <ng-container matColumnDef="date_modified_UTC">
    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the Safety Plan snapshot was last updated.">
      Last Updated At (UTC)
    </th>
    <td mat-cell *matCellDef="let snapshot">
      {{ (snapshot.date_modified * 1000) | timezoneConvertUTCLong }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>

    <td mat-cell *matCellDef="let snapshot">
      <a *ngFor="let file of snapshot.files" [href]="file.url" target="_blank" class="m-1">
        <span class="badge badge-pill badge-secondary">
          {{ file.name }}
        </span>
      </a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
</table>

<mat-paginator #paginator2 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
