import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FolderModel} from "../../shared/folder.model";

@Component({
  selector: 'app-account-folders-tabs',
  templateUrl: './account-folders-tabs.component.html',
  styleUrls: ['./account-folders-tabs.component.scss']
})
export class AccountFoldersTabsComponent implements OnInit {

  folder_id: number;
  folder: FolderModel;
  is_training_doc: boolean = false;

  // Used to determine which list of document types to show.
  show_document_types_list_for: 'account'|'user' = 'account';
  // Used to either show or hide the document visibility field.
  show_document_visibility: boolean;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public renderer: Renderer2
  ) { }

  ngOnInit() {
    this.folder_id = this.dialogData.folder.id;
    this.folder = this.dialogData.folder;
    this.is_training_doc = this.dialogData.is_training_doc;
    // Store which list of document types to show.
    this.show_document_types_list_for = this.dialogData.show_document_types_list_for;
    // Store whether to show the document visibility or not.
    this.show_document_visibility = this.dialogData.show_document_visibility || false;
  }

}
