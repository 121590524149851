import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrequalificationModel } from 'src/app/models/prequalification.model';
import { PrequalificationService } from 'src/app/prequalifications/prequalification.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { AppService } from 'src/app/app.service';

declare var saveAs: any;

@Component({
  selector: 'app-contractors-prequalifications',
  templateUrl: './contractors-prequalifications.component.html',
  styleUrls: ['./contractors-prequalifications.component.scss']
})
export class ContractorsPrequalificationsComponent implements OnInit {
  prequal = new PrequalificationModel();
  contractorId: number;
  score: number;

  constructor(
    public route: ActivatedRoute,
    public prequalService: PrequalificationService,
    private router: Router,
    public utils: UtilsService,
    private api: ApiRequestService,
    public app: AppService
  ) {}

  ngOnInit() {
    this.prequal.id = Number(this.route.snapshot.params['prequal_id']);
    this.contractorId = Number(this.route.snapshot.parent.params.contractor_id);
    // this.setPrequal();

    this.getPreQualification();
  }

  getPreQualification() {
    return this.api.makeRequest('get', `v2/contractor-pre-qualifications/${this.contractorId}`)
    .then((response) => {
      this.prequal = response;
    }).catch((errorResponse) => {
      this.router.navigate(['new/edit'], { relativeTo: this.route });
    });
  }

  onDelete() {
    this.utils.showModal(
      'Remove Pre-Qualification',
      `Are you sure you want to remove the "${this.prequal.name}" Pre-Qualification?`,
      () => {
        this.api.makeRequest('delete', `v2/contractor-pre-qualifications/${this.contractorId}`)
        .then(() => {
          this.utils.showToast('The Pre-Qualification was successfully removed.');
          this.router.navigate(['/contractors', this.contractorId, 'prequalifications', 'new', 'edit']);
        }).catch(() => {});
      }
    );
  }

  // setPrequal() {
  //   this.prequalService
  //     .findForContractor(this.contractorId)
  //     .subscribe((response) => {
  //       if (!response.data) {
  //         this.router.navigate(['new/edit'], { relativeTo: this.route });
  //       }
  //       this.prequal = response.data;
  //     });
  // }

  // delete() {
  //   this.prequalService
  //     .remove([this.prequal.id])
  //     .subscribe((prequal: PrequalificationModel) => {
  //       this.utils.showToast(`Deleted: "${prequal.name}".`);
  //       this.router.navigate([
  //         'prequalifications',
  //         {
  //           contractorId: this.prequal.contractor.id
  //         }
  //       ]);
  //     });
  // }

  async onDownloadPDF() {
    const data = await this.prequalService.exportPdf(this.prequal.id);
    saveAs(data, `Prequalification ${this.prequal.name}`);
  }
}
