<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="javascript:void(0)">Related {{ utils.getLangTerm('contractors.plural', 'Contractors') }}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button type="button" color="primary" mat-raised-button (click)="onManageContractors()" matTooltip="Click this button to create add a {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.">
          <fa-icon [icon]="faHardHat"></fa-icon>
          Manage {{ utils.getLangTerm('contractors.plural', 'Contractors') }}
        </button>
      </span>

      <div class="search-container">
        <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
          <input
            class="form-control mr-sm-2"
            type="search"
            [(ngModel)]="dataSource.search"
            name="search"
            placeholder="Search"
            aria-label="Search" id="search-input"
            matTooltip="Search the by name or industry." />
        </form>

        <button
          mat-raised-button
          color="secondary"
          (click)="dataSource.getData(true)"
          appMarginRight
          id="search-button"
          matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon> Search
        </button>
      </div>
    </div>
  </nav>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto"></span>
      <mat-select class="filter-dropdowns mr-3" [(ngModel)]="dataSource.industry" matTooltip="Filter {{ utils.getLangTerm('contractors.plural', 'Contractors') }} by industry." name="industry" (selectionChange)="dataSource.getData(true)">
        <mat-option value="">All Industries</mat-option>
        <mat-option *ngFor="let industry of industries" [value]="industry.name">{{ industry.name }}</mat-option>
      </mat-select>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

      <!-- Multiple item selector for lists. -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Toggle select all files in this list.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)"
            matTooltip="Toggle select this file.">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- The ID of the contractor. -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the id of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.">
          ID #
        </th>
        <td mat-cell *matCellDef="let contractor">
          {{ contractor.id }}
        </td>
      </ng-container>

      <!-- Contractor -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the name of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.">{{ utils.getLangTerm('contractors.singular', 'Contractor') }}</th>
        <td mat-cell *matCellDef="let contractor" class="text-wrap">
          {{ contractor.name }}
        </td>
      </ng-container>

      <!-- Industry -->
      <ng-container matColumnDef="industry">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the industry of the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.">Industry</th>
        <td mat-cell *matCellDef="let contractor">
          {{ contractor.industry }}
        </td>
      </ng-container>

      <!-- Date the contractor was enrolled. -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} was enrolled.">
          Date Enrolled
        </th>
        <td mat-cell *matCellDef="let contractor">
          {{ (contractor.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- UTC Date the contractor was enrolled. -->
      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} was enrolled.">
          Date Enrolled (UTC)
        </th>
        <td mat-cell *matCellDef="let contractor">
          {{ (contractor.date_created * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let contractor">
          <button type="button" mat-button (click)="onSendMessage(contractor.id)" matTooltip="Click this button to send a message to the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}.">
            Send Message
          </button>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
