import { Component, OnInit, ViewChild } from '@angular/core';
import { UserModel } from 'src/app/models/user.model';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import { AccountModel } from 'src/app/models/account.model';
import { NgForm } from '@angular/forms';
import { AccountsSelectorComponent } from '../../accounts/accounts-selector/accounts-selector.component';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import {TradeModel} from "../../trades/trades.model";

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss']
})
export class UsersEditComponent implements OnInit {

  user: UserModel = new UserModel();

  trades: TradeModel[] = [];

  accountsDisplayedColumns: string[] = [
    'account_id',
    'name',
    'subscription',
    'date_created',
    'permissions',
    'actions'
  ];

  accountsDataSource = new UserAccountsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  inProgress: boolean = false;

  passwordValidated: boolean = false;
  showPasswordInputs: boolean = false;
  passwordFieldType: string = 'password';
  confirmPasswordFieldType: string = 'password';

  // Mobile field Country Code selector values
  phone_input_id: string = 'phone-1';
  phone_number: string = '';
  phone_country_code: string = '';
  phone_dial_code: string = '';
  phone_e164: string = '';
  phone_error_state: boolean = false;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.user.id = Number(this.route.snapshot.params['id']);
    if ( this.user.id ) {
      this.getUser();
    }

    // Get trades from the API.
    this.getTrades();
  }

  ngAfterViewInit() {
    if ( !this.user.id ) {
      return;
    }

    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.accountsDataSource.limit = this.paginator.pageSize;
      this.accountsDataSource.offset = this.paginator.pageIndex;
      this.accountsDataSource.order_by = this.sort.active;
      this.accountsDataSource.order = this.sort.direction;

      this.accountsDataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  private async getTrades() {
    this.trades = await this.utils.getTrades();
  }

  /**
   * Get Output values from phone input component.
   */
  getOutputPhoneDetails($event) {
    if($event) {
      let phone = $event;
      this.phone_number = phone.hasOwnProperty('number') && phone.number !== null ? phone.number : '';
      this.phone_country_code = phone.hasOwnProperty('countryCode') && phone.countryCode !== null ? phone.countryCode : '';
      this.phone_dial_code = phone.hasOwnProperty('dialCode') && phone.dialCode !== null ? phone.dialCode : '';
      this.phone_e164 = phone.hasOwnProperty('e164Number') && phone.e164Number !== null ? phone.e164Number : '';
      this.phone_error_state = phone.hasOwnProperty('errorState') && phone.errorState !== null ? phone.errorState : true;
    }
  }

  /**
   * Set Input values for phone input component.
   */
  private initPhoneDetails() {
    this.phone_number = this.user.mobile;
    this.phone_country_code = this.user.mobile_country_code;
    this.phone_dial_code = this.user.mobile_dial_code;
    this.phone_e164 = this.user.mobile_e164;
  }

  getUser() {
    return this.api.makeRequest('get', `v2/admin/users/${this.user.id}`)
    .then((response: UserModel) => {
      this.user.apply(response);
      this.initPhoneDetails();
      // Set the user id in the datasource and get the data
      this.accountsDataSource.userId = this.user.id;
      this.accountsDataSource.getData();
    });
  }

  // Redirect the user to the specified account by id
  onRedirectToAccount(employerId: number) {
    this.utils.refreshPage(['/', 'admin', 'accounts', employerId, 'edit']);
  }

  onSubmit(form: NgForm) {
    // Check form validation.
    if( !form.valid ) {
      this.utils.showModal('Form Validation Failed','Please enter all required fields.');
      return;
    }

    // Check Error states of phone input then continue submission
    if (this.phone_error_state) {
      if(this.phone_number.length < 3) {
        this.utils.showModal('Form Validation Failed','A Phone number must be at least 3 characters long.');
      } else {
        this.utils.showModal('Form Validation Failed', 'Please enter all required fields.');
      }
      return;
    }

    // Terminate early if another process is in progress.
    if ( this.inProgress ) {
      return;
    }

    // Update User details with Mobile field Country Code selector values
    this.user.mobile = this.phone_number;
    this.user.mobile_country_code = this.phone_country_code;
    this.user.mobile_dial_code = this.phone_dial_code;
    this.user.mobile_e164 = this.phone_e164;

    this.inProgress = true;

    if ( this.user.id ) {
      this.api.makeRequest('put', `v2/admin/users/${this.user.id}`, this.user)
      .then((response: UserModel) => {
        this.user.apply(response);
        this.utils.showModal('User Updated', 'The user was sucessfully updated.');
      })
      .finally(() => {
        this.inProgress = false;
      });
    } else {
      this.api.makeRequest('post', `v2/admin/users`, this.user)
      .then((response: UserModel) => {
        this.utils.showToast('The new user was successfully created.');
        this.utils.refreshPage(['/admin', 'users', response.id, 'edit']);
      })
      .finally(() => {
        this.inProgress = false;
      });
    }
  }

  /**
   * Send an API request to update the user's email, username or password.
   * @param form
   */
  onUpdatePassword(form: NgForm) {
    // Make sure all required fields are filled out.
    if( !form.valid ) {
      this.utils.showModal('Form Validation Failed','Please enter all required fields.');
      return;
    }
    // Only existing users may be updated.
    if ( !this.user.id ) {
      this.utils.showModal('Error','You can only update the password for a pre-existing user.');
      return;
    }
    // Change the in progress flag to true.
    this.inProgress = true;
    // Send the API request to update the user's email, username or password.
    this.api.makeRequest('put', `v2/admin/users/${this.user.id}/update-password`, this.user)
    .then((response: UserModel) => {
      // Apply the response to the user model.
      this.user.apply(response);
      this.utils.showModal('Password Updated', 'The user\'s login credentials was successfully updated.');
    })
    .finally(() => {
      this.inProgress = false;
    });
  }

  onSelectAccounts() {
    this.utils.showComponentDialog(AccountsSelectorComponent, {
      multiple: true,
      returnObjects: true,
      selected: this.user.accounts
    }, {
      disableClose: false
    }, (accounts: AccountModel[]) => {
      if ( accounts && accounts.length > 0 ) {
        let accountNames: string[] = [];
        let accountIds: number[] = [];
        accounts.forEach((account) => {
          accountNames.push(account.name);
          accountIds.push(account.id);
        });
        this.utils.showModal('Link Selected Accounts', 'Are you sure you want to link the selected accounts (' + accountNames.join(', ') + ') with the user?', () => {
          this.inProgress = true;
          this.api.makeRequest('post', `v2/admin/users-accounts`, {
            permissions: 'Employee',
            account_ids: accountIds,
            user_ids: [this.user.id]
          })
          .then(() => {
            this.utils.showToast('The accounts were linked to the user.');
            this.accountsDataSource.getData(true);
            this.inProgress = false; // TBD: test to see if we need to place it in finally instead.
          })
          .then(() => {
            this.getUser();
          });
        });
      }
    });
  }

  onSelectEmployer() {
    this.utils.showComponentDialog(AccountsSelectorComponent, {
      multiple: false,
      returnObjects: true,
      selected: [{
        id: this.user.account_id
      }]
    }, {
      disableClose: false
    }, (account: any) => {
      if ( account ) {
        this.utils.showModal('Selected Employer', 'Are you sure you want to link the selected account, ' + account.name + ', as the employer of the new user?', () => {
          this.user.account_id = account.id;
          this.user.employer = account;
        });
      }
    });
  }

  onUpdateUserPermissions(account: any) {
    this.utils.showModal('Update User Permissions', 'Are you sure you want to update the "' + account.name + '" account permissions for this user to ' + account.permissions + '?', () => {
      this.inProgress = true;
      this.api.makeRequest('put', `v2/admin/users-accounts`, {
        permissions: account.permissions,
        account_id: account.account_id,
        user_id: this.user.id
      }).then(() => {
        this.utils.showToast('The user\'s account permissions were updated.');
        this.accountsDataSource.getData(true);
        this.inProgress = false;
      });
    });
  }

  onDestroyUserAccount(link: any, evt: any) {
    this.utils.showQuickActions(evt.target, `Are you sure you want to remove the '${link.name}' user account link?`, [
      {
        text: 'Yes',
        handler: () => {
          this.inProgress = true;
          this.api.makeRequest('delete', `v2/admin/users-accounts/${link.account_id}/${link.user_id}`)
          .then(() => {
            this.utils.showToast('The user account link were removed.');
            this.accountsDataSource.getData(true);
            this.inProgress = false;
          })
          .then(() => {
            this.getUser();
          });
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  getOutputPasswordValidated($event) {
    this.passwordValidated = $event;
  }

  /**
   * Toggles the visibility of the password field based on the given field parameter.
   *
   * @param {string} field - The name of the field to toggle visibility for ('password' or 'confirmPassword').
   * @returns {void}
   */
  togglePasswordVisibility(field: string): void {
    if (field === 'password') {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    } else if (field === 'confirmPassword') {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
    }
  }
}

export class UserAccountsDataSource extends ApiDataSource {

  order_by = 'name';
  order = 'asc';

  userId: number;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/admin/users-accounts`, resetOffset, {
      admin_user_id: this.userId
    });
  }
}
