<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class="mr-auto">
    <button mat-raised-button routerLink="/sites" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>

    <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
    </button>

    <button mat-raised-button [routerLink]="utils.prepareSiteRelatedLink(site_id, child_id ? parent_id : null, 'edit/emergency-evacuation-plans')" *ngIf="site.id" appMarginRight>
      <mat-icon>edit</mat-icon> Edit
    </button>

    <button mat-raised-button (click)="onExportEmergencyEvacuationPlan()">
      <mat-icon>cloud_download</mat-icon> Export
    </button>
  </span>
</nav>

<mat-tab-group>
  <mat-tab label="Emergency Evacuation Plan">

    <div class="app-margin">

      <div class="container-fluid">
        <div class="row">
          <div class="col">

            <h5 *ngIf="site.emergency_phone || site.hospital_address || site.hospital_phone || site.medical_centre_address || site.medical_centre_phone">Emergency Numbers & Locations</h5>

            <ng-container *ngIf="site.emergency_phone">
              <b>Emergency Phone</b>
              <p id="emergency_phone">{{ site.emergency_phone }}</p>
            </ng-container>

            <ng-container *ngIf="site.hospital_address">
              <b>Hospital Address</b>
              <p id="hospital_address">{{ site.hospital_address }}</p>
            </ng-container>

            <ng-container *ngIf="site.hospital_phone">
              <b>Hospital Phone</b>
              <p id="hospital_phone">{{ site.hospital_phone }}</p>
            </ng-container>

            <ng-container *ngIf="site.medical_centre_address">
              <b>Medical Centre Address</b>
              <p id="medical_centre_address">{{ site.medical_centre_address }}</p>
            </ng-container>

            <ng-container *ngIf="site.medical_centre_phone">
              <b>Medical Centre Phone</b>
              <p id="medical_centre_phone">{{ site.medical_centre_phone }}</p>
            </ng-container>

            <h5 *ngIf="site.first_aid_kit_location || site.aed_location || site.fire_extinguisher_location">First Aid Kit, AED & Fire Extinguisher</h5>

            <ng-container *ngIf="site.first_aid_kit_location">
              <b>First Aid Kit Location</b>
              <p id="first_aid_kit_location">{{ site.first_aid_kit_location }}</p>
            </ng-container>

            <ng-container *ngIf="site.aed_location">
              <b>AED (Automated External Defibrillator) Location</b>
              <p id="aed_location">{{ site.aed_location }}</p>
            </ng-container>

            <ng-container *ngIf="site.fire_extinguisher_location">
              <b>Fire Extinguisher Location</b>
              <p id="fire-extinguisher-location">{{ site.fire_extinguisher_location }}</p>
            </ng-container>

          </div>

          <div class="col">

            <h5 *ngIf="site.alarm_description || site.assembly_location">Evacuation Alerts & Assembly Locations</h5>

            <ng-container *ngIf="site.alarm_description">
              <b>Description of Alarm</b>
              <p id="alarm_description">{{ site.alarm_description }}</p>
            </ng-container>

            <ng-container *ngIf="site.assembly_location">
              <b>Assembly Location</b>
              <p id="assembly_location">{{ site.assembly_location }}</p>
            </ng-container>

            <h5 *ngIf="site.worksafe_phone || site.civil_defence_phone || site.poison_centre_phone || site.power_phone || site.faults_phone">Additional Contact Numbers</h5>

            <ng-container *ngIf="site.worksafe_phone">
              <b>WorkSafe Phone</b>
              <p id="worksafe_phone">{{ site.worksafe_phone }}</p>
            </ng-container>

            <ng-container *ngIf="site.civil_defence_phone">
              <b>Civil Defence Phone</b>
              <p id="civil_defence_phone">{{ site.civil_defence_phone }}</p>
            </ng-container>

            <ng-container *ngIf="site.poison_centre_phone">
              <b>Poison Centre Phone</b>
              <p id="poison_centre_phone">{{ site.poison_centre_phone }}</p>
            </ng-container>

            <ng-container *ngIf="site.power_phone">
              <b>Power (Customer Service) Phone</b>
              <p id="power_phone">{{ site.power_phone }}</p>
            </ng-container>

            <ng-container *ngIf="site.faults_phone">
              <b>24Hr Faults Phone</b>
              <p id="faults_phone">{{ site.faults_phone }}</p>
            </ng-container>

          </div>
        </div>

        <div class="row" appMarginBottom>
          <div class="col">
            <small><b>Note:</b> Fields that are left empty will not show to users.</small>
          </div>
        </div>
      </div>

    </div>

  </mat-tab>

  <mat-tab label="Safety Managers">
    <app-sites-safety-managers [site_id]="site.id"></app-sites-safety-managers>
  </mat-tab>

  <mat-tab label="First Aiders">
    <app-sites-first-aiders [site_id]="site.id"></app-sites-first-aiders>
  </mat-tab>

  <mat-tab label="Emergency Evacuation Plan Map">

    <ng-container *ngIf="site.evacuation_map_url">
      <img *ngIf="utils.isImage(site.evacuation_map_url); else showPDF" [src]="site.evacuation_map_url" class="profile-picture" alt="Emergency Evacuation Plan Map" appMargin />
      <ng-template #showPDF>
        <mat-nav-list dense>
          <h3 matSubheader>Uploaded PDF</h3>
          <a mat-list-item [href]="site.evacuation_map_url" target="_blank">Emergency Response Plan Map <mat-icon appMarginLeft>launch</mat-icon></a>
        </mat-nav-list>
      </ng-template>
    </ng-container>

  </mat-tab>
</mat-tab-group>


