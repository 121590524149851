import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { CustomDataSource } from '../../utils/custom-data-source';
import { SitesCodesClaimComponent } from './codes-claim/sites-codes-claim.component';
import {SitesCodesFilterComponent} from "./sites-codes-filter/sites-codes-filter.component";
import {CodeModel} from "../../models/code.model";

@Component({
  selector: 'app-codes',
  templateUrl: './sites-codes.component.html',
  styleUrls: ['./sites-codes.component.css']
})
export class SitesCodesComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'site',
    'name',
    'feature',
    'type',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];
  dataSource = new CodesDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  parent_id: number;
  child_id: number;
  site_id: number;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;
      // Add teh site id as the parent id of the data source.
      this.dataSource.site_id = this.site_id;
      this.dataSource.site_ids.push(this.dataSource.site_id);
      // Get data from the data source.
      this.dataSource.getData();
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC dates by it's corresponding date field.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  claimCode() {
    const dialogRef = this.dialog.open(SitesCodesClaimComponent, {
      width: '300px',
      data: { site_id: this.dataSource.site_id }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) { this.dataSource.getData(); }
    });
  }

  onRemove(code: CodeModel) {
    this.utils.showModal(
      'Remove QR Code',
      'Are you sure you want to remove this QR code?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'sites/' + code.site_id + '/qrcodes/' + code.id,
          {},
          {},
          (response) => {
            this.utils.showToast('The QR code was removed.');
            this.dataSource.selection.deselect(code.id);
            this.dataSource.getData();
          }
        );
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Remove Selected QR Codes',
      'Are you sure you want to remove the selected QR codes?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'sites/' +
            this.dataSource.site_id +
            '/qrcodes/' +
            this.dataSource.selection.selected.join(','),
          {},
          {},
          (response) => {
            this.utils.showToast('The QR code was removed.');
            this.dataSource.selection.clear();
            this.dataSource.getData();
          }
        );
      }
    );
  }

  onFilter() {
    this.utils.showComponentDialog(
      SitesCodesFilterComponent,
      {
        selected_site_ids: this.dataSource.site_ids,
        parent_site_id: this.parent_id
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {

          this.dataSource.site_ids = results.site_ids;

          if ( this.dataSource.site_ids.length === 0 ) {
            this.dataSource.site_ids.push(this.site_id);
          }

          this.dataSource.getData();
        }
      }
    );
  }
}

/**
 * The data source to get the list of QR codes for the site.
 */
export class CodesDataSource extends CustomDataSource {

  site_id = 0;

  site_ids: number[] = [];

  // record sorting and direction
  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('sites/' + this.site_id + '/qrcodes', resetOffset, () => {}, {
      site_ids: this.site_ids.join(',')
    });
  }
}
