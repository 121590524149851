import { AccountModel } from './account.model';
import { UserModel } from './user.model';
import { Auditable } from '../shared/auditable.model';
import { HazardModel } from './hazard.model';
import { FolderModel } from '../shared/folder.model';
import { TagModel } from "./tag.model";

export class SiteModel extends Auditable {
  id = null;
  name = '';
  location = '';
  geofence = '';
  induction_content = '&nbsp;';

  onsite_manager_name = '';
  onsite_manager_email = '';
  onsite_manager_mobile = '';
  onsite_manager_visible = true;

  selected_contractors: number[] = [];

  // defining the site type and status on 29/10/18
  site_type = 'Commercial Site';
  site_status = 'Under Active Management';

  account: AccountModel;
  open_time = '06:00:00';
  close_time = '18:00:00';

  is_logbook = 0;

  // timezone of current location
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  enable_after_hours_notification = true;
  after_hours_notification_duration: number = 75;

  max_geofence_size: number = 50000;
  geofence_size: number = 0;

  /**
   * Job number used for cross referencing other systems.
   */
  external_reference = '';

  // Emergency Evacuation Plan Fields.
  emergency_phone: string = '';
  aed_location: string = '';
  evacuation_map_url: string = '';
  medical_centre_address = '';
  medical_centre_phone = '';
  worksafe_phone = '0800030040';
  civil_defence_phone = '0800222200';
  poison_centre_phone = '034747000';
  power_phone = '';
  faults_phone = '';
  fire_extinguisher_location = '';
  first_aid_kit_location = '';
  assembly_location = '';
  trained_first_aider_id: number;
  safety_manager_id: number;
  alarm_description = '';
  trained_first_aider = new UserModel();
  safety_manager = new UserModel();

  hospital_address: string = '';
  hospital_phone: string = '';

  /**
   * Mobile Country Code fields.
   */
  emergency_phone_e164: string = '';
  emergency_phone_country_code: string = '';
  emergency_phone_dial_code: string = '';
  hospital_phone_e164: string = '';
  hospital_phone_country_code: string = '';
  hospital_phone_dial_code: string = '';
  medical_centre_phone_e164: string = '';
  medical_centre_phone_country_code: string = '';
  medical_centre_phone_dial_code: string = '';
  worksafe_phone_e164: string = '';
  worksafe_phone_country_code: string = '';
  worksafe_phone_dial_code: string = '';
  civil_defence_phone_e164: string = '';
  civil_defence_phone_country_code: string = '';
  civil_defence_phone_dial_code: string = '';
  poison_centre_phone_e164: string = '';
  poison_centre_phone_country_code: string = '';
  poison_centre_phone_dial_code: string = '';
  power_phone_e164: string = '';
  power_phone_country_code: string = '';
  power_phone_dial_code: string = '';
  faults_phone_e164: string = '';
  faults_phone_country_code: string = '';
  faults_phone_dial_code: string = '';

  site_access_option: string = 'signin:induction';  // signin:only, signin:induction, signin:all

  hazards: HazardModel[] = [];

  folders: FolderModel[] = [];

  site_risk_assessment: string = 'moderate';
  show_site_risk_assessment: boolean = false;

  date_created: number = 0;

  sssp: any = null;

  estimated_project_start_date: number = 0;
  estimated_project_end_date: number = 0;

  short_description: string = '';

  isExpanded: boolean = false;

  parent_site_id: number = null;
  parent_site: SiteModel = null;

  /**
   * Tags that belongs to Sites?
   */
  tags: TagModel[] = [];
  tag_ids: number[] = [];

  qr_code_auto_signout_enabled: boolean = true;

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = null;
    this.name = '';
    this.location = '';
    this.geofence = '';
    this.induction_content = '';
    this.onsite_manager_name = '';
    this.onsite_manager_email = '';
    this.onsite_manager_mobile = '';
    this.onsite_manager_visible = true;

    this.selected_contractors.length = 0;

    this.site_type = 'Commercial Site';
    this.site_status = 'Under Active Management';
    this.open_time = '08:00:00';
    this.close_time = '17:00:00';
    this.is_logbook = 0;

    this.enable_after_hours_notification = true;
    this.after_hours_notification_duration = 75;

    this.medical_centre_address = '';
    this.medical_centre_phone = '';
    this.worksafe_phone = '0800030040';
    this.civil_defence_phone = '0800222200';
    this.poison_centre_phone = '034747000';
    this.power_phone = '';
    this.faults_phone = '';
    this.fire_extinguisher_location = '';
    this.first_aid_kit_location = '';
    this.assembly_location = '';
    this.trained_first_aider_id = 0;
    this.safety_manager_id = 0;
    this.alarm_description = '';
    this.trained_first_aider = new UserModel();
    this.safety_manager = new UserModel();

    this.hazards.length = 0;

    this.site_risk_assessment = 'moderate';
    this.show_site_risk_assessment = false;

    this.date_created = 0;

    this.sssp = null;

    this.estimated_project_start_date = 0;
    this.estimated_project_end_date = 0;

    this.short_description = '';

    this.isExpanded = false;

    this.parent_site = null;
    this.parent_site_id = null;

    this.tags = [];
    this.tag_ids = [];

    this.qr_code_auto_signout_enabled = true;
  }
}
