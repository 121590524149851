import { Component, OnInit } from '@angular/core';
import {EmployeeService} from "../employee.service";

@Component({
  selector: 'app-employees-assigned-forms',
  templateUrl: './employees-assigned-forms.component.html',
  styleUrls: ['./employees-assigned-forms.component.scss']
})
export class EmployeesAssignedFormsComponent implements OnInit {

  constructor(
    public employeeService: EmployeeService
  ) {}

  ngOnInit() {}
}
