import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '../directives/directives.module';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { EmployeesDocsComponent } from './employees-docs/employees-docs.component';
import { EmployeesAddComponent } from './employees-add/employees-add.component';
import { EmployeesLinkedSitesComponent } from './employees-linked-sites/employees-linked-sites.component';
import { EmployeesNotificationsComponent } from './employees-notificaions/employees-notifications/employees-notifications.component';
import { EmployeesRoutingModule } from './employees-routing.module';
import { EmployeesSelectorComponent } from './employees-selector/employees-selector.component';
import { EmployeesTabsComponent } from './employees-tabs/employees-tabs.component';
import { EmployeesComponent } from './employees/employees.component';
import { PipesModule } from '../pipes/pipes.module';
import {UserPublicProfileComponent} from "../shared/user-public-profile/user-public-profile.component";
import {EmployeesImporterComponent} from "./employees-importer/employees-importer.component";
import {EmployeesDocsImporterComponent} from "./employees-docs-importer/employees-docs-importer.component";
import {EmployeesSitesVisitedComponent} from "./employees-sites-visited/employees-sites-visited.component";
import {EmployeesSitesWorkedOnComponent} from "./employees-sites-worked-on/employees-sites-worked-on.component";
import {SharedModule} from "../shared/shared.module";
import {EmployeesAssignedFormsComponent} from "./employees-assigned-forms/employees-assigned-forms.component";
import {
  EmployeesNotificationsSitesComponent
} from './employees-notificaions/employees-notifications-sites/employees-notifications-sites.component';
import {
  EmployeesNotificationsTabsComponent
} from './employees-notificaions/employees-notifications-tabs/employees-notifications-tabs.component';
import {
  EmployeesNotificationsRolesComponent
} from './employees-notificaions/employees-notifications-roles/employees-notifications-roles.component';

@NgModule({
  declarations: [
    EmployeesComponent,
    EmployeeDetailsComponent,
    EmployeesDocsComponent,
    EmployeesAddComponent,
    EmployeesLinkedSitesComponent,
    EmployeesNotificationsComponent,
    EmployeesSelectorComponent,
    EmployeesTabsComponent,
    EmployeesImporterComponent,
    EmployeesDocsImporterComponent,
    EmployeesSitesVisitedComponent,
    EmployeesSitesWorkedOnComponent,
    EmployeesAssignedFormsComponent,
    EmployeesNotificationsTabsComponent,
    EmployeesNotificationsSitesComponent,
    EmployeesNotificationsRolesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    EmployeesRoutingModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatListModule,
    MatTabsModule,
    MatTooltipModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatSidenavModule,
    MatCardModule,
    MatProgressSpinnerModule,
    PipesModule,
    SharedModule
  ],
  entryComponents: [SitesSelectorComponent, UserPublicProfileComponent]
})
export class EmployeesModule {}
