<div mat-dialog-title>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5>Tool</h5>
      </div>
      <div class="col">
        <button mat-icon-button class="float-right" mat-dialog-close>
          <mat-icon color="secondary">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<mat-dialog-content>
  <p>{{ tool.title }}</p>

  <h6>Description</h6>
  <p>{{ tool.description }}</p>

  <ng-container>
    <h6>Industry</h6>
    <p>{{tool.industry ? tool.industry.name : "All"}}</p>
  </ng-container>

  <ng-container *ngIf="tool.date_created">
    <h6>Expires At</h6>
    <p>{{ tool.expires_at ? ((tool.expires_at * 1000) | timezoneConvertLong) : 'Not Set' }}</p>
  </ng-container>

  <ng-container>
    <h6>Maintenance & Safety Checks</h6>
    <p>{{ tool.maintenance_safety_checks.join(', ') }}</p>
  </ng-container>

  <ng-container *ngIf="tool.created_by_user">
    <h6>Created By</h6>
    <p>
      <a (click)="onUserPublicView(tool.created_by_user.hash)"
         matTooltip="View User Public Profile">
        {{ tool.created_by_user.contact_person }}
      </a>
    </p>
  </ng-container>

  <ng-container *ngIf="tool.date_created">
    <h6>Created At</h6>
    <p>{{ (tool.date_created * 1000) | timezoneConvertLong}}</p>
  </ng-container>

  <ng-container *ngIf="tool.updated_by_user">
    <h6>Last Updated By</h6>
    <p>
      <a (click)="onUserPublicView(tool.updated_by_user.hash)"
         matTooltip="View User Public Profile">
        {{ tool.updated_by_user.contact_person }}
      </a>
    </p>
  </ng-container>

  <ng-container *ngIf="tool.date_modified">
    <h6>Last Updated At</h6>
    <p>{{ (tool.date_modified * 1000) | timezoneConvertLong}}</p>
  </ng-container>

  <ng-container *ngIf="tool?.files?.length">
    <h6>Uploaded Files</h6>
    <app-files-list [view]="true" [files]="tool?.files"></app-files-list>
  </ng-container>
</mat-dialog-content>
