<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class=" mr-auto">
    <button mat-raised-button routerLink="/sites" appMarginRight tourAnchor="tour.navigate.sites">
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>

    <button mat-raised-button *ngIf="site.parent_site_id" [routerLink]="'/sites/'+site.parent_site_id+'/children'" appMarginRight tourAnchor="tour.navigate.sites">
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
    </button>

    <button
      mat-raised-button
      [routerLink]="utils.prepareSiteRelatedLink(site.id, site.parent_site_id, 'view/details')"
      *ngIf="site.id"
      appMarginRight>
      <mat-icon>visibility</mat-icon>
      View
    </button>

    <button
      mat-raised-button
      (click)="onRemove()"
      color="warn"
      *ngIf="site.id"
      appMarginRight >
      <mat-icon>delete</mat-icon>
      Delete
    </button>
  </span>

  <!--button mat-icon-button (click)="onStartTour()" appMarginRight><mat-icon>help</mat-icon></button-->
</nav>

<mat-tab-group *ngIf="site.id" class="bg-white">
  <mat-tab label="Overview">
    <ng-template matTabContent>
      <app-sites-details-edit></app-sites-details-edit>
    </ng-template>
  </mat-tab>
  <mat-tab label="Geofence Map">
    <ng-template matTabContent>
      <app-sites-details-map></app-sites-details-map>
    </ng-template>
  </mat-tab>
  <mat-tab label="Settings">
    <ng-template matTabContent>
      <app-sites-settings></app-sites-settings>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<app-sites-details-edit *ngIf="!site.id"></app-sites-details-edit>
