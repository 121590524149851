import { Component, OnInit, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { DynamicFormsCategoryModel } from '../dynamic-forms-category.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dynamic-forms-categories-edit',
  templateUrl: './dynamic-forms-categories-edit.component.html',
  styleUrls: ['./dynamic-forms-categories-edit.component.scss']
})
export class DynamicFormsCategoriesEditComponent implements OnInit {

  category: DynamicFormsCategoryModel = {} as DynamicFormsCategoryModel;

  constructor(
    private utils: UtilsService,
    private api: ApiRequestService,
    private dialogRef: MatDialogRef<DynamicFormsCategoriesEditComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit() {
    // Get the category id
    const category_id = Number(this.dialogData['category_id']);
    if ( category_id ) {
      this.api.makeRequest('get', `v2/dynamic-forms-categories/${category_id}`)
      .then((response: DynamicFormsCategoryModel) => {
        this.category = response;
      })
      .catch((errorResponse: any) => {
        this.utils.handleAPIErrors(errorResponse);
      });
    }
  }

  /**
   * Validate the form input and save the data.
   * @param form The NgForm object that is used for validation.
   * @param autoCloseDialog An optional param to indicate if the dialog should be closed or not.
   * @returns Nothing.
   */
  onSubmit(form: NgForm, autoCloseDialog?: boolean) {
    // Validate the submitted form.
    if ( !form.valid ) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    // Store the request Promise in this.
    let request: Promise<any>;
    let hasErrors: boolean = false;

    if ( this.category.id ) {
      // Make an update request if the record id is present.
      request = this.api.makeRequest('put', `v2/dynamic-forms-categories/${this.category.id}`, this.category)
      .then((response: DynamicFormsCategoryModel) => {
        this.category = response;
        this.utils.showToast(`The category "${this.category.category}" was updated.`);
      })
      .catch((errorResponse: any) => {
        hasErrors = true;
        this.utils.handleAPIErrors(errorResponse);
      });
    } else {
      // Make a "create" request when the record id is not present.
      request = this.api.makeRequest('post', `v2/dynamic-forms-categories`, this.category)
      .then((response: DynamicFormsCategoryModel) => {
        this.category = response;
        this.utils.showToast(`The category "${this.category.category}" was created. You can now update it.`);
      })
      .catch((errorResponse: any) => {
        hasErrors = true;
        this.utils.handleAPIErrors(errorResponse);
      });
    }

    // Check if the dialog should close. It should only close if there are no API errors.
    request.finally(() => {
      if ( autoCloseDialog && !hasErrors ) {
        this.onClose();
      }
    });
  }

  /**
   * Close the dialog and respond with the category object.
   */
  onClose() {
    this.dialogRef.close(this.category);
  }

}
