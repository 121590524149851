import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-user-messages-filter',
  templateUrl: './user-messages-filter.component.html',
  styleUrls: ['./user-messages-filter.component.scss']
})
export class UserMessagesFilterComponent implements OnInit {

  dataSource = new UserMessagesFilterDataSource(); // No API calls needed

  constructor(
    private app: AppService,
    public location: Location,
    private utils: UtilsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserMessagesFilterComponent>
    // private api: ApiRequestService
  ) {

    // set a default priority of nothing if no values are passed through
    if (typeof this.data['filter_priority'] === 'undefined') {
      this.data['filter_priority'] = "";
    }

    // set a default due date of nothing if no values are passed through
    if (typeof this.data['date_filter_range'] === 'undefined') {
      this.data['date_filter_range'] = [];
    }
  }

  ngOnInit() {
    this.dataSource.filter_priority = this.data['filter_priority'];
    this.dataSource.date_filter_range = this.data['date_filter_range'];
  }

  getOutputDateRange($event) {
    this.dataSource.date_filter_range = $event;
  }

  onClearAndClose() {
    this.dataSource.filter_priority = "";
    this.dataSource.date_filter_range = [];

    this.onSaveAndClose();
  }

  onSaveAndClose() {
    let response: any;

    response = {
      priority: this.dataSource.filter_priority,
      date_filter_range: this.dataSource.date_filter_range
    }

    this.dialogRef.close(response);
  }

}

/**
 * the data source class should contain everything needed to get data for the feature.
 */
export class UserMessagesFilterDataSource {

  filter_priority: string = "";
  date_filter_range: Date[] = [];

  constructor() {
    this.filter_priority = "";
    this.date_filter_range = []
  }

}
