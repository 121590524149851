import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { AppService } from '../../app.service';
import { HazardModel } from '../../models/hazard.model';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { FileModel } from 'src/app/shared/file.model';
import { FileService } from 'src/app/shared/file.service';
import { RISK_LEVEL_OF_CONTROLS } from '../../shared/risk-level-of-controls';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-hazards-view',
  templateUrl: './hazards-view.component.html',
  styleUrls: ['./hazards-view.component.css']
})
export class HazardsViewComponent implements OnInit {
  loc_list = RISK_LEVEL_OF_CONTROLS;
  media: FileModel[];

  hazard: HazardModel = new HazardModel();

  // columns to show and the data source
  mediaDisplayedColumns = ['name', 'icon'];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public utils: UtilsService,
    public app: AppService,
    private api: ApiService,
    private fileService: FileService
  ) {}

  ngOnInit() {
    // get the site id
    this.hazard.id = Number(this.route.parent.snapshot.params['hazard_id']);

    this.fileService
      .findAllForHazard(this.hazard.id)
      .subscribe((response) => (this.media = response.data));

    // check if it's a valid id otherwise it's a new site being created.
    // NaN, NULL, 0, any sequence of letters will trigger a new site creation form.
    if (this.hazard.id) {
      this.api.laravelApiRequest(
        'get',
        'hazards/' + this.hazard.id,
        {},
        {},
        (response) => {
          // this.hazard.apply(response.data);
          this.hazard = response.data
        },
        (error) => {
          this.utils.showModal('Error', error.message);
        }
      );
    } else {
      this.router.navigate(['hazards']);
    }
  }

  onRemove() {
    this.utils.showModal(
      'Remove Hazard/Risk',
      'Are you sure you want to remove this Hazard/Risk?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'hazards/' + this.hazard.id,
          {},
          {},
          () => {
            this.utils.showToast('The Hazard/Risk was removed.');
            this.router.navigate(['hazards']);
          },
          (error) => {
            this.api.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  downloadMedia(id: number, name: string) {
    this.api
      .laravelApiDownloadObservable(`media/export/${id}`)
      .subscribe((data) => {
        saveAs(data, name);
      });
  }

  getMatIcon(name: string) {
    const extension = name.split('.').pop();
    const video = 'video_label';
    const mappings = {
      jpg: 'image',
      png: 'image',
      gif: 'image',
      bmp: 'image',
      mp4: video,
      wmv: video,
      webm: video,
      avi: video,
      flv: video
    };
    let icon = mappings[extension];
    if (!icon) { icon = 'text_format'; }
    return icon;
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }
}

export class HazardsLinkedSitesDataSource extends CustomDataSource {
  // record sorting and direction
  sort_by = 'name';
  sort_order = 'asc';

  hazard_id = 0;

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      'hazards/' + this.hazard_id + '/linked-sites',
      resetOffset
    );
  }
}
