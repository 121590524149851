<div mat-dialog-title>
  <div class="d-flex">
    <h3>Select {{ utils.getLangTerm('employees.plural', 'Employees') }}</h3>

    <span style="flex: 1 1 auto;"></span>

    <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2"
        type="search"
        [(ngModel)]="dataSource.search"
        name="search"
        placeholder="Search"
        aria-label="Search"/>
    </form>

    <button mat-raised-button (click)="dataSource.getData(true)" appMarginRight>
      <mat-icon>search</mat-icon> Search
    </button>
  </div>
</div>

<mat-dialog-content>
  <div class="mat-elevation-z8 no-borders-or-shadows">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="name"
      matSortDirection="asc"
      matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.user_id) : null"
            [checked]="dataSource.selection.isSelected(row.user_id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="contact_person">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Full Name
        </th>
        <td mat-cell *matCellDef="let element">
          <a (click)="onUserPublicView(element.hash, $event)"
             matTooltip="View User Public Profile">
            {{ element.contact_person }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }} </th>
        <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
      </ng-container>

      <ng-container matColumnDef="trade">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Title</th>
        <td mat-cell *matCellDef="let element">{{ element.trade || '-' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="dataSource.selection.toggle(row.user_id)"></tr>
    </table>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <mat-paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>

  <button
    mat-raised-button
    color="primary"
    type="button"
    mat-dialog-close
    (click)="onSaveAndClose()"
    [disabled]="!data['multiple'] && !dataSource.selection.hasValue()">
    Select & Close
  </button>
</mat-dialog-actions>
