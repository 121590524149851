import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-sites-selector',
  templateUrl: './sites-selector.component.html',
  styleUrls: ['./sites-selector.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class SitesSelectorComponent implements OnInit, AfterViewInit {

  @ViewChild('sites') sites;

  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'location',
    'site_status',
    'site_risk_assessment',
    'actions'
  ];
  dataSource: SitesSelectorDataSource;
  returnAllSelected = false;

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<SitesSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // set multiple if it is not present to false
    if (typeof this.data['multiple'] === 'undefined') {
      this.data['multiple'] = false;
    }

    // construct the datasource
    this.dataSource = new SitesSelectorDataSource(
      this.app,
      this.api,
      this.data['multiple']
    );

    // set a default selection of nothing if no selected values are passed through
    if (typeof this.data['selected'] === 'undefined') {
      this.data['selected'] = [];
    }

    // set allow_no_selection if it is not present then set to false
    if (typeof this.data['allow_no_selection'] === 'undefined') {
      this.data['allow_no_selection'] = false;
    }

    // Include/Exclude child site selection.
    if (typeof this.data['include_child_site_selection'] == 'undefined') {
      this.data['include_child_site_selection'] = false;
    }

    // loop and select the values
    for (let i = 0; i < this.data['selected'].length; i++) {
      if (this.data['selected'][i]) {
        this.dataSource.selection.select(this.data['selected'][i]);
      }
    }
    this.returnAllSelected = this.data.returnAllSelected;

    // Check if we have an inbound parent site id.
    const parent_site_id = Number(this.data['parent_site_id']);
    if ( parent_site_id ) {
      this.onFilterByParentSite(parent_site_id);
    } else {
      this.dataSource.getData();
    }
  }

  ngOnInit() { }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  // clears the search bar and performs a search
  onClearSearch() {
    // clear the search field in the datasource
    this.dataSource.search = '';
    // search for records
    this.dataSource.getData(true);
  }

  onClearSelected() {
    this.dataSource.selection.clear();
  }

  /**
   * before the dialog close we want to store the new active site id
   */
  onSaveAndClose() {
    if (this.returnAllSelected) {
      return this.dialogRef.close({
        selected: this.dataSource.selection.selected,
        isAllSelected: this.dataSource.isAllSelected()
      });
    }

    let response: number | number[];

    if (this.data['multiple']) {
      response = this.dataSource.selection.selected;
    } else {
      if (this.dataSource.selection.selected.length) {
        response = this.dataSource.selection.selected[0];
      } else {
        response = 0;
      }
    }

    this.dialogRef.close(response);
  }

  onSelectSiteById(event, selected_site_id: number) {
    event.stopPropagation();
    this.dataSource.selection.toggle(selected_site_id);
  }

  onFilterByParentSite(parent_site_id: number) {
    this.dataSource.parent_site_id = parent_site_id;
    this.displayedColumns.pop();
    this.dataSource.getData(true);
  }

  onResetList() {
    this.dataSource.parent_site_id = null;
    this.displayedColumns.push('actions');
    this.dataSource.getData(true);
  }
}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class SitesSelectorDataSource extends CustomDataSource {

  sort_by = 'name';
  sort_order = 'asc';

  // The parent site id to filter by.
  parent_site_id: number;

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('sites', resetOffset, null, {
      include_archived: true,
      parent_site_id: this.parent_site_id
    });
  }
}
