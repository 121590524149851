import {Component, Inject, OnInit} from '@angular/core';
import {RISK_LEVEL_OF_CONTROLS} from "../../shared/risk-level-of-controls";
import {TaskAnalysesRevisedWorkflowHazardModel} from "../../models/task-analyses-revised-workflow-hazard.model";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {UtilsService} from "../../shared/utils.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-task-analyses-revised-workflows-hazards-view',
  templateUrl: './task-analyses-revised-workflows-hazards-view.component.html',
  styleUrls: ['./task-analyses-revised-workflows-hazards-view.component.scss']
})
export class TaskAnalysesRevisedWorkflowsHazardsViewComponent implements OnInit {

  loc_list = RISK_LEVEL_OF_CONTROLS;
  ta_revised_id: number;
  ta_revised_workflow_id: number;
  ta_revised_workflow_hazard: TaskAnalysesRevisedWorkflowHazardModel = new TaskAnalysesRevisedWorkflowHazardModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    // get the task analysis workflow id
    this.ta_revised_workflow_hazard.id = this.dialogData.ta_revised_workflow_hazard.id;

    // get the task analysis workflow id
    this.ta_revised_workflow_id = this.dialogData.ta_revised_workflow_id;

    // get the task analysis id
    this.ta_revised_id = this.dialogData.ta_revised_id;

    if(this.ta_revised_workflow_hazard.id) {
      this.getWorkflowHazard(this.ta_revised_workflow_hazard.id);
    }
  }

  private getWorkflowHazard (ta_revised_workflow_hazard_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow_id}/hazards/${ta_revised_workflow_hazard_id}`, [], {})
      .then((response) => {
        this.ta_revised_workflow_hazard = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
