<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12">
      <h4>Importing {{ displayType }}</h4>

      <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
        <div>
          <p>
            You are about to import {{ displayType }}. Please select the CSV file you would like to upload and import.
          </p>

          <ng-container *ngIf="type === 'contractors' || type === 'employees'">
            <button
              mat-raised-button
              type="button"
              (click)="onDownloadImportTemplate()"
              color="secondary"
              appMarginRight>
              <mat-icon appMarginRight>cloud_download</mat-icon>Download CSV Template
            </button>
          </ng-container>

          <button
            mat-raised-button
            type="button"
            (click)="onTriggerFileSelector($event)"
            color="primary"
            appMarginRight>
            Select &amp; Upload CSV File
          </button>

          <span *ngIf="selectedFile">{{ selectedFile.name }}</span>

          <input type="file" (change)="onFileSelected($event)" style="display: none;" #fileInput/>

          <p *ngIf="selectedFile && !(possible_headers.length && sample_data.length)" appMarginTop>
            Please wait while we upload and analyse the file.
          </p>
        </div>

        <ng-container *ngIf="possible_headers.length && sample_data.length">
          <p appMarginTop>
            Map the columns below to its corresponding fields in the <b>sample data</b> table below.
            If you map more than one column to the same field, the data will be concatenated.
          </p>

          <p appMarginTop *ngIf="type === 'contractors'">
            Example: "Column A" and "Column B" columns can be combined into one field called "Full Name" if you map "Full Name" to both columns. <br><br>
            The <b>Entity Name</b>, <b>Full Name</b>, <b>Email</b>, <b>Mobile Country Code</b> and <b>Mobile</b> columns are required. Examples of Mobile Country Codes include NZ, AU, US, and GB, among others.
          </p>

          <p appMarginTop *ngIf="type === 'employees'">
            Example: "Column A" and "Column B" columns can be combined into one field called "Full Name" if you map "Full Name" to both columns. <br><br>
            The <b>Full Name</b>, <b>Email</b>, <b>Mobile Country Code</b> and <b>Mobile</b> columns are required. Examples of Mobile Country Codes include NZ, AU, US, and GB, among others.
          </p>

          <p appMarginTop *ngIf="type === 'user-documents'">
            Example: "Column A" and "Column B" columns can be combined into one field called "Document Name" if you map "Document Name" to both columns. <br><br>
            The <b>Email Address</b> and <b>Document Name</b> columns are required.
            The mapped <b>Document Name</b> column can contain comma-separated <b>Document Names</b> to import multiple documents in one row.
          </p>

          <p appMarginTop *ngIf="type === 'sites'">
            Example: "Column A" and "Column B" columns can be combined into one field called "Name" if you map "Name" to both columns. <br><br>
            The <b>Name</b> and <b>Location</b> columns are required.
          </p>

          <p appMarginTop *ngIf="type === 'hazards'">
            Example: "Column A" and "Column B" columns can be combined into one field called "Hazard/Risk" if you map "Hazard/Risk" to both columns. <br><br>
            The <b>Hazard/Risk</b>, <b>Description of Risk/Harm</b> and <b>Status</b> columns are required.
          </p>

          <p appMarginTop>
            Rows without data or invalid data in these columns will be ignored.
          </p>

          <div class="table-responsive">
            <table class="table table-hover">
              <thead class="thead-light">
                <tr>
                  <th scope="col" *ngFor="let header of possible_headers">
                    {{ header }}
                  </th>
                </tr>
                <tr>
                  <th scope="col" *ngFor="let header of possible_headers; let i = index">
                    <mat-form-field>
                      <mat-select placeholder="Map Field" [(ngModel)]="mapped_fields[i]" name="header_{{ i }}">
                        <mat-option value="-1">-- Ignore --</mat-option>
                        <mat-option *ngFor="let field of importable_fields; let j = index" [value]="j">
                          {{ field }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of sample_data">
                  <td *ngFor="let col of row">
                    {{ col.length > 32 ? (col | slice: 0:32) + '...' : col }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div appMarginTop>
            <div>
              <mat-slide-toggle [(ngModel)]="first_row_headers" name="first_row_headers">
                Treat the first row as headers.
              </mat-slide-toggle>
            </div>

            <div *ngIf="type === 'contractors'">
              <mat-slide-toggle [(ngModel)]="first_row_admin" name="first_row_admin" (change)="onToggleCompanyAdmin()">
                The first person is the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} Administrator; the rest are Employees.
              </mat-slide-toggle>
            </div>

            <div *ngIf="type === 'contractors' || type === 'employees'">
              <mat-slide-toggle [(ngModel)]="send_notifications" name="send_notifications">
                Send email notifications.
              </mat-slide-toggle>
            </div>

            <button mat-raised-button type="submit" color="primary" appMarginTop [disabled]="!isValidMapping() || is_importing">
              Import {{ displayType }}
            </button>

            <span *ngIf="is_importing">
              You will get an email when your data is imported.
            </span>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</div>
