<form #f="ngForm" (ngSubmit)="onSubmit(f)">

  <p>
    <mat-form-field class="full-width">
      <mat-label>Company Name</mat-label>
      <input matInput placeholder="Company Name" [(ngModel)]="user.entity_name" name="entity_name"  type="text" required [matAutocomplete]="entity_name_auto" (keyup)="getEntityNames($event.target.value)" />
      <mat-hint>Enter the name of your company or employer here.</mat-hint>
      <mat-autocomplete #entity_name_auto="matAutocomplete">
        <mat-option *ngFor="let autoCompletedEntityName of autoCompletedEntityNames" [value]="autoCompletedEntityName">
          {{ autoCompletedEntityName }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field class="full-width">
      <mat-label>Full Name</mat-label>
      <input matInput placeholder="Full Name" [(ngModel)]="user.contact_person" name="contact_person" type="text" required />
      <mat-hint>Enter your first and last name here.</mat-hint>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field class="full-width">
      <mat-label>Mobile Phone Number (Including Country Code)</mat-label>
      <app-phone-input
        [idInput]="mobile_input_id"
        [numberInput]="user.mobile"
        [countryCodeInput]="user.mobile_country_code"
        [dialCodeInput]="user.mobile_dial_code"
        [e164NumberInput]="user.mobile_e164"
        [additionalClassInput]="'compact'"
        (phoneDetailsOutput)="getOutputPhoneDetails($event)"
        required="true">
      </app-phone-input>
      <mat-hint>Enter your mobile number here.</mat-hint>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field class="full-width">
      <mat-label>Industry</mat-label>
      <mat-select matInput placeholder="Industry" [(ngModel)]="user.industry" name="industry" required>
        <mat-option *ngFor="let industry of industries" [value]="industry.name">
          {{ industry.name }}
        </mat-option>
      </mat-select>
      <mat-hint>Select your industry.</mat-hint>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field class="full-width">
      <mat-label>Job Title</mat-label>
      <mat-select matInput placeholder="Job Title" [(ngModel)]="user.trade" name="trade" required>
        <mat-option *ngFor="let trade of trades" [value]="trade.name">
          {{ trade.name }}
        </mat-option>
      </mat-select>
      <mat-hint>Select your job title.</mat-hint>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field class="full-width">
      <mat-label>Email Address</mat-label>
      <input matInput placeholder="Email Address" [(ngModel)]="user.email" name="email" type="email" required email />
      <mat-hint>Enter your email address here.</mat-hint>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field class="full-width">
      <mat-label>Password</mat-label>
      <input matInput placeholder="Password" [(ngModel)]="user.password" name="password" [type]="passwordFieldType" required />
      <button mat-icon-button matSuffix aria-label="Show/Hide" (click)="togglePasswordVisibility()" type="button">
        <mat-icon>{{ passwordFieldType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
      </button>
      <mat-hint>Enter a your password here.</mat-hint>
    </mat-form-field>
  </p>

  <app-password-strength-indicator [passwordInput]="user.password" (passwordValidatedOutput)="getOutputPasswordValidated($event)"></app-password-strength-indicator>

  <p>
    <mat-checkbox [(ngModel)]="tos" matInput name="tos" required>
      I agree to the
      <a [href]="utils.getTermsAndConditionsUrl()" target="_blank">Terms & Conditions</a>
      and
      <a [href]="utils.getPrivacyPolicyUrl()" target="_blank">Privacy Policy</a>.
    </mat-checkbox>
  </p>

  <button type="submit" mat-raised-button [disabled]="!f.valid || inProgress || !passwordValidated || mobile_error_state" color="primary" class="full-width">
    Sign Up
  </button>
</form>
