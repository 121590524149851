<div mat-dialog-title style="display: flex; justify-content: space-between; border-bottom: 1px solid silver;">
  <h5> Induction Module </h5>
  <button mat-icon-button mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="p-3">
  <p>
    Read through the introduction as well as any other content sections that may
    appear. You may need to scroll down to read more or to go to the next section.
    At the end, answer all the questions by clicking an answer to be yes
    (coloured) or no (gray). When you are done, save your answers. You will
    automatically be returned to the list of modules.
  </p>

  <mat-horizontal-stepper
    [linear]="true"
    (selectionChange)="onSelectionChange($event)">
    <mat-step>
      <ng-template matStepLabel>Introduction</ng-template>
      <h5>{{ inductionService.data.modules[module_index].name }}</h5>
      <div [innerHTML]="inductionService.data.modules[module_index].description | sanitizeHtml"></div>

      <div appMarginTop class="d-flex justify-content-end">
        <button mat-raised-button matStepperNext color="primary">Next</button>
      </div>
    </mat-step>

    <ng-container *ngFor="let content of inductionService.data.modules[module_index].contents; let i = index">
      <mat-step *ngIf="content.content">
        <ng-template matStepLabel>Section {{ i+1 }}</ng-template>
        <h5>{{ content.name }}</h5>
        <div [innerHTML]="content.content | sanitizeHtml"></div>

        <div appMarginTop class="d-flex justify-content-end">
          <button
            mat-raised-button
            matStepperPrevious
            appMarginRight
            color="secondary">
            Back
          </button>
          <button mat-raised-button matStepperNext color="primary">Next</button>
        </div>
      </mat-step>
    </ng-container>

    <mat-step>
      <ng-template matStepLabel>Questions</ng-template>

      <ng-container *ngFor="let question of inductionService.data.modules[module_index].questions; let i = index">
        <p>
          <b>{{ question.question }}</b>
        </p>

        <p>Click to slide the options on or off.</p>

        <ng-container *ngFor="let answer of inductionService.data.modules[module_index].questions[i].answers; let j = index">
          <div>
            <mat-slide-toggle [(ngModel)]="inductionService.data.modules[module_index].questions[i].answers[j].user_answer">
              {{ answer.answer_text }}
            </mat-slide-toggle>
          </div>
        </ng-container>
      </ng-container>

      <p *ngIf="!inductionService?.data?.modules[module_index]?.questions?.length">
        There are no questions loaded for this module. Please contact {{ utils.getAppName() }} for assistance.
      </p>

      <div appMarginTop class="d-flex justify-content-end">
        <button
          mat-raised-button
          matStepperPrevious
          appMarginRight
          color="secondary">
          Back
        </button>

        <button
          mat-raised-button
          type="button"
          color="primary"
          [disabled]="!inductionService?.data?.modules[module_index]?.questions?.length"
          (click)="onSaveAnswers()">
          Save Answers
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
