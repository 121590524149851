import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TaStepHazardModel } from '../../../models/ta-step-hazard.model';
import { TaStepsService } from '../ta-steps.service';

@Component({
  selector: 'app-ta-steps-edit',
  templateUrl: './ta-steps-edit.component.html',
  styleUrls: ['./ta-steps-edit.component.css']
})
export class TaStepsEditComponent implements OnInit {
  parent_id: number;
  id: number;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public service: TaStepsService
  ) {}

  ngOnInit() {
    this.parent_id = Number(this.route.snapshot.params['ta_id']);
    this.id = Number(this.route.snapshot.params['step_id']);

    if (this.id) {
      this.service.get(this.parent_id, this.id, false, () => {
      });
    }
  }

  onSubmit(form: NgForm) {
    // make sure the form data is valid
    if (form.valid) {
      // check if the site should be created or updated
      if (this.id) {
        // update the site
        this.service.update(this.parent_id);
      } else {
        // create the site
        this.service.add(this.parent_id, (response) => {
          // and navigate back to the list of sites
          this.router.navigate(['/task-analyses', this.parent_id, 'steps']);
        });
      }
    } else {
      alert('Please make sure you enter all fields with valid information.');
    }
  }

  onAddHazard() {
    this.service.item.hazards.push(new TaStepHazardModel());
  }

  onRemoveHazard(i: number) {
    this.service.item.hazards.splice(i, 1);
  }
}
