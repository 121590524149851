import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../models/api-response.model';
import { CodeModel } from '../../models/code.model';

type ResponseEntity = ApiResponseModel<CodeModel>;
type ResponseEntities = ApiResponseModel<CodeModel[]>;

@Injectable({
  providedIn: 'root'
})
export class CodesService {
  constructor(private api: ApiService) {}

  public post(code: CodeModel): Observable<ResponseEntity> {
    return this.api.laravelApiObservable('post', 'codes', code);
  }

  public delete(id: number): Observable<ResponseEntity> {
    return this.api.laravelApiObservable('delete', `codes/${id}`);
  }

  public find(id: number): Observable<ApiResponseModel<CodeModel>> {
    return this.api.laravelApiObservable('get', `codes/${id}`);
  }

  public get(): Observable<ResponseEntities> {
    return this.api.laravelApiObservable('get', 'codes');
  }

  public put(code: CodeModel): Observable<ResponseEntity> {
    return this.api.laravelApiObservable('put', `codes/${code.id}`, code);
  }

  public unlink(id: number): Observable<null> {
    return this.api.laravelApiObservable('patch', `codes/${id}/unlink`, null);
  }

  public addMany(
    _name: string,
    _number: number
  ): Observable<ApiResponseModel<number[]>> {
    return this.api.laravelApiObservable('post', 'codes/many', {
      name: _name,
      number: _number
    });
  }

  public deleteMany(ids: number[]): Observable<ApiResponseModel<null>> {
    return this.api.laravelApiObservable('post', 'codes/delete-many', {
      code_ids: ids
    });
  }

  public unlinkMany(ids: number[]): Observable<ApiResponseModel<null>> {
    return this.api.laravelApiObservable('patch', 'codes/unlink-many', {
      code_ids: ids
    });
  }
}
