import { Component } from '@angular/core';
import { UserModel } from '../../models/user.model';
import { UtilsService } from '../../shared/utils.service';
import { ApiRequestService } from '../../shared/api-request.service';
import { NgForm } from '@angular/forms';
import { AppService } from '../../app.service';
import { StorageService } from '../../shared/storage.service';

@Component({
  selector: 'app-login-credentials',
  templateUrl: './login-credentials.component.html',
  styleUrls: ['./login-credentials.component.scss']
})
export class LoginCredentialsComponent {

  // Used to show the current user's email and username.
  email: string = '';
  username: string = '';

  // Used to update the user's password.
  password: string = '';
  passwordConfirmation: string = '';

  // Used to show/hide the password fields.
  passwordFieldType: string = 'password';
  confirmPasswordFieldType: string = 'password';

  // Used to show password strength.
  passwordValidated: boolean = false;

  inProgress: boolean = false;

  constructor(
    public utils: UtilsService,
    private api: ApiRequestService,
    private app: AppService,
    private storage: StorageService
  ) {}

  ngOnInit() {
    // Make an API request to get the current authenticated user's profile.
    this.api.makeRequest('get', 'v2/user')
    .then((response: UserModel) => {
      // Extract the email and username from the response.
      this.email = response.email;
      this.username = response.username;
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
    });
  }

  /**
   * Update the authenticated user's login credentials.
   * @param form The form object.
   */
  onUpdateLoginCredentials(form: NgForm) {
    // Check if all required fields are filled out.
    if ( !form.valid ) {
      this.utils.showModal('Error Message', 'Please make sure you enter all required fields.');
      return;
    }
    // Check if another request is in progress.
    if ( this.inProgress ) {
      return;
    }
    // Construct a confirmation message.
    let confirmationMessage = 'Are you sure you want to update your login credentials?';
    if ( this.password ) {
      confirmationMessage += ' You changed your password and will be logged out of all other devices.';
    }
    // Show the confirmation message.
    this.utils.showModal('Confirmation Needed', confirmationMessage, () => {
      // Set the in progress state to true.
      this.inProgress = true;
      // Make an API request to update the user's login credentials.
      this.api.makeRequest('put', 'v2/user/login-credentials', {
        email: this.email,
        username: this.username,
        password: this.password,
        password_confirmation: this.passwordConfirmation
      })
      .then((response) => {
        this.utils.showToast('Your login credentials has been updated.');
        // Re-authenticate the user.
        this.handleSignInResponse(response);
        this.inProgress = false;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
        this.inProgress = false;
      });
    });
  }

  /**
   * Send a password reset request to the user.
   */
  onResetPassword() {
    // Get confirmation before sending the password reset request.
    this.utils.showModal('Confirm Password Reset', 'Are you sure you want to request a password reset? An email will be sent to you with a unique link to update your password.', () => {
      // Send a request to reset the password.
      this.api.makeRequest('post', 'v2/password-reset-request', {
        email: this.app.user.email
      })
      .then((response) => {
        this.utils.showToast(response.message);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
    });
  }

  /**
   * Store the user's auth, account and profile details.
   * @param response
   * @private
   */
  private handleSignInResponse(response) {
    // Check if all required properties are present.
    if (!response.account || !response.user || !response.user_account_link || !response.api_token) {
      this.utils.showToast('We could not refresh your account details. You may need to log in again.');
      return;
    }
    // Set the app auth status to true.
    this.app.isAuthenticated = true;
    // Store the API token.
    this.storage.set('AuthToken', response.api_token);
    // Apply the user profile, account details and user link details for permissions.
    this.app.user.apply(response.user);
    this.app.account.apply(response.account);
    this.app.userAccountLink.apply(response.user_account_link);
  }

  getOutputPasswordValidated($event) {
    this.passwordValidated = $event;
  }

  /**
   * Toggles the visibility of the password field based on the given field parameter.
   *
   * @param {string} field - The name of the field to toggle visibility for ('password' or 'confirmPassword').
   * @returns {void}
   */
  togglePasswordVisibility(field: string): void {
    if (field === 'password') {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    } else if (field === 'confirmPassword') {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
    }
  }
}
