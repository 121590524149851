import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { SiteModel } from 'src/app/models/site.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-sites-settings',
  templateUrl: './sites-settings.component.html',
  styleUrls: ['./sites-settings.component.scss']
})
export class SitesSettingsComponent implements OnInit {

  site: SiteModel = new SiteModel();

  inProgress: boolean = false;

  parent_id: number;
  child_id: number;
  site_id: number;

  after_hours_notification_duration_options: number[] = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75]; // minutes

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      this.api.makeRequest('get', `v1/sites/${this.site_id}`)
        .then((response) => {
          this.site.apply(response.data);

          // Extract Tag ids.
          this.site.tag_ids = this.site.tags ? this.site.tags.map((tag) => tag.id) : [];
        })
        .catch((errorResponse) => {
          this.utils.showToast('The ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() + ' could not be found. Please try again.');
          this.router.navigate(['sites']);
        });
    } else {
      this.router.navigate(['/sites']);
      return;
    }
  }

  onSave(form: NgForm) {
    if ( !form.valid ) {
      this.utils.showToast('Please make sure you enter or select all required fields.');
      return;
    }

    this.inProgress = true;

    this.api.makeRequest('put', `v1/sites/${this.site.id}`, this.site)
    .then((response) => {
      this.utils.showToast('The settings were updated successfully.');
    })
    .finally(() => {
      this.inProgress = false;
    });
  }

}
