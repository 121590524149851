import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { UtilsService } from 'src/app/shared/utils.service';
import { AppService } from '../../app.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import {AccessRecordsFilterComponent} from "../access-records-filter/access-records-filter.component";
import {ApiDataSource} from "../../utils/api-data-source";
import {ApiRequestService} from "../../shared/api-request.service";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {ChartsUtilsService} from "../../charts/charts-utils.service";

@Component({
  selector: 'app-access-records',
  templateUrl: './access-records.component.html',
  styleUrls: ['./access-records.component.scss']
})
export class AccessRecordsComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'id',
    'contact_person',
    'entity_name',
    'site_name',
    'type',
    'method',
    'date_created',
    // 'date_created_UTC',
    'export'
  ];
  dataSource = new AccessRecordsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  contractors: any[] = [];

  constructor(
    public app: AppService,
    public utils: UtilsService,
    private api: ApiRequestService,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit() {
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      // Force sorting by id instead since ids are created in the same order as dates.
      if ( ['date_created', 'date_created_UTC'].indexOf(this.sort.active) > -1 ) {
        this.dataSource.order_by = 'id';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * Export the list to CSV.
   */
  onExport() {
    this.dataSource.exportData()
      .then((response) => {
          saveAs(response, 'Access Records.csv');
      })
      .catch((errorResponse) => {
        // Show a standard error message.
        this.utils.showModal('Export Error', 'We could not export your records. Please try again and contact support if the issue persists.');
      });
  }

  composeNewMessage(userId: number) {
    if ( !userId ) {
      this.utils.showToast('We are unable to open the message composer. Please refresh the page and try again.');
      return; // early termination
    }

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectUserIds: [userId]
    })
    .then(() => {
      this.utils.showToast('You can view the message stats in the messages section.');
    });
  }

  onFilter() {
    this.utils.showComponentDialog(
      AccessRecordsFilterComponent,
      {
        type: this.dataSource.type,
        site_ids: this.dataSource.site_ids,
        contractor_ids: this.dataSource.contractor_ids,
        user_ids: this.dataSource.user_ids,
        employee_ids: this.dataSource.employee_ids,
        date_range: this.dataSource.access_date
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.type = typeof results.type !== 'undefined' ? results.type : this.dataSource.type;
          this.dataSource.site_ids = results.site_ids ? results.site_ids : this.dataSource.site_ids;
          this.dataSource.contractor_ids = results.contractor_ids ? results.contractor_ids : this.dataSource.contractor_ids;
          this.dataSource.user_ids = results.user_ids ? results.user_ids : this.dataSource.user_ids;
          this.dataSource.employee_ids = results.employee_ids ? results.employee_ids : this.dataSource.employee_ids;
          this.dataSource.access_date = results.date_range ? results.date_range : this.dataSource.access_date;
          this.dataSource.getData();
        }
      }
    );
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class AccessRecordsDataSource extends ApiDataSource {

  order_by: string = 'id';
  order: string = 'desc';

  type: string = ''; // Can only be: in, out, in/out or location.

  site_ids: number[] = [];
  contractor_ids: number[] = [];
  user_ids: number[] = [];
  employee_ids: number[] = [];
  access_date: Date[] = [];

  /**
   * This prepares the query params for the API requests to list or export the data.
   */
  prepareQueryParams() {
    return {
      type: this.type ? this.type : '',
      site_ids: this.site_ids.length ? this.site_ids.join(',') : '',
      contractor_ids: this.contractor_ids.length ? this.contractor_ids.join(',') : '',
      user_ids: this.user_ids.length ? this.user_ids.join(',') : '',
      employee_ids: this.employee_ids.length ? this.employee_ids.join(',') : '',
      date_range: this.access_date && this.access_date.length > 1 ? (this.access_date[0].getTime() / 1000) + ',' + (this.access_date[1].getTime() / 1000) : 0
    };
  }

  /**
   * Make a request to get list data.
   * @param resetOffset This will reset the offset to load data from the beginning.
   */
  getData(resetOffset: boolean = false) {
    return this.makeRequest(`v2/access-records`, resetOffset, this.prepareQueryParams());
  }

  /**
   * Make a request to export the data to a CSV file.
   */
  exportData() {
    return this.api.makeDownloadRequest(`v2/access-records/export`, {}, this.prepareQueryParams());
  }
}
