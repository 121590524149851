<div mat-dialog-title>
  <h5>{{ !folder_link.id ? "Create" : "Edit" }} Document Link</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit(form)">
  <mat-form-field>
    <input
      matInput
      placeholder="Document Link URL"
      [(ngModel)]="folder_link.url"
      name="url"
      type="url"
      #url
      required />
    <mat-error *ngIf="!url.valid">Please enter the Document Link URL. </mat-error>
  </mat-form-field>

  <mat-form-field>
    <textarea
      matInput
      placeholder="Document Link Description"
      [(ngModel)]="folder_link.description"
      name="description"
      type="text"
      rows="10"
      cols="30"
      #description
      matTooltip="Enter a Document Link Description.">
    </textarea>
    <mat-hint>Please enter a Document Link Description. (Optional)</mat-hint>
  </mat-form-field>

  <button
    mat-raised-button
    [disabled]="!form.valid || inProgress"
    color="primary"
    type="submit"
    id="save"
    class="mt-3">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    type="button"
    mat-icon-button
    [disabled]="!folder_link.id"
    class="float-right mt-3"
    (click)="onDelete()">
    <mat-icon>delete</mat-icon>
  </button>
</form>
