import { UserModel } from '../models/user.model';
import { Auditable } from '../shared/auditable.model';
import { FileModel } from '../shared/file.model';

export class ActionNoteModel extends Auditable {

  id: number;
  note: string;
  files?: FileModel[];

  /**
   * Who created this Action Note?
   */
  creator: UserModel;
}
