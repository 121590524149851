<mat-tab-group>
      <mat-tab label="Notification Roles">
        <ng-template matTabContent>
          <app-sites-notifications-roles [site_id]="site_id"></app-sites-notifications-roles>
        </ng-template>
      </mat-tab>

  <mat-tab [label]="utils.getLangTerm('employees.plural', 'Employees')">
    <ng-template matTabContent>
      <app-sites-notifications-users [site_id]="site_id"></app-sites-notifications-users>
    </ng-template>
  </mat-tab>
</mat-tab-group>
