<div mat-dialog-title>
  <h5 *ngIf="!ppe.id">Create PPE</h5>
  <h5 *ngIf="ppe.id">Edit PPE</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit()">
  <mat-form-field>
    <input
      matInput
      placeholder="PPE"
      [(ngModel)]="ppe.title"
      name="title"
      type="text"
      required
      #title
    />
    <mat-error *ngIf="!title.valid">Please enter a PPE title.</mat-error>
  </mat-form-field>

  <mat-form-field>
      <textarea
        rows="4"
        matInput
        placeholder="Description"
        [(ngModel)]="ppe.description"
        name="description"
        #description>
      </textarea>
  </mat-form-field>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="container-fluid ppe-icon-wrapper" *ngIf="ppe.id">
          <h6>Web Icon</h6>
          <div class="row">
            <div class="col-12" *ngIf="ppe.web_icon">
              <img [src]="ppe.web_icon" class="ppe-icon" alt="PPE Web Icon" />
            </div>
            <div class="col-12">
              <button mat-raised-button color="secondary" type="button" (click)="onTriggerWebIconSelector($event)" *ngIf="!ppe.web_icon" appMarginTop appMarginBottom>
                Select &amp; Upload Web Icon
              </button>
              <button mat-raised-button color="secondary" type="button" (click)="onRemoveWebIcon()" *ngIf="ppe.web_icon" appMarginTop appMarginBottom appMarginLeft>
                Remove Web Icon
              </button>
              <input type="file" (change)="onUploadSelectedWebIcon($event)" style="display: none;" #webIconInput />
            </div>
            <div class="col-12" *ngIf="!ppe.web_icon">
              <mat-hint>Only image files are allowed. Upload a square image for the best results.</mat-hint>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="container-fluid ppe-icon-wrapper" *ngIf="ppe.id">
          <h6>Mobile Icon</h6>
          <div class="row">
            <div class="col-12" *ngIf="ppe.mobile_icon">
              <img [src]="ppe.mobile_icon" class="ppe-icon" alt="PPE Mobile Icon" />
            </div>
            <div class="col-12">
              <button mat-raised-button color="secondary" type="button" (click)="onTriggerMobileIconSelector($event)" *ngIf="!ppe.mobile_icon" appMarginTop appMarginBottom>
                Select &amp; Upload Mobile Icon
              </button>
              <button mat-raised-button color="secondary" type="button" (click)="onRemoveMobileIcon()" *ngIf="ppe.mobile_icon" appMarginTop appMarginBottom appMarginLeft>
                Remove Mobile Icon
              </button>
              <input type="file" (change)="onUploadSelectedMobileIcon($event)" style="display: none;" #mobileIconInput />
            </div>
            <div class="col-12" *ngIf="!ppe.mobile_icon">
              <mat-hint>Only image files are allowed. Upload a square image for the best results.</mat-hint>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button
    mat-raised-button
    [disabled]="!form.valid || inProgress"
    color="primary"
    type="submit"
    id="save"
  >
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    type="button"
    mat-icon-button
    [disabled]="!ppe.id"
    class="float-right"
    (click)="onDelete()"
  >
    <mat-icon>delete</mat-icon>
  </button>
</form>

