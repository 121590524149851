import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import {NetworkedAccountsSelectorComponent} from "../../shared/networked-accounts-selector/networked-accounts-selector.component";
import {NetworkedUsersSelectorComponent} from "../../shared/networked-users-selector/networked-users-selector.component";
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";

@Component({
  selector: 'app-sites-safety-observations-filter',
  templateUrl: './sites-safety-observations-filter.component.html',
  styleUrls: ['./sites-safety-observations-filter.component.scss']
})
export class SitesSafetyObservationsFilterComponent implements OnInit {

  dataSource = new SitesSafetyObservationsFilterDataSource(); // No API calls needed
  faHardHat = faHardHat;

  constructor(
    private app: AppService,
    public location: Location,
    public utils: UtilsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SitesSafetyObservationsFilterComponent>
    // private api: ApiRequestService
  ) {

    // set a default category of nothing if no values are passed through
    if (typeof this.data['filter_so_category'] === 'undefined') {
      this.data['filter_so_category'] = "";
    }

    // set a default type of nothing if no values are passed through
    if (typeof this.data['filter_so_type'] === 'undefined') {
      this.data['filter_so_type'] = "";
    }

    // set a default priority of nothing if no values are passed through
    if (typeof this.data['filter_so_priority'] === 'undefined') {
      this.data['filter_so_priority'] = "";
    }

    // set a default status of nothing if no values are passed through
    if (typeof this.data['filter_so_status'] === 'undefined') {
      this.data['filter_so_status'] = "";
    }

    // set a default rating of nothing if no values are passed through
    if (typeof this.data['filter_so_rating'] === 'undefined') {
      this.data['filter_so_rating'] = "";
    }

    // set a default Sites selection of nothing if no selected values are passed through
    if (typeof this.data['selected_site_ids'] == 'undefined') {
      this.data['selected_site_ids'] = [];
    }

    // set a default Contractors selection of nothing if no selected values are passed through
    if (typeof this.data['selected_contractor_ids'] == 'undefined') {
      this.data['selected_contractor_ids'] = [];
    }

    // set a default Users selection of nothing if no selected values are passed through
    if (typeof this.data['selected_user_ids'] == 'undefined') {
      this.data['selected_user_ids'] = [];
    }

    // set a default Reported By Users selection of nothing if no selected values are passed through
    if (typeof this.data['selected_reported_by_ids'] == 'undefined') {
      this.data['selected_reported_by_ids'] = [];
    }

    // set a default date range of nothing if no values are passed through
    if (typeof this.data['date_filter_range'] === 'undefined') {
      this.data['date_filter_range'] = [];
    }
  }

  ngOnInit() {
    this.dataSource.filter_so_category = this.data['filter_so_category'];
    this.dataSource.filter_so_type = this.data['filter_so_type'];
    this.dataSource.filter_so_priority = this.data['filter_so_priority'];
    this.dataSource.filter_so_status = this.data['filter_so_status'];
    this.dataSource.filter_so_rating = this.data['filter_so_rating'];
    this.dataSource.selected_site_ids = this.data['selected_site_ids'];
    this.dataSource.selected_contractor_ids = this.data['selected_contractor_ids'];
    this.dataSource.selected_user_ids = this.data['selected_user_ids'];
    this.dataSource.selected_reported_by_ids = this.data['selected_reported_by_ids'];
    this.dataSource.date_filter_range = this.data['date_filter_range'];
  }

  getOutputDateRange($event) {
    this.dataSource.date_filter_range = $event;
  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.dataSource.selected_site_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (!results) { return; }
        this.dataSource.selected_site_ids = results;
      }
    );
  }

  onSelectContractors() {
    this.utils.showComponentDialog(
      NetworkedAccountsSelectorComponent, {
      selected: this.dataSource.selected_contractor_ids,
      multiple: true
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.dataSource.selected_contractor_ids = results;
    });
  }

  onSelectReportedBy() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.dataSource.selected_reported_by_ids,
      multiple: true,
      visitors_from_all_sites: true
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.dataSource.selected_reported_by_ids = results;
    });
  }

  onSelectUsers() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.dataSource.selected_user_ids,
      multiple: true,
      selectedAccountId: this.app.account.id,
      visitors_from_all_sites: true
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.dataSource.selected_user_ids = results;
    });
  }

  onClearAndClose() {
    this.dataSource.filter_so_category = "";
    this.dataSource.filter_so_type = "";
    this.dataSource.filter_so_priority = "";
    this.dataSource.filter_so_status = "";
    this.dataSource.filter_so_rating = "";
    this.dataSource.selected_site_ids = [];
    this.dataSource.selected_contractor_ids = [];
    this.dataSource.selected_user_ids = [];
    this.dataSource.selected_reported_by_ids = [];
    this.dataSource.date_filter_range = [];

    this.onSaveAndClose();
  }

  onSaveAndClose() {
    let response: any;

    response = {
      so_category: this.dataSource.filter_so_category,
      so_type: this.dataSource.filter_so_type,
      so_priority: this.dataSource.filter_so_priority,
      so_status: this.dataSource.filter_so_status,
      so_rating: this.dataSource.filter_so_rating,
      site_ids: this.dataSource.selected_site_ids,
      contractor_ids: this.dataSource.selected_contractor_ids,
      user_ids: this.dataSource.selected_user_ids,
      reported_by_ids: this.dataSource.selected_reported_by_ids,
      date_filter_range: this.dataSource.date_filter_range
    }

    this.dialogRef.close(response);
  }

}

/**
 * the data source class should contain everything needed to get data for the feature.
 */
export class SitesSafetyObservationsFilterDataSource {

  filter_so_category: string = "";
  filter_so_type: string = "";
  filter_so_priority: string = "";
  filter_so_status: string = "";
  filter_so_rating: string = "";
  selected_site_ids: number[] = [];
  selected_contractor_ids: number[] = [];
  selected_user_ids: number[] = [];
  selected_reported_by_ids: number[] = [];
  date_filter_range: Date[] = [];

  constructor() {
    this.filter_so_category = "";
    this.filter_so_type = "";
    this.filter_so_priority = "";
    this.filter_so_status = "";
    this.filter_so_rating = "";
    this.selected_site_ids = [];
    this.selected_contractor_ids = [];
    this.selected_user_ids = [];
    this.selected_reported_by_ids = [];
    this.date_filter_range = []
  }

}
