import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoauthPrequalificationsComponent } from './noauth-prequalifications/noauth-prequalifications.component';
import {NoauthUserPublicProfileComponent} from "./noauth-user-public-profile/noauth-user-public-profile.component";

const routes: Routes = [
  {
    path: 'noauth',
    children: [
      {
        path: 'prequalifications/:hash',
        component: NoauthPrequalificationsComponent
      },
      {
        path: 'user-public-profile/:hash',
        component: NoauthUserPublicProfileComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NoauthRoutingModule {}
