import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '../directives/directives.module';
import { TaStepsEditComponent } from './steps/ta-steps-edit/ta-steps-edit.component';
import { TaStepsViewComponent } from './steps/ta-steps-view/ta-steps-view.component';
import { TaStepsComponent } from './steps/ta-steps/ta-steps.component';
import { TaskAnalysesEditComponent } from './task-analyses-edit/task-analyses-edit.component';
import { TaskAnalysesRoutingModule } from './task-analyses-routing.module';
import { TaskAnalysesViewComponent } from './task-analyses-view/task-analyses-view.component';
import { TaskAnalysesComponent } from './task-analyses/task-analyses.component';
import { TaWorkersEditComponent } from './workers/ta-workers-edit/ta-workers-edit.component';
import { TaWorkersViewComponent } from './workers/ta-workers-view/ta-workers-view.component';
import { TaWorkersComponent } from './workers/ta-workers/ta-workers.component';
import { FilesModule } from '../files/files.module';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [
    TaStepsComponent,
    TaskAnalysesComponent,
    TaskAnalysesEditComponent,
    TaskAnalysesViewComponent,
    TaWorkersComponent,
    TaWorkersEditComponent,
    TaWorkersViewComponent,
    TaStepsComponent,
    TaStepsEditComponent,
    TaStepsViewComponent
  ],
  imports: [
    CommonModule,
    TaskAnalysesRoutingModule,
    FormsModule,
    MatBadgeModule,
    MatMenuModule,
    MatListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatTabsModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    DirectivesModule,
    FilesModule,
    PipesModule
  ],
  entryComponents: []
})
export class TaskAnalysesModule {}
