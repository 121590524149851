import { Auditable } from "../shared/auditable.model";

export class TaWorkerModel extends Auditable {
  id = 0;
  fullname = '';
  mobile = '';
  email = '';

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        // do not store unwanted data
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.fullname = '';
    this.mobile = '';
    this.email = '';
  }
}
