<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class=" mr-auto">
      <button
        mat-raised-button
        routerLink="/admin/sites"
        appMarginRight
        tourAnchor="tour.navigate.sites">
        <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
      </button>

    </span>

</nav>


<mat-tab-group *ngIf="site.id" class="bg-white">
  <mat-tab label="Overview">
    <ng-template matTabContent>
      <app-admin-site-details-edit></app-admin-site-details-edit>
    </ng-template>
  </mat-tab>
  <mat-tab label="Geofence Map">
    <ng-template matTabContent>
      <app-admin-site-details-map></app-admin-site-details-map>
    </ng-template>
  </mat-tab>
  <mat-tab label="Settings">
    <ng-template matTabContent>
      <app-admin-site-settings></app-admin-site-settings>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<app-admin-site-details-edit *ngIf="!site.id"></app-admin-site-details-edit>
