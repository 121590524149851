import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IncidentsTreatmentsPieChartComponent
} from "./incidents/incidents-treatments-pie-chart/incidents-treatments-pie-chart.component";
import {IncidentsStatusesPieChartComponent} from "./incidents/incidents-statuses-pie-chart/incidents-statuses-pie-chart.component";
import {IncidentsTypesPieChartComponent} from "./incidents/incidents-types-pie-chart/incidents-types-pie-chart.component";
import {
  IncidentsHistoricalComboChartComponent
} from "./incidents/incidents-historical-combo-chart/incidents-historical-combo-chart.component";
import {IncidentsStatsTableComponent} from "./incidents/incidents-stats-table/incidents-stats-table.component";
import {MatTableModule} from "@angular/material/table";
import {
  IncidentsChartsContainerComponent
} from "./incidents/incidents-charts-container/incidents-charts-container.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatCardModule} from "@angular/material/card";
import {MatTooltipModule} from "@angular/material/tooltip";
import {
  IncidentsByTypeComboChartComponent
} from "./incidents/incidents-by-type-combo-chart/incidents-by-type-combo-chart.component";
import {
  IncidentsByBodyPartsPieChartComponent
} from "./incidents/incidents-by-body-parts-pie-chart/incidents-by-body-parts-pie-chart.component";
import {MasterChartsPopupDialogComponent} from "./master-charts-popup-dialog/master-charts-popup-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTabsModule} from "@angular/material/tabs";
import {
  SafetyObservationsChartsContainerComponent
} from "./safety-observations/safety-observations-charts-container/safety-observations-charts-container.component";
import {
  SafetyObservationsCategoriesPieChartComponent
} from "./safety-observations/safety-observations-categories-pie-chart/safety-observations-categories-pie-chart.component";
import {
  SafetyObservationsTypesPieChartComponent
} from "./safety-observations/safety-observations-types-pie-chart/safety-observations-types-pie-chart.component";
import {
  SafetyObservationsStatusesPieChartComponent
} from "./safety-observations/safety-observations-statuses-pie-chart/safety-observations-statuses-pie-chart.component";
import {
  SafetyObservationsPrioritiesPieChartComponent
} from "./safety-observations/safety-observations-priorities-pie-chart/safety-observations-priorities-pie-chart.component";
import {
  SafetyObservationsHistoricalComboChartComponent
} from "./safety-observations/safety-observations-historical-combo-chart/safety-observations-historical-combo-chart.component";
import {
  SafetyObservationsStatsTableComponent
} from "./safety-observations/safety-observations-stats-table/safety-observations-stats-table.component";
import {
  SafetyObservationsTasksStatusesPieChartComponent
} from "./safety-observations/safety-observations-tasks-statuses-pie-chart/safety-observations-tasks-statuses-pie-chart.component";
import {
  DynamicFormsTemplatesStatsTableComponent
} from "./dynamic-forms/dynamic-forms-templates-stats-table/dynamic-forms-templates-stats-table.component";
import {
  DynamicFormsChartsContainerComponent
} from "./dynamic-forms/dynamic-forms-charts-container/dynamic-forms-charts-container.component";
import {MatPaginatorModule} from "@angular/material/paginator";
import {
  SiteAccessActivitiesTypesLineChartComponent
} from "./site-access-activities/site-access-activities-types-line-chart/site-access-activities-types-line-chart.component";
import {
  SiteAccessActivitiesChartsContainerComponent
} from "./site-access-activities/site-access-activities-charts-container/site-access-activities-charts-container.component";
import {
  SiteAccessActivitiesMethodsPieChartComponent
} from "./site-access-activities/site-access-activities-methods-pie-chart/site-access-activities-methods-pie-chart.component";
import {
  SiteAccessActivitiesAcknowledgedPieChartComponent
} from "./site-access-activities/site-access-activities-acknowledged-pie-chart/site-access-activities-acknowledged-pie-chart.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {
  SiteAuditsTemplatesStatsTableComponent
} from "./site-audits/site-audits-templates-stats-table/site-audits-templates-stats-table.component";
import {
  SiteAuditsChartsContainerComponent
} from "./site-audits/site-audits-charts-container/site-audits-charts-container.component";
import {
  InductionsChartsContainerComponent
} from "./inductions/inductions-charts-container/inductions-charts-container.component";
import {InductionsStatsTableComponent} from "./inductions/inductions-stats-table/inductions-stats-table.component";
import {
  TasksHistoricalComboChartComponent
} from "./tasks/tasks-historical-combo-chart/tasks-historical-combo-chart.component";
import {TasksStatusesPieChartComponent} from "./tasks/tasks-statuses-pie-chart/tasks-statuses-pie-chart.component";
import {
  TasksPrioritiesPieChartComponent
} from "./tasks/tasks-priorities-pie-chart/tasks-priorities-pie-chart.component";
import {TasksChartsContainerComponent} from "./tasks/tasks-charts-container/tasks-charts-container.component";
import {
  DynamicFormsAssignedStatsTableComponent
} from "./dynamic-forms/dynamic-forms-assigned-stats-table/dynamic-forms-assigned-stats-table.component";
import {
  SitesHistoricalComboChartComponent
} from "./sites/sites-historical-combo-chart/sites-historical-combo-chart.component";
import {SitesChartsContainerComponent} from "./sites/sites-charts-container/sites-charts-container.component";
import {SitesTypesPieChartComponent} from "./sites/sites-types-pie-chart/sites-types-pie-chart.component";
import {SitesStatusesPieChartComponent} from "./sites/sites-statuses-pie-chart/sites-statuses-pie-chart.component";
import {
  SitesRiskAssessmentsPieChartComponent
} from "./sites/sites-risk-assessments-pie-chart/sites-risk-assessments-pie-chart.component";
import {
  SitesAfterHoursNotificationsStatusesPieChartComponent
} from "./sites/sites-after-hours-notifications-statuses-pie-chart/sites-after-hours-notifications-statuses-pie-chart.component";
import {
  SitesGeofenceStatusesPieChartComponent
} from "./sites/sites-geofence-statuses-pie-chart/sites-geofence-statuses-pie-chart.component";
import {
  SitesTagsStackedBarChartComponent
} from "./sites/sites-tags-stacked-bar-chart/sites-tags-stacked-bar-chart.component";
import {HazardsChartsContainerComponent} from "./hazards/hazards-charts-container/hazards-charts-container.component";
import {
  HazardsHistoricalComboChartComponent
} from "./hazards/hazards-historical-combo-chart/hazards-historical-combo-chart.component";
import {
  HazardsStatusesPieChartComponent
} from "./hazards/hazards-statuses-pie-chart/hazards-statuses-pie-chart.component";
import {
  HazardsRiskAssessmentsMatrixChartComponent
} from "./hazards/hazards-risk-assessments-matrix-chart/hazards-risk-assessments-matrix-chart.component";
import {SharedModule} from "../shared/shared.module";
import {
  ToolboxTalksHistoricalComboChartComponent
} from "./toolbox-talks/toolbox-talks-historical-combo-chart/toolbox-talks-historical-combo-chart.component";
import {
  ToolboxTalksChartsContainerComponent
} from "./toolbox-talks/toolbox-talks-charts-container/toolbox-talks-charts-container.component";
import {MessagesTypesPieChartComponent} from "./messages/messages-types-pie-chart/messages-types-pie-chart.component";
import {
  MessagesHistoricalComboChartComponent
} from "./messages/messages-historical-combo-chart/messages-historical-combo-chart.component";
import {
  MessagesPrioritiesPieChartComponent
} from "./messages/messages-priorities-pie-chart/messages-priorities-pie-chart.component";
import {
  MessagesStatusesPieChartComponent
} from "./messages/messages-statuses-pie-chart/messages-statuses-pie-chart.component";
import {
  MessagesChartsContainerComponent
} from "./messages/messages-charts-container/messages-charts-container.component";
import {
  DynamicFormsCategoriesAssignedStatsTableComponent
} from "./dynamic-forms/dynamic-forms-categories-assigned-stats-table/dynamic-forms-categories-assigned-stats-table.component";
import {
  DynamicFormsAssignedHistoricalComboChartComponent
} from "./dynamic-forms/dynamic-forms-assigned-historical-combo-chart/dynamic-forms-assigned-historical-combo-chart.component";
import {
  SiteAuditsHistoricalComboChartComponent
} from "./site-audits/site-audits-historical-combo-chart/site-audits-historical-combo-chart.component";

@NgModule({
  entryComponents: [
    MasterChartsPopupDialogComponent
  ],
  declarations: [
    IncidentsTreatmentsPieChartComponent,
    IncidentsStatusesPieChartComponent,
    IncidentsTypesPieChartComponent,
    IncidentsHistoricalComboChartComponent,
    IncidentsStatsTableComponent,
    IncidentsChartsContainerComponent,
    IncidentsByTypeComboChartComponent,
    IncidentsByBodyPartsPieChartComponent,
    MasterChartsPopupDialogComponent,
    SafetyObservationsChartsContainerComponent,
    SafetyObservationsCategoriesPieChartComponent,
    SafetyObservationsTypesPieChartComponent,
    SafetyObservationsStatusesPieChartComponent,
    SafetyObservationsPrioritiesPieChartComponent,
    SafetyObservationsHistoricalComboChartComponent,
    SafetyObservationsStatsTableComponent,
    SafetyObservationsTasksStatusesPieChartComponent,
    DynamicFormsTemplatesStatsTableComponent,
    DynamicFormsChartsContainerComponent,
    SiteAccessActivitiesTypesLineChartComponent,
    SiteAccessActivitiesChartsContainerComponent,
    SiteAccessActivitiesMethodsPieChartComponent,
    SiteAccessActivitiesAcknowledgedPieChartComponent,
    SiteAuditsTemplatesStatsTableComponent,
    SiteAuditsChartsContainerComponent,
    InductionsChartsContainerComponent,
    InductionsStatsTableComponent,
    TasksHistoricalComboChartComponent,
    TasksStatusesPieChartComponent,
    TasksPrioritiesPieChartComponent,
    TasksChartsContainerComponent,
    DynamicFormsAssignedStatsTableComponent,
    SitesHistoricalComboChartComponent,
    SitesChartsContainerComponent,
    SitesTypesPieChartComponent,
    SitesStatusesPieChartComponent,
    SitesRiskAssessmentsPieChartComponent,
    SitesAfterHoursNotificationsStatusesPieChartComponent,
    SitesGeofenceStatusesPieChartComponent,
    SitesTagsStackedBarChartComponent,
    HazardsChartsContainerComponent,
    HazardsHistoricalComboChartComponent,
    HazardsStatusesPieChartComponent,
    HazardsRiskAssessmentsMatrixChartComponent,
    ToolboxTalksHistoricalComboChartComponent,
    ToolboxTalksChartsContainerComponent,
    MessagesTypesPieChartComponent,
    MessagesHistoricalComboChartComponent,
    MessagesPrioritiesPieChartComponent,
    MessagesStatusesPieChartComponent,
    MessagesChartsContainerComponent,
    DynamicFormsCategoriesAssignedStatsTableComponent,
    DynamicFormsAssignedHistoricalComboChartComponent,
    SiteAuditsHistoricalComboChartComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatExpansionModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    DragDropModule,
    MatCardModule,
    MatTooltipModule,
    MatDialogModule,
    MatTabsModule,
    MatPaginatorModule,
    MatCheckboxModule,
    SharedModule
  ],
  exports: [
    IncidentsTreatmentsPieChartComponent,
    IncidentsStatusesPieChartComponent,
    IncidentsTypesPieChartComponent,
    IncidentsHistoricalComboChartComponent,
    IncidentsStatsTableComponent,
    IncidentsChartsContainerComponent,
    IncidentsByTypeComboChartComponent,
    IncidentsByBodyPartsPieChartComponent,
    MasterChartsPopupDialogComponent,
    SafetyObservationsChartsContainerComponent,
    SafetyObservationsCategoriesPieChartComponent,
    SafetyObservationsTypesPieChartComponent,
    SafetyObservationsStatusesPieChartComponent,
    SafetyObservationsPrioritiesPieChartComponent,
    SafetyObservationsHistoricalComboChartComponent,
    SafetyObservationsStatsTableComponent,
    SafetyObservationsTasksStatusesPieChartComponent,
    DynamicFormsTemplatesStatsTableComponent,
    DynamicFormsChartsContainerComponent,
    SiteAccessActivitiesTypesLineChartComponent,
    SiteAccessActivitiesChartsContainerComponent,
    SiteAccessActivitiesMethodsPieChartComponent,
    SiteAccessActivitiesAcknowledgedPieChartComponent,
    SiteAuditsTemplatesStatsTableComponent,
    SiteAuditsChartsContainerComponent,
    InductionsChartsContainerComponent,
    InductionsStatsTableComponent,
    TasksHistoricalComboChartComponent,
    TasksStatusesPieChartComponent,
    TasksPrioritiesPieChartComponent,
    TasksChartsContainerComponent,
    DynamicFormsAssignedStatsTableComponent,
    SitesHistoricalComboChartComponent,
    SitesChartsContainerComponent,
    SitesTypesPieChartComponent,
    SitesStatusesPieChartComponent,
    SitesRiskAssessmentsPieChartComponent,
    SitesAfterHoursNotificationsStatusesPieChartComponent,
    SitesGeofenceStatusesPieChartComponent,
    SitesTagsStackedBarChartComponent,
    HazardsChartsContainerComponent,
    HazardsHistoricalComboChartComponent,
    HazardsStatusesPieChartComponent,
    HazardsRiskAssessmentsMatrixChartComponent,
    ToolboxTalksHistoricalComboChartComponent,
    ToolboxTalksChartsContainerComponent,
    MessagesTypesPieChartComponent,
    MessagesHistoricalComboChartComponent,
    MessagesPrioritiesPieChartComponent,
    MessagesStatusesPieChartComponent,
    MessagesChartsContainerComponent,
    DynamicFormsCategoriesAssignedStatsTableComponent,
    DynamicFormsAssignedHistoricalComboChartComponent,
    SiteAuditsHistoricalComboChartComponent
  ]
})
export class ChartsModule { }
