import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrequalificationModel } from 'src/app/models/prequalification.model';
import { NoauthService } from 'src/app/shared/noauth.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { PrequalScorePipe } from 'src/app/pipes/prequal-score.pipe';

@Component({
  selector: 'app-noauth-prequalifications',
  templateUrl: './noauth-prequalifications.component.html',
  styleUrls: ['./noauth-prequalifications.component.scss'],
  providers: [PrequalScorePipe]
})
export class NoauthPrequalificationsComponent implements OnInit {
  prequal = new PrequalificationModel();
  score: number;

  constructor(
    private route: ActivatedRoute,
    private noauthService: NoauthService,
    private utils: UtilsService,
    private prequalScorePipe: PrequalScorePipe
  ) {}

  ngOnInit() {
    this.find();
  }

  find() {
    this.noauthService
      .findPrequal(this.route.snapshot.params['hash'])
      .subscribe((response) => {
        this.prequal = response.data;
        this.score = this.prequalScorePipe.transform(this.prequal);
      });
  }

  onComplete() {
    this.noauthService.completePrequal(this.prequal.hash).subscribe(() => {
      this.utils.showToast(
        `You submitted "${this.prequal.name}". ${this.prequal.account.name} was notified.`
      );
      this.find();
    });
  }
}
