import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ApiRequestService} from "../../../shared/api-request.service";
import {UtilsService} from "../../../shared/utils.service";
import {RiskMatrixComponent} from "../../../shared/risk-matrix/risk-matrix.component";
import {StorageService} from "../../../shared/storage.service";
import {ChartsUtilsService} from "../../charts-utils.service";
import {HazardsFilters} from "../../../hazards/hazards-filter/hazards-filter.interface";

declare var google;

@Component({
  selector: 'app-hazards-risk-assessments-matrix-chart',
  templateUrl: './hazards-risk-assessments-matrix-chart.component.html',
  styleUrls: ['./hazards-risk-assessments-matrix-chart.component.scss']
})
export class HazardsRiskAssessmentsMatrixChartComponent implements OnInit, OnDestroy {

  // Config storage key. This must be unique when multiple containers are stored on one page.
  @Input('configStorageKey') configStorageKey: string;

  // Get the risk matrix ref.
  risk_matrix_ref: RiskMatrixComponent;

  // The chart object.
  chart: any;

  // Input to switch near realtime data polling on or off.
  @Input('enable_realtime_data') enable_realtime_data: boolean;
  // Input to change how long between intervals in seconds.
  @Input('realtime_data_interval_seconds') realtime_data_interval_seconds: number;
  // The realtime data interval for polling data.
  private realtime_data_interval: any;

  // The filters to use for API requests.
  @Input('filters') filters: HazardsFilters;

  // Input to switch near realtime data polling on or off. This can only be single or multiple.
  @Input('query_type') query_type: string;

  // Used to pass a reference to this component back to the parent component.
  @Output() referenceEvent: EventEmitter<HazardsRiskAssessmentsMatrixChartComponent> = new EventEmitter<HazardsRiskAssessmentsMatrixChartComponent>();

  // Determine if the chart can be downloaded or not.
  @Input('canBeDownloaded') canBeDownloaded: boolean;

  // The name of the file that will be used when the chart is downloaded.
  @Input('chartDownloadFilename') chartDownloadFilename: string;

  // The name of the chart for the parent card title.
  cardTitle: string = 'Risk Matrix';

  // Used to store the chart data.
  chartData: any[][] = [
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0],
    [0,0,0,0,0]
  ];

  // The default incident type storage key.
  defaultRiskAssessmentTypeStorageKey: string;

  // The selected risk assessment type.
  selectedRiskAssessmentType: string = '';

  // The available risk assessment types.
  riskAssessmentTypes: any = [
    {
      type: '',
      label: 'All Risk Assessments'
    },
    {
      type: 'ira',
      label: 'Initial Risk Assessments'
    },
    {
      type: 'rra',
      label: 'Residual Risk Assessments'
    }
  ];

  constructor(
    private api: ApiRequestService,
    public utils: UtilsService,
    private storage: StorageService,
    public chartsUtils: ChartsUtilsService
  ) { }

  async ngOnInit(): Promise<any> {
    // Define the default incident type storage key.
    this.defaultRiskAssessmentTypeStorageKey = this.configStorageKey + '-default-risk-assessment-type';
    // Validate the default config.
    this.validateDefaultConfig();
    // Get the default incident type from storage.
    const defaultRiskAssessmentTypeStorageObject = await this.storage.dbGet('charts', this.defaultRiskAssessmentTypeStorageKey);
    // Get the default incident type from storage. Set as Near Miss if it was not found.
    this.selectedRiskAssessmentType = defaultRiskAssessmentTypeStorageObject ? defaultRiskAssessmentTypeStorageObject.default_risk_assessment_type : '';
    // Set the card title.
    this.cardTitle = 'Risk Matrix (' + (this.selectedRiskAssessmentType.toUpperCase() || 'ALL') + ')';
    // Update the download filename of the chart.
    this.chartDownloadFilename = this.cardTitle + ' Chart';
    // Get the data from the api.
    this.getData();
    // Check if realtime data should be pulled.
    if ( this.enable_realtime_data ) {
      // Create an interval to poll the API.
      this.realtime_data_interval = setInterval(() => {
        // Make a request to get the data.
        this.getData();
      }, this.realtime_data_interval_seconds * 1000);
    }
    // Send a reference to the parent component.
    this.referenceEvent.emit(this);
  }

  /**
   * Lifecycle hook called when the component is about to be destroyed.
   * It clears the near realtime data interval if it was set.
   */
  ngOnDestroy(): void {
    // Check if near realtime data polling is enabled and if the interval is set.
    if ( this.enable_realtime_data && this.realtime_data_interval ) {
      // Clear the interval to stop the data polling.
      clearInterval(this.realtime_data_interval);
    }
  }

  /**
   * Validates the configuration of a chart.
   * Ensures that certain properties are defined and sets default values if not provided.
   *
   * @return {void}
   */
  validateDefaultConfig(): void {
    // Ensure realtime data is defined and set to false if not provided.
    if ( typeof this.enable_realtime_data == 'undefined' ) {
      this.enable_realtime_data = false;
    }
    // Ensure realtime data interval seconds is defined and set to 60 if not provided.
    if ( typeof this.realtime_data_interval_seconds == 'undefined' ) {
      this.realtime_data_interval_seconds = 60;
    }
    // Ensure the query type is defined and set to 'single' if not provided. It can be 'multiple'.
    if ( typeof this.query_type == 'undefined' ) {
      this.query_type = 'single';
    }
    // Ensure "can be downloaded" is defined and set to true if not provided.
    if ( typeof this.canBeDownloaded == 'undefined' ) {
      setTimeout(() => {
        this.canBeDownloaded = true;
      }, 1000); // Need delay due to change detection errors in dev.
    }
    // Ensure "chart download filename" is defined and set a default if not provided.
    if ( typeof this.chartDownloadFilename == 'undefined' ) {
      this.chartDownloadFilename = this.cardTitle + ' Chart';
    }
  }

  /**
   * Handles the event when the risk assessment type is changed.
   * Updates the default incident type in storage, changes the chart title,
   * updates the file download filename, and reloads the data from the API.
   *
   * @return A promise representing the completion of the method.
   */
  async onRiskAssessmentTypeChanged(): Promise<any> {
    // Update the default incident type in storage.
    await this.storage.dbUpdateOrCreate('charts', { default_risk_assessment_type: this.selectedRiskAssessmentType , id: this.defaultRiskAssessmentTypeStorageKey});
    // Update the name of the chart.
    this.cardTitle = 'Risk Matrix (' + (this.selectedRiskAssessmentType.toUpperCase() || 'ALL') + ')';
    // Update the file download filename.
    this.chartDownloadFilename = this.cardTitle + ' Chart';
    // Reload the data from the API.
    this.getData();
  }

  /**
   * Sets the reference to the RiskMatrixComponent instance for further use.
   *
   * @param {RiskMatrixComponent} event - The event object representing the RiskMatrixComponent instance.
   * @return {void}
   */
  getRiskMatrixRef(event: RiskMatrixComponent): void {
    // Store the reference.
    this.risk_matrix_ref = event;
  }

  /**
   * Updates the filters from the parent component.
   *
   * @param {any} filters - The new filters to update.
   * @return {void}
   */
  updateFiltersFromParentComponent(filters: any): void {
    // Update the filters.
    this.filters = filters;
  }

  /**
   * Retrieves data from the API and updates the chart visuals.
   *
   * @returns {void}
   */
  getData(): void {
    // Normalize the query params.
    const queryParams:{[p: string]: any} = this.utils.normalizeQueryParams({
      ...this.filters,
      query_type: this.query_type,
      date_range: this.filters.date_range.map((date: Date) => {
        return date.getTime() / 1000;
      }).join(','),
      risk_assessment_type: this.selectedRiskAssessmentType
    });
    // Get data from the API.
    this.api.makeRequest('get', 'v2/hazards/charts/risk-assessments', {}, queryParams)
      .then((response) => {
        // Store the chart data.
        this.chartData = response;
      });
  }

  /**
   * Executes the onDownload function if it is defined.
   *
   * @return {void}
   */
  onDownload(): void {
    // Check if a download function is defined.
    if ( typeof this.risk_matrix_ref.onDownload == 'function' ) {
      // Call the custom download function.
      this.risk_matrix_ref.onDownload();
    }
  }
}
