<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="javascript:void(0)">
    Inspection/Audit Questions
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <span class="mr-auto">
      <button mat-raised-button color="primary" (click)="add()" id="add" class="margin-right">
        <mat-icon>add</mat-icon>
        Add Question
      </button>

      <button mat-raised-button  class="mr-2" color="" (click)="onTriggerFileSelector($event)" matTooltip="Click this button to import Inspection/Audit questions from a CSV file.">
        <mat-icon>cloud_upload</mat-icon> Import CSV
      </button>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>
        <button mat-menu-item (click)="onExportSelected('csv')">
          <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
        </button>
        <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>delete</mat-icon> Delete Selected
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect and/or export Inspection/Audit questions." style="min-width: 150px">
        <mat-icon>more_vert</mat-icon>
        List Menu/Export
      </button>
    </span>

    <mat-select [(ngModel)]="selectedSection" placeholder="Section Filter" class="navbar-brand selection-filter" (selectionChange)="filterSection()">
      <mat-option value="">All Sections</mat-option>
      <mat-option *ngFor="let section of sections" [value]="section">
        {{ section }}
      </mat-option>
    </mat-select>

    <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (ngSubmit)="dataSource.getData(true)">
      <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search" aria-label="Search" />
      <button mat-raised-button class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0" color="secondary" (click)="dataSource.getData(false)" type="button" id="search">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>
  </div>

</nav>

<div class="mat-elevation-z8 scroll-box">
  <table mat-table [dataSource]="dataSource" style="width: 100%" matSort matSortActive="name" matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        #
      </th>
      <td mat-cell *matCellDef="let auditItem" class="no-padding">
        {{ auditItem.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Question
      </th>
      <td mat-cell *matCellDef="let auditItem" class="no-padding">
        {{ auditItem.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="section">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Section
      </th>
      <td mat-cell *matCellDef="let auditItem" class="no-padding">
        {{ auditItem.section }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Date Created
      </th>
      <td mat-cell *matCellDef="let auditItem" class="no-padding">
        {{ (auditItem.date_created * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Date Created (UTC)
      </th>
      <td mat-cell *matCellDef="let auditItem" class="no-padding">
        {{ (auditItem.date_created * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef class="custom-menu"></th>

      <td mat-cell *matCellDef="let auditItem">
        <button type="button" mat-button (click)="edit(auditItem)" matTooltip="Edit the Inspection/Audit question information." matTooltipPosition="above">
          Edit
        </button>

        <button type="button" mat-button (click)="onRemove(auditItem)" matTooltip="Remove (Archive) the Inspection/Audit question." matTooltipPosition="above">
          Delete
        </button>
      </td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!--import file is triggered from a menu option in the list-->
  <input type="file" mat-raised-button (change)="onImport($event)" style="display: none;" #fileInput />

  <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons>
  </mat-paginator>
</div>
