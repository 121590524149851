<div mat-dialog-title>
  <h5>{{ utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') }} Form</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form #f="ngForm" class="form-container" appMarginBottom (ngSubmit)="onSubmit(f)">

    <div class="d-flex justify-content-between py-3">
      <button mat-raised-button color="secondary" (click)="onSelectReportedBy()"
        [matBadge]="toolbox_talk.reported_by ? '1' : '0'" matTooltip="You can optionally select a reviewer." type="button">
        <mat-icon>rate_review</mat-icon>
        {{ !toolbox_talk.reported_by ? 'Assign' : 'Change' }} Conducted By User
      </button>

      <div *ngIf="toolbox_talk.id"
        [matTooltip]="'You can copy this ' + utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + ' to other ' + utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() + '.'">
        <mat-checkbox [(ngModel)]="toolbox_talk.copy_to_all_sites" name="copy_to_all_sites" appMarginRight appMarginTop>
          Copy to all {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
          or
        </mat-checkbox>
        <button mat-raised-button type="button" color="secondary" (click)="onSelectSites()" appMarginRight [matBadge]="
           toolbox_talk.copy_to_all_sites ? 'All' : toolbox_talk.selected_site_ids?.length.toString()" matBadgePosition="below before">
          <mat-icon>file_copy</mat-icon>
          Copy to Selected {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
        </button>
      </div>
    </div>

    <mat-form-field>
      <input matInput [placeholder]="utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk')"
        [(ngModel)]="toolbox_talk.name" name="name" type="text" #name required />
      <mat-error *ngIf="!name.valid">
        Please enter the name or subject of the {{ utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') }}.
      </mat-error>
    </mat-form-field>

    <editor style="margin: 0.6rem 0;" placeholder="Induction" [(ngModel)]="toolbox_talk.agenda"
      name="toolbox_talks_agenda" id="toolbox_talks_agenda" [init]="{
        skin_url: '/assets/skins/lightgray',
        plugins: 'link,image,lists,hr,table,media',
        height: '350'
      }">
    </editor>

    <mat-form-field *ngIf="toolbox_talk.id && toolbox_talk.site">
      <input matInput placeholder="{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Name" [value]="toolbox_talk.site?.name" type="text" disabled />
    </mat-form-field>

    <mat-form-field *ngIf="toolbox_talk.id && toolbox_talk.site">
      <input matInput placeholder="{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Address" [value]="toolbox_talk.site?.location" type="text" disabled />
    </mat-form-field>

    <mat-form-field *ngIf="toolbox_talk.id && toolbox_talk.users.length">
      <input matInput [value]="formatUsers(toolbox_talk.users)" placeholder="Attendees" name="attendees" disabled />
    </mat-form-field>

    <mat-form-field *ngIf="toolbox_talk.id && toolbox_talk.creator">
      <input matInput placeholder="Created By" [value]="toolbox_talk.creator?.contact_person" type="text" disabled />
    </mat-form-field>

    <mat-form-field *ngIf="toolbox_talk.id">
      <input matInput placeholder="Created At" [value]="(toolbox_talk.date_created * 1000) | timezoneConvertLong"
        type="text" disabled />
    </mat-form-field>

    <mat-form-field *ngIf="toolbox_talk.id && toolbox_talk.updater">
      <input matInput placeholder="Last Updated By" [value]="toolbox_talk.updater?.contact_person" type="text"
        disabled />
    </mat-form-field>

    <mat-form-field *ngIf="toolbox_talk.id">
      <input matInput placeholder="Last Updated At"
        [value]="(toolbox_talk.date_modified * 1000) | timezoneConvertMedium" type="text" disabled />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button *ngIf="!toolbox_talk.id" [disabled]="!f.valid || inProgress" color="primary"
          (click)="onSubmit(f)" class="save-button" type="button" appMarginRight style="max-width: 160px;">
    <mat-icon>save</mat-icon>
    Save & Close
  </button>

  <button mat-raised-button *ngIf="toolbox_talk.id" [disabled]="!f.valid || inProgress" color="primary"
    (click)="onSubmit(f)" class="save-button" type="button" style="max-width: 100px;">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button mat-raised-button *ngIf="toolbox_talk.id" [disabled]="!f.valid || inProgress" color="primary"
          (click)="onSubmit(f, true)" class="save-button" type="button" appMarginRight style="max-width: 180px;">
    <mat-icon>save</mat-icon>
    Save & Continue
  </button>

  <app-files-selector (selected)="newFiles = $event" [selectedFilesCount]="newFiles?.length" appMarginRight>
  </app-files-selector>

  <button mat-raised-button (click)="onRemoveFiles()" color="secondary" [matBadge]="toolbox_talk?.files.length.toString()"
    [disabled]="!toolbox_talk?.files.length" type="button" class="remove-files">
    <mat-icon>open_in_browser</mat-icon>
    Manage Files
  </button>

  <button mat-raised-button (click)="onSelectUsers()" color="secondary" [matBadge]="toolbox_talk?.user_ids.length.toString()"
    type="button">
    <mat-icon>people</mat-icon>
    Attendees
  </button>

  <button mat-raised-button (click)="onExport('pdf')" color="secondary" type="button"
    *ngIf="toolbox_talk.id && toolbox_talk.site_id">
    <mat-icon>cloud_download</mat-icon>
    Export PDF
  </button>

  <button mat-raised-button (click)="onExport('csv')" color="secondary" type="button"
    *ngIf="toolbox_talk.id && toolbox_talk.site_id">
    <mat-icon>cloud_download</mat-icon>
    Export CSV
  </button>
</mat-dialog-actions>
