import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from '../models/user.model';
import { RemoteService } from './remote.service';
import { StorageService } from './storage.service';
import { UtilsService } from './utils.service';
import {AppService} from "../app.service";
import {SessionService} from "./session.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {
  user: UserModel = new UserModel();

  constructor(
    public remote: RemoteService,
    private sessionService: SessionService,
    private app: AppService,
    public utils: UtilsService,
    public storage: StorageService,
    public router: Router
  ) {}

  ngOnInit() {}

  signin(callback?: any): void {
    this.storage.set('AuthToken', '401'); // 401 - unauthenticated, authentication not required

    // make the signin request
    this.remote.serviceAPI(
      'post',
      'sign-in',
      this.user,
      {},
      {
        success: (response) => {
          // success will only execute if the status is ok

          this.storage.set('isAuthenticated', true);

          // set the user's auth token
          this.storage.set('AuthToken', response.data.auth_token);
          this.storage.set('Subscription', response.data.subscription);
          this.storage.set('isSiteSoftAdmin', response.data.is_sitesoft_admin);

          if (response.data.subscription_expired) {
            this.utils.showToast(
              'Your subscription expired. You will have limited access to features. You are now logged in.'
            );
          } else {
            this.utils.showToast('You are now logged in.');
          }

          // get the user's accessible sites
          // this.sitesService.getAccessibleSites(() => {

          // show the user they are logged in

          // execute the callback methos
          if (typeof callback === 'function') {
            callback(response);
          }
          // });
        }
      }
    );
  }

  /**
   *
   * @param callback a callback method that is executed upon success
   *
   * adds a new record
   */
  signup(callback?: any): void {
    this.storage.set('AuthToken', '401'); // 401 - unauthenticated, authentication not required

    // make the signin request
    this.remote.serviceAPI(
      'post',
      'signup',
      this.user,
      {},
      {
        success: (response) => {
          // success will only execute if the status is ok

          // get the user's accessible sites
          // this.sitesService.getAccessibleSites(() => {

          // show the user they are logged in
          this.utils.showToast('You successfully registered for an account.');

          // execute the callback methos
          if (typeof callback === 'function') {
            callback(response);
          }
          // });
        }
      }
    );
  }

  signout(additionalMessage: string = ''): void {
    this.app.isAuthenticated = false;

    this.app.user.reset();
    this.app.account.reset();

    // keep for backward compatability
    this.sessionService.signout(additionalMessage);
  }

}
