<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/safety-observations">Related Files</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <app-file-browse-button
          (afterDialogClosedEvent)="dataSource.getData()"
          parentObjectType="safety_observation"
          [parentObjectId]="safety_observation_id"
          [filesCount]="dataSource?.total | async">
        </app-file-browse-button>
      </span>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Toggle select all files in this list.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let file">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(file.id) : null"
            [checked]="dataSource.selection.isSelected(file.id)"
            matTooltip="Toggle select this file.">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- File ID column. -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the id of the file.">
          ID #
        </th>
        <td mat-cell *matCellDef="let file">
          {{ file.id }}
        </td>
      </ng-container>

      <!-- Image thumbnail if any. -->
      <ng-container matColumnDef="thumbnail">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let file">
          <img class="img-thumbnail" (click)="onViewFile(file.url)" [src]="file.url" [alt]="file.name" *ngIf="utils.isImage(file.url)">
        </td>
      </ng-container>

      <!-- Name of the file. -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the name of the file.">
          File Name
        </th>
        <td mat-cell *matCellDef="let file">
          {{ file.name }}
        </td>
      </ng-container>

      <!-- The date the file was uploaded. -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the file was created at.">
          Created At
        </th>
        <td mat-cell *matCellDef="let file">
          {{ (file.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- The date the file was uploaded. -->
      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the file was created at.">
          Created At (UTC)
        </th>
        <td mat-cell *matCellDef="let file">
          {{ (file.date_created * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let file">
          <button type="button" mat-button (click)="onViewFile(file.url)" matTooltip="Click this button to view the file.">
            View
          </button>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
