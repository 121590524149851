import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hazards-hierarchy-of-controls',
  templateUrl: './hazards-hierarchy-of-controls.component.html',
  styleUrls: ['./hazards-hierarchy-of-controls.component.scss']
})
export class HazardsHierarchyOfControlsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
