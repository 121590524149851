import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { AppService } from '../../../app.service';
import { ApiService } from '../../../shared/api.service';
import { UtilsService } from '../../../shared/utils.service';
import * as moment from "moment";

interface IRange {
  value: Date[];
  label: string;
}

@Component({
  selector: 'app-legacy-dashboard-widget',
  templateUrl: './legacy-dashboard-widget.component.html',
  styleUrls: ['./legacy-dashboard-widget.component.scss']
})
export class LegacyDashboardWidgetComponent implements OnInit {
  // date picker var
  datepickerConfig: Partial<BsDatepickerConfig>;

  // site activities chart vars
  site_activities_chart: Chart;
  site_activities_chart_ref;
  site_activities_chart_data = {
    labels: [],
    data: []
  };

  // site activities chart vars
  hazards_chart: Chart;
  hazards_chart_ref;
  hazards_chart_data = {
    labels: [],
    data: []
  };

  // site activities chart vars
  incidents_chart: Chart;
  incidents_chart_ref;
  incidents_chart_data = {
    labels: [],
    data: []
  };

  // site activities chart vars
  users_chart: Chart;
  users_chart_ref;
  users_chart_data = {
    labels: [],
    data: []
  };

  // Date Range Picker Preset Data
  ranges: IRange[] = [{
    value: [
      moment().startOf('day').toDate(),
      moment().endOf('day').toDate()
    ],
    label: 'Today'
  }, {
    value: [
      moment().subtract(1, 'day').startOf('day').toDate(),
      moment().subtract(1, 'day').endOf('day').toDate()
    ],
    label: 'Yesterday'
  }, {
    value: [
      moment().startOf('week').toDate(),
      moment().endOf('week').toDate()
    ],
    label: 'This Week'
  }, {
    value: [
      moment().subtract(7, 'days').startOf('week').toDate(),
      moment().subtract(7, 'days').endOf('week').toDate()
    ],
    label: 'Last Week'
  }, {
    value: [
      moment().startOf('month').toDate(),
      moment().endOf('month').toDate()
    ],
    label: 'This Month'
  }, {
    value: [
      moment().subtract(1, 'month').startOf('month').toDate(),
      moment().subtract(1, 'month').endOf('month').toDate()
    ],
    label: 'Last Month'
  }, {
    value: [
      moment().subtract(2, 'months').startOf('month').toDate(),
      moment().endOf('month').toDate()
    ],
    label: 'Last Three Months'
  }, {
    value: [
      moment().subtract(5, 'months').startOf('month').toDate(),
      moment().endOf('month').toDate()
    ],
    label: 'Last Six Months'
  }, {
    value: [
      moment().subtract(11, 'months').startOf('month').toDate(),
      moment().endOf('month').toDate()
    ],
    label: 'Last Twelve Months'
  }];

  constructor(
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    // setting the date picker object to select date ranges
    this.datepickerConfig = Object.assign(
      {},
      {
        containerClass: 'theme-orange',
        dateInputFormat: 'DD MMM YYYY',
        rangeInputFormat: 'DD MMM YYYY',
        showWeekNumbers: true,
        adaptivePosition: true,
        ranges: this.ranges
      }
    );

    // the site activities chart
    this.site_activities_chart_ref = document.getElementById(
      'site_activities_chart_ref'
    );
    this.site_activities_chart = new Chart(this.site_activities_chart_ref, {
      type: 'line',
      data: {
        labels: this.site_activities_chart_data.labels,
        datasets: [
          {
            label: this.utils.getLangTerm('contractors.plural', 'Contractors') + '/Visitors Per Day',
            // backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: this.site_activities_chart_data.data
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    });

    // the site activities chart
    this.hazards_chart_ref = document.getElementById('hazards_chart_ref');
    this.hazards_chart = new Chart(this.hazards_chart_ref, {
      type: 'line',
      data: {
        labels: this.hazards_chart_data.labels,
        datasets: [
          {
            label: 'Hazards & Risks Per Day',
            // backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: this.hazards_chart_data.data
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    });

    // the site activities chart
    this.incidents_chart_ref = document.getElementById('incidents_chart_ref');
    this.incidents_chart = new Chart(this.incidents_chart_ref, {
      type: 'line',
      data: {
        labels: this.incidents_chart_data.labels,
        datasets: [
          {
            label: 'Incidents & Near Misses Per Day',
            // backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: this.incidents_chart_data.data
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    });

    // the site activities chart
    this.users_chart_ref = document.getElementById('users_chart_ref');
    this.users_chart = new Chart(this.users_chart_ref, {
      type: 'pie',
      data: {
        labels: this.users_chart_data.labels,
        datasets: [
          {
            backgroundColor: ['rgb(0, 90, 255)', 'rgb(255, 99, 132)'],
            // borderColor: 'rgb(255, 99, 132)',
            data: this.users_chart_data.data
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    });
    this.refreshStats();
  }

  refreshStats() {
    this.api.laravelApiRequest(
      'get',
      'dashboard/stats?from=' +
        this.app.filter_date_range[0].getTime() / 1000 +
        '&to=' +
        this.app.filter_date_range[1].getTime() / 1000 +
        '&selected_sites=' +
        this.app.chart_selected_sites.join(','),
      {},
      {},
      (response) => {
        // clear and populate the site activities charts
        this.site_activities_chart_data.labels.length = 0;
        this.site_activities_chart_data.labels.push(
          ...response.data.site_activities.labels
        );

        this.site_activities_chart_data.data.length = 0;
        this.site_activities_chart_data.data.push(
          ...response.data.site_activities.values
        );

        // clear and populate the hazards charts
        this.hazards_chart_data.labels.length = 0;
        this.hazards_chart_data.labels.push(...response.data.hazards.labels);

        this.hazards_chart_data.data.length = 0;
        this.hazards_chart_data.data.push(...response.data.hazards.values);

        // clear and populate the incidents and near misses charts
        this.incidents_chart_data.labels.length = 0;
        this.incidents_chart_data.labels.push(
          ...response.data.incidents.labels
        );

        this.incidents_chart_data.data.length = 0;
        this.incidents_chart_data.data.push(...response.data.incidents.values);

        // clear and populate the incidents and near misses charts
        this.users_chart_data.labels.length = 0;
        this.users_chart_data.labels.push(...response.data.users.labels);

        this.users_chart_data.data.length = 0;
        this.users_chart_data.data.push(...response.data.users.values);

        // update the charts
        this.site_activities_chart.update();
        this.hazards_chart.update();
        this.incidents_chart.update();
        this.users_chart.update();
      }
    );
  }

  onSelectSites() {
    // show the site selector
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.app.chart_selected_sites,
        multiple: true
      },
      {},
      (results) => {
        if (typeof results !== 'undefined') {
          this.app.chart_selected_sites = results;

          this.refreshStats();
        }
      }
    );
  }
}
