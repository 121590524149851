import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiRequestService} from 'src/app/shared/api-request.service';
import {MatTableDataSource} from "@angular/material/table";
import {UtilsService} from "../../../shared/utils.service";
import {ChartsUtilsService} from "../../charts-utils.service";
import {
  SafetyObservationsFilter
} from "../../../safety-observations/safety-observations-filter/safety-observations-filter.interface";

@Component({
  selector: 'app-safety-observations-stats-table',
  templateUrl: './safety-observations-stats-table.component.html',
  styleUrls: ['./safety-observations-stats-table.component.css']
})
export class SafetyObservationsStatsTableComponent implements OnInit {

  // The filters to use for API requests.
  @Input('filters') filters: SafetyObservationsFilter;

  // Input to switch near realtime data polling on or off. This can only be single or multiple.
  @Input('query_type') query_type: string;

  dataSource = new MatTableDataSource<SafetyObservationsStatistic>();
  displayedColumns: string[] = ['header', 'data'];

  // Used to pass a reference to this component back to the parent component.
  @Output() referenceEvent: EventEmitter<SafetyObservationsStatsTableComponent> = new EventEmitter<SafetyObservationsStatsTableComponent>();

  // Determine if the chart can be downloaded or not.
  @Input('canBeDownloaded') canBeDownloaded: boolean;

  // Set the card title for the parent component.
  cardTitle: string = 'Safety Observations Stats';

  constructor(
    private api: ApiRequestService,
    private utils: UtilsService,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit(): void {
    // Validate the default config.
    this.validateDefaultConfig();
    // Get the data from the API.
    this.getData();
    // Send a reference to the parent component.
    this.referenceEvent.emit(this);
  }

  /**
   * Validates the configuration of a chart.
   * Ensures that certain properties are defined and sets default values if not provided.
   *
   * @return {void}
   */
  validateDefaultConfig(): void {
    // Ensure the query type is defined and set to 'single' if not provided. It can be 'multiple'.
    if ( typeof this.query_type == 'undefined' ) {
      this.query_type = 'single';
    }
    // Ensure "can be downloaded" is defined and set to true if not provided.
    if ( typeof this.canBeDownloaded == 'undefined' ) {
      this.canBeDownloaded = false;
    }
  }

  /**
   * Updates the filters from the parent component.
   *
   * @param {any} filters - The new filters to update.
   * @return {void}
   */
  updateFiltersFromParentComponent(filters: any): void {
    // Update the filters.
    this.filters = filters;
  }

  /**
   * Retrieves data from the API and populates the dataSource.
   *
   * @return {void} The method does not return a value.
   */
  getData(): void {
    // Normalize the query params.
    const queryParams:{[p: string]: any} = this.utils.normalizeQueryParams({
      ...this.filters,
      query_type: this.query_type,
      date_range: this.filters.date_range.map((date: Date) => {
        return date.getTime() / 1000;
      }).join(',')
    });
    // Make a request to get the data from the API.
    this.api.makeRequest('get', 'v2/safety-observations/charts/stats', {}, queryParams).then((response) => {
      this.dataSource.data = [
        {header: 'Total Safety Observations', data: response.total_safety_observations},
        {header: 'Min Rating', data: response.min_so_rating},
        {header: 'Max Rating', data: response.max_so_rating},
        {header: 'Avg Rating', data: response.avg_so_rating}
      ];
    });
  }
}

/**
 * Interface representing an Safety Observation statistic.
 * @interface
 */
interface SafetyObservationsStatistic {
  header: string;
  data: number;
}
