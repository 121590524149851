import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { UserModel } from 'src/app/models/user.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-dynamic-forms-notification-users',
  templateUrl: './dynamic-forms-notification-users.component.html',
  styleUrls: ['./dynamic-forms-notification-users.component.scss']
})
export class DynamicFormsNotificationUsersComponent implements OnInit {

  @Input('form_id') form_id: number;

  notification_user_ids: number[] = [];

  displayedColumns: string[] = [
    'select',
    'id',
    'contact_person',
    'email',
    'name',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];

  dataSource = new DynamicFormsNotificationUsersDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService,
    private router: Router
  ) { }

  ngOnInit() {
    // Set the form id in the datasource and get the data.
    this.dataSource.form_id = this.form_id;

    this.getNotificationUserIds();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC columns by their corresponding date columns.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData(true);
    })).subscribe();
  }

  /**
   * Get a list of user ids from the API for this form.
   */
  private getNotificationUserIds() {
    // Make an API request to get the form data.
    this.api.makeRequest('get', `v2/dynamic-forms/notification-user-ids/${this.form_id}`)
    .then((response: number[]) => {
      // The response will contain notification user ids.
      this.notification_user_ids = response;
    })
    .catch((errorResponse: any) => {
      this.utils.handleAPIErrors(errorResponse);
    });

    this.dataSource.getData(true);
  }

  /**
   * Open the user select that allows the authenticated user to make a user selection.
   * Selected users will be notified when form submissions takes place.
   */
  onSelectUsers() {
    // Initialise the const user ids variable if undefined.
    if ( typeof this.notification_user_ids == 'undefined' ) {
      this.notification_user_ids = [];
    }
    // Open the dialog to make a user selection.
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      multiple: true,
      selected: this.notification_user_ids,
      visitors_from_all_sites: true
    })
    .then((response: number[]) => {
      if ( typeof response != 'undefined' ) {
        // The response will contain the list of selected user ids.
        this.notification_user_ids = response;

        // Make a request to sync users.
        this.api.makeRequest('put', `v2/dynamic-forms/notification-users/${this.form_id}`, {
          notification_user_ids: this.notification_user_ids
        })
        .then((response) => {
          // The response will contain the list of selected user ids.
          this.notification_user_ids = response;
        })
        .catch((errorResponse) => {})
        .finally(() => {
          this.getNotificationUserIds();
        })
      }
    });
  }

  /**
   * Show a quick action confirmation dialog before removing the user.
   * @param form The user object.
   * @param evt The referenced http element to attach the quick action to.
   */
   onDeleteUser(user: UserModel, evt: any) {
    this.utils.showQuickActions(evt.target, `Are you sure you want to remove "${user.contact_person}" from this list?`, [
      {
        text: 'Yes',
        handler: () => {
          this.deleteSelectedUsers([user.id]);
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  /**
   * Open a confirmation dialog before removing selected users.
   */
  onDeleteSelectedUsers() {
    this.utils.showModal(
      'Remove Selected Users',
      'Are you sure you want to remove the selected users?',
      () => {
        const archived = this.deleteSelectedUsers(this.dataSource.selection.selected);
        if ( archived != null ) {
          archived.then(() => {
            // Clear the list selection after removing the selected users.
            this.dataSource.selection.clear();
          });
        }
      }
    );
  }

  /**
   * Remove selected users from the notifications list.
   * @param userIds The selected user ids.
   * @returns a Promise which is used for callbacks.
   */
  private deleteSelectedUsers(userIds: number[]): null|Promise<any> {
    if ( !userIds.length ) {
      this.utils.showModal('Remove Selected Users', 'You need to select at least one user to remove.');
      return;
    }
    return this.api.makeRequest('delete', `v2/dynamic-forms/notification-users/${this.form_id}/${userIds.join(',')}`)
    .then(() => {
      this.utils.showToast('The selected users were removed.');
      this.getNotificationUserIds();
    })
    .catch((error) => {
      this.utils.handleAPIErrors(error);
    });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class DynamicFormsNotificationUsersDataSource extends ApiDataSource {

  order_by = 'contact_person';
  order = 'asc';

  form_id: number;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/dynamic-forms/notification-users/${this.form_id}`, resetOffset);
  }
}
