import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErpsEditComponent } from './erps-edit/erps-edit.component';
import { ErpsViewComponent } from './erps-view/erps-view.component';
import { ErpsComponent } from './erps/erps.component';
import { ErpTeamsEditComponent } from './erps-teams/erps-teams-edit/erp-teams-edit.component';
import { ErpTeamsViewComponent } from './erps-teams/erps-teams-view/erp-teams-view.component';
import { ErpTeamsComponent } from './erps-teams/erps-teams/erp-teams.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: 'erps',
    canActivate: [
      AuthGuard
    ],
    children: [
      { path: '', component: ErpsComponent },
      {
        path: ':erp_id',
        children: [
          { path: 'edit', component: ErpsEditComponent },
          { path: 'view', component: ErpsViewComponent },
          {
            path: 'teams',
            children: [
              { path: '', component: ErpTeamsComponent },
              {
                path: 'teams/:member_id/edit',
                component: ErpTeamsEditComponent
              },
              {
                path: 'teams/:member_id/view',
                component: ErpTeamsViewComponent
              }
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErpRoutingModule {}
