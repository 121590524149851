import { Component, Inject, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/shared/utils.service';
import { SystemNotification } from '../../../interfaces/system-notification.interface';
import { NgForm } from '@angular/forms';
import { ApiRequestService } from '../../../shared/api-request.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-system-notification-edit',
  templateUrl: './system-notification-edit.component.html',
  styleUrls: ['./system-notification-edit.component.scss']
})
export class SystemNotificationEditComponent implements OnInit {

  // All system notifications.
  system_notification: SystemNotification = {} as SystemNotification;

  constructor(
    private api: ApiRequestService,
    public utils: UtilsService,
    private dialogRef: MatDialogRef<SystemNotificationEditComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit(): void {
    // Get the system notification id.
    const system_notification_id: number = Number(this.dialogData['system_notification_id']);
    if ( !system_notification_id ) {
      // Close the dialog if the provided id is invalid.
      this.dialogRef.close();
      return;
    }
    // Get the system notification by id.
    this.getSystemNotificationById(system_notification_id);
  }

  /**
   * Retrieves the system notification by ID.
   *
   * @param {number} system_notification_id - The ID of the system notification.
   * @returns {undefined}
   */
  getSystemNotificationById(system_notification_id: number): void {
    // Make an API request to get the system notification if it exists.
    this.api.makeRequest('get', `v2/system-notifications/${system_notification_id}`)
      .then((response: any): void => {
        // Apply the system notification from the API.
        this.system_notification = response;
      })
      .catch((errorResponse: any): void => {
        // Handle API error responses.
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  /**
   * Validate the form input and save the data.
   * @param form The NgForm object that is used for validation.
   * @param autoCloseDialog An optional param to indicate if the dialog should be closed or not.
   * @returns Nothing.
   */
  onSubmit(form: NgForm, autoCloseDialog?: boolean): void {
    // Check if the submitted form is valid or not.
    if ( !form.valid ) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    // Make an update request if the record id is present.
    this.api.makeRequest('put', `v2/system-notifications/${this.system_notification.id}`, this.system_notification)
      .then((response: SystemNotification): void => {
        // Override the system notification.
        this.system_notification = response;
        this.utils.showToast(`The system notification was updated.`);
        // Check if the dialog should be close.
        if ( autoCloseDialog ) {
          this.onClose();
        }
      })
      .catch((errorResponse: any): void => {
        this.utils.handleAPIErrors(errorResponse);
      });

  }

  /**
   * Close the dialog.
   */
  onClose(): void {
    this.dialogRef.close();
  }

}
