import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TaWorkersService } from '../ta-workers.service';

@Component({
  selector: 'app-ta-workers-edit',
  templateUrl: './ta-workers-edit.component.html',
  styleUrls: ['./ta-workers-edit.component.css']
})
export class TaWorkersEditComponent implements OnInit {
  parent_id: number;
  id: number;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public service: TaWorkersService
  ) {}

  ngOnInit() {
    this.parent_id = Number(this.route.snapshot.params['ta_id']);
    this.id = Number(this.route.snapshot.params['worker_id']);

    if (this.id) {
      this.service.get(this.parent_id, this.id);
    }
  }

  onSubmit(form: NgForm) {
    // make sure the form data is valid
    if (form.valid) {
      // check if the site should be created or updated
      if (this.id) {
        // update the site
        this.service.update(this.parent_id);
      } else {
        // create the site
        this.service.add(this.parent_id, (response) => {
          // and navigate back to the list of sites
          this.router.navigate(['/task-analyses', this.parent_id, 'workers']);
        });
      }
    } else {
      alert('Please make sure you enter all fields with valid information.');
    }
  }
}
