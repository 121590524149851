import { Component, Inject } from '@angular/core';
import { AuditItemModel } from 'src/app/audit-items/audit-item.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-audits-view-items',
  templateUrl: './audits-view-items.component.html',
  styleUrls: ['./audits-view-items.component.scss']
})
export class AuditsViewItemsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public auditItem: AuditItemModel
  ) {}

}
