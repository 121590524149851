<div mat-dialog-title>
  <h5>View {{ !data.is_training_doc ? "Folder" : "Document" }}</h5>
  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div appMargin>
    <h6>Name</h6>
    <p>{{ data.folder.name }}</p>

    <h6 *ngIf="data.folder.expiry">Expiry</h6>
    <p *ngIf="data.folder.expiry"> {{ (data.folder.expiry * 1000) | timezoneConvertMedium }}</p>

    <h6 *ngIf="data.folder.visibility">Visibility</h6>
    <p>{{data.folder.visibility}}</p>

    <h6 *ngIf="data.folder.folder_type">Document Type</h6>
    <p>{{data.folder.folder_type}}</p>

    <h6 *ngIf="data.folder.folder_number">Document Number</h6>
    <p>{{data.folder.folder_number}}</p>

    <ng-container *ngIf="data.folder.files?.length">
      <h6>Files</h6>
      <app-files-list [files]="data.folder.files" [view]="true"></app-files-list>
    </ng-container>

    <ng-container *ngIf="data.folder.links?.length">
      <h6>Links</h6>
      <mat-list class="mb-2">
        <mat-list-item *ngFor="let link of data.folder.links; last as isLast">
          <div mat-line class="text-wrap"><a target="_blank" [href]="link.url">{{ link.url | ellipsis: [150] }}</a></div>
          <div mat-line class="text-wrap">Created: {{ (link.date_created * 1000) | timezoneConvertMedium }} </div>
          <div mat-line class="text-wrap">Last Modified: {{ (link.date_modified * 1000) | timezoneConvertMedium }} </div>
          <mat-divider class="w-100 m-0" [inset]="true" *ngIf="!isLast"></mat-divider>
        </mat-list-item>
      </mat-list>
    </ng-container>

    <ng-container *ngIf="data.folder.sites?.length">
      <h6>{{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}</h6>
      <mat-list>
        <mat-list-item *ngFor="let site of data.folder.sites">
          <mat-icon mat-list-icon>location_on</mat-icon>
          <h4 mat-line>
            {{site.name}}
          </h4>
          <p mat-line>{{site.location}}</p>
        </mat-list-item>
      </mat-list>
    </ng-container>
  </div>
</mat-dialog-content>
