<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button mat-raised-button routerLink="/items" id="back">
    <mat-icon>arrow_back</mat-icon>
    Back
  </button>
  <button mat-raised-button (click)="delete()" *ngIf="item?.id" color="danger" matTooltip="Delete Item" id="delete">
    <mat-icon>delete</mat-icon>
  </button>
</nav>

<div class="app-padding">
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Item</h5>
          <form #form="ngForm" (ngSubmit)="onSubmit()" class="card-body">
            <mat-form-field style="width: 100%">
              <input matInput placeholder="Name" [(ngModel)]="item.name" name="name" type="text" required #name
                id="name" />
              <mat-error *ngIf="!name.valid">Please enter an Item name.
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input required matInput placeholder="Question" [(ngModel)]="item.question" name="question" type="text"
                id="question" />
            </mat-form-field>

            <mat-form-field>
              <textarea required placeholder="Tooltip" matInput name="tooltip" id="tooltip" rows="5"
                [(ngModel)]="item.tooltip"></textarea>
            </mat-form-field>

            <mat-form-field>
              <input required matInput placeholder="Sort Order" [(ngModel)]="item.sort_order" name="sort_order" type="number" id="sort_order" />
              <mat-hint>The pre-qualification items are ordered by default using the sort order number in ascending order.</mat-hint>
            </mat-form-field>

            <div appMarginTop>
              <button mat-raised-button color="primary" id="save" [disabled]="!form.valid || inProgress" type="submit" >
                Save
              </button>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>
</div>