import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from '../../../../app.service';
import { ApiService } from '../../../../shared/api.service';
import { UtilsService } from '../../../../shared/utils.service';
import { CustomDataSource } from '../../../../utils/custom-data-source';
import { CoursesModulesQuestionsEditComponent } from '../courses-modules-questions-edit/courses-modules-questions-edit.component';
import { CourseModuleQuestionModel } from 'src/app/models/course-module-question.model';
import { CoursesModulesQuestionsViewComponent } from '../courses-modules-questions-view/courses-modules-questions-view.component';

@Component({
  selector: 'app-courses-modules-questions',
  templateUrl: './courses-modules-questions.component.html',
  styleUrls: ['./courses-modules-questions.component.scss']
})
export class CoursesModulesQuestionsComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'question',
    'status',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];
  dataSource = new QuestionsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    // get the site id
    this.dataSource.course_id = Number(this.route.snapshot.params['course_id']);
    this.dataSource.module_id = Number(this.route.snapshot.params['module_id']);

    if (this.dataSource.course_id && this.dataSource.module_id) {
      // get the list of codes
      // this.service.get(this.dataSource.course_id, this.dataSource.module_id, 0, true);
      this.dataSource.getData();
    } else {
      // redirect back to sites because the site id is not valid
      this.router.navigate([
        'courses',
        this.dataSource.course_id,
        'modules',
        this.dataSource.module_id,
        'questions'
      ]);
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns based on their corresponding date columns.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.openDialog(CoursesModulesQuestionsEditComponent, {
      question_data: new CourseModuleQuestionModel(),
      course_id: this.dataSource.course_id,
      module_id: this.dataSource.module_id
    });
  }

  onView(question: CourseModuleQuestionModel) {
    this.openDialog(CoursesModulesQuestionsViewComponent, {
      question_data: question,
      course_id: this.dataSource.course_id,
      module_id: this.dataSource.module_id
    });
  }

  onEdit(question: CourseModuleQuestionModel) {
    this.openDialog(CoursesModulesQuestionsEditComponent, {
      question_data: {...question},
      course_id: this.dataSource.course_id,
      module_id: this.dataSource.module_id
    });
  }

  openDialog(component: any, _data: any) {
    this.dialog
      .open(component, { width: '600px', minHeight: '500px', data: _data })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove Question',
      'Are you sure you want to remove this question?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          `inductions/${this.dataSource.course_id}/modules/${
            this.dataSource.module_id
          }/questions/${id}`,
          {},
          {},
          (response) => {
            this.utils.showToast('The question was removed');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          }
        );
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Remove Selected Question',
      'Are you sure you want to remove the selected questions?',
      () => {
        const selectedQuestions = this.dataSource.selection.selected.join(',');

        this.api.laravelApiRequest(
          'delete',
          `inductions/${this.dataSource.course_id}/modules/${
            this.dataSource.module_id
          }/questions/${selectedQuestions}`,
          {},
          {},
          (response) => {
            this.utils.showToast('The selected questions was removed');
            this.dataSource.selection.clear();
            this.dataSource.getData();
          }
        );
      }
    );
  }
}

export class QuestionsDataSource extends CustomDataSource {
  course_id = 0;
  module_id = 0;

  sort_by = 'question';
  sort_order = 'asc';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      `inductions/${this.course_id}/modules/${this.module_id}/questions`,
      resetOffset
    );
  }
}
