<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/erps">
      Emergency Response Plan Templates
    </a>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button
          mat-raised-button
          color="primary"
          routerLink="/erps/new/edit"
          appMarginRight>
          Add Template
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.deselectAll()">
            Deselect All
          </button>
          <!--button mat-menu-item (click)="onExportSelected('pdf')">Export to PDF</button>
          <button mat-menu-item (click)="onExportSelected('csv')">Export to CSV</button-->
          <button mat-menu-item (click)="onDeleteSelected()">Delete</button>
        </mat-menu>

        <button
          mat-raised-button
          color="secondary"
          [matMenuTriggerFor]="appMenu"
          *ngIf="dataSource.selection.hasValue()">
          List Actions
        </button>
      </span>

      <form
        class="form-inline my-2 my-lg-0"
        (ngSubmit)="dataSource.getData(true)">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search"/>

        <button
          mat-raised-button
          color="secondary"
          type="submit"
          class="mr-sm-2">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

      <!--button mat-raised-button (click)="export()" color="secondary"><mat-icon>import_export</mat-icon> Export</button-->
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="name"
      matSortDirection="asc"
      matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="65px">
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Emergency Response Plan
        </th>
        <td mat-cell *matCellDef="let element">
          <b>{{ element.name }}</b>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <a routerLink="/erps/{{ element.id }}/view"
            matTooltip="View the emergency response plan template."
            matTooltipPosition="above">
            View
          </a>
          |
          <a routerLink="/erps/{{ element.id }}/edit"
            matTooltip="Edit the emergency response plan template."
            matTooltipPosition="above">
            Edit
          </a>
          |
          <a href="javascript:void(0)"
            (click)="onRemove(element.id)"
            matTooltip="Remove the emergency response plan template."
            matTooltipPosition="above">
            Delete
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
