<ng-container matColumnDef="phone_input">
  <form #f="ngForm" [formGroup]="phoneForm">
    <div class="wrapper">
        <ngx-intl-tel-input  [cssClass]="inputClass"
                             [preferredCountries]="preferredCountries"
                             [enableAutoCountrySelect]="true"
                             [enablePlaceholder]="true"
                             [searchCountryFlag]="true"
                             [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                             [selectFirstCountry]="false"
                             [selectedCountryISO]="selectedCountry"
                             [maxLength]="mobileMaxLength"
                             [phoneValidation]="true"
                             [separateDialCode]="separateDialCode"
                             [inputId]="idInput"
                             (countryChange)="onChange($event)"
                             #phoneInput
                             name="phone"
                             formControlName="phone">
        </ngx-intl-tel-input>
    </div>
  </form>
</ng-container>
