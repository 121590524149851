import { InjectionToken } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';

// Used to enject the overlay reference into the quick actions component.
export const QUICK_ACTIONS_OVERLAY = new InjectionToken<OverlayRef>('QUICK_ACTIONS_OVERLAY');
// Used to enject data into the quick actions component.
export const QUICK_ACTIONS_STRUCT = new InjectionToken<{
    text: string,
    buttons: { 
        text: string, 
        handler: any 
    }[]
}>('QUICK_ACTIONS_STRUCT');
