<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="javascript:void(0)">Related Users</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button type="button" color="primary" mat-raised-button (click)="onManageUsers()" matTooltip="Click this button to sync users.">
          <mat-icon>account_circle</mat-icon>
          Manage Users
        </button>
      </span>

      <div class="search-container">
        <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
          <input
            class="form-control mr-sm-2"
            type="search"
            [(ngModel)]="dataSource.search"
            name="search"
            placeholder="Search"
            aria-label="Search" id="search-input"
            matTooltip="Search the users by name, email, mobile or job title." />
        </form>

        <button
          mat-raised-button
          color="secondary"
          (click)="dataSource.getData(true)"
          appMarginRight
          id="search-button"
          matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon> Search
        </button>
      </div>
    </div>
  </nav>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto"></span>
      <mat-select class="filter-dropdowns mr-3" [(ngModel)]="dataSource.trade" matTooltip="Filter users by job title." name="trade" (selectionChange)="dataSource.getData(true)">
        <mat-option value="">All Job Titles</mat-option>
        <mat-option *ngFor="let trade of trades" [value]="trade.name">{{ trade.name }}</mat-option>
      </mat-select>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

      <!-- Multiple item selector for lists. -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Toggle select all records.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let user">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(user.id) : null"
            [checked]="dataSource.selection.isSelected(user.id)"
            matTooltip="Toggle select this record.">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- The ID of the user. -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the id of the user.">
          ID #
        </th>
        <td mat-cell *matCellDef="let user">
          {{ user.id }}
        </td>
      </ng-container>

      <!-- Full Name -->
      <ng-container matColumnDef="contact_person">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the full name of the user.">
          Full Name
        </th>
        <td mat-cell *matCellDef="let user" class="text-wrap">
          <a (click)="onUserPublicView(user.hash)"
             matTooltip="View User Public Profile">
            {{ user.contact_person }}
          </a>
        </td>
      </ng-container>

      <!-- Email Address -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the email address of the user.">
          Email Address
        </th>
        <td mat-cell *matCellDef="let user">
          {{ user.email }}
        </td>
      </ng-container>

      <!-- Mobile Number -->
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the {{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }} of the user.">
          {{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}
        </th>
        <td mat-cell *matCellDef="let user">
          {{ user.mobile }}
        </td>
      </ng-container>

      <!-- Trade -->
      <ng-container matColumnDef="trade">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the job title of the user.">
          Job Title
        </th>
        <td mat-cell *matCellDef="let user">
          {{ user.trade }}
        </td>
      </ng-container>

      <!-- Date the user was enrolled. -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the user was enrolled.">
          Date Enrolled
        </th>
        <td mat-cell *matCellDef="let user">
          {{ (user.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- UTC Date the user was enrolled. -->
      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the user was enrolled.">
          Date Enrolled (UTC)
        </th>
        <td mat-cell *matCellDef="let user">
          {{ (user.date_created * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let contractor">
          <button type="button" mat-button (click)="onSendMessage(contractor.id)" matTooltip="Click this button to send a message to the user.">
            Send Message
          </button>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
