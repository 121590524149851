import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {TaskAnalysesRevisedWorkflowModel} from "../../models/task-analyses-revised-workflow.model";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {RISK_LEVEL_OF_CONTROLS} from "../../shared/risk-level-of-controls";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-sites-task-analyses-revised-workflows-view',
  templateUrl: './sites-task-analyses-revised-workflows-view.component.html',
  styleUrls: ['./sites-task-analyses-revised-workflows-view.component.scss']
})
export class SitesTaskAnalysesRevisedWorkflowsViewComponent implements OnInit {

  path: string;
  site_id: number;
  ta_revised_id: number;
  ta_revised_workflow: TaskAnalysesRevisedWorkflowModel = new TaskAnalysesRevisedWorkflowModel();

  loc_list: string[] = RISK_LEVEL_OF_CONTROLS;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    // get the task analysis workflow id
    this.ta_revised_workflow.id = this.dialogData.ta_revised_workflow.id;

    // get the task analysis id
    this.ta_revised_id = this.dialogData.ta_revised_id;

    // get the site id
    this.site_id = this.dialogData.site_id;

    // get sites path edit/view
    this.path = this.dialogData.path;

    if(this.ta_revised_workflow.id) {
      this.getWorkflow(this.ta_revised_workflow.id);
    }
  }

  private getWorkflow (ta_revised_workflow_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${ta_revised_workflow_id}`, [], {
      site_id: this.site_id
    })
      .then((response) => {
        this.ta_revised_workflow = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
