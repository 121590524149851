import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { ContractorService } from '../contractor.service';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-contractors-contractors',
  templateUrl: './contractors-contractors.component.html',
  styleUrls: ['./contractors-contractors.component.scss']
})
export class ContractorsContractorsComponent implements OnInit {

  listColumns: string[] = [
    'icons',
    'name',
    'contact_person',
    'industry',
    'date_created'
    // 'date_created_UTC'
  ];

  listDataSource = new ContractorContractorsDataSource(this.app, this.api);

  // Defining the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    public utils: UtilsService,
    private api: ApiRequestService,
    public contractorService: ContractorService
  ) {}

  ngOnInit() {
    if ( Number(this.route.parent.snapshot.params['contractor_id']) != this.contractorService.contractor.id ) {
      this.router.navigate(['contractors']);
    }
    this.listDataSource.contractor_id = this.contractorService.contractor.id;

    this.listDataSource.getData(true);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.listDataSource.limit = this.paginator.pageSize;
      this.listDataSource.offset = this.paginator.pageIndex;
      this.listDataSource.order_by = this.sort.active;
      this.listDataSource.order = this.sort.direction;

      // Sort UTC columns by their corresponding date column.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.listDataSource.order_by = 'date_created';
      }

      this.listDataSource.getData();
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.listDataSource.getData();
      }
    );
  }
}

/**
 * Get the list data for subcontractors.
 */
export class ContractorContractorsDataSource extends ApiDataSource {
  contractor_id = 0;
  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/contractors/${this.contractor_id}/contractors`, resetOffset);
  }
}
