import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UtilsService} from "../../../shared/utils.service";
import {ApiRequestService} from "../../../shared/api-request.service";
import {PpeModel} from "../ppe.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-ppe-edit',
  templateUrl: './ppe-edit.component.html',
  styleUrls: ['./ppe-edit.component.scss']
})
export class PpeEditComponent implements OnInit {

  @ViewChild('webIconInput') webIconInput: ElementRef;
  @ViewChild('mobileIconInput') mobileIconInput: ElementRef;

  inProgress = false;

  constructor(
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public ppe: PpeModel,
    private dialogRef: MatDialogRef<PpeEditComponent>,
    private api: ApiRequestService,
    public renderer: Renderer2
  ) { }

  ngOnInit() {
    if(this.ppe.id) {
      this.find(this.ppe.id);
    }
  }

  onSubmit() {
    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;
    !this.ppe.id ? this.create() : this.update();
  }

  private find(ppe_id: number) {
    this.api.makeRequest('get', `v2/admin/ppe/${ppe_id}`)
      .then((response) => {
        this.ppe = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onDelete() {
    this.utils.showModal(
      'Remove PPE',
      `Are you sure you want to remove "${this.ppe.title}"?`,
      () => {
        this.api.makeRequest('delete', `v2/admin/ppe/${this.ppe.id}`)
          .then((response) => {
            this.utils.showToast(`"${this.ppe.title}" has been deleted.`);
            this.dialogRef.close(true);
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  private update() {
    this.api
      .makeRequest('put', `v2/admin/ppe/${this.ppe.id}`, this.ppe)
      .then((response: PpeModel) => {
        this.ppe = response;
        this.onSuccess(`Updated: "${response.title}"`);
      })
      .finally(() => (this.inProgress = false));
  }

  private create() {
    this.api
      .makeRequest('post', 'v2/admin/ppe', this.ppe)
      .then((response: PpeModel) => {
        this.ppe = response;
        this.onSuccess(`New PPE "${response.title}" created.`);
      })
      .finally(() => this.inProgress = false);
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    this.dialogRef.close(this.ppe);
  }

  // Click an ion-button that in turn triggers the file input type to select a file.
  // When a file is selected, it is uploaded.
  onTriggerWebIconSelector(evt: Event) {
    if(this.webIconInput) {
      const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
      this.webIconInput.nativeElement.dispatchEvent(clickEvt);
    }
  }

  // When a file is selected, store it.
  onUploadSelectedWebIcon(evt: any) {
    // Make sure there are files to send.
    if ( evt.target.files.length == 0 ) {
      return; // Stop the rest of the code from executing.
    }
    // Upload the first selected file.
    this.api.makeUploadRequest(`v2/admin/ppe/${this.ppe.id}/web-icon`, evt.target.files[0])
      .then((response) => {
        this.utils.showToast('Your PPE Web Icon was updated.');
        if(this.ppe.id) {
          this.find(this.ppe.id);
        }
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      })
      .finally(() => {
        // Clear the selected files so that the user can reselect files.
        this.webIconInput.nativeElement.value = '';
      });
  }

  // When a file is selected, store it.
  onRemoveWebIcon() {
    this.api.makeRequest('delete', `v2/admin/ppe/${this.ppe.id}/web-icon`)
      .then((response) => {
        this.utils.showToast('Your PPE Web Icon was removed.');
        if(this.ppe.id) {
          this.find(this.ppe.id);
        }
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  // Click an ion-button that in turn triggers the file input type to select a file.
  // When a file is selected, it is uploaded.
  onTriggerMobileIconSelector(evt: Event) {
    if(this.mobileIconInput) {
      const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
      this.mobileIconInput.nativeElement.dispatchEvent(clickEvt);
    }
  }

  // When a file is selected, store it.
  onUploadSelectedMobileIcon(evt: any) {
    // Make sure there are files to send.
    if ( evt.target.files.length == 0 ) {
      return; // Stop the rest of the code from executing.
    }
    // Upload the first selected file.
    this.api.makeUploadRequest(`v2/admin/ppe/${this.ppe.id}/mobile-icon`, evt.target.files[0])
      .then((response) => {
        this.utils.showToast('Your PPE Mobile Icon was updated.');
        if(this.ppe.id) {
          this.find(this.ppe.id);
        }
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      })
      .finally(() => {
        // Clear the selected files so that the user can reselect files.
        this.mobileIconInput.nativeElement.value = '';
      });
  }

  // When a file is selected, store it.
  onRemoveMobileIcon() {
    this.api.makeRequest('delete', `v2/admin/ppe/${this.ppe.id}/mobile-icon`)
      .then((response) => {
        this.utils.showToast('Your PPE Mobile Icon was removed.');
        if(this.ppe.id) {
          this.find(this.ppe.id);
        }
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

}
