<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/task-analysis">
      Task Analysis Documents
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button
          mat-raised-button
          color="primary"
          (click)="onAdd()"
          appMarginRight>
          <mat-icon>add</mat-icon>
          New
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onDeleteSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Task Analysis Documents." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          Menu
        </button>
      </span>

      <span
        class="spinner-container"
        *ngIf="(dataSource.loading | async)"
        appMarginRight>
      </span>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onSelectSites()" matTooltip="Filter the list of Hazardous Substances by selected {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }}">
        <mat-icon>filter_list</mat-icon> Filter by {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
      </button>

      <form
        class="form-inline my-2 my-lg-0"
        (ngSubmit)="dataSource.getData(true)"
        style="min-width: 325px">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search"/>

        <button
          mat-raised-button
          color="secondary"
          type="submit"
          class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="task_name"
      matSortDirection="asc"
      matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="65px">
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Toggle selecting all Task Analysis Documents on the page.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)"
            matTooltip="Toggle selecting this Task Analysis Document.">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by Task Analysis ID.">
          #
        </th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <!-- Associated Site Count -->
      <ng-container matColumnDef="sites">
        <th mat-header-cell *matHeaderCellDef id="th-sites" matTooltip="The number of {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} that are assigned to this document.">
          {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
        </th>
        <td mat-cell *matCellDef="let document">
          <ng-container *ngIf="document.sites_count == 1 && document.sites.length == 1; else summarisedSites">
            <ng-container *ngIf="document.sites[0]?.parent_site">
              <small style="display: block;">
                <a [routerLink]="utils.prepareSiteRelatedLink(document?.sites[0]?.parent_site?.id, null, '/edit/details')">
                  {{ document?.sites[0]?.parent_site?.name || '' }}
                </a>
              </small>
            </ng-container>
            <mat-icon *ngIf="document.sites[0]?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
            <a [routerLink]="utils.prepareSiteRelatedLink(document?.sites[0]?.id, document?.sites[0]?.parent_site?.id, '/edit/details')">
              {{ document?.sites[0]?.name || '' }}
            </a>
          </ng-container>
          <ng-template #summarisedSites>
            <span [matTooltip]="formatSitesListColumnTooltip(document)">
              {{ formatSitesListColumnText(document) }}
            </span>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="task_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Task Analysis Document name.">
          Task
        </th>
        <td mat-cell *matCellDef="let element">{{ element.task_name }}</td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the Task Analysis was created on.">
          Date Created
        </th>
        <td mat-cell *matCellDef="let element">{{ (element.date_created * 1000) | timezoneConvertMedium }}</td>
      </ng-container>

      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the Task Analysis was created on.">
          Date Created (UTC)
        </th>
        <td mat-cell *matCellDef="let element">{{ (element.date_created * 1000) | timezoneConvertUTCMedium }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let element">
          <button type="button" mat-button (click)="onView(element.id)" matTooltip="View the Task Analysis Document." matTooltipPosition="above">
            View
          </button>

          <button type="button" mat-button (click)="onEdit(element.id)" matTooltip="Edit the Task Analysis Document." matTooltipPosition="above">
            Edit
          </button>

          <button type="button" mat-button (click)="onRemove(element.id)" matTooltip="Remove (Archive) the Task Analysis Document." matTooltipPosition="above">
            Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
