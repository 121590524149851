<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="javascript:void(0)">Related Messages</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button type="button" color="primary" mat-raised-button (click)="onEditMessage()" matTooltip="Click this button to send a new message.">
          <mat-icon>add</mat-icon>
          New Message
        </button>
      </span>

      <div class="search-container">
        <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
          <input
            class="form-control mr-sm-2"
            type="search"
            [(ngModel)]="dataSource.search"
            name="search"
            placeholder="Search"
            aria-label="Search" id="search-input"
            matTooltip="Search the messages by subject, body, type, status and priority." />
        </form>

        <button
          mat-raised-button
          color="secondary"
          (click)="dataSource.getData(true)"
          appMarginRight
          id="search-button"
          matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon> Search
        </button>
      </div>
    </div>
  </nav>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto"></span>

      <mat-select class="filter-dropdowns mr-3" [(ngModel)]="dataSource.type" matTooltip="Filter messages by type." name="type" (selectionChange)="dataSource.getData(true)">
        <mat-option value="">All Types</mat-option>
        <mat-option value="email">Email</mat-option>
        <mat-option value="push notification">Push Notification</mat-option>
        <mat-option value="sms">SMS</mat-option>
      </mat-select>

      <mat-select class="filter-dropdowns mr-3" [(ngModel)]="dataSource.status" matTooltip="Filter messages by status." name="status" (selectionChange)="dataSource.getData(true)">
        <mat-option value="">All Statuses</mat-option>
        <mat-option value="draft">Draft</mat-option>
        <mat-option value="queued">Queued</mat-option>
        <mat-option value="in progress">In Progress</mat-option>
        <mat-option value="sent">Sent</mat-option>
      </mat-select>

      <mat-select class="filter-dropdowns mr-3" [(ngModel)]="dataSource.priority" matTooltip="Filter messages by priority." name="priority" (selectionChange)="dataSource.getData(true)">
        <mat-option value="">All Priorities</mat-option>
        <mat-option value="low">Low</mat-option>
        <mat-option value="normal">Normal</mat-option>
        <mat-option value="high">High</mat-option>
        <mat-option value="critical">Critical</mat-option>
      </mat-select>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

      <!-- Multiple item selector for lists. -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Toggle select all files in this list.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)"
            matTooltip="Toggle select this file.">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- The ID of the message. -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the id of the message.">
          ID #
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.id }}
        </td>
      </ng-container>

      <!-- Subject -->
      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the subject of the message.">
          Subject
        </th>
        <td mat-cell *matCellDef="let message" class="text-wrap">
          {{ message.subject }}
        </td>
      </ng-container>

      <!-- Type -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the message type.">
          Type
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.type | titlecase }}
        </td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the status of the message.">
          Status
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.status | titlecase }}
        </td>
      </ng-container>

      <!-- Priority -->
      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the priority of the message.">
          Priority
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.priority | titlecase }}
        </td>
      </ng-container>

      <!-- Date the message was created. -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the message was created.">
          Created At
        </th>
        <td mat-cell *matCellDef="let message">
          {{ (message.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- UTC Date the message was created. -->
      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the message was created.">
          Created At (UTC)
        </th>
        <td mat-cell *matCellDef="let message">
          {{ (message.date_created * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let message">
          <button type="button" mat-button *ngIf="canEditMessage(message)" (click)="onEditMessage(message.id)" matTooltip="Click this button to edit the message.">
            Edit
          </button>
          <button type="button" mat-button *ngIf="message.status == 'sent'" (click)="onViewMessage(message.id)" matTooltip="Click this button to view the message.">
            View
          </button>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
