<div mat-dialog-title class="position-relative w-100">
  <h5>Master Charts</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close this dialog." [mat-dialog-close]="false" style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <mat-tab-group [selectedIndex]="selectedTabIndex">

    <mat-tab label="Account Inductions">
      <ng-template matTabContent>
        <app-inductions-charts-container [baseStorageKey]="'master-inductions'" [disableExpansionPanel]="true" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-inductions-charts-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Forms">
      <ng-template matTabContent>
        <app-dynamic-forms-charts-container [baseStorageKey]="'master-forms'" [disableExpansionPanel]="true" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-dynamic-forms-charts-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Hazards & Risks">
      <ng-template matTabContent>
        <app-hazards-charts-container [baseStorageKey]="'master-hazards'" [disableExpansionPanel]="true" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-hazards-charts-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Incidents">
      <ng-template matTabContent>
        <app-incidents-charts-container [baseStorageKey]="'master-incidents'" [disableExpansionPanel]="true" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-incidents-charts-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Inspections & Audits">
      <ng-template matTabContent>
        <app-site-audits-charts-container [baseStorageKey]="'master-site-audits'" [disableExpansionPanel]="true" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-site-audits-charts-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Messages">
      <ng-template matTabContent>
        <app-messages-charts-container [baseStorageKey]="'master-messages'" [disableExpansionPanel]="true" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-messages-charts-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Safety Observations">
      <ng-template matTabContent>
        <app-safety-observations-charts-container [baseStorageKey]="'master-safety-observations'" [disableExpansionPanel]="true" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-safety-observations-charts-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Access Activities">
      <ng-template matTabContent>
        <app-site-access-activities-charts-container [baseStorageKey]="'master-access-activities'" [disableExpansionPanel]="true" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-site-access-activities-charts-container>
      </ng-template>
    </mat-tab>

    <mat-tab [label]="utils.getLangTerm('parent-child-sites-combined.plural', 'Sites')">
      <ng-template matTabContent>
        <app-sites-charts-container [baseStorageKey]="'master-sites'" [disableExpansionPanel]="true" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-sites-charts-container>
      </ng-template>
    </mat-tab>

    <mat-tab label="Tasks">
      <ng-template matTabContent>
        <app-tasks-charts-container [baseStorageKey]="'master-tasks'" [disableExpansionPanel]="true" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-tasks-charts-container>
      </ng-template>
    </mat-tab>

    <mat-tab [label]="utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks')">
      <ng-template matTabContent>
        <app-toolbox-talks-charts-container [baseStorageKey]="'master-toolbox-talks'" [disableExpansionPanel]="true" [enable_realtime_data]="enable_realtime_data" [realtime_data_interval_seconds]="realtime_data_interval_seconds" [query_type]="query_type"></app-toolbox-talks-charts-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">

</mat-dialog-actions>
