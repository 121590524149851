import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { EmployeesDocsComponent } from './employees-docs/employees-docs.component';
import { EmployeesAddComponent } from './employees-add/employees-add.component';
import { EmployeesLinkedSitesComponent } from './employees-linked-sites/employees-linked-sites.component';
import { EmployeesNotificationsComponent } from './employees-notificaions/employees-notifications/employees-notifications.component';
import { EmployeesTabsComponent } from './employees-tabs/employees-tabs.component';
import { EmployeesComponent } from './employees/employees.component';
import { AuthGuard } from '../guards/auth.guard';
import {EmployeesImporterComponent} from "./employees-importer/employees-importer.component";
import {EmployeesDocsImporterComponent} from "./employees-docs-importer/employees-docs-importer.component";
import {EmployeesSitesVisitedComponent} from "./employees-sites-visited/employees-sites-visited.component";
import {EmployeesSitesWorkedOnComponent} from "./employees-sites-worked-on/employees-sites-worked-on.component";
import {EmployeesAssignedFormsComponent} from "./employees-assigned-forms/employees-assigned-forms.component";
import {
  EmployeesNotificationsTabsComponent
} from './employees-notificaions/employees-notifications-tabs/employees-notifications-tabs.component';

const routes: Routes = [
  {
    path: 'employees',
    component: EmployeesComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'employees/add',
    component: EmployeesAddComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'employees/importer',
    component: EmployeesImporterComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'employees/docs-importer',
    component: EmployeesDocsImporterComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'employees/:user_id',
    component: EmployeesTabsComponent,
    canActivate: [
      AuthGuard
    ],
    children: [
      {
        pathMatch: 'full', path: '', redirectTo: 'details'
      },
      { path: 'details', component: EmployeeDetailsComponent },
      { path: 'docs', component: EmployeesDocsComponent },
      { path: 'linked-sites', component: EmployeesLinkedSitesComponent },
      { path: 'notifications', component: EmployeesNotificationsTabsComponent },
      { path: 'assigned-forms', component: EmployeesAssignedFormsComponent },
      { path: 'sites-visited', component: EmployeesSitesVisitedComponent },
      { path: 'sites-worked-on', component: EmployeesSitesWorkedOnComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeesRoutingModule {}
