import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as CONST from '../app.const';
import { SessionService } from './session.service';
import { StorageService } from './storage.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class RemoteService {
  constructor(
    public http: HttpClient,
    public storage: StorageService,
    public utils: UtilsService,
    public sessionService: SessionService
  ) {}

  /**
   * send a request to the service API and return an observable.
   */
  serviceAPI(
    method: string,
    endpoint: string,
    payload?: any,
    headers?: any,
    callbacks?: any,
    includeJSONContentType: boolean = true
  ) {
    return new Promise((resolve, reject) => {
      // define the callbacks object if it doesn't exist
      if (!callbacks) {
        callbacks = {};
      }

      // create the headers object if it doesn't exist
      if (!headers) {
        headers = {};
      }

      const AuthToken = this.storage.get('AuthToken');

      if (AuthToken) {
        headers['SS-Token'] = AuthToken;

        // delare the request variable
        let request;

        switch (method) {
          case 'post':
          case 'POST':
            request = this.post(
              endpoint,
              payload,
              headers,
              includeJSONContentType
            );
            break;
          case 'put':
          case 'PUT':
            request = this.put(
              endpoint,
              payload,
              headers,
              includeJSONContentType
            );
            break;
          case 'delete':
          case 'DELETE':
            request = this.delete(endpoint, headers, includeJSONContentType);
            break;
          default:
            // default to get requests
            request = this.get(endpoint, headers, includeJSONContentType);
        }

        // always in the subscribe method does not fire when an error occurs
        request.subscribe(
          (data: any) => {
            if (data.status !== 'auth_failed') {
              // if the status is not ok, then show the error message
              if (data.status !== 'ok') {
                this.utils.showModal(data.status, data.message);
                resolve(false);
              } else {
              if (callbacks.always && typeof callbacks.always === 'function') {
                callbacks.success();
              }
                resolve(data);
              }

              if (callbacks.always && typeof callbacks.always === 'function') {
                callbacks.always();
              }
            } else {
              // rather log the
              // this.utils.showToast(data.message);

              // sign the user out
              this.sessionService.signout();

              resolve(false);
            }
          },
          (error) => {
            // alert the error instead
            this.utils.showModal(error.statusText, error.response);

            if (callbacks.always && typeof callbacks.always === 'function') {
              callbacks.always();
            }

            resolve(false);
          }
        );
      } else {
        // sign the user out
        // this.authService.signout();

        this.sessionService.signout(
          'You tried accessing a page that requires authentication.'
        );

        resolve(false);
      }
    });
  }

  getServiceURL(endpoint: string) {
    let url = '';
    // check if the endpoint starts with http/https or not
    if (!/^https?:\/\//i.test(endpoint)) {
      // check if it's a production release or not
      url = CONST.SERVICE_URL + endpoint;
    } else {
      // the endpoint contains http/s so use it as it is
      url = endpoint;
    }
    return url;
  }

  /**
   * make a get request and return an observable.
   */
  get(url: string, headers?: any, includeJSONContentType?: boolean) {
    return this.http.get(this.getServiceURL(url), {
      headers: this.getHeaders(headers, includeJSONContentType)
    });
  }

  /**
   * send a post request and return an observable.
   */
  post(
    url: string,
    payload: any,
    headers?: any,
    includeJSONContentType?: boolean
  ) {
    return this.http.post(this.getServiceURL(url), payload, {
      headers: this.getHeaders(headers, includeJSONContentType)
    });
  }

  /**
   * send a put request and return an observable.
   */
  put(
    url: string,
    payload: any,
    headers?: any,
    includeJSONContentType?: boolean
  ) {
    return this.http.put(this.getServiceURL(url), payload, {
      headers: this.getHeaders(headers, includeJSONContentType)
    });
  }

  /**
   * send a delete request and return an observable.
   */
  delete(url: string, headers?: any, includeJSONContentType?: boolean) {
    return this.http.delete(this.getServiceURL(url), {
      headers: this.getHeaders(headers, includeJSONContentType)
    });
  }

  /**
   * upload a single file to the API.
   * @param file a single file object
   * @param endpoint the api endpoint
   * @param headers
   * @param callbacks
   * @param callbacks
   */
  upload(endpoint: string, file: any, headers?: any, callbacks?: any) {
    // create the form data object
    const form = new FormData();
    // append the file
    form.append('file', file);
    // post the file to the API
    return this.serviceAPI('post', endpoint, form, headers, callbacks, false);
  }

  /**
   * getting the default header settings to send json data
   */
  private getHeaders(
    additionalHeaders?: any,
    includeJSONContentType?: boolean
  ) {
    const headers = {};

    includeJSONContentType =
      includeJSONContentType === null || includeJSONContentType ? true : false;

    if (includeJSONContentType) {
      headers['Content-Type'] = 'application/json; charset=utf-8';
    }

    // add additional headers to the headers object
    if (additionalHeaders) {
      Object.keys(additionalHeaders).forEach((key) => {
        headers[key] = additionalHeaders[key];
      });
    }
    return new HttpHeaders(headers);
  }
}
