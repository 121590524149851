<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">Incidents</span>
    <button class="navbar-toggler" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="primary" id="add"
        (click)="onAddIncident()" appMarginRight>
        <mat-icon>add</mat-icon>
          Add
        </button>

         <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
<!--          <button mat-menu-item (click)="onExportIncidents(dataSource.selection.hasValue())">-->
<!--            <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV-->
<!--          </button>-->
          <button mat-menu-item (click)="dataSource.onExport('csv')">
            <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
          </button>
          <button mat-menu-item (click)="dataSource.onExport('xlsx')">
            <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to XLSX
          </button>
          <button mat-menu-item (click)="onDeleteSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect and/or export Incidents." style="min-width: 150px">
          <mat-icon>more_vert</mat-icon>
          Menu
        </button>
      </span>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="chartsUtils.onOpenMasterCharts('Incidents')" matTooltip="Click this button to open the charts.">
        <mat-icon>bar_chart</mat-icon> Charts
      </button>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" [ngClass]="{ 'pulsating-filter-button': !dataSource.isFiltersEmptyExcludingTags(['tag_ids']) }" (click)="onFilter()" matTooltip="Click this button to display filter options.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px"
        (ngSubmit)="dataSource.getData(true)">
        <input class="form-control mr-sm-2" type="search"
          [(ngModel)]="dataSource.search" name="search" placeholder="Search"
          aria-label="Search" id="search-input"
          matTooltip="Search for Incidents by name"/>
        <button mat-raised-button
                color="secondary"
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                (click)="dataSource.getData()"
                id="search-button"
                matTooltip="Run the current search">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

    </div>
  </nav>

  <app-tags-filter (tagsFilterOutput)="getOutputTagsFilter($event)"></app-tags-filter>

  <app-incidents-charts-container [baseStorageKey]="'incidents'"></app-incidents-charts-container>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort
      matSortActive="date_incident" matSortDirection="desc" matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="
              dataSource.selection.hasValue() && dataSource.isAllSelected()
            " [indeterminate]="
              dataSource.selection.hasValue() && !dataSource.isAllSelected()
            " matTooltip="Toggle selecting all Incidents">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)"
            matTooltip="Toggle selecting this Incident">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          #
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.id }}
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element?.site?.parent_site">
            <small style="display: block;">
              <a [routerLink]="utils.prepareSiteRelatedLink(element?.site?.parent_site?.id, null, '/edit/details')">
                {{ element?.site?.parent_site?.name || '' }}
              </a>
            </small>
          </ng-container>
          <mat-icon *ngIf="element?.site?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
          <a [routerLink]="utils.prepareSiteRelatedLink(element?.site?.id, element?.site?.parent_site?.id, '/edit/details')">
            {{ element?.site?.name || '' }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Incident">
          Incident & Near Miss
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.details | ellipsis: [50] }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by type">
          Type
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.type }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by status">
          Status
        </th>
        <td mat-cell *matCellDef="let element">
          <span [ngClass]="{
            'text-danger': element.status == 'Reported',
            'blink':  element.status == 'Reported',
            'text-warning':  element.status != 'Reported' && element.status != 'Completed',
            'text-success':  element.status == 'Completed'
          }">
            {{ element.status }}
          </span>
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="date_incident">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by date">
          Date of Incident
        </th>
        <td mat-cell *matCellDef="let element">
          {{ (element.date_incident * 1000) | timezoneConvertLong}} <br>
          {{ utils.convertDate(element.date_incident) | timezoneConvertUTCLong}}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_incident_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by date">
          Date of Incident (UTC)
        </th>
        <td mat-cell *matCellDef="let element">
          {{ utils.convertDate(element.date_incident) | timezoneConvertUTCLong}}
        </td>
      </ng-container>

      <ng-container matColumnDef="estimated_lti_days">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by estimated LTI days.">
          Estimated LTI Days
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.estimated_lti_days }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actual_lti_days">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by actual LTI days.">
          Actual LTI in Days
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.actual_lti_days }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actual_lti_hours">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by actual LTI hours.">
          Actual LTI in Hours
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.actual_lti_hours }}
        </td>
      </ng-container>

      <ng-container matColumnDef="created_by_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
         Reported By
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element?.created_by_user else showSystemCreator">
            <a (click)="onUserPublicView(element.created_by_user.hash)"
               matTooltip="View User Public Profile">
              {{ element.created_by_user.contact_person }}
            </a>
          </span>
          <ng-template #showSystemCreator>System</ng-template>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let element">
          <button type="button" mat-button (click)="downloadPdf(element.id)" matTooltip="Download a report of this Incident as PDF." matTooltipPosition="above">
            Download PDF
          </button>

          <a type="button" mat-button (click)="onManageIncident(element.id)" matTooltip="Edit the incident and near miss information." matTooltipPosition="above">
            Manage
          </a>

          <button type="button" mat-button (click)="onRemove(element.id)" matTooltip="Remove (Archive) the incident and near miss." matTooltipPosition="above">
            Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
