<div mat-dialog-title>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5>Tag</h5>
      </div>
      <div class="col">
        <button mat-icon-button class="float-right" mat-dialog-close>
          <mat-icon color="secondary">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<p>{{ tag.title }}</p>

<h6>Description</h6>
<p>{{ tag.description }}</p>

<ng-container *ngIf="tag.created_by_user">
  <h6>Created By</h6>
  <p>
    <a (click)="onUserPublicView(tag.created_by_user.hash)"
       matTooltip="View User Public Profile">
      {{ tag.created_by_user.contact_person }}
    </a>
  </p>
</ng-container>

<ng-container *ngIf="tag.date_created">
  <h6>Created At</h6>
  <p>{{ (tag.date_created * 1000) | timezoneConvertLong }}</p>
</ng-container>

<ng-container *ngIf="tag.updated_by_user">
  <h6>Last Updated By</h6>
  <p>
    <a (click)="onUserPublicView(tag.updated_by_user.hash)"
       matTooltip="View User Public Profile">
      {{ tag.updated_by_user.contact_person }}
    </a>
  </p>
</ng-container>

<ng-container *ngIf="tag.date_modified">
  <h6>Last Updated At</h6>
  <p>{{ (tag.date_modified * 1000) | timezoneConvertLong }}</p>
</ng-container>
