import {UserModel} from "../../models/user.model";
import {Auditable} from "../../shared/auditable.model";

export class IndustriesModel extends Auditable{
  id: number;
  name: string;

  /**
   * Who created this Industry?
   */
  created_by_user: UserModel;

  /**
   * Who updated this Industry?
   */
  updated_by_user: UserModel;
}
