import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TaStepModel } from '../../models/ta-step.model';
import { RemoteService } from '../../shared/remote.service';
import { UtilsService } from '../../shared/utils.service';

@Injectable({
  providedIn: 'root'
})
export class TaStepsService {
  list: TaStepModel[] = []; // the list of items
  item: TaStepModel = new TaStepModel(); // the single accessed item

  search = ''; // the search string

  limit = 15; // limiting the records to be returned
  offset = 0; // the offset after records are retrieved

  constructor(
    public remote: RemoteService,
    public utils: UtilsService,
    public router: Router
  ) {}

  /**
   *
   * @param parent_id
   * @param id the id of the item that should be fetched. 0 by default.
   * @param refresh should the list be refreshed. false by default.
   * @param callback
   *
   * get an item or list of items
   */
  get(
    parent_id: number,
    id: number = 0,
    refresh: boolean = false,
    callback?: any
  ) {
    // always reset the default item when a new request is made
    this.item.reset();

    // should the list and offset be refreshed?
    if (refresh) {
      this.list.length = 0;
      this.offset = 0;
    }

    // make the get request to retrieve the data
    this.remote.serviceAPI(
      'GET',
      'task-analysis-steps/' +
        parent_id +
        (id ? '/' + id : '') +
        '?limit=' +
        this.limit +
        '&offset=' +
        this.offset +
        '&search=' +
        this.search,
      {},
      {},
      {
        success: (response) => {
          if (response.status === 'ok') {
            if (id) {
              // check if an item is returned
              if (response.data.length) {
                // if so, apply the data changes
                this.item.apply(response.data[0]);
              } else {
                // if not, redirect the user back to the list
                this.router.navigate(['/task-analyses', parent_id, 'steps']);
              }
            } else {
              response.data.forEach((item, index) => {
                this.list.push(new TaStepModel().apply(item));
                this.offset++;
              });
            }

            if (typeof callback === 'function') {
              callback(response);
            }
          }
        }
      }
    );
  }

  /**
   *
   * @param parent_id
   * @param callback a callback method that is executed upon success
   *
   * adds a new record
   */
  add(parent_id: number, callback?: any) {
    this.remote.serviceAPI(
      'post',
      'task-analysis-steps/' + parent_id + '/',
      this.item,
      {},
      {
        success: (response) => {
          if (response.status === 'ok') {
            this.utils.showToast('New step added.');

            if (typeof callback === 'function') {
              callback(response);
            }
          }
        }
      }
    );
  }

  /**
   *
   * @param parent_id
   * @param callback a callback that is executed upon success.
   *
   * updates a existing record
   */
  update(parent_id: number, callback?: any) {
    this.remote.serviceAPI(
      'put',
      'task-analysis-steps/' + parent_id + '/' + this.item.id,
      this.item,
      {},
      {
        success: (response) => {
          if (response.status === 'ok') {
            this.utils.showToast('The step was updated.');

            if (typeof callback === 'function') {
              callback(response);
            }
          }
        }
      }
    );
  }

  /**
   *
   * @param parent_id
   * @param id id of item that is to be removed
   * @param callback a callback that is executed upon success.
   *
   * remove an item from the service
   */
  remove(parent_id: number, id: number, callback?: any) {
    // prompt the user before proceeding
    this.utils.showModal(
      'Remove Step',
      'Are you sure you want to remove this step?',
      () => {
        // send the delete request
        this.remote.serviceAPI(
          'delete',
          'task-analysis-steps/' + parent_id + '/' + id,
          {},
          {},
          {
            success: (response) => {
              if (response.status === 'ok') {
                // remove the item from the list
                this.list.forEach((item, index) => {
                  if (item.id === id) {
                    this.list.splice(index, 1);
                    this.offset--;
                  }
                });

                this.utils.showToast('The step was deleted.');

                if (typeof callback === 'function') {
                  callback(response);
                }
              }
            }
          }
        );
      }
    );
  }
}
