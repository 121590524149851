<mat-sidenav-container *ngIf="contractorService.contractor; else showLoader">
  <mat-sidenav position="end" [opened]="true" [disableClose]="true" mode="side">

    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ contractorService.contractor.name }}</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <p> <b>Industry:</b> {{ contractorService.contractor.industry }} </p>
        <p> <b>Main Contact:</b> {{ contractorService.contractor.contact_person }} </p>
        <p> <b>Email:</b> {{ contractorService.contractor.email }} </p>
        <p> <b>{{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}:</b> {{ contractorService.contractor.mobile }} </p>
        <p>
          <b>Date Enrolled:</b> {{ (contractorService.contractor.date_created * 1000) | timezoneConvertMedium }} <br>
          <b>Date Enrolled (UTC):</b> {{ (contractorService.contractor.date_created * 1000) | timezoneConvertUTCMedium }} <br>
        </p>
        <p>
          <b>Last Updated:</b> {{ (contractorService.contractor.date_modified * 1000) | timezoneConvertMedium }} <br>
          <b>Last Updated (UTC):</b> {{ (contractorService.contractor.date_modified * 1000) | timezoneConvertUTCMedium }} <br>
        </p>
      </mat-card-content>

      <mat-card-actions>
        <button mat-button (click)="composeNewMessage(contractorService.contractor.id)" matTooltip="Click this button to send a message to this contractor.">
          <mat-icon>send</mat-icon>
          Send Message
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-list>
      <mat-list-item routerLink="details" routerLinkActive="active-submenu-item"> Details </mat-list-item>
      <mat-list-item routerLink="sites" routerLinkActive="active-submenu-item" *ngIf="app.userCan('manage_sites')"> {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }} (Sub-Safety Plans) </mat-list-item>
      <mat-list-item routerLink="docs" routerLinkActive="active-submenu-item"> Company Documents </mat-list-item>
      <mat-list-item routerLink="prequalifications" routerLinkActive="active-submenu-item" *ngIf="app.userCan('manage_prequalifications')"> Pre-Qualification </mat-list-item>
      <mat-list-item routerLink="contractors" routerLinkActive="active-submenu-item"> {{ utils.getLangTerm('sub-contractors.plural', 'Subcontractors') }} </mat-list-item>
      <mat-list-item routerLink="employees" routerLinkActive="active-submenu-item"> {{ utils.getLangTerm('employees.plural', 'Employees') }} </mat-list-item>
      <mat-list-item routerLink="related-accounts" routerLinkActive="active-submenu-item" *ngIf="app.account.isMasterAccount() && app.userCan('manage_slave_accounts')"> Related Accounts </mat-list-item>
    </mat-list>
  </mat-sidenav>

  <section>
    <router-outlet></router-outlet>
  </section>
</mat-sidenav-container>

<ng-template #showLoader>
  <div class="d-flex justify-content-center align-items-center full-height">
    <table>
      <tr>
        <td class="d-flex justify-content-center">
          <mat-progress-spinner [mode]="'indeterminate'" diameter="32"></mat-progress-spinner>
        </td>
      </tr>
      <tr>
        <td class="d-flex justify-content-center">
          <p>Please wait while we get the information...</p>
        </td>
      </tr>
    </table>
  </div>
</ng-template>
