import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { ApiResponseModel } from '../models/api-response.model';
import { Observable } from 'rxjs';
import { IncidentModel } from '../models/incident.model';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class IncidentService {
  private readonly resourceUrl = 'incidents';

  constructor(private api: ApiService) {}

  /**
   * Exports the Incident to PDF.
   * @returns The URL to the export.
   */
  export(id: number): Observable<ApiResponseModel<{ url: string }>> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl + `/${id}/export`
    );
  }

  find(
    id: number,
    eagerLoad = false
  ): Observable<ApiResponseModel<IncidentModel>> {
    if (eagerLoad) {
      return this.api.laravelApiObservable(
        'get',
        this.resourceUrl + `/${id}`,
        {},
        {},
        { eager_load: true }
      );
    }
    return this.api.laravelApiObservable('get', this.resourceUrl + `/${id}`);
  }

  reviewedInvestigation(id: number): Observable<{}> {
    return this.api.laravelApiObservable(
      'put',
      this.resourceUrl + `/${id}/reviewed-investigation`
    );
  }

  submitInvestigation(id: number): Observable<{}> {
    return this.api.laravelApiObservable(
      'put',
      this.resourceUrl + `/${id}/submit-investigation`
    );
  }

  update(incident: IncidentModel): Observable<{}> {
    return this.api.laravelApiObservable(
      'put',
      this.resourceUrl + `/${incident.id}`,
      incident
    );
  }

  findAssignee(incidentId: number): Observable<ApiResponseModel<UserModel>> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl + `/${incidentId}/assignee`
    );
  }

  setAssignee(
    incidentId: number,
    assigneeId: number
  ): Observable<ApiResponseModel<UserModel>> {
    return this.api.laravelApiObservable(
      'put',
      this.resourceUrl + `/${incidentId}/assignee`,
      { assignee_id: assigneeId }
    );
  }

  getReviewer(incidentId: number): Observable<ApiResponseModel<UserModel>> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl + `/${incidentId}/reviewer`
    );
  }

  updateReviewer(
    incidentId: number,
    reviewerId: number
  ): Observable<ApiResponseModel<UserModel>> {
    return this.api.laravelApiObservable(
      'put',
      this.resourceUrl + `/${incidentId}/reviewer`,
      { reviewer_id: reviewerId }
    );
  }

  getWitnesses(id: number): Observable<ApiResponseModel<UserModel[]>> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl + `/${id}/witnesses`
    );
  }

  addWitness(
    id: number,
    witnessId: number,
    _statement: string
  ): Observable<{}> {
    return this.api.laravelApiObservable(
      'put',
      this.resourceUrl + `/${id}/witnesses/${witnessId}`,
      {statement: _statement}
    );
  }

  removeWitnesses(id: number, witnessIds: number[]): Observable<{}> {
    return this.api.laravelApiObservable(
      'delete',
      this.resourceUrl + `/${id}/witnesses/${witnessIds.join(',')}`
    );
  }
}
