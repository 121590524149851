import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiRequestService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  private prepareRequestURL(endpoint: string): string {
    let url = '';
    // Check if the endpoint starts with http/https
    if (!/^https?:\/\//i.test(endpoint)) {
      // Get the api url and append the endpoint
      url = environment.API_URL + endpoint;
    } else {
      // The endpoint contains http/s. Do not make changes.
      url = endpoint;
    }
    return url;
  }

  makeRequestObservable(
    method: string,
    endpoint: string,
    payload: any = {},
    params = {},
    headers = {}
  ): Observable<any> {

    // Add the authorization header if it is not present.
    if ( typeof headers['Authorization'] == 'undefined' ) {
      headers['Authorization'] = 'Bearer ' + this.storage.get('AuthToken');
    }

    let request: any;

    const options = {
      headers: new HttpHeaders(headers),
      params: params
    };

    const apiEndpoint = this.prepareRequestURL(endpoint);

    switch (method) {
      case 'post':
      case 'POST':
        request = this.http.post(apiEndpoint, payload, options);
        break;
      case 'put':
      case 'PUT':
        request = this.http.put(apiEndpoint, payload, options);
        break;
      case 'delete':
      case 'DELETE':
        request = this.http.delete(apiEndpoint, options);
        break;
      default:
        request = this.http.get(apiEndpoint, options);
    }

    return request;
  }

  makeRequest(
    method: string,
    endpoint: string,
    payload: any = {},
    params = {},
    headers = {}
  ): Promise<any> {
    return this.makeRequestObservable(method, endpoint, payload, params, headers).toPromise();
  }

  makeUploadRequest(
    endpoint: string,
    files: File | File[],
    params = {},
    headers = {}
  ) {
    const form = new FormData();
    if ( Array.isArray(files) ) {
      files.forEach((file) => {
        form.append('files[]', file, file.name);
      });
    } else {
      form.append('file', files, files.name);
    }
    return this.makeRequest('post', endpoint, form, params, headers);
  }

  // Get file blobs from an API request.
  makeDownloadRequest(
    endpoint: string,
    headers = {},
    params = {}
  ): Promise<any> {

    if ( typeof headers['Authorization'] == 'undefined' ) {
      headers['Authorization'] = 'Bearer ' + this.storage.get('AuthToken');
    }

    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';

    const apiEndpoint = this.prepareRequestURL(endpoint);

    return this.http.get(apiEndpoint, {
      headers: new HttpHeaders(headers),
      responseType: 'blob', // Response must be blob
      params: params
    }).toPromise();
  }
}
