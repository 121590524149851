
<form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div appMargin>
          <div>
            <mat-slide-toggle [(ngModel)]="app.account.site_arrival_notifications_enabled" name="site_arrival_notifications_enabled">
              <span class="text-wrap">
                Notify {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} managers as users arrive onsite.
              </span>
            </mat-slide-toggle>
          </div>

          <div>
            <mat-slide-toggle [(ngModel)]="app.account.pandemic_enabled" name="pandemic_enabled">
              <span class="text-wrap">
                The Covid-19 feature is <b>{{ app.account.pandemic_enabled ? 'enabled' : 'disabled' }}</b>.
              </span>
            </mat-slide-toggle>
          </div>

          <div>
            <mat-slide-toggle [(ngModel)]="app.account.pandemic_auto_gps_sign_outs_enabled" name="pandemic_auto_gps_sign_outs_enabled" [disabled]="!app.account.pandemic_enabled">
              <span class="text-wrap">
                Automatic GPS sign-outs are <b>{{ app.account.pandemic_auto_gps_sign_outs_enabled ? 'enabled' : 'disabled' }}</b> for the Covid-19 feature.
              </span>
            </mat-slide-toggle>
            <mat-hint>
              <small>
                <span *ngIf="app.account.pandemic_auto_gps_sign_outs_enabled; else AutoGPSSignOutsDisabled">
                  GPS users will automatically be signed out of {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} when the Covid-19 feature is enabled.
                </span>
                <ng-template #AutoGPSSignOutsDisabled>
                  GPS users will receive push and/or sms notifications to prompt them to sign out manually to complete the sign-out declaration when the Covid-19 feature is enabled (deprecated).
                </ng-template>
              </small>
            </mat-hint>
          </div>

          <div class="alert alert-info" role="alert" appMarginTop>
            By enabling the Covid-19 feature, all users visiting to any of your {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} will need to complete a compulsory sign in and sign out declaration (deprecated). <br>
            It will be mandatory for them to complete the declarations providing all information as directed in the {{ utils.getAppName() }} mobile app.
          </div>

          <div class="d-flex justify-content-end" appMarginTop>
            <button type="submit" mat-raised-button [disabled]="inProgress || !f.valid" color="primary" class="margin-right" id="save">
              <mat-icon>save</mat-icon>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div appMargin>
          <h4>Sign In Declaration</h4>
          <editor
          placeholder="Sign In Declaration"
          [(ngModel)]="app.account.pandemic_sign_in_declaration"
          name="pandemic_sign_in_declaration"
          id="pandemic_sign_in_declaration"
          [init]="{
            skin_url: '/assets/skins/lightgray',
            plugins: 'link,image,lists,hr,table,media',
            height: '300'
          }"></editor>
        </div>
      </div>

      <div class="col">
        <div appMargin>
          <h4>Sign Out Declaration</h4>
          <editor
          placeholder="Sign Out Declaration"
          [(ngModel)]="app.account.pandemic_sign_out_declaration"
          name="pandemic_sign_out_declaration"
          id="pandemic_sign_out_declaration"
          [init]="{
            skin_url: '/assets/skins/lightgray',
            plugins: 'link,image,lists,hr,table,media',
            height: '300'
          }"></editor>
          <mat-hint>
            <small>The sign-out declaration is deprecated and will be removed in future versions of the web portal.</small>
          </mat-hint>
        </div>
      </div>
    </div>
  </div>
</form>


