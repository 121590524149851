<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class=" mr-auto">
    <button mat-raised-button routerLink="/sites" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>

    <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
    </button>

    <button mat-raised-button [routerLink]="utils.prepareSiteRelatedLink(site_id, child_id ? parent_id : null, 'view/emergency-evacuation-plans')" *ngIf="site.id" appMarginRight>
      <mat-icon>visibility</mat-icon> View
    </button>

    <button mat-raised-button (click)="onExportEmergencyEvacuationPlan()">
      <mat-icon>cloud_download</mat-icon> Export
    </button>
  </span>
</nav>

<mat-tab-group>
  <mat-tab label="Emergency Evacuation Plan">

    <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit()">
      <div class="card">
        <div class="card-body">

          <div class="container-fluid">
            <div class="row">
              <div class="col">

                <h5>Emergency Numbers & Locations</h5>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>Emergency Number (Including Country Code)</mat-label>
                  <app-phone-input
                    [idInput]="phone_input_id['emergency']"
                    [numberInput]="site.emergency_phone"
                    [countryCodeInput]="site.emergency_phone_country_code"
                    [dialCodeInput]="site.emergency_phone_dial_code"
                    [e164NumberInput]="site.emergency_phone_e164"
                    (phoneDetailsOutput)="getOutputPhoneDetails($event, 'emergency')"
                    required="false">
                  </app-phone-input>
                  <mat-hint>Enter the emergency number e.g. 111, 911, 999, 10111 depending on the country you are in.</mat-hint>
                </mat-form-field>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>Hospital Address</mat-label>
                  <input matInput placeholder="Hospital Address"
                         [(ngModel)]="site.hospital_address"
                         name="hospital_address" type="text" id="hospital_address"
                         matTooltip="E.g. 124 Shakespeare Rd, Takapuna, Auckland 0620."/>
                  <mat-hint>Enter the address of the nearest hospital.</mat-hint>
                </mat-form-field>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>Hospital Phone (Including Country Code)</mat-label>
                  <app-phone-input
                    [idInput]="phone_input_id['hospital']"
                    [numberInput]="site.hospital_phone"
                    [countryCodeInput]="site.hospital_phone_country_code"
                    [dialCodeInput]="site.hospital_phone_dial_code"
                    [e164NumberInput]="site.hospital_phone_e164"
                    (phoneDetailsOutput)="getOutputPhoneDetails($event, 'hospital')"
                    required="false">
                  </app-phone-input>
                  <mat-hint>Enter the phone number of the nearest hospital.</mat-hint>
                </mat-form-field>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>Medical Centre Address</mat-label>
                  <input matInput placeholder="Medical Centre Address"
                         [(ngModel)]="site.medical_centre_address"
                         name="medical-centre-address" type="text" id="medical-centre-address"
                         matTooltip="E.g. 124 Shakespeare Rd, Takapuna, Auckland 0620."/>
                  <mat-hint>Enter the address of the nearest medical centre.</mat-hint>
                </mat-form-field>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>Medical Centre Phone (Including Country Code)</mat-label>
                  <app-phone-input
                    [idInput]="phone_input_id['medical_centre']"
                    [numberInput]="site.medical_centre_phone"
                    [countryCodeInput]="site.medical_centre_phone_country_code"
                    [dialCodeInput]="site.medical_centre_phone_dial_code"
                    [e164NumberInput]="site.medical_centre_phone_e164"
                    (phoneDetailsOutput)="getOutputPhoneDetails($event, 'medical_centre')"
                    required="false">
                  </app-phone-input>
                  <mat-hint>Enter the phone number of the nearest medical centre.</mat-hint>
                </mat-form-field>

                <h5>First Aid Kit, AED & Fire Extinguisher</h5>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>First Aid Kit Location</mat-label>
                  <input matInput placeholder="First Aid Kit Location"
                         [(ngModel)]="site.first_aid_kit_location"
                         name="first-aid-kit-location" type="text" id="first-aid-kit-location"
                         matTooltip="E.g. At the receptions desk."/>
                  <mat-hint>Enter the location of the first aid kits.</mat-hint>
                </mat-form-field>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>AED (Automated External Defibrillator) Location</mat-label>
                  <input matInput placeholder="AED (Automated External Defibrillator) Location"
                         [(ngModel)]="site.aed_location"
                         name="aed_location" type="text" id="aed_location"
                         matTooltip="E.g. At the receptions desk."/>
                  <mat-hint>Enter the location of the nearest AED.</mat-hint>
                </mat-form-field>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>Fire Extinguisher Location</mat-label>
                  <input matInput placeholder="Fire Extinguisher Location"
                         [(ngModel)]="site.fire_extinguisher_location"
                         name="fire-extinguisher-location" type="text" id="fire-extinguisher-location"
                         matTooltip="E.g. Next to the elevators on each floor"/>
                  <mat-hint>Enter the location of the fire extinguisher.</mat-hint>
                </mat-form-field>

              </div>

              <div class="col">

                <h5>Evacuation Alerts & Assembly Locations</h5>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>Description of Alarm</mat-label>
                  <input matInput placeholder="Description of Alarm"
                         [(ngModel)]="site.alarm_description" name="alarm_description"
                         type="text" id="alarm_description"
                         matTooltip="E.g. A constant alarm sounding throughout the building."/>
                  <mat-hint>Enter a clear description of the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} alarm.</mat-hint>
                </mat-form-field>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>Assembly Location</mat-label>
                  <input matInput placeholder="Assembly Location"
                         [(ngModel)]="site.assembly_location" name="assembly-location"
                         type="text" id="assembly-location"
                         matTooltip="E.g. Parking lot at the corner of Como St and Auburn St."/>
                  <mat-hint>Enter the location where people should assemble during an evacuation.</mat-hint>
                </mat-form-field>

                <h5>Additional Contact Numbers</h5>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>Worksafe Phone (Including Country Code)</mat-label>
                  <app-phone-input
                    [idInput]="phone_input_id['worksafe']"
                    [numberInput]="site.worksafe_phone"
                    [countryCodeInput]="site.worksafe_phone_country_code"
                    [dialCodeInput]="site.worksafe_phone_dial_code"
                    [e164NumberInput]="site.worksafe_phone_e164"
                    (phoneDetailsOutput)="getOutputPhoneDetails($event, 'worksafe')"
                    required="false">
                  </app-phone-input>
                  <mat-hint>Enter the phone number for WorkSafe.</mat-hint>
                </mat-form-field>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>Civil Defence Phone (Including Country Code)</mat-label>
                  <app-phone-input
                    [idInput]="phone_input_id['civil_defence']"
                    [numberInput]="site.civil_defence_phone"
                    [countryCodeInput]="site.civil_defence_phone_country_code"
                    [dialCodeInput]="site.civil_defence_phone_dial_code"
                    [e164NumberInput]="site.civil_defence_phone_e164"
                    (phoneDetailsOutput)="getOutputPhoneDetails($event, 'civil_defence')"
                    required="false">
                  </app-phone-input>
                  <mat-hint>Enter the phone number for Civil Defence.</mat-hint>
                </mat-form-field>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>Poison Centre Phone (Including Country Code)</mat-label>
                  <app-phone-input
                    [idInput]="phone_input_id['poison_centre']"
                    [numberInput]="site.poison_centre_phone"
                    [countryCodeInput]="site.poison_centre_phone_country_code"
                    [dialCodeInput]="site.poison_centre_phone_dial_code"
                    [e164NumberInput]="site.poison_centre_phone_e164"
                    (phoneDetailsOutput)="getOutputPhoneDetails($event, 'poison_centre')"
                    required="false">
                  </app-phone-input>
                  <mat-hint>Enter the phone number for the poison centre.</mat-hint>
                </mat-form-field>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>Power (Customer Service) Phone (Including Country Code)</mat-label>
                  <app-phone-input
                    [idInput]="phone_input_id['power']"
                    [numberInput]="site.power_phone"
                    [countryCodeInput]="site.power_phone_country_code"
                    [dialCodeInput]="site.power_phone_dial_code"
                    [e164NumberInput]="site.power_phone_e164"
                    (phoneDetailsOutput)="getOutputPhoneDetails($event, 'power')"
                    required="false">
                  </app-phone-input>
                  <mat-hint>Enter the phone number for the power service provider.</mat-hint>
                </mat-form-field>

                <mat-form-field class="mb-3 full-width">
                  <mat-label>24Hr Faults Phone (Including Country Code)</mat-label>
                  <app-phone-input
                    [idInput]="phone_input_id['faults']"
                    [numberInput]="site.faults_phone"
                    [countryCodeInput]="site.faults_phone_country_code"
                    [dialCodeInput]="site.faults_phone_dial_code"
                    [e164NumberInput]="site.faults_phone_e164"
                    (phoneDetailsOutput)="getOutputPhoneDetails($event, 'faults')"
                    required="false">
                  </app-phone-input>
                  <mat-hint>Enter the phone number where {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} faults can be reported.</mat-hint>
                </mat-form-field>

              </div>
            </div>

            <div class="row" appMarginBottom>
              <div class="col">
                <small><b>Note:</b> Fields that are left empty will not show to users.</small>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <button mat-raised-button [disabled]="!f.valid || inProgress || getPhoneDetailsErrorState()" type="submit" color="primary" class="save-button" class="margin-right">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

  </mat-tab>

  <mat-tab label="Safety Managers">
    <app-sites-safety-managers [site_id]="site.id" [enableEditing]="true"></app-sites-safety-managers>
  </mat-tab>

  <mat-tab label="First Aiders">
    <app-sites-first-aiders [site_id]="site.id" [enableEditing]="true"></app-sites-first-aiders>
  </mat-tab>

  <mat-tab label="Emergency Evacuation Plan Map">

    <div class="alert alert-primary" role="alert" appMargin>
      Use the following guidelines for uploading your emergency evacuation plan map:
      <ul>
        <li>It can only be a <b>PDF</b> or an <b>image</b> file.</li>
        <li>For the best image results, use an A4 scanned image of the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} map.</li>
        <li>The image will be scaled to fit on a page in the A4 PDF export.</li>
        <li>If you upload a PDF document, it will be merged with the exported information.</li>
      </ul>
    </div>

    <div appMargin>
      <button mat-raised-button color="secondary" type="button" (click)="onSelectEmergencyEvacuationPlanMap()">
        Select &amp; Upload Evacuation Map
      </button>

      <button mat-raised-button color="danger" type="button" (click)="onRemoveEmergencyEvacuationPlanMap($event)" *ngIf="site.evacuation_map_url" appMarginLeft>
        Remove Evacuation Map
      </button>

      <input type="file" (change)="onUploadEmergencyEvacuationPlanMap($event)" style="display: none;" #emergencyEvacuationPlanMapFileSelector />
    </div>

    <ng-container *ngIf="site.evacuation_map_url">
      <img *ngIf="utils.isImage(site.evacuation_map_url); else showPDF" [src]="site.evacuation_map_url" class="profile-picture" alt="Emergency Evacuation Plan Map" appMargin />
      <ng-template #showPDF>
        <mat-nav-list dense>
          <h3 matSubheader>Uploaded PDF</h3>
          <a mat-list-item [href]="site.evacuation_map_url" target="_blank">Emergency Response Plan Map <mat-icon appMarginLeft>launch</mat-icon></a>
        </mat-nav-list>
      </ng-template>
    </ng-container>

  </mat-tab>
</mat-tab-group>


