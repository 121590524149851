import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ApiDataSource} from "../../utils/api-data-source";
import {AppService} from "../../app.service";
import {UtilsService} from "../utils.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import {tap} from "rxjs/operators";
import {ApiRequestService} from "../api-request.service";

@Component({
  selector: 'app-industries-selector',
  templateUrl: './industries-selector.component.html',
  styleUrls: ['./industries-selector.component.scss']
})
export class IndustriesSelectorComponent implements OnInit, AfterViewInit {

  // columns to show and the data source
  displayedColumns: string[] = ['select', 'name'];
  dataSource: IndustriesSelectorDataSource;
  returnAllSelected = false;

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  initialSelectedValues: number[];

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<IndustriesSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // set multiple if it is not present to false
    if ( typeof this.data['multiple'] == 'undefined' ) {
      this.data['multiple'] = false;
    }

    // construct the datasource
    this.dataSource = new IndustriesSelectorDataSource(
      this.app,
      this.api,
      this.data['multiple']
    );

    // set a default selection of nothing if no selected values are passed through
    if (typeof this.data['selected'] == 'undefined') {
      this.data['selected'] = [];
    }

    // loop and select the values
    for (let i = 0; i < this.data['selected'].length; i++) {
      if (this.data['selected'][i]) {
        this.dataSource.selection.select(this.data['selected'][i]);
      }
    }

    this.initialSelectedValues = this.dataSource.selection.selected;
  }

  ngOnInit() {
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData(false);
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  // clears the search bar and performs a search
  onClearSearch() {
    // clear the search field in the datasource
    this.dataSource.search = '';
    // search for records
    this.dataSource.getData(true);
  }

  onClearSelected() {
    this.dataSource.selection.clear();
  }

  /**
   * before the dialog close we want to store the new active ppe id
   */
  onSaveAndClose() {
    // Create a response variable.
    let response: number | number[];

    // Check if multiple selections are allowed.
    if ( this.dataSource.multiple ) {
      // Respond with all selected records.
      response = this.dataSource.selection.selected;
    } else {
      // Respond only with one record or an empty array if none is selected.
      if ( this.dataSource.selection.selected.length ) {
        response = [this.dataSource.selection.selected[0]];
      } else {
        response = [];
      }
    }

    this.dialogRef.close(response);
  }
}

export class IndustriesSelectorDataSource extends ApiDataSource {

  order_by = 'name';
  order = 'asc';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/industries', resetOffset, {
      search: this.search,
      component: 'list'
    });
  }
}
