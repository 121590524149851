<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/sites">All {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}</a>

    <span class="mr-auto">
      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>

        <button mat-menu-item (click)="dataSource.onExport('csv')">
          <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect and/or export records." style="min-width: 150px">
        <mat-icon>more_vert</mat-icon>
        Menu
      </button>
    </span>

    <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onFilter()" matTooltip="Click this button to display filter options.">
      <mat-icon>filter_list</mat-icon> Filter
    </button>

    <form tourAnchor="tour.search" class="form-inline my-2 my-lg-0"
      (ngSubmit)="dataSource.getData(true)" style="min-width: 325px">
      <input class="form-control mr-sm-2" type="search"
        [(ngModel)]="dataSource.search" name="search" placeholder="Search"
        matTooltip="Search by name or location"
        aria-label="Search" id="search-input" />
      <button mat-raised-button
              class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
              color="secondary"
              matTooltip="Run the current search"
              id="search-button">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name"
      matSortDirection="asc" matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Toggle selecting all records on this page">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)"
            matTooltip="Select '{{row.name}}' for deletion">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Id">#</th>
        <td mat-cell *matCellDef="let element">
          {{ element.id }}
        </td>
      </ng-container>

      <!-- Account Column -->
      <ng-container matColumnDef="account">
        <th mat-header-cell *matHeaderCellDef matTooltip="The account the record belongs to.">Account</th>
        <td mat-cell *matCellDef="let element">
          <a routerLink="/admin/accounts/{{element?.account?.id}}/edit">
            {{ element?.account?.name || '' }}
          </a>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Name">Name</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element?.parent_site">
            <small style="display: block;">
              <a [routerLink]="'/admin/sites/' + element?.parent_site?.id + '/details'">
                {{ (element?.parent_site?.name || '') | ellipsis: [30] }}
              </a>
            </small>
          </ng-container>
          <mat-icon *ngIf="element?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
          <a [routerLink]="'/admin/sites/' + element?.id + '/details'">
            {{ (element?.name || '') | ellipsis: [30] }}
          </a>
        </td>
      </ng-container>

      <!-- Address Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Location">Address</th>
        <td mat-cell *matCellDef="let element">
          {{ element.location | ellipsis: [30] }}
        </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="site_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Type">Type</th>
        <td mat-cell *matCellDef="let element">
          <a (click)="onSiteTypeFilter(element.site_type)" matTooltip="Filter by {{element.site_type}}">
            {{ element.site_type }}
          </a>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="site_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Status">Status</th>
        <td mat-cell *matCellDef="let element">
          <a (click)="onSiteStatusFilter(element.site_status)" matTooltip="Filter by {{element.site_status}}">
            {{ element.site_status }}
          </a>
        </td>
      </ng-container>

      <!-- Geofenced Column -->
      <ng-container matColumnDef="geofence">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Geofenced">Geofenced</th>
        <td mat-cell *matCellDef="let element">
          {{ element.geofence ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="site_risk_assessment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by the risk assessment.">Risk Level</th>
        <td mat-cell *matCellDef="let element">
          <span [class]="utils.getSiteRiskStatusTextColor(element.site_risk_assessment)">
            {{ element.site_risk_assessment | titlecase }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the record was created.">Created At</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.date_created*1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef tourAnchor="tour.actions">
          Actions
        </th>
        <td mat-cell *matCellDef="let element">
          <a type="button" mat-button routerLink="/admin/sites/{{ element.id }}/details" matTooltip="Edit the information." matTooltipPosition="above">
            Edit
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" tourAnchor="tour.sort.headers"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator tourAnchor="tour.paginator" [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
