import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-induction-modules-view',
  templateUrl: './induction-modules-view.component.html',
  styleUrls: ['./induction-modules-view.component.scss']
})
export class InductionModulesViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
