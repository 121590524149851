import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiRequestService } from '../../../shared/api-request.service';
import { SystemNotification } from '../../../interfaces/system-notification.interface';
import { UserModel } from '../../../models/user.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-employees-notifications',
  templateUrl: './employees-notifications.component.html',
  styleUrls: ['./employees-notifications.component.scss']
})
export class EmployeesNotificationsComponent implements OnInit {

  // Get the user id.
  @Input('user_id') user_id: number;

  // The user with system notifications.
  user: UserModel = {
    system_notifications: [],
    notification_sites_count: 0
  } as UserModel;

  // All system notifications.
  system_notifications: SystemNotification[] = [];

  // Group system notifications.
  grouped_system_notifications: { [key: string]: {
    system_notifications: SystemNotification[],
    has_site_related_notifications: boolean
  } } = {};

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    // Redirect the page if the user id is not valid.
    if ( !this.user_id ) {
      this.router.navigate(['/employees']);
      return;
    }

    // Get all system notifications.
    this.getSystemNotifications().then((): void => {
      // Get the notification role id.
      this.getUserById(this.user_id);
    });
  }

  /**
   * Retrieves the user by ID. The user must be a direct user of the active account.
   *
   * @param {number} user_id - The ID of the notification role to retrieve.
   * @returns {undefined}
   */
  getUserById(user_id: number): void {
    // Make an API request to get the user.
    this.api.makeRequest('get', `v2/employee/${user_id}/notifications`)
      .then((response: any): void => {
        // Apply the user data from the API.
        this.user = response as UserModel;
        // Map the system notifications.
        this.mapSystemNotifications();
      })
      .catch((errorResponse: any): void => {
        // Handle API error responses.
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  /**
   * Maps the system notifications by marking them as checked if they exist in the user's selected system notifications.
   * This method updates the 'checked' property of each system notification in the 'system_notifications' array.
   *
   * @returns {void} - This method does not return anything.
   */
  mapSystemNotifications(): void {
    this.system_notifications = this.system_notifications.map((system_notification: SystemNotification): SystemNotification => {
      system_notification.checked = this.user.system_notifications.some((role_system_notification: SystemNotification): boolean => {
        return role_system_notification.id == system_notification.id;
      });
      return system_notification;
    });
    // Group the system notifications by feature.
    this.groupSystemNotificationsByFeature();
  }

  /**
   * Retrieves system notifications from the API.
   *
   * @returns {Promise<any>} - A promise that resolves with the system notifications data.
   */
  async getSystemNotifications(): Promise<any> {
    // Make an API request to get all system notifications.
    return this.api.makeRequest('get', `v2/system-notifications`)
      .then((response: any): void => {
        // Apply the system notifications from the API.
        this.system_notifications = response.map((notification: SystemNotification) => {
          return notification as SystemNotification;
        });
      })
      .catch((errorResponse: any): void => {
        // Handle API error responses.
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  /**
   * Groups the system notifications by feature.
   *
   * @returns {void}
   */
  groupSystemNotificationsByFeature(): void {
    // Transform the system notifications into grouped system notification.
    this.grouped_system_notifications = this.system_notifications.reduce((grouped, notification: SystemNotification) => {
      // Get the feature.
      const feature: string = notification.feature;
      if ( !grouped[feature] ) {
        grouped[feature] = {
          system_notifications: [],
          has_site_related_notifications: false
        };
      }
      // Push the notification into the group.
      grouped[feature].system_notifications.push(notification);
      // Check if any system notifications are site related.
      if ( !grouped[feature].has_site_related_notifications && notification.is_site_related ) {
        grouped[feature].has_site_related_notifications = true;
      }
      // Return the grouped notifications.
      return grouped;
    }, {});
  }

  /**
   * Validate the form input and save the data.
   * @param form The NgForm object that is used for validation.
   * @returns Nothing.
   */
  onSubmit(form: NgForm): void {
    // Check if the submitted form is valid or not.
    if ( !form.valid ) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    // Fetch and prepare selected system notifications. Only get system notifications that the client checked.
    this.user.system_notifications = Object.values(this.grouped_system_notifications)
      .reduce((acc: SystemNotification[], group) => {
        const selectedNotifications: SystemNotification[] = group.system_notifications.filter((system_notification: SystemNotification): boolean => {
          // Check if any sites were selected and filter the system notifications for it.
          if ( this.user.notification_sites_count > 0 ) {
            return system_notification.is_site_related && system_notification.checked;
          }
          return system_notification.checked;
        });
        return acc.concat(selectedNotifications);
      }, []);

    // Make an API request to update the selected notifications for the user.
    this.api.makeRequest('put', `v2/employee/${this.user.id}/notifications`, this.user)
      .then((response: UserModel): void => {
        this.user = response as UserModel;
        this.utils.showToast(`The notification(s) was updated for the user.`);
        // Remap the system notifications.
        this.mapSystemNotifications();
      })
      .catch((errorResponse: any): void => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }
}
