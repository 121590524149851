<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/erps">ERP's</a></li>
    <li class="breadcrumb-item">
      <a routerLink="/erps/{{ parent_id }}/teams">ERP Team Members</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Edit</li>
  </ol>
</nav>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
        <div class="card">
          <div class="card-body form-container">
            <h5 class="card-title">ERP Team Member Form</h5>

            <mat-form-field>
              <input
                matInput
                placeholder="Full Name"
                [(ngModel)]="service.item.name"
                name="name"
                type="text"
                #name
                required
              />
              <mat-error *ngIf="!name.valid"
                >Please enter the full name of the team member.</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                placeholder="Role / Responsibility"
                [(ngModel)]="service.item.role"
                name="role"
                type="text"
                #role
                required
              />
              <mat-error *ngIf="!role.valid"
                >Please enter the team member's role.</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                placeholder="Training Required"
                [(ngModel)]="service.item.training_required"
                name="training_required"
                type="text"
                #training_required
                required
              />
              <mat-error *ngIf="!training_required.valid"
                >Please enter the training that is required.</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                placeholder="Contact Number"
                [(ngModel)]="service.item.phone"
                name="phone"
                type="text"
                #phone
                required
              />
              <mat-error *ngIf="!phone.valid"
                >Please enter a contact number.</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                placeholder="Email Address"
                [(ngModel)]="service.item.email"
                name="email"
                type="text"
                #email
                required
              />
              <mat-error *ngIf="!email.valid"
                >Please enter an email address.</mat-error
              >
            </mat-form-field>
          </div>
        </div>

        <div appMarginTop class="d-flex justify-content-end">
          <button
            type="submit"
            mat-raised-button
            [disabled]="!f.valid"
            color="primary"
          >
            Save
          </button>
        </div>
      </form>
    </div>

    <div class="col-12 col-md-6">
      <h4>Instructions</h4>
      <p>
        Complete the form on the left to add a new or update an existing team
        member.
      </p>
      <p>
        Enter the team memeber's details as well as their responsibilities
        during the rescue efforts.
      </p>
      <p>
        Provide up to date contact information so that the team member can be
        contacted.
      </p>
    </div>
  </div>
</div>
