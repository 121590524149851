import {UserModel} from "../../models/user.model";
import {Auditable} from "../../shared/auditable.model";
import {PpeModel} from "../ppe/ppe.model";
import {WorkPermitModel} from "../work-permits/work-permits.model";

export class TaskAnalysesRevisedModel extends Auditable {
  id: number;

  ppe: PpeModel[];
  ppe_ids?: number[];
  work_permits: WorkPermitModel[];
  work_permit_ids?: number[];

  task_name: string;
  task_description: string;
  work_procedure: string;

  training_qualifications: string;
  qualifications: string;
  duties: string;
  training: string;
  certificates: string;
  code_of_practice: string;
  maintenance_checks: string;
  safety_practices: string;
  ppe_other: string;
  work_permits_other: string;
  tools_other: string;

  clone_from_id: number;

  /**
   * Who created this TA Template?
   */
  created_by_user: UserModel;

  /**
   * Who updated this TA Template?
   */
  updated_by_user: UserModel;

  cloneFrom(data: any) {
    this.clone_from_id = data.id;

    this.ppe = data.ppe;
    this.work_permits = data.work_permits;

    this.ppe_ids = this.ppe ? this.ppe.map((ppe) => ppe.id) : [];
    this.work_permit_ids = this.work_permits ? this.work_permits.map((work_permit) => work_permit.id) : [];

    this.task_name = data.task_name;
    this.task_description = data.task_description;
    this.work_procedure = data.work_procedure;

    this.training_qualifications = data.training_qualifications;
    this.qualifications = data.qualifications;
    this.duties = data.duties;
    this.training = data.training;
    this.certificates = data.certificates;
    this.code_of_practice = data.code_of_practice;
    this.maintenance_checks = data.maintenance_checks;
    this.safety_practices = data.safety_practices;
    this.ppe_other = data.ppe_other;
    this.work_permits_other = data.work_permits_other;
    this.tools_other = data.tools_other;
  }
}
