<form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
  <div class="card">
    <div class="card-body ">
      <div class="container-fluid">

        <div class="row">
          <div class="col form-container">
            <mat-form-field>
              <input matInput
                     placeholder="Address / Address Lookup"
                     [(ngModel)]="site.location"
                     name="location"
                     id="location"
                     #location
                     type="text"
                     required/>
              <mat-error *ngIf="!location.valid">
                Please enter a location / physical address.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="full-width">
              <input matInput
                     placeholder="Max Geofence Size"
                     [(ngModel)]="site.max_geofence_size"
                     name="max_geofence_size"
                     type="number"
                     id="max_geofence_size"/>
              <mat-hint>
                Enter max geofence size here.
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">

            <small>
              Use the map below to draw a geofence. The default max geofence size is 50000 m2.
            </small>

            <div #map id="map" appMarginTop></div>

            <small><b>Allowed Geofence Size:</b> {{ site.max_geofence_size }} &#13217; | <b>Current Size:</b> {{ site.geofence_size | number: '1.0-0' }} &#13217;</small>

          </div>
        </div>

        <div class="row">
          <div class="col">

            <button
              mat-raised-button
              [disabled]="!f.valid || inProgress || site.geofence_size > site.max_geofence_size"
              (click)="onSubmit(f)"
              type="button"
              color="primary"
              class="save-button mr-3 mt-3">
              <mat-icon>save</mat-icon> Save
            </button>

            <button
              mat-raised-button
              type="button"
              (click)="resetMapControls()"
              color="secondary"
              class="mt-3">
              <mat-icon>restore</mat-icon> Reset Map
            </button>

          </div>
        </div>

      </div>
    </div>
  </div>
</form>
