import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { HazardsEditComponent } from 'src/app/hazards/hazards-edit/hazards-edit.component';
import { HazardControlModel } from 'src/app/models/hazard-control.model';
import { HazardModel } from 'src/app/models/hazard.model';
import { SafetyObservation } from 'src/app/models/safety-observation.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';

@Component({
  selector: 'app-safety-observations-view-hazards',
  templateUrl: './safety-observations-view-hazards.component.html',
  styleUrls: ['./safety-observations-view-hazards.component.scss']
})
export class SafetyObservationsViewHazardsComponent implements OnInit {

  @Input('safety_observation_id') safety_observation_id: number;
  @Input('safety_observation') safety_observation: SafetyObservation;

  dataSource: SafetyObservationsHazardsDataSource = new SafetyObservationsHazardsDataSource(this.app, this.api);

  listColumns: string[] = [
    // 'select',
    'id',
    'name',
    'status',
    'ira',
    'rra',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];

  // the paginator and sorter
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.dataSource.safety_observation_id = this.safety_observation_id;
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC dates based on its corresponding date column.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      this.dataSource.getData();
    })).subscribe();
  }

  /**
   * Create a new or update an existing hazard.
   * @param hazardData
   */
   onEditHazard(hazardData?: HazardModel) {
    // Open the hazard edit dialog for editing
    let hazard: HazardModel = new HazardModel();
    if ( hazardData ) {
      // applying the data now also handles converting strings to JSON.
      hazard.apply(hazardData);
    } else {
      // Set the hazard description.
      hazard.description = this.safety_observation.so_description;
      // Clear hazard controls
      hazard.controls.length = 0;
      // New hazard control
      let control = new HazardControlModel();
      control.control = this.safety_observation.so_action_taken;
      control.loc = 1;
      hazard.controls.push(control);
    }

    // Assign the safety observation to the hazard.
    hazard.safety_observation_id = this.safety_observation_id; // This will only be stored when the hazard is created, not updated.

    this.utils.showComponentDialog(HazardsEditComponent, hazard)
    .then((response) => {
      if ( response ) {
        this.dataSource.getData();
      }
    });
  }

  /**
   * The hazard that should be removed.
   * @param hazard_id The hazard id.
   */
  onRemoveHazard(hazard_id: number) {
    this.utils.showModal(
      'Delete Hazard/Risk',
      'Are you sure you want to delete this Hazard/Risk? It will also be removed from ' + this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() + ' if associated with any.',
      () => {
        this.api.makeRequest('delete', `v1/hazards/${hazard_id}`, {}, {
          site_id: this.safety_observation.site_id
        })
        .then((response) => {
          this.dataSource.getData(true);
        })
        .catch((errorResponse) => {
          this.utils.handleAPIErrors(errorResponse);
        });
      }
    );
  }
}

export class SafetyObservationsHazardsDataSource extends ApiDataSource {
  // Order the records by the date it was created.
  order_by = 'date_created';
  order = 'desc';

  safety_observation_id: number;

  status: string = '';

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/safety-observations/${this.safety_observation_id}/hazards`, resetOffset, {
      status: this.status
    });
  }
}
