import { Directive, Input, ElementRef, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appPrequalScoreColor]'
})
export class PrequalScoreColorDirective { 

  @Input() scorePercentage: number;

  constructor(
    private element: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ( this.scorePercentage > 60 ) {
      this.element.nativeElement.style.color = 'green';
    } else if ( this.scorePercentage > 30 ) {
      this.element.nativeElement.style.color = 'orange';
    } else {
      this.element.nativeElement.style.color = 'red';
    }
  }

} 
