import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {NgForm} from "@angular/forms";
import {PpeSelectorComponent} from "../../shared/ppe-selector/ppe-selector.component";
import {WorkPermitsSelectorComponent} from "../../shared/work-permits-selector/work-permits-selector.component";
import {ToolsSelectorComponent} from "../../shared/tools-selector/tools-selector.component";
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {ActivatedRoute, Router} from "@angular/router";
import {AppService} from "../../app.service";
import {NetworkedUsersSelectorComponent} from "../../shared/networked-users-selector/networked-users-selector.component";
import {SitesTaskAnalysesRevisedVersioningComponent} from "../sites-task-analyses-revised-versioning/sites-task-analyses-revised-versioning.component";
import {UserModel} from "../../models/user.model";
import {NetworkedEmployeesSelectorComponent} from "../../shared/networked-employees-selector/networked-employees-selector.component";

@Component({
  selector: 'app-sites-task-analyses-revised-edit',
  templateUrl: './sites-task-analyses-revised-edit.component.html',
  styleUrls: ['./sites-task-analyses-revised-edit.component.scss']
})
export class SitesTaskAnalysesRevisedEditComponent implements OnInit {

  @Input('taRevisedId') taRevisedId: number;
  @Input('site_id') site_id: number;

  ta_revised: TaskAnalysesRevisedModel = {} as TaskAnalysesRevisedModel;

  inProgress = false;
  selectedFiles: File[] = [];
  newFiles: FileList;
  path: string;

  approverId: number = 0;
  approverAcknowledged: boolean = false;
  approverUpdateId: number = 0;

  authorizerId: number = 0;
  authorizerAcknowledged: boolean = false;
  authorizerUpdateId: number = 0;

  supervisorId: number = 0;
  supervisorAcknowledged: boolean = false;
  supervisorUpdateId: number = 0;

  acknowledgeUserId: number = 0;
  users: UserModel[] = [] as UserModel[];
  userUpdateIds: number[] = [];

  employeeIds: number[] = [];

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // get the ta revised id from the parent route params
    this.ta_revised.id = this.taRevisedId; // Number(this.route.parent.snapshot.children[0].params.ta_revised_id);

    // get the site id from the parent route params
    this.ta_revised.site_id = this.site_id; // Number(this.route.parent.snapshot.params['site_id']);

    // get sites path edit/view
    this.path = this.route.parent.snapshot.routeConfig.path;

    if(this.ta_revised.id) {
      this.find(this.ta_revised.id);
      this.getEmployeeIds();
    } else {
      this.redirectBack();
    }
  }

  private redirectBack() {
    if(this.ta_revised.site_id && this.path) {
      this.router.navigate([`/sites/${this.ta_revised.site_id}/${this.path}/task-analyses-revised/`]);
    }
  }

  onSubmit(form: NgForm, findOnly: boolean = false) {
    // Do not process if already in progress.
    if (this.inProgress) {
      return;
    }

    // Perform form validation.
    if (!form.valid) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    this.inProgress = true;

    if(this.ta_revised.id) {
      if(findOnly) {
        this.find(this.ta_revised.id);
        this.inProgress = false;
      } else {
        this.update();
      }
    }
  }

  private find(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {
      site_id: this.ta_revised.site_id
    })
      .then((response) => {
        this.ta_revised = response;

        if(this.ta_revised.contractor) {
          this.redirectBack();
        } else {
          // Extract PPE ids.
          this.ta_revised.ppe_ids = this.ta_revised.ppe ? this.ta_revised.ppe.map((ppe) => ppe.id) : [];

          // Extract Work Permit ids.
          this.ta_revised.work_permit_ids = this.ta_revised.work_permits ? this.ta_revised.work_permits.map((work_permit) => work_permit.id) : [];

          // Extract Tool ids.
          this.ta_revised.tool_ids = this.ta_revised.tools ? this.ta_revised.tools.map((tool) => tool.id) : [];

          // Extract User ids.
          this.ta_revised.user_ids = this.ta_revised.users ? this.ta_revised.users.map((user) => user.id) : [];

          // Extract File ids.
          this.ta_revised.file_ids = this.ta_revised.files ? this.ta_revised.files.map((file) => file.id) : [];

          // Fixed Acknowledgement Data not changed by Form updates
          this.approverId = this.ta_revised.approver_id;
          this.approverAcknowledged = this.ta_revised.approver_acknowledged;

          this.authorizerId = this.ta_revised.authorizer_id;
          this.authorizerAcknowledged = this.ta_revised.authorizer_acknowledged;

          this.supervisorId = this.ta_revised.supervisor_id;
          this.supervisorAcknowledged = this.ta_revised.supervisor_acknowledged;

          // this.acknowledgeUserId = 0;
          this.users = this.ta_revised.users;
        }

      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private getEmployeeIds() {
    this.api.makeRequest('get', `v2/task-analyses-revised/${this.ta_revised.id}/employees`, {}, {
      site_id: this.ta_revised.site_id
    })
      .then((response) => {
        this.employeeIds = response;
      })
      .catch((errorResponse) => {
        // this.onSuccess('Employees could not be found.');
      });
  }

  onDelete() {
    this.utils.showModal(
      'Remove TA/JSA/SWMS',
      `Are you sure you want to remove the "${this.ta_revised.task_name}" TA/JSA/SWMS?`,
      () => {
        this.api.makeRequest('delete', `v2/task-analyses-revised/${this.ta_revised.id}`, {
          site_id: this.ta_revised.site_id
        })
          .then((response) => {
            this.utils.showToast(`The "${this.ta_revised.task_name}" TA/JSA/SWMS has been deleted.`);
            // this.dialogRef.close(true);
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  private update(versioningCheck: boolean = true, updateAcknowledgement: string = "", updateUsers: string = "") {
    if(versioningCheck) {
      this.acknowledgementCheck();
    } else {
      if(updateAcknowledgement != "") {
        this.acknowledgementUpdate(updateAcknowledgement);
      } else if(updateUsers != "") {
        this.usersUpdate(updateUsers);
      } else {
        this.continueUpdate();
      }
    }
  }

  private continueUpdate() {
    let request: Promise<any>;

    request = this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised.id}`, this.ta_revised, {
        site_id: this.ta_revised.site_id
      })
      .then((response) => {
        this.onSuccess(`Updated: "${response.task_name}"`);
        // this.ta_revised.apply(response);
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised/${this.ta_revised.id}`, this.selectedFiles)
          .then((response) => {
            this.utils.showToast('Your files successfully uploaded.');
            this.find(this.ta_revised.id);
          })
          .finally(() => {
            this.selectedFiles.length = 0;
          });
      } else {
        this.find(this.ta_revised.id);
      }

      this.inProgress = false;
    });
  }

  private acknowledgementUpdate(updateAcknowledgement: string = "") {
    this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised.id}/acknowledgement`, this.ta_revised, {
        site_id: this.ta_revised.site_id,
        user_id: this.app.user.id,
        acknowledge_approver_status: updateAcknowledgement == "approver" ? this.approverAcknowledged : false,
        acknowledge_authorizer_status: updateAcknowledgement == "authorizer" ? this.authorizerAcknowledged : false,
        acknowledge_supervisor_status: updateAcknowledgement == "supervisor" ? this.supervisorAcknowledged : false,
        acknowledge_user_id: updateAcknowledgement == "assignee" ? this.acknowledgeUserId : 0
      })
      .then((response) => {
        let update_text = "Acknowledgement";
        if(updateAcknowledgement == "approver") {
          update_text = "Approval";
        } else if(updateAcknowledgement == "authorizer") {
          update_text = "Authorization";
        } else if(updateAcknowledgement == "supervisor") {
          update_text = "Supervisor Acknowledgement";
        }

        this.find(this.ta_revised.id);

        this.onSuccess(`Updated "${update_text}"`);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  private usersUpdate (updateUsers: string = "") {
    this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised.id}/users`, this.ta_revised, {
        site_id: this.ta_revised.site_id,
        user_id: this.app.user.id,
        approver_update_id: updateUsers == "approver" ? this.approverUpdateId : false,
        authorizer_update_id: updateUsers == "authorizer" ? this.authorizerUpdateId : false,
        supervisor_update_id: updateUsers == "supervisor" ? this.supervisorUpdateId : false,
        user_update_ids: updateUsers == "assignees" ? (this.userUpdateIds.length ? this.userUpdateIds.join(',') : '') : false
      })
      .then((response) => {
        let update_text = "Assignees";
        if(updateUsers == "approver") {
          update_text = "Approver";
        } else if(updateUsers == "authorizer") {
          update_text = "Authorizer";
        } else if(updateUsers == "supervisor") {
          update_text = "Supervisor";
        }

        this.find(this.ta_revised.id);

        this.onSuccess(`Updated "${update_text}"`);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  private acknowledgementCheck(file_id: number = 0) {
    this.api
      .makeRequest('get', `v2/task-analyses-revised/${this.ta_revised.id}/acknowledgement`, this.ta_revised, {
        site_id: this.ta_revised.site_id
      })
      .then((response) => {
        if(response.data.acknowledgementsDone) {
          this.beforeVersioning(file_id);
        } else if(file_id > 0) {
          this.continueOnRemoveFile(file_id);
        } else {
          this.continueUpdate();
        }
      });
  }

  private createNewVersion(amendment_title: string, reason_for_amendment: string, file_id: number = 0) {
    let request: Promise<any>;
    let upload_ta_revised_id: number = 0;

    this.inProgress = true;

    request = this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised.id}/version`, this.ta_revised, {
        site_id: this.ta_revised.site_id,
        amendment_title: amendment_title,
        reason_for_amendment: reason_for_amendment,
        file_remove_id: file_id
      })
      .then((response) => {
        this.onSuccess(`Created New Version of "${response.task_name}"`);
        upload_ta_revised_id = response.id;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 && upload_ta_revised_id > 0 ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised/${upload_ta_revised_id}`, this.selectedFiles)
          .then((response) => {
            this.inProgress = false;
            this.redirectBack();
          })
          .finally(() => {
            this.selectedFiles.length = 0;
          });
      } else {
        this.inProgress = false;
        this.redirectBack();
      }
    });
  }

  private beforeVersioning(file_id: number = 0) {
    this.inProgress = false;

    this.utils.showComponentDialog(
      SitesTaskAnalysesRevisedVersioningComponent,
      {
        task_name: this.ta_revised.task_name
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          // If confirmed then begin versioning of Task Analysis
          if(results.confirmed) {
            this.createNewVersion(results.amendment_title, results.reason_for_amendment, file_id);
          }
        }
      }
    );
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
  }

  selectPPE() {
    this.utils.showComponentDialog(
      PpeSelectorComponent,
      {
        selected: this.ta_revised.ppe_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.ta_revised.ppe_ids = results;
      }
    );
  }

  selectWorkPermits() {
    this.utils.showComponentDialog(
      WorkPermitsSelectorComponent,
      {
        selected: this.ta_revised.work_permit_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.ta_revised.work_permit_ids = results;
      }
    );
  }

  selectTools() {
    this.utils.showComponentDialog(
      ToolsSelectorComponent,
      {
        selected: this.ta_revised.tool_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.ta_revised.tool_ids = results;
      }
    );
  }

  onSelectUsers() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        selected: this.ta_revised.user_ids,
        multiple: true,
        selectedAccountId: this.app.account.id,
        visitor_site_id: this.ta_revised.site_id
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }

        if(!this.employeesCheck(results)) {
          this.utils.showModal(
            'Confirm Assignees Update',
            `There are users selected that are not employees. Are you sure you want to continue to update Assignees?`,
            () => {
              this.ta_revised.user_ids = results;
              this.continueOnSelectUsers(results);
            }
          );
        } else {
          this.ta_revised.user_ids = results;
          this.continueOnSelectUsers(results);
        }

      }
    );
  }

  private continueOnSelectUsers(userUpdateIds: number[]) {
    this.userUpdateIds = userUpdateIds;
    this.inProgress = true;
    this.update(false, "", "assignees");
  }

  private employeesCheck(assignees: number[]) {
    for (const assignee of assignees) {
      if(!this.employeeIds.includes(assignee)) {
        return false;
      }
    }
    return true;
  }

  onSelectFiles(form: NgForm, files?: any[]) {
    if ( files.length > 0 ) {
      this.selectedFiles.push(...files);
      this.onSubmit(form);
    }
  }

  onRemoveFile(file_id: any) {
    if ( file_id > 0 ) {
      // Do not process if already in progress.
      if (this.inProgress) {
        return;
      }

      // this.inProgress = true;
      // this.acknowledgementCheck(file_id); // Removed to avoid Versioning when Managing Files
      this.utils.showToast('Your file was removed successfully.');
    }
  }

  continueOnRemoveFile(file_id: number) {
    this.api.makeRequest('delete',`v2/file-manager/task_analysis_revised/${this.ta_revised.id}/${file_id}`, [], {})
      .then((response) => {
        this.utils.showToast('Your file was removed successfully.');
        this.find(this.ta_revised.id);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  onSelectApprover() {
    this.utils.showComponentDialog(
      NetworkedEmployeesSelectorComponent,
      {
        multiple: false,
        selected: [this.ta_revised.approver_id]
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        // this.ta_revised.approver_id = userId;

        this.approverUpdateId = userId;
        this.inProgress = true;
        this.update(false, "", "approver");

      }
    );
  }

  onSelectAuthorizer() {
    this.utils.showComponentDialog(
      NetworkedEmployeesSelectorComponent,
      {
        multiple: false,
        selected: [this.ta_revised.authorizer_id]
        // selectedAccountId: this.app.account.id
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        // this.ta_revised.authorizer_id = userId;

        this.authorizerUpdateId = userId;
        this.inProgress = true;
        this.update(false, "", "authorizer");
      }
    );
  }

  onSelectSupervisor() {
    this.utils.showComponentDialog(
      NetworkedEmployeesSelectorComponent,
      {
        multiple: false,
        selected: [this.ta_revised.supervisor_id]
        // selectedAccountId: this.app.account.id
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        // this.ta_revised.supervisor_id = userId;

        this.supervisorUpdateId = userId;
        this.inProgress = true;
        this.update(false, "", "supervisor");
      }
    );
  }

  onApprove() {
    this.approverAcknowledged = true;
    this.inProgress = true;
    this.update(false, "approver");
  }

  onAuthorize() {
    this.authorizerAcknowledged = true;
    this.inProgress = true;
    this.update(false, "authorizer");
  }

  onSupervisorAcknowledge() {
    this.supervisorAcknowledged = true;
    this.inProgress = true;
    this.update(false, "supervisor");
  }

  onAcknowledge() {
    this.acknowledgeUserId = this.app.user.id;
    this.inProgress = true;
    this.update(false, "assignee");
  }

}
