import { ActionModel } from '../actions/action.model';
import { FileModel } from '../shared/file.model';
import { Auditable } from '../shared/auditable.model';

export class AuditItemModel extends Auditable {
  id: number;
  name: string;
  status: 'Yes' | 'Partial' | 'No' | 'NA';
  section: string;
  action: ActionModel;
  template: boolean;
  files: FileModel[];
  note: string;
  actions: ActionModel[];
}
