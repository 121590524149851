<div mat-dialog-title>
  <h5>View Hazard/Risk</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <ng-container>
    <h6>Hazard/Risk</h6>
    <p id="name">{{ hazard.name }}</p>
  </ng-container>

  <ng-container *ngIf="hazard.description">
    <h6>Description</h6>
    <p id="description">{{ hazard.description }}</p>
  </ng-container>

  <ng-container>
    <h6>Significant</h6>
    <p>{{ hazard.significant ? 'Yes' : 'No' }}</p>
  </ng-container>

  <ng-container *ngIf="hazard.status">
    <h6>Status</h6>
    <p id="status">{{ hazard.status }}</p>
  </ng-container>

  <ng-container *ngIf="hazard.ira_likelihood && hazard.ira_severity">
    <h6>Initial Risk Assessment</h6>
    <p id="ira">
      <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(hazard.ira_likelihood, hazard.ira_severity))">
        {{ utils.getRiskAssessmentText(hazard.ira_likelihood, hazard.ira_severity) }}
      </span>
    </p>
  </ng-container>

  <ng-container *ngIf="hazard.rra_likelihood && hazard.rra_severity">
    <h6>Residual Risk Assessment</h6>
    <p id="rra">
      <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(hazard.rra_likelihood, hazard.rra_severity))">
        {{ utils.getRiskAssessmentText(hazard.rra_likelihood, hazard.rra_severity) }}
      </span>
    </p>
  </ng-container>

  <ng-container *ngIf="hazard.controls.length">
    <h6>Hazard/Risk Controls</h6>
    <p id="controls" *ngFor="let control of hazard.controls; let i = index">
      <ng-container *ngIf="control && control?.control && control?.loc">
        <b>{{ i + 1 }} - {{ control.control }}</b> <br />
        {{ loc_list[control.loc - 1] }}
      </ng-container>
    </p>
  </ng-container>

  <ng-container *ngIf="hazard.reviewer">
    <h6>Reviewer</h6>
    <p id="reviewed-by">
      <a (click)="onUserPublicView(hazard.reviewer.hash)"
         matTooltip="View User Public Profile">
        {{ hazard.reviewer.contact_person }}
      </a>
    </p>
  </ng-container>

  <ng-container *ngIf="hazard.reviewer_date">
    <h6>Review Date</h6>
    <p id="reviewed-date">{{ hazard.reviewer_date * 1000 | date:'medium' }}</p>
  </ng-container>

  <ng-container *ngIf="hazard.last_reviewer_date">
    <h6>Last Review Date</h6>
    <p id="last-reviewed-date">{{ hazard.last_reviewer_date * 1000 | date:'medium' }}</p>
  </ng-container>

  <ng-container>
    <h6>Created By</h6>
    <p id="created-by">
      <a (click)="onUserPublicView(hazard.created_by_hash)"
         matTooltip="View User Public Profile">
        {{ hazard.created_by_name }}
      </a>
    </p>
  </ng-container>

  <ng-container *ngIf="hazard.files">
    <h6>Files</h6>
    <app-files-list
      id="files"
      [files]="hazard.files"
      [view]="true">
    </app-files-list>
  </ng-container>
</mat-dialog-content>
