<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/visitors">Visitors</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="listDataSource.selectAllToggle()">
            <mat-icon *ngIf="listDataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!listDataSource.isAllSelected()">select_all</mat-icon>
            {{ listDataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onExport('csv')">
            <mat-icon>cloud_download</mat-icon> Export {{ listDataSource.selection.hasValue() ? '(' + listDataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect and/or export Visitors." style="min-width: 150px">
          <mat-icon>more_vert</mat-icon>
          List Menu/Export
        </button>

      </span>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="listDataSource.getData(true)" style="min-width: 325px">
        <input class="form-control mr-sm-2"
               type="search"
               [(ngModel)]="listDataSource.search"
               name="search"
               placeholder="Search Visitors"
               aria-label="Search"
               id="search-input"
               matTooltip="Search for visitors by name, email address or mobile number." />

        <button mat-raised-button
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                color="secondary"
                (click)="listDataSource.getData(true)"
                id="search-button"
                matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon>
          Search
        </button>
      </form>

    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="listDataSource" style="width: 100%" matSort matSortActive="contact_person" matSortDirection="asc" matSortDisableClear>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? listDataSource.selectAllToggle() : null"
                        [checked]="listDataSource.selection.hasValue() && listDataSource.isAllSelected()"
                        [indeterminate]="listDataSource.selection.hasValue() && !listDataSource.isAllSelected()"
                        matTooltip="Toggle selecting all Visitors on this page">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? listDataSource.selection.toggle(row.id) : null"
                        [checked]="listDataSource.selection.isSelected(row.id)"
                        matTooltip="Toggle selecting '{{row.contact_person}}' for exporting'">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="contact_person">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by full name">
          Full Name
        </th>
        <td mat-cell *matCellDef="let element">
          <a (click)="onUserPublicView(element.hash)"
             matTooltip="View User Public Profile">
            {{ element.contact_person }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by Email Address">
          Email Address
        </th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by {{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}">
          {{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by Date Visited">
          Date Visited
        </th>
        <td mat-cell *matCellDef="let element">{{ (element.date_created * 1000) | timezoneConvertMedium }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button type="button" mat-button (click)="onView(element.id)" matTooltip="View your visitor record.">
            View
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
