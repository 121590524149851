<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="listDataSource.parent_site_id">
    <button mat-raised-button class="mr-sm-2" type="button" (click)="onResetList()">
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>
  </nav>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">PCBU1 {{ listDataSource.parent_site_id ? utils.getLangTerm('child-sites.plural', 'Subsites') : utils.getLangTerm('sites.plural', 'Sites') }}</span>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">

      </span>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="listDataSource.getData(true)" style="min-width: 325px">
        <input
          class="form-control mr-sm-2" type="search"
          [(ngModel)]="listDataSource.search" name="search" placeholder="Search"
          aria-label="Search" id="search-input"
          matTooltip="Search for {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} by name, address or associated owner." />
        <button mat-raised-button
                color="secondary"
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                (click)="listDataSource.getData(true)"
                id="search-button"
                matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="listDataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? listDataSource.selectAllToggle() : null"
            [checked]="listDataSource.selection.hasValue() && listDataSource.isAllSelected()"
            [indeterminate]="listDataSource.selection.hasValue() && !listDataSource.isAllSelected()"
            matTooltip="Toggle select all records in this list.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? listDataSource.selection.toggle(row.id) : null"
            [checked]="listDataSource.selection.isSelected(row.id)"
            matTooltip="Toggle select this record.">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by name.">
          {{ listDataSource.parent_site_id ? utils.getLangTerm('child-sites.singular', 'Subsite') : utils.getLangTerm('sites.singular', 'Site') }} Name
        </th>
        <td mat-cell *matCellDef="let site">
          <mat-icon *ngIf="site?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
          {{ site.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef matTooltip="Toggle sorting by the address.">
          {{ listDataSource.parent_site_id ? utils.getLangTerm('child-sites.singular', 'Subsite') : utils.getLangTerm('sites.singular', 'Site') }} Address
        </th>
        <td mat-cell *matCellDef="let site">
          {{ site.location }}
        </td>
      </ng-container>

      <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef>
          {{ listDataSource.parent_site_id ? utils.getLangTerm('child-sites.singular', 'Subsite') : utils.getLangTerm('sites.singular', 'Site') }} Owner
        </th>
        <td mat-cell *matCellDef="let site">
          {{ site.account.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let site">
          <button type="button" mat-button *ngIf="!site.parent_site_id && !listDataSource.parent_site_id " (click)="onFilterByParentSite(site.id)" matTooltip="Show {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}." matTooltipPosition="above" >
            {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
            <mat-icon>chevron_right</mat-icon>
          </button>

          <button type="button" mat-button (click)="site.sub_sssp ? onManageSSSP(site.sub_sssp.sssp_hash) : onCreateSSSP(site.id)" [matTooltip]="(site.sub_sssp ? 'Manage the' : 'Create a') + ' Safety Plan.'" matTooltipPosition="above">
            {{ site.sub_sssp ? 'Manage' : 'Create' }} Safety Plan
          </button>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
