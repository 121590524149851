import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { ApiRequestService } from '../shared/api-request.service';
import { UtilsService } from '../shared/utils.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountModel } from '../models/account.model';
import { GlobalAccountsSelectorComponent } from '../shared/global-accounts-selector/global-accounts-selector.component';

@Component({
  selector: 'app-default-account',
  templateUrl: './default-account.component.html',
  styleUrls: ['./default-account.component.scss']
})
export class DefaultAccountComponent implements OnInit {
  /**
   * Is the default account currently being set?
   */
  inProgress = false;

  /**
   * The default account for the authenticated user.
   */
  defaultAccount = new AccountModel();

  // Used to determine if the user already triggered an account update or not.
  alreadyUpdated: boolean = false;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService,
    private dialogRef: MatDialogRef<DefaultAccountComponent>
  ) {
    // Disable external close options.
    dialogRef.disableClose = true;
  }

  public ngOnInit(): void {
    if ( !this.app.user.account_id ) {
      return;
    }
    // Get the user's default account.
    this.getDefaultAccount(this.app.user.account_id);
  }

  /**
   * Close the dialog.
   */
  public onClose(): void {
    // Check if the user already updated their account.
    if ( this.alreadyUpdated ) {
      // Close the dialog.
      this.dialogRef.close();
      return;
    }

    this.inProgress = true;
    // Send a request to update the user's default account.
    this.api.makeRequest('put', 'v2/user/employer', {
      account_id: this.defaultAccount.id,
      search: null
    })
      .then((response) => {
        // Close the dialog.
        this.dialogRef.close();
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      }).finally(() => (this.inProgress = false));
  }

  /**
   * Open a dialog to allow the user to select or enter an employer name.
   */
  public onSelectAccount(): void {
    this.utils.showComponentDialog(
      GlobalAccountsSelectorComponent,
      {
        selected: [this.defaultAccount.id],
        multiple: false,
        search: this.defaultAccount.name
      },
      {
        width: '1024px'
      },
      (response: number|string) => {
        // Terminate the request if the dialog was closed with selecting or entering a value.
        if ( typeof response == 'undefined' ) {
          return;
        }
        // Do final confirmation.
        this.utils.showModal('Confirmation Required', 'Are you sure you want to change your employer?', () => {
          // Make a request to update the user's employer.
          this.api.makeRequest('put', 'v2/user/employer', {
            account_id: typeof response === 'number' ? response : null,
            search: typeof response !== 'number' ? response : null
          })
            .then((subResponse) => {
              this.utils.showToast('Your employer was successfully changed.');
              this.alreadyUpdated = true;
              this.getDefaultAccount(subResponse.account.id);
            })
            .catch((errorResponse) => {
              this.utils.handleAPIErrors(errorResponse);
            });
        });
      }
    );
  }

  /**
   * Get the user's default account.
   * @param accountId
   * @private
   */
  private getDefaultAccount(accountId: number) {
    return this.api.makeRequest('get', `v2/accounts/${accountId}`).then(
      (account: AccountModel) => {
        // Store the default account details.
        this.defaultAccount.apply(account);
        // Set the new account id.
        this.app.user.account_id = this.defaultAccount.id;
      }
    );
  }
}
