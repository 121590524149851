<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-6">

      <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
        <div class="card">
          <div class="card-body form-container">
            <h5 class="card-title">{{ user.id ? 'Update' : 'Add' }} User</h5>

            <mat-form-field class="full-width mb-3">
              <mat-label>Full Name</mat-label>
              <input matInput placeholder="Full Name" [(ngModel)]="user.contact_person" name="contact_person" type="text" #contact_person required />
              <mat-hint>
                Enter the first and last name of the user.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3" *ngIf="!user.id">
              <mat-label>Email Address</mat-label>
              <input matInput placeholder="Email Address" [(ngModel)]="user.email" name="email" type="email" #email email required />
              <mat-hint>
                Enter a valid email address for the user.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <mat-label> {{ utils.getLangTerm('users.form.mobile', 'Mobile Number') }}</mat-label>
              <app-phone-input
                [idInput]="phone_input_id"
                [numberInput]="user.mobile"
                [countryCodeInput]="user.mobile_country_code"
                [dialCodeInput]="user.mobile_dial_code"
                [e164NumberInput]="user.mobile_e164"
                (phoneDetailsOutput)="getOutputPhoneDetails($event)"
                required="true">
              </app-phone-input>
              <mat-hint>
                Enter a {{ utils.getLangTerm('users.form.mobile', 'Mobile Number') }} for the user.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <mat-label>Address</mat-label>
              <input matInput placeholder="Address" [(ngModel)]="user.address" name="address" type="text" #address />
              <mat-hint>
                Enter the address for the user.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <mat-label> {{ utils.getLangTerm('users.form.suburb', 'Suburb') }} </mat-label>
              <input matInput [placeholder]="utils.getLangTerm('users.form.suburb', 'Suburb')" [(ngModel)]="user.suburb" name="suburb" type="text" #suburb />
              <mat-hint>
                Enter the {{ utils.getLangTerm('users.form.suburb', 'Suburb') }} for the user.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <mat-label> {{ utils.getLangTerm('users.form.city', 'City') }} </mat-label>
              <input matInput [placeholder]="utils.getLangTerm('users.form.city', 'City')" [(ngModel)]="user.city" name="city" type="text" #city />
              <mat-hint>
                Enter the {{ utils.getLangTerm('users.form.city', 'City') }} for the user.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <mat-label> {{ utils.getLangTerm('users.form.postal_code', 'Postal Code') }} </mat-label>
              <input matInput [placeholder]="utils.getLangTerm('users.form.postal_code', 'Postal Code')" [(ngModel)]="user.postal_code" name="postal_code" type="text" #postal_code />
              <mat-hint>
                Enter the {{ utils.getLangTerm('users.form.postal_code', 'Postal Code') }} for the user.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <mat-label>Country</mat-label>
              <input matInput placeholder="Country" [(ngModel)]="user.country" name="country" type="text" #country />
              <mat-hint>
                Enter the country for the user.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <mat-label>Job Title</mat-label>
              <mat-select [(ngModel)]="user.trade" name="trade" #trade>
                <mat-option *ngFor="let trade of trades" [value]="trade.name">{{ trade.name }}</mat-option>
              </mat-select>
              <mat-hint>
                Select the user's job title.
              </mat-hint>
            </mat-form-field>

            <div class="d-flex justify-content-end">
              <button type="button" mat-raised-button color="secondary" (click)="onSelectEmployer()" appMarginRight>
                Selected Employer * <span *ngIf="user.employer">({{ user.employer.name }})</span>
              </button>

              <button type="submit" mat-raised-button [disabled]="!f.valid || !user.account_id || inProgress || phone_error_state" color="primary">
                <mat-icon>save</mat-icon>
                Save
              </button>
            </div>
          </div>
        </div>
      </form>

      <form #f2="ngForm" class="form-container" appMarginTop (ngSubmit)="onUpdatePassword(f2)" *ngIf="user.id">
        <div class="card">
          <div class="card-body form-container">
            <h5 class="card-title">Login Credentials</h5>

            <mat-form-field class="full-width mb-3">
              <mat-label>Email Address</mat-label>
              <input matInput [(ngModel)]="user.email" name="e" type="email" email required autocomplete="off" data-lpignore="true" />
              <mat-hint>
                Enter a valid email address for the user.
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width mb-3">
              <mat-label>Username</mat-label>
              <input matInput [(ngModel)]="user.username" name="u" type="text" required autocomplete="off" data-lpignore="true" />
              <mat-hint>
                Enter a username for the user.
              </mat-hint>
            </mat-form-field>

            <ng-container>
              <mat-checkbox name="showPasswordInputs" [(ngModel)]="showPasswordInputs">Update the user's password.</mat-checkbox>
              <mat-hint>
                <small>Tick this box if you want to update the user's password.</small>
              </mat-hint>
            </ng-container>

            <ng-container *ngIf="showPasswordInputs">
                <mat-form-field class="full-width mb-3">
                  <mat-label>Password</mat-label>
                  <input matInput [(ngModel)]="user.password" name="password" [type]="passwordFieldType" [required]="user.password != ''" autocomplete="new-password" data-lpignore="true" />
                  <button mat-icon-button matSuffix aria-label="Show/Hide" (click)="togglePasswordVisibility('password')" type="button">
                    <mat-icon>{{ passwordFieldType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
                  </button>
                  <button *ngIf="user.password" matSuffix mat-icon-button aria-label="Clear" (click)="user.password = ''">
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-hint>
                    Enter a new user password for the user. Leave it empty if you don't want to change the password.
                  </mat-hint>
                </mat-form-field>

              <ng-container *ngIf="user.password != ''">

                <div class="alert alert-warning" role="alert">
                  <small>
                    If the user's password changes, the user will be required to login again.
                  </small>
                </div>

                <app-password-strength-indicator [passwordInput]="user.password" (passwordValidatedOutput)="getOutputPasswordValidated($event)"></app-password-strength-indicator>

                <mat-form-field class="full-width mb-3">
                  <mat-label>Password Confirmation</mat-label>
                  <input matInput [(ngModel)]="user.password_confirmation" name="password_confirmation" [type]="confirmPasswordFieldType" [required]="user.password != ''" autocomplete="new-password" data-lpignore="true" />
                  <button mat-icon-button matSuffix aria-label="Show/Hide" (click)="togglePasswordVisibility('confirmPassword')" type="button">
                    <mat-icon>{{ confirmPasswordFieldType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
                  </button>
                  <mat-hint>
                    Confirm the new password.
                  </mat-hint>
                </mat-form-field>
              </ng-container>
            </ng-container>



            <div class="d-flex justify-content-end">
              <button type="submit" mat-raised-button [disabled]="!f2.valid || inProgress || (user.password != '' && (user.password_confirmation != user.password || !passwordValidated))" color="primary">
                Update
              </button>
            </div>
          </div>
        </div>
      </form>

    </div>

    <div class="col-6">

      <ng-container *ngIf="user.id">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle Navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <span class="mr-auto">
              <button type="button" mat-raised-button color="secondary" (click)="onSelectAccounts()" appMarginRight>
                Select Accounts
              </button>
            </span>

            <form class="form-inline my-2 my-lg-0" (ngSubmit)="accountsDataSource.getData(true)">
              <input class="form-control mr-sm-2" type="search" [(ngModel)]="accountsDataSource.search" name="search"
                placeholder="Search" aria-label="Search" />
            </form>

            <button type="button" mat-raised-button color="secondary" (click)="accountsDataSource.getData(true)" appMarginRight>
              <mat-icon>filter_list</mat-icon> Search
            </button>
          </div>
        </nav>

        <div class="scroll-box">

          <table
            mat-table
            [dataSource]="accountsDataSource"
            matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

            <ng-container matColumnDef="account_id">
              <th mat-header-cell mat-sort-header *matHeaderCellDef   matTooltip="Sort by #">
                #
              </th>
              <td mat-cell *matCellDef="let account">
                {{ account.account_id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell mat-sort-header *matHeaderCellDef   matTooltip="Sort by Name">
                Name
              </th>
              <td mat-cell *matCellDef="let account">
                <a (click)="onRedirectToAccount(account.account_id)">{{ account.name }}</a>
              </td>
            </ng-container>

            <ng-container matColumnDef="subscription">
              <th mat-header-cell mat-sort-header *matHeaderCellDef   matTooltip="Sort by Subscription">
                Subscription
              </th>
              <td mat-cell *matCellDef="let account">
                {{ account.subscription }}
              </td>
            </ng-container>

            <ng-container matColumnDef="date_created">
              <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Sort by Date Joined">
                Date Joined
              </th>
              <td mat-cell *matCellDef="let account">
                {{ (account.date_created * 1000) | timezoneConvertShort }} <br>
                {{ (account.date_created * 1000) | timezoneConvertUTCShort }}
              </td>
            </ng-container>

            <ng-container matColumnDef="permissions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
              <td mat-cell *matCellDef="let account">
                <mat-form-field>
                  <mat-select (selectionChange)="onUpdateUserPermissions(account)" [(ngModel)]="account.permissions" [ngModelOptions]="{ standalone: true }">
                    <mat-option *ngFor="let role of utils.getUserRoles()" [value]="role">{{ role }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let account">
                <button type="button" mat-button (click)="onDestroyUserAccount(account, $event)">
                  Delete
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="accountsDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: accountsDisplayedColumns"></tr>
          </table>

          <mat-paginator
            [length]="accountsDataSource?.total | async"
            [pageSize]="accountsDataSource.limit"
            [pageSizeOptions]="accountsDataSource.pageSizeOptions"
            showFirstLastButtons>
          </mat-paginator>
        </div>
      </ng-container>

    </div>

  </div>
</div>
