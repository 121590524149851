import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class UserMessagesService {

  unread_messages_count: number = 0;

  constructor(
    private app: AppService,
    private api: ApiRequestService
  ) { }

  getMessagesMeta() {
    this.api.makeRequest('get', 'v2/user/messages/meta')
    .then((response: {
      unread_messages_count: number
    }) => {
      this.unread_messages_count = response.unread_messages_count
    });
  }
}


