import { Auditable } from "../shared/auditable.model";
import { SiteModel } from "./site.model";

export class SsspModel extends Auditable {

  id = 0;
  account_id = 0;
  site_id = 0;
  contractor_id = 0;
  sssp_hash: string = '';

  include_site_hazards: boolean = false;
  include_site_hazardous_substances: boolean = false;
  include_site_task_analysis: boolean = false;
  include_site_toolbox_talks: boolean = false;
  include_site_documents: boolean = false;
  include_site_noticeboard: boolean = false;
  include_site_contractors: boolean = false;
  include_site_managers: boolean = false;
  include_site_emergency_evacuation_plan: boolean = false;
  include_site_qrcodes: boolean = false;
  include_site_contractor_sssps: boolean = false;
  include_editors: boolean = false;
  include_site_audits: boolean = false;
  include_site_incidents: boolean = false;
  include_site_safety_observations: boolean = false;
  include_site_task_analyses_revised: boolean = false;
  short_description: string = '';
  location_of_work: string = '';

  site: SiteModel = new SiteModel();

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          switch ( key ) {
            case 'site':
              this[key].apply(data[key]);
              break;
            default:
              this[key] = data[key];
          }
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.account_id = 0;
    this.site_id = 0;
    this.contractor_id = 0;
    this.sssp_hash = '';
    this.include_site_hazards = false;
    this.include_site_hazardous_substances = false;
    this.include_site_task_analysis = false;
    this.include_site_toolbox_talks = false;
    this.include_site_documents = false;
    this.include_site_noticeboard = false;
    this.include_site_contractors = false;
    this.include_site_managers = false;
    this.include_site_emergency_evacuation_plan = false;
    this.include_site_qrcodes = false;
    this.include_site_contractor_sssps = false;
    this.include_editors = false;
    this.include_site_audits = false;
    this.include_site_incidents = false;
    this.include_site_safety_observations = false;
    this.include_site_task_analyses_revised = false;
    this.short_description = '';
    this.location_of_work = '';
  }
}
