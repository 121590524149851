import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { UtilsService } from '../../shared/utils.service';

@Component({
  selector: 'app-erps',
  templateUrl: './erps.component.html',
  styleUrls: ['./erps.component.css']
})
export class ErpsComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = ['select', 'name', 'actions'];
  dataSource = new ERPDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    // public service: HazardsService,
    public utils: UtilsService,
    public app: AppService,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove ERP',
      'Are you sure you want to remove this emergency respopnse plan?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'erps/' + id,
          {},
          {},
          (response) => {
            this.utils.showToast('The emergency response plan was removed.');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Remove Selected ERPs',
      'Are you sure you want to remove the selected emergency response plans?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'erps/' + this.dataSource.selection.selected.join(','),
          {},
          {},
          (response) => {
            this.utils.showToast(
              'The selected emergency response plans was removed.'
            );
            this.dataSource.selection.clear();
            this.dataSource.getData();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  onExportSelected(type: string = 'pdf') {
    // make a request to download a document
    this.api.laravelApiDownloadRequest(
      'erps/export/' +
        type +
        '/' +
        this.dataSource.selection.selected.join(','),
      {},
      (response) => {
        // handle internet explorer
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // get current date object
        const date = new Date();

        // handle other browsers
        const data = window.URL.createObjectURL(response);
        // create a download link
        const link = document.createElement('a');
        link.href = data;
        link.download =
          'hazards-risks-' +
          date.getFullYear() +
          (date.getMonth() + 1) +
          date.getDate() +
          '.' +
          type;
        link.click(); // initiate the download

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(function() {
          window.URL.revokeObjectURL(data);
        }, 100);
      },
      (error) => {
        this.utils.showModal('Error', error.message);
      }
    );
  }
}

export class ERPDataSource extends CustomDataSource {
  // record sorting and direction
  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('erps', resetOffset);
  }
}
