import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import {AccountModel} from "../../../models/account.model";

@Component({
  selector: 'app-multi-account-selector',
  templateUrl: './multi-account-selector.component.html',
  styleUrls: ['./multi-account-selector.component.scss']
})
export class MultiAccountSelectorComponent implements OnInit {

  listColumns: string[] = [
    'name'
  ];
  dataSource: MultiAccountSelectorDataSource = new MultiAccountSelectorDataSource(this.app, this.api, this.data['multiple'] || false);

  // Define the paginator and sorter.
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private api: ApiRequestService,
    private app: AppService,
    public utils: UtilsService,
    public dialogRef: MatDialogRef<MultiAccountSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      selected?: number[],
      multiple?: boolean,
      return_object?: boolean // Only works when multiple is false.
    }
  ) { }

  ngOnInit(): void {
    // Set a default selection of nothing if no selected values are passed through.
    if ( typeof this.data['selected'] == 'undefined' ) {
      this.data['selected'] = [];
    }

    // Loop and select the values.
    for ( let i: number = 0; i < this.data['selected'].length; i++ ) {
      if ( this.data['selected'][i] ) {
        this.dataSource.selection.select(this.data['selected'][i]);
      }
    }

    // Add the select column if multiple is enabled.
    if ( (typeof this.data['multiple'] != 'undefined' && this.data['multiple'] == true) || this.dataSource.multiple ) {
      this.listColumns.unshift('select');
    } else {
      // Alternatively add the actions column to allow single site selection.
      this.listColumns.push('actions');
    }

    // Get the data from the datasource.
    this.dataSource.getData();
  }

  ngAfterViewInit(): void {
    // Reset the paginator when sorting takes place.
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData();
    })).subscribe();
  }

  /**
   * Determine if the site id or object data should be sent as a response.
   *
   * @param {AccountModel} account - The account data to use.
   * @param {Event} event - The event triggered.
   *
   * @returns {void}
   */
  onSelectAccount(account: AccountModel, event: Event): void {
    // Prevent the default events and propagation from triggering.
    event.preventDefault();
    event.stopPropagation();

    // Check if the account object should be returned.
    if ( (typeof this.data['return_object'] != 'undefined' && this.data['return_object'] == true) ) {
      // Return the account object.
      this.dialogRef.close(account);
    } else {
      // Return the account id.
      this.dialogRef.close(account.id);
    }
  }

  /**
   * Returns the selected account ids.
   *
   * @returns {void}
   */
  onSelectAndClose(): void {
    // Return the selected account ids.
    this.dialogRef.close(this.dataSource.selection.selected);
  }

  /**
   * Closes the dialog and clears the selected accounts.
   *
   * @returns {void}
   */
  onClearAndClose(): void {
    // Close the dialog with no accounts selected.
    this.dialogRef.close([]);
  }
}

export class MultiAccountSelectorDataSource extends ApiDataSource {

  order_by: string = 'name';
  order: string = 'asc';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/user/accessible-accounts', resetOffset, {
      order: this.order,
      order_by: this.order_by,
      list: 'admin'
    });
  }
}
