<div class="card">
  <div class="card-body">
    <h5 class="card-title">Update Login Credentials</h5>
    <form (submit)="onUpdateLoginCredentials(f)" #f="ngForm">
      <p>
        <mat-form-field class="full-width">
          <mat-label>Email Address</mat-label>
          <input matInput placeholder="Email Address" [(ngModel)]="email" name="email" type="email" email required />
          <mat-hint>Enter your email address.</mat-hint>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field class="full-width">
          <mat-label>Username</mat-label>
          <input
            matInput
            placeholder="Username"
            required
            type="text"
            [(ngModel)]="username"
            name="username"/>
          <mat-hint>Enter your username.</mat-hint>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field class="full-width">
          <mat-label>New Password</mat-label>
          <input
            matInput
            placeholder="New Password"
            [(ngModel)]="password"
            name="password"
            [type]="passwordFieldType"/>
          <button mat-icon-button matSuffix aria-label="Show/Hide" (click)="togglePasswordVisibility('password')" type="button">
            <mat-icon>{{ passwordFieldType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
          </button>
          <mat-hint>Enter your new password. Leave empty if you are not changing your password.</mat-hint>
        </mat-form-field>
      </p>

      <app-password-strength-indicator [passwordInput]="password" (passwordValidatedOutput)="getOutputPasswordValidated($event)"></app-password-strength-indicator>

      <p *ngIf="password.length > 0">
        <mat-form-field class="full-width">
          <mat-label>New Password Confirmation</mat-label>
          <input
            matInput
            placeholder="New Password Confirmation"
            [required]="password.length > 0"
            [(ngModel)]="passwordConfirmation"
            name="password_confirmation"
            [type]="confirmPasswordFieldType"/>
          <button mat-icon-button matSuffix aria-label="Show/Hide" (click)="togglePasswordVisibility('confirmPassword')" type="button">
            <mat-icon>{{ confirmPasswordFieldType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
          </button>
          <mat-hint>Enter your new password again for confirmation.</mat-hint>
        </mat-form-field>
      </p>

      <div class="button-row">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!f.valid || (password && (password !== passwordConfirmation ||  !passwordValidated)) || inProgress">
          <mat-icon>save</mat-icon>
          Update Login Credentials
        </button>

        <button
          mat-raised-button
          color="secondary"
          type="button"
          (click)="onResetPassword()">
          <mat-icon>restore</mat-icon>
          Reset Password
        </button>
      </div>

    </form>
  </div>
</div>
