import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppService } from 'src/app/app.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-inductions',
  templateUrl: './inductions.component.html',
  styleUrls: ['./inductions.component.scss']
})
export class InductionsComponent implements OnInit {

  displayedColumns: string[] = [
    'select',
    'id',
    'title',
    'published',
    'actions'
  ];

  dataSource = new InductionsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData();
    });

    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();

  }

  onDeleteSelected() {}

}

export class InductionsDataSource extends ApiDataSource {

  order_by = 'id';
  order = 'asc';

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/inductions', resetOffset);
  }
}
