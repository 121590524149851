<div #chart_ref style="height: 200px;"></div>

<div class="row mt-2">
  <div class="col">
    <sub *ngIf="!filters.date_range || filters.date_range.length == 0 else date_range">A maximum of 3 years of data is displayed.</sub>
    <ng-template #date_range>
      <sub>{{ chartsUtils.getFormattedDateRange(filters.date_range) }}</sub>
    </ng-template>
  </div>
  <div class="col">
    <div class="d-flex justify-content-end">
      <mat-checkbox id="default_show_onsite_only" name="default_show_onsite_only" [(ngModel)]="default_show_onsite_only" (change)="onDefaultShowOnsiteOnlyChanged()" matTooltip="When enabled, only current onsite records will be shown.">
        Show Onsite Only
      </mat-checkbox>
    </div>
  </div>
</div>
