import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';
import { AccountModel } from './models/account.model';
import { UserAccountLinkModel } from './models/user-account-link.model';
import { UserModel } from './models/user.model';

/**
 * The app service class is used to store global data for the app in use.
 */
@Injectable({
  providedIn: 'root'
})
export class AppService {
  // the user's authentication status
  isAuthenticated = false;

  // the user's default account
  account: AccountModel = new AccountModel();

  // the user's own data
  user: UserModel = new UserModel();

  // user account link model used for permissions
  userAccountLink: UserAccountLinkModel = new UserAccountLinkModel();

  // todo remove this when all dependencies on it are removed
  activeSiteId = 0;

  // a global date range filter for reporting
  date: Date = new Date();
  filter_date_range: Date[] = [
    new Date(new Date().setDate(new Date().getDate() - 30)),
    new Date()
  ]; // this.date.getFullYear(), this.date.getMonth() + 1, 0

  // used to view stats for all sites or selected sites. empty array means all sites.
  chart_selected_sites: number[] = [];

  constructor() {}

  userCan(feature: string) {
    let can = false;

    let features: any = [];

    // the free subscription does not include any additional features
    const free: any = [
      'manage_employees',
      'manage_contractors',
      'manage_tasks'
    ];

    // the basic subscription is targeted towards access control and contractor compliance
    const basic: any = [
      'manage_sites',
      'manage_visitors',
      'manage_qrcodes',
      'manage_inductions',
      'manage_documents',
      'view_access_records',
      'view_onsite_records',
      'view_time_onsite_records',
      'view_pandemic_records',
      'manage_prequalifications',
      'manage_slave_accounts'
    ];

    // the advance subscription is targetted towards more reporting
    const advanced: any = [
      'manage_hazards',
      'manage_incidents',
      'manage_hazardous_substances',
      'manage_erps',
      'manage_toolbox_talks',
      'manage_audits',
      'manage_safety_observations',
      'manage_tools',
      'manage_dynamic_forms'
    ];

    // the premium subscription is targetted towards premium support and analytics
    const premium: any = ['manage_sssp', 'manage_task_analysis'];

    switch (this.account.subscription) {
      case 'Free':
        features = free;
        break;
      case 'Basic':
        features = free.concat(basic);
        break;
      case 'Advanced':
        features = free.concat(basic).concat(advanced);
        break;
      case 'Premium':
        features = free
          .concat(basic)
          .concat(advanced)
          .concat(premium);
        break;
      default:
        features = free;
    }

    can = features.indexOf(feature) > -1;

    return can;
  }

  getSubscriptions() {
    return [
      'Free',
      'Basic',
      'Advanced',
      'Premium'
      // 'Basic',
      // 'Essential',
      // 'Advanced',
      // 'Professional',
      // 'Enterprise'
    ];
  }
}
