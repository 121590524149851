import { Auditable } from "../shared/auditable.model";
import { UserAccountLinkModel } from "./user-account-link.model";

export class EmployeeModel extends Auditable {

  // Fillable
  contact_person: string = '';
  email: string = '';
  mobile: string = '';
  entity_name: string = '';
  address: string = '';
  suburb: string = '';
  city: string = '';
  postal_code: string = '';
  country: string = '';
  name: string = '';
  trade: string = '';
  iqp_number: string = '';

  /**
   * Mobile Country Code fields.
   */
  mobile_e164: string = '';
  mobile_country_code: string = '';
  mobile_dial_code: string = '';

  // Guarded
  id: number = 0;
  username: string = '';
  account_id: number = 0;
  device_id: string = '';
  lat: string = '';
  lng: string = '';
  date_created: number = 0;
  date_modified: number = 0;
  profile_pic: string = '';
  created_by: number = 0;
  updated_by: number = 0;
  account_id_updated_at: number = 0;
  version: string = '';
  allow_sms_notifications: boolean = false;
  allow_push_notifications: boolean = false;
  allow_email_notifications: boolean = false;

  // Intermediate Table Data
  pivot: UserAccountLinkModel = new UserAccountLinkModel();

  hash: string = '';

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          switch ( key ) {
            case 'pivot':
              this[key].apply(data[key]);
              break;
            default:
              this[key] = data[key];
          }
        }
      });
    }
    return this;
  }

  reset() {
    this.contact_person = '';
    this.email = '';
    this.mobile = '';
    this.mobile_e164 = '';
    this.mobile_country_code = '';
    this.mobile_dial_code = '';
    this.entity_name = '';
    this.address = '';
    this.suburb = '';
    this.city = '';
    this.postal_code = '';
    this.country = '';
    this.name = '';
    this.trade = '';
    this.iqp_number = '';

    this.id = 0;
    this.account_id = 0;
    this.device_id = '';
    this.lat = '';
    this.lng = '';
    this.date_created = 0;
    this.date_modified = 0;
    this.profile_pic = '';
    this.created_by = 0;
    this.updated_by = 0;
    this.account_id_updated_at = 0;
    this.version = '';
    this.allow_sms_notifications = false;
    this.allow_push_notifications = false;
    this.allow_email_notifications = false;

    this.pivot.reset();
  }
}
