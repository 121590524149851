import { Component, OnInit, Inject } from '@angular/core';
import { IncidentCauseModel } from '../../incident-cause.model';
import { IncidentCauseService } from '../../incident-cause.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UtilsService } from 'src/app/shared/utils.service';
import { ActionModel } from 'src/app/actions/action.model';
import { ActionsService } from 'src/app/actions/actions.service';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { FileUploaderService } from 'src/app/shared/file-uploader.service';
import { merge } from 'rxjs';
import { FilesRemoveComponent } from 'src/app/files/files-remove/files-remove.component';

@Component({
  selector: 'app-incidents-causes-edit',
  templateUrl: './incidents-causes-edit.component.html',
  styleUrls: ['./incidents-causes-edit.component.scss']
})
export class IncidentsCausesEditComponent implements OnInit {
  actionFiles: { [id: number]: FileList } = {};
  inProgress = false;

  constructor(
    private incidentCauseService: IncidentCauseService,
    @Inject(MAT_DIALOG_DATA) public incidentCause: IncidentCauseModel,
    private utils: UtilsService,
    private dialogRef: MatDialogRef<IncidentsCausesEditComponent>,
    private actionService: ActionsService,
    private fileUploaderService: FileUploaderService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (!this.incidentCause.id) {
      return (this.incidentCause.actions = [{} as ActionModel]);
    }
    this.incidentCauseService
      .getActions(this.incidentCause.id)
      .subscribe((response) => {
        this.incidentCause.actions = response.data;
      });
  }

  invalidActions() {
    // Incident causes must have actions.
    if (!this.incidentCause.actions || !this.incidentCause.actions.length) {
      return true;
    }

    // All actions must have assigned users.
    for (let i = 0; i < this.incidentCause.actions.length; i++) {
      if (
        !this.incidentCause.actions[i].user_ids ||
        !this.incidentCause.actions[i].user_ids.length
      ) {
        return true;
      }
    }

    return false;
  }

  onSelectUsers(index: number, action: ActionModel) {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: true,
        selected: action.user_ids,
        visitors_from_all_sites: true
      },
      {},
      (results) => {
        if (typeof results !== 'undefined') {
          this.incidentCause.actions[index].user_ids = results;
        }
      }
    );
  }

  onRemoveAction(index: number, action: ActionModel) {
    this.utils.showModal(
      'Remove Corrective Action',
      'Are you sure you want to remove the corrective action?',
      () => {
        this.removeAction(index, action);
    });
  }

  removeAction(index: number, action: ActionModel) {
    if ( action.id ) {
      this.actionService.remove([action.id]).subscribe(() => {
        this.utils.showToast('The corrective action was removed.');
      });
    }
    this.incidentCause.actions.splice(index, 1);
  }

  onAddAction() {
    this.incidentCause.actions.push({} as ActionModel);
  }

  onCanSubmit() {
    // All causes should have actions.
    if (
      typeof this.incidentCause.actions === 'undefined' ||
      this.incidentCause.actions.length === 0
    ) {
      return false;
    }
    // All actions must be assigned to users.
    let canSubmit = true;
    this.incidentCause.actions.forEach((action) => {
      if (
        typeof action.user_ids === 'undefined' ||
        action.user_ids.length === 0
      ) {
        canSubmit = false;
      }
    });
    return canSubmit;
  }

  onSubmit() {
    if (!this.onCanSubmit()) {
      this.utils.showModal(
        'Validation Failed',
        'Please assign all corrective actions to users by clicking the <b>person</b> icon next to each corrective action.'
      );
      return;
    }

    if (this.inProgress) {
      return;
    }

    this.inProgress = true;

    this.incidentCause.id ? this.update() : this.create();
  }

  update() {
    this.incidentCauseService.update(this.incidentCause).subscribe(
      (response) => {
        this.utils.showToast('Updated incident cause');

        this.uploadActionFiles(response.data.actions).subscribe(
          () => {
            this.utils.showToast('Uploaded files');
            this.actionFiles = {};
            this.ngOnInit();
          },
          (_error) => {},
          () => {
            this.inProgress = false
          }
        );
      },
      (_error: any) => (this.inProgress = false)
    );
  }

  create() {
    this.incidentCauseService.create(this.incidentCause).subscribe(
      (response) => {
        this.utils.showToast('Created incident cause, now uploading files...');
        this.dialogRef.close(true);

        this.uploadActionFiles(response.data.actions).subscribe(
          () => {
            this.utils.showToast('Uploaded files');
            this.dialogRef.close(true);
            this.inProgress = false;
          },
          (_error: any) => (this.inProgress = false)
        );
      },
      (_error: any) => (this.inProgress = false)
    );
  }

  private uploadActionFiles(actions: ActionModel[]) {
    const requests = [];
    actions.forEach((action) => {
      const files = this.actionFiles[action.id];
      if (!files) { return; }
      requests.push(
        this.fileUploaderService.storeUploadedFiles(files, 'action', action.id)
      );
    });
    return merge(...requests);
  }

  onDelete() {
    this.utils.showModal(
      'Remove Incident',
      'Are you sure you want to remove this incident?',
      () => {
        this.incidentCauseService
          .remove([this.incidentCause.id])
          .subscribe(() => {
            this.utils.showToast('Removed Cause.');
            this.dialogRef.close(true);
          });
      }
    );
  }

  onRemoveFiles(index: number) {
    const dialogRef = this.dialog.open(FilesRemoveComponent, {
      data: this.incidentCause.actions[index].files,
      width: '700px'
    });
    dialogRef.afterClosed().subscribe((response) => {
      this.incidentCause.actions[index].files = response;
    });
  }
}
