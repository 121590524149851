import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-password-strength-indicator',
  templateUrl: './password-strength-indicator.component.html',
  styleUrls: ['./password-strength-indicator.component.scss']
})
export class PasswordStrengthIndicatorComponent implements OnInit, OnChanges {

  public readonly minCharacters: number = 8;
  public readonly maxCharacters: number = 32;
  public readonly specialCharactersAllowed: boolean = true;

  regexForLowerLetter: RegExp = /[a-z]/;
  regexForUpperLetter: RegExp = /[A-Z]/;
  regexForNumber: RegExp = /\d/;
  regexForSpecialCharacter: RegExp = /[-+_!@#$%^&*.,?{}()\[\]]/
  passwordStrength: number = 0;

  lengthScore: number = 0;
  goodLengthScore: number = 0;
  lowerLetterScore: number = 0;
  upperLetterScore: number = 0;
  numberScore: number = 0;
  specialCharacterScore: number = 0;

  isLengthValid: boolean = false;
  containsLowerCaseLetter: boolean = false;
  containsUpperCaseLetter: boolean = false;
  containsNumber: boolean = false;
  containsSpecialCharacter: boolean = false;


  poorPasswordClass: string = 'password-strength-background';
  averagePasswordClass: string = 'password-strength-background';
  goodPasswordClass: string = 'password-strength-background';
  veryGoodPasswordClass: string = 'password-strength-background';

  password: string;
  specialCharacters: string = '-+_!@#$%^&*.,?{}()[]'

  @Input() passwordInput: string;
  @Output() passwordValidatedOutput: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    if(this.passwordInput) {
      this.password = this.passwordInput;
    } else {
      this.password = '';
    }
    this.checkPasswordStrength(this.password);
  }

  ngOnChanges() {
    if(this.passwordInput && this.password !== this.passwordInput) {
      this.password = this.passwordInput;
      this.checkPasswordStrength(this.password);
    } else if (!this.passwordInput) {
      this.password = '';
      this.checkPasswordStrength(this.password);
    }
  }

  checkPasswordStrength(password: string) {

    if (password.length >= this.minCharacters && password.length <= this.maxCharacters) {
      this.lengthScore = 1;
      this.isLengthValid = true;
    } else {
      this.lengthScore = 0;
      this.isLengthValid = false;
    }

    if (password.match(this.regexForLowerLetter)) {
      this.lowerLetterScore = 1;
      this.containsLowerCaseLetter = true;
    } else {
      this.lowerLetterScore = 0;
      this.containsLowerCaseLetter = false;
    }

    if (password.match(this.regexForUpperLetter)) {
      this.upperLetterScore = 1;
      this.containsUpperCaseLetter = true;
    } else {
      this.upperLetterScore = 0;
      this.containsUpperCaseLetter = false;
    }

    if (password.match(this.regexForNumber)) {
      this.numberScore = 1;
      this.containsNumber = true;
    } else {
      this.numberScore = 0;
      this.containsNumber = false;
    }

    if (password.match(this.regexForSpecialCharacter) || !this.specialCharactersAllowed) {
      this.specialCharacterScore = 1;
      this.containsSpecialCharacter = true;
    } else {
      this.specialCharacterScore = 0;
      this.containsSpecialCharacter = false;
    }

    if (password.length >= this.minCharacters && password.length <= this.maxCharacters) {
      this.goodLengthScore = 1;
    } else {
      this.goodLengthScore = 0;
    }

    this.passwordStrength = this.lengthScore + this.lowerLetterScore + this.upperLetterScore
      + this.numberScore + this.specialCharacterScore + this.goodLengthScore;

    if (this.passwordStrength < 3) {
      this.poorPasswordClass = 'password-strength-background';
      this.averagePasswordClass = 'password-strength-background';
      this.goodPasswordClass = 'password-strength-background';
      this.veryGoodPasswordClass = 'password-strength-background';

      this.passwordValidatedOutput.emit(false);
    } else if (this.passwordStrength === 3) {
      this.poorPasswordClass = 'weak-password';
      this.averagePasswordClass = 'password-strength-background';
      this.goodPasswordClass = 'password-strength-background';
      this.veryGoodPasswordClass = 'password-strength-background';

      this.passwordValidatedOutput.emit(false);
    } else if (this.passwordStrength === 4) {
      this.poorPasswordClass = 'average-password';
      this.averagePasswordClass = 'average-password';
      this.goodPasswordClass = 'password-strength-background';
      this.veryGoodPasswordClass = 'password-strength-background';

      this.passwordValidatedOutput.emit(false);
    } else if (this.passwordStrength === 5) {
      this.poorPasswordClass = 'good-password';
      this.averagePasswordClass = 'good-password';
      this.goodPasswordClass = 'good-password';
      this.veryGoodPasswordClass = 'password-strength-background';

      this.passwordValidatedOutput.emit(false);
    } else if (this.passwordStrength === 6) {
      this.poorPasswordClass = 'verygood-password';
      this.averagePasswordClass = 'verygood-password';
      this.goodPasswordClass = 'verygood-password';
      this.veryGoodPasswordClass = 'verygood-password';

      this.passwordValidatedOutput.emit(true);
    }

  }

  getAlertClass(status: boolean) {
    if (status) {
      return "alert alert-success d-flex align-items-center small p-1"
    } else {
      return "alert alert-danger d-flex align-items-center small p-1"
    }
  }

}
