import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMarginLeft]'
})
export class MarginLeftDirective {
  constructor(element: ElementRef) {
    element.nativeElement.style.marginLeft = '16px';
  }
}
