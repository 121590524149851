import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { IncidentLtiModel } from 'src/app/models/incident-lti.model';
import { IncidentModel } from 'src/app/models/incident.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import * as moment from 'moment';
import { NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { UserModel } from 'src/app/models/user.model';

@Component({
  selector: 'app-incidents-ltis-edit',
  templateUrl: './incidents-ltis-edit.component.html',
  styleUrls: ['./incidents-ltis-edit.component.scss']
})
export class IncidentsLtisEditComponent implements OnInit {

  incident: IncidentModel;

  incident_lti: IncidentLtiModel = new IncidentLtiModel;

  @ViewChild('estimatedDateTimePicker') estimatedDateTimePicker: NgxMatDatetimePicker<any>;
  @ViewChild('actualDateTimePicker') actualDateTimePicker: NgxMatDatetimePicker<any>;

  estimatedDateTime: moment.Moment;
  actualDateTime: moment.Moment;

  selectedFiles: File[] = [];

  selectedEstimatedTimezone: string;
  selectedActualTimezone: string;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private dialogRef: MatDialogRef<IncidentsLtisEditComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit() {
    // Store the incident
    if ( this.dialogData['incident'] ) {
      this.incident = this.dialogData['incident'];

      this.selectedEstimatedTimezone = this.incident.site.timezone;
      this.selectedActualTimezone = this.incident.site.timezone;
    }
    // Get the LTI id
    const incident_lti_id = Number(this.dialogData['incident_lti_id']);
    // Validate and make a request to get the message data
    if ( incident_lti_id ) {
      this.getIncidentLti(incident_lti_id);
    }
  }

  getOutputEstimatedTimezone($event) {
    this.selectedEstimatedTimezone = $event;
  }

  getOutputActualTimezone($event) {
    this.selectedActualTimezone = $event;
  }

  getIncidentLti(incident_lti_id?: number) {
    if ( incident_lti_id ) {
      this.api.makeRequest('get', `v2/incidents/${this.incident.id}/ltis/${incident_lti_id}`)
      .then((response : IncidentLtiModel) => {
        this.incident_lti.apply(response);

        if ( this.incident_lti.return_to_work_estimated_date ) {
          this.estimatedDateTime = moment.unix(this.incident_lti.return_to_work_estimated_date);
        }

        if ( this.incident_lti.return_to_work_actual_date ) {
          this.actualDateTime = moment.unix(this.incident_lti.return_to_work_actual_date);
        }
      });
    }

  }

  onSave() {
    if ( this.estimatedDateTime ) {
      const dateToSave = moment.tz(this.estimatedDateTime.format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a', this.selectedEstimatedTimezone);
      this.incident_lti.return_to_work_estimated_date = dateToSave.unix();
    }

    if ( this.actualDateTime ) {
      const dateToSave = moment.tz(this.actualDateTime.format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a', this.selectedActualTimezone);
      this.incident_lti.return_to_work_actual_date = dateToSave.unix();
    }

    let saveRequest: Promise<any>;

    // Add form validation
    if ( this.incident_lti.incident_lti_id ) {
      // Update the LTI
      saveRequest = this.api.makeRequest('put', `v2/incidents/${this.incident.id}/ltis/${this.incident_lti.incident_lti_id}`, this.incident_lti)
      .then((response: IncidentLtiModel) => {
        this.incident_lti.apply(response);
      });
    } else {
      // Create the LTI
      saveRequest = this.api.makeRequest('post', `v2/incidents/${this.incident.id}/ltis`, this.incident_lti)
      .then((response: IncidentLtiModel) => {
        this.incident_lti.apply(response);
      });
    }

    return saveRequest.then(() => {
      // Upload any files if the message id is present.
      if ( this.incident_lti.incident_lti_id && this.selectedFiles.length > 0 ) {
        return this.api.makeUploadRequest(`v2/file-manager/incident_lti/${this.incident_lti.incident_lti_id}`, this.selectedFiles)
        .then(() => {
          this.getIncidentLti(this.incident_lti.incident_lti_id);
        })
        .finally(() => {
          this.selectedFiles.length = 0;
        });
      }
    });
  }

  onSelectFiles(files?: any[]) {
    if ( files.length > 0 ) {
      this.selectedFiles.length = 0;
      this.selectedFiles.push(...files);
    }
  }

  onSelectUser() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: false,
        selected: [this.incident_lti.affected_user_id],
        visitor_site_id: this.incident.site_id
      },
      {},
    (selectedUserId: number) => {
        if ( typeof selectedUserId == 'undefined' ) {
          return;
        }
        this.incident_lti.affected_user_id = selectedUserId;
        this.api.makeRequest('get', `v2/public/user/${this.incident_lti.affected_user_id}`)
        .then((selectedUser: UserModel) => {
          this.incident_lti.affected_user_contact_person = selectedUser.contact_person;
          this.incident_lti.affected_user_email = selectedUser.email;
          this.incident_lti.affected_user_phone = selectedUser.mobile;
        });
      }
    );
  }

}
