<div mat-dialog-title>
  <h3>
    Select Job Titles
    <span *ngIf="dataSource.selection.selected.length">
      ({{ dataSource.selection.selected.length }})
    </span>
  </h3>
  <button mat-icon-button class="float-right" [mat-dialog-close]="initialSelectedValues">
    <mat-icon color="secondary">close</mat-icon>
  </button>

  <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
    <input
      class="form-control mr-sm-2"
      type="search"
      [(ngModel)]="dataSource.search"
      name="search"
      placeholder="Search"
      aria-label="Search" />

    <button mat-raised-button class="mr-sm-2" type="submit">
      <mat-icon>search</mat-icon> Search
    </button>

    <button
      mat-raised-button
      class="mr-sm-2"
      (click)="onClearSelected()"
      type="button" >
      Clear Selected
    </button>
  </form>
</div>

<mat-dialog-content>
  <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef width="65px">
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()" >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)"
          appMarginRight >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Title</th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ element.name }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="dataSource.selection.toggle(row.id)">
    </tr>
  </table>

  <mat-paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons >
  </mat-paginator>

</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <button
    mat-raised-button
    color="primary"
    type="button"
    mat-dialog-close
    (click)="onSaveAndClose()">
    Select & Close
  </button>
</mat-dialog-actions>

