import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ItemsEditComponent } from './items-edit/items-edit.component';
import { ItemsViewComponent } from './items-view/items-view.component';
import { ItemsComponent } from './items.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: 'items',
    component: ItemsComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'items/:id/edit/:pivotId',
    component: ItemsEditComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'items/:id/edit',
    component: ItemsEditComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'items/:id/view/:pivotId',
    component: ItemsViewComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'items/:id/view',
    component: ItemsViewComponent,
    canActivate: [
      AuthGuard
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ItemsRoutingModule { }
