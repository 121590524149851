<div mat-dialog-title class="position-relative w-100">
  <h5>Filter Forms</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close this dialog." mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>

  <mat-form-field style="width:100%;" floatLabel="always" class="mb-3" *ngIf="!show_date_picker_only">
    <mat-label>Show Active or Archived Forms.</mat-label>
    <mat-select [(ngModel)]="filter_options.only_archived" matTooltip="Filter forms by archival status." name="only_archived">
      <mat-option value="false">All Active</mat-option>
      <mat-option value="true">Only Archived</mat-option>
    </mat-select>
    <mat-hint>Use this to filter between active and archived forms.</mat-hint>
  </mat-form-field>

  <div class="container-fluid">
    <div class="row" *ngIf="!show_date_picker_only">
      <div class="col">
        <button mat-raised-button color="secondary" type="button" style="width:100%;" class="mb-3" (click)="onSelectCategories()" [matBadge]="'' + (filter_options.category_ids && filter_options.category_ids.length ? filter_options.category_ids.length : '')" matTooltip="Select from a list of form categories in your account.">Categories</button>
      </div>
      <div class="col">
        <button mat-raised-button color="secondary" type="button" style="width:100%;" class="mb-3" (click)="onSelectSites()" [matBadge]="'' + (filter_options.site_ids && filter_options.site_ids.length ? filter_options.site_ids.length : '')" matTooltip="Select from a list of {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} in your account.">{{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}</button>
      </div>
    </div>
    <div class="row" *ngIf="!show_date_picker_only">
      <div class="col">
        <button mat-raised-button color="secondary" type="button" style="width:100%;" class="mb-3" (click)="onSelectContractors()" [matBadge]="'' + (filter_options.contractor_ids && filter_options.contractor_ids.length ? filter_options.contractor_ids.length : '')" matTooltip="Select from a list of {{ utils.getLangTerm('contractors.plural', 'Contractors') }} in your account.">{{ utils.getLangTerm('contractors.plural', 'Contractors') }}</button>
      </div>
      <div class="col">
        <button mat-raised-button color="secondary" type="button" style="width:100%;" class="mb-3" (click)="onSelectUsers()" [matBadge]="'' + (filter_options.user_ids && filter_options.user_ids.length ? filter_options.user_ids.length : '')" matTooltip="Select from a list of users in your connected network.">Networked Users</button>
      </div>
    </div>
    <div class="row" *ngIf="!show_date_picker_only">
      <div class="col">
        <button mat-raised-button color="secondary" type="button" style="width:100%;" class="mb-3" (click)="onSelectIndustries()" [matBadge]="'' + (filter_options.industry_ids && filter_options.industry_ids.length ? filter_options.industry_ids.length : '')" matTooltip="Select from a global list of industries.">Industries</button>
      </div>
      <div class="col">
        <button mat-raised-button color="secondary" type="button" style="width:100%;" class="mb-3" (click)="onSelectTrades()" [matBadge]="'' + (filter_options.trade_ids && filter_options.trade_ids.length ? filter_options.trade_ids.length : '')" matTooltip="Select from a global list of job titles.">Job Titles</button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-date-range-picker style="width: 100%;" dateRangeTitle="Select Date Range" dateRangePlaceholder="Select Date Range" dateRangeTooltip="Filter forms by date range." [dateRangeInput]="filter_options.date_range" (dateRangeOutput)="getOutputDateRange($event)"></app-date-range-picker>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-center my-3">
  <button type="button" mat-raised-button color="primary" (click)="onSaveAndClose()">
    <mat-icon>filter_list</mat-icon> Apply Filter
  </button>

  <button type="button" mat-raised-button color="secondary" (click)="onClearAndClose()">
    <mat-icon> clear_all</mat-icon> Clear Filter
  </button>
</mat-dialog-actions>
