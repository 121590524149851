<div mat-dialog-title>
  <h5>Incident Cause Form</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form #form="ngForm" (ngSubmit)="onSubmit()">
    <mat-form-field>
      <input matInput placeholder="Cause" [(ngModel)]="incidentCause.name"
        name="name" type="text" required #name matTooltip="What happened?" />
      <mat-error *ngIf="!name.valid">Please enter a Cause.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Type" name="type"
        [(ngModel)]="incidentCause.type" required #primary matInput
        matTooltip="Was this directly or indirectly the reason for the incident?">
        <mat-option></mat-option>
        <mat-option value="Primary">Primary</mat-option>
        <mat-option value="Secondary">Secondary</mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngFor="let action of incidentCause?.actions; let i = index">
      <mat-form-field>
        <input matInput placeholder="Corrective Action {{ i + 1 }}"
          [(ngModel)]="action.name" name="action_{{ i }}" required
          matTooltip="What needs to be done to fix this issue?" />

        <button mat-button matSuffix mat-icon-button disabled>
          <mat-icon matTooltip="This has not been completed yet"
            *ngIf="!action.completed">warning</mat-icon>
        </button>

        <button type="button" mat-button matSuffix mat-icon-button *ngIf="i !== 0"
          (click)="onRemoveAction(i, action)" matTooltip="Remove this corrective action.">
          <mat-icon>close</mat-icon>
        </button>

        <mat-error>
          Please enter a Corrective Action.
        </mat-error>
      </mat-form-field>

      <div appMarginBottom class="d-flex justify-content-end">
        <button mat-raised-button
          [matBadge]="action?.user_ids?.length || 0" matBadgePosition="before"
          type="button" (click)="onSelectUsers(i, action)"
          matTooltip="Select users you want to assign this task to."
          class="margin-right" color="secondary">
          <mat-icon>person_pin</mat-icon>
          Select Assignees
        </button>

        <app-files-selector (selected)="actionFiles[action.id] = $event" [selectedFilesCount]="actionFiles[action.id]?.length" appMarginRight></app-files-selector>

        <button
          mat-raised-button
          (click)="onRemoveFiles(i)"
          color="secondary"
          [matBadge]="incidentCause.actions[i].files?.length"
          [disabled]="!incidentCause.actions[i].files?.length"
          type="button">
          <mat-icon>open_in_browser</mat-icon>
          Manage Files
        </button>

      </div>

    </div>

    <mat-error *ngIf="invalidActions()">
      Please assign people to all Corrective Actions.
    </mat-error>

    <mat-form-field>
      <textarea matInput placeholder="Description"
        [(ngModel)]="incidentCause.description" name="description" type="text"
        matTooltip="What specifically makes this a cause?"></textarea>
    </mat-form-field>
  </form>

  <small>
    <b>Note:</b> Assigned users will be notified to action the tasks that are assigned to them.
  </small>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button [disabled]="!form.valid || inProgress || !onCanSubmit()"
    color="primary" id="save" class="margin-right" (click)="onSubmit()"
    matTooltip="Save this Cause">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button mat-raised-button color="secondary" type="button"
    (click)="onAddAction()" matTooltip="Add another Action to the list">
    <mat-icon>add</mat-icon>
    Add Corrective Action
  </button>

  <span class="mr-auto"></span>

  <button type="button" mat-icon-button [disabled]="!incidentCause.id"
    class="float-right" (click)="onDelete()" matTooltip="Delete this Cause">
    <mat-icon>delete</mat-icon>
  </button>
</mat-dialog-actions>
