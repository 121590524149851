<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/user/messages">My Messages</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto"> </span>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onFilter()" matTooltip="Click this button to display filter options.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>

      <form class="form-inline my-2 my-lg-0"
            style="min-width: 325px"
            (ngSubmit)="listDataSource.getData(true)">
        <input
          class="form-control mr-sm-2" type="search"
          [(ngModel)]="listDataSource.search" name="search" placeholder="Search"
          aria-label="Search" id="search-input"
          matTooltip="Search for messages by subject or body content." />
        <button mat-raised-button
                color="secondary"
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                (click)="listDataSource.getData(true)"
                id="search-button"
                matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="listDataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the type of message.">
          Subject
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.subject }}
        </td>
      </ng-container>

      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the type of message.">
          Priority
        </th>
        <td mat-cell *matCellDef="let message">
          {{ message.priority | titlecase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="reply_to">
        <th mat-header-cell *matHeaderCellDef>
          Sender
        </th>
        <td mat-cell *matCellDef="let message">
          <ng-container *ngIf="message.reply_to.id">
            <b>{{ message.reply_to.contact_person }}</b> <br>
            {{ message.reply_to.employer.name }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="date_modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the type of message.">
          Date Received
        </th>
        <td mat-cell *matCellDef="let message">
          {{ (message.date_modified * 1000) | timezoneConvertLong }}
        </td>
      </ng-container>
      <ng-container matColumnDef="date_modified_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the type of message.">
          Date Received (UTC)
        </th>
        <td mat-cell *matCellDef="let message">
          {{ (message.date_modified * 1000) | timezoneConvertUTCLong }}
        </td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          Actions
        </th>
        <td mat-cell *matCellDef="let message">
          <button type="button" mat-button (click)="onViewMessage(message.id)" matTooltip="Click this button to view this message.">
            View
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"  [ngClass]="{ 'font-weight-bold bg-light' : !row.pivot.is_read }"></tr>
    </table>

    <mat-paginator #paginator2 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>

</div>
