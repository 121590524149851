<div mat-dialog-title>
  <h5>Task Analysis Document</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <mat-form-field>
      <input
        matInput
        placeholder="Task Analysis Document Name"
        [(ngModel)]="task_analysis.task_name"
        name="task_name"
        type="text"
        #task_name
        required />
      <mat-hint>
        Please enter a Task Analysis Document name.
      </mat-hint>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    (click)="onSubmit(f)"
    mat-raised-button
    [disabled]="!f.valid || inProgress"
    color="primary"
    class="save-button"
    style="max-width: 100px;">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <app-files-selector
    (selected)="newFiles = $event"
    [selectedFilesCount]="newFiles?.length"
    class="margin-right">
  </app-files-selector>

  <button
    mat-raised-button
    (click)="onRemoveFiles()"
    color="secondary"
    [matBadge]="'' + task_analysis.files?.length"
    [disabled]="!task_analysis.files?.length"
    type="button">
    <mat-icon>open_in_browser</mat-icon>
    Manage Files
  </button>

  <button
    (click)="onSelectSites()"
    mat-raised-button
    [matBadge]="'' + task_analysis.site_ids?.length"
    color="secondary"
    type="button">
    <mat-icon>location_on</mat-icon>
    Select {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
  </button>

  <span class="mr-auto"></span>

  <button
    mat-icon-button
    [disabled]="!task_analysis?.id"
    class="float-right"
    (click)="onRemove()"
    matTooltip="Delete this Task Analysis">
    <mat-icon>delete</mat-icon>
  </button>
</mat-dialog-actions>
