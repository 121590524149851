import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { PrequalificationService } from 'src/app/prequalifications/prequalification.service';
import { PrequalificationModel } from 'src/app/models/prequalification.model';
import { UserModel } from 'src/app/models/user.model';
import { UtilsService } from 'src/app/shared/utils.service';
import { ContractorService } from '../contractor.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';

@Component({
  selector: 'app-contractors-tabs',
  templateUrl: './contractors-tabs.component.html',
  styleUrls: ['./contractors-tabs.component.scss'],
  providers: [ContractorService]
})
export class ContractorsTabsComponent implements OnInit {
  prequalification: PrequalificationModel;
  contractor: UserModel;

  constructor(
    private route: ActivatedRoute,
    public app: AppService,
    private prequalService: PrequalificationService,
    public contractorService: ContractorService,
    private router: Router,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    // Get the contractor ID
    const contractorId = Number(this.route.snapshot.params['contractor_id']);
    if ( !contractorId ) {
      // Show Error
      this.router.navigate(['/contractors']);
      return;
    }

    this.contractorService.shouldRedirectOnFailure = true;
    this.contractorService.getContractorById(contractorId);


    // Might need to keep this, check it out.
    this.prequalService
      .findForContractor(contractorId)
      .subscribe((result) => (this.prequalification = result.data));
  }

  composeNewMessage(contractorId: number) {
    if ( !contractorId ) {
      this.utils.showToast('Unable to open message composer. Please try again.');
      return; // early termination
    }

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectAccountIds: [contractorId]
    })
    .then(() => {
      this.utils.showToast('You can view the message stats in the messages section.');
    });
  }
}
