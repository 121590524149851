import {UserModel} from "../../models/user.model";
import {Auditable} from "../../shared/auditable.model";

export class PpeModel extends Auditable {
  id: number;
  title: string;
  description: string;
  web_icon: string;
  mobile_icon: string;

  /**
   * Who created this PPE?
   */
  created_by_user: UserModel;

  /**
   * Who updated this PPE?
   */
  updated_by_user: UserModel;
}
