import { Auditable } from "../shared/auditable.model";

export class HazardControlModel extends Auditable {
  control = '';
  // level of control
  loc: 1 | 2 | 3 | 4 | 5 | 6 | 7 = 1;

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.control = '';
    this.loc = 1;
  }
}
