import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gitlab-issue',
  templateUrl: './gitlab-issue.component.html',
  styleUrls: ['./gitlab-issue.component.scss']
})
export class GitlabIssueComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
