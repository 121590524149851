<div class="release-notes">

    <ng-container *ngFor="let versionNotebook of versionNotebooks">
        <h5 style="color: #000;">{{ versionNotebook.heading }}</h5>
        <ng-container *ngFor="let notesInfo of versionNotebook.notes">
            <h6>{{ notesInfo.heading }}:</h6>
            <ul>
                <li *ngFor="let item of notesInfo.items">{{item}}</li>
            </ul>
        </ng-container>
    </ng-container>

</div>
