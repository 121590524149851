import {Component, Inject, OnInit} from '@angular/core';
import {UserModel} from "../../models/user.model";
import {UtilsService} from "../utils.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NoauthService} from "../noauth.service";

@Component({
  selector: 'app-user-public-profile',
  templateUrl: './user-public-profile.component.html',
  styleUrls: ['./user-public-profile.component.scss']
})
export class UserPublicProfileComponent implements OnInit {

  user = new UserModel();
  shareLink: string;
  readonly baseUrl = window.location.origin;

  constructor(
    @Inject(MAT_DIALOG_DATA) public hash: string,
    private dialog: MatDialogRef<UserPublicProfileComponent>,
    private noauthService: NoauthService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.find();
  }

  find() {
    this.noauthService
      .findUser(this.hash, false)
      .subscribe((response) => {
        this.user = response.data;
        this.shareLink =
          this.baseUrl +
          `/noauth/user-public-profile/${this.hash}`;
      }, (error) => {
        this.utils.showModal('Error Message', 'Sorry, we could not retrieve the user’s profile. Please try again later.');
        this.dialog.close();
      });
  }

  notifyCopied() {
    this.utils.showToast('Copied link to clipboard');
  }

}
