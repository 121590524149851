<div mat-dialog-title>
  <h5>
    {{ form.title }}
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
</div>

<div mat-dialog-content class="p-3">

  <div class="alert alert-info sc-custom-content" role="alert" style="font-size: small;">
    <div class="row">
      <div class="col">
        <ng-container *ngIf="form?.account?.name"><b>From:</b> {{ form?.account?.name }} <br></ng-container>
        <b>Submission Status:</b> {{ form?.pivot?.dynamic_form_status }} <br>
        <ng-container *ngIf="(form?.pivot?.dynamic_form_status == 'Approved' || form?.pivot?.dynamic_form_status == 'Approved: Work in Progress') && form?.pivot?.approved_by && form?.pivot?.approved_by_user"><b>Approved By:</b> <a (click)="onUserPublicView(form.pivot.approved_by_user.hash)" matTooltip="View User Public Profile">{{ form.pivot.approved_by_user.contact_person }}</a> <br></ng-container>
        <ng-container *ngIf="(form?.pivot?.dynamic_form_status == 'Approved' || form?.pivot?.dynamic_form_status == 'Approved: Work in Progress') && form?.pivot?.approved_on"><b>Approved At:</b> {{ (form.pivot.approved_on * 1000) | timezoneConvertMedium }} <br></ng-container>
        <ng-container *ngIf="form?.pivot?.dynamic_form_status == 'Rejected' && form?.pivot?.rejected_by && form?.pivot?.rejected_by_user"><b>Rejected By:</b> <a (click)="onUserPublicView(form.pivot.rejected_by_user.hash)" matTooltip="View User Public Profile">{{ form.pivot.rejected_by_user.contact_person }}</a> <br></ng-container>
        <ng-container *ngIf="form?.pivot?.dynamic_form_status == 'Rejected' && form?.pivot?.rejected_on"><b>Rejected At:</b> {{ (form.pivot.rejected_on * 1000) | timezoneConvertMedium }} <br></ng-container>
        <ng-container *ngIf="form?.pivot?.assigned_by && form?.pivot?.assigned_by_user"><b>Assigned By:</b> <a (click)="onUserPublicView(form.pivot.assigned_by_user.hash)" matTooltip="View User Public Profile">{{ form.pivot.assigned_by_user.contact_person }}</a> <br></ng-container>
        <ng-container *ngIf="form?.pivot?.assigned_on"><b>Assigned At:</b> {{ (form.pivot.assigned_on * 1000) | timezoneConvertMedium }} <br></ng-container>
        <ng-container *ngIf="form?.pivot?.date_modified"><b>Last Updated At / Submitted At:</b> {{ (form.pivot.date_modified * 1000) | timezoneConvertMedium }} <br></ng-container>

        <p class="mt-3">{{ form?.description }}</p>
      </div>

      <div class="col">
        <ng-container *ngIf="form?.pivot?.dynamic_form_notes">
          <p><b>Notes:</b></p>
          <p class="mt-3">{{ form?.pivot?.dynamic_form_notes }}</p>
        </ng-container>

        <ng-container *ngIf="form?.pivot?.dynamic_form_user_comments && form?.pivot?.dynamic_form_status != 'Approved: Work in Progress'">
          <p><b>Comments:</b></p>
          <p class="mt-3">{{ form?.pivot?.dynamic_form_user_comments }}</p>
        </ng-container>

        <mat-form-field class="full-width mb-3" *ngIf="canCompleteWork()">
          <mat-label>Comments</mat-label>
          <textarea
            matInput
            rows="5"
            placeholder="Comments"
            [(ngModel)]="form.pivot.dynamic_form_user_comments"
            name="dynamic_form_user_comments"
            id="dynamic_form_user_comments"
            dusk="dusk_dynamic_form_user_comments">
          </textarea>
          <mat-hint>You can enter some comments here.</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>

  <form (submit)="onSaveForm(f)" #f="ngForm">

    <div class="alert alert-warning sc-custom-content" role="alert" *ngIf="canSaveForm() && (!f.valid || !validateFieldOptions())">
      Please complete all required fields denoted with an asterisk (*).
    </div>

    <div *ngFor="let formField of form.fields; let i = index">
      <ng-container *ngIf="evaluateConditions(formField)">
        <div [ngSwitch]="formField.field_type" appMarginBottom>

          <mat-form-field floatLabel="always" *ngSwitchCase="'input'">
            <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>

            <ng-container *ngIf="formField.field_input_type == 'tel'; else noTel">
              <app-phone-input
                [idInput]="'input-'+formField.id"
                [numberInput]="formField.field_answer.answer"
                [countryCodeInput]="formField.field_answer.meta.mobile_country_code"
                [dialCodeInput]="formField.field_answer.meta.mobile_dial_code"
                [e164NumberInput]="formField.field_answer.meta.mobile_e164"
                (phoneDetailsOutput)="extractPhoneDetails($event, i)"
                [required]="formField.field_is_required == true"
                [disabled]="!canSaveForm()">
              </app-phone-input>
            </ng-container>
            <ng-template #noTel>
              <input matInput [type]="formField.field_input_type" [(ngModel)]="formField.field_answer.answer" [name]="'input-'+formField.id" [required]="formField.field_is_required == true" [disabled]="!canSaveForm()">
            </ng-template>

            <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
          </mat-form-field>

          <mat-form-field floatLabel="always" *ngSwitchCase="'datetime'">
            <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="formField.field_answer.answer" [max]="formField.field_date_range == 'past' ? currentDate : null" [min]="formField.field_date_range == 'future' ? currentDate : null" [name]="'datetime-'+formField.id" readonly (click)="picker.open()" [required]="formField.field_is_required == true" [disabled]="!canSaveForm()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
          </mat-form-field>

          <mat-form-field floatLabel="always" *ngSwitchCase="'textarea'">
            <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>
            <textarea matInput [(ngModel)]="formField.field_answer.answer" [name]="'textarea-'+formField.id" [required]="formField.field_is_required == true" [disabled]="!canSaveForm()"></textarea>
            <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
          </mat-form-field>

          <mat-form-field floatLabel="always" *ngSwitchCase="'select'">
            <mat-label class="sc-custom-form-field-label">{{ formField.field_label }}</mat-label>
            <select matNativeControl [(ngModel)]="formField.field_answer.answer" [name]="'select-'+formField.id" [required]="formField.field_is_required == true" [disabled]="!canSaveForm()">
              <option></option>
              <option *ngFor="let formFieldOption of formField.field_options" [value]="formFieldOption.option_text">{{formFieldOption.option_text}}</option>
            </select>
            <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>
          </mat-form-field>

          <div *ngSwitchCase="'radio'">
            <span class="sc-custom-label">{{formField.field_label}} <ng-container *ngIf="formField.field_is_required == true">*</ng-container></span>
            <mat-hint *ngIf="formField.field_description" appMarginBottom>{{ formField.field_description }}</mat-hint>
            <mat-radio-group aria-label="Selectable Options" [(ngModel)]="formField.field_answer.answer" [name]="'radio-'+formField.id" [required]="formField.field_is_required == true" [disabled]="!canSaveForm()">
              <mat-radio-button class="sc-custom-radio" *ngFor="let formFieldOption of formField.field_options" [value]="formFieldOption.option_text">
                {{formFieldOption.option_text}}
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div *ngSwitchCase="'checkbox'">
            <span class="sc-custom-label">{{formField.field_label}}</span>
            <mat-hint *ngIf="formField.field_description" appMarginBottom>{{ formField.field_description }}</mat-hint>
            <mat-checkbox class="sc-custom-checkbox" *ngFor="let formFieldOption of formField.field_options" [(ngModel)]="formFieldOption.option_answer.answer" [name]="'checkbox-'+formFieldOption.id" [required]="formFieldOption.option_is_required == true" [disabled]="!canSaveForm()">
              {{formFieldOption.option_text}} <span *ngIf="formFieldOption.option_is_required == true">*</span>
            </mat-checkbox>
          </div>

          <div *ngSwitchCase="'toggle'">
            <span class="sc-custom-label">{{formField.field_label}}</span>
            <mat-hint *ngIf="formField.field_description" appMarginBottom>{{ formField.field_description }}</mat-hint>
            <mat-slide-toggle class="sc-custom-toggle" *ngFor="let formFieldOption of formField.field_options" [(ngModel)]="formFieldOption.option_answer.answer" [name]="'toggle-'+formFieldOption.id" [required]="formFieldOption.option_is_required == true" [disabled]="!canSaveForm()">
              {{formFieldOption.option_text}} <span *ngIf="formFieldOption.option_is_required == true">*</span>
            </mat-slide-toggle>
          </div>

          <div *ngSwitchCase="'paragraph'">
            <span class="sc-custom-header">{{formField.field_label}}</span>
            <p class="sc-custom-content" *ngIf="formField.field_description">{{formField.field_description }}</p>
          </div>

          <div *ngSwitchCase="'wysiwyg'">
            <span class="sc-custom-header">{{formField.field_label}}</span>
            <div class="sc-custom-content" *ngIf="formField.field_description" [innerHTML]="formField.field_description | sanitizeHtml"></div>
          </div>

          <div *ngSwitchCase="'filepicker'">
            <span class="sc-custom-label">{{formField.field_label}}</span>
            <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>

            <mat-nav-list dense *ngIf="formField.field_answer.files && formField.field_answer.files.length > 0">
              <mat-list-item *ngFor="let file of formField.field_answer.files; let j = index">
                <img *ngIf="utils.isImage(file.name); else showDocumentIcon" matListIcon [src]="file.url" [alt]="file.name" />
                <ng-template #showDocumentIcon>
                  <img matListIcon src="assets/images/document-placeholder.png" alt="Document" />
                </ng-template>
                <a matLine [href]="file.url" target="_blank"><span class="sc-list-item-text">{{ file.name }}</span></a>
                <button type="button" color="warn" mat-icon-button (click)="onRemoveUploadedFile(i, j, formField.field_answer.id, file.id, $event)" [disabled]="!canSaveForm()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-list-item>
            </mat-nav-list>

            <div class="file-selector-button-margin-top">
              <app-file-browse-button
                (selectedFiles)="onSelectFiles($event, i)"
                parentObjectType="dynamic_form_field_answer"
                [parentObjectId]="formField.field_answer.id"
                [filesCount]="formField.field_answer?.files?.length || 0"
                *ngIf="canSaveForm()"
                [disabled]="!canSaveForm()">
              </app-file-browse-button>
            </div>
          </div>

          <div *ngSwitchCase="'signature'">
            <app-digital-signature *ngIf="!formField.field_answer.answer; else showSignature" [title]="formField.field_label" [subtitle]="formField.field_description" [meta]="formField.field_answer.meta" (meta_output)="onLockSignature(i, $event)" [allow_change_signer]="true"></app-digital-signature>
            <ng-template #showSignature>
              <app-digital-signature-image [meta]="formField.field_answer.meta"></app-digital-signature-image>
            </ng-template>
          </div>

          <div *ngSwitchCase="'user-selector'">
            <span class="sc-custom-label">{{formField.field_label}}</span>
            <mat-hint *ngIf="formField.field_description">{{ formField.field_description }}</mat-hint>

            <mat-nav-list dense *ngIf="formField.field_answer.meta['user_objects']">
              <mat-list-item *ngFor="let user_object of formField.field_answer.meta['user_objects']; let j = index;" (click)="onUserPublicView(user_object.hash)">
                <ng-container matLine>
                  <span style="color: #0096AB;" matTooltip="Open Public User Profile">{{ user_object.contact_person }}</span>
                </ng-container>
                <button type="button" color="warn" mat-icon-button *ngIf="canSaveForm()" (click)="onRemoveUserObjectForUserSelectorField(i, j, $event)">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-list-item>
            </mat-nav-list>

            <button type="button" mat-button mat-raised-button color="secondary" *ngIf="canSaveForm()" (click)="onOpenUsersSelectorForUserSelectorField(i)" matTooltip="Click this button to open the users selector." appMarginTop>
              <mat-icon>people</mat-icon>
              Select Users
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</div>

<mat-dialog-actions class="d-flex justify-content-end">
  <ng-container *ngIf="canSaveForm()">
    <button mat-raised-button color="secondary" type="button" (click)="onSaveForm(f)" matTooltip="Save the form and continue editing it. Saving the form's progress will change the status to 'In Progress' which will require resubmitting the form if it was submitted before.">Save Progress</button>
    <button mat-raised-button color="primary" type="button" (click)="onSubmitForm(f, true)" matTooltip="Submit the form and close the dialog.">Submit & Close</button>
  </ng-container>

  <ng-container *ngIf="canCompleteWork()">
    <button mat-raised-button color="primary" type="button" (click)="onCompleteWork()" matTooltip="Change the status of the form.">Complete Work</button>
  </ng-container>
</mat-dialog-actions>
