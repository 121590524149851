import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../../app.service';
import { CourseModuleQuestionModel } from '../../../../models/course-module-question.model';
import { ApiService } from '../../../../shared/api.service';
import { UtilsService } from '../../../../shared/utils.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-courses-modules-questions-view',
  templateUrl: './courses-modules-questions-view.component.html',
  styleUrls: ['./courses-modules-questions-view.component.scss']
})
export class CoursesModulesQuestionsViewComponent implements OnInit {

  course_id: number;
  module_id: number;

  question_data: CourseModuleQuestionModel = new CourseModuleQuestionModel();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      question_data: CourseModuleQuestionModel;
      course_id: number;
      module_id: number;
    }
  ) {}

  ngOnInit() {
    this.question_data = this.data.question_data;
    this.course_id = this.data.course_id;
    this.module_id = this.data.module_id;

    this.api.laravelApiRequest(
      'get',
      `inductions/${this.course_id}/modules/${this.module_id}/questions/${this.question_data.id}`,
      {},
      {},
      (response) => {
        this.question_data = response.data;
      }
    );
  }

  onRemove() {
    this.utils.showModal(
      'Remove Question',
      'Are you sure you want to remove this question?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          `inductions/${this.course_id}/modules/${this.module_id}/questions/${this.question_data.id}`,
          {},
          {},
          (response) => {
            this.utils.showToast('The question was removed');
            this.router.navigate([
              'courses',
              this.course_id,
              'modules',
              this.module_id,
              'questions'
            ]);
          }
        );
      }
    );
  }
}
