<div mat-dialog-title class="p-3">
  <h5>
    Safety Observation
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
</div>

<div mat-dialog-content class="p-3">
  <form #f="ngForm" (ngSubmit)="onSaveSafetyObservation(f)">

    <div class="row mb-3">
      <div class="col">
        <mat-form-field class="full-width">
          <mat-label>Category:</mat-label>
          <mat-select [(ngModel)]="safety_observation.so_category" required name="so_category" aria-label="Select the category of the safety observation.">
            <mat-option *ngFor="let so_category of utils.getSafetyObservationCategories()" [value]="so_category">{{ so_category }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-hint>
          <small>Select the category of the safety observation.</small>
        </mat-hint>
      </div>

      <div class="col">
        <mat-form-field class="full-width">
          <mat-label>Type:</mat-label>
          <mat-select [(ngModel)]="safety_observation.so_type" required name="so_type" aria-label="Select the type of safety observation.">
            <mat-option *ngFor="let so_type of utils.getSafetyObservationTypes()" [value]="so_type">{{ so_type }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-hint>
          <small>Select the type of the safety observation.</small>
        </mat-hint>
      </div>

      <div class="col">
        <mat-form-field class="full-width">
          <mat-label>When was the Safety Observation reported?</mat-label>
          <input matInput [ngxMatDatetimePicker]="reportedDateTimePickerRef" readonly required name="reportedDateTime" [(ngModel)]="reportedDateTime">
          <mat-datepicker-toggle matSuffix [for]="reportedDateTimePickerRef"></mat-datepicker-toggle>
          <button type="button" mat-button matSuffix mat-icon-button *ngIf="reportedDateTime" (click)="reportedDateTime = null" matTooltip="Clear the date and time."><mat-icon>close</mat-icon></button>
          <ngx-mat-datetime-picker #reportedDateTimePickerRef [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
          <mat-hint>
            Use the calendar icon to select the date and time.<br>
            <b class="text-danger" *ngIf="!reportedDateTime">Please selected a date and time.</b>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <mat-form-field class="full-width">
          <mat-label>Priority:</mat-label>
          <mat-select [(ngModel)]="safety_observation.so_priority" name="so_priority" aria-label="Select the priority of the safety observation.">
            <mat-option *ngFor="let so_priority of utils.getSafetyObservationPriorities()" [value]="so_priority">{{ so_priority }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-hint>
          <small>Select the priority of the safety observation.</small>
        </mat-hint>
      </div>

      <div class="col">
        <mat-form-field class="full-width">
          <mat-label>Status:</mat-label>
          <mat-select [(ngModel)]="safety_observation.so_status" name="so_status" aria-label="Select the status of the safety observation.">
            <mat-option *ngFor="let so_status of utils.getSafetyObservationStatuses()" [value]="so_status">{{ so_status }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-hint>
          <small>Select the status of the safety observation.</small>
        </mat-hint>
      </div>

      <div class="col">
        <table style="width: 100%; margin-top: 16px;">
          <tr>
            <td>
              <span class="material-icons">
                sentiment_very_dissatisfied
              </span>
            </td>
            <td>
              <mat-slider
                class="rating-slider-width"
                [max]="5"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                name="so_rating"
                [(ngModel)]="safety_observation.so_rating">
              </mat-slider>
            </td>
            <td>
              <span class="material-icons">
                sentiment_satisfied_alt
              </span>
            </td>
          </tr>
        </table>
        <mat-hint>
          <small>Use the slider above to rate the safety observation from 1-5.</small>
        </mat-hint>
      </div>
    </div>

    <mat-form-field class="full-width mb-3">
      <mat-label>Description of Safety Observation</mat-label>
      <textarea
        matInput
        rows="5"
        placeholder="Description"
        [(ngModel)]="safety_observation.so_description"
        name="so_description"
        type="text"
        id="so_description"
        required
        dusk="dusk_so_description">
      </textarea>
      <mat-hint>Enter the description of the safety observation.</mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width mb-3">
      <mat-label>Action Taken</mat-label>
      <input
        matInput
        placeholder="Action Taken"
        [(ngModel)]="safety_observation.so_action_taken"
        name="so_action_taken"
        type="text"
        id="so_action_taken"
        dusk="dusk_so_action_taken" />
      <mat-hint>Enter the action taken for the safety observation.</mat-hint>
    </mat-form-field>

    <div class="row" *ngIf="!safety_observation.site_id || !f.valid">
      <div class="col-12">
        <div class="alert alert-warning" role="alert">
          Please select a {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} to associate this safety observation with and complete the form above.
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">

        <button
          mat-raised-button
          class="mr-3"
          type="button"
          color="secondary"
          id="site_selector_button"
          dusk="dusk_site_selector_button"
          (click)="onSelectSite()"
          [matBadge]="safety_observation.site_id ? 'Yes' : 'No'"
          matTooltip="Select the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} you want the safety observation to be associated with.">
          <mat-icon>location_on</mat-icon>
          Select {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
        </button>

        <button
          mat-raised-button
          class="mr-3"
          type="button"
          color="secondary"
          id="contractor_selector_button"
          dusk="dusk_contractor_selector_button"
          (click)="onSelectContractors()"
          [matBadge]="'' + (safety_observation.contractor_ids.length || safety_observation.contractors.length)"
          matTooltip="Select the {{ utils.getLangTerm('contractors.plural', 'Contractors') }} you want the safety observation to be associated with.">
          <fa-icon [icon]="faHardHat"></fa-icon>
          Select {{ utils.getLangTerm('contractors.plural', 'Contractors') }}
        </button>

        <button
          mat-raised-button
          class="mr-3"
          type="button"
          color="secondary"
          id="users_selector_button"
          dusk="dusk_users_selector_button"
          (click)="onSelectUsers()"
          [matBadge]="'' + (safety_observation.user_ids.length || safety_observation.users.length)"
          matTooltip="Select the users you want the safety observation associated with.">
          <mat-icon>account_circle</mat-icon>
          Select Users
        </button>

        <button
          mat-raised-button
          class="mr-3"
          type="button"
          color="secondary"
          (click)="onSelectReporter()"
          [matBadge]="'' + (safety_observation.reported_by ? 1 : 0)"
          matTooltip="You can optionally select a reporter.">
          <mat-icon>rate_review</mat-icon>
          {{ !safety_observation.reported_by ? 'Select' : 'Change' }} Reporter
        </button>

        <app-file-browse-button
          (selectedFiles)="onSelectFiles($event)"
          (afterDialogClosedEvent)="onSaveSafetyObservation(f)"
          parentObjectType="safety_observation"
          [parentObjectId]="safety_observation.id"
          [filesCount]="safety_observation.files.length">
        </app-file-browse-button>

        <button
          mat-raised-button
          [disabled]="!f.valid || !safety_observation.site_id"
          class="mr-3"
          type="button"
          color="primary"
          id="save_close_button"
          dusk="dusk_save_close_button"
          (click)="onSaveSafetyObservation(f)">
          <mat-icon>save</mat-icon>
          Save & Close
        </button>

        <button
          mat-raised-button
          [disabled]="!f.valid || !safety_observation.site_id"
          class="mr-3"
          type="button"
          color="primary"
          id="save_continue_button"
          dusk="dusk_save_continue_button"
          (click)="onSaveSafetyObservation(f, true)">
          <mat-icon>save</mat-icon>
          Save & Continue
        </button>

      </div>
    </div>

  </form>
</div>
