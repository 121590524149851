import { Component, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppService } from 'src/app/app.service';
import { NetworkedAccountsSelectorComponent } from '../networked-accounts-selector/networked-accounts-selector.component';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { ApiService } from '../api.service';
import { tap } from 'rxjs/operators';
import {ApiDataSource} from "../../utils/api-data-source";
import {ApiRequestService} from "../api-request.service";
import {AccountModel} from "../../models/account.model";
import {UtilsService} from "../utils.service";

@Component({
  selector: 'app-global-accounts-selector',
  templateUrl: './global-accounts-selector.component.html',
  styleUrls: ['./global-accounts-selector.component.scss']
})
export class GlobalAccountsSelectorComponent {

  displayedColumns = ['name'];
  dataSource: GlobalAccountsDataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public app: AppService,
    public api: ApiRequestService,
    public dialogRef: MatDialogRef<NetworkedAccountsSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utils: UtilsService
  ) {
    // Set multiple if it is not present and default it to false
    if ( typeof this.data['multiple'] === 'undefined' || typeof this.data['multiple'] !== 'boolean' ) {
      this.data['multiple'] = false;
    }

    if ( this.data['multiple'] ) {
      this.displayedColumns.unshift('select');
    } else {
      this.displayedColumns.push('actions');
    }

    // Construct the datasource
    this.dataSource = new GlobalAccountsDataSource(
      this.app,
      this.api,
      this.data['multiple']
    );

    // Set a default selection if no selected values are passed through
    if (typeof this.data['selected'] === 'undefined') {
      this.data['selected'] = [];
    }

    // Loop and select the values in the data source
    for (let i = 0; i < this.data['selected'].length; i++) {
      this.dataSource.selection.select(this.data['selected'][i]);
    }

    // Set a default search of '' if no search term were provided.
    if (typeof this.data['search'] == 'undefined') {
      this.data['search'] = '';
    }
    // Set the provided search term.
    this.dataSource.search = this.data['search'];

    // Get the data
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onCanCreateNewAccount(): boolean {
    // Get the data.
    const data: AccountModel[] = this.dataSource.dataSubject.getValue();
    // Check the length and search term.
    return data.length == 0 && this.dataSource.search != '';
  }

  onSelectSingle(id: number) {
    if ( typeof id == 'undefined' || id == null ) {
      // Pass the search term.
      this.utils.showModal('Confirmation Required.', 'Are you sure you want to proceed with "' + this.dataSource.search + '" as the name of your company/employer?', () => {
        this.dialogRef.close(this.dataSource.search);
      });
    } else {
      // Pass the selected id.
      this.dialogRef.close(id);
    }
  }

  onSelectMultiple() {
    this.dialogRef.close(this.dataSource.selection.selected);
  }

  onDeselectAllRecords() {
    this.dataSource.selection.clear();
  }
}

export class GlobalAccountsDataSource extends ApiDataSource {

  order_by = 'name';
  order = 'asc';

  // Debounce timer and delay for the entity name autocomplete.
  debounceTimer: any;
  debounceDelay: number = 300;

  getData(resetOffset: boolean = false) {
    // Do not perform a search if the search string is less than 3 characters.
    if ( this.search.length < 3 ) {
      // Clear the previous results.
      this.clearResults();
      return;
    }
    // Check if the debounce timer is set.
    if ( this.debounceTimer ) {
      // Clear the debounce timer.
      clearTimeout(this.debounceTimer);
    }
    // Set the debounce timer.
    this.debounceTimer = setTimeout(() => {
      // Make the API request to get accounts.
      this.makeRequest('v2/global-account-search', resetOffset, {});
    }, this.debounceDelay);
  }
}
