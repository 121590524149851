<nav class="navbar navbar-expand-lg navbar-light bg-light">

  <span class=" mr-auto">
    <button mat-raised-button routerLink="/sites" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>

    <button mat-raised-button *ngIf="site.parent_site_id" [routerLink]="'/sites/'+site.parent_site_id+'/children'" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
    </button>

    <button
      mat-raised-button
      [routerLink]="utils.prepareSiteRelatedLink(site.id, site.parent_site_id, 'edit/details')"
      appMarginRight>
      <mat-icon>edit</mat-icon> Edit
    </button>

    <button mat-raised-button (click)="onRemove()" color="warn" appMarginRight>
      <mat-icon>delete</mat-icon> Delete
    </button>
  </span>
</nav>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <ng-container *ngIf="site.name">
        <h6>Name</h6>
        <p>{{ site.name }}</p>
      </ng-container>

      <ng-container *ngIf="site.location">
          <h6>Location / Physical Address</h6>
          <p>{{ site.location }}</p>
        </ng-container>

      <ng-container *ngIf="site.site_type">
        <h6>Type</h6>
        <p>{{ site.site_type }}</p>
      </ng-container>

      <ng-container *ngIf="site.site_status">
        <h6>Status</h6>
        <p>{{ site.site_status }}</p>
      </ng-container>

      <ng-container *ngIf="site.external_reference">
        <h6>Job #</h6>
        <p>{{ site.external_reference }}</p>
      </ng-container>

      <ng-container *ngIf="site.open_time">
        <h6>Opening Time</h6>
        <p>{{ site.open_time }}</p>
      </ng-container>

      <ng-container *ngIf="site.close_time">
        <h6>Closing Time</h6>
        <p>{{ site.close_time }}</p>
      </ng-container>

      <ng-container *ngIf="site.timezone">
        <h6>Timezone</h6>
        <p>{{ site.timezone }}</p>
      </ng-container>

      <ng-container *ngIf="site.estimated_project_start_date">
        <h6>Estimated Start Date</h6>
        <p>{{ (site.estimated_project_start_date * 1000) | timezoneConvertMedium }}</p>
      </ng-container>

      <ng-container *ngIf="site.estimated_project_end_date">
        <h6>Estimated End Date</h6>
        <p>{{ (site.estimated_project_end_date * 1000) | timezoneConvertMedium }}</p>
      </ng-container>

      <ng-container *ngIf="site.short_description">
        <h6>Short Description of Work</h6>
        <p>{{ site.short_description }}</p>
      </ng-container>

      <ng-container>
        <h6>Send after hour notifications?</h6>
        <p>{{ site.enable_after_hours_notification ? 'Yes' : 'No' }}</p>
      </ng-container>

      <ng-container>
        <h6>Risk Level</h6>
        <p [class]="utils.getSiteRiskStatusTextColor(site.site_risk_assessment)">{{ site.site_risk_assessment | titlecase }}</p>
      </ng-container>

      <ng-container *ngIf="site.tags?.length">
        <h6>Site Tags</h6>
        <mat-chip-list aria-label="List of Tags">
          <mat-chip *ngFor="let tag of site.tags">{{ tag.title }}</mat-chip>
        </mat-chip-list>
      </ng-container>

    </div>

    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12">
          <form tourAnchor="tour.search" class="form-inline my-2 my-lg-0" (ngSubmit)="getOnsiteUsers(true)" style="min-width: 325px">
            <input class="form-control mr-sm-2" type="search" [(ngModel)]="search" name="search" placeholder="Search Onsite Users"
              matTooltip="Search for users by name, email, contact number or employer."
              aria-label="Search" id="search-input" />

            <button mat-raised-button
                    class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                    color="secondary"
                    matTooltip="Run the current search"
                    id="search-button">
              <mat-icon>search</mat-icon> Search
            </button>
          </form>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div #map id="map"></div>
          <small>
            The geofence (polygon) is the sign-in perimeter. The circular zone around the sign-in perimeter is the buffered sign-out zone. Automatic GPS sign-outs are delayed by 5 minutes to counter-act GPS drifting.
          </small>
        </div>
      </div>
    </div>
  </div>
</div>
