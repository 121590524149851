import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { UtilsService } from '../shared/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private app: AppService,
    private utils: UtilsService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if ( !this.app.isAuthenticated ) {
        this.utils.redirectPage(['/sign-in'], {}, true);
        return false;
      }
    return true;
  }
}
