<h5 mat-dialog-title>
  System Notification
  <button type="button" mat-icon-button class="float-right my-0" (click)="onClose()">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</h5>

<div mat-dialog-content>
  <form #f="ngForm" (ngSubmit)="onSubmit(f)">

    <div class="alert alert-warning" role="alert">
      <b>Identifier:</b> {{ system_notification.identifier }}
      <ng-container *ngIf="system_notification.is_site_related">
        <br>
        <i>
          This system notification is {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} related.
        </i>
      </ng-container>
    </div>

    <mat-form-field class="full-width mb-3 mt-3">
      <mat-label>Label</mat-label>
      <input
        matInput
        placeholder="Label"
        [(ngModel)]="system_notification.label"
        name="label"
        type="text"
        id="label"
        required />
      <mat-hint>Enter the label of the system notification.</mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width mb-3">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        rows="3"
        placeholder="Description"
        [(ngModel)]="system_notification.description"
        name="description"
        type="text"
        id="description"
        required >
      </textarea>
      <mat-hint>Enter the description of the system notification.</mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width mb-3">
      <mat-label>Feature</mat-label>
      <input
        matInput
        placeholder="Feature"
        [(ngModel)]="system_notification.feature"
        name="feature"
        type="text"
        id="feature"
        required />
      <mat-hint>Enter the feature of the system notification.</mat-hint>
    </mat-form-field>

    <div class="d-flex justify-content-end">
      <button
        mat-raised-button
        [disabled]="!f.valid"
        type="submit"
        class="mr-1"
        color="primary">
        <mat-icon>save</mat-icon> Update
      </button>

      <button
        mat-raised-button
        [disabled]="!f.valid"
        type="button"
        color="primary" (click)="onSubmit(f, true)">
        <mat-icon>save</mat-icon> Update & Close
      </button>
    </div>
  </form>
</div>
