import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrequalificationService } from 'src/app/prequalifications/prequalification.service';
import { PrequalificationModel } from 'src/app/models/prequalification.model';
import { UtilsService } from 'src/app/shared/utils.service';
import {NoauthService} from "../../shared/noauth.service";

declare var saveAs: any;

@Component({
  selector: 'app-my-prequalifications-edit',
  templateUrl: './my-prequalifications-edit.component.html',
  styleUrls: ['./my-prequalifications-edit.component.scss']
})
export class MyPrequalificationsEditComponent implements OnInit {
  prequal: PrequalificationModel = new PrequalificationModel();
  score: number;
  shareLink: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private id: number,
    private prequalService: PrequalificationService,
    private utils: UtilsService,
    private noauthService: NoauthService
  ) {}

  ngOnInit() {
    if (this.id === 0) { return (this.prequal = new PrequalificationModel()); }
    this.find();
  }

  resetShareLink() {
    this.prequalService.resetShareLink(this.prequal.id).subscribe(() => {
      this.utils.showToast(
        'Reset the share link. Users will need the new link to access this Prequalification.'
      );
      this.find();
    });
  }

  find() {
    this.prequalService.find(this.id, true).subscribe((response) => {
      this.prequal = response.data;
      this.shareLink =
        window.location.origin +
        `/noauth/prequalifications/${this.prequal.hash}`;
    });
  }

  async downloadPdf() {
    const data = await this.prequalService.exportPdf(this.prequal.id);
    saveAs(data, `Prequalification ${this.prequal.name}`);
  }

  notifyCopied() {
    this.utils.showToast('Copied link to clipboard');
  }

  /**
   * Completes the prequalification process.
   *
   * This method calls the completePrequal method of the noauthService to complete the prequalification process,
   * using the prequal hash as a parameter. It then subscribes to the response of the completePrequal method and
   * performs the following actions:
   * 1. Displays a toast message with the name of the prequalification and the name of the account that was notified.
   * 2. Calls the find method to update the list of prequalifications.
   *
   * This function was replicated from the noauth-prequalifications component.
   *
   * @return {void}
   */
  onComplete() {
    this.noauthService.completePrequal(this.prequal.hash).subscribe(() => {
      this.utils.showToast(
        `You submitted "${this.prequal.name}". ${this.prequal.account.name} was notified.`
      );
      this.find();
    });
  }
}
