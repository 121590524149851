<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class=" mr-auto">
    <button mat-raised-button routerLink="/hazardous_substances" appMarginRight>
      <mat-icon>arrow_back</mat-icon> Hazardous Substance Register
    </button>

    <button
      mat-raised-button
      routerLink="/hazardous_substances/{{ hazardous_substance.id }}/edit"
      *ngIf="hazardous_substance.id"
      appMarginRight>
      <mat-icon>edit</mat-icon> Edit
    </button>

    <button
      mat-raised-button
      (click)="onRemove()"
      *ngIf="hazardous_substance.id"
      appMarginRight>
      <mat-icon>delete</mat-icon> Delete
    </button>
  </span>
</nav>

<mat-tab-group (selectedTabChange)="onTabChanged($event)">
  <mat-tab label="Details">
    <div class="container-fluid" appPadding>
      <ng-container *ngIf="hazardous_substance.substance">
        <h6>Substance / Product</h6>
        <p>{{ hazardous_substance.substance }}</p>
      </ng-container>

      <ng-container *ngIf="hazardous_substance.risk_info">
        <h6>Related Risk / Harm</h6>
        <p>{{ hazardous_substance.risk_info }}</p>
      </ng-container>

      <ng-container *ngIf="hazardous_substance.additional_info">
        <h6>Precautions for use</h6>
        <p>{{ hazardous_substance.additional_info }}</p>
      </ng-container>

      <ng-container *ngIf="hazardous_substance?.ppe.length > 0">
        <h6>PPE</h6>
        <p>{{ formattedPPE(hazardous_substance.ppe) }}</p>
      </ng-container>

      <ng-container *ngIf="hazardous_substance.storage_requirements">
        <h6>Storage Requirements</h6>
        <p>{{ hazardous_substance.storage_requirements }}</p>
      </ng-container>

      <ng-container *ngIf="hazardous_substance.hazardous_substance_type">
        <h6>Hazardous Substance Type</h6>
        <p>{{ ('(' + hazardous_substance?.hazardous_substance_type?.substr(0, 1) + ') ') + hazardous_substance.hazardous_substance_type }}</p>
      </ng-container>

      <ng-container *ngIf="hazardous_substance.hazardous_substance_unit_type">
        <h6>Unit Type</h6>
        <p>{{ hazardous_substance.hazardous_substance_unit_type }}</p>
      </ng-container>

      <ng-container *ngIf="hazardous_substance.un_classification">
        <h6>United Nations (UN) Class & Packaging Group</h6>
        <p>{{ hazardous_substance.un_classification }}</p>
      </ng-container>

      <ng-container *ngIf="hazardous_substance.hsno_classification">
        <h6>GHS/HSNO Classification</h6>
        <p>{{ hazardous_substance.hsno_classification }}</p>
      </ng-container>

      <ng-container *ngIf="hazardous_substance.hsno_approval_number">
        <h6>GHS/HSNO Approval # and Group Standard</h6>
        <p>{{ hazardous_substance.hsno_approval_number }}</p>
      </ng-container>

      <ng-container *ngIf="hazardous_substance.sds_expires_at">
        <h6>Expiry Date of Safety Data Sheet (SDS)</h6>
        <p>{{ (hazardous_substance.sds_expires_at * 1000) | timezoneConvertMedium }}</p>
      </ng-container>
    </div>
  </mat-tab>

  <mat-tab label="Files">
    <app-files-list
      [files]="hazardous_substance.files"
      [view]="true">
    </app-files-list>
  </mat-tab>

  <mat-tab label="{{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}">
    <div class="container-fluid">
      <div class="d-flex justify-content-end" appMargin>
        <form
          class="form-inline my-2 my-lg-0"
          (ngSubmit)="linkedSitesDataSource.getData(true)">
          <input
            class="form-control mr-sm-2"
            type="search"
            [(ngModel)]="linkedSitesDataSource.search"
            name="search"
            placeholder="Search"
            aria-label="Search"/>

          <button
            mat-raised-button
            color="secondary"
            type="submit"
            class="mr-sm-2">
            <mat-icon>search</mat-icon> Search
          </button>
        </form>
      </div>

      <div class="mat-elevation-z8">
        <table
          mat-table
          [dataSource]="linkedSitesDataSource"
          matSort
          matSortActive="name"
          matSortDirection="asc"
          matSortDisableClear>

          <!-- Site Name -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <!-- Site Address -->
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Address</th>
            <td mat-cell *matCellDef="let element">{{ element.location }}</td>
          </ng-container>

          <!-- Hazardous Substance Quantity -->
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Quantity</th>
            <td mat-cell *matCellDef="let element">{{ element?.pivot?.quantity }}</td>
          </ng-container>

          <!-- Hazardous Substance Max Quantity Allowed -->
          <ng-container matColumnDef="max_quantity_allowed">
            <th mat-header-cell *matHeaderCellDef>Max Quantity Allowed</th>
            <td mat-cell *matCellDef="let element">{{ element?.pivot?.max_quantity_allowed }}</td>
          </ng-container>

          <!-- Hazardous Substance Location -->
          <ng-container matColumnDef="hs_location">
            <th mat-header-cell *matHeaderCellDef>Hazardous Substance Location</th>
            <td mat-cell *matCellDef="let element">{{ element?.pivot?.location }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="linkedSitesDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: linkedSitesDisplayedColumns"></tr>
        </table>

        <mat-paginator
          [length]="linkedSitesDataSource?.total | async"
          [pageSize]="linkedSitesDataSource.limit"
          [pageSizeOptions]="linkedSitesDataSource.pageSizeOptions"
          showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
