<mat-tab-group mat-stretch-tabs="true">
  <mat-tab label="Sign-In Form">

    <div class="container-fluid p-0 m-0" *ngIf="code && code.site_id; else noSiteForCode">
      <div class="row p-0 m-0">
        <div class="col-sm-12 col-md-6 p-0 m-0">
          <div class="p-3">
            <h5>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Info</h5>
            <p>{{ code.account.name }}</p>
            <p>{{ code.site.name }}</p>
            <p>{{ code.site.location }}</p>

            <h5>Emergency Evacuation Plan</h5>
            <p>DIAL {{ code.site.emergency_phone || '111' }} FOR FIRE, AMBULANCE, POLICE, GAS, CHEMICAL SPILLS.</p>
            <p>
              <b>
                When you hear the evacuation alarm, switch off all plant and equipment. <br>
                Proceed to the safe assembly point in a calm and orderly fashion by following the exit signs.<br>
                DO NOT take your belongings or use lifts during an evacuation.
              </b>
            </p>
            <p><b>Description of Alarm</b>: {{ code.site.alarm_description || 'A consistent alarm sounding throughout the site or building.' }}</p>
            <p *ngIf="code.site.assembly_location"><b>Assembly Location</b>: {{ code.site.assembly_location }}</p>
            <p *ngIf="code.site.emergency_phone"><b>Emergency Phone</b>: {{ code.site.emergency_phone }}</p>
            <p *ngIf="code.site.hospital_address"><b>Hospital Address</b>: {{ code.site.hospital_address }}</p>
            <p *ngIf="code.site.hospital_phone"><b>Hospital Phone</b>: {{ code.site.hospital_phone }}</p>
            <p *ngIf="code.site.medical_centre_address"><b>Medical Centre Address</b>: {{ code.site.medical_centre_address }}</p>
            <p *ngIf="code.site.medical_centre_phone"><b>Medical Centre Phone</b>: {{ code.site.medical_centre_phone }}</p>
            <p *ngIf="code.site.first_aid_kit_location"><b>First Aid Kit Location</b>: {{ code.site.first_aid_kit_location }}</p>
            <p *ngIf="code.site.aed_location"><b>AED (Automated External Defibrillator) Location</b>: {{ code.site.aed_location }}</p>
            <p *ngIf="code.site.fire_extinguisher_location"><b>Fire Extinguisher Location</b>: {{ code.site.fire_extinguisher_location }}</p>
            <p *ngIf="code.site.worksafe_phone"><b>WorkSafe Phone</b>: {{ code.site.worksafe_phone }}</p>
            <p *ngIf="code.site.civil_defence_phone"><b>Civil Defence Phone</b>: {{ code.site.civil_defence_phone }}</p>
            <p *ngIf="code.site.poison_centre_phone">Poison Centre Phone<b></b>: {{ code.site.poison_centre_phone }}</p>
            <p *ngIf="code.site.power_phone"><b>Power (Customer Service) Phone</b>: {{ code.site.power_phone }}</p>
            <p *ngIf="code.site.faults_phone"><b>24Hr Faults Phone</b>: {{ code.site.faults_phone }}</p>

            <ng-container *ngIf="['signin:induction', 'signin:all'].indexOf(code.site.site_access_option) > -1">
              <ng-container *ngIf="clearWhiteSpace(code.site.induction_content) != ''">
                <h5>Noticeboard</h5>
                <div [innerHTML]="code.site.induction_content"></div>
              </ng-container>

              <ng-container *ngIf="code.site.hazards.length > 0">
                <h5>Hazards & Risks</h5>
                <ul>
                  <li *ngFor="let hazard of code.site.hazards">{{ hazard.name }}</li>
                </ul>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="code.site.site_access_option == 'signin:all' && code.site.folders.length > 0">
              <h5>Documents</h5>
              <ul>
                <li *ngFor="let folder of code.site.folders">
                  {{ folder.name }}
                  <ul *ngIf="folder.files.length > 0">
                    <li *ngFor="let file of folder.files">
                      <a [href]="file.url" target="_blank">{{ file.name }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </ng-container>

            <hr>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 p-0 m-0">
          <div class="p-3">
            <p><small>Use the forms below to fill in your company name, email address, full name and mobile number.</small></p>
            <form #signin_f="ngForm" (ngSubmit)="onSignIn(signin_f)">
              <p>
                <mat-form-field class="full-width">
                  <mat-label>Company Name</mat-label>
                  <input matInput placeholder="Company Name" [(ngModel)]="signInFormData.company_name" name="company_name" type="text" [matAutocomplete]="signin_entity_name_auto" (keyup)="getEntityNames($event.target.value)" />
                  <mat-hint>Enter the name of your company or employer here.</mat-hint>
                  <mat-autocomplete #signin_entity_name_auto="matAutocomplete">
                    <mat-option *ngFor="let autoCompletedEntityName of autoCompletedEntityNames" [value]="autoCompletedEntityName">
                      {{ autoCompletedEntityName }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </p>

              <p>
                <mat-form-field class="full-width">
                  <mat-label>Email Address</mat-label>
                  <input matInput placeholder="Email Address" [(ngModel)]="signInFormData.email" name="email" type="email" [matAutocomplete]="signin_email_auto" (keyup)="getUserEmails($event.target.value, 'signin')" required email />
                  <mat-hint>Enter your email address here. This field has auto-completion based on past {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} visits</mat-hint>
                  <mat-autocomplete #signin_email_auto="matAutocomplete">
                    <mat-option *ngFor="let email  of autoCompletedUserEmails" [value]="email" (click)="onSelectUserEmail(email)">
                      {{ email  }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </p>

              <p>
                <mat-form-field class="full-width">
                  <mat-label>Full Name</mat-label>
                  <input matInput placeholder="Full Name" [(ngModel)]="signInFormData.contact_person" name="contact_person" type="text" required />
                  <mat-hint>Enter your first and last name here.</mat-hint>
                </mat-form-field>
              </p>

              <p>
                <mat-form-field class="full-width">
                  <mat-label>Mobile Number (Including Country Code)</mat-label>
                  <app-phone-input
                    [idInput]="mobile_input_id"
                    [numberInput]="signInFormData.mobile"
                    [countryCodeInput]="signInFormData.mobile_country_code"
                    [dialCodeInput]="signInFormData.mobile_dial_code"
                    [e164NumberInput]="signInFormData.mobile_e164"
                    [additionalClassInput]="'compact'"
                    (phoneDetailsOutput)="getOutputPhoneDetails($event)"
                    required="true">
                  </app-phone-input>
                  <mat-hint>Enter your mobile number here.</mat-hint>
                </mat-form-field>
              </p>

              <p>
                <mat-checkbox [(ngModel)]="signInFormData.acknowledged" matInput name="acknowledged" required>
              <span class="text-wrap">
                I agree to the
                <a [href]="utils.getTermsAndConditionsUrl()" target="_blank">Terms & Conditions</a>
                and
                <a [href]="utils.getPrivacyPolicyUrl()" target="_blank">Privacy Policy</a> and acknowledge that I understand the emergency evacuation plan outlined above.
              </span>
                </mat-checkbox>
              </p>

              <button type="submit" mat-raised-button [disabled]="!signin_f.valid || inProgress || mobile_error_state" color="primary" class="full-width">
                Sign In
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Sign-Out Form">
    <div class="p-3">
      <p><small>Use the input below to select your email address. Email address will only show if you are currently signed into the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}.</small></p>
      <form #signout_f="ngForm" (ngSubmit)="onSignOut(signout_f)">
        <p>
          <mat-form-field class="full-width">
            <mat-label>Email Address</mat-label>
            <input matInput placeholder="Email Address" [(ngModel)]="signOutFormData.autocomplete_value" name="email" type="email" [matAutocomplete]="signout_email_auto" (keyup)="getUserEmails($event.target.value, 'signout')" email />
            <mat-hint>Select your email address here. This field has auto-completion based on users signed into the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}.</mat-hint>
            <mat-autocomplete #signout_email_auto="matAutocomplete">
              <mat-option *ngFor="let email  of autoCompletedOnsiteUserEmails" [value]="email" (click)="onSelectOnsiteUserEmail(email)">
                {{ email  }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </p>

        <ng-container *ngIf="signOutFormData.id && signOutFormData.id > 0">
          <h5 class="mt-1">Selected User Details</h5>
          <mat-divider class="w-100 m-0 mb-3" [inset]="true"></mat-divider>

          <h6>Company Name</h6>
          <p>{{ signOutFormData.company_name }}</p>

          <h6>Full Name</h6>
          <p>{{ signOutFormData.contact_person }}</p>

          <h6>Email</h6>
          <p>{{ hideEmail(signOutFormData.email) }}</p>

          <h6>Mobile Number</h6>
          <p>{{ hideMobile(signOutFormData.mobile) }}</p>
        </ng-container>

        <div class="signout-button-row">
          <button class="mr-1" type="submit" mat-raised-button [disabled]="!signout_f.valid || inProgress || signOutFormData.id <= 0" color="primary">
            Sign Out
          </button>
          <button class="ml-1" type="button" mat-raised-button [disabled]="!signout_f.valid || inProgress || signOutFormData.id <= 0" color="secondary" (click)="onClear()">
            Clear Results
          </button>
        </div>
      </form>
    </div>
  </mat-tab>
</mat-tab-group>

<ng-template #noSiteForCode>
  <p>No {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} is associated with this code.</p>
</ng-template>
