<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button mat-raised-button routerLink="/sites" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
  </button>

  <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
  </button>

  <button mat-raised-button [routerLink]="utils.prepareSiteRelatedLink(site_id, child_id ? parent_id : null, 'edit/codes')" appMarginRight>
    <mat-icon>arrow_back</mat-icon> QR Codes
  </button>
</nav>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class="mr-auto">
    <button
      mat-raised-button
      [routerLink]="utils.prepareSiteRelatedLink(site_id, child_id ? parent_id : null, 'edit/codes/' + code.id + '/view')"
      *ngIf="code.id"
      appMarginRight>
      <mat-icon>visibility</mat-icon>
      View
    </button>

    <button
      mat-raised-button
      (click)="onRemove()"
      *ngIf="code.id"
      color="warn"
      appMarginRight >
      <mat-icon>delete</mat-icon>
      Delete
    </button>
  </span>
</nav>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
        <div class="card">
          <div class="card-body form-container">
            <h5 class="card-title">Codes Form</h5>

            <mat-form-field>
              <mat-select placeholder="Features" [(ngModel)]="code.feature" name="feature" required>
                <mat-option *ngFor="let feature of features" [value]="feature">
                  {{ feature }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-select placeholder="Type" [(ngModel)]="code.type" name="type" required>
                <mat-option *ngFor="let type of types[code.feature]" [value]="type">
                  {{ type }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                placeholder="Name"
                [(ngModel)]="code.name"
                name="name"
                type="text"
                #name
                required/>
              <mat-error *ngIf="!name.valid">Please enter a Name.</mat-error>
            </mat-form-field>

            <mat-form-field>
              <textarea
                matInput
                placeholder="Message"
                [(ngModel)]="code.message"
                name="message"
                #message
                rows="10">
              </textarea>
              <mat-error *ngIf="!message.valid">Please enter a message.</mat-error>
            </mat-form-field>

            <button
              type="button"
              mat-raised-button
              style="max-width: 100px;"
              [disabled]="!f.valid || inProgress"
              class="save-button"
              color="primary"
              (click)="onSubmit(f)">
              <mat-icon>save</mat-icon>
              Save
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="col-12 col-md-6">
      <h4>Instructions</h4>
      <p>
        Complete the form on the left to add a new or update an existing QR
        code.
      </p>
      <p>
        QR codes can be placed at {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} for {{ utils.getLangTerm('contractors.singular', 'Contractor') }} and {{ utils.getLangTerm('employees.plural', 'Employees') }}
        to scan.
      </p>
      <p>
        QR codes can contain confidential information. Only {{ utils.getLangTerm('contractors.singular', 'Contractor') }} and {{ utils.getLangTerm('employees.plural', 'Employees') }} that have access to your {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} can see the
        information.
      </p>
      <p>
        Enter a Name and a message. When the QR code is scanned,
        the person scanning it will see the information.
      </p>
      <p>
        There are various types of QR codes available namely in, out, in/out and
        location under access control.
      </p>
      <p>
        Scanning an <b>in</b> QR code will sign the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} or
        {{ utils.getLangTerm('employees.plural', 'Employees') }} in.
      </p>
      <p>
        Scanning an <b>out</b> QR code will sign the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} or
        {{ utils.getLangTerm('employees.plural', 'Employees') }} out.
      </p>
      <p>
        Scanning an <b>in/out</b> QR code will either scan the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} or {{ utils.getLangTerm('employees.plural', 'Employees') }} in or out depending on their current sign-in status.
      </p>
      <p>
        Scanning the <b>location</b> QR code will mark the {{ utils.getLangTerm('contractors.singular', 'Contractor') }}
        or {{ utils.getLangTerm('employees.singular', 'Employee') }}'s location.
      </p>
    </div>
  </div>
</div>
