<div mat-dialog-title>
  <h5 >{{ !ta_revised_workflow_id ? "Create" : "Edit" }} Step</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<app-task-analyses-revised-workflows-edit *ngIf="!ta_revised_workflow_id"></app-task-analyses-revised-workflows-edit>

<mat-tab-group *ngIf="ta_revised_workflow_id">
  <mat-tab label="Overview">
    <app-task-analyses-revised-workflows-edit ></app-task-analyses-revised-workflows-edit>
  </mat-tab>
  <mat-tab label="Hazards & Risks">
    <app-task-analyses-revised-workflows-hazards></app-task-analyses-revised-workflows-hazards>
  </mat-tab>
</mat-tab-group>

