<div class="container-fluid">

  <div class="mat-elevation-z8 scroll-box">
    <table
      class="w-100"
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="name"
      matSortDirection="asc"
      matSortDisableClear
    >
      <!-- ID Column -->
<!--      <ng-container matColumnDef="id">-->
<!--        <th mat-header-cell *matHeaderCellDef>#</th>-->
<!--        <td mat-cell *matCellDef="let folder">{{ folder.id }}</td>-->
<!--      </ng-container>-->

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let folder">{{ folder.name }}</td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="folder_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let folder">{{ folder.folder_type }}</td>
      </ng-container>

      <!-- Visibility Column -->
      <ng-container matColumnDef="visibility">
        <th mat-header-cell *matHeaderCellDef>Visibility</th>
        <td mat-cell *matCellDef="let folder">{{ folder.visibility }}</td>
      </ng-container>

      <!-- Expiry Column -->
      <ng-container matColumnDef="expiry">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiry Date</th>
        <td mat-cell *matCellDef="let folder">
          <span *ngIf="folder.expiry">
            {{ (folder.expiry * 1000) | timezoneConvertLong }}
          </span>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let folder">
          <button *ngIf="folder.links?.length" type="button" mat-button (click)="onViewLinks(folder.links, folder.name)" matTooltip="Click this button to view the Training/Competency Links.">
            View Links
          </button>

          <button *ngIf="folder.files?.length" type="button" mat-button (click)="onViewFiles(folder.files, folder.name)" matTooltip="Click this button to view the Training/Competency Files.">
            View Files
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions"
      showFirstLastButtons
    ></mat-paginator>
  </div>

</div>
