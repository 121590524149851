import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import {ActionsService} from "../../actions/actions.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";
import {NetworkedUsersSelectorComponent} from "../../shared/networked-users-selector/networked-users-selector.component";
import {
  UserAccessibleSitesSelectorComponent
} from "../user-accessible-sites-selector/user-accessible-sites-selector.component";
import {SiteModel} from "../../models/site.model";

@Component({
  selector: 'app-user-tasks-filter',
  templateUrl: './user-tasks-filter.component.html',
  styleUrls: ['./user-tasks-filter.component.scss']
})
export class UserTasksFilterComponent implements OnInit {

  dataSource = new ActionsFilterDataSource(); // No API calls needed.

  priorities = ['Low', 'Medium', 'High', 'Critical'];

  constructor(
    private app: AppService,
    public location: Location,
    public utils: UtilsService,
    private actionsService: ActionsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserTasksFilterComponent>
    // private api: ApiRequestService
  ) {
    // set a default site selection of nothing if no selected values are passed through
    if (typeof this.data['selected_site_ids'] === 'undefined') {
      this.data['selected_site_ids'] = [];
    }

    // set a default assigned users selection of nothing if no selected values are passed through
    if (typeof this.data['selected_assigned_users'] === 'undefined') {
      this.data['selected_assigned_users'] = [];
    }

    // set a default priority of nothing if no values are passed through
    if (typeof this.data['filter_priority'] === 'undefined') {
      this.data['filter_priority'] = "";
    }

    // set a default status of nothing if no values are passed through
    if (typeof this.data['filter_status'] === 'undefined') {
      this.data['filter_status'] = "";
    }

    // set a default due date of nothing if no values are passed through
    if (typeof this.data['due_date_range'] === 'undefined') {
      this.data['due_date_range'] = [];
    }
  }

  ngOnInit() {
    this.dataSource.selected_site_ids = this.data['selected_site_ids'];
    this.dataSource.selected_assigned_users = this.data['selected_assigned_users'];
    this.dataSource.filter_priority = this.data['filter_priority'];
    this.dataSource.filter_status = this.data['filter_status'];
    this.dataSource.due_date_range = this.data['due_date_range'];
  }

  /**
   * Get the due date range from the date range component.
   * @param $event
   */
  getOutputDateRange($event) {
    this.dataSource.due_date_range = $event;
  }

  /**
   * Open the sites' selector. This will show sites the user has access to.
   */
  onSelectSites() {
    // Open the accessible sites' selector.
    this.utils.showComponentDialog(UserAccessibleSitesSelectorComponent, {
      selected: [this.dataSource.selected_site_ids],
      multiple: false,
      return_object: false
    }).then((response: SiteModel | number | number[]) => {
      // Validate the selection response.
      if (typeof response !== 'number') { return; }
      this.dataSource.selected_site_ids = [response];
    });
  }

  /**
   * Open the users' selector.
   */
  onSelectUsers() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        selected: this.dataSource.selected_assigned_users,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.selected_assigned_users = results;
        }
      }
    );
  }

  /**
   * Clear the filters and close the dialog.
   */
  onClearAndClose() {
    this.dataSource.selected_site_ids = [];
    this.dataSource.selected_assigned_users = [];
    this.dataSource.filter_priority = "";
    this.dataSource.filter_status = "";
    this.dataSource.due_date_range = [];

    this.onSaveAndClose();
  }

  /**
   * Save the filters and close the dialog.
   */
  onSaveAndClose() {
    let response: any;

    response = {
      site_ids: this.dataSource.selected_site_ids,
      user_ids: this.dataSource.selected_assigned_users,
      priority: this.dataSource.filter_priority,
      status: this.dataSource.filter_status,
      due_date: this.dataSource.due_date_range
    }

    this.dialogRef.close(response);
  }

}

/**
 * the data source class should contain everything needed to get data for the feature.
 */
export class ActionsFilterDataSource {

  selected_site_ids: number[] = [];
  selected_assigned_users: number[] = [];
  filter_priority: string = "";
  filter_status: string = "";
  due_date_range: Date[] = [];

  constructor() {
    this.selected_site_ids = [];
    this.selected_assigned_users = [];
    this.filter_priority = "";
    this.filter_status = "";
    this.due_date_range = []
  }

}
