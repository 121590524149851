import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { merge } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {
  private readonly resourceUrl = 'file-uploader';

  constructor(private api: ApiService) {}

  public storeUploadedFiles(
    files: FileList,
    parentType: string,
    parentId: number
  ) {
    const requests = [];
    for (let i = 0; i < files.length; i++) {
      const form = new FormData();
      form.set('file', files[i], files[i].name);
      requests.push(
        this.api.laravelApiObservable(
          'post',
          this.resourceUrl + `/${parentType}/${parentId}`,
          form
        )
      );
    }
    return merge(...requests);
  }
}
