import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { tap } from 'rxjs/operators';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { IncidentNoteModel } from '../incident-note.model';
import { IncidentNotesEditComponent } from '../incident-notes/incident-notes-edit/incident-notes-edit.component';
import { IncidentNoteService } from '../incident-notes/incident-note.service';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-incidents-notes',
  templateUrl: './incidents-notes.component.html',
  styleUrls: ['./incidents-notes.component.scss']
})
export class IncidentsNotesComponent implements OnInit {
  @Input() private incidentId: number;
  @Input() public incidentStatus: string;

  displayedColumns: string[] = [
    'select',
    'id',
    'value',
    'created_by_name',
    'date_created',
    // 'date_created_UTC',
    'actions'
  ];
  dataSource = new IncidentNotesDataSource(this.app, this.api);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public app: AppService,
    public api: ApiService,
    public utils: UtilsService,
    private noteService: IncidentNoteService
  ) {}

  ngOnInit() {
    this.dataSource.incidentId = this.incidentId;
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // Resets the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns by their corresponding date columns.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData();
    });

    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.utils.showComponentDialog(
      IncidentNotesEditComponent,
      { incident_id: this.incidentId },
      {},
      (success: boolean) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      }
    );
  }

  onEdit(incidentNote: IncidentNoteModel) {
    this.utils.showComponentDialog(
      IncidentNotesEditComponent,
      {...incidentNote, incident_id: this.incidentId},
      {},
      (success: boolean) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      }
    );
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove Note',
      'Are you sure you want to remove this note?',
      () => {
        this.noteService.remove([id]).subscribe(() => {
          this.dataSource.selection.deselect(id);
          this.dataSource.getData();
          this.utils.showToast('The note was removed');
        });
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Remove Selected Notes',
      'Are you sure you want to remove the selected notes?',
      () => {
        this.noteService
          .remove(this.dataSource.selection.selected)
          .subscribe(() => {
            this.dataSource.selection.clear();
            this.dataSource.getData();
            this.utils.showToast('The selected notes were removed.');
          });
      }
    );
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }
}

export class IncidentNotesDataSource extends CustomDataSource {
  sort_by = 'value';
  sort_order = 'asc';
  incidentId: number;

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('incident-notes', resetOffset, () => {}, {
      incident_id: this.incidentId
    });
  }
}
