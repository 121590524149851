import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/shared/utils.service';
import { ItemModel } from './item.model';
import { ItemsService } from './items.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  @Input() items: ItemModel[];
  displayedColumns = ['name', 'sort_order', 'buttons'];

  constructor(
    public utils: UtilsService,
    private itemsService: ItemsService
  ) {}

  ngOnInit() {
    if (!this.items) {
      this.itemsService
        .get()
        .subscribe((items: ItemModel[]) => (this.items = items));
    }
  }

  public delete(item: ItemModel) {
    this.utils.showModal(
      'Remove Item',
      `Are you sure you want to remove "${item.name}"?`,
      () => {
        this.itemsService.delete(item.id).subscribe((response: ItemModel) => {
          this.utils.showToast(`Item "${response.name}" was removed`);
          this.refresh();
        });
      }
    );
  }

  private refresh() {
    return this.itemsService
      .get()
      .subscribe((items: ItemModel[]) => (this.items = items));
  }
}
