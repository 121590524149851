import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from '../../../utils/api-data-source';
import { ApiRequestService } from '../../../shared/api-request.service';
import { MatSort, Sort } from '@angular/material/sort';
import { EmployeesSelectorComponent } from '../../../employees/employees-selector/employees-selector.component';
import { UserPublicProfileComponent } from '../../../shared/user-public-profile/user-public-profile.component';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-notification-roles-users',
  templateUrl: './notification-roles-users.component.html',
  styleUrls: ['./notification-roles-users.component.scss']
})
export class NotificationRolesUsersComponent implements OnInit, AfterViewInit {

  // Get the notification role id.
  @Input('notification_role_id') notification_role_id: number;

  // Columns to show and the data source.
  displayedColumns: string[] = ['select', 'contact_person', 'email', 'mobile', 'trade', 'actions'];
  dataSource: NotificationRoleUsersDataSource = new NotificationRoleUsersDataSource(this.app, this.api, true);

  // The paginator and sorter.
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) {}

  ngOnInit(): void {
    // Store the notification role id before making API requests.
    this.dataSource.notification_role_id = this.notification_role_id;

    // Make the initial API request to get the list of users.
    this.dataSource.getData(true);
  }

  ngAfterViewInit(): void {
    // Reset the paginator when sorting.
    this.sort.sortChange.subscribe((): void => {
      this.paginator.pageIndex = 0;
    });

    const _tap: any = tap((): void => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      // Refresh the data.
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator.page.pipe(_tap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter: Sort): void => {
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData(true);
    })).subscribe();
  }

  /**
   * Opens the user selector dialog and syncs selected users with the notification role.
   *
   * @returns {void}
   */
  onSelectUsers(): void {
    // Show the user selector.
    this.utils.showComponentDialog(EmployeesSelectorComponent, {
        multiple: true,
        selected: this.dataSource.allIds
      }, {}, (user_ids: number[]): void => {
        if ( typeof user_ids !== 'undefined') {
          // Make an update request to the API.
          this.api.makeRequest('put', `v2/account/notification-roles/${this.notification_role_id}/users`, {
            user_ids: user_ids
          }).then((): void => {
            this.utils.showToast('The selected ' + this.utils.getLangTerm('employees.plural', 'Employees').toLowerCase() + ' were successfully synced to the notification role.');
          }).finally((): void => {
            this.dataSource.getData(true);
          });
        }
      }
    );
  }

  /**
   * Unlinks a user from the notification role.
   *
   * @param {number} user_id - The ID of the user to unlink.
   *
   * @param evt
   * @return {void}
   */
  onUnlinkUser(user_id: number, evt: any): void {
    this.utils.showQuickActions(evt.target, 'Are you sure you want to unlink the ' + this.utils.getLangTerm('employees.singular', 'Employee').toLowerCase() + ' from the notification role?', [
      {
        text: 'Yes',
        handler: (): void => {
          // Unlink the site.
          this.unlinkUsers([user_id]);
        }
      },
      {
        text: 'No',
        handler: (): void => { }
      }
    ]);
  }

  /**
   * Unlinks selected users from the notification role.
   *
   * @return {void}
   */
  onUnlinkSelectedUsers(): void {
    // Get the selected user ids.
    const ids: any[] = this.dataSource.selection.selected;
    // Check if we have users selected.
    if ( ids.length == 0 ) {
      this.utils.showModal('Unlink Users', 'Please select some ' + this.utils.getLangTerm('employees.plural', 'Employees').toLowerCase() + ' to unlink from the notification role.');
      return;
    }
    // Make a request to unlink the users from the notification role.
    this.utils.showModal('Unlink Users', `Are you sure you want to unlink (${ids.length}) ${this.utils.getLangTerm('employees.plural', 'Employees').toLowerCase()} from the notification role?`, (): void => {
      this.unlinkUsers(this.dataSource.selection.selected).then((): void => {
        // Clear the list selection.
        this.dataSource.selection.clear();
      });
    });
  }

  /**
   * This method unlinks the specified users from the notification role.
   *
   * @param {number[]} user_ids - An array of user IDs to unlink from the notification role.
   * @returns {Promise<any>} - A promise that resolves when the unlinking is complete.
   * @private
   */
  private unlinkUsers(user_ids: number[]): Promise<any> {
    // Make an API request to unlink users.
    return this.api.makeRequest('delete', `v2/account/notification-roles/${this.notification_role_id}/users`, {}, {
      user_ids: user_ids.join(',')
    }).then((): void => {
      this.utils.showToast('The ' + this.utils.getLangTerm('employees.plural', 'Employees').toLowerCase() + ' were detached from the notification role.');
      this.dataSource.getData();
    });
  }

  /**
   *
   * @param {string} hash - The unique identifier (hash) of the user's public profile to open
   * @param {Event} event - The event object triggering the method
   * @return {void}
   */
  onOpenUserPublicProfileView(hash: string, event: Event): void {
    // Prevent the default events and propagation from triggering.
    event.preventDefault();
    event.stopPropagation();
    // Open the user's public profile view.
    this.utils.showComponentDialog(UserPublicProfileComponent, hash, { width: '90%' });
  }
}

export class NotificationRoleUsersDataSource extends ApiDataSource {

  // Order and order by params.
  order_by: string = 'contact_person';
  order: string = 'asc';

  // Store the notification role id.
  notification_role_id: number;

  getData(resetOffset: boolean = false): void {
    this.makeRequest(`v2/account/notification-roles/${this.notification_role_id}/users`, resetOffset, {
      order_by: this.order_by,
      order: this.order
    });
  }
}
