<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button mat-raised-button routerLink="/sites" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
  </button>

  <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
  </button>
</nav>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">Inspections & Audits</span>

    <button class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <span class="mr-auto">

          <mat-menu #appMenu="matMenu">
            <button mat-menu-item (click)="dataSource.selectAllToggle()">
                <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon> {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
            </button>
            <button mat-menu-item (click)="onExportSiteAuditPDFs()" matTooltip="Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to PDF">
              <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to PDF
            </button>
            <button mat-menu-item (click)="onExportSiteAudits('csv')" matTooltip="Export Summary to CSV ({{ dataSource.selection.hasValue() ? dataSource.selection.selected.length + ' Selected' : 'All' }})">
              <mat-icon>cloud_download</mat-icon> Export Summary to CSV ({{ dataSource.selection.hasValue() ? dataSource.selection.selected.length + ' Selected' : 'All' }})
            </button>
            <button mat-menu-item (click)="onExportSiteAudits('xlsx')" matTooltip="Export Full Report to XLSX ({{ dataSource.selection.hasValue() ? dataSource.selection.selected.length + ' Selected' : 'All' }})">
              <mat-icon>cloud_download</mat-icon> Export Full Report to XLSX ({{ dataSource.selection.hasValue() ? dataSource.selection.selected.length + ' Selected' : 'All' }})
            </button>
            <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
              <mat-icon>delete</mat-icon> Delete Selected
            </button>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Inspections & Audits." style="min-width: 120px">
            <mat-icon>more_vert</mat-icon>
            Menu
          </button>

          <span class="spinner-container" *ngIf="(dataSource.loading | async)"
                appMarginRight>
          </span>
        </span>

        <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onFilter()" matTooltip="Click this button to display filter options.">
          <mat-icon>filter_list</mat-icon> Filter
        </button>

      <form
        class="form-inline my-2 my-lg-0" style="min-width: 325px"
        (ngSubmit)="dataSource.getData(true)">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search" />

        <button
          mat-raised-button
          class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
          color="secondary"
          (click)="dataSource.getData(false)"
          type="button"
          id="search">
          <mat-icon>search</mat-icon>Search
        </button>
      </form>
    </div>

  </nav>

  <app-site-audits-charts-container [baseStorageKey]="'site-audits-inspections-templates'" [parent_filters]="{ site_ids: [site_id] }"></app-site-audits-charts-container>

  <div class="mat-elevation-z8 scroll-box">
    <table
      mat-table
      [dataSource]="dataSource"
      style="width: 100%"
      matSort
      matSortActive="date_created"
      matSortDirection="desc"
      matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          #
        </th>
        <td mat-cell *matCellDef="let audits" class="no-padding">
          {{ audits.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
        </th>
        <td mat-cell *matCellDef="let audits" class="no-padding">
          <ng-container *ngIf="audits.site_id">
            <ng-container *ngIf="audits?.parent_site_id">
              <small style="display: block;">
                <a [routerLink]="utils.prepareSiteRelatedLink(audits?.parent_site_id, null, '/edit/details')">
                  {{ audits?.parent_site_name || '' }}
                </a>
              </small>
            </ng-container>
            <mat-icon *ngIf="audits?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
            <a [routerLink]="utils.prepareSiteRelatedLink(audits?.site_id, audits?.parent_site_id, '/edit/details')">
              {{ audits?.site_name || '' }}
            </a>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Inspection/Audit
        </th>
        <td mat-cell *matCellDef="let audits" class="no-padding text-wrap">
          <span [matTooltip]="audits.positive_comments">{{ audits.name }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="created_by_name">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Created By
        </th>
        <td mat-cell *matCellDef="let audits" class="no-padding">
          <a (click)="onUserPublicView(audits.created_by_hash)"
             matTooltip="View User Public Profile">
            {{ audits.created_by_name }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="created_by_employer">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Created By Employer
        </th>
        <td mat-cell *matCellDef="let audits" class="no-padding">
          {{ audits.created_by_employer }}
        </td>
      </ng-container>

      <ng-container matColumnDef="completed">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Completed
        </th>
        <td mat-cell *matCellDef="let audits" class="no-padding">
          {{ audits.completed ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="percentCompleted">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          % Completed
        </th>
        <td mat-cell *matCellDef="let audits" class="no-padding">
          {{ audits.percentCompleted }}%
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Date Created
        </th>
        <td mat-cell *matCellDef="let audits" class="no-padding">
          {{ audits.date_created * 1000 | timezoneConvertMedium }} <br/>
          {{ audits.date_created * 1000 | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Date Created(UTC)
        </th>
        <td mat-cell *matCellDef="let audits" class="no-padding">
          {{ audits.date_created * 1000 | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_modified">
        <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
          Last Updated At
        </th>
        <td mat-cell *matCellDef="let audits" class="no-padding">
          {{ audits.date_modified * 1000 | timezoneConvertMedium }} <br/>
          {{ audits.date_modified * 1000 | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let audits">
          <a type="button" mat-button [routerLink]="utils.prepareSiteRelatedLink(audits.site_id, audits?.site?.parent_site_id, 'edit/audits/' + audits.id + '/view')" matTooltip="View the Inspection/Audit information." matTooltipPosition="above">
            View
          </a>

          <a type="button" mat-button [routerLink]="utils.prepareSiteRelatedLink(audits.site_id, audits?.site?.parent_site_id, 'edit/audits/' + audits.id + '/edit')" matTooltip="Edit the Inspection/Audit information." matTooltipPosition="above">
            Edit
          </a>

          <button type="button" mat-button (click)="onRemove(audits)" matTooltip="Remove (Archive) the Inspection/Audit." matTooltipPosition="above">
            Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions"
      showFirstLastButtons>
    </mat-paginator>
  </div>
