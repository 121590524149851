import { CustomDataSource } from '../utils/custom-data-source';
export class AuditItemDataSource extends CustomDataSource {
  sort_by = 'name';
  sort_order = 'asc';
  auditId: number;
  template: boolean;
  section: string = '';

  getData(resetOffset = false) {
    this.getDataFromLaravelAPI(
      `audit-items?auditId=${this.auditId}`,
      resetOffset,
      null,
      {
        template: this.template,
        section: this.section
      }
    );
  }

  // This function might become redundant.
  getDataForSection(resetOffset = false, section: string) {
    this.getDataFromLaravelAPI(
      'audit-items',
      resetOffset,
      {},
      {
        auditId: this.auditId,
        section: section,
        template: this.template
      }
    );
  }
}
