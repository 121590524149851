import { CurrentTimezoneStateService } from './../shared/current-timezone-state.service';
import { Pipe, PipeTransform } from '@angular/core';
import * as momentTz from 'moment-timezone';
import * as moment from 'moment';

/**
 * timezoneConvertLong Pipe results in: Sep 10, 2021 5:12AM NZDT
 */
@Pipe({
  name: 'timezoneConvertLong'
})
export class TimezoneConvertPipeLong implements PipeTransform {
  constructor(private cTmzState: CurrentTimezoneStateService) {}

  transform(value: string | number, args?: any): any {
    const currentdate = new Date(value);
    let appCurrentTimezone = this.cTmzState.getCurrentTimezone();

    const localDate = moment
      .utc(currentdate)
      .tz(appCurrentTimezone)
      .format('MMM D, YYYY');

    const localTime = moment
      .utc(currentdate)
      .tz(appCurrentTimezone)
      .format('HH:mm'); // Using 24-hour format here

    const localAbbr = momentTz()
      .tz(appCurrentTimezone)
      .format('z');

    return `${localDate} at ${localTime} ${localAbbr}`;
  }
}

/**
 * timezoneConvertMedium Pipe results in: Sep 10, 2021 NZDT
 */
@Pipe({
  name: 'timezoneConvertMedium'
})
export class TimezoneConvertPipeMedium implements PipeTransform {
  constructor(private cTmzState: CurrentTimezoneStateService) {}

  transform(value: string | number, args?: any): any {
    const currentdate = new Date(value);
    let appCurrentTimezone = this.cTmzState.getCurrentTimezone();
    const local = moment
      .utc(currentdate)
      .tz(appCurrentTimezone)
      .format('MMM D, YYYY');
    const localAbbr = momentTz()
      .tz(appCurrentTimezone)
      .format('z');
    return local + ' ' + localAbbr;
  }
}

/**
 * timezoneConvertShort Pipe results in: 10/9/2021 NZDT
 */
@Pipe({
  name: 'timezoneConvertShort'
})
export class TimezoneConvertPipeShort implements PipeTransform {
  constructor(private cTmzState: CurrentTimezoneStateService) {}

  transform(value: string | number, args?: any): any {
    const currentdate = new Date(value);
    let appCurrentTimezone = this.cTmzState.getCurrentTimezone();
    const local = moment
      .utc(currentdate)
      .tz(appCurrentTimezone)
      .format('D/M/YYYY');
    const localAbbr = momentTz()
      .tz(appCurrentTimezone)
      .format('z');
    return local + ' ' + localAbbr;
  }
}

@Pipe({
  name: 'timezoneConvertUTCLong'
})
export class TimezoneConvertPipeUTCLong implements PipeTransform {
  constructor(private cTmzState: CurrentTimezoneStateService) {}

  transform(value: string | number, args?: any): any {
    const currentdate = new Date(value);
    const local = moment
      .utc(currentdate)
      .format('MMM D, YYYY h:mmA');
    return local + ' UTC';
  }
}

@Pipe({
  name: 'timezoneConvertUTCMedium'
})
export class TimezoneConvertPipeUTCMedium implements PipeTransform {
  constructor(private cTmzState: CurrentTimezoneStateService) {}

  transform(value: string | number, args?: any): any {
    const currentdate = new Date(value);
    const local = moment
      .utc(currentdate)
      .format('MMM D, YYYY');
    return local + ' UTC';
  }
}

@Pipe({
  name: 'timezoneConvertUTCShort'
})
export class TimezoneConvertPipeUTCShort implements PipeTransform {
  constructor(private cTmzState: CurrentTimezoneStateService) {}

  transform(value: string | number, args?: any): any {
    const currentdate = new Date(value);
    const local = moment
      .utc(currentdate)
      .format('D/M/YYYY');
    return local + ' UTC';
  }
}
