<nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="prequalification.id">
  <span class="mr-auto">
    <button mat-raised-button routerLink="../../">
      <mat-icon>arrow_back</mat-icon> Back
    </button>

    <button mat-raised-button color="danger" (click)="onDelete()" *ngIf="app.account.isMasterAccount()">
      <mat-icon>delete</mat-icon>
      Delete
    </button>
  </span>
</nav>

<div class="alert alert-info" role="alert" *ngIf="!app.account.isMasterAccount()">
  This Pre-Qualification is managed by "{{app.account.master_account.name}}".
  You can create the Pre-Qualification but will not be able to make changes to it.
  To make changes, please contact "{{app.account.master_account.name}}".
</div>

<form #form="ngForm" (ngSubmit)="onSubmit()">
  <div class="container-fluid" class="app-padding">
    <div class="row">
      <div class="col-4">

        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h4 id="title">Pre-Qualification</h4>
            </div>

            <mat-form-field>
              <input matInput placeholder="Name"
                [(ngModel)]="prequalification.name" name="name" type="text"
                required #name id="name" />
              <mat-error *ngIf="!name.valid">Please enter an Prequalification
                Pre-Qualification Name
              </mat-error>
            </mat-form-field>

            <mat-form-field appMarginBottom>
              <textarea matInput placeholder="Pre-Qualification Description"
                [(ngModel)]="prequalification.description" name="description"
                 #description id="description" rows="5"></textarea>
              <mat-hint>A description that will be presented to the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} when they complete their pre-qualification.</mat-hint>
            </mat-form-field>

            <app-timezone-select [timezoneInput]="selectedTimezone" (timezoneOutput)="getOutputTimezone($event)"></app-timezone-select>

            <mat-form-field>
              <input
                matInput
                [matDatepicker]="picker"
                placeholder="Pre-Qualification Expiry Date"
                readonly
                (click)="picker.open()"
                [(ngModel)]="expiryDate"
                name="expires_at"
                id="expires_at"
                [min]="currentDate" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false"></mat-datepicker>

              <button type="button" mat-button matSuffix mat-icon-button *ngIf="expiryDate"
                (click)="onRemoveExpiryDate($event)" matTooltip="Remove the Pre-Qualification Expiry Date.">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <div class="full-width" appMarginBottom>
              <mat-slide-toggle
                [(ngModel)]="prequalification.enable_notifications"
                name="enable_notifications"
                matTooltip="{{prequalification.enable_notifications ? 'Disable notifications for this pre-qualification' : 'Enable notifications for this pre-qualification'}}">
                Enable Pre-Qualification Notification
              </mat-slide-toggle>
            </div>

            <button mat-raised-button [disabled]="!form.valid || inProgress"
              color="primary" id="save" type="submit">
              <mat-icon>save</mat-icon>
              Save
            </button>

            <button mat-raised-button [matMenuTriggerFor]="menu" type="button" *ngIf="templates?.length"
              color="secondary">
              <mat-icon>file_copy</mat-icon>
              Copy From Template
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngFor="let template of templates"
                (click)="onSelectTemplate(template)">
                {{ template.name }}
              </button>
            </mat-menu>

          </div>
        </div>

      </div>
      <div class="col-7">
        <div class="row">
          <div class="col-12">
            <h4>Selectable Pre-Qualification Documents</h4>
          </div>
          <div class="col-4" *ngFor="let item of items; let i = index">
            <mat-checkbox (change)="onToggleItem(item.id)" [checked]="isItemSelected(item.id)">{{ item.name }}</mat-checkbox>
          </div>
        </div>
      </div>
    </div>

  </div>
</form>
