<form #f="ngForm">
  <div class="container-fluid p-3">
    <div class="row">
      <div class="col">
        <p>In addition to the <b>Details</b> and <b>Safety Plan Docs</b> (see tab above), you can include any of the following in the <b>Safety Plan Snapshots</b>.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-6">

        <ul class="checklist">
          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_noticeboard" name="include_site_noticeboard">Include the <b>Noticeboard</b>.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_emergency_evacuation_plan" name="include_site_emergency_evacuation_plan">Include the <b>Emergency Evacuation Plan</b>.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_documents" name="include_site_documents">Include the <b>Documents</b>.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_contractors" name="include_site_contractors">Include the list of <b>{{ utils.getLangTerm('contractors.plural', 'Contractors') }}</b>.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_contractor_sssps" name="include_site_contractor_sssps">Include all <b>Sub-Safety Plans</b> (Safety Plan Docs) of {{ utils.getLangTerm('contractors.plural', 'Contractors') }}.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_managers" name="include_site_managers">Include the list of <b>Managers</b>.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_hazards" name="include_site_hazards">Include the list of <b>Hazards & Risks</b>.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_hazardous_substances" name="include_site_hazardous_substances">Include the list of <b>Hazardous Substances</b>.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_task_analysis" name="include_site_task_analysis">Include the list of <b>Task Analysis Documents</b>.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_toolbox_talks" name="include_site_toolbox_talks">Include the list of <b>{{ utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') }}</b>.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_qrcodes" name="include_site_qrcodes">Include the list of <b>QR Codes</b>.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_editors" name="include_editors">Include the <b>"Prepared By / Edited By"</b> details.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_audits" name="include_site_audits">Include the list of <b>Inspections & Audits</b>.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_incidents" name="include_site_incidents">Include the list of <b>Incidents</b>.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_safety_observations" name="include_site_safety_observations">Include the list of <b>Safety Observations</b>.</mat-checkbox>
          </li>

          <li>
            <mat-checkbox [(ngModel)]="sssp.include_site_task_analyses_revised" name="include_site_task_analyses_revised">Include the list of <b>TA/JSA/SWMS</b> records.</mat-checkbox>
          </li>

        </ul>

      </div>

      <div class="col-6">

        <mat-form-field class="full-width mb-3">
          <mat-label>Short description of work:</mat-label>
          <textarea
            matInput
            rows="5"
            placeholder="Enter a short description of work here."
            [(ngModel)]="sssp.short_description"
            name="short_description"
            type="text"
            id="short_description"
            dusk="dusk_short_description" ></textarea>
          <mat-hint>
            Enter a short description of work here.
          </mat-hint>
        </mat-form-field>

        <mat-form-field class="full-width mb-3">
          <mat-label>Location of work:</mat-label>
          <textarea
            matInput
            rows="5"
            placeholder="Enter the location of work here."
            [(ngModel)]="sssp.location_of_work"
            name="location_of_work"
            type="text"
            id="location_of_work"
            dusk="dusk_location_of_work" ></textarea>
          <mat-hint>
            Enter the location of work here.
          </mat-hint>
        </mat-form-field>

      </div>
    </div>

    <div class="row">
      <div class="col">
        <button type="button" mat-raised-button color="primary" (click)="onSave(f)" matTooltip="Save the Safety Plan configuration." matTooltipPosition="above">
          Save
        </button>
      </div>
    </div>

  </div>
</form>
