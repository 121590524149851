import { ApiRequestService } from 'src/app/shared/api-request.service';
import { AppService } from 'src/app/app.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { site_statuses, site_types } from 'src/app/shared/shared-items';
import { UtilsService } from 'src/app/shared/utils.service';
import { NgForm } from '@angular/forms';
import { SiteModel } from 'src/app/models/site.model';

declare var google;

@Component({
  selector: 'app-admin-site-details-edit',
  templateUrl: './admin-site-details-edit.component.html',
  styleUrls: ['./admin-site-details-edit.component.scss']
})
export class AdminSiteDetailsEditComponent implements OnInit {
  /**
   * Is the form being submitted?
   */
  inProgress = false;

  // the site model placeholder
  site: SiteModel = new SiteModel();

  site_types: string[] = site_types;

  site_statuses: string[] = site_statuses;

  site_risk_assessments: string[] = [
    'very low',
    'low',
    'moderate',
    'high',
    'critical'
  ];

  estimatedProjectStartDate: moment.Moment;
  estimatedProjectEndDate: moment.Moment;

  constructor(
    // public service: SitesService,
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private apiRequest: ApiRequestService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    // get the site id
    this.site.id = Number(this.route.parent.snapshot.params['site_id']);

    // check if we are updating a site or creating a new site
    if (this.site.id) {
      // get the data from the api
      this.apiRequest
        .makeRequest('get', 'v2/admin/sites/' + this.site.id, {}, {}, {})
        .then((response) => {
          // apply the data
          this.site.apply(response.data);

          // Set the estimated site start date
          if (this.site.estimated_project_start_date) {
            this.estimatedProjectStartDate = moment.unix(
              this.site.estimated_project_start_date
            );
          }

          // Set the estimated site end date
          if (this.site.estimated_project_end_date) {
            this.estimatedProjectEndDate = moment.unix(
              this.site.estimated_project_end_date
            );
          }
        })
        .catch((error) => {
          this.utils.showModal('Error', error.message);
        });
    } else {
      // Load the Google Maps script and configure the map once it is loaded.
      this.utils.loadExternalScript('google-maps-script', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA91kej0UNf8eHR_O_iTY7oFZVFTSVxk2I&libraries=drawing,places,geometry', () => {
        // slight pause before populating map - wait for map to load
        setTimeout(() => {
          // set the default geofence bounds and coordinates
          const initBounds = new google.maps.LatLngBounds();
          initBounds.extend(new google.maps.LatLng(-36.848552, 174.762105));

          // add address lookup
          const autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('location')
          );
          autocomplete.setBounds(initBounds);
          autocomplete.addListener('place_changed', () => {
            // get the place
            const place = autocomplete.getPlace();
            // store the location
            this.site.location = place.formatted_address;
          });
        }, 300);
      });
    }
  }

  // get timezone output from timezone select component
  getOutputTimezone($event) {
    this.site.timezone = $event;
  }

  onSubmit(form: NgForm) {
    // make sure the form data is valid
    if (form.valid) {
      if (this.inProgress) {
        return;
      }

      this.inProgress = true;

      // Convert the estimated site start and end dates to a unix timestamps.
      if (this.estimatedProjectStartDate) {
        this.site.estimated_project_start_date = this.estimatedProjectStartDate.unix();
      } else {
        this.site.estimated_project_start_date = null;
      }

      if (this.estimatedProjectEndDate) {
        this.site.estimated_project_end_date = this.estimatedProjectEndDate.unix();
      } else {
        this.site.estimated_project_end_date = null;
      }

      // check if the site should be created or updated
      if (this.site.id) {
        this.apiRequest
          .makeRequest('put', 'v2/admin/sites/' + this.site.id, this.site, {}, {})
          .then((response) => {
            this.utils.showToast('The ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() + ' was updated.');
            this.inProgress = false;
          })
          .finally(() => (this.inProgress = false));
      } else {
        // create a new site

        this.apiRequest
          .makeRequest('post', 'v2/admin/sites', this.site, {}, {})
          .then((response) => {
            this.utils.showToast('The ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() + ' was created.');
            this.inProgress = false;

            // refresh the component to envoke change detection for the new route
            this.router
              .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
              .then(() => {
                this.router.navigate([
                  '/admin/sites',
                  response.data.id,
                  'edit',
                  'details'
                ]);
              });
          })
          .finally(() => (this.inProgress = false));
      }
    } else {
      this.utils.showModal(
        'Form Error',
        'Please make sure you enter all fields with valid information.'
      );
    }
  }

  /**
   * Show the project start and end dates if the status of the site is any of:
   * - Pre-Construction
   * - Under Construction
   * - Hand Over Complete
   *
   * @param site_status The status of the site.
   */
  showProjectStartEndDates(site_status: string) {
    return (
      ['Pre-Construction', 'Under Construction', 'Hand Over Complete'].indexOf(
        site_status
      ) > -1
    );
  }
}
