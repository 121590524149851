<span class="edit" *ngIf="edit; else showView">
  <span *ngFor="let filledStar of filledStars; let i = index">
    <mat-icon (click)="onRateDown(i)" matTooltip="Reduce or remove this rating">
      star
    </mat-icon>
  </span>
  <span *ngFor="let emptyStar of emptyStars; let i = index">
    <mat-icon (click)="onRateUp(i)" matTooltip="Increase this rating">
      star_border
    </mat-icon>
  </span>
</span>

<ng-template #showView>
  <span *ngFor="let filledStar of filledStars">
    <mat-icon>star</mat-icon>
  </span>
  <span *ngFor="let emptyStar of emptyStars">
    <mat-icon>star_border</mat-icon>
  </span>
</ng-template>