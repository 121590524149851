import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../app.service";
import {UtilsService} from "../utils.service";
import {tap} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ApiDataSource} from "../../utils/api-data-source";
import {ApiRequestService} from "../api-request.service";
import {
  DynamicFormsAssignedUsersFormViewComponent
} from "../../dynamic-forms/dynamic-forms-assigned-users-form-view/dynamic-forms-assigned-users-form-view.component";
import {DynamicFormModel} from "../../dynamic-forms/dynamic-form.model";

@Component({
  selector: 'app-user-public-profile-assigned-forms',
  templateUrl: './user-public-profile-assigned-forms.component.html',
  styleUrls: ['./user-public-profile-assigned-forms.component.scss']
})
export class UserPublicProfileAssignedFormsComponent implements OnInit {

  // The user hash that will be used to get the info.
  @Input() hash: string;

  // Used to determine if admin links should be included or not.
  @Input() includeAdminActions: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // The datasource object for list data.
  dataSource: UserPublicProfileAssignedFormsDataSource = new UserPublicProfileAssignedFormsDataSource(this.app, this.api);

  // The columns to display.
  displayedColumns: string[] = [
    'from_account',
    'title',
    'description',
    'dynamic_form_status',
    'assigned_on',
    'actions'
  ];

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    // Store the hash.
    this.dataSource.hash = this.hash;
    // Do not load data if the hash is missing.
    if ( this.dataSource.hash ) {
      this.dataSource.getData(true);
    }
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData(false); // TBD: Need to add sorting to the API endpoint.
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * Open the form view that will show user submitted answers.
   * @param form_id The form id that you want to view.
   * @param user_id The user id that you want to view.
   */
  onViewUserSubmittedAnswers(form_id: number, user_id: number) {
    // Open the dialog.
    this.utils.showComponentDialog(DynamicFormsAssignedUsersFormViewComponent, {
      form_id: form_id,
      user_id: user_id
    }, {
      minWidth: '60%'
    })
      .then(() => {
        // Refresh the list when the dialog closes.
        this.dataSource.getData(false);
      });
  }

  /**
   * Validates and checks what the status of the form submission is. It then
   * returns a corresponding color e.g.
   * - red = Assigned or Rejected.
   * - orange/yellow = In Progress or Work in Progress.
   * - green = Submitted or Completed.
   * - blue = Approved.
   * @returns
   * @param form The form object to check.
   */
  getFormSubmissionStatusColors(form: DynamicFormModel) {
    return {
      'text-danger': form && form.pivot && form.pivot.dynamic_form_status && ['Assigned', 'Rejected'].indexOf(form.pivot.dynamic_form_status) > -1,
      'text-warning': form && form.pivot && form.pivot.dynamic_form_status && ['Pending', 'In Progress', 'Approved: Work in Progress'].indexOf(form.pivot.dynamic_form_status) > -1,
      'text-success': form && form.pivot && form.pivot.dynamic_form_status && ['Submitted', 'Work Completed'].indexOf(form.pivot.dynamic_form_status) > -1,
      'text-info': form && form.pivot && form.pivot.dynamic_form_status && ['Approved'].indexOf(form.pivot.dynamic_form_status) > -1
    };
  }
}

export class UserPublicProfileAssignedFormsDataSource extends ApiDataSource {
  // The user hash.
  hash: string = '';

  // Sorting variables.
  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset = false) {
    // Do not load data if the hash is missing.
    if ( this.hash ) {
      this.makeRequest(`v2/user/${this.hash}/public-assigned-forms`, resetOffset);
    }
  }
}
