<div mat-dialog-title>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5>View Document Link</h5>
      </div>
      <div class="col">
        <button mat-icon-button class="float-right" mat-dialog-close>
          <mat-icon color="secondary">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>


<div class="text-wrap">
  <p><a target="_blank" [href]="folder_link.url">{{ folder_link.url }}</a></p>
</div>

<h6>Description</h6>
<p>{{ folder_link.description }}</p>

<ng-container *ngIf="folder_link.created_by_user">
  <h6>Created By</h6>
  <p>
    <a (click)="onUserPublicView(folder_link.created_by_user.hash)"
       matTooltip="View User Public Profile">
      {{ folder_link.created_by_user.contact_person }}
    </a>
  </p>
</ng-container>

<ng-container *ngIf="folder_link.date_created">
  <h6>Created At</h6>
  <p>{{ (folder_link.date_created * 1000) | timezoneConvertLong}}</p>
</ng-container>

<ng-container *ngIf="folder_link.updated_by_user">
  <h6>Last Updated By</h6>
  <p>
    <a (click)="onUserPublicView(folder_link.updated_by_user.hash)"
       matTooltip="View User Public Profile">
      {{ folder_link.updated_by_user.contact_person }}
    </a>
  </p>
</ng-container>

<ng-container *ngIf="folder_link.date_modified">
  <h6>Last Updated At</h6>
  <p>{{ (folder_link.date_modified * 1000) | timezoneConvertLong}}</p>
</ng-container>
