<div mat-dialog-title>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5>Work Permit</h5>
      </div>
      <div class="col">
        <button mat-icon-button class="float-right" mat-dialog-close>
          <mat-icon color="secondary">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>


<p>{{ workPermit.title }}</p>

<h6>Description</h6>
<p>{{ workPermit.description }}</p>

<ng-container>
  <h6>Industry</h6>
  <p>{{workPermit.industry ? workPermit.industry.name : "All"}}</p>
</ng-container>

<ng-container *ngIf="workPermit.created_by_user">
  <h6>Created By</h6>
  <p>
    <a (click)="onUserPublicView(workPermit.created_by_user.hash)"
      matTooltip="View User Public Profile">
      {{ workPermit.created_by_user.contact_person }}
    </a>
  </p>
</ng-container>

<ng-container *ngIf="workPermit.date_created">
  <h6>Created At</h6>
  <p>{{ (workPermit.date_created * 1000) | timezoneConvertLong }}</p>
</ng-container>

<ng-container *ngIf="workPermit.updated_by_user">
  <h6>Last Updated By</h6>
  <p>
    <a (click)="onUserPublicView(workPermit.updated_by_user.hash)"
      matTooltip="View User Public Profile">
      {{ workPermit.updated_by_user.contact_person }}
    </a>
  </p>
</ng-container>

<ng-container *ngIf="workPermit.date_modified">
  <h6>Last Updated At</h6>
  <p>{{ (workPermit.date_modified * 1000) | timezoneConvertLong }}</p>
</ng-container>

