<div mat-dialog-title>
  <h5>User Public Profile</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="Details">
      <app-user-public-profile-details [user]="user"></app-user-public-profile-details>
    </mat-tab>

    <mat-tab label="Training/Competencies">
      <app-user-public-profile-training-docs [hash]="hash"></app-user-public-profile-training-docs>
    </mat-tab>

    <mat-tab label="Assigned Forms">
      <app-user-public-profile-assigned-forms [hash]="hash"></app-user-public-profile-assigned-forms>
    </mat-tab>

    <mat-tab label="{{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }} Visited">
      <app-user-public-profile-sites-visited [hash]="hash" [includeAdminActions]="true"></app-user-public-profile-sites-visited>
    </mat-tab>

    <mat-tab label="{{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }} Worked On">
      <app-user-public-profile-sites-worked-on [hash]="hash" [includeAdminActions]="true"></app-user-public-profile-sites-worked-on>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
  <span class="mr-auto"></span>

  <a [href]="shareLink" target="_blank" class="float-right">
    <mat-icon>open_in_new</mat-icon>
    Share
  </a>

  <button mat-icon-button
    ngxClipboard
    [cbContent]="shareLink"
    (click)="notifyCopied()"
    class="float-right"
    matTooltip="Copy link to clipboard">
    <mat-icon>file_copy</mat-icon>
  </button>
</mat-dialog-actions>
