import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { CustomDataSource } from '../../utils/custom-data-source';
import { HazardModel } from 'src/app/models/hazard.model';
import { HazardsEditComponent } from '../hazards-edit/hazards-edit.component';
import { HazardsViewComponent } from '../hazards-view/hazards-view.component';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { HazardsFilterComponent } from "../hazards-filter/hazards-filter.component";
import {ChartsUtilsService} from "../../charts/charts-utils.service";
import {
  AccountSitesHazardsSelectorComponent
} from "../../shared/account-sites-hazards-selector/account-sites-hazards-selector.component";

@Component({
  selector: 'app-hazards',
  templateUrl: './hazards.component.html',
  styleUrls: ['./hazards.component.css']
})
export class HazardsComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'site',
    'name',
    'status',
    'ira',
    'rra',
    'controls',
    'date_created',
    // 'date_created_UTC',
    // 'created_by',
    'reviewer_date',
    'actions'
  ];
  dataSource = new HazardsDataSource(this.app, this.oldApi);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    // public service: HazardsService,
    public utils: UtilsService,
    public app: AppService,
    private oldApi: ApiService,
    public renderer: Renderer2,
    private dialog: MatDialog,
    private api: ApiRequestService,
    public chartsUtils: ChartsUtilsService
  ) { }

  ngOnInit() {
    // this.service.get(0, true);
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.dataSource.sort_by = "date_created";
      } else {
        this.dataSource.sort_by = this.sort.active;
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onEdit(new HazardModel());
  }

  onEdit(hazard: HazardModel) {
    this.dialog
      .open(HazardsEditComponent, {
        data: { ...hazard }
      })
      .afterClosed()
      .subscribe((success) => {
        // if (!success) { return; } // Removed to update list on close
        this.dataSource.getData(true);
      });
  }

  onView(hazard: HazardModel) {
    this.dialog
      .open(HazardsViewComponent, {
        data: hazard
      })
      .afterClosed()
      .subscribe((success) => {
        if (!success) { return; }
        this.dataSource.getData(true);
      });
  }

  onClearSearch() {
    this.dataSource.search = '';
    this.dataSource.getData(true);
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Archive Hazard/Risk',
      'Are you sure you want to archive this Hazard/Risk template?',
      () => {
        this.oldApi.laravelApiRequest(
          'delete',
          'hazards/' + id,
          {},
          {},
          (response) => {
            this.utils.showToast('The Hazard/Risk template was archived.');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  onRestore(id: number) {
    this.utils.showModal(
      'Restore Hazard/Risk template',
      'Are you sure you want to restore this Hazard/Risk template?',
      () => {
        this.oldApi.laravelApiRequest(
          'put',
          'hazards/' + id + '/restore',
          {},
          {},
          (response) => {
            this.utils.showToast('The Hazard/Risk template was restored.');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );

      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Archive Hazard & Risk Templates',
      'Are you sure you want to archive the selected Hazard & Risk templates?',
      () => {
        this.oldApi.laravelApiRequest(
          'delete',
          'hazards/' + this.dataSource.selection.selected.join(','),
          {},
          {},
          (response) => {
            this.utils.showToast('The selected Hazard & Risk templates was archived.');
            this.dataSource.selection.clear();
            this.dataSource.getData();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  onRestoreSelected() {
    this.utils.showModal(
      'Restore Selected Hazard & Risk templates',
      'Are you sure you want to restore the selected Hazard & Risk templates?',
      () => {
        this.oldApi.laravelApiRequest(
          'put',
          'hazards/' + this.dataSource.selection.selected.join(',') + '/restore',
          {},
          {},
          (response) => {
            this.utils.showToast('The selected Hazard & Risk templates was restored.');
            this.dataSource.selection.clear();
            this.dataSource.getData();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );

      }
    );
  }

  /**
   * Exports the list of hazards into the specified format and sends a download request to the browser.
   * @param type csv, xls, xlsx, pdf.
   * Last updated by Kobus Beets on 13/1/2021.
   */
  onExportSelected(type: string = 'xlsx') {
    this.api.makeDownloadRequest(`v1/hazards/export/${type}` + (this.dataSource.selection.selected.length ? '/' + this.dataSource.selection.selected.join(',') : ''), {}, {
      site_ids: this.dataSource.site_ids.join(','),
      order_by: this.dataSource.sort_by,
      order: this.dataSource.sort_order
    })
      .then((response) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // Get the current date object
        const date = new Date();

        // Create object url to handle file downloads
        const data = window.URL.createObjectURL(response);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = `hazards-risks-${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}.${type}`;
        // Initiate the download
        downloadLink.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 300); // Minimum 300 miliseconds
      })
      .catch((errorResponse) => {
        this.utils.showModal('Error', errorResponse.message);
      });
  }

  onTriggerFileSelector(evt: Event) {
    if(this.fileInput) {
      const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
      this.fileInput.nativeElement.dispatchEvent(clickEvt);
    }
  }

  onImport(evt: any) {
    if (evt.target.files.length > 0) {
      const files: any = [];
      for (let i = 0; i < evt.target.files.length; i++) {
        files.push(evt.target.files[i]);
      }

      this.utils.showModal('Import Hazard & Risk Templates', 'Are you sure you want to import the Hazard & Risk templates from the selected files? It is recommended to use the same format as the Hazard & Risk templates exports. You will need to remove any duplicated Hazard & Risk templates from the list after importing.', () => {
        this.oldApi.laravelApiUploadFiles('hazards/import', files, (response) => {
          this.utils.showToast('Import completed successfully.');
          this.dataSource.getData(true);
        });
      })

      // clear the target value
      evt.target.value = '';
    }
  }

  onCreateFrom(hazard: HazardModel) {
    const newHazard: HazardModel = new HazardModel();
    newHazard.createFrom(hazard);
    this.onEdit(newHazard);
  }

  onFilter() {
    this.utils.showComponentDialog(
      HazardsFilterComponent,
      {
        site_ids: this.dataSource.site_ids,
        archived: this.dataSource.archived
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.dataSource.site_ids = results.site_ids ? results.site_ids : this.dataSource.site_ids;
          this.dataSource.archived = results.archived ? results.archived : this.dataSource.archived;
          this.dataSource.getData();
        }
      }
    );
  }

  onSelectHazards() {
    this.utils.showComponentDialog(
      AccountSitesHazardsSelectorComponent,
      {
        selected: [],
        multiple: true,
        selectedAccountId: this.app.account.id,
        site_id: 0
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        if(results.length > 0) {
          this.onCopySiteHazards(results.join(','));
        }
      }
    );
  }

  onCopySiteHazards(selected_hazard_ids) {
    this.api.makeRequest('put', `v2/hazards/templates/copy-site-hazards`, [], {
      selected_hazard_ids: selected_hazard_ids
    })
      .then((response) => {
        this.dataSource.getData(true);
        this.utils.showToast(this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site') + ' Hazards were copied to Templates.');
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class HazardsDataSource extends CustomDataSource {
  // Record sorting and direction
  sort_by: string = 'name';
  sort_order: string = 'asc';

  site_ids: number[] = [];
  archived = 'false';

  getData(resetOffset: boolean = false) {
    // this.getDataFromAPI('hazards/' + this.app.activeSiteId, resetOffset);
    this.getDataFromLaravelAPI(
      'hazards' +
      (this.site_ids
        ? '?site_ids=' + this.site_ids.join(',')
        : ''),
      resetOffset,
      () => { },
      {
        eager_load: true,
        archived: this.archived ? this.archived : 'false'
      }
    );
  }
}
