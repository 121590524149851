import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '../directives/directives.module';
import { ToolboxTalksEditDialogComponent } from './toolbox-talks-edit-dialog/toolbox-talks-edit-dialog.component';
import { ToolboxTalksEditComponent } from './toolbox-talks-edit/toolbox-talks-edit.component';
import { ToolboxTalksRoutingModule } from './toolbox-talks-routing.module';
import { ToolboxTalksSelectorComponent } from './toolbox-talks-selector/toolbox-talks-selector.component';
import { ToolboxTalkViewComponent } from './toolbox-talks-view/toolbox-talks-view.component';
import { ToolboxTalksComponent } from './toolbox-talks/toolbox-talks.component';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { FilesModule } from '../files/files.module';
import { ToolboxTalksFilterComponent } from './toolbox-talks-filter/toolbox-talks-filter.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from "../shared/shared.module";
import { ToolboxTalksTabsComponent } from './toolbox-talks-tabs/toolbox-talks-tabs.component';
import { ToolboxTalksCorrectiveActionsComponent } from './toolbox-talks-corrective-actions/toolbox-talks-corrective-actions.component';
import { ToolboxTalksCorrectiveActionsFilterComponent } from './toolbox-talks-corrective-actions-filter/toolbox-talks-corrective-actions-filter.component';
import { MatSelectModule } from "@angular/material/select";
import { EditorModule } from '@tinymce/tinymce-angular';
import {ChartsModule} from "../charts/charts.module";

@NgModule({
  declarations: [
    ToolboxTalksComponent,
    ToolboxTalksEditComponent,
    ToolboxTalksEditDialogComponent,
    ToolboxTalksSelectorComponent,
    ToolboxTalkViewComponent,
    ToolboxTalksFilterComponent,
    ToolboxTalksTabsComponent,
    ToolboxTalksCorrectiveActionsComponent,
    ToolboxTalksCorrectiveActionsFilterComponent
  ],
    imports: [
        CommonModule,
        ToolboxTalksRoutingModule,
        FormsModule,
        MatBadgeModule,
        MatMenuModule,
        MatListModule,
        MatDialogModule,
        MatTabsModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        DirectivesModule,
        FilesModule,
        MatTooltipModule,
        BsDatepickerModule,
        PipesModule,
        SharedModule,
        MatSelectModule,
        EditorModule,
        ChartsModule
    ],
  exports: [
    ToolboxTalksCorrectiveActionsComponent
  ],
  entryComponents: [
    SitesSelectorComponent,
    ToolboxTalksFilterComponent,
    ToolboxTalksCorrectiveActionsFilterComponent
  ]
})
export class ToolboxTalksModule {}
