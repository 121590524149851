import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import { SiteModel } from 'src/app/models/site.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-sites-tabs',
  templateUrl: './sites-tabs.component.html',
  styleUrls: ['./sites-tabs.component.scss']
})
export class SitesTabsComponent implements OnInit {

  activeTab = 'hazards';

  parent_id: number;
  child_id: number;
  site_id: number;

  // site reference
  site: SiteModel = new SiteModel();

  constructor(
    public route: ActivatedRoute,
    public app: AppService,
    private api: ApiService,
    public router: Router,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      // get the site
      this.api.laravelApiRequest(
        'get',
        'sites/' + this.site_id,
        {},
        {},
        (response) => {
          this.site.apply(response.data);
        },
        (errorResponse) => {
          this.utils.handleAPIErrors(errorResponse);
          // Redirect the user back to where they came from.
          this.utils.goBack();
        }
      );
    }
  }

  composeNewMessage(siteId: number = null) {
    if ( !siteId && !this.site.id ) {
      this.utils.showToast('You need to open a site first.');
      return; // early termination
    }

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectSiteIds: [siteId]
    })
    .then(() => {
      this.utils.showToast('You can view the message stats in the messages section.');
    });
  }
}
