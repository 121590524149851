<div mat-dialog-title>
  <h5 *ngIf="!ta_revised.id">New TA/JSA/SWMS Template</h5>
  <h5 *ngIf="ta_revised.id">Update TA/JSA/SWMS Template</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit(form)">

  <mat-dialog-content class="mb-3">
    <mat-form-field class="mb-3">
      <input
        matInput
        placeholder="Name"
        [(ngModel)]="ta_revised.task_name"
        name="task_name"
        type="text"
        required
        #task_name
        matTooltip="Enter a TA/JSA/SWMS template name."/>
      <mat-hint>Please enter a TA/JSA/SWMS template name.</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="2"
        matInput
        placeholder="Description"
        [(ngModel)]="ta_revised.task_description"
        name="task_description"
        required
        #task_description
        matTooltip="Enter a TA/JSA/SWMS template description.">
      </textarea>
      <mat-hint>Please enter a TA/JSA/SWMS template description.</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <input
        matInput
        placeholder="Work Procedure"
        [(ngModel)]="ta_revised.work_procedure"
        name="work_procedure"
        type="text"
        #work_procedure />
      <mat-hint>Enter the Work Procedure of this TA/JSA/SWMS.</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Training/Qualifications"
        [(ngModel)]="ta_revised.training_qualifications"
        name="training_qualifications"
        #training_qualifications>
      </textarea>
      <mat-hint>Enter the Training/Qualifications needed for this TA/JSA/SWMS. (Seperated by a new line)</mat-hint>
    </mat-form-field>

<!--    <mat-form-field class="mb-3">-->
<!--      <textarea-->
<!--        rows="4"-->
<!--        matInput-->
<!--        placeholder="Qualifications"-->
<!--        [(ngModel)]="ta_revised.qualifications"-->
<!--        name="qualifications"-->
<!--        #qualifications>-->
<!--      </textarea>-->
<!--      <mat-hint>Enter the Qualifications needed for this TA/JSA/SWMS. (Seperated by a new line)</mat-hint>-->
<!--    </mat-form-field>-->

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Duties"
        [(ngModel)]="ta_revised.duties"
        name="duties"
        #duties>
      </textarea>
      <mat-hint>Enter the Duties to undertake for this TA/JSA/SWMS. (Seperated by a new line)</mat-hint>
    </mat-form-field>

<!--    <mat-form-field class="mb-3">-->
<!--      <textarea-->
<!--        rows="4"-->
<!--        matInput-->
<!--        placeholder="Training"-->
<!--        [(ngModel)]="ta_revised.training"-->
<!--        name="training"-->
<!--        #training>-->
<!--      </textarea>-->
<!--      <mat-hint>Enter the Training required for this TA/JSA/SWMS. (Seperated by a new line)</mat-hint>-->
<!--    </mat-form-field>-->

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Certificates of Competency"
        [(ngModel)]="ta_revised.certificates"
        name="certificates"
        #certificates>
      </textarea>
      <mat-hint>Enter the Certificates of Competency required for this TA/JSA/SWMS. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Code of Practice"
        [(ngModel)]="ta_revised.code_of_practice"
        name="code_of_practice"
        #code_of_practice>
      </textarea>
      <mat-hint>Enter the Code of Practice for this TA/JSA/SWMS. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Maintenance Checks"
        [(ngModel)]="ta_revised.maintenance_checks"
        name="maintenance_checks"
        #maintenance_checks>
      </textarea>
      <mat-hint>Enter the Maintenance Checks required for this TA/JSA/SWMS. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Safety Practice"
        [(ngModel)]="ta_revised.safety_practices"
        name="safety_practices"
        #safety_practices>
      </textarea>
      <mat-hint>Enter the Safety Practices for this TA/JSA/SWMS. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="PPE Other"
        [(ngModel)]="ta_revised.ppe_other"
        name="ppe_other"
        #ppe_other>
      </textarea>
      <mat-hint>Enter Other PPE required for this TA/JSA/SWMS. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Work Permits Other"
        [(ngModel)]="ta_revised.work_permits_other"
        name="work_permits_other"
        #work_permits_other>
      </textarea>
      <mat-hint>Enter Other Work Permits required for this TA/JSA/SWMS. (Seperated by a new line)</mat-hint>
    </mat-form-field>

    <mat-form-field class="mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Tools Other"
        [(ngModel)]="ta_revised.tools_other"
        name="tools_other"
        #tools_other>
      </textarea>
      <mat-hint>Enter Other Tools required for this TA/JSA/SWMS. (Seperated by a new line)</mat-hint>
    </mat-form-field>
  </mat-dialog-content>

  <button
    mat-raised-button
    [disabled]="!form.valid || inProgress || !ta_revised.id"
    (click)="onSubmit(form, false)"
    color="primary"
    type="button"
    id="save">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    mat-raised-button
    [disabled]="!form.valid || inProgress"
    class="margin-right"
    color="primary"
    type="submit"
    id="save_close">
    <mat-icon>save</mat-icon>
    Save & Close
  </button>

  <button
    mat-raised-button
    type="button"
    (click)="selectPPE()"
    [matBadge]="'' + (ta_revised.ppe_ids?.length)"
    class="margin-right"
    color="secondary"
    matTooltip="Select PPE from the PPE selection list.">
    <mat-icon>headset</mat-icon>
    PPE
  </button>

  <button
    mat-raised-button
    type="button"
    (click)="selectWorkPermits()"
    [matBadge]="'' + (ta_revised.work_permit_ids?.length)"
    class="margin-right"
    color="secondary"
    matTooltip="Select Work Permits from the Work Permits selection list.">
    <mat-icon>assignment_ind</mat-icon>
    Work Permits
  </button>

  <button
    type="button"
    mat-icon-button
    [disabled]="!ta_revised.id"
    class="float-right"
    (click)="onDelete()">
    <mat-icon>archive</mat-icon>
  </button>
</form>

