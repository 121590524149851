<div mat-dialog-title>
  <div class="d-flex">
    <h3>Hazardous Substances</h3>
    <span style="flex: 1 1 auto;"></span>
    <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2"
        type="search"
        [(ngModel)]="dataSource.search"
        name="search"
        placeholder="Search"
        aria-label="Search"/>

      <button mat-raised-button class="mr-sm-2" type="submit">
        <mat-icon>search</mat-icon> Search
      </button>

      <button
        mat-raised-button
        class="mr-sm-2"
        (click)="onClearSelected()"
        type="button">
        Clear Selected
      </button>
    </form>
  </div>
</div>

<mat-dialog-content>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="substance"
    matSortDirection="asc"
    matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef width="65px">
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
        </mat-checkbox>
      </th>

      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)"
          appMarginRight>
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        #
      </th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ element.id }}
      </td>
    </ng-container>

    <!-- Hazardous Substance -->
    <ng-container matColumnDef="substance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Hazardous Substance
      </th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ element.substance }}
      </td>
    </ng-container>

    <!-- Hazardous Substance Type -->
    <ng-container matColumnDef="hazardous_substance_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by hazardous substance type">
        Type
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.hazardous_substance_type }}
      </td>
    </ng-container>

    <!-- Hazardous Substance Unit Type -->
    <ng-container matColumnDef="hazardous_substance_unit_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by hazardous substance unit type">
        Unit Type
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.hazardous_substance_unit_type }}
      </td>
    </ng-container>

    <!-- Date Created Column -->
    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Date Created
      </th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ (element.date_created * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <!-- Date Created (UTC) Column -->
    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Date Created (UTC)
      </th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ (element.date_created * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="dataSource.selection.toggle(row.id)">
    </tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <mat-paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>

  <button
    mat-raised-button
    color="primary"
    type="button"
    mat-dialog-close
    (click)="onSaveAndClose()"
    [disabled]="!data['multiple'] && !dataSource.selection.hasValue()">
    Select & Close
  </button>
</mat-dialog-actions>
