<ng-container matColumnDef="password_strength_indicator">
  <div class="password-strength" id="passwordStrengthMeter">
    <span [ngClass]="poorPasswordClass" id="poor"></span>
    <span [ngClass]="averagePasswordClass" id="average"></span>
    <span [ngClass]="goodPasswordClass" id="good"></span>
    <span [ngClass]="veryGoodPasswordClass" id="very-good"></span>
  </div>
  <div class="password-strength-requirements my-3">
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
      <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
      </symbol>

      <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
      </symbol>
    </svg>

    <div [ngClass]=" getAlertClass(isLengthValid) " role="alert" id="lengthMessage" style="margin-bottom: 0 !important;">
      <svg class="bi flex-shrink-0 me-2" width="16" height="16" role="img"
           [attr.aria-label]=" isLengthValid ? 'Success:' : 'Danger:' ">
        <use *ngIf="!isLengthValid" xlink:href="#exclamation-triangle-fill" />
        <use *ngIf="isLengthValid" xlink:href="#check-circle-fill" />
      </svg>
      <div class="pl-2">
        Password must be at least {{ minCharacters }} characters long
      </div>
    </div>
    <div class="password-strength-info-msg">Character length can't exceed {{ maxCharacters }} characters</div>

    <div [ngClass]=" getAlertClass(containsLowerCaseLetter) " role="alert" id="lowerCaseLetter" style="margin-top: 0.75rem">
      <svg class="bi flex-shrink-0 me-2" width="16" height="16" role="img"
           [attr.aria-label]=" containsLowerCaseLetter ? 'Success:' : 'Danger:' ">
        <use *ngIf="!containsLowerCaseLetter" xlink:href="#exclamation-triangle-fill" />
        <use *ngIf="containsLowerCaseLetter" xlink:href="#check-circle-fill" />
      </svg>
      <div class="pl-2">
        Password must contain a lower case letter
      </div>
    </div>

    <div [ngClass]=" getAlertClass(containsUpperCaseLetter) " role="alert" id="upperCaseLetter">
      <svg class="bi flex-shrink-0 me-2" width="16" height="16" role="img"
           [attr.aria-label]=" containsUpperCaseLetter ? 'Success:' : 'Danger:' ">
        <use *ngIf="!containsUpperCaseLetter" xlink:href="#exclamation-triangle-fill" />
        <use *ngIf="containsUpperCaseLetter" xlink:href="#check-circle-fill" />
      </svg>
      <div class="pl-2">
        Password must contain a upper case letter
      </div>
    </div>

    <div [ngClass]=" getAlertClass(containsNumber) " role="alert" id="numberCharacter">
      <svg class="bi flex-shrink-0 me-2" width="16" height="16" role="img"
           [attr.aria-label]=" containsNumber ? 'Success:' : 'Danger:' ">
        <use *ngIf="!containsNumber" xlink:href="#exclamation-triangle-fill" />
        <use *ngIf="containsNumber" xlink:href="#check-circle-fill" />
      </svg>
      <div class="pl-2">
        Password must contain a number
      </div>
    </div>

    <div *ngIf="specialCharactersAllowed" [ngClass]=" getAlertClass(containsSpecialCharacter) " role="alert" id="specialCharacter" style="margin-bottom: 0 !important;">
      <svg class="bi flex-shrink-0 me-2" width="16" height="16" role="img"
           [attr.aria-label]=" containsSpecialCharacter ? 'Success:' : 'Danger:' ">
        <use *ngIf="!containsSpecialCharacter" xlink:href="#exclamation-triangle-fill" />
        <use *ngIf="containsSpecialCharacter" xlink:href="#check-circle-fill" />
      </svg>
      <div class="pl-2">
        Password must contain a special character
      </div>
    </div>
    <div *ngIf="specialCharactersAllowed" class="password-strength-info-msg">Special Characters allowed: {{ specialCharacters }}</div>
  </div>
</ng-container>
