<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button mat-raised-button routerLink="/sites" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>

    <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
    </button>
  </nav>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">
      {{ utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') }}
    </span>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button
          mat-raised-button
          color="primary"
          appMarginRight
          (click)="onEdit('new')">
          <mat-icon>add</mat-icon>
          New
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>

          <button mat-menu-item (click)="onExport('pdf')">
            <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to PDF
          </button>

          <button mat-menu-item (click)="onExport('csv')">
            <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
          </button>

          <button mat-menu-item (click)="onDeleteSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" [matTooltip]="'Use this menu to select/deselect and/or export ' + utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks') + '.'" style="min-width: 150px">
          <mat-icon>more_vert</mat-icon>
          Menu
        </button>
      </span>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onFilter()" matTooltip="Click this button to display filter options.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (ngSubmit)="dataSource.getData(true)">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search"/>

        <button
          mat-raised-button
          color="secondary"
          type="submit"
          class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <app-toolbox-talks-charts-container [baseStorageKey]="'toolbox-talks'" [parent_filters]="{ site_ids: [site_id] }"></app-toolbox-talks-charts-container>

  <div class="mat-elevation-z8 scroll-box">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="date_created"
      matSortDirection="desc"
      matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="65px">
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Site Column -->
      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
        </th>
        <td mat-cell *matCellDef="let element" class="text-wrap">
          <ng-container *ngIf="element.site">
            <ng-container *ngIf="element?.site?.parent_site">
              <small style="display: block;">
                <a [routerLink]="utils.prepareSiteRelatedLink(element?.site?.parent_site?.id, null, '/edit/details')">
                  {{ element?.site?.parent_site?.name || '' }}
                </a>
              </small>
            </ng-container>
            <mat-icon *ngIf="element?.site?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
            <a [routerLink]="utils.prepareSiteRelatedLink(element?.site?.id, element?.site?.parent_site?.id, '/edit/details')">
              {{ element?.site?.name || '' }}
            </a>
          </ng-container>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </th>
        <td mat-cell *matCellDef="let element">
          <b>{{ element.name }}</b>
        </td>
      </ng-container>

      <!-- Created By Column -->
      <ng-container matColumnDef="created_by_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Created By
        </th>
        <td mat-cell *matCellDef="let toolboxTalk">
          <span *ngIf="toolboxTalk.created_by_user">
            <a (click)="onUserPublicView(toolboxTalk.created_by_user.hash)"
               matTooltip="View User Public Profile">
              {{ toolboxTalk.created_by_user?.contact_person }}
            </a>
          </span>
        </td>
      </ng-container>

      <!-- Updated By Column -->
      <ng-container matColumnDef="updated_by_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Last Updated By
        </th>
        <td mat-cell *matCellDef="let toolboxTalk">
          <span *ngIf="toolboxTalk.updated_by_user">
            <a (click)="onUserPublicView(toolboxTalk.updated_by_user.hash)"
               matTooltip="View User Public Profile">
              {{ toolboxTalk.updated_by_user?.contact_person }}
            </a>
          </span>
        </td>
      </ng-container>

      <!-- Reported By Column -->
      <ng-container matColumnDef="reported_by_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Conducted By
        </th>
        <td mat-cell *matCellDef="let toolboxTalk">
          {{ toolboxTalk.reported_by_user?.contact_person }}
        </td>
      </ng-container>

      <!-- Date Created Column -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by the date it was created.">
          Created At
        </th>
        <td mat-cell *matCellDef="let toolboxTalk">
          {{ (toolboxTalk.date_created * 1000) | timezoneConvertShort }}
        </td>
      </ng-container>

      <!-- Date Created (UTC) Column -->
      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by the date it was created.">
          Created At (UTC)
        </th>
        <td mat-cell *matCellDef="let toolboxTalk">
          {{ (toolboxTalk.date_created * 1000) | timezoneConvertUTCShort }}
        </td>
      </ng-container>

      <!-- Date Modified Column -->
      <ng-container matColumnDef="date_modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by the last updated date.">
          Last Updated At
        </th>
        <td mat-cell *matCellDef="let toolboxTalk">
          {{ (toolboxTalk.date_modified * 1000) | timezoneConvertShort }}
        </td>
      </ng-container>

      <!-- Date Modified (UTC) Column -->
      <ng-container matColumnDef="date_modified_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by the last updated date.">
          Last Updated At (UTC)
        </th>
        <td mat-cell *matCellDef="let toolboxTalk">
          {{ (toolboxTalk.date_modified * 1000) | timezoneConvertUTCShort }}
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let element">

          <a
            type="button"
            mat-button [routerLink]="utils.prepareSiteRelatedLink(element.site_id, element?.site?.parent_site_id, 'edit/toolbox-talks/' + element.id + '/view')"
            [matTooltip]="'Click this button to view this ' + utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + '.'">
            View
          </a>

          <button
            type="button"
            mat-button (click)="onEdit(element.id)"
            [matTooltip]="'Click this button to edit this ' + utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + '.'">
            Edit
          </button>

          <button
            type="button"
            mat-button (click)="onCreateFrom(element)"
            [matTooltip]="'Clone this ' + utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + ' information.'">
            Clone
          </button>

          <button
            type="button"
            mat-button (click)="onExport('pdf', element.id)"
            [matTooltip]="'Click this button to export this ' + utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + ' to PDF.'">
            Export PDF
          </button>

          <button
            type="button"
            mat-button (click)="onExport('csv', element.id)"
            [matTooltip]="'Click this button to export this ' + utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + ' to CSV.'">
            Export CSV
          </button>

          <button
            type="button"
            mat-button (click)="onRemove(element.id)"
            [matTooltip]="'Click this button to delete this ' + utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + '.'">
            Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
