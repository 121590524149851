import { Auditable } from "../shared/auditable.model";

export class UserAccountLinkModel extends Auditable {

  account_id = 0;
  default_account = 0;
  id = 0;
  permissions = 'Employee';
  user_id = 0;

  notes: string = '';

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.account_id = 0;
    this.default_account = 0;
    this.id = 0;
    this.permissions = 'Employee';
    this.user_id = 0;
  }
}
