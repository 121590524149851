import { Component } from '@angular/core';

import { UtilsService } from '../shared/utils.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  constructor(
    public utils: UtilsService
  ) {}
}
