import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TaWorkersService } from '../ta-workers.service';

@Component({
  selector: 'app-ta-workers',
  templateUrl: './ta-workers.component.html',
  styleUrls: ['./ta-workers.component.css']
})
export class TaWorkersComponent implements OnInit {
  parent_id: number;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public service: TaWorkersService
  ) {}

  ngOnInit() {
    // get the site id
    this.parent_id = Number(this.route.snapshot.params['ta_id']);

    if (this.parent_id) {
      // get the list of codes
      this.service.get(this.parent_id, 0, true);
    } else {
      // redirect back to sites because the site id is not valid
      this.router.navigate(['task-analyses']);
    }
  }

  onRemove(id: number) {
    this.service.remove(this.parent_id, id);
  }
}
