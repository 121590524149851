<!-- <mat-form-field class="full-width">
    <mat-select matInput placeholder="Select Timezone" [(ngModel)]="timezoneInput" id="timezone" name="timezone" #timezone
        required (selectionChange)="submit($event)">
        <input matInput class="p-3 bg-light" (keyup)="onKey($event.target.value)"
            placeholder="Search timezones by name." />
        <mat-option *ngFor="let tmz of filteredList" [value]="tmz.timezone">{{ tmz.offset }} {{ tmz.timezone }}
        </mat-option>
    </mat-select>
</mat-form-field> -->

<mat-form-field class="full-width">
  <mat-label>Search timezones by name.</mat-label>
  <input
    matInput
    type="text"
    class="p-3"
    (keyup)="onKey($event.target.value)"
    [(ngModel)]="timezoneInput"
    [matAutocomplete]="auto" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="submit($event)">
    <mat-option *ngFor="let tmz of filteredList" [value]="tmz.timezone">
      {{ tmz.offsetString }} {{ tmz.timezone }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
