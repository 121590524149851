import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoursesEditComponent } from './courses-edit/courses-edit.component';
import { CoursesModulesEditComponent } from './courses-modules/courses-modules-edit/courses-modules-edit.component';
import { CoursesModulesQuestionsEditComponent } from './courses-modules/courses-modules-questions/courses-modules-questions-edit/courses-modules-questions-edit.component';
import { CoursesModulesQuestionsViewComponent } from './courses-modules/courses-modules-questions/courses-modules-questions-view/courses-modules-questions-view.component';
import { CoursesModulesQuestionsComponent } from './courses-modules/courses-modules-questions/courses-modules-questions/courses-modules-questions.component';
import { CoursesModulesSectionsEditComponent } from './courses-modules/courses-modules-sections/course-modules-sections-edit/courses-modules-sections-edit.component';
import { CoursesModulesSectionsViewComponent } from './courses-modules/courses-modules-sections/course-modules-sections-view/courses-modules-sections-view.component';
import { CoursesModulesSectionsComponent } from './courses-modules/courses-modules-sections/courses-modules-sections/courses-modules-sections.component';
import { CoursesModulesViewComponent } from './courses-modules/courses-modules-view/courses-modules-view.component';
import { CoursesModulesComponent } from './courses-modules/courses-modules/courses-modules.component';
import { CoursesViewComponent } from './courses-view/courses-view.component';
import { CoursesComponent } from './courses/courses.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: 'courses',
    component: CoursesComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'courses/:course_id/edit',
    component: CoursesEditComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'courses/:course_id/view',
    component: CoursesViewComponent,
    canActivate: [
      AuthGuard
    ]
  },
  { 
    path: 'courses/:course_id/modules', 
    component: CoursesModulesComponent,
    canActivate: [
      AuthGuard
    ] 
  },
  {
    path: 'courses/:course_id/modules/:module_id/edit',
    component: CoursesModulesEditComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'courses/:course_id/modules/:module_id/view',
    component: CoursesModulesViewComponent,
    canActivate: [
      AuthGuard
    ]
  },

  {
    path: 'courses/:course_id/modules/:module_id/questions',
    component: CoursesModulesQuestionsComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'courses/:course_id/modules/:module_id/questions/:question_id/edit',
    component: CoursesModulesQuestionsEditComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'courses/:course_id/modules/:module_id/questions/:question_id/view',
    component: CoursesModulesQuestionsViewComponent,
    canActivate: [
      AuthGuard
    ]
  },

  {
    path: 'courses/:course_id/modules/:module_id/sections',
    component: CoursesModulesSectionsComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'courses/:course_id/modules/:module_id/sections/:section_id/edit',
    component: CoursesModulesSectionsEditComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'courses/:course_id/modules/:module_id/sections/:section_id/view',
    component: CoursesModulesSectionsViewComponent,
    canActivate: [
      AuthGuard
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoursesRoutingModule { }
