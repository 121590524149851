import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MATERIAL_MODULES } from '../app.const';
import { ActionsEditComponent } from './actions-edit/actions-edit.component';
import { ActionsRoutingModule } from './actions-routing.module';
import { ActionsViewComponent } from './actions-view/actions-view.component';
import { ActionsComponent } from './actions.component';
import { FilesModule } from '../files/files.module';
import { ActionsViewTabsComponent } from './actions-view-tabs/actions-view-tabs.component';
import { ActionsNotesComponent } from './actions-notes/actions-notes.component';
import { ActionsNotesEditComponent } from './actions-notes-edit/actions-notes-edit.component';
import { ActionsFilterComponent } from './actions-filter/actions-filter.component';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import { PipesModule } from '../pipes/pipes.module';
import { ActionsUploadDialogComponent } from './actions-upload-dialog/actions-upload-dialog.component';
import { ActionsUploadMappingDialogComponent } from './actions-upload-mapping-dialog/actions-upload-mapping-dialog.component';
import { DirectivesModule } from '../directives/directives.module';
import {ChartsModule} from "../charts/charts.module";

@NgModule({
    imports: [
        CommonModule,
        ActionsRoutingModule,
        FormsModule,
        ...MATERIAL_MODULES,
        MatDatepickerModule,
        PipesModule,
        FilesModule,
        MatSlideToggleModule,
        SharedModule,
        BsDatepickerModule,
        DirectivesModule,
        ChartsModule
    ],
  declarations: [
    ActionsComponent,
    ActionsViewComponent,
    ActionsEditComponent,
    ActionsViewTabsComponent,
    ActionsNotesComponent,
    ActionsNotesEditComponent,
    ActionsFilterComponent,
    ActionsUploadDialogComponent,
    ActionsUploadMappingDialogComponent
  ],
  entryComponents: [
    ActionsEditComponent,
    ActionsViewTabsComponent,
    ActionsNotesComponent,
    ActionsNotesEditComponent,
    ActionsFilterComponent,
    ActionsUploadDialogComponent,
    ActionsUploadMappingDialogComponent
  ]
})
export class ActionsModule {}
