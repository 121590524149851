import {Component, Inject, OnInit} from '@angular/core';
import {UserModel} from "../../models/user.model";
import {UtilsService} from "../../shared/utils.service";
import {ApiRequestService} from "../../shared/api-request.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-visitors-view',
  templateUrl: './visitors-view.component.html',
  styleUrls: ['./visitors-view.component.scss']
})
export class VisitorsViewComponent implements OnInit {

  visitor: UserModel = new UserModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private id: number,
    public utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    const visitor_id = this.id;
    if(visitor_id) {
      this.getVisitor(visitor_id);
    }
  }

  private getVisitor (visitor_id: number) {
    this.api.makeRequest('get', `v2/visitors/${visitor_id}`)
      .then((response) => {
        this.visitor = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
