<div class="app-margin">
  <h1 id="title">Verify Hazard/Risk</h1>

  <p>{{ hazard.name }}</p>

  <ng-container *ngIf="hazard.description">
    <h6>Description</h6>
    <p id="description">{{ hazard.description }}</p>
  </ng-container>

  <ng-container *ngIf="hazard.status">
    <h6>Status</h6>
    <p id="status">{{ hazard.status }}</p>
  </ng-container>

  <ng-container *ngIf="hazard.ira_likelihood && hazard.ira_severity">
    <h6>Initial Risk Assessment</h6>
    <p id="ira">
      {{ utils.getRiskAssessmentText(hazard.ira_likelihood, hazard.ira_severity) }}
    </p>
  </ng-container>

  <ng-container *ngIf="hazard.rra_likelihood && hazard.rra_severity">
    <h6>Residual Risk Assessment</h6>
    <p id="rra">
      {{ utils.getRiskAssessmentText(hazard.rra_likelihood, hazard.rra_severity) }}
    </p>
  </ng-container>

  <ng-container *ngIf="hazard.controls.length">
    <h6>Hazard/Risk Controls</h6>

    <p id="controls" *ngFor="let control of hazard.controls; let i = index">
      <b>{{ i + 1 }} - {{ control.control }}</b> <br />
      {{ loc_list[control.loc - 1] }}
    </p>
  </ng-container>

  <ng-container>
    <h6>Created By</h6>
    <p id="created-by">{{ hazard.created_by_name }}</p>
  </ng-container>

  <ng-container *ngIf="hazard.files">
    <h6>Files</h6>
    <app-files-list
      id="files"
      [files]="hazard.files"
      [view]="true">
    </app-files-list>
  </ng-container>

  <button
    mat-raised-button
    color="primary"
    (click)="onToggleVerify()"
    class="margin-right">
    {{ hazard.status === 'Verified' ? 'Un-verify' : 'Verify' }}
  </button>

  <button
    mat-raised-button
    color="secondary"
    matTooltip="Notifies users of the Hazard"
    (click)="onNotify()">
    Send notifications
  </button>
</div>
