<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef>Total</th>
    <td mat-cell *matCellDef="let element">{{ element.total }}</td>
  </ng-container>

  <ng-container matColumnDef="stat_completed">
    <th mat-header-cell *matHeaderCellDef>Completed</th>
    <td mat-cell *matCellDef="let element">{{ element.stat_completed }}</td>
  </ng-container>

  <ng-container matColumnDef="stat_not_completed">
    <th mat-header-cell *matHeaderCellDef>Not Completed</th>
    <td mat-cell *matCellDef="let element">{{ element.stat_not_completed }}</td>
  </ng-container>

  <ng-container matColumnDef="percent_completed">
    <th mat-header-cell *matHeaderCellDef>Percent Completed %</th>
    <td mat-cell *matCellDef="let element">{{ element.percent_completed }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<sub *ngIf="!filters.date_range || filters.date_range.length == 0 else date_range">A maximum of 3 years of data is displayed.</sub>
<ng-template #date_range>
  <sub>{{ chartsUtils.getFormattedDateRange(filters.date_range) }}</sub>
</ng-template>

<mat-paginator #paginator [length]="total" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
