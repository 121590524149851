import { UserModel } from './user.model';
import { FileModel } from '../shared/file.model';
import { SiteModel } from './site.model';
import { Auditable } from '../shared/auditable.model';

export class ToolboxTalkModel extends Auditable {
  id = 0;
  name = '';
  agenda = '';
  site_id = 0;
  selected_site_ids: number[] = [];
  copy_to_all_sites = false;
  user_ids = [];
  users: UserModel[] = [];
  files: FileModel[] = [];
  created_by_name: string;

  /**
   * User who created this Toolbox Talk.
   */
  creator: UserModel;

  /**
   * User who last updated this Toolbox Talk.
   */
  updater: UserModel;

  /**
   * Site the Toolbox Talk was created on.
   */
  site: SiteModel;

  /**
   * The Toolbox Talk ID from which the new Toolbox Talk is created.
   */
  create_from_id = 0;

  /**
   * The Reported By User.
   */
  reported_by = 0;
  reported_by_user: UserModel = new UserModel();

  created_by_user: UserModel = new UserModel();
  updated_by_user: UserModel = new UserModel();

  // This is a definition used in the toolbox talks view page for code-completion for signatures.
  auth_user_as_assignee: UserModel = null;
  // This is used to show or hide the signing button.
  allow_signing: boolean = false;

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.name = '';
    this.user_ids = [];
    this.site_id = 0;
    this.selected_site_ids.length = 0;
    this.copy_to_all_sites = false;
    this.files = [];
    this.reported_by = 0;
    this.reported_by_user = new UserModel();
  }

  createFrom(data: any) {
    this.create_from_id = data.id;
    this.name = data.name;
    this.agenda = data.agenda;
    this.site_id = data.site_id;
  }
}
