<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="mr-auto">
      <button mat-raised-button color="primary" (click)="onAdd()" appMarginRight id="add" matTooltip="Click this button to add a new TA/JSA/SWMS Workflow Step Note.">
        <mat-icon>add</mat-icon>
        New Note
      </button>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>
        <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>delete</mat-icon> Delete Selected
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect TA/JSA/SWMS Workflow Step Notes." style="min-width: 120px">
        <mat-icon>more_vert</mat-icon>
        List Menu
      </button>
    </span>

    <form class="form-inline my-2 my-lg-0"
      style="min-width: 325px"
      (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2" type="search"
        [(ngModel)]="dataSource.search" name="search" placeholder="Search"
        aria-label="Search" id="search-input"
        matTooltip="Search for TA/JSA/SWMS Workflow Step Notes." />
      <button mat-raised-button
              class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
              color="secondary"
              (click)="dataSource.getData(true)"
              id="search-button"
              matTooltip="Click this button to run the current search.">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>

  </nav>

  <mat-dialog-content class="my-3 mx-0 py-0 pr-3 pl-0" style="max-height: 40vh">
    <div class="scroll-box">
      <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

      <table mat-table [dataSource]="dataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef width="65px">
            <mat-checkbox
              (change)="$event ? dataSource.selectAllToggle() : null"
              [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? dataSource.selection.toggle(row.id) : null"
              [checked]="dataSource.selection.isSelected(row.id)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            #
          </th>
          <td mat-cell *matCellDef="let ta_revised_workflow_note">
            {{ ta_revised_workflow_note.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Note
          </th>
          <td mat-cell *matCellDef="let ta_revised_workflow_note">
            <mat-form-field>
              <textarea matInput rows="5" [value]="ta_revised_workflow_note.note" disabled></textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="created_by_user">
          <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
            Created By
          </th>
          <td mat-cell *matCellDef="let ta_revised_workflow_note" class="no-padding">
            <span *ngIf="ta_revised_workflow_note?.created_by_user else showSystemCreator">
              <a (click)="onUserPublicView(ta_revised_workflow_note.created_by_user.hash)"
                matTooltip="View User Public Profile">
                {{ ta_revised_workflow_note.created_by_user.contact_person }}
              </a>
            </span>
            <ng-template #showSystemCreator>System</ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="date_created">
          <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
            Created At
          </th>
          <td mat-cell *matCellDef="let ta_revised_workflow_note" class="no-padding">
        <span>
          {{ (ta_revised_workflow_note.date_created * 1000) | timezoneConvertMedium }}
        </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="date_created_UTC">
          <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
            Created At (UTC)
          </th>
          <td mat-cell *matCellDef="let ta_revised_workflow_note" class="no-padding">
        <span>
          {{ (ta_revised_workflow_note.date_created * 1000) | timezoneConvertUTCMedium }}
        </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>

          <td mat-cell *matCellDef="let ta_revised_workflow_note">
            <button *ngIf="ta_revised_workflow_note.created_by_user && ta_revised_workflow_note.created_by_user.id == app.user.id" type="button" mat-button (click)="onEdit(ta_revised_workflow_note)" matTooltip="Click this button to edit this TA/JSA/SWMS Workflow Step Note.">
              Edit
            </button>

            <button *ngIf="ta_revised_workflow_note.created_by_user && ta_revised_workflow_note.created_by_user.id == app.user.id" type="button" mat-button (click)="onRemove(ta_revised_workflow_note.id)" matTooltip="Click this button to remove this TA/JSA/SWMS Workflow Step Note.">
              Delete
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
    </div>
  </mat-dialog-content>

</div>
