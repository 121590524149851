<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="button-row">
    <button mat-raised-button (click)="location.back()">
      <mat-icon>arrow_back</mat-icon> Back
    </button>

    <button
      mat-raised-button
      routerLink="/admin/codes/{{ code.id }}/view"
      *ngIf="code?.id">
      <mat-icon>remove_red_eye</mat-icon>
      View
    </button>

    <button mat-raised-button (click)="onDelete(code)" *ngIf="code?.id">
      <mat-icon>delete</mat-icon>
      Delete
    </button>
  </div>
</nav>

<div class="container-fluid" class="app-padding">
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <h6>Codes</h6>
          </div>

          <form #form="ngForm" (ngSubmit)="onSubmit()" class="card-body">
            <mat-form-field style="width: 100%">
              <input matInput
                placeholder="Name"
                [(ngModel)]="code.name"
                name="name"
                type="text"
                required
                #name/>
              <mat-error *ngIf="!name.valid">
                Please enter a Codes name.
              </mat-error>
            </mat-form-field>

            <button mat-raised-button
              [disabled]="!form.valid || inProgress"
              color="primary"
              type="submit">
              <mat-icon>save</mat-icon>
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
