import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { IncidentService } from 'src/app/incidents/incident.service';
import { IncidentsEditComponent } from 'src/app/incidents/incidents-edit/incidents-edit.component';
import { IncidentsTabsComponent } from 'src/app/incidents/incidents-tabs/incidents-tabs.component';
import { IncidentsDataSource } from 'src/app/incidents/incidents/incidents.component';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { SitesIncidentsFilterComponent } from '../sites-incidents-filter/sites-incidents-filter.component';
import {StorageService} from "../../shared/storage.service";

@Component({
  selector: 'app-sites-incidents',
  templateUrl: './sites-incidents.component.html',
  styleUrls: ['./sites-incidents.component.scss']
})
export class SitesIncidentsComponent implements OnInit, AfterViewInit {

  // Incident filters storage key.
  incidentFiltersStorageKey: string = 'site_incidents_aid_' + this.app.account.id + '_filters';

  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'site',
    'details',
    'type',
    'status',
    'date_incident',
    // 'date_incident_UTC',
    'estimated_lti_days',
    'actual_lti_days',
    'actual_lti_hours',
    'actions'
  ];
  dataSource = new IncidentsDataSource(this.app, this.api);

  parent_id: number;
  child_id: number;
  site_id: number;

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  datepickerConfig: Partial<BsDatepickerConfig>;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private route: ActivatedRoute,
    private incidentService: IncidentService,
    private storage: StorageService
  ) {}

  ngOnInit() {
    this.datepickerConfig = Object.assign(
      {},
      {
        containerClass: 'theme-orange',
        dateInputFormat: 'DD MMM YYYY',
        rangeInputFormat: 'DD MMM YYYY',
        showWeekNumbers: true
      }
    );

    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      // Get the filters from storage or reset to default values.
      this.getListFilters();

      // Reset the selected site ids.
      this.dataSource.filters.site_ids.length = 0;
      this.dataSource.filters.site_ids.push(this.site_id);

      // Get the data from the API.
      this.dataSource.getData();
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      // sorting for utc time by parsing original time
      if (this.sort.active === "date_incident_UTC") {
        this.dataSource.order_by = "date_incident";
      } else {
        this.dataSource.order_by = this.sort.active;
      }
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * Retrieves the list of filters for the incidents.
   *
   * @return {void}
   */
  getListFilters(): void {
    // Get and apply the incidents filters from storage.
    this.dataSource.filters = this.storage.get(this.incidentFiltersStorageKey) || {
      site_ids: [],
      user_ids: [],
      contractor_ids: [],
      tag_ids: [],
      type: '',
      status: '',
      date_range: []
    }
    // Remap the dates to new Date objects. Storing it in storage stores it as string.
    this.dataSource.filters.date_range = this.dataSource.filters.date_range.map((date: Date|string) => new Date(date));
  }

  async downloadPdf(id: number) {
    this.utils.showToast(
      'You can either wait or continue using the application ' +
      'while you wait for the incident report. You will automatically be redirected to it once it is finished.'
    );

    this.incidentService.export(id).subscribe((response) => {
      const link = document.createElement('a');
      link.href = response.data.url;
      link.target = '_blank';
      link.download = 'incident-report-2000.pdf';
      link.click();
    });
  }

  onAddIncident() {
    this.utils.showComponentDialog(IncidentsEditComponent, {
      site_id: this.site_id
    }, {
      width: '90%'
    })
    .then(() => {
      this.dataSource.getData();
    })
  }


  onManageIncident(incident_id: number) {
    this.utils.showComponentDialog(IncidentsTabsComponent, {
      incident_id: incident_id
    }, {
      width: '90%'
    })
    .then(() => {
      // Refresh the list after the dialog closed.
      this.dataSource.getData();
    });
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Delete Incident',
      'Are you sure you want to delete this incident?',
      () => {
        this.api.makeRequest('delete', `v2/incidents/${id}`)
        .then(() => {
          this.dataSource.selection.deselect(id);
          this.dataSource.getData();
          this.utils.showToast('The incident was deleted.');
        })
        .catch((errorResponse) => {
          this.utils.showModal('Delete Incident Error', errorResponse.message);
        });
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Delete Selected Incidents',
      'Are you sure you want to delete the selected incidents?',
      () => {
        this.api.makeRequest('delete', `v2/incidents/${this.dataSource.selection.selected.join(',')}`)
        .then(() => {
          this.dataSource.selection.clear();
            this.dataSource.getData();
            this.utils.showToast('The selected incidents were deleted.');
        })
        .catch((errorResponse) => {
          this.utils.showModal('Delete Incidents Error', errorResponse.message);
        });
      }
    );
  }

  /**
   * Updates the selected tag ids in the data source filters and
   * updates the incidents filter preferences. Then, it retrieves
   * the data from the API using the updated filters.
   *
   * @param {Array} $event - The selected tag ids to be filtered.
   *
   * @return {void}
   */
  getOutputTagsFilter($event) {
    // Update the selected tag ids.
    this.dataSource.filters.tag_ids = $event;

    // Update the incidents filter preferences.
    this.storage.set(this.incidentFiltersStorageKey, this.dataSource.filters);

    // Get the data from the API.
    this.dataSource.getData(true);
  }

  /**
   * Opens the site incidents filter dialog and updates the filter values based on the selection.
   * This method shows a component dialog with pre-filled filter values and width options.
   *
   * @return {void}
   */
  onFilter(): void {
    // Open the site incidents filter dialog.
    this.utils.showComponentDialog(SitesIncidentsFilterComponent, {
      site_ids: this.dataSource.filters.site_ids,
      user_ids: this.dataSource.filters.user_ids,
      tag_ids: this.dataSource.filters.tag_ids,
      type: this.dataSource.filters.type,
      status: this.dataSource.filters.status,
      date_range: this.dataSource.filters.date_range,
      parent_site_id: this.parent_id
    }, {
      width: '1024px'
    }, (filters: any) => {
      if ( typeof filters !== 'undefined' && filters != false ) {
        // Update the filter values.
        this.dataSource.filters.site_ids = filters.site_ids;
        this.dataSource.filters.user_ids = filters.user_ids;
        this.dataSource.filters.tag_ids = filters.tag_ids;
        this.dataSource.filters.type = filters.type;
        this.dataSource.filters.status = filters.status;
        this.dataSource.filters.date_range = filters.date_range;

        // Push the default site id if the site ids are empty.
        if ( this.dataSource.filters.site_ids.length == 0 ) {
          this.dataSource.filters.site_ids.push(this.site_id);
        }

        // Update the incidents filter preferences.
        this.storage.set(this.incidentFiltersStorageKey, this.dataSource.filters);

        // Get the data from the API.
        this.dataSource.getData(true);
      }
    });
  }
}
