import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '../directives/directives.module';
import { RepoSearchComponent } from '../shared/repo-search/repo-search.component';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { HazardousSubstancesEditComponent } from './hazardous-substances-edit/hazardous-substances-edit.component';
import { HazardousSubstancesRoutingModule } from './hazardous-substances-routing.module';
import { HazardousSubstancesSelectorComponent } from './hazardous-substances-selector/hazardous-substances-selector.component';
import { HazardousSubstancesViewComponent } from './hazardous-substances-view/hazardous-substances-view.component';
import { HazardousSubstancesComponent } from './hazardous-substances/hazardous-substances.component';
import { HazardousSubstancesEditDialogComponent } from './hazardous-substances-edit-dialog/hazardous-substances-edit-dialog.component';
import { FilesModule } from '../files/files.module';
import { PipesModule } from '../pipes/pipes.module';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';

@NgModule({
  declarations: [
    HazardousSubstancesComponent,
    HazardousSubstancesEditComponent,
    HazardousSubstancesSelectorComponent,
    HazardousSubstancesViewComponent,
    HazardousSubstancesEditDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatTabsModule,
    HazardousSubstancesRoutingModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    DirectivesModule,
    MatBadgeModule,
    MatTooltipModule,
    FilesModule,
    PipesModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    BsDatepickerModule
  ],
  entryComponents: [
    SitesSelectorComponent,
    RepoSearchComponent
  ],
  providers: [
    {
      provide: NGX_MAT_DATE_FORMATS, useValue: {
        display: {
          dateInput: 'DD/MM/YYYY HH:mm A',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    }
  ]
})
export class HazardousSubstancesModule {}
