<div mat-dialog-title>
  <h5>Incident Note Form</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit()">
  <mat-form-field>
    <textarea
      matInput
      placeholder="Value"
      [(ngModel)]="note.value"
      name="description"
      type="text"
      rows="10"
      cols="30"
      required
    ></textarea>
  </mat-form-field>

  <button
    mat-raised-button
    [disabled]="!form.valid || inProgress"
    color="primary"
    type="submit"
    id="save"
  >
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    type="button"
    mat-icon-button
    [disabled]="!note.id"
    class="float-right"
    (click)="onDelete()"
  >
    <mat-icon>delete</mat-icon>
  </button>
</form>
