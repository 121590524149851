<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/sssp">All Safety Plans</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <!--button mat-raised-button color="primary" (click)="onComposeMessage()" appMarginRight id="add" matTooltip="Click this button to compose a new message.">
          <mat-icon>add</mat-icon>
          Create Safety Plan
        </button-->

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="listDataSource.selectAllToggle()">
            <mat-icon *ngIf="listDataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!listDataSource.isAllSelected()">select_all</mat-icon>
            {{ listDataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!listDataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Safety Plans." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>
      </span>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onFilter()" matTooltip="Click this button to display filter options.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="listDataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? listDataSource.selectAllToggle() : null"
            [checked]="listDataSource.selection.hasValue() && listDataSource.isAllSelected()"
            [indeterminate]="listDataSource.selection.hasValue() && !listDataSource.isAllSelected()"
            matTooltip="Toggle select all Safety Plans in this list.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? listDataSource.selection.toggle(row.id) : null"
            [checked]="listDataSource.selection.isSelected(row.id)"
            matTooltip="Toggle select this Safety Plan.">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          #
        </th>
        <td mat-cell *matCellDef="let sssp">
          {{ sssp.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="site_name">
        <th mat-header-cell *matHeaderCellDef >
          {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Name
        </th>
        <td mat-cell *matCellDef="let sssp">
          {{ sssp.site ? sssp.site.name : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="site_location">
        <th mat-header-cell *matHeaderCellDef >
          {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Address
        </th>
        <td mat-cell *matCellDef="let sssp">
          {{ sssp.site ? sssp.site.location : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contractor">
        <th mat-header-cell *matHeaderCellDef >
          {{ utils.getLangTerm('contractors.singular', 'Contractor') }}
        </th>
        <td mat-cell *matCellDef="let sssp">
          {{ sssp.contractor ? sssp.contractor.name : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="tags">
        <th mat-header-cell *matHeaderCellDef >
          Tags
        </th>
        <td mat-cell *matCellDef="let sssp">
          <span [class]="'badge badge-pill badge-' + (sssp.contractor ? 'secondary' : 'info')">
            {{ sssp.contractor ? utils.getLangTerm('contractors.singular', 'Contractor') + ' Safety Plan' : 'Master Safety Plan' }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="date_modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Last Updated At
        </th>
        <td mat-cell *matCellDef="let sssp">
          {{ (sssp.date_modified * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_modified_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Last Updated At (UTC)
        </th>
        <td mat-cell *matCellDef="let sssp">
          {{ (sssp.date_modified * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let sssp">
          <button type="button" mat-button (click)="onManageSSSP(sssp.sssp_hash)" matTooltip="Click this button to manage this Safety Plan.">
            Manage Safety Plan
          </button>

          <button type="button" mat-button (click)="onRemove(sssp.id, $event)" matTooltip="Click this button to remove this Safety Plan.">
            Delete
          </button>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
