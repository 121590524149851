<div #chart_ref style="height: 200px;"></div>

<div class="row mt-2">
  <div class="col">
    <div class="d-flex justify-content-start">
      <h6>TOTAL: {{ total_incidents }}</h6>
    </div>
  </div>
  <div class="col">
    <div class="d-flex justify-content-end">
      <label for="types" class="mr-1">Filter&nbsp;By:</label>
      <select class="form-select mr-3" id="types" [(ngModel)]="default_incident_type" (change)="onDefaultIncidentTypeChanged()" matTooltip="Select which incident type to retrieve records for.">
        <option [value]="incident_type" *ngFor="let incident_type of incident_types">{{ incident_type }}</option>
      </select>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <sub *ngIf="!filters.date_range || filters.date_range.length == 0 else date_range">A maximum of 3 years of data is displayed.</sub>
    <ng-template #date_range>
      <sub>{{ chartsUtils.getFormattedDateRange(filters.date_range) }}</sub>
    </ng-template>
  </div>
</div>
