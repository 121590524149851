<div mat-dialog-title class="p-3">
  <h5>
    Compose Message
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
</div>

<div mat-dialog-content class="p-3">
  <form #f="ngForm" (ngSubmit)="onSave(f)">

    <div class="row">
      <div class="col">
        <mat-form-field class="full-width mb-3">
          <mat-label>Select the message type:</mat-label>
          <mat-select [(ngModel)]="message.type" name="type" aria-label="Select the type of message you want to send." [disabled]="!canEditMessage()">
            <mat-option value="email">Email</mat-option>
            <mat-option value="push notification">Push Notification</mat-option>
            <mat-option value="sms" [disabled]="!app.account.enable_sms_notifications">SMS (Text)</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field class="full-width mb-3">
          <mat-label>Select the message priority:</mat-label>
          <mat-select [(ngModel)]="message.priority" name="priority" aria-label="Select the priority of the message you want to send." [disabled]="!canEditMessage()">
            <mat-option value="low">Low</mat-option>
            <mat-option value="normal">Normal</mat-option>
            <mat-option value="high">High</mat-option>
            <mat-option value="critical">Critical</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col">
        <app-timezone-select [timezoneInput]="selectedTimezone" (timezoneOutput)="getOutputTimezone($event)"></app-timezone-select>
      </div>

      <div class="col">
        <mat-form-field class="full-width mb-3">
          <mat-label>Schedule to send later:</mat-label>
          <input matInput [ngxMatDatetimePicker]="deferDateTimePicker" readonly name="deferDateTime" [(ngModel)]="deferDateTime" [disabled]="!canEditMessage()">
          <mat-datepicker-toggle matSuffix [for]="deferDateTimePicker"></mat-datepicker-toggle>
          <button type="button" mat-button matSuffix mat-icon-button *ngIf="deferDateTime" (click)="deferDateTime = null" matTooltip="Remove the scheduled date."><mat-icon>close</mat-icon></button>
          <ngx-mat-datetime-picker #deferDateTimePicker [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
          <mat-hint>Click the calendar icon to select a date and time.</mat-hint>
       </mat-form-field>
      </div>
    </div>

    <mat-form-field class="full-width mb-3">
      <mat-label>Subject</mat-label>
      <input
        matInput
        placeholder="Subject"
        [(ngModel)]="message.subject"
        [disabled]="!canEditMessage()"
        name="subject"
        type="text"
        id="subject"
        required
        dusk="dusk_subject" />
      <mat-hint>Enter the subject of the message.</mat-hint>
    </mat-form-field>

    <div class="alert alert-info" role="alert">
      <small><mat-hint>When adding images in the content, the source (src) must be a URL that can be accessed with an internet connection. Copying and pasting an image will not work. You can attach your images to the message. After saving the message, your images will be uploaded. You can then copy the uploaded image URL and use it as the source.</mat-hint></small>
    </div>

    <editor
      placeholder="Message"
      [(ngModel)]="message.message"
      name="message"
      id="message"
      required
      dusk="dusk_message"
      [disabled]="!canEditMessage()"
      [init]="{
        skin_url: '/assets/skins/lightgray',
        plugins: 'link,image,lists,hr,table,media',
        height: '300'
      }">
    </editor>
    <small><mat-hint>Enter your message here. SMS (Text) messages are limited to 160 characters per message.</mat-hint></small> <br>
    <small><mat-hint>Characters: {{ getMessageCharacterCount() }} | Words: {{ getMessageWordCount() }}.</mat-hint></small> <br>

    <mat-card class="mt-3" *ngIf="message.const_sites_ids.length > 0">
      <mat-card-content>
        <p>
          You selected {{ message.const_sites_ids.length }} site{{ message.const_sites_ids.length > 1 ? 's' : '' }}.
          {{ message.only_onsite ? ('This message will only be sent to users that are signed into the selected ' + utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase()) : ('This message will be sent to all users associated with the selected ' + utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase()) }}.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <mat-slide-toggle [(ngModel)]="message.only_onsite" name="only_onsite" [disabled]="!canEditMessage()">Send this message only to onsite users.</mat-slide-toggle>
      </mat-card-actions>
    </mat-card>

    <mat-card class="mt-3" *ngIf="message.const_accounts_ids.length > 0">
      <mat-card-content>
        <p>
          You selected {{ message.const_accounts_ids.length }} {{ utils.getLangTerm('contractors.singular', 'Contractor') }}{{ message.const_accounts_ids.length > 1 ? 's' : '' }}.
          {{ message.only_admins ? ('This message will only be sent to the administrators of the selected ' + utils.getLangTerm('contractors.singular', 'Contractor') + (message.const_accounts_ids.length > 1 ? 's' : '')) : ('This message will be sent to all users associated with the selected ' + utils.getLangTerm('contractors.singular', 'Contractor') + (message.const_accounts_ids.length > 1 ? 's' : '')) }}.

           If you want to send the message to all users associated with the selected {{ utils.getLangTerm('contractors.plural', 'Contractors') }}, turn the toggle below off.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <mat-slide-toggle [(ngModel)]="message.only_admins" name="only_admins" [disabled]="!canEditMessage()">Send this message only to administrators.</mat-slide-toggle>
      </mat-card-actions>
    </mat-card>

    <div class="row mt-3">
      <div class="col-12">

        <button
          mat-raised-button
          class="mr-3"
          type="button"
          color="secondary"
          id="site_selector_button"
          [disabled]="isAutosaving || !canEditMessage()"
          dusk="dusk_site_selector_button"
          (click)="onSelectSites()"
          [matBadge]="'' + (message.const_sites_ids.length)"
          matTooltip="Select the {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} you want to send the message to.">
          <mat-icon>location_on</mat-icon> Select {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
        </button>

        <button
          mat-raised-button
          class="mr-3"
          type="button"
          color="secondary"
          id="account_selector_button"
          [disabled]="isAutosaving || !canEditMessage()"
          dusk="dusk_account_selector_button"
          (click)="onSelectAccounts()"
          [matBadge]="'' + (message.const_accounts_ids.length)"
          matTooltip="Select the {{ utils.getLangTerm('contractors.plural', 'Contractors') }} you want to send the message to.">
          <fa-icon [icon]="faHardHat"></fa-icon> Select {{ utils.getLangTerm('contractors.plural', 'Contractors') }}
        </button>

        <button
          mat-raised-button
          class="mr-3"
          type="button"
          color="secondary"
          id="user_selector_button"
          [disabled]="isAutosaving || !canEditMessage()"
          dusk="dusk_user_selector_button"
          (click)="onSelectUsers()"
          [matBadge]="'' + (message.const_users_ids.length)"
          matTooltip="Select the users you want to send the message to.">
          <mat-icon>account_circle</mat-icon> Select Users
        </button>

        <app-file-browse-button
          parentObjectType="message"
          [parentObjectId]="message.id"
          [filesCount]="message.files.length"
          (afterDialogClosedEvent)="getMessage(message.id)"
          (selectedFiles)="onSelectFiles($event)"
          [disabled]="isAutosaving || !canEditMessage()">
        </app-file-browse-button>

        <button
          mat-raised-button
          [disabled]="!f.valid"
          *ngIf="canEditMessage()"
          class="mr-3"
          type="submit"
          color="primary"
          id="save_button"
          [disabled]="isAutosaving"
          dusk="dusk_save_button">
          <mat-icon>save</mat-icon> {{ isAutosaving ? 'Autosaving...' : 'Save' }}
        </button>

        <button
          mat-raised-button
          [disabled]="message.status == 'draft' ? !canQueueMessage() : !canDequeueMessage()"
          class="float-right"
          type="button"
          color="primary"
          id="send_button"
          [disabled]="isAutosaving"
          dusk="dusk_send_button"
          (click)="message.status == 'draft' ? onQueueMessage() : onDequeueMessage()">
          {{ message.status == 'draft' ? 'Send' : 'Dequeue' }} Message
        </button>

      </div>
    </div>

  </form>
</div>
