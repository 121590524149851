import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/utils.service';
import { CodesService } from '../codes.service';
import { CodeModel } from 'src/app/models/code.model';

@Component({
  selector: 'app-codes-edit',
  templateUrl: './codes-edit.component.html',
  styleUrls: ['./codes-edit.component.scss']
})
export class CodesEditComponent implements OnInit {
  code = new CodeModel();

  inProgress: boolean = false;

  constructor(
    public location: Location,
    private route: ActivatedRoute,
    private util: UtilsService,
    private router: Router,
    private codeService: CodesService
  ) {}

  ngOnInit() {
    this.code.id = Number(this.route.snapshot.params['id']);
    if (!isNaN(this.code.id)) {
      this.codeService
        .find(this.code.id)
        .subscribe((result) => (this.code = result.data));
    }
  }

  onSubmit() {

    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;

    isNaN(this.code.id) ? this.create() : this.update();
  }

  /**
   * Send a request to the API to "soft" delete the QR code.
   * @param code
   */
  onDelete(code: CodeModel) {
    this.util.showModal('Remove Codes', `Are you sure you want to remove "${code.name}"?`, () => {
        this.codeService.delete(code.id).subscribe((result) => {
          this.util.showToast(`QR code "${code.name}" was removed.`);
          this.router.navigate(['/admin/codes']);
        });
      }
    );
  }

  private update() {
    this.codeService
      .put(this.code)
      .subscribe((result) => {
        this.onSuccess(`Updated: "${result.data.name}"`);
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      });
  }

  private create() {
    this.codeService
      .post(this.code)
      .subscribe((result) => {
        this.onSuccess(`Created: "${result.data.name}"`);
        this.inProgress = false;
        this.router.navigate(['/admin/codes']);
      }, () => {
        this.inProgress = false;
      });
  }

  private onSuccess(message: string) {
    this.util.showToast(message);
  }
}
