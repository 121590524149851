import { Injectable } from '@angular/core';
import { ContractorAccountModel } from '../models/contractor-account.model';
import { AppService } from '../app.service';
import { ApiRequestService } from '../shared/api-request.service';
import { Router } from '@angular/router';

@Injectable()
export class ContractorService {

  contractor: ContractorAccountModel;

  shouldRedirectOnFailure: boolean = false;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private router: Router
  ) { }

  getContractorById(id: number) {
    return this.api.makeRequest('get', `v2/contractors/${id}`)
    .then((contractor: ContractorAccountModel) => {
      if ( !this.contractor ) {
        this.contractor = new ContractorAccountModel();
      }
      this.contractor.apply(contractor);
      return contractor;
    })
    .catch((errorResponse) => {
      // Show Error Message
      if ( this.shouldRedirectOnFailure ) {
        this.router.navigate(['contractors']);
      }
    });
  }
}
