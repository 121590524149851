import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { ApiResponseModel } from '../models/api-response.model';
import { AccountModel } from '../models/account.model';
import { Observable } from 'rxjs';
import { UserAccountLinkModel } from '../models/user-account-link.model';

type ManagedAccountsResponse = ApiResponseModel<AccountModel[]>;
type SwitchedAccountResponse = ApiResponseModel<{
  account: AccountModel;
  user_account_link: UserAccountLinkModel;
}>;

@Injectable({
  providedIn: 'root'
})
export class ManagedAccountService {
  private readonly resourceUrl = 'managed-accounts';

  constructor(private api: ApiService) {}

  query(params?: { search?: string, order?: string }): Observable<ManagedAccountsResponse> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl,
      {},
      {},
      params
    );
  }

  switch(_id: number): Observable<SwitchedAccountResponse> {
    return this.api.laravelApiObservable('put', this.resourceUrl, { id: _id });
  }
}
