import { Auditable } from "../shared/auditable.model";

export class CourseModuleQuestionModel extends Auditable {
  id = 0;
  question = '';
  answers: { id: number; answer_text: string; answer_value: boolean }[] = [];
  answers_to_remove: number[] = [];
  status = 'Published';

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.question = '';
    this.answers.length = 0;
    this.status = 'Published';
  }
}
