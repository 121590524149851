<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">Hazardous Substances</span>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button
          mat-raised-button
          color="primary"
          routerLink="/hazardous_substances/new/edit"
          appMarginRight
          id="add"
          matTooltip="Create a Hazardous Substance and add it to your list">
          <mat-icon>add</mat-icon>
          Add
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onExportSelected('pdf')">
            <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? 'Selected' : 'All' }} to PDF
          </button>
          <button mat-menu-item (click)="onExportSelected('csv')" matTooltip="Export Summary to CSV ({{ dataSource.selection.hasValue() ? 'Selected' : 'All' }})">
            <mat-icon>cloud_download</mat-icon> Export Summary to CSV ({{ dataSource.selection.hasValue() ? 'Selected' : 'All' }})
          </button>
          <button mat-menu-item (click)="onExportSelected('xlsx')" matTooltip="Export Full Report to XLSX ({{ dataSource.selection.hasValue() ? 'Selected' : 'All' }})">
            <mat-icon>cloud_download</mat-icon> Export Full Report to XLSX ({{ dataSource.selection.hasValue() ? 'Selected' : 'All' }})
          </button>
          <button mat-menu-item (click)="onDeleteSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect and/or export Hazardous Substances." style="min-width: 150px">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>
      </span>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onFilter()" matTooltip="Click this button to display filter options.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>

      <form
        class="form-inline my-2 my-lg-0"
        (ngSubmit)="dataSource.getData(true)"
        style="min-width: 325px">

        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search"
          matTooltip="Filter Hazardous Substances by name"/>

        <button
          mat-raised-button
          color="secondary"
          type="submit"
          class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
          matTooltip="Run the current search">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="substance" matSortDirection="asc" matSortDisableClear>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="65px">
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Toggle selecting all Hazardous Substances on the page">
          </mat-checkbox>
        </th>

        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)"
            matTooltip="Toggle selecting this Hazardous Substance">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by substance ID">
          #
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.id }}
        </td>
      </ng-container>

      <!-- Hazardous Substance -->
      <ng-container matColumnDef="substance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by substance">
          Hazardous Substance
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.substance }}
        </td>
      </ng-container>

      <!-- Hazardous Substance Type -->
      <ng-container matColumnDef="hazardous_substance_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by hazardous substance type">
          Type
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element?.hazardous_substance_type">
            {{ ('(' + element?.hazardous_substance_type?.substr(0, 1) + ') ') + element.hazardous_substance_type }}
          </ng-container>
        </td>
      </ng-container>

      <!-- Hazardous Substance Unit Type -->
      <ng-container matColumnDef="hazardous_substance_unit_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by hazardous substance unit type">
          Unit Type
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.hazardous_substance_unit_type }}
        </td>
      </ng-container>

      <!-- Hazardous Substance Quantity -->
      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef matTooltip="The quantity that is currently held on all site.">
          Quantity (All Sites)
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.quantity || '' }}
        </td>
      </ng-container>

      <!-- Hazardous Substance Max Allowed Quantity -->
      <ng-container matColumnDef="max_quantity_allowed">
        <th mat-header-cell *matHeaderCellDef matTooltip="The max quantity that is allowed to be held on all site.">
          Max Quantity Allowed (All Sites)
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.max_quantity_allowed || '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="sds_expires_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>SDS Expires At</th>
        <td mat-cell *matCellDef="let element">
          {{ element.sds_expires_at ? ((element.sds_expires_at * 1000) | timezoneConvertMedium) : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created (UTC)</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.date_created * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let element">
          <a type="button" mat-button routerLink="/hazardous_substances/{{ element.id }}/view" matTooltip="View the hazardous substance information." matTooltipPosition="above">
            View
          </a>

          <a type="button" mat-button routerLink="/hazardous_substances/{{ element.id }}/edit" matTooltip="Edit the hazardous substance information." matTooltipPosition="above">
            Edit
          </a>

          <button type="button" mat-button (click)="onCreateFrom(element)" matTooltip="Create hazardous substance from this hazardous substance information." matTooltipPosition="above">
            Clone
          </button>

          <button type="button" mat-button (click)="onRemove(element.id)" matTooltip="Remove (Archive) the hazardous substance." matTooltipPosition="above">
            Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
