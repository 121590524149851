<div mat-dialog-title>
  <div class="d-flex">
    <h3 class="my-2">
      Induction Report
    </h3>

    <button mat-raised-button  class="m-2 ml-4" color="" (click)="onExport('csv')" matTooltip="Click this button to export Induction Report.">
      <mat-icon>cloud_download</mat-icon> Export
    </button>

    <span style="flex: 1 1 auto;"></span>

    <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-2 float-right float-lg-none" color="" (click)="onFilter()" matTooltip="Click this button to display filter options.">
      <mat-icon>filter_list</mat-icon> Filter
    </button>

    <form
      class="form-inline my-2 my-lg-0"
      (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2"
        type="search"
        [(ngModel)]="dataSource.search"
        name="search"
        placeholder="Search Assigned Users"
        aria-label="Search Assigned Users" />
    </form>

    <button class="my-2" mat-raised-button (click)="dataSource.getData(true)" appMarginRight>
      <mat-icon>search</mat-icon> Search
    </button>
  </div>
</div>

<mat-dialog-content>
  <table mat-table [dataSource]="dataSource" matSort
         matSortActive="user_id" matSortDirection="asc" matSortDisableClear>

    <ng-container matColumnDef="accounts.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
      <td mat-cell *matCellDef="let element">{{ element?.user?.employer.name }}</td>
    </ng-container>

    <ng-container matColumnDef="users.contact_person">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Full Name
      </th>
      <td mat-cell *matCellDef="let element">
        <a (click)="onUserPublicView(element?.user.hash, $event)"
           matTooltip="View User Public Profile">
          {{ element?.user.contact_person }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="users.email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
      <td mat-cell *matCellDef="let element">{{ element?.user.email }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns">
    </tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <mat-paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>
</mat-dialog-actions>

