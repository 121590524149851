<div class="container-fluid">
  <nav class="navbar navbar-expand-lg">
<!--    <button class="navbar-toggler" type="button" data-toggle="collapse"-->
<!--      data-target="#navbarSupportedContent"-->
<!--      aria-controls="navbarSupportedContent" aria-expanded="false"-->
<!--      aria-label="Toggle navigation">-->
<!--      <span class="navbar-toggler-icon"></span>-->
<!--    </button>-->

<!--    <div class="collapse navbar-collapse" id="navbarSupportedContent">-->
      <span class="mr-auto">
        <button mat-raised-button color="primary" (click)="onAdd()"
          appMarginRight matTooltip="Add a new incident witness.">
          <mat-icon>add</mat-icon>
          Add Witness
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onDeleteSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Incident Witnesses." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>

      </span>

      <form class="form-inline my-2 my-lg-0"
            style="min-width: 325px"
            (ngSubmit)="dataSource.getData(true)"
      >
        <input class="form-control mr-sm-2" type="search"
          [(ngModel)]="dataSource.search" name="search" placeholder="Search"
          aria-label="Search" />
        <button mat-raised-button
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                color="secondary"
                (click)="dataSource.getData()"
        >
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

<!--    </div>-->
  </nav>

  <table mat-table [dataSource]="dataSource" matSort matSortActive="full_name"
    matSortDirection="asc" matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="
            dataSource.selection.hasValue() && dataSource.isAllSelected()
          " [indeterminate]="
            dataSource.selection.hasValue() && !dataSource.isAllSelected()
          ">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        #
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.id }}
      </td>
    </ng-container>

    <!-- Full Name Column -->
    <ng-container matColumnDef="full_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Full Name
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.full_name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="company_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Company Name
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.company_name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Email
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.email }}
      </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ utils.getLangTerm('users.list.mobile', 'Mobile Number') }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.phone }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Date Created
      </th>
      <td mat-cell *matCellDef="let element">
        {{ (element.date_created * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Date Created (UTC)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ (element.date_created * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let element">
        <button type="button" mat-button (click)="onEdit(element.id)" matTooltip="Edit the incident witness information." matTooltipPosition="above">
          Edit
        </button>

        <button type="button" mat-button (click)="onRemove(element.id)" matTooltip="Remove (Archive) the incident witness." matTooltipPosition="above">
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons></mat-paginator>
</div>
