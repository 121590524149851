import {Component, Input, OnInit} from '@angular/core';
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {ActivatedRoute, Router} from "@angular/router";
import {RISK_LEVEL_OF_CONTROLS} from "../../shared/risk-level-of-controls";
import {UserModel} from "../../models/user.model";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-sites-task-analyses-revised-view',
  templateUrl: './sites-task-analyses-revised-view.component.html',
  styleUrls: ['./sites-task-analyses-revised-view.component.scss']
})
export class SitesTaskAnalysesRevisedViewComponent implements OnInit {

  @Input() private taRevisedId: number;
  @Input() site_id: number;

  ta_revised: TaskAnalysesRevisedModel = {} as TaskAnalysesRevisedModel;
  ta_revised_view: TaskAnalysesRevisedModel = {} as TaskAnalysesRevisedModel;

  loc_list: string[] = RISK_LEVEL_OF_CONTROLS;

  inProgress = false;
  path: string;

  approverId: number = 0;
  approverAcknowledged: boolean = false;

  authorizerId: number = 0;
  authorizerAcknowledged: boolean = false;

  supervisorId: number = 0;
  supervisorAcknowledged: boolean = false;

  acknowledgeUserId: number = 0;
  users: UserModel[] = [] as UserModel[];

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // get the ta revised id from the parent route params
    this.ta_revised.id = this.taRevisedId;
    this.ta_revised_view.id = this.ta_revised.id;

    // get the site id from the parent route params
    this.ta_revised.site_id = this.site_id; // Number(this.route.parent.snapshot.params['site_id']);
    this.ta_revised_view.site_id = this.ta_revised.site_id;

    // get sites path edit/view
    this.path = this.route.parent.snapshot.routeConfig.path;

    if(this.ta_revised.id) {
      this.find(this.ta_revised.id);
    } else {
      this.redirectBack();
    }
  }

  private redirectBack() {
    if(this.ta_revised.site_id && this.path) {
      this.router.navigate([`/sites/${this.ta_revised.site_id}/${this.path}/task-analyses-revised/`]);
    }
  }

  public find(ta_revised_id: number, view: boolean = false) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {
      site_id: this.ta_revised.site_id
    })
      .then((response) => {

        if(view) {
          this.populateTaView(response);
        } else {
          this.ta_revised = {...response};

          // Extract PPE ids.
          this.ta_revised.ppe_ids = this.ta_revised.ppe ? this.ta_revised.ppe.map((ppe) => ppe.id) : [];

          // Extract Work Permit ids.
          this.ta_revised.work_permit_ids = this.ta_revised.work_permits ? this.ta_revised.work_permits.map((work_permit) => work_permit.id) : [];

          // Extract Tool ids.
          this.ta_revised.tool_ids = this.ta_revised.tools ? this.ta_revised.tools.map((tool) => tool.id) : [];

          // Extract User ids.
          this.ta_revised.user_ids = this.ta_revised.users ? this.ta_revised.users.map((user) => user.id) : [];

          // Extract File ids.
          this.ta_revised.file_ids = this.ta_revised.files ? this.ta_revised.files.map((file) => file.id) : [];

          // Fixed Acknowledgement Data not changed by Form updates
          this.approverId = this.ta_revised.approver_id;
          this.approverAcknowledged = this.ta_revised.approver_acknowledged;

          this.authorizerId = this.ta_revised.authorizer_id;
          this.authorizerAcknowledged = this.ta_revised.authorizer_acknowledged;

          this.supervisorId = this.ta_revised.supervisor_id;
          this.supervisorAcknowledged = this.ta_revised.supervisor_acknowledged;

          // this.acknowledgeUserId = 0;
          this.users = this.ta_revised.users;

          this.populateTaView(response);
        }

      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private populateTaView(response: any) {
    this.ta_revised_view = {...response};

    // Extract PPE ids.
    this.ta_revised_view.ppe_ids = this.ta_revised_view.ppe ? this.ta_revised_view.ppe.map((ppe) => ppe.id) : [];

    // Extract Work Permit ids.
    this.ta_revised_view.work_permit_ids = this.ta_revised_view.work_permits ? this.ta_revised_view.work_permits.map((work_permit) => work_permit.id) : [];

    // Extract Tool ids.
    this.ta_revised_view.tool_ids = this.ta_revised_view.tools ? this.ta_revised_view.tools.map((tool) => tool.id) : [];

    // Extract User ids.
    this.ta_revised_view.user_ids = this.ta_revised_view.users ? this.ta_revised_view.users.map((user) => user.id) : [];

    // Extract File ids.
    this.ta_revised_view.file_ids = this.ta_revised_view.files ? this.ta_revised_view.files.map((file) => file.id) : [];
  }

  private acknowledgementUpdate(updateAcknowledgement: string = "") {
    this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.taRevisedId}/acknowledgement`, this.ta_revised, {
        site_id: this.ta_revised.site_id,
        user_id: this.app.user.id,
        acknowledge_approver_status: updateAcknowledgement == "approver" ? this.approverAcknowledged : false,
        acknowledge_authorizer_status: updateAcknowledgement == "authorizer" ? this.authorizerAcknowledged : false,
        acknowledge_supervisor_status: updateAcknowledgement == "supervisor" ? this.supervisorAcknowledged : false,
        acknowledge_user_id: updateAcknowledgement == "user" ? this.acknowledgeUserId : 0
      })
      .then((response) => {
        let acknowledgement_text = "Acknowledgement";
        if(updateAcknowledgement == "approver") {
          acknowledgement_text = "Approval";
        } else if(updateAcknowledgement == "authorizer") {
          acknowledgement_text = "Authorization";
        } else if(updateAcknowledgement == "supervisor") {
          acknowledgement_text = "Supervisor Acknowledgement";
        }

        this.onSuccess(`TA/JSA/SWMS Version ${this.ta_revised.version_no ? this.ta_revised.version_no : 1}: Updated "${acknowledgement_text}"`);
        this.find(this.ta_revised_view.id, true);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
  }

  onApprove() {
    this.approverAcknowledged = true;
    this.inProgress = true;
    this.acknowledgementUpdate("approver");
  }

  onAuthorize() {
    this.authorizerAcknowledged = true;
    this.inProgress = true;
    this.acknowledgementUpdate( "authorizer");
  }

  onSupervisorAcknowledge() {
    this.supervisorAcknowledged = true;
    this.inProgress = true;
    this.acknowledgementUpdate( "supervisor");
  }

  onAcknowledge() {
    this.acknowledgeUserId = this.app.user.id;
    this.inProgress = true;
    this.acknowledgementUpdate( "user");
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
