<div mat-dialog-title style="display: flex; justify-content: space-between; border-bottom: 1px solid silver;">
  <h5> Induction Modules </h5>
  <button mat-icon-button mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="p-3">
  <p *ngIf="!inductionService?.data?.modules">
    We could not find any induction material. Please contact {{ utils.getAppName() }} for assistance.
  </p>

  <h5 *ngIf="inductionService?.data?.name">{{ inductionService.data.name }}</h5>
  <div *ngIf="inductionService?.data?.description" [innerHTML]="inductionService?.data?.description | sanitizeHtml"></div>

  <p>
    Click on each of these modules, read the induction and complete all questions.
    You need to save your answers at the end of each module. When all answers are
    saved for each module, you can submit your answers. You will not be able to
    revisit this induction once you submitted your answers. Scroll down to see if
    there are more modules to complete.
  </p>

  <div class="list-group">
    <button
      type="button"
      class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
      *ngFor="let module of inductionService?.data?.modules; let i = index"
      (click)="onOpenModule(i)">
      {{ i + 1 }}. {{ module.name }}
      <span>
        <span
          *ngIf="module?.was_saved"
          class="badge badge-primary badge-pill"
          appMarginRight>
          Saved
        </span>
        <mat-icon>navigate_next</mat-icon>
      </span>
    </button>
  </div>
</div>

<mat-dialog-actions>
  <div appMarginTop>
    <button
      mat-raised-button
      type="button"
      color="primary"
      [disabled]="!canSubmit()"
      (click)="onSubmitAnswers()">
      Submit Answers
    </button>
  </div>
</mat-dialog-actions>
