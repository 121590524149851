  <mat-sidenav-container>
    <mat-sidenav position="end" [opened]="true" [disableClose]="true" mode="side" *ngIf="site.id">

      <mat-card>
        <mat-card-header>
          <mat-card-title>#{{ site.id }}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <p> <b>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Name:</b> {{ site.name }} </p>
          <p> <b>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Address:</b> {{ site.location }} </p>
          <p> <b>Created At:</b> {{ (site.date_created * 1000) | timezoneConvertMedium }} </p>
          <p> <b>Account:</b> {{ site?.account?.name }} </p>
        </mat-card-content>

        <mat-card-actions></mat-card-actions>
      </mat-card>

      <mat-list>
        <mat-list-item routerLink="details" routerLinkActive="active-submenu-item"> Details </mat-list-item>
      </mat-list>
    </mat-sidenav>

    <section>
      <router-outlet></router-outlet>
    </section>
  </mat-sidenav-container>

  <ng-template #showLoader>
    <div class="d-flex justify-content-center align-items-center full-height">
      <table>
        <tr>
          <td class="d-flex justify-content-center">
            <mat-progress-spinner [mode]="'indeterminate'" diameter="32"></mat-progress-spinner>
          </td>
        </tr>
        <tr>
          <td class="d-flex justify-content-center">
            <p>Please wait while we get the information...</p>
          </td>
        </tr>
      </table>
    </div>
  </ng-template>
