<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/prequalifications">
    <span id="title">Pre-Qualification Templates</span>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <span class="mr-auto">
      <button mat-raised-button color="primary" (click)="add()" id="add"
              class="margin-right">
        <mat-icon>add</mat-icon>
        Add Template
      </button>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>
        <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>delete</mat-icon> Delete Selected
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Pre-Qualification Templates." style="min-width: 120px">
        <mat-icon>more_vert</mat-icon>
        List Menu
      </button>
    </span>

    <form tourAnchor="tour.search" class="form-inline my-2 my-lg-0" style="min-width: 325px"
          (ngSubmit)="dataSource.getData(true)">
      <input class="form-control mr-sm-2" type="search"
             [(ngModel)]="dataSource.search" name="search" placeholder="Search"
             aria-label="Search" />
      <button mat-raised-button
              class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
              color="secondary"
              type="button"
              (click)="dataSource.getData(true)"
              id="searchButton">
        <mat-icon>search</mat-icon>Search
      </button>
    </form>
  </div>

</nav>

<div class="mat-elevation-z8 scroll-box">
  <table mat-table [dataSource]="dataSource" style="width: 100%" matSort
    matSortActive="name" matSortDisableClear matSortDirection="asc">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="
            dataSource.selection.hasValue() && dataSource.isAllSelected()
          " [indeterminate]="
            dataSource.selection.hasValue() && !dataSource.isAllSelected()
          ">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="no-padding"
        class="no-padding" mat-sort-header>
        #
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        {{ prequalification.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="no-padding"
        class="no-padding" mat-sort-header>
        Name
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        {{ prequalification.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="default">
      <th mat-header-cell *matHeaderCellDef class="no-padding"
        class="no-padding" mat-sort-header>
        Default
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        <mat-slide-toggle [(ngModel)]="prequalification.default" name="default"
          (change)="toggleDefault(prequalification.id)"
          matTooltip="{{prequalification.default ? 'Unset as default Pre-Qualification' : 'Make this the default Pre-Qualification for all future ' + utils.getLangTerm('contractors.plural', 'Contractors') }}">
        </mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="enable_notifications">
      <th mat-header-cell *matHeaderCellDef class="no-padding"
        class="no-padding" mat-sort-header>
        Send Initial Notifications
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        <mat-slide-toggle [(ngModel)]="prequalification.enable_notifications" name="enable_notifications"
          (change)="toggleNotifications(prequalification.id)"
          matTooltip="{{(prequalification.enable_notifications ? 'Disable ' : 'Enable ') + 'initial notifications for this pre-qualification when a ' + utils.getLangTerm('contractors.singular', 'Contractor') + ' is added.'}}">
        </mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="items">
      <th mat-header-cell *matHeaderCellDef class="no-padding"
        class="no-padding">
        Item Count
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        {{ prequalification.items.length }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef class="no-padding"
        class="no-padding" mat-sort-header>
        Date Created
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        {{ (prequalification.date_created * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef class="no-padding"
        class="no-padding" mat-sort-header>
        Date Created (UTC)
      </th>
      <td mat-cell *matCellDef="let prequalification" class="no-padding">
        {{ (prequalification.date_created * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let prequalification">
        <a type="button" mat-button routerLink="/prequalifications/{{ prequalification.id }}/view" matTooltip="View the pre-qualification template information." matTooltipPosition="above">
          View
        </a>

        <button type="button" mat-button (click)="edit(prequalification)" matTooltip="Edit the pre-qualification template information." matTooltipPosition="above">
          Edit
        </button>

        <button type="button" mat-button (click)="onCreateFrom(prequalification)" matTooltip="Create pre-qualification template from this pre-qualification template information." matTooltipPosition="above">
         Clone
        </button>

        <button type="button" mat-button (click)="onRemove(prequalification)" matTooltip="Remove (Archive) the pre-qualification template." matTooltipPosition="above">
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayed_columns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayed_columns"></tr>
  </table>

  <mat-paginator [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>
</div>
