import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { PageModel } from '../models/page.model';
import { ApiService } from '../shared/api.service';
import { ActionModel } from './action.model';
import { ApiResponseModel } from '../models/api-response.model';
import { FileUploaderService } from '../shared/file-uploader.service';

type Page = PageModel<ActionModel>;
type ActionResult = ApiResponseModel<ActionModel>;

@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  constructor(
    private api: ApiService,
    private fileUploader: FileUploaderService
  ) {}

  public storeFiles(files: FileList, id: number) {
    return this.fileUploader.storeUploadedFiles(files, 'action', id);
  }

  public post(action: ActionModel): Observable<ApiResponseModel<ActionModel>> {
    if (action.due) { action.due = this.formatDate(action.due); }
    return this.api.laravelApiObservable('post', 'actions', action);
  }

  public remove(ids: number[]): Observable<ActionModel> {
    return this.api.laravelApiObservable('delete', `actions/${ids.join(',')}`);
  }

  public find(id: number): Observable<ActionResult> {
    return this.api.laravelApiObservable('get', `actions/${id}`);
  }

  public get(): Observable<Page> {
    return this.api.laravelApiObservable('get', 'actions');
  }

  public put(action: ActionModel): Observable<ApiResponseModel<ActionModel>> {
    if (action.due) { action.due = this.formatDate(action.due); }
    return this.api.laravelApiObservable('put', `actions/${action.id}`, action);
  }

  public complete(id: number): Observable<null> {
    return this.api.laravelApiObservable('patch', `actions/complete/${id}`);
  }

  public open(id: number): Observable<null> {
    return this.api.laravelApiObservable('patch', `actions/open/${id}`);
  }

  private formatDate(date: Date | string): string {
    return moment(date).format('YYYY-MM-DD');
  }
}
