<div class="container-fluid">

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">{{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }} Visited</span>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto"></span>

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (ngSubmit)="dataSource.getData(true)">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search"/>

        <button
          mat-raised-button
          color="secondary"
          type="submit"
          class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table class="w-100" mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let site">{{ site.id }}</td>
      </ng-container>

      <!-- Owner Column -->
      <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef>Owner</th>
        <td mat-cell *matCellDef="let site">{{ site?.account?.name }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let site">
          <ng-container *ngIf="site?.parent_site">
            <small style="display: block;">
              <a *ngIf="includeAdminActions; else parentSiteNameOnly" [routerLink]="utils.prepareSiteRelatedLink(site?.parent_site?.id, null, '/edit/details')">
                {{ site?.parent_site?.name || '' }}
              </a>
              <ng-template #parentSiteNameOnly>
                {{ site?.parent_site?.name || '' }}
              </ng-template>
            </small>
            <mat-icon style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
          </ng-container>
          <a *ngIf="includeAdminActions; else siteNameOnly" [routerLink]="utils.prepareSiteRelatedLink(site?.id, site?.parent_site?.id, '/edit/details')">
            {{ site?.name || '' }}
          </a>
          <ng-template #siteNameOnly>
            {{ site?.name || '' }}
          </ng-template>
        </td>
      </ng-container>

      <!-- Location Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Address</th>
        <td mat-cell *matCellDef="let site">{{ site.location }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>

</div>
