import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaStepsEditComponent } from './steps/ta-steps-edit/ta-steps-edit.component';
import { TaStepsViewComponent } from './steps/ta-steps-view/ta-steps-view.component';
import { TaStepsComponent } from './steps/ta-steps/ta-steps.component';
import { TaskAnalysesEditComponent } from './task-analyses-edit/task-analyses-edit.component';
import { TaskAnalysesViewComponent } from './task-analyses-view/task-analyses-view.component';
import { TaskAnalysesComponent } from './task-analyses/task-analyses.component';
import { TaWorkersEditComponent } from './workers/ta-workers-edit/ta-workers-edit.component';
import { TaWorkersViewComponent } from './workers/ta-workers-view/ta-workers-view.component';
import { TaWorkersComponent } from './workers/ta-workers/ta-workers.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  { 
    path: 'task-analysis', 
    component: TaskAnalysesComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'task-analysis/:ta_id',
    children: [
      { path: 'edit', component: TaskAnalysesEditComponent },
      { path: 'view', component: TaskAnalysesViewComponent },
      {
        path: 'workers',
        children: [
          { path: '', component: TaWorkersComponent },
          { path: ':worker_id/edit', component: TaWorkersEditComponent },
          { path: ':worker_id/view', component: TaWorkersViewComponent }
        ]
      },
      {
        path: 'steps',
        children: [
          { path: '', component: TaStepsComponent },
          { path: ':step_id/edit', component: TaStepsEditComponent },
          { path: ':step_id/view', component: TaStepsViewComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskAnalysesRoutingModule {}
