import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/utils.service';
import { ItemModel } from '../item.model';
import { ItemsService } from '../items.service';

@Component({
  selector: 'app-items-edit',
  templateUrl: './items-edit.component.html',
  styleUrls: ['./items-edit.component.scss']
})
export class ItemsEditComponent implements OnInit {
  item: ItemModel = new ItemModel();
  pivotId: number;

  /**
   * Is the form being submitted?
   */
  inProgress = false;

  constructor(
    private route: ActivatedRoute,
    private utils: UtilsService,
    private itemsService: ItemsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => this.handleParams(params));
  }

  onSubmit() {
    if (this.inProgress) {
      return;
    }

    this.inProgress = true;
    this.item.id ? this.update() : this.create();
  }

  public delete() {
    this.utils.showModal(
      'Remove Item',
      `Are you sure you want to remove "${this.item.name}"?`,
      () =>
        this.itemsService.delete(this.item.id).subscribe((item: ItemModel) => {
          this.utils.showToast(`Deleted Item: ${item.name}`);
          this.router.navigate(['items']);
        })
    );
  }

  private create() {
    this.itemsService.post(this.item).subscribe({
      next: (item) => {
        this.utils.showToast(`Created Item: ${item.name}`);
        this.router.navigate(['items']);
      },
      error: () => (this.inProgress = false),
      complete: () => (this.inProgress = false)
    });
  }

  private update() {
    this.itemsService.put(this.item).subscribe({
      next: () => {
        this.utils.showToast(`Edited Item`);
        this.router.navigate(['items']);
      },
      error: () => (this.inProgress = false),
      complete: () => (this.inProgress = false)
    });
  }

  private handleParams(params: Params) {
    if (isNaN(params.id)) { return; }
    this.pivotId = params.pivotId;
    this.itemsService
      .find(params.id, params.pivotId)
      .subscribe((item: ItemModel) => (this.item = item));
  }
}
