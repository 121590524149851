<h1 mat-dialog-title>Claim a QR Code</h1>
<mat-dialog-content>
  <mat-form-field>
    <input
      matInput
      [(ngModel)]="paper_id"
      placeholder="Code ID"
      maxlength="8"
      minlength="8"
      #paperInput="ngModel"
    />
  </mat-form-field>
</mat-dialog-content>

<div mat-dialog-actions class="action-buttons">
  <span [matTooltip]="paperInput.invalid ? 'Code IDs should be 8 characters long' : ''">
    <button
      mat-raised-button
      color="primary"
      (click)="claim()"
      [disabled]="paperInput.invalid || !paper_id || inProgress">
      <mat-icon>check</mat-icon>
      Claim
    </button>
  </span>

  <span class="mr-auto"></span>

  <button mat-raised-button [mat-dialog-close]="false" color="secondary">
    <mat-icon>close</mat-icon>
    Cancel
  </button>
</div>
