<div class="container-fluid">
  <nav class="navbar navbar-expand-lg">
      <span class="mr-auto">
        <button mat-raised-button color="primary" (click)="onAdd()"
          matTooltip="Add a cause to the incident." appMarginRight id="add">
          <mat-icon>add</mat-icon>
          Add Cause
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onDeleteSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Incident Causes." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>
      </span>

      <form class="form-inline my-2 my-lg-0"
            style="min-width: 325px"
            (ngSubmit)="dataSource.getData(true)">
        <input class="form-control mr-sm-2" type="search"
          [(ngModel)]="dataSource.search" name="search" placeholder="Search"
          aria-label="Search" id="search-input" />
        <button mat-raised-button
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                color="secondary"
                (click)="dataSource.getData()"
                id="search-button">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
  </nav>

  <table mat-table [dataSource]="dataSource" matSort matSortActive="name"
    matSortDirection="asc" matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="
            dataSource.selection.hasValue() && dataSource.isAllSelected()
          " [indeterminate]="
            dataSource.selection.hasValue() && !dataSource.isAllSelected()
          ">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        #
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.id }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Name
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Description
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.description }}
      </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Type
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.type }}
      </td>
    </ng-container>

    <!-- Date Created Column -->
    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Date Created
      </th>
      <td mat-cell *matCellDef="let element">
        {{ (element.date_created * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <!-- Date Created (UTC) Column -->
    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Date Created (UTC)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ (element.date_created * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let element">
        <button type="button" mat-button (click)="onEdit(element)" matTooltip="Edit the incident cause information." matTooltipPosition="above">
          Edit
        </button>

        <button type="button" mat-button (click)="onRemove(element.id)" matTooltip="Remove (Archive) the incident cause." matTooltipPosition="above">
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons></mat-paginator>
</div>
