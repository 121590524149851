<nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="prequal.id">
  <span class=" mr-auto">
    <button mat-raised-button routerLink="../../" class="margin-right">
      <mat-icon>arrow_back</mat-icon> Back
    </button>

    <button mat-raised-button class="margin-right"
      routerLink="{{ prequal.id }}/edit" id="edit" *ngIf="app.account.isMasterAccount()">
      <mat-icon>edit</mat-icon>
      Edit
    </button>

    <button mat-raised-button color="danger" (click)="onDelete()" *ngIf="app.account.isMasterAccount()">
      <mat-icon>delete</mat-icon>
      Delete
    </button>
  </span>

  <button mat-raised-button class="force-right" (click)="onDownloadPDF()">
    <mat-icon>save_alt</mat-icon>
    Download as PDF
  </button>
</nav>

<div class="alert alert-info" role="alert" *ngIf="!app.account.isMasterAccount()">
  This Pre-Qualification is managed by "{{app.account.master_account.name}}". Please get in touch with them if you need to make any changes.
</div>

<div class="app-padding" *ngIf="prequal.id">
  <h6>{{ utils.getLangTerm('contractors.singular', 'Contractor') }}</h6>
  <p>{{ prequal.contractor?.name }}</p>

  <ng-container *ngIf="prequal.description != ''">
    <h6>Pre-Qualification Description</h6>
    <p>{{ prequal.description }}</p>
  </ng-container>

  <h6>Health and Safety Score</h6>
  <p appPrequalScoreColor [scorePercentage]="prequal.score">
    {{ prequal.score }}%
  </p>

  <ng-container *ngIf="prequal.expires_at">
    <h6>Pre-Qualification Expiry Date</h6>
    <p>{{ (prequal.expires_at * 1000) | timezoneConvertShort }}</p>
  </ng-container>

  <p><sup>The {{ utils.getLangTerm('contractors.singular', 'Contractor') }} Pre-Qualification score is calculated based on the quantity of approved documents and their ratings.</sup></p>

  <app-contractors-items (change)="getPreQualification()" *ngIf="prequal.id"
    [prequalId]="prequal.id">
  </app-contractors-items>
</div>
