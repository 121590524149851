<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <form #form="ngForm" class="form-container" (ngSubmit)="onSubmit(form)">
        <div class="card">
          <div class="card-body form-container">
            <h4 class="card-title">User Details</h4>

            <p>
              <mat-form-field class="full-width">
                <mat-label>Full Name</mat-label>
                <input matInput placeholder="Full Name" [(ngModel)]="app.user.contact_person" name="contact_person" type="text" #contact_person required />
                <mat-hint>Enter your first and last name.</mat-hint>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field class="full-width">
                <mat-label> {{ utils.getLangTerm('users.form.mobile', 'Mobile Number') }}</mat-label>
                <app-phone-input
                  [idInput]="mobile_input_id"
                  [numberInput]="app.user.mobile"
                  [countryCodeInput]="app.user.mobile_country_code"
                  [dialCodeInput]="app.user.mobile_dial_code"
                  [e164NumberInput]="app.user.mobile_e164"
                  (phoneDetailsOutput)="getOutputPhoneDetails($event)"
                  required="true">
                </app-phone-input>
                <mat-hint>Enter your {{ utils.getLangTerm('users.form.mobile', 'Mobile Number') }}.</mat-hint>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field class="full-width">
                <mat-label>Employer</mat-label>
                <input type="text" matInput placeholder="Employer" [value]="app.user?.employer?.name" readonly (click)="onSelectEmployer()">
                <mat-hint>Select your employer from a selection of accounts you have access to.</mat-hint>
                <button mat-button matSuffix (click)="onSelectEmployer()" type="button">
                  <mat-icon>business</mat-icon>
                  Select Employer
                </button>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field class="full-width">
                <mat-label>Job Title</mat-label>
                <mat-select matInput placeholder="Job Title" [(ngModel)]="app.user.trade" name="trade" #trade>
                  <mat-option *ngFor="let trade of trades" [value]="trade.name">{{ trade.name }}</mat-option>
                </mat-select>
                <mat-hint>Select your job title.</mat-hint>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field class="full-width">
                <mat-label>IQP Number</mat-label>
                <input matInput placeholder="IQP Number" [(ngModel)]="app.user.iqp_number" name="iqp_number" type="text" />
                <mat-hint>Enter your IQP (Independent Qualified Person) number. Leave it blank if you don't know what this is.</mat-hint>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field class="full-width">
                <mat-label>Address</mat-label>
                <input matInput placeholder="Address" [(ngModel)]="app.user.address" name="address" type="text" />
                <mat-hint>Enter your address or the address of your employer.</mat-hint>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field class="full-width">
                <mat-label> {{ utils.getLangTerm('users.form.suburb', 'Suburb') }} </mat-label>
                <input matInput [placeholder]="utils.getLangTerm('users.form.suburb', 'Suburb')" [(ngModel)]="app.user.suburb" name="suburb" type="text" />
                <mat-hint>Enter your {{ utils.getLangTerm('users.form.suburb', 'Suburb') }}.</mat-hint>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field class="full-width">
                <mat-label> {{ utils.getLangTerm('users.form.city', 'City') }} </mat-label>
                <input matInput [placeholder]="utils.getLangTerm('users.form.city', 'City')" [(ngModel)]="app.user.city" name="city" type="text" />
                <mat-hint>Enter your {{ utils.getLangTerm('users.form.city', 'City') }}.</mat-hint>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field class="full-width">
                <mat-label> {{ utils.getLangTerm('users.form.postal_code', 'Postal Code') }} </mat-label>
                <input matInput [placeholder]="utils.getLangTerm('users.form.postal_code', 'Postal Code')" [(ngModel)]="app.user.postal_code" name="postal_code" type="text" />
                <mat-hint>Enter your {{ utils.getLangTerm('users.form.postal_code', 'Postal Code') }}.</mat-hint>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field class="full-width">
                <mat-label>Country</mat-label>
                <input matInput placeholder="Country" [(ngModel)]="app.user.country" name="country" type="text" />
                <mat-hint>Enter your country.</mat-hint>
              </mat-form-field>
            </p>

            <p class="d-flex justify-content-between align-items-center">
              <app-timezone-select style="width: 95%" [timezoneInput]="currentTimezone" (timezoneOutput)="getOutputTimezone($event)"></app-timezone-select>
                <span (click)="removeSelectedTimezone()"><mat-icon class="text-danger">close</mat-icon></span>
            </p>

            <button mat-raised-button [disabled]="!form.valid || inProgress || mobile_error_state" color="primary" (click)="onSubmit(form)"
              class="detail-button margin-right" type="button">
              <mat-icon>save</mat-icon>
              Save
            </button>

          </div>
        </div>
      </form>
    </div>

    <div class="col-12 col-md-6">
      <h4>Profile Picture</h4>
      <div class="row">
        <div class="col-12" *ngIf="app.user.profile_pic">
          <img [src]="app.user.profile_pic" class="profile-picture" alt="Profile Picture" />
        </div>
        <div class="col-12">
          <button mat-raised-button color="secondary" type="button" (click)="onTriggerFileSelector($event)" appMarginTop appMarginBottom>
            Select &amp; Upload Image
          </button>
          <button mat-raised-button color="secondary" type="button" (click)="onRemoveProfilePicture()"  *ngIf="app.user.profile_pic" appMarginTop appMarginBottom appMarginLeft>
            Remove Image
          </button>
          <input type="file" (change)="onUploadSelectedFile($event)" style="display: none;" #fileInput />
        </div>
        <div class="col-12">
          <mat-hint>Only image files are allowed. Upload a square image for the best results.</mat-hint>
        </div>
      </div>
    </div>
  </div>
</div>
