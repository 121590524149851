<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <span class=" mr-auto">
    <button mat-raised-button routerLink="/hazardous_substances" appMarginRight>
      <mat-icon>arrow_back</mat-icon> Hazardous Substances
    </button>

    <button
      mat-raised-button
      routerLink="/hazardous_substances/{{ hazardous_substance.id }}/view"
      *ngIf="hazardous_substance.id"
      appMarginRight>
      <mat-icon>visibility</mat-icon>
      View
    </button>

    <button
      mat-raised-button
      (click)="onRemove()"
      *ngIf="hazardous_substance.id"
      appMarginRight>
      <mat-icon>delete</mat-icon>
      Delete
    </button>
  </span>
</nav>

<div class="container-fluid" appPadding>
  <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
    <div class="card">
      <div class="card-body form-container">
        <h5 class="card-title">Hazardous Substance Form</h5>

        <mat-form-field appMarginBottom>
          <input
            matInput
            placeholder="Substance / Product"
            [(ngModel)]="hazardous_substance.substance"
            name="substance"
            type="text"
            #substance
            required/>
          <mat-hint>Please enter the hazardous substance / product name.</mat-hint>
        </mat-form-field>

        <mat-form-field appMarginBottom>
          <textarea
            matInput
            placeholder="What is the risk / related harm?"
            [(ngModel)]="hazardous_substance.risk_info"
            name="risk_info"
            type="text"
            rows="4"
            #risk_info
            required>
          </textarea>
          <mat-hint>Please enter the risk / related harm.</mat-hint>
        </mat-form-field>

        <mat-form-field appMarginBottom>
          <textarea
            matInput
            placeholder="Precautions for use"
            [(ngModel)]="hazardous_substance.additional_info"
            name="additional_info"
            type="text"
            rows="4"
            #additional_info
            required>
          </textarea>
          <mat-hint>Please enter the precautions for use.</mat-hint>
        </mat-form-field>

        <mat-form-field appMarginBottom>
          <textarea
            matInput
            placeholder="Enter the storage requirements"
            [(ngModel)]="hazardous_substance.storage_requirements"
            name="storage_requirements"
            type="text"
            rows="4"
            #storage_requirements>
          </textarea>
          <mat-hint>Please enter the storage requirements.</mat-hint>
        </mat-form-field>

        <mat-form-field class="full-width" appMarginBottom>
          <mat-select placeholder="Hazardous Substance Type"
            [(ngModel)]="hazardous_substance.hazardous_substance_type"
            name="hazardous_substance_type" required id="hazardous_substance_type"
            (valueChange)="onChangeUnitType($event)">
            <mat-option *ngFor="let hazardous_substance_type of utils.getHazardousSubstanceTypes()" [value]="hazardous_substance_type">
              {{ ('(' + hazardous_substance_type?.substr(0, 1) + ') ') + hazardous_substance_type }}
            </mat-option>
          </mat-select>
          <mat-hint>Please select the type of hazardous substance.</mat-hint>
        </mat-form-field>

        <mat-form-field class="full-width" appMarginBottom>
          <mat-select placeholder="Unit Type"
                      [(ngModel)]="hazardous_substance.hazardous_substance_unit_type"
                      name="hazardous_substance_unit_type" required id="hazardous_substance_unit_type">
            <mat-option *ngFor="let hazardous_substance_unit_type of utils.getHazardousSubstanceUnitTypes()" [value]="hazardous_substance_unit_type">
              {{ hazardous_substance_unit_type }}
            </mat-option>
          </mat-select>
          <mat-hint>The default unit type is automatically inferred from the hazardous substance type.</mat-hint>
        </mat-form-field>

        <mat-form-field appMarginBottom>
          <input
            matInput
            placeholder="United Nation (UN) Class & Packaging Group"
            [(ngModel)]="hazardous_substance.un_classification"
            name="un_classification"
            type="text"
            #un_classification />
          <mat-hint>Please enter the United Nation (UN) Class & Packaging Group.</mat-hint>
        </mat-form-field>

        <mat-form-field appMarginBottom>
          <input
            matInput
            placeholder="GHS/HSNO Classification"
            [(ngModel)]="hazardous_substance.hsno_classification"
            name="hsno_classification"
            type="text"
            #hsno_classification />
          <mat-hint>Please enter the GHS/HSNO Classification.</mat-hint>
        </mat-form-field>

        <mat-form-field appMarginBottom>
          <input
            matInput
            placeholder="GHS/HSNO Approval # and Group Standard"
            [(ngModel)]="hazardous_substance.hsno_approval_number"
            name="hsno_approval_number"
            type="text"
            #hsno_approval_number />
          <mat-hint>Please enter the GHS/HSNO Approval # and Group Standard.</mat-hint>
        </mat-form-field>

        <mat-form-field floatLabel="always" appMarginBottom>
          <mat-label>Expiry Date of Safety Data Sheet (SDS)</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="selectedExpiryDate" [min]="currentDate" name="sds_expires_at" readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <button type="button" mat-button matSuffix mat-icon-button *ngIf="selectedExpiryDate" (click)="selectedExpiryDate = null" matTooltip="Remove the date."><mat-icon>close</mat-icon></button>
          <mat-datepicker #picker></mat-datepicker>
          <mat-hint>Please select the expiry date of the Safety Data Sheet (SDS).</mat-hint>
        </mat-form-field>

        <div appMarginTop appMarginBottom>
          <mat-checkbox
            [(ngModel)]="hazardous_substance.link_to_all_sites"
            name="link_to_all_sites"
            appMarginRight
            appMarginTop>
            Link to all {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }} or
          </mat-checkbox>

          <button
            mat-raised-button
            type="button"
            color="secondary"
            (click)="onSelectSites()"
            appMarginRight
            [matBadge]="'' + (hazardous_substance.link_to_all_sites ? 'All' : hazardous_substance.site_ids?.length)">
            <mat-icon>location_on</mat-icon>
            Select {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
          </button>
        </div>

        <div class="save-container">
          <button
            type="submit"
            mat-raised-button
            style="max-width: 100px;"
            [disabled]="!f.valid || inProgress"
            color="primary"
            class="save-button"
            id="save"
            appMarginRight>
            <mat-icon>save</mat-icon>
            Save
          </button>

          <button
            mat-raised-button
            (click)="onManagePPE()"
            color="secondary"
            [matBadge]="'' + (hazardous_substance?.ppe_ids?.length || '')"
            type="button" appMarginRight>
            <mat-icon>open_in_browser</mat-icon>
            PPE
          </button>

          <app-files-selector
            (selected)="newFiles = $event"
            [selectedFilesCount]="newFiles?.length"
            appMarginRight>
          </app-files-selector>

          <button
            mat-raised-button
            (click)="onRemoveFiles()"
            color="secondary"
            [matBadge]="'' + (hazardous_substance.files?.length)"
            [disabled]="!hazardous_substance.files?.length"
            type="button">
            <mat-icon>open_in_browser</mat-icon>
            Manage Files
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
