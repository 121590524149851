import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/utils.service';
import { AuditItemModel } from '../audit-item.model';
import { AuditItemsService } from '../audit-items.service';

@Component({
  selector: 'app-audit-items-edit',
  templateUrl: './audit-items-edit.component.html',
  styleUrls: ['./audit-items-edit.component.scss']
})
export class AuditItemsEditComponent implements OnInit {
  auditItem = new AuditItemModel();

  constructor(
    private route: ActivatedRoute,
    private util: UtilsService,
    private router: Router,
    private auditItemService: AuditItemsService,
    private utils: UtilsService
  ) {}

  ngOnInit() {
    this.auditItem.id = Number(this.route.snapshot.params['id']);
    if (!isNaN(this.auditItem.id)) {
      this.auditItemService
        .find(this.auditItem.id)
        .subscribe((response) => (this.auditItem = response.data));
    }
  }

  onSubmit() {
    isNaN(this.auditItem.id) ? this.create() : this.update();
  }

  delete() {
    this.utils.showModal(
      'Remove Question',
      `Are you sure you want to remove the "${this.auditItem.name}" question?`,
      () => {
        this.auditItemService.remove([this.auditItem.id]).subscribe(() => {
          this.utils.showToast(`The "${this.auditItem.name}" question was removed.`);
          this.router.navigate(['../..'], { relativeTo: this.route });
        });
      }
    );
  }

  private update() {
    this.auditItemService
      .put(this.auditItem)
      .subscribe(() => this.onSuccess(`The "${this.auditItem.name}" question was updated.`));
  }

  private create() {
    this.auditItemService
      .post(this.auditItem)
      .subscribe((response) =>
        this.onSuccess(`The "${response.data.name}" question was created.`)
      );
  }

  private onSuccess(message: string) {
    this.util.showToast(message);
    this.router.navigate(['/audit-items']);
  }
}
