<div mat-dialog-title>
  <h5>Question Details</h5>
  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <ng-container *ngIf="question_data.question">
    <h6>Question</h6>
    <p>{{ question_data.question }}</p>
  </ng-container>

  <ng-container *ngIf="question_data.status">
    <h6>Status</h6>
    <p>{{ question_data.status }}</p>
  </ng-container>

  <ng-container *ngIf="question_data.answers">
    <h6>Answers</h6>
    <p *ngFor="let answer of question_data.answers">
      {{ answer.answer_text }} -
      <b>{{ answer.answer_value ? 'Yes' : 'No' }}</b>
    </p>
  </ng-container>
</mat-dialog-content>
