import { Component, Inject, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { UtilsService } from "../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SitesSelectorComponent } from "../../shared/sites-selector/sites-selector.component";
import { NetworkedEmployeesSelectorComponent } from "../../shared/networked-employees-selector/networked-employees-selector.component";
import { NetworkedUsersSelectorComponent } from "../../shared/networked-users-selector/networked-users-selector.component";
import {ToolboxTalksFilters} from "./toolbox-talks-filters.interface";

@Component({
  selector: 'app-toolbox-talks-filter',
  templateUrl: './toolbox-talks-filter.component.html',
  styleUrls: ['./toolbox-talks-filter.component.scss']
})
export class ToolboxTalksFilterComponent implements OnInit {

  // Set default filter values.
  filters: ToolboxTalksFilters = {
    attendee_ids: [],
    date_range: [],
    site_ids: [],
    employee_ids: [],
    reported_by_ids: []
  } as ToolboxTalksFilters;

  constructor(
    public location: Location,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ToolboxTalksFilterComponent>
  ) { }

  ngOnInit() {
    this.filters.site_ids = this.data['site_ids'] || [];
    this.filters.reported_by_ids = this.data['reported_by_ids'] || [];
    this.filters.employee_ids = this.data['employee_ids'] || [];
    this.filters.attendee_ids = this.data['attendee_ids'] || [];
    this.filters.date_range = this.data['date_range'] || [];
  }

  /**
   * Sets the output date range for the filters.
   *
   * @param {any} event - The event object containing the new date range.
   * @return {void}
   */
  getOutputDateRange(event: any): void {
    this.filters.date_range = event;
  }

  /**
   * Displays a dialog for filtering sites and updates the site ids filter based on the selected sites.
   *
   * @returns {void}
   */
  onFilterSites(): void {
    this.utils.showComponentDialog(SitesSelectorComponent, {
        selected: this.filters.site_ids,
        multiple: true
      }, {
        width: '1024px'
      }, (results: any): void => {
        if ( typeof results != 'undefined' ) {
          this.filters.site_ids = results;
        }
    });
  }

  /**
   * Opens a dialog to select the reported by users and updates the filters accordingly.
   *
   * @return {void}
   */
  onSelectReportedBy(): void {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.filters.reported_by_ids,
      multiple: true,
      visitors_from_all_sites: true
    }, {
      width: '1024px'
    }, (results: number[]): void => {
      if ( !results ) { return; }
      this.filters.reported_by_ids = results;
    });
  }

  /**
   * Displays a dialog to select multiple employees and updates the filters with the selected employee IDs.
   *
   * @return {void}
   */
  onSelectEmployees(): void {
    this.utils.showComponentDialog(NetworkedEmployeesSelectorComponent, {
      selected: this.filters.employee_ids,
      multiple: true
    }, {
      width: '1024px'
    }, (results: number[]): void => {
      if ( !results ) { return; }
      this.filters.employee_ids = results;
    });
  }

  /**
   * Opens a dialog for selecting attendees and updates the filter with the selected attendee IDs.
   *
   * @return {void}
   */
  onSelectAttendees(): void {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.filters.attendee_ids,
      multiple: true,
      visitors_from_all_sites: true
    }, {
      width: '1024px'
    }, (results: number[]): void => {
      if ( !results ) { return; }
      this.filters.attendee_ids = results;
    });
  }

  /**
   * Clears all values in the filters object and calls the onSaveAndClose method.
   *
   * @return {void}
   */
  onClearAndClose(): void {
    // Clear all values.
    this.filters.site_ids = [];
    this.filters.reported_by_ids = [];
    this.filters.employee_ids = [];
    this.filters.attendee_ids = [];
    this.filters.date_range = [];
    // Save and close.
    this.onSaveAndClose();
  }

  /**
   * Saves the filters and closes the dialog.
   *
   * @returns {void}
   */
  onSaveAndClose(): void {
    this.dialogRef.close(this.filters);
  }
}
