<div mat-dialog-title>
  <h5>Prequalification Details</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <h6>Pre-Qualification Name</h6>
  <p id="name">{{ prequal.name }}</p>

  <ng-container *ngIf="prequal.description != ''">
    <h6>Pre-Qualification Description</h6>
    <p>{{ prequal.description }}</p>
  </ng-container>

  <h6>Client</h6>
  <p>{{ prequal.account?.name }}</p>

  <h6>Health and Safety Score</h6>
  <p>{{ prequal.score }}%</p>

  <app-my-items
    *ngIf="prequal?.items.length > 0"
    [items]="prequal.items"
    [edit]="false"
    [hash]="prequal.hash"
  ></app-my-items>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="downloadPdf()" color="secondary">
    <mat-icon>save_alt</mat-icon>
    Download as PDF
  </button>
  <span class="mr-auto"></span>
  <a [href]="shareLink" target="_blank" class="float-right">
    <mat-icon>open_in_new</mat-icon>
    Share
  </a>
  <button
    mat-icon-button
    ngxClipboard
    [cbContent]="shareLink"
    (click)="notifyCopied()"
    class="float-right"
    matTooltip="Copy link to clipboard"
  >
    <mat-icon>file_copy</mat-icon>
  </button>
</mat-dialog-actions>
