<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="javascript:void(0)">Related Hazards & Risks</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button type="button" color="primary" mat-raised-button (click)="onEditHazard()" matTooltip="Click this button to create a new hazard.">
          <mat-icon>add</mat-icon>
          Add Hazard/Risk
        </button>
      </span>

      <div class="search-container">
        <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
          <input
            class="form-control mr-sm-2"
            type="search"
            [(ngModel)]="dataSource.search"
            name="search"
            placeholder="Search"
            aria-label="Search" id="search-input"
            matTooltip="Search the Hazards & Risks by name, description or controls." />
        </form>

        <button
          mat-raised-button
          color="secondary"
          (click)="dataSource.getData(true)"
          appMarginRight
          id="search-button"
          matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon> Search
        </button>
      </div>
    </div>
  </nav>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto"></span>
      <mat-select class="filter-dropdowns mr-3" [(ngModel)]="dataSource.status" matTooltip="Filter Hazards & Risks by verification status." name="status" (selectionChange)="dataSource.getData(true)">
        <mat-option value="">All Statuses</mat-option>
        <mat-option value="Verified">Verified</mat-option>
        <mat-option value="Unverified">Unverified</mat-option>
      </mat-select>
    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Toggle select all records.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let hazard">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(hazard.id) : null"
            [checked]="dataSource.selection.isSelected(hazard.id)"
            matTooltip="Toggle select the record.">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- ID of the hazard. -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the id of the corrective action.">
          ID #
        </th>
        <td mat-cell *matCellDef="let hazard">
          {{ hazard.id }}
        </td>
      </ng-container>

      <!-- Hazard / Description -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hazard/Risk</th>
        <td mat-cell *matCellDef="let hazard" class="text-wrap">
          <b>{{ hazard.name }}</b> <br />
          <small>{{ hazard.description }}</small>
        </td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let hazard">{{ hazard.status }}</td>
      </ng-container>

      <!-- Initial Risk Assessment -->
      <ng-container matColumnDef="ira">
        <th mat-header-cell *matHeaderCellDef>Initial <br />Risk Assessment</th>
        <td mat-cell *matCellDef="let hazard">
          <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(hazard.ira_likelihood, hazard.ira_severity))">
            {{ utils.getRiskAssessmentText(hazard.ira_likelihood, hazard.ira_severity) }}
          </span>
        </td>
      </ng-container>

      <!-- Residual Risk Assessment -->
      <ng-container matColumnDef="rra">
        <th mat-header-cell *matHeaderCellDef>
          Residual <br />Risk Assessment
        </th>
        <td mat-cell *matCellDef="let hazard">
          <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(hazard.rra_likelihood, hazard.rra_severity))">
            {{ utils.getRiskAssessmentText(hazard.rra_likelihood, hazard.rra_severity) }}
          </span>
        </td>
      </ng-container>

      <!-- Date the hazard was created at -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the Hazard/Risk was added.">
          Created At
        </th>
        <td mat-cell *matCellDef="let hazard">
          {{ (hazard.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- Date the hazard was created at -->
      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the date the Hazard/Risk was added.">
          Created At (UTC)
        </th>
        <td mat-cell *matCellDef="let hazard">
          {{ (hazard.date_created * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let hazard">
          <button type="button" mat-button class="mr-3" (click)="onEditHazard(hazard)" matTooltip="Click this button to edit the Hazard/Risk.">
            Edit
          </button>
          <button type="button" mat-button (click)="onRemoveHazard(hazard.id)" matTooltip="Click this button to remove the Hazard/Risk.">
            Delete
          </button>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="listColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: listColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
