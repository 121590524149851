import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { CodeModel } from 'src/app/models/code.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-codes-edit',
  templateUrl: './sites-codes-edit.component.html',
  styleUrls: ['./sites-codes-edit.component.css']
})
export class SitesCodesEditComponent implements OnInit {

  parent_id: number;
  child_id: number;
  site_id: number;

  code: CodeModel = new CodeModel();

  features: string[] = ['Access Control'];

  types: any = {
    'Access Control': ['In', 'Out', 'In/Out', 'Location'] // , 'Evacuation'
  };

  inProgress: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) {}

  ngOnInit() {

    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      this.code.id = Number(this.route.snapshot.params['code_id']);

      if (this.code.id) {
        this.api.laravelApiRequest(
          'get',
          'sites/' + this.site_id + '/qrcodes/' + this.code.id,
          {},
          {},
          (response) => {
            this.code.apply(response.data);
          }
        );

        // this.service.get(this.site_id, this.code.id);
      }
    }
  }

  onRemove() {
    this.utils.showModal(
      'Remove QR Code',
      'Are you sure you want to remove this QR code?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'sites/' + this.site_id + '/qrcodes/' + this.code.id,
          {},
          {},
          (response) => {
            this.utils.showToast('The QR code was removed.');
            this.router.navigate([this.utils.prepareSiteRelatedLink(this.site_id, this.child_id ? this.parent_id : null, 'edit/codes')]);
          }
        );
      }
    );
  }

  onSubmit(form: NgForm) {
    // make sure the form data is valid
    if (form.valid) {

      if ( this.inProgress ) {
        return;
      }

      this.inProgress = true;

      // check if the site should be created or updated
      if (this.code.id) {
        this.api.laravelApiRequest(
          'put',
          'sites/' + this.site_id + '/qrcodes/' + this.code.id,
          this.code,
          {},
          (response) => {
            this.utils.showToast('The QR code was updated');
            this.inProgress = false;
          },
          () => {
            this.inProgress = false;
          }
        );

        // update the site
        // this.service.update(this.site_id);
      } else {
        this.api.laravelApiRequest(
          'post',
          'sites/' + this.site_id + '/qrcodes',
          this.code,
          {},
          (response) => {
            this.utils.showToast('The QR code was added');
            this.inProgress = false;
            this.router.navigate([this.utils.prepareSiteRelatedLink(this.site_id, this.child_id ? this.parent_id : null, 'edit/codes')]);
          },
          () => {
            this.inProgress = false;
          }
        );

        // create the site
        // this.service.add(this.site_id, (response) => {
        //  // and navigate back to the list of sites
        //  this.router.navigate(['/sites', this.site_id, 'codes']);
        // });
      }
    } else {
      alert('Please make sure you enter all fields with valid information.');
    }
  }
}
