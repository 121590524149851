<mat-card>
  <mat-card-content>
    <span class="sc-custom-label">{{ title }}</span>
    <mat-hint>{{ subtitle }}</mat-hint>

    <canvas #signatureCanvasRef
            (mousedown)="startDrawing($event)"
            (mousemove)="draw($event)"
            (mouseup)="stopDrawing()"
            (touchstart)="startDrawing($event)"
            (touchmove)="draw($event)"
            (touchend)="stopDrawing()">
    </canvas>

    <mat-hint *ngIf="meta.user_name">To be signed by <b>{{ meta.user_name }}.</b></mat-hint>
  </mat-card-content>

  <mat-card-actions>
    <button type="button" mat-button mat-raised-button (click)="onChangeSigner()" [disabled]="!allow_change_signer" *ngIf="allow_change_signer && !usedDrawing">Change Signer</button>
    <button type="button" mat-button mat-raised-button (click)="clearSignature()" [disabled]="!hasDrawing" *ngIf="!usedDrawing">Clear Signature</button>
    <button type="button" mat-button mat-raised-button color="secondary" (click)="saveSignature()" [disabled]="!hasDrawing" *ngIf="!usedDrawing">Use Signature</button>
  </mat-card-actions>
</mat-card>
