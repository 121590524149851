<nav mat-tab-nav-bar hidden>
  <a mat-tab-link
    routerLink="details"
    routerLinkActive
    #rla1="routerLinkActive"
    [active]="rla1.isActive">
    Company Details
  </a>

  <a mat-tab-link
    routerLink="docs"
    routerLinkActive
    #rla2="routerLinkActive"
    [active]="rla2.isActive">
    Company Documents
  </a>

  <a mat-tab-link
    routerLink="user/details"
    routerLinkActive
    #rla3="routerLinkActive"
    [active]="rla3.isActive">
    My Details
  </a>

  <a mat-tab-link
    routerLink="user/docs"
    routerLinkActive
    #rla4="routerLinkActive"
    [active]="rla4.isActive">
    My Training / Competencies
  </a>

  <a mat-tab-link
    routerLink="managers"
    routerLinkActive
    #rla5="routerLinkActive"
    [active]="rla5.isActive">
    Account Managers
  </a>

  <a mat-tab-link
    routerLink="switch"
    routerLinkActive
    #rla6="routerLinkActive"
    [active]="rla6.isActive">
    Managed Accounts
  </a>
</nav>

<router-outlet></router-outlet>
