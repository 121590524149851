import { PrequalificationModel } from './prequalification.model';
import { Auditable } from '../shared/auditable.model';

export class ContractorModel extends Auditable {
  id = null; // the user id
  email = '';
  mobile = '';
  contact_person = '';
  signoff = false;
  date_signoff = '';
  name = '';
  industry = '';
  address = '';
  suburb = '';
  city = '';
  postal_code = '';
  country = '';
  personalised_message = '';
  percent_completed = 0;
  average_rating = 0;
  inductions_completed = 0;
  inductions_count = 0;
  prequalification: PrequalificationModel = new PrequalificationModel();

  entity_name = ''; // backward compat

  site_ids: number[] = [];
  link_to_all_sites = false;

  iqp_number = '';

  main_contact_id: number = null;

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = null; // the user id
    this.email = '';
    this.mobile = '';
    this.contact_person = '';
    this.signoff = false;
    this.date_signoff = '';
    this.name = '';
    this.industry = '';
    this.address = '';
    this.suburb = '';
    this.city = '';
    this.postal_code = '';
    this.country = '';
    this.personalised_message = '';
    this.percent_completed = 0;
    this.average_rating = 0;
    this.entity_name = '';

    this.site_ids.length = 0;
    this.link_to_all_sites = false;
    this.inductions_completed = 0;
    this.inductions_count = 0;
    this.prequalification = new PrequalificationModel();

    this.iqp_number = '';
  }
}
