import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { ApiRequestService } from '../shared/api-request.service';
import { UtilsService } from '../shared/utils.service';
import { GitLabIssue } from './gitlab-issue';
import { GitLabMilestone } from './gitlab-milestone';

@Component({
  selector: 'app-gitlab-issues',
  templateUrl: './gitlab-issues.component.html',
  styleUrls: ['./gitlab-issues.component.scss']
})
export class GitlabIssuesComponent implements OnInit {

  // The GitLab project ID.
  gitLabProjectId: number = 50468244;

  // The GitLab bearer token.
  gitLabBearerToken: string = "glpat-2D_R4X2xVzp8oQ_KwRyE";

  milestone: GitLabMilestone;

  milestones: GitLabMilestone[] = [];

  listDataSource: MatTableDataSource<GitLabIssue>;

  listDisplayColumns: string[] = [
    'id',
    'state',
    'updated_at',
    'title'
  ];

  page: number = 1;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getMilestones()
    .then(() => {
      this.getIssues();
    });
  }

  /**
   * Gets the milestones for the GitLab project.
   */
  async getMilestones(): Promise<any> {
    // Get the milestones for the project.
    try {
      const milestones = await this.api.makeRequest('get', `https://gitlab.com/api/v4/projects/${this.gitLabProjectId}/milestones`, {}, {
        state: "active"
      }, {
        "Authorization": `Bearer ${this.gitLabBearerToken}`
      });
      this.milestones.length = 0;
      this.milestones.push(...milestones);
      if (milestones.length > 0) {
        this.milestone = milestones[0];
      }
    } catch (_error) {
      this.utils.showToast('Failed to load GitLab milestones.');
      await this.router.navigate(['dashboard']);
    }
  }

  /**
   * Gets the issues for the selected milestone.
   */
  async getIssues(): Promise<any> {
    // Get the issues for the selected milestone.
    try {
      const issues = await this.api.makeRequest('get', `https://gitlab.com/api/v4/projects/${this.gitLabProjectId}/issues`, {}, {
        // state: "active",
        milestone: this.milestone.title,
        page: this.page,
        per_page: 30
      }, {
        "Authorization": `Bearer ${this.gitLabBearerToken}`
      });
      this.listDataSource = new MatTableDataSource<GitLabIssue>(issues);
    } catch (_error) {
      this.utils.showToast('Failed to load GitLab issues for the selected milestone.');
      await this.router.navigate(['dashboard']);
    }
  }

  /**
   * Returns true if the previous button should be disabled.
   */
  shouldDisablePrevButton() {
    // If we're on page 1, disable the previous button.
    return this.page <= 1;
  }

  /**
   * Returns true if the next button should be disabled.
   */
  shouldDisableNextButton() {
    // If there are no issues, disable the next button.
    return this.listDataSource && this.listDataSource.data && this.listDataSource.data.length == 0;
  }

  /**
   * Called when the user clicks the previous button.
   */
  onLoadPrev(): void {
    // Don't go below page 1.
    if ( this.page <= 1 ) {
      return;
    }
    // Decrement the page and load the previous page.
    this.page--;
    // Load the previous page.
    this.getIssues();
  }

  /**
   * Called when the user clicks the next button.
   */
  onLoadNext(): void {
    // Increment the page and load the next page.
    this.page++;
    // Load the next page.
    this.getIssues();
  }
}
