
<ng-container matColumnDef="date_range_filter">
  <div class="position-relative" style="padding: 5px !important;">
    <div class="filter-date-title mx-3">{{ dateRangeTitle }}</div>
    <input type="text" readonly
           [placeholder]="dateRangePlaceholder"
           [(ngModel)]="date_range" name="date_range"
           class="form-control mx-3 mt-3 filter-date" bsDaterangepicker
           [bsConfig]="datepickerConfig"
           #rangePicker="bsDaterangepicker"
           (onShown)="onDateRangePickerShow()"
           (ngModelChange)="onDateRangePickerChange()"
           [matTooltip]="dateRangeTooltip"
           id="date_range_input" />
  </div>
</ng-container>
