<div mat-dialog-title>
  <h5 *ngIf="!workPermit.id">Create Work Permit</h5>
  <h5 *ngIf="workPermit.id">Edit Work Permit</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit()">
  <mat-form-field class="full-width mb-3">
    <input
      matInput
      placeholder="Work Permit"
      [(ngModel)]="workPermit.title"
      name="title"
      type="text"
      required
      #title
    />
    <mat-error *ngIf="!title.valid">Please enter a Work Permit title.</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width mb-3">
      <textarea
        rows="4"
        matInput
        placeholder="Description"
        [(ngModel)]="workPermit.description"
        name="description"
        #description>
      </textarea>
  </mat-form-field>

  <mat-form-field class="full-width mb-3">
    <mat-label>Industry</mat-label>
    <mat-select [(ngModel)]="workPermit.industry_id" name="industry"
                #industry>
      <mat-option [value]="0">All</mat-option>
      <mat-option *ngFor="let industry of industries" [value]="industry.id">{{ industry.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <button
    mat-raised-button
    [disabled]="!form.valid || inProgress"
    color="primary"
    type="submit"
    id="save"
  >
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    type="button"
    mat-icon-button
    [disabled]="!workPermit.id"
    class="float-right"
    (click)="onDelete()"
  >
    <mat-icon>delete</mat-icon>
  </button>
</form>

