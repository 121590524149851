<div mat-dialog-title>
  <h5>Training/Competency Links - <span *ngIf="name">{{ name }}</span></h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <ng-container *ngIf="links?.length">
    <mat-list class="link-list">
      <mat-list-item *ngFor="let link of links; last as isLast">
        <div mat-line class="text-wrap"><a target="_blank" [href]="link.url">{{ link.url | ellipsis: [150] }}</a></div>
        <div mat-line class="text-wrap">Created: {{ (link.date_created * 1000) | timezoneConvertMedium }} </div>
        <div mat-line class="text-wrap">Last Modified: {{ (link.date_modified * 1000) | timezoneConvertMedium }} </div>
        <mat-divider class="w-100 m-0" [inset]="true" *ngIf="!isLast"></mat-divider>
      </mat-list-item>
    </mat-list>
  </ng-container>
</mat-dialog-content>
