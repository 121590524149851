import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { DirectivesModule } from '../directives/directives.module';
import { SitesSelectorComponent } from '../shared/sites-selector/sites-selector.component';
import { ErpRoutingModule } from './erp-routing.module';
import { ErpsEditDialogComponent } from './erps-edit-dialog/erps-edit-dialog.component';
import { ErpsEditComponent } from './erps-edit/erps-edit.component';
import { ErpsSelectorComponent } from './erps-selector/erps-selector.component';
import { ErpsViewComponent } from './erps-view/erps-view.component';
import { ErpsComponent } from './erps/erps.component';
import { ErpTeamsEditComponent } from './erps-teams/erps-teams-edit/erp-teams-edit.component';
import { ErpTeamsViewComponent } from './erps-teams/erps-teams-view/erp-teams-view.component';
import { ErpTeamsComponent } from './erps-teams/erps-teams/erp-teams.component';
import { FilesModule } from '../files/files.module';

@NgModule({
  declarations: [
    ErpsComponent,
    ErpsEditComponent,
    ErpsEditDialogComponent,
    ErpsSelectorComponent,
    ErpsViewComponent,
    ErpTeamsComponent,
    ErpTeamsViewComponent,
    ErpTeamsEditComponent
  ],
  imports: [
    CommonModule,
    ErpRoutingModule,
    FormsModule,
        MatBadgeModule,
    MatMenuModule,
    MatListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatTabsModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    DirectivesModule,
    FilesModule
  ],
  entryComponents: [SitesSelectorComponent]
})
export class ErpModule {}
