import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { StorageService } from './storage.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(
    public storage: StorageService,
    public router: Router,
    public utils: UtilsService,
    public app: AppService
  ) {}

  isAuthenticated(): boolean {
    return this.app.isAuthenticated;
  }

  isSiteSoftAdmin(): boolean {
    return this.app.user.is_sitesoft_admin;
  }

  signout(additionalMessage: string = ''): void {
    // clear the session
    this.storage.clear();

    // show a message.
    this.utils.showToast('You were signed out. ' + additionalMessage);

    // redirect the user
    this.router.navigate(['/sign-in']);
  }
}
