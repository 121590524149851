<div mat-dialog-title>
  <div class="d-flex">
    <h3>
      Networked Users {{ dataSource.multiple ? '(' + dataSource.selection.selected.length + ')' : '' }}
    </h3>

    <span style="flex: 1 1 auto;"></span>

    <button mat-raised-button (click)="onChangeSelectedAccount()" class="mr-sm-2">
      Select Company
    </button>

    <form
      class="form-inline my-2 my-lg-0"
      (ngSubmit)="dataSource.getData(true)">
      <input
        class="form-control mr-sm-2"
        type="search"
        [(ngModel)]="dataSource.search"
        name="search"
        placeholder="Search Users"
        aria-label="Search Users" />
    </form>

    <button mat-raised-button (click)="dataSource.getData(true)" appMarginRight>
      <mat-icon>search</mat-icon> Search
    </button>
  </div>
</div>

<mat-dialog-content>
  <table mat-table [dataSource]="dataSource" matSort
    matSortActive="accounts.name" matSortDirection="asc" matSortDisableClear>

    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? onToggleRowSelection(row) : null"
            [checked]="dataSource.selection.isSelected(row.id)">
          </mat-checkbox>
        </td>
      </ng-container>

    <ng-container matColumnDef="accounts.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
      <td mat-cell *matCellDef="let element">{{ element.company_name }}</td>
    </ng-container>

    <ng-container matColumnDef="users.contact_person">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Full Name
      </th>
      <td mat-cell *matCellDef="let element">
        <a (click)="onUserPublicView(element.hash, $event)"
           matTooltip="View User Public Profile">
          {{ element.contact_person }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="users.email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="users.mobile">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Mobile Number</th>
      <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
    </ng-container>

    <ng-container matColumnDef="users.iqp_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>IQP Number</th>
      <td mat-cell *matCellDef="let element">{{ element.iqp_number || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="users.date_created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Joined</th>
      <td mat-cell *matCellDef="let element">{{ (element.date_created * 1000) | timezoneConvertMedium }}</td>
    </ng-container>

    <ng-container matColumnDef="onsite">
      <th mat-header-cell *matHeaderCellDef>Is Onsite?</th>
      <td mat-cell *matCellDef="let element">{{ element.onsite ? 'Yes' : 'No' }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-raised-button
          color="primary"
          (click)="return_objects ? onSelectSingleObject(element) : onSelectSingle(element.id)"
          *ngIf="!this.dataSource.isSelected(element.id)">
          Select
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onToggleRowSelection(row)">
    </tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <mat-paginator #paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>

  <button mat-raised-button appMarginRight color="primary"
    (click)="onSelectMultiple()"
    *ngIf="data['multiple']">
    Select
  </button>

  <button mat-raised-button appMarginRight color="secondary"
    (click)="onSelectAllRecords()"
    *ngIf="dataSource.isAllSelected() && (dataSource.selection.selected.length != paginator.length)">
    Select All ({{paginator.length}})
  </button>

  <button mat-raised-button color="secondary"
    (click)="onDeselectAllRecords()"
    *ngIf="dataSource.selection.selected.length > paginator.pageSize">
    Deselect All
  </button>

  <button mat-raised-button color="secondary"
    (click)="onDeselectSingle()"
    *ngIf="!data['multiple'] && data['selected'].length > 0">
    Deselect User
  </button>
</mat-dialog-actions>
