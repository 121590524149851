import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMargin]'
})
export class MarginDirective {
  constructor(element: ElementRef) {
    element.nativeElement.style.margin = '16px';
  }
}
