import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appReadOnly]'
})
export class ReadOnlyDirective {
  constructor(private el: ElementRef) {}

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.preventDefault(); // Prevent the default click behavior
    event.stopPropagation(); // Prevent the event from bubbling up
  }
}
