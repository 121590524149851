import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/shared/utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-roles-tabs',
  templateUrl: './notification-roles-tabs.component.html',
  styleUrls: ['./notification-roles-tabs.component.scss']
})
export class NotificationRolesTabsComponent implements OnInit {

  // Get a reference to the tabs container.
  @ViewChild('tabsContainer', { static: false }) tabsContainer: ElementRef;

  // The notification role id.
  notification_role_id: number;

  constructor(
    public utils: UtilsService,
    private dialogRef: MatDialogRef<NotificationRolesTabsComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit(): void {
    // Get the notification role id.
    this.notification_role_id = Number(this.dialogData['notification_role_id']);
  }

  /**
   * Scrolls the tabs container to the top using a smooth behavior.
   *
   * @return {void}
   */
  onScrollToTop(): void {
    this.tabsContainer.nativeElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  /**
   * Close the dialog and respond with the notification role object.
   */
  onClose(): void {
    this.dialogRef.close();
  }
}
