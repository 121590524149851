import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PrequalificationModel } from '../models/prequalification.model';
import { ApiService } from '../shared/api.service';
import { ApiResponseModel } from '../models/api-response.model';

type ResponseEntity = ApiResponseModel<PrequalificationModel>;
type ResponseEntities = ApiResponseModel<PrequalificationModel[]>;

@Injectable({
  providedIn: 'root'
})
export class PrequalificationService {
  private readonly resourceUrl = 'prequalifications';

  constructor(private api: ApiService) {}

  public resetShareLink(id: number): Observable<ApiResponseModel<string>> {
    return this.api.laravelApiObservable(
      'patch',
      this.resourceUrl + `/${id}/reset-hash`
    );
  }

  public remove(ids: number[]): any {
    return this.api.laravelApiObservable(
      'delete',
      `prequalifications/${ids.join(',')}`
    );
  }

  public find(id: number, eager_load = false): Observable<ResponseEntity> {
    if (eager_load) {
      return this.api.laravelApiObservable(
        'get',
        `prequalifications/${id}?eager_load=true`
      );
    }
    return this.api.laravelApiObservable('get', `prequalifications/${id}`);
  }

  public findForContractor(contractorId: number): Observable<ResponseEntity> {
    return this.api.laravelApiObservable(
      'get',
      `prequalifications/contractor/${contractorId}`
    );
  }

  public get(template?: boolean): Observable<ResponseEntities> {
    return this.api.laravelApiObservable(
      'get',
      `prequalifications`,
      {},
      {},
      { offset: 0, sort_by: 'name', order: 'asc', template: template }
    );
  }

  public create(prequal: PrequalificationModel): Observable<PrequalificationModel> {
    return this.api.laravelApiObservable('post', 'prequalifications', prequal);
  }

  public update(prequal: PrequalificationModel): Observable<PrequalificationModel> {
    return this.api.laravelApiObservable(
      'put',
      `prequalifications/${prequal.id}`,
      prequal
    );
  }

  public exportPdf(id: number): Promise<any> {
    return this.api
      .laravelApiDownloadObservable(`prequalifications/export/${id}`)
      .toPromise();
  }

  public toggleDefault(id: number) {
    return this.api.laravelApiObservable(
      'patch',
      `prequalifications/${id}/toggle-default`
    );
  }

  public toggleNotifications(id: number) {
    return this.api.laravelApiObservable(
      'put',
      `prequalifications/${id}/toggle-notifications`
    ).toPromise();
  }
}
