import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {ToolboxTalkModel} from "../../models/toolbox-talk.model";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiService} from "../../shared/api.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ToolboxTalksEditComponent} from "../../toolbox-talks/toolbox-talks-edit/toolbox-talks-edit.component";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {
  DigitalSignatureDialogComponent
} from '../../shared/digital-signature-dialog/digital-signature-dialog.component';
import { UserModel } from '../../models/user.model';
import { DigitalSignatureMeta } from '../../shared/digital-signature/digital-signature-meta';

@Component({
  selector: 'app-sites-toolbox-talks-view',
  templateUrl: './sites-toolbox-talks-view.component.html',
  styleUrls: ['./sites-toolbox-talks-view.component.scss']
})
export class SitesToolboxTalksViewComponent implements OnInit {

  @Input('site_id') site_id: number;
  @Input('toolbox_talk_id') toolbox_talk_id: number;

  toolbox_talk: ToolboxTalkModel = new ToolboxTalkModel();
  reported_by_user: any;

  constructor(
    public app: AppService,
    private oldApi: ApiService,
    private api: ApiRequestService,
    public router: Router,
    public renderer: Renderer2,
    public route: ActivatedRoute,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    // get the toolbox talk and site id
    this.toolbox_talk.id = this.toolbox_talk_id;

    this.getToolboxTalk(this.toolbox_talk.id);
  }

  onEdit(toolbox_talk_id: number | string) {
    this.utils.showComponentDialog(ToolboxTalksEditComponent, {
      id: toolbox_talk_id,
      site_id: this.site_id
      // toolbox_talk_id: toolbox_talk_id
    }, {}, () => {
      // this.dataSource.getData();
      this.getToolboxTalk(Number(toolbox_talk_id));
    });
  }

  onRemove() {
    this.utils.showModal(
      'Remove ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk'),
      'Are you sure you want to remove this ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + '?',
      () => {
        this.oldApi.laravelApiRequest(
          'delete',
          'toolbox-talks/' + this.toolbox_talk.id,
          {},
          {},
          () => {
            this.utils.showToast('The ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + ' was removed.');
            this.router.navigate(['toolbox-talks']);
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  onExport(type: string = 'pdf') {
    if ( !this.toolbox_talk.id || !this.toolbox_talk.site_id ) {
      this.utils.showModal('Error Message', 'We are unable to export the ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + '. Please try again or contact us at ' + this.utils.getSupportEmailAddress() + ' for assistance.');
      return;
    }

    this.api.makeDownloadRequest(`v1/toolbox-talks/export/${type}/` + this.toolbox_talk.id, {}, {
      site_ids: [this.toolbox_talk.site_id]
    })
      .then((response) => {
        // Get the current date object
        const date = new Date();

        // Create object url to handle file downloads
        const data = window.URL.createObjectURL(response);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = (this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks')) + ` - ${date.getFullYear()}${(date.getMonth()+1)}${date.getDate()}.${type}`;
        // Initiate the download
        downloadLink.click();

        // For Firefox, it is necessary to delay revoking the ObjectURL
        setTimeout(function() {
          window.URL.revokeObjectURL(data);
        }, 300); // Minimum 300 miliseconds
      })
      .catch((errorResponse) => {
        this.utils.showModal('Error', errorResponse.message);
      });
  }

  getToolboxTalk(toolbox_talk_id: number) {
    this.oldApi.laravelApiRequest(
      'get',
      'toolbox-talks/' + toolbox_talk_id,
      {},
      {},
      (response) => {
        this.toolbox_talk.apply(response.data);

        if (response.data.reported_by_user) {
          this.reported_by_user = response.data.reported_by_user;
        }
      },
      (error) => {
        this.utils.showModal('Error', error.message);
      }
    );
  }

  onUserPublicView(hash: string, event: Event): void {
    // Stop default browser actions and event propagation.
    event.preventDefault();
    event.stopPropagation();

    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

  /**
   * Saves the signature file by adding it to the existing files and updating the file list.
   *
   * @return {void}
   * @param meta_output
   */
  onSaveSignature(meta_output: DigitalSignatureMeta): void {
    // Check if the signature file was emitted. Don't do anything if not.
    if ( meta_output ) {
      // Save the signature.
      this.saveSignature(meta_output.user_id, meta_output.base64_image_data);
    }
  }

  /**
   * Opens the digital signature pad dialog.
   *
   * @param {number} user_id - The user id.
   * @param {string} [intended_signer_name] - The name of the intended signer.
   * @param {string} [signature_url] - The URL of the signature.
   * @param {number} signed_at - The date the signature was signed.
   * @return {Promise<void>} - A promise that resolves when the dialog is closed.
   */
  async onOpenDigitalSignaturePad(user_id: number, intended_signer_name?: string, signature_url?: string, signed_at?: number): Promise<void> {
    await this.utils.showComponentDialog(DigitalSignatureDialogComponent, {
      user_id: user_id,
      intended_signer_name: intended_signer_name,
      signature_url: signature_url,
      signed_at: signed_at
    }).then((signature_base64_image_data): void => {
      // Check if the file is present.
      if ( typeof signature_base64_image_data != 'undefined' && signature_base64_image_data ) {
        // Save the signature.
        this.saveSignature(user_id, signature_base64_image_data);
      }
    });
  }

  /**
   * Saves the signature image for a given user ID.
   *
   * @param {number} user_id - The ID of the user.
   * @param {string} signature_base64_image_data - The base64 encoded image data of the signature.
   * @private
   * @return {void}
   */
  private saveSignature(user_id: number, signature_base64_image_data: string): void {
    // Make an API request to upload and store the image as a signature.
    this.api.makeRequest('post', `v1/toolbox-talks/${this.toolbox_talk.id}/upload-signature`, {
      user_id: user_id,
      signature_base64_image_data: signature_base64_image_data
    })
      .then((response): void => {
        // Show a response message.
        this.utils.showToast(response.message);
        // Refresh the toolbox talk.
        this.getToolboxTalk(this.toolbox_talk.id);
      })
      .catch((errorResponse): void => {
        // Show the error message from the API.
        this.utils.handleAPIErrors(errorResponse);
      });
  }
}
