<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/items">{{ utils.getLangTerm('contractors.singular', 'Contractor') }} Pre-Qualification Items</a>

  <button
    mat-raised-button
    color="primary"
    matTooltip="Add a new {{ utils.getLangTerm('contractors.singular', 'Contractor') }} Pre-Qualification item."
    routerLink="/items/new/edit"
    id="add"
  >
    Add Item
  </button>
</nav>

<div class="mat-elevation-z8 scroll-box">
  <table mat-table [dataSource]="items">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="no-padding">Name</th>
      <td mat-cell *matCellDef="let item" class="no-padding">
        {{ item.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sort_order">
      <th mat-header-cell *matHeaderCellDef class="no-padding">Sort Order</th>
      <td mat-cell *matCellDef="let item" class="no-padding">
        {{ item.sort_order }}
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let item">
        <button type="button" mat-button routerLink="/items/{{ item.id }}/view/{{ item.pivot?.id }}" matTooltipPosition="above"
          matTooltip="View the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} Pre-Qualification item.">
          View
        </button>

        <button type="button" mat-button routerLink="/items/{{ item.id }}/edit/{{ item.pivot?.id }}" matTooltipPosition="above"
          matTooltip="Edit the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} Pre-Qualification item.">
          Edit
        </button>

        <button type="button" mat-button (click)="delete(item)" matTooltipPosition="above"
          matTooltip="Delete (Archive) the {{ utils.getLangTerm('contractors.singular', 'Contractor') }} Pre-Qualification item.">
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
