<div mat-dialog-title>
  <h3>
    {{ dataSource.parent_site_id ? utils.getLangTerm('child-sites.plural', 'Subsites') : utils.getLangTerm('sites.plural', 'Sites') }}
    <span *ngIf="dataSource.selection.selected.length">
      ({{ dataSource.selection.selected.length }})
    </span>
  </h3>

  <button mat-icon-button class="float-right" [mat-dialog-close]="undefined">
    <mat-icon color="secondary">close</mat-icon>
  </button>

  <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
    <button mat-raised-button class="mr-sm-2" type="button" *ngIf="dataSource.parent_site_id" (click)="onResetList()">
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>

    <input
      class="form-control mr-sm-2"
      type="search"
      [(ngModel)]="dataSource.search"
      name="search"
      placeholder="Search"
      aria-label="Search" />

    <button mat-raised-button class="mr-sm-2" type="submit">
      <mat-icon>search</mat-icon> Search
    </button>

    <button
      mat-raised-button
      class="mr-sm-2"
      (click)="onClearSelected()"
      type="button">
      Clear Selected
    </button>
  </form>
</div>

<mat-dialog-content>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="name"
    matSortDirection="asc"
    matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef width="65px">
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
          *ngIf="dataSource.multiple">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)"
          appMarginRight>
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ element.id }}
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        <ng-container *ngIf="element?.parent_site">
          <small style="display: block;">{{ element?.parent_site?.name }}</small>
        </ng-container>
        <mat-icon *ngIf="element?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
        {{ element.name | ellipsis: [30] }}
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ element.location | ellipsis: [60] }}
      </td>
    </ng-container>

    <!-- Status -->
    <ng-container matColumnDef="site_status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ element.site_status }}
      </td>
    </ng-container>

    <ng-container matColumnDef="site_risk_assessment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the risk assessment.">Risk Assessment</th>
      <td mat-cell *matCellDef="let element">
        <span [class]="utils.getSiteRiskStatusTextColor(element.site_risk_assessment)">
          {{ element.site_risk_assessment | titlecase }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef tourAnchor="tour.actions">
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <button type="button" mat-button (click)="onFilterByParentSite(element.id)" matTooltip="Show {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}." matTooltipPosition="above" >
          {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
          <mat-icon>chevron_right</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="onSelectSiteById($event, element.id)"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <mat-paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>

  <button
    mat-raised-button
    color="primary"
    type="button"
    mat-dialog-close
    (click)="onSaveAndClose()"
    [disabled]="!data['multiple'] && !dataSource.selection.hasValue() && !data['allow_no_selection']">
    Select & Close
  </button>
</mat-dialog-actions>
