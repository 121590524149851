import { Component, OnInit, ViewChild } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ApiDataSource } from '../../../utils/api-data-source';
import { MatSort, Sort } from '@angular/material/sort';
import { AppService } from '../../../app.service';
import { ApiRequestService } from '../../../shared/api-request.service';
import { UtilsService } from '../../../shared/utils.service';
import { NotificationRolesTabsComponent } from '../notification-roles-tabs/notification-roles-tabs.component';
import { NotificationRole } from '../../../interfaces/notification-role.interface';
import { SystemNotification } from '../../../interfaces/system-notification.interface';
import { SiteModel } from '../../../models/site.model';
import { UserModel } from '../../../models/user.model';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-notification-roles',
  templateUrl: './notification-roles.component.html',
  styleUrls: ['./notification-roles.component.scss']
})
export class NotificationRolesComponent implements OnInit {

  // The columns that should show in the list.
  listColumns: string[] = [
    'select',
    'id',
    'name',
    'is_active',
    'system_notifications_count',
    'sites_count',
    'users_count',
    'date_created',
    'date_modified',
    'actions'
  ];

  // The list data source.
  listDataSource: NotificationRolesDataSource = new NotificationRolesDataSource(this.app, this.api);

  // The paginator and sorter.
  @ViewChild('paginator1', {static: false}) paginator1: MatPaginator;
  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit(): void {
    // Get the data when the view loads.
    this.listDataSource.getData(true);
  }

  ngAfterViewInit(): void {
    // Reset the paginator when sorting takes place.
    this.sort.sortChange.subscribe((): void => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    // Define the paginator tap events.
    const paginatorTap = tap((paginator): void => {
      // Set the page index and size for both paginator(s).
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      // Set the limit and offset in the dara source.
      this.listDataSource.limit = paginator['pageSize'];
      this.listDataSource.offset = paginator['pageIndex'];

      // Refresh the list data but do not reset the offset.
      this.listDataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter: Sort): void => {
      this.listDataSource.order = sorter['direction'];
      this.listDataSource.getData(true);
    })).subscribe();
  }

  /**
   * Create a new or edit an existing notification role.
   *
   * @param notification_role_id The id of the notification role.
   */
  onEditNotificationRole(notification_role_id?: number): void {
    this.utils.showComponentDialog(NotificationRolesTabsComponent, {
      notification_role_id: notification_role_id
    }, {
      width: '90%'
    })
      .then((): void => {
        // Refresh the list after the dialog closed.
        this.listDataSource.getData();
      });
  }

  /**
   * Remove the notification role.
   * @param {number} notification_role_id - The id of the notification role to delete.
   * @param {any} evt - The element reference to place the confirmation dialog in the right place.
   * @return {void}
   */
  onDeleteNotificationRole(notification_role_id: number, evt: any): void {
    this.utils.showQuickActions(evt.target, 'Are you sure you want to delete the notification role?', [
      {
        text: 'Yes',
        handler: (): void => {
          this.api.makeRequest('delete', `v2/account/notification-roles/${notification_role_id}`)
            .then((): void => {
              // Refresh the list after the removal of the notification role.
              this.listDataSource.getData();
              // Show a success toast message.
              this.utils.showToast('The notification role was deleted.');
            })
            .catch((error: any): void => {
              // Show the error response.
              this.utils.handleAPIErrors(error);
            });
        }
      },
      {
        text: 'No',
        handler: (): void => { }
      }
    ]);
  }

  /**
   * Deletes selected notification roles.
   *
   * @returns {void}
   */
  onDeleteSelectedNotificationRoles(): void {
    // Get the selected notification role ids.
    const ids: any[] = this.listDataSource.selection.selected;
    // Check if we have notification roles selected.
    if ( ids.length == 0 ) {
      this.utils.showModal('Delete Notification Roles', 'Please select some notification roles to delete.');
      return;
    }
    // Make a request to delete the notification roles.
    this.utils.showModal('Delete Notification Roles', `Are you sure you want to delete (${ids.length}) notification role/s?`, (): void => {
      this.api.makeRequest('delete', `v2/account/notification-roles/${ids.join(',')}`)
        .then((): void => {
          // Refresh the list after the notification roles was deleted and deselect all records.
          this.listDataSource.deselectAll();
          this.listDataSource.getData();
          // Show a success message.
          this.utils.showToast('The selected notification roles was deleted.');
        })
        .catch((error): void => {
          // Show an error response
          this.utils.handleAPIErrors(error);
        });
    });
  }

  /**
   * Get system notifications labels for a specific notification role to display in a tooltip.
   *
   * @param notification_role The notification role for which the system notifications labels are needed.
   * @return The concatenated labels of system notifications for the specified notification role separated by newline characters.
   */
  getNotificationRoleSystemNotificationsForTooltip(notification_role: NotificationRole) {
    return notification_role.system_notifications.map((system_notification: SystemNotification) => {
      return system_notification.label;
    }).join('\n');
  }

  /**
   * Retrieves the names of sites associated with a specific notification role for tooltip display.
   *
   * @param notification_role The notification role for which to retrieve associated sites.
   * @return A string containing the names of sites associated with the provided notification role, separated by line breaks.
   */
  getNotificationRoleSitesForTooltip(notification_role: NotificationRole) {
    return notification_role.sites.map((site: SiteModel) => {
      return site.name;
    }).join('\n');
  }

  /**
   * Retrieves the contact persons of the users associated with a given notification role.
   *
   * @param {NotificationRole} notification_role - The notification role for which to fetch users.
   * @returns A string containing the contact persons of the users associated with the notification role, separated by new lines.
   */
  getNotificationRoleUsersForTooltip(notification_role: NotificationRole) {
    return notification_role.users.map((user: UserModel) => {
      return user.contact_person;
    }).join('\n');
  }

  // onExportSafetyObservations(exportType: string = 'csv') {
  //   if ( exportType == 'pdf' ) {
  //     this.exportSafetyObservationsPDF()
  //   }  else {
  //     this.exportSafetyObservationsCSV(exportType)
  //   }
  // }
  //
  // exportSafetyObservationsPDF(){
  //   this.safetyObservationsService.dataSource.exportDataPDF().subscribe((response)=>{
  //       const link = document.createElement('a');
  //       link.href = response.url;
  //       link.target = '_blank';
  //       link.download = 'safety-observation.pdf';
  //       link.click();
  //     });
  // }
  //
  // exportSafetyObservationsCSV(exportType){
  //   this.safetyObservationsService.dataSource.exportData(exportType)
  //   .then((response) => {
  //     saveAs(response, 'Safety Observations - ' + moment().toString() + '.' + 'csv');
  //   })
  //   .catch((errorResponse) => {
  //     this.utils.handleAPIErrors(errorResponse);
  //   });
  // }
  //
  // onFilter() {
  //   this.utils.showComponentDialog(
  //     SafetyObservationsFilterComponent,
  //     {
  //       so_category: this.safetyObservationsService.dataSource.so_category,
  //       so_type: this.safetyObservationsService.dataSource.so_type,
  //       so_priority: this.safetyObservationsService.dataSource.so_priority,
  //       so_status: this.safetyObservationsService.dataSource.so_status,
  //       so_rating: this.safetyObservationsService.dataSource.so_rating,
  //       site_ids: this.safetyObservationsService.dataSource.site_ids,
  //       contractor_ids: this.safetyObservationsService.dataSource.contractor_ids,
  //       user_ids: this.safetyObservationsService.dataSource.user_ids,
  //       reported_by_ids: this.safetyObservationsService.dataSource.reported_by_ids,
  //       date_range: this.safetyObservationsService.dataSource.date_filter_range
  //     },
  //     {
  //       width: '1024px'
  //     },
  //     (results) => {
  //       if (typeof results !== 'undefined') {
  //         this.safetyObservationsService.dataSource.so_category = typeof results.so_category !== 'undefined' ? results.so_category : this.safetyObservationsService.dataSource.so_category;
  //         this.safetyObservationsService.dataSource.so_type = typeof results.so_type !== 'undefined' ? results.so_type : this.safetyObservationsService.dataSource.so_type;
  //         this.safetyObservationsService.dataSource.so_priority = typeof results.so_priority !== 'undefined' ? results.so_priority : this.safetyObservationsService.dataSource.so_priority;
  //         this.safetyObservationsService.dataSource.so_status = typeof results.so_status !== 'undefined' ? results.so_status : this.safetyObservationsService.dataSource.so_status;
  //         this.safetyObservationsService.dataSource.so_rating = typeof results.so_rating !== 'undefined' ? results.so_rating : this.safetyObservationsService.dataSource.so_rating;
  //         this.safetyObservationsService.dataSource.site_ids = results.site_ids ? results.site_ids : this.safetyObservationsService.dataSource.site_ids;
  //         this.safetyObservationsService.dataSource.contractor_ids = results.contractor_ids ? results.contractor_ids : this.safetyObservationsService.dataSource.contractor_ids;
  //         this.safetyObservationsService.dataSource.user_ids = results.user_ids ? results.user_ids : this.safetyObservationsService.dataSource.user_ids;
  //         this.safetyObservationsService.dataSource.reported_by_ids = results.reported_by_ids ? results.reported_by_ids : this.safetyObservationsService.dataSource.reported_by_ids;
  //         this.safetyObservationsService.dataSource.date_filter_range = results.date_range ? results.date_range : this.safetyObservationsService.dataSource.date_filter_range;
  //         this.safetyObservationsService.dataSource.getData();
  //       }
  //     }
  //   );
  // }
  //
  // onUserPublicView(hash: string) {
  //   this.utils.showComponentDialog(
  //     UserPublicProfileComponent,
  //     hash,
  //     { width: '90%' },
  //     () => {
  //       // Refresh the list regardless of how the dialog is closed.
  //       // this.safetyObservationsService.dataSource.getData();
  //     }
  //   );
  // }
}

/**
 * An API data source for retrieving notification roles data.
 */
export class NotificationRolesDataSource extends ApiDataSource {

  // Set the default order and order by data.
  order_by: string = 'name';
  order: string = 'asc';

  /**
   * Retrieves data from the API.
   *
   * @param {boolean} resetOffset - A flag indicating whether to reset the offset.
   * @returns {void}
   */
  getData(resetOffset: boolean = false): void {
    // Make a request to the API to get the data.
    this.makeRequest(`v2/account/notification-roles`, resetOffset);
  }
}

