<div mat-dialog-title>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5>Reorder Steps</h5>
      </div>
      <div class="col">
        <button mat-icon-button class="float-right" mat-dialog-close>
          <mat-icon color="secondary">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<mat-dialog-content>

  <div cdkDropList class="drag_n_drop-list" (cdkDropListDropped)="drop($event)">
    <div class="drag_n_drop-box" *ngFor="let steps of ta_revised_reordered_workflow" cdkDrag>
      <div class="drag_n_drop-custom-placeholder" *cdkDragPlaceholder></div>
      <span><b>#{{steps.step_no}}</b> - {{steps.description  | ellipsis: [150]}}</span>
    </div>
  </div>

</mat-dialog-content>

<button
  mat-raised-button
  [disabled]="inProgress"
  color="primary"
  type="button"
  (click)="onSaveOrder()"
  class="mt-3"
  id="save">
  <mat-icon>save</mat-icon>
  Save
</button>

