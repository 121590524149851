import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IncidentsContainerComponent } from './incidents-container/incidents-container.component';
import { IncidentsEditComponent } from './incidents-edit/incidents-edit.component';
import { IncidentsViewComponent } from './incidents-view/incidents-view.component';
import { IncidentsComponent } from './incidents/incidents.component';
import { IncidentsTabsComponent } from './incidents-tabs/incidents-tabs.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  { 
    path: 'incidents', 
    component: IncidentsComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'incidents/:incident_id',
    component: IncidentsContainerComponent,
    canActivate: [
      AuthGuard
    ],
    children: [
      // { path: 'edit', component: IncidentsEditComponent },
      { path: 'view', component: IncidentsViewComponent },
      {
        path: 'tabs',
        component: IncidentsTabsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IncidentsRoutingModule {}
