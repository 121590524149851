import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessagesRoutingModule } from './messages-routing.module';
import { MessagesComponent } from './messages.component';
import { MessageEditComponent } from './message-edit/message-edit.component';
import { MessageViewComponent } from './message-view/message-view.component';
import { MatComponentsModule } from '../mat-components/mat-components.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MessagesFilterComponent } from './messages-filter/messages-filter.component';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { UtilsModule } from '../utils/utils.module';
import { SharedModule } from '../shared/shared.module';
import {ChartsModule} from "../charts/charts.module";

@NgModule({
  entryComponents: [
    MessageEditComponent,
    MessagesFilterComponent
  ],
  declarations: [
    MessagesComponent,
    MessageEditComponent,
    MessageViewComponent,
    MessagesFilterComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        MessagesRoutingModule,
        MatComponentsModule,
        PipesModule,
        BsDatepickerModule,
        EditorModule,
        FontAwesomeModule,
        UtilsModule,
        SharedModule,
        ChartsModule
    ]
})
export class MessagesModule { }
