import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { GitlabIssueComponent } from './gitlab-issue/gitlab-issue.component';
import { GitlabIssuesComponent } from './gitlab-issues.component';

const routes: Routes = [
  {
    path: 'gitlab-issues',
    component: GitlabIssuesComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'gitlab-issues/:issue_iid',
    component: GitlabIssueComponent,
    canActivate: [
      AuthGuard
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GitlabIssuesRoutingModule { }
