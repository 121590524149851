<div mat-dialog-title class="position-relative w-100">
  <h5>Signature</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close this dialog." mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="warn">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="px-3 py-3">
  <app-digital-signature-image *ngIf="meta.signature_url; else showSignaturePad" [meta]="meta"></app-digital-signature-image>

  <ng-template #showSignaturePad>
    <app-digital-signature (meta_output)="onSave($event)" [meta]="meta"></app-digital-signature>
  </ng-template>
</mat-dialog-content>
