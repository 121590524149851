import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { SsspModel } from 'src/app/models/sssp-model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-sssp-config',
  templateUrl: './sssp-config.component.html',
  styleUrls: ['./sssp-config.component.scss']
})
export class SsspConfigComponent implements OnInit {

  @Input('sssp_hash') sssp_hash: string;

  sssp: SsspModel = new SsspModel();

  constructor(
    public app: AppService,
    public api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.getSssp();
  }

  /**
   * Get the Safety Plan based on the hash that is provided
   */
  getSssp() {
    return this.api.makeRequest('get', `v1/sssp/${this.sssp_hash}`)
    .then((response) => {
      this.sssp.apply(response);
    })
    .catch((errorResponse) => {
      this.utils.showModal('Error', errorResponse.error.message);
    });
  }

  /**
   *
   * @param form The form data to validate if any.
   */
  onSave(form: NgForm) {
    if ( !form.valid ) {
      // Form validation failed.
    }

    this.api.makeRequest('put', `v1/sssp/${this.sssp_hash}`, this.sssp)
    .then((response) => {
      this.sssp.apply(response);
      this.utils.showToast('The Safety Plan configuration was saved successfully.');
    })
    .catch((errorResponse) => {
      this.utils.showModal('Error', errorResponse.error.message);
    });
  }

}





