import {UserModel} from "./user.model";
import {Auditable} from "../shared/auditable.model";
import {HazardControlModel} from "./hazard-control.model";
import {HazardModel} from "./hazard.model";
import {FileModel} from "../shared/file.model";

export class TaskAnalysesRevisedWorkflowHazardModel extends Auditable {
  id = 0;

  name = '';

  status = 'Unverified';
  description = '';

  /**
   * Initial risk assessment
   */
  ira_likelihood: 1 | 2 | 3 | 4 | 5 = 2;
  ira_severity: 1 | 2 | 3 | 4 | 5 = 2;

  /**
   * Residual risk assessment
   */
  rra_likelihood: 1 | 2 | 3 | 4 | 5 = 2;
  rra_severity: 1 | 2 | 3 | 4 | 5 = 2;

  controls: HazardControlModel[] = [new HazardControlModel()];

  /**
   * Who reviewed this Hazard?
   */
  reviewer: UserModel;
  reviewer_id: number = 0;
  reviewer_date: number = 0;
  last_reviewer_date: number = 0;

  /**
   * The Reference that this Task Analysis Workflow Hazard/Risk was created from?
   */
  reference: HazardModel;
  reference_id: number = 0;

  /**
   * Files that belongs to Task Analysis Workflow Hazard/Risk?
   */
  files?: FileModel[];
  file_ids?: number[];

  /**
   * Who created this Task Analysis Workflow Hazard/Risk?
   */
  created_by_user: UserModel;

  /**
   * Who updated this Task Analysis Workflow Hazard/Risk?
   */
  updated_by_user: UserModel;

  /**
   * Used for identifying a significant hazard.
   */
  significant: boolean = false;
}
