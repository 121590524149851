<div mat-dialog-title>
  <h5>Create Task</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit()">
  <mat-form-field>
    <input
      matInput
      placeholder="Task"
      [(ngModel)]="action.name"
      name="name"
      type="text"
      required
      #name/>
    <mat-error *ngIf="!name.valid">Please enter a Task.</mat-error>
  </mat-form-field>

  <app-timezone-select [timezoneInput]="selectedTimezone" (timezoneOutput)="getOutputTimezone($event)"></app-timezone-select>

  <mat-form-field>
    <input
      matInput
      (dateInput)="onDateChange($event.value)"
      [matDatepicker]="picker"
      placeholder="Due Date"
      [(ngModel)]="action.due"
      name="due"
      #due
      readonly
      (click)="picker.open()"/>
    <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <mat-select
      matInput
      placeholder="Priority"
      [(ngModel)]="action.priority"
      name="priority"
      type="text"
      id="priority">
      <mat-option *ngFor="let priority of priorities" [value]="priority">
        {{priority}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-slide-toggle [(ngModel)]="action.individual_actions"
    name="individual-actions" *ngIf="!action.id">
    Create individual tasks for each selected user
  </mat-slide-toggle>

  <p>
    <small>
      <b>Note:</b> Assigned users will be notified to action the tasks that are assigned to them.
    </small>
  </p>

  <button
    mat-raised-button
    [disabled]="!form.valid || inProgress"
    color="primary"
    type="submit"
    id="save">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    mat-raised-button
    type="button"
    (click)="onSelectSite()"
    [matBadge]="'' + (action.site_id ? 1 : 0)"
    class="margin-right"
    color="secondary">
    <mat-icon>location_on</mat-icon>
    {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
  </button>

  <button
    mat-raised-button
    type="button"
    (click)="selectAssignee()"
    [matBadge]="'' + (action.user_ids?.length || 0)"
    class="margin-right"
    color="secondary">
    <mat-icon>account_circle</mat-icon>
    Users
  </button>

  <app-files-selector (selected)="newFiles = $event" [selectedFilesCount]="newFiles?.length" class="margin-right"></app-files-selector>

  <button
    mat-raised-button
    (click)="onRemoveFiles()"
    color="secondary"
    [matBadge]="'' + (action.files?.length || 0)"
    [disabled]="!action.files?.length"
    type="button">
    <mat-icon>open_in_browser</mat-icon>
    Remove Files
  </button>

  <button
    type="button"
    mat-icon-button
    [disabled]="!action.id"
    class="float-right"
    (click)="delete()">
    <mat-icon>delete</mat-icon>
  </button>
</form>
