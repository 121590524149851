import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/api.service';
import { ItemModel } from './item.model';
import { ItemPivotModel } from './item-pivot.model';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../models/api-response.model';
import { FileModel } from '../shared/file.model';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {
  private readonly resourceUrl = 'items';

  constructor(private api: ApiService) {}

  public findFilesForPivot(
    id: number
  ): Observable<ApiResponseModel<FileModel[]>> {
    return this.api.laravelApiObservable(
      'get',
      this.resourceUrl + `/pivot/${id}/files`
    );
  }

  public delete(id: number): Observable<{}> {
    return this.api.laravelApiObservable('delete', `items/${id}`);
  }

  public find(
    id: number,
    pivotId?: number
  ): Observable<ItemModel> {
    if (pivotId) {
      return this.api.laravelApiObservable(
        'get',
        `items/${id}?pivot_id=${pivotId}`
      );
    }
    return this.api.laravelApiObservable('get', `items/${id}`);
  }

  public findPivot(id: number): Observable<ApiResponseModel<ItemPivotModel>> {
    return this.api.laravelApiObservable('get', `items/pivot/${id}`);
  }

  public put(item: ItemModel): Observable<{}> {
    return this.api.laravelApiObservable('put', `items/${item.id}`, item);
  }

  public updatePivot(
    id: number,
    pivot: ItemPivotModel
  ): Promise<ApiResponseModel<null>> {
    return this.api
      .laravelApiObservable('put', `items/pivot/${id}`, pivot)
      .toPromise();
  }

  public post(item: ItemModel): Observable<ItemModel> {
    return this.api.laravelApiObservable('post', 'items', item);
  }

  public get(): Observable<ItemModel[]> {
    return this.api.laravelApiObservable('get', 'items');
  }

  public approve(pivotId: number): Observable<{}> {
    return this.api.laravelApiObservable('patch', `items/approve/${pivotId}`);
  }

  public reject(pivotId: number, contractorId: number): Observable<{}> {
    return this.api.laravelApiObservable(
      'patch',
      this.resourceUrl + `/reject/${pivotId}`,
      { contractor_id: contractorId }
    );
  }
}
