import {Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import { Subscription } from 'rxjs';
import {UtilsService} from "../shared/utils.service";

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsServiceService {

  // Define the router events subscription.
  private routerEventsSubscription: Subscription;

  // Used to determine if Google Analytics is ready for usage.
  private isGoogleAnalyticsReady: boolean = false;

  constructor(
    private router: Router,
    private utilsService: UtilsService
  ) {
    // Initialize Google Analytics.
    this.init();
  }

  init(): void {
    // Terminate early if the Google Analytics measurement id is not set.
    if ( !environment.GOOGLE_ANALYTICS.MEASUREMENT_ID ) {
      return;
    }
    // Load the Google Analytics library.
    this.utilsService.loadExternalScript('google-analytics', `https://www.googletagmanager.com/gtag/js?id=${environment.GOOGLE_ANALYTICS.MEASUREMENT_ID}`, (): void => {
      // Define the data layer and apply the config.
      window.dataLayer = window.dataLayer || [];
      gtag('js', new Date());
      gtag('config', environment.GOOGLE_ANALYTICS.MEASUREMENT_ID);

      // Subscribe to route changes and send page views.
      this.routerEventsSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd): void => {
          // Log the page view.
          this.logPageView(event.urlAfterRedirects);
        });

      // Google Analytics is ready for use.
      this.isGoogleAnalyticsReady = true;
    });
  }

  /**
   * Called when the component is about to be destroyed.
   * unsubscribes from the router events subscription if it is set.
   *
   * @return {void}
   */
  destroy(): void {
    // Check if the router events subscription is set and unsubscribe from it.
    if ( this.routerEventsSubscription ) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  /**
   * Logs an event to Google Analytics.
   *
   * Example: logEvent('Create', { feature: 'Incidents' });
   * Example: logEvent('Update', { feature: 'Incidents' });
   * Example: logEvent('Delete', { feature: 'Incidents' });
   * Example: logEvent('Import', { feature: 'Incidents' });
   * Example: logEvent('Export', { feature: 'Incidents', format: 'PDF' });
   *
   * @param {string} action - The name of the event action to log.
   * @param {Object} [params] - Additional parameters to include with the event, in the form of a key-value pair object.
   * @returns {void} - This method does not return anything.
   */
  logEvent(action: string, params?: { [key: string]: any }): void {
    // Terminate early if Google Analytics is not ready.
    if ( !this.isGoogleAnalyticsReady || !environment.GOOGLE_ANALYTICS.MEASUREMENT_ID ) {
      return;
    }
    // Send event to Google Analytics.
    gtag('event', action, params);
  }

  /**
   * Logs a page view to Google Analytics.
   *
   * Example: logPageView('/incidents')
   * Example: logPageView('/hazards')
   * Example: logPageView('/safety-observations')
   *
   * @param {string} url - The URL of the page being viewed.
   * @return {void}
   */
  logPageView(url: string): void {
    // Terminate early if Google Analytics is not ready.
    if ( !this.isGoogleAnalyticsReady || !environment.GOOGLE_ANALYTICS.MEASUREMENT_ID ) {
      return;
    }
    // Send the page view to Google Analytics.
    gtag('config', environment.GOOGLE_ANALYTICS.MEASUREMENT_ID, { 'page_path': url });
  }
}
