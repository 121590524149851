import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TradesEditComponent } from './trades-edit/trades-edit.component';
import { TradesViewComponent } from './trades-view/trades-view.component';
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatComponentsModule} from "../../mat-components/mat-components.module";
import {FormsModule} from "@angular/forms";
import {MATERIAL_MODULES} from "../../app.const";
import {PipesModule} from "../../pipes/pipes.module";
import {FilesModule} from "../../files/files.module";
import {TradesComponent} from "./trades.component";
import {RouterModule} from "@angular/router";
import {DirectivesModule} from "../../directives/directives.module";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatComponentsModule,
    FormsModule,
    ...MATERIAL_MODULES,
    PipesModule,
    FilesModule,
    RouterModule,
    DirectivesModule
  ],
  declarations: [
    TradesComponent,
    TradesEditComponent,
    TradesViewComponent
  ],
  entryComponents: [
    TradesEditComponent,
    TradesViewComponent
  ]
})
export class TradesModule { }
