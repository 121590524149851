import {
  Component,
  OnInit,
  Renderer2
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ERPModel } from 'src/app/models/erp.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from '../../shared/utils.service';

@Component({
  selector: 'app-erps-view',
  templateUrl: './erps-view.component.html',
  styleUrls: ['./erps-view.component.css']
})
export class ErpsViewComponent implements OnInit {
  erp: ERPModel = new ERPModel();

  constructor(
    public app: AppService,
    private api: ApiService,
    public router: Router,
    public renderer: Renderer2,
    public route: ActivatedRoute,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    // the hazard id may already be set by the hazard edit component dialog
    if (!this.erp.id && !this.erp.site_id) {
      // get the hazard id from the url
      this.erp.id = Number(this.route.snapshot.params['erp_id']);
    }

    if (this.erp.id) {
      this.api.laravelApiRequest(
        'get',
        'erps/' + this.erp.id,
        {},
        {},
        (response) => {
          this.erp.apply(response.data);
        },
        (error) => {
          this.utils.showModal('Error', error.message);
        }
      );
    }
  }

  onRemove() {
    this.utils.showModal(
      'Remove ERP',
      'Are you sure you want to remove this emergency response plan?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'erps/' + this.erp.id,
          {},
          {},
          () => {
            this.utils.showToast('The emergency response plan was removed');
            this.router.navigate(['erps']);
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }
}
