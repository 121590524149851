<div mat-dialog-title>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h5>TA/JSA/SWMS Template</h5>
      </div>
      <div class="col">
        <button mat-icon-button class="float-right" mat-dialog-close>
          <mat-icon color="secondary">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<mat-dialog-content>

  <p>{{ ta_revised.task_name }}</p>

  <h6>Description</h6>
  <p>{{ ta_revised.task_description }}</p>

  <ng-container *ngIf="ta_revised.work_procedure">
    <h6>Work Procedure</h6>
    <p>{{ ta_revised.work_procedure }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised.training_qualifications">
    <h6>Training/Qualifications</h6>
    <p class="white-space-pre-wrap">{{ ta_revised.training_qualifications }}</p>
  </ng-container>

<!--  <ng-container *ngIf="ta_revised.qualifications">-->
<!--    <h6>Qualifications</h6>-->
<!--    <p class="white-space-pre-wrap">{{ ta_revised.qualifications }}</p>-->
<!--  </ng-container>-->

  <ng-container *ngIf="ta_revised.duties">
    <h6>Duties</h6>
    <p class="white-space-pre-wrap">{{ ta_revised.duties }}</p>
  </ng-container>

<!--  <ng-container *ngIf="ta_revised.training">-->
<!--    <h6>Training</h6>-->
<!--    <p class="white-space-pre-wrap">{{ ta_revised.training }}</p>-->
<!--  </ng-container>-->

  <ng-container *ngIf="ta_revised.certificates">
    <h6>Certificates of Competency</h6>
    <p class="white-space-pre-wrap">{{ ta_revised.certificates }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised.code_of_practice">
    <h6>Code of Practice</h6>
    <p class="white-space-pre-wrap">{{ ta_revised.code_of_practice }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised.maintenance_checks">
    <h6>Maintenance Checks</h6>
    <p class="white-space-pre-wrap">{{ ta_revised.maintenance_checks }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised.safety_practices">
    <h6>Safety Practice</h6>
    <p class="white-space-pre-wrap">{{ ta_revised.safety_practices }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised.ppe_other">
    <h6>PPE Other</h6>
    <p class="white-space-pre-wrap">{{ ta_revised.ppe_other }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised.work_permits_other">
    <h6>Work Permits Other</h6>
    <p class="white-space-pre-wrap">{{ ta_revised.work_permits_other }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised.tools_other">
    <h6>Tools Other</h6>
    <p class="white-space-pre-wrap">{{ ta_revised.tools_other }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised?.ppe && ta_revised.ppe.length">
    <h6>PPE</h6>
    <ul>
      <li *ngFor="let ppe of ta_revised.ppe">{{ppe.title}}</li>
    </ul>
  </ng-container>

  <ng-container *ngIf="ta_revised?.work_permits && ta_revised.work_permits.length">
    <h6>Work Permits</h6>
    <ul>
      <li *ngFor="let work_permit of ta_revised.work_permits">{{work_permit.title}}</li>
    </ul>
  </ng-container>

  <ng-container *ngIf="ta_revised.created_by_user">
    <h6>Created By</h6>
    <p>
      <a (click)="onUserPublicView(ta_revised.created_by_user.hash)"
        matTooltip="View User Public Profile">
        {{ ta_revised.created_by_user.contact_person }}
      </a>
    </p>
  </ng-container>

  <ng-container *ngIf="ta_revised.date_created">
    <h6>Created At</h6>
    <p>{{( ta_revised.date_created * 1000) | timezoneConvertMedium }}</p>
  </ng-container>

  <ng-container *ngIf="ta_revised.updated_by_user">
    <h6>Last Updated By</h6>
    <p>
      <a (click)="onUserPublicView(ta_revised.updated_by_user.hash)"
        matTooltip="View User Public Profile">
        {{ ta_revised.updated_by_user.contact_person }}
      </a>
    </p>
  </ng-container>

  <ng-container *ngIf="ta_revised.date_modified">
    <h6>Last Updated At</h6>
    <p>{{ (ta_revised.date_modified * 1000) | timezoneConvertMedium }}</p>
  </ng-container>

</mat-dialog-content>


