import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {IndustriesModel} from "../../admin/industries/industries.model";

@Component({
  selector: 'app-tools-filter',
  templateUrl: './tools-filter.component.html',
  styleUrls: ['./tools-filter.component.scss']
})
export class ToolsFilterComponent implements OnInit {

  dataSource = new ToolsFilterDataSource(); // No API calls needed

  industries: IndustriesModel[] = [];

  constructor(
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ToolsFilterComponent>
  ) {
    // set a default industry id selection of zero if no selected values are passed through
    if (typeof this.data['selected_industry_id'] === 'undefined') {
      this.data['selected_industry_id'] = 0;
    }

    // set a default archived value of false if no values are passed through
    if (typeof this.data['filter_archived'] === 'undefined') {
      this.data['filter_archived'] = "false";
    }
  }

  ngOnInit() {
    this.dataSource.selected_industry_id = this.data['selected_industry_id'];
    this.dataSource.filter_archived = this.data['filter_archived'];
    this.getIndustries();
  }

  private async getIndustries() {
    this.industries = await this.utils.getIndustries();
  }

  onClearAndClose() {
    this.dataSource.selected_industry_id = 0;
    this.dataSource.filter_archived = "false";

    this.onSaveAndClose();
  }

  onSaveAndClose() {
    let response: any;

    response = {
      industry_id: this.dataSource.selected_industry_id,
      archived: this.dataSource.filter_archived
    }

    this.dialogRef.close(response);
  }

}

/**
 * the data source class should contain everything needed to get data for the feature.
 */
export class ToolsFilterDataSource {
  selected_industry_id: number = 0;
  filter_archived: string = 'false';

  constructor() {
    this.selected_industry_id = 0;
    this.filter_archived = 'false';
  }
}
