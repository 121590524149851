<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
<!--    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">-->
<!--      <span class="navbar-toggler-icon"></span>-->
<!--    </button>-->

<!--    <div class="collapse navbar-collapse" id="navbarSupportedContent">-->
      <span class="mr-auto">
        <button mat-raised-button color="primary" (click)="onEditTimeRecord()" appMarginRight id="add" matTooltip="Click this button to add a new LTI record.">
          <mat-icon>add</mat-icon>
          New Time Record
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle('incident_lti_time_record_id')">
            <mat-icon *ngIf="dataSource.isAllSelected('incident_lti_time_record_id')"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected('incident_lti_time_record_id')">select_all</mat-icon>
            {{ dataSource.isAllSelected('incident_lti_time_record_id') ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Incident LTI Time Records." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>
      </span>

      <form class="form-inline my-2 my-lg-0"
            style="min-width: 325px"
            (ngSubmit)="dataSource.getData(true)"
      >
        <input
          class="form-control mr-sm-2" type="search"
          [(ngModel)]="dataSource.search" name="search" placeholder="Search"
          aria-label="Search" id="search-input"
          matTooltip="Search for LTIs." />
        <button mat-raised-button
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                color="secondary"
                (click)="dataSource.getData(true)"
                id="search-button"
                matTooltip="Click this button to run the current search.">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

<!--    </div>-->
  </nav>

  <div class="alert alert-info mt-3" role="alert">
    Actual LTI Summary: <b>{{ incident_lti.actual_lti_days }} Day{{ incident_lti.actual_lti_days != 1 ? 's' : '' }}</b> OR <b>{{ incident_lti.actual_lti_hours }} Hour{{ incident_lti.actual_lti_hours != 1 ? 's' : '' }}</b>.
  </div>

  <div class="scroll-box">
    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? dataSource.selectAllToggle('incident_lti_time_record_id') : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected('incident_lti_time_record_id')"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected('incident_lti_time_record_id')"
            matTooltip="Toggle select all messages in this list.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.incident_lti_time_record_id) : null"
            [checked]="dataSource.selection.isSelected(row.incident_lti_time_record_id)"
            matTooltip="Toggle select this message.">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="incident_lti_time_record_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the LTI time record ID.">
          #
        </th>
        <td mat-cell *matCellDef="let timeRecord">
          {{ timeRecord.incident_lti_time_record_id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actual_lti_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the affected user.">
          Actual LTI Date
        </th>
        <td mat-cell *matCellDef="let timeRecord">
          {{ (timeRecord.actual_lti_date * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actual_lti_date_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the affected user.">
          Actual LTI Date(UTC)
        </th>
        <td mat-cell *matCellDef="let timeRecord">
          {{ (timeRecord.actual_lti_date * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actual_lti_days">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the affected user's email address.">
          Actual LTI Days
        </th>
        <td mat-cell *matCellDef="let timeRecord">
          {{ timeRecord.actual_lti_days }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actual_lti_hours">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by the affected user's email address.">
          Actual LTI Hours
        </th>
        <td mat-cell *matCellDef="let timeRecord">
          {{ timeRecord.actual_lti_hours }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let time_record">
          <button type="button" mat-button (click)="onEditTimeRecord(time_record.incident_lti_time_record_id)" matTooltip="Click this button to edit this LTI time record."  *ngIf="app.userAccountLink.permissions != 'Employee'">
            Edit
          </button>

          <button type="button" mat-button (click)="onRemove(time_record.incident_lti_time_record_id)" matTooltip="Click this button to remove this LTI time record."  *ngIf="app.userAccountLink.permissions != 'Employee'">
            Delete
          </button>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>

