<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/user/tasks">My Tasks</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <span class="mr-auto">
      <button mat-raised-button color="primary" appMarginRight (click)="add()" id="add" class="margin-right">
        <mat-icon>add</mat-icon>
        Add Task
      </button>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon>
          <mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>

        <button mat-menu-item (click)="onExport('pdf')">
          <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' +
          dataSource.selection.selected.length + ') Selected' : 'All' }} to PDF
        </button>

        <button mat-menu-item (click)="onExport('csv')">
          <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' +
          dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
        </button>

        <button mat-menu-item (click)="onExport('xlsx')">
          <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' +
          dataSource.selection.selected.length + ') Selected' : 'All' }} to XLSX
        </button>

        <button mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>delete</mat-icon> Delete Selected
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu"
        matTooltip="Use this menu to select/deselect and/or export Tasks." style="min-width: 150px">
        <mat-icon>more_vert</mat-icon>
        List Menu/Export
      </button>
    </span>

    <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onFilter()"
      matTooltip="Click this button to display filter options.">
      <mat-icon>filter_list</mat-icon> Filter
    </button>

    <form tourAnchor="tour.search" class="form-inline my-2 my-lg-0" style="min-width: 325px"
      (ngSubmit)="dataSource.getData(true)">
      <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search"
        aria-label="Search" placeholder="Search" matTooltip="Search for Tasks" />
      <button mat-raised-button class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0" color="secondary"
        (click)="dataSource.getData(false)" type="button" matTooltip="Run the current search">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>

  </div>

</nav>

<div class="mat-elevation-z8 scroll-box">
  <table mat-table [dataSource]="dataSource" style="width: 100%" matSort matSortActive="name" matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        #
      </th>
      <td mat-cell *matCellDef="let action" class="no-padding text-wrap">
        {{ action.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} <br> Task
      </th>
      <td mat-cell *matCellDef="let action" class="no-padding text-wrap">
        <p>
          <span *ngIf="action?.site">
            {{action.site.location}}
            <br>
          </span>
          {{ action.name }}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="users">
      <th mat-header-cell *matHeaderCellDef class="no-padding">
        Assigned Users
      </th>
      <td mat-cell *matCellDef="let action" class="no-padding">
        <ng-container *ngIf="action?.users?.length">
          <span *ngFor="let user of action?.users">
            <a (click)="onUserPublicView(user.hash)"
                 matTooltip="View User Public Profile">
              {{ user.contact_person }}
            </a><br>
          </span>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Created At/By
      </th>
      <td mat-cell *matCellDef="let action" class="no-padding">
        <p>
          {{ (action.date_created * 1000) | timezoneConvertLong }} <br>
          <span *ngIf="action?.creator">
            <a (click)="onUserPublicView(action.creator.hash)"
               matTooltip="View User Public Profile">
              {{ action.creator.contact_person }}
            </a>
          </span>
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Created At/By (UTC)
      </th>
      <td mat-cell *matCellDef="let action" class="no-padding">
        <p>
          {{ (action.date_created * 1000) | timezoneConvertUTCLong }} <br>
          <span *ngIf="action?.creator">{{ action.creator.contact_person }}</span>
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="priority">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Priority
      </th>
      <td mat-cell *matCellDef="let action" class="no-padding">
        {{ action.priority }}
      </td>
    </ng-container>

    <ng-container matColumnDef="due">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Due Date
      </th>
      <td mat-cell *matCellDef="let action" class="no-padding">
        <span *ngIf="action.due">
          {{ action.due | timezoneConvertMedium }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="due_UTC">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Due Date (UTC)
      </th>
      <td mat-cell *matCellDef="let action" class="no-padding">
        {{ action.due | timezoneConvertUTCMedium }}
      </td>
    </ng-container>


    <ng-container matColumnDef="completed">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Status
      </th>
      <td mat-cell *matCellDef="let action" class="no-padding">
        <mat-slide-toggle [(ngModel)]="action.completed" (change)="update(action)"
          matTooltip="{{action.completed ? 'Open task' : 'Complete task'}}">
        </mat-slide-toggle>
        {{ action.completed ? 'Completed' : 'Open' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let action">
        <button type="button" mat-button (click)="view(action)" matTooltip="View the task information."
          matTooltipPosition="above">
          View
        </button>

        <button type="button" mat-button (click)="edit(action)" matTooltip="Edit the task information."
          matTooltipPosition="above">
          Edit
        </button>

        <button type="button" mat-button (click)="onRemove(action)" matTooltip="Remove (Archive) the task."
          matTooltipPosition="above">
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons>
  </mat-paginator>
</div>
