<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/task-analyses">TA's</a></li>
    <li class="breadcrumb-item">
      <a routerLink="/task-analyses/{{ parent_id }}/workers">TA Workers</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">View</li>
  </ol>
</nav>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <ng-container *ngIf="service.item.fullname">
        <h6>Fullname</h6>
        <p>{{ service.item.fullname }}</p>
      </ng-container>

      <ng-container *ngIf="service.item.mobile">
        <h6>Mobile</h6>
        <p>{{ service.item.mobile }}</p>
      </ng-container>

      <ng-container *ngIf="service.item.email">
        <h6>Email Address</h6>
        <p>{{ service.item.email }}</p>
      </ng-container>
    </div>
    <div class="col-12 col-md-6"></div>
  </div>
</div>
