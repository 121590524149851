
<div class="container-fluid">
  <div class="row m-0 mb-1">
    <div class="col-2 p-0">
      <div class="px-3 py-2">
        <mat-form-field *ngIf="ta_revised.parent" class="full-width">
          <mat-label>Version:</mat-label>
          <mat-select [(ngModel)]="ta_revised_view.id" (ngModelChange)="find(ta_revised_view.id, true)" name="version_no" aria-label="Select the version of the TA/JSA/SWMS you want to view.">
            <mat-option *ngFor="let ta_children of ta_revised.parent.children" [value]="ta_children.id">{{ ta_children.version_no }}</mat-option>
            <mat-option [value]="ta_revised.parent.id">1</mat-option>
          </mat-select>
        </mat-form-field>
        <ng-container *ngIf="!ta_revised.parent">
          <h6>Version</h6>
          <p>1</p>
        </ng-container>
      </div>
    </div>
    <div class="col text-center text-lg-right">
      <button
        color="success"
        type="button"
        *ngIf="app.user.id == approverId"
        [disabled]="approverAcknowledged || inProgress"
        mat-raised-button
        (click)="onApprove()"
        class="mx-2 ml-lg-3 mr-lg-0 my-2">
        <mat-icon>check_circle</mat-icon>
        {{ approverAcknowledged ? 'Approved' : 'Approve' }}
      </button>

      <button
        color="success"
        type="button"
        *ngIf="app.user.id == authorizerId"
        [disabled]="authorizerAcknowledged || inProgress"
        mat-raised-button
        (click)="onAuthorize()"
        class="mx-2 ml-lg-3 mr-lg-0 my-2">
        <mat-icon>check_circle</mat-icon>
        {{ authorizerAcknowledged ? 'Authorized' : 'Authorize' }}
      </button>

      <button
        color="success"
        type="button"
        *ngIf="app.user.id == supervisorId"
        [disabled]="supervisorAcknowledged || inProgress"
        mat-raised-button
        (click)="onSupervisorAcknowledge()"
        class="mx-2 ml-lg-3 mr-lg-0 my-2">
        <mat-icon>check_circle</mat-icon>
        Supervisor {{ supervisorAcknowledged ? 'Acknowledged' : 'Acknowledge' }}
      </button>

      <button
        color="success"
        type="button"
        *ngIf="utils.getSiteTAUserCheck(users, app.user.id) && approverAcknowledged && authorizerAcknowledged && supervisorAcknowledged"
        [disabled]="!utils.getSiteTAUserStatusRequired(users, app.user.id) || acknowledgeUserId > 0 || inProgress"
        mat-raised-button
        (click)="onAcknowledge()"
        class="mx-2 ml-lg-3 mr-lg-0 my-2">
        <mat-icon>check_circle</mat-icon>
        {{ !utils.getSiteTAUserStatusRequired(users, app.user.id) || acknowledgeUserId > 0 ? 'Acknowledged' : 'Acknowledge' }}
      </button>
    </div>
  </div>
</div>

<div class="horizontal-scroll-con">
  <div class="row m-0 mb-1" style="min-width: 1100px">
    <div class="col p-0">
      <div class="px-3 py-2">

        <section class="jumbotron py-4">
            <h1 class="display-4" style="white-space: pre-wrap">TA/JSA/SWMS - {{ ta_revised_view.task_name }}</h1><br />

            <h4>Description</h4>
            <p style="white-space: pre-wrap">{{ ta_revised_view.task_description }}</p>

            <ng-container *ngIf="ta_revised_view.work_procedure">
              <hr class="my-4">
              <h4>Work Procedure</h4>
              <p style="white-space: pre-wrap">{{ ta_revised_view.work_procedure }}</p>
            </ng-container>
        </section>

        <ng-container>
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr class="text-center">
                <th scope="col" style="width: 10%">ID #</th>
                <th scope="col" style="width: 10%">Version #</th>
                <th scope="col" style="width: 25%">Task</th>
                <th scope="col" style="width: 35%">{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}</th>
                <th scope="col" style="width: 20%">{{ utils.getLangTerm('contractors.singular', 'Contractor') }} Co</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-center">
                <td style="width: 10%">{{ ta_revised_view.id }}</td>
                <td style="width: 10%">{{ ta_revised_view.version_no ? ta_revised_view.version_no : 1 }}</td>
                <td style="width: 25%">{{ ta_revised_view.task_name }}</td>
                <td style="width: 35%">{{ ta_revised_view.site ? ta_revised_view.site.location : '' }}</td>
                <td style="width: 20%">{{ ta_revised_view.contractor ? ta_revised_view.contractor.name : '' }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.amendment_title || ta_revised_view.reason_for_amendment">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr class="text-center">
                <th scope="col" style="width: 30%">Amendment Title</th>
                <th scope="col" style="width: 70%">Reason for Amendment</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-center">
                <td style="width: 30%">{{ ta_revised_view.amendment_title }}</td>
                <td style="width: 70%">{{ ta_revised_view.reason_for_amendment }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.created_by_user">
          <table class="table table-bordered" style="max-width: 700px">
            <thead class="thead-dark">
              <tr class="text-center">
                <th scope="col" style="width: 20%">User ID #</th>
                <th scope="col" style="width: 40%">Created By</th>
                <th scope="col" style="width: 40%">Created Date</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-center">
                <td style="width: 20%">{{ ta_revised_view.created_by_user.id }}</td>
                <td style="width: 40%">
                  <a (click)="onUserPublicView(ta_revised_view.created_by_user.hash)"
                     matTooltip="View User Public Profile">
                    {{ ta_revised_view.created_by_user.contact_person }}
                  </a>
                </td>
                <td style="width: 40%">{{ ta_revised_view.date_created ? (( ta_revised_view.date_created * 1000) | timezoneConvertMedium) : '' }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.updated_by_user">
          <table class="table table-bordered" style="max-width: 700px">
            <thead class="thead-dark">
              <tr class="text-center">
                <th scope="col" style="width: 20%">User ID #</th>
                <th scope="col" style="width: 40%">Last Updated By</th>
                <th scope="col" style="width: 40%">Last Updated At</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-center">
                <td style="width: 20%">{{ ta_revised_view.updated_by_user.id }}</td>
                <td style="width: 40%">
                  <a (click)="onUserPublicView(ta_revised_view.updated_by_user.hash)"
                     matTooltip="View User Public Profile">
                    {{ ta_revised_view.updated_by_user.contact_person }}
                  </a>
                </td>
                <td style="width: 40%">{{ ta_revised_view.date_modified ? (( ta_revised_view.date_modified * 1000) | timezoneConvertMedium) : '' }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <h4 class="mt-5 mb-4">Authorisation</h4>

        <ng-container *ngIf="ta_revised_view.approver">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr class="text-left">
                <th scope="col" style="width: 10%">User ID #</th>
                <th scope="col" style="width: 45%">Company</th>
                <th scope="col" style="width: 15%">Approved By</th>
                <th scope="col" style="width: 15%">Approved</th>
                <th scope="col" style="width: 15%">Approved Date</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-left">
                <td style="width: 10%">{{ ta_revised_view.approver.id }}</td>
                <td style="width: 45%">
                    <div *ngFor="let account of ta_revised_view.approver.accounts">
                      <span *ngIf="account.id == ta_revised_view.approver.account_id">{{ account.name }}</span>
                    </div>
                </td>
                <td style="width: 15%">
                  <a (click)="onUserPublicView(ta_revised_view.approver.hash)"
                     matTooltip="View User Public Profile">
                    {{ ta_revised_view.approver.contact_person }}
                  </a>
                </td>
                <td style="width: 15%">
                  <span *ngIf="ta_revised_view.approver_acknowledged" style="color: green">Yes</span>
                  <span *ngIf="!ta_revised_view.approver_acknowledged" style="color: red">No</span>
                </td>
                <td style="width: 15%">{{ ta_revised_view.approver_acknowledged_date ? (( ta_revised_view.approver_acknowledged_date * 1000) | timezoneConvertMedium) : '' }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.authorizer">
          <table class="table table-bordered">
            <thead class="thead-dark">
            <tr class="text-left">
              <th scope="col" style="width: 10%">User ID #</th>
              <th scope="col" style="width: 45%">Company</th>
              <th scope="col" style="width: 15%">Authorized By</th>
              <th scope="col" style="width: 15%">Authorized</th>
              <th scope="col" style="width: 15%">Authorized Date</th>
            </tr>
            </thead>
            <tbody class="bg-white">
            <tr class="text-left">
              <td style="width: 10%">{{ ta_revised_view.authorizer.id }}</td>
              <td style="width: 45%">
                <div *ngFor="let account of ta_revised_view.authorizer.accounts">
                  <span *ngIf="account.id == ta_revised_view.authorizer.account_id">{{ account.name }}</span>
                </div>
              </td>
              <td style="width: 15%">
                <a (click)="onUserPublicView(ta_revised_view.authorizer.hash)"
                   matTooltip="View User Public Profile">
                  {{ ta_revised_view.authorizer.contact_person }}
                </a>
              </td>
              <td style="width: 15%">
                <span *ngIf="ta_revised_view.authorizer_acknowledged" style="color: green">Yes</span>
                <span *ngIf="!ta_revised_view.authorizer_acknowledged" style="color: red">No</span>
              </td>
              <td style="width: 15%">{{ ta_revised_view.authorizer_acknowledged_date ? (( ta_revised_view.authorizer_acknowledged_date * 1000) | timezoneConvertMedium) : '' }}</td>
            </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.supervisor">
          <table class="table table-bordered">
            <thead class="thead-dark">
            <tr class="text-left">
              <th scope="col" style="width: 10%">User ID #</th>
              <th scope="col" style="width: 45%">Company</th>
              <th scope="col" style="width: 15%">Supervised By</th>
              <th scope="col" style="width: 15%">Acknowledged</th>
              <th scope="col" style="width: 15%">Acknowledged Date</th>
            </tr>
            </thead>
            <tbody class="bg-white">
            <tr class="text-left">
              <td style="width: 10%">{{ ta_revised_view.supervisor.id }}</td>
              <td style="width: 45%">
                <div *ngFor="let account of ta_revised_view.supervisor.accounts">
                  <span *ngIf="account.id == ta_revised_view.supervisor.account_id">{{ account.name }}</span>
                </div>
              </td>
              <td style="width: 15%">
                <a (click)="onUserPublicView(ta_revised_view.supervisor.hash)"
                   matTooltip="View User Public Profile">
                  {{ ta_revised_view.supervisor.contact_person }}
                </a>
              </td>
              <td style="width: 15%">
                <span *ngIf="ta_revised_view.supervisor_acknowledged" style="color: green">Yes</span>
                <span *ngIf="!ta_revised_view.supervisor_acknowledged" style="color: red">No</span>
              </td>
              <td style="width: 15%">{{ ta_revised_view.supervisor_acknowledged_date ? (( ta_revised_view.supervisor_acknowledged_date * 1000) | timezoneConvertMedium) : '' }}</td>
            </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.files?.length">
          <h4 class="mt-5 mb-4">Files</h4>

          <table class="table table-bordered" style="max-width: 700px">
            <thead class="thead-dark">
              <tr class="text-left">
                <th scope="col" style="width: 100%">Files</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-left">
                <td style="width: 100%">
                  <app-files-list [view]="true" [files]="ta_revised_view.files"></app-files-list>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.workflows?.length">
          <h4 class="mt-5 mb-4">Workflow</h4>

          <table class="table table-bordered">
            <thead class="thead-dark">
            <tr class="text-left">
              <th scope="col" style="width: 10%">WS ID #</th>
              <th scope="col" style="width: 90%">&nbsp;</th>
            </tr>
            </thead>
            <tbody class="bg-white">
            <tr *ngFor="let workflow of ta_revised_view.workflows" class="text-left">
              <td style="width: 10%;vertical-align:top;padding-top: 25px;">{{ workflow.id }}</td>
              <td style="width: 90%">

                <table class="table table-bordered">
                  <thead class="thead-light">
                    <tr class="text-left">
                      <th scope="col" style="width: 10%">Step No</th>
                      <th scope="col" style="width: 30%">Work Step</th>
                      <th scope="col" style="width: 15%">Created By</th>
                      <th scope="col" style="width: 15%">Created At</th>
                      <th scope="col" style="width: 15%">Last Updated By</th>
                      <th scope="col" style="width: 15%">Last Updated At</th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr class="text-left">
                      <td style="width: 10%;">{{ workflow.step_no }}</td>
                      <td style="width: 30%;">{{ workflow.description }}</td>
                      <td style="width: 15%;">
                        <span *ngIf="workflow.created_by_user">
                          <a (click)="onUserPublicView(workflow.created_by_user.hash)"
                             matTooltip="View User Public Profile">
                            {{ workflow.created_by_user.contact_person }}
                          </a>
                        </span>
                      </td>
                      <td style="width: 15%;">{{ workflow.date_created ? (( workflow.date_created * 1000) | timezoneConvertMedium) : '' }}</td>
                      <td style="width: 15%;">
                        <span *ngIf="workflow.updated_by_user">
                          <a (click)="onUserPublicView(workflow.updated_by_user.hash)"
                             matTooltip="View User Public Profile">
                            {{ workflow.updated_by_user.contact_person }}
                          </a>
                        </span>
                      </td>
                      <td style="width: 15%;">{{ workflow.date_modified ? (( workflow.date_modified * 1000) | timezoneConvertMedium) : '' }}</td>
                    </tr>
                  </tbody>
                </table>

                <ng-container *ngIf="workflow.step_hazards?.length">
                  <table class="table table-bordered">
                    <thead class="thead-light">
                    <tr class="text-left">
                      <th scope="col" style="width: 10%">ID #</th>
                      <th scope="col" style="width: 30%">Hazards/Risks</th>
                      <th scope="col" style="width: 10%">Significant</th>
                      <th scope="col" style="width: 15%">Last Review Date</th>
                      <th scope="col" style="width: 15%">Review Date</th>
                      <th scope="col" style="width: 10%">IRA</th>
                      <th scope="col" style="width: 10%">RRA</th>
                    </tr>
                    </thead>
                    <tbody class="bg-white">
                      <ng-container *ngFor="let hazard of workflow.step_hazards">
                        <tr class="text-left">
                          <td style="width: 10%">{{ hazard.id }}</td>
                          <td style="width: 30%">{{ hazard.name }}</td>
                          <td style="width: 10%">{{ hazard.significant ? 'Yes' : 'No' }}</td>
                          <td style="width: 15%">{{ hazard.last_reviewer_date ? (hazard.last_reviewer_date * 1000 | timezoneConvertMedium) : '' }}</td>
                          <td style="width: 15%">{{ hazard.reviewer_date ? (hazard.reviewer_date * 1000 | timezoneConvertMedium) : '' }}</td>
                          <td style="width: 10%">
                            <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(hazard.ira_likelihood, hazard.ira_severity))">
                              {{ utils.getRiskAssessmentText(hazard.ira_likelihood, hazard.ira_severity) }}
                            </span>
                          </td>
                          <td style="width: 10%">
                            <span [class]="utils.getSiteRiskStatusTextColor(utils.getRiskAssessmentText(hazard.rra_likelihood, hazard.rra_severity))">
                              {{ utils.getRiskAssessmentText(hazard.rra_likelihood, hazard.rra_severity) }}
                            </span>
                          </td>
                        </tr>
                        <tr class="text-left">
                          <td style="width: 100%" colspan="7" class="controls-back">
                            <ng-container *ngIf="hazard?.reviewer">
                              <div><strong>Reviewer:</strong> <span>
                                  <a (click)="onUserPublicView(hazard.reviewer.hash)" matTooltip="View User Public Profile">
                                    {{ hazard.reviewer.contact_person }}
                                  </a>
                              </span></div><br />
                            </ng-container>
                            <div><strong>Description:</strong> {{ hazard.description }}</div><br />
                            <ng-container *ngIf="hazard.controls?.length">
                              <div *ngFor="let control of utils.jsonParse(hazard.controls); let i = index">
                                <ng-container *ngIf="control && control?.control && control?.loc">
                                  <span style="font-weight: 500">{{ i + 1 }} - {{ control.control }}</span> <br />
                                  {{ loc_list[control.loc - 1] }}
                                </ng-container>
                              </div>
                            </ng-container>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </ng-container>

                <ng-container *ngIf="workflow.notes?.length">
                  <table class="table table-bordered">
                    <thead class="thead-light">
                      <tr class="text-left">
                        <th scope="col" style="width: 10%">Note ID #</th>
                        <th scope="col" style="width: 60%">Note</th>
                        <th scope="col" style="width: 15%">Written By</th>
                        <th scope="col" style="width: 15%">Last Updated</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr *ngFor="let note of workflow.notes" class="text-left">
                        <td style="width: 10%">{{ note.id }}</td>
                        <td style="width: 60%">{{ note.note }}</td>
                        <td style="width: 15%">
                          <span *ngIf="note.created_by_user">
                            <a (click)="onUserPublicView(note.created_by_user.hash)"
                               matTooltip="View User Public Profile">
                              {{ note.created_by_user.contact_person }}
                            </a>
                          </span>
                        </td>
                        <td style="width: 15%">{{ note.date_modified ? (( note.date_modified * 1000) | timezoneConvertMedium) : (( note.date_created * 1000) | timezoneConvertLong) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>

                <ng-container *ngIf="workflow.files?.length">
                  <table class="table table-bordered" style="max-width: 700px">
                    <thead class="thead-light">
                      <tr class="text-left">
                        <th scope="col" style="width: 100%">Files</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr class="text-left">
                        <td style="width: 100%">
                          <app-files-list [view]="true" [files]="workflow.files"></app-files-list>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>

              </td>
            </tr>
            </tbody>
          </table>
        </ng-container>

        <h4 class="mt-5 mb-4">Factors</h4>

        <ng-container *ngIf="ta_revised_view.training_qualifications">
          <table class="table table-bordered">
            <thead class="thead-dark">
            <tr class="text-left">
              <th scope="col" style="width: 100%">Training/Qualifications</th>
            </tr>
            </thead>
            <tbody class="bg-white">
            <tr class="text-left">
              <td style="width: 100%">{{ ta_revised_view.training_qualifications }}</td>
            </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.duties">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr class="text-left">
                <th scope="col" style="width: 100%">Duties</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-left">
                <td style="width: 100%">{{ ta_revised_view.duties }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.certificates">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr class="text-left">
                <th scope="col" style="width: 100%">Certificates of Competency</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-left">
                <td style="width: 100%">{{ ta_revised_view.certificates }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.code_of_practice">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr class="text-left">
                <th scope="col" style="width: 100%">Code of Practice</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-left">
                <td style="width: 100%">{{ ta_revised_view.code_of_practice }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.maintenance_checks">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr class="text-left">
                <th scope="col" style="width: 100%">Maintenance Checks</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-left">
                <td style="width: 100%">{{ ta_revised_view.maintenance_checks }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.safety_practices">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr class="text-left">
                <th scope="col" style="width: 100%">Safety Practice</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-left">
                <td style="width: 100%">{{ ta_revised_view.safety_practices }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.ppe?.length || ta_revised_view.ppe_other">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr class="text-left">
                <th scope="col" style="width: 100%">PPE</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-left">
                <td style="width: 100%">
                  <ng-container *ngIf="ta_revised_view.ppe?.length">
                    <div *ngFor="let ppe of ta_revised_view.ppe; let i = index">
                      <span>{{ ppe.title }}</span><br />
                    </div>
                  </ng-container>
                  <div *ngIf="ta_revised_view.ppe_other" style="white-space: pre-wrap;">{{ ta_revised_view.ppe_other }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.work_permits?.length || ta_revised_view.work_permits_other">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr class="text-left">
                <th scope="col" style="width: 100%">Work Permits</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-left">
                <td style="width: 100%">
                  <ng-container *ngIf="ta_revised_view.work_permits?.length">
                    <div *ngFor="let work_permit of ta_revised_view.work_permits; let i = index">
                      <span>{{ work_permit.title }}</span><br />
                    </div>
                  </ng-container>
                  <div *ngIf="ta_revised_view.work_permits_other" style="white-space: pre-wrap;">{{ ta_revised_view.work_permits_other }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.tools?.length || ta_revised_view.tools_other">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr class="text-left">
                <th scope="col" style="width: 100%">Tools</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="text-left">
                <td style="width: 100%">
                  <ng-container *ngIf="ta_revised_view.tools?.length">
                    <div *ngFor="let tool of ta_revised_view.tools; let i = index">
                      <span>{{ tool.title }}</span><br />
                    </div>
                  </ng-container>
                  <div *ngIf="ta_revised_view.tools_other" style="white-space: pre-wrap;">{{ ta_revised_view.tools_other }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="ta_revised_view.users?.length">
          <h4 class="mt-5 mb-4">Assignees</h4>

          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr class="text-left">
                <th scope="col" style="width: 10%">User ID #</th>
                <th scope="col" style="width: 45%">Company</th>
                <th scope="col" style="width: 15%">Assignee</th>
                <th scope="col" style="width: 15%">Acknowledged</th>
                <th scope="col" style="width: 15%">Acknowledged Date</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr *ngFor="let user of ta_revised_view.users" class="text-left">
                <td style="width: 10%">{{ user.id }}</td>
                <td style="width: 45%">
                  <div *ngFor="let account of user.accounts">
                    <span *ngIf="account.id == user.account_id">{{ account.name }}</span>
                  </div>
                </td>
                <td style="width: 15%">
                  <a (click)="onUserPublicView(user.hash)"
                    matTooltip="View User Public Profile">
                    {{ user.contact_person }}
                  </a>
                </td>
                <td style="width: 15%">
                  <span *ngIf="user.pivot.acknowledged" style="color: green">Yes</span>
                  <span *ngIf="!user.pivot.acknowledged" style="color: red">No</span>
                </td>
                <td style="width: 15%">{{ user.pivot.acknowledged_date ? (( user.pivot.acknowledged_date * 1000) | timezoneConvertMedium) : '' }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>

      </div>
    </div>
  </div>
</div>
