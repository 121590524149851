import { Component, Inject, OnInit } from '@angular/core';
import { IncidentService } from '../incident.service';
import { ActivatedRoute } from '@angular/router';
import { IncidentModel } from 'src/app/models/incident.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-incidents-tabs',
  templateUrl: './incidents-tabs.component.html',
  styleUrls: ['./incidents-tabs.component.scss']
})
export class IncidentsTabsComponent implements OnInit {
  incident: IncidentModel = {} as IncidentModel;

  constructor(
    private incidentService: IncidentService,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) {}

  ngOnInit() {
    this.incident.id = Number(this.dialogData.incident_id);
    this.find();
  }

  find() {
    this.incidentService
      .find(this.incident.id, true)
      .subscribe((response) => {
        this.incident = response.data;
      });
  }

  invalidRisks() {
    return false;
  }

  shouldShowInvestigationTabs() {
    return ['In Progress', 'Submitted', 'Completed'].indexOf(this.incident.status) > -1;
  }
}
