<div mat-dialog-title>
  <h5>Section Form</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<form #f="ngForm" (ngSubmit)="onSubmit(f)">
  <mat-dialog-content class="form-container">
    <mat-form-field>
      <input
        matInput
        placeholder="Section Name"
        [(ngModel)]="section.name"
        name="name"
        type="text"
        #name
        required />
      <mat-error *ngIf="!name.valid">Please enter a section name.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-select
        placeholder="Status"
        [(ngModel)]="section.status"
        name="status"
        required
        matTooltip="Draft sections will not be visible to {{ utils.getLangTerm('contractors.plural', 'Contractors') }} and {{ utils.getLangTerm('employees.plural', 'Employees') }}." >
        <mat-option value="Draft">Draft</mat-option>
        <mat-option value="Published">Published</mat-option>
      </mat-select>
      <mat-error *ngIf="!section.status">Please select the status of this section.</mat-error>
    </mat-form-field>

    <editor
      placeholder="Section Content *"
      [(ngModel)]="section.content"
      name="content"
      [init]="{
        skin_url: '/assets/skins/lightgray',
        plugins: 'link,image,lists,hr,table,media',
        height: '450'
      }">
    </editor>
    <mat-error *ngIf="!section.content">Please select the content of this section.</mat-error>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      type="submit"
      mat-raised-button
      [disabled]="!f.valid || inProgress || !section.content"
      color="primary"
      class="save">
      <mat-icon>save</mat-icon>
      Save
    </button>

    <span class="mr-auto"></span>

    <button
      type="button"
      mat-icon-button
      [disabled]="!section.id"
      class="float-right"
      (click)="onRemove()">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-dialog-actions>

</form>
