<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" [routerLink]="['inductions']">Related Accounts</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="secondary" (click)="onSelectSlaveAccounts()" appMarginRight>
          <mat-icon>account_tree</mat-icon> Select Accounts
        </button>

         <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onUnlinkSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>link_off</mat-icon> Unlink Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect multiple selected accounts." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>
      </span>

      <form class="form-inline my-2 my-lg-0"
            style="min-width: 325px"
            (ngSubmit)="dataSource.getData(true)">
        <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search"
          placeholder="Search" aria-label="Search" id="search-input"
          matTooltip="Search for accounts by name." />
        <button mat-raised-button
                color="secondary"
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                (click)="dataSource.getData(true)"
                id="search-button"
                matTooltip="Run the current search">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"
      matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef width="60px">
          <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null" [checked]="
              dataSource.selection.hasValue() && dataSource.isAllSelected()
            " [indeterminate]="
              dataSource.selection.hasValue() && !dataSource.isAllSelected()
            " matTooltip="Toggle selecting all Incidents">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let account">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(account.id) : null"
            [checked]="dataSource.selection.isSelected(account.id)" matTooltip="Select/Deselect this account.">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef  matTooltip="Toggle sorting by ID"  width="80px">
          #
        </th>
        <td mat-cell *matCellDef="let account">
          {{ account.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef   matTooltip="Toggle sorting by name">
          Account Name
        </th>
        <td mat-cell *matCellDef="let account">
          {{ account.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef width="250px"></th>
        <td mat-cell *matCellDef="let account">
          <button *ngIf="false" type="button" mat-button (click)="onSync(account.id)">
            Sync
          </button>
          <button type="button" mat-button (click)="onUnlink(account.id)">
            Unlink
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let account; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
  </div>

  <p class="pl-3 pr-3 mt-3">
    <small><b>Note:</b> All related accounts will be synchronised with data from the "{{app.account.name}}" account. The data being synchronised consists of, but is not limited to, {{ utils.getLangTerm('contractors.plural', 'Contractors') }}, {{ utils.getLangTerm('contractors.singular', 'Contractor') }} pre-qualifications and features with templated data.</small>
  </p>

</div>
