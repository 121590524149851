<div mat-dialog-title>
  <h5>Add Notes to {{ item.name }}</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form #f="ngForm">
    <mat-form-field>
      <textarea
        matInput
        required
        name="notes"
        cols="50"
        rows="10"
        [(ngModel)]="item.pivot.notes"
        placeholder="Item Notes"
      ></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!f.valid"
    (click)="onSaveAndClose()"
  >
    Save
  </button>
</mat-dialog-actions>
