<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button mat-raised-button routerLink="/sites" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
    </button>

    <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
      <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
    </button>
  </nav>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">Forms</span>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button type="button" mat-raised-button color="primary" id="add" (click)="onEdit()" appMarginRight>
          <mat-icon>add</mat-icon>
          New
        </button>

        <mat-menu #appMenu="matMenu">
          <button type="button" mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>

          <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onArchiveSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>archive</mat-icon> Archive Selected
          </button>

          <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onExportFormsData('pdf')" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>file_download</mat-icon> Export to PDF (Empty)
          </button>

          <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onExportFormsData('pdf', true)" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>file_download</mat-icon> Export to PDF (Submitted)
          </button>

          <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onExportFormsData('csv', true)" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>file_download</mat-icon> Export to CSV
          </button>

          <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onExportFormStructures()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>file_download</mat-icon> Export Selected Form Structures
          </button>

          <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onTriggerImportFormStructureFileSelector($event)">
            <mat-icon>file_upload</mat-icon> Import Form Structures
          </button>

          <button type="button" *ngIf="dataSource.only_archived == 'true'" mat-menu-item (click)="onRestoreSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>restore</mat-icon> Restore Selected
          </button>
        </mat-menu>

        <button type="button" mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to perform list actions." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          Menu
        </button>
      </span>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
        <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onOpenFilters()" matTooltip="Click this button to display filter options for this list.">
          <mat-icon>filter_list</mat-icon> Filter
        </button>

        <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search" aria-label="Search" id="search-input" matTooltip="Search for forms by title." />

        <button mat-raised-button color="secondary" appMarginRight id="search-button" matTooltip="Run the current search">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <app-dynamic-forms-charts-container [baseStorageKey]="'site-dynamic-forms'" [parent_filters]="{ site_ids: [site_id] }"></app-dynamic-forms-charts-container>

  <div class="mat-elevation-z8 scroll-box">

    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="desc" matSortDisableClear>

      <caption class="px-3">This is a list of all assigned forms in your account that are associated with this {{ utils.getLangTerm('child-sites.singular', 'Subsite').toLowerCase() }}.</caption>

      <!-- Selection Toggle -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef id="th-select">
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Toggle selection of all forms in the visible list.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let form">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(form.id) : null"
            [checked]="dataSource.selection.isSelected(form.id)" matTooltip="Toggle selection of this form.">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-id" matTooltip="Toggle sorting by form ID.">
          #
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.id }}
        </td>
      </ng-container>

      <!-- Title -->
      <ng-container matColumnDef="title">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-title" matTooltip="Toggle sorting by form title.">
          Title
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.title }}
        </td>
      </ng-container>

      <!-- Form Type -->
      <ng-container matColumnDef="form_type">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-form_type" matTooltip="Toggle sorting by form type.">
          Form Type
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.form_type | titlecase }}
        </td>
      </ng-container>

      <!-- Is Active -->
      <ng-container matColumnDef="is_active">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-is_active" matTooltip="Toggle sorting by whether the form is active or not.">
          Is Active
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.is_active ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <!-- Associated Site Count -->
      <ng-container matColumnDef="const_sites_count">
        <th mat-header-cell *matHeaderCellDef id="th-const_sites_count" matTooltip="The number of {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase() }} that are assigned with this form.">
          {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
        </th>
        <td mat-cell *matCellDef="let form">
          <ng-container *ngIf="form.const_sites_count == 1 && form.const_sites.length == 1; else summarisedSites">
            <ng-container *ngIf="form.const_sites[0]?.parent_site">
              <small style="display: block;">
                <a [routerLink]="utils.prepareSiteRelatedLink(form.const_sites[0]?.parent_site?.id, null, '/edit/details')">
                  {{ form.const_sites[0]?.parent_site?.name || '' }}
                </a>
              </small>
            </ng-container>
            <mat-icon *ngIf="form.const_sites[0]?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
            <a [routerLink]="utils.prepareSiteRelatedLink(form.const_sites[0].id, form.const_sites[0]?.parent_site?.id, '/edit/details')">
              {{ form.const_sites[0].name || '' }}
            </a>
          </ng-container>
          <ng-template #summarisedSites>
            <span [matTooltip]="dynamicFormsService.formatSitesListColumnTooltip(form)">
              {{ dynamicFormsService.formatSitesListColumnText(form) }}
            </span>
          </ng-template>
        </td>
      </ng-container>

      <!-- Assigned User Count -->
      <ng-container matColumnDef="users_count">
        <th mat-header-cell *matHeaderCellDef id="th-users_count" matTooltip="The number of users that are assigned to this form.">
          Assigned Users
        </th>
        <td mat-cell *matCellDef="let form">
          <span *ngIf="form.users_count != 1" [innerHtml]="dynamicFormsService.formatAssignedUsersListColumnText(form)"></span>
          <ng-container *ngIf="form.users_count == 1">
            <span *ngIf="form.assigned_users?.length">
              <a (click)="onUserPublicView(form.assigned_users[0].hash)"
                 matTooltip="View User Public Profile">
                {{ form.assigned_users[0].contact_person }}
              </a> <br/> {{ form.assigned_users[0].employer.name }}
            </span>
            <span *ngIf="!form.assigned_users?.length">{{ form.users_count }} Users</span>
          </ng-container>
        </td>
      </ng-container>

      <!-- Assigned User's Form Submission Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef id="th-users_status" matTooltip="The status of the user submitted answers. This will only show if there is 1 user assigned to the form.">
          Status
        </th>
        <td mat-cell *matCellDef="let form">
          <ng-container *ngIf="form.users_count == 1 && form.assigned_users?.length">
            <span [ngClass]="getFormSubmissionStatusColors(form.assigned_users[0])">
              {{ form?.assigned_users[0]?.pivot?.dynamic_form_status }}
            </span>
          </ng-container>
        </td>
      </ng-container>


      <!-- Assigned User's Form Verification Status -->
      <ng-container matColumnDef="verified">
        <th mat-header-cell *matHeaderCellDef id="th-users_verified" matTooltip="The verification status of the user submitted answers. This will only show if there is 1 user assigned to the form.">
          Verified
        </th>
        <td mat-cell *matCellDef="let form">
          <ng-container *ngIf="form.users_count == 1 && form.assigned_users?.length">
            {{ form?.assigned_users[0]?.pivot?.dynamic_form_verified ? 'Yes' : 'No' }}
          </ng-container>
        </td>
      </ng-container>

      <!-- Date Created -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created" matTooltip="Toggle sorting by creation date.">
          Created On
        </th>
        <td mat-cell *matCellDef="let form">
          {{ (form.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- Date Created (UTC) -->
      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created_UTC" matTooltip="Toggle sorting by creation date.">
          Created On (UTC)
        </th>
        <td mat-cell *matCellDef="let form">
          {{ (form.date_created * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <!-- Date Modifeid -->
      <ng-container matColumnDef="date_modified">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_modified" matTooltip="Toggle sorting by the date the form was last updated on.">
          Last Updated On
        </th>
        <td mat-cell *matCellDef="let form">
          {{ (form.date_modified * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- Date Modifeid (UTC) -->
      <ng-container matColumnDef="date_modified_UTC">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_modified_UTC" matTooltip="Toggle sorting by the date the form was last updated on.">
          Last Updated On (UTC)
        </th>
        <td mat-cell *matCellDef="let form">
          {{ (form.date_modified * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef id="th-actions"></th>
        <td mat-cell *matCellDef="let form">

          <ng-container *ngIf="!form.deleted_at else restoreOptions;">
            <button type="button" mat-button (click)="onViewResponses(form.id)" matTooltip="View the form responses, submitted by assigned users.">
              View Responses
            </button>

            <button type="button" mat-button (click)="onEdit(form.id)" matTooltip="Edit the form.">
              Edit
            </button>

            <button type="button" mat-button (click)="onClone(form)" matTooltip="Clone the form. You can access it in your list of form templates after cloning it.">
              Clone
            </button>

            <button type="button" mat-button (click)="onArchive(form, $event)" matTooltip="Archive the form.">
              Archive
            </button>
          </ng-container>

          <ng-template #restoreOptions>
            <button type="button" mat-button (click)="onRestore(form, $event)" matTooltip="Restore the form.">
              Restore
            </button>
          </ng-template>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>

<input type="file" (change)="onImportFormStructures($event)" style="display: none;" #importFormStructureFileInput />
