<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand">Tasks</a>

  <span class="mr-auto">
    <button mat-raised-button color="primary" (click)="onEdit()" id="add">
      <mat-icon>add</mat-icon>
      Add Note
    </button>
  </span>

  <form tourAnchor="tour.search"
        class="form-inline my-2 my-lg-0"
        (ngSubmit)="dataSource.getData(true)"
        style="min-width: 325px">
    <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search"
      aria-label="Search" />
    <button mat-raised-button
            class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
            color="secondary"
            (click)="dataSource.getData(false)"
            type="button">
      <mat-icon>search</mat-icon>
      Search
    </button>
  </form>
</nav>

<div class="mat-elevation-z8 scroll-box">
  <table mat-table [dataSource]="dataSource" style="width: 100%" matSort matSortActive="name" matSortDisableClear>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        #
      </th>
      <td mat-cell *matCellDef="let note" class="no-padding text-wrap">
        {{ note.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Note
      </th>
      <td mat-cell *matCellDef="let note" class="no-padding text-wrap">
        {{ note.note }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Created At/By
      </th>
      <td mat-cell *matCellDef="let note" class="no-padding">
        <p>
          {{ (note.date_created * 1000) | timezoneConvertMedium }} <br>
          <span *ngIf="note?.creator">
            <a (click)="onUserPublicView(note.creator.hash)"
                 matTooltip="View User Public Profile">
              {{ note.creator.contact_person }}
            </a>
          </span>
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Created At/By (UTC)
      </th>
      <td mat-cell *matCellDef="let note" class="no-padding">
        <p>
          {{ (note.date_created * 1000) | timezoneConvertUTCMedium }} <br>
          <span *ngIf="note?.creator">{{ note.creator.contact_person }}</span>
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let note">
        <button type="button" mat-button (click)="onEdit(note.id)" matTooltip="Edit the task note." matTooltipPosition="above">
          Edit
        </button>

        <button type="button" mat-button (click)="onDelete(note.id)" matTooltip="Remove (Archive) the task note." matTooltipPosition="above">
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [length]="dataSource?.total | async" [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons>
  </mat-paginator>
</div>
