<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">Details</span>
  </nav>
</div>

<div class="container-fluid p-3">

  <form #f="ngForm" (ngSubmit)="onSubmit(f)">

    <div class="row">
      <div class="col-12">

        <mat-form-field class="full-width mb-3">
          <mat-label>Full Name</mat-label>
          <input
            matInput
            placeholder="Full Name"
            [(ngModel)]="employeeService.employee.contact_person"
            name="contact_person"
            type="text"
            id="contact_person"
            required
            dusk="dusk_contact_person"/>
          <mat-hint>Enter the name of your {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
        </mat-form-field>

        <mat-form-field class="full-width mb-3">
          <mat-label>Email Address</mat-label>
          <input
            matInput
            placeholder="Email Address"
            [(ngModel)]="employeeService.employee.email"
            name="email"
            type="email"
            required
            id="email"
            dusk="dusk_email"/>
          <mat-hint>Enter the email address of your {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
        </mat-form-field>

        <mat-form-field class="full-width mb-3">
          <mat-label>{{ utils.getLangTerm('users.form.mobile', 'Mobile Number') }}</mat-label>
          <app-phone-input
            [idInput]="mobile_input_id"
            [numberInput]="employeeService.employee.mobile"
            [countryCodeInput]="employeeService.employee.mobile_country_code"
            [dialCodeInput]="employeeService.employee.mobile_dial_code"
            [e164NumberInput]="employeeService.employee.mobile_e164"
            [additionalClassInput]="'grey'"
            (phoneDetailsOutput)="getOutputPhoneDetails($event)"
            required="true">
          </app-phone-input>
          <mat-hint>Enter the {{ utils.getLangTerm('users.form.mobile', 'Mobile Number') }} of your {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
        </mat-form-field>

        <mat-form-field class="full-width mb-3">
          <mat-label>{{ utils.getLangTerm('users.form.notes', 'Notes') }}</mat-label>
          <textarea
            matInput
            rows="5"
            [placeholder]="utils.getLangTerm('users.form.notes', 'Notes')"
            [(ngModel)]="employeeService.employee.pivot.notes"
            name="notes"
            id="notes"
            dusk="dusk_notes">
          </textarea>
          <mat-hint>Enter {{ utils.getLangTerm('users.form.notes', 'Notes') }} for your {{ utils.getLangTerm('employees.singular', 'Employee') }}. The notes can only be seen in this active account by administrators.</mat-hint>
        </mat-form-field>

        <mat-form-field class="full-width mb-3">
          <mat-label>Job Title</mat-label>
          <mat-select
            matInput
            placeholder="Job Title"
            [(ngModel)]="employeeService.employee.trade"
            name="trade"
            id="trade"
            dusk="dusk_trade">
            <mat-option *ngFor="let trade of trades" [value]="trade.name">{{ trade.name }}</mat-option>
          </mat-select>
          <mat-hint>Select the job title of your {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
        </mat-form-field>

      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="full-width mb-3">
          <mat-label>Address</mat-label>
          <input
            matInput
            placeholder="Address"
            [(ngModel)]="employeeService.employee.address"
            name="address"
            type="text"
            id="address"
            dusk="dusk_address"/>
          <mat-hint>Enter the address of your {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field class="full-width mb-3">
          <mat-label>{{ utils.getLangTerm('users.form.suburb', 'Suburb') }}</mat-label>
          <input
            matInput
            [placeholder]="utils.getLangTerm('users.form.suburb', 'Suburb')"
            [(ngModel)]="employeeService.employee.suburb"
            name="suburb"
            type="text"
            id="suburb"
            dusk="dusk_suburb"/>
          <mat-hint>Enter the {{ utils.getLangTerm('users.form.suburb', 'Suburb') }} of your {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field class="full-width mb-3">
          <mat-label> {{ utils.getLangTerm('users.form.city', 'City') }} </mat-label>
          <input
            matInput
            [placeholder]="utils.getLangTerm('users.form.city', 'City')"
            [(ngModel)]="employeeService.employee.city"
            name="city"
            type="text"
            id="city"
            dusk="dusk_city"/>
          <mat-hint>Enter the {{ utils.getLangTerm('users.form.city', 'City') }} of your {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field class="full-width mb-3">
          <mat-label> {{ utils.getLangTerm('users.form.postal_code', 'Postal Code') }} </mat-label>
          <input
            matInput
            [placeholder]="utils.getLangTerm('users.form.postal_code', 'Postal Code')"
            [(ngModel)]="employeeService.employee.postal_code"
            name="postal_code"
            type="text"
            id="postal_code"
            dusk="dusk_postal_code"/>
          <mat-hint>Enter the {{ utils.getLangTerm('users.form.postal_code', 'Postal Code') }} of your {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field class="full-width mb-3">
          <mat-label>Country</mat-label>
          <input
            matInput
            placeholder="Country"
            [(ngModel)]="employeeService.employee.country"
            name="country"
            type="text"
            id="country"
            dusk="dusk_country"/>
          <mat-hint>Enter the country of your {{ utils.getLangTerm('employees.singular', 'Employee') }}.</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button
          mat-raised-button
          [disabled]="!f.valid || mobile_error_state"
          (click)="onSubmit(f)"
          type="submit"
          color="primary"
          id="update_button"
          dusk="dusk_update_button">
          <mat-icon>save</mat-icon>
          Update
        </button>
      </div>
    </div>

  </form>

</div>
