import { Injectable } from '@angular/core';
import { RemoteService } from '../shared/remote.service';
import { UtilsService } from '../shared/utils.service';
import { ApiService } from '../shared/api.service';

@Injectable({
  providedIn: 'root'
})
export class InductionService {

  data: any = {
    modules: []
  };

  constructor(
    public remote: RemoteService, 
    public utils: UtilsService,
    private api: ApiService
  ) {}

  get(course_id: number, callback?: any) {
    this.api.laravelApiObservable('get', `user/induction/${course_id}`).toPromise().then(
      (response) => {
        this.data = response.data;
        if (typeof callback === 'function') {
          callback();
        }
      }
    );
  }

  saveAnswers(callback?: any) {
    this.api.laravelApiObservable('post', 'user/induction', this.data).toPromise().then(
      (response) => {
        this.utils.showToast('Your answers was successfully saved.');
        if (typeof callback === 'function') {
          callback();
        }
      }
    );
  }

  submitAnswers(course_id: number, callback?: any) {
    this.utils.showModal(
      'Submit Answers',
      'Are you sure you want to submit your answers? You will no longer be able to change them.',
      () => {
        this.api.laravelApiObservable('post', 'user/complete-induction', {
          course_id: course_id
        }).toPromise().then(
          (response) => {
            this.utils.showToast(
              'Your answers is submitted and can no longer be changed.'
            );
            if (typeof callback === 'function') {
              callback(response);
            }
          }
        );
      }
    );
  }
}
