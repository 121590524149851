<div mat-dialog-title class="position-relative w-100">
  <h5>Filter Currently Onsite Records</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close Filter Currently Onsite Records" mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">
  <ng-container matColumnDef="sites">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectSites()"
        [matBadge]="'' + (dataSource.selected_site_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>location_on</mat-icon> {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="contractors">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectContractors()"
        [matBadge]="'' + (dataSource.selected_contractor_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <fa-icon [icon]="faHardHat"></fa-icon> {{ utils.getLangTerm('contractors.plural', 'Contractors') }}
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="users">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectUsers()"
        [matBadge]="'' + (dataSource.selected_user_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>account_circle</mat-icon> Users
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="employees">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectEmployees()"
        [matBadge]="'' + (dataSource.selected_employee_ids.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>account_circle</mat-icon> {{ utils.getLangTerm('employees.plural', 'Employees') }}
      </button>
    </div>
  </ng-container>

</div>

<div class="d-flex justify-content-center my-2 py-2">
  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        class="float-right"
        color="primary"
        (click)="onSaveAndClose()">
        <mat-icon>filter_list</mat-icon> Apply Filter
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        color="secondary"
        class="float-right"
        (click)="onClearAndClose()">
        <mat-icon> clear_all</mat-icon> Clear Filter
      </button>
    </div>
  </ng-container>
</div>
