import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkPermitsEditComponent } from './work-permits-edit/work-permits-edit.component';
import { WorkPermitsViewComponent } from './work-permits-view/work-permits-view.component';
import {WorkPermitsComponent} from "./work-permits.component";
import {MatComponentsModule} from "../../mat-components/mat-components.module";
import {FormsModule} from "@angular/forms";
import {MATERIAL_MODULES} from "../../app.const";
import {PipesModule} from "../../pipes/pipes.module";
import {FilesModule} from "../../files/files.module";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import {RouterModule} from "@angular/router";
import {DirectivesModule} from "../../directives/directives.module";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatComponentsModule,
    FormsModule,
    ...MATERIAL_MODULES,
    PipesModule,
    FilesModule,
    RouterModule,
    DirectivesModule
  ],
  declarations: [
    WorkPermitsComponent,
    WorkPermitsEditComponent,
    WorkPermitsViewComponent
  ],
  entryComponents: [
    WorkPermitsViewComponent,
    WorkPermitsEditComponent
  ]
})
export class WorkPermitsModule { }
