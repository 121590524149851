import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-task-analyses-revised-workflows-tabs',
  templateUrl: './task-analyses-revised-workflows-tabs.component.html',
  styleUrls: ['./task-analyses-revised-workflows-tabs.component.scss']
})
export class TaskAnalysesRevisedWorkflowsTabsComponent implements OnInit {

  path: string;
  ta_revised_id: number;
  ta_revised_workflow_id: number;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public renderer: Renderer2
  ) { }

  ngOnInit() {
    this.ta_revised_workflow_id = this.dialogData.ta_revised_workflow.id;

    // get the task analysis id from the parent route params
    this.ta_revised_id = this.dialogData.ta_revised_id;

    // get sites path edit/view
    this.path = this.dialogData.path;
  }

}
