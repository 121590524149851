<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
        <div class="card" appMarginTop>
          <div class="card-body form-container">
            <h4 class="card-title" id="title">Company Details</h4>

            <mat-form-field>
              <input matInput placeholder="Company Name" [(ngModel)]="app.account.name" name="name" type="text" #name
                required id="name" />
              <mat-error *ngIf="!name.valid">Please enter the company name.</mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Address" [(ngModel)]="app.account.address" name="address" type="text"
                #address id="address" />
              <mat-error *ngIf="!address.valid">Please enter the street address.</mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput [placeholder]="utils.getLangTerm('accounts.form.suburb', 'Suburb')" [(ngModel)]="app.account.suburb" name="suburb" type="text" #suburb
                id="suburb" />
              <mat-error *ngIf="!suburb.valid">Please enter the {{ utils.getLangTerm('accounts.form.suburb', 'Suburb') }}.</mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput [placeholder]="utils.getLangTerm('accounts.form.city', 'City')" [(ngModel)]="app.account.city" name="city" type="text" #city
                id="city" />
              <mat-error *ngIf="!city.valid">Please enter the {{ utils.getLangTerm('accounts.form.city', 'City') }}.</mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput [placeholder]="utils.getLangTerm('accounts.form.postal_code', 'Postal Code')" [(ngModel)]="app.account.postal_code" name="postal_code"
                type="text" #postal_code id="postal_code" />
              <mat-error *ngIf="!postal_code.valid">Please enter the {{ utils.getLangTerm('accounts.form.postal_code', 'Postal Code') }}.</mat-error>
            </mat-form-field>

            <mat-form-field>
              <input matInput placeholder="Country" [(ngModel)]="app.account.country" name="country" type="text"
                #country id="country" />
              <mat-error *ngIf="!country.valid">Please enter the country.</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-select matInput placeholder="Industry" [(ngModel)]="app.account.industry" id="industry"
                name="industry" #industrySelect required>
                <mat-option *ngFor="let industry of industries" [value]="industry.name">{{ industry.name }}</mat-option>
              </mat-select>
            </mat-form-field>

            <app-timezone-select [timezoneInput]="app.account.timezone" (timezoneOutput)="getOutputTimezone($event)"></app-timezone-select>

            <button type="button" mat-raised-button [disabled]="!f.valid || inProgress" color="primary"
              class="save-button"
              style="max-width: 100px;"
              class="margin-right my-2"
              (click)="onSubmit(f)">
              <mat-icon>save</mat-icon>
              Save
            </button>

            <button
              mat-raised-button
              type="button"
              [disabled]="inProgress"
              (click)="onSelectMainContact()"
              [matBadge]="'' + (app.account.main_contact_id ? 1 : 0)"
              style="max-width: 150px;"
              class="margin-right my-2"
              color="secondary"
              matTooltip="Select a Main Contact.">
              <mat-icon>person</mat-icon>
              Main Contact
            </button>

          </div>
        </div>
      </form>
    </div>

    <div class="col-12 col-md-6">
      <div class="card" appMarginTop>
        <div class="card-body" appPaddingTop>
          <h4>Profile Picture</h4>
          <div class="row">
            <div class="col-12" *ngIf="app.account.company_logo_url">
              <img [src]="app.account.company_logo_url" class="img-fluid" alt="Company Logo" />
            </div>
            <div class="col-12">
              <button mat-raised-button color="secondary" type="button" (click)="onTriggerFileSelector($event)"
                appMarginTop appMarginBottom>
                Select &amp; Upload Image
              </button>
              <button mat-raised-button color="secondary" type="button" (click)="onRemoveCompanyLogo()"
                *ngIf="app.account.company_logo_url" appMarginTop appMarginBottom appMarginLeft>
                Remove Image
              </button>
              <input type="file" (change)="onUploadSelectedFile($event)" style="display: none;" #fileInput />
            </div>
            <div class="col-12">
              <mat-hint>Only image files are allowed.</mat-hint>
            </div>
          </div>
        </div>
      </div>


      <div class="card" appMarginTop>

        <div class="card-body" appPaddingTop>
          <h4 class="card-title">{{ utils.getAppName() }} Account Info</h4>

          <table class="text-wrap">
            <tr>
              <td width="250px" align="right"><b>Subscription:</b></td>
              <td>{{ app.account.subscription }}</td>
            </tr>

            <tr>
              <td align="right"><b
                  matTooltip="Enable/Disable Hazards & Risks verification notifications in the account configuration page."
                  matTooltipPosition="above">Hazards & Risks Verification Notifications:</b></td>
              <td>{{ app.account.hazard_verification ? 'Enabled' : 'Disabled' }}</td>
            </tr>

            <tr>
              <td align="right"><b
                  matTooltip="SMS Notifications are sent to users after a {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} closes when they are still onsite."
                  matTooltipPosition="above">SMS Notifications:</b></td>
              <td>{{ app.account.enable_sms_notifications ? 'Enabled' : 'Disabled' }}</td>
            </tr>

            <tr>
              <td align="right"><b>Date Joined:</b></td>
              <td>{{ (app.account.date_created * 1000) | timezoneConvertShort }}</td>
            </tr>

            <tr>
              <td colspan="2">
                Please contact your account manager if you want to upgrade/downgrade your account. Additional
                charges may apply for subscription upgrades and SMS notifications.
              </td>
            </tr>
          </table>

        </div>
      </div>
    </div>
  </div>
</div>
