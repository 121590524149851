<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button mat-raised-button routerLink="/sites" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('sites.plural', 'Sites') }}
  </button>

  <button mat-raised-button *ngIf="child_id" [routerLink]="'/sites/'+parent_id+'/children'" appMarginRight>
    <mat-icon>arrow_back</mat-icon> {{ utils.getLangTerm('child-sites.plural', 'Subsites') }}
  </button>

  <button mat-raised-button [routerLink]="utils.prepareSiteRelatedLink(site_id, child_id ? parent_id : null, 'edit/audits')" appMarginRight>
    <mat-icon>arrow_back</mat-icon> Inspections & Audits
  </button>
</nav>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="button-row">

      <button mat-raised-button [routerLink]="utils.prepareSiteRelatedLink(site_id, child_id ? parent_id : null, 'edit/audits/' + audit.id + '/view')"
        *ngIf="audit?.id">
        <mat-icon>remove_red_eye</mat-icon>
        View
      </button>

      <button mat-raised-button (click)="delete(audit)" *ngIf="audit?.id" color="warn">
        <mat-icon>delete</mat-icon>
        Delete
      </button>

    </div>
  </nav>

  <div class="container-fluid" class="app-padding">
    <div class="card h-100">
      <div class="card-body">
        <div class="card-title">
          <h6>Inspection/Audit</h6>
        </div>

        <form #form="ngForm" (ngSubmit)="onSubmit()" class="card-body">
          <mat-form-field>
            <input matInput placeholder="Inspection/Audit" [(ngModel)]="audit.name"
              name="name" type="text" required #name />
            <mat-error *ngIf="!name.valid">Please enter an Inspection/Audit name.
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Positive Comments"
              [(ngModel)]="audit.positive_comments" name="positive_comments"
              type="text" />
          </mat-form-field>

          <button mat-raised-button
            [disabled]="!form.valid || inProgress" color="primary"
            (click)="onSubmit()" class="margin-right" type="button">
            <mat-icon>save</mat-icon>
            Save
          </button>

          <button mat-raised-button color="secondary" id="contractors-button"
            [matBadge]="'' + audit?.contractor_ids?.length" type="button"
            (click)="onSelectContractors()">
            <fa-icon [icon]="faHardHat"></fa-icon>
            Associate {{ utils.getLangTerm('contractors.plural', 'Contractors') }}
          </button>
        </form>
      </div>
    </div>
  </div>
