import { Component, Inject, OnInit, Optional, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ToolboxTalksEditComponent } from '../toolbox-talks-edit/toolbox-talks-edit.component';
import { FileService } from 'src/app/shared/file.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';

@Component({
  selector: 'app-toolbox-talks-edit-dialog',
  templateUrl: './toolbox-talks-edit-dialog.component.html',
  styleUrls: ['./toolbox-talks-edit-dialog.component.scss']
})
export class ToolboxTalksEditDialogComponent extends ToolboxTalksEditComponent
  implements OnInit {
  constructor(
    public app: AppService,
    oldApi: ApiService,
    api: ApiRequestService,
    public router: Router,
    public renderer: Renderer2,
    public utils: UtilsService,
    @Optional() public dialogRef: MatDialogRef<ToolboxTalksEditDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    fileService: FileService
  ) {
    super(
      app,
      oldApi,
      api,
      router,
      renderer,
      utils,
      fileService,
      dialogData['id'],
      dialogRef
    );
    // set the hazard and site ids
    this.toolbox_talk.id = Number(this.dialogData['id']);
    this.toolbox_talk.site_id = Number(this.dialogData['site_id']);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async onSubmit(form: NgForm) {
    super.onSubmit(form);
  }

  onClose() {
    this.dialogRef.close();
  }

}
