<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/reports/time-onsite-records">Time Spent
      Onsite</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">

      <span class="mr-auto">
        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="onExport()">
            <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to export Time Spent Onsite Records." style="min-width: 150px">
          <mat-icon>more_vert</mat-icon>
          List Menu/Export
        </button>
      </span>

      <span class="spinner-container" *ngIf="(dataSource.loading | async)" appMarginRight> </span>

      <button type="button" mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="chartsUtils.onOpenMasterCharts('Access Activities')" matTooltip="Click this button to open the charts.">
        <mat-icon>bar_chart</mat-icon> Charts
      </button>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="onFilter()" matTooltip="Click this button to display filter options.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>

    </div>
  </nav>

  <app-site-access-activities-charts-container [baseStorageKey]="'site-access-activities-tor'"></app-site-access-activities-charts-container>

  <div class="mat-elevation-z8 scroll-box">
    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="desc" matSortDisableClear>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by ID">
          #
        </th>
        <td mat-cell *matCellDef="let record">
          <b>{{ record.id }}</b>
        </td>
      </ng-container>

      <ng-container matColumnDef="contact_person">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by full name">
          Full Name
        </th>
        <td mat-cell *matCellDef="let record">
          <ng-container *ngIf="record.user; else noUser">
            <a (click)="onUserPublicView(record.user.hash)"
               matTooltip="View User Public Profile">
              <b>{{ record.user.contact_person }}</b>
            </a> <br>
            {{ record.user.email }} <br>
            {{ record.user.mobile }} <br>
          </ng-container>
          <ng-template #noUser>Unassigned User</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="contact_person_account">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by company name">
          Company Name
        </th>
        <td mat-cell *matCellDef="let record">
          <ng-container *ngIf="record.user_account; else noUserAccount">
            {{ record.user_account.name }}
          </ng-container>
          <ng-template #noUserAccount>Unassigned Account</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="site_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by site">
          {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
        </th>
        <td mat-cell *matCellDef="let record">
          <ng-container *ngIf="record?.site; else noSite">
            <ng-container *ngIf="record?.site?.parent_site">
              <small style="display: block;">
                <a [routerLink]="utils.prepareSiteRelatedLink(record?.site?.parent_site?.id, null, '/edit/details')">
                  {{ record?.site?.parent_site?.name || '' }}
                </a>
              </small>
            </ng-container>
            <mat-icon *ngIf="record?.site?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
            <a [routerLink]="utils.prepareSiteRelatedLink(record?.site?.id, record?.site?.parent_site?.id, '/edit/details')">
              {{ record?.site?.name || '' }}
            </a>
          </ng-container>
          <ng-template #noSite>Unassigned {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="nearsite">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Was<br>Near {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
        </th>
        <td mat-cell *matCellDef="let time_onsite">
          {{ time_onsite.nearsite ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="onsite">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Is<br> Onsite</th>
        <td mat-cell *matCellDef="let time_onsite">
          {{ time_onsite.onsite ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="acknowledged">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by acknowledgement">
          {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Induction<br>
          Acknowledged
        </th>
        <td mat-cell *matCellDef="let time_onsite">
          {{ time_onsite.acknowledged ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by reason">
          Reason for<br>
          Visit
        </th>
        <td mat-cell *matCellDef="let time_onsite">
          {{ time_onsite.reason }}
        </td>
      </ng-container>

      <ng-container matColumnDef="time_spent_onsite">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by duration">
          Total Duration
        </th>
        <td mat-cell *matCellDef="let time_onsite">
          {{ utils.unixSecondsToElapsedDateTime(time_onsite.time_spent_onsite) }}
        </td>
      </ng-container>

      <!-- Method Column -->
      <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by QR Code">
          Initial <br>
          Sign-in Method
        </th>
        <td mat-cell *matCellDef="let time_onsite">
          <span [matTooltip]="time_onsite.device_id">{{ time_onsite.method }}</span>
        </td>
      </ng-container>

      <!-- Method Column -->
      <ng-container matColumnDef="has_covid_symptoms">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by whether the users have covid symptoms or not.">
          Covid Symptoms
        </th>
        <td mat-cell *matCellDef="let time_onsite">
          <span>{{ time_onsite.has_covid_symptoms ? 'Yes' : 'No' }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>
          Notes
        </th>
        <td mat-cell *matCellDef="let time_onsite">
          {{ time_onsite.notes }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by date">
          Date Created
        </th>
        <td mat-cell *matCellDef="let time_onsite">
          {{ (time_onsite.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by date">
          Date Created (UTC)
        </th>
        <td mat-cell *matCellDef="let time_onsite">
          {{ utils.unixToDate(time_onsite.date_created) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <ng-container matColumnDef="export">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let access_record">
          <button type="button" mat-button (click)="composeNewMessage(access_record.user_id)" matTooltip="Click this button to send a message to the user.">
            Send Message
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>
