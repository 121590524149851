import {IndustriesModel} from "../industries/industries.model";
import {UserModel} from "../../models/user.model";
import {Auditable} from "../../shared/auditable.model";

export class WorkPermitModel extends Auditable{
  id: number;
  title: string;
  description: string;
  industry_id: number = 0;

  /**
   * Which Industry is associated?
   */
  industry: IndustriesModel;

  /**
   * Who created this Work Permit?
   */
  created_by_user: UserModel;

  /**
   * Who updated this Work Permit?
   */
  updated_by_user: UserModel;
}
