import {Auditable} from "../shared/auditable.model";
import {HazardModel} from "./hazard.model";
import {FileModel} from "../shared/file.model";
import {TaskAnalysesRevisedWorkflowNoteModel} from "./task-analyses-revised-workflow-note.model";
import {UserModel} from "./user.model";
import {TaskAnalysesRevisedWorkflowHazardModel} from "./task-analyses-revised-workflow-hazard.model";

export class TaskAnalysesRevisedWorkflowModel extends Auditable {
  id = 0;

  description = '';
  step_no: number;

  /**
   * Initial risk assessment
   */
  ira_likelihood: 1 | 2 | 3 | 4 | 5 = 2;
  ira_severity: 1 | 2 | 3 | 4 | 5 = 2;

  /**
   * Residual risk assessment
   */
  rra_likelihood: 1 | 2 | 3 | 4 | 5 = 2;
  rra_severity: 1 | 2 | 3 | 4 | 5 = 2;

  /**
   * Who created this Task Analysis Workflow?
   */
  created_by_user: UserModel;

  /**
   * Who updated this Task Analysis Workflow?
   */
  updated_by_user: UserModel;

  /**
   * Hazards that belongs to Task Analysis Workflow?
   */
  hazards?: HazardModel[];
  hazard_ids?: number[];

  /**
   * Hazards that belongs to Task Analysis Workflow?
   */
  step_hazards?: TaskAnalysesRevisedWorkflowHazardModel[];
  step_hazard_ids?: number[];

  /**
   * Files that belongs to Task Analysis Workflow?
   */
  files?: FileModel[];
  file_ids?: number[];

  /**
   * Notes that belongs to Task Analysis Workflow?
   */
  notes?: TaskAnalysesRevisedWorkflowNoteModel[];
  // note_ids?: number[];


  apply(data: any) {
    if ( data ) {
      this.reset();
      Object.keys(data).forEach((key) => {
        if (key in this) {
          switch ( key ) {
            case 'files':
              this[key].length = 0;
              data[key].forEach((file: FileModel) => {
                this[key].push(file);
              });
              break;
            case 'hazards':
              this[key].length = 0;
              data[key].forEach((hazard: HazardModel) => {
                this[key].push(hazard);
              });
              break;
            case 'created_by_user':
              this.created_by_user.apply(data[key]);
              break;
            case 'updated_by_user':
              this.updated_by_user.apply(data[key]);
              break;
            default:
              this[key] = data[key];
          }
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;

    this.description = '';
    this.step_no = 0;

    this.ira_likelihood = 2;
    this.ira_severity = 2;

    this.rra_likelihood = 2;
    this.rra_severity = 2;

    if(this.files) {
      this.files.length = 0;
    }

    if(this.hazards) {
      this.hazards.length = 0;
    }

    if(this.hazard_ids) {
      this.hazard_ids.length = 0;
    }

    if(this.notes) {
      this.notes.length = 0;
    }
  }

}
