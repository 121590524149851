import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import {ApiDataSource} from "../../../utils/api-data-source";
import {ApiRequestService} from "../../../shared/api-request.service";
import {
  NetworkedUsersSelectorComponent
} from "../../../shared/networked-users-selector/networked-users-selector.component";
import {UserPublicProfileComponent} from "../../../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-sites-first-aiders',
  templateUrl: './sites-first-aiders.component.html',
  styleUrls: ['./sites-first-aiders.component.scss']
})
export class SitesFirstAidersComponent implements OnInit, AfterViewInit {

  // Store the site id for use later on.
  @Input('site_id') site_id: number;

  // Enable editing for the list of site managers.
  @Input('enableEditing') enableEditing: boolean = false;

  // The columns to display.
  displayedColumns: string[] = [
    'select',
    'employer',
    'contact_person',
    'email',
    'mobile',
    'trade',
    'actions'
  ];

  // Define the data source to use for the table.
  dataSource: SitesFirstAidersDataSource = new SitesFirstAidersDataSource(this.app, this.api);

  // The paginator and sorter
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public app: AppService,
    private oldApi: ApiService,
    public utils: UtilsService,
    public route: ActivatedRoute,
    private api: ApiRequestService
  ) {}

  ngOnInit() {
    // Load the data if the site id is valid.
    if ( this.site_id ) {
      // Add the site id to the data source object.
      this.dataSource.site_id = this.site_id;
      // Load the list data.
      this.dataSource.getData(true);
    }
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place.
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];
      this.dataSource.getData(true);
    })).subscribe();

    // Define and subscribe to the paginator events.
    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];

      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();
  }

  /**
   * Open the networked users selector to manage the list of site first aiders.
   */
  onManageSiteFirstAiders() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.dataSource.allIds,
      multiple: true,
      visitor_site_id: this.dataSource.site_id
    }, {
      width: '1024px'
    })
    .then((user_ids: number[]) => {
      if ( user_ids && user_ids.length > 0 ) {
        this.api.makeRequest('post', `v2/sites/${this.dataSource.site_id}/first-aiders`, {
          user_ids: user_ids
        })
        .then((response) => {
          // Reload the list of site safety managers.
          this.dataSource.getData();
        });
      }
    });
  }

  /**
   * Remove one selected site first aider.
   * @param evt
   * @param user_id
   */
  onRemoveSiteFirstAider(evt, user_id: number) {
    this.utils.showQuickActions(evt.target, 'Are you sure you want to remove the first aider?', [
      {
        text: 'No',
        handler: () => {}
      }, {
        text: 'Yes',
        handler: () => {
          this.removeSiteFirstAiders([user_id]);
        }
      }
    ]);
  }

  /**
   * Remove multiple site first aiders.
   * @param evt
   */
  onRemoveSelectedSiteFirstAiders(evt) {
    this.utils.showQuickActions(evt.target, 'Are you sure you want to remove the selected first aiders?', [
      {
        text: 'No',
        handler: () => {}
      }, {
        text: 'Yes',
        handler: () => {
          this.removeSiteFirstAiders(this.dataSource.selection.selected);
        }
      }
    ]);
  }

  /**
   * Remove selected site first aiders.
   * @param user_ids
   * @private
   */
  private removeSiteFirstAiders(user_ids: number[]) {
    if ( user_ids && user_ids.length > 0 ) {
      this.api.makeRequest('delete', `v2/sites/${this.dataSource.site_id}/first-aiders/` + user_ids.join(','))
        .then((response) => {
          this.utils.showToast('The site first aider(s) were removed.');
          // Reload the list of site managers.
          this.dataSource.getData();
          // Clear the selection if any.
          this.dataSource.selection.clear();
        })
        .catch((errorResponse) => {
          this.utils.showModal('Error Message', 'The first aider(s) could not be removed. Please try again.');
        });
    }
  }

  /**
   * Compose a new message for the site first aiders.
   */
  onComposeNewMessage(user_id: number = null) {
    // Check if the user have selected site first aiders.
    if ( this.dataSource.selection.selected.length == 0 && !user_id ) {
      this.utils.showToast('Please select first aiders to send the message to.');
      return;
    }

    // Open the message composer.
    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectUserIds: user_id ? [user_id] : this.dataSource.selection.selected
    })
    .then((response) => {
      if ( response ) {
        this.utils.showToast('You can view the message stats in the messages section.');
      }
    });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class SitesFirstAidersDataSource extends ApiDataSource {

  // The site id to use
  site_id: number;

  // The field to order results by and the order direction.
  order_by: string = 'contact_person';
  order: string = 'asc';

  getData(resetOffset: boolean = false) {
    // Do not load any data if the site id is not present.
    if ( !this.site_id ) {
      return;
    }

    return this.makeRequest(`v2/sites/${this.site_id}/first-aiders`, resetOffset);
  }
}
