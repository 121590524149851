import { Component, OnInit } from '@angular/core';
import { AuditItemModel } from '../audit-item.model';
import { UtilsService } from 'src/app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import { AuditItemsService } from '../audit-items.service';

@Component({
  selector: 'app-audit-items-template-view',
  templateUrl: './audit-items-template-view.component.html',
  styleUrls: ['./audit-items-template-view.component.css']
})
export class AuditItemsTemplateViewComponent implements OnInit {
  auditItem = new AuditItemModel();

  constructor(
    public utils: UtilsService,
    private activatedRoute: ActivatedRoute,
    private auditItemService: AuditItemsService
  ) {}

  ngOnInit() {
    this.auditItem.id = Number(this.activatedRoute.snapshot.params['id']);
    this.auditItemService
      .find(this.auditItem.id)
      .subscribe((response) => (this.auditItem = response.data));
  }
}
