import { TaStepHazardModel } from './ta-step-hazard.model';
import { Auditable } from '../shared/auditable.model';

export class TaStepModel extends Auditable {
  id = 0;
  step = '';
  hazards: TaStepHazardModel[] = [];

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        // do not store unwanted data
        if (key in this) {
          switch (key) {
            case 'hazards':
              data[key].forEach((hazard) => {
                this.hazards.push(new TaStepHazardModel().apply(hazard));
              });
              break;
            default:
              this[key] = data[key];
          }
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.step = '';
    this.hazards = [];
  }
}
