<div class="container-fluid">
  <mat-card *ngFor="let item of items; let i = index" class="mat-elevation-z8" [id]="item.id">

    <mat-card-title>
      {{ item.name }}
    </mat-card-title>

    <mat-card-subtitle>
      <div class="alert alert-info" role="alert">
        {{ item.tooltip }}
      </div>

      <p *ngIf="edit"><strong>{{ item.question }}</strong></p>

      <p *ngIf="!edit">Do you have <strong>{{ item.name }}</strong> documents? <span>{{ item.pivot.answer }}</span></p>

      <mat-button-toggle-group [(ngModel)]="item.pivot.answer" (change)="onUpdateAnswer(item)" aria-label="Answer" *ngIf="edit">
        <mat-button-toggle value="Yes">Yes</mat-button-toggle>
        <mat-button-toggle value="No">No</mat-button-toggle>
        <mat-button-toggle value="NA">Not Applicable</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-card-subtitle>

    <mat-card-content>
      <app-files-list [files]="item.pivot.files" [view]="!edit" *ngIf="item.pivot.answer == 'Yes'"></app-files-list>
    </mat-card-content>

    <mat-card-actions *ngIf="edit; else showMeta">
      <button mat-raised-button color="accent" (click)="onUpload(i)"
        [disabled]="!newFiles[item.pivot.id] || inProgresses[i]" *ngIf="item.pivot.answer == 'Yes'">
        <mat-icon id="save">cloud_upload</mat-icon>
        Upload
      </button>

      <app-files-selector (selected)="newFiles[item.pivot.id] = $event" *ngIf="item.pivot.answer == 'Yes'"
        [selectedFilesCount]="newFiles[item.pivot.id]?.length"></app-files-selector>

      <button mat-raised-button color="secondary" (click)="onAddNotes(item)" [matBadge]="item.pivot.notes ? 1 : 0">
        <mat-icon>speaker_notes</mat-icon>
        Add Notes
      </button>

      <span class="mr-auto"></span>
      <span class="float-right" *ngIf="item.pivot.answer == 'Yes'">
        <input matInput [matDatepicker]="picker" placeholder="Expiry" readonly [(ngModel)]="expiries[item.pivot.id]"
          name="expiry {{ item.pivot.id }}" (click)="picker.open()" (dateChange)="onSetExpiry(item.pivot.id, item.name)"
          matTooltip="Please select an expiry date if the uploaded documents have one." />
        <mat-datepicker-toggle matSuffix [for]="picker" matTooltip="Select expiry date."></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

        <button *ngIf="expiries[item.pivot.id]" type="button" mat-button matSuffix mat-icon-button (click)="onSetExpiry(item.pivot.id, item.name, true)"
          matTooltip="Remove the Pre-Qualification document expiry date.">
          <mat-icon>close</mat-icon>
        </button>
      </span>
    </mat-card-actions>

    <ng-template #showMeta>
      <mat-card-subtitle>
        <p><strong>Notes:</strong> {{ item.pivot.notes || 'None' }}</p>
        <p><strong>Expiry Date:</strong> {{ expiries[item.pivot.id] ? expiries[item.pivot.id].format("DD/MM/YYYY") : 'None' }}</p>
      </mat-card-subtitle>
    </ng-template>
  </mat-card>
</div>
