<div class="app-margin">

  <ng-container *ngIf="prequal.name != ''">
    <h6>Pre-Qualification Name</h6>
    <p>{{ prequal.name }}</p>
  </ng-container>

  <ng-container *ngIf="prequal.description != ''">
    <h6>Pre-Qualification Description</h6>
    <p>{{ prequal.description }}</p>
  </ng-container>

  <h6>Client</h6>
  <p>{{ prequal.account?.name }}</p>

  <h6>Health and Safety Score</h6>
  <p>{{ prequal.score }}%</p>

  <app-my-items *ngIf="prequal?.items.length > 0" [items]="prequal.items" [edit]="true" (edited)="find()" [hash]="prequal.hash"></app-my-items>

  <div>
    <p>
      By clicking the submit button <strong>{{ prequal.account?.name }}</strong> will be notified to review your uploaded documents.
      Your overall <strong>Health and Safety Score</strong> will be updated by them. You can still continue making changes as needed after submitting it.
    </p>
  </div>

  <button mat-raised-button color="primary" (click)="onComplete()">
    <mat-icon>check</mat-icon>
    Submit
  </button>
</div>
