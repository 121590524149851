<button type="button" mat-raised-button class="mt-3 mb-3 ml-3" color="primary" (click)="onAddSSSPDoc()" matTooltip="Click this button to add a new document to this Safety Plan.">
  <mat-icon>add</mat-icon>
  Add Document
</button>

<mat-paginator #paginator1 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

<app-sssp-doc *ngFor="let doc of listDataSource.connect() | async" [sssp_hash]="sssp.sssp_hash" [sssp_doc]="doc" [sssp]="sssp" (onDeleted)="onDeleted($event)"></app-sssp-doc>

<mat-paginator #paginator2 [length]="listDataSource?.total | async" [pageSize]="listDataSource.limit" [pageSizeOptions]="listDataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
