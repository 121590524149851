import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import { faBiohazard } from '@fortawesome/free-solid-svg-icons';
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {FileService} from "../../shared/file.service";
import {Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {SiteHazardsSelectorComponent} from "../../shared/site-hazards-selector/site-hazards-selector.component";
import {TaskAnalysesRevisedWorkflowModel} from "../../models/task-analyses-revised-workflow.model";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {RISK_LIKELIHOODS} from "../../shared/risk-likelihoods";
import {RISK_SEVERITIES} from "../../shared/risk-severities";
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {SitesTaskAnalysesRevisedVersioningComponent} from "../sites-task-analyses-revised-versioning/sites-task-analyses-revised-versioning.component";

@Component({
  selector: 'app-sites-task-analyses-revised-workflows-edit',
  templateUrl: './sites-task-analyses-revised-workflows-edit.component.html',
  styleUrls: ['./sites-task-analyses-revised-workflows-edit.component.scss']
})
export class SitesTaskAnalysesRevisedWorkflowsEditComponent implements OnInit {

  faBiohazard = faBiohazard;

  inProgress = false;
  selectedFiles: File[] = [];
  newFiles: FileList;
  path: string;
  ta_revised_id: number;
  site_id: number;
  ta_revised: TaskAnalysesRevisedModel = new TaskAnalysesRevisedModel();
  ta_revised_workflow: TaskAnalysesRevisedWorkflowModel = new TaskAnalysesRevisedWorkflowModel();

  likelihood_list: string[] = RISK_LIKELIHOODS;
  severity_list: string[] = RISK_SEVERITIES;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    private fileService: FileService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<SitesTaskAnalysesRevisedWorkflowsEditComponent>,
    public router: Router,
    public renderer: Renderer2
  ) { }

  ngOnInit() {
    this.ta_revised_workflow.id = this.dialogData.ta_revised_workflow.id;

    // get the task analysis id from the parent route params
    this.ta_revised_id = this.dialogData.ta_revised_id;

    // get the site id from the parent route params
    this.site_id = this.dialogData.site_id;

    // get sites path edit/view
    this.path = this.dialogData.path;

    if(this.ta_revised_workflow.id) {
      this.find(this.ta_revised_workflow.id);
    }

    if(this.ta_revised_id) {
      this.findTa(this.ta_revised_id);
    }
  }

  onSubmit(form: NgForm, closeDialog:boolean = true, findOnly: boolean = false) {
    // Do not process if already in progress.
    if (this.inProgress) {
      return;
    }

    // Perform form validation.
    if (!form.valid) {
      this.utils.showFormValidationError('Please enter all required fields.');
      return;
    }

    this.inProgress = true;

    if(!this.ta_revised_workflow.id) {
      this.create();
    } else {
      if(findOnly) {
        this.find(this.ta_revised_workflow.id);
        this.inProgress = false;
      } else {
        this.update(closeDialog);
      }
    }
  }

  getRiskAssessmentColorChange(likelihood: number, severity: number) {
    const riskAssessment = this.utils.getRiskAssessmentText(
      likelihood,
      severity
    );

    if (['Very Low', 'Low'].find((value) => value === riskAssessment)) {
      return 'success';
    }

    if (['Moderate'].find((value) => value === riskAssessment)) {
      return 'warning';
    }

    if (['High', 'Critical'].find((value) => value === riskAssessment)) {
      return 'danger';
    }

    return 'danger';
  }

  private find(ta_revised_workflow_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${ta_revised_workflow_id}`, [], {
      site_id: this.site_id
    })
      .then((response) => {
        this.ta_revised_workflow = response;

        // Extract Hazard ids.
        this.ta_revised_workflow.hazard_ids = this.ta_revised_workflow.hazards ? this.ta_revised_workflow.hazards.map((hazard) => hazard.id) : [];

        // Extract File ids.
        this.ta_revised_workflow.file_ids = this.ta_revised_workflow.files ? this.ta_revised_workflow.files.map((file) => file.id) : [];

      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private findTa(ta_revised_id: number) {

    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {
      site_id: this.site_id
    })
      .then((response) => {
        this.ta_revised = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private create() {
    this.acknowledgementCheck("create");
  }

  private continueCreate() {
    let request: Promise<any>;
    let upload_ta_revised_workflow_id: number = 0;

    request = this.api
      .makeRequest('post', `v2/task-analyses-revised/${this.ta_revised_id}/workflows`, this.ta_revised_workflow, {
        site_id: this.site_id
      })
      .then((response) => {
        this.onSuccess(`New TA/JSA/SWMS Workflow Step created.`);
        this.ta_revised_workflow.apply(response);
        upload_ta_revised_workflow_id = response.id;
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised_workflow/${upload_ta_revised_workflow_id}`, this.selectedFiles)
          .then((response) => {
            this.utils.showToast('Your files successfully uploaded.');
          })
          .finally(() => {
            this.selectedFiles.length = 0;
            this.close(true);
          });
      } else {
        this.close(true);
      }
    });
  }

  private update(closeDialog:boolean = true) {
      this.acknowledgementCheck("update", closeDialog);
  }

  private continueUpdate(closeDialog:boolean = true) {
    let request: Promise<any>;

    request = this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised_id}/workflows/${this.ta_revised_workflow.id}`, this.ta_revised_workflow, {
        site_id: this.site_id
      })
      .then((response) => {
        this.onSuccess(`TA/JSA/SWMS Workflow Step updated.`);
        this.ta_revised_workflow.apply(response);
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised_workflow/${this.ta_revised_workflow.id}`, this.selectedFiles)
          .then((response) => {
            this.utils.showToast('Your files successfully uploaded.');
          })
          .finally(() => {
            this.selectedFiles.length = 0;
            if(!closeDialog) {
              this.find(this.ta_revised_workflow.id);
              this.inProgress = false;
            } else {
              this.close(false);
            }
          });
      } else {
        if(!closeDialog) {
          this.find(this.ta_revised_workflow.id);
          this.inProgress = false;
        } else {
          this.close(false);
        }
      }
    });
  }

  private acknowledgementCheck(crud_action: string, closeDialog:boolean = true, file_id: number = 0) {
    this.api
      .makeRequest('get', `v2/task-analyses-revised/${this.ta_revised_id}/acknowledgement`, this.ta_revised, {
        site_id: this.site_id
      })
      .then((response) => {
        if(response.data.acknowledgementsDone) {
          this.beforeVersioning(crud_action, file_id);
        } else {

          switch(crud_action) {
            case "update":
              this.continueUpdate(closeDialog);
              break;
            case "create":
              this.continueCreate();
              break;
            case "file_delete":
              this.continueOnRemoveFile(file_id);
              break;
            default:
            // do nothing
          }

        }
      });
  }

  private beforeVersioning(crud_action: string, file_id: number = 0) {
    this.inProgress = false;

    this.utils.showComponentDialog(
      SitesTaskAnalysesRevisedVersioningComponent,
      {
        task_name: this.ta_revised.task_name
      },
      {
        width: '768px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          // If confirmed then begin versioning of Task Analysis
          if(results.confirmed) {
            this.createNewVersion(results.amendment_title, results.reason_for_amendment, crud_action, [], file_id);
          }
        }
      }
    );
  }

  private createNewVersion(amendment_title: string, reason_for_amendment: string, crud_action:string, remove_ids:number[] = [], file_id: number = 0) {
    let request: Promise<any>;
    let upload_ta_revised_workflow_id: number = 0;

    this.inProgress = true;

    request = this.api
      .makeRequest('put', `v2/task-analyses-revised/${this.ta_revised_id}/workflows-version`, this.ta_revised_workflow, {
        site_id: this.site_id,
        workflow_id: this.ta_revised_workflow.id,
        amendment_title: amendment_title,
        reason_for_amendment: reason_for_amendment,
        crud_action: crud_action,
        remove_ids: remove_ids.length ? remove_ids.join(',') : '',
        file_remove_id: file_id,
        workflow_upload_id: this.selectedFiles.length > 0 ? this.ta_revised_workflow.id : 0
      })
      .then((response) => {
        this.onSuccess(`Created a new version of the "${this.ta_revised.task_name}" TA/JSA/SWMS.`);
        upload_ta_revised_workflow_id = response.upload_id;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });

    // Check if there are any files to upload.
    request.finally(() => {
      if ( this.selectedFiles.length > 0 && upload_ta_revised_workflow_id > 0 ) {
        this.api.makeUploadRequest(`v2/file-manager/task_analysis_revised_workflow/${upload_ta_revised_workflow_id}`, this.selectedFiles)
          .then((response) => {
            this.inProgress = false;
            this.redirectBack();
          })
          .finally(() => {
            this.selectedFiles.length = 0;
          });
      } else {
        this.inProgress = false;
        this.redirectBack();
      }
    });
  }

  private redirectBack() {
    if(this.site_id && this.path) {
      this.router.navigate([`/sites/${this.site_id}/${this.path}/task-analyses-revised/`]);
    }
    // Code Review 30/08/2021: Temp fix. Close all dialogs when a new TA version is created.
    // this.dialog.closeAll();

    this.dialogRef.close('version');
  }

  private close(shouldCloseAll: boolean = false) {
    this.inProgress = false;

    // Code Review 30/08/2021: Temp fix. Close all dialogs when a new TA version is created.
    if ( shouldCloseAll ) {
      this.dialog.closeAll();
    } else {
      this.dialogRef.close();
    }

  }

  private onSuccess(message: string) {
    this.utils.showToast(message);
    // this.dialogRef.close(true);
  }

  onSelectHazards() {
    this.utils.showComponentDialog(
      SiteHazardsSelectorComponent,
      {
        selected: this.ta_revised_workflow.hazard_ids,
        multiple: true,
        selectedAccountId: this.app.account.id,
        site_id: this.site_id
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (!results) { return; }
        this.ta_revised_workflow.hazard_ids = results;
      }
    );
  }

  onSelectFiles(form: NgForm, files?: any[]) {
    if ( files.length > 0 ) {
      this.selectedFiles.push(...files);
      this.onSubmit(form, false);
    }
  }

  onRemoveFile(file_id: any) {
    if ( file_id > 0 ) {
      // Do not process if already in progress.
      if (this.inProgress) {
        return;
      }

      // this.inProgress = true;
      // this.acknowledgementCheck('file_delete', false, file_id); // Removed to avoid Versioning when Managing Files
      this.utils.showToast('Your file was removed successfully.');
    }
  }

  continueOnRemoveFile(file_id: number) {
    this.api.makeRequest('delete',`v2/file-manager/task_analysis_revised_workflow/${this.ta_revised_workflow.id}/${file_id}`, [], {})
      .then((response) => {
        this.utils.showToast('Your file was removed successfully.');
        this.find(this.ta_revised_workflow.id);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

}
