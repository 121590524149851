<mat-dialog-content class="my-3 mx-0 py-0 pr-3 pl-0">
  <form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
    <mat-form-field>
      <input matInput
        placeholder="Document Name"
        [(ngModel)]="folder.name"
        name="name"
        type="text"
        #name
        required
        id="name"/>
      <mat-error *ngIf="!name.valid">
        Please enter the name of the document.
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Document Type" [(ngModel)]="folder.folder_type" name="type" required id="type">
        <mat-option *ngFor="let document_type of documentTypes" [value]="document_type">{{ document_type }}</mat-option>
      </mat-select>
      <mat-error *ngIf="!folder.folder_type">
        Please select the type of document.
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        placeholder="Document Number"
        #folder_number
        id="folder_number"
        [(ngModel)]="folder.folder_number"
        name="folder_number"
        type="text"/>
    </mat-form-field>

    <app-timezone-select [timezoneInput]="selectedTimezone" (timezoneOutput)="getOutputTimezone($event)"></app-timezone-select>

    <mat-form-field>
      <input
        matInput
        [matDatepicker]="picker"
        placeholder="Expiry Date"
        readonly
        (click)="picker.open()"
        [(ngModel)]="date"
        name="date"
        id="date"
        [min]="now"/>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker disabled="false"></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngIf="show_document_visibility">
      <mat-select placeholder="Document Visibility" [(ngModel)]="folder.visibility" name="visibility" required id="visibility">
        <mat-option value="Private">Private - Only viewable to person they belong to</mat-option>
        <mat-option value="Group">Group - Administrators and person they belong to</mat-option>
        <mat-option value="Public">Public - Everyone and person they belong to</mat-option>
      </mat-select>
      <mat-error *ngIf="!folder.visibility">
        Please select the level of privacy.
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button
    [disabled]="!f.valid || inProgress"
    color="primary"
    (click)="onSubmit(f)"
    id="save">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button mat-raised-button
    color="secondary"
    (click)="onRemoveExpiryDate()"
    class="remove-expiry-date"
    [disabled]="!folder.expiry && !date">
    <mat-icon>calendar_today</mat-icon>
    Remove Expiry Date
  </button>

  <app-files-selector (selected)="newFiles = $event" [selectedFilesCount]="newFiles?.length" appMarginRight></app-files-selector>

  <button mat-raised-button
    (click)="onRemoveFiles()"
    color="secondary"
    [matBadge]="'' + (folder.files?.length)"
    [disabled]="!folder.files?.length">
    <mat-icon>open_in_browser</mat-icon>
    Manage Files
  </button>

  <span class="mr-auto"></span>

  <button type="button"
    mat-icon-button
    [disabled]="!folder.id"
    class="float-right"
    (click)="onRemove()">
    <mat-icon>delete</mat-icon>
  </button>
</mat-dialog-actions>

