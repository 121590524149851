<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <h6>{{ utils.getAppName() }} New Zealand Ltd</h6>

      <p>
        Level 11, 19 Como Street <br />
        Takapuna <br />
        Auckland, 0622 <br />
        New Zealand
      </p>

      <h6>Sales &amp; Support</h6>
      <p>
        Tel +64 800 748 763 <br />
        <a [href]="'mailto:' + utils.getSalesEmailAddress()">{{ utils.getSalesEmailAddress() }}</a> <br />
        <a [href]="'mailto:' + utils.getSupportEmailAddress()">{{ utils.getSupportEmailAddress() }}</a>
      </p>

      <iframe
        style="border: 0;"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3195.252144951165!2d174.76702461529692!3d-36.78850448384448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d39d6d92c8b49%3A0x15282ad97c7503e1!2s19+Como+St%2C+Takapuna%2C+Auckland+0622!5e0!3m2!1sen!2snz!4v1527731028188"
        width="100%"
        height="350"
        frameborder="0"
        allowfullscreen="allowfullscreen">
      </iframe>
    </div>
  </div>
</div>
