import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { SiteModel } from 'src/app/models/site.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-sites-main-sssp',
  templateUrl: './sites-main-sssp.component.html',
  styleUrls: ['./sites-main-sssp.component.scss']
})
export class SitesMainSsspComponent implements OnInit {

  site: SiteModel = new SiteModel();

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // Get the site id from the route params.
    const parent_id: number = Number(this.route.parent.snapshot.params['parent_id']);
    const child_id: number = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && parent_id) ) {
      // Store the site id.
      const site_id: number = child_id ? child_id : parent_id;
      // Make a request to get the site details.
      this.api.makeRequest('get', `v1/sites/${site_id}`)
        .then((response) => {
          this.site.apply(response.data); // response.data is a legacy format that need to be used.
        });
    }
  }

  onCreateSSSP() {
    this.api.makeRequest('post', 'v1/sssp', {
      site_id: this.site.id
    })
    .then((response: any) => {
      // This means the request was successful.
      this.site.sssp = response;
    })
    .catch((error) => {
      this.utils.showModal('Error', 'Failed to create the Master Safety Plan.');
    });
  }

}
