import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AppService} from "../../../app.service";
import {ApiRequestService} from "../../../shared/api-request.service";
import {UtilsService} from "../../../shared/utils.service";
import {SiteModel} from "../../../models/site.model";

@Component({
  selector: 'app-admin-site-details-tabs',
  templateUrl: './admin-site-details-tabs.component.html',
  styleUrls: ['./admin-site-details-tabs.component.scss']
})
export class AdminSiteDetailsTabsComponent implements OnInit {

  // the site model placeholder
  site: SiteModel = new SiteModel();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private apiRequest: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    // get the site id
    this.site.id = Number(this.route.parent.snapshot.params['site_id']);
  }

}
