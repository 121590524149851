<div mat-dialog-title>
  <h3>
    Select Tools
    <span *ngIf="dataSource.selection.selected.length">
      ({{ dataSource.selection.selected.length }})
    </span>
  </h3>
  <button mat-icon-button class="float-right" [mat-dialog-close]="undefined">
    <mat-icon color="secondary">close</mat-icon>
  </button>

  <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
    <mat-form-field class="mr-sm-2">
      <mat-select class="filter-dropdowns"
                  placeholder="Industry"
                  [(ngModel)]="dataSource.industry"
                  (selectionChange)="dataSource.getData(true)"
                  id="industry"
                  name="industry">
        <mat-option value="">All Industries</mat-option>
        <mat-option *ngFor="let industry of industries" [value]="industry.id">{{ industry.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <input
      class="form-control mr-sm-2"
      type="search"
      [(ngModel)]="dataSource.search"
      name="search"
      placeholder="Search"
      aria-label="Search" />

    <button mat-raised-button class="mr-sm-2" type="submit">
      <mat-icon>search</mat-icon> Search
    </button>

    <button
      mat-raised-button
      class="mr-sm-2"
      (click)="onClearSelected()"
      type="button" >
      Clear Selected
    </button>

    <button
      mat-raised-button
      color="primary"
      class="mr-sm-2"
      (click)="onCreateNewTool()"
      type="button" >
      <mat-icon>add</mat-icon>
      New Tool
    </button>
  </form>
</div>

<mat-dialog-content>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="title"
    matSortDirection="asc"
    matSortDisableClear >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef width="65px">
        <mat-checkbox
          (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
          [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)"
          appMarginRight>
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ element.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ element.title | ellipsis: [30] }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ element.description | ellipsis: [60] }}
      </td>
    </ng-container>

    <ng-container matColumnDef="industry">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Industry</th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ element.industry ? element.industry.name : "" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ (element.date_created * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created (UTC)</th>
      <td mat-cell *matCellDef="let element" class="text-wrap">
        {{ (element.date_created * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="dataSource.selection.toggle(row.id)"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <mat-paginator
    [length]="dataSource?.total | async"
    [pageSize]="dataSource.limit"
    [pageSizeOptions]="dataSource.pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>

  <button
    mat-raised-button
    color="primary"
    type="button"
    mat-dialog-close
    (click)="onSaveAndClose()"
    [disabled]="!data['multiple'] && !dataSource.selection.hasValue()">
    Select & Close
  </button>
</mat-dialog-actions>


