import { Component, OnInit } from '@angular/core';
import { SiteModel } from 'src/app/models/site.model';
import { SitesService } from 'src/app/shared/sites.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-sites-emergency-evacuation-plan-view',
  templateUrl: './sites-emergency-evacuation-plan-view.component.html',
  styleUrls: ['./sites-emergency-evacuation-plan-view.component.scss']
})
export class SitesEmergencyEvacuationPlanViewComponent implements OnInit {

  site = new SiteModel();

  parent_id: number;
  child_id: number;
  site_id: number;

  constructor(
    private siteService: SitesService,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      this.site.id = this.site_id;

      this.siteService.find(this.site.id, true).subscribe((response) => {
        this.site = response.data;
      });
    }
  }

  onExportEmergencyEvacuationPlan() {
    this.api.laravelApiObservable('get', `sites/${this.site.id}/emergency-evacuation-plan`).toPromise()
    .then((response) => {
      window.open(response.data.url, '_blank');
    });
  }
}
