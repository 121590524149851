<div mat-dialog-title>
  <h5>
    Lost Time Injury (LTI) Record
    <button mat-icon-button class="float-right" mat-dialog-close>
      <mat-icon color="secondary">close</mat-icon>
    </button>
  </h5>
</div>

<mat-dialog-content class="mb-3">
  <div class="container-fluid">
    <form #f="ngForm" (ngSubmit)="onSave()">
      <mat-tab-group>
        <mat-tab label="Affected Person">
          <div class="row m-0 mt-3">
            <div class="col">
              <mat-form-field>
                <input matInput type="text" placeholder="Affected Person" [(ngModel)]="incident_lti.affected_user_contact_person" name="affected_user_contact_person" matTooltip="The name of the affected person." />
                <mat-hint>Please enter the name of the affected person.</mat-hint>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field>
                <input matInput type="text" placeholder="Affected Person's {{ utils.getLangTerm('users.form.mobile', 'Mobile Number') }}" [(ngModel)]="incident_lti.affected_user_phone" name="affected_user_phone" matTooltip="The {{ utils.getLangTerm('users.form.mobile', 'Mobile Number') }} of the affected person." />
                <mat-hint>Please enter the {{ utils.getLangTerm('users.form.mobile', 'Mobile Number') }} of the affected person.</mat-hint>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field>
                <input matInput type="text" placeholder="Affected Person's Email Address" [(ngModel)]="incident_lti.affected_user_email" name="affected_user_email" matTooltip="The email address of the affected person." />
                <mat-hint>Please enter the email address of the affected person.</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="row m-0 mt-3">
            <div class="col d-flex justify-content-end">
              <button mat-raised-button type="button" (click)="onSelectUser()" [disabled]="!f.valid" color="secondary"><mat-icon>account_circle</mat-icon> Select User</button>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Health Provider">
          <div class="row m-0 mt-3">
            <div class="col">
              <mat-form-field class="mt-3">
                <input matInput type="text" placeholder="Health Provider's Name" [(ngModel)]="incident_lti.health_provider_name" name="health_provider_name" matTooltip="The name of the health provider. e.g. Southern Cross." />
                <mat-hint>Please enter the name of the health provider.</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="row m-0 mt-3">
            <div class="col">
              <mat-form-field>
                <input matInput type="text" placeholder="Health Provider Main Contact" [(ngModel)]="incident_lti.health_provider_contact_person" name="health_provider_contact_person" matTooltip="The name of the main contact at the health provider." />
                <mat-hint>Please enter the name of the main contact for the health provider.</mat-hint>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field>
                <input matInput type="text" placeholder="Health Provider Contact Number" [(ngModel)]="incident_lti.health_provider_phone" name="health_provider_phone" matTooltip="The phone number of the main contact at the health provider." />
                <mat-hint>Please enter the phone number of the main contact for the health provider.</mat-hint>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field>
                <input matInput type="text" placeholder="Health Provider Email Address" [(ngModel)]="incident_lti.health_provider_email" name="health_provider_email" matTooltip="The email address of the main contact at the health provider." />
                <mat-hint>Please enter the email address of the main contact for the health provider.</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="row m-0 mt-3">
            <div class="col">
              <mat-form-field>
                <textarea matInput placeholder="Health Provider Description" [(ngModel)]="incident_lti.health_provider_description" name="health_provider_description" rows="5" matTooltip="Enter any additional health provider comments."></textarea>
                <mat-hint>Use this description input field to capture additional comments and feedback for the health provider.</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="ACC Case Manager">
          <div class="row m-0 mt-3">
            <div class="col">
              <mat-form-field class="mt-3">
                <input matInput type="text" placeholder="ACC Case Manager's Office Name" [(ngModel)]="incident_lti.acc_case_manager_office_name" name="acc_case_manager_office_name" matTooltip="The name of the ACC case manager's office. e.g. Auckland Office." />
                <mat-hint>Please enter the name of the ACC case manager's office.</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="row m-0 mt-3">
            <div class="col">
              <mat-form-field>
                <input matInput type="text" placeholder="ACC Case Manager's Name" [(ngModel)]="incident_lti.acc_case_manager_contact_person" name="acc_case_manager_contact_person" matTooltip="The name of the ACC case manager." />
                <mat-hint>Please enter the name of the ACC case manager.</mat-hint>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field>
                <input matInput type="text" placeholder="ACC Case Manager's Contact Number" [(ngModel)]="incident_lti.acc_case_manager_phone" name="acc_case_manager_phone" matTooltip="The phone number of the ACC case manager." />
                <mat-hint>Please enter the contact number of the ACC case manager.</mat-hint>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field>
                <input matInput type="text" placeholder="ACC Case Manager's Email Address" [(ngModel)]="incident_lti.acc_case_manager_email" name="acc_case_manager_email" matTooltip="The email address of the ACC case manager." />
                <mat-hint>Please enter the email address of the ACC case manager.</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="row m-0 mt-3">
            <div class="col">
              <mat-form-field>
                <textarea matInput placeholder="ACC Case Manager's Description" [(ngModel)]="incident_lti.acc_case_manager_description" name="acc_case_manager_description" rows="5" matTooltip="Enter any additional comments here."></textarea>
                <mat-hint>Use this description input field to capture additional comments or feedback for ACC.</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Return to Work">

          <div class="row m-0 mt-3">
            <div class="col">
              <app-timezone-select [timezoneInput]="selectedEstimatedTimezone" (timezoneOutput)="getOutputEstimatedTimezone($event)"></app-timezone-select>
            </div>
            <div class="col">
              <app-timezone-select [timezoneInput]="selectedActualTimezone" (timezoneOutput)="getOutputActualTimezone($event)"></app-timezone-select>
            </div>
          </div>

          <div class="row m-0 mt-3">
            <div class="col">
              <mat-form-field>
                <mat-label>Estimated Date:</mat-label>
                <input matInput [ngxMatDatetimePicker]="estimatedDateTimePicker" readonly name="estimatedDateTime" [(ngModel)]="estimatedDateTime">
                <mat-datepicker-toggle matSuffix [for]="estimatedDateTimePicker"></mat-datepicker-toggle>
                <button type="button" mat-button matSuffix mat-icon-button *ngIf="estimatedDateTime" (click)="estimatedDateTime = null" matTooltip="Remove the date."><mat-icon>close</mat-icon></button>
                <ngx-mat-datetime-picker #estimatedDateTimePicker [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
                <mat-hint>Click the calendar icon to select a date and time.</mat-hint>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field>
                <mat-label>Actual Date:</mat-label>
                <input matInput [ngxMatDatetimePicker]="actualDateTimePicker" readonly name="actualDateTime" [(ngModel)]="actualDateTime">
                <mat-datepicker-toggle matSuffix [for]="actualDateTimePicker"></mat-datepicker-toggle>
                <button type="button" mat-button matSuffix mat-icon-button *ngIf="actualDateTime" (click)="actualDateTime = null" matTooltip="Remove the date."><mat-icon>close</mat-icon></button>
                <ngx-mat-datetime-picker #actualDateTimePicker [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
                <mat-hint>Click the calendar icon to select a date and time.</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="row m-0 mt-3">
            <div class="col">
              <mat-form-field>
                <textarea matInput placeholder="Return to Work Description" [(ngModel)]="incident_lti.return_to_work_description" name="return_to_work_description" rows="5" matTooltip="Enter the return to work description here."></textarea>
                <mat-hint>Please enter the return to work description here.</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Time Records" class="pt-3" *ngIf="incident_lti.incident_lti_id">
          <app-incidents-ltis-time-records [incident]="incident" [incident_lti]="incident_lti" (closed_incident_lti_time_record_evt)="getIncidentLti(incident_lti.incident_lti_id)"></app-incidents-ltis-time-records>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="onSave()" [disabled]="!f.valid" color="primary" class="mr-3">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <app-file-browse-button
    parentObjectType="incident_lti"
    [parentObjectId]="incident_lti.incident_lti_id"
    [filesCount]="incident_lti.files.length"
    (afterDialogClosedEvent)="getIncidentLti(incident_lti.incident_lti_id)"
    (selectedFiles)="onSelectFiles($event)">
  </app-file-browse-button>
</mat-dialog-actions>
