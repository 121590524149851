import { Component, Input, OnInit } from '@angular/core';
import { DigitalSignatureMeta } from '../digital-signature/digital-signature-meta';

@Component({
  selector: 'app-digital-signature-image',
  templateUrl: './digital-signature-image.component.html',
  styleUrls: ['./digital-signature-image.component.scss']
})
export class DigitalSignatureImageComponent implements OnInit {

  // The meta information for this component.
  @Input('meta') meta: DigitalSignatureMeta;

  // This is used to display the title above the signature box.
  @Input('title') title: string;

  constructor() { }

  ngOnInit(): void {
    // Check if the title is set and if not, make it "Signature" by default.
    this.title = this.title || 'Signature';

    // Apply meta defaults to the authenticated user.
    if ( typeof this.meta == 'undefined' ) {
      this.meta = {
        user_id: null,
        user_name: null,
        signature_url: '',
        signed_at: null
      } as DigitalSignatureMeta;
    }

    // If the "signed_at" value is not a number it is likely a date string e.g. 2024-06-24T03:03:25.000000Z.
    if ( typeof this.meta.signed_at != 'undefined' && typeof this.meta.signed_at != 'number' ) {
      // Convert the signed_at value to a unix timestamp.
      this.meta.signed_at = Date.parse(this.meta.signed_at) / 1000;
    }
  }

}
