import { Auditable } from "../shared/auditable.model";

export class CourseModel extends Auditable {
  id = 0;
  name = '';
  description = '';
  induction_enabled: boolean = false;
  selectedSites: number[] = [];
  reset_status = false;

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.selectedSites.length = 0;
    this.induction_enabled = false;
  }
}
