import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { ApiService } from 'src/app/shared/api.service';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { UtilsService } from '../../shared/utils.service';
import {HazardousSubstancesEditDialogComponent} from "../../hazardous-substances/hazardous-substances-edit-dialog/hazardous-substances-edit-dialog.component";
import { HazardousSubstanceModel } from "../../models/hazardous-substance.model";
import {SiteModel} from "../../models/site.model";
import {HazardousSubstancesFilterComponent} from "../hazardous-substances-filter/hazardous-substances-filter.component";

@Component({
  selector: 'app-hazardous-substances',
  templateUrl: './hazardous-substances.component.html',
  styleUrls: ['./hazardous-substances.component.css']
})
export class HazardousSubstancesComponent implements OnInit, AfterViewInit {

  // columns to show and the data source
  displayedColumns: string[] = [
    'select',
    'id',
    'substance',
    'hazardous_substance_type',
    'hazardous_substance_unit_type',
    'quantity',
    'max_quantity_allowed',
    'sds_expires_at',
    // 'date_created',
    // 'date_created_UTC',
    'actions'
  ];
  dataSource = new HazardousSubstanceDataSource(this.app, this.oldApi);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    // public service: HazardsService,
    public utils: UtilsService,
    public app: AppService,
    private oldApi: ApiService,
    private api: ApiRequestService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // this.service.get(0, true);
    this.dataSource.getData();
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns by their corresponding date columns.
      if ( this.sort.active == 'date_created_UTC' ) {
        this.dataSource.sort_by = 'date_created';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * Filter the list of hazardous substances by site and/or type.
   */
  onFilter() {
    this.utils.showComponentDialog(HazardousSubstancesFilterComponent, {
      selected_site_ids: this.dataSource.selected_site_ids,
      hazardous_substance_type: this.dataSource.hazardous_substance_type
    }, {
      width: '768px'
    }, (results) => {
      if (typeof results !== 'undefined') {
        // Store the site ids.
        this.dataSource.selected_site_ids = results.site_ids;
        // Store the hazardous substance type.
        this.dataSource.hazardous_substance_type = results.hazardous_substance_type;
        // Refresh the list.
        this.dataSource.getData();
      }
    });
  }

  onClearSearch() {
    this.dataSource.search = '';
    this.dataSource.getData(true);
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove Hazardous Substance',
      'Are you sure you want to remove this hazardous substance?',
      () => {
        this.oldApi.laravelApiRequest(
          'delete',
          'hazardous_substances/' + id,
          {},
          {},
          (response) => {
            this.utils.showToast('The hazardous substance was removed');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  onDeleteSelected() {
    this.utils.showModal(
      'Remove Hazardous Substances',
      'Are you sure you want to remove the selected hazardous substances?',
      () => {
        this.oldApi.laravelApiRequest(
          'delete',
          'hazardous_substances/' +
            this.dataSource.selection.selected.join(','),
          {},
          {},
          (response) => {
            this.utils.showToast(
              'The selected hazardous substances was removed'
            );
            this.dataSource.selection.clear();
            this.dataSource.getData();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  /**
   * Send an API request to export a CSV list of hazardous substances.
   * @param type
   */
  onExportSelected(type: string = 'csv') {
    // Send the download request to the API.
    this.api.makeDownloadRequest(`v1/hazardous_substances/export/${type}` + (this.dataSource.selection.selected.length ? '/' + this.dataSource.selection.selected.join(',') : ''), {}, {
      site_ids: this.dataSource.selected_site_ids.join(','),
      hazardous_substance_type: this.dataSource.hazardous_substance_type
    })
    .then((response) => {
      // IE & Edge should save or open the blob immediately.
      if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
        window.navigator.msSaveOrOpenBlob(response);
        return;
      }

      // Get the current date object.
      const date = new Date();

      // Create object url to handle file downloads.
      const data = window.URL.createObjectURL(response);

      // Create a download link.
      const downloadLink = document.createElement('a');
      downloadLink.href = data;
      downloadLink.download = `hazardous-substances-${date.getFullYear()}${(date.getMonth()+1)}${date.getDate()}.${type}`;
      // Initiate the download.
      downloadLink.click();

      // For FireFox it is necessary to delay revoking the ObjectURL.
      setTimeout(function() {
        window.URL.revokeObjectURL(data);
      }, 300); // Minimum 300 milliseconds.
    })
    .catch((errorResponse) => {
      this.utils.showModal('Error', errorResponse.message);
    });
  }

  onCreateFrom(hazardousSubstance: HazardousSubstanceModel) {
    const newHazardousSubstance: HazardousSubstanceModel = new HazardousSubstanceModel();
    newHazardousSubstance.createFrom(hazardousSubstance);

    this.dialog
      .open(HazardousSubstancesEditDialogComponent, {
        data: {...newHazardousSubstance}
      })
      .afterClosed()
      .subscribe((success) => {
        // if (!success) { return; } // Removed to update list on close
        this.dataSource.getData();
      });
  }

  /**
   * Format the text display for associated sites.
   * Examples:
   *  - Head Office
   *  - 0 Sites
   *  - 1 Site (If name is not accessible otherwise the name of the site)
   *  - 2 Sites
   *  - Etc.
   * @param form
   */
  formatSitesListColumnText(hazardous_substance: HazardousSubstanceModel) {
    // Check if we have one user.
    if ( hazardous_substance.sites_count == 1 ) {
      // Check if we have details of one user.
      if ( hazardous_substance.sites[0] ) {
        return hazardous_substance.sites[0].name;
      }
      // Respond with a generic 1 User response.
      return hazardous_substance.sites_count + ' ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site');
    }
    // Respond with the users count.
    return hazardous_substance.sites_count + ' ' + this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites');
  }

  /**
   * The tooltip text is only used if there is more than 1 site.
   * @param form
   */
  formatSitesListColumnTooltip(hazardous_substance: HazardousSubstanceModel) {
    if ( hazardous_substance.sites_count > 1 ) {
      const site_names: string[] = hazardous_substance.sites.map((site: SiteModel) => {
        return (site.parent_site_id ? '- ' : '') + site.name;
      });
      return site_names.join(`\n`);
    }
    return '';
  }
}

export class HazardousSubstanceDataSource extends CustomDataSource {
  // record sorting and direction
  sort_by = 'substance';
  sort_order = 'asc';

  selected_site_ids: number[] = [];

  hazardous_substance_type: string = '';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI('hazardous_substances', resetOffset, () => {}, {
        site_ids: this.selected_site_ids.join(','),
        hazardous_substance_type: this.hazardous_substance_type
      }
    );
  }
}
