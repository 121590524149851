<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/tools">Tools</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <span class="mr-auto">
      <button mat-raised-button color="primary" appMarginRight (click)="onAdd()" id="add"
              class="margin-right">
        <mat-icon>add</mat-icon>
        Add Tool
      </button>

      <button mat-raised-button  class="mr-2" color="" (click)="onTriggerFileSelector($event)" matTooltip="Click this button to import tools from spreadsheet.">
        <mat-icon>cloud_upload</mat-icon> Import
      </button>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="dataSource.selectAllToggle()">
          <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
          {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
        </button>
        <button *ngIf="dataSource.archived == 'false'" mat-menu-item (click)="onRemoveSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>archive</mat-icon> Archive Selected
        </button>
        <button *ngIf="dataSource.archived == 'false'" mat-menu-item (click)="onExportSelected('csv')">
          <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
        </button>
        <button *ngIf="dataSource.archived == 'true'" mat-menu-item (click)="onRestoreSelected()" [disabled]="!dataSource.selection.hasValue()">
          <mat-icon>restore</mat-icon> Restore Selected
        </button>
      </mat-menu>

      <button *ngIf="dataSource.archived == 'false'" mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect and/or export Tools." style="min-width: 150px">
        <mat-icon>more_vert</mat-icon>
        List Menu/Export
      </button>
      <button *ngIf="dataSource.archived == 'true'" mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Tools." style="min-width: 120px">
        <mat-icon>more_vert</mat-icon>
        List Menu
      </button>
    </span>

    <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" color="" (click)="onFilter()" matTooltip="Click this button to display filter options.">
      <mat-icon>filter_list</mat-icon> Filter
    </button>

    <form tourAnchor="tour.search" class="form-inline my-2 my-lg-0" style="min-width: 325px"
          (ngSubmit)="dataSource.getData(true)">
      <input class="form-control mr-sm-2" type="search"
             [(ngModel)]="dataSource.search"
             name="search"
             aria-label="Search"
             placeholder="Search"
             matTooltip="Search for Tools"/>
      <button mat-raised-button class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0" color="secondary"
              (click)="dataSource.getData(false)"
              type="button"
              matTooltip="Run the current search">
        <mat-icon>search</mat-icon> Search
      </button>
    </form>

  </div>

</nav>

<div class="mat-elevation-z8 scroll-box">
  <table mat-table [dataSource]="dataSource" style="width: 100%" matSort matSortActive="title" matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? dataSource.selectAllToggle() : null"
          [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()" [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? dataSource.selection.toggle(row.id) : null"
          [checked]="dataSource.selection.isSelected(row.id)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        #
      </th>
      <td mat-cell *matCellDef="let tool">
        {{ tool.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Tool
      </th>
      <td mat-cell *matCellDef="let tool" class="no-padding text-wrap">
          {{ tool.title }}
      </td>
    </ng-container>

    <ng-container matColumnDef="industry">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Industry
      </th>
      <td mat-cell *matCellDef="let tool" class="no-padding">
        <span *ngIf="tool?.industry else showAllIndustries">{{ tool.industry.name }}</span>
        <ng-template #showAllIndustries>All</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="expires_at">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Expires At
      </th>
      <td mat-cell *matCellDef="let tool" class="no-padding">
        {{ tool.expires_at ? ((tool.expires_at * 1000) | timezoneConvertMedium) : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="maintenance_safety_checks">
      <th mat-header-cell *matHeaderCellDef class="no-padding">
        Maintenance & Safety Checks
      </th>
      <td mat-cell *matCellDef="let tool" class="no-padding text-wrap" style="max-width: 400px;">
        {{ tool.maintenance_safety_checks.join(', ') }}
      </td>
    </ng-container>

    <ng-container matColumnDef="created_by_user">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Created By
      </th>
      <td mat-cell *matCellDef="let tool" class="no-padding">
        <span *ngIf="tool?.created_by_user else showSystemCreator">
          <a (click)="onUserPublicView(tool.created_by_user.hash)"
             matTooltip="View User Public Profile">
            {{ tool.created_by_user.contact_person }}
          </a>
        </span>
        <ng-template #showSystemCreator>System</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Created At
      </th>
      <td mat-cell *matCellDef="let tool" class="no-padding">
          {{ (tool.date_created * 1000) | timezoneConvertMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date_created_UTC">
      <th mat-header-cell *matHeaderCellDef class="no-padding" mat-sort-header>
        Created At (UTC)
      </th>
      <td mat-cell *matCellDef="let tool" class="no-padding">
          {{ (tool.date_created * 1000) | timezoneConvertUTCMedium }}
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let tool">
        <button *ngIf="dataSource.archived == 'false'" type="button" mat-button (click)="onView(tool.id)" matTooltip="View the tool information." matTooltipPosition="above">
          View
        </button>

        <button *ngIf="dataSource.archived == 'false'" type="button" mat-button (click)="onEdit(tool)" matTooltip="Edit the tool information." matTooltipPosition="above">
          Edit
        </button>

        <button *ngIf="dataSource.archived == 'false'" type="button" mat-button (click)="onRemove(tool)" matTooltip="Remove (Archive) the tool." matTooltipPosition="above">
          Archive
        </button>

        <button *ngIf="dataSource.archived == 'true'" type="button" mat-button (click)="onRestore(tool.id)" matTooltip="Restore this tool." matTooltipPosition="above">
          Restore
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!--import file is triggered from a menu option in the list-->
  <input type="file" (change)="onImport($event)" style="display: none;" #fileInput />

  <mat-paginator [length]="dataSource?.total | async"
                 [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions"
                 showFirstLastButtons>
  </mat-paginator>
</div>
