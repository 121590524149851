<ng-container *ngIf="!(parentObjectType && parentObjectId)">
  <input #fileInput type="file" multiple (change)="onSelectFiles($event)" hidden />
</ng-container>

<button
  mat-raised-button
  class="mr-3"
  type="button"
  color="secondary"
  id="file_browse_button"
  dusk="dusk_file_browse_button"
  [matBadge]="'' + (filesCount > 0 ? filesCount : '')"
  (click)="parentObjectType && parentObjectId ? onManageFiles() : onTriggerFileSelector($event)"
  matTooltip="Select the files you want to upload.&#13;Allowed extensions: doc,pdf,docx,zip,jpg,jpeg,bmp,png,gif,xls,xlsx,eml,&#13;csv,txt,mp3,m4a&#13;Maximum file size allowed (24MB)"
  matTooltipClass="files-tooltip"
  [disabled]="disabled">
  <mat-icon>folder</mat-icon>
  {{ parentObjectType && parentObjectId ? 'Manage Files' : 'Browse Files' }}
</button>
