import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/api.service';
import { AccountConfigurationModel } from 'src/app/models/account-configuration.model';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountConfigurationService {
  private readonly resourceUrl = 'account-configuration';

  constructor(private api: ApiService) {}

  update(
    id: number,
    accountConfig: AccountConfigurationModel
  ): Observable<{}> {
    return this.api.laravelApiObservable(
      'put',
      this.resourceUrl + `/${id}`,
      accountConfig
    );
  }

  find(id: number): Observable<ApiResponseModel<AccountConfigurationModel>> {
    return this.api.laravelApiObservable('get', this.resourceUrl + `/${id}`);
  }
}
