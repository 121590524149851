import {Auditable} from "../../shared/auditable.model";
import {UserModel} from "../../models/user.model";

export class TradeModel extends Auditable {
  id: number;
  name: string;

  /**
   * Who created this Trade?
   */
  created_by_user: UserModel;

  /**
   * Who updated this Trade?
   */
  updated_by_user: UserModel;
}
