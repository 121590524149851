import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../shared/api.service';
import { AuditItemModel } from './audit-item.model';
import { ApiResponseModel } from '../models/api-response.model';

type ResponseItem = ApiResponseModel<AuditItemModel>;
type ResponseItems = ApiResponseModel<AuditItemModel[]>;

@Injectable({
  providedIn: 'root'
})
export class AuditItemsService {
  constructor(private api: ApiService) {}

  post(auditItem: AuditItemModel): Observable<ResponseItem> {
    return this.api.laravelApiObservable('post', 'audit-items', auditItem);
  }

  remove(ids: number[]): Observable<{}> {
    return this.api.laravelApiObservable('delete', `audit-items/${ids}`);
  }

  find(id: number): Observable<ResponseItem> {
    return this.api.laravelApiObservable('get', `audit-items/${id}`);
  }

  get(): Observable<ResponseItems> {
    return this.api.laravelApiObservable('get', 'audit-items');
  }

  put(auditItem: AuditItemModel): Observable<{}> {
    return this.api.laravelApiObservable(
      'put',
      `audit-items/${auditItem.id}`,
      auditItem
    );
  }

  getSections(): Observable<ApiResponseModel<string[]>> {
    return this.api.laravelApiObservable('get', 'audit-items/sections');
  }

  public submit(item: AuditItemModel, files: FileList) {
    const form = new FormData();
    Array.from(files).forEach((file) => form.append(file.name, file));
    form.append('_method', 'PUT');
    return this.api.laravelApiObservable(
      'put',
      `audit-items/submit/${item.id}`,
      form
    );
  }
}
