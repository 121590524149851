import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TaWorkersService } from '../ta-workers.service';

@Component({
  selector: 'app-ta-workers-view',
  templateUrl: './ta-workers-view.component.html',
  styleUrls: ['./ta-workers-view.component.css']
})
export class TaWorkersViewComponent implements OnInit {
  parent_id: number;
  id: number;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public service: TaWorkersService
  ) {}

  ngOnInit() {
    this.parent_id = Number(this.route.snapshot.params['ta_id']);
    this.id = Number(this.route.snapshot.params['worker_id']);

    if (this.id) {
      this.service.get(this.parent_id, this.id);
    } else {
      this.router.navigate(['task-analyses', this.parent_id, 'workers']);
    }
  }
}
