import {Component, Inject, OnInit} from '@angular/core';
import {Location} from "@angular/common";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UtilsService } from 'src/app/shared/utils.service';
import { AccountModel } from 'src/app/models/account.model';
import { AccountsSelectorComponent } from '../../accounts/accounts-selector/accounts-selector.component';

@Component({
  selector: 'app-sites-filter',
  templateUrl: './admin-sites-filter.component.html',
  styleUrls: ['./admin-sites-filter.component.scss']
})
export class AdminSitesFilterComponent implements OnInit {

  selectedAccounts: AccountModel[] = [];

  dataSource = new SitesFilterDataSource(); // No API calls needed

  constructor(
    public location: Location,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AdminSitesFilterComponent>
    // private api: ApiRequestService
  ) {
    // set a default types of nothing if no values are passed through
    if (typeof this.data['filter_type'] === 'undefined') {
      this.data['filter_type'] = "";
    }

    // set a default status of nothing if no values are passed through
    if (typeof this.data['filter_status'] === 'undefined') {
      this.data['filter_status'] = "";
    }

    // set a default accounts of nothing if no values are passed through
    if (typeof this.data['filter_accounts'] === 'undefined') {
      this.data['filter_accounts'] = [];
    }

    // set a default account ids of nothing if no values are passed through
    if (typeof this.data['filter_account_ids'] === 'undefined') {
      this.data['filter_account_ids'] = [];
    }

    // set a default date range of nothing if no values are passed through
    if (typeof this.data['date_filter_range'] === 'undefined') {
      this.data['date_filter_range'] = [];
    }
  }

  ngOnInit() {
    this.dataSource.filter_type = this.data['filter_type'];
    this.dataSource.filter_status = this.data['filter_status'];
    this.dataSource.filter_accounts = this.data['filter_accounts'];
    this.dataSource.filter_account_ids = this.data['filter_account_ids'];
    this.selectedAccounts = this.data['filter_accounts'];
    this.dataSource.date_filter_range = this.data['date_filter_range'];
  }

  onSelectAccounts() {
    this.utils.showComponentDialog(AccountsSelectorComponent, {
      multiple: true,
      returnObjects: true,
      selected: this.selectedAccounts
    }, {
      disableClose: false
    }, (accounts: AccountModel[]) => {
      if ( accounts && accounts.length > 0 ) {
        this.selectedAccounts = accounts;
        this.dataSource.filter_accounts = accounts;
        this.dataSource.filter_account_ids = accounts.map((item: any) => item.id);
      }
    });
  }

  onClearAndClose() {
    this.dataSource.filter_type = "";
    this.dataSource.filter_status = "";
    this.dataSource.filter_accounts = [];
    this.dataSource.filter_account_ids = [];
    this.dataSource.date_filter_range = [];

    this.onSaveAndClose();
  }

  getOutputDateRange($event) {
    this.dataSource.date_filter_range = $event;
  }

  onSaveAndClose() {
    let response: any;

    response = {
      type: this.dataSource.filter_type,
      status: this.dataSource.filter_status,
      accounts: this.dataSource.filter_accounts,
      accountIds: this.dataSource.filter_account_ids,
      date_filter_range: this.dataSource.date_filter_range
    }

    this.dialogRef.close(response);
  }

}

/**
 * the data source class should contain everything needed to get data for the feature.
 */
 export class SitesFilterDataSource {

  filter_type: string = "";
  filter_status: string = "";
  filter_accounts: any[] = [];
  filter_account_ids: any[] = [];
  date_filter_range: Date[] = [];

  constructor() {
    this.filter_type = "";
    this.filter_status = "";
    this.filter_account_ids = [];
    this.date_filter_range = [];
  }

}
