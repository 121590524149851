<div mat-dialog-title>
  <h5>View an Induction</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="container-fluid" appPadding>
  <div class="row">
    <div class="col-12 col-md-6">
      <ng-container *ngIf="induction.name">
        <h6>Induction Name</h6>
        <p>{{ induction.name }}</p>
      </ng-container>
    </div>
    <div class="col-12 col-md-6"></div>

    <div class="col-12" appMarginTop>
      <ng-container *ngIf="induction.description">
        <h6>Introduction</h6>
        <div [innerHTML]="induction.description | sanitizeHtml"></div>
      </ng-container>
    </div>
  </div>
</div>
