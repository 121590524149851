<form #f="ngForm" class="form-container" (ngSubmit)="onSubmit(f)">
  <div class="card">
    <div class="card-body ">
      <div class="container-fluid">
        <div class="row">
          <div class="col form-container">
            <mat-form-field>
              <input
                matInput
                placeholder="Name"
                [(ngModel)]="site.name"
                name="name"
                type="text"
                #name
                required
                id="site_name" />
              <mat-error *ngIf="!name.valid">Please enter a name.</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="!site.id">
              <input
                matInput
                placeholder="Address / Address Lookup"
                [(ngModel)]="site.location"
                name="location"
                id="location"
                #location
                type="text"
                required />
              <mat-error *ngIf="!location.valid">Please enter a physical address.</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-select placeholder="Type" [(ngModel)]="site.site_type" name="site_type" required id="site_type" >
                <mat-option [value]="siteType" *ngFor="let siteType of utils.getSiteTypes()">{{ siteType }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-select
                placeholder="Status"
                [(ngModel)]="site.site_status"
                name="site_status"
                required
                id="site_status">
                <mat-option *ngFor="let siteStatus of utils.getSiteStatuses()" value="{{ siteStatus }}">
                  {{ siteStatus }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>Short description of work:</mat-label>
              <textarea
                matInput
                rows="5"
                placeholder="Enter a short description of work here."
                [(ngModel)]="site.short_description"
                name="short_description"
                type="text"
                id="short_description"
                dusk="dusk_short_description" ></textarea>
              <mat-hint>
                Enter a short description of the work here.
              </mat-hint>
            </mat-form-field>

          </div>

          <div class="col">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Job #"
                [(ngModel)]="site.external_reference"
                name="external_reference"
                type="text"
                id="external_reference" />
            </mat-form-field>

            <div class="row">
              <div class="col-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Opening Time"
                    [(ngModel)]="site.open_time"
                    name="open_time"
                    type="time"
                    id="open_time" />
                </mat-form-field>
              </div>

              <div class="col-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Closing Time"
                    [(ngModel)]="site.close_time"
                    name="close_time"
                    type="time"
                    id="close_time" />
                </mat-form-field>
              </div>
            </div>

            <div class="full-width" appMarginBottom>
              <mat-slide-toggle [(ngModel)]="site.enable_after_hours_notification" name="enable_after_hours_notification">
                Enable after hours notifications.
              </mat-slide-toggle>
            </div>

            <app-timezone-select [timezoneInput]="site.timezone" (timezoneOutput)="getOutputTimezone($event)"></app-timezone-select>

            <div class="row" *ngIf="showProjectStartEndDates(site.site_status)">
              <div class="col-6">
                <mat-form-field class="full-width">
                  <mat-label>Estimated Start Date</mat-label>
                  <input matInput [ngxMatDatetimePicker]="estimatedProjectStartDatePicker" readonly name="estimatedProjectStartDate" [(ngModel)]="estimatedProjectStartDate">
                  <mat-datepicker-toggle matSuffix [for]="estimatedProjectStartDatePicker"></mat-datepicker-toggle>
                  <button type="button" mat-button matSuffix mat-icon-button *ngIf="estimatedProjectStartDate" (click)="estimatedProjectStartDate = null" matTooltip="Remove the estimated start date."><mat-icon>close</mat-icon></button>
                  <ngx-mat-datetime-picker #estimatedProjectStartDatePicker [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
                  <mat-hint>Click the calendar icon to select a date and time.</mat-hint>
                </mat-form-field>
              </div>

              <div class="col-6">
                <mat-form-field class="full-width">
                  <mat-label>Estimated End Date</mat-label>
                  <input matInput [ngxMatDatetimePicker]="estimatedProjectEndDatePicker" readonly name="estimatedProjectEndDate" [(ngModel)]="estimatedProjectEndDate">
                  <mat-datepicker-toggle matSuffix [for]="estimatedProjectEndDatePicker"></mat-datepicker-toggle>
                  <button type="button" mat-button matSuffix mat-icon-button *ngIf="estimatedProjectEndDate" (click)="estimatedProjectEndDate = null" matTooltip="Remove the estimated end date."><mat-icon>close</mat-icon></button>
                  <ngx-mat-datetime-picker #estimatedProjectEndDatePicker [showSeconds]="false" [touchUi]="false" [enableMeridian]="false"></ngx-mat-datetime-picker>
                  <mat-hint>Click the calendar icon to select a date and time.</mat-hint>
                </mat-form-field>
              </div>
            </div>

            <mat-form-field class="full-width mt-3">
              <mat-select
                placeholder="Risk Level"
                [(ngModel)]="site.site_risk_assessment"
                name="site_risk_assessment"
                required
                id="site_risk_assessment">
                <mat-option *ngFor="let site_risk_assessmen of site_risk_assessments" [value]="site_risk_assessmen">
                  {{ site_risk_assessmen | titlecase }}
                </mat-option>
              </mat-select>
              <mat-hint>The risk level will only show when it is enabled in the settings.</mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col">

            <button
              mat-raised-button
              appMarginTop
              [disabled]="!f.valid || inProgress || site.geofence_size > site.max_geofence_size"
              (click)="onSubmit(f)"
              type="button"
              color="primary"
              class="save-button mr-3">
              <mat-icon>save</mat-icon> Save
            </button>

            <button
              mat-raised-button
              (click)="onSelectParentSite()"
              *ngIf="site.parent_site_id"
              type="button"
              color="secondary"
              class="site-select-button mr-3">
              Change {{ utils.getLangTerm('sites.singular', 'Site') }}
            </button>

            <button
              mat-raised-button
              appMarginTop
              appMarginRight
              type="button"
              (click)="selectTags()"
              [matBadge]="site.tag_ids ? site.tag_ids?.length : 0"
              color="secondary"
              matTooltip="Select Tags from the Tags selection list.">
              <mat-icon> local_offer</mat-icon>
              Tags
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</form>
