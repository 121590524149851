<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">Form Templates</span>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button type="button" mat-raised-button matTooltip="Click this button to create a new form." color="primary" id="add" (click)="onEdit()" appMarginRight>
          <mat-icon>add</mat-icon> Add Form
        </button>

        <mat-menu #appMenu="matMenu">
          <button type="button" mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onArchiveSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>archive</mat-icon> Archive Selected
          </button>
          <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onExportFormsData()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>file_download</mat-icon> Export to PDF (Empty)
          </button>
          <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onExportFormStructures()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>file_download</mat-icon> Export Form Structures
          </button>
          <button type="button" *ngIf="dataSource.only_archived == 'false'" mat-menu-item (click)="onTriggerImportFormStructureFileSelector($event)">
            <mat-icon>file_upload</mat-icon> Import Form Structures
          </button>
          <button type="button" *ngIf="dataSource.only_archived == 'true'" mat-menu-item (click)="onRestoreSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>restore</mat-icon> Restore Selected
          </button>
        </mat-menu>

        <button type="button" mat-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to perform list actions." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>
      </span>

      <button type="button" mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="chartsUtils.onOpenMasterCharts('Forms')" matTooltip="Click this button to open the charts.">
        <mat-icon>bar_chart</mat-icon> Charts
      </button>

      <button type="button" mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="onOpenFilters()" matTooltip="Click this button to display filter options for this list.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>

      <form class="form-inline my-2 my-lg-0" (ngSubmit)="dataSource.getData(true)">
        <input class="form-control mr-sm-2" type="search" [(ngModel)]="dataSource.search" name="search" placeholder="Search" aria-label="Search" id="search-input" matTooltip="Search for forms by title." />

        <button mat-raised-button color="secondary" appMarginRight id="search-button" matTooltip="Run the current search">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>
  </nav>

  <app-dynamic-forms-charts-container [baseStorageKey]="'dynamic-forms-templates'"></app-dynamic-forms-charts-container>

  <div class="mat-elevation-z8 scroll-box">

    <mat-paginator #paginator1 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>

      <caption class="px-3">This is a list of all template forms in your account.</caption>

      <!-- Selection Toggle -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef id="th-select">
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()"
            matTooltip="Toggle selection of all forms in the visible list.">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let form">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(form.id) : null"
            [checked]="dataSource.selection.isSelected(form.id)" matTooltip="Toggle selection of this form.">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-id" matTooltip="Toggle sorting by form ID.">
          #
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.id }}
        </td>
      </ng-container>

      <!-- Title -->
      <ng-container matColumnDef="title">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-title" matTooltip="Toggle sorting by form title.">
          Title
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.title }}
        </td>
      </ng-container>

      <!-- Form Type -->
      <ng-container matColumnDef="form_type">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-form_type" matTooltip="Toggle sorting by form type.">
          Form Type
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.form_type | titlecase }}
        </td>
      </ng-container>

      <!-- Is Active -->
      <ng-container matColumnDef="is_active">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-is_active" matTooltip="Toggle sorting by whether the form is active or not.">
          Is Active
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.is_active ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <!-- Is Active -->
      <ng-container matColumnDef="is_template">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-is_template" matTooltip="Toggle sorting by whether the form is a template or not.">
          Is Template
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.is_template ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <!-- Is Recurring -->
      <ng-container matColumnDef="is_recurring">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-is_recurring" matTooltip="Toggle sorting by whether it is a recurring form or not.">
          Is Recurring
        </th>
        <td mat-cell *matCellDef="let form">
          {{ form.is_recurring ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <!-- Date Created -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created" matTooltip="Toggle sorting by creation date.">
          Created On
        </th>
        <td mat-cell *matCellDef="let form">
          {{ (form.date_created * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- Date Created (UTC) -->
      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_created_utc" matTooltip="Toggle sorting by creation date.">
          Created On (UTC)
        </th>
        <td mat-cell *matCellDef="let form">
          {{ (form.date_created * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <!-- Date Modifeid -->
      <ng-container matColumnDef="date_modified">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_modified" matTooltip="Toggle sorting by the date the form was last updated on.">
          Last Updated On
        </th>
        <td mat-cell *matCellDef="let form">
          {{ (form.date_modified * 1000) | timezoneConvertMedium }}
        </td>
      </ng-container>

      <!-- Date Modifeid (UTC) -->
      <ng-container matColumnDef="date_modified_UTC">
        <th mat-header-cell mat-sort-header *matHeaderCellDef id="th-date_modified_utc" matTooltip="Toggle sorting by the date the form was last updated on.">
          Last Updated On (UTC)
        </th>
        <td mat-cell *matCellDef="let form">
          {{ (form.date_modified * 1000) | timezoneConvertUTCMedium }}
        </td>
      </ng-container>

      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef id="th-actions"></th>
        <td mat-cell *matCellDef="let form">

          <ng-container *ngIf="!form.deleted_at else restoreOptions;">
            <button type="button" mat-button (click)="onEdit(form.id)" matTooltip="Edit the form.">
              Edit
            </button>

            <button type="button" mat-button (click)="onClone(form)" matTooltip="Clone the form.">
              Clone
            </button>

            <button type="button" mat-button (click)="onArchive(form, $event)" matTooltip="Archive the form.">
              Archive
            </button>
          </ng-container>

          <ng-template #restoreOptions>
            <button type="button" mat-button (click)="onRestore(form, $event)" matTooltip="Restore the form.">
              Restore
            </button>
          </ng-template>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator #paginator2 [length]="dataSource?.total | async" [pageSize]="dataSource.limit" [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>
</div>

<input type="file" (change)="onImportFormStructures($event)" style="display: none;" #importFormStructureFileInput />
