import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from "../../shared/utils.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {TagModel} from "../../models/tag.model";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-account-tags-view',
  templateUrl: './account-tags-view.component.html',
  styleUrls: ['./account-tags-view.component.scss']
})
export class AccountTagsViewComponent implements OnInit {

  tag: TagModel = new TagModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) private id: number,
    private utils: UtilsService,
    private api: ApiRequestService
  ) { }

  ngOnInit() {
    const tag_id = this.id;
    if(tag_id) {
      this.getTag(tag_id);
    }
  }

  private getTag (tag_id: number) {
    this.api.makeRequest('get', `v2/tags/${tag_id}`)
      .then((response) => {
        this.tag = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

}
