import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HazardousSubstancesEditComponent } from './hazardous-substances-edit/hazardous-substances-edit.component';
import { HazardousSubstancesViewComponent } from './hazardous-substances-view/hazardous-substances-view.component';
import { HazardousSubstancesComponent } from './hazardous-substances/hazardous-substances.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: 'hazardous_substances',
    canActivate: [
      AuthGuard
    ],
    children: [
      { path: '', component: HazardousSubstancesComponent },
      {
        path: ':hazardous_substance_id/edit',
        component: HazardousSubstancesEditComponent
      },
      {
        path: ':hazardous_substance_id/view',
        component: HazardousSubstancesViewComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HazardousSubstancesRoutingModule {}
