<!-- Card View -->
<!--<mat-card *ngFor="let file of files" class="mat-elevation-z8">-->
<!--  <mat-card-header>-->
<!--    <mat-card-title>-->
<!--      <a-->
<!--        [href]="file.url"-->
<!--        target="_blank"-->
<!--        matTooltip="Go to this link in a new tab"-->
<!--      >-->
<!--        {{ file.name | ellipsis:[20] }}-->
<!--        <mat-icon>open_in_new</mat-icon>-->
<!--      </a>-->
<!--    </mat-card-title>-->
<!--    <mat-card-subtitle>-->
<!--      <span *ngIf="file.date_created; else lastModified">-->
<!--        Created: {{ file.date_created * 1000 | date }}-->
<!--      </span>-->
<!--      <ng-template #lastModified>-->
<!--        Last Modified: {{ file.lastModified * 1000 | date }}-->
<!--      </ng-template>-->
<!--    </mat-card-subtitle>-->
<!--  </mat-card-header>-->
<!--  <img-->
<!--    mat-card-image-->
<!--    [src]="file.url"-->
<!--    [alt]="file.name"-->
<!--    *ngIf="isImage(file.name)"-->
<!--  />-->
<!--  <mat-card-actions *ngIf="!view">-->
<!--    <button-->
<!--      mat-icon-button-->
<!--      matTooltip="Delete this file"-->
<!--      (click)="delete(file.id)"-->
<!--      [disabled]="!file.id"-->
<!--      class="float-right"-->
<!--    >-->
<!--      <mat-icon>delete</mat-icon>-->
<!--    </button>-->
<!--  </mat-card-actions>-->
<!--</mat-card>-->

<!-- List View -->
<mat-list>
  <mat-list-item *ngFor="let file of files; last as isLast">
    <img matListAvatar class="rounded-0" style="cursor: pointer;" (click)="onOpenUrl(file.url)" [src]="file.url" [alt]="file.name" *ngIf="isImage(file.name)" />
    <img matListAvatar class="rounded-0" style="cursor: pointer;" (click)="onOpenUrl(file.url)" [alt]="file.name" src="./assets/images/document-placeholder.png" *ngIf="!isImage(file.name)" />
    <div mat-line class="text-wrap" style="cursor: pointer;" (click)="onOpenUrl(file.url)"> {{ file.name }} </div>
    <div mat-line class="text-wrap" style="cursor: pointer;" (click)="onOpenUrl(file.url)"> Created: {{ (file.date_created * 1000) | timezoneConvertMedium }} </div>
    <div mat-line class="text-wrap" style="cursor: pointer;" (click)="onOpenUrl(file.url)"> Last Modified: {{ (file.date_modified * 1000) | timezoneConvertMedium }} </div>

    <!--    Copy URL-->
    <!--          <button-->
    <!--            mat-icon-button-->
    <!--            ngxClipboard-->
    <!--            matTooltip="Copy this file URL"-->
    <!--            [cbContent]="file.url"-->
    <!--            (click)="onCopiedURL()"-->
    <!--            class="float-right"-->
    <!--            style="cursor: pointer;"-->
    <!--          >-->
    <!--            <mat-icon>content_copy</mat-icon>-->
    <!--          </button>-->

    <!--    Open URL-->
    <!--          <button-->
    <!--            mat-icon-button-->
    <!--            matTooltip="View this image"-->
    <!--            (click)="onOpenUrl(file.url)"-->
    <!--            class="float-right"-->
    <!--            style="cursor: pointer;"-->
    <!--          >-->
    <!--            <mat-icon [ngStyle]="{'color': '#F4901E'}">open</mat-icon>-->
    <!--          </button>-->

    <button
      mat-icon-button
      matTooltip="Delete this file"
      (click)="delete(file.id)"
      [disabled]="!file.id"
      *ngIf="!view"
      class="float-right"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <mat-divider class="w-100 m-0" [inset]="true" *ngIf="!isLast"></mat-divider>
  </mat-list-item>
</mat-list>

