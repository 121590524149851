import { Component, OnInit, Input } from '@angular/core';
import { ActionModel } from '../action.model';
import { ActionsService } from '../actions.service';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {UtilsService} from "../../shared/utils.service";

@Component({
  selector: 'app-actions-view',
  templateUrl: './actions-view.component.html',
  styleUrls: ['./actions-view.component.scss']
})
export class ActionsViewComponent implements OnInit {

  @Input() action: ActionModel;

  constructor(
    public utils: UtilsService
  ) {}

  ngOnInit() { }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }
}
