<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/reports/onsite-records">Currently
      Onsite</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="onExport()">
          <mat-icon>cloud_download</mat-icon> Export to CSV
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to export onsite records to CSV." style="min-width: 150px">
        <mat-icon>more_vert</mat-icon>
        List Menu/Export
      </button>

      <span class="mr-auto"></span>

      <span class="spinner-container" *ngIf="(dataSource.loading | async)" appMarginRight></span>

      <button type="button" mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="chartsUtils.onOpenMasterCharts('Access Activities')" matTooltip="Click this button to open the charts.">
        <mat-icon>bar_chart</mat-icon> Charts
      </button>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-2 my-1 float-right float-lg-none" (click)="onFilter()" matTooltip="Click this button to display filter options.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px"
            (ngSubmit)="dataSource.getData(true)">
        <input class="form-control mr-sm-2" type="search"
               [(ngModel)]="dataSource.search" name="search" placeholder="Search"
               aria-label="Search" id="search-input"
               matTooltip="Search for onsite records by any column"/>
        <button mat-raised-button
                class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0"
                color="secondary"
                id="search-button" matTooltip="Run the current search">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>
    </div>

  </nav>

  <app-site-access-activities-charts-container [baseStorageKey]="'site-access-activities-or'"></app-site-access-activities-charts-container>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="desc" matSortDisableClear>

      <!-- Record ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by id.">
          #
        </th>
        <td mat-cell *matCellDef="let onsite_record">
          <b>{{ onsite_record.id }}</b>
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="contact_person">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by full name">
          Full Name
        </th>
        <td mat-cell *matCellDef="let onsite_record">
          <a (click)="onUserPublicView(onsite_record.hash)"
             matTooltip="View User Public Profile">
            <b>{{ onsite_record.contact_person }}</b>
          </a> <br>
          {{ onsite_record.email }} <br>
          {{ onsite_record.mobile }}
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="entity_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by company name">
          Company Name
        </th>
        <td mat-cell *matCellDef="let onsite_record">
          {{ onsite_record.entity_name || '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="site_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by Site">
          {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
        </th>
        <td mat-cell *matCellDef="let onsite_record">
          <ng-container *ngIf="onsite_record.site_id">
            <ng-container *ngIf="onsite_record?.parent_site_id">
              <small style="display: block;">
                <a [routerLink]="utils.prepareSiteRelatedLink(onsite_record?.parent_site_id, null, '/edit/details')">
                  {{ onsite_record?.parent_site_name || '' }}
                </a>
              </small>
            </ng-container>
            <mat-icon *ngIf="onsite_record?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
            <a [routerLink]="utils.prepareSiteRelatedLink(onsite_record?.site_id, onsite_record?.parent_site_id, '/edit/details')">
              {{ onsite_record.site_name || '' }}
            </a>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by signed in time">
          Signed in Time
        </th>
        <td mat-cell *matCellDef="let onsite_record">
          {{ (onsite_record.date_created * 1000) | timezoneConvertLong }}</td>
      </ng-container>

      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by signed in time">
          Signed in Time (UTC)
        </th>
        <td mat-cell *matCellDef="let onsite_record">
          {{ onsite_record.date_created * 1000 | timezoneConvertUTCLong }}</td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by reason">
          Reason for<br> Visit
        </th>
        <td mat-cell *matCellDef="let onsite_record">
          {{ onsite_record.reason || '-' }}</td>
      </ng-container>

      <!-- Method Column -->
      <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by QR Code">
          Initial <br>
          Sign-in Method
        </th>
        <td mat-cell *matCellDef="let onsite_record">
          <span [matTooltip]="onsite_record.device_id">{{ onsite_record.method }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let onsite_record">

          <button type="button" mat-button (click)="composeNewMessage(onsite_record.user_id)" matTooltip="Click this button to send a message to the user.">
            Send Message
          </button>

          <button type="button" mat-button (click)="onTakeOffsite(onsite_record.id)" matTooltip="Sign the user out of the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }}.">
            Sign-out
          </button>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
