import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getAllTimezonesWithUTC } from 'src/app/actions/actions-upload-dialog/actions-upload-dialog.component';

@Component({
  selector: 'app-table-timezone-select',
  templateUrl: './table-timezone-select.component.html',
  styleUrls: ['./table-timezone-select.component.scss']
})
export class TableTimezoneSelectComponent implements OnInit {
  @Input() timezoneInput: string;
  @Output() timezoneOutput: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  hasError(timezone) {
    let result = true;
    // if (!timezone || timezone === '' || timezone === undefined) return false;

    getAllTimezonesWithUTC().forEach((item: any) => {
      if (item.timezone === timezone) {
        result = false;
      }
    })
    return result;
  }

  onOutput($event) {
    this.timezoneOutput.emit($event);
  }

}
