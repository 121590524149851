<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="button-row">
    <button mat-raised-button routerLink="../.." class="margin-right">
      <mat-icon>arrow_back</mat-icon> Inspection/Audit Questions
    </button>

    <button
      mat-raised-button
      class="margin-right"
      *ngIf="auditItem.id"
      routerLink="/audit-items-template/{{ auditItem.id }}/edit">
      <mat-icon>edit</mat-icon>
      Edit
    </button>
  </div>
</nav>

<div class="app-padding">
  <h6>Question</h6>
  <p>{{ auditItem.name }}</p>

  <h6>Section</h6>
  <p>{{ auditItem.section }}</p>
</div>
