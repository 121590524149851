import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { ApiService } from '../../shared/api.service';
import { CustomDataSource } from '../../utils/custom-data-source';
import { FolderModel } from 'src/app/shared/folder.model';
import { FoldersViewComponent } from 'src/app/folders/folders-view/folders-view.component';
import { UtilsService } from 'src/app/shared/utils.service';
import {AccountFoldersTabsComponent} from "../../account/account-folders-tabs/account-folders-tabs.component";
import {MatDialog} from "@angular/material/dialog";
import {ApiRequestService} from "../../shared/api-request.service";

@Component({
  selector: 'app-employees-docs',
  templateUrl: './employees-docs.component.html',
  styleUrls: ['./employees-docs.component.css']
})
export class EmployeesDocsComponent implements OnInit, AfterViewInit {
  // columns to show and the data source
  displayedColumns: string[] = [
    'id',
    'name',
    'folder_type',
    'expiry',
    // 'expiry_UTC',
    'actions'
  ];
  dataSource = new EmployeeDocsDataSource(this.app, this.oldApi);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private oldApi: ApiService,
    private api: ApiRequestService,
    private utils: UtilsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.dataSource.user_id = Number(
      this.route.parent.snapshot.params['user_id']
    );

    if (this.dataSource.user_id) {
      this.dataSource.getData();
    } else {
      this.router.navigate(['employees']);
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns by its corresponding date fields.
      if ( this.sort.active == 'expiry_UTC' ) {
        this.dataSource.sort_by = 'expiry';
      }

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onView(folder: FolderModel) {
    this.dialog
      .open(FoldersViewComponent, {
        width: '750px',
        data: {
          folder: folder,
          is_training_doc: true
        }
      })
      .afterClosed()
      .subscribe((result) => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData(true);
      });
  }

  onEdit(folder: FolderModel) {
    this.dialog
      .open(AccountFoldersTabsComponent, {
        width: '1024px',
        data: {
          folder: folder,
          is_training_doc: true,
          show_document_types_list_for: 'user',
          show_document_visibility: true
        }
      })
      .afterClosed()
      .subscribe((result) => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onRemove(id: number) {
    this.utils.showModal(
      'Remove Document',
      'Are you sure you want to remove this document?',
      () => {
        this.oldApi.laravelApiRequest(
          'delete',
          'folders/' + id,
          {},
          {},
          (response) => {
            this.utils.showToast('The document was removed');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          }
        );
      }
    );
  }

  onAdd() {
    this.onEdit({ user_id: this.dataSource.user_id } as FolderModel);
  }

  onExport(type: string = 'csv') {
    // Get the current date object
    const date = new Date();
    const user_id = this.dataSource.user_id;

    this.api.makeDownloadRequest(`v2/employees-folders/export/${type}/${user_id}`, {}, {
      eager_load: true
    })
      .then((file) => {
        saveAs(file, `${ this.utils.getLangTerm('employees.singular', 'Employee') } Training Documents - ${user_id} - ${this.app.account.name} - ${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}.${type}`);
      });
  }

}

/**
 * the data source class should contain everything needed to get data for the feature.
 * this only applies to lists. forms will use direct calls to the api.
 */
export class EmployeeDocsDataSource extends CustomDataSource {
  user_id = 0;

  // record sorting and direction
  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      'folders/user/' + this.user_id,
      resetOffset,
      () => {},
      { eager_load: true }
    );
  }
}
