import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GitlabIssuesRoutingModule } from './gitlab-issues-routing.module';
import { GitlabIssueComponent } from './gitlab-issue/gitlab-issue.component';
import { GitlabIssuesComponent } from './gitlab-issues.component';
import { MatComponentsModule } from '../mat-components/mat-components.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [
    GitlabIssuesComponent,
    GitlabIssueComponent
  ],
  imports: [
    CommonModule,
    GitlabIssuesRoutingModule,
    MatComponentsModule,
    FormsModule,
    PipesModule
  ]
})
export class GitlabIssuesModule { }
