import { Component, OnInit } from '@angular/core';
import {TaskAnalysesRevisedModel} from "../../models/task-analyses-revised.model";
import {UtilsService} from "../../shared/utils.service";
import {AppService} from "../../app.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-task-analyses-revised-tabs',
  templateUrl: './task-analyses-revised-tabs.component.html',
  styleUrls: ['./task-analyses-revised-tabs.component.scss']
})
export class TaskAnalysesRevisedTabsComponent implements OnInit {

  ta_revised: TaskAnalysesRevisedModel = {} as TaskAnalysesRevisedModel;
  path: string;
  urlPath: string;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiRequestService,
    private route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    // get the ta revised id from the parent route params
    this.ta_revised.id = Number(this.route.parent.snapshot.children[0].params.ta_revised_id);

    this.urlPath = this.route.snapshot.url[0].path;

    if (!this.ta_revised.id) {
      this.redirectBack();
      return;
    }

    if(this.ta_revised.id) {
      this.find(this.ta_revised.id);
    }
  }

  private find(ta_revised_id: number) {
    this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}`, [], {})
      .then((response) => {
        this.ta_revised = response;
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  public onRemove () {
    this.utils.showModal(
      'Remove TA/JSA/SWMS Template',
      `Are you sure you want to remove the "${this.ta_revised.task_name}" TA/JSA/SWMS Template?`,
      () => {
        this.remove([this.ta_revised.id]);
      }
    );
  }

  private remove(ids: number[]) {
    this.api.makeRequest('delete', `v2/task-analyses-revised/${ids.join(',')}`, [], {})
      .then((response) => {
        this.utils.showToast('The TA/JSA/SWMS Templates have been removed.');
        this.redirectBack()
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  private redirectBack() {
    this.router.navigate([`/task-analyses-revised/`]);
  }

}
