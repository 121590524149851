import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppService } from 'src/app/app.service';
import { ApiService } from '../api.service';
import { tap } from 'rxjs/operators';
import { CustomDataSource } from 'src/app/utils/custom-data-source';
import { UtilsService } from '../utils.service';
import { NetworkedAccountsSelectorComponent } from '../networked-accounts-selector/networked-accounts-selector.component';
import { ApiRequestService } from '../api-request.service';
import { UserPublicProfileComponent } from '../user-public-profile/user-public-profile.component';
import { UserModel } from '../../models/user.model';

@Component({
  selector: 'app-networked-users-selector',
  templateUrl: './networked-users-selector.component.html',
  styleUrls: ['./networked-users-selector.component.scss']
})
export class NetworkedUsersSelectorComponent implements OnInit {

  // Columns to show in the view
  displayedColumns: string[] = [
    'accounts.name',
    'users.contact_person',
    'users.email',
    'onsite'
    // 'users.mobile',
    // 'users.iqp_number',
    // 'users.date_created'
  ];

  // The data source
  dataSource: NetworkedUsersSelectorDataSource;

  // The paginator and sorter objects
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // Used to check if objects should be returned instead.
  return_objects: boolean = false;

  selected_objects: UserModel[] = [];

  constructor(
    public app: AppService,
    public api: ApiService,
    public dialogRef: MatDialogRef<NetworkedUsersSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utils: UtilsService,
    public apiRequest: ApiRequestService
  ) {

    // Set multiple if it is not present and default it to false
    if ( typeof this.data['multiple'] === 'undefined' || typeof this.data['multiple'] !== 'boolean' ) {
      this.data['multiple'] = false;
    }

    if ( this.data['multiple'] ) {
      this.displayedColumns.unshift('select');
    } else {
      this.displayedColumns.push('actions');
    }

    // Construct the datasource
    this.dataSource = new NetworkedUsersSelectorDataSource(
      this.app,
      this.api,
      this.data['multiple']
    );

    // Check if we need to return objects or ids.
    if ( typeof this.data['return_objects'] != 'undefined' ) {
      this.return_objects = this.data['return_objects'];
    }

    // Set a default selection if no selected values are passed through
    if (typeof this.data['selected'] === 'undefined' || (this.data['selected'].length == 1 && !this.data['selected'][0])) {
      this.data['selected'] = [];
    }

    // Loop and select the values in the data source
    for (let i = 0; i < this.data['selected'].length; i++) {
      this.dataSource.selection.select(this.data['selected'][i]);
    }

    // Check if we have selected objects to work with.
    if ( typeof this.data['selected_objects'] !== 'undefined' ) {
      this.selected_objects = this.data['selected_objects'];
    }

    // set a selected account id if passed through
    if ( this.data['selectedAccountId'] ) {
      this.dataSource.selectedAccountId = this.data['selectedAccountId'];
    }

    // set visitor site id if passed through
    if ( this.data['visitor_site_id'] ) {
      this.dataSource.visitor_site_id = this.data['visitor_site_id'];
    }

    // set visitors from all sites if passed through
    if ( this.data['visitors_from_all_sites'] && typeof this.data['visitors_from_all_sites'] === 'boolean') {
      this.dataSource.visitors_from_all_sites = this.data['visitors_from_all_sites'];
    }

    // Get the data
    this.dataSource.getData();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onSelectSingle(id: number) {
    this.dialogRef.close(id);
  }

  /**
   * Selects a single object.
   *
   * @param {UserModel} user - The user object that will be selected.
   * @return {void}
   */
  onSelectSingleObject(user: UserModel): void {
    // Close the dialog and return the user object.
    this.dialogRef.close({
      id: user.id,
      contact_person: user.contact_person
    } as UserModel);
  }

  onDeselectSingle() {
    this.dataSource.selection.clear();
    this.dialogRef.close(0);
  }

  /**
   * Handles the selection of multiple items.
   *
   * @return {void} - Does not return anything.
   */
  onSelectMultiple(): void {
    if ( this.return_objects ) {
      // Return the objects.
      this.dialogRef.close(this.selected_objects);
    } else {
      // Return the selected ids.
      this.dialogRef.close(this.dataSource.selection.selected);
    }
  }

  /**
   * Function to toggle row selection.
   *
   * @param {any} row - The row object to be toggled.
   * @return {void}
   */
  onToggleRowSelection(row: any): void {
    // Toggle the default row selection.
    this.dataSource.selection.toggle(row.id);
    // Check if objects should be returned.
    if ( this.return_objects ) {
      // Get the index of the selected object. The index will be -1 if it is not present.
      const index: number = this.selected_objects.findIndex(obj => obj.id === row.id);
      // Check if the row is selected and the index is not present.
      if ( this.dataSource.selection.isSelected(row.id) && index == -1 ) {
        // Add the row to the array.
        this.selected_objects.push(row);
      } else {
        // The row is not selected. Check if an object was selected.
        if (index > -1) {
          // Remove the object if it was selected.
          this.selected_objects.splice(index, 1);
        }
      }
    }
  }

  onSelectAllRecords() {
    this.apiRequest.makeRequest('get', `v1/account/${this.app.account.id}/all-networked-userids`, {}, {
      search: this.dataSource.search,
      visitor_site_id: this.dataSource.visitor_site_id,
      visitors_from_all_sites: this.dataSource.visitors_from_all_sites
    })
    .then((res) => {
      this.dialogRef.close(res);
    });
  }

  onDeselectAllRecords() {
    this.dataSource.selection.clear();
  }

  onChangeSelectedAccount() {
    this.utils.showComponentDialog(
      NetworkedAccountsSelectorComponent,
      {
        selected: [this.dataSource.selectedAccountId]
      },
      {
        width: '1024px'
      },
      (result: any) => {
        if (typeof result !== 'undefined') {
          this.dataSource.selectedAccountId = result;
          this.dataSource.getData(true);
        }
      }
    );
  }

  onUserPublicView(hash: string, event: Event) {
    // Prevent the default events and propagation from triggering.
    event.preventDefault();
    event.stopPropagation();

    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }
}

/**
 * The networked accounts selector data source.
 */
export class NetworkedUsersSelectorDataSource extends CustomDataSource {

  sort_by = 'accounts.name';
  sort_order = 'asc';

  selectedAccountId: number;
  visitor_site_id: number;
  visitors_from_all_sites: boolean;

  constructor (
    public app: AppService,
    public api: ApiService,
    public multiple: boolean = true,
    public data: any = {}
  ) {
    super(app, api, multiple, data);

    // Start with own account
    this.selectedAccountId = app.account.id;
    this.visitor_site_id = null;
    this.visitors_from_all_sites = false;
  }

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(`account/${this.selectedAccountId}/networked-users`, resetOffset, () => {}, {
      visitor_site_id: this.visitor_site_id,
      visitors_from_all_sites: this.visitors_from_all_sites
    });
  }
}
