<div mat-dialog-title>
  <h5>{{ utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') }}</h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <mat-form-field>
      <input matInput [placeholder]="utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk')" [(ngModel)]="toolbox_talk.name"
        name="name" type="text" #name required />
      <mat-error *ngIf="!name.valid">Please enter the name of the {{ utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') }}.
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <textarea matInput placeholder="Agenda" [(ngModel)]="toolbox_talk.agenda"
        name="agenda" type="text" rows="10" #agenda></textarea>
      <mat-error *ngIf="!agenda.valid">Please enter the agenda or minutes of the meeting.
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="toolbox_talk.users.length">
      <input matInput [value]="formatUsers(toolbox_talk.users)"
        placeholder="Attendees" name="attendees" disabled />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>

  <button (click)="onSubmit(f)" mat-raised-button
    [disabled]="!f.valid || inProgress" color="primary" [disabled]="!f.valid"
    type="button" class="" appMarginRight>
    <mat-icon>save</mat-icon>
    {{ toolbox_talk.id ? 'Update' : 'Save' }}
  </button>

  <app-files-selector (selected)="newFiles = $event" [selectedFilesCount]="newFiles?.length" appMarginRight>
  </app-files-selector>

  <button mat-raised-button (click)="onRemoveFiles()" color="secondary"
    [matBadge]="'' + (toolbox_talk?.files.length)"
    [disabled]="!toolbox_talk?.files.length" type="button" class="remove-files"
    id="remove-files">
    <mat-icon>open_in_browser</mat-icon>
    Manage Files
  </button>

  <button mat-raised-button (click)="onSelectUsers()" color="secondary"
    [matBadge]="'' + (toolbox_talk?.user_ids.length)" type="button">
    <mat-icon>people</mat-icon>
    Attendees
  </button>

  <button mat-raised-button (click)="onExport()" color="secondary" type="button" *ngIf="toolbox_talk.id && toolbox_talk.site_id">
    <mat-icon>cloud_download</mat-icon>
    Export
  </button>
</mat-dialog-actions>
