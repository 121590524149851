import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessRecordsComponent } from './access-records/access-records.component';
import { OnsiteRecordsComponent } from './onsite-records/onsite-records.component';
import { TimeOnsiteRecordsComponent } from './time-onsite-records/time-onsite-records.component';
import { AuthGuard } from '../guards/auth.guard';
import { PandemicRecordsComponent } from './pandemic-records/pandemic-records.component';

const routes: Routes = [
  { 
    path: 'reports/access-records', 
    component: AccessRecordsComponent,
    canActivate: [
      AuthGuard
    ] 
  },
  { 
    path: 'reports/onsite-records', 
    component: OnsiteRecordsComponent,
    canActivate: [
      AuthGuard
    ] 
  },
  {
    path: 'reports/time-onsite-records',
    component: TimeOnsiteRecordsComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'reports/pandemic-records',
    component: PandemicRecordsComponent,
    canActivate: [
      AuthGuard
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule {}
