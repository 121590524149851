import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../shared/api.service';

@Injectable({
  providedIn: 'root'
})
export class SitesCodeService {
  private resourceUrl = environment.LARAVEL_API_URL + 'sites';

  constructor(private api: ApiService) {}

  claimCode(siteId: number, paperId: string) {
    return this.api.laravelApiObservable(
      'patch',
      this.resourceUrl + `/${siteId}/qrcodes/claim`,
      { paper_id: paperId }
    );
  }
}
