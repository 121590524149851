import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sites-importer',
  templateUrl: './sites-importer.component.html',
  styleUrls: ['./sites-importer.component.css']
})
export class SitesImporterComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
