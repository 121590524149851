import {UserModel} from "./user.model";
import {Auditable} from "../shared/auditable.model";

export class TagModel extends Auditable {
  id: number;
  title: string = '';
  description: string = '';

  account_id: number = 0;

  /**
   * Who created this Tool?
   */
  created_by_user: UserModel;

  /**
   * Who updated this Tool?
   */
  updated_by_user: UserModel;
}
