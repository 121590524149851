import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMarginBottom]'
})
export class MarginBottomDirective {
  constructor(element: ElementRef) {
    element.nativeElement.style.marginBottom = '16px';
  }
}
