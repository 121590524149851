<mat-dialog-content class="my-3 mx-0 py-0 pr-3 pl-0">
    <form #form="ngForm" (ngSubmit)="onSubmit()" class="card-body" (keydown.enter)="$event.preventDefault()">

      <mat-form-field class="mb-1">
        <input
          matInput
          placeholder="Name"
          #name
          id="name"
          [(ngModel)]="folder.name"
          name="name"
          type="text"
          required/>
        <mat-error *ngIf="!name.valid">Please enter a Folder name.</mat-error>
      </mat-form-field>

      <mat-form-field class="mb-1">
        <mat-select placeholder="Document Type" [(ngModel)]="folder.folder_type" name="type" required id="type">
          <mat-option *ngFor="let document_type of documentTypes" [value]="document_type">{{ document_type }}</mat-option>
        </mat-select>
        <mat-error *ngIf="!folder.folder_type">
          Please select the type of document.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="mb-1">
        <input
          matInput
          placeholder="Document Number"
          id="folder_number"
          [(ngModel)]="folder.folder_number"
          name="folder_number"
          type="text"/>
      </mat-form-field>

      <app-timezone-select [timezoneInput]="selectedTimezone" (timezoneOutput)="getOutputTimezone($event)"></app-timezone-select>

      <mat-form-field class="mb-1">
        <input
          matInput
          [matDatepicker]="picker"
          placeholder="Expiry Date"
          readonly
          (click)="picker.open()"
          [(ngModel)]="date"
          name="date"
          id="date"
          [min]="now"/>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
      </mat-form-field>

      <mat-form-field *ngIf="show_document_visibility">
        <mat-select placeholder="Document Visibility" [(ngModel)]="folder.visibility" name="visibility" required id="visibility">
          <mat-option value="Private">Private - Only viewable to the account it belongs to</mat-option>
          <mat-option value="Group">Group - Administrators and person they belong to</mat-option>
          <mat-option value="Public">Public - Everyone and person they belong to</mat-option>
        </mat-select>
        <mat-error *ngIf="!folder.visibility">
          Please select the level of privacy.
        </mat-error>
      </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions style="margin: 0">
  <button
    mat-raised-button
    [disabled]="!form.valid || inProgress"
    color="primary"
    (click)="onSubmit()"
    appMarginRight>
    <mat-icon id="save">save</mat-icon>
    Save
  </button>

  <app-files-selector
    (selected)="newFiles = $event"
    [selectedFilesCount]="newFiles?.length"
    appMarginRight>
  </app-files-selector>

  <button
    mat-raised-button
    (click)="onRemoveFiles()"
    color="secondary"
    [matBadge]="'' + (folder?.files?.length || '')"
    [disabled]="!folder.files?.length">
    <mat-icon>open_in_browser</mat-icon>
    Manage Files
  </button>

  <button
    mat-raised-button
    (click)="onSelectSites()"
    color="secondary"
    [matBadge]="'' + (isAllSelected ? 'All' : (folder?.sites?.length || ''))"
    *ngIf="!folder.user_id">
    <mat-icon>location_on</mat-icon>
    Add {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
  </button>

  <span class="mr-auto"></span>

  <button
    type="button"
    mat-icon-button
    [disabled]="!folder.id"
    class="float-right"
    (click)="delete()">
    <mat-icon>delete</mat-icon>
  </button>
</mat-dialog-actions>
