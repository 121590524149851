import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { NgForm } from '@angular/forms';
import { ApiRequestService } from 'src/app/shared/api-request.service';

declare var tinymce: any;

@Component({
  selector: 'app-account-pandemic',
  templateUrl: './account-pandemic.component.html',
  styleUrls: ['./account-pandemic.component.scss']
})
export class AccountPandemicComponent implements OnInit {

  inProgress: boolean = false;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.api.makeRequest('get', 'v1/account')
    .then((response: any) => {
      this.app.account.apply(response.data);
    });
  }

  ngAfterViewInit() {
    tinymce.execCommand('mceAddControl', false, 'pandemic_sign_in_declaration');
    tinymce.execCommand('mceAddControl', false, 'pandemic_sign_out_declaration');
  }

  onSubmit(form: NgForm) {
    if ( !form.valid ) {

      return;
    }
    // Send the updates to the server
    this.api.makeRequest('put', `v1/account/${this.app.account.id}`, this.app.account)
    .then((response: any) => {
      this.utils.showToast('The settings for the account and Covid-19 feature is updated.');
    });
  }

}
