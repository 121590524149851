import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { CodeModel } from 'src/app/models/code.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-codes-view',
  templateUrl: './sites-codes-view.component.html',
  styleUrls: ['./sites-codes-view.component.scss']
})
export class SitesCodesViewComponent implements OnInit {

  parent_id: number;
  child_id: number;
  site_id: number;

  code: CodeModel = new CodeModel();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private oldApi: ApiService,
    public utils: UtilsService,
    private api: ApiRequestService
  ) {}

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      this.code.id = Number(this.route.snapshot.params['code_id']);
      if ( !this.code.id ) {
        this.router.navigate([this.utils.prepareSiteRelatedLink(this.site_id, this.child_id ? this.parent_id : null, 'edit/codes')]);
        return;
      }

      this.oldApi.laravelApiRequest(
        'get',
        'sites/' + this.site_id + '/qrcodes/' + this.code.id,
        {},
        {},
        (response) => {
          this.code.apply(response.data);
        }
      );
    }

  }

  onRemove() {
    this.utils.showModal(
      'Remove QR Code',
      'Are you sure you want to remove this QR code?',
      () => {
        this.oldApi.laravelApiRequest(
          'delete',
          'sites/' + this.site_id + '/qrcodes/' + this.code.id,
          {},
          {},
          () => {
            this.utils.showToast('The QR code was removed.');
            this.router.navigate([this.utils.prepareSiteRelatedLink(this.site_id, this.child_id ? this.parent_id : null, 'edit/codes')]);
          }
        );
      }
    );
  }

}
