import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../app.service";
import {UtilsService} from "../utils.service";
import {tap} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ApiDataSource} from "../../utils/api-data-source";
import {ApiRequestService} from "../api-request.service";

@Component({
  selector: 'app-user-public-profile-sites-visited',
  templateUrl: './user-public-profile-sites-visited.component.html',
  styleUrls: ['./user-public-profile-sites-visited.component.scss']
})
export class UserPublicProfileSitesVisitedComponent implements OnInit {

  // The user hash that will be used to get the info.
  @Input() hash: string;

  // Used to determine if admin links should be included or not.
  @Input() includeAdminActions: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // The datasource object for list data.
  dataSource = new UserPublicProfileSitesVisitedDataSource(this.app, this.api);

  // The columns to display.
  displayedColumns: string[] = [
    'id',
    'owner',
    'name',
    'location'
  ];

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    // Store the hash.
    this.dataSource.hash = this.hash;
    // Do not load data if the hash is missing.
    if ( this.dataSource.hash ) {
      this.dataSource.getData(true);
    }
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData(false); // TBD: Need to add sorting to the API endpoint.
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }
}

export class UserPublicProfileSitesVisitedDataSource extends ApiDataSource {
  // The user hash.
  hash: string = '';

  // Sorting variables.
  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset = false) {
    // Do not load data if the hash is missing.
    if ( this.hash ) {
      this.makeRequest(`v2/user/${this.hash}/sites-visited`, resetOffset);
    }
  }
}
