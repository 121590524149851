import { Component, OnInit } from '@angular/core';
import {EmployeeService} from "../employee.service";

@Component({
  selector: 'app-employees-sites-worked-on',
  templateUrl: './employees-sites-worked-on.component.html',
  styleUrls: ['./employees-sites-worked-on.component.scss']
})
export class EmployeesSitesWorkedOnComponent implements OnInit {

  constructor(
    public employeeService: EmployeeService
  ) {}

  ngOnInit() {}
}
