import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {Location} from "@angular/common";
import {UtilsService} from "../../shared/utils.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';

@Component({
  selector: 'app-multi-account-dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.scss']
})
export class DashboardFilterComponent implements OnInit {

  // Define the filter options.
  filter_options: {
    site_ids: number[],
    date_range: Date[]
  } = {
    site_ids: [],
    date_range: []
  };

  constructor(
    private app: AppService,
    public location: Location,
    public utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DashboardFilterComponent>
  ) {

    // set a default type of nothing if no values are passed through
    if (typeof this.data['site_ids'] == 'undefined') {
      this.data['site_ids'] = [];
    }

    // Set a default date range if it was not defined.
    if (typeof this.data['date_range'] == 'undefined') {
      this.data['date_range'] = [];
    }
  }

  ngOnInit() {
    // Set the site ids and date range.
    this.filter_options.site_ids = this.data['site_ids'];
    this.filter_options.date_range = this.data['date_range'];

    // Remap the dates as new Date objects. Storing it in storage stores it as string.
    this.filter_options.date_range = this.filter_options.date_range.map((date: Date|string) => new Date(date));
  }

  /**
   * Open the sites' selector.
   */
  onSelectSites() {
    this.utils.showComponentDialog(SitesSelectorComponent, {
      multiple: true,
      selected: this.filter_options.site_ids
    })
    .then((response: number[]) => {
      // The response is returning either an array of selected ids, undefined or an empty string.
      // Discard response if it is undefined or an empty string.
      if ( typeof response != 'undefined' && response ) {
        this.filter_options.site_ids = response;
      }
    });
  }

  /**
   * Updates the date range filter for the output.
   *
   * @param {Date[]} date_range - An array of Date objects representing the new date range.
   *
   * @return {void}
   */
  getOutputDateRange(date_range: Date[]): void {
    // Update the date range filter.
    this.filter_options.date_range = date_range;
  }

  /**
   * Clear the filter options and send the response back.
   */
  onClearAndClose() {
    // Clear all filter options.
    this.filter_options.site_ids = [];
    this.filter_options.date_range = [];

    this.onSaveAndClose();
  }

  /**
   * Close the dialog and send the selected options as the response.
   */
  onSaveAndClose() {
    this.dialogRef.close(this.filter_options);
  }
}
