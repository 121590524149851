import { FileModel } from '../shared/file.model';
import { Auditable } from '../shared/auditable.model';
import {SiteModel} from "./site.model";
import {PpeModel} from "../admin/ppe/ppe.model";

export class HazardousSubstanceModel extends Auditable {

  id: number = 0;
  date_identified: number = null;
  substance: string = '';
  has_safety_data_sheets: boolean = false;
  safety_data_sheets_url: string = '';
  risk_info: string = '';
  ira_likelihood: number = 2;
  ira_severity: number = 2;
  ppe_required: boolean = false;
  additional_info: string = '';
  rra_likelihood: number = 2;
  rra_severity: number = 2;
  storage_requirements: string = '';
  un_classification: string = '';
  hsno_classification: string = '';
  hsno_approval_number: string = '';
  hazardous_substance_type: string = 'Liquid';
  hazardous_substance_unit_type: string = 'L';
  sds_expires_at: number = null;

  files: FileModel[] = [];

  // the selected list of sites this hazard is attached to
  site_ids: number[] = [];
  link_to_all_sites = false;

  /**
   * The Hazardous Substance ID from which the new Hazardous Substance is created.
   */
  create_from_id: number = 0;

  sites: SiteModel[] = [];
  sites_count: number = 0;

  ppe: PpeModel[] = [];
  ppe_ids: number[] = [];

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          this[key] = data[key];
        }
      });
    }
    return this;
  }

  reset() {
    this.id = 0;
    this.date_identified = null;
    this.substance = '';
    this.has_safety_data_sheets = false;
    this.safety_data_sheets_url = '';
    this.risk_info = '';
    this.ira_likelihood = 2;
    this.ira_severity = 2;
    this.ppe_required = false;
    this.additional_info = '';
    this.rra_likelihood = 2;
    this.rra_severity = 2;
    this.storage_requirements = '';
    this.un_classification = '';
    this.hsno_classification = '';
    this.hazardous_substance_type = 'Liquid';
    this.hazardous_substance_unit_type = 'L';
    this.site_ids.length = 0;
    this.link_to_all_sites = false;
    this.files = [];
  }

  /**
   * Create a new Hazardous Substance from an existing one.
   * The create_from_id is used to copy files e.g. SDS.
   * @param data
   */
  createFrom(data: HazardousSubstanceModel) {
    // Store the "create from" id.
    this.create_from_id = data.id;
    // Copy the rest of the info.
    this.substance = data.substance;
    this.has_safety_data_sheets = data.has_safety_data_sheets;
    this.safety_data_sheets_url = data.safety_data_sheets_url;
    this.risk_info = data.risk_info;
    this.ira_likelihood = data.ira_likelihood;
    this.ira_severity = data.ira_severity;
    this.ppe_required = data.ppe_required;
    this.additional_info = data.additional_info;
    this.rra_likelihood = data.rra_likelihood;
    this.rra_severity = data.rra_severity;
    this.storage_requirements = data.storage_requirements;
    this.un_classification = data.un_classification;
    this.hsno_classification = data.hsno_classification;
    this.hazardous_substance_type = data.hazardous_substance_type;
    this.hazardous_substance_unit_type = data.hazardous_substance_unit_type;
    this.sds_expires_at = data.sds_expires_at;
    // Copy the PPE IDs.
    this.ppe_ids = data.ppe_ids || [];
  }

}
