import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { Message } from 'src/app/models/message.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';

@Component({
  selector: 'app-message-view',
  templateUrl: './message-view.component.html',
  styleUrls: ['./message-view.component.scss']
})
export class MessageViewComponent implements OnInit {

  message: Message = new Message();

  userListHeaders: string[] = [
    'contact_person',
    'employer',
    'is_read'
  ];
  userListDataSource = new MessageUsersDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;

  constructor(
    public app: AppService,
    public api: ApiRequestService,
    public utils: UtilsService,
    private dialogRef: MatDialogRef<MessageViewComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit() {
    // Get the message id
    const messageId = Number(this.dialogData['messageId']);
    // Validate and make a request to get the message data
    if ( !messageId ) {
      this.utils.showToast('We could not load the message. Please refresh the page and try again.');
      this.dialogRef.close();
      return;
    }

    this.api.makeRequest('get', `v2/messages/${messageId}`)
    .then((message: Message) => {
      this.message.apply(message);
      this.userListDataSource.messageId = this.message.id;
      this.userListDataSource.getData(true);
    })
    .catch(() => {
      this.utils.showToast('The message could not be opened.');
      this.dialogRef.close();
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.userListDataSource.limit = paginator['pageSize'];
      this.userListDataSource.offset = paginator['pageIndex'];
      this.userListDataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();
  }

  onOpenUrl(url: string) {
    if ( !url ) {
      return;
    }
    this.utils.openUrl(url);
  }

}

export class MessageUsersDataSource extends ApiDataSource {

  messageId: number = 0;

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/messages/${this.messageId}/users`, resetOffset);
  }
}
