import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatComponentsModule } from '../mat-components/mat-components.module';
import { FormsModule } from '@angular/forms';
import { FileBrowseButtonComponent } from './file-browse-button/file-browse-button.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { ClipboardModule } from 'ngx-clipboard';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  entryComponents: [
    FileManagerComponent
  ],
  declarations: [
    FileBrowseButtonComponent,
    FileManagerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatComponentsModule,
    ClipboardModule,
    PipesModule
  ],
  exports: [
    FileBrowseButtonComponent,
    FileManagerComponent
  ]
})
export class UtilsModule { }
