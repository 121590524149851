import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ErpsEditDialogComponent } from 'src/app/erps/erps-edit-dialog/erps-edit-dialog.component';
import { ErpsSelectorComponent } from 'src/app/erps/erps-selector/erps-selector.component';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { CustomDataSource } from 'src/app/utils/custom-data-source';

@Component({
  selector: 'app-sites-erps',
  templateUrl: './sites-erps.component.html',
  styleUrls: ['./sites-erps.component.scss']
})
export class SitesErpsComponent implements OnInit, AfterViewInit {

  parent_id: number;
  child_id: number;
  site_id: number;

  // columns to show and the data source
  displayedColumns: string[] = ['select', 'name', 'actions'];
  dataSource: SiteERPsDataSource;

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public utils: UtilsService,
    public app: AppService,
    private api: ApiService,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;

      // init the data source
      this.dataSource = new SiteERPsDataSource(this.app, this.api, true, {
        site_id: this.site_id
      });

      // get the data
      this.dataSource.getData(true);
    }
  }

  ngAfterViewInit() {
    // reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      this.dataSource.getData();
    });

    // subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onEdit(erp_id: number | string) {
    // open the hazard edit dialog for editing
    this.utils.showComponentDialog(
      ErpsEditDialogComponent,
      {
        site_id: this.site_id,
        erp_id: erp_id
      },
      {},
      (response) => {
        // response is not used

        this.dataSource.getData(true);
      }
    );
  }

  /**
   * @param id the id of the erp.
   * Remove an ERP.
   */
  onRemove(id: number) {
    this.utils.showModal(
      'Delete ERP',
      'Are you sure you want to delete this emergency response plan?',
      () => {
        this.onRemoveERPs([id], (response) => {
          this.dataSource.selection.deselect(id);
        });
      }
    );
  }

  /**
   * Remove selected ERPs from the specified site
   */
  onDeleteSelected() {
    this.utils.showModal(
      'Delete Selected ERPs',
      'Are you sure you want to delete the selected emergency response plans?',
      () => {
        this.onRemoveERPs(this.dataSource.selection.selected, (response) => {
          this.dataSource.selection.clear();
        });
      }
    );
  }

  /**
   * open a dialog allowing the user to select ERPs to be linked to sites.
   */
  onSelectERPs() {
    // show the hazard selector
    this.utils.showComponentDialog(
      ErpsSelectorComponent,
      {
        multiple: true
      },
      {},
      (results) => {
        if (typeof results !== 'undefined') {
          this.copyERPsToSite(results);
        }
      }
    );
  }

  copyERPsToSite(selected_erp_ids) {
    this.api.laravelApiRequest(
      'put',
      'sites/' + this.site_id + '/erps',
      {
        selected_erp_ids: selected_erp_ids
      },
      {},
      (response) => {
        this.dataSource.getData(true);
        this.utils.showToast('ERP templates copied to site.');
      },
      (error) => {
        this.utils.showModal('Error', error.message);
      }
    );
  }

  onRemoveERPs(hazard_ids: number[], callback?: any) {
    if (this.site_id) {
      // Remove ERPs.
      this.api.laravelApiRequest(
        'delete',
        'erps/' + hazard_ids.join(',') + '?site_id=' + this.site_id,
        {},
        {},
        (response) => {
          // reload the data and reset the offset
          this.dataSource.getData(true);

          if (typeof callback === 'function') {
            callback(response);
          }
        },
        (error) => {
          this.utils.showModal('Error', error.message);
        }
      );
    }
  }
}

export class SiteERPsDataSource extends CustomDataSource {
  // record sorting and direction
  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset: boolean = false) {
    this.getDataFromLaravelAPI(
      'sites/' + this['site_id'] + '/erps',
      resetOffset
    );
  }
}
