import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErpTeamsService } from '../erp-teams.service';

@Component({
  selector: 'app-erp-teams-edit',
  templateUrl: './erp-teams-edit.component.html',
  styleUrls: ['./erp-teams-edit.component.css']
})
export class ErpTeamsEditComponent implements OnInit {
  parent_id: number;
  id: number;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public service: ErpTeamsService
  ) {}

  ngOnInit() {
    this.parent_id = Number(this.route.snapshot.params['erp_id']);
    this.id = Number(this.route.snapshot.params['member_id']);

    if (this.id) {
      this.service.get(this.parent_id, this.id);
    }
  }

  onSubmit(form: NgForm) {
    // make sure the form data is valid
    if (form.valid) {
      // check if the site should be created or updated
      if (this.id) {
        // update the site
        this.service.update(this.parent_id);
      } else {
        // create the site
        this.service.add(this.parent_id, (response) => {
          // and navigate back to the list of sites
          this.router.navigate(['/erps', this.parent_id, 'teams']);
        });
      }
    } else {
      alert('Please make sure you enter all fields with valid information.');
    }
  }
}
