import { SiteModel } from './site.model';
import { FileModel } from '../shared/file.model';
import { UserModel } from './user.model';
import { IncidentCauseModel } from '../incidents/incident-cause.model';
import { Auditable } from '../shared/auditable.model';

export class IncidentLtiModel extends Auditable {

  incident_lti_id: number = 0;
  incident_id: number = 0;
  affected_user_id: number = 0;
  affected_user_contact_person: string = '';
  affected_user_email: string = '';
  affected_user_phone: string = '';
  health_provider_name: string = '';
  health_provider_contact_person: string = '';
  health_provider_email: string = '';
  health_provider_phone: string = '';
  health_provider_description: string = '';
  work_status: string = '';
  work_status_days: number = 0;
  acc_case_manager_office_name: string = '';
  acc_case_manager_contact_person: string = '';
  acc_case_manager_email: string = '';
  acc_case_manager_phone: string = '';
  acc_case_manager_description: string = '';
  return_to_work_estimated_date: number = 0;
  return_to_work_actual_date: number = 0;
  return_to_work_description: string = '';
  actual_lti_days: number = 0;
  actual_lti_hours: number = 0;
  deleted: boolean = false;
  date_created: number = 0;
  date_modified: number = 0;
  deleted_at: number = 0;
  created_by: number = 0;
  updated_by: number = 0;
  deleted_by: number = 0;

  files: FileModel[] = [];

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        if (key in this) {
          switch ( key ) {
            case 'files':
              this[key].length = 0;
              data[key].forEach((file: FileModel) => {
                this[key].push(file);
              });
              break;
            default:
              this[key] = data[key];
          }
        }
      });
    }
    return this;
  }

  reset() {
    this.incident_lti_id = 0;
    this.incident_id = 0;
    this.affected_user_id = 0;
    this.affected_user_contact_person = '';
    this.affected_user_email = '';
    this.affected_user_phone = '';
    this.health_provider_name = '';
    this.health_provider_contact_person = '';
    this.health_provider_email = '';
    this.health_provider_phone = '';
    this.health_provider_description = '';
    this.work_status = '';
    this.work_status_days = 0;
    this.acc_case_manager_office_name = '';
    this.acc_case_manager_contact_person = '';
    this.acc_case_manager_email = '';
    this.acc_case_manager_phone = '';
    this.acc_case_manager_description = '';
    this.return_to_work_estimated_date = 0;
    this.return_to_work_actual_date = 0;
    this.return_to_work_description = '';
    this.actual_lti_days = 0;
    this.actual_lti_hours = 0;
    this.deleted = false;
    this.date_created = 0;
    this.date_modified = 0;
    this.deleted_at = 0;
    this.created_by = 0;
    this.updated_by = 0;
    this.deleted_by = 0;
  }
}
