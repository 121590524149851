<div mat-dialog-title>
  <h5>Training/Competency Files - <span *ngIf="name">{{ name }}</span></h5>

  <button mat-icon-button class="float-right" mat-dialog-close>
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <ng-container *ngIf="files?.length">
    <p>
      <app-files-list
        id="files"
        [files]="files"
        [view]="true">
      </app-files-list>
    </p>
  </ng-container>
</mat-dialog-content>
