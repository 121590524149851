<nav>
  <ol class="breadcrumb">
    <li class="breadcrumb-item" [ngClass]="{ active: last }" *ngFor="let menuItem of getBreadcrumbs(); let last = last">
      <a [routerLink]="menuItem.uri" *ngIf="!last; else isLast">{{ menuItem.name | titlecase }}</a>
      <ng-template #isLast>
        {{ menuItem.name | titlecase }}
      </ng-template>
    </li>
  </ol>
</nav>

<nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="activeRoute == '/admin'">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="mr-auto">
        <button mat-raised-button color="primary" id="add" (click)="runAutoSiteSignOut()" appMarginRight>
          Run Automatic {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Sign Out
        </button>
      </span>
  </div>
</nav>

<mat-list role="list" *ngIf="activeRoute == '/admin'">
  <mat-list-item role="listitem">
    <button mat-button routerLink="/admin/accounts">
      <mat-icon>account_tree</mat-icon>
      Accounts
    </button>
  </mat-list-item>

  <mat-list-item role="listitem">
    <button mat-button routerLink="/admin/users">
      <mat-icon>people</mat-icon>
      Users
    </button>
  </mat-list-item>

  <mat-list-item role="listitem">
    <button mat-button routerLink="/admin/sites">
      <mat-icon>poll</mat-icon>
      All {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }}
    </button>
  </mat-list-item>

  <mat-list-item role="listitem">
    <button mat-button routerLink="/admin/codes">
      <mat-icon>qr_code</mat-icon>
      QR Codes
    </button>
  </mat-list-item>

  <mat-list-item role="listitem">
    <button mat-button routerLink="/items">
      <mat-icon>assignment</mat-icon>
      Pre-Qualification Items
    </button>
  </mat-list-item>

  <mat-list-item role="listitem">
    <button mat-button routerLink="/gitlab-issues">
      <mat-icon>construction</mat-icon>
      Development Schedule
    </button>
  </mat-list-item>

  <mat-list-item role="listitem">
    <button mat-button routerLink="/admin/trades">
      <mat-icon>work</mat-icon>
      Job Titles
    </button>
  </mat-list-item>

  <mat-list-item role="listitem">
    <button mat-button routerLink="/admin/industries">
      <mat-icon>business</mat-icon>
      Industries
    </button>
  </mat-list-item>

  <mat-list-item role="listitem">
    <button mat-button routerLink="/admin/ppe">
      <mat-icon>headset</mat-icon>
      PPE
    </button>
  </mat-list-item>

  <mat-list-item role="listitem">
    <button mat-button routerLink="/admin/work-permits">
      <mat-icon>assignment_ind</mat-icon>
      Work Permits
    </button>
  </mat-list-item>

  <mat-list-item role="listitem">
    <button mat-button routerLink="/admin/task-analyses-revised">
      <mat-icon>developer_board</mat-icon>
      TA/JSA/SWMS
    </button>
  </mat-list-item>

  <mat-list-item role="listitem">
    <button mat-button routerLink="/admin/system-notifications">
      <mat-icon>notifications_active</mat-icon>
      System Notifications
    </button>
  </mat-list-item>

  <mat-list-item role="listitem" *ngIf="!utils.isProduction()">
    <button mat-button (click)="onResetDatabase()">
      <mat-icon>restore</mat-icon>
      Reset Database
    </button>
  </mat-list-item>
</mat-list>

<router-outlet></router-outlet>
