import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hazards-importer',
  templateUrl: './hazards-importer.component.html',
  styleUrls: ['./hazards-importer.component.scss']
})
export class HazardsImporterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
